export class ColorUtils {
    static getRainbowRange(min: number, max: number, hMax: number = 360, l: number = 100, s: number = 50): {value: number, color: string}[] { 
        const result: {value: number, color: string}[] = [];

        const range = (max - min);  
        const colorRange = hMax; 

        for (let i = min; i <= max; i++) {
            const extendedValue = (((i - min) * colorRange) / range);
            result.push({value: i, color: `hsl(${extendedValue},${l}%,${s}%)`})
        }

        return result;
    }

    static getRedToBlueRainbowRange(min: number, max: number, l: number = 100, s: number = 50): {value: number, color: string}[] { 
        return this.getRainbowRange(min, max, 255, l, s);
    }

    static getRedToGreenRainbowRange(min: number, max: number, l: number = 100, s: number = 50): {value: number, color: string}[] { 
        return this.getRainbowRange(min, max, 125, l, s);
    }
}