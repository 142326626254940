import { ModelTreeRoot } from './../../model/model-tree-root';
import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenuPanelComponent } from '../menu-panel/menu-panel.component';
import { BusinessUnitsBrowserComponent } from 'src/app/ui/pages/layout/organization/view/business-units-browser/business-units-browser.component';
import { MaintenanceBrowserComponent } from 'src/app/ui/pages/planning/maintenance/main/view/maintenance-browser/maintenance-browser.component';
import { CadConverterContainerComponent } from 'src/app/ui/pages/graphic-works/blueprint/view/cad-converter-container/cad-converter-container.component';
import { DrawingContainerComponent } from 'src/app/ui/pages/graphic-works/picto/view/drawing-container/drawing-container.component';
import { ClientsComponent } from 'src/app/ui/pages/clients/view/clients/clients.component';
import { HelpdeskMainComponent } from 'src/app/ui/pages/helpdesk/view/helpdesk-main/helpdesk-main.component';
import { MobilityBrowserComponent } from 'src/app/ui/pages/planning/mobility/main/view/mobility-browser/mobility-browser.component';
import { RolesAdminComponent } from 'src/app/ui/pages/administration/roles/view/roles-admin/roles-admin.component';
import { UserAccountAdminComponent } from 'src/app/ui/pages/administration/user-account/view/user-account-admin/user-account-admin.component';
import { DomainsComponent } from 'src/app/ui/pages/administration/domains/view/domains/domains.component';
import { PurchasesListComponent } from 'src/app/ui/pages/layout/inventory/referentials/purchases/view/purchases-list/purchases-list.component';
import { StoresManageComponent } from 'src/app/ui/pages/layout/inventory/referentials/storage/view/stores-manage/stores-manage.component';
import { ProvidersManageComponent } from 'src/app/ui/pages/layout/inventory/referentials/providers/view/providers-manage/providers-manage.component';
import { CatalogPageComponent } from 'src/app/ui/pages/layout/inventory/catalog/view/catalog-page/catalog-page.component';
import { CustomPerimetersManageComponent } from 'src/app/ui/pages/layout/real-estate/perimeters/view/custom-perimeters-manage/custom-perimeters-manage.component';
import { LayoutTypeComponent } from 'src/app/ui/pages/layout/real-estate/layout-types/view/layout-type-container/layout-type-container.component';
import { RefCityAdminComponent } from 'src/app/ui/pages/layout/real-estate/referentials/view/ref-city-admin/ref-city-admin.component';
import { AddressesAdminComponent } from 'src/app/ui/pages/layout/real-estate/referentials/view/addresses-admin/addresses-admin.component';
import { WorkplacesAdminComponent } from 'src/app/ui/pages/layout/real-estate/referentials/view/workplaces-admin/workplaces-admin.component';
import { LeaseListComponent } from 'src/app/ui/pages/planning/strategy/referentials/view/lease-list/lease-list.component';
import { LeaseDurationTypesComponent } from 'src/app/ui/pages/planning/strategy/referentials/view/lease-duration-types/lease-duration-types.component';
import { LeaseItemsComponent } from 'src/app/ui/pages/planning/strategy/referentials/view/lease-items/lease-items.component';
import { LeaseContractorsComponent } from 'src/app/ui/pages/planning/strategy/referentials/view/lease-contractors/lease-contractors.component';
import { LeaseTypesComponent } from 'src/app/ui/pages/planning/strategy/referentials/view/lease-types/lease-types.component';
import { LeaseEditorComponent } from 'src/app/ui/pages/planning/strategy/leases/view/lease-editor/lease-editor.component';
import { StrategyEditorComponent } from 'src/app/ui/pages/planning/strategy/nested/simulations/editor/main/view/strategy-editor/strategy-editor.component';
import { MobilityCompanyTasksComponent } from 'src/app/ui/pages/planning/mobility/referentials/view/mobility-company-tasks/mobility-company-tasks.component';
import { MobilityContributorsComponent } from 'src/app/ui/pages/planning/mobility/referentials/view/mobility-contributors/mobility-contributors.component';
import { MobilityFacilityCompaniesComponent } from 'src/app/ui/pages/planning/mobility/referentials/view/mobility-facility-companies/mobility-facility-companies.component';
import { MobilityFunctionsComponent } from 'src/app/ui/pages/planning/mobility/referentials/view/mobility-functions/mobility-functions.component';
import { MobilityJobTasksComponent } from 'src/app/ui/pages/planning/mobility/referentials/view/mobility-job-tasks/mobility-job-tasks.component';
import { MobilityJobsComponent } from 'src/app/ui/pages/planning/mobility/referentials/view/mobility-jobs/mobility-jobs.component';
import { MobilityProjectTaskTypesComponent } from 'src/app/ui/pages/planning/mobility/referentials/view/mobility-project-task-types/mobility-project-task-types.component';
import { LayersGrantsComponent } from 'src/app/ui/pages/administration/layers-grants/view/layers-grants/layers-grants.component';
import { BusinessUnitsUploadComponent } from 'src/app/ui/pages/layout/organization/view/business-units-upload/business-units-upload.component';
import { HttpClient } from '@angular/common/http';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { TranslateService } from '@ngx-translate/core';
import Container from 'typedi';
import { MessageService, PrimeNGConfig } from 'primeng/api';
import { SitesComponent } from 'src/app/ui/pages/layout/real-estate/sites/sites/sites.component';
import { BuildingsComponent } from 'src/app/ui/pages/layout/real-estate/buildings/buildings/buildings.component';
import { FloorsComponent } from 'src/app/ui/pages/layout/real-estate/floors/floors/floors.component';
import { MapComponent } from 'src/app/ui/pages/layout/real-estate/map/map-component/map.component';
import { ButtonModule } from 'primeng/button';
import { ToolbarModule } from 'primeng/toolbar';
import { NestedToolbarComponent } from '../nested-toolbar/nested-toolbar.component';
import { WelcomeScreenComponent } from 'src/app/ui/pages/welcome-screen/welcome-screen.component';
import { ToastModule } from 'primeng/toast';

@Component({
  selector: 'xc-main-container',
  standalone: true,
  imports: [CommonModule,
    MenuPanelComponent,
    BreadcrumbModule,
    ButtonModule,
    ToolbarModule,
    OverlayPanelModule,
    ToastModule,
    ClientsComponent,
    WelcomeScreenComponent,
    BusinessUnitsBrowserComponent,
    BusinessUnitsUploadComponent,
    MobilityBrowserComponent,
    StrategyEditorComponent,
    MaintenanceBrowserComponent,
    CadConverterContainerComponent,
    DrawingContainerComponent,
    HelpdeskMainComponent,
    LayersGrantsComponent,
    RolesAdminComponent,
    UserAccountAdminComponent,
    DomainsComponent,
    PurchasesListComponent,
    StoresManageComponent,
    ProvidersManageComponent,
    CatalogPageComponent,
    MapComponent,
    SitesComponent,
    BuildingsComponent,
    FloorsComponent,
    CustomPerimetersManageComponent,
    LayoutTypeComponent,
    RefCityAdminComponent,
    AddressesAdminComponent,
    WorkplacesAdminComponent,
    LeaseListComponent,
    LeaseDurationTypesComponent,
    LeaseItemsComponent,
    LeaseContractorsComponent,
    LeaseTypesComponent,
    LeaseEditorComponent,
    MobilityCompanyTasksComponent,
    MobilityContributorsComponent,
    MobilityFacilityCompaniesComponent,
    MobilityFunctionsComponent,
    MobilityJobTasksComponent,
    MobilityJobsComponent,
    MobilityProjectTaskTypesComponent,
    NestedToolbarComponent
  ],
  templateUrl: './main-container.component.html',
  styleUrls: ['./main-container.component.scss'],
  providers: [MessageService]
})
export class MainContainerComponent implements OnInit {
  model: ModelTreeRoot;
  userId?: string;

  constructor(httpClient: HttpClient, private translate: TranslateService, private primeNGConfig: PrimeNGConfig, messageService: MessageService) {
    this.model = new ModelTreeRoot(httpClient, messageService);
    translate.addLangs(['en', 'fr']);

    // AppInfoService.message$.subscribe(x => {
    //   this.snackBar.openFromComponent(CustomizableSnackbarComponent, {
    //     duration: 5000,
    //     data: x.message,
    //     verticalPosition: "top", // Allowed values are  'top' | 'bottom'
    //     horizontalPosition: "center" // Allowed values are 'start' | 'center' | 'end' | 'left'      
    //   });
    // })

  }

  async ngOnInit(): Promise<void> {
    await this.model.getAccessPage();
    const localValue = Container.has('culture') ? Container.get<string>('culture') : "fr";
    const locale = localValue.split("-")[0];
    this.translate.use(locale);
    this.translate.get('primeng').subscribe(res => this.primeNGConfig.setTranslation(res));
  }
}
