import { MainEventsEnum } from "./main-events-enum";
import packageInfo from 'package.json';
import { MenuTree } from "./menu-tree";
import { ClientVM } from "./client-vm";
import { MenuTreeNode } from "./menu-tree-node";
import { TreeNodeSelectEvent } from "primeng/tree";
import { EventListener } from "src/app/core/events/event-listener";
import { readableUUID } from "src/app/core/events/event-listener-uuid";
import { AppMenuRouteEnum } from "src/app/core/model/data-model/enums/app-menu-route-enum";

export class MenuPanelVM extends EventListener {
    client: ClientVM;
    appVersion: string = "version: na";
    hasMoreClients: boolean = false;
    mouseIsOver: boolean = false;

    menu!: MenuTree;
    selectedMenuItem: MenuTreeNode | undefined;

    private constructor(client: ClientVM, hasMoreClient: boolean) {
        super(readableUUID(MenuPanelVM.name));

        this.client = client;
        this.hasMoreClients = hasMoreClient;

        this.appVersion = packageInfo.version;

        this.addEventListener(MainEventsEnum.sideMenuOpeningRequested, () => {
            this.mouseIsOver = true;
        });
    }

    static async newAsync(client: ClientVM, hasMoreClient: boolean): Promise<MenuPanelVM> {
        const result = new MenuPanelVM(client, hasMoreClient);
        result.menu = await MenuTree.newAsync(client.grants);
        return result;
    }

    async newMenuClick(e: TreeNodeSelectEvent): Promise<void> {
        const n = (e.node as MenuTreeNode);
        if (!n.enable) return;
        await this.emitEventAsync(MainEventsEnum.selectedMenuPageChange, n.route);
    }

    // removeGrants(): void {
    //     this.menuItems.forEach(i => {
    //         i.granted = false;
    //     });
    // }

    private expandBack(item: MenuTreeNode): void {
        item.expanded = true;
        if (item.parent) {
            this.expandBack(item.parent);
        }
    }

    updateSelected(route: string): void {
        this.selectedMenuItem = this.menu.getMenuItem(route);
        if (this.selectedMenuItem) {
            this.expandBack(this.selectedMenuItem);
        }
    }

    async changeClient(): Promise<void> {
        await this.emitEventAsync(MainEventsEnum.clientPageRequested);
    }

    async healingButtonClick(): Promise<void> {
        // this.updateSelected(RootJunctionsEnum.helpdesk);
        // this.selectedItem = undefined;
        // await this.emitEventAsync(MainEventsEnum.selectedMenuPageChange, RootJunctionsEnum.helpdesk);
    }

    mouseover(): void {
        this.mouseIsOver = true;
    }

    mouseleave(): void {
        this.mouseIsOver = false;
    }

    async welcomeLinkClick(): Promise<void> {
        await this.emitEventAsync(MainEventsEnum.selectedMenuPageChange, AppMenuRouteEnum.welcome);
    }
}