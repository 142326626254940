import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StackChartWrapperVM } from '../../model/stack-chart-wrapper';
import { StackChartComponent } from '../stack-chart/stack-chart.component';
import { MaterialModule } from 'src/app/core/material.module';

@Component({
  selector: 'xc-stack-chart-wrapper',
  standalone: true,
  imports: [CommonModule, MaterialModule, StackChartComponent],
  templateUrl: './stack-chart-wrapper.component.html',
  styleUrls: ['./stack-chart-wrapper.component.scss']
})
export class StackChartWrapperComponent {
  @Input() model: StackChartWrapperVM | undefined;
}
