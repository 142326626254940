import { Component, Inject, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { CreateUserDialogVM } from '../../model/create-user-dialog-vm';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { FormsModule, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { Directory } from 'src/app/core/model/data-model/tables/directory';
import { Perimeter } from 'src/app/core/model/data-model/tables/perimeter';
import { AppRole } from 'src/app/core/model/data-model/tables/app-role';
import { CreateValidators } from '../../model/create-validators';

@Component({
  selector: 'xc-create-user-dialog',
  standalone: true,
  imports: [CommonModule, MatDialogModule, MatInputModule, MatButtonModule, MatFormFieldModule, MatSelectModule, MatCheckboxModule, MatAutocompleteModule, FormsModule, ReactiveFormsModule],
  templateUrl: './create-user-dialog.component.html',
  styleUrls: ['./create-user-dialog.component.scss']
})
export class CreateUserDialogComponent {
  addAppUserFormGroup: UntypedFormGroup;

  constructor(public dialogRef: MatDialogRef<CreateUserDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: CreateUserDialogVM) {
    this.addAppUserFormGroup = new UntypedFormGroup({
      directoryCtrl: new UntypedFormControl(Directory, [Validators.required, CreateValidators.directoryValidator()]),
      perimeterCtrl: new UntypedFormControl(Perimeter),
      emailCtrl: new UntypedFormControl(null, [Validators.required, CreateValidators.emailValidator()]),
      companyAdminCtrl: new UntypedFormControl(false, Validators.required),
      appRoleCtrl: new UntypedFormControl(AppRole, Validators.required)
    });
  }

  cancel(): void {
    this.dialogRef.close();
  }

  submitForm(): void {
    console.log(location);
    const appUserCreateDTO = {
      DirectoryId: (this.addAppUserFormGroup.controls['directoryCtrl'].value as Directory).diId,
      RoleId: (this.addAppUserFormGroup.controls['appRoleCtrl'].value as AppRole).apRoId,
      PerimeterId: (this.addAppUserFormGroup.controls['perimeterCtrl'].value as Perimeter).peId,
      Email: this.addAppUserFormGroup.controls['emailCtrl'].value,
      IsCompanyAdmin: this.addAppUserFormGroup.controls['companyAdminCtrl'].value,
      Caller: location.host
    };
    this.dialogRef.close(appUserCreateDTO);
  }

}
