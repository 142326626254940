import { DispalyThemeCategoryTable } from './../../db-model/tables/display-theme-category-table';
import { DefinedModelBase } from "../defined-model-base"

export class DisplayThemeCategory extends DefinedModelBase {
    diThCaId!: number;
    diThCaName!: string;
    diThCaDisplayNameId!: number;
    diThCaDisplayOrder!: number;

    constructor(dtoData: any, tableDef: any[] = []) {
        super(dtoData, [DispalyThemeCategoryTable].concat(tableDef));
    }
}
  