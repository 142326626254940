import { FloorModelEnum } from "src/app/core/model/data-model/enums/floor-model-enum";
import { Point } from "src/app/core/model/geometry-model/point.model";
import { Polygon } from "src/app/core/model/geometry-model/polygon.model";
import { Rectangle } from "src/app/core/model/geometry-model/rectangle.model";
import { EditableLayer } from "./editable-layer";
import { InteractionCommand } from "./interaction-command";
import { SelectedEntities } from "./selected-entities";
import { SelectedLayerEntities } from "./selected-layer-entities";
import { VariousSelectionGizmoModel } from '../../../subitems/gizmos/various-selection-gizmo/model/various-selection-gizmo-model';
import { FloorBlueprint } from '../floor-blueprint';
import { BlueprintDoorsLayer } from '../../../../svg-entities/model/layers/blueprint-doors-layer';
import { BlueprintEquipmentLayer } from '../../../../svg-entities/model/layers/blueprint-equipment-layer';
import { BlueprintLabelLayer } from '../../../../svg-entities/model/layers/blueprint-label-layer';
import { BlueprintWallsLayer } from '../../../../svg-entities/model/layers/blueprint-walls-layer';
import { logError } from 'src/app/core/services/logging-service';

export class SelectionInteraction {
    floorBlueprint: FloorBlueprint | undefined;
    currentCommand: InteractionCommand;
    editableLayers: EditableLayer[] = [];
    selectionPathPoints: Point[] = [];
    selectedEntities: SelectedEntities = new SelectedEntities();
    gizmo: VariousSelectionGizmoModel = new VariousSelectionGizmoModel(this.selectedEntities);

    constructor(command: InteractionCommand, blueprint: FloorBlueprint) {
        this.currentCommand = command;
        this.floorBlueprint = blueprint;
        this.gizmo.layerCommandButtonClick = (layerId: number) => { this.onUserLayerSelect(layerId); }
    }

    updateSelectionPathPoints(p: Point, layerId: number | null, toRemove: boolean): void {
        if (this.selectionPathPoints.length === 0)
        {
            return;
        }

        if (this.currentCommand.isDrawingRectangularSelectionCommand())
        {
            const firstPoint = this.selectionPathPoints[0];
            const temp: Point[] = [];

            temp.push(firstPoint);
            const secondPoint = new Point(p.x, firstPoint.y).rounded();
            temp.push(secondPoint);
            temp.push(p.rounded());
            const lastPoint = new Point(firstPoint.x, p.y).rounded();
            temp.push(lastPoint);

            this.selectionPathPoints = temp;
        }
        else
        {
            var distance = this.selectionPathPoints[this.selectionPathPoints.length - 1].distanceTo(p);
            if (distance > 0.3)
            {
                this.selectionPathPoints.push(p.rounded());
            }
        }

        this.selectedEntities.clear();
        this.getZoneSelection(layerId, toRemove);
    }

    getZoneSelection(layerId: number | null, toRemove: boolean) {
        const polygon = new Polygon(this.selectionPathPoints);
        polygon.close();
        this.gizmo.bbox = Rectangle.surroundingBbox(polygon.vertices);
        this.gizmo.commandsPosition = this.gizmo.bbox.center();

        this.editableLayers.forEach(l => {
            // Une cloison est sélectionnée si ses deux extrémités sont dans le polygone et si elle n'est pas en périphérie de la zone d'étude
            // Un équipement est sélectionné si le centre de sa bbox est dans le polygone
            // Une porte est sélectionnée si son point d'insertion est dans le polygone
            // Une étiquette est sélectionnée si le centre de son rectangle d'arrière plan est dans le polygone

            // Piste d'optimisation pour la recherche des items par coordonnées
            // https://math.stackexchange.com/questions/1588601/create-unique-identifier-from-close-coordinates
            // Une autre piste d'optimisation serait de commencer par trouver les surfaces sur lesquelles se produit la sélection
            // et de ne rechercher que sur les items enfants de ces surfaces
            // mais ça voudrait dire avoir la répartition hiérarchique des items par surface sur chaque calques et être capable
            // de maintenir cette répartition au travers des modifications

            // IMPORTANT !
            // Il ne doit pas être possible de mélanger dans une même sélection des éléments au FloorDataState Deleted avec les autres statuts

            let layer = this.floorBlueprint?.layersController.layer(l.layerId, this.floorBlueprint.topMostTaskId());
            if (layer != null && layer.isVisible()) {
                if (layerId != null && l.layerId !== layerId) {
                    return;
                }
                switch (l.layerId) {
                    case FloorModelEnum.Walls:
                        this.selectedEntities.setWalls((layer as BlueprintWallsLayer).select(polygon), toRemove);
                        break;
                    case FloorModelEnum.Doors:
                        this.selectedEntities.setDoors((layer as BlueprintDoorsLayer).select(polygon), toRemove);
                        break;
                    case FloorModelEnum.RoomLabels:
                    case FloorModelEnum.WorkplaceLabels:
                    case FloorModelEnum.PeopleLabels:
                    case FloorModelEnum.Measurement:
                        this.selectedEntities.setLabels(l.layerId, l.layerName, (layer as BlueprintLabelLayer).select(polygon), toRemove);
                        break;
                    default:
                        if (layer.isUseLayer) {
                            this.selectedEntities.setEquipments(l.layerId, l.layerName, (layer as BlueprintEquipmentLayer).selectByCenter(polygon), toRemove);
                        }
                        break;
                }
            }
        });
    }

    clear(): void {
        this.selectionPathPoints.splice(0);
        this.gizmo.show(false);
        if (this.userLayerSelect) {
            this.userLayerSelect(null);
        } else {
            logError("SelectionInteraction.userLayerSelect n'est pas écouté");
        }
    }

    userLayerSelect?: (selectedLayerItems: SelectedLayerEntities | null) => Promise<void>;
    userLayerSelectionError?: () => void;
    onUserLayerSelect(layerId: number): void {
        this.gizmo.show(false);
        const selectedItems = this.selectedEntities.layerEntities.find(x=> x.layerId === layerId);

        // Il est impossible de mélanger le dataState Deleted avec les autres statuts
        if (!selectedItems?.hasHeterogeneousDataState()) {
            if (this.userLayerSelect) {
                this.userLayerSelect(selectedItems ? selectedItems : null);
            } else {
                logError("SelectionInteraction.userLayerSelect n'est pas écouté");
            }
        } else {
            if (this.userLayerSelectionError) {
                this.userLayerSelectionError();
            } else {
                logError("SelectionInteraction.userLayerSelectionError n'est pas écouté");
            }
        }

        this.selectedEntities.layerEntities.splice(0);
    }
}