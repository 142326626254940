import { ApiEndpoints } from './api-endpoints';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams, HttpXhrBackend } from "@angular/common/http";
import { catchError, lastValueFrom, map, Observable, of } from "rxjs";
import { ApiData } from "./api-data";
import Container from 'typedi';
import { DataModelBuilder } from '../model/data-model/data-model.builder';
import { logError } from './logging-service';
import { EventEmitter } from '../events/event-emitter';
import { UserMessageSeverityEnum } from './user-message-severity-enum';
import { toastError } from './toast-service';

export class ApiService extends EventEmitter {
    //companyId: string | undefined;
    static userMessageRequestedEvent = "userMessageRequested";
    http: HttpClient;

    constructor(){
        super();
        this.http = Container.get('httpClient');
        // if (Container.has('companyId')) {
        //     this.companyId = Container.get('companyId');
        // } else {
        //     // La première fois c'est normal puisqu'on va choisir un client on n'a pas encore de companyId
        //     //LoggerService.error("ApiService : Le company id n'a pas été trouvé");
        // }
    }

    currentCompanyId(): string {
        if (Container.has('companyId')) {
            return Container.get('companyId');
        }
        return "na";
    }

    dynt(tableName: string, id?: number): string {
        let lastMember = "";
        if (id) {
            lastMember = `/${id}`;
        }
        return ApiEndpoints.dynt(tableName) + lastMember;
    }

    dynh(tableName: string): string {
        return ApiEndpoints.dynh(tableName);
    }

    dynview(tableName: string): string {
        return ApiEndpoints.dynview(tableName);
    }

    endPoint(url: string): string {
        return ApiEndpoints.endPoint(url);
    }

    protected async downloadFileAsync(url: string, fileName: string, params?: HttpParams): Promise<boolean> {
        const headers = this.getDownloadHeaders();
        return await lastValueFrom(this.http.get(url, {headers: headers, params: params, responseType: 'blob'})
            .pipe(
                map(data => {
                    return this.resolveAndDownloadBlob(data, fileName);
                }),
                catchError(this.handleError.bind(this))
        ));
    }

    private resolveAndDownloadBlob(response: any, fileName: string): boolean {
        try {
            const url = window.URL.createObjectURL(new Blob([response]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            window.URL.revokeObjectURL(url);
            link.remove();
            return true;
        } catch (error) {
            return false;
        }
    }

    protected async getAsync<T>(url: string, usageContextId?: number, params?: HttpParams, responseType?: string): Promise<ApiData<T> | null> {
        const headers = this.getHeaders(usageContextId);
        return await lastValueFrom(this.http.get<ApiData<T>>(url, {headers, params})
            .pipe(
                map(data => {
                    return this.getResult<T>(url, data);
                }),
                catchError(this.handleError.bind(this))
        ));
    }

    protected async getSingleAsync<T>(tableName: string, url: string, usageContextId?: number, params?: HttpParams): Promise<T | null> {
        const headers = this.getHeaders(usageContextId);
        return await lastValueFrom(this.http.get<ApiData<T>>(url, {headers, params})
            .pipe(
                map((data: ApiData<T>) => {
                    const result = this.getResult<T>(url, data);
                    if (result != null) {
                        return DataModelBuilder.instance<T>(tableName, result.payload);
                    }
                    return null;
                }),
                catchError(this.handleError.bind(this))
        ));
    }

    protected async getSingleFromArrayAsync<T>(tableName: string, url: string, usageContextId?: number, params?: HttpParams): Promise<T | null> {
        const headers = this.getHeaders(usageContextId);
        return await lastValueFrom(this.http.get<ApiData<any[]>>(url, {headers, params})
            .pipe(
                map(data => {
                    const result = this.getResult<any[]>(url, data);
                    if (result != null) {
                        return DataModelBuilder.instance<T>(tableName, result.payload[0]);
                    }
                    return null;
                }),
                catchError(this.handleError.bind(this))
        ));
    }

    protected async getArrayAsync<T>(tableName: string, url: string, usageContextId?: number, params?: HttpParams): Promise<T[]> {
        const headers = this.getHeaders(usageContextId);
        return await lastValueFrom(this.http.get<ApiData<any[]>>(url, {headers, params})
            .pipe(
                map(data => {
                    const result = this.getResult<any[]>(url, data);
                    if (result != null) {
                        return result.payload.map(item => {
                            return DataModelBuilder.instance<T>(tableName, item);
                        })
                    }
                    return [];
                }),
                catchError(this.handleError.bind(this))
        ));
    }

    protected async getArray2Async<T>(classType: any, url: string, params?: HttpParams): Promise<T[]> {
        const headers = this.getHeaders();
        return await lastValueFrom(this.http.get<ApiData<T[]>>(url, {headers, params})
            .pipe(
                map(data => {
                    return this.getArrayResult<T>(classType, url, data);
                }),
                catchError(this.handleError.bind(this))
        ));
    }

    protected async postAsync<T>(url: string, body: any, params?: HttpParams, headers?: HttpHeaders): Promise<ApiData<T> | null> {
        if (!headers) {
            headers = this.getHeaders();
        }
        return await lastValueFrom(this.http.post<ApiData<T>>(url, body, {headers, params})
            .pipe(
                map(data => {
                    return this.getResult<T>(url, data);
                }),
                catchError(this.handleError.bind(this))
            ));
      }

    protected async postAsyncAndGetSingle<T>(tableName: string, url: string, body: any, params?: HttpParams): Promise<T | null> {
        const headers = this.getHeaders();
        return await lastValueFrom(this.http.post<ApiData<T>>(url, body, {headers, params})
            .pipe(
                map(data => {
                    const result = this.getResult<T>(url, data);
                    if (result != null) {
                        return DataModelBuilder.instance<T>(tableName, result.payload);
                    }
                    return null;
                }),
                catchError(this.handleError.bind(this))
            ));
      }
    
    protected async patchAsync<T>(url: string, body?: any, usageContextId?: number, params?: HttpParams, successMessage?: string): Promise<ApiData<T> | null> {
        const headers = this.getHeaders(usageContextId);
        return await lastValueFrom(this.http.patch<ApiData<T>>(url, body, {headers, params})
            .pipe(
                map(data => {
                    return this.getResult<T>(url, data, successMessage);
                }),
                catchError(this.handleError.bind(this))
            ));
    }
    
    protected async patchAsyncAndGetRowsArray<T>(tableName: string, url: string, body: any, usageContextId?: number, params?: HttpParams): Promise<T[]> {
        const headers = this.getHeaders(usageContextId);
        return await lastValueFrom(this.http.patch<ApiData<any[]>>(url, body, {headers, params})
            .pipe(
                map(data => {
                    const result = this.getResult<any[]>(url, data);
                    if (result != null) {
                        return result.payload.map(item => {
                            return DataModelBuilder.instance<T>(tableName, item);
                        })
                    }
                    return [];
                }),
                catchError(this.handleError.bind(this))
        ));
    }

    protected async deleteAsync<T>(url: string, params?: HttpParams, successMessage?: string): Promise<ApiData<T>> {
        const headers = this.getHeaders();
        return await lastValueFrom(this.http.delete<ApiData<T>>(url, {headers, params})
            .pipe(
                map(data => {
                    return this.getResult<T>(url, data, successMessage);
                }),
                catchError(this.handleError.bind(this))
            ));
    }
    
    protected getCompanyIdHeader(): HttpHeaders {
        // let companyId = "na";
                    
        // if (this.companyId) {
        //     companyId = this.companyId;
        // }

        return new HttpHeaders().set("pb-client-id", this.currentCompanyId());
    }
    
    private getHeaders(usageContextId: number | undefined = undefined): HttpHeaders {
        let contextIdString: string = '1';
        if (usageContextId) {
            contextIdString = String(usageContextId);
        }
    
        let headers = new HttpHeaders()
            .set("pb-context-id", contextIdString);
            
        headers = headers.set("pb-client-id", this.currentCompanyId());
        headers = headers.set("pb-caller", "planb");

        headers = headers.set("Content-Type", "application/json");

        return headers;
    }
    
    private getDownloadHeaders(): HttpHeaders {
        let headers = new HttpHeaders();
        headers = headers.set("pb-client-id", this.currentCompanyId());
        headers = headers.set("Content-Type", "blob");
        return headers;
    }

    protected handleError(error: any): Observable<any> {
        if (error instanceof HttpErrorResponse) {
          // The backend returned an unsuccessful response code.
          // The response body may contain clues as to what went wrong,
          logError(`Backend returned code ${error.status}, ` + `body message was: ${JSON.stringify(error.error)}`);
          switch (error.status) {
            case 500:
                return of('Internal Server Error');
            case 400:
                return of('Bad Request');
            case 401:
                return of('Not authenticated');
            case 404:
                return of('Page Not Found');
            default:
                return of(error.error);
          }
        } else {
            // A client-side or network error occurred. Handle it accordingly.
            logError(`Domain error code: ${error.statusCode}\nError: ${error.message}`);
            toastError(error.message);
            return of(error.message);
        }
    }
    
    private getResult<T>(url: string, data: ApiData<T>, message?: string): ApiData<T> {
        if (!data) {
            logError("ApiService get error on " + url);
            return new ApiData<T>({});
        }

        if (!message && data.message && data.message.length === 0) {
            message = data.message[0];
        }

        if (message && message.length > 0) {
            toastError(message);
            console.log(message);
        }
        return new ApiData<T>(data);
    }

    private getPayload<T>(url: string, data: ApiData<T>): T | null {
        if (!data) {
            logError("ApiService get error on " + url);
            return null;
        }

        if (data.message) {
            this.sendUserMessage(data.message, UserMessageSeverityEnum.error);
            console.log(data.message);
        }

        return data.payload;
    }

    private getArrayResult<T>(classType: any, url: string, data: ApiData<T[]>): T[] {
        const payload = this.getPayload(url, data);
        if (payload == null || !Array.isArray(payload)) return [];
        const result = data.payload.map(item => {
            const tmp = new classType(item) as T;
            return tmp;
        });
        if (result.length > 0) {
            //if (result[0].error) logError(result[0].error);
        }
        return result;
    }

    private sendUserMessage(message: string, severity: UserMessageSeverityEnum): void {
        this.emitEventAsync(ApiService.userMessageRequestedEvent, message, severity);
    }
}