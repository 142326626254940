import { FloorModelEnum } from "src/app/core/model/data-model/enums/floor-model-enum";
import { BpSvgGroup } from "../../../../bp-svg-core-model/bp-svg-group";
import { BpSvgUse } from "../../../../bp-svg-core-model/bp-svg-use";
import { BpSvgLabel } from "../../../../svg-entities/model/bp-svg-label";
import { BpSvgWall } from "../../../../svg-entities/model/bp-svg-wall";
import { SelectedLayerEntities } from "./selected-layer-entities";
import { BpSvgMeasurementLabel } from "../../../../svg-entities/model/bp-svg-measurement-label";

export class SelectedEntities {
    layerEntities: SelectedLayerEntities[] = [];

    setWalls(walls: BpSvgWall[], toRemove: boolean): void {
        if (walls.length > 0) {
            this.layerEntities.push(new SelectedLayerEntities(FloorModelEnum.Walls, "Cloisons", walls, "line", toRemove));
        }
    }

    setDoors(doors: BpSvgGroup[], toRemove: boolean): void {
        if (doors.length > 0) {
            this.layerEntities.push(new SelectedLayerEntities(FloorModelEnum.Doors, "Portes", doors, "g", toRemove));
        }
    }

    setLabels(layerId: number, layerName: string, labels: BpSvgLabel[], toRemove: boolean): void {
        if (labels.length > 0) {
            this.layerEntities.push(new SelectedLayerEntities(layerId, layerName, labels, "rect", toRemove));
        }
    }

    setEquipments(layerId: number, layerName: string, equipments: BpSvgUse[], toRemove: boolean): void {
        if (equipments.length > 0) {
            this.layerEntities.push(new SelectedLayerEntities(layerId, layerName, equipments, "path", toRemove));
        }
    }

    clear(): void {
        this.layerEntities.splice(0);
    }
}
