import { UnitOfWorkTable } from "../../db-model/tables/unit-of-work-table";
import { DefinedModelBase } from "../defined-model-base";

export class UnitOfWork extends DefinedModelBase {
    unOfWoId!: number;
    unOfWoCode!: string;
    unOfWoName!: string;
    unOfWoIsActive!: boolean;

    constructor(dtoData: any, tableDef: any[] = []) {
        super(dtoData, [UnitOfWorkTable].concat(tableDef));
    }
}