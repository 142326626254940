export const FloorAnatomyDbView = {
    databaseTableName: "floor_anatomy_view",
    buSiteId: "Bu_SiteId", 
    flBuildingId: "Fl_BuildingId",
    flId: "Fl_Id",
    roLaTyId: "RoLaTy_Id",
    roLaTyCode: "RoLaTy_Code",
    roLaTyName: "RoLaTy_Name",
    roLaTyColor: "RoLaTy_Color",
    roLaTyIsNUA: "RoLaTy_IsNUA",
    roLaTyParentId: "RoLaTy_ParentId",
    flAnViArea: "FlAnVi_Area",
    flAnViRoundedArea: "FlAnVi_RoundedArea",
    roViIsCurrentState: "RoVi_IsCurrentState"
}