export class XcMaths {
    static toRad(andleInDeg: number): number {
        return andleInDeg / 180.0 * Math.PI;
    }

    static toDeg(angleInRad: number): number {
        return angleInRad * 180.0 / Math.PI;
    }

    static round(value: number, decimals: number): number {
        return Number(Math.round(Number(value + "e" + decimals)) + "e-" + decimals);
    }

    static get360DegreeAngle(angle: number, decimals: number = 0): number {
        if(angle >= 0)
        {
            return XcMaths.round(angle, decimals) % 360;
        }

        return XcMaths.round(360 + angle, decimals);
}
}
