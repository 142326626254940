import { AppService } from 'src/app/core/services/backend-services/app-service';
import { RoomAttributionTypeView } from 'src/app/core/model/data-model/views/room-attribution-type-view';
import { RoomActivityStatusTypeView } from 'src/app/core/model/data-model/views/room-activity-status-type-view';
import { LoggerService } from 'src/app/core/services/logger.service';
import { RoomAllocationViewSet } from 'src/app/core/model/data-model/view-set/room-allocation-view-set';
import { Container } from 'typedi';
import { MatDialog } from "@angular/material/dialog";
import { RoomLayoutType } from "src/app/core/model/data-model/tables/room-layout-type";
import { EntitiesFormModel } from "./entities-form-model";
import { RoomAttributionTypeEnum } from 'src/app/core/model/data-model/enums/room-attribution-type-enum';
import { ArrayUtils } from 'src/app/core/model/static-functions/array-utils';
import { RoomActivityStatusTypeEnum } from 'src/app/core/model/data-model/enums/room-activity-status-type-enum';
import { RoomUseOption } from './room-use-option';
import { Perimeter } from 'src/app/core/model/data-model/tables/perimeter';
import { TaskService } from 'src/app/core/services/backend-services/task-service';
import { EntitiesSelectionSet } from '../../../../blueprint-viewer-content-panel/itself/model/interaction/entities-selection-set';
import { BpRoomService } from '../../../../blueprint-viewer-content-panel/itself/services/bp-room-service';
import { RoomAllocationDisplay } from '../../../../dialog/room-allocation-editor/model/room-allocation-display';
import { RoomAllocationEditorComponent } from '../../../../dialog/room-allocation-editor/view/room-allocation-editor/room-allocation-editor.component';
import { BpSvgRoomLabel } from '../../../../svg-entities/model/bp-svg-room-label';
import { FloorModelEnum } from 'src/app/core/model/data-model/enums/floor-model-enum';
import { RoomService } from 'src/app/core/services/backend-services/room-service';
import { XcMaths } from 'src/app/core/model/static-functions/xc-maths';
import { PerimeterTypeEnum } from 'src/app/core/model/data-model/enums/perimeter-type-enum';
import { FloorModelCategoryEnum } from 'src/app/core/model/data-model/enums/floor-model-category-enum';
import { EventEmitter } from 'src/app/core/events/event-emitter';
import { FloorBlueprintEventsEnum } from '../../../../../container/model/floor-blueprint-events-enum';
import { take } from 'rxjs';
import { ZAppParameterEnum } from 'src/app/core/model/data-model/enums/z-app-parameter-enum';
import { EquipmentStorageAreaTable } from 'src/app/core/model/db-model/tables/equipment-storage-area-table';
import { DyntService } from 'src/app/core/services/backend-services/dynt-service';
import { TablesNamesEnum } from 'src/app/core/model/db-model/tables-names-enum';
import { EquipmentStorageArea } from 'src/app/core/model/data-model/tables/equipment-storage-area';
import { ClientVM } from 'src/app/ui/main/model/client-vm';
import { logError, missingListener } from 'src/app/core/services/logging-service';

export class RoomFormVM extends EventEmitter implements EntitiesFormModel {
    selectedRoomLabels: EntitiesSelectionSet = new EntitiesSelectionSet();
    hasPlanningTask: boolean = false;
    layerId: number = FloorModelEnum.Rooms;
    isEditable: boolean = false;

    currentFloorId: number = 0;
    currentFloorName: string = "";

    totalArea: number = 0;
    
    initialCode: string | undefined;
    initialTypeId: number | undefined;
    sharedCode: string = "*";

    currentLayoutType: RoomLayoutType | undefined;
    sharedLayoutType: RoomLayoutType | undefined;

    currentUseOption: RoomUseOption | undefined;
    sharedUseOption: RoomUseOption | undefined;

    currentPerimeter: Perimeter | undefined;
    sharedPerimeter: Perimeter | undefined;

    currentAllocations: RoomAllocationViewSet[] = [];

    roomLayoutTypes: RoomLayoutType[] = [];
    activityStatusTypes: RoomActivityStatusTypeView[] = [];
    attributionTypes: RoomAttributionTypeView[] = [];
    roomUsesOptions: RoomUseOption[] = [];
    perimeters: Perimeter[] = [];
    
    dialog: MatDialog | undefined;

    canCreateDirectTask: boolean = false;
    userHasCreateDirectTaskGrant: boolean = false;
    directTaskName: string = "Nouvelle étude";

    storageRoomLayoutTypeId: number = 0;
    canDefineStorageArea: boolean = false;

    constructor() { 
        super();
        this.loadStorageParameter();
    }

    async loadStorageParameter(): Promise<void> {
        const p = Container.get(AppService);
        const param = await p.getParam(ZAppParameterEnum.StorageRoomLayoutTypeId);
        if (param) {
            this.storageRoomLayoutTypeId = Number(param.apPaValue);
        }
    }

    async initialize(selectedRoomLabels: EntitiesSelectionSet, floorId: number, floorName: string, isPlanningTask: boolean, editable: boolean) {
        this.isEditable = editable;
        this.selectedRoomLabels = selectedRoomLabels;
        this.hasPlanningTask = isPlanningTask;

        const rs = Container.get(RoomService);
        const refData = await rs.loadReferenceDatas(floorId);

        const tmp = refData.layoutTypes;
        this.roomLayoutTypes = tmp.sort((a,b) => a.roLaTyName.localeCompare(b.roLaTyName));
        this.sharedLayoutType = new RoomLayoutType({
            roLaTyId: 0,
            roLaTyCode: "*",
            roLaTyDepth: 0,
            roLaTyParentId: 0,
            roLaTyIsActive: true,
            roLaTyName: "*",
            roLaTyColor: "#ffefefef",
            roLaTyScopeId: 1,
            roLaTyIsNUA: false}
        );
        this.roomLayoutTypes = [this.sharedLayoutType].concat(tmp);

        this.activityStatusTypes = refData.activityStatusTypes;
        this.attributionTypes = refData.attributionTypes;

        this.buildRoomUsesOptions();
        
        if (this.currentFloorId !== floorId) {
            this.currentFloorId = floorId;
            this.currentFloorName = floorName;

            const tmp = refData.perimeters;
            this.sharedPerimeter = new Perimeter(
                {peId: 0,
                peName: "*",
                peDescription: "*",
                peTypeId: 0,
                peIsSharingAreaItem: true,
                peReadGrantId: 0});
            this.perimeters = [this.sharedPerimeter].concat(tmp);
        }

        this.userHasCreateDirectTaskGrant = refData.UserCanCreateDirectTask;

        await this.setInitialValues();
    }

    async setInitialValues(): Promise<void> {
        // Le user peut créer une tâche directe s'il est habilité et
        // avoir au moins le plan "Occupancy" et
        // dans le cas où le client a le plan inférieur à "Mobility", s'il n'y a qu'une seule surface sélectionnée
        const client = Container.get("client");
        const hasOccupancy = (client as ClientVM).hasOccupancy();
        const hasSingleRoomLimitation = !(client as ClientVM).hasMobility() && !(client as ClientVM).isPlayground();
        const directTaskGranted = hasOccupancy && (this.selectedRoomLabels.count === 1 || (this.selectedRoomLabels.count > 1 && !hasSingleRoomLimitation));
        this.canCreateDirectTask = this.userHasCreateDirectTaskGrant && directTaskGranted;

        if (this.selectedRoomLabels.count === 1) {
            const label = this.selectedRoomLabels.single<BpSvgRoomLabel>();
            this.initialCode = label.text.tSpans[0].text;
        } else {
            this.initialCode = undefined;
        }

        await this.getSharedValues();

        this.calculateTotalArea();
    }

    async getSharedValues(): Promise<void> {
        this.currentLayoutType = this.getSharedLayoutType();
        await this.getStorageAreaContext();

        this.currentUseOption = this.getSharedUseOption();
        this.currentPerimeter = await this.getSharedPerimeter();
        this.currentAllocations = this.getSharedAllocations();
    }

    async getStorageAreaContext(): Promise<void> {
        const selectedRoomsAreStorageLayout = this.currentLayoutType?.roLaTyId === this.storageRoomLayoutTypeId;
        let selectedRoomsAreAlreadyEquipmentStorageArea: boolean = false;

        if (selectedRoomsAreStorageLayout) {
            // Récupère les emplacements de stockage qui sont des locaux du parc
            const t = Container.get(DyntService);
            const stores = await t.downloadTable<EquipmentStorageArea>(TablesNamesEnum.EquipmentStorageArea);
            const roomIds = stores.filter(x=> x.eqStArRoomId != null).map(x=> x.eqStArRoomId!);

            // Si la ou une des surfaces sélectionnées est déjà définie comme emplacment de stockage d'équipement
            // la commande de définition sera masquée
            const selectedRoomsIds = (this.selectedRoomLabels.items as BpSvgRoomLabel[]).map(x=> x.svgRoom).map(x=> x!.floorDataId);
            selectedRoomsAreAlreadyEquipmentStorageArea = roomIds.filter(value => selectedRoomsIds.includes(value)).length > 0;
        }

        this.canDefineStorageArea = selectedRoomsAreStorageLayout && !selectedRoomsAreAlreadyEquipmentStorageArea;
    }

    getSharedLayoutType(): RoomLayoutType | undefined {
        let types = (this.selectedRoomLabels.items as BpSvgRoomLabel[]).map(x=> x.svgRoom!.room().roLayoutTypeId);
        types = [...new Set(types)];
        if (types.length === 1) {
            return this.roomLayoutTypes.find(x=> x.roLaTyId === types[0]);
        } else {
            return this.sharedLayoutType;
        }
    }

    getSharedUseOption(): RoomUseOption | undefined {
        let types = (this.selectedRoomLabels.items as BpSvgRoomLabel[]).map(x=> ({activityStatusTypeId: x.svgRoom!.room().roActivityStatusTypeId, attributionTypeId: x.svgRoom!.room().roAttributionTypeId}));
        //types = [...new Set(types)];
        types = [...new Map(types.map(obj => [`${obj.activityStatusTypeId}:${obj.attributionTypeId}`, obj])).values()];
        if (types.length === 1) {
            return this.roomUsesOptions.find(x=> x.activityStatusId === types[0].activityStatusTypeId && x.attributionTypeId === types[0].attributionTypeId);
        } else {
            return this.sharedUseOption;
        }
    }

    async getSharedPerimeter(): Promise<Perimeter | undefined> {
        let types = (this.selectedRoomLabels.items as BpSvgRoomLabel[]).map(x=> x.svgRoom!.room().roAttributionTypeId);
        types = [...new Set(types)];
        if (types.length === 1 && types[0] === RoomAttributionTypeEnum.Sharing) {
            // Télécharge la mutualisation du local
            const s = Container.get(BpRoomService);
            const sharing = await s.downloadRoomSharing(this.selectedRoomLabels.floorDataParentIds());
            if (sharing != null && sharing.length === 1) {
                const singleSharing = sharing[0];
                return this.perimeters.find(x=> x.peId === singleSharing.roShPerimeterId);
            }
        }
        return this.sharedPerimeter;
    }

    getSharedAllocations(): RoomAllocationViewSet[] {
        // Recherche si toutes les surfaces ont le même tableau d'allocations
        let result: RoomAllocationViewSet[] = [];
        let allocations = (this.selectedRoomLabels.items as BpSvgRoomLabel[]).map(x=> x.svgRoom!.allocations());
        if (allocations != null && allocations.length > 0) {
            result = allocations[0];
            if (allocations.length > 1) {
                for (let i = 1; i < allocations.length; i++) {
                const aArray = allocations[i];
                aArray.forEach(a => {
                    const v = result.find(x=> x.dataSet.roAlBusinessUnitId === a.dataSet.roAlBusinessUnitId && x.dataSet.roAlRate === a.dataSet.roAlRate);
                    if (v == null) {
                        result = [];
                    i = allocations.length;
                    return;
                    }
                });
                }
            }
        }
        return result;
    }

    calculateTotalArea(): void {
        const rooms = (this.selectedRoomLabels.items as BpSvgRoomLabel[]).map(x=> x.svgRoom?.roomSet?.roomSet);
        this.totalArea = XcMaths.round(ArrayUtils.sumBy(rooms, 'roArea'), 2);
    }

    onSelectionSetChanged(): void {
        this.setInitialValues();
    }

    buildRoomUsesOptions(): void {
        this.sharedUseOption = {
            label: "*",
            activityStatusId: 0,
            attributionTypeId: RoomAttributionTypeEnum.None,
            disabled: false,
            color: "#efefef"
            } as RoomUseOption;
        this.roomUsesOptions.push(this.sharedUseOption);    

        this.activityStatusTypes.forEach(a => {
        // Activité : 'Utilisable' / 'Entravaux' / 'Vacante'
        // 'Utilisable' n'est pas affiché dans la liste
        if (a.roAcTyId !== RoomActivityStatusTypeEnum.Usable) {
                this.roomUsesOptions.push({
                label: a.roAcTyViDisplayName,
                activityStatusId: a.roAcTyId,
                attributionTypeId: RoomAttributionTypeEnum.None,
                disabled: false,
                color: a.roAcTyColor
                } as RoomUseOption);
            }
        });

        this.attributionTypes.forEach(a => {
        // Attribution : 'Aucune' / 'Structurelle' / 'Mutualisée' / 'Allouée'
        // 'Aucune' n'est pas affichée dans la liste
        if (a.roAtTyId !== RoomAttributionTypeEnum.None) {
            this.roomUsesOptions.push({
                label: a.roAtTyViDisplayName,
                activityStatusId: RoomActivityStatusTypeEnum.Usable,
                attributionTypeId: a.roAtTyId,
                disabled: false,
                color: a.roAtTyColor
                } as RoomUseOption);
            }
        });

        // Lorsqu'il y a plusieurs surfaces sélectionnées et qu'elles n'ont pas toutes le même usage
        // this.roomUsesOptions.push({
        // label: 'Plusieurs utilisations',
        // activityStatusId: null,
        // attributionTypeId: null,
        // disabled: true,
        // color: "#ffffff"
        // } as RoomUseOption);

        this.roomUsesOptions.sort((a, b) => a.label.localeCompare(b.label));
    }

    roomLayoutTypeOptionCompare( option: RoomLayoutType, value: RoomLayoutType ) : boolean {
        return option.roLaTyId === value?.roLaTyId;
    }

    roomUseOptionCompare( option: RoomUseOption, value: RoomUseOption ) : boolean {
        return option.activityStatusId === value?.activityStatusId && option.attributionTypeId === value?.attributionTypeId;
    }

    perimeterCompare( option: Perimeter, value: Perimeter ) : boolean {
        return option.peId === value?.peId;
    }
    
    themeRefreshRequested?: (reloadCaptions: boolean) => void;
    askForThemeRefresh(reloadCaptions: boolean): void {
        setTimeout(() => {
            if (this.themeRefreshRequested) {
                this.themeRefreshRequested(reloadCaptions);
            } else {
                missingListener("RoomFormVM.themeRefreshRequested");
            }
        }, 0);
    }

    async onCodeInputKeyUpEnter(e: Event, valid: boolean | null) {
        if (this.selectedRoomLabels.count === 1) {
            const single = this.selectedRoomLabels.single<BpSvgRoomLabel>();
            if (valid) {
                // L'enregistrement est demandé
                const s = Container.get(RoomService);
                const result = await s.updateRoomLabel(single.svgRoom!.floorDataId, single.mainText());
                if (result != null) {
                    // L'enregistrement a réussi, on actualise la valeur initiale
                    this.initialCode = single.mainText();
                } else {
                    // L'enregistrement a échoué, on remet la valeur à son état initial
                    single.text.tSpans[0].text = this.initialCode!;
                }
            } else {
                // La saisie n'est pas valide, on remet la valeur à son état initial
                single.text.tSpans[0].text = this.initialCode!;
            }
        }
    }

    //layoutTypeUpdateRequested?: (floorDataId: number, layoutTypeId: number) => Promise<boolean>
    async onLayoutTypeChange(e: RoomLayoutType) {
        // L'enregistrement est demandé
        const s = Container.get(RoomService);
        const result = await s.updateRoomLayoutType(this.selectedRoomLabels.floorDataParentIds(), e.roLaTyId);
        if (result) {
            // L'enregistrement a réussi
            (this.selectedRoomLabels.items as BpSvgRoomLabel[]).forEach(l => {
                l.svgRoom!.roomSet!.roomSet.dataSet.roLayoutTypeId = e.roLaTyId;
            });
            // Demande l'actualisation du thème s'il est affiché
            this.askForThemeRefresh(true);
        }
    }

    async onUseChange(e: RoomUseOption) {
        const s = Container.get(RoomService);
        // Si la surface devient 'en travaux' ou 'vacante', elle n'est donc plus utilisée
        // Le backend se charge de supprimer les données associées si nécessaire
        if (e.activityStatusId === RoomActivityStatusTypeEnum.Empty || e.activityStatusId === RoomActivityStatusTypeEnum.UnderConstruction) {
            const result = await s.discardRooms(this.selectedRoomLabels.floorDataParentIds(), e.activityStatusId);
            if (result) {
                // L'enregistrement a réussi
                (this.selectedRoomLabels.items as BpSvgRoomLabel[]).forEach(l => {
                    l.svgRoom!.roomSet!.roomSet.dataSet.roActivityStatusTypeId = e.activityStatusId;
                    l.svgRoom!.roomSet!.roomSet.dataSet.roAttributionTypeId = RoomAttributionTypeEnum.None;
                    l.svgRoom!.setAllocations([]);
                });
                await this.getSharedValues();

                // Demande l'actualisation du thème s'il est affiché
                this.askForThemeRefresh(true);
            }
            return;
        }

        // La surface devient allouable
        if (e.attributionTypeId === RoomAttributionTypeEnum.Allocation) {
            // S'abonne à l'event de changement d'allocation déclenché par la fermeture de la dialog de sélection
            this.onAllocationChange = async (updatedAllocations: RoomAllocationDisplay[]) => {
                if (updatedAllocations != null) {
                    await this.updateAllocations(updatedAllocations);
                }
            }
            // Affiche la dialog de sélection d'allocation
            this.getAllocations();
            return;
        }

        // La surface devient mutualisée
        // Le périmètre par défaut est celui de l'étage
        if (e.attributionTypeId === RoomAttributionTypeEnum.Sharing) {
            const defaultPerimeter = this.perimeters.find(x=> x.peTypeId === PerimeterTypeEnum.floor);
            if (defaultPerimeter != null ){
                const result = await s.updateRoomsSharing(this.selectedRoomLabels.floorDataParentIds(), defaultPerimeter.peId);
                if (result) {
                    // L'enregistrement a réussi
                    (this.selectedRoomLabels.items as BpSvgRoomLabel[]).forEach(l => {
                        l.svgRoom!.roomSet!.roomSet.dataSet.roActivityStatusTypeId = RoomActivityStatusTypeEnum.Usable;
                        l.svgRoom!.roomSet!.roomSet.dataSet.roAttributionTypeId = RoomAttributionTypeEnum.Sharing;
                        l.svgRoom!.setAllocations([]);
                    });
                    await this.getSharedValues();

                    // Demande l'actualisation du thème s'il est affiché
                    this.askForThemeRefresh(true);
                }
            } else {
                throw "Impossible de récupérer le périmètre de mutualisation 'Etage'";
            }
            return;
        }

        // La surface devient structurelle
        if (e.attributionTypeId === RoomAttributionTypeEnum.Exploitation) {
            const result = await s.wipeRooms(this.selectedRoomLabels.floorDataParentIds());
            if (result) {
                // L'enregistrement a réussi
                (this.selectedRoomLabels.items as BpSvgRoomLabel[]).forEach(l => {
                    l.svgRoom!.roomSet!.roomSet.dataSet.roActivityStatusTypeId = RoomActivityStatusTypeEnum.Usable;
                    l.svgRoom!.roomSet!.roomSet.dataSet.roAttributionTypeId = RoomAttributionTypeEnum.Exploitation;
                    l.svgRoom!.setAllocations([]);
                });
                await this.getSharedValues();

                // Demande l'actualisation du thème s'il est affiché
                this.askForThemeRefresh(true);
            }
            return;
        }
    }

    async onPerimeterChange(e: Perimeter) {
        const s = Container.get(BpRoomService);
        const result = await s.updateRoomsSharing(this.selectedRoomLabels.floorDataParentIds(), e.peId);
        if (result != null) {
            // L'enregistrement a réussi
            //this.initialPerimeterId = e.id;
        } else {
            // L'enregistrement a échoué, on remet la valeur à son état initial
            //this.currentPerimeter = this.perimeters.find(x=> x.peId === this.initialPerimeterId);
        }
    }

    onAllocationChange?: (updatedAllocations: RoomAllocationDisplay[]) => void;
    async updateAllocations(updatedAllocations: RoomAllocationDisplay[]) {
        // Nota: la répartition de surface est calculée par le backend
        const s = Container.get(BpRoomService);
        const result = await s.updateRoomAllocations(this.selectedRoomLabels.floorDataParentIds(), updatedAllocations);
        if (result.length > 0) {
            // L'enregistrement a réussi, le backend a retourné les nouvelles allocations
            (this.selectedRoomLabels.items as BpSvgRoomLabel[]).forEach(l => {
                l.svgRoom!.room().roActivityStatusTypeId = RoomActivityStatusTypeEnum.Usable;
                l.svgRoom!.room().roAttributionTypeId = RoomAttributionTypeEnum.Allocation;
                l.svgRoom!.setAllocations(result.filter(x=> x.dataSet.roAlFloorDataId === l.parentId));
            });
            await this.getSharedValues();

            // Demande l'actualisation du thème s'il est affiché
            this.askForThemeRefresh(true);
        }
    }

    getAllocations(): void {
        if (!this.dialog) return;
        let dialogRef = this.dialog.open(RoomAllocationEditorComponent, { data: { allocations: this.getSharedAllocations() }, width: "700px" });
            // Le .pipe(take(1)) s'assure que le traitement ne sera exécuté qu'une seule fois
            dialogRef.afterClosed().pipe(take(1)).subscribe(result => {
          if (result != null) {
            // Si le résultat n'est pas null c'est que les allocations ont été modifiées et que le user a cliqué sur Ok
            if (this.onAllocationChange) {
                this.onAllocationChange(result);
            } else {
                logError("RoomFormVM.onAllocationChange n'est pas écouté");
            }
          }
        });
    }

    async onDirectTaskCreateButtonClick(name: string) {
        // Si le client a la licence "Occupancy" il ne peut modifier que le mobilier
        const layersIds: number[] = [];
        const client = Container.get("client");
        const hasMobility = (client as ClientVM).hasMobility();
        const isPlaygroud = (client as ClientVM).isPlayground();
        if (hasMobility || isPlaygroud) {
            layersIds.push(FloorModelCategoryEnum.Planning);
        } else {
            layersIds.push(FloorModelEnum.Furniture);
        }


        const s = Container.get(TaskService);
        const result = await s.createDirectTask(this.selectedRoomLabels.floorDataParentIds(), name, layersIds);
        if (result != null) {
            // La tâche directe a été créée, on demande le chargement sur le plan
            await this.emitEventAsync(FloorBlueprintEventsEnum.directTaskCreated, result.taId);
        }
    }

    async onAllocationUpdateButtonClick(): Promise<void> {
        // S'abonne à l'event de changement d'allocation déclenché par la fermeture de la dialog de sélection
        this.onAllocationChange = async (updatedAllocations: RoomAllocationDisplay[]) => {
            if (updatedAllocations != null) {
                await this.updateAllocations(updatedAllocations);
            }
        }
        // Affiche la dialog de sélection d'allocation
        this.getAllocations();
    }

    allocationsCopyRequested?: () => void;
    onAllocationSetButtonClick(): void {
        if (this.allocationsCopyRequested && this.selectedRoomLabels.count === 1) {
            this.allocationsCopyRequested()
        }
    }

    async onDefineStorageAreaButtonClick() {
        const values: {}[] = [];
        (this.selectedRoomLabels.items as BpSvgRoomLabel[]).forEach(l => {
            values.push({
                "TableName": EquipmentStorageAreaTable.databaseTableName,
                [EquipmentStorageAreaTable.eqStArRoomId]: l.svgRoom?.floorDataId,
                [EquipmentStorageAreaTable.eqStArName]: `${this.currentFloorName} ${l.svgRoom?.roomSet?.roomSet.dataSet.roCode}`
            })
        });

        const t = Container.get(DyntService);
        const result = await t.post<EquipmentStorageArea>("", values);
        if (result != null) {
            this.canDefineStorageArea = false;
        }
    }
}