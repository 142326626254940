export enum TablesNamesEnum {
    Address = "address",
    AppGrantGroup = "app_grant_group",
    AppGrant = "app_grant",
    AppRoleGrant = "app_role_grant",
    AppRole = "app_role",
    AppUser = "app_user",
    Building = "building",
    BusinessUnit = "business_unit",
    City = "city",
    PolicyCity = "policy_city",
    Continent = "continent",
    Country = "country",
    Directory = "directory",
    DisplayTheme = "display_theme",
    DisplayThemable = "display_themable",
    DisplayThemeCategory = "display_theme_category",
    DisplayThemeItem = "display_theme_item",
    Document = "document",
    DocumentClassification = "document_classification",
    DocumentDataModel = "document_data_model",
    DocumentType = "document_type",
    DocumentVersion = "document_version",
    DxfEntity = "dxf_entity",
    DxfImport = "dxf_import",
    DxfLayer = "dxf_layer",
    EmailGrantedDomain = "email_granted_domain",
    EquipmentAllocation = "equipment_allocation",
    EquipmentCatalog = "equipment_catalog",
    EquipmentCategory = "equipment_category",
    EquipmentChangeLog = "equipment_change_log",
    EquipmentInventoryState = "equipment_inventory_state",
    EquipmentItem = "equipment_item",
    EquipmentPlanning = "equipment_planning",
    EquipmentPlanningState = "equipment_planning_state",
    EquipmentPurchase = "equipment_purchase",
    EquipmentPurchaseDetail = "equipment_purchase_detail",
    EquipmentRemoval = "equipment_removal",
    EquipmentRemovalCase = "equipment_removal_case",
    EquipmentStorage = "equipment_storage",
    EquipmentStorageArea = "equipment_storage_area",
    FacilityCompany = "facility_company",
    FacilityCompanyTask = "facility_company_task",
    FacilityJob = "facility_job",
    FacilityJobTask = "facility_job_task",
    Floor = "floor",
    FloorHistory = "floor_history",
    FloorCatalog = "floor_catalog",
    FloorCatalogImport = "floor_catalog_import",
    FloorCatalogImportItem = "floor_catalog_import_item",
    FloorCatalogImportLog = "floor_catalog_import_log",
    FloorData = "floor_data",
    FloorDataImport = "floor_data_import",
    FloorDataImportItem = "floor_data_import_item",
    FloorDataImportLog = "floor_data_import_log",
    FloorDataState = "floor_data_state",
    FloorDataTimestamp = "floor_data_timestamp",
    FloorDoorOpeningType = "floor_door_opening_type",
    FloorDoorStyle = "floor_door_style",
    FloorModel = "floor_model",
    FloorModelCategory = "floor_model_category",
    FloorModelUserOption = "floor_model_user_option",
    FloorReferenceArea = "floor_reference_area",
    FloorRoomWall = "floor_room_wall",
    FloorWallStyle = "floor_wall_style",
    Lease = "lease",
    LeaseAmendment = "lease_amendment",
    LeaseAmendmentDetail = "lease_amendment_detail",
    LeaseContractor = "lease_contractor",
    LeaseDurationType = "lease_duration_type",
    LeaseInternalType = "lease_internal_type",
    LeaseRent = "lease_rent",
    LeaseRentIndexation = "lease_rent_indexation",
    LeaseRentItem = "lease_rent_item",
    LeaseRevisionIndex = "lease_revision_index",
    LeaseStatus = "lease_status",
    PeopleLocation = "people_location",
    PeopleLocationType = "people_location_type",
    Perimeter = "perimeter",
    PerimeterDetail = "perimeter_detail",
    PerimeterType = "perimeter_type",
    ProviderCompany = "provider_company",
    Room = "room",
    RoomActivityStatusType = "room_activity_status_type",
    RoomAllocation = "room_allocation",
    RoomAttributionType = "room_attribution_type",
    RoomLayoutType = "room_layout_type",
    RoomSharing = "room_sharing",
    Simulation = "simulation",
    SimulationArea = "simulation_area",
    SimulationBusinessUnit = "simulation_business_unit",
    SimulationFloor = "simulation_floor",
    SimulationFloorAnatomy = "simulation_floor_anatomy",
    SimulationOccupation = "simulation_occupation",
    SimulationPhoto = "simulation_photo",
    SimulationSandbox = "simulation_sandbox",
    SimulationWorkforce = "simulation_workforce",
    Site = "site",
    Task = "task",
    TaskBusinessType = "task_business_type",
    TaskChangeLog = "task_change_log",
    TaskContributor = "task_contributor",
    TaskContributorDirectory = "task_contributor_directory",
    TaskFacility = "task_facility",
    TaskFloor = "task_floor",
    TaskFloorModel = "task_floor_model",
    TaskFunction = "task_function",
    TaskLink = "task_link",
    TaskLinkType = "task_link_type",
    TaskStatus = "task_status",
    TaskType = "task_type",
    TaskValidation = "task_validation",
    UnitOfWork = "unit_of_work",
        Workplace = "workplace",
    WorkplaceOccupancy = "workplace_occupancy",
    WorkplaceSensor = "workplace_sensor",
    WorkplaceType = "workplace_type",
    ZAppMenu = "z_app_menu",
    ZAppMenuRoute = "z_app_menu_route",
    ZAppParameter = "z_app_parameter",
    ZDbColumn = "z_db_column",
    ZDbView = "z_db_view",
    ZDbViewColumn = "z_db_view_column",
    ZDbTable = "z_db_table",
    ZColumnConstraint = "z_column_constraint",
    ZConstraintMode = "z_constraint_mode",
    ZConstraintType = "z_constraint_type",
    ZContextUITable = "z_context_ui_table",
    ZContextUITableUser = "z_context_ui_table_user",
    ZHierarchyDepthName = "z_hierarchy_depth_name",
    ZLanguageItem = "z_language_item",
    ZScope = "z_scope",
    ZSortDirection = "z_sort_direction"
}