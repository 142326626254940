import { EquipmentPurchaseDetailTable } from "../../db-model/tables/equipment-purchase-detail-table";
import { DefinedModelBase } from "../defined-model-base";

export class EquipmentPurchaseDetail extends DefinedModelBase {
    eqPuDeId!: number;
    eqPuDePurchaseId!: number;
    eqPuDeEquipmentCategoryId!: number;
    eqPuDeEquipmentCatalogId!: number;
    eqPuDeQuantity!: number;
    eqPuDeUnitPrice!: number;

    constructor(dtoData: any, tableDef: any[] = []) {
        super(dtoData, [EquipmentPurchaseDetailTable].concat(tableDef));
    }
}