import { FloorWallStyleTable } from './../../model/db-model/tables/floor-wall-style-table';
import { Service } from "typedi";
import { ApiService } from "../api-service";
import { UsageContextIdEnum } from '../../model/usage-context-id-enum';
import { FloorWallStyle } from 'src/app/core/model/data-model/tables/floor-wall-style';

@Service({ global: true })  
export class WallService extends ApiService {
    constructor() {
        super();
    }

    async downloadWallStyles(): Promise<FloorWallStyle[]> {
        const result = await this.getAsync<FloorWallStyle[]>(this.dynview(FloorWallStyleTable.databaseTableName), UsageContextIdEnum.ReferentialAdministration);
        if (result != null) {
            return result.payload.map(item => {
                return new FloorWallStyle(item);
            })
        }
        return [];
    }
    
}