import { SimulationFloorTable } from "../../db-model/tables/simulation-floor-table";
import { DefinedModelBase } from "../defined-model-base";

export class SimulationFloor extends DefinedModelBase {
    siFlId!: number;
    siFlRootSimulation!: number;
    siFlFloor!: number;
    siFlArea!: number;

    constructor(dtoData: any, tableDef: any[] = []) {
        super(dtoData, [SimulationFloorTable].concat(tableDef));
    }
}