export enum EquipmentPlanningStateEnum {
    StandingBy = 1,
    Installed,
    Unchanged,
    ToStore,
    ToMove,
    ToRemove,
    FromStore,
    FromMove,
    FromPurchase,
    Updated,
}