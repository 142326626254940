export const ZDbColumnTable = {
    databaseTableName: "z_db_column",
    dbCoTableName: "DbCo_TableName", 
    dbCoColumnName: "DbCo_ColumnName",
    dbCoColumnDisplayNameId: "DbCo_ColumnDisplayNameId",
    dbCoIsPrimaryKey: "DbCo_IsPrimaryKey", 
    dbCoIsIdentity: "DbCo_IsIdentity", 
    dbCoIsBrowsable: "DbCo_IsBrowsable", 
    dbCoIsReadOnly: "DbCo_IsReadOnly", 
    dbCoScopeId: "DbCo_ScopeId", 
    dbCoCategoryId: "DbCo_CategoryId",
    dbCoDisplayOrder: "DbCo_DisplayOrder",
    dbCoIsDefault: "DbCo_IsDefault", 
    dbCoType: "DbCo_Type", 
    dbCoDisplayStringFormat: "DbCo_DisplayStringFormat", 
    dbCoReadGrantId: "DbCo_ReadGrantId", 
    dbCoUpdateGrantId: "DbCo_UpdateGrantId"
}