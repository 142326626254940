import { Point } from "src/app/core/model/geometry-model/point.model";
import { InsertGizmo } from "./insert-gizmo";
import { SvgEntityTypesEnum } from "src/app/core/model/svg-model/svg-entity-type-enum";
import { SvgTransform } from "src/app/core/model/svg-model/svg-transform.model";
import { Segment } from "src/app/core/model/geometry-model/segment.model";
import { XcMaths } from "src/app/core/model/static-functions/xc-maths";
import { logError } from "src/app/core/services/logging-service";

export class EllipseInsertGizmo extends InsertGizmo {
    centerPoint!: Point;
    rx: number = 0.05;
    ry: number = 0.03;
    strokeWidth: number = 0.001;
    transform: SvgTransform = new SvgTransform({});
    centerPointIsDefined: boolean = false;
    rxIsDefined: boolean = false;

    constructor() {
        super(SvgEntityTypesEnum.ellipse);
        this.initialize();
    }

    protected override initialize(): void {
        this.centerPoint = Point.origin();
        this.rx = 0.05;
        this.transform = new SvgTransform({});
        this.centerPointIsDefined = false;
        this.rxIsDefined = false;
        this.completed = false;
    }

    override update(point: Point): void {
        if (!this.centerPointIsDefined) {
            this.centerPoint = point;
        } else {
            if (!this.rxIsDefined) {
                this.rx = point.distanceTo(this.centerPoint);
                const s = new Segment(this.centerPoint, point);
                const a = XcMaths.toDeg(s.angle());
                this.transform = new SvgTransform({ rotationCenter: this.centerPoint, rotationAngle: -a });
            } else {
                const a = XcMaths.toRad(this.transform ? this.transform.rotationAngle : 0);
                const tmp = new Point(this.centerPoint.x + this.rx, this.centerPoint.y);
                const p1 = tmp.rotate(this.centerPoint, a);
                const p2 = tmp.rotate(this.centerPoint, a + Math.PI);
                const majorAxis = new Segment(p1, p2);
                // On force un rayon minimum pour que l'ellipse soit toujours visible
                this.ry = Math.max(point.orthogonalDistanceTo(majorAxis), 0.01);
            }
        }
    }

    override define(point: Point | undefined): void {
        if (point) this.update(point);
        if (!this.centerPointIsDefined) {
            this.centerPointIsDefined = true;
        } else {
            if (!this.rxIsDefined) {
                this.rxIsDefined = true;
            } else {
                this.completed = true;
                if (this.insertionCompleted) {
                    this.insertionCompleted();
                } else {
                    logError("EllipseInsertGizmo.insertionCompleted n'est pas écouté");
                }
            }
        }
    }
}
