@if (model) {
<div>
    <input 
        type="date"
        [ngModel]="model.value | date:'yyyy-MM-dd'"
        (ngModelChange)="model.value = $event"
        [disabled]="model.definition.dbCoIsReadOnly"
        (focusout)="model.focusOut()" (keydown)="model.keyDown($event)">
</div>
}

