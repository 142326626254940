import { SvgEntityTypesEnum } from "src/app/core/model/svg-model/svg-entity-type-enum";
import { Grips } from "src/app/components-lib/svg/grips/grips";
import { Point } from "src/app/core/model/geometry-model/point.model";

export abstract class InsertGizmo {
    entityType: SvgEntityTypesEnum;
    targetGrips: Grips = new Grips("purple", "purple");
    completed: boolean = false;
    inserting: boolean = false;
    insertionCompleted?: () => void;

    constructor(entityType: SvgEntityTypesEnum) {
        this.entityType = entityType;
    }

    /**
     * Doit être overridé dans les classes héritées
     */
    protected initialize(): void {
    }


    /**
     * Doit être overridé dans les classes héritées
     */
    define(point: Point | undefined): void { }
    
    
    /**
     * Doit être overridé dans les classes héritées
     */
    update(point: Point): void { }

    reset(): void {
        this.initialize();
    }
}