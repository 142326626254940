import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { WorkplaceUpdateGizmo } from '../model/workplace-update-gizmo.model';
import { PeopleLocationDisplay } from '../../../../../dialog/workplace-allocation-editor/model/people-location-display';
import { WorkplaceAllocationsEditorComponent } from '../../../../../dialog/workplace-allocation-editor/view/workplace-allocations-editor/workplace-allocations-editor.component';
import { BpSvgWorkplaceLabel } from '../../../../../svg-entities/model/bp-svg-workplace-label';
import { take } from 'rxjs';

@Component({
  selector: 'g[xc-workplace-update-gizmo]',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './workplace-update-gizmo.component.svg',
  styleUrls: ['./workplace-update-gizmo.component.scss']
})
export class WorkplaceUpdateGizmoComponent {
  @Input() model: WorkplaceUpdateGizmo | undefined;

  constructor(public dialog: MatDialog) { }

  ngOnInit(): void {
  }

  async allocateButtonMouseDown(e: MouseEvent, label: BpSvgWorkplaceLabel): Promise<void> {
    e.stopPropagation();
    if (label != null) {
      let dialogRef = this.dialog.open<WorkplaceAllocationsEditorComponent, any, PeopleLocationDisplay[]>(WorkplaceAllocationsEditorComponent, { data: { workplaceId: label.floorDataId, currentAllocations: label.workplaceAllocations, useRatio: true, title: "Allocations de la position de travail" }, width: "800px" });
            // Le .pipe(take(1)) s'assure que le traitement ne sera exécuté qu'une seule fois
            dialogRef.afterClosed().pipe(take(1)).subscribe(async result => {
        if (result != null) {
            // Si le résultat n'est pas null c'est que les allocations ont été modifiées et que le user a cliqué sur Ok
            await this.model?.onAllocationsChange(result);
        }
      });
    }
  }
}
