import { SvgEntityPoint } from "../../../shared/gizmos/model/svg-entity-point";
import { SvgEntityPointStyleEnum } from "../../../shared/gizmos/model/svg-entity-point-style-enum";
import { Segment } from "src/app/core/model/geometry-model/segment.model";
import { Point } from "src/app/core/model/geometry-model/point.model";
import { BpSvgBoundingBox } from "src/app/ui/pages/layout/real-estate/floor-blueprint/content/bp-svg-core-model/bp-svg-bounding-box";

export class CanvasGizmo {
    x: number;
    y: number;
    width: number;
    height: number;
    widthcms: number[] = [];
    heightcms: number[] = [];
    boxStrokeWidth: number = 0.5;
    selectablesPoints: SvgEntityPoint[] = [];

    constructor(bbox: BpSvgBoundingBox) {
        this.x = bbox.minX;
        this.y = bbox.minY;
        this.width = bbox.width();
        this.height = bbox.height();
        this.widthcms = this.getCms(this.width);
        this.heightcms = this.getCms(this.height);
        this.getSelectablePoints();
    }

    update(bbox: DOMRect): void {
        this.x = bbox.x;
        this.y = bbox.y;
        this.width = bbox.width;
        this.height = bbox.height;
        this.widthcms = this.getCms(this.width);
        this.heightcms = this.getCms(this.height);
        this.getSelectablePoints();
    }

    getCms(value: number): number[] {
        return [...Array(Math.ceil(value * 100)).keys()];
    }

    getSelectablePoints(): void {
        this.selectablesPoints.splice(0);
        this.selectablesPoints.push(new SvgEntityPoint(SvgEntityPointStyleEnum.end, new Point(this.x, this.y)));
        this.selectablesPoints.push(new SvgEntityPoint(SvgEntityPointStyleEnum.end, new Point(this.x + this.width, this.y)));
        this.selectablesPoints.push(new SvgEntityPoint(SvgEntityPointStyleEnum.end, new Point(this.x + this.width, this.y + this.height)));
        this.selectablesPoints.push(new SvgEntityPoint(SvgEntityPointStyleEnum.end, new Point(this.x, this.y + this.height)));
        const diag = new Segment(new Point(this.x, this.y), new Point(this.x + this.width, this.y + this.height));
        const c = diag.midPoint();
        this.selectablesPoints.push(new SvgEntityPoint(SvgEntityPointStyleEnum.center, new Point(c.x, c.y)));
    }
}