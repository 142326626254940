import { BuildingTable } from 'src/app/core/model/db-model/tables/building-table';
import { BusinessUnitTable } from 'src/app/core/model/db-model/tables/business-unit-table';
import { DateUtils } from './../../model/static-functions/date-utils';
import { BuildingsAndBusBuildingsDTO } from './dto/buildings-and-bus-buildings-dto';
import { DtoNamesEnum } from './dto/definitions/dto-names-enum';
import { BusAndBuildingsBusDTO } from './dto/bus-and-buildings-bus-dto';
import { Service } from "typedi";
import { ApiControllers } from "../api-endpoints";
import { ApiService } from "../api-service";
import { HttpParams } from '@angular/common/http';
import { SimulationTable } from '../../model/db-model/tables/simulation-table';
import { SimulationTimelinesDTO } from './dto/simulation-timelines-dto';
import { SimulationPhotoTable } from '../../model/db-model/tables/simulation-photo-table';
import { SimulationPhoto } from '../../model/data-model/tables/simulation-photo';
import { SimulationPhotoDTO } from './dto/simulation-photo-dto';

@Service({ global: true })  
export class SimulationService extends ApiService {
    private buildingsEndpoint: string = `${ApiControllers.simulations}/buildings`;
    private busEndpoint: string = `${ApiControllers.simulations}/bus`;
    private createSimulationEndpoint: string = `${ApiControllers.simulations}/create`;
    private createVariantEndpoint: string = `${ApiControllers.simulations}/createvariant`;
    private timelinesEndpoint(rootId: number): string { return `${ApiControllers.simulations}/timelines/${rootId}` };
    private createPhotoEndpoint: string = `${ApiControllers.simulationPhotos}/create`;
    private deletePhotoEndpoint(photoId: number): string { return `${ApiControllers.simulationPhotos}/${photoId}` };
    private photoOccupationEndpoint(photoId: number): string { return `${ApiControllers.simulationPhotos}/item/${photoId}` };

    constructor() {
        super();
    }

    async getTimelines(rootId: number): Promise<SimulationTimelinesDTO | null> {
        return await this.getSingleAsync<SimulationTimelinesDTO>(DtoNamesEnum.SimulationTimelinesDTO, this.endPoint(this.timelinesEndpoint(rootId)));
    }

    async getBusAndBuildingsBus(buildingsIds: number[]): Promise<BusAndBuildingsBusDTO | null> {
        let params = new HttpParams();
        buildingsIds.forEach(id => {
            params = params.append('buildingsIds', id);
        });
        return await this.getSingleAsync<BusAndBuildingsBusDTO>(DtoNamesEnum.BusAndBuildingsBusDTO, this.endPoint(this.busEndpoint), undefined, params);
    }

    async getBuildingsAndBusBuildings(busIds: number[]): Promise<BuildingsAndBusBuildingsDTO | null> {
        let params = new HttpParams();
        busIds.forEach(id => {
            params = params.append('busIds', id);
        });
        return await this.getSingleAsync<BuildingsAndBusBuildingsDTO>(DtoNamesEnum.BuildingsAndBusBuildingsDTO, this.endPoint(this.buildingsEndpoint), undefined, params);
    }

    async getPhotoOccupation(photoId: number): Promise<SimulationPhotoDTO | null> {
        return await this.getSingleAsync<SimulationPhotoDTO>(DtoNamesEnum.SimulationPhotoDTO, this.endPoint(this.photoOccupationEndpoint(photoId)));
    }

    async createSimulation(name: string, endDate: Date, taskId: number | null, buildingsIds: number[], busIds: number[]): Promise<number | null> {
        const dto: any = {};
        dto[SimulationTable.siName] = name;
        dto[SimulationTable.siDescription] = name;
        dto[SimulationTable.siStartDate] = DateUtils.toBackendString(new Date(Date.now()));
        dto[SimulationTable.siEndDate] = DateUtils.toBackendString(endDate);
        dto[SimulationTable.siProjectTaskId] = taskId;
        dto[BuildingTable.databaseTableName] = buildingsIds;
        dto[BusinessUnitTable.databaseTableName] = busIds;

        const result = await this.postAsync<any>(this.endPoint(this.createSimulationEndpoint), dto);
        if (result) {
            return result.payload;
        }
        return null;
    }

    async createPhoto(simulationId: number, name: string, date: Date, currentPhotoId: number): Promise<SimulationPhoto | null> {
        const dto: any = {};
        dto[SimulationPhotoTable.siPhSimulation] = simulationId;
        dto[SimulationPhotoTable.siPhName] = name;
        dto[SimulationPhotoTable.siPhDescription] = name;
        dto[SimulationPhotoTable.siPhPhotoDate] = DateUtils.toBackendString(date);
        dto["CurrentPhotoId"] = currentPhotoId;

        const result = await this.postAsync<any>(this.endPoint(this.createPhotoEndpoint), dto);
        if (result) {
            return new SimulationPhoto(result.payload);
        }
        return null;
    }

    async createVariant(photoId: number): Promise<SimulationTimelinesDTO | null> {
        const dto: any = {};
        dto[SimulationPhotoTable.siPhId] = photoId;
        dto[SimulationTable.siName] = "Nouvelle variante";

        const result = await this.postAsync<any>(this.endPoint(this.createVariantEndpoint), dto);
        if (result) {
            return new SimulationTimelinesDTO(result.payload);
        }
        return null;
    }
    
    async deletePhoto(photoId: number): Promise<number | null> {
        const result = await this.deleteAsync<any>(this.endPoint(this.deletePhotoEndpoint(photoId)));
        if (result) {
            return result.payload;
        }
        return null;
    }
}