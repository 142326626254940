import { SvgEntity } from 'src/app/core/model/svg-model/svg-entity.model';
import { BpSvgDef } from './bp-svg-def';
import { Vector } from 'src/app/core/model/geometry-model/vector.model';
import { Point } from 'src/app/core/model/geometry-model/point.model';
import { SvgTransform } from 'src/app/core/model/svg-model/svg-transform.model';
import { XcMaths } from 'src/app/core/model/static-functions/xc-maths';
import { EquipmentPlanning } from 'src/app/core/model/data-model/tables/equipment-planning';
import { EquipmentItem } from 'src/app/core/model/data-model/tables/equipment-item';
import { BpSvgEntity } from './bp-svg-entity';
import { BpSvgBoundingBox } from './bp-svg-bounding-box';
import { Polygon } from 'src/app/core/model/geometry-model/polygon.model';
import { IntersectedSegment } from 'src/app/core/model/geometry-model/intersected-segment';
import { GeometricElementType } from 'src/app/core/model/geometry-model/geometric-element-type.model';
import { Segment } from 'src/app/core/model/geometry-model/segment.model';
import { EquipmentPlanningStateView } from 'src/app/core/model/data-model/views/equipment-planning-state-view';
import { FloorDataStateView } from 'src/app/core/model/data-model/views/floor-data-state-view';

export class BpSvgUse extends BpSvgEntity {
    href: string | undefined;
    floorCatalogId: number;
    floorCatalogName: string | undefined;
    floorModelName: string | undefined;
    def: BpSvgDef | undefined;
    fillColor: string | undefined;
    inventoryStateId!: number | null;
    equipmentPlanning: EquipmentPlanning | undefined;
    equipmentItem: EquipmentItem | undefined;
    equipmentPlanningState: EquipmentPlanningStateView | undefined;
    floorDataState: FloorDataStateView | undefined;

    constructor(dtoData: any) {
        super(dtoData);
        this.href = SvgEntity.getValue(dtoData, "href");
        this.floorCatalogId = SvgEntity.getNumber(dtoData, "floorCatalogId", 0, false);
    }

    setThemeColor(color: string): void {
        this.fillColor = color;
        this.fillOpacity = 0.5;
    }

    resetThemeColor(): void {
        this.fillColor = undefined;
        this.fillOpacity = undefined;
    }

    // Renommer getTransformedBbosCenter
    // Pour accélerer les traitements on pourrait peut être stocker le transformed bbox center
    // plutôt que le recalculer à chaque fois
    // par contre cela impliquerait de le maintenir au travers des updates
    getBboxCenter(): Point | null {
        const defBboxCenter = this.def?.bBox?.center();
        if (defBboxCenter && this.transform) {
            return this.transform.transformedPoint(defBboxCenter);
        }
        return null;
    }

    transformedBbox(): BpSvgBoundingBox | null {
        const defBbox = this.def?.bBox;
        if (defBbox && this.transform) {
            return defBbox.transformNew(this.transform);
        }
        return null;
    }

    translate(delta: Vector): void
    {
        this.transform = this.initialTransform?.addTranslation(delta);
    }

    rotate(rotationCenter: Point, deltaAngleDeg: number): void {
        if (!this.initialTransform) return;

        const rotatedInsertionPoint = this.initialTransform.translate.rotate(rotationCenter, XcMaths.toRad(deltaAngleDeg));
        const totalAngle = Math.round((this.initialTransform.rotationAngle + deltaAngleDeg) % 360);

        this.transform = new SvgTransform({ rotationCenter: this.initialTransform.rotationCenter, rotationAngle: totalAngle, translate: rotatedInsertionPoint});
    }

    // TODO : mutualiser le code avec la méthode précédente
    rotateTranslate(rotationCenter: Point, deltaAngleDeg: number, deltaTranslate: Vector): void {
        if (this.initialTransform) {
            const rotatedInsertionPoint = this.initialTransform.translate.rotate(rotationCenter, XcMaths.toRad(deltaAngleDeg));
            const totalAngle = Math.round((this.initialTransform.rotationAngle + deltaAngleDeg) % 360);
            const tmp = new SvgTransform({ rotationCenter: this.initialTransform.rotationCenter, rotationAngle: totalAngle, translate: rotatedInsertionPoint});
            this.transform = tmp.addTranslation(deltaTranslate);
        }
    }

    // getCopy(id: number): SvgUse {
    //     const copy = super.getCopy(id) as SvgUse;
    //     copy.def = this.def;
    //     return copy;
    // }

    /**
     * 
     * @param polygon Polygone de détection d'intersection. L'usage courant est un réticule de visée carré
     * @returns Retourne un tableau de segments
     */
    contourIntersects(polygon: Polygon): IntersectedSegment[] {
        let result: IntersectedSegment[] = [];

        if (this.def && this.transform) {
            const g = this.def.contour.geometry;
            const ss = g.filter(x=> x.elementType === GeometricElementType.Segment) as Segment[];
            for (const s of ss) {
                const ts = this.transform.transformedSegment(s);
                const intersects = polygon.getBpIntersects([ts]);
                if (intersects.length > 0) {
                    intersects.forEach(i => {
                        i.floorDataId = this.floorDataId;
                    });
                    result = result.concat(intersects);
                }
            }
        }

        return result;
    }
}

