import { TablesNamesEnum } from './../tables-names-enum';

export const BusinessUnitTable = {
    databaseTableName: TablesNamesEnum.BusinessUnit,
    buUnId: 'BuUn_Id',
    buUnCode: 'BuUn_Code',
    buUnName: 'BuUn_Name',
    buUnDepth: 'BuUn_Depth',
    buUnParentId: 'BuUn_ParentId',
    buUnColor: 'BuUn_Color',
    buUnIsActive: 'BuUn_IsActive',
    buUnIsFictive: 'BuUn_IsFictive',
    buUnVacancyRatio: 'BuUn_VacancyRatio',
    buUnFlexibilityRatio: 'BuUn_FlexibilityRatio',
    buUnOccupancyRatio: 'BuUn_OccupancyRatio',
    buUnAnatomyId: 'BuUn_AnatomyId',
    buUnReadGrantId: 'BuUn_ReadGrantId'
}