import { Command } from "./command";

export class CommandGroup<T extends number> {
    items: Command<T>[] = [];

    addCommand(id: T, label: string, icon: string, enabled: boolean = true): Command<T> {
        const cmd = new Command<T>(id, label, icon, enabled);
        this.items.push(cmd);
        return cmd;
    }
}