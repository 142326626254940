import { TablesNamesEnum } from './../tables-names-enum';
export const WorkplaceTypeTable = {
    databaseTableName: TablesNamesEnum.WorkplaceType,
    woTyId: 'WoTy_Id',
    woTyName: 'WoTy_Name',
    woTyColor: 'WoTy_Color',
    woTyScope: 'WoTy_Scope',
    woTyIsActive: 'WoTy_IsActive',
    woTyIsAllocatable: 'WoTy_IsAllocatable',
    woTyDisplayNameId: 'WoTy_DisplayNameId'
}