import { RoomAttributionTypeDbView } from './../../db-model/views/room-attribution-type-db-view';
import { RoomActivityStatusTypeDbView } from './../../db-model/views/room-activity-status-type-db-view';
import { RoomAttributionTypeView } from './../views/room-attribution-type-view';
import { RoomActivityStatusTypeView } from './../views/room-activity-status-type-view';
import { RoomLayoutTypeTable } from './../../db-model/tables/room-layout-type-table';
import { Perimeter } from './../tables/perimeter';
import { RoomLayoutType } from './../tables/room-layout-type';
import { PerimeterTable } from '../../db-model/tables/perimeter-table';

export class RoomReferenceDataSet {
    layoutTypes: RoomLayoutType[] = [];
    activityStatusTypes: RoomActivityStatusTypeView[] = [];
    attributionTypes: RoomAttributionTypeView[] = [];
    perimeters: Perimeter[] = [];
    UserCanCreateDirectTask: boolean = false;

    constructor(dtoData: any) {
        if (dtoData) {
            dtoData[RoomLayoutTypeTable.databaseTableName].forEach((e: any) => {
                this.layoutTypes.push(new RoomLayoutType(e));
            });
            dtoData[RoomActivityStatusTypeDbView.databaseTableName].forEach((e: any) => {
                this.activityStatusTypes.push(new RoomActivityStatusTypeView(e));
            });
            dtoData[RoomAttributionTypeDbView.databaseTableName].forEach((e: any) => {
                this.attributionTypes.push(new RoomAttributionTypeView(e));
            });
            dtoData[PerimeterTable.databaseTableName].forEach((e: any) => {
                this.perimeters.push(new Perimeter(e));
            });
            this.UserCanCreateDirectTask = dtoData["UserCanCreateDirectTask"];
        }
    }
}