import { Point } from "src/app/core/model/geometry-model/point.model";
import { MapTile } from "./map-tile";
import { DxfSvgEntity } from "src/app/ui/pages/graphic-works/blueprint/model/svg/dxf-svg-entity";
import { Rectangle } from "src/app/core/model/geometry-model/rectangle.model";
import { DxfSvgDOM } from "../dxf-svg-dom";

export class MapTiling {
    readonly minPoint: Point;
    readonly mapWidth: number;
    readonly mapHeight: number;
    // TODO : implémenter l'utilisation de la tile size qui pour le moment est égale à 1
    readonly tileSize: number;
    readonly tiles: MapTile[] = [];

    constructor(minPoint: Point, mapWidth: number, mapHeight: number, tileSize: number) {
        this.minPoint = minPoint;
        this.mapWidth = mapWidth;
        this.mapHeight = mapHeight;
        this.tileSize = tileSize;

        let index = 0;
        for (let h = 0; h < this.mapHeight; h++) {
            for (let w = 0; w < this.mapWidth; w++) {
                this.tiles.push(new MapTile(h, w, tileSize, index));  
                index++;              
            }
        }
    }

    /**
     * Constitue le tableau géospatial des éléments passés
     * NOTA : un même élément est classé dans autant d'espaces du tableau géospatial qu'il qu'il traverse
     * @param items Tableau des éléments à répartir dans le tableau géospatial
     */
    feed(items: DxfSvgEntity[]): void {
        // Le traitement consiste à sélectionner toutes les tuiles comprise dans la bbox de l'entité
        // puis à ne conserver que celles qui sont traversées par l'entité
        items.forEach(i => {
            i.tilesIndexes.splice(0);
            const points = this.getDxfEntityPoints(i, this.minPoint);
            points.forEach(p => {
                const tile = this.getTile(p);
                if (tile !== null) {
                    tile.addEntity(i);
                } else {
                    // Se produit lorsque le point d'insertion d'un bloc se trouve à l'extérieur de l'étendue du dessin
                    // ou après un déplacement lorsque l'entité ou un de ses points se retrouvent à l'extérieur de l'étendue du dessin
                    // Dans ce dernier cas il faut agrandir la viewbox et recalculer le tiling
                    console.log("Erreur de geo tiling pour l'élément", i);
                }
            });
        });

        items.forEach(i => {
            const bbox = DxfSvgDOM.getEntityBbox(i.entityId);
            if (bbox) {
                const topLeft = new Point(bbox.x, bbox.y);
                const bottomRight = new Point(bbox.x + bbox.width, bbox.y + bbox.height);
                const bboxTiles = this.getTiles(topLeft, bottomRight);
                bboxTiles.forEach(t => {
                });
            }
        });
    }

    getTile(p: Point): MapTile | null {
        if (p.x <= this.mapWidth - 1 && p.y <= this.mapHeight - 1) {
            const index = (p.y * this.mapWidth) + p.x;
            if (index >= 0 && index <= this.tiles.length) {
                // L'index peut être hors limites lorsque le rectangle de sélection sort des limites du dessin
                return this.tiles[index];
            }
        }
        return null;
    }

    /**
     * 
     * @param p1 minPoint du rectangle
     * @param p2 maxPoint du rectangle
     * @returns Retourne les espaces compris dans le rectangle délimité par les deux points passés
     */
    getTiles(p1: Point, p2: Point): MapTile[] {
        const result: MapTile[] = [];

        const pp1 = this.getRelativeFlooredPoint(p1, this.minPoint);
        const pp2 = this.getRelativeFlooredPoint(p2, this.minPoint);

        const rw = pp2.x - pp1.x + 1;
        const rh = pp2.y - pp1.y + 1;
        for (let h = 0; h < rh; h++) {
            for (let w = 0; w < rw; w++) {
                const s = this.getTile(new Point(pp1.x + w, pp1.y + h));
                if (s !== null) {
                    result.push(s);
                }
            }
        }

        return result;
    }

    update(entities: DxfSvgEntity[]): void {
        //const ids = entities.map((x: any)=> x.entityId as number)
        // Retire les entités des tuiles où elles sont actuellement référencées
        this.remove(entities);
        // this.tiles.forEach(t => {
        //     t.items = t.items.filter((x: any)=> !ids.includes(x.entityId));
        // });
        // Replace les entités en fonction de leurs coordonnées actuelles
        this.feed(entities);
    }

    remove(entities: DxfSvgEntity[]): void {
        const tilesIds = entities.flatMap(x=> x.tilesIndexes);
        tilesIds.forEach(tid => {
            const tileEntities = entities.filter(x=> x.tilesIndexes.includes(tid));
            const tile = this.tiles.find(x=> x.index === tid);
            if (tile) {
                tile.remove(tileEntities);
            }
        });
    }

    getDxfEntityPoints(entity: DxfSvgEntity, extentsMinPoint: Point): Point[] {
        return this.getRelativeFlooredPoints(entity.selectablePoints, extentsMinPoint);
    }
    
    getRelativeFlooredPoint(p: Point, o: Point): Point {
        const temp = new Point(p.x - o.x, p.y - o.y);
        return new Point(Math.floor(temp.x), Math.floor(temp.y));
    }
    
    getRelativeFlooredPoints(points: Point[], o: Point): Point[] {
        const result: Point[] = [];
        points.forEach(p => {
            result.push(this.getRelativeFlooredPoint(p, o));
        });
        return result;
    }
}