import { SvgTransform } from "src/app/core/model/svg-model/svg-transform.model";
import { DxfLayerDTO } from "src/app/core/services/backend-services/dto/dxf-layer-dto";
import { SvgEntityTypesEnum } from "src/app/core/model/svg-model/svg-entity-type-enum";
import { MapTiling } from "src/app/ui/pages/graphic-works/blueprint/model/map-tiling/map-tiling";
import { LayersController } from "./layers-controller";
import Container from "typedi";
import { DxfService } from "src/app/core/services/backend-services/dxf-service";
import { HtmlConstants } from "src/app/core/model/html-model/html-constants.model";
import { DyntService } from "src/app/core/services/backend-services/dynt-service";
import { DxfImportTable } from "src/app/core/model/db-model/tables/dxf-import-table";
import { DxfEntityTable } from "src/app/core/model/db-model/tables/dxf-entity-table";
import { DxfSvgDef } from "./svg/dxf-svg-def";
import { DxfSvgEntity } from "./svg/dxf-svg-entity";
import { BpSvgBoundingBox } from "../../../layout/real-estate/floor-blueprint/content/bp-svg-core-model/bp-svg-bounding-box";

export class Drawing {
    importId!: number;
    viewbox!: string;
    extents!: string;
    transform: SvgTransform = new SvgTransform({ });
    static zoomScale: number = 1;
    layersController: LayersController;
    defs: DxfSvgDef[] = [];
    geoTiling: MapTiling | undefined;
    justInsertedEntities: DxfSvgEntity[] = [];
    
    constructor() {
        this.viewbox = "0 0 10 10";
        this.layersController = new LayersController();
    }

    set(importId: number, extents: string, layers: DxfLayerDTO[]): void {
        this.importId = importId;
        this.extents = extents;
        const bbox = BpSvgBoundingBox.getFromString(extents);
        if (bbox) {
            this.viewbox = `${bbox?.minX} ${bbox?.minY} ${bbox?.width()} ${bbox?.height()}`;
            // TODO : essayer une tile size = 10
            //this.geoTiling = new MapTiling(bbox.topLeft(), Math.ceil(bbox.width()), Math.ceil(bbox.height()), 1);

            const l0 = layers.find(x=> x.dxLaName === "0");
            if (l0) {
                this.defs = l0.entities.filter(x=> x.entityType === SvgEntityTypesEnum.g) as DxfSvgDef[];
            }

            this.layersController.layers = layers;
            //this.geoTiling.feed(this.layersController.getAllEntities());
        } else {
            console.log("Impossible de définir l'étendue du dessin");
        }
    }
    
    /**
     * Actualise l'épaisseur des traits en fonction du facteur de zoom. Méthode branchée sur le panzoomController.zoomChanged event
     * @param zoomScale 
     * @returns 
     */
    updateStrokeWidth(zoomScale: number): void {
        Drawing.zoomScale = zoomScale;
        //this.layersController.updateStrokeWidth();
        //this.layersController.updateFontSize();
    }

    /**
     * Actualise le tiling après modification d'une ou plusieurs entités
     * On doit éventuellement préalablement actualiser la viewbox si les entités ont été déplacées au delà de l'étendue du dessin
     * @param entities 
     */
    async updateGeoTiling(entities: DxfSvgEntity[]): Promise<void> {
        this.geoTiling?.update(entities);
    }

    removeFromGeoTiling(entities: DxfSvgEntity[]): void {
        this.geoTiling?.remove(entities);
    }

    async updateExtents(svgBbox: SVGRect): Promise<boolean> {
        let result = false;
        const currentBbox = BpSvgBoundingBox.getFromString(this.extents);
        const newBbox = new BpSvgBoundingBox(svgBbox.x, svgBbox.y, svgBbox.x + svgBbox.width, svgBbox.y + svgBbox.height).rounded();
        if (currentBbox && !newBbox.equals(currentBbox)) {
            this.geoTiling = new MapTiling(newBbox.topLeft(), Math.ceil(newBbox.width()), Math.ceil(newBbox.height()), 1);
            this.geoTiling.feed(this.layersController.getAllEntities());

            const newExtents = `${newBbox.minX} ${newBbox.minY};${newBbox.maxX} ${newBbox.maxY}`;
            
            // Enregistre la nouvelle étendue du dessin
            const s = Container.get(DyntService);
            await s.patch(DxfImportTable.databaseTableName, this.importId, { TableName: DxfImportTable.databaseTableName, [DxfImportTable.dxImId]: this.importId, [DxfImportTable.dxImExtents]: newExtents });
            
            this.extents = newExtents;
            result = true;
        }

        this.justInsertedEntities.splice(0);
        return result;
    }

    async insert(layer: DxfLayerDTO, entity: DxfSvgEntity): Promise<void> {
        let status: boolean = false;

        const cursor = layer.dxLaLocked ? HtmlConstants.styleCursorInherit : HtmlConstants.styleCursorPointer;
        entity.locked = layer.dxLaLocked;
        entity.cursor = cursor;

        const s = Container.get(DxfService);
        const l = await s.insertEntity(entity.layerId, entity.statement, entity.entityType);
        if (l != null) {
            status = true;
            this.layersController.insert(layer, entity);
            entity.entityId = l.dxEnId;
        }

        // Si l'entité ajoutée se trouve même partiellement hors de l'extension du dessin, tous ses points ne sont pas
        // référencés dans le tuilage
        // En attendant la fin de la commande d'insertion, on stocke la nouvelle entité dans un tableau dédié
        this.justInsertedEntities.push(entity);

        if (status) {
            this.updateGeoTiling([entity]);
        }
    }

    async delete(entities: DxfSvgEntity[]): Promise<boolean> {
        const ids = entities.map(x=> x.entityId);
        const s = Container.get(DyntService);
        const result = await s.deleteMultiple(DxfEntityTable.databaseTableName, ids);
        if (result != null) {
            this.removeFromGeoTiling(entities);
            this.layersController.delete(entities);
            return true;
        }
        return false;
    }
}