// TODO : Probablement à renommer de façon plus générique et à déplacer vers une couche lib

export enum RealEstateChartTypeEnum {
    none = "none",
    businessunits = "businessunits",
    buildings = "buildings",
    inventory = "inventory",
    anatomy = "anatomy",
    leases = "leases",
    attributions = "attributions",
    activities = "activities",
    occupation = "occupation",
    workforce = "workforce",
    moveLists = "moveLists",
    history = "history",
    referential = "referential",
    netratios = "netRatios"
}