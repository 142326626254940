export class CreateDoorDTO {
    doorWidth: number;
    openingAngle: number;
    doorStyleId: number;
    openingDirection: number;
    wallSideOrientation: number;

    constructor(doorWidth: number, openingAngle: number, doorStyleId: number, openingDirection: number, wallSideOrientation: number) {
        this.doorWidth = doorWidth;
        this.openingAngle = openingAngle;
        this.doorStyleId = doorStyleId;
        this.openingDirection = openingDirection;
        this.wallSideOrientation = wallSideOrientation;
    }
}