import { Container } from 'typedi';
import { LoggerService } from 'src/app/core/services/logger.service';
import { FloorModelEnum } from "src/app/core/model/data-model/enums/floor-model-enum";
import { WorkplaceTypeEnum } from "src/app/core/model/data-model/enums/workplace-type-enum";
import { WorkplaceType } from "src/app/core/model/data-model/tables/workplace-type";
import { EntitiesFormModel } from '../../properties/model/entities-form-model';
import { WorkplaceService } from 'src/app/core/services/backend-services/workplace-service';
import { EventListener } from 'src/app/core/events/event-listener';
import { readableUUID } from 'src/app/core/events/event-listener-uuid';
import { FloorBlueprintEventsEnum } from '../../../../../container/model/floor-blueprint-events-enum';
import { FloorTask } from '../../../../shared-model/floor-task';

export class LabelingFormModel extends EventListener implements EntitiesFormModel {
    static labelTypeChange = "labelTypeChange";
    static workplaceInsertRequest = "workplaceInsertRequest";
    static workplaceRenumberRequest = "workplaceRenumberRequest";
    static roomInsertRequest = "roomInsertRequest";
    static measurementInsertRequest = "measurementInsertRequest";

    workplacesTypes: WorkplaceType[] = [];
    labelingType: number = 0;
    hasPlanningTask: boolean = false;
    canUpdatePartition: boolean = false;
    layerId: number = FloorModelEnum.WorkplaceLabels;
    isEditable: boolean = false;

    workplaceInsertCode: string = "001";
    workplaceInsertType: number = WorkplaceTypeEnum.Standard;
    workplaceInsertCycle: boolean = false;
    workplaceRenumberRefine: boolean = false;
    
    roomInsertCode: string = "001";

    FloorModelEnum = FloorModelEnum;

    constructor() { 
        super(readableUUID(LabelingFormModel.name));
        this.downloadWorkplacesTypes();

        this.addEventListener(FloorBlueprintEventsEnum.selectedTaskChanged, async (task: FloorTask | undefined) => {
            this.hasPlanningTask = task !== undefined;
        });
    }

    async downloadWorkplacesTypes(): Promise<void> {
        const s = Container.get(WorkplaceService);
        const tmp = await s.getTypes();
        this.workplacesTypes = tmp.filter(x=> x.woTyId !== WorkplaceTypeEnum.NR);
    }
    
    // La commande "Ajouter un local à l'étude" n'est disponible que si une étude est chargée

    // Si une étude est chargée, l'insertion de position de travail ne peut se faire que sur les locaux de l'étude
    // L'insertion de local n'est disponible que si une étude est chargée et ne peut se faire que sur la zone de l'étude
    // ce qui est vérifié en utilisant les contours des surfaces source

    reset(): void {
      this.labelingType = 0;
    }

    async onLabelTypeSelectionChange(e: number): Promise<void> {
        await this.emitEventAsync(LabelingFormModel.labelTypeChange, e);
        switch (this.labelingType) {
            case 1:
                this.layerId = FloorModelEnum.WorkplaceLabels;
                break;
            case 2:
                this.layerId = FloorModelEnum.RoomLabels;
                break;
            default:
                break;
        }
    }

    //workplaceInsertRequest?: (labelingForm: LabelingFormModel) => void;
    async onWorkplaceInsertButtonClick() {
        await this.emitEventAsync(LabelingFormModel.workplaceInsertRequest, this);
        // Déclenche l'event qui est écouté par le composant parent
        // if (this.workplaceInsertRequest) {
        //     this.workplaceInsertRequest(this);
        // } else {
        //     LoggerService.missingListener("LabelingFormModel.workplaceInsertRequest");
        // }
    }

    //workplaceRenumberRequest?: (labelingForm: LabelingFormModel) => void;
    async onWorkplaceRenumberButtonClick() {
        await this.emitEventAsync(LabelingFormModel.workplaceRenumberRequest, this);
        // Déclenche l'event qui est écouté par le composant parent
        // if (this.workplaceRenumberRequest) {
        //     this.workplaceRenumberRequest(this);
        // } else {
        //     LoggerService.missingListener("LabelingFormModel.workplaceRenumberRequest");
        // }
    }

    //roomInsertRequest?: (code: string) => void;
    async onRoomInsertButtonClick() {
        await this.emitEventAsync(LabelingFormModel.roomInsertRequest, this.roomInsertCode);
        // if (this.roomInsertRequest) {
        //     this.roomInsertRequest(this.roomInsertCode);
        // } else {
        //   LoggerService.missingListener("LabelingFormModel.roomInsertRequest");
        // }
    }

    //measurementInsertRequest?: () => void;
    async onMeasurementButtonClick() {
        await this.emitEventAsync(LabelingFormModel.measurementInsertRequest);
        // if (this.measurementInsertRequest) {
        //     this.measurementInsertRequest();
        // } else {
        //   LoggerService.missingListener("LabelingFormModel.measurementInsertRequest");
        // }
    }
  }
