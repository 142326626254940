import { FloorEquipmentProviderView } from './../../../model/data-model/views/floor-equipment-provider-view';
import { Floor } from "src/app/core/model/data-model/tables/floor";
import { FloorEquipmentProviderDbView } from 'src/app/core/model/db-model/views/floor-equipment-provider-db-view';

export class FloorEquipmentProviderDTO extends Floor {
    equipmentProviders: FloorEquipmentProviderView[] = [];

    constructor(dtoData: any) {
        super(dtoData);
        if (dtoData[FloorEquipmentProviderDbView.databaseTableName]) {
            dtoData[FloorEquipmentProviderDbView.databaseTableName].forEach((e: any) => {
                this.equipmentProviders.push(new FloorEquipmentProviderView(e));
            });
        }
    }
}