import {DonutChartSliceData } from './donut-chart-slice-data';

export class DonutChartDataVM {
    title: string = "";
    subTitle: string = "";
    slicesDatas: DonutChartSliceData[] = [];
    labelsFontSize: number = 3;
    centerText: string = "";
    totalValue: number = 0;
    labelsOverflow: boolean = false;

    constructor(title: string, subTitle: string, slicesDatas: DonutChartSliceData[], totalValue: number, fontSize: number = 3) {
        this.title = title;
        this.subTitle = subTitle;
        this.slicesDatas = slicesDatas;
        this.totalValue = totalValue;
        this.labelsFontSize = fontSize
        if (slicesDatas.length > 0 && slicesDatas.map(x=> x.value).reduce((a, b) => a + b) > 0) {
            this.arrangeLabels();
        } else {
            this.centerText = "Aucune donnée disponible"
        }
    }

    arrange(): void {
        this.slicesDatas.forEach(sd => {
            sd.setRotation();
            sd.flushLabelPosition();
        });
    }

    arrangeLabels(): void {
        let rightLabels = this.slicesDatas.filter(x=> x.labelPosition.x > 0);
        let leftLabels = this.slicesDatas.filter(x=> x.labelPosition.x < 0);
        this.arrangelabelsOverlap(rightLabels, leftLabels);
    }

    arrangelabelsOverlap(rightLabels: DonutChartSliceData[], leftLabels: DonutChartSliceData[]): void {
        // Lorsque les parts sont très petites, il y a chevauchement des étiquettes
        // on veut donc ajuster leurs positions en x de façon à ce qu'elles soient lisibles

        this.arrangeQuarterLabels(rightLabels);
        this.arrangeQuarterLabels(leftLabels);
    }

    arrangeQuarterLabels(labels: DonutChartSliceData[]): void {
        // TODO : optimisation please !
        if (labels.length < 2) return;

        const sortedArray = labels.sort((a, b) => a.labelPosition.y - b.labelPosition.y);
        const targetDist = (this.labelsFontSize * 2) + 0.5;
        let hasMoved: boolean = false;
        let counter = 0;
        // Premier traitement : les étiquettes sont écartées les unes des autres verticalement si nécessaire
        do {
            hasMoved = false;
            counter++;
            let step = 1.5;
            for (let index = 0; index < sortedArray.length / 2; index++) {
                const s1 = sortedArray[index];
                const s2 = sortedArray[index + 1];
                const dist = Math.abs(s2.labelMovedPosition.y - s1.labelMovedPosition.y);
                if (dist < targetDist) {
                    s1.prepareMoveLabel(Math.min(-step, -dist/2));
                    step -= 0.1;
                    hasMoved = true
                }
            }
            step = 1.5;
            for (let index = sortedArray.length - 1; index > sortedArray.length / 2; index--) {
                const s1 = sortedArray[index];
                const s2 = sortedArray[index - 1];
                const dist = Math.abs(s2.labelMovedPosition.y - s1.labelMovedPosition.y);
                if (dist < targetDist) {
                    s1.prepareMoveLabel(Math.max(step, dist/2));
                    step -= 0.1;
                    hasMoved = true
                }
            }
        } while (hasMoved && counter < 100);

        // Second traitement : les étiquettes sont redescendues s'il y en a au dessus de la viewbox
        counter = 0;
        do {
            hasMoved = false;
            counter++;
            let step = 1.5;

            // La position des étiquettes est calculée à partir de l'origine qui se trouve en haut à gauche du svg
            // les plus hautes ont donc des coordonnées négatives
            const higherLabel = sortedArray[0];
            if (higherLabel.labelMovedPosition.y < -45) {
                higherLabel.prepareMoveLabel(step);
                hasMoved = true
                for (let index = 1; index < sortedArray.length; index++) {
                    const s1 = sortedArray[index - 1];
                    const s2 = sortedArray[index];
                    const dist = Math.abs(s2.labelMovedPosition.y - s1.labelMovedPosition.y);
                    if (dist < targetDist) {
                        s2.prepareMoveLabel(step);
                    }
                }
            }
        } while (hasMoved && counter < 100);

        // troisième traitement : les étiquettes sont remontées s'il y en a au dessous de la viewbox
        counter = 0;
        do {
            hasMoved = false;
            counter++;
            let step = -1.5;

            // La position des étiquettes est calculée à partir de l'origine qui se trouve en haut à gauche du svg
            // les plus hautes ont donc des coordonnées négatives
            const lowerLabel = sortedArray[sortedArray.length - 1];
            if (lowerLabel.labelMovedPosition.y > 140) {
                lowerLabel.prepareMoveLabel(step);
                hasMoved = true
                for (let index = sortedArray.length - 2; index > 0; index--) {
                    const s1 = sortedArray[index + 1];
                    const s2 = sortedArray[index];
                    const dist = Math.abs(s2.labelMovedPosition.y - s1.labelMovedPosition.y);
                    if (dist < targetDist) {
                        s2.prepareMoveLabel(step);
                    }
                }
            }
        } while (hasMoved && counter < 100);
    }

    checkLabelsOverflow(): boolean {
        let ys = this.slicesDatas.map(x=> x.leaderLine.endPoint.y);
        let maxy = Math.max(...ys);
        let miny = Math.min(...ys);

        return (maxy > 125 || miny < -25);
    }
}
