import { Rectangle } from "src/app/core/model/geometry-model/rectangle.model";
import { DxfSvgEntity } from "../svg/dxf-svg-entity";
import { Point } from "src/app/core/model/geometry-model/point.model";

export class MapTile {
    row: number;
    col: number;
    size: number;
    index: number;
    items: DxfSvgEntity[] = [];
    
    constructor(row: number, col: number, size: number, index: number) {
        this.row = row;
        this.col = col;
        this.size = size;
        this.index = index;
    }

    addEntity(entity: DxfSvgEntity): void {
        this.items.push(entity);
        entity.tilesIndexes.push(this.index);
    }

    remove(entities: DxfSvgEntity[]): void {
        const tmp: DxfSvgEntity[] = [];
        this.items.forEach(i => {
            const de = entities.find(x=> x.entityId === i.entityId);
            if (!de) {
                tmp.push(i);
            } else {
                de.tilesIndexes.forEach( (i, index) => {
                    if(i === index) de.tilesIndexes.splice(index, 1);
                });
            }
        });
        this.items = tmp;

    }

    rectangle(): Rectangle {
        const tl = new Point(this.col * this.size, this.row * this.size);
        const tr = new Point((this.col + 1) * this.size, this.row * this.size);
        const br = new Point((this.col + 1) * this.size, (this.row + 1) * this.size);
        const bl = new Point(this.col * this.size, (this.row + 1) * this.size);
        return new Rectangle([tl, tr, br, bl]);
    }
}