import { CreationStepperComponent } from './../../../creation-stepper/view/creation-stepper/creation-stepper.component';
import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StrategySchedulingVM } from '../../model/strategy-scheduling-vm';
import { StrategyScheduleComponent } from '../strategy-schedule/strategy-schedule.component';
import { FormsModule } from '@angular/forms';
import { AutoFocusDirective } from 'src/app/components-lib/directives/auto-focus.directive';

@Component({
  selector: 'xc-strategy-scheduling',
  standalone: true,
  imports: [CommonModule, FormsModule, CreationStepperComponent, StrategyScheduleComponent, AutoFocusDirective],
  templateUrl: './strategy-scheduling.component.html',
  styleUrls: ['./strategy-scheduling.component.scss']
})
export class StrategySchedulingComponent {
  @Input() model: StrategySchedulingVM | undefined;
}
