import { TablesNamesEnum } from './../tables-names-enum';
export const DirectoryTable = {
    databaseTableName: TablesNamesEnum.Directory,
    diId: 'Di_Id',
    diCode: 'Di_Code',
    diTitle: 'Di_Title',
    diFirstName: 'Di_FirstName',
    diLastName: 'Di_LastName',
    diBusinessUnitId: 'Di_BusinessUnitId',
    diOccupancyRatio: 'Di_OccupancyRatio',
    diIsInternal: 'Di_IsInternal'
  };
  