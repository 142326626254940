import { WorkplaceAllocationViewSet } from "src/app/core/model/data-model/view-set/workplace-allocation-view-set";
import { WorkplaceViewSet } from "src/app/core/model/data-model/view-set/workplace-view-set";
import { BpSvgLabel } from "./bp-svg-label";
import { BlueprintSvgEntityTypeEnum } from "./blueprint-svg-entity-type-enum";

export class BpSvgWorkplaceLabel extends BpSvgLabel {
    workplace: WorkplaceViewSet | undefined;
    workplaceAllocations: WorkplaceAllocationViewSet[] = [];

    constructor(dtoData: any) {
        super(dtoData, BlueprintSvgEntityTypeEnum.workplaceLabel);

        this.backgroundSizeChanged = () => {
            
        }
    }
}