import { FloorModelDbView } from './../../db-model/views/floor-model-db-view';
import { FloorCatalogImport } from './../tables/floor-catalog-import';
import { FloorCatalog } from './../tables/floor-catalog';
import { FloorCatalogImportItem } from '../tables/floor-catalog-import-item';

export class FloorCatalogImportItemView {
    floorCatalogImport: FloorCatalogImport;
    floorCatalogImportItem: FloorCatalogImportItem;
    floorCatalog: FloorCatalog;
    flMoDisplayName!: string;

    constructor(dtoData: any) {
        this.floorCatalogImport = new FloorCatalogImport(dtoData);
        this.floorCatalogImportItem = new FloorCatalogImportItem(dtoData);
        this.floorCatalog = new FloorCatalog(dtoData);
        this.flMoDisplayName = dtoData[FloorModelDbView.flMoDisplayName];
    }
}