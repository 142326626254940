import { ITabPageContent } from "src/app/components-lib/tab-page-container/model/i-tab-page-content";
import { ProvidersTabEnum } from "./providers-tab-enum";

export class ProvidersDashboardVM implements ITabPageContent {
    contentType:  ProvidersTabEnum = ProvidersTabEnum.dashboard;
    content: any;

    set(data: any): void {
    }
    
}