export class SvgConstants {
    static SvgTagName = "svg";
    static DefsTagName = "defs";
    static GTagName = "g";

    static ViewboxAttributeName = "viewBox";
    static PreserverAspectRatioAttributeName = "preserveAspectRatio";
    static XAttributeName = "x";
    static YAttributeName = "y";
    static TextAnchorAttributeName = "text-anchor";
    static X1AttributeName = "x1";
    static Y1AttributeName = "y1";
    static X2AttributeName = "x2";
    static Y2AttributeName = "y2";
    static dAttributeName = "d";
    static CxAttributeName = "cx";
    static CyAttributeName = "cy";
    static RadiusAttributeName = "r";
    static XRadiusAttributeName = "rx";
    static YRadiusAttributeName = "ry";
    static DXAttributeName = "dx";
    static DYAttributeName = "dy";
    static FillRuleAttributeName = "fill-rule";

    static PathAbsoluteMoveToFlagName = 'M';
    static PathRelativeMoveToFlagName = 'm';
    static PathAbsoluteLineToFlagName = 'L';
    static PathRelativeLineToFlagName = 'l';
    static PathArcFlagName = 'A';
    static PathCloseFlagName = 'Z';

    static TextAnchorStartAttributeValue = "start";
    static TextAnchorMiddleAttributeValue = "middle";
    static TextAnchorEndAttributeValue = "end";
    static FillRuleNonZeroAttributeValue = "nonzero";
    static FillRuleEvenOddAttributeValue = "evenodd";
    static NoneAttributeValue = "none";
    static PreserverAspectRatioxMinYMinValue = "xMinYMin";
}