import { ZLanguageItemTable } from 'src/app/core/model/db-model/tables/z-language-item-table';
import { Polygon } from "src/app/core/model/geometry-model/polygon.model";
import { HtmlConstants } from 'src/app/core/model/html-model/html-constants.model';
import { Segment } from 'src/app/core/model/geometry-model/segment.model';
import { FloorDataTable } from 'src/app/core/model/db-model/tables/floor-data-table';
import { FloorModelTable } from 'src/app/core/model/db-model/tables/floor-model-table';
import { IntersectedSegment } from 'src/app/core/model/geometry-model/intersected-segment';
import { getLayerEntities } from '../bp-svg-composite-entities-builder';
import { BpSvgEntity } from '../../../bp-svg-core-model/bp-svg-entity';
import { FloorDataStateEnum } from 'src/app/core/model/data-model/enums/floor-data-state-enum';

export class BlueprintLayer<T extends BpSvgEntity = BpSvgEntity> {
    data: BpSvgEntity[] = [];
    fill: string;
    id: number;
    categoryId: number;
    invariantName: string;
    name: string;
    stroke: string;
    strokeWidth: number = 0.01;
    opacity: number = 1;
    taskId: number;
    isUseLayer: boolean;
    isLabelLayer: boolean = false;
    display: string = HtmlConstants.styleDisplayBlock;
    displayChangedByProcess: boolean = false;
    hiddenByUser: boolean = false;

    constructor(dtoData: any, taskId: number) {
        this.fill = dtoData.fill;
        this.id = dtoData.id;
        this.categoryId = dtoData.categoryId;
        this.invariantName = dtoData.invariantName;
        this.name = dtoData.name;
        this.stroke = dtoData.stroke;
        this.strokeWidth = Math.max(dtoData.strokeWidth, 0.01);
        this.taskId = taskId;
        this.isUseLayer = dtoData.isUseLayer;

        this.data = getLayerEntities(this.id, dtoData.data);
    }

    typedData(): T[] {
        return this.data as T[];
    }

    setItemsSelectable(selectable: boolean): void {
        this.data.forEach(e => {
            if (selectable) {
                e.cursor = HtmlConstants.styleCursorPointer;
            } else {
                e.cursor = HtmlConstants.styleCursorInherit;
            }
        });
    }

    /**
     * Actualise le curseur de sélection sur une partie des éléments
     * @param exceptedRoomsIds Identifiants des éléments qui ne doivent pas être sélectionnables
     * @param selectable Condition de 'sélectionnabilité'
     */
     setItemsSelectableExcept(exceptedItemsIds: number[], selectable: boolean): void {
        this.data.forEach(e => {
            if (selectable && !exceptedItemsIds.includes(e.floorDataId)) {
                e.cursor = HtmlConstants.styleCursorPointer;
            } else {
                e.cursor = HtmlConstants.styleCursorInherit;
            }
        });
    }

    setCursor(cursor: string): void {
        this.data.forEach(e => {
            e.cursor = cursor;
        });
    }

    show(display: boolean): void {
        if (display) {
            this.display = HtmlConstants.styleDisplayBlock;
        } else {
            this.display = HtmlConstants.styleDisplayNone;
        }
    }

    switchDisplay(fromProcess: boolean): void {
        if (fromProcess) this.displayChangedByProcess = false;
        if (this.display === HtmlConstants.styleDisplayBlock) {
            this.display = HtmlConstants.styleDisplayNone;
        } else {
            this.display = HtmlConstants.styleDisplayBlock;
        }
    }

    hideByProcess(hidden: boolean): void {
        this.displayChangedByProcess = true;
        this.show(!hidden);
    }

    hideByUser(hidden: boolean): void {
        this.hiddenByUser = hidden;
        this.show(!this.hiddenByUser && !this.displayChangedByProcess);
    }

    isVisible(): boolean {
        return this.display === HtmlConstants.styleDisplayBlock;
    }

    insert(items: BpSvgEntity | BpSvgEntity[]): void {
        if (items instanceof BpSvgEntity) {
            this.data.push(items);
        } else {
            this.data.push(...items);
        }
    }

    remove(floorDataId: number): boolean {
        const index = this.data.findIndex(x=>x.floorDataId === floorDataId);
        if (index > -1) {
            this.data.splice(index, 1);
            return true;
        }
        return false;
    }

    updateDataState(floorDataId: number, dataStateId: number): void {
        const e = this.data.find(x=> x.floorDataId === floorDataId);
        if (e) {
            e.dataStateId = dataStateId;
            e.show(e.dataStateId !== FloorDataStateEnum.Deleted); 

        }
    }

    showDataState(dataStateId: number, display: boolean): void {
        this.data.forEach(e => {
            if (e.dataStateId === dataStateId) {
                e.show(display);
            }
        });
    }

    showPlanningState(planningStateId: number, display: boolean): void {
        this.data.forEach(e => {
            if (e.planningStateId === planningStateId) {
                e.show(display);
            }
        });
    }

    hideChildrenByProcess(parentIds: number[], hidden: boolean): number[] {
        const children = this.data.filter(x=> parentIds.includes(x.parentId!));
        children.forEach(c => {
            c.hiddenByProcess = hidden;
        });

        return children.map(x=> x.floorDataId);
    }

    static getIntersects(reticle: Polygon, segments: Segment[], layerId: number, taskId: number): IntersectedSegment[] {
        const result = reticle.getBpIntersects(segments);
        IntersectedSegment.setLayerSource(result, layerId, taskId);
        return result;
    }

    static getIntersectedSegments(reticle: Polygon, segments: Segment[], layerId: number, taskId: number): Segment[] {
        // L'intersection avec le réticule donne plusieurs fois le même segment si celui-ci le traverse
        // On élimine les doublons
        const tmp = this.getIntersects(reticle, segments, layerId, taskId);
        const distinctSegments: Segment[] = [];
        tmp.forEach(i => {
            if (!distinctSegments.includes(i.segment!)) {
                distinctSegments.push(i.segment!);
            }
        });
        return distinctSegments;
    }

    // static fromView<T extends BlueprintLayer>(dtoData: any, isUseLayer: boolean): T {
    //     const attributes = dtoData["attributes"];
    //     const taskId = dtoData[FloorDataTable.flDaTaskId];
    //     return new BlueprintLayer({ 
    //         name: dtoData.name,
    //         invariantName: dtoData.invariantname,
    //         id: dtoData.id,
    //         stroke: dtoData.stroke,
    //         fill: dtoData.fill,
    //         isUseLayer: isUseLayer
    //      }, taskId) as T;
    // }
}