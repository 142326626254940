import { Point } from "./point.model";
import { Polygon } from "./polygon.model";
import { Rectangle } from "./rectangle.model";
import { Segment } from "./segment.model";

export class PolygonService {
    // TODO : Méthode SVG à sortir de la lib de géométrie
    static centeredSquare(point: Point, squareHalfSize: number): Rectangle {
        var topLeft = new Point(point.x - squareHalfSize, point.y - squareHalfSize);
        var topRight = new Point(point.x + squareHalfSize, point.y - squareHalfSize);
        var bottomRight = new Point(point.x + squareHalfSize, point.y + squareHalfSize);
        var bottomLeft = new Point(point.x - squareHalfSize, point.y + squareHalfSize);

        const result = new Rectangle([ topLeft, topRight, bottomRight, bottomLeft ]);
        result.close();
        return result;
    }

    static separateOverlapedSegments(polygons: Polygon[], tolerance: number = Number.EPSILON): Segment[] {
        const result: Segment[] = [];

        for (let i = 0; i < polygons.length - 1; i++) {
            const p1 = polygons[i];
            const p1s = p1.getSegments();
            for (let j = i + 1; j < polygons.length; j++) {
                const p2 = polygons[j];
                const p2s = p2.getSegments();
                for (let k = 0; k < p1s.length; k++) {
                    const s1 = p1s[k];
                    for (let l = 0; l < p2s.length; l++) {
                        const s2 = p2s[l];
                        const overlap = s1.getOverlapSegment(s2, tolerance);
                        if (overlap != null) result.push(overlap);
                    }
                }
            }
        }

        return result;
    }

    /**
     * 
     * @param polygons 
     * @param points 
     * @returns Retourne un objet avec l'indication de position de chaque point
     */
    static endsPositions(polygons: Polygon[], points: Point[]): {p: Point, isInside: boolean}[] {
        const tmp: {p: Point, isInside: boolean}[] = [];
        points.forEach(pt => {
            const item = {p: pt, isInside: false};
            tmp.push(item);
            polygons.forEach(p => {
                if (!item.isInside) {
                    if (p.contains(pt)) {
                        item.isInside = true;
                    } else {
                        // Le point n'a pas été trouvé dans le polygone
                        // mais il peut arriver qu'il soit sur un côté et détecté comme extérieur
                        // on fait donc un test supplémentaire dans ce cas
                        item.isInside = p.isInTheEdge(pt);
                    }
                }
            });
        });

        return tmp;
    }
}