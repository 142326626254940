import { Service } from "typedi";
import { ApiControllers } from "../api-endpoints";
import { ApiService } from "../api-service";

@Service({ global: true })  
export class ContextService extends ApiService {
    private updateColumnOrderEndpoint: string = `${ApiControllers.context}/displayorder`;

    constructor() {
        super();
    }

    async saveUserColumnsOrder(dto: {}[]): Promise<void> {
        await this.patchAsync(this.endPoint(this.updateColumnOrderEndpoint), dto);
    }
}