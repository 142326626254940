import { FloorDataImportItemTable } from '../../db-model/tables/floor-data-import-item-table';
import { DefinedModelBase } from '../defined-model-base';

export class FloorDataImportItem extends DefinedModelBase {
    flDaImItId!: number;
    flDaImItRequestId!: string;
    flDaImItFloorCatalogId!: number | null;
    flDaImItSvgStatement!: string;
    flDaImItBoundingBox!: string;
    flDaImItFloorModelId!: number;
    flDaImItTaskId!: number;
    flDaImItDataStateId!: number;
    flDaImItParentId!: number | null;
    flDaImItSourceId!: number | null;
    flDaImItAnchorId!: number | null;

    constructor(dtoData: any) {
        super(dtoData, [FloorDataImportItemTable]);
    }
}