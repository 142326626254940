import { BuildingAttributionDbView } from '../../db-model/views/building-attribution-db-view';
import { DefinedModelBase } from '../defined-model-base';

export class BuildingAttributionView extends DefinedModelBase {
    buSiteId!: number;
    flBuildingId!: number;
    roAttributionTypeId!: number;
    roAtTyName!: string;
    roAtTyColor!: string;
    roAtTyDisplayNameId!: number;
    roAtTyViDisplayName!: string;
    buAtVi_Area!: number;
    buAtViRoundedArea!: number;

    constructor(dtoData: any) {
        super(dtoData, [BuildingAttributionDbView]);
    }
}