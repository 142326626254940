@if (dialogData.dialogTitle) {
<h2 mat-dialog-title>{{dialogData.dialogTitle}}</h2>
}
<!-- This is the content of the upload-dialog, containing a list of the files to upload -->
<mat-dialog-content class='mat-typography'>
    <xc-file-upload (uploading)='onUploading()'
               (successfulUpload)='successfulUpload($event)'
               [acceptedFiles]='dialogData.acceptedFiles'
               [uploadUrl]='dialogData.uploadUrl'
               (filesCount)='onFileAdded($event)'
               [beginUpload]='beginUpload'
               [fileMaxSize]="dialogData.maxSize"
               [fileName]="dialogData.fileName">
    </xc-file-upload>
</mat-dialog-content>

<!-- This are the actions of the upload-dialog, containing the primary and the cancel button-->
<mat-dialog-actions align='end'>
  @if (showCancelButton) {
<button color="warn" mat-button mat-dialog-close>Annuler</button>
}
  <button mat-button
          color="accent"
          [disabled]="!canBeClosed || uploadListEmpty"
          (click)="startUpload()">
    {{primaryButtonText}}
  </button>
</mat-dialog-actions>

