export enum MobilityProjectsGanttTreeEventsEnum {
    taskSelectedByUser = "taskSelectedByUser",
    taskSelectedByrocess = "taskSelectedByrocess",
    projectExpanded = "projectExpanded",
    projectCollapsed = "projectCollapsed",
    projectsTreeScrolled = "projectsTreeScrolled",
    projectsLoaded = "projectsLoaded",
    refreshProjectRequested = "refreshProjectRequested",
    addScheduleProjectRequested = "addScheduleProjectRequested",
    taskRemoved = "taskRemoved",
    templateSwitchChange = "templateSwitchChange"
}