import { Container } from 'typedi';
import { ProviderCompany } from "src/app/core/model/data-model/tables/provider-company";
import { EditableLayer } from "../../../../blueprint-viewer-content-panel/itself/model/interaction/editable-layer";
import { BpSvgEquipmentCatalogGroup } from './bp-svg-equipment-catalog-group';
import { TablesNamesEnum } from 'src/app/core/model/db-model/tables-names-enum';
import { DyntService } from 'src/app/core/services/backend-services/dynt-service';
import { BpSvgGroup } from '../../../../bp-svg-core-model/bp-svg-group';
import { Point } from 'src/app/core/model/geometry-model/point.model';
import { EquipmentCategoryOptionGroup } from './equipment-category-option-group';
import { BpEquipmentService } from 'src/app/core/services/backend-services/bp-equipment-service';
import { logError } from 'src/app/core/services/logging-service';

export class EquipmentListFormVM {
    editableLayers: EditableLayer[] = [];
    selectedLayer: EditableLayer | undefined;

    equipmentCategories: EquipmentCategoryOptionGroup[] = [];
    selectedCategory:  EquipmentCategoryOptionGroup | undefined;

    categoryEquipments: BpSvgEquipmentCatalogGroup[] = [];
    //selectedEquipment;

    equipmentsProviders: ProviderCompany[]= [];
    selectedProvider: ProviderCompany | undefined;

    overedImageUrl: string | undefined;
    overedImagePos: Point | undefined;

    constructor() { 
    }

    async loadProviders(): Promise<void> {
        const s = Container.get(DyntService);
        this.equipmentsProviders = await s.downloadTable<ProviderCompany>(TablesNamesEnum.ProviderCompany);
    }

    setEditableLayers(editableLayers: EditableLayer[]): void {
        this.editableLayers = editableLayers.filter(x=> x.isEquipmentLayer);
        this.equipmentCategories.splice(0);
        this.categoryEquipments.splice(0);
    }

    async downloadLayerEquipmentCategories(layerId: number): Promise<void> {
        this.equipmentCategories.splice(0);
        this.selectedCategory = undefined;

        const s = Container.get(BpEquipmentService);
        const result = await s.getLayerEquipmentCategories(layerId);
        if (result != null) {
            this.equipmentCategories = (result as EquipmentCategoryOptionGroup[]).sort((a, b) => a.name.localeCompare(b.name));
            if (this.equipmentCategories.length === 1) {
                this.selectedCategory = this.equipmentCategories[0];
                await this.downloadEquipmentCategoryFloorCatalog(this.selectedCategory.id);
            }
        }
    }

    async downloadEquipmentCategoryFloorCatalog(categoryId: number): Promise<void> {
        if (!this.selectedLayer) return;
        const s = Container.get(BpEquipmentService);
        const result = await s.getEquipmentCategoryFloorCatalog(this.selectedLayer.layerId, categoryId);
        if (result != null) {
            this.categoryEquipments = result;
        }
    }

    async onLayerChange(e: EditableLayer): Promise<void> {
        this.equipmentCategories.splice(0);
        this.categoryEquipments.splice(0);
        await this.downloadLayerEquipmentCategories(e.layerId);
    }

    async onCategoryChange(e: { id: number, name: string }): Promise<void> {
        await this.downloadEquipmentCategoryFloorCatalog(e.id);
    }

    equipmentInsertRequested?: (item: BpSvgGroup) => void;
    onEquipmentClick(e: MouseEvent, item: BpSvgGroup): void {
        if (this.equipmentInsertRequested != null) {
            this.equipmentInsertRequested(item);
        } else {
            logError("EquipmentLitFormVM.equipmentInsertRequested n'est pas écouté");
        }
    }

    ecMouseEnter(e: MouseEvent, imageUrl: string): void {
        this.overedImageUrl = imageUrl;
        this.overedImagePos = new Point(e.offsetX, e.clientY);
    }

    ecMouseLeave(e: MouseEvent): void {
        this.overedImageUrl = undefined;
        //this.overedImageUrl = "99999999999999/FloorCatalog/152.svg";
        this.overedImagePos = undefined;
    }
}