import { FormsModule } from '@angular/forms';
import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from 'src/app/core/material.module';
import { EquipmentListFormVM } from '../../model/equipment-list-form-vm';

@Component({
  standalone: true,
  imports: [CommonModule, MaterialModule, FormsModule],
  selector: 'xc-equipment-list-form',
  templateUrl: './equipment-list-form.component.html',
  styleUrls: ['./equipment-list-form.component.scss']
})
export class EquipmentListFormComponent implements OnInit {
  @Input() model: EquipmentListFormVM | undefined;

  constructor() { }

  ngOnInit(): void {
  }

}
