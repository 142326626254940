import { DbModelUtils } from 'src/app/core/model/static-functions/db-model-utils';
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { TaskTypeEnum } from "src/app/core/model/data-model/enums/task-type-enum";
import { Task } from "src/app/core/model/data-model/tables/task";
import { FacilityCompanyTaskView } from "src/app/core/model/data-model/views/facility_company_task_view";
import { TaskBusinessTypeView } from "src/app/core/model/data-model/views/task-business-type-view";
import { TaskTypeView } from "src/app/core/model/data-model/views/task-type-view";
import { RealEstateProcessing } from "src/app/core/model/data-processing/real-estate-processing";
import { TablesNamesEnum } from "src/app/core/model/db-model/tables-names-enum";
import { TaskTable } from "src/app/core/model/db-model/tables/task-table";
import { ViewsNames } from "src/app/core/model/db-model/views-names-enum";
import { RealEstateView } from "src/app/core/services/backend-services/dto/real-estate-view";
import { DyntService } from "src/app/core/services/backend-services/dynt-service";
import { RealEstateService } from "src/app/core/services/backend-services/real-estate-service";
import { TaskContributorService } from "src/app/core/services/backend-services/task-contributor-service";
import Container from "typedi";
import { ArrayUtils } from 'src/app/core/model/static-functions/array-utils';
import { FloorModelCategoryTable } from 'src/app/core/model/db-model/tables/floor-model-category.table';
import { Point } from 'src/app/core/model/geometry-model/point.model';
import { SelectOptionItem } from './select-option-item';
import { FloorModelView } from 'src/app/core/model/data-model/views/floor-model-view';
import { EventEmitter } from 'src/app/core/events/event-emitter';
import { businessTypeIsFacilityType, businessTypeIsSpacePlanningType } from 'src/app/core/model/data-processing/task-processing';
import { TaskCreateInternalDTO } from './task-create-internal-dto';
import { FacilityCompanyTaskSelectItem } from './facility_company_task_select_item';
import { MobilityProjectsGanttEditorEventsEnum } from './mobility-projects-gantt-editor-events-enum';
import { TaskBusinessTaskEnum } from 'src/app/core/model/data-model/enums/task-business-type-enum';

export class TaskCreationFormVM extends EventEmitter {
    dto: TaskCreateInternalDTO = new TaskCreateInternalDTO();

    taskTypes: TaskTypeView[] = [];
    selectedTaskType: TaskTypeView | undefined;
    TaskTypeEnum = TaskTypeEnum;

    fromTemplate: boolean = false;
    startNewProject: boolean = false;

    templateRef: Task[] = [];
    templates: SelectOptionItem[] = [];
    selectedTemplate!: SelectOptionItem;

    businessTypes: SelectOptionItem[] = [];
    selectedBusinessType!: SelectOptionItem;

    facilityCompaniesTasks: FacilityCompanyTaskSelectItem[] = [];
    selectedFacilityCompanyTask!: SelectOptionItem;

    realEstate: RealEstateView[] = [];
    filteredRealEstate: RealEstateView[] = [];
    realEstateFilterValue: string = "";
    selectedFloor: RealEstateView | undefined;

    layersCategories: SelectOptionItem[] = [];
    selectedLayers: SelectOptionItem[] = [];

    canCreateTask: boolean = false;
    canCreate: boolean = false;
    //accessor formCompleted: boolean = false;

    constructor() {
        super();

        this.initialize().then(() => {
            if (this.taskTypes.length > 0) {
                this.selectedTaskType = this.taskTypes[0];
                this.dto.setType(this.selectedTaskType.taTyId);
            }
        });
    }

    async initialize(): Promise<void> {
        const s = Container.get(DyntService);
        this.taskTypes = await s.downloadTable<TaskTypeView>(ViewsNames.TaskTypeView);
        await this.loadTemplateTasks();
        this.canCreateTask = await this.userCanCreateTasks();
    }

    async userCanCreateTasks(): Promise<boolean> {
        // L'utilisateur peut créer une tâche s'il est référencé dans l'annuaire des contributeurs
        // et s'il est habilité en écriture sur la table task
        const s = Container.get(TaskContributorService);
        return await s.canCreateTask();
    }

    async loadTemplateTasks(): Promise<void> {
        const s = Container.get(DyntService);
        this.templateRef = await s.downloadTable<Task>(TablesNamesEnum.Task, undefined, TaskTable.taIsTemplate, 1);

        const tmp: SelectOptionItem[] = [];
        tmp.push(new SelectOptionItem(0, "Aucun"));
        const taskTypeTemplates = this.templateRef.filter(x=> x.taTypeId === this.selectedTaskType?.taTyId && x.taDepth === 1);
        taskTypeTemplates.forEach(t => {
            tmp.push(new SelectOptionItem(t.taId, t.taName));
        });
        this.templates = tmp;
        this.selectedTemplate = tmp[0];
    }

    async loadBusinessTypes(): Promise<void> {
        const s = Container.get(DyntService);
        const items = await s.downloadTable<TaskBusinessTypeView>(ViewsNames.TaskBusinessTypeView, undefined);
        
        const tmp: SelectOptionItem[] = [];
        tmp.push(new SelectOptionItem(0, "Aucun"));
        const sortedItems = items.sort((a, b) => a.taBuTyDisplayName.localeCompare(b.taBuTyDisplayName));
        sortedItems.forEach(e => {
            tmp.push(new SelectOptionItem(e.taBuTyId, e.taBuTyDisplayName));
        });

        this.businessTypes = tmp;
        this.selectedBusinessType = tmp[0];
    }

    async loadFacilityCompanyTasks(): Promise<void> {
        const s = Container.get(DyntService);
        const items = await s.downloadTable<FacilityCompanyTaskView>(ViewsNames.FacilityCompanyTaskView, undefined);
        
        const tmp: FacilityCompanyTaskSelectItem[] = [];
        tmp.push(new FacilityCompanyTaskSelectItem(0, "Aucune", [new SelectOptionItem(0, "Aucune")], false));
        const companiesIds = [... new Set(items.map(x=> x.faCoId))];
        companiesIds.forEach(cid => {
            const tasks = items.filter(x=> x.faCoId === cid);
            const t0 = tasks[0];
            tmp.push(new FacilityCompanyTaskSelectItem(t0.faCoId, t0.faCoName, tasks.map(x=> new SelectOptionItem(x.jobTaskView.JobTask.faJoTaId, x.jobTaskView.JobTask.faJoTaName))));
        });

        this.facilityCompaniesTasks = tmp;
        this.selectedFacilityCompanyTask = tmp[0];
    }

    async loadRealEstate(): Promise<void> {
        const s = Container.get(RealEstateService);
        const result = await s.loadFlatHierarchy();
        this.realEstate = RealEstateProcessing.getBuildingItems(result);
        this.filteredRealEstate = this.realEstate;
        this.selectedFloor = undefined;
    }

    async loadLayers(): Promise<void> {
        const s = Container.get(DyntService);
        const items = await s.downloadTable<FloorModelView>(ViewsNames.FloorModelView, undefined);
        const cats = ArrayUtils.DistinctBy(items.map(x=> x.category), DbModelUtils.key(FloorModelCategoryTable, FloorModelCategoryTable.flMoCaId));
        const catsTmp: SelectOptionItem[] = [];
        cats.forEach(c => {
            const newCat = new SelectOptionItem(c.flMoCaId, c.flMoCaDisplayName);
            const layers = items.filter(x=> x.flMoCategoryId === c.flMoCaId);
            const layersTmp: SelectOptionItem[] = [];
            layers.forEach(l => {
                layersTmp.push(new SelectOptionItem(l.flMoId, l.flMoDisplayName));
            });
            newCat.children = layersTmp;
            catsTmp.push(newCat);
        });

        this.layersCategories = catsTmp;
    }

    taskTypeCompare(a: TaskTypeView, b: TaskTypeView ): boolean {
        return a?.taTyId === b?.taTyId;
    }

    selectedTemplateCompare(a: SelectOptionItem, b: SelectOptionItem ): boolean {
        return a?.id === b?.id;
    }

    selectedBusinessTypeIsFacilityType(): boolean {
        if (!this.selectedBusinessType) return false;
        return businessTypeIsFacilityType(this.selectedBusinessType.id);
    }

    selectedBusinessTypeIsSpacePlanningType(): boolean {
        if (!this.selectedBusinessType) return false;
        return this.selectedBusinessType.id === TaskBusinessTaskEnum.BlueprintImport ||
        this.selectedBusinessType.id === TaskBusinessTaskEnum.SpacePlanning ||
        this.selectedBusinessType.id === TaskBusinessTaskEnum.SpacePlanningImport;
        //return businessTypeIsSpacePlanningType(this.selectedBusinessType.id);
    }

    async onSelectedTaskTypeChange(): Promise<void> {
        if (!this.selectedTaskType) return;

        switch (this.selectedTaskType.taTyId) {
            case TaskTypeEnum.Milestone:
                break;
            case TaskTypeEnum.Project:
                await this.loadTemplateTasks();
                break;
            case TaskTypeEnum.Task:
                if (this.fromTemplate) {
                    await this.loadTemplateTasks();
                } else {
                    await this.loadBusinessTypes();
                }
                break;
        }

        this.dto.setType(this.selectedTaskType.taTyId);
        this.canCreate = this.formCompleted();
    }

    async onFromTemplateChange(): Promise<void> {
        if (this.fromTemplate) {
            await this.loadTemplateTasks();
        } else {
            await this.loadBusinessTypes();
        }

        this.canCreate = this.formCompleted();
    }

    async onStartNewProjectChange(): Promise<void> {
        this.dto.startNewProject = this.startNewProject;
    }

    onSelectedTemplateChange(): void {
        this.dto.setTemplate(this.selectedTemplate);
    }

    async onSelectedBusinessTypeChange(): Promise<void> {
        //this.selectedFacilityCompanyTask = this.facilityCompaniesTasks[0];
        //this.selectedFloor = undefined;
        if (this.selectedBusinessTypeIsFacilityType()) {
            await this.loadFacilityCompanyTasks();
        } else {
            if (this.selectedBusinessTypeIsSpacePlanningType()) {
                await this.loadRealEstate();
                await this.loadLayers();
            }
        }

        this.dto.setBusinessType(this.selectedBusinessType);
        this.canCreate = this.formCompleted();
    }

    onSelectecFacillityTaskChange(): void {
        this.dto.setFacilityTask(this.selectedFacilityCompanyTask);
        this.canCreate = this.formCompleted();
    }

    realEstateAutocompleteDisplay(item: RealEstateView): string {
        return item?.name;
    }

    onRealEstateSearchChange(): void {
        const tmpB: RealEstateView[] = [];
        this.realEstate.forEach(re => {
            const tmpF: RealEstateView[] = [];
            re.children.forEach(c => {
                if (c.name.toLowerCase().includes(this.realEstateFilterValue.toLowerCase())) {
                    tmpF.push(c);
                }
            });
            if (tmpF.length > 0) {
                tmpB.push(re);
                re.children = tmpF;
            }
        });
        this.filteredRealEstate = tmpB;
    }

    onRealEstateSelectionChange(e: MatAutocompleteSelectedEvent): void {
        this.selectedFloor = e.option.value;
        this.realEstateFilterValue = this.selectedFloor ? this.selectedFloor.name : "";
        this.dto.floor = this.selectedFloor;
        this.canCreate = this.formCompleted();
    }

    onSelectecLayersChange(): void {
        this.dto.layers = this.selectedLayers;
        this.canCreate = this.formCompleted();
    }

    formCompleted(): boolean {
        if (!this.selectedTaskType) return false;
        switch (this.selectedTaskType.taTyId) {
            case TaskTypeEnum.Milestone:
                return true;
            case TaskTypeEnum.Project:
                return this.selectedTemplate.id !== 0;
            case TaskTypeEnum.Task:
                if (this.fromTemplate) {
                    return this.selectedTemplate.id !== 0;
                }
                if (this.selectedBusinessType.id === 0) return false;
                if (this.selectedBusinessTypeIsFacilityType()) {
                    return this.selectedFacilityCompanyTask.id !== 0;
                }
                if (this.selectedBusinessTypeIsSpacePlanningType()) {
                    return this.selectedFloor !== undefined && this.selectedLayers.length > 0;
                }
                return true;
        }

        return false;
    }

    async addTaskButtonClick(e: MouseEvent): Promise<void> {
        if (!this.formCompleted()) return;
        this.dto.clickPoint = new Point(e.clientX, e.clientY);
        await this.emitEventAsync(MobilityProjectsGanttEditorEventsEnum.taskInsertRequested, this.dto);
    }
}