import { TabPager } from "src/app/components-lib/tab-page-container/model/tab-pager";
import { readableUUID } from "src/app/core/events/event-listener-uuid";
import { PurchaseTabEnum } from "./purchase-tab-enum";
import { TabPageItem } from "src/app/components-lib/tab-page-container/model/tab-page-item";
import { ITabPageContent } from "src/app/components-lib/tab-page-container/model/i-tab-page-content";
import { PurchasesReceiptVM } from "./purchases-receipt-vm";
import { DynTableVMBuilder } from "src/app/components-lib/dyn-grid/model/dyn-table-vm-builder";
import { FieldListItem } from "src/app/components-lib/shared-model/field-list-item";
import { ListField } from "src/app/components-lib/shared-model/list-field";
import { EquipmentCatalog } from "src/app/core/model/data-model/tables/equipment-catalog";
import { EquipmentCategoryView } from "src/app/core/model/data-model/views/equipment-category-view";
import { TablesNamesEnum } from "src/app/core/model/db-model/tables-names-enum";
import { EquipmentCatalogTable } from "src/app/core/model/db-model/tables/equipment-catalog-table";
import { EquipmentCategoryTable } from "src/app/core/model/db-model/tables/equipment-category-table";
import { EquipmentPurchaseDetailTable } from "src/app/core/model/db-model/tables/equipment-purchase-detail-table";
import { ViewsNames } from "src/app/core/model/db-model/views-names-enum";
import { ApiEndpoints } from "src/app/core/services/api-endpoints";
import { DyntService } from "src/app/core/services/backend-services/dynt-service";
import { PaginationService } from "src/app/core/services/backend-services/pagination-service";
import { UploadService } from "src/app/core/services/backend-services/upload-service";
import Container from "typedi";
import { TableReportWrapper } from "src/app/ui/shared/charts/table/table-report-wrapper";

export class PurchasesManageVM extends TabPager {
    purchaseTabEnum = PurchaseTabEnum;
    purchaseId: number = 0;
    hasReceipt: boolean = false;

    constructor() {
        super(readableUUID("PurchasesManageVM"));
    }
    
    async initialize(purchaseId: number, hasReceipt: boolean): Promise<void> {
        this.prepareTabs();
        this.purchaseId = purchaseId;
        this.hasReceipt = hasReceipt;
        await this.selectTabPage(0);
    }

    prepareTabs(): void {
        this.tabs.push(new TabPageItem(PurchaseTabEnum.detail, "Détail"));
        this.tabs.push(new TabPageItem(PurchaseTabEnum.receipt, "Réception"));
    }

    async refresh(purchaseId: number, hasReceipt: boolean): Promise<void> {
        this.purchaseId = purchaseId;
        this.hasReceipt = hasReceipt;
        await this.setSelectedContent();
    }

    override async loadContent(): Promise<ITabPageContent | undefined> {
        if (!this.selectedTab) return;

        switch (this.selectedTab.id) {
            case PurchaseTabEnum.detail:
                // const p = new PurchasesListVM();
                // await p.loadMainTable();
                return await this.loadDetailsTable(this.purchaseId);
            case PurchaseTabEnum.receipt:
                return new PurchasesReceiptVM(this.purchaseId, this.hasReceipt);
            default:
                break;
        }

        return undefined;
    }

    async loadDetailsTable(purchaseId: number): Promise<TableReportWrapper> {
        const detailsTable = "equipment_purchase_detail_dynview";
        const p = Container.get(PaginationService);
        const paginator = await p.getPaginator(detailsTable, purchaseId.toString());
        const s = Container.get(DyntService);
        const viewSet = await s.contextedDataSet(detailsTable, 0, EquipmentPurchaseDetailTable.eqPuDePurchaseId, purchaseId, paginator?.pageItemsCount);
        const table = DynTableVMBuilder.getVM(viewSet, detailsTable, paginator);
        
        // Chargement des valeurs actuelles        
        table.setCurrentSelectOption(EquipmentPurchaseDetailTable.eqPuDeEquipmentCategoryId, "CategoryId", "CategoryName");
        table.setCurrentSelectOption(EquipmentPurchaseDetailTable.eqPuDeEquipmentCatalogId, "CatalogId", "CatalogName");

        table.loadOptionsRequested = async (f: ListField, rowId: number): Promise<FieldListItem[]> => {
            const p = Container.get(DyntService);
            if (f.colId === EquipmentPurchaseDetailTable.eqPuDeEquipmentCategoryId) {
                // Sélectionne les catégories d'équipement
                const cats = await p.downloadTable<EquipmentCategoryView>(ViewsNames.EquipmentCategoryView, undefined, EquipmentCategoryTable.eqCaDepth, 3);
                return cats.sort((a, b) => a.eqCaFlatTreeDisplayName.localeCompare(b.eqCaFlatTreeDisplayName)).map(x=> new FieldListItem(x.eqCaId, x.eqCaFlatTreeDisplayName, null));
            }

            // Sélectionne les références d'équipements
            const row = table.dataSource.find(x=> x.rowId === rowId);
            if (row) {
                const catField = row.fields.find(x=> x.colId === EquipmentPurchaseDetailTable.eqPuDeEquipmentCategoryId) as ListField;
                if (catField && catField.selectedItem) {
                    const refs = await p.downloadTable<EquipmentCatalog>(TablesNamesEnum.EquipmentCatalog, undefined, EquipmentCatalogTable.eqCgCategoryId, catField.selectedItem.id);
                    return refs.map(x=> new FieldListItem(x.eqCgId, x.eqCgName, null));
                }
            }

            return [];
        }

        table.imageUploadRequested = async (file: File, inserting: boolean, maxSize: number, rowId: number): Promise<string> => {
            const s = Container.get(UploadService);
            if (inserting) {
                return await s.uploadTempFile(file, `${ApiEndpoints.endPoint(ApiEndpoints.fsToAirlock)}`, maxSize);
            } else {
                return await s.uploadUpdateFile(file, `${ApiEndpoints.endPoint(ApiEndpoints.fsProviderLogo)}`, rowId);
            }
        }

        table.newRowSaveRequested = async (values : any): Promise<any> => {
            let result: any;
            values[EquipmentPurchaseDetailTable.eqPuDePurchaseId] = purchaseId;
            const t = Container.get(DyntService);
            result = await t.post<any>("", values);
            return result;
        }

        table.deletionRequested = async (tableName: string, rowId: any): Promise<boolean> =>  {
            let result: boolean = false;
    
            return result;
        }

        const rw = new TableReportWrapper();
        rw.set(table);
        return rw;
    }
}