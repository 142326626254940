import { AppUserTable } from './../../db-model/tables/app-user-table';
import { DefinedModelBase } from '../defined-model-base';

export class AppUser extends DefinedModelBase {
    apUsId!: number;
    apUsDirectoryId!: number;
    apUsCode!: string;
    apUsTitle!: string;
    apUsFirstName!: string;
    apUsLastName!: string;
    apUsRoleId!: number;
    apUsIsActive!: boolean;
    apUsPolicyId!: string;
    apUsPerimeterId!: number | null;
    apUsLastRoute!: string | null

    constructor(dtoData: any, tableDef: any[] = []) {
        super(dtoData, [AppUserTable].concat(tableDef));
    }
}