import { PeopleLocationTable } from '../../db-model/tables/people-location-table';
import { DefinedModelBase } from '../defined-model-base';

export class PeopleLocation extends DefinedModelBase {
    peLoDirectoryId!: number;
    peLoFloorDataId!: number;
    peLoRatio!: number;
    peLoInPattern!: string;
    peLoLocationDate!: Date;

    constructor(dtoData: any, tableDef: any[] = []) {
        super(dtoData, [PeopleLocationTable].concat(tableDef));
    }
}