import { TablesNamesEnum } from './../tables-names-enum';
export const FloorCatalogTable = {
    databaseTableName: TablesNamesEnum.FloorCatalog,
    flCgId: 'FlCg_Id',
    flCgCode: 'FlCg_Code',
    flCgName: 'FlCg_Name',
    flCgDescription: 'FlCg_Description',
    flCgSvgStatement: 'FlCg_SvgStatement',
    flCgFloorModelId: 'FlCg_FloorModelId',
    flCgSvgBoundingBox: 'FlCg_SvgBoundingBox',
    flCgScope: 'FlCg_Scope',
    flCgImageUrl: 'FlCg_ImageUrl'
  };
  