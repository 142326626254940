import { Task } from 'src/app/core/model/data-model/tables/task';
import { TaskStatusDbView } from "src/app/core/model/db-model/views/task-status-db-view";
import { TaskStatusView } from 'src/app/core/model/data-model/views/task-status-view';
import { TaskContributorTabView } from './task-contributor-tab-view';
import { TaskValidation } from 'src/app/core/model/data-model/tables/task-validation';
import { TaskChangeLogView } from 'src/app/core/model/data-model/views/task-change-log-view';
import { TaskTable } from 'src/app/core/model/db-model/tables/task-table';
import { TaskValidationTable } from 'src/app/core/model/db-model/tables/task-validation-table';
import { TaskChangeLogDbView } from 'src/app/core/model/db-model/views/task-change-log-db-view';
import { TaskContributorDbView } from 'src/app/core/model/db-model/views/task-contributor-db-view';

export class TaskDataset {
    taskStatusView: TaskStatusView[] = [];
    taskValidations: TaskValidation[] =[];
    taskContributorView: TaskContributorTabView[] = [];
    tasks: Task[] = [];
    taskChangeLogView: TaskChangeLogView[] = [];

    constructor(dtoData: any) {
        dtoData[TaskStatusDbView.databaseTableName].forEach((e: any) => {
            this.taskStatusView.push(new TaskStatusView(e));
        });

        dtoData[TaskValidationTable.databaseTableName].forEach((e: any) => {
            this.taskValidations.push(new TaskValidation(e));
        });

        dtoData[TaskContributorDbView.databaseTableName].forEach((e: any) => {
            this.taskContributorView.push(new TaskContributorTabView(e));
        });

        dtoData[TaskTable.databaseTableName].forEach((e: any) => {
            this.tasks.push(new Task(e));
        });

        dtoData[TaskChangeLogDbView.databaseTableName].forEach((e: any) => {
            this.taskChangeLogView.push(new TaskChangeLogView(e));
        });
    }
}