import { SvgEntityTypesEnum } from "src/app/core/model/svg-model/svg-entity-type-enum";
import { UpdateGizmo } from "./update-gizmo";
import { Vector } from "src/app/core/model/geometry-model/vector.model";
import { SelectableSvgPath } from "../../svg/selectable-svg-path";
import { SvgEntityPoint } from "../../../shared/gizmos/model/svg-entity-point";
import { Point } from "src/app/core/model/geometry-model/point.model";
import { SvgPathService } from "src/app/core/model/svg-model/svg-path-service";
import { GeometricElementType } from "src/app/core/model/geometry-model/geometric-element-type.model";
import { Segment } from "src/app/core/model/geometry-model/segment.model";
import { Arc } from "src/app/core/model/geometry-model/arc.model";
import { GeometricElement } from "src/app/core/model/geometry-model/geometric-element.model";

export class PathGizmo extends UpdateGizmo<SelectableSvgPath> {
    initialSubs: GeometricElement[] = [];

    constructor(pixelUnit: number) {
        super(pixelUnit, SvgEntityTypesEnum.path);
    }

    initializePointTranslation(p: Point): void {
        if (this.single) { 
            this.initialSubs = SvgPathService.getEntitiesAt(this.single.d, p);
        }
    }

    translateEndpoint(target: SvgEntityPoint | Point, withExtension: boolean): void {
        let tmp = target.clone();
        if (this.single && this.currentGrip) {
            if (withExtension) {
                // si le grip est à l'extrémité du path on calcule l'extension du segment
                // si le grip est à l'intérieur du path on calcule l'extension en fonction de l'un ou l'autre segments
                this.initialSubs.forEach(e => {
                    switch (e.elementType) {
                        case GeometricElementType.Segment:
                            const s = (e as Segment);
                            const opd = tmp.orthogonalDistanceTo(s);
                            if (opd < 0.01) {
                                // Si le pointeur n'est pas trop éloigné de la droite du segment original
                                // on contraint le déplacement en extension
                                tmp = s.getOrthogonalProjection(tmp);
                            }
                            break;
                        case GeometricElementType.Arc:
                            const a = (e as Arc);
                            const c = a.center();
                            // si la distance du pointeur au centre est proche du rayon
                            // on contraint le déplacement en extension
                            const cd = tmp.distanceTo(c);
                            if (Math.abs(a.radius - cd) < Math.min(0.01, a.radius / 10)) {
                                const ps = new Segment(c, tmp);
                                tmp = ps.ajustEnd(a.radius).endPoint; 
                            }
                            break;
                        default:
                            break;
                    }
                });
            }

            this.single.translatePoint(this.currentGrip.point, tmp);
            this.currentGrip.point = tmp;
            this.grips.resetPoints(this.single);
        }
    }

    endEndpointTranslation(): void {
        if (this.single) {
            this.single.updateSelectablePoints();
        }
    }

    translatePaths(delta: Vector): void {
        this.entities.forEach(e => {
            e.translate(delta);
        });
        this.grips.items.forEach(g => {
            g.point = g.point.translate(delta);
        });
    }

    endPathsTranslation(): void {
        this.entities.forEach(e => {
            e.updateSelectablePoints();
            this.grips.resetPoints(e);
        });
    }
}