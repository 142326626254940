import { Component, Input, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserInteraction } from '../../model/interaction/user-interaction';
import { SvgSelectionPathComponent } from 'src/app/components-lib/svg/svg-selection-path/svg-selection-path.component';
import { SvgEntityTypesEnum } from 'src/app/core/model/svg-model/svg-entity-type-enum';
import { UpdateGizmosComponent } from '../../gizmo/view/update-gizmos/update-gizmos.component';
import { OrthoSysAxesGizmoComponent } from '../../gizmo/view/ortho-sys-axes-gizmo/ortho-sys-axes-gizmo.component';
import { CanvasGizmoComponent } from '../../gizmo/view/canvas-gizmo/canvas-gizmo.component';
import { LineInsertGizmoComponent } from '../../gizmo/insert/view/line-insert-gizmo/line-insert-gizmo.component';
import { CircleInsertGizmoComponent } from '../../gizmo/insert/view/circle-insert-gizmo/circle-insert-gizmo.component';
import { EllipseInsertGizmoComponent } from '../../gizmo/insert/view/ellipse-insert-gizmo/ellipse-insert-gizmo.component';
import { PathInsertGizmoComponent } from '../../gizmo/insert/view/path-insert-gizmo/path-insert-gizmo.component';
import { logError } from 'src/app/core/services/logging-service';

@Component({
  selector: 'xc-drawing',
  standalone: true,
  imports: [CommonModule, 
    SvgSelectionPathComponent, 
    UpdateGizmosComponent, 
    LineInsertGizmoComponent,
    CircleInsertGizmoComponent,
    EllipseInsertGizmoComponent,
    PathInsertGizmoComponent,
    OrthoSysAxesGizmoComponent,
    CanvasGizmoComponent
  ],
  templateUrl: './drawing.component.svg',
  styleUrls: ['./drawing.component.scss']
})
export class DrawingComponent implements OnDestroy {
  @Input() model: UserInteraction | undefined;
  SvgEntityTypesEnum = SvgEntityTypesEnum;

  ngAfterViewInit(): void {
    if (this.model) {
      this.model.initializePanZoom();
      this.model.initializeViewboxObserver();
    } else {
      logError("DrawingComponent userInteraction n'a pas été initialisé");
    }
  }

  ngOnDestroy(): void {
    if (this.model) {
      window.removeEventListener('keydown', this.model.kd, true);
      this.model.viewboxObserver?.disconnect();
      this.model = undefined
    } else {
      logError("DrawingComponent - Le document event listener n'a pas pu être supprimé");
    }
  }
}
