import { DefinedModelBase } from '../defined-model-base';

/**
 * 
 * @param dtoData dataset provenant du backend
 * @param t classe du modèle de données
 * @param u classe du modèle de données
 * @returns Objet composite formé des membres de deux classes du modèle de données tel que produit par une vue de la base
 */
export function getView<T extends DefinedModelBase, 
                        U extends DefinedModelBase>(
                            dtoData: any, 
                            t: new (dtoData: any) => T, 
                            u: new (dtoData: any) => U
                            ): T & U;
/**
 * 
 * @param dtoData dataset provenant du backend
 * @param t classe du modèle de données
 * @param u classe du modèle de données
 * @param v classe du modèle de données
 * @returns Objet composite formé des membres de trois classes du modèle de données tel que produit par une vue de la base
 */
export function getView<T extends DefinedModelBase, 
                        U extends DefinedModelBase, 
                        V extends DefinedModelBase>(
                            dtoData: any, 
                            t: new (dtoData: any) => T, 
                            u: new (dtoData: any) => U, 
                            v: new (dtoData: any) => V
                            ): T & U & V;
/**
 * 
 * @param dtoData dataset provenant du backend
 * @param t classe du modèle de données
 * @param u classe du modèle de données
 * @param v classe du modèle de données
 * @param w classe du modèle de données
 * @returns Objet composite formé des membres de quatre classes du modèle de données tel que produit par une vue de la base
 */
export function getView<T extends DefinedModelBase, 
                        U extends DefinedModelBase, 
                        V extends DefinedModelBase,
                        W extends DefinedModelBase>(
                            dtoData: any, 
                            t: new (dtoData: any) => T, 
                            u: new (dtoData: any) => U, 
                            v: new (dtoData: any) => V, 
                            w: new (dtoData: any) => W
                            ): T & U & V & W;
/**
 * 
 * @param dtoData dataset provenant du backend
 * @param t classe du modèle de données
 * @param u classe du modèle de données
 * @param v classe du modèle de données
 * @param w classe du modèle de données
 * @param x classe du modèle de données
 * @returns Objet composite formé des membres de cinq classes du modèle de données tel que produit par une vue de la base
 */
export function getView<T extends DefinedModelBase, 
                        U extends DefinedModelBase, 
                        V extends DefinedModelBase,
                        W extends DefinedModelBase,
                        X extends DefinedModelBase>(
                            dtoData: any, 
                            t: new (dtoData: any) => T, 
                            u: new (dtoData: any) => U, 
                            v: new (dtoData: any) => V, 
                            w: new (dtoData: any) => W, 
                            x: new (dtoData: any) => X
                            ): T & U & V & W & X;
/**
 * 
 * @param dtoData dataset provenant du backend
 * @param t classe du modèle de données
 * @param u classe du modèle de données
 * @param v classe du modèle de données
 * @param w classe du modèle de données
 * @param x classe du modèle de données
 * @param y classe du modèle de données
 * @returns Objet composite formé des membres de six classes du modèle de données tel que produit par une vue de la base
 */
export function getView<T extends DefinedModelBase, 
                        U extends DefinedModelBase, 
                        V extends DefinedModelBase,
                        W extends DefinedModelBase,
                        X extends DefinedModelBase,
                        Y extends DefinedModelBase>(
                            dtoData: any, 
                            t: new (dtoData: any) => T, 
                            u: new (dtoData: any) => U, 
                            v: new (dtoData: any) => V, 
                            w: new (dtoData: any) => W, 
                            x: new (dtoData: any) => X, 
                            y: new (dtoData: any) => Y
                            ): T & U & V & W & X & Y;
/**
 * 
 * @param dtoData dataset provenant du backend
 * @param t classe du modèle de données
 * @param u classe du modèle de données
 * @param v classe du modèle de données
 * @param w classe du modèle de données
 * @param x classe du modèle de données
 * @param y classe du modèle de données
 * @param z classe du modèle de données
 * @returns Objet composite formé des membres de sept classes du modèle de données tel que produit par une vue de la base
 */
export function getView<T extends DefinedModelBase, 
                        U extends DefinedModelBase, 
                        V extends DefinedModelBase,
                        W extends DefinedModelBase,
                        X extends DefinedModelBase,
                        Y extends DefinedModelBase,
                        Z extends DefinedModelBase>(
                            dtoData: any, 
                            t: new (dtoData: any) => T, 
                            u: new (dtoData: any) => U, 
                            v: new (dtoData: any) => V, 
                            w: new (dtoData: any) => W, 
                            x: new (dtoData: any) => X, 
                            y: new (dtoData: any) => Y, 
                            z: new (dtoData: any) => Z
                            ): T & U & V & W & X & Y & Z;

/**
 * 
 * @param dtoData dataset provenant du backend
 * @param t classe du modèle de données
 * @param u classe du modèle de données
 * @param v classe du modèle de données
 * @param w classe du modèle de données
 * @param x classe du modèle de données
 * @param y classe du modèle de données
 * @param z classe du modèle de données
 * @returns Objet composite formé des membres de classes du modèle de données tel que produit par une vue de la base
 */
export function getView<T extends DefinedModelBase, 
                        U extends DefinedModelBase, 
                        V extends DefinedModelBase,
                        W extends DefinedModelBase,
                        X extends DefinedModelBase,
                        Y extends DefinedModelBase,
                        Z extends DefinedModelBase>(
                            dtoData: any, 
                            t: new (dtoData: any) => T, 
                            u: new (dtoData: any) => U, 
                            v?: new (dtoData: any) => V, 
                            w?: new (dtoData: any) => W, 
                            x?: new (dtoData: any) => X, 
                            y?: new (dtoData: any) => Y, 
                            z?: new (dtoData: any) => Z
                            ): (T & U) | 
                                (T & U & V) | 
                                (T & U & V & W) | 
                                (T & U & V & W & X) | 
                                (T & U & V & W & X & Y) | 
                                (T & U & V & W & X & Y & Z) {
    const tt = createInstance<T>(dtoData, t);
    const uu = createInstance<U>(dtoData, u);
    let result = {...tt, ...uu};

    result = mergeResult<V>(result, dtoData, v);
    result = mergeResult<W>(result, dtoData, w);
    result = mergeResult<X>(result, dtoData, x);
    result = mergeResult<Y>(result, dtoData, y);
    result = mergeResult<Z>(result, dtoData, z);

    // if (v) {
    //     const vv = createInstance<V>(dtoData, v);
    //     result = {...result, ...vv};
    // }

    // if (w) {
    //     const ww = createInstance<W>(dtoData, w);
    //     result = {...result, ...ww};
    // }

    // if (x) {
    //     const xx = createInstance<X>(dtoData, x);
    //     result = {...result, ...xx};
    // }

    // if (y) {
    //     const yy = createInstance<Y>(dtoData, y);
    //     result = {...result, ...yy};
    // }

    // if (z) {
    //     const zz = createInstance<Z>(dtoData, z);
    //     result = {...result, ...zz};
    // }

    return result;
}

function createInstance<T extends DefinedModelBase>(dtoData: any, t: new (dtoData: any) => T): T {
    return new t(dtoData);
}

function mergeResult<T extends DefinedModelBase>(result: any, dtoData: any, t?: new (dtoData: any) => T): any {
    if (t) {
        const vv = createInstance<T>(dtoData, t);
        return {...result, ...vv};
    }

    return result;
}
