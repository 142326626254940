import { SvgEntityTypesEnum } from "src/app/core/model/svg-model/svg-entity-type-enum";
import { SelectableSvgEntity } from "../../svg/selectable-svg-entity";
import { SvgDOMBase } from "src/app/core/model/svg-model/abstract/svg-dom-base";
import { Point } from "src/app/core/model/geometry-model/point.model";
import { getEntityBbox } from "../../svg/selectable-entity-builder";
import { Grips } from "../../model/grips/grips";
import { Grip } from "../../model/grips/grip";
import { logError } from "src/app/core/services/logging-service";

export abstract class UpdateGizmo<T extends SelectableSvgEntity> extends SvgDOMBase {
    entityType: SvgEntityTypesEnum;
    entities: T[] = [];
    grips: Grips;
    currentGrip: Grip | undefined;
    currentEntity: T | undefined;
    single: T | undefined;
    singleBbox: SVGRect | undefined;
    
    constructor(pixelUnit: number, entityType: SvgEntityTypesEnum) {
        super("SvgImage", "196b6f40-d974-4028-b12f-7bb0490d6b3c");
        
        this.entityType = entityType;
        this.grips = new Grips("orange", "orange", pixelUnit);
    }

    setSelection(entities: T[]): void {
        this.entities = entities;

        this.loadGrips();

        if (entities.length === 1) {
            this.single = entities[0];
            this.updateSingleBbox(this.single);
        } else {
            this.single = undefined;
            this.singleBbox = undefined;
        }
    }

    loadGrips(): void {
        this.grips.clear();
        this.entities.forEach(e => {
            this.grips.loadFromEntity(e);
        });
    }

    refreshGrips(): void {
        this.grips.clear();
        this.entities.forEach(e => {
            e.updateSelectablePoints();
            this.grips.loadFromEntity(e);
        });
    }

    updateSingleBbox(e: T): void {
        this.singleBbox = getEntityBbox(e.entityId);
    }

    getPoints(): Point[] {
        const result: Point[] = [];

        this.entities.forEach(e => {
            result.push(...e.selectablePoints);
        });

        return result;
    }

    updateSize(size: number): void {
        this.grips.updateSize(size);
    }

    getEntity(grip: Grip): T | undefined {
        let result: T | undefined;
        this.entities.forEach(e => {
            e.selectablePoints.forEach(p => {
                if (p.equals(grip.point)) {
                    result = e;
                }
            });
        });
        return result;
    }

    clearCurrent(): void {
        this.single = undefined;
        this.currentGrip = undefined;
    }

    gripClicked?: (point: Point, grip: Grip, entity: SelectableSvgEntity | undefined) => void;
    gripMouseDown(e: MouseEvent, grip: Grip): void {
        e.stopPropagation();
        if (this.gripClicked) {
            this.currentEntity = this.getEntity(grip);
            this.currentGrip = grip;
            this.gripClicked(new Point(e.clientX, e.clientY), grip, this.currentEntity);
        } else {
            logError("UpdateGizmo.gripMouseDown n'est pas écouté");
        }
    }

    singleClicked?: (e: any) => void;
    singleMouseDown(e: MouseEvent): void {
        e.stopPropagation();
        if (this.singleClicked) {
            this.singleClicked(this.single!);
        } else {
            logError("UpdateGizmo.singleClicked n'est pas écouté");
        }
    }
}
