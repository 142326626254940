import { PolicyCityTable } from '../../../db-model/policy/tables/policy-city-table';
import { DefinedModelBase } from '../../defined-model-base';

export class PolicyCity extends DefinedModelBase {
    id!: number;
    name!: string;
    latitude!: string;
    longitude!: string;
    countryId!: number;

    constructor(dtoData: any) {
        super(dtoData, [PolicyCityTable]);
    }
}