import { Point } from "src/app/core/model/geometry-model/point.model";
import { Segment } from "src/app/core/model/geometry-model/segment.model";
import { SvgTransform } from "src/app/core/model/svg-model/svg-transform.model";
import { PolygonService } from "src/app/core/model/geometry-model/polygon-service";
import { IntersectionService } from "src/app/core/model/geometry-model/intersection-service";
import { GeometricElement } from "src/app/core/model/geometry-model/geometric-element.model";
import { GeometricElementType } from "src/app/core/model/geometry-model/geometric-element-type.model";
import { XcMaths } from "src/app/core/model/static-functions/xc-maths";
import { BpSvgBoundingBox } from "src/app/ui/pages/layout/real-estate/floor-blueprint/content/bp-svg-core-model/bp-svg-bounding-box";

export class GridGizmo {
    displayed: boolean = false;
    hLines: Segment[] = [];
    vLines: Segment[] = [];
    stroke: string = "blue";
    strokeWidth: number = 0.25;
    margin: number = 1;
    transform: SvgTransform;

    constructor(extents: BpSvgBoundingBox) {
        this._extents = extents;
        this.transform = new SvgTransform({rotationAngle: 0, rotationCenter: Point.origin(), translate: Point.origin()});
    }

    private _extents: BpSvgBoundingBox;
    get extents(): BpSvgBoundingBox {
        return this._extents;
    }
    set extents(value: BpSvgBoundingBox) {
        this._extents = value;
        this.draw();
    }

    private _unitLength: number = 1;
    get unitLength(): number {
        return this._unitLength;
    }
    set unitLength(value: number) {
        this._unitLength = value;
        this.draw();
    }

    draw(): void {
        this.hLines.splice(0);
        this.vLines.splice(0);
        const height = this.extents.height();
        const width = this.extents.width();
        for (let i = -this.margin; i < (this.extents.width() + this.margin) / this.unitLength; i++) {
            const x = (this.extents.minX + i * this.unitLength); // + this.offset.u;
            const y1 = this.extents.minY - this.margin; // + this.offset.v;
            const y2 = this.extents.minY + height + this.margin; // + this.offset.v;
            this.vLines.push(new Segment(new Point(x, y1), new Point(x, y2)));
        }
        for (let i = -this.margin; i < (this.extents.height() + this.margin) / this.unitLength; i++) {
            const x1 = this.extents.minX - this.margin; // + this.offset.u;
            const x2 = this.extents.minX + width + this.margin; // + this.offset.u;
            const y = (this.extents.minY + i * this.unitLength); // + this.offset.v;
            this.hLines.push(new Segment(new Point(x1, y), new Point(x2, y)));
        }
    }

    reset(): void {
        this.transform = new SvgTransform({rotationAngle: 0, rotationCenter: Point.origin(), translate: Point.origin()});
    }

    move(p: Point): void {
        const nearestIntersect = this.getNearestIntersect(p);
        if (nearestIntersect) {
            // Défini le vecteur de translation*
            const v = p.minus(nearestIntersect);
            // Applique la transformation
            this.transform = new SvgTransform({rotationAngle: this.transform.rotationAngle, rotationCenter: nearestIntersect, translate: v.toPoint()});
        }
    }

    align(e: GeometricElement): void {
        if (e.elementType === GeometricElementType.Segment) {
            const s = e as Segment;
            const nearestIntersect = this.getNearestIntersect(s.startPoint);
            if (nearestIntersect) {
                const v = s.startPoint.minus(nearestIntersect);
                const a = -XcMaths.toDeg(s.angle());
                // Applique la transformation
                this.transform = new SvgTransform({rotationAngle: a, rotationCenter: nearestIntersect, translate: v.toPoint()});
            }
        }
    }

    /**
     * Recherche le point d'intersection le plus proche du point cliqué passé en argument
     * @param p 
     */
    getNearestIntersect(p: Point): Point | undefined {
        // Défini un réticule un peu plus grand que la taille d'une unité de grille
        const r = PolygonService.centeredSquare(p, this.unitLength * 1.1);
        // Recherche les lignes à l'intérieur du réticule

        const ss: Segment[] = [];
        this.hLines.forEach(hl => {
            const hli = IntersectionService.getRectangleIntersects(hl, r);
            if (hli.length > 0) {
                ss.push(hl);
            } 
        });
        this.vLines.forEach(vl => {
            const vli = IntersectionService.getRectangleIntersects(vl, r);
            if (vli.length > 0) {
                ss.push(vl);
            } 
        });
        // Récupère les intersections entre ces lignes
        const hs = ss.filter(x=> x.startPoint.y === x.endPoint.y);
        const vs = ss.filter(x=> x.startPoint.x === x.endPoint.x);
        const ii: Point[] = [];
        hs.forEach(s1 => {
            vs.forEach(s2 => {
                const i = IntersectionService.getEntitiesIntersects([s1, s2]);
                if (i) {
                    ii.push(...i);
                }
            });
        });
        return Point.getNearest(ii, p);
    }

    /**
     * Recherche le point d'intersection le plus proche du point cliqué passé en argument
     * @param p 
     */
    getTransformedNearestIntersect(p: Point): Point | undefined {
        // Défini un réticule un peu plus grand que la taille d'une unité de grille
        const r = PolygonService.centeredSquare(p, this.unitLength * 1.1);
        // Recherche les lignes à l'intérieur du réticule

        const ss: Segment[] = [];
        const rc = this.transform.rotationCenter;
        const ra = XcMaths.toRad(this.transform.rotationAngle);
        const tr = this.transform.translate;
        this.hLines.forEach(hl => {
            const thl = hl.transform(rc, ra, tr.vector());
            const hli = IntersectionService.getRectangleIntersects(thl, r);
            if (hli.length > 0) {
                ss.push(thl);
            } 
        });
        this.vLines.forEach(vl => {
            const tvl = vl.transform(rc, ra, tr.vector());
            const vli = IntersectionService.getRectangleIntersects(tvl, r);
            if (vli.length > 0) {
                ss.push(tvl);
            } 
        });
        // Récupère les intersections entre ces lignes
        const hs = ss.filter(x=> XcMaths.round(x.angle(), 3) === XcMaths.round(-ra, 3));
        const vs = ss.filter(x=> XcMaths.round(x.angle(), 3) === XcMaths.round(-((Math.PI / 2) + ra), 3));
        const ii: Point[] = [];
        hs.forEach(s1 => {
            vs.forEach(s2 => {
                const i = IntersectionService.getEntitiesIntersects([s1, s2]);
                if (i) {
                    ii.push(...i);
                }
            });
        });
        return Point.getNearest(ii, p);
        // if (ss.length > 0) {
        //     const ii = IntersectionService.getEntitiesIntersects(ss);
        //     if (ii.length > 0) {
        //         // Recherche le point d'intersection le plus proche
        //         return Point.getNearest(ii, p);
        //     }
        // }

        //return undefined;
    }
}