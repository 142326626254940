// Cette vue n'est pas issue de la base mais constituée par le backend
export const RealEstateViewDef = {
    realEstateTableName: 'tableName',
    id: 'id',
    name: 'name',
    parentId: 'parentId',
    latitude: 'latitude',
    longitude: 'longitude',
    isActive: 'isActive',
    isFictive: 'isFictive',
    thumbnailDocumentId: 'thumbnailDocumentId',
    children: 'Children'
  };