import { FloorModelUserOptionTable } from "../../db-model/tables/floor-model-user-option-table";
import { DefinedModelBase } from "../defined-model-base";

export class FloorModelUserOption extends DefinedModelBase {
    flMoUsOpUserId!: number;
    flMoUsOpFloorModelId!: number;
    flMoUsOpColor!: string;
    flMoUsOpDisplayed!: boolean;
    flMoUsOpLazyLoad!: boolean;

    constructor(dtoData: any, tableDef: any[] = []) {
        super(dtoData, [FloorModelUserOptionTable].concat(tableDef));
    }
}