import { AppMenuRouteEnum } from "src/app/core/model/data-model/enums/app-menu-route-enum";
import { PageModel } from "../../main/model/page-model";
import { readableUUID } from "src/app/core/events/event-listener-uuid";
import { Token, marked } from "marked";
import Container from "typedi";
import { LanguageCode } from "src/app/core/services/culture-service";
import { FsService } from "src/app/core/services/backend-services/fs/fs-service";
import { DocService } from "src/app/core/services/backend-services/doc/doc-service";
import { Contents } from "src/app/core/model/db-model/doc/tables/contents";
import { TreeNode } from "primeng/api";
import { MarkedRenderer } from "ngx-markdown";
import { toastWarn } from "src/app/core/services/toast-service";

export class WelcomeScreenVM extends PageModel {
    // https://marked.js.org/using_pro#walk-tokens

    docFolder: string = "/var/fs/help/playground";
    contentsData: Contents[] = [];
    contents: TreeNode<Contents>[] = [];
    flatContents: TreeNode<Contents>[] = [];
    selectedContent: TreeNode<Contents> | null | undefined = null;
    document: string | undefined;
    previousEnable: boolean = false;
    nextEnable: boolean = false;
    
    private constructor() {
        super(AppMenuRouteEnum.welcome, 0, readableUUID(WelcomeScreenVM.name));

        const myRenderer = new MarkedRenderer();
        myRenderer.link = (href: string, title: string | null | undefined, text: string) => {
            // le renderer transforme les liens internes en ajoutant une classe qui permettra ensuite de
            // récupérer les liens dans le DOM
            // et il déplace le href qui contient le nom du fichier cible vers un attribut [target]
            return '<a class="doc-link" [target]="' + href + '" href="#">' + text + '</a>';
        };
       
        const lc = LanguageCode();
        this.docFolder = `${this.docFolder}/${lc}`;

        const walkTokens = async (token: Token) => {
            // le parser télécharge les images et transforme l'url d'origine en url locale
            if (token.type === 'image') {
              try {
                const s = Container.get(FsService);
                const href = await s.getBlobUrl(this.docFolder, token.href);
                token.href = (await fetch(href)).url;
              } catch (ex) {
                token.title = 'invalid';
              }
            }
          };
          
          marked.use({ walkTokens, async: true }, { renderer: myRenderer });
    }

    static async newAsync(): Promise<WelcomeScreenVM> {
        const tmp = new WelcomeScreenVM();
        await tmp.loadContents();
        tmp.selectedContent = tmp.contents.find(x=> x.data?.fileName === "welcome.md");
        await tmp.loadDocument("welcome.md");
        return tmp;
    }

    async loadContents(): Promise<void> {
        const s = Container.get(DocService);
        const data = await s.getContents();
        this.contentsData = data;
        const roots = data.filter(x=> x.parentId == null);
        roots.forEach(e => {
            const newEntry = {label: e.name, data: e};
            this.contents.push(newEntry);
            this.flatContents.push(newEntry);
            this.loadChildren(data, newEntry);
        });
    }

    loadChildren(data: Contents[], parent: TreeNode): void {
        const children = data.filter(x=> x.parentId === parent.data.id);
        if (children.length > 0) {
            parent.children = [];
            children.forEach(c => {
                const newEntry = {label: c.name, data: c};
                this.flatContents.push(newEntry);
                parent.children?.push(newEntry);
            });
        }
    }

    async loadDocument(fileName: string): Promise<void> {
        // on supprime les éventuels listeners précédemment créés
        this.removeLinkClickListeners();

        const s = Container.get(FsService);
        const d = await s.getDocument(this.docFolder, fileName);
        if (d !== null) {
          this.document = await marked.parse(d, {async: true});
          this.addLinkClickListeners();

          const data = this.contentsData.find(x=> x.fileName === fileName);
          if (data) {
            this.previousEnable = data.previousId != null;
            this.nextEnable = data.nextId != null;
          }
        }
    }

    getDocumentLinks(): HTMLCollectionOf<Element> {
        return document.getElementsByClassName("doc-link");
    }

    addLinkClickListeners(): void {
        // on utilise un timeout pour rejeter en queue de traitements asynchrones
        // la récupération des liens créés dans le document
        // de façon à ce qu'elle se produise une fois le document chargé dans le DOM
        setTimeout(() => {
            const links = this.getDocumentLinks();
            for (let i = 0; i < links.length; i++) {
                const l = links[i];
                const fileName = l.getAttribute('[target]');
                // ajoute un event listener sur chaque lien du document
                if (fileName != null) {
                    l.addEventListener('click', this.linkClick.bind(l, this, fileName));
                }
            }
        }, 0);
    }

    removeLinkClickListeners(): void {
        const links = this.getDocumentLinks();
        for (let i = 0; i < links.length; i++) {
            const l = links[i];
            // ajoute un event listener sur chaque lien du document
            l.removeEventListener('click', this.linkClick.bind(l, this, ""));
        }
    }
    
    async selectedContentChange(): Promise<void> {
        if (!this.selectedContent?.data) return;
        await this.loadDocument(this.selectedContent.data.fileName);
    }

    async linkClick(that: WelcomeScreenVM, target: string): Promise<void> {
        if (target.endsWith(".md")) {
            // la cible du lien est un autre document
            // le fichier doit exister parmi les items de documentation
            const data = that.contentsData.find(x=> x.fileName === target);
            if (data == undefined) {
                that.previousEnable = false;
                that.nextEnable = false;
                toastWarn("Rubrique introuvable, désolé 😕", "Attention !");
                return;
            }
            await that.loadDocument(target);
        } else {
            // la cible du lien est une page de l'application
        }
    }

    async previousButtonClick(): Promise<void> {
        const data = this.contentsData.find(x=> x.id === this.selectedContent?.data?.previousId);
        if (data) {
            await this.loadDocument(data.fileName);
            this.selectedContent = this.flatContents.find(x=> x.data?.fileName === data.fileName);
        }
    }

    async nextButtonClick(): Promise<void> {
        const data = this.contentsData.find(x=> x.id === this.selectedContent?.data?.nextId);
        if (data) {
            await this.loadDocument(data.fileName);
            this.selectedContent = this.flatContents.find(x=> x.data?.fileName === data.fileName);
        }
    }
}