@if (model) {
    <div class="flex">
        <p-dropdown 
            [options]="model.sites" 
            optionLabel="name"
            (onChange)="model.selectedSiteChange($event)"/>
        <div class="flex flex-wrap gap-3 ml-3">
            @for (option of model.options; track $index) {
                <div class="field-checkbox mb-0">
                    <p-radioButton 
                        [inputId]="option.id" 
                        name="label" 
                        [value]="option" 
                        [(ngModel)]="model.selectedOption"
                        (onClick)="model.optionChange($event)"/>
                    <label [for]="option.id" class="ml-2">
                        {{ option.label }}
                    </label>
                </div>
            }
        </div>
    </div>
}