import { MaterialModule } from 'src/app/core/material.module';
import { AfterViewInit, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GrantTypeEnum } from 'src/app/core/model/data-model/enums/grant-type-enum';
import { MatDialog } from '@angular/material/dialog';
import { InventoryTreeModel } from '../../model/inventory-tree-model';
import { logError } from 'src/app/core/services/logging-service';

@Component({
  selector: 'xc-inventory-tree',
  standalone: true,
  imports: [CommonModule, MaterialModule],
  templateUrl: './inventory-tree.component.html',
  styleUrls: ['./inventory-tree.component.scss']
})
export class InventoryTreeComponent implements AfterViewInit {
  @Input() model: InventoryTreeModel | undefined;
  public GrantTypeEnum = GrantTypeEnum;

  constructor(protected dialog: MatDialog){}

  ngAfterViewInit(): void {
    // Injecte le matDialog dans le modèle
    if (this.model) {
      this.model.dialog = this.dialog;
    } else {
      logError("InventoryTreeComponent : model.dialog n'a pas pu être initialisé");
    }
  }
}
