import { FloorModelView } from "src/app/core/model/data-model/views/floor-model-view";
import { GrantedLayer } from "../../../layers-grants/model/granted-layer";

export class RoleGrantedLayer extends GrantedLayer {
    isRoleReadGranted: boolean = false;
    isRoleUpdateGranted: boolean = false;

    constructor(layer: FloorModelView) {
        super(layer);
    }

    setRoleGrants(grants: number[]): void {
        this.isRoleReadGranted = this.layer.flMoReadGrantId != null && grants.includes(this.layer.flMoReadGrantId);
        this.isRoleUpdateGranted = this.layer.flMoUpdateGrantId != null && grants.includes(this.layer.flMoUpdateGrantId);
    }
}