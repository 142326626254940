import { EventListener } from "src/app/core/events/event-listener";
import { BoardToolbarCommandEnum } from "./board-toolbar-command-enum";
import { BoardToolbarVM } from "./board-toolbar-vm";
import { UserInteraction } from "./interaction/user-interaction";
import { readableUUID } from "src/app/core/events/event-listener-uuid";
import { PictoBrowserEventsEnum } from "./picto-browser-events-enum";

export class DrawingBoardVM extends EventListener {
    userInteraction: UserInteraction;
    boardToolbar: BoardToolbarVM;

    constructor() {
        super(readableUUID(DrawingBoardVM.name));

        this.addEventListener(PictoBrowserEventsEnum.selectedPictoChange, async (id: number) => {
            await this.userInteraction.loadPicto(id);
        });

        this.userInteraction = new UserInteraction();
        this.boardToolbar = new BoardToolbarVM();

        this.boardToolbar.selectedMenuChanged = (selectedCommand: BoardToolbarCommandEnum) => {
            this.userInteraction.setCommand(selectedCommand);
        }

        this.userInteraction.abortCommandRequested = () => {
            this.boardToolbar.setCommand(BoardToolbarCommandEnum.none);
        }
    }
}