import { TaskFunctionEnum } from 'src/app/core/model/data-model/enums/task-function-enum';
import { TaskValidation } from 'src/app/core/model/data-model/tables/task-validation';
import { TaskContributorView } from 'src/app/core/model/data-model/views/task-contributor-view';

export class TaskContributorTabView extends TaskContributorView {
    hasValidationFunction: boolean = false;
    // Le contributeur peut valider une étude même s'il n'a pas la fonction 'Validation'
    // s'il n'y a pas de valideur sur l'étude
    // La propriété 'hasDefaultValidationFunction' prend le pas sur la propriété 'hasValidationFunction'
    hasDefaultValidationFunction: boolean = false;
    validation: TaskValidation | undefined;

    constructor(dtoData: any) {
        super(dtoData);
        // Le contributeur peut valider une étude s'il a la fonction 'Validation'
        this.hasValidationFunction = this.taCrFunctionId === TaskFunctionEnum.Validation;
    }
}