import { lastValueFrom } from 'rxjs';
import { HttpClient, HttpEventType, HttpParams, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ApiService } from 'src/app/core/services/api-service';
import { ApiData } from '../api-data';
import { Service } from 'typedi';
import { ApiResponseCodeEnum } from '../api-response-code-enum';

@Injectable({
    providedIn: 'root'
})
@Service({ global: true })  
export class UploadService extends ApiService {
  constructor() {
      super();
  }

  async uploadUpdateFile(file: File, uploadUrl: string, itemId: number): Promise<string> {
    const formData: FormData = new FormData();
    formData.append('file', file, `${crypto.randomUUID()}.${file.name.split('.').pop()}`);
    formData.append('itemId', itemId.toString());

    const headers = this.getCompanyIdHeader();

    const req = new HttpRequest('POST', uploadUrl, formData, { headers,
      reportProgress: false
    });
    const response = (await lastValueFrom(this.http.request(req))) as HttpResponse<ApiData<string>>;
    if (response.status !== 200) return "";
    if (response.body?.statusCode !== ApiResponseCodeEnum.success) return "";

    return response.body.payload;
  }

  async uploadTempFile(file: File, uploadUrl: string, fileMaxSize: number): Promise<string> {
    const formData: FormData = new FormData();
    formData.append('file', file, `${crypto.randomUUID()}.${file.name.split('.').pop()}`);
    formData.append('maxSize', fileMaxSize.toString());

    const headers = this.getCompanyIdHeader();

    const req = new HttpRequest('POST', uploadUrl, formData, { headers,
      reportProgress: false
    });
    const response = (await lastValueFrom(this.http.request(req))) as HttpResponse<ApiData<string>>;
    if (response.status !== 200) return "";
    if (response.body?.statusCode !== ApiResponseCodeEnum.success) return "";

    return response.body.payload;
  }

  public upload(files: Set<File>, uploadUrl: string, fileMaxSize: number, fileName: string): { [key: string]: { progress: Observable<number>, response: Observable<ApiData<any>> } } {
    // this will be the our resulting map
    const status: { [key: string]: { progress: Observable<number>, response: Observable<ApiData<any>> } } = {};

    files.forEach(file => {
      // create a new multipart-form for every file
      const formData: FormData = new FormData();
      formData.append('file', file, file.name);
      formData.append('maxSize', fileMaxSize.toString());
      formData.append('fileName', fileName);

      const headers = this.getCompanyIdHeader();

      // create a http-post request and pass the form
      // tell it to report the upload progress
      const req = new HttpRequest('POST', uploadUrl, formData, { headers,
        reportProgress: true
      });

      // create a new progress-subject for every file
      const progress = new Subject<number>();

      // create a new response-subject for every file
      const response = new Subject<ApiData<any>>();

      // send the http-request and subscribe for progress-updates
      this.http.request(req).subscribe((event) => {
        if (event.type === HttpEventType.UploadProgress) {

          // calculate the progress percentage
          const percentDone = Math.round(100 * event.loaded / event.total!);

          // pass the percentage into the progress-stream
          progress.next(percentDone);
        } else if (event instanceof HttpResponse) {
          const data = event.body as ApiData<any>;
          response.next(data);
          if (event.ok) {
            // message could be null
            if (data.message) {
              if (Array.isArray(data.message)) {
                console.log(data.message);
              } else {
                //this.snackBarService.displayError(data.message);
              }
            }
          } else {
            if (data.message) {
              if (Array.isArray(data.message)) {
                console.log(data.message);
              } else {
                //this.snackBarService.displayError(data.message);
              }
            }
          }

        // Close the progress-stream if we get an answer form the API
        // The upload is complete
        response.complete();
        progress.complete();
      }
    });

    // Save every progress-observable in a map of all observables
    status[file.name] = {
      progress: progress.asObservable(),
      response: response.asObservable(),
    };
  });

  // return the map of progress.observables
  return status;
  }
}