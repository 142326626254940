import { FormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { InputTextModule } from 'primeng/inputtext';
import { InputNumberModule } from 'primeng/inputnumber';
import { TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';
import { DynTableVM } from './../dyn-grid/model/dyn-table-vm';
import { Component, Input } from '@angular/core';
import { ColorPickerModule } from 'primeng/colorpicker';
import { DropdownModule } from 'primeng/dropdown';
import { CalendarModule } from 'primeng/calendar';
import { DynImageFileCellComponent } from '../dyn-grid/dyn-image-file-cell/dyn-image-file-cell.component';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'xc-dyn-table',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule, 
    TableModule, 
    CheckboxModule, 
    ColorPickerModule, 
    ButtonModule, 
    InputTextModule, 
    InputNumberModule,
    TooltipModule, 
    DropdownModule,
    CalendarModule,
    DynImageFileCellComponent],
  templateUrl: './dyn-table.component.html',
  styleUrl: './dyn-table.component.scss'
})
export class DynTableComponent {
  @Input() model: DynTableVM | undefined;
}
