import { GeometricElementType } from "src/app/core/model/geometry-model/geometric-element-type.model";
import { Segment } from "src/app/core/model/geometry-model/segment.model";
import { SvgEntityTypesEnum } from "src/app/core/model/svg-model/svg-entity-type-enum";
import { BpSvgGroup } from "../../../bp-svg-core-model/bp-svg-group";
import { BpSvgLine } from "../../../bp-svg-core-model/bp-svg-line";
import { BpSvgPath } from "../../../bp-svg-core-model/bp-svg-path";
import { BlueprintLayer } from "./blueprint-layer";

export class BlueprintCoreContoursLayer extends BlueprintLayer<BpSvgGroup> {
    segments: Segment[] = [];

    constructor(dtoData: any, taskId: number) {
        super(dtoData, taskId);
        // La géométrie est fournie par le backend
        this.getSegments();
    }

    getSegments(): void {
        this.segments = [];
        // Ce calque contient un seul groupe
        // qui contient lui-même les paths ou lines de contours non simulables
        const group = this.data[0] as BpSvgGroup;
        group.entities.forEach(c => {
            switch (c.entityType) {
                case SvgEntityTypesEnum.path:
                    const g = (c as BpSvgPath).geometry;
                    g.forEach(element => {
                        if (element.elementType === GeometricElementType.Segment) {
                            const newSegment = Segment.fromDto(element);
                            if (newSegment) {
                                this.segments.push(newSegment);
                            }
                        }
                    });
                    break;
                case SvgEntityTypesEnum.line:
                    const l = c as BpSvgLine;
                    this.segments.push(l.segment());
                    break;
                default:
                    break;
            }
        });
    }
}