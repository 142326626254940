import { DropdownChangeEvent } from "primeng/dropdown";
import { Site } from "src/app/core/model/data-model/tables/site";
import { TablesNamesEnum } from "src/app/core/model/db-model/tables-names-enum";
import { DyntService } from "src/app/core/services/backend-services/dynt-service";
import { NestedToolbarBase } from "src/app/ui/main/model/nested-toolbar-base";
import { NestedToolbarTypeEnum } from "src/app/ui/main/model/nested-toolbar-type-enum";
import Container from "typedi";
import { SitesEventsEnum } from "./sites-events-enum";
import { RealEstateChartTypeEnum } from "../charts/shared-model/real-estate-chart-type-enum";
import { RadioButtonClickEvent } from "primeng/radiobutton";

export class SitesToolbarVM extends NestedToolbarBase {
    sites: {id: number, name: string}[] = [];
    options: {id: string, label: string}[] = [];
    selectedOption: {id: string, label: string} | undefined;

    private constructor() {
        super(NestedToolbarTypeEnum.sites);

        this.options.push({id: RealEstateChartTypeEnum.anatomy, label: "Anatomie"});
        this.options.push({id: RealEstateChartTypeEnum.activities, label: "Activités"});
        this.options.push({id: RealEstateChartTypeEnum.attributions, label: "Attributions"});
        this.options.push({id: RealEstateChartTypeEnum.inventory, label: "Inventaire"});
        this.options.push({id: RealEstateChartTypeEnum.referential, label: "Référentiel"});

        this.selectedOption = this.options[0];
    }

    static async newAsync(): Promise<SitesToolbarVM> {
        const tmp = new SitesToolbarVM();
        await tmp.loadSites();
        tmp.emitEventAsync(SitesEventsEnum.selecteSiteChange, tmp.sites[0]);
        tmp.emitEventAsync(SitesEventsEnum.selectedOptionChange, tmp.selectedOption);
        return tmp;
    }

    async loadSites(): Promise<void> {
        const s = Container.get(DyntService);
        this.sites = [{id: 0, name: "Tous"}].concat((await s.downloadTable<Site>(TablesNamesEnum.Site)).map(x=> ({id: x.stId, name: x.stName})));
    }

    async selectedSiteChange(e: DropdownChangeEvent): Promise<void> {
        this.emitEventAsync(SitesEventsEnum.selecteSiteChange, e.value);
    }

    async optionChange(e: RadioButtonClickEvent): Promise<void> {
        this.emitEventAsync(SitesEventsEnum.selectedOptionChange, e.value);
    }

}