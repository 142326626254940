import { TaskStatusEnum } from "../data-model/enums/task-status-enum";
import { TaskStatus } from "../data-model/tables/task-status";

export class TaskWorkflowProcessing {
    static actionName(statusId: number): string {
        switch (statusId) {
            case TaskStatusEnum.Canceled:
                return "Annuler";
            // case TaskStatusEnum.Idling:
            //     return "Mettre en veille";
            case TaskStatusEnum.Pending:
                return "Réinitialiser";
            case TaskStatusEnum.Started:
                return "Démarrer";
            case TaskStatusEnum.Validating:
                return "Demander la validation";
            case TaskStatusEnum.Closed:
                return "Clôturer";
            case TaskStatusEnum.Ended:
                return "Terminer";
            default:
                throw "TaskStatus : cas non géré";
        }

    }

    // NOTA : un projet est commencé lorsqu'une de ses tâches est commencée
    // Un projet est non commencé si toutes ses tâches sont non commencées
    // Un projet est annulé si toutes les tâches le sont
    // On peut annuler un projet. Dans ce cas toutes les tâches sont annulées
    // On peut réouvrir un projet qui a été annulé
    // La clôture et la terminaison doivent être effectuées par une commande dédiée après contrôle de l'état des tâches filles
    static nextAvailableStatus<T extends TaskStatus>(status: T[], currentStatusId: number, forProject: boolean): T[] {
        let result: T[] = [];
        // NOTA : le statut 'Validating' n'est pas accessible ici
        // La validation s'applique aux études sur plan et ne peut être faite que sur l'étude affichée
        switch (currentStatusId) {
            case TaskStatusEnum.Started:
                // La tâche est démarrée
                result = status.filter(x=> x.taStId === TaskStatusEnum.Canceled || x.taStId === TaskStatusEnum.Pending || x.taStId === TaskStatusEnum.Ended || x.taStId === TaskStatusEnum.Validating);
                break;
            case TaskStatusEnum.Canceled:
                // La tâche est annulée
                result = status.filter(x=> x.taStId === TaskStatusEnum.Pending || x.taStId === TaskStatusEnum.Started);
                break;
            case TaskStatusEnum.Pending:
                // La tâche n'est pas encore démarrée
                result = status.filter(x=> x.taStId === TaskStatusEnum.Canceled || x.taStId === TaskStatusEnum.Started);
                break;
            case TaskStatusEnum.Idling:
                // La tâche est en veille
                // TODO : Obsolete - à supprimer
                //result = status.filter(x=> x.taStId === TaskStatusEnum.Canceled || x.taStId === TaskStatusEnum.Pending || x.taStId === TaskStatusEnum.Started);
                break;
            case TaskStatusEnum.Validating:
                // La tâche doit être validée
                // le responsable de la tâche l'a considérée comme terminée et a demandé la validation
                // la validation peut être rejetée et la tâche remise en activité
                result = status.filter(x=> x.taStId === TaskStatusEnum.Started);
                break;
            case TaskStatusEnum.Closed:
                // La tâche est clôturée
                // c'est le dernier statut en termes de workflow, toutes les actions attendues sur le gantt ont été réalisées
                // un projet clôturé disparaît du gantt courant
                // on peut juste revenir au statut précédent si clôturé par erreur
                result = status.filter(x=> x.taStId === TaskStatusEnum.Ended);
                break;
            case TaskStatusEnum.Ended:
                // La tâche est terminée
                // c'est l'avant-dernier statut en termes de workflow
                // il peut rester des traitements hors gantt à effectuer par d'autres services avant clôture
                // on peut revenir à un statut actif si terminé par erreur
                result = status.filter(x=> x.taStId === TaskStatusEnum.Started);
                break;
            default:
                break;
        }

        if (forProject) {
            result = result.filter(x=> x.taStId !== TaskStatusEnum.Started && 
                x.taStId !== TaskStatusEnum.Closed && 
                x.taStId !== TaskStatusEnum.Ended && 
                x.taStId !== TaskStatusEnum.Validating);
        }
        return result;
    }
}