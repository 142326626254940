import { Point } from "src/app/core/model/geometry-model/point.model";
import { GripPath } from "./grip-path";
import { SvgTransform } from "src/app/core/model/svg-model/svg-transform.model";

export class Grip extends GripPath {
    index: number = 0;
    parentId: string | number | undefined;
    isSelected: boolean = false;
    static defaultColor: string = "orange";
    static selectedColor: string = "green";

    constructor(data: any, radius: number, parentId?: string | number) {
        super(data, radius);
        this.parentId = parentId;
    }

    select(selected: boolean): void {
        this.isSelected = selected;
    }

    update(p: Point, a: number): void {
        this.point = p;
        this.transform = new SvgTransform({rotationAngle: a, rotationCenter: p});
    }
}