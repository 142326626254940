import { ZDbTableTable } from '../../db-model/tables/z-db-table-table';
import { DefinedModelBase } from '../defined-model-base';

export class ZDbTable extends DefinedModelBase {
    dbTaTableName!: string;
    dbTaTableDisplayName!: number;
    dbTaCategoryId!: number;
    dbTaScope!: number;
    dbTaHasIdentityId!: boolean;
    dbTaTableTypeId!: number;
    dbTaIsHierarchical!: boolean;
    dbTaInsertGrantId!: number | null;
    dbTaDeleteGrantId!: number | null;
    dbTaUpdateGrantId!: number | null;
    dbTaReadGrantId!: number | null;

    constructor(dtoData: any, tableDef: any[] = []) {
        super(dtoData, [ZDbTableTable].concat(tableDef));
    }
}