import { XcMaths } from "src/app/core/model/static-functions/xc-maths";
import { GanttRootProjectContainerVM } from "../../shared/model/gantt-root-project-container-vm";
import { GanttSvgTaskVM } from "../../shared/model/gantt-svg-task-vm";

export class TaskMovingProcess {
    rootProject: GanttRootProjectContainerVM | undefined;
    task: GanttSvgTaskVM | undefined;
    parent: GanttSvgTaskVM | undefined;
    processing: boolean = false;
    initialXPosition: number | undefined;
    todayXOffset: number = 0;
    previousPos: number = Number.EPSILON;

    initializeProcess(rootProject: GanttRootProjectContainerVM, task: GanttSvgTaskVM, initialXPosition: number, todayXOffset: number): void {
        this.rootProject = rootProject;
        this.task = task;
        this.initialXPosition = initialXPosition;
        this.previousPos = this.initialXPosition;
        this.processing = true;
        this.todayXOffset = todayXOffset;

        if (this.task.task.taParentId != null && this.task.task.taParentId !== this.rootProject.svgRootProject.task.taParentId) {
            this.parent = this.rootProject.svgRootProject.find(this.task.task.taParentId);
        }
    }

    validate(): number | undefined {
        if (!this.task) return undefined;
        // Déplace les tâches modifiées sur la position correspondant à un jour entier
        const rootId = this.rootProject?.svgRootProject.task.taId;
        this.rootProject?.validate(this.task);
        this.cleanMove();
        return rootId;
    }

    cleanMove(): void {
        this.rootProject = undefined;
        this.task = undefined;
        this.initialXPosition = undefined;
        this.processing = false;
    }

    abort(): void {
        if (!this.task) return;
        // Remet la tâche à sa position de départ
        this.rootProject?.reset();
        this.cleanMove();
    }

    getDirection(x: number): number {
        if (x ===  this.previousPos) return 0;
        if (x <  this.previousPos) return -1;
        return 1;
    }

    move(x: number): void {
        if (this.initialXPosition && this.task && !this.rootProject?.moving) {
            const delta = XcMaths.round(x - this.initialXPosition, 0);

            const direction = this.getDirection(x);

            this.rootProject?.move(this.task, delta, direction);
            this.previousPos = x;
        }
    }
}