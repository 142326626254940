import { Point } from "src/app/core/model/geometry-model/point.model";
import { SelectableSvgEntity } from "../../svg/selectable-svg-entity";
import { Grip } from "../grips/grip";

export class InteractionCommand {
    static noneCommand = "None";

    static selectingSingleItemCommand = "Sélection unitaire";
    static unSelectingSingleItemCommand = "Déselection unitaire";
    static drawingRectangularSelectionCommand = "Tracé d'un rectangle de sélection";

    static lineEndpointTranslationCommand = "Déplacement de l'extrémité d'une ligne";
    static pathPointTranslationCommand = "Déplacement d'un point d'une polyligne";

    static circleRadiusChangeCommand = "Modification du rayon d'un cercle";
    static ellipseRadiusChangeCommand = "Modification du rayon d'une ellipse";
    
    static entityTranslationCommand = "Déplacement d'un élément";

    static lineInsertionCommand = "Insertion d'une ligne";
    static pathInsertionCommand = "Insertion d'une polyligne";
    static circleInsertionCommand = "Insertion d'un cercle";
    static ellipseInsertionCommand = "Insertion d'une ellipse";

    static gridTranslationCommand = "Déplacement de la grille";
    static gridRotationCommand = "Rotation de la grille";
    static gridUnitLengthCommand = "Taille unitaire de la grille";

    gripClicked: Grip | undefined;
    initialgripClickedPosition: Point | undefined;
    entityClicked: SelectableSvgEntity | undefined;
    selectedGripsOptions: number[] = [];

    private name: string = InteractionCommand.noneCommand;
    initialSvgMousePosition: Point | undefined;
    tempSvgMousePosition: Point | undefined;
    magnetAttraction: number = 0;

    /**
     * Vide la commande en cours (name, tempSvgMousePosition et magnetAttraction)
     */
    clear(): void {
        this.name = InteractionCommand.noneCommand;
    }

    set(commandName: string): void {
        this.name = commandName;
    }

    isCommand(name: string): boolean {
        return this.name === name;
    }

    isNoneCommand(): boolean {
        return this.name === InteractionCommand.noneCommand;
    }


    isSelectingSingleItemCommand(): boolean {
        return this.isCommand(InteractionCommand.selectingSingleItemCommand);
    }

    isUnselectingSingleItemCommand(): boolean {
        return this.isCommand(InteractionCommand.unSelectingSingleItemCommand);
    }

    isCircleRadiusChangeCommand(): boolean {
        return this.isCommand(InteractionCommand.circleRadiusChangeCommand);
    }

    isEllipseRadiusChangeCommand(): boolean {
        return this.isCommand(InteractionCommand.ellipseRadiusChangeCommand);
    }

    isDrawingRectangularSelectionCommand(): boolean {
        return this.isCommand(InteractionCommand.drawingRectangularSelectionCommand);
    }

    isLineEndpointTranslationCommand(): boolean {
        return this.isCommand(InteractionCommand.lineEndpointTranslationCommand);
    }

    isPathPointTranslationCommand(): boolean {
        return this.isCommand(InteractionCommand.pathPointTranslationCommand);
    }

    isEntityTranslationCommand(): boolean {
        return this.isCommand(InteractionCommand.entityTranslationCommand);
    }

    isLineInsertionCommand(): boolean {
        return this.isCommand(InteractionCommand.lineInsertionCommand);
    }

    isPathInsertionCommand(): boolean {
        return this.isCommand(InteractionCommand.pathInsertionCommand);
    }

    isCircleInsertionCommand(): boolean {
        return this.isCommand(InteractionCommand.circleInsertionCommand) ||
        this.isCommand(InteractionCommand.circleRadiusChangeCommand);
    }

    isEllipseInsertionCommand(): boolean {
        return this.isCommand(InteractionCommand.ellipseInsertionCommand);
    }

    isGridTranslationCommand(): boolean {
        return this.isCommand(InteractionCommand.gridTranslationCommand);
    }

    isGridRotationCommand(): boolean {
        return this.isCommand(InteractionCommand.gridRotationCommand);
    }

    isGridUnitLengthCommand(): boolean {
        return this.isCommand(InteractionCommand.gridUnitLengthCommand);
    }

    isSelectionCommand(): boolean {
        return this.isDrawingRectangularSelectionCommand() ||
        this.isSelectingSingleItemCommand() ||
        this.isUnselectingSingleItemCommand();
    }

    isInsertionCommand(): boolean {
        return this.isLineInsertionCommand() ||
        this.isPathInsertionCommand() ||
        this.isCircleInsertionCommand() ||
        this.isEllipseInsertionCommand()
    }

    isGridCommand(): boolean {
        return this.isGridTranslationCommand() ||
        this.isGridRotationCommand() ||
        this.isGridUnitLengthCommand();
    }
}