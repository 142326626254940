import { Component, Input } from '@angular/core';
import { LineGizmo } from '../../model/line-gizmo';

@Component({
  selector: 'g[xc-picto-line-gizmo]',
  standalone: true,
  imports: [],
  templateUrl: './line-gizmo.component.svg',
  styleUrl: './line-gizmo.component.scss'
})
export class LineGizmoComponent {
  @Input() model: LineGizmo | undefined;
}
