import { IntersectedSegment } from "src/app/core/model/geometry-model/intersected-segment";
import { Point } from "src/app/core/model/geometry-model/point.model";
import { PolygonService } from "src/app/core/model/geometry-model/polygon-service";
import { BlueprintLayer } from "../../../../svg-entities/model/layers/blueprint-layer";
import { WallInteraction } from "./wall-interaction";
import { XcMaths } from "src/app/core/model/static-functions/xc-maths";
import { SvgTransform } from "src/app/core/model/svg-model/svg-transform.model";
import { BpSvgWall } from "../../../../svg-entities/model/bp-svg-wall";
import { DoorInteraction } from "./door-interaction";
import { Rectangle } from "src/app/core/model/geometry-model/rectangle.model";
import { Segment } from "src/app/core/model/geometry-model/segment.model";

export class PartitioningDetection {
    
    // Cette méthode est appelée par le callback du timer de détection d'accrochage à la trame de cloisonnement
    static detectPartitioningFrame(svgHitPoint: Point, wallInteraction: WallInteraction): void {
        if (wallInteraction.partitioningFrameLayer == null || wallInteraction.currentCommand.initialSvgMousePosition === undefined) return;
        // Défini le réticule de détection
        const reticle = PolygonService.centeredSquare(svgHitPoint, 0.15);

        // Le réticule détecte une trame de cloisonnement
        const partitioningFrameSegments = wallInteraction.partitioningFrameLayer.segments;
        const partitioningIntersects = BlueprintLayer.getIntersects(reticle, partitioningFrameSegments, wallInteraction.partitioningFrameLayer.id, wallInteraction.partitioningFrameLayer.taskId);
        if (partitioningIntersects.length > 0) {
            // Le segment est placé sur la ligne de trame
            const s = partitioningIntersects[0].segment;
            const orthoProj = s.getOrthogonalProjection(svgHitPoint);
            const nearestIntersect = IntersectedSegment.getNearest(partitioningIntersects, svgHitPoint);
            wallInteraction.updateGizmo.translateWall(wallInteraction.currentCommand.initialSvgMousePosition, orthoProj);
            wallInteraction.currentCommand.magnetAttraction = wallInteraction.magnetAttraction;
            return;
        }
    }

    // Cette méthode est appelée par le callback du timer de détection d'accrochage
    static detectTargetWall(hitPoint: Point, doorInteraction: DoorInteraction): void {
        if (!doorInteraction.planningTaskWallsLayer || !doorInteraction.updateGizmo?.transientShape) return;

        // Il n'est pas possible d'insérer une porte dans uen cloison en périphérie de la zone d'étude

        const reticle = PolygonService.centeredSquare(hitPoint, 0.15);
        const activeWalls = doorInteraction.planningTaskWallsLayer.getActiveWalls(false).filter(x=> !x.isTaskZoneBound && x.isSpaceBound);
        //const wallSegments = doorInteraction.planningTaskWallsLayer.getActiveWallsSegments(false);
        const wallIntersects = PartitioningDetection.getBpIntersects(reticle, activeWalls, doorInteraction.planningTaskWallsLayer.id, doorInteraction.planningTaskWallsLayer.taskId);
        if (wallIntersects.length > 0) {
            const s = wallIntersects[0].segment;
            doorInteraction.detectedWall = (doorInteraction.planningTaskWallsLayer.data as BpSvgWall[]).find(x=> x.startPoint.equals(s.startPoint) && x.endPoint.equals(s.endPoint));
            const angle = XcMaths.get360DegreeAngle(XcMaths.toDeg(-s.angle()) + 180);

            const segmentPoint = s.getOrthogonalProjection(hitPoint);
            doorInteraction.updateGizmo.transientShape.transform = new SvgTransform({ translate: segmentPoint, rotationCenter: null, rotationAngle: angle});

            doorInteraction.currentCommand.magnetAttraction = doorInteraction.magnetAttraction;
            return;
        }
    }

    
    // TODO : Méthode à déplacer car uniquement dépendante de floorBlueprint
    static getBpIntersects(reticle: Rectangle, walls: BpSvgWall[], layerId: number, taskId: number, withFullInside: boolean = true): IntersectedSegment[] {
        let result: IntersectedSegment[] = [];

        walls.forEach(w => {
            const s = w.segment();
            if (withFullInside && reticle.contains(s.startPoint) && reticle.contains(s.endPoint)) {
                result.push(new IntersectedSegment(s, []));
                return;
            }

            const intersects: {segment: Segment, point: Point}[] = [];

            for(let s2 of reticle.getSegments())
            {
                const intersect = s2.getIntersect(s);
                if (intersect != null)
                {
                    intersects.push({segment: s2, point: intersect});
                }
            }

            if (intersects.length > 0) {
                result.push(new IntersectedSegment(s, intersects));
            }
        });

        IntersectedSegment.setLayerSource(result, layerId, taskId);
        return result;
    }


}