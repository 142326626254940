import { LeaseStatusTable } from '../../db-model/tables/lease-status-table';
import { DefinedModelBase } from '../defined-model-base';

export class LeaseStatus extends DefinedModelBase {
    leStId!: number;
    leStName!: string;
    leStDisplayNameId!: number;
    leStColor!: string;
    
    constructor(dtoData: any, tableDef: any[] = []) {
        super(dtoData, [LeaseStatusTable].concat(tableDef))
    }
}