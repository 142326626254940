import { XcMaths } from "./xc-maths";

export class DateUtils {
    static readonly week = 7 * 24 * 60 * 60 * 1000;
    static readonly day = 24 * 60 * 60 * 1000;
    static readonly shortDays = ['di','lu','ma','me','je','ve','sa'];
    static readonly longDays = ['dimanche','lundi','mardi','mercredi','jeudi','vendredi','samedi'];
    static readonly shortMonths = ['jan','fév','mars','avr','mai','juin','juil','août','sept','oct','nov','déc'];
    static readonly longMonths = ['janvier','février','mars','avril','mai','juin','juillet','août','septembre','octobre','novembre','décembre'];

    static getDayName(dayOfWeek: number, short: boolean = true) {
        if (short) {
            return DateUtils.shortDays[dayOfWeek];
        }
        return DateUtils.longDays[dayOfWeek];
    }

    static getMonthName(month: number, short: boolean = true) {
        if (short) {
            return DateUtils.shortMonths[month];
        }
        return DateUtils.longMonths[month];
    }

    static getDays(firstDate: Date, lastDate: Date): number {
        return XcMaths.round((lastDate.getTime() - firstDate.getTime()) / this.day, 0);
    }

    static addDays(d: Date, days: number): Date {
        var date = new Date(d.valueOf());
        date.setDate(date.getDate() + days);
        return date;
    }

    /**
     * 
     * @returns Retourne la date du jour à 0h00 contrairement à getDate() qui retourne la date/heure courante
     */
    static today(): Date {
        const d = new Date();
        d.setHours(0, 0, 0, 0);
        return d;
    }

    /**
     * Compare deux dates sans tenir compte de l'heure
     * @param d1 
     * @param d2 
     * @returns True si les deux date sont égales
     */
    static equals(d1: Date, d2: Date): boolean {
        return d1.getFullYear() === d2.getFullYear() && d1.getMonth() === d2.getMonth() && d1.getDate() === d2.getDate();
    }

    static getWeekNumber(d: Date): number {
        const firstDayOfYear = new Date(d.getFullYear(), 0, 1);
        const pastDaysOfYear = (d.valueOf() - firstDayOfYear.valueOf()) / this.day;
        return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
    }

    /**
     * 
     * @param month Attention, month doit être passé sur une base de 1 à 12
     * @param year Année concernée
     * @returns Retourne le nombre de jours dans le mois
     */
    static daysInMonth (month: number, year: number) {
        return new Date(year, month, 0).getDate();
    }

    /**
     * 
     * @param d Date dont on veut connaître le trimestre
     * @returns Retourne le trimestre sur une base de 0 à 3
     */
    static quarter(d: Date): number {
        const m = d.getMonth();
        return Math.floor(m / 3);
    }

    static quarters(startDate: Date, endDate: Date): {year: number, quarter: number, startDate: Date, endDate: Date, passed: number, lasting: number, total: number}[] {
        const result: {year: number, quarter: number, startDate: Date, endDate: Date, passed: number, lasting: number, total: number}[] = [];

        const firstQ = this.quarterInfos(startDate);
        result.push(firstQ);
        let nextDate = this.addDays(firstQ.endDate, 1);
        while (nextDate <= endDate) {
            const newQ = this.quarterInfos(nextDate);
            result.push(newQ);
            nextDate = this.addDays(newQ.endDate, 1);
        }
        return result;
    }

    static quarterMonths(month: number): {startMonth: number, endMonth: number} {
        if ([0, 1, 2].includes(month)) {
            return {startMonth: 0, endMonth: 2};
        }
        if ([3, 4, 5].includes(month)) {
            return {startMonth: 3, endMonth: 5};
        }
        if ([6, 7, 8].includes(month)) {
            return {startMonth: 6, endMonth: 8};
        }
        return {startMonth: 9, endMonth: 11};
    }

    static quarterInfos(date: Date): {year: number, quarter: number, startDate: Date, endDate: Date, passed: number, lasting: number, total: number} {
        const year = date.getFullYear();
        const month = date.getMonth();
        const qMonths = this.quarterMonths(month);
        const m1d = this.daysInMonth(qMonths.startMonth + 1, year);
        const m2d = this.daysInMonth(qMonths.startMonth + 2, year);
        const m3d = this.daysInMonth(qMonths.endMonth + 1, year);
        const qFirstDate = new Date(year, qMonths.startMonth, 1);
        const qlastDate = new Date(year, qMonths.endMonth, this.daysInMonth(qMonths.endMonth + 1, year));
        const passed = this.dateDiffInDays(date, qFirstDate);
        const lasting = this.dateDiffInDays(date, qlastDate);
        return {year: year, quarter: this.quarter(date), startDate: qFirstDate, endDate: qlastDate, passed: passed, lasting: lasting, total: m1d + m2d + m3d};
    }

    /**
     * 
     * @param d Date dont on veut connaître le semestre
     * @returns Retourne le numéro du semestre sur une base de 0 à 1
     */
    static half(d: Date): number {
        const m = d.getMonth();
        return Math.floor((m) / 6);
    }

    static halfMonths(month: number): {startMonth: number, endMonth: number} {
        if ([0, 1, 2, 3, 4, 5].includes(month)) {
            return {startMonth: 0, endMonth: 5};
        }
        return {startMonth: 6, endMonth: 11};
    }

    static halfInfos(date: Date): {year: number, half: number, startDate: Date, endDate: Date, passed: number, lasting: number, total: number} {
        const year = date.getFullYear();
        const month = date.getMonth();
        const hMonths = this.halfMonths(month);
        const m1d = this.daysInMonth(hMonths.startMonth + 1, year);
        const m2d = this.daysInMonth(hMonths.startMonth + 2, year);
        const m3d = this.daysInMonth(hMonths.startMonth + 3, year);
        const m4d = this.daysInMonth(hMonths.startMonth + 4, year);
        const m5d = this.daysInMonth(hMonths.startMonth + 5, year);
        const m6d = this.daysInMonth(hMonths.endMonth + 1, year);
        const qFirstDate = new Date(year, hMonths.startMonth, 1);
        const qlastDate = new Date(year, hMonths.endMonth, this.daysInMonth(hMonths.endMonth + 1, year));
        const passed = this.dateDiffInDays(date, qFirstDate);
        const lasting = this.dateDiffInDays(date, qlastDate);
        return {year: year, half: this.half(date), startDate: qFirstDate, endDate: qlastDate, passed: passed, lasting: lasting, total: m1d + m2d + m3d + m4d + m5d + m6d};
    }

    static halves(startDate: Date, endDate: Date): {year: number, half: number, startDate: Date, endDate: Date, passed: number, lasting: number, total: number}[] {
        const result: {year: number, half: number, startDate: Date, endDate: Date, passed: number, lasting: number, total: number}[] = [];

        const firstH = this.halfInfos(startDate);
        result.push(firstH);
        let nextDate = this.addDays(firstH.endDate, 1);
        while (nextDate <= endDate) {
            const newH = this.halfInfos(nextDate);
            result.push(newH);
            nextDate = this.addDays(newH.endDate, 1);
        }
        return result;
    }

    static yearInfos(date: Date): {year: number, startDate: Date, endDate: Date, passed: number, lasting: number, total: number} {
        const year = date.getFullYear();
        const qFirstDate = new Date(year, 0, 1);
        const qlastDate = new Date(year, 11, 31);
        const passed = this.dateDiffInDays(date, qFirstDate);
        const lasting = this.dateDiffInDays(date, qlastDate);
        return {year: year, startDate: qFirstDate, endDate: qlastDate, passed: passed, lasting: lasting, total: this.daysInYear(year)};
    }

    static daysInYear(year: number) {
        return ((year % 4 === 0 && year % 100 > 0) || year %400 == 0) ? 366 : 365;
    }

    static dateDiffInDays(d1: Date, d2: Date): number {
        const diffTime = Math.abs(d2.getTime() - d1.getTime());
        return Math.round(diffTime / this.day);
        // Il n'existe aucune raison pour devoir traiter les cas où les deux dates auraient des fuseaux horaires différents
        // Discard the time and time-zone information.
        // const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
        // const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());
    }

    static toBackendString(date: Date): string {
        return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    }

    static toNullableBackendString(date: Date | null): string | null {
        if (date == null) return null;
        return this.toBackendString(date);
    }

    static isISOLeapYear(year: number) {
        const startsWithThursday = new Date(year, 0, 1).toString().split(' ')[0] === 'Thu'
        const endsWithThursday = new Date(year, 11, 31).toString().split(' ')[0] === 'Thu'
    
        if (startsWithThursday || endsWithThursday) {
            return true
        }
    
        return false
    }

    // static weeksInBetween(startYear: number, startWeek: number, endYear: number, endWeek: number) {
    //     let diff = 0
    //     for (let i = startYear; i < endYear; i++) {
    //         const has53Weeks = this.isISOLeapYear(i)
    //         if (has53Weeks) {
    //             diff += 53
    //         } else {
    //             diff += 52
    //         }
    //     }
    
    //     return (diff += endWeek - --startWeek)
    //     /* 
    //         the '--startWeek' makes sure the starting week
    //         is not subtracted from the result, because you
    //         want the result to be inclusive'
            
    //     */
    // }
}