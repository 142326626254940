@if (model) {
    <p-splitter class="w-full" styleClass="mb-5 h-full" [panelSizes]="[25, 75]" [minSizes]="[15, 50]">
        <ng-template pTemplate>
            <div class="col">
                <xc-pictos-browser [model]="model.pictoBrowser"/>
            </div>
        </ng-template>
        <ng-template pTemplate>
            <div class="w-full">
                <xc-drawing-board class="flex h-full" [model]="model.drawingBoard"/>
            </div>
        </ng-template>
    </p-splitter>
}
