import { ZAppMenuTable } from "../../db-model/tables/z-app-menu-table";
import { DefinedModelBase } from "../defined-model-base";

export class ZAppMenu extends DefinedModelBase {
    zApMeId!: number;
    zApMeDisplayNameId!: number;
    zApMeParentId!: number | null;
    zApMeGrantId!: number | null;
    zApMeScopeId!: number;
    zApMeOrderIndex!: number;
    zApMeDepth!: number;
    zApMeRouteId!: number | null;
    zApMeLicencing!: number;

    constructor(dtoData: any, tableDef: any[] = []) {
        super(dtoData, [ZAppMenuTable].concat(tableDef));
    }
}