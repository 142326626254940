import { GrantAdminCategoryEnum } from "./grant-admin-category-enum";
import { GrantAdminPageModel } from "./grant-admin-page-model";
import Container from "typedi";
import { RoleGrantedLayer } from "./role-granted-layer";
import { DyntService } from "src/app/core/services/backend-services/dynt-service";
import { FloorModelView } from "src/app/core/model/data-model/views/floor-model-view";
import { FloorModelCategoryEnum } from "src/app/core/model/data-model/enums/floor-model-category-enum";
import { FloorModelTable } from "src/app/core/model/db-model/tables/floor-model-table";
import { ViewsNames } from "src/app/core/model/db-model/views-names-enum";
import { CheckboxChangeEvent } from "primeng/checkbox";
import { GrantTypeEnum } from "src/app/core/model/data-model/enums/grant-type-enum";
import { AppRoleGrant } from "src/app/core/model/data-model/tables/app-role-grant";
import { TablesNamesEnum } from "src/app/core/model/db-model/tables-names-enum";
import { AppRoleGrantTable } from "src/app/core/model/db-model/tables/app-role-grant-table";
import { GrantService } from "src/app/core/services/backend-services/grant-service";
import { toastInfo } from "src/app/core/services/toast-service";

export class LayersGrantsVM extends GrantAdminPageModel {
    layers: RoleGrantedLayer[] = [];
    roleId!: number;
    roleGrants: AppRoleGrant[] = [];
    grantTypes = GrantTypeEnum;

    private constructor() {
        super(GrantAdminCategoryEnum.layers);
    }

    static async newAsync(roleId: number): Promise<LayersGrantsVM> {
        const result = new LayersGrantsVM();
        result.roleId = roleId;
        await result.loadData();
        return result;
    }

    async loadData(): Promise<void> {
        const s = Container.get(DyntService);
        const datas = await s.downloadTable<FloorModelView>(ViewsNames.FloorModelView, undefined, FloorModelTable.flMoCategoryId, FloorModelCategoryEnum.Equipment.toString());
        this.layers = datas.map(x=> new RoleGrantedLayer(x)).sort((a, b) => a.layer.flMoDisplayName.localeCompare(b.layer.flMoDisplayName));
        this.roleGrants = await s.downloadTable<AppRoleGrant>(TablesNamesEnum.AppRoleGrant, undefined, AppRoleGrantTable.apRoGrRoleId, this.roleId);
        const grantIds = this.roleGrants.map(x=> x.apRoGrGrantId);
        this.layers.forEach(layer => {
            layer.setRoleGrants(grantIds);
        });

    }

    async grantChange(e: CheckboxChangeEvent, layer: FloorModelView, grantType: GrantTypeEnum): Promise<void> {
        const s = Container.get(GrantService);
        let grantId: number;
        let message: string;
        if (grantType === GrantTypeEnum.Read) {
            grantId = layer.flMoReadGrantId!;
            message = "lecture";
        } else {
            grantId = layer.flMoUpdateGrantId!;
            message = "écriture";
        }
        if (e.checked) {
            const result = await s.CreateAppRoleGrant(this.roleId, grantId);
            if (result == null) {
                // TODO : Une erreur s'est produite côté backend, on recharge la liste
            } else {
                toastInfo(`Le rôle a été habilité en ${message} sur le calque ${layer.flMoDisplayName}`);
            }
        } else {
            const result = await s.deleteRoleGrants(this.roleId, [grantId]);
            if (result == null) {
                // TODO : Une erreur s'est produite côté backend, on recharge la liste
            } else {
                toastInfo(`Le rôle n'est plus habilité en ${message} sur le calque ${layer.flMoDisplayName}`);
            }
        }
    }
}