import { SvgEntityTypesEnum } from "src/app/core/model/svg-model/svg-entity-type-enum";
import { SelectableSvgEntity } from "./selectable-svg-entity";
import { SelectableSvgCircle } from "./selectable-svg-circle";
import { SelectableSvgEllipse } from "./selectable-svg-ellipse";
import { SelectableSvgLine } from "./selectable-svg-line";
import { SelectableSvgPath } from "./selectable-svg-path";
import { SvgPathService } from "src/app/core/model/svg-model/svg-path-service";
import { SvgEntity } from "src/app/core/model/svg-model/svg-entity.model";
import { SvgGroup } from "src/app/core/model/svg-model/svg-group.model";
import { SvgEntityParser } from "../../shared/gizmos/model/svg-entity-parser";

export function getSelectableEntities(entities: any): Array<SelectableSvgEntity> {
    let result = new Array<SelectableSvgEntity>();
    if(entities){
        entities.forEach((element: { entityType: any; }) => {
            (element as any)
            switch(element.entityType){
                case SvgEntityTypesEnum.line:
                    result.push(new SelectableSvgLine(element));
                    break;
                case SvgEntityTypesEnum.path:
                    result.push(...getPathsElements(element));
                    break;
                case SvgEntityTypesEnum.circle:
                    result.push(new SelectableSvgCircle(element));
                    break;
                case SvgEntityTypesEnum.ellipse:
                    result.push(new SelectableSvgEllipse(element));
                    break
                default:
                    break;
            }
        });
    }

    return result;
}

function getPathsElements(data: any): SelectableSvgEntity[] {
    const result: SelectableSvgEntity[] = [];

    const path = new SelectableSvgPath(data);
    // Un path peut être la combinaison de plusieurs éléments
    // pour le dessin on a besoin que ces éléments soit individualisés
    const subPaths = SvgPathService.getSubPathsStatements(path.d);
    subPaths.forEach(sp => {
        const subPath = new SelectableSvgPath(data);
        subPath.d = sp;
        const tmp = getFromBaseEntity(SvgPathService.getSubEntity(subPath));
        if (tmp) {
            result.push(tmp);
        };
    });

    return result;
}

function getFromBaseEntity(e: SvgEntity): SelectableSvgEntity | undefined {
    switch(e.entityType){
        case SvgEntityTypesEnum.line:
            return new SelectableSvgLine(e);
        case SvgEntityTypesEnum.path:
            return new SelectableSvgPath(e)
        case SvgEntityTypesEnum.circle:
            return new SelectableSvgCircle(e);
        case SvgEntityTypesEnum.ellipse:
            return new SelectableSvgEllipse(e);
        default:
            return undefined;
    }
}

export function createRandomString(length: number): string {
    const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    const randomArray = new Uint8Array(length);
    crypto.getRandomValues(randomArray);
    randomArray.forEach((number) => {
      result += chars[number % chars.length];
    });
    return result;
}

export function getEntityBbox(entityId: string): SVGRect | undefined {
    const tmp = document.querySelector(`[entity-id="${entityId}"]`);
    if (tmp != null) {
        const element = tmp as unknown as SVGSVGElement;
        return element.getBBox();
    }
    return undefined;
}

export function getPictoGroup(id: string, entities: SelectableSvgEntity[]): string {
    const gAttributes = SvgEntityParser.getAttributesStatement([["stroke-width", 0.01], ["fill-opacity", 0.01], ["stroke", "black"], ["id", `${id}`]]);
    return SvgEntityParser.getTagStatement("g", gAttributes, getEntitiesStatement(entities));
}

export function getEntitiesStatement(entities: SelectableSvgEntity[]): string {
    let result: string = "";

    entities.forEach(e => {
        result += e.getStatement();
    });

    return result;
}
