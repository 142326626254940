import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SvgReticle } from '../../../shared/gizmos/model/svg-reticle';

@Component({
  selector: 'g[xc-cad-conv-svg-reticles]',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './cad-conv-svg-reticles.component.svg',
  styleUrls: ['./cad-conv-svg-reticles.component.scss']
})
export class CadConvSvgReticlesComponent {
  @Input() model: SvgReticle | undefined;
}
