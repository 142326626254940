import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PropertiesEditorVM } from '../../model/properties-editor-vm';
import { DefinedModelBase } from 'src/app/core/model/data-model/defined-model-base';
import { PropertyGridComponent } from 'src/app/components-lib/property-grid/view/property-grid/property-grid.component';
import { ReportGridComponent } from 'src/app/components-lib/report-grid/view/report-grid/report-grid.component';
import { DynTableComponent } from 'src/app/components-lib/dyn-table/dyn-table.component';

@Component({
  selector: 'xc-properties-editor',
  standalone: true,
  imports: [CommonModule, PropertyGridComponent, DynTableComponent, ReportGridComponent],
  templateUrl: './properties-editor.component.html',
  styleUrls: ['./properties-editor.component.scss']
})
export class PropertiesEditorComponent {
  @Input() model: PropertiesEditorVM<DefinedModelBase> | undefined;
}
