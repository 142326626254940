import { DrawingCommandEnum } from "./drawing-command-enum";
import { CommandGroup } from "./command-group";
import { ICommand } from "./i-command";

export class DrawingCommands extends CommandGroup<DrawingCommandEnum> {
    lineCommand: ICommand;
    plineCommand: ICommand;
    circleCommand: ICommand;
    ellipseCommand: ICommand;
    textCommand: ICommand;

    constructor() {
        super();
        this.lineCommand = this.addCommand(DrawingCommandEnum.lineInsertionCommand, "Ligne", "open_in_full");
        this.plineCommand = this.addCommand(DrawingCommandEnum.plineInsertionCommand, "Polyligne", "polyline");
        this.circleCommand = this.addCommand(DrawingCommandEnum.circleInsertionCommand, "Cercle", "motion_photos_on");
        this.ellipseCommand = this.addCommand(DrawingCommandEnum.ellipseInsertionCommand, "Ellipse", "motion_photos_on");
        this.textCommand = this.addCommand(DrawingCommandEnum.textInsertionCommand, "Text", "title");

    }
}