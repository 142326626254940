@if (model) {
    <p-splitter 
        [panelSizes]="[20, 80]" 
        styleClass="h-full mb-5">
        <ng-template pTemplate>
            <div class="w-full">
                <pb-business-units-tree [model]="model.businessUnitsTreeModel"/>
            </div>
        </ng-template>
        <ng-template pTemplate>
            <div class="w-full h-full overflow-auto">
                @switch (model.selectedOption?.chartType) {
                    @case (model.tabPageContentTypeEnum.donutchart) {
                        <xc-donut-chart-wrapper class="chart75" [model]="$any(model.content)" />
                    }
                    @case (model.tabPageContentTypeEnum.referential) {
                        <xc-properties-editor [model]="$any(model.content)" />
                    }
                    @case (model.tabPageContentTypeEnum.none) {
                        <xc-dyn-table class="table" [model]="$any(model.content)?.content"/>
                    }
                }
            </div>
        </ng-template>
    </p-splitter>
}
