import { XcMaths } from 'src/app/core/model/static-functions/xc-maths';
import { DonutChartDataVM } from '../charts/donut/model/donut-chart-data-vm';
import { DonutChartSliceBuilder } from '../charts/donut/model/donut-chart-slice-builder';
import { DonutChartSliceData } from '../charts/donut/model/donut-chart-slice-data';
import { SliceAnonymousData } from './slice-anonymous-data';
import { HbarChartDataVM } from '../charts/hbar/model/hbar-chart-data-vm';
import { HbarBarItem } from '../charts/hbar/model/hbar-bar-item';

export class ChartBuilder {
    static getAnatomyDonutChartData(name: string, data: SliceAnonymousData[], depth: number): DonutChartDataVM {
        return ChartBuilder.getDonutChartData(name, "Anatomie", " m²", data, depth);
    }

    static getActivityDonutChartData(name: string, data: SliceAnonymousData[]): DonutChartDataVM {
        return ChartBuilder.getDonutChartData(name, "Activité", " m²", data);
    }

    static getAttributionDonutChartData(name: string, data: SliceAnonymousData[]): DonutChartDataVM {
        return ChartBuilder.getDonutChartData(name, "Attribution", " m²", data);
    }

    static getInventoryDonutChartData(name: string, data: SliceAnonymousData[], unit: string): DonutChartDataVM {
        return ChartBuilder.getDonutChartData(name, "Inventaire", unit, data);
    }

    static getNetRatiosHbarChartData(name: string, data: SliceAnonymousData[], unit: string): HbarChartDataVM {
        const result = ChartBuilder.getHbarChartData(name, "Ratios utiles", unit, data);
        return result;
    }

    static getBusinessUnitsChartData(name: string, data: SliceAnonymousData[]) {
        return ChartBuilder.getDonutChartData(name, "Allocations", " m²", data);
    }

    static getDonutChartData(mainLabel: string, subLabel: string, unit: string, data: SliceAnonymousData[], max: number = 1): DonutChartDataVM {
        const pcData: DonutChartSliceData[] = [];

        const totalArea = data.reduce((sum, el) => sum += el.value, 0);
        
        let step = 0;
        data.forEach(d => {
            const ratio = XcMaths.round(d.value * 100 / totalArea, 2);
            pcData.push(ChartBuilder.getSliceData(d, step, ratio, 0.25 + (d.level * (0.75 / max)), `${d.value.toLocaleString()} ${unit} - ${ratio}%`, max));
            if (d.children.length > 0) {
                pcData.push(...this.getChildrenSlices(d.children, step, ratio, d.value, max, unit));
            }
            step += ratio;
        });

        const result = new DonutChartDataVM(mainLabel, subLabel, pcData, totalArea);
        result.centerText = `${totalArea.toLocaleString()} ${unit}`;
        return result;
    }

    static getChildrenSlices(children: SliceAnonymousData[], pStep: number, ratio: number, totalArea: number, max: number, unit: string): DonutChartSliceData[] {
        const result: DonutChartSliceData[] = [];

        let step = pStep;
        children.forEach(d => {
            const cRatio = XcMaths.round(d.value * ratio / totalArea, 2);
            result.push(ChartBuilder.getSliceData(d, step, cRatio, 0.25 + (d.level * (0.75 / max)), `${d.value.toLocaleString()} ${unit} - ${cRatio}%`, max));
            if (d.children.length > 0) {
                result.push(...this.getChildrenSlices(d.children, step, cRatio, d.value, max, unit));
            }
            step += cRatio;
        });

        return result;
    }

    static getSliceData(d: SliceAnonymousData, step: number, ratio: number, topRadius: number, subLabel: string, max: number): DonutChartSliceData {
        return new DonutChartSliceData(d.id, 
            d.name, 
            d.value, 
            d.color, 
            DonutChartSliceBuilder.getDonutSlicePath(ratio, topRadius, max === 1 ? 0.65 : topRadius - (0.75 / max)), 
            step, 
            ratio,
            d.name,
            subLabel,
            topRadius)
    }

    static getHbarChartData(mainLabel: string, subLabel: string, unit: string, data: SliceAnonymousData[], max: number = 1): HbarChartDataVM {
        //const bars: HbarBarItem[] = [];

        const result = new HbarChartDataVM(mainLabel, subLabel);
        const hLabels: string[] = [];
        for (let i = 0; i <= 100; i+=10) {
            hLabels.push(`${i}%`)
        }
        result.setSize(200, 100, Math.min(10, 100 / data.length), hLabels);
        result.loadSeries(data);

        // let level = 0;
        // data.forEach(d => {
        //     const b = new HbarBarItem(d.id, d.name, level, d.value, d.color, 0);
        //     let step = 0;
        //     d.children.forEach(c => {
        //         b.children.push(new HbarBarItem(c.id, c.name, 0, c.value, c.color, step));
        //         step += c.value;
        //     });
        //     bars.push(b);
        //     // const ratio = XcMaths.round(d.value * 100 / totalArea, 2);
        //     // pcData.push(ChartBuilder.getSliceData(d, step, ratio, 0.25 + (d.level * (0.75 / max)), `${d.value.toLocaleString()} ${unit} - ${ratio}%`, max));
        //     // if (d.children.length > 0) {
        //     //     pcData.push(...this.getChildrenSlices(d.children, step, ratio, d.value, max, unit));
        //     // }
        //     level += 1;
        // });

        // result.bars = bars;

        return result;
    }

}