import { DisplayThemeCaption } from "./display-theme-caption";
import { DisplayThemeView } from "src/app/core/model/data-model/views/display-theme-view";
import { EventListener } from "src/app/core/events/event-listener";
import { readableUUID } from "src/app/core/events/event-listener-uuid";
import { ThemingModel } from "./theming-model";
import { DisplayThemeEnum } from "src/app/core/model/data-model/enums/display-theme-enum";
import { BpSvgRoom } from "../../../../svg-entities/model/bp-svg-room";
import { FloorBlueprint } from "../../../../blueprint-viewer-content-panel/itself/model/floor-blueprint";
import { RoomLayoutType } from "src/app/core/model/data-model/tables/room-layout-type";
import { DisplayThemableEnum } from "src/app/core/model/data-model/enums/display-themable-enum";
import { FloorDataStateEnum } from "src/app/core/model/data-model/enums/floor-data-state-enum";
import { BlueprintWorkplaceLabelLayer } from "../../../../svg-entities/model/layers/blueprint-workplace-label-layer";
import { FloorModelEnum } from "src/app/core/model/data-model/enums/floor-model-enum";
import { GraphicalDefaultValues } from "../../../../shared-model/graphical-default-values";
import { RoomActivityStatusTypeEnum } from "src/app/core/model/data-model/enums/room-activity-status-type-enum";
import { RoomAttributionTypeEnum } from "src/app/core/model/data-model/enums/room-attribution-type-enum";
import { ArrayUtils } from "src/app/core/model/static-functions/array-utils";
import { XcMaths } from "src/app/core/model/static-functions/xc-maths";
import { SvgPattern } from "src/app/core/model/svg-model/svg-pattern.model";
import { DisplayThemeSet } from "./display-theme-set";
import Container from "typedi";
import { DyntService } from "src/app/core/services/backend-services/dynt-service";
import { TablesNamesEnum } from "src/app/core/model/db-model/tables-names-enum";

export class ThemeCaptions extends EventListener {
    blueprint: FloorBlueprint;
    roomLayoutTypes: RoomLayoutType[] = [];

    selectedTheme: DisplayThemeSet | undefined;
    previousSelectedThemeId: number | undefined;
    displayThemeEnum = DisplayThemeEnum;

    constructor(blueprint: FloorBlueprint) {
        super(readableUUID(ThemeCaptions.name));

        this.blueprint = blueprint;

        this.addEventListener(ThemingModel.selectedThemeChange, async (theme: DisplayThemeSet) => {
            this.previousSelectedThemeId = this.selectedTheme?.themeView.diThId;
            this.selectedTheme = theme;
            await this.setDisplayReport(this.previousSelectedThemeId, theme.themeView.diThId);
        });

        this.addEventListener(ThemingModel.refreshThemeNeeded, async (reloadCaptions: boolean) => {
            await this.refreshDisplayReport(reloadCaptions);
        });
    }

    displayableCaptions(): DisplayThemeCaption[] {
        if (!this.selectedTheme) return [];
        return this.selectedTheme.captions.filter(x=> !x.removedFromList);
    }

    isRoomTheme(themeId: number): boolean {
        return themeId === DisplayThemeEnum.LayoutType ||
        themeId === DisplayThemeEnum.Allocations ||
        themeId === DisplayThemeEnum.Attributions ||
        themeId === DisplayThemeEnum.ActivityStatus ||
        themeId === DisplayThemeEnum.Mixed;
    }

    isEquipmentTheme(themeId: number): boolean {
        return themeId === DisplayThemeEnum.InstallationInstalled ||
        themeId === DisplayThemeEnum.InstallationUninstalled ||
        themeId === DisplayThemeEnum.ReconcileInstalled ||
        themeId === DisplayThemeEnum.ReconcileUninstalled ||
        themeId === DisplayThemeEnum.Identification ||
        themeId === DisplayThemeEnum.EquipmentsProviders;
    }

    isPlanningTheme(themeId: number): boolean {
        return themeId === DisplayThemeEnum.InstallationInstalled ||
        themeId ===  DisplayThemeEnum.InstallationUninstalled;
    }

    async setDisplayReport(previousThemeId: number | undefined, selectedThemeId: number): Promise<void> {
        if (previousThemeId !== undefined) {
            this.removePreviousTheme(previousThemeId, selectedThemeId);
        }

        switch (selectedThemeId) {
            case DisplayThemeEnum.LayoutType:
                await this.setRoomLayoutTypeReport(false);
                break;
            case DisplayThemeEnum.Allocations:
                this.setRoomAllocationsReport(false);
                break;
            case DisplayThemeEnum.Attributions:
                this.setRoomAttributionsReport();
                break;
            case DisplayThemeEnum.ActivityStatus:
                this.setRoomActivityStatusReport();
                break;
            case DisplayThemeEnum.Mixed:
                this.setRoomMixedReport(false);
                break;
            case DisplayThemeEnum.WorkplaceTypes:
                this.setWorkplaceColor();
                break;
            case DisplayThemeEnum.InstallationInstalled:
                this.setPlanningInstalledReport();
                break;
            case DisplayThemeEnum.InstallationUninstalled:
                this.setPlanningUninstalledReport();
                break;
            case DisplayThemeEnum.EquipmentsProviders:
                this.setEquipmentsProvidersReport();
                break;
            case DisplayThemeEnum.ReconcileInstalled:
                this.setEquipmentReconcileInstalledReport();
                break;
            case DisplayThemeEnum.ReconcileUninstalled:
                this.setEquipmentReconcileUninstalledReport();
                break;
            case DisplayThemeEnum.Identification:
                this.setEquipmentIdentificationReport();
                break;
            case DisplayThemeEnum.ImportReconcile:
                this.setImportReconcileReport()
                break;
            default:
                break;
        }
    }

    async refreshDisplayReport(reloadCaptions: boolean): Promise<void> {
        switch (this.selectedTheme?.themeView.diThId) {
            case DisplayThemeEnum.LayoutType:
                await this.setRoomLayoutTypeReport(!reloadCaptions);
                //this.setRoomsLayoutTypeColor(this.getTopBottomRooms());
                break;
            case DisplayThemeEnum.Allocations:
                this.setRoomAllocationsReport(!reloadCaptions);
                //this.setRoomsAllocationsColor(this.getTopBottomRooms());
                break;
            case DisplayThemeEnum.Attributions:
                this.setRoomAttributionsReport();
                break;
            case DisplayThemeEnum.ActivityStatus:
                this.setRoomActivityStatusReport();
                break;
            case DisplayThemeEnum.Mixed:
                this.setRoomMixedReport(!reloadCaptions);
                //this.setRoomMixedColor(this.getTopBottomRooms());
                break;
            case DisplayThemeEnum.WorkplaceTypes:
                this.setWorkplaceColor();
                break;
            case DisplayThemeEnum.InstallationInstalled:
                this.setPlanningInstalledReport();
                break;
            case DisplayThemeEnum.InstallationUninstalled:
                this.setPlanningUninstalledReport();
                break;
            case DisplayThemeEnum.EquipmentsProviders:
                this.setEquipmentsProvidersReport();
                break;
            case DisplayThemeEnum.ReconcileInstalled:
                this.setEquipmentReconcileInstalledReport();
                break;
            case DisplayThemeEnum.ReconcileUninstalled:
                this.setEquipmentReconcileUninstalledReport();
                break;
            case DisplayThemeEnum.Identification:
                this.setEquipmentIdentificationReport();
                break;
            case DisplayThemeEnum.ImportReconcile:
                this.setImportReconcileReport()
                break;
            default:
                break;
        }
    }

    async setRoomLayoutTypeReport(refresh: boolean): Promise<void> {
        if (this.roomLayoutTypes.length === 0) {
            const s = Container.get(DyntService);
            this.roomLayoutTypes = await s.downloadTable<RoomLayoutType>(TablesNamesEnum.RoomLayoutType);
        }
        const rooms = this.getTopBottomRooms();
        if (!refresh) this.setRoomLayoutTypeCaptions(rooms);
        this.setRoomsLayoutTypeColor(rooms);
    }

    setRoomAllocationsReport(refresh: boolean): void {
        const rooms = this.getTopBottomRooms();
        if (!refresh) this.setRoomsAllocationsCaptions(rooms);
        this.setRoomsAllocationsColor(rooms);
    }

    setRoomAttributionsReport(): void {
        this.setRoomAttributionColor(this.getTopBottomRooms());
    }

    setRoomActivityStatusReport(): void {
        this.setRoomActivityStatusColor(this.getTopBottomRooms());
    }

    setRoomMixedReport(refresh: boolean): void {
        const rooms = this.getTopBottomRooms();
        if (!refresh) this.setRoomMixedCaption(rooms);
        this.setRoomMixedColor(rooms);
    }

    // setWorkplaceTypeReport(): void {
    //     const theme = this.displayThemes.find(x=> x.themeView.diThId === DisplayThemeEnum.WorkplaceTypes);
    //     if (theme) {
    //         this.setWorkplaceColor(theme);
    //     }
    // }

    setPlanningInstalledReport(): void {
        this.blueprint.layersController.showDataState(this.blueprint.topMostTaskId(), FloorDataStateEnum.Deleted, false);
        if (!this.selectedTheme) return;
        this.setPlanningTheme(this.blueprint.topMostTaskId(), this.selectedTheme.captions);
    }

    setPlanningUninstalledReport(): void {
        this.blueprint.layersController.showDataState(this.blueprint.topMostTaskId(), FloorDataStateEnum.Deleted, true);
        if (!this.selectedTheme) return;
        this.setPlanningTheme(this.blueprint.topMostTaskId(), this.selectedTheme.captions);
    }

    setEquipmentsProvidersReport(): void {
        if (!this.selectedTheme) return;
        this.setEquipmentsProvidersTheme(this.blueprint.topMostTaskId(), this.selectedTheme.captions);
    }

    setEquipmentReconcileInstalledReport(): void {
        if (!this.selectedTheme) return;
        this.setEquipmentTheme(this.blueprint.topMostTaskId(), this.selectedTheme);
    }

    setEquipmentReconcileUninstalledReport(): void {
        if (!this.selectedTheme) return;
        this.setEquipmentTheme(this.blueprint.topMostTaskId(), this.selectedTheme);
    }

    setEquipmentIdentificationReport(): void {
        if (!this.selectedTheme) return;
        const taskId = this.blueprint.topMostTaskId();

        const equipments = this.blueprint.layersController.equipments(taskId);

        // Récupère les items de légende standard pour le thème
        let themeCaptions = this.selectedTheme.captions;

        // Il n'y a que deux items de légende pour ce thème,
        // les éléments qui ont un code d'identification
        // et ceux qui n'en n'ont pas ou pas encore et dans ce cas ils ont un code fictif commençant par #xc ou aucun code

        const identifiedItems = equipments.filter(x=> x.equipmentItem?.eqItMaterialId !== null && !x.equipmentItem?.eqItMaterialId.startsWith("#xc"));
        const unidentifiedItems = equipments.filter(x=> x.equipmentItem?.eqItMaterialId == null || x.equipmentItem?.eqItMaterialId.startsWith("#xc"));
        const identifiedCaption = themeCaptions.find(x=> x.id === DisplayThemableEnum.Identified);
        if (!identifiedCaption) return;
        identifiedCaption.pattern = undefined;
        identifiedCaption.subLabel = this.getCountSubLabelText(identifiedItems.length);
        const unidentifiedCaption = themeCaptions.find(x=> x.id === DisplayThemableEnum.StandingBy);
        if (!unidentifiedCaption) return;
        unidentifiedCaption.pattern = undefined;
        unidentifiedCaption.subLabel = this.getCountSubLabelText(unidentifiedItems.length);

        identifiedItems.forEach(i => {
                if (identifiedCaption.hidden) {
                    i.resetThemeColor();
                } else {
                    i.setThemeColor(identifiedCaption.color);
                }
        });

        unidentifiedItems.forEach(i => {
                if (unidentifiedCaption.hidden) {
                    i.resetThemeColor();
                } else {
                    i.setThemeColor(unidentifiedCaption.color);
                }
        });
    }

    setImportReconcileReport(): void {
        //const theme = this.displayThemes.find(x=> x.themeView.diThId === DisplayThemeEnum.ImportReconcile);

    }

    removePreviousTheme(previousThemeId: number, selectedThemeId: number): void {
        switch (previousThemeId) {
            case DisplayThemeEnum.LayoutType:
            case DisplayThemeEnum.Allocations:
            case DisplayThemeEnum.Attributions:
            case DisplayThemeEnum.ActivityStatus:
            case DisplayThemeEnum.Mixed:
                // Il n'est pas nécessaire de supprimer le pochage actuel des surfaces si le nouveau thème est aussi un thème de surface
                // puisqu'elles vont toutes être pochées à nouveau
                if (!this.isRoomTheme(selectedThemeId)) {
                    this.blueprint.allTaskIds().forEach(taskId => {
                        const roomLayer = this.blueprint.layersController.roomsLayer(taskId);
                        if (roomLayer) {
                            roomLayer.resetRoomsColor();
                        }
                    });
                }
                break;
            case DisplayThemeEnum.WorkplaceTypes:
                const workplaceLayers = this.blueprint.layersController.allLayers<BlueprintWorkplaceLabelLayer>(FloorModelEnum.WorkplaceLabels);
                workplaceLayers.forEach(wl => {
                    wl.resetLabelsColor();
                });
                break;
            case DisplayThemeEnum.EquipmentsProviders:
            case DisplayThemeEnum.ReconcileInstalled:
            case DisplayThemeEnum.ReconcileUninstalled:
            case DisplayThemeEnum.InstallationUninstalled:
                // On masque les éléments supprimés précédemment affichés
                //this.floorBlueprint.showDataState(this.floorBlueprint.topMostTaskId(), FloorDataStateEnum.Deleted, false);
                // Pas de break à la suite, c'est normal !
            case DisplayThemeEnum.InstallationInstalled:
                // Restaure les couleurs des cloisons par type
                this.blueprint.layersController.wallsLayer(this.blueprint.topMostTaskId())?.resetColor();
                // Restaure les arrière plan d'étiquette de position de travail si le nouveau thème ne les concerne pas
                if (!this.isPlanningTheme(selectedThemeId)) {
                    const workplaceLayers = this.blueprint.layersController.allLayers<BlueprintWorkplaceLabelLayer>(FloorModelEnum.WorkplaceLabels);
                    workplaceLayers.forEach(wl => {
                        wl.setLabelsColor("grey", GraphicalDefaultValues.LabelBackgroundOpacity);
                        
                    });
                }
                // Il n'est pas nécessaire de supprimer le pochage actuel des équipemets si le nouveau thème est aussi un thème d'équipement
                // puisqu'ils vont tous être pochés à nouveau
                if (!this.isEquipmentTheme(selectedThemeId)) {
                    const equipmentsLayers = this.blueprint.layersController.equipmentLayers();
                    equipmentsLayers.forEach(l => {
                        l.resetEquipmentsColor();
                    });
                }
                break;
            default:
                break;
        }
    }

    setRoomLayoutTypeCaptions(rooms: BpSvgRoom[]): void {
        const tmp: DisplayThemeCaption[] = [];

        // Isole les identifiants des types d'implantation
        const layoutTypesIds = rooms.map(r => r.room().roLayoutTypeId);
        const distinctIds = [...new Set(layoutTypesIds)];
        // Mappe les ids sur la table de référence
        const layoutTypes = this.roomLayoutTypes.filter(x=> distinctIds.includes(x.roLaTyId));

        // Crée le tableau de rapport
        layoutTypes.forEach(lt => {
            const area = XcMaths.round(rooms.reduce((sum, x) => sum + (x.room().roLayoutTypeId === lt.roLaTyId ? x.room().roArea : 0), 0), 2);
            const areaString = area + " m²";
            const newItem = DisplayThemeCaption.fromBlueprintData(DisplayThemeEnum.LayoutType, lt.roLaTyId, lt.roLaTyName, areaString, lt.roLaTyColor);
            tmp.push(newItem);
        });

        //const theme = this.displayThemes.find(x=> x.themeView.diThId === DisplayThemeEnum.LayoutType);
        const theme = this.selectedTheme
        if (!theme) return;
        theme.captions = tmp;
    }

    setRoomsLayoutTypeColor(rooms: BpSvgRoom[]): void {
        // Mappe le thème sur les éléments du plan
        this.selectedTheme?.captions.forEach(c => {
            const themedRooms = rooms.filter(x=> x.room().roLayoutTypeId === c.id);
            themedRooms.forEach(r => {
                if (c.hidden) {
                    r.setFill("#ffffff");
                } else {
                    r.setFill(c.color);
                }
            });
        });
    }

    setRoomsAllocationsCaptions(rooms: BpSvgRoom[]): void {
        const tmp: DisplayThemeCaption[] = [];

        // TODO: utiliser une seule boucle pour constituer les trois tableau
        // plutôt que trois filter()

        // Récupère le thème
        //const theme = this.displayThemes.find(x=> x.themeView.diThId === DisplayThemeEnum.Allocations);
        const theme = this.selectedTheme
        if (!theme) return;
        // Récupère les items de légende standard pour le thème
        let themeCaptions = theme.captions;
        const notAllocatedThemeCaption = themeCaptions.find(x=> x.id === DisplayThemableEnum.NotAllocated);
        const multiAllocatedThemeCaption = themeCaptions.find(x=> x.id === DisplayThemableEnum.MultiAllocated);
        if (!notAllocatedThemeCaption || !multiAllocatedThemeCaption) return;

        // Isole les patterns
        const patterns = themeCaptions.map(x => x.pattern).filter(x => x !== undefined) as SvgPattern[];
        // Injecte les patterns dans le svg
        this.blueprint.setPatterns(patterns);

        // Crée le tableau de rapport
        theme.captions.splice(2);

        // rapport des surfaces non allouées
        const notAllocatedRooms = rooms.filter(x=> x.room().roAttributionTypeId !== RoomAttributionTypeEnum.Allocation);
        let notAllocatedArea = 0;
        notAllocatedThemeCaption.subLabel = "0 m²";
        notAllocatedRooms.forEach(r => {
            notAllocatedArea += XcMaths.round(r.area(), 2);
            notAllocatedThemeCaption.subLabel = notAllocatedArea + " m²";
        });

        // Rapport des surfaces allouées à plusieurs entités
        const multiAllocatedRooms = rooms.filter(x=> x.allocations().length > 1);
        let multiAllocatedArea = 0;
        multiAllocatedThemeCaption.subLabel = "0 m²";
        multiAllocatedRooms.forEach(r => {
            multiAllocatedArea += XcMaths.round(r.area(), 2);
            multiAllocatedThemeCaption.subLabel = multiAllocatedArea + " m²";
        });

        // Rapport des surfaces allouées à une seule entité
        // Isole les entités distinctes
        const singleAllocatedRooms = rooms.filter(x=> x.allocations().length === 1);
        const allocations = singleAllocatedRooms.map(x=> x.roomSet?.allocationsSet[0]);
        const buIds = allocations.map(x=> x?.dataSet.buUnId);
        const distinctBuIds = [...new Set(buIds)];
        distinctBuIds.forEach(buId => {
            const area = XcMaths.round(singleAllocatedRooms.reduce((sum, x) => sum + (x.allocations()[0].dataSet.buUnId === buId ? x.area() : 0), 0), 2);
            const areaString = area + " m²";
            const a = allocations.find(x=> x?.dataSet.buUnId === buId);
            if (a) {
                const newItem = DisplayThemeCaption.fromBlueprintData(DisplayThemeEnum.Allocations, a.dataSet.buUnId, a.dataSet.buUnName, areaString, a.dataSet.buUnColor);
                tmp.push(newItem);
            }
        });

        theme.captions = themeCaptions.concat(tmp);
    }

    setRoomsAllocationsColor(rooms: BpSvgRoom[]): void {
        if (!this.selectedTheme) return;
        const notAllocatedThemeCaption = this.selectedTheme.captions.find(x=> x.id === DisplayThemableEnum.NotAllocated);
        const multiAllocatedThemeCaption = this.selectedTheme.captions.find(x=> x.id === DisplayThemableEnum.MultiAllocated);
        if (!notAllocatedThemeCaption || !multiAllocatedThemeCaption) return;
        const singleAllocatedThemeCations = this.selectedTheme.captions.slice(2);

        const notAllocatedRooms = rooms.filter(x=> x.room().roAttributionTypeId !== RoomAttributionTypeEnum.Allocation);
        const multiAllocatedRooms = rooms.filter(x=> x.room().roAttributionTypeId === RoomAttributionTypeEnum.Allocation && x.allocations().length > 1);
        const singleAllocatedRooms = rooms.filter(x=> x.room().roAttributionTypeId === RoomAttributionTypeEnum.Allocation && x.allocations().length === 1);

        notAllocatedRooms.forEach(r => {
            if (notAllocatedThemeCaption.hidden) {
                r.setFill("#ffffff");
            } else {
                r.setFill(notAllocatedThemeCaption.getPatternUrlRef());
            }
        });

        multiAllocatedRooms.forEach(r => {
            if (multiAllocatedThemeCaption.hidden) {
                r.setFill("#ffffff");
            } else {
                r.setFill(multiAllocatedThemeCaption.getPatternUrlRef());
            }
        });

        singleAllocatedThemeCations.forEach(c => {
            const themedRooms = singleAllocatedRooms.filter(x=> x.allocations()[0].dataSet.buUnId === c.id);
            themedRooms.forEach(r => {
                if (c.hidden) {
                    r.setFill("#ffffff");
                } else {
                    r.setFill(c.color);
                }
            });
        });
    }

    setRoomAttributionColor(rooms: BpSvgRoom[]): void {
        //const tmp: DisplayThemeCaption[] = [];

        // Récupère le thème
        //const theme = this.displayThemes.find(x=> x.themeView.diThId === DisplayThemeEnum.Attributions);
        const theme = this.selectedTheme
        if (!theme) return;
        // Récupère les items de légende standard pour le thème
        let themeCaptions = theme.captions;

        // Isole les patterns
        const patterns = themeCaptions.map(x=> x.pattern).filter(x=> x) as SvgPattern[];
        // Injecte les patterns dans le svg
        this.blueprint.setPatterns(patterns);

        themeCaptions.forEach(tc => {
            const themeColor = tc.getPatternUrlRef();
            let themeRooms: BpSvgRoom[];
            switch (tc.id) {
                case DisplayThemableEnum.Allocated:
                    themeRooms = rooms.filter(x=> x.room().roAttributionTypeId === RoomAttributionTypeEnum.Allocation)
                    break;
                case DisplayThemableEnum.Shared:
                    themeRooms = rooms.filter(x=> x.room().roAttributionTypeId === RoomAttributionTypeEnum.Sharing)
                    break;
                case DisplayThemableEnum.Exploitation:
                    themeRooms = rooms.filter(x=> x.room().roAttributionTypeId === RoomAttributionTypeEnum.Exploitation)
                    break;
                default:
                    themeRooms = rooms.filter(x=> x.room().roAttributionTypeId === RoomAttributionTypeEnum.None)
                    break;
            }

            const area = XcMaths.round(ArrayUtils.sumBy(themeRooms.map(x=> x.roomSet), "area"), 2);
            const areaString = area + " m²";
            tc.subLabel = areaString;

            themeRooms.forEach(r => {
                if (tc.hidden) {
                    r.setFill("#ffffff");
                } else {
                    r.setFill(themeColor);
                }
            });
        });
    }

    setRoomActivityStatusColor(rooms: BpSvgRoom[]): void {
        //const tmp: DisplayThemeCaption[] = [];

        // Récupère le thème
        //const theme = this.displayThemes.find(x=> x.themeView.diThId === DisplayThemeEnum.ActivityStatus);
        const theme = this.selectedTheme
        if (!theme) return;
        // Récupère les items de légende standard pour le thème
        let themeCaptions = theme.captions;

        // Isole les patterns
        const patterns = themeCaptions.map(x=> x.pattern).filter(x=> x) as SvgPattern[];
        // Injecte les patterns dans le svg
        this.blueprint.setPatterns(patterns);

        themeCaptions.forEach(tc => {
            const themeColor = tc.getPatternUrlRef();
            let themeRooms: BpSvgRoom[];
            switch (tc.id) {
                case DisplayThemableEnum.Empty:
                    themeRooms = rooms.filter(x=> x.room().roActivityStatusTypeId === RoomActivityStatusTypeEnum.Empty)
                    break;
                case DisplayThemableEnum.UnderConstruction:
                    themeRooms = rooms.filter(x=> x.room().roActivityStatusTypeId === RoomActivityStatusTypeEnum.UnderConstruction)
                    break;
                default:
                    themeRooms = rooms.filter(x=> x.room().roActivityStatusTypeId === RoomActivityStatusTypeEnum.Usable)
                    break;
            }

            const area = XcMaths.round(ArrayUtils.sumBy(themeRooms.map(x=> x.roomSet), "area"), 2);
            const areaString = area + " m²";
            tc.subLabel = areaString;

            themeRooms.forEach(r => {
                if (tc.hidden) {
                    r.setFill("#ffffff");
                } else {
                    r.setFill(themeColor);
                }
            });
        });
    }

    setRoomMixedCaption(rooms: BpSvgRoom[]): void {
        const tmp: DisplayThemeCaption[] = [];

        // Récupère le thème
        //const theme = this.displayThemes.find(x=> x.themeView.diThId === DisplayThemeEnum.Mixed);
        const theme = this.selectedTheme
        if (!theme) return;
        // Récupère les items de légende standard pour le thème
        let themeCaptions = theme.captions;

        // Isole les patterns
        const patterns = themeCaptions.map(x=> x.pattern).filter(x=> x) as SvgPattern[];
        // Injecte les patterns dans le svg
        this.blueprint.setPatterns(patterns);

        (themeCaptions.filter(x=> !x.isDynamicDataGenerated)).forEach(tc => {
            let themeRooms: BpSvgRoom[];
            switch (tc.id) {
                case DisplayThemableEnum.Empty:
                    themeRooms = rooms.filter(x=> x.room().roActivityStatusTypeId === RoomActivityStatusTypeEnum.Empty)
                    break;
                case DisplayThemableEnum.UnderConstruction:
                    themeRooms = rooms.filter(x=> x.room().roActivityStatusTypeId=== RoomActivityStatusTypeEnum.UnderConstruction)
                    break;
                case DisplayThemableEnum.Shared:
                    themeRooms = rooms.filter(x=> x.room().roAttributionTypeId === RoomAttributionTypeEnum.Sharing)
                    break;
                case DisplayThemableEnum.Exploitation:
                    themeRooms = rooms.filter(x=> x.room().roAttributionTypeId === RoomAttributionTypeEnum.Exploitation)
                    break;
                default:
                    themeRooms = rooms.filter(x=> x.room().roAttributionTypeId === RoomAttributionTypeEnum.Allocation && x.allocations().length > 1);
                    break;
            }

            const area = XcMaths.round(ArrayUtils.sumBy(themeRooms.map(x=> x.roomSet), "area"), 2);
            const areaString = area + " m²";
            tc.subLabel = areaString;
        });

        // Isole les surfaces allouées à une seule entité
        const singleAllocatedRooms = rooms.filter(x=> x.room().roAttributionTypeId === RoomAttributionTypeEnum.Allocation && x.roomSet?.allocationsSet.length === 1);

        // Rapport des surfaces allouées à une seule entité
        // Isole les entités distinctes
        const allocations = singleAllocatedRooms.map(x=> x.allocations()[0]);
        const buIds = allocations.map(x=> x?.dataSet.buUnId);
        const distinctBuIds = [...new Set(buIds)];
        distinctBuIds.forEach(buId => {
            const area = XcMaths.round(singleAllocatedRooms.reduce((sum, x) => sum + (x.roomSet?.allocationsSet[0].dataSet.buUnId === buId ? x.area() : 0), 0), 2);
            const areaString = area + " m²";
            const a = allocations.find(x=> x.dataSet.buUnId === buId);
            if (a) {
                const newItem = DisplayThemeCaption.fromBlueprintData(DisplayThemeEnum.Allocations, a.dataSet.buUnId, a.dataSet.buUnName, areaString, a.dataSet.buUnColor);
                // On vérifie que l'item de légende n'est pas déjà présente
                const existingDynamicCaption = themeCaptions.find(x=> x.mainLabel === newItem.mainLabel);
                if (existingDynamicCaption == null) {
                    tmp.push(newItem);
                }
            }
        });

        if (tmp.length > 0) {
            theme.captions = themeCaptions.concat(tmp);
        }
    }

    setRoomMixedColor(rooms: BpSvgRoom[]): void {
        // Récupère le thème
        //const theme = this.displayThemes.find(x=> x.themeView.diThId === DisplayThemeEnum.Mixed);
        const theme = this.selectedTheme
        if (!theme) return;
        // Récupère les items de légende standard pour le thème
        let themeCaptions = theme.captions;

        (themeCaptions.filter(x=> !x.isDynamicDataGenerated)).forEach(tc => {
            const themeColor = tc.getPatternUrlRef();
            let themeRooms: BpSvgRoom[];
            switch (tc.id) {
                case DisplayThemableEnum.Empty:
                    themeRooms = rooms.filter(x=> x.room().roActivityStatusTypeId === RoomActivityStatusTypeEnum.Empty)
                    break;
                case DisplayThemableEnum.UnderConstruction:
                    themeRooms = rooms.filter(x=> x.room().roActivityStatusTypeId === RoomActivityStatusTypeEnum.UnderConstruction)
                    break;
                case DisplayThemableEnum.Shared:
                    themeRooms = rooms.filter(x=> x.room().roAttributionTypeId === RoomAttributionTypeEnum.Sharing)
                    break;
                case DisplayThemableEnum.Exploitation:
                    themeRooms = rooms.filter(x=> x.room().roAttributionTypeId === RoomAttributionTypeEnum.Exploitation)
                    break;
                default:
                    themeRooms = rooms.filter(x=> x.room().roAttributionTypeId === RoomAttributionTypeEnum.Allocation && x.allocations().length > 1);
                    break;
            }

            themeRooms.forEach(r => {
                if (tc.hidden) {
                    r.setFill("#ffffff");
                } else {
                    r.setFill(themeColor);
                }
            });
        });

        // Isole les surfaces allouées à plusieurs entités
        const multiAllocatedThemeCaption = themeCaptions.find(x=> x.id === DisplayThemableEnum.MultiAllocated);
        if (!multiAllocatedThemeCaption) return;
        const multiAllocatedRooms = rooms.filter(x=> x.room().roAttributionTypeId === RoomAttributionTypeEnum.Allocation && x.allocations().length > 1);
        // Mappe la couleur de légende
        const multiAllocatedColor = multiAllocatedThemeCaption.getPatternUrlRef();
        multiAllocatedRooms.forEach(r => {
            if (multiAllocatedThemeCaption.hidden) {
                r.setFill("#ffffff");
            } else {
                r.setFill(multiAllocatedColor);
            }
        });

        // Isole les surfaces allouées à une seule entité
        const singleAllocatedRooms = rooms.filter(x=> x.room().roAttributionTypeId === RoomAttributionTypeEnum.Allocation && x.roomSet?.allocationsSet.length === 1);
        //const singleAllocations: PocRoomAllocation[] = [];
        singleAllocatedRooms.forEach(r => {
            const allocation = r.allocations()[0];
            const singleAllocatedThemeCaption = themeCaptions.find(x=> x.mainLabel === allocation.dataSet.buUnName);
            //singleAllocations.push(r.room.allocations[0]);
            if (singleAllocatedThemeCaption?.hidden) {
                r.setFill("#ffffff");
            } else {
                r.setFill(allocation.dataSet.buUnColor);
            }
        });
    }

    setWorkplaceColor(): void {
        if (!this.selectedTheme) return;

        const labels = this.blueprint.layersController.workplaceLabels();

        // Récupère les items de légende standard pour le thème
        let themeCaptions = this.selectedTheme.captions;

        themeCaptions.forEach(tc => {
            const themeLabels = labels.filter(x=> x.workplace?.dataSet.woWorkplaceType === tc.id);

            tc.subLabel = themeLabels.length.toString();

            // On supprime le pattern pour la légende pour utiliser une simple couleur
            tc.pattern = undefined;

            themeLabels.forEach(l => {
                if (tc.hidden) {
                    l.resetDisplay();
                } else {
                    l.setDisplay(tc.color, GraphicalDefaultValues.LabelBackgroundOpacity);
                }
            });
        });
    }

    setPlanningTheme(taskId: number, themeCaptions: DisplayThemeCaption[]): void {
        // Récupère les items de légende standard pour le thème
        //let themeCaptions = theme.captions;

        const captionIds = themeCaptions.map(x=> x.id);

        const equipments = this.blueprint.layersController.equipments(taskId);
        const themedEquipments = equipments.filter(x=> captionIds.includes(x.dataStateId));
        const unthemedEquipments = equipments.filter(x=> !captionIds.includes(x.dataStateId));

        const workplaceLabels = this.blueprint.layersController.workplaceLabels(taskId);
        const themedWorkplaceLabels = workplaceLabels.filter((x: any)=> captionIds.includes(x.dataStateId));
        const unthemedWorkplaceLabels = workplaceLabels.filter((x: any)=> !captionIds.includes(x.dataStateId));

        const walls = this.blueprint.layersController.wallsLayer(taskId)?.typedData();
        if (!walls) return;
        const themedWalls = walls.filter(x=> captionIds.includes(x.dataStateId));
        const unthemedWalls = walls.filter(x=> !captionIds.includes(x.dataStateId));

        themeCaptions.forEach(tc => {
            // On supprime le pattern pour la légende pour utiliser une simple couleur
            tc.pattern = undefined;

            if (!tc.hidden) {
                const themeEquipments = themedEquipments.filter(x=> x.dataStateId === tc.id);
                themeEquipments.forEach(e => {
                    e.setThemeColor(tc.color);
                });

                const themeWorkplaceLabels = themedWorkplaceLabels.filter((x: any)=> x.dataStateId === tc.id);
                themeWorkplaceLabels.forEach(l => {
                    l.setDisplay(tc.color, 0.5);
                });

                const themeWalls = themedWalls.filter(x=> x.dataStateId === tc.id);
                themeWalls.forEach(w => {
                    w.setColor(tc.color);
                });
            }
        });


        unthemedEquipments.forEach(e => {
            e.resetThemeColor();
        });

        unthemedWorkplaceLabels.forEach(e => {
            e.resetDisplay();
        });

        unthemedWalls.forEach(e => {
            e.resetColor();
        });

    }

    setEquipmentsProvidersTheme(taskId: number, themeCaptions: DisplayThemeCaption[]): void {
        const equipments = this.blueprint.layersController.equipments(taskId);
        // TODO : monter une méthode pour séparer un tableau en deux (ou plus) selon un critère en ne le parcourant qu'une seule fois
        const themedEquipments = equipments.filter((x: any)=> x.dataStateId !== FloorDataStateEnum.Deleted);
        const unthemedEquipments = equipments.filter((x: any)=> x.dataStateId === FloorDataStateEnum.Deleted);

        themeCaptions.forEach(tc => {
            // On supprime le pattern pour la légende pour utiliser une simple couleur
            tc.pattern = undefined;

            const themeEquipments = themedEquipments.filter(x=> x.def?.equipmentProviderId === tc.id);
            if (themeEquipments.length === 0) {
                // Il n'y a pas d'équipement de ce fournisseur sur le plan, on retire l'entrée de légende
                tc.removedFromList = true;
            }else {
                tc.removedFromList = false;
                tc.subLabel =  this.getCountSubLabelText(themeEquipments.length);

                themeEquipments.forEach(e => {
                    if (tc.hidden) {
                        e.resetThemeColor();
                    } else {
                        e.setThemeColor(tc.color);
                    }
                });
            }
        });

        unthemedEquipments.forEach(e => {
            e.show(false);
        });
    }

    setEquipmentTheme(taskId: number, theme: {themeView: DisplayThemeView, captions: DisplayThemeCaption[]}): void {
        const equipments = this.blueprint.layersController.equipments(taskId);

        // Si le thème est "Uninstalled" on force l'affichage
        if (theme.themeView.diThId === DisplayThemeEnum.ReconcileUninstalled || theme.themeView.diThId === DisplayThemeEnum.InstallationUninstalled) {
            equipments.forEach(e => {
                e.show(true);
            });
        } else {
            equipments.forEach(e => {
                if (e.dataStateId === FloorDataStateEnum.Deleted) {
                    e.show(false);
                }
            });
        }

        // Récupère les items de légende standard pour le thème
        let themeCaptions = theme.captions;

        const captionIds = themeCaptions.map(x=> x.id);
        const themedEquipments = equipments.filter(x=> captionIds.includes(x.planningStateId));
        const unthemedEquipments = equipments.filter(x=> !captionIds.includes(x.planningStateId));

        themeCaptions.forEach(tc => {
            let themeEquipments = themedEquipments.filter(x=> x.planningStateId === tc.id);

            tc.subLabel = themeEquipments.length.toString();

            // On supprime le pattern pour la légende pour utiliser une simple couleur
            tc.pattern = undefined;

            themeEquipments.forEach(e => {
                if (tc.hidden) {
                    e.resetThemeColor();
                } else {
                    e.setThemeColor(tc.color);
                }
            });
        });

        unthemedEquipments.forEach(e => {
            e.resetThemeColor();
        });
    }

    getTopBottomRooms(): BpSvgRoom[] {
        const taskIds = this.blueprint.topAndBottomTaskIds();
        let rooms: BpSvgRoom[];
        // if (topMostTaskOnly) {
        //     this.blueprint.layersController.roomsLayer(taskIds[0])?.resetRoomsColor();
        //     rooms = this.blueprint.layersController.rooms(taskIds[1]);
        // } else {
            rooms = this.blueprint.layersController.topBottomDistinctSvgRooms(taskIds[0], taskIds[1]);
        // }
        return rooms;
    }

    getCountSubLabelText(count: number): string {
        switch (count) {
            case 0:
                return "Aucun élément"
            case 1:
                return count + " élément"
            default:
                return count + " éléments"
        }
    }

    onThemeItemHideButtonClick(item: DisplayThemeCaption): void {
        item.hidden = true;
        this.refreshDisplayReport(false);
    }

    onThemeItemDisplayButtonClick(item: DisplayThemeCaption): void {
        // Si l'item de légende est masqué, on le réaffiche
        // s'il est déjà affiché, on l'isole
        // c'est-à-dire qu'on masque tous les autres
        if (item.hidden) {
        } else {
            this.selectedTheme?.captions.forEach(c => {
                c.hidden = true;
            });
        }
        item.hidden = false;

        this.refreshDisplayReport(false);
    }

    hasHiddenItems(): boolean {
        if (this.selectedTheme != null) {
            for (const c of this.selectedTheme.captions) {
                if (c.hidden) {
                    return true;
                }
            }
        }

        return false;
    }

    onShowAllButtonClick(): void {
        this.selectedTheme?.captions.forEach(c => {
            c.hidden = false;
        });

        this.refreshDisplayReport(false);
    }

}