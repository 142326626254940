import { DynTableVM } from "src/app/components-lib/dyn-grid/model/dyn-table-vm";
import { DynTableVMBuilder } from "src/app/components-lib/dyn-grid/model/dyn-table-vm-builder";
import { AppUserService } from "src/app/core/services/backend-services/app-user-service";
import { DyntService } from "src/app/core/services/backend-services/dynt-service";
import { PaginationService } from "src/app/core/services/backend-services/pagination-service";
import Container from "typedi";
import { MatDialog } from "@angular/material/dialog";
import { CreateUserDialogVM } from "./create-user-dialog-vm";
import { CreateUserDialogComponent } from "../view/create-user-dialog/create-user-dialog.component";
import { PageModel } from "src/app/ui/main/model/page-model";
import { take } from "rxjs";
import { AppUserTable } from "src/app/core/model/db-model/tables/app-user-table";
import { FieldListItem } from "src/app/components-lib/shared-model/field-list-item";
import { ListField } from "src/app/components-lib/shared-model/list-field";
import { AppRole } from "src/app/core/model/data-model/tables/app-role";
import { TablesNamesEnum } from "src/app/core/model/db-model/tables-names-enum";
import { AppRoleTable } from "src/app/core/model/db-model/tables/app-role-table";
import { Perimeter } from "src/app/core/model/data-model/tables/perimeter";
import { PerimeterTable } from "src/app/core/model/db-model/tables/perimeter-table";
import { AppUser } from "src/app/core/model/data-model/tables/app-user";
import { AppMenuRouteEnum } from "src/app/core/model/data-model/enums/app-menu-route-enum";
import { toastWarn } from "src/app/core/services/toast-service";

export class UserAccountAdminVM extends PageModel {
    adminTable: DynTableVM | undefined;
    dialog: MatDialog | undefined;
    waitingForResult: boolean = false;

    private constructor() {
        super(AppMenuRouteEnum.administration_useraccount, 0, "a257d98b-d73c-4036-8e97-bd017fde5008");
    }

    static async newAsync(): Promise<UserAccountAdminVM> {
        const result = new UserAccountAdminVM();

        await result.loadTable();

        return result;
    }

    async loadTable(): Promise<void> {
        this.adminTable = await this.loadAdminData();
    }

    async loadAdminData(): Promise<DynTableVM> {
        const tableName = "app_user_dynview";
        const s = Container.get(DyntService);
        const viewSet = await s.contextedDataSet(tableName);
        const p = Container.get(PaginationService);
        const paginator = await p.getPaginator(tableName);
        const adminTable = DynTableVMBuilder.getVM(viewSet, tableName, paginator);

        // Chargement des valeurs actuelles pour les rôles
        const rolesIds = viewSet.viewData.map(x=> (x as any)[AppUserTable.apUsRoleId]);
        const roles = await s.downloadTable<AppRole>(TablesNamesEnum.AppRole, undefined, AppRoleTable.apRoId, rolesIds);
        const currentRolesOptions = roles.map(x=> new FieldListItem(x.apRoId, x.apRoName, null));
        adminTable.setSelectCurrentOptions(AppUserTable.apUsRoleId, currentRolesOptions);

        // Chargement des valeurs actuelles pour les Périmètres
        const perimetersIds = viewSet.viewData.map(x=> (x as any)[AppUserTable.apUsPerimeterId]);
        const perimeters = await s.downloadTable<Perimeter>(TablesNamesEnum.Perimeter, undefined, PerimeterTable.peId, perimetersIds);
        const currentPerimetersOptions = perimeters.map(x=> new FieldListItem(x.peId, x.peName, null));
        currentPerimetersOptions.push(new FieldListItem(null, "NR", null));
        adminTable.setSelectCurrentOptions(AppUserTable.apUsPerimeterId, currentPerimetersOptions);
        
        adminTable.loadOptionsRequested = async (f: ListField, rowId: number) => {
            if (f.colId === AppUserTable.apUsRoleId) {
                // Sélectionne les rôles
                const roles = await s.downloadTable<AppRole>(AppRoleTable.databaseTableName);
                return roles.map(x=> new FieldListItem(x.apRoId, x.apRoName, null));
            }

            // Sélectionne les périmètres
            const perimeters = await s.downloadTable<Perimeter>(PerimeterTable.databaseTableName);
            const pResults = [new FieldListItem(null, "NR", null)];
            pResults.push(...perimeters.map(x=> new FieldListItem(x.peId, x.peName, null)));
            return pResults;
    }
        
        // On substitue à la méthode d'insertion standard une méthode spécifique adaptée à cette table
        //adminTable.overrideInsertMethod(this.insertMethod);
        adminTable.newRowAddRequested = async () => {
            await this.insertMethod();
        }

        adminTable.rowDeleteRequested = async (tableName: string, rowId: number) => {
            const s = Container.get(AppUserService);
            const result = await s.deleteUserAccount(rowId);
            console.log("delete", rowId);
            if (result) {
                return true;
            }
            return false;
        }

        return adminTable;
    }

    insertMethod: any = () => {
        if (this.dialog) {
            const dialogRef = this.dialog.open(CreateUserDialogComponent, {
                data: new CreateUserDialogVM(), width: "500px"
            });

            // Le .pipe(take(1)) s'assure que le traitement ne sera exécuté qu'une seule fois
            dialogRef.afterClosed().pipe(take(1)).subscribe(async (data: {DirectoryId: number, RoleId: number, PerimeterId: number | undefined, Email: string, IsCompanyAdmin: boolean, Caller: string}) => {
                if (data) {
                    // On vérifie que le user n'existe pas déjà dana la table
                    const newDirectoryId = data.DirectoryId;
                    const ds = Container.get(DyntService);
                    const existingAppUser = await ds.downloadRow<AppUser>(TablesNamesEnum.AppUser, undefined, AppUserTable.apUsDirectoryId, newDirectoryId);
                    if (existingAppUser != null) {
                        toastWarn("Ce compte utilisateur existe déjà !");
                        return;
                    }

                    const s = Container.get(AppUserService);
                    this.waitingForResult = true;
                    const result = await s.createUserAccount(data);
                    if (result != null) {
                        // Recharge la table
                        await this.loadTable();
                    }
                    this.waitingForResult = false;
                }
            })
        }
    }
}