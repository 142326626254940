import { SelectableRealEstateTreeNode } from './../selectable-real-estate-tree/model/selectable-real-estate-tree-node';
import { SelectableRealEstateTreeVM } from './../selectable-real-estate-tree/model/selectable-real-estate-tree-vm';
import { SimulationCreationData } from './simulation-creation-data';

export class CreationStepTwoVM {
    realEstateTree: SelectableRealEstateTreeVM = new SelectableRealEstateTreeVM();
    creationData: SimulationCreationData;

    constructor(creationData: SimulationCreationData) {
        this.creationData = creationData
        this.realEstateTree.selectedBuildingsChanged = (selectedbuildings: SelectableRealEstateTreeNode[]) => {
            this.creationData.selectedBuildings = selectedbuildings;
        }
    }

    async loadDatas(): Promise<void> {
        await this.realEstateTree.loadTree([]);
        //await this.realEstateTree.loadTree(this.creationData.selectedBus.map(x=> x.bu.buUnId));
        //this.creationData.selectedbuildings = this.realEstateTree.getSelectedBuildings();

        // On mappe les éléments éventuellement précédemment sélectionnés
        // en cas de retour arrière
        this.realEstateTree.selectBuildings(this.creationData.selectedBuildings.map(x=> x.id));
    }
}