import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PictosPanel } from '../../../model/side-panel/pictos-panel';
import { MaterialModule } from 'src/app/core/material.module';
import { CadConvSvgGroupComponent } from '../../cad-conv-svg-group/cad-conv-svg-group.component';

@Component({
  selector: 'xc-pictos-panel',
  standalone: true,
  imports: [CommonModule, MaterialModule, CadConvSvgGroupComponent],
  templateUrl: './pictos-panel.component.html',
  styleUrls: ['./pictos-panel.component.scss']
})
export class PictosPanelComponent {
  @Input() model: PictosPanel | undefined;
}
