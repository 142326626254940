import { Component, Input } from '@angular/core';
import { CatalogPage } from '../../model/catalog-page';
import { MVVMEventDestroyLifeCycle } from 'src/app/components-lib/shared-model/mvvm-events-destroy-lifecycle';
import { SplitterModule } from 'primeng/splitter';
import { CommonModule } from '@angular/common';
import { InventoryTreeComponent } from 'src/app/ui/pages/layout/inventory/catalog/view/inventory-tree/inventory-tree.component';
import { InventoryTabPagerComponent } from 'src/app/ui/pages/layout/inventory/catalog/pager/view/inventory-tab-pager/inventory-tab-pager.component';

@Component({
  selector: 'xc-catalog-page',
  standalone: true,
  imports: [CommonModule, SplitterModule, InventoryTreeComponent, InventoryTabPagerComponent],
  templateUrl: './catalog-page.component.html',
  styleUrl: './catalog-page.component.scss'
})
export class CatalogPageComponent extends MVVMEventDestroyLifeCycle {
  @Input() override model: CatalogPage | undefined;
}
