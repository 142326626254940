import { PageModel } from "src/app/ui/main/model/page-model";
import { DrawingBoardVM } from "./drawing-board-vm";
import { PictosBrowser } from "./picto-browser";
import { AppMenuRouteEnum } from "src/app/core/model/data-model/enums/app-menu-route-enum";

export class DrawingContainerModel extends PageModel {
    drawingBoard: DrawingBoardVM  | undefined;
    pictoBrowser: PictosBrowser | undefined;

    constructor() {
        super(AppMenuRouteEnum.graphicworks_pictograms, 0, "0f7a7c2e-99ef-4c94-91ec-82f29b9c6d2b");
    }
    
    async initialize(): Promise<void> {
        this.drawingBoard = new DrawingBoardVM();
        this.pictoBrowser = new PictosBrowser();
        await this.pictoBrowser.initialize();
    }
}