import { Component, Input } from '@angular/core';
import { MVVMEventDestroyLifeCycle } from 'src/app/components-lib/shared-model/mvvm-events-destroy-lifecycle';
import { BuildingsToolbarVM } from '../buildings-toolbar-vm';
import { InputGroupModule } from 'primeng/inputgroup';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { SelectButtonModule } from 'primeng/selectbutton';
import { FormsModule } from '@angular/forms';
import { RadioButtonModule } from 'primeng/radiobutton';
import { InputGroupAddonModule } from 'primeng/inputgroupaddon';

@Component({
  selector: 'xc-buildings-toolbar',
  standalone: true,
  imports: [InputGroupModule, InputGroupAddonModule, AutoCompleteModule, SelectButtonModule, FormsModule, RadioButtonModule],
  templateUrl: './buildings-toolbar.component.html',
  styleUrl: './buildings-toolbar.component.scss'
})
export class BuildingsToolbarComponent extends MVVMEventDestroyLifeCycle {
  @Input() override model: BuildingsToolbarVM | undefined;
}
