import { ViewsNames } from 'src/app/core/model/db-model/views-names-enum';
import { AppRole } from "src/app/core/model/data-model/tables/app-role";
import { GrantAdminCategoryEnum } from "./grant-admin-category-enum";
import { GrantAdminPageModel } from "./grant-admin-page-model";
import { BlueprintUpdateGrantsVM } from "./blueprint-update-grants-vm";
import Container from "typedi";
import { DyntService } from "src/app/core/services/backend-services/dynt-service";
import { AppGrantTable } from "src/app/core/model/db-model/tables/app-grant-table";
import { AppGrantView } from 'src/app/core/model/data-model/views/app-grant-view';
import { AppRoleGrant } from 'src/app/core/model/data-model/tables/app-role-grant';
import { TablesNamesEnum } from 'src/app/core/model/db-model/tables-names-enum';
import { AppRoleGrantTable } from 'src/app/core/model/db-model/tables/app-role-grant-table';
import { TabViewChangeEvent } from 'primeng/tabview';
import { LayersGrantsVM } from './layers-grants-vm';
import { PerimetersGrantsVM } from './perimeters-grants-vm';

export class GrantsAdminVM {
    roles: AppRole[] = [];
    selectedRole: AppRole | undefined;
    selectedTabIndex: number = 0;
    selectedPage: GrantAdminPageModel | undefined;
    allGrants: AppGrantView[] = [];

    private constructor() {
    }

    static async newAsync(): Promise<GrantsAdminVM> {
        const result = new GrantsAdminVM();

        await result.loadRoles();

        return result;
    }

    async loadRoles(): Promise<void> {
        const s = Container.get(DyntService);
        this.roles = await s.downloadTable<AppRole>(TablesNamesEnum.AppRole);
    }

    async selectedRoleChanged(): Promise<void> {
        await this.loadPage();
    }

    async selectedTabChange(e: TabViewChangeEvent): Promise<void> {
        this.selectedTabIndex = e.index;
        await this.loadPage();
    }

    private async loadPage(): Promise<void> {
        if (!this.selectedRole) return;
        switch (this.selectedTabIndex) {
            case GrantAdminCategoryEnum.layers:
                this.selectedPage = await LayersGrantsVM.newAsync(this.selectedRole.apRoId);
                break;
            case GrantAdminCategoryEnum.businessUnits:
                break;
            case GrantAdminCategoryEnum.pages:
                break;
            case GrantAdminCategoryEnum.perimeters:
                this.selectedPage = await PerimetersGrantsVM.newAsync(this.selectedRole.apRoId);
                break;
            case GrantAdminCategoryEnum.blueprintUpdate:
                await this.loadBlueprintPage(this.selectedRole.apRoId);
                break;
            default:
                break;
        }
    }

    async loadGrants(): Promise<void> {

    }

    async loadBlueprintPage(roleId: number): Promise<void> {
        const s = Container.get(DyntService);
        // TODO : revoir les groupes de droits et constituer une énumération
        const allGrants = await s.downloadTable<AppGrantView>(ViewsNames.AppGrantView, undefined, AppGrantTable.apGrGroupId, 20);
        const roleGrants = await s.downloadTable<AppRoleGrant>(TablesNamesEnum.AppRoleGrant, undefined, AppRoleGrantTable.apRoGrRoleId, this.selectedRole?.apRoId);
        this.selectedPage = new BlueprintUpdateGrantsVM(roleId, allGrants, roleGrants);
    }

    // Pour le moment on laisse de côté les habilitations sur les tables et les colonnes
    // c'est potentiellement obsolète

    // Les options disponibles doivent avoir été préalablement filtrées par le plan de souscription
    // Valables dans tous les cas :
    //    Modification sur le plan
    //    Périmètres
    //    Entités
    // Dépendant du plan de souscription
    //    Calques. Non disponible en dessous du plan Mobilité puisque les modifications sur le plan sont au minimum contraintes aux changements sur le mobilier
    //    Navigation. N'activer que les pages accessibles dans le plan de souscription
}