import { Field } from './field';
import { FieldTypeEnum } from './field-type-enum';
import { ZColumnView } from 'src/app/core/model/data-model/views/z-column-view';
import { ZColumnConstraintView } from 'src/app/core/model/data-model/views/z-column-constraint-view';

export class AnyField extends Field<any> {

    constructor(colId: string, rowId: any, value: any, definition: ZColumnView, constraints: ZColumnConstraintView[], readOnlyByScope: boolean) {
        super(FieldTypeEnum.none, colId, rowId, value, definition, constraints, readOnlyByScope);
    }
}