import { FloorTable } from "../tables/floor-table";

export const SimulationFloorDbView = {
    databaseTableName: "simulation_floor_view",
    flCode: FloorTable.flCode, 
    flElevation: FloorTable.flElevation, 
    siFlViId: "SiFlVi_Id", 
    siFlViRootSimulation: "SiFlVi_RootSimulation",
    siFlViFloor: "SiFlVi_Floor", 
    siFlViArea: "SiFlVi_Area", 
    siFlViBuildingId: "SiFlVi_BuildingId"
}