import { logError } from "../../services/logging-service";

export function buildModel(model: any, dtoData: any, tableDef: any[]): void {
    if (tableDef.length === 0) {
        logError("ModelBase : aucune définition de table n'a été passée");
        return;
    }

    // Pour chaque table de référence...
    tableDef.forEach(td => {
        // pour chaque propriété de la table de référence...
        for(const key of Object.keys(td)) {
            const dbColumnName = td[key as keyof typeof td];
            model[key] = dtoData[dbColumnName];
        }       
    });

    // Elimine les propriétés qui n'ont pas été initialisées parce qu'elles ne se trouvent pas dans le dto data set
    Object.keys(model).forEach(key => model[key] === undefined && delete model[key]);
}