import { readableUUID } from "src/app/core/events/event-listener-uuid";
import { AppMenuRouteEnum } from "src/app/core/model/data-model/enums/app-menu-route-enum";
import { TablesNamesEnum } from "src/app/core/model/db-model/tables-names-enum";
import { DyntService } from "src/app/core/services/backend-services/dynt-service";
import { PageModel } from "src/app/ui/main/model/page-model";
import Container from "typedi";
import { DonutChartWrapperVM } from "src/app/ui/shared/charts/donut/model/donut-chart-wrapper-vm";
import { SiteActivityStatusView } from "src/app/core/model/data-model/views/site-activity-status-view";
import { SiteAnatomyView } from "src/app/core/model/data-model/views/site-anatomy-view";
import { SiteAttributionView } from "src/app/core/model/data-model/views/site-attribution-view";
import { SiteEquipmentProviderView } from "src/app/core/model/data-model/views/site-equipment-provider-view";
import { BuildingTable } from "src/app/core/model/db-model/tables/building-table";
import { ViewsNames } from "src/app/core/model/db-model/views-names-enum";
import { ChartBuilder } from "src/app/ui/shared/shared-model/chart-builder";
import { RealEstateAnatomyView } from "src/app/core/model/data-model/views/real-estate-anatomy-view";
import { RealEstateActivityStatusView } from "src/app/core/model/data-model/views/real-estate-activity-status-view";
import { RealEstateAttributionView } from "src/app/core/model/data-model/views/real-estate-attribution-view";
import { RealEstateEquipmentProviderView } from "src/app/core/model/data-model/views/real-estate-equipment-provider-view";
import { ReferentialReportWrapper } from "src/app/ui/shared/charts/referential/referential-report-wrapper";
import { ReportGridItem } from "src/app/components-lib/report-grid/model/report-grid-item";
import { ReportGridNumberItem } from "src/app/components-lib/report-grid/model/report-grid-number-item";
import { SiteView } from "src/app/core/model/data-model/views/site-view";
import { SiteTable } from "src/app/core/model/db-model/tables/site-table";
import { XcMaths } from "src/app/core/model/static-functions/xc-maths";
import { ITabPageContent } from "src/app/components-lib/tab-page-container/model/i-tab-page-content";
import { RealEstateChartTypeEnum } from "../charts/shared-model/real-estate-chart-type-enum";
import { AnonymousChartDataBuilder } from "../charts/anonymous-chart-data-builder";
import { SiteAnonymousChartDataBuilder } from "./site-anonymous-chart-data-builder";
import { MainEventsEnum } from "src/app/ui/main/model/main-events-enum";
import { SitesToolbarVM } from "./sites-toolbar-vm";
import { SitesEventsEnum } from "./sites-events-enum";
import { RealEstateService } from "src/app/core/services/backend-services/real-estate-service";
import { IInitRowEditable } from "src/app/components-lib/dyn-grid/model/i-init-row-editable";
import { DynTableRow } from "src/app/components-lib/dyn-grid/model/dyn-table-row";

export class SitesVM extends PageModel {
    selectedSite: {id: number, name: string} | null = null;

    toolbar: SitesToolbarVM | undefined;

    options: {id: string, label: string}[] = [];
    selectedOption: {id: string, label: string} | undefined;

    optionTypesEnum = RealEstateChartTypeEnum;

    currentView: ITabPageContent | undefined;

    private constructor() {
        super(AppMenuRouteEnum.layout_realestate_sites, 0, readableUUID(SitesVM.name));

        this.addEventListener(SitesEventsEnum.selecteSiteChange, async (selectedSite: {id: number, name: string} | null) => {
            this.selectedSite = selectedSite;
            if (this.selectedOption) await this.loadView(this.selectedOption.id);
        });

        this.addEventListener(SitesEventsEnum.selectedOptionChange, async (selectedOption: {id: string, label: string} | undefined) => {
            this.selectedOption = selectedOption;
            if (this.selectedOption) await this.loadView(this.selectedOption.id);
        });
    }

    static async newAsync(): Promise<SitesVM> {
        const tmp = new SitesVM();
        tmp.toolbar = await SitesToolbarVM.newAsync();
        tmp.emitEventAsync(MainEventsEnum.nestedToolbarAvailable, tmp.toolbar);
        return tmp;
    }

    async loadView(id: string): Promise<void> {
        if (this.selectedSite == null) return;

        switch (id) {
            case RealEstateChartTypeEnum.anatomy:
            case RealEstateChartTypeEnum.activities:
            case RealEstateChartTypeEnum.attributions:
            case RealEstateChartTypeEnum.inventory:
                await this.loadDonutChart(this.selectedSite.id, id);
                break;
            case RealEstateChartTypeEnum.referential:
                await this.loadRefDatas(this.selectedSite.id);
                break;
        }
    }

    async loadDonutChart(siteId: number, contentId: string): Promise<void> {
        const s = Container.get(DyntService);

        this.currentView = new DonutChartWrapperVM();
        switch (contentId) {
            case RealEstateChartTypeEnum.anatomy:
                let ana: SiteAnatomyView[] | RealEstateAnatomyView[];
                if (this.selectedSite?.id === 0) {
                    ana = await s.downloadTable<RealEstateAnatomyView>(ViewsNames.RealEstateAnatomyView);
                } else {
                    ana = await s.downloadTable<SiteAnatomyView>(ViewsNames.SiteAnatomyView, undefined, BuildingTable.buSiteId, siteId);
                }
                const anaChartData = await AnonymousChartDataBuilder.getAnatomyData(ana, 3);
                this.currentView.set(anaChartData);
                break;
            case RealEstateChartTypeEnum.activities:
                let ac: SiteActivityStatusView[] | RealEstateActivityStatusView[];
                if (this.selectedSite?.id === 0) {
                    ac = await s.downloadTable<RealEstateActivityStatusView>(ViewsNames.RealEstateActivityStatusView);
                } else {
                    ac = await s.downloadTable<SiteActivityStatusView>(ViewsNames.SiteActivityStatusView, undefined, BuildingTable.buSiteId, siteId);
                }
                const acAnonymous = SiteAnonymousChartDataBuilder.getActivityData(ac);
                const pieChartData = ChartBuilder.getActivityDonutChartData("", acAnonymous);
                this.currentView.set(pieChartData);
                break;
            case RealEstateChartTypeEnum.attributions:
                let at: SiteAttributionView[] | RealEstateAttributionView[];
                if (this.selectedSite?.id === 0) {
                    at = await s.downloadTable<RealEstateAttributionView>(ViewsNames.RealEstateAttributionView);
                } else {
                    at = await s.downloadTable<SiteAttributionView>(ViewsNames.SiteAttributionView, undefined, BuildingTable.buSiteId, siteId);
                }
                const atAnonymous = SiteAnonymousChartDataBuilder.getAttributionData(at);
                const attrPieChartData = ChartBuilder.getAttributionDonutChartData("", atAnonymous);
                this.currentView.set(attrPieChartData);
                break;
            case RealEstateChartTypeEnum.inventory:
                let inv: SiteEquipmentProviderView[] | RealEstateEquipmentProviderView[];
                if (this.selectedSite?.id === 0) {
                    inv = await s.downloadTable<RealEstateEquipmentProviderView>(ViewsNames.RealEstateEquipmentProviderView);
                } else {
                    inv = await s.downloadTable<SiteEquipmentProviderView>(ViewsNames.SiteEquipmentProviderView, undefined, BuildingTable.buSiteId, siteId);
                }
                const invAnonymous = SiteAnonymousChartDataBuilder.getInventoryData(inv);
                const invChartData = ChartBuilder.getInventoryDonutChartData("", invAnonymous, "€");
                this.currentView.set(invChartData);
                break;
            default:
                break;
        }
    }

    async loadRefDatas(siteId: number): Promise<void> {
        const s = Container.get(DyntService);

        let sitesCount: number = 0;
        let buildingsCount: number = 0;
        let grossArea: number;
        let netArea: number;
        let utilRatio: number;

        if (siteId === 0) {
            const sw = await s.downloadTable<SiteView>(ViewsNames.SiteView, undefined, SiteTable.stParentId, null);
            sitesCount = sw.length;
            grossArea = XcMaths.round(sw.map(x=> x.siViGLA).reduce((a, b)=>a + b), 0);
            netArea = XcMaths.round(sw.map(x=> x.siViNUA).reduce((a, b)=>a + b), 0);
            utilRatio = XcMaths.round(netArea * 100 / grossArea, 0);
        } else {
            const sw = await s.downloadRow<SiteView>(ViewsNames.SiteView, undefined, SiteTable.stId, siteId);
            buildingsCount = sw ? sw.siViBuildingsCount : 0;
            grossArea = XcMaths.round(sw ? sw.siViGLA : 0, 0);
            netArea = XcMaths.round(sw ? sw.siViNUA : 0, 0);
            utilRatio = XcMaths.round(netArea * 100 / grossArea, 0);
        }

        const rrw = await ReferentialReportWrapper.newAsync(crypto.randomUUID(), "", TablesNamesEnum.Site, siteId);
        const reportItems: ReportGridItem<any>[] = [];
        if (siteId === 0) {
            reportItems.push(new ReportGridNumberItem("Sites", sitesCount));
        } else {
            reportItems.push(new ReportGridNumberItem("Immeubles", buildingsCount));
        }
        reportItems.push(new ReportGridNumberItem("Surface brute", grossArea, "1.0-2", "m²"));
        reportItems.push(new ReportGridNumberItem("Surface nette", netArea, "1.0-2", "m²"));
        reportItems.push(new ReportGridNumberItem("Ratio utile", utilRatio, undefined, "%"));
        rrw.setReportGrid(reportItems);

        if (rrw.adminTable) {
            const at = rrw.adminTable;
            at.newRowSaveRequested = async (values : any) => {
                let result: any;
                const t = Container.get(RealEstateService);
                if (siteId === 0) {
                    result = await t.createNewSite(
                        values[SiteTable.stCode], 
                        values[SiteTable.stName], 
                        values[SiteTable.stIsActive], 
                        values[SiteTable.stIsFictive], 
                        values[SiteTable.stLocation]
                    );
                } else {
                    result = await t.createNewBuilding(
                        values[BuildingTable.buSiteId], 
                        values[BuildingTable.buCode], 
                        values[BuildingTable.buName], 
                        values[BuildingTable.buIsActive], 
                        values[BuildingTable.buIsFictive], 
                        values[BuildingTable.buLocation], 
                        values[BuildingTable.buDeliveryAddressId], 
                        values[BuildingTable.buLobbyAddressId], 
                        values[BuildingTable.buParkingAddressId], 
                        values[BuildingTable.buMailingAddressId]
                    );
                }
                return result;
            }

            if (siteId !== 0) {
                // cas de l'ajout d'un immeuble
                // on insère l'id du site parent
                at.onBlankRowInserted = (row: DynTableRow): void => {
                    const siteIdColumn = row.fields.find(x=> x.colId === BuildingTable.buSiteId);
                    siteIdColumn!.value = siteId;
                }
            }
        }

        this.currentView = rrw;
    }
}