import { TaskContributorService } from './../../../../../../../core/services/backend-services/task-contributor-service';
import { TaskContributorDirectory } from "src/app/core/model/data-model/tables/task-contributor-directory";
import { TaskContributorView } from "src/app/core/model/data-model/views/task-contributor-view";
import { TaskFunctionView } from 'src/app/core/model/data-model/views/task-function-view';
import { TaskView } from "src/app/core/model/data-model/views/task-view";
import { directoryItemDisplayName, taskContributorDirectoryItemDisplayName } from "src/app/core/model/data-processing/people-processing";
import { TablesNamesEnum } from "src/app/core/model/db-model/tables-names-enum";
import { ViewsNames } from "src/app/core/model/db-model/views-names-enum";
import { TaskContributorDbView } from "src/app/core/model/db-model/views/task-contributor-db-view";
import { DyntService } from "src/app/core/services/backend-services/dynt-service";
import { toastWarn } from 'src/app/core/services/toast-service';
import Container from "typedi";

export class TaskContributorsVM {
    task: TaskView | undefined;
    contributors: {contributor: TaskContributorView, editing: boolean, initialLoad: number}[] = [];
    contributorsDirectory: TaskContributorDirectory[] = [];
    selectedContributorsDirectory: TaskContributorDirectory | undefined;
    taskFunctions: TaskFunctionView[] = [];
    selectedTaskFunction: TaskFunctionView | undefined;
    workload: number = 1;
    directoryItemDisplayName = directoryItemDisplayName;
    taskContributorDirectoryItemDisplayName = taskContributorDirectoryItemDisplayName;

    async set(task: TaskView): Promise<void> {
        this.task=task;
        await this.loadContributors();
        if (this.contributorsDirectory.length === 0) {
            this.loadRefData();
        }
    }
    
    async loadContributors(): Promise<void> {
        const s = Container.get(DyntService);
        const result = await s.downloadTable<TaskContributorView>(ViewsNames.TaskContributorView, undefined, TaskContributorDbView.taCrTaskId, this.task!.taId);
        this.contributors = result.map(x=> this.getContributor(x));
    }

    async loadRefData(): Promise<void> {
        const s = Container.get(DyntService);
        // On élimine du jeu de données les entrées où le contributeur est déclaré comme inactif
        this.contributorsDirectory = (await s.downloadTable<TaskContributorDirectory>(TablesNamesEnum.TaskContributorDirectory)).filter(x=> x.taCrDiIsActive).sort((a, b) => a.taCrDiLastName.localeCompare(b.taCrDiLastName));
        this.taskFunctions = (await s.downloadTable<TaskFunctionView>(ViewsNames.TaskFunctionView)).sort((a, b) => a.displayName.localeCompare(b.displayName));
    }

    getContributor(c: TaskContributorView): {contributor: TaskContributorView, editing: boolean, initialLoad: number} {
        return {contributor: c, editing: false, initialLoad: c.taCrLoad};
    }

    async contributorWorkloadClick(c: {contributor: TaskContributorView, editing: boolean, initialLoad: number}): Promise<void> {
        c.editing = true;
    }

    async saveWorkload(c: {contributor: TaskContributorView, editing: boolean, initialLoad: number}): Promise<void> {
        const s = Container.get(TaskContributorService);
        const result = await s.updateWorkload(c.contributor.taCrContributorId, c.contributor.taCrTaskId, c.contributor.taCrFunctionId, c.contributor.taCrLoad);
        if (result) {
            c.initialLoad = c.contributor.taCrLoad;
            c.editing = false;
        }
    }

    async contributorWorkloadFocusOut(c: {contributor: TaskContributorView, editing: boolean, initialLoad: number}): Promise<void> {
        await this.saveWorkload(c);
    }

    async contributorWorkloadkeyDown(e: KeyboardEvent, c: {contributor: TaskContributorView, editing: boolean, initialLoad: number}): Promise<void> {
        if (e.key.toLowerCase() === "escape") {
            c.contributor.taCrLoad = c.initialLoad;
            c.editing = false;
            return;
        }
        if (e.key.toLowerCase() === "enter") {
            await this.saveWorkload(c);
            return;
        }
    }

    async newContributorButtonClick(): Promise<void> {
        if (!this.selectedContributorsDirectory || !this.selectedTaskFunction || !this.task) return;

        // Vérifie qu'il n'y a pas déjà un contributeur avec cette fonction
        const existing = this.contributors.find(x=> x.contributor.taCrContributorId === this.selectedContributorsDirectory?.taCrDiId && x.contributor.taCrFunctionId === this.selectedTaskFunction?.taFuId);
        if (existing) {
            toastWarn("Il y a déjà un contributeur avec cette fonction sur la tâche sélectionnée");
            return;
        }

        const s = Container.get(TaskContributorService);
        const result = await s.createNewContributor(this.task.taId, this.selectedContributorsDirectory.taCrDiId, this.selectedTaskFunction.taFuId, this.workload);
        if (result != null) {
            this.contributors.push(this.getContributor(result));
            // Vide le formulaire
            this.selectedContributorsDirectory = undefined;
            this.selectedTaskFunction = undefined;
            this.workload = 1;
        }
    }

    async deleteContributorButtonClick(c: TaskContributorView): Promise<void> {
        if (this.contributors.length === 1) return;

        const s = Container.get(TaskContributorService);
        const result = await s.removeContributor(c.taCrTaskId, c.taCrContributorId, c.taCrFunctionId);
        if (result) {
            const i = this.contributors.findIndex(x=> x.contributor.taCrTaskId === c.taCrTaskId && x.contributor.taCrContributorId === c.taCrContributorId && x.contributor.taCrFunctionId === c.taCrFunctionId);
            if (i >= 0) {
                this.contributors.splice(i, 1);
            }
        }
    }
}