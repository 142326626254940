export const ZColumnConstraintTable = {
    databaseTableName: "z_column_constraint",
    coCoId: "CoCo_Id",
    coCoTableName: "CoCo_TableName", 
    coCoColumnName: "CoCo_ColumnName", 
    coCoConstraintType: "CoCo_ConstraintType", 
    coCoConstraintMode: "CoCo_ConstraintMode", 
    coCoValue: "CoCo_Value", 
    coCoIndex: "CoCo_Index",
    coCoTriggeredId: "CoCo_TriggeredId",
    coCoMessageId: "CoCo_MessageId"
}