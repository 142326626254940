export const ZDbTableTable = {
    databaseTableName: "z_db_table",
    dbTaTableName: "DbTa_TableName", 
    dbTaTableDisplayName: "DbTa_TableDisplayName",
    dbTaCategoryId: "DbTa_CategoryId", 
    dbTaScope: "DbTa_Scope", 
    dbTaHasIdentityId: "DbTa_HasIdentityId", 
    dbTaTableTypeId: "DbTa_TableTypeId", 
    dbTaIsHierarchical: "DbTa_IsHierarchical", 
    dbTaInsertGrantId: "DbTa_InsertGrantId", 
    dbTaDeleteGrantId: "DbTa_DeleteGrantId", 
    dbTaUpdateGrantId: "DbTa_UpdateGrantId", 
    dbTaReadGrantId: "DbTa_ReadGrantId"
}