import { FloorModelCategoryView } from './floor-model-category-view';
import { FloorModel } from './../tables/floor-model';
import { FloorModelDbView } from '../../db-model/views/floor-model-db-view';

export class FloorModelView extends FloorModel {
    flMoDisplayName!: string;
    category: FloorModelCategoryView;

    constructor(dtoData: any, tableDef: any[] = []) {
        super(dtoData, [FloorModelDbView].concat(tableDef));
        this.category = new FloorModelCategoryView(dtoData);
    }
}