import { Point } from "src/app/core/model/geometry-model/point.model";
import { Polygon } from "src/app/core/model/geometry-model/polygon.model";
import { Segment } from "src/app/core/model/geometry-model/segment.model";
import { Vector } from "src/app/core/model/geometry-model/vector.model";
import { XcMaths } from "src/app/core/model/static-functions/xc-maths";
import { SvgTransform } from "src/app/core/model/svg-model/svg-transform.model";
import { logError } from "src/app/core/services/logging-service";

export class BpSvgBoundingBox {
    minX: number;
    minY: number;
    maxX: number;
    maxY: number;

    constructor(minX: number, minY: number, maxX: number, maxY: number){
        this.minX = minX;
        this.minY = minY;
        this.maxX = maxX;
        this.maxY = maxY;
    }

    equals(b: BpSvgBoundingBox){
        return this.minX == b.minX && this.minY == b.minY && this.maxX == b.maxX && this.maxY == b.maxY;
    }

    clone(): BpSvgBoundingBox {
        return new BpSvgBoundingBox(this.minX, this.minY, this.maxX, this.maxY);
    }

    rounded(d: number = 3): BpSvgBoundingBox {
        return new BpSvgBoundingBox(XcMaths.round(this.minX, d), XcMaths.round(this.minY, d), XcMaths.round(this.maxX, d), XcMaths.round(this.maxY, d));
    }

    add(b: BpSvgBoundingBox): boolean {
        let result = false;
        if (isNaN(this.minX) || b.minX < this.minX)
        {
            this.minX = b.minX;
            result = true;
        }

        if (isNaN(this.minY) || b.minY < this.minY)
        {
            this.minY = b.minY;
            result = true;
        }

        if (isNaN(this.maxX) || b.maxX > this.maxX)
        {
            this.maxX = b.maxX;
            result = true;
        }

        if (isNaN(this.maxY) || b.maxY > this.maxY)
        {
            this.maxY = b.maxY;
            result = true;
        }

        return result;
    }

    extend(p: Point): void {
        if (p.x < this.minX) this.minX = p.x;
        if (p.y < this.minY) this.minY = p.y;
        if (p.x > this.maxX) this.maxX = p.x;
        if (p.y > this.maxY) this.maxY = p.y;
    }

    geometry(): Polygon {
        const result = new Polygon([this.topLeft(), this.topRight(), this.bottomRight(), this.bottomLeft()]);
        result.close();
        return result;
    }

    transformNew(t: SvgTransform): BpSvgBoundingBox {
        // Récupère la géométrie de la bbox
        let geometry = this.geometry();
        // transforme la géométrie
        geometry = geometry.transformNew(t.rotationCenter, XcMaths.toRad(t.rotationAngle), t.translate.vector());
        // Retrouve le rectangle droit après rotation (éventuelle)
        return BpSvgBoundingBox.fromPolygon(geometry);
    }

    translateNew(t: Vector): BpSvgBoundingBox {
        // Récupère la géométrie de la bbox
        let geometry = this.geometry();
        // transforme la géométrie
        geometry = geometry.translateNew(t);
        return BpSvgBoundingBox.fromPolygon(geometry);
    }


    width(): number {
        return XcMaths.round(this.maxX - this.minX, 2);
    }

    height(): number {
        return XcMaths.round(this.maxY - this.minY, 2);
    }

    /**  
     * @description Retourne le centre de la boite englobante
    */
    center(): Point {
        const minPoint = new Point(this.minX, this.minY);
        const maxPoint = new Point(this.maxX, this.maxY);
        const diagonal = new Segment(minPoint, maxPoint);
        return diagonal.midPoint();
    }

    topLeft(): Point {
        return new Point(this.minX, this.minY);
    }

    topRight(): Point {
        return new Point(this.maxX, this.minY);
    }

    bottomLeft(): Point {
        return new Point(this.minX, this.maxY);
    }

    bottomRight(): Point {
        return new Point(this.maxX, this.maxY);
    }

    top(): Segment {
        return new Segment(this.topLeft(), this.topRight());
    }

    right(): Segment {
        return new Segment(this.topRight(), this.bottomRight());
    }

    bottom(): Segment {
        return new Segment(this.bottomRight(), this.bottomLeft());
    }

    left(): Segment {
        return new Segment(this.bottomLeft(), this.topLeft());
    }

    areOnTheEdge(points: Point[]): Point[] {
        let result: Point[] = [];
        
        points.forEach(p => {
            if (p.x === this.minX || p.x === this.maxX || p.y === this.minY || p.y === this.maxY) {
                result.push(p);
            }
        });

        return result;
    }

    
    viewBox(): string {
        return `${this.minX} ${this.minY} ${this.width()} ${this.height()}`
    }

    static getFromString(bboxString: string): BpSvgBoundingBox | null {
        const values = bboxString.split(';');
        if(values.length === 2){
            const minPoint = Point.getFromString(values[0]);
            const maxPoint = Point.getFromString(values[1]);
            if (minPoint && maxPoint) {
                return new BpSvgBoundingBox(minPoint.x, minPoint.y, maxPoint.x, maxPoint.y);
            }
        }

        return null;
    }

    static default(): BpSvgBoundingBox {
        return new BpSvgBoundingBox(0, 0, 0, 0);
    }

    /**
     * 
     * @param p attend un rectangle
     * @returns 
     */
    static fromPolygon(p: Polygon): BpSvgBoundingBox {
        if (!(p.vertices.length === 4 || (p.vertices.length === 5 && p.vertices[0].equals(p.vertices[4])))) {
            logError("Le polygone n'est pas un rectangle");
        }

        const topLeft = p.vertices[0];
        const topRight = p.vertices[1];
        const bottomRight = p.vertices[2];
        const bottomLeft = p.vertices[3];
        const minX = Math.min(topLeft.x, topRight.x, bottomRight.x, bottomLeft.x);
        const minY = Math.min(topLeft.y, topRight.y, bottomRight.y, bottomLeft.y);
        const maxX = Math.max(topLeft.x, topRight.x, bottomRight.x, bottomLeft.x);
        const maxY = Math.max(topLeft.y, topRight.y, bottomRight.y, bottomLeft.y);
        return new BpSvgBoundingBox(minX, minY, maxX, maxY);
    }
}