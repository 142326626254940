import { FloorModelEnum } from 'src/app/core/model/data-model/enums/floor-model-enum';
import { Container } from 'typedi';
import { EntitiesFormModel } from "./entities-form-model";
import { WorkplaceTypeEnum } from 'src/app/core/model/data-model/enums/workplace-type-enum';
import { EntitiesSelectionSet } from '../../../../blueprint-viewer-content-panel/itself/model/interaction/entities-selection-set';
import { BpSvgWorkplaceLabel } from '../../../../svg-entities/model/bp-svg-workplace-label';
import { WorkplaceTypeView } from 'src/app/core/model/data-model/views/workplace-type-view';
import { ViewsNames } from 'src/app/core/model/db-model/views-names-enum';
import { DyntService } from 'src/app/core/services/backend-services/dynt-service';
import { WorkplaceService } from 'src/app/core/services/backend-services/workplace-service';
import { logError } from 'src/app/core/services/logging-service';

export class WorkplaceFormModel implements EntitiesFormModel {
    workplacesTypes: WorkplaceTypeView[] = [];
    isEditable: boolean = false;

    selectedLabels: EntitiesSelectionSet<BpSvgWorkplaceLabel> | undefined;
    hasPlanningTask: boolean = false;
    layerId: number = FloorModelEnum.WorkplaceLabels;

    defaultCode: string = "001";
    defaultTypeId: number = WorkplaceTypeEnum.Standard;
    initialCode: string = this.defaultCode;
    initialTypeId: number = this.defaultTypeId;
    sharedCode: string = "*";
    sharedTypeId: number = 0;

    constructor() { }

    private async loadReferenceDatas(): Promise<void> {
        if (this.workplacesTypes.length === 0) {
            const s = Container.get(DyntService);
            let tmp = await s.downloadTable<WorkplaceTypeView>(ViewsNames.WorkplaceTypeView);
            tmp = tmp.sort((a,b) => a.woTyName.localeCompare(b.woTyName));
            const variousFakeWorkplaceType = new WorkplaceTypeView({"WoTy_DisplayName": "*", "WoTy_Id": 0});
            this.workplacesTypes = [variousFakeWorkplaceType].concat(tmp);
        }
    }

    async initialize(selectedLabels: EntitiesSelectionSet<BpSvgWorkplaceLabel>, isPlanningTask: boolean, editable: boolean): Promise<void> {
        this.isEditable = editable;
        await this.loadReferenceDatas();
        this.hasPlanningTask = isPlanningTask;
        this.selectedLabels = selectedLabels;
        this.selectionSetUpdate();
    }

    setInitialValues(): void {
        if (this.selectedLabels?.count === 1) {
            const label = this.selectedLabels.items[0] as BpSvgWorkplaceLabel;
            this.initialCode = label.text.tSpans[0].text;
            this.initialTypeId = label.workplace ? label.workplace.dataSet.woWorkplaceType : this.defaultTypeId;
        } else {
            this.initialCode = this.defaultCode;
            this.initialTypeId = this.defaultTypeId;
        }
    }
    
    selectionSetUpdate(): void {
        this.getSharedType();
        this.setInitialValues();
    }

    getSharedType(): void {
        let types = (this.selectedLabels?.items as BpSvgWorkplaceLabel[]).map(x=> x.workplace?.dataSet.woWorkplaceType);
        types = [...new Set(types)];
        if (types.length === 1) {
            this.sharedTypeId = types[0] ?? 0;
        } else {
            this.sharedTypeId = 0;
        }
    }

    async onCodeInputKeyUpEnter(e: Event, valid: boolean | null) {
        if (this.selectedLabels?.count === 1) {
            const label = this.selectedLabels.single<BpSvgWorkplaceLabel>();
            // L'enregistrement est demandé
            if (valid) {
                // La saisie est valide
                const s = Container.get(WorkplaceService);
                const result = await s.updateLabel(label.floorDataId, label.mainText());
                if (result != null) {
                    // L'enregistrement a réussi, on actualise la valeur initiale
                    this.initialCode = label.mainText();
                } else {
                    // L'enregistrement a échoué, on remet la valeur à son état initial
                    label.text.tSpans[0].text = this.initialCode;
                }
            } else {
                // La saisie n'est pas valide, on remet la valeur à son état initial
                label.text.tSpans[0].text = this.initialCode;
            }
        }
    }

    themeRefreshRequested?: () => void
    async onTypeChange(selectedType: number) {
        if (selectedType === 0 || !this.selectedLabels) return;

        // L'enregistrement est demandé
        const ids = this.selectedLabels.items.map(x=> x.floorDataId);
        const s = Container.get(WorkplaceService);
        const result = await s.updateType(ids, selectedType);
        if (result != null) {
            // L'enregistrement a réussi
            this.selectedLabels.items.forEach(label => {
                const wl = (label as BpSvgWorkplaceLabel);
                if (wl.workplace) {
                    wl.workplace.dataSet.woWorkplaceType = selectedType;
                }
            });

            if (this.selectedLabels.count === 1) {
                this.initialTypeId = selectedType;
            }

            if (this.hasPlanningTask) {
                this.selectedLabels.items.forEach(label => {
                    const resultItem = result.find(x=> x.floorDataId === label.floorDataId);
                    if (resultItem) {
                        label.dataStateId = resultItem.dataStateId;
                    }
                });
            }

            // Demande l'actualisation du thème s'il est affiché
            if (this.themeRefreshRequested) {
                this.themeRefreshRequested();
            } else {
                logError("WorkplaceFormModel.themableDataChange n'est pas écouté");
            }
        } else {
            // L'enregistrement a échoué, on remet la valeur à son état initial
            if (this.selectedLabels.count === 1) {
                const wl = (this.selectedLabels.items[0] as BpSvgWorkplaceLabel);
                if (wl.workplace) {
                    wl.workplace.dataSet.woWorkplaceType = this.initialTypeId;
                }
            }
        }
    }

}
