import { EntitiesSelectionSet } from "../../../../itself/model/interaction/entities-selection-set";
import { UpdateGizmoArgs } from "../../update-gizmo-args.model";

export class RoomUpdateGizmoArgs implements UpdateGizmoArgs {
    selectionSet: EntitiesSelectionSet;
    isPlanningTask: boolean;
    canUpdateFurnitureLayer: boolean;
    isEditable: boolean;

    constructor(selectionSet: EntitiesSelectionSet, isPlanningTask: boolean, canUpdateFurnitureLayer: boolean, isEditable: boolean) {
        this.selectionSet = selectionSet;
        this.isPlanningTask = isPlanningTask;
        this.canUpdateFurnitureLayer = canUpdateFurnitureLayer;
        this.isEditable = isEditable;
    }
}