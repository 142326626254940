import { InteractionCommand } from "../interaction-command";
import { SvgEntityTypesEnum } from "src/app/core/model/svg-model/svg-entity-type-enum";
import { InsertInteraction } from "./insert-interaction";
import { EllipseInsertGizmo } from "../../../gizmo/insert/model/ellipse-insert-gizmo";
import { SelectableSvgEllipse } from "../../../svg/selectable-svg-ellipse";
import { logError } from "src/app/core/services/logging-service";

export class EllipseInsertInteraction extends InsertInteraction<EllipseInsertGizmo> {
    constructor(command: InteractionCommand) {
        super(command, new EllipseInsertGizmo(), SvgEntityTypesEnum.ellipse);
        this.gizmo.insertionCompleted = () => {
            if (this.insertionCompleted) {
                this.insertionCompleted(SelectableSvgEllipse.fromValues(this.gizmo.centerPoint, this.gizmo.rx, this.gizmo.ry, 0.001, this.gizmo.transform));
            } else {
                logError("EllipseInsertInteraction.insertionCompleted n'est pas écouté");
            }
        }
    }
}