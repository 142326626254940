import { buildModel } from "../../model-builder";

export class Contents {
    id!: number;
    name!: string;
    parentId!: number | null;
    fileName!: string;
    displayOrder!: number;
    previousId!: number | null;
    nextId!: number | null;

    static dbMap = {
        databaseTableName: "Contents",
        id: 'id',
        name: 'name',
        parentId: 'parentId',
        fileName: "fileName",
        displayOrder: "displayOrder",
        previousId: "previousId",
        nextId: "nextId"
    }

    constructor(dtoData: any, tableDef: any[] = []) {
        buildModel(this, dtoData, [Contents.dbMap].concat(tableDef));
    }
}