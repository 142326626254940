import { TablesNamesEnum } from './../../model/db-model/tables-names-enum';
import { HttpParams, HttpRequest, HttpResponse } from "@angular/common/http";
import { Service } from "typedi";
import { BusinessUnit } from "../../model/data-model/tables/business-unit";
import { UsageContextIdEnum } from "../../model/usage-context-id-enum";
import { ApiControllers, ApiEndpoints } from "../api-endpoints";
import { ApiService } from "../api-service";
import { BusinessUnitTreeDataDTO } from "./dto/business-unit-tree-data-dto";
import { BusinessUnitTable } from '../../model/db-model/tables/business-unit-table';
import { lastValueFrom } from 'rxjs';
import { ApiData } from '../api-data';
import { ApiResponseCodeEnum } from '../api-response-code-enum';

@Service({ global: true })  
export class BusinessUnitService extends ApiService {
    private createEndpoint: string = `${ApiControllers.bus}`;
    private fileUploadEndpoint: string = `${ApiControllers.bus}/batchinsert`;

    constructor() {
        super();
    }

    async loadGrantedItems(granted: boolean = true): Promise<BusinessUnit[]> {
        let params = new HttpParams();
        params = params.set('granted', granted);
        return this.getArrayAsync<BusinessUnit>(TablesNamesEnum.BusinessUnit, this.endPoint(ApiEndpoints.busItems), UsageContextIdEnum.none, params)
    }

    // async loadViewCurrentItems(): Promise<BusinessUnitView[]> {
    //   const result = await this.getAsync<any[]>(this.endPoint(ApiEndpoints.busCurrentView));
    //     if (result != null) {
    //         return result.payload.map(item => {
    //             return new BusinessUnitView(item);
    //         })
    //     }
    //     return [];
    // }

    // async loadCurrentAnatomy(): Promise<BusinessUnitAnatomyDTO | null> {
    //   const result = await this.getAsync<any>(this.endPoint(ApiEndpoints.busCurrentAnatomy));
    //     if (result != null) {
    //         return new BusinessUnitAnatomyDTO(result.payload);
    //     }
    //     return null;
    // }

    async loadBusTreeDatas(): Promise<BusinessUnitTreeDataDTO | null> {
      const result = await this.getAsync<any>(this.endPoint(ApiEndpoints.busTreeDatas));
        if (result != null) {
            return new BusinessUnitTreeDataDTO(result.payload);
        }
        return null;
    }

    async createItem(value: {}): Promise<BusinessUnit[] | null> {
        const result = await this.postAsync<any>(this.endPoint(this.createEndpoint), value);
        if (result) {
            return result.payload.map((item: any) => {
                return new BusinessUnit(item);
            })
        }
        return null;
    }

    async loadFileData(file: File): Promise<string[]> {
        const formData: FormData = new FormData();
        formData.append('file', file, `${crypto.randomUUID()}.${file.name.split('.').pop()}`);

        const headers = this.getCompanyIdHeader();

        const req = new HttpRequest('POST', this.endPoint(this.fileUploadEndpoint), formData, { headers,
          reportProgress: false
        });
        const response = (await lastValueFrom(this.http.request(req))) as HttpResponse<ApiData<string[]>>;
        if (response.status !== 200) return [];
        if (response.body?.statusCode !== ApiResponseCodeEnum.success) return [];
    
        return response.body.payload;
        }
}