import { DefinedModelBase } from '../defined-model-base';
import { FloorTable } from "../../db-model/tables/floor-table";

export class Floor extends DefinedModelBase {
    databaseTableName: string = FloorTable.databaseTableName;
    flId!: number;
    flBuildingId!: number;
    flCode!: string;
    flName!: string;
    flIsActive!: boolean;
    flIsFictive!: boolean;
    flLocation!: string | null;
    flArea!: number;
    flElevation!: number;
    flTaskId!: number;
    flDefaultPerimeterId!: number

    constructor(dtoData: any, tableDef: any[] = []) {
        super(dtoData, [FloorTable].concat(tableDef))
    }
}