import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaintenanceBrowserVM } from '../../model/maintenance-browser-vm';
import { WipIconComponent } from 'src/app/components-lib/wip-icon/wip-icon.component';

@Component({
  selector: 'xc-maintenance-browser',
  standalone: true,
  imports: [CommonModule, WipIconComponent],
  templateUrl: './maintenance-browser.component.html',
  styleUrls: ['./maintenance-browser.component.scss']
})
export class MaintenanceBrowserComponent {
  @Input() model: MaintenanceBrowserVM | undefined;
}
