import { Component } from '@angular/core';

@Component({
  selector: 'g[xc-ortho-sys-axes-gizmo]',
  standalone: true,
  imports: [],
  templateUrl: './ortho-sys-axes-gizmo.component.svg',
  styleUrl: './ortho-sys-axes-gizmo.component.scss'
})
export class OrthoSysAxesGizmoComponent {

}
