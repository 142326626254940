import { Vector } from '../../../../../../../core/model/geometry-model/vector.model';
import { DxfSvgLine } from "../../svg/dxf-svg-line";
import { Point } from "src/app/core/model/geometry-model/point.model";
import { UpdateGizmo } from "./update-gizmo";
import { SvgEntityTypesEnum } from "src/app/core/model/svg-model/svg-entity-type-enum";
import { SvgLineService } from 'src/app/core/model/svg-model/svg-line-service';
import { SvgEntityPointStyleEnum } from '../../../../shared/gizmos/model/svg-entity-point-style-enum';

export class LineGizmo extends UpdateGizmo<DxfSvgLine> {
    initialMousePosition: Point | undefined;

    constructor(pixelUnit: number) {
        super(pixelUnit, SvgEntityTypesEnum.line);
    }

    freeTranslateEndpoint(currentHitPoint: Point, save: boolean): void {
        if (this.single && this.currentGrip) {
            if (this.currentGrip.point.equals(this.single.startPoint)) {
                this.single.startPoint = currentHitPoint;
            } else {
                this.single.endPoint = currentHitPoint;
            }
            this.currentGrip.point = currentHitPoint;

            // Recalcule le midPoint
            const i = this.single.selectablePoints.findIndex(x=> x.gripStyle === SvgEntityPointStyleEnum.middle);
            if (i > -1) {
                const l = SvgLineService.geometry(this.single);
                const m = l.midPoint();
                const g = this.grips.items[i];
                g.point = m;
            }
        }
    }

    endFreeTranslateEndpoint(): void {
        if (this.single) {
            this.single.updateSelectablePoints();
            this.grips.loadFromPoints(this.single.selectablePoints);
        }
    }

    translateLines(delta: Vector): void {
        this.entities.forEach(e => {
            e.translate(delta);
        });
        this.grips.items.forEach(g => {
            g.point = g.point.translate(delta);
        });
    }

    endTranslate(): void {
        this.entities.forEach(e => {
            e.updateSelectablePoints();
        });
    }
}