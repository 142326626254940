import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MaterialModule } from 'src/app/core/material.module';

@Component({
  standalone: true,
  imports: [CommonModule, MaterialModule],
  selector: 'xc-people-properties-form',
  templateUrl: './people-properties-form.component.html',
  styleUrls: ['./people-properties-form.component.scss']
})
export class PeoplePropertiesFormComponent implements OnInit {
  //@Input() model: EntitiesSelectionSet;

  constructor() { }

  ngOnInit(): void {
  }

}
