<h3 class="header">{{data.title}}</h3>
<mat-dialog-content class="mat-typography">
  <!-- <form class="workplace-allocation-form"> -->
  <mat-form-field class="field-full-width" appearance="outline">
    <mat-label>Rechercher dans l'annuaire</mat-label>
    <input
      matInput
      placeholder="Saisir un nom"
      aria-label="Rechercher dans l'annuaire"
      (keyup)="onSearchChange($event)"
      autocomplete="off"
    />
  </mat-form-field>
  <!-- </form> -->

  <div class="flex-row">
    <div class="vertical-container">
      @for (item of filteredDirectory; track item) {
      <div class="people-item-row">
        <div class="people-item">
          <span
            >{{ item.dataSet.diFirstName }} {{ item.dataSet.diLastName }}
            {{ item.dataSet.diOccupancyRatio * 100 }}%</span
          >
          <span class="mat-small gray">{{ item.dataSet.buUnName }}</span>
        </div>
        <button
          [disabled]="data.useRatio && (totalRatio() === 100 || contains(item))"
          class="add-bu-button"
          mat-icon-button
          aria-label="Ajouter à la liste"
          (click)="addPeopleClick($event, item)"
        >
          <mat-icon class="material-icons-outlined">forward</mat-icon>
        </button>
      </div>
      }
    </div>

    <div class="flex-child">
      <ul class="allocation-list">
        @for (item of displayAllocations; track item) {
        <li class="allocation-item">
          <div class="allocation-item-container">
            <span>{{ item.name }}</span>
            @if (data.useRatio) {
            <input
              class="allocation-rate"
              type="number"
              min="0.01"
              max="100"
              [(ngModel)]="item.ratio"
              (ngModelChange)="rateChange($event, item)"
            />
            } @if (data.useRatio) {
            <mat-icon matSuffix>percent</mat-icon>
            }
            <button
              class="delete-bu-button"
              mat-icon-button
              aria-label="Supprimer le collaborateur de la liste"
              (click)="deletePeopleClick($event, item)"
              color="warn"
            >
              <mat-icon class="material-icons-outlined delete-button-icon"
                >delete</mat-icon
              >
            </button>
          </div>
        </li>
        }
      </ul>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button
    class="allocation-command"
    mat-button
    color="primary"
    [disabled]="!canSave"
    (click)="save()"
  >
    Ok
  </button>
  <button mat-button color="warn" (click)="cancel()">Annuler</button>
</mat-dialog-actions>
