import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SvgStrategyStackingVM } from '../../model/svg-strategy-stacking-vm';
import { SvgBuildingStackComponent } from '../svg-building-stack/svg-building-stack.component';

@Component({
  selector: 'xc-svg-strategy-stacking',
  standalone: true,
  imports: [CommonModule, SvgBuildingStackComponent],
  templateUrl: './svg-strategy-stacking.component.svg',
  styleUrls: ['./svg-strategy-stacking.component.scss']
})
export class SvgStrategyStackingComponent {
  @Input() model: SvgStrategyStackingVM | undefined;
}
