import Container from "typedi";
import { MenuTreeNode } from "./menu-tree-node";
import { DyntService } from "src/app/core/services/backend-services/dynt-service";
import { ZAppMenuView } from "src/app/core/model/data-model/views/z-app-menu-view";
import { ViewsNames } from "src/app/core/model/db-model/views-names-enum";
import { ClientGrantsVM } from "./client-grants-vm";

export class MenuTree {
    items: MenuTreeNode[] = [];

    // TODO : trouver une façon plus propre et robuste de gérer les menus accessible en démo
    playgroundExcluded: number[] = [1, 3, 4, 37, 40, 41, 47, 48, 49, 50, 52];

    private constructor() {
    }

    static async newAsync(grants: ClientGrantsVM): Promise<MenuTree> {
        const result = new MenuTree();

        if (!grants.isPlayground) {
            result.playgroundExcluded = [];
        }

        const s = Container.get(DyntService);
        const data = await s.downloadTable<ZAppMenuView>(ViewsNames.ZAppMenuView);

        const rootMenu = result.makeMenuItems(data.filter(x=> x.zApMeDepth === 1).sort((a, b) => a.zApMeOrderIndex - b.zApMeOrderIndex), grants, undefined);
        result.makeMenuChildren(data, grants, rootMenu);

        result.items = rootMenu;

        return result;
    }

    private makeMenuChildren(data: ZAppMenuView[], grants: ClientGrantsVM, menuItems: MenuTreeNode[]): void {
        menuItems.forEach(x => {
            const cData = data.filter(y=> y.zApMeParentId?.toString() === x.key).sort((a, b) => a.zApMeOrderIndex - b.zApMeOrderIndex);
            if (cData.length > 0) {
                const l1 = this.makeMenuItems(cData, grants, x);
                x.children = l1;
                this.makeMenuChildren(data, grants, l1);
            }
        });
    }

    private makeMenuItem(data: ZAppMenuView, enable: boolean, parent: MenuTreeNode | undefined): MenuTreeNode {
        return new MenuTreeNode(data.zApMeId.toString(), data.zApMeDisplayName, enable, data.zApMeRouteId !== null, data.zApMeRoName, parent);
    }

    private makeMenuItems(data: ZAppMenuView[], grants: ClientGrantsVM, parent: MenuTreeNode | undefined): MenuTreeNode[] {
        const result: MenuTreeNode[] = [];
        data.forEach(d => {
            result.push(this.makeMenuItem(d, grants.licencing.includes(d.zApMeLicencing) && !this.playgroundExcluded.includes(d.zApMeId), parent));
        });
        return result;
    }

    getMenuItem(route: string): MenuTreeNode | undefined {
        return this.searchForItem(this.items, route);
    }

    private searchForItem(parents: MenuTreeNode[], route: string): MenuTreeNode | undefined {
        let result: MenuTreeNode | undefined;
        for (const m of parents) {
            if (m.route === route) {
                result = m;
                break;
            }
            if (m.children) {
                result = this.searchForItem(m.children, route);
                if (result != null) {
                    break;
                }
            }
        }
        return result;
    }
}