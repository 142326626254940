import { SelectableBuTreeNode } from './selectable-bu-tree-node';
import { NestedTreeControl } from "@angular/cdk/tree";
import { MatTreeNestedDataSource } from '@angular/material/tree';
import Container from 'typedi';
import { BusinessUnit } from 'src/app/core/model/data-model/tables/business-unit';
import { SimulationService } from 'src/app/core/services/backend-services/simulation-service';
import { BusAndBuildingsBusDTO } from 'src/app/core/services/backend-services/dto/bus-and-buildings-bus-dto';
import { logError } from 'src/app/core/services/logging-service';

export class SelectableBusTreeVM {
    treeControl = new NestedTreeControl<SelectableBuTreeNode>(node => node.children);
    dataSource = new MatTreeNestedDataSource<SelectableBuTreeNode>();
    
    readonly: boolean = false;
    selectedNode: SelectableBuTreeNode | undefined;

    buData: BusAndBuildingsBusDTO | undefined;

    constructor(readonly: boolean = false) {
        this.readonly = readonly
    }

    async loadTree(selectedBuildingsIds: number[]): Promise<void> {
        const s = Container.get(SimulationService);
        const data = await s.getBusAndBuildingsBus(selectedBuildingsIds);
        if (data !== null) {
            this.buData = data;
            this.dataSource.data = this.getNodeChildren(data.businessUnits, null);
            this.listenToSelectionChange();

            // Si le jeu de données comprend une préselection par les immeubles, il faut préselectionner les entités correspondants
            if (this.buData.buildingAllocations.length > 0) {
                this.selectBus(this.buData.buildingAllocations.map(x=> x.roAlBusinessUnitId));
            }
        }
    }

    selectBus(busIds: number[]): void {
        const lastBusNodes = this.getLastChildren(this.dataSource.data);
        lastBusNodes.forEach(n => {
            if (busIds.includes(n.bu.buUnId)) {
                n.setSelected(true);
            }
        });
    }

    getNodeChildren(data: BusinessUnit[], parentId: number | null): SelectableBuTreeNode[] {
        const bus = data.filter(x=> x.buUnParentId === parentId);
        const temp: SelectableBuTreeNode[] = [];
        bus.forEach(bu => {
            if (bu.buUnParentId == null) {
                // Cas où les entités sont de premier niveau donc directement sous le root node
                bu.buUnParentId = 0;
            }
            const newNode = new SelectableBuTreeNode(bu);
            const children = this.getNodeChildren(data, bu.buUnId);
            newNode.setChildren(children);
            children.forEach(ch => {
                ch.setChildren(this.getNodeChildren(data, ch.bu.buUnId));
            });
            temp.push(newNode);
        });
        return temp;
    }

    seletedNodeChange?: (selectedNodes: SelectableBuTreeNode[]) => void;
    listenToSelectionChange(): void {
        this.dataSource.data.forEach(n => {
            n.selectedChange = () => {
                if (this.seletedNodeChange) {
                    this.seletedNodeChange(this.getSelectedBus());
                } else {
                    logError("SelectableBusTreeVM.selectedNodeChange n'était pas écouté");
                }
                    }
        });
    }

    getSelectedBus(): SelectableBuTreeNode[] {
        return this.getLastChildren(this.dataSource.data).filter(x=> x.selected);
    }

    getLastChildren(nodes: SelectableBuTreeNode[]): SelectableBuTreeNode[] {
        let result: SelectableBuTreeNode[] = [];

        nodes.forEach(n => {
            if (n.children.length === 0) {
                result.push(n);
            } else {
                result = result.concat(this.getLastChildren(n.children));
            }
        });   

        return result;
    }

    hasChild = (_: number, node: SelectableBuTreeNode) => !!node.children && node.children.length > 0;
}