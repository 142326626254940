import { FloorDbView } from "../../db-model/views/floor-db-view";
import { Floor } from "../tables/floor";

export class FloorView extends Floor {
    flViGLA!: number;
    flViNUA!: number;

    constructor(dtoData: any) {
        super(dtoData, [FloorDbView]);
    }
}