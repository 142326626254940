@if (model) {
<div id="leaseTreeContainer">
  <h4>Baux</h4>
  @if (model != null) {
<mat-tree id="moveWorkTree" [dataSource]="model.dataSource" [treeControl]="model.treeControl" class="example-tree">
      <!-- This is the tree node template for leaf nodes -->
      <!-- There is inline padding applied to this node using styles.
        This padding value depends on the mat-icon-button width. -->
      <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle (click)="model.nodeClick($event, node)">
        <div matBadge="{{node.badgeType}}" matBadgeOverlap="false" matBadgePosition="before" class="demo-section">{{node.name}}</div>
        <!-- {{node.name}} -->
      </mat-tree-node>
      <!-- This is the tree node template for expandable nodes -->
      <mat-nested-tree-node *matTreeNodeDef="let node; when: model.hasChild" 
          (click)="model.nodeClick($event, node)">
          <div class="mat-tree-node">
              <button mat-icon-button matTreeNodeToggle
                      [attr.aria-label]="'Toggle ' + node.name">
                  <mat-icon class="mat-icon-rtl-mirror">
                  {{model.treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                  </mat-icon>
              </button>
              {{node.name}}
              </div>
              <!-- There is inline padding applied to this div using styles.
                  This padding value depends on the mat-icon-button width.  -->
              <div [class.example-tree-invisible]="!model.treeControl.isExpanded(node)"
                  role="group">
              <ng-container matTreeNodeOutlet></ng-container>
          </div>
      </mat-nested-tree-node>
    </mat-tree>
}
  
  </div>
}

