export const ZDbViewColumnTable = {
    databaseTableName: "z_db_view_column",
    dbViCoId: "DbViCo_Id", 
    dbViCoViewId: "DbViCo_ViewId",
    dbViCoTableName: "DbViCo_TableName", 
    dbViCoTableAlias: "DbViCo_TableAlias", 
    dbViCoColumnName: "DbViCo_ColumnName", 
    dbViCoColumnAlias: "DbViCo_ColumnAlias",
    dbViCoScope: "DbViCo_Scope",
    dbViCoDisplayNameId: "DbViCo_DisplayNameId", 
    dbViCoAggregate: "DbViCo_Aggregate", 
    dbViCoIsBrowsable: "DbViCo_IsBrowsable", 
    dbViCoIsReadOnly: "DbViCo_IsReadOnly", 
    dbViCoMainColumnOverride: "DbViCo_MainColumnOverride"
}