import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CadConverterContainerVM } from '../../model/cad-converter-container-vm';
import { ResizableLeftPanelVM } from 'src/app/components-lib/resizable-left-panel/model/resizable-left-panel-vm';
import { CadConvDefaultViewComponent } from '../cad-conv-default-view/cad-conv-default-view.component';
import { CadConvDrawingBoardComponent } from '../cad-conv-drawing-board/cad-conv-drawing-board.component';
import { CadConverterSidePanelComponent } from '../side-panel/cad-converter-side-panel/cad-converter-side-panel.component';
import { ResizableLeftPanelComponent } from 'src/app/components-lib/resizable-left-panel/view/resizable-left-panel/resizable-left-panel.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
  selector: 'xc-cad-converter-container',
  standalone: true,
  imports: [CommonModule, MatProgressSpinnerModule, CadConverterSidePanelComponent, CadConvDefaultViewComponent, CadConvDrawingBoardComponent, ResizableLeftPanelComponent],
  templateUrl: './cad-converter-container.component.html',
  styleUrls: ['./cad-converter-container.component.scss']
})
export class CadConverterContainerComponent extends ResizableLeftPanelVM {
  @Input() override model: CadConverterContainerVM | undefined;
  sidePanelContent = CadConverterSidePanelComponent;
  
  constructor() {
    super();
  }
}
