import { DxfService } from 'src/app/core/services/backend-services/dxf-service';
import { LineInteraction } from './line-interaction';
import { PanzoomController } from "src/app/components-lib/svg/panzoom-controller";
import { SvgDOMBase } from "src/app/core/model/svg-model/abstract/svg-dom-base";
import { Drawing } from "../drawing";
import { InteractionCommand } from "./interaction-command";
import { SelectionInteraction } from "./selection-interaction";
import { SvgEntityTypesEnum } from "src/app/core/model/svg-model/svg-entity-type-enum";
import { DxfLayerDTO } from "src/app/core/services/backend-services/dto/dxf-layer-dto";
import { Grip } from "src/app/components-lib/svg/grips/grip";
import { Point } from "src/app/core/model/geometry-model/point.model";
import { IEntityInteraction } from "./i-entity-interaction";
import { PathInteraction } from './path-interaction';
import { CircleInteraction } from './circle-interaction';
import { EllipseInteraction } from './ellipse-interaction';
import { TextInteraction } from './text-interaction';
import { UseInteraction } from './use-interaction';
import { DrawingCommandEnum } from '../commands/drawing-command-enum';
import { Grips } from 'src/app/components-lib/svg/grips/grips';
import Container from 'typedi';
import { DxfSvgDOM } from '../dxf-svg-dom';
import { SvgGeometryService } from 'src/app/ui/pages/graphic-works/blueprint/model/svg/svg-geometry-service';
import { TimeoutDetection } from '../timeout-detection';
import { ToolGridOptionsEnum } from '../commands/tool-grid-options-enum';
import { DxfSvgText } from '../svg/dxf-svg-text';
import { SvgEntityPointStyleEnum } from '../../../shared/gizmos/model/svg-entity-point-style-enum';
import { CircleInsertInteraction } from './insert/circle-insert-interaction';
import { EllipseInsertInteraction } from './insert/ellipse-insert-interaction';
import { IInsertInteraction } from './insert/i-insert-interaction';
import { LineInsertInteraction } from './insert/line-insert-interaction';
import { PathInsertInteraction } from './insert/path-insert-interaction';
import { TextInsertInteraction } from './insert/text-insert-interaction';
import { AttributeObserver } from 'src/app/components-lib/attribute-observer';
import { CircleGizmo } from '../gizmos/update/circle-gizmo';
import { EllipseGizmo } from '../gizmos/update/ellipse-gizmo';
import { LineGizmo } from '../gizmos/update/line-gizmo';
import { PathGizmo } from '../gizmos/update/path-gizmo';
import { TextGizmo } from '../gizmos/update/text-gizmo';
import { UpdateGizmos } from '../gizmos/update/update-gizmos';
import { UseGizmo } from '../gizmos/update/use-gizmo';
import { DxfSvgEntity } from '../svg/dxf-svg-entity';
import { BpSvgBoundingBox } from 'src/app/ui/pages/layout/real-estate/floor-blueprint/content/bp-svg-core-model/bp-svg-bounding-box';
import { logError } from 'src/app/core/services/logging-service';

export class UserInteraction extends SvgDOMBase {
    readonly layersTagId: string = "layers";
    drawing: Drawing;
    panzoomController: PanzoomController;
    currentCommand: InteractionCommand = new InteractionCommand();
    selectionInteraction: SelectionInteraction;
    currentInteractions: IEntityInteraction<DxfSvgEntity>[] = [];
    insertInteraction: IInsertInteraction | undefined;
    EntityType = SvgEntityTypesEnum;
    updateGizmos: UpdateGizmos;
    viewboxObserver: AttributeObserver | undefined;
    toolbarCommandEnded?: () => void;
    targetGrips: Grips = new Grips("purple", "purple");
    currentGripStyle: SvgEntityPointStyleEnum = SvgEntityPointStyleEnum.end;

    kd = this.keyDown.bind(this);

    constructor() {
        super("svgImage", "0d7b3e7f-478c-4ce0-813a-acbf135d4bd6");
        
        this.drawing = new Drawing();
        this.updateGizmos = new UpdateGizmos(1);
        this.updateGizmos.gripClicked = (point: Point, grip: Grip, entity: DxfSvgEntity | undefined) => {
            this.currentCommand.initialSvgMousePosition = this.getPointPosition(point.x, point.y);
            this.currentCommand.gripClicked = grip;
            this.currentCommand.entityClicked = entity;

            if (this.selectionInteraction.selectedEntities.length > 1) {
                this.currentCommand.set(InteractionCommand.entityTranslationCommand);
            } else {
                if (entity) {
                    switch (entity.entityType) {
                        case SvgEntityTypesEnum.circle:
                            this.currentCommand.set(InteractionCommand.entityTranslationCommand );
                            break;
                        case SvgEntityTypesEnum.line:
                            this.currentCommand.set(InteractionCommand.lineEndpointTranslationCommand);
                            break;
                        case SvgEntityTypesEnum.ellipse:
                            this.currentCommand.set(InteractionCommand.entityTranslationCommand );
                            break;
                        case SvgEntityTypesEnum.path:
                            this.currentCommand.set(InteractionCommand.pathPointTranslationCommand);
                            break;
                        case SvgEntityTypesEnum.text:
                            this.currentCommand.set(InteractionCommand.entityTranslationCommand );
                            break;
                        case SvgEntityTypesEnum.use:
                            this.currentCommand.set(InteractionCommand.entityTranslationCommand );
                            break;
                        default:
                            break;
                    }
                }
            }
        }

        this.updateGizmos.singleClicked = (e: DxfSvgEntity) => {
            if (e.entityType === SvgEntityTypesEnum.text) {
                const t = (e as DxfSvgText);
                const bbox = DxfSvgDOM.getEntityBbox(t.entityId);
                const clientPos = this.getClientPointFromSvgPoint(new Point(bbox?.x, bbox?.y));
                const pu = this.getSvgPixelUnit();
                if (clientPos && bbox) {
                    this.currentCommand.set(InteractionCommand.textInputCommand);
                    (this.currentInteractions[0] as TextInteraction).beginUpdate(t.text!, clientPos.x, clientPos.y, bbox.width / pu, bbox.height / pu);
                }
                return;
            }
        }

        // this.updateGizmos.gridGizmo.gridTranslateRequested = () => {
        //     this.currentCommand.set(InteractionCommand.gridTranslationCommand);
        //     this.updateGizmos.reticle.set(1);
        // }

        // this.updateGizmos.gridGizmo.gridAlignRequested = () => {
        //     this.currentCommand.set(InteractionCommand.gridRotationCommand);
        //     this.updateGizmos.reticle.set(2);
        // }

        this.panzoomController = new PanzoomController();
        // Branche le listener sur le zoom event
        this.panzoomController.zoomChange = (scale: number) => {
            this.drawing.updateStrokeWidth(scale);
        };
        // Branche le listener sur le viewboxChanged event
        this.panzoomController.viewboxChange = (viewbox: string) => {
            this.drawing.viewbox = viewbox;
        }
        this.selectionInteraction = new SelectionInteraction(this.currentCommand, this.drawing);
        this.selectionInteraction.selectionChanged = (selection: DxfSvgEntity[]) => {
            this.setInteractions(selection);
        }
    }

    initializePanZoom(): void {
        this.panzoomController?.initialize("drawing");
        document.addEventListener('keydown', this.kd, true);
    }

    initializeViewboxObserver(): void {
        this.viewboxObserver = new AttributeObserver(document.getElementById("svgImage"), "viewBox");
        this.viewboxObserver.attributeChanged = () => {
            const pu = this.getSvgPixelUnit() * 15;
            this.updateGizmos.updateNonScalingElementsSize(pu);
            this.updateGripSize(pu);
            if (this.currentCommand.isTextInputCommand()) {
                const i = this.currentInteractions[0] as TextInteraction;
                const e = i.updateGizmo.single!;
                const bbox = DxfSvgDOM.getEntityBbox(e.entityId);
                const clientPos = this.getClientPointFromSvgPoint(new Point(bbox?.x, bbox?.y));
                const pu = this.getSvgPixelUnit();
                if (clientPos && bbox) {
                    i.updateLabelBbox(clientPos.x, clientPos.y, bbox.width / pu, bbox.height / pu);
                }
            }
        }
    }

    async setDrawing(importId: number, extents: string, layers: DxfLayerDTO[]): Promise<void> {
        await this.abortCommand();
        this.drawing.set(importId, extents, layers);  
        this.panzoomController.resetOriginalViewbox();
        this.updateGizmos.drawingExtents = BpSvgBoundingBox.getFromString(this.drawing.extents)!;
    }

    updateGripSize(size: number): void {
        this.targetGrips.updateSize(size);
    }

    setInsertCommand(selectedCommandId: DrawingCommandEnum) {
        switch (selectedCommandId) {
            case DrawingCommandEnum.lineInsertionCommand:
                this.currentCommand.set(InteractionCommand.lineInsertionCommand);
                this.insertInteraction = new LineInsertInteraction(this.currentCommand, this.drawing.layersController.layers);
                break;
            case DrawingCommandEnum.plineInsertionCommand:
                this.currentCommand.set(InteractionCommand.pathInsertionCommand);
                this.insertInteraction = new PathInsertInteraction(this.currentCommand, this.drawing.layersController.layers);
                break;
            case DrawingCommandEnum.circleInsertionCommand:
                this.currentCommand.set(InteractionCommand.circleInsertionCommand);
                this.insertInteraction = new CircleInsertInteraction(this.currentCommand, this.drawing.layersController.layers);
                break;
            case DrawingCommandEnum.ellipseInsertionCommand:
                this.currentCommand.set(InteractionCommand.ellipseInsertionCommand);
                this.insertInteraction = new EllipseInsertInteraction(this.currentCommand, this.drawing.layersController.layers);
                break;
            case DrawingCommandEnum.textInsertionCommand:
                this.currentCommand.set(InteractionCommand.textInsertionCommand);
                this.insertInteraction = new TextInsertInteraction(this.currentCommand, this.drawing.layersController.layers);
                break;
            default:
                break;
        }

        if (this.insertInteraction) {
            this.insertInteraction.insertionCompleted = async (entity: DxfSvgEntity) => {
                const currentLayer = this.drawing.layersController.layers.find(x=> x.current);
                if (currentLayer) {
                    await this.drawing.insert(currentLayer, entity);
                    // La commande est répétitive jusqu'à Escape ou changement de commande
                    // sauf pour l'insertion de texte
                    if (this.insertInteraction?.entityType === SvgEntityTypesEnum.text) {
                        // Dans le cas d'une insertion de texte on passe en mode saisie après l'insertion
                        this.abortCommand();
                        const t = (entity as DxfSvgText);
                        const bbox = DxfSvgDOM.getEntityBbox(t.entityId);
                        const clientPos = this.getClientPointFromSvgPoint(new Point(bbox?.x, bbox?.y));
                        const pu = this.getSvgPixelUnit();
                        if (clientPos && bbox) {
                            this.setSelection([entity]);
                            this.currentCommand.set(InteractionCommand.textInputCommand);
                            (this.currentInteractions[0] as TextInteraction).beginUpdate(t.text!, clientPos.x, clientPos.y, bbox.width / pu, bbox.height / pu);
                        }
                    } else {
                        this.insertInteraction?.gizmo.reset();
                    }
                }
            }
        }
    }

    setGridOptionCommand(optionId: ToolGridOptionsEnum): void {
        switch (optionId) {
            case ToolGridOptionsEnum.position:
                this.currentCommand.set(InteractionCommand.gridTranslationCommand);
                this.updateGizmos.reticle.set(1);
                break;
            case ToolGridOptionsEnum.align:
                this.currentCommand.set(InteractionCommand.gridRotationCommand);
                this.updateGizmos.reticle.set(2);
                break;
            case ToolGridOptionsEnum.size:
                this.currentCommand.set(InteractionCommand.gridUnitLengthCommand);
                break;
            case ToolGridOptionsEnum.reset:
                this.updateGizmos.gridGizmo.reset();
                break;
            default:
                break;
        }
    }

    abortCommandRequested?: () => void;
    async abortCommand(): Promise<void> {
        this.selectionInteraction.selectedEntities.splice(0);
        this.targetGrips.clear();

        if (this.currentCommand.isInsertionCommand()) {
            await this.updateDrawingExtents();
        }

        this.endCommand();

        this.insertInteraction = undefined;
        if (this.abortCommandRequested) {
            this.abortCommandRequested();
        } else {
            logError("UserInteraction.abortCommandRequested n'est pas écouté");
        }
    }

    endCommand(): void {
        this.currentCommand.clear();
        this.updateGizmos.reticle.set(0);
    }

    raiseToolbarCommandEnded(): void {
        if (this.toolbarCommandEnded) {
            this.toolbarCommandEnded();
        } else {
            logError("UserInteraction.toolbarCommandEnded n'est pas écouté");
        }
    }

    setSelection(entities: DxfSvgEntity[]): void {
        this.selectionInteraction.addSelection(entities);
    }

    setInteractions(entities: DxfSvgEntity[]): void {
        this.updateGizmos.setSelection(entities);
        this.currentInteractions.splice(0);
        this.updateGizmos.gizmos.forEach(g => {
            if (g.entities.length > 0) {
                switch (g.entityType) {
                    case SvgEntityTypesEnum.line:
                        this.currentInteractions.push(new LineInteraction(this.currentCommand, this.drawing.layersController.layers, g as LineGizmo));
                        break;
                    case SvgEntityTypesEnum.circle:
                        this.currentInteractions.push(new CircleInteraction(this.currentCommand, this.drawing.layersController.layers, g as CircleGizmo));
                        break;
                    case SvgEntityTypesEnum.path:
                        this.currentInteractions.push(new PathInteraction(this.currentCommand, this.drawing.layersController.layers, g as PathGizmo));
                        break;
                    case SvgEntityTypesEnum.ellipse:
                        this.currentInteractions.push(new EllipseInteraction(this.currentCommand, this.drawing.layersController.layers, g as EllipseGizmo));
                        break;
                    case SvgEntityTypesEnum.text:
                        this.currentInteractions.push(new TextInteraction(this.currentCommand, this.drawing.layersController.layers, g as TextGizmo));
                        break;
                    case SvgEntityTypesEnum.use:
                        this.currentInteractions.push(new UseInteraction(this.currentCommand, this.drawing.layersController.layers, g as UseGizmo));
                        break;
                    default:
                        break;
                }
            }
        });

        this.currentInteractions.forEach(i => {
            i.geoTileUpdateRequested = async (entities: DxfSvgEntity[]) => {
                await this.drawing.updateGeoTiling(entities);
            }

            if (i.entityType === SvgEntityTypesEnum.text) {
                const ti = i as TextInteraction;
                ti.labelUpdateEnded = async (t: DxfSvgText) => {
                    const s = Container.get(DxfService);
                    await s.update([t]);
                    ti.updateGizmo.updateSingleBbox(t);
                    this.currentCommand.clear();
                }
            }
        });
    }

    async updateDrawingExtents(): Promise<boolean> {
        const svgBbox = this.getSvgBbox(this.layersTagId);
        if (svgBbox) {
            return await this.drawing.updateExtents(svgBbox);
        }
        return false;
    }

    async deleteSelected(): Promise<void> {
        if (await this.drawing.delete(this.selectionInteraction.selectedEntities)) {
            this.selectionInteraction.selectedEntities.splice(0);
            await this.updateDrawingExtents();
        }
    }

    async mouseDown(e: MouseEvent): Promise<void> {
        // On ne traite que les clics du bouton gauche
        if (e.buttons !== 1) return;

        // Si on est déjà dans une commande de sélection rectangulaire, c'est que c'est actuellement le second MouseDown et il termine la commande
        if (this.currentCommand.isDrawingRectangularSelectionCommand()) {
            return;
        }

        const hitPoint = this.getPointPosition(e.clientX, e.clientY);
        if (hitPoint == null) {
            logError("UserInteraction : impossible de récupérer les coordonnées du clic");
            return;
        }

        if (this.currentCommand.isGridCommand()) {
            if (this.currentCommand.isGridRotationCommand()) {
                const itemsAtClientPoint = this.drawing.layersController.getSvgEntitiesFromClientPoint(e.clientX, e.clientY);
                if (itemsAtClientPoint.length > 0) {
                    this.updateGizmos.gridGizmo.align(SvgGeometryService.getGeometry(itemsAtClientPoint)[0]);
                }
            }

            if (this.currentCommand.isGridUnitLengthCommand()) {
                let tmp = hitPoint;
                const nearestGrip = this.targetGrips.getSelected();
                if (nearestGrip) tmp = nearestGrip.point;
                if (this.currentCommand.initialSvgMousePosition) {
                    // Définition du second point, recalcul de la grille et sortie de la commande
                    this.updateGizmos.gridGizmo.unitLength = tmp.distanceTo(this.currentCommand.initialSvgMousePosition);
                    this.targetGrips.clear();

                    this.endCommand();
                    this.raiseToolbarCommandEnded();
                } else {
                    // Définition du premier point de la mesure
                    this.currentCommand.initialSvgMousePosition = tmp;
                }
            }
            return;
        }

        if (this.currentCommand.isTextInputCommand()) {
            await (this.currentInteractions[0] as TextInteraction).focusOut();
            return;
        } else {
            // A remplacer par un click sur le gizmo
            // if (this.currentInteractions.length === 1 && this.currentInteractions[0].entityType === SvgEntityTypesEnum.text && this.selectionInteraction.selectedEntities.length === 1) {
            //     const t = (this.selectionInteraction.selectedEntities[0] as DxfSvgText);
            //     const clientPos = this.getClientPointFromSvgPoint(t.position());
            //     if (clientPos) {
            //         this.currentCommand.set(InteractionCommand.textInputCommand);
            //         (this.currentInteractions[0] as TextInteraction).beginUpdate(t.text!, clientPos.x, clientPos.y);
            //     }
            //     return;
            // }
        }

        // On stocke la position initiale du clic pour pouvoir neutraliser dans le MouseMove le déplacement involontaire au démarrage
        // de la commande de sélection rectangulaire, ce mouvement se produisant avant le MouseUp
        this.currentCommand.initialSvgMousePosition = hitPoint;
      
        if (this.currentCommand.isInsertionCommand() && this.insertInteraction) {
            const nearestGrip = this.targetGrips.getSelected();
            this.insertInteraction.define(nearestGrip?.point);
            return;
        }

        // Si on clique sur un élément c'est qu'on veut le sélectionner
        // La commande est immédiate, on n'attend rien de MouseMove ni de MouseUp
        const itemsAtClientPoint = this.drawing.layersController.getSvgEntitiesFromClientPoint(e.clientX, e.clientY);
        if (itemsAtClientPoint.length > 0) {
            this.setSelection(itemsAtClientPoint);
            return;
        }

        // A ce stade on sait qu'on commence une sélection rectangulaire
        // On mémorise le point cliqué comme premier point du path de sélection
        this.currentCommand.set(InteractionCommand.drawingRectangularSelectionCommand);
        this.selectionInteraction.selectionPathPoints.push(hitPoint);

    }

    timer: number | undefined;
    async mouseMove(e: MouseEvent): Promise<void> {
        if (e.buttons !== 1 && !this.currentCommand.isInsertionCommand() && !this.currentCommand.isGridUnitLengthCommand() && this.updateGizmos.reticle.current === 0) {
            return;
        }
      
        if (this.currentCommand.isNoneCommand() && this.updateGizmos.reticle.current === 0) {
            return;
        }
      
        const hitPoint = this.getPointPosition(e.clientX, e.clientY);
        if (hitPoint == null) {
            logError("Erreur inattendue : impossible de récupérer les coordonnées du clic");
            return;
        }

        this.updateGizmos.reticle.move(hitPoint);
      
        if (this.currentCommand.isNoneCommand()) {
            return;
        }

        if (this.currentCommand.isGridTranslationCommand()) {
            // Arrête le timer
            clearTimeout(this.timer);

            if (this.currentCommand.tempSvgMousePosition &&
                hitPoint.distanceTo(this.currentCommand.tempSvgMousePosition) < this.currentCommand.magnetAttraction) {
                return;
            }
        
            // La souris est sortie du périmètre d'attraction, on masque l'attracteur
            if (this.currentCommand.magnetAttraction > 0) {
                this.targetGrips.clear();
                this.currentCommand.magnetAttraction = 0;
            }
        
            // Actualise la position courante de la souris pour le contrôle de l'attraction lors du prochain MouseMove
            this.currentCommand.tempSvgMousePosition = hitPoint;
            
            // Redémarre le timer
            this.timer = window.setTimeout(TimeoutDetection.detectTargetGrips, 150, hitPoint, this);

            return;
        }

        if (this.currentCommand.isInsertionCommand() && this.insertInteraction) {
            // Arrête le timer
            clearTimeout(this.timer);
            
            if (this.currentCommand.tempSvgMousePosition &&
                hitPoint.distanceTo(this.currentCommand.tempSvgMousePosition) < this.currentCommand.magnetAttraction) {
                return;
            }
        
            // La souris est sortie du périmètre d'attraction, on masque l'attracteur
            if (this.currentCommand.magnetAttraction > 0) {
                this.targetGrips.clear();
                this.currentCommand.magnetAttraction = 0;
            }
        
            // Actualise la position courante de la souris pour le contrôle de l'attraction lors du prochain MouseMove
            this.currentCommand.tempSvgMousePosition = hitPoint;
            
            this.insertInteraction.mouseMove(e, hitPoint);
            
            // Redémarre le timer
            this.timer = window.setTimeout(TimeoutDetection.detectTargetGrips, 150, hitPoint, this);

            return;
        }

        if (this.currentCommand.isGridUnitLengthCommand()) {
            // Arrête le timer
            clearTimeout(this.timer);
            
            if (this.currentCommand.tempSvgMousePosition &&
                hitPoint.distanceTo(this.currentCommand.tempSvgMousePosition) < this.currentCommand.magnetAttraction) {
                return;
            }
        
            // La souris est sortie du périmètre d'attraction, on masque l'attracteur
            if (this.currentCommand.magnetAttraction > 0) {
                this.targetGrips.clear();
                this.currentCommand.magnetAttraction = 0;
            }
        
            // Actualise la position courante de la souris pour le contrôle de l'attraction lors du prochain MouseMove
            this.currentCommand.tempSvgMousePosition = hitPoint;
            
            // Redémarre le timer
            this.timer = window.setTimeout(TimeoutDetection.detectTargetGrips, 150, hitPoint, this);

            return;
        }

        if (this.currentCommand.initialSvgMousePosition == null) {
            return;
        }

        if (this.currentCommand.isDrawingRectangularSelectionCommand()) {
            // On actualise le contour de sélection avec le point sous la souris, ainsi que la collection des éléments compris dans ce contour
            this.selectionInteraction.updateSelectionPathPoints(hitPoint, e.shiftKey);
            return;
        }
      
        if (this.currentCommand.isEntityTranslationCommand() || 
            this.currentCommand.isLineEndpointTranslationCommand() ||
            this.currentCommand.isPathPointTranslationCommand()) {
            // Arrête le timer
            clearTimeout(this.timer);
        
            // Le magnetAttraction sert à empêcher tout mouvement tant que la souris ne s'est pas déplacée d'une distance minimale
            // lorsqu'un attracteur est affiché
            // Cela permet à l'utilisateur de bien voir l'attracteur avant de décider ou non de relâcher la souris
            // sans que le moindre minuscule mouvement ne le fasse disparaître ou changer de place
            if (this.currentCommand.tempSvgMousePosition &&
                hitPoint.distanceTo(this.currentCommand.tempSvgMousePosition) < this.currentCommand.magnetAttraction) {
                return;
            }
        
            // La souris est sortie du périmètre d'attraction, on masque l'attracteur
            if (this.currentCommand.magnetAttraction > 0) {
                this.targetGrips.clear();
                this.currentCommand.magnetAttraction = 0;
            }
        
            // Actualise la position courante de la souris pour le contrôle de l'attraction lors du prochain MouseMove
            this.currentCommand.tempSvgMousePosition = hitPoint;
            this.currentInteractions.forEach(async i => {
                await i.mouseMove(e, hitPoint);
            });
            this.currentCommand.initialSvgMousePosition = hitPoint;
            
            // Redémarre le timer
            this.timer = window.setTimeout(TimeoutDetection.detectTargetGrips, 150, hitPoint, this);

            return;
        }

        this.currentInteractions.forEach(async i => {
            await i.mouseMove(e, hitPoint);
        });

    }

    async mouseUp(e: MouseEvent): Promise<void> {
        // Arrête le timer
        clearTimeout(this.timer);

        if (this.currentCommand.isNoneCommand()) {
            return;
        }
      
        let hitPoint: Point | undefined = undefined;;

        if (this.currentCommand.isDrawingRectangularSelectionCommand()) {
            //this.selectionInteraction.gizmo.show(true);
            this.endCommand();
            this.selectionInteraction.selectionPathPoints.splice(0);
        }
    
        if (this.currentCommand.isSelectingSingleItemCommand()) {
            // Fin de la commande de sélection d'un élément
            this.endCommand();
            return;
        }

        if (this.currentCommand.isGridTranslationCommand() && this.targetGrips.items.length > 0) {
            const nearestGrip = this.targetGrips.getSelected();
            if (nearestGrip) {
                this.updateGizmos.gridGizmo.move(nearestGrip.point);
            }
            return;
        }

        if (this.currentCommand.isEntityTranslationCommand() || 
            this.currentCommand.isLineEndpointTranslationCommand() ||
            this.currentCommand.isPathPointTranslationCommand()) {
            
            if (this.targetGrips.items.length > 0) {
                // S'il y a des grips de positionnement affichés, déplace l'extrémité de la cloison sur le grip le plus proche
                const nearestGrip = this.targetGrips.getSelected();
                if (nearestGrip) {
                    hitPoint = nearestGrip.point;
                }
            } 

            this.targetGrips.clear();

            this.currentInteractions.forEach(async i => {
                await i.mouseUp(e, hitPoint!);
            });
                        
            this.endCommand();

            const s = Container.get(DxfService);
            await s.update(this.selectionInteraction.selectedEntities);
            if (!await this.updateDrawingExtents()) {
                await this.drawing.updateGeoTiling(this.selectionInteraction.selectedEntities);
            }

            return;
        }

        this.currentInteractions.forEach(async i => {
            await i.mouseUp(e, hitPoint!);
        });
    }

    gripCommandSwitchRequested?: () => void;
    async keyDown(e: KeyboardEvent) {
        e.stopPropagation();
        switch (e.key.toLowerCase()) {
            case "a":
                if (this.gripCommandSwitchRequested) {
                    this.gripCommandSwitchRequested();
                } else {
                    logError("UserInteraction.gripCommandSwitchRequested n'est pas écouté");
                }
                break;
            case "w":
                this.panzoomController.resetView();
                break;
            case "escape":
                await this.abortCommand();
                break;
            case "delete":
                await this.deleteSelected();
                break;
            default:
                await this.insertInteraction?.keyDown(e);
                break;
        }
    }

}