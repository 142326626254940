export class FloorTask {
    projectId!: number;
    projectName!: string;
    taskId!: number;
    taskName!: string;
    taskStatus!: number;
    taskDeadLine!: Date;
    taskFloorId!: number;
    taskFloorName!: string;
    taskFloorTaskId!: number;
    taskStatusColor!: string;
    taskStatusName!: string;

    constructor(dtoData: any) {
        if (dtoData) {
            this.projectId = dtoData.projectId;
            this.projectName = dtoData.projectName;
            this.taskId = dtoData.taskId;
            this.taskName = dtoData.taskName;
            this.taskStatus = dtoData.taskStatus;
            this.taskDeadLine = dtoData.taskDeadLine;
            this.taskFloorId = dtoData.taskFloorId;
            this.taskFloorName = dtoData.taskFloorName;
            this.taskFloorTaskId = dtoData.taskFloorTaskId;
            this.taskStatusName = dtoData.taskStatusName;
            if (dtoData.taskStatusColor) {
                this.taskStatusColor = dtoData.taskStatusColor;
            }
        }
    }
}
