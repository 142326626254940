import { RoomActivityStatusTypeDbView } from './room-activity-status-type-db-view';
import { RoomTable } from './../tables/room-table';
import { FloorDataTable } from './../tables/floor-data-table';
import { FloorTable } from './../tables/floor-table';
import { BuildingTable } from './../tables/building-table';
import { RoomActivityStatusTypeTable } from '../tables/room-activity-status-type-table';

export const FloorActivityStatusDbView = {
  databaseTableName: 'floor_activity_status_view',
  buSiteId: BuildingTable.buSiteId,
  flBuildingId: FloorTable.flBuildingId,
  flId: FloorTable.flId,
  flDaTaskId: FloorDataTable.flDaTaskId,
  roActivityStatusTypeId: RoomTable.roActivityStatusTypeId,
  roAcTyName: RoomActivityStatusTypeTable.roAcTyName,
  roAcTyDisplayNameId: RoomActivityStatusTypeTable.roAcTyDisplayNameId,
  roAcTyViDisplayName: RoomActivityStatusTypeDbView.roAcTyViDisplayName,
  roAcTyColor: RoomActivityStatusTypeTable.roAcTyColor,
  flAcStViArea: 'FlAcStVi_Area',
  flAcStViRoundedArea: 'FlAcStVi_RoundedArea',
  roViIsCurrentState: "RoVi_IsCurrentState"
};