@if ( model && model.canCreateSimulation) {
    <div class="flex">
        <p-dropdown [options]="model.simulations" 
            [(ngModel)]="model.selectedSimulation" 
            (onChange)="model.selectedSimulationChange($event)"
            optionLabel="siName" 
            [showClear]="true" 
            placeholder="Simulation...">
        </p-dropdown>

        <mat-button-toggle-group #group="matButtonToggleGroup" [value]="model.selectedMenuCommand" (change)="model.selectedMenuCommandClick($event)">
            <mat-button-toggle [value]="model.StrategyMenuCommandEnum.schedule" matTooltip="Calendrier">
                <mat-icon class="material-icons-outlined enlarged">calendar_month</mat-icon>
            </mat-button-toggle>
            <mat-button-toggle [value]="model.StrategyMenuCommandEnum.workforce" matTooltip="Effectifs">
                <mat-icon class="material-icons-outlined enlarged">people</mat-icon>
            </mat-button-toggle>
            <!-- <mat-button-toggle [value]="model.StrategyMenuCommandEnum.stacking" matTooltip="Occupation">
                <mat-icon class="material-icons-outlined enlarged rotated">stacked_bar_chart</mat-icon>
            </mat-button-toggle> -->
        </mat-button-toggle-group>

        <button mat-icon-button [disabled]="model.creating" (click)="model.createButtonClick()"
            matTooltip="Créer une simulation">
            <mat-icon class="material-icons-outlined">add_task</mat-icon>
        </button>

        <!-- <button mat-icon-button [disabled]="model.addingPhoto || model.selectedMenuCommand !== model.StrategyMenuCommandEnum.schedule" (click)="model.addPhotoButtonClick()" 
            matTooltip="Ajouter une photo">
            <mat-icon class="material-icons-outlined">add_a_photo</mat-icon>
        </button> -->
    </div>
}
