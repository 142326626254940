import { DefinedModelBase } from '../defined-model-base';
import { FloorModelTable } from "../../db-model/tables/floor-model-table";

export class FloorModel extends DefinedModelBase {
    flMoId!: number;
    flMoCategoryId!: number;
    flMoName!: string;
    flMoDisplayNameId!: number;
    flMoBlueprintAttributes!: string | null;
    flMoDisplayOrder!: number;
    flMoScope!: number;
    flMoFsActive!: boolean;
    flMoTableName!: string | null;
    flMoReadGrantId!: number | null;
    flMoUpdateGrantId!: number | null;

    constructor(dtoData: any, tableDef: any[] = []) {
        super(dtoData, [FloorModelTable].concat(tableDef));
    }
}