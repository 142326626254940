import { Component, Input, OnInit, Type, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ResizableLeftPanelVM } from '../../model/resizable-left-panel-vm';
import { ContentDirective } from 'src/app/components-lib/directives/content.directive';
import { IViewModel } from 'src/app/components-lib/shared-model/i-view-model';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { TabIconComponent } from 'src/app/ui/shared/svg-icons/tab-icon/tab-icon.component';
import { Tabs } from 'src/app/components-lib/shared-model/tabs-model';

@Component({
  selector: 'xc-resizable-left-panel',
  standalone: true,
  imports: [CommonModule, ContentDirective, MatButtonModule, MatTooltipModule, MatIconModule, TabIconComponent],
  templateUrl: './resizable-left-panel.component.html',
  styleUrls: ['./resizable-left-panel.component.scss']
})
export class ResizableLeftPanelComponent extends ResizableLeftPanelVM implements OnInit {
  @Input() content: Type<any> | undefined;
  @Input() contentModel: any | undefined;
  @Input() tabs: Tabs | undefined;
  @Input() selectedTabIndex: number | string | undefined;
  @Input() showGutter: boolean = false;
  @Input() tooltip: string = "";
  @ViewChild(ContentDirective, {static: true}) contentHost!: ContentDirective;

  ngOnInit(): void {
    this.loadContent();
  }

  private loadContent(): void {
    if (!this.content || !this.contentModel) return;

    const viewContainerRef = this.contentHost.viewContainerRef;
    const componentRef = viewContainerRef.createComponent<IViewModel>(this.content);
    componentRef.instance.model = this.contentModel;
    componentRef.instance.selectedTabIndex   = this.selectedTabIndex;
  }
}
