import { Component, Input } from '@angular/core';
import { MobilityProjectsGanttTreeVM } from '../../model/mobility-projects-gantt-tree-vm';
import { TreeModule } from 'primeng/tree';
import { InputSwitchModule } from 'primeng/inputswitch';
import { DividerModule } from 'primeng/divider';
import { MVVMEventDestroyLifeCycle } from 'src/app/components-lib/shared-model/mvvm-events-destroy-lifecycle';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'xc-mobility-projects-gantt-tree',
  standalone: true,
  imports: [TreeModule, InputSwitchModule, DividerModule, FormsModule],
  templateUrl: './mobility-projects-gantt-tree.component.html',
  styleUrl: './mobility-projects-gantt-tree.component.scss'
})
export class MobilityProjectsGanttTreeComponent extends MVVMEventDestroyLifeCycle {
  @Input() override model: MobilityProjectsGanttTreeVM | undefined;
}
