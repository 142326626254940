@if (sidePanelResizer != null && sidePanel != null) {
<div id="flBpSidePanel" [style.width.px]="sidePanelResizer.sidePanelWidth" [ngClass]="{'resizing': sidePanelResizer.sidePanelResizing}">
    <div id="flBpSidePanelResizeHandle" 
      [style.cursor]="sidePanelResizer.sidePanelIsFold ? 'default' : 'col-resize'" 
      [style.pointer-events]="sidePanelResizer.sidePanelIsFold ? 'none' : 'initial'" 
      [style.width.px]="sidePanelResizer.resizeHandleWidth" 
      (mousedown)="sidePanelResizer.initializeStackSidenavRezising($event)"
      (mouseup)="sidePanelResizer.onSidenavEndResizing($event)">
    </div>
    <div id="flBpSidePanelGutter">
        <!-- Commande d'enroulement du panneau -->
        <button mat-icon-button id="tab-pin-button" (click)="sidePanelResizer.onPinClick()">
          <mat-icon id="tab-pin-icon" 
              class="material-icons-outlined" 
              [ngClass]="{'fold-direction': !sidePanelResizer.sidePanelIsFold, 'unfold-direction': sidePanelResizer.sidePanelIsFold}">arrow_drop_down</mat-icon>
        </button>
        <div id="flBpSidePanelTabsContainer">
          @for (t of sidePanel.tabs.tabItems; track t) {
  <div class="tab" [ngClass]="{'tab-selected' : t.isSelected, 'tab-disabled' : !t.enabled}" 
              [matTooltip]="t.contentTitle"
              matTooltipPosition="before"
              (click)="sidePanel.tabs.selectedTabClick(t)">
              <mat-icon class="tab-icon material-icons-sharp">{{t.iconName}}</mat-icon>
            @if (t.displayBadge) {
<div class="data-badge"></div>
}
          </div>
}
        </div>
    </div>
    <div id="flBpSidePanelMainContent" 
      [style.width.px]="sidePanelResizer.sidePanelWidth - sidePanelResizer.sidePanelMinWidth"
      [ngClass]="{'resizing': sidePanelResizer.sidePanelResizing}">
      <div id="flBpSidePanelHeader">
            <!-- Titre du contenu du panneau -->
            <div id="flBpSidePanelHeaderTitleContainer">
              <p id="flBpSidePanelHeaderContainerLabel">{{sidePanel.tabs.selectedTab == null ? 'Aucun onglet sélectionné' : sidePanel.tabs.selectedTab.contentTitle}}</p>
          </div>
      </div>
      <xc-bp-viewer-side-panel-content id="flBpSidePanelContent" [model]="sidePanel.content" [selectedTabIndex]="sidePanel.tabs.selectedTab?.index"></xc-bp-viewer-side-panel-content>
    </div>
  </div>
}
  
