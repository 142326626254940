import { TaskBusinessTaskEnum } from "../data-model/enums/task-business-type-enum";
import { TaskStatusEnum } from "../data-model/enums/task-status-enum";

export function isAnyHowEnded(statusId: number): boolean {
    return statusId === TaskStatusEnum.Canceled || statusId === TaskStatusEnum.Closed || statusId === TaskStatusEnum.Ended;
}

export function isAnyHowStarted(statusId: number): boolean {
    return statusId === TaskStatusEnum.Started || statusId === TaskStatusEnum.Idling;
}

export function businessTypeIsFacilityType(type: TaskBusinessTaskEnum): boolean {
    return type === TaskBusinessTaskEnum.Intervention ||
    type === TaskBusinessTaskEnum.PreventiveMaintenance ||
    type === TaskBusinessTaskEnum.CurativeMaintenance;
}

export function businessTypeIsSpacePlanningType(type: TaskBusinessTaskEnum): boolean {
    return type === TaskBusinessTaskEnum.BlueprintImport ||
    type === TaskBusinessTaskEnum.SpacePlanning ||
    type === TaskBusinessTaskEnum.SpacePlanningImport ||
    type === TaskBusinessTaskEnum.ReconcileBlueprint;
}
