import { Component, Input } from '@angular/core';
import { MVVMEventDestroyLifeCycle } from 'src/app/components-lib/shared-model/mvvm-events-destroy-lifecycle';
import { LeaseDurationTypes } from '../../model/lease_duration_types';
import { DynTableComponent } from 'src/app/components-lib/dyn-table/dyn-table.component';

@Component({
  selector: 'xc-lease-duration-types',
  standalone: true,
  imports: [DynTableComponent],
  templateUrl: './lease-duration-types.component.html',
  styleUrl: './lease-duration-types.component.scss'
})
export class LeaseDurationTypesComponent extends MVVMEventDestroyLifeCycle {
  @Input() override model: LeaseDurationTypes | undefined;
}
