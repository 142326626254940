@if (model != null) {

    <mat-form-field appearance="outline">
        <mat-label>Sélectionnez un type d'étiquette</mat-label>
        <mat-select [(ngModel)]="model.labelingType" (ngModelChange)="model.onLabelTypeSelectionChange($event)">
          <mat-option [value]="0" disabled="true">Aucune</mat-option>
          <mat-option [value]="model.FloorModelEnum.WorkplaceLabels">Position de travail</mat-option>
          <mat-option [value]="model.FloorModelEnum.RoomLabels" [disabled]="!model.canUpdatePartition" [style.display]="model.hasPlanningTask ? 'flex' : 'none'">Local</mat-option>
          <mat-option [value]="model.FloorModelEnum.Measurement" [style.display]="model.hasPlanningTask ? 'flex' : 'none'">Cotes</mat-option>
        </mat-select>
    </mat-form-field>

    @if (model.labelingType === model.FloorModelEnum.WorkplaceLabels) {

        <mat-form-field appearance="outline">
            <mat-label>Sélectionnez un type de position de travail</mat-label>
            <mat-select [(ngModel)]="model.workplaceInsertType">
              @for (w of model.workplacesTypes; track w) {
  <mat-option [value]="w.woTyId">{{w.woTyName}}</mat-option>
}
            </mat-select>
        </mat-form-field>

        <!-- Code -->
        <mat-form-field appearance="outline">
            <mat-label>Code</mat-label>
            <input matInput [(ngModel)]="model.workplaceInsertCode"
            required
            name="code" 
            #code="ngModel"
            pattern="[0-9a-zA-Z \- \/ _]{1,10}"
            autocomplete="off">
            <div [hidden]="code.valid || code.pristine">
            Entre 1 et 10 caractères alphanumériques + (- / _)
            </div>
        </mat-form-field>

        <div>
            <mat-slide-toggle #cycleToggle [(ngModel)]="model.workplaceInsertCycle">Création en série</mat-slide-toggle>
  
            <button mat-button 
                    color="primary" 
                    [disabled]="!code.valid"
                    (click)="model.onWorkplaceInsertButtonClick()">Insérer</button>
        </div>

        <div>
            <mat-slide-toggle #refineToggle [(ngModel)]="model.workplaceRenumberRefine">Lisser</mat-slide-toggle>
  
            <button mat-button 
                    color="primary" 
                    [disabled]="!code.valid"
                    (click)="model.onWorkplaceRenumberButtonClick()">Renuméroter</button>
        </div>
    
}


    @if (model.labelingType === model.FloorModelEnum.RoomLabels) {

        <!-- Code -->
        <mat-form-field>
            <mat-label>Code</mat-label>
            <input matInput [(ngModel)]="model.roomInsertCode"
            required
            name="roomCode" 
            #roomCode="ngModel"
            pattern="[0-9a-zA-Z \- \/ _]{1,10}"
            autocomplete="off">
            <div [hidden]="roomCode.valid || roomCode.pristine">
            Entre 1 et 10 caractères alphanumériques + (- / _)
            </div>
        </mat-form-field>
  
        <button mat-button 
                color="primary" 
                [disabled]="!roomCode.valid"
                (click)="model.onRoomInsertButtonClick()">Créer un local</button>
    
}

    @if (model.labelingType === model.FloorModelEnum.Measurement) {

        <button mat-button 
        color="primary" 
        (click)="model.onMeasurementButtonClick()">Créer une cote</button>
    
}

    <!-- <div id="commandsPanel">
        <button mat-button color="primary">Ajouter à l'étude</button>
    </div> -->


}

