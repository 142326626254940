import { SvgEntityTypesEnum } from "src/app/core/model/svg-model/svg-entity-type-enum";
import { BpSvgGroup } from "../../bp-svg-core-model/bp-svg-group";
import { BpSvgLine } from "../../bp-svg-core-model/bp-svg-line";
import { BlueprintSvgEntityTypeEnum } from "./blueprint-svg-entity-type-enum";

export class BpSvgMeasureLines extends BpSvgGroup {
    leaderlines: BpSvgLine[] = [];
    marks: BpSvgLine[] = [];
    extensionLines: BpSvgLine[] = [];
    measureLine: BpSvgLine | undefined;

    constructor(leaderlines: BpSvgLine[], marks: BpSvgLine[], extensionLines: BpSvgLine[], measureLine: BpSvgLine | undefined) {
        super({entityType: SvgEntityTypesEnum.g}, BlueprintSvgEntityTypeEnum.measureLines);

        this.leaderlines = leaderlines;
        this.marks = marks,
        this.extensionLines = extensionLines;
        this.measureLine = measureLine;
        
        this.entities.push(...leaderlines);
        this.entities.push(...marks);
        this.entities.push(...extensionLines);
        if (measureLine) {
            this.entities.push(measureLine);
        }
    }
}