import { Point } from "src/app/core/model/geometry-model/point.model";
import { SvgEntityTypesEnum } from "src/app/core/model/svg-model/svg-entity-type-enum";
import { DxfLayerDTO } from "src/app/core/services/backend-services/dto/dxf-layer-dto";
import { DxfSvgEntity } from "../svg/dxf-svg-entity";
import { EntityInteraction } from "./entity-interaction";
import { IEntityInteraction } from "./i-entity-interaction";
import { InteractionCommand } from "./interaction-command";
import { SelectionInteraction } from "./selection-interaction";
import { CircleGizmo } from "../gizmos/update/circle-gizmo";
import { DxfSvgCircle } from "../svg/dxf-svg-circle";

export class CircleInteraction extends EntityInteraction implements IEntityInteraction<DxfSvgCircle> {
    entityType: SvgEntityTypesEnum = SvgEntityTypesEnum.circle;
    layers: DxfLayerDTO[] = [];
    selectionSet: DxfSvgCircle[] = [];
    selectionInteraction: SelectionInteraction | undefined;
    currentCommand: InteractionCommand;
    updateGizmo: CircleGizmo;

    constructor(command: InteractionCommand, layers: DxfLayerDTO[], gizmo: CircleGizmo) {
        super();
        this.currentCommand = command;
        this.layers = layers;
        this.updateGizmo = gizmo;
    }

    activate(entities: DxfSvgCircle[], toRemove: boolean): Promise<void> {
        throw new Error("Method not implemented.");
    }
    
    deactivate(): void {
        throw new Error("Method not implemented.");
    }
    
    abort(): void {
        throw new Error("Method not implemented.");
    }
    
    deleteSelectedItems(): Promise<void> {
        throw new Error("Method not implemented.");
    }
    
    beginInsert(args: any): void {
        throw new Error("Method not implemented.");
    }
    
    selectionDeleted?: (() => void) | undefined;
    raiseSelectionDeleted(): void {
        throw new Error("Method not implemented.");
    }
    
    async mouseDown(e: MouseEvent, hitPoint: Point): Promise<boolean> {
        throw new Error("Method not implemented.");
    }
    
    async mouseMove(e: MouseEvent, hitPoint: Point): Promise<void> {
        if (this.currentCommand.isEntityTranslationCommand() && this.currentCommand.initialSvgMousePosition) {
            const delta = hitPoint.minus(this.currentCommand.initialSvgMousePosition);
            this.updateGizmo.translateCircles(delta);
        }
    }
    
    async mouseUp(e: MouseEvent, hitPoint: Point | undefined): Promise<void> {
        if (this.currentCommand.isEntityTranslationCommand()) {
            if (hitPoint && this.currentCommand.gripClicked) {
                const delta = hitPoint.minus(this.currentCommand.gripClicked.point);
                this.updateGizmo.translateCircles(delta);
            }
            this.updateGizmo.endTranslate();       
            this.updateGizmo.clearCurrent();   
            return;         
        }
    }
    
    keyDown(e: KeyboardEvent): Promise<void> {
        throw new Error("Method not implemented.");
    }

}