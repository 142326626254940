export class TasksValidationsRefAreCheck {
    hasRefAreaUpdateGrant!: boolean;
    isUniqueOrLastValidation!: boolean;
    isRefAreaWillBeUpdated!: boolean;
    currentFloorRefArea!: number;
    currentTaskFloorRefArea!: number;

    constructor(dtoData: any){
      this.hasRefAreaUpdateGrant = dtoData["HasRefAreaUpdateGrant"];
      this.isUniqueOrLastValidation = dtoData["IsUniqueOrLastValidation"];
      this.isRefAreaWillBeUpdated = dtoData["IsRefAreaWillBeUpdated"];
      this.currentFloorRefArea = dtoData["CurrentFloorRefArea"];
      this.currentTaskFloorRefArea = dtoData["CurrentTaskFloorRefArea"];
    }
  }
  