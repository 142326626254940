import { EquipmentPlanningStateTable } from "../../db-model/tables/equipment-planning-state-table";
import { DefinedModelBase } from "../defined-model-base";

export class EquipmentPlanningState extends DefinedModelBase {
    eqPlStId!: number;
    eqPlStName!: string;
    eqPlStColor!: string;
    eqPlStDisplayNameId!: number;
    eqPlStScopeId!: number;

    constructor(dtoData: any, tableDef: any[] = []) {
        super(dtoData, [EquipmentPlanningStateTable].concat(tableDef));
    }
}