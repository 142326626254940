import { RoomAllocationViewSet } from 'src/app/core/model/data-model/view-set/room-allocation-view-set';
import { HttpParams } from "@angular/common/http";
import { Room } from "src/app/core/model/data-model/tables/room";
import { FloorDataTable } from "src/app/core/model/db-model/tables/floor-data-table";
import { RoomTable } from "src/app/core/model/db-model/tables/room-table";
import { TaskFloorModelTable } from "src/app/core/model/db-model/tables/task-floor-model-table";
import { TaskTable } from "src/app/core/model/db-model/tables/task-table";
import { Point } from "src/app/core/model/geometry-model/point.model";
import { ApiEndpoints } from "src/app/core/services/api-endpoints";
import { ApiService } from "src/app/core/services/api-service";
import { Service } from "typedi";
import { RoomSharing } from 'src/app/core/model/data-model/tables/room-sharing';
import { TablesSetsEnum } from 'src/app/core/model/data-model/tables-sets-enum';
import { TablesNamesEnum } from 'src/app/core/model/db-model/tables-names-enum';
import { RoomAllocationTable } from 'src/app/core/model/db-model/tables/room-allocation-table';
import { UsageContextIdEnum } from 'src/app/core/model/usage-context-id-enum';
import { RoomContourDataDTO } from './dto/room-contour-data-dto';
import { UpdatedFloorDataDTO } from '../../../services/dto/updated-floor-data-dto';
import { RoomAllocationDisplay } from '../../../dialog/room-allocation-editor/model/room-allocation-display';
import { BpSvgRoomLabel } from '../../../svg-entities/model/bp-svg-room-label';
import { BlueprintLayer } from '../../../svg-entities/model/layers/blueprint-layer';
import { BpSvgRoom } from '../../../svg-entities/model/bp-svg-room';
import { BpSvgDef } from '../../../bp-svg-core-model/bp-svg-def';
import { getBpEntities } from '../../../bp-svg-core-model/bp-svg-entity-builder';
import { BpSvgGroup } from '../../../bp-svg-core-model/bp-svg-group';
import { BpSvgUse } from '../../../bp-svg-core-model/bp-svg-use';
import { RoomSet } from '../model/room-set';
import { SimilarRooms } from '../model/similar-room/similar-rooms';
import { TightBbox } from '../model/similar-room/tight-bbox';
import { BlueprintEquipmentLayer } from '../../../svg-entities/model/layers/blueprint-equipment-layer';

@Service({ global: true })  
export class BpRoomService extends ApiService {
    private getSimilarRoomsEndPoint(roomId: number, tolerance: number): string {return `${ApiEndpoints.floorDataRoom()}/similar/${roomId}/${tolerance}`};

    constructor() {
        super();
    }

    async createRoom(
        taskId: number, 
        code: string, 
        svgStatement: string, 
        wallIds: number[], 
        hitPoint: Point, 
        fromCurrentPartitioning: boolean): Promise<{ roomSet: RoomSet, svgLabel: BpSvgRoomLabel, svgRoom: BpSvgRoom, taskFloorModels: BlueprintLayer[]} | null> {
        const dto: any = {};
        dto[FloorDataTable.flDaTaskId] = taskId;
        dto[RoomTable.roCode] = code;
        dto[FloorDataTable.flDaSvgStatement] = svgStatement;
        dto['WallIds'] = wallIds;
        dto['x'] = hitPoint.x;
        dto['y'] = hitPoint.y;

        const result = await this.postAsync<any>(this.endPoint(ApiEndpoints.floorDataRoom()), dto, new HttpParams().set('fromCurrent', String(fromCurrentPartitioning)));
        if (result != null) {
            const tmp = {} as { roomSet: RoomSet, svgLabel: BpSvgRoomLabel, svgRoom: BpSvgRoom, taskFloorModels: BlueprintLayer[] };
            const groupEntities = getBpEntities(result.payload['SvgGroupDTO'][0].entities);
            tmp.svgRoom = new BpSvgRoom(groupEntities[0], result.payload['SvgGroupDTO'][0]);
            tmp.svgLabel = new BpSvgRoomLabel(result.payload['SvgGroupDTO'][1]);
            tmp.roomSet = new RoomSet(result.payload['Room']);
            tmp.taskFloorModels = [];
            if (result.payload[TaskFloorModelTable.databaseTableName]) {
                tmp.taskFloorModels = result.payload[TaskFloorModelTable.databaseTableName].map((e: any) => new BlueprintLayer(e, taskId)) as BlueprintLayer[];
            }
            return tmp;
        }
        return null;
    }
    
    async deleteRooms(roomIds: number[]): Promise<UpdatedFloorDataDTO[]> {
        let params = new HttpParams();
        roomIds.forEach(id => {
            params = params.append(`id`, String(id));
        });
        const result = await this.deleteAsync<UpdatedFloorDataDTO[]>(this.endPoint(ApiEndpoints.floorDataRoom()), params);
        if (result != null) {
            return result.payload.map(item => {
                return new UpdatedFloorDataDTO(item);
            })
        }
        return [];
    }
          
    async getSvgContour(taskId: number, x: number, y: number, isUpdate: boolean): Promise<RoomContourDataDTO | null> {
        let params = new HttpParams();
        params = params.append('taskId', String(taskId));
        params = params.append('x', String(x));
        params = params.append('y', String(y));
        params = params.append('isUpdate', String(isUpdate));
        const result = await this.getAsync<any>(this.endPoint(ApiEndpoints.svgContour), UsageContextIdEnum.none, params);
        if (result != null) {
            return new RoomContourDataDTO(result.payload);
        }
        return null;
    }
        
    async reCalculateRoom(roomId: number, svgStatement: string, x: number, y: number, wallIds: number[]): Promise<{ room: Room, svgGroups: BpSvgGroup[] } | null> {
        const dto: any = {};
        dto[FloorDataTable.flDaSvgStatement] = svgStatement;
        dto['WallIds'] = wallIds;
        dto['x'] = x;
        dto['y'] = y;
        const result = await this.patchAsync<any>(this.endPoint(ApiEndpoints.updateContour(roomId)), dto);
        if (result != null) {
            const tmp = {} as { room: Room, svgGroups: BpSvgGroup[] };
            tmp.svgGroups = result.payload['SvgGroupDTO'] as BpSvgGroup[];
            tmp.room = new Room(result.payload['Room']);
            return tmp;
        }
        return null;
    }
    
    async addRoomToTask(roomFloorDataId: number, taskId: number): Promise<any> {
        const dto = {
          [FloorDataTable.flDaId]: roomFloorDataId,
          [TaskTable.taId]: taskId,
        };
        const result = await this.postAsync<any>(this.endPoint(ApiEndpoints.addRoomToTask), dto);
        if (result != null) {
            return result.payload;
        }
        return null;
    }
    
    async getSimilarRooms(roomId: number, tolerance: number): Promise<SimilarRooms | null> {
        const result = await this.getAsync<any>(this.endPoint(this.getSimilarRoomsEndPoint(roomId, tolerance)));
        if (result != null && result.payload != null) {
            const p = result.payload;
            const defsResult = p['FloorCatalog'].map((x: any)=> new BpSvgDef(x));
            const roomsResult = p['SvgGroupDTO'].map((x: any)=> {
                const groupEntities = getBpEntities(x.entities);
                return new BpSvgRoom(groupEntities[0], x);
            });;
            const furnitureResult = p['SvgUseDTO'].map((x: any)=> new BpSvgUse(x));
            const floorModel = new BlueprintEquipmentLayer(p[TablesNamesEnum.FloorModel], p[TablesNamesEnum.FloorModel].taskId)
            return new SimilarRooms(defsResult, roomsResult, furnitureResult, TightBbox.fromData(p["SourceTightBbox"].vertices), floorModel);
        }
        return null;
    }
    
    async downloadRoomSharing(roomIds: number[]): Promise<RoomSharing[]> {
        let params = new HttpParams();
        roomIds.forEach(id => {
            params = params.append(`id`, String(id));
        });
        return this.getArrayAsync<RoomSharing>(TablesNamesEnum.RoomSharing, this.endPoint(ApiEndpoints.roomSharing), UsageContextIdEnum.none, params);
    }

    async updateRoomsSharing(floorDataIds: number[], perimeterId: number): Promise<RoomSharing[]> {
        const dto: any = {};
        dto['RoomIds'] = floorDataIds;
        dto['UpdateValue'] = perimeterId;
        return this.patchAsyncAndGetRowsArray<RoomSharing>(TablesNamesEnum.RoomSharing, this.endPoint(ApiEndpoints.roomSharing), dto);
    }

    async updateRoomAllocations(floorDataIds: number[], roomAllocations: RoomAllocationDisplay[] | RoomAllocationViewSet[]): Promise<RoomAllocationViewSet[]> {
        if (roomAllocations.length > 0) {
            const allocations: any[] = [];
            roomAllocations.forEach((e: RoomAllocationDisplay | RoomAllocationViewSet) => {
                const item: any = {};
                if (e instanceof RoomAllocationDisplay) {
                    item[RoomAllocationTable.roAlBusinessUnitId] = e.businessUnitId;
                    item[RoomAllocationTable.roAlRate] = e.ratio;
                } else {
                    item[RoomAllocationTable.roAlBusinessUnitId] = e.dataSet.roAlBusinessUnitId;
                    item[RoomAllocationTable.roAlRate] = e.dataSet.roAlRate;
                }
                allocations.push(item);
            });
            const dto: any = {};
            dto['RoomIds'] = floorDataIds;
            dto['UpdateValue'] = allocations;
            return await this.patchAsyncAndGetRowsArray<RoomAllocationViewSet>(TablesSetsEnum.RoomAllocationViewSet, this.endPoint(ApiEndpoints.roomAllocate()), dto);
        }
        return [];
    }
 
}