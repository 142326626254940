import { DirectoryTable } from './../../db-model/tables/directory-table';
import { DefinedModelBase } from '../defined-model-base';
export class Directory extends DefinedModelBase {
    diId!: number;
    diCode!: string;
    diTitle!: string;
    diFirstName!: string;
    diLastName!: string;
    diBusinessUnitId!: number;
    diOccupancyRatio!: number;
    diIsInternal!: boolean;

    constructor(dtoData: any, tableDef: any[] = []) {
        super(dtoData, [DirectoryTable].concat(tableDef));
    }
}