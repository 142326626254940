
export class EquipmentPlanningStateCommand {
    id: number;
    name: string;
    selected: boolean = false;
    enabled: boolean = true;

    constructor(id: number, name: string) {
        this.id = id;
        this.name = name;
    }
}