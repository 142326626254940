import { Point } from "src/app/core/model/geometry-model/point.model";
import { SvgEntity } from "src/app/core/model/svg-model/svg-entity.model";
import { SvgText } from "src/app/core/model/svg-model/svg-text.model";
import { UpdateGizmo } from '../../update-gizmo.model';
import { EntitiesSelectionSet } from '../../../../itself/model/interaction/entities-selection-set';
import { PeopleLocationDisplay } from '../../../../../dialog/workplace-allocation-editor/model/people-location-display';
import { BpSvgPeopleLabel } from '../../../../../svg-entities/model/bp-svg-people-label';
import { BpSvgWorkplaceLabel } from '../../../../../svg-entities/model/bp-svg-workplace-label';
import { SvgEntityTypesEnum } from 'src/app/core/model/svg-model/svg-entity-type-enum';
import { logError } from 'src/app/core/services/logging-service';

export class WorkplaceUpdateGizmo implements UpdateGizmo {
    selectedLabels: EntitiesSelectionSet | undefined;
    transientLabel: SvgText | undefined;
    initialMousePosition: Point | undefined;
    translatingPeopleLabels: BpSvgPeopleLabel[] = [];
    isActive: boolean = false;
    commandsPosition: Point | undefined;
    inserting: boolean = false;
    isEditable: boolean = false;

    show(labels: EntitiesSelectionSet): void {
        this.selectedLabels = labels;
        this.updateCommandsPosition();
        this.isActive = true;
    }

    hide(): void {
        this.selectedLabels = undefined;
        this.transientLabel = undefined;
        this.isActive = false;
    }

    showTransient(code: string): void {
        const data = { x: 0, y: 0, fontSize: '0.5', text: code, entityType: SvgEntityTypesEnum.text }
        this.transientLabel = new SvgText(data);
    }

    hideTransient(): void {
        this.transientLabel = undefined;
        this.inserting = false;
    }

    moveTransientLabel(hitPoint: Point): void {
        this.transientLabel?.moveTo(hitPoint);
    }

    updateCommandsPosition(): void {
        if (this.selectedLabels?.count === 1) {
            const bg = (this.selectedLabels.items[0] as BpSvgWorkplaceLabel).backgroundRectangle;
            this.commandsPosition = new Point(bg.x + (bg.width / 2) - 0.12, bg.y - 0.24);
        }
    }

    selectionSetUpdate(): void {
        this.updateCommandsPosition();
    }

    initializeTranslation(initialMousePosition: Point, peoplelabels: BpSvgPeopleLabel[]): void {
        this.initialMousePosition = initialMousePosition;
        this.translatingPeopleLabels = peoplelabels;
        if (this.selectedLabels?.count === 1) {
            (this.selectedLabels.items[0] as BpSvgWorkplaceLabel).storeCurrentPosition();
        }
    }

    translate(hitPoint: Point): void {
        if (this.initialMousePosition) {
            const delta = new Point(hitPoint.x - this.initialMousePosition.x, hitPoint.y - this.initialMousePosition.y).vector();
            if (this.selectedLabels?.count === 1) {
                const uniqueLabel = this.selectedLabels.items[0] as BpSvgWorkplaceLabel;
                uniqueLabel.translate(delta);
                // Actualise la position des commandes
                this.updateCommandsPosition();
                // Actualise les lignes de repère des étiquettes de personne
                if (this.translatingPeopleLabels != null) {
                    this.translatingPeopleLabels.forEach(pl => {
                        pl.moveLeaderEndTo(uniqueLabel.backgroundRectangle);
                    });
                }
            }
        }
    }

    cancelTranslation(): void {
        if (this.selectedLabels?.count === 1) {
            const uniqueLabel = this.selectedLabels.items[0] as BpSvgWorkplaceLabel;
            uniqueLabel.cancelTranslation();
            // Actualise la position des commandes
            this.updateCommandsPosition();
            // Actualise les lignes de repère des étiquettes de personne
            if (this.translatingPeopleLabels != null) {
                this.translatingPeopleLabels.forEach(pl => {
                    pl.moveLeaderEndTo(uniqueLabel.backgroundRectangle);
                });
            }
        }
    }

    overlayMouseDown?: (e: MouseEvent) => void;
    onOverlayMouseDown(e: MouseEvent, label: SvgEntity): void {
        if (e.button !== 0) return;

        if (e.shiftKey) return;
        e.stopPropagation();
        if (this.overlayMouseDown) {
            this.overlayMouseDown(e);
        } else {
            logError("WorkplaceUpdateGizmo.overlayMouseDown n'est pas écouté");
        }
    }

    allocationsChange?: (e: PeopleLocationDisplay[]) => void;
    async onAllocationsChange(result: PeopleLocationDisplay[]): Promise<void> {
        if (this.allocationsChange) {
            this.allocationsChange(result);
        } else {
            logError("WorkplaceUpdateGizmo.allocationsChange n'est pas écouté");
        }
    }
}
