import { logError } from "src/app/core/services/logging-service";

export class SelectableRealEstateTreeNode {
    databaseTableName: string;
    id: number;
    parentId: number | null;
    name: string;
    latitude: number;
    longitude: number;
    children: SelectableRealEstateTreeNode[] = [];
    selected: boolean = false;
    childrenPartiallySelected: boolean = false;
    parentNode: SelectableRealEstateTreeNode | undefined;

    constructor(databaseTableName: string, id: number, parentId: number | null, name: string, latitude: number, longitude: number) {
        this.databaseTableName = databaseTableName;
        this.id = id;
        this.parentId = parentId;
        this.name = name;
        this.latitude = latitude;
        this.longitude = longitude;
    }

    setChildren(children: SelectableRealEstateTreeNode[]): void {
        this.children = children;
        this.setChildrenSelectedChangeListner(children);
    }

    setChildrenSelectedChangeListner(children: SelectableRealEstateTreeNode[]): void {
        children.forEach(c => {
            c.selectedChange = () => {
                this.checkChildrenSelected();
            }
        });
    }

    checkChildrenSelected(): void {
        let allValuesAreSame: boolean = true;
        let tmp = this.children[0].selected;
        for (const child of this.children) {
            if (child.selected !== tmp || child.childrenPartiallySelected) {
                this.childrenPartiallySelected = true;
                this.selected = false;
                allValuesAreSame = false;
                break;
            }
        }

        if (allValuesAreSame) {
            this.childrenPartiallySelected = false;
            this.selected = tmp;
        }

        this.raiseSelectedChangedEvent();
    }

    setSelected(selected: boolean): void {
        this.selected = selected;
        if (this.children != null) {
            this.children.forEach(c => {
                c.setSelected(this.selected);
            });
        }
        this.raiseSelectedChangedEvent();
    }

    raiseSelectedChangedEvent(): void {
        if (this.selectedChange) {
            this.selectedChange();
        } else {
            logError("SelectableRealEstateTreeNode.selectedChange n'est pas écouté par SelectableRealEstateTreeVM");
        }
    }

    selectedChange?: () => void;
    onSelectedChange(): void {
        this.setSelected(!this.selected);

        this.raiseSelectedChangedEvent();
    }
}
