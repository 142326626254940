import { Service } from "typedi";
import { ApiService } from "../../api-service";
import { catchError, lastValueFrom, map } from "rxjs";
import { HttpParams } from "@angular/common/http";
import { ApiControllers } from "../../api-endpoints";

@Service({ global: true })  
export class FsService extends ApiService {
    private documentEndPoint: string = `${ApiControllers.fs}/document`;

    constructor() {
        super();
    }

    async getBlobUrl(folderPath: string, documentName: string): Promise<string> {
        let params = new HttpParams();
        params = params.set('documentPath', `${folderPath}/${documentName}`);
        return await lastValueFrom(this.http.get(this.endPoint(this.documentEndPoint), {responseType: 'blob', params: params})
        .pipe(
            map(data => {
                return URL.createObjectURL(data);
            }),
            catchError(this.handleError.bind(this))
        ));
    }

    async getDocument(folderPath: string, documentName: string): Promise<string> {
        let params = new HttpParams();
        params = params.set('documentPath', `${folderPath}/${documentName}`);
        return await lastValueFrom(this.http.get(this.endPoint(this.documentEndPoint), {responseType: 'blob', params: params})
        .pipe(
            map(data => {
                return data.text();
            }),
            catchError(this.handleError.bind(this))
        ));
    }
}