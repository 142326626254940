import { SvgEntityTypesEnum } from "src/app/core/model/svg-model/svg-entity-type-enum";
import { InsertGizmo } from "./insert-gizmo";
import { Point } from "src/app/core/model/geometry-model/point.model";
import { Vector } from "src/app/core/model/geometry-model/vector.model";
import { logError } from "src/app/core/services/logging-service";

export class LineInsertGizmo extends InsertGizmo {
    startPoint!: Point;
    endPoint!: Point;
    strokeWidth: number = 0.01;
    startPointIsDefined: boolean = false;

    constructor() {
        super(SvgEntityTypesEnum.line);
        this.initialize();
    }

    protected override initialize(): void {
        this.startPoint = Point.origin();
        this.endPoint = Point.origin();
        this.startPointIsDefined = false;
        this.completed = false;
    }

    update(point: Point): void {
        if (!this.startPointIsDefined) {
            this.startPoint = point;
            this.endPoint = point.translate(new Vector(0.5, -0.5));
        } else {
            this.endPoint = point;
        }
    }

    define(point: Point | undefined): void {
        if (point) this.update(point);
        if (!this.startPointIsDefined) {
            this.startPointIsDefined = true;
        } else {
            this.completed = true;
            if (this.insertionCompleted) {
                this.insertionCompleted();
            } else {
                logError("LineInsertGizmo.insertionCompleted n'est pas écouté");
            }
        }
    }
}