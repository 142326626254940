import { Component, Input } from '@angular/core';
import { MVVMEventDestroyLifeCycle } from 'src/app/components-lib/shared-model/mvvm-events-destroy-lifecycle';
import { MobilityJobTasks } from '../../model/mobility-job-tasks';
import { DynTableComponent } from 'src/app/components-lib/dyn-table/dyn-table.component';

@Component({
  selector: 'xc-mobility-job-tasks',
  standalone: true,
  imports: [DynTableComponent],
  templateUrl: './mobility-job-tasks.component.html',
  styleUrl: './mobility-job-tasks.component.scss'
})
export class MobilityJobTasksComponent extends MVVMEventDestroyLifeCycle {
  @Input() override model: MobilityJobTasks | undefined;
}
