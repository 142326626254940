import { TablesNamesEnum } from 'src/app/core/model/db-model/tables-names-enum';
import { PolicyCity } from './../../model/data-model/policy/tables/policy-city';
import { Service } from "typedi";
import { ApiControllers } from "../api-endpoints";
import { ApiService } from "../api-service";

@Service({ global: true })  
export class AddressService extends ApiService {
    private refCitiesEndpoint(countryId: number): string { return `${ApiControllers.policyGeneric}/CitiesReferences/${countryId}` };
    private cityEndpoint: string = `${ApiControllers.address}/city`;

    constructor() {
        super();
    }

    async getRefCities(countryId: number): Promise<PolicyCity[]> {
        return await this.getArrayAsync<PolicyCity>(TablesNamesEnum.PolicyCity, this.endPoint(this.refCitiesEndpoint(countryId)));
    }

    async insertPolicyCity(city: PolicyCity): Promise<any> {
        const result = await this.postAsync<any>(this.endPoint(this.cityEndpoint), city);
        return result?.payload[0];
    }
}