import { TablesNamesEnum } from './../tables-names-enum';

export const BuildingTable = {
    databaseTableName: TablesNamesEnum.Building,
    buId: 'Bu_Id',
    buCode: 'Bu_Code',
    buName: 'Bu_Name',
    buSiteId: 'Bu_SiteId',
    buIsActive: 'Bu_IsActive',
    buIsFictive: 'Bu_IsFictive',
    buLocation: 'Bu_Location',
    buDefaultPerimeterId: 'Bu_DefaultPerimeterId',
    buMailingAddressId: "Bu_MailingAddressId",
    buDeliveryAddressId: "Bu_DeliveryAddressId",
    buParkingAddressId: "Bu_ParkingAddressId",
    buLobbyAddressId: "Bu_LobbyAddressId"
  };
  