import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayerCommandButtonComponent } from '../../layer-command-button/ui/layer-command-button.component';
import { VariousSelectionGizmoModel } from '../model/various-selection-gizmo-model';

@Component({
  selector: 'g[xc-various-selection-gizmo]',
  standalone: true,
  imports: [CommonModule, LayerCommandButtonComponent],
  templateUrl: './various-selection-gizmo.component.svg',
  styleUrls: ['./various-selection-gizmo.component.scss']
})
export class VariousSelectionGizmoComponent {
  @Input() model: VariousSelectionGizmoModel | undefined;
}
