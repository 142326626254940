import { ZConstraintTypeEnum } from "src/app/core/model/data-model/enums/z-constraint-type-enum";
import { ZColumnConstraintView } from "src/app/core/model/data-model/views/z-column-constraint-view";
import { ZColumnView } from "src/app/core/model/data-model/views/z-column-view";
import { Field } from "./field";
import { FieldBuilder } from "./field-builder";
import { FieldTypeEnum } from "./field-type-enum";
import { ZDbViewColumnView } from "src/app/core/model/data-model/views/z-db-view-column-view";

export class StringField extends Field<string> {
    minLength: number = 0;
    maxLength: number = 10;
    allowsDiacritics: boolean;

    constructor(colId: string, rowId: any, value: string, definition: ZColumnView | ZDbViewColumnView, constraints: ZColumnConstraintView[], readOnlyByScope: boolean) {
        super(FieldTypeEnum.string, colId, rowId, value, definition, constraints, readOnlyByScope);
    
        this.minLength = FieldBuilder.getConstraint<number>(this.constraints, ZConstraintTypeEnum.Minimum, this.allowsNull ? 0 : 1);
        this.maxLength = FieldBuilder.getConstraint<number>(this.constraints, ZConstraintTypeEnum.Maximum, 10);
        this.allowsDiacritics = FieldBuilder.getConstraint<boolean>(this.constraints, ZConstraintTypeEnum.AllowsDiacritics, false);

        this.errorsCheck = this.specificErrorsCheck;
    }

    specificErrorsCheck(): string[] {
        let result: string[] = [];

        if (this.readOnly) return result;
        
        this.constraints.forEach(c => {
            switch (c.coCoConstraintType) {
                case ZConstraintTypeEnum.Minimum:
                    if (Number(c.coCoValue) > this.value?.length) {
                        result.push(`${this.colId}: ${c.coCoVidisplayName}`);
                    }
                    break;
                case ZConstraintTypeEnum.Maximum:
                    if (Number(c.coCoValue) < this.value?.length) {
                        result.push(`${this.colId}: ${c.coCoVidisplayName}`);
                    }
                    break;
                case ZConstraintTypeEnum.AllowsDiacritics:
                    const regex = new RegExp(/[a-zA-Z0-9]/);
                    if (c.coCoValue === "false" && !this.value.match(regex)) {
                        result.push(`${this.colId}: ${c.coCoVidisplayName}`);
                    }
                    break;
                case ZConstraintTypeEnum.AllowsNull:
                    if (c.coCoValue === "false" && this.value?.length === 0) {
                        result.push(`${this.colId}: ${c.coCoVidisplayName}`);
                    }
                    break;
                default:
                    break;
            }
        });

        return result;
    }

    // valueChanged():void {

    // }
}