import { RoomLayoutTypeStatusDbView } from "../../db-model/views/room-layout-type-status-db-view";
import { DefinedModelBase } from "../defined-model-base";

export class RoomLayoutTypeStatusView extends DefinedModelBase {
    buSiteId!: number;
    flBuildingId!: number; 
    roLayoutTypeId!: number;
    roLaTyStViLayoutTypeTree!: string;
    roLaTyStViStatusTableName!: string; 
    roLaTyStViStatusId!: number; 
    roLaTyStViStatusDisplayName!: string; 
    roLaTyStViStatusColor!: string; 
    roLaTyStViTotalArea!: number; 
    roLaTyStViMinArea!: number; 
    roLaTyStViMaxArea!: number; 
    roLaTyStViAvgArea!: number; 
    roLaTyStViCount!: number; 

    constructor(dtoData: any) {
        super(dtoData, [RoomLayoutTypeStatusDbView]);
    }
}