import { Component, Input } from '@angular/core';
import { MobilityProjectsScheduleVM } from '../../model/mobility-projects-schedule-vm';
import { MVVMEventDestroyLifeCycle } from 'src/app/components-lib/shared-model/mvvm-events-destroy-lifecycle';
import { GanttScheduleBaseComponent } from '../../../shared/view/gantt-schedule-base/gantt-schedule-base.component';
import { logError } from 'src/app/core/services/logging-service';

@Component({
  selector: 'xc-mobility-projects-schedule',
  standalone: true,
  imports: [GanttScheduleBaseComponent],
  templateUrl: './mobility-projects-schedule.component.html',
  styleUrl: './mobility-projects-schedule.component.scss'
})
export class MobilityProjectsScheduleComponent extends MVVMEventDestroyLifeCycle {
  @Input() override model: MobilityProjectsScheduleVM | undefined;

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    if (this.model) {
      document.removeEventListener('keydown', this.model.kd, true);
      this.model = undefined
    } else {
      logError("MobilityProjectsScheduleComponent - Le document event listener n'a pas pu être supprimé");
    }
  }
}
