@if (model) {
    @switch (model.type) {
        @case (NestedToolbarType.map) {
            <xc-map-toolbar [model]="model"/>
        }
        @case (NestedToolbarType.sites) {
            <xc-sites-toolbar [model]="model"/>
        }
        @case (NestedToolbarType.buildings) {
            <xc-buildings-toolbar [model]="model"/>
        }
        @case (NestedToolbarType.floors) {
            <xc-floors-toolbar [model]="model"/>
        }
        @case (NestedToolbarType.businessUnits) {
            <pb-business-units-toolbar [model]="model"/>
        }
    }
}
