export const RoomLayoutTypeStatusDbView = {
    databaseTableName: "room_layout_type_status_view",
    buSiteId: "Bu_SiteId", 
    flBuildingId: "Fl_BuildingId", 
    roLayoutTypeId: "Ro_LayoutTypeId",
    roLaTyStViLayoutTypeTree: "RoLaTyStVi_LayoutTypeTree",
    roLaTyStViStatusTableName: "RoLaTyStVi_StatusTableName",  
    roLaTyStViStatusId: "RoLaTyStVi_StatusId",  
    roLaTyStViStatusDisplayName: "RoLaTyStVi_StatusDisplayName",  
    roLaTyStViStatusColor: "RoLaTyStVi_StatusColor",  
    roLaTyStViTotalArea: "RoLaTyStVi_TotalArea",  
    roLaTyStViMinArea: "RoLaTyStVi_MinArea",  
    roLaTyStViMaxArea: "RoLaTyStVi_MaxArea",  
    roLaTyStViAvgArea: "RoLaTyStVi_AvgArea",  
    roLaTyStViCount: "RoLaTyStVi_Count"
}