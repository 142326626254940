import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StrategyScheduleVM } from '../../model/strategy-schedule-vm';
import { SvgTimelineComponent } from '../svg-timeline/svg-timeline.component';
import { SvgPhotoMarkerGizmoComponent } from '../svg-photo-marker-gizmo/svg-photo-marker-gizmo.component';
import { SvgAddPhotoGizmoComponent } from '../svg-add-photo-gizmo/svg-add-photo-gizmo.component';

@Component({
  selector: 'xc-strategy-schedule',
  standalone: true,
  imports: [CommonModule, SvgTimelineComponent, SvgPhotoMarkerGizmoComponent, SvgAddPhotoGizmoComponent],
  templateUrl: './strategy-schedule.component.svg',
  styleUrls: ['./strategy-schedule.component.scss']
})
export class StrategyScheduleComponent {
  @Input() model: StrategyScheduleVM | undefined;
}
