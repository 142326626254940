import { PageModel } from "src/app/ui/main/model/page-model";
import { LayoutTypeContentVM } from "./layout-type-content-vm";
import { AppMenuRouteEnum } from "src/app/core/model/data-model/enums/app-menu-route-enum";
import { LayoutTypesTreeModel } from "./layout-types-tree-model";

export class LayoutTypeContainerVM extends PageModel {
    content: LayoutTypeContentVM | undefined;
    tree: LayoutTypesTreeModel | undefined;

    private constructor() {
        super(AppMenuRouteEnum.layout_realestate_layouttypes, 0, "048e893a-8747-44ac-a13b-af465bcabcc7");
    }

    static async newAsync(): Promise<LayoutTypeContainerVM> {
        const result = new LayoutTypeContainerVM();
        result.tree = await LayoutTypesTreeModel.newAsync();
        result.content = await LayoutTypeContentVM.newAsync();
        result.tree.selectRoot();
        return result;
    }

    override removeListeners(): void {
        this.content?.removeListeners();
        this.tree?.removeListeners();
    }
}