import { Point } from "src/app/core/model/geometry-model/point.model";
import { InsertGizmo } from "./insert-gizmo";
import { SvgEntityTypesEnum } from "src/app/core/model/svg-model/svg-entity-type-enum";
import { logError } from "src/app/core/services/logging-service";

export class CircleInsertGizmo extends InsertGizmo {
    centerPoint!: Point;
    radius: number = 0.01;
    strokeWidth: number = 0.001;
    centerPointIsDefined: boolean = false;

    constructor() {
        super(SvgEntityTypesEnum.circle);
        this.initialize();
    }

    protected override initialize(): void {
        this.centerPoint = Point.origin();
        this.radius = 0.01;
        this.centerPointIsDefined = false;
        this.completed = false;
    }

    override update(point: Point): void {
        if (!this.centerPointIsDefined) {
            this.centerPoint = point;
        } else {
            this.radius = point.distanceTo(this.centerPoint);
        }
    }

    override define(point: Point | undefined): void {
        if (point) this.update(point);
        if (!this.centerPointIsDefined) {
            this.centerPointIsDefined = true;
        } else {
            this.completed = true;
            if (this.insertionCompleted) {
                this.insertionCompleted();
            } else {
                logError("CircleInsertGizmo.insertionCompleted n'est pas écouté");
            }
        }
    }
}
