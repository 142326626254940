import { RoomActivityStatusTypeTable } from './../../db-model/tables/room-activity-status-type-table';
import { DefinedModelBase } from '../defined-model-base';

export class RoomActivityStatusType extends DefinedModelBase {
    roAcTyId!: number;
    roAcTyName!: string;
    roAcTyDisplayNameId!: number;
    roAcTyColor!: string;

    constructor(dtoData: any, tableDef: any[] = []) {
        super(dtoData, [RoomActivityStatusTypeTable].concat(tableDef));
    }
}