import { StrategyMenuCommandEnum } from './strategy-menu-command-enum';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { Simulation } from 'src/app/core/model/data-model/tables/simulation';
import { TablesNamesEnum } from 'src/app/core/model/db-model/tables-names-enum';
import { SimulationTable } from 'src/app/core/model/db-model/tables/simulation-table';
import { DyntService } from 'src/app/core/services/backend-services/dynt-service';
import Container from 'typedi';
import { DropdownChangeEvent } from 'primeng/dropdown';
import { EventListener } from 'src/app/core/events/event-listener';
import { readableUUID } from 'src/app/core/events/event-listener-uuid';
import { logError } from 'src/app/core/services/logging-service';

export class StrategyToolbarVM extends EventListener {
    static selectedSimulationChangeEvent = "selectedSimulationChangeEvent";

    simulations: Simulation[] = [];
    selectedSimulation: Simulation | undefined;
    selectedMenuCommand: StrategyMenuCommandEnum = StrategyMenuCommandEnum.none;
    StrategyMenuCommandEnum = StrategyMenuCommandEnum;

    // TODO : récupérer les habilitations du user
    canCreateSimulation: boolean = true;
    creating: boolean = false;
    addingPhoto: boolean = false;

    private constructor() {
        super(readableUUID(StrategyToolbarVM.name));
    }

    static async newAsync(): Promise<StrategyToolbarVM> {
        const result = new StrategyToolbarVM();
        await result.loadSimulations();
        return result;
    }

    async loadSimulations(): Promise<void> {
        const d = Container.get(DyntService);
        this.simulations = await d.downloadTable<Simulation>(TablesNamesEnum.Simulation, undefined, SimulationTable.siDepth, 1);
    }

    selectMenuCommand(command: StrategyMenuCommandEnum): void {
        this.raiseSelectedMenuCommandChanged(command);
    }

    creationRequested?: () => void;
    createButtonClick(): void {
        if (this.creationRequested) {
            this.creationRequested();
        } else {
            logError("StrategyToolbarVM.creationRequested n'est pas écouté");
        }
    }

    addPhotoRequested?: () => void;
    addPhotoButtonClick(): void {
        if (this.addPhotoRequested) {
            this.addPhotoRequested();
        } else {
            logError("StrategyToolbarVM.addPhotoRequested n'est pas écouté");
        }
    }

    selectedMenuChanged?: (selectedCommand: StrategyMenuCommandEnum) => void;
    selectedMenuCommandClick(e: MatButtonToggleChange): void {
        this.raiseSelectedMenuCommandChanged(e.value);
    }

    raiseSelectedMenuCommandChanged(selectedCommand: StrategyMenuCommandEnum): void {
        if (this.selectedMenuChanged) {
            this.selectedMenuCommand = selectedCommand;
            this.selectedMenuChanged(selectedCommand);
        } else {
            logError("StrategyToolbarVM.selectedMenuChanged n'est pas écouté");
        }
    }

    selectedSimulationChange(e: DropdownChangeEvent): void {
        const id = e.value == null ? null : e.value.siId;
        this.emitEventAsync(StrategyToolbarVM.selectedSimulationChangeEvent, id);
    }
}