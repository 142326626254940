import { CreationStepFourComponent } from './../creation-step-four/creation-step-four.component';
import { CreationStepThreeComponent } from './../creation-step-three/creation-step-three.component';
import { CreationStepTwoComponent } from './../creation-step-two/creation-step-two.component';
import { CreationStepOneComponent } from './../creation-step-one/creation-step-one.component';
import { FormBuilder, FormsModule, Validators, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from 'src/app/core/material.module';
import { CreationStepperVM } from './../../model/creation-stepper-vm';
import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'xc-creation-stepper',
  standalone: true,
  imports: [CommonModule, MaterialModule, FormsModule, CreationStepOneComponent, CreationStepTwoComponent, CreationStepThreeComponent, CreationStepFourComponent],
  templateUrl: './creation-stepper.component.html',
  styleUrls: ['./creation-stepper.component.scss']
})
export class CreationStepperComponent {
  @Input() model: CreationStepperVM | undefined;
}
