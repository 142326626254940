export enum FieldTypeEnum {
    none = 0,
    string = 1,
    number = 2,
    boolean = 3,
    date = 4,
    list = 5,
    color = 6,
    custom = 7,
    imageFile = 8,
    selectCommand
}