import { getView } from '../views/view-builder';
import { DisplayThemableView } from './../views/display-themable-view';
import { DisplayThemeView } from './../views/display-theme-view';

export class DisplayThemeItemViewSet {
    static readonly databaseTableName: string = "display_theme_item_view";
    dataSet: (DisplayThemeView & DisplayThemableView);

    constructor(dtoData: any) {
        this.dataSet = getView(dtoData, DisplayThemeView, DisplayThemableView);
    }
}