import { TaskLinkTable } from '../../db-model/tables/task-link-table';
import { DefinedModelBase } from '../defined-model-base';

export class TaskLink extends DefinedModelBase {
    taLiId!: number;
    taLiSourceTaskId!: number;
    taLiTargetTaskId!: number;
    taLiLinkType!: number;

    constructor(dtoData: any, tableDef: any[] = []) {
        super(dtoData, [TaskLinkTable].concat(tableDef));
    }

    static fromValues(taLiId: number, taLiSourceTaskId: number, taLiTargetTaskId: number, taLiLinkType: number): TaskLink {
        return new TaskLink({ [TaskLinkTable.taLiId]: taLiId, [TaskLinkTable.taLiSourceTaskId]: taLiSourceTaskId, [TaskLinkTable.taLiTargetTaskId]: taLiTargetTaskId, [TaskLinkTable.taLiLinkType]: taLiLinkType});
    }
}  