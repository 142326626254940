import { CountryTable } from '../../db-model/tables/country-table';
import { DefinedModelBase } from '../defined-model-base';
export class Country extends DefinedModelBase {
        cnId!: number;
        cnIsoCode!: string;
        cnName!: string;
        cnDisplayNameId!: number;
        cnContinentId!: number;

    constructor(dtoData: any, tableDef: any[] = []) {
        super(dtoData, [CountryTable].concat(tableDef));
    }
}