import { Arc } from "./arc.model";
import { GeometricElementType } from "./geometric-element-type.model";
import { GeometricElement } from "./geometric-element.model";
import { Point } from "./point.model";
import { Segment } from "./segment.model";
import { Vector } from "./vector.model";

export class Circle implements GeometricElement {
    elementType: GeometricElementType = GeometricElementType.Circle;
    center: Point = Point.origin();
    radius: number = 0;

    constructor(center: Point, radius: number) {
        this.center = center;
        this.radius = radius;
    }

    equals(c: Circle): boolean{
        return this.center.equals(c.center) && 
        this.radius === c.radius;
    }

    translate (translate: Vector): Circle {
        const newCenter = this.center.translate(translate);
        return new Circle(newCenter, this.radius);
    }

    /**
     * 
     * @returns Tableau des points de quadrant right, top, left, botton
     */
    getQuadrants(): Point[] {
        const result: Point[] = [];

        const p0 = new Point(this.center.x + this.radius, this.center.y);
        result.push(p0);
        const p1 = new Point(this.center.x, this.center.y + this.radius);
        result.push(p1);
        const p2 = new Point(this.center.x - this.radius, this.center.y);
        result.push(p2);
        const p3 = new Point(this.center.x, this.center.y - this.radius);
        result.push(p3);

        return result;
    }

    getIntersects(s: Segment | Arc): Point[] {
        const result: Point[] = [];

        if (s instanceof Segment) {
            const baX = s.endPoint.x - s.startPoint.x;
            const baY = s.endPoint.y - s.startPoint.y;
            const caX = this.center.x - s.startPoint.x;
            const caY = this.center.y - s.startPoint.y;

            const a = baX * baX + baY * baY;
            const bBy2 = baX * caX + baY * caY;
            const c = caX * caX + caY * caY - this.radius * this.radius;

            const pBy2 = bBy2 / a;
            const q = c / a;

            const d = pBy2 * pBy2 - q;

            if (d < 0)
            {
                return result;
            }

            const tmpSqrt = Math.sqrt(d);
            const abScalingFactor1 = -pBy2 + tmpSqrt;
            const abScalingFactor2 = -pBy2 - tmpSqrt;

            const p1 = new Point(s.startPoint.x - baX * abScalingFactor1, s.startPoint.y - baY * abScalingFactor1);
            if (s.contains(p1))
            {
                result.push(p1);
            }
            if (d == 0)
            {
                return result;
            }

            const p2 = new Point(s.startPoint.x - baX * abScalingFactor2, s.startPoint.y - baY * abScalingFactor2);
            if (s.contains(p2))
            {
                result.push(p2);
            }

            return result;
        }

        // https://lucidar.me/fr/mathematics/how-to-calculate-the-intersection-points-of-two-circles/
        const c = this.center;
        const a = (s as Arc);
        const ac = a.center();
        //const c = new Circle(ac, this.radius);
        const ccd = c.distanceTo(ac);
        const r1 = this.radius;
        const r2 = a.radius;
        const r1Pr2 = r1 + r2;
        const r1Mr2 = Math.abs(r1 - r2);
        // si ccd > r1Pr2 les cercles sont trop éloignés et il n'y a pas d'intersection
        // si ccd < r1Mr2 un cercle est à l'interieur de l'autre et il n'y a pas d'intersection
        // si ccd = 0 et r1 = r2 les cercles sont confondus et il y a un nombre infini de points d'intersection
        // si les cercles sont tangents et ccd = r1Pr2 il n'y a qu'un seul point d'intersection
        // si les cercles sont tangents et ccd = r1Mr2, il n'y a qu'un seul point d'intersection, un cercle est à l'intérieur de l'autre
        // si ccd < r1Pr2, il y a deux points d'intersection.

        const l1 = ((r1 * r1) - (r2 * r2) + (ccd * ccd)) / (ccd * 2);
        const l2 = ((r2 * r2) - (r1 * r1) + (ccd * ccd)) / (ccd * 2);
        const h = Math.sqrt((r1 * r1) - (l1 * l1));
        const mx = c.x + (l1 / ccd) * (ac.x - c.x);
        const my = c.y + (l1 / ccd) * (ac.y - c.y);
        const m = new Point(mx, my);
        // projection orthogonale
        const ll = new Segment(c, ac);
        const h1 = ll.getOrthogonalOffset(m, h);
        const h2 = ll.getOrthogonalOffset(m, -h);

        return [h1, h2];
    }

    /**
     * https://www.xarg.org/2018/02/create-a-circle-out-of-three-points/
     * @param p1 
     * @param p2 
     * @param p3 
     * @returns 
     */
    static fromThreePoints(p1: Point, p2: Point, p3: Point): Circle {
        const x1 = p1.x;
        const y1 = p1.y;
        const x2 = p2.x;
        const y2 = p2.y;
        const x3 = p3.x;
        const y3 = p3.y;
      
        const a = x1 * (y2 - y3) - y1 * (x2 - x3) + x2 * y3 - x3 * y2;
      
        const b = (x1 * x1 + y1 * y1) * (y3 - y2) + (x2 * x2 + y2 * y2) * (y1 - y3) + (x3 * x3 + y3 * y3) * (y2 - y1);
       
        const c = (x1 * x1 + y1 * y1) * (x2 - x3) + (x2 * x2 + y2 * y2) * (x3 - x1) + (x3 * x3 + y3 * y3) * (x1 - x2);

        const x = -b / (2 * a);
        const y = -c / (2 * a);
      
        return new Circle(new Point(x, y), Math.hypot(x - x1, y - y1));
    }
}