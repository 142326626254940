import { AppGrantTable } from '../../db-model/tables/app-grant-table';
import { DefinedModelBase } from '../defined-model-base';
export class AppGrant extends DefinedModelBase {
    apGrId!: number;
    apGrName!: string;
    apGrGroupId!: number;
    apGrDisplayNameId!: number;

    constructor(dtoData: any, tableDef: any[] = []) {
        super(dtoData, [AppGrantTable].concat(tableDef));
    }
}