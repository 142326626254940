import { AfterViewInit, Directive, ElementRef } from '@angular/core';

@Directive({
  standalone: true,
  selector: '[xcAutoFocus]'
})
export class AutoFocusDirective implements AfterViewInit {

  constructor(private element:ElementRef) {}

   ngAfterViewInit(){
    setTimeout(() => {
      // To avoid race condition
      this.element.nativeElement.focus();
    });
   }
}
