<div class="equipment-form-container">
    <div id="content-header">
        <span class="header">Insertion d'équipements</span>
    </div>
    <mat-divider></mat-divider>

    @if (model) {

    <!-- Type d'équipement (calque) -->
    <mat-form-field id="eq-type" appearance="outline">
        <mat-label>Type d'équipement</mat-label>
        <mat-select [(ngModel)]="model.selectedLayer" (ngModelChange)="model.onLayerChange($event)" name="layerSelect">
            @for (layer of model.editableLayers; track layer) {
            <mat-option [value]="layer">
                {{ layer.layerName }}
            </mat-option>
            }
        </mat-select>
    </mat-form-field>

    <!-- Catégorie d'équipement -->
    @if (model.equipmentCategories.length > 0) {
    <mat-form-field id="eq-category" appearance="outline">
        <mat-label>Catégorie d'équipement</mat-label>
        <mat-select [(ngModel)]="model.selectedCategory" (ngModelChange)="model.onCategoryChange($event)"
            name="categorySelect">
            @for (g of model.equipmentCategories; track g) {
            <mat-optgroup [label]="g.name">
                @for (cat of g.children; track cat) {
                <mat-option [value]="cat">
                    {{ cat.name }}
                </mat-option>
                }
            </mat-optgroup>
            }
        </mat-select>
    </mat-form-field>
    }

    <!-- Equipements -->
    <div>
        @if (model.categoryEquipments.length > 0) {
        <mat-selection-list #items [multiple]="false" hideSingleSelectionIndicator>
            @for (item of model.categoryEquipments; track item) {
            <mat-list-option class="picto-option" (click)="model.onEquipmentClick($event, item)"
                (mouseenter)="model.ecMouseEnter($event, item.imageUrl)" (mouseleave)="model.ecMouseLeave($event)"
                [value]="item">
                <div>
                    <div class="eq-label">
                        <span class="catcode">{{ item.equipmentName }}</span>
                        <span class="provider">{{ item.providerName }}</span>
                    </div>
                    <img class="picto" [src]="'https://static.echo-on.fr/' + item.imageUrl" />
                </div>
            </mat-list-option>
            }
        </mat-selection-list>
        }
        <!-- <img class="thumbnail" 
            [class.thumbnail-over]="model.overedImageUrl != undefined"
            [style.display]="model.overedImageUrl ? 'block' : 'none'" 
            [style.top.px]="model.overedImagePos?.y" 
            [style.left.px]="model.overedImagePos?.x" 
            [src]="'https://static.echo-on.fr/'+ model.overedImageUrl">
    -->
    </div>

    <!-- Remplacements -->
    <!-- <div class="box"> -->
    <!-- <span class="box-header">Remplacement</span>
    <mat-form-field id="providers" appearance="outline">
        <mat-label>Fournisseurs</mat-label>
        <mat-select [(ngModel)]="model.selectedProvider" name="ProviderSelect">
            @for (p of model.equipmentsProviders; track p) {
            <mat-option [value]="p">
                {{ p.prCoName }}
            </mat-option>
            }
        </mat-select>
    </mat-form-field> -->

    <!-- <button mat-button color="primary" (click)="model.onAddRoomToTaskButtonClick()">Ajouter un local à l'étude</button> -->
    <!-- </div> -->

    }
</div>