import { Component, Input } from '@angular/core';
import { MVVMEventDestroyLifeCycle } from 'src/app/components-lib/shared-model/mvvm-events-destroy-lifecycle';
import { BuildingsVM } from '../buildings-vm';
import { DonutChartWrapperComponent } from 'src/app/ui/shared/charts/donut/view/donut-chart-wrapper/donut-chart-wrapper.component';
import { StackChartWrapperComponent } from 'src/app/ui/shared/charts/stack/ui/stack-chart-wrapper/stack-chart-wrapper.component';
import { BuildingAxonoWrapperComponent } from 'src/app/ui/shared/charts/axono/view/building-axono-wrapper/building-axono-wrapper.component';
import { InputGroupModule } from 'primeng/inputgroup';
import { InputGroupAddonModule } from 'primeng/inputgroupaddon';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { SelectButtonModule } from 'primeng/selectbutton';
import { BusinessUnitPieChartComponent } from '../../charts/business-unit-chart/view/business-unit-pie-chart/business-unit-pie-chart.component';
import { PropertiesEditorComponent } from '../../properties-editor/view/properties-editor/properties-editor.component';

@Component({
  selector: 'xc-buildings',
  standalone: true,
  imports: [
    BusinessUnitPieChartComponent, 
    DonutChartWrapperComponent,
    StackChartWrapperComponent, 
    BuildingAxonoWrapperComponent, 
    PropertiesEditorComponent,
    AutoCompleteModule,
    InputGroupModule,
    InputGroupAddonModule,
    SelectButtonModule
  ],
  templateUrl: './buildings.component.html',
  styleUrl: './buildings.component.scss'
})
export class BuildingsComponent extends MVVMEventDestroyLifeCycle {
  @Input() override model: BuildingsVM | undefined;
}
