import { TablesNamesEnum } from '../tables-names-enum';

export const RoomLayoutTypeTable = {
    databaseTableName: TablesNamesEnum.RoomLayoutType,
    roLaTyId: 'RoLaTy_Id',
    roLaTyCode: 'RoLaTy_Code',
    roLaTyDepth: 'RoLaTy_Depth',
    roLaTyParentId: 'RoLaTy_ParentId',
    roLaTyIsActive: 'RoLaTy_IsActive',
    roLaTyName: 'RoLaTy_Name',
    roLaTyColor: 'RoLaTy_Color',
    roLaTyScopeId: 'RoLaTy_ScopeId',
    roLaTyIsNUA: 'RoLaTy_IsNUA'
}