import { DefinedModelBase } from '../defined-model-base';
import { DxfImportTable } from '../../db-model/tables/dxf-import-table';
export class DxfImport extends DefinedModelBase {
    dxImId!: number;
    dxImDate!: Date;
    dxImFileName!: string;
    dxImExtents!: string;

    constructor(dtoData: any, tableDef: any[] = []) {
        super(dtoData, [DxfImportTable].concat(tableDef));
    }
}