import { Point } from "src/app/core/model/geometry-model/point.model";

export class DonutChartSlicePathVM {
    p1: Point;
    p2: Point;
    p3: Point;
    p4: Point;
    regularPath: string;
    swollenPath: string;

    constructor(p1: Point, p2: Point, p3: Point, p4: Point, regularPath: string, swollenPath: string) {
        this.p1 = p1;
        this.p2 = p2;
        this.p3 = p3;
        this.p4 = p4;
        this.regularPath = regularPath;
        this.swollenPath = swollenPath;
    }
}