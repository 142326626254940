import { TaskValidation } from "src/app/core/model/data-model/tables/task-validation";

export class TaskValidationDTO {
    taskValidation!: TaskValidation;
    isLastValidation!: boolean;
  
    constructor(dtoData: any) {
        this.taskValidation = new TaskValidation(dtoData['TaskValidation']);
        this.isLastValidation = dtoData["IsLastValidation"];
    }
}