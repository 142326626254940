import { TaskFloorTable } from '../../db-model/tables/task-floor-table';
import { DefinedModelBase } from '../defined-model-base';

export class TaskFloor extends DefinedModelBase {
    taFlTaskId!: number;
    taFlFloorId!: number;
    taFlIsSourceFloor!: boolean;
    taFlIsTargetFloor!: boolean;
    taFlIsPlanningTask!: boolean;

    constructor(dtoData: any, tableDef: any[] = []) {
        super(dtoData, [TaskFloorTable].concat(tableDef));
    }
}