export class TabItem {
    index: string;
    iconName: string;
    contentTitle: string;
    isSelected: boolean = false;
    enabled: boolean = true;
    displayBadge: boolean = false;

    constructor(index: string, iconName: string, contentTitle: string, displayBadge: boolean = false) {
        this.index = index;
        this.iconName = iconName;
        this.contentTitle = contentTitle;
        this.displayBadge = displayBadge;
    }
}