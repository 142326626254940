@if (model) {
<div id="axono">
	<svg class="hidden">
        <defs>
            <symbol id="icon-search" viewBox="0 0 32 32">
                <title>search</title>
                <path class="path1" d="M26.819 24.917c0.262 0.262 0.262 0.688 0 0.951l-0.951 0.951c-0.263 0.262-0.688 0.262-0.951 0l-6.656-6.656c-0.072-0.072-0.12-0.158-0.153-0.248-1.365 0.998-3.041 1.593-4.862 1.593-4.562 0-8.261-3.699-8.261-8.261s3.699-8.261 8.261-8.261c4.562 0 8.261 3.699 8.261 8.261 0 1.821-0.595 3.497-1.593 4.862 0.091 0.032 0.176 0.080 0.248 0.153l6.656 6.656zM13.246 7.739c-3.041 0-5.508 2.466-5.508 5.508s2.466 5.508 5.508 5.508 5.508-2.466 5.508-5.508c0-3.041-2.466-5.508-5.508-5.508z"></path>
            </symbol>
            <symbol id="icon-stack" viewBox="0 0 32 32">
                <title>stack</title>
                <path class="path1" d="M29.143 11.071l-13.143-6.571-13.143 6.571 13.143 6.571 13.143-6.571zM16 6.681l8.781 4.39-8.781 4.39-8.781-4.39 8.781-4.39zM26.51 14.684l2.633 1.316-13.143 6.571-13.143-6.571 2.633-1.316 10.51 5.255zM26.51 19.612l2.633 1.316-13.143 6.571-13.143-6.571 2.633-1.316 10.51 5.255z"></path>
            </symbol>
			<symbol id="icon-angle-up" viewBox="0 0 21 32">
                <title>angle-up</title>
                <path class="path1" d="M19.196 21.143q0 0.232-0.179 0.411l-0.893 0.893q-0.179 0.179-0.411 0.179t-0.411-0.179l-7.018-7.018-7.018 7.018q-0.179 0.179-0.411 0.179t-0.411-0.179l-0.893-0.893q-0.179-0.179-0.179-0.411t0.179-0.411l8.321-8.321q0.179-0.179 0.411-0.179t0.411 0.179l8.321 8.321q0.179 0.179 0.179 0.411z"></path>
            </symbol>
            <symbol id="icon-angle-down" viewBox="0 0 21 32">
                <title>angle-down</title>
                <path class="path1" d="M19.196 13.143q0 0.232-0.179 0.411l-8.321 8.321q-0.179 0.179-0.411 0.179t-0.411-0.179l-8.321-8.321q-0.179-0.179-0.179-0.411t0.179-0.411l0.893-0.893q0.179-0.179 0.411-0.179t0.411 0.179l7.018 7.018 7.018-7.018q0.179-0.179 0.411-0.179t0.411 0.179l0.893 0.893q0.179 0.179 0.179 0.411z"></path>
            </symbol>
        </defs>
    </svg>
    <div id="building">
        @if (model.selectedFloor) {
<span class="selected-floor-label">{{model.selectedFloor.floor.flName}}</span>
}
        <div class="surroundings" [ngClass]="{'surroundings--hidden' : model.isExpanded}">
            <img class="surroundings__map" src="assets/img/surroundings.svg" alt="Surroundings" />
        </div>
        <div id="floors">
            @for (f of model.floors; track f) {
  <div class="floor" 
                [style.transform]="'translate3d(' + f.overX + 'px, 0, ' + (f.notOverAboveZ + f.notOverBelowZ + 38 * f.absoluteElevation / model.elevationMax) + 'vh)'" 
               
                [attr.data-text]="f.floor.flCode"
                (click)="model.floorClick($event, f)"
                (mouseenter)="model.floorMouseEnter($event, f)"
                (mouseleave)="model.floorMouseLeave($event, f)"
                [ngClass]="{'floor-selected' : f.isSelected, 
                    'floor-not-selected-above' : f.isNotSelectedAbove, 
                    'floor-not-selected-below' : f.isNotSelectedBelow}">
                <svg class="svg" [attr.viewBox]="f.viewbox"
                    xmlns="http://www.w3.org/2000/svg"
                    width="100%" height="100%" preserveAspectRatio="xMidYMid meet">
                    @if (f.extents) {
<path
                        class="floor-extents fade-transition"
                        [ngClass]="{'extended' : f.isSelected}"
                        [style.fill]="f.extents.fill"
                        [style.opacity]="f.extents.opacity"
                        [style.fill-rule]="f.extents.fillRule"
                        [style.fill-opacity]="f.extents.fillOpacity"
                        [attr.d]="$any(f.extents).d"
                        >
                    </path>
}
                    @for (r of f.svgRooms; track r) {
  <path
                        class="room fade-transition"
                        [style.fill]="r.fill"
                        [style.opacity]="r.opacity"
                        [style.fill-rule]="r.fillRule"
                        [style.fill-opacity]="r.fillOpacity"
                        [style.display]="r.display"
                        [attr.d]="$any(r).d"
                        [ngClass]="{'hidden-by-process' : r.hiddenByProcess}">
                    </path>
}
                </svg>

                @if (!model.selectedFloor) {
<span class="floor-label">{{f.floor.flCode}}</span>
}

            </div>
}
        </div>
    </div>
    <nav class="axononav" [ngClass]="{'axononav--hidden' : !model.isExpanded}">
        <button class="boxbutton axononav__button--up" aria-label="Go up" [ngClass]="{'boxbutton--disabled' : !model.hasFloorsAbove}"
            (click)="model.onUpButtonClick($event)">
            <svg class="icon icon--angle-down">
                <use xlink:href="#icon-angle-up"></use>
            </svg>
        </button>
        <button class="boxbutton boxbutton--dark axononav__button--all-levels" aria-label="Back to all levels"
            (click)="model.onCollapseButtonClick($event)">
            <svg class="icon icon--stack">
                <use xlink:href="#icon-stack"></use>
            </svg>
        </button>
        <button class="boxbutton axononav__button--down" aria-label="Go down" [ngClass]="{'boxbutton--disabled' : !model.hasFloorsBelow}"
            (click)="model.onDownButtonClick($event)">
            <svg class="icon icon--angle-down">
                <use xlink:href="#icon-angle-down"></use>
            </svg>
        </button>
    </nav>
    <aside class="spaces-list" id="spaces-list">

    </aside>
</div>
}
