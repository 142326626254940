import { PerimeterTypeTable } from "../../db-model/tables/perimeter-type-table";
import { DefinedModelBase } from "../defined-model-base";

export class PerimeterType extends DefinedModelBase {
    peTyId!: number;
    peTyName!: string;
    peTyScope!: number;
    peTyDisplayNameId!: number;

    constructor(dtoData: any, tableDef: any[] = []) {
        super(dtoData, [PerimeterTypeTable].concat(tableDef));
    }
}