import { LeaseDurationTypes } from './../../pages/planning/strategy/referentials/model/lease_duration_types';
import { AddressesAdminVM } from './../../pages/layout/real-estate/referentials/model/addresses-admin-vm';
import { HttpClient } from '@angular/common/http';
import { PageModel } from "./page-model";
import Container from 'typedi';
import { MenuPanelVM } from './menu-panel-vm';
import { MaintenanceBrowserVM } from 'src/app/ui/pages/planning/maintenance/main/model/maintenance-browser-vm';
import { CadConverterContainerVM } from 'src/app/ui/pages/graphic-works/blueprint/model/cad-converter-container-vm';
import { DrawingContainerModel } from '../../pages/graphic-works/picto/model/drawing-container-model';
import { ClientsVM } from '../../pages/clients/model/clients-vm';
import { AppUserService } from 'src/app/core/services/backend-services/app-user-service';
import { BusinessUnitsBrowserVM } from '../../pages/layout/organization/model/business-units-browser-vm';
import { PageRouter } from './router/page-router';
import { EventListener } from 'src/app/core/events/event-listener';
import { MainEventsEnum } from './main-events-enum';
import { ClientPageEventsEnum } from '../../pages/clients/model/client-page-events-enum';
import { readableUUID } from 'src/app/core/events/event-listener-uuid';
import { MobilityBrowserVM } from '../../pages/planning/mobility/main/model/mobility-browser-vm';
import { ClientVM } from './client-vm';
import { AppMenuRouteEnum } from 'src/app/core/model/data-model/enums/app-menu-route-enum';
import { RolesAdminVM } from '../../pages/administration/roles/model/roles-admin-vm';
import { UserAccountAdminVM } from '../../pages/administration/user-account/model/user-account-admin-vm';
import { GrantedDomainsVM } from '../../pages/administration/domains/model/granted-domains-vm';
import { PurchasesListVM } from '../../pages/layout/inventory/referentials/purchases/model/purchases-list-vm';
import { StoresManage } from '../../pages/layout/inventory/referentials/storage/model/stores-manage';
import { ProvidersManage } from '../../pages/layout/inventory/referentials/providers/model/providers-manage';
import { CatalogPage } from '../../pages/layout/inventory/catalog/model/catalog-page';
import { CustomPerimetersManageVM } from '../../pages/layout/real-estate/perimeters/model/custom-perimeters-manage-vm';
import { LayoutTypeContainerVM } from '../../pages/layout/real-estate/layout-types/model/layout-type-container-vm';
import { RefCityAdminVM } from '../../pages/layout/real-estate/referentials/model/ref-city-admin-vm';
import { WorkplacesAdminVM } from '../../pages/layout/real-estate/referentials/model/workplaces-admin-vm';
import { LeasesList } from '../../pages/planning/strategy/referentials/model/leases_list';
import { LeaseItems } from '../../pages/planning/strategy/referentials/model/lease_items';
import { LeaseContractors } from '../../pages/planning/strategy/referentials/model/lease_contractors';
import { LeaseTypes } from '../../pages/planning/strategy/referentials/model/lease_types';
import { LeaseEditorVM } from '../../pages/planning/strategy/leases/model/lease-editor-vm';
import { StrategyEditorVM } from '../../pages/planning/strategy/nested/simulations/editor/main/model/strategy-editor-vm';
import { MobilityContributors } from '../../pages/planning/mobility/referentials/model/mobility-contributors';
import { MobilityFacilityCompanies } from '../../pages/planning/mobility/referentials/model/mobility-facility-companies';
import { MobilityFunctions } from '../../pages/planning/mobility/referentials/model/mobility-functions';
import { MobilityJobTasks } from '../../pages/planning/mobility/referentials/model/mobility-job-tasks';
import { MobilityJobs } from '../../pages/planning/mobility/referentials/model/mobility-jobs';
import { MobilityCompanyTasks } from '../../pages/planning/mobility/referentials/model/mobility-company-tasks';
import { MobilityProjectTaskTypes } from '../../pages/planning/mobility/referentials/model/mobility-project-task-types';
import { AppService } from 'src/app/core/services/backend-services/app-service';
import { ZAppParameterEnum } from 'src/app/core/model/data-model/enums/z-app-parameter-enum';
import { LayersGrantsVM } from '../../pages/administration/layers-grants/model/layers-grants-vm';
import { BusinessUnitsUpload } from '../../pages/layout/organization/model/business-units-upload';
import { ZAppMenuRouteView } from 'src/app/core/model/data-model/views/z-app-menu-route-view';
import { DyntService } from 'src/app/core/services/backend-services/dynt-service';
import { ViewsNames } from 'src/app/core/model/db-model/views-names-enum';
import { MenuItem, MenuItemCommandEvent, MessageService } from 'primeng/api';
import { SitesVM } from '../../pages/layout/real-estate/sites/sites-vm';
import { BuildingsVM } from '../../pages/layout/real-estate/buildings/buildings-vm';
import { FloorsVM } from '../../pages/layout/real-estate/floors/floors-vm';
import { RealEstateMapInteraction } from '../../pages/layout/real-estate/map/real-estate-map-interaction';
import { WelcomeScreenVM } from '../../pages/welcome-screen/welcome-screen-vm';
import { CieUserGrantsViewDTO } from 'src/app/core/services/backend-services/dto/cie-user-grants-view-dto';

export class ModelTreeRoot extends EventListener {
    selectedClient: CieUserGrantsViewDTO | undefined;
    menuPanel: MenuPanelVM | undefined;
    selectedPage: PageModel | undefined;
    selectedRouteName: string | undefined;
    breadcrumbSegments: MenuItem[] = [];
    breadcrumbHome: MenuItem = { icon: 'pi pi-home', command: this.openSideMenu.bind(this) };
    nestedToolbar: any;
    PageEnum = AppMenuRouteEnum;
    routes = AppMenuRouteEnum;
    routesView: ZAppMenuRouteView[] = [];

    constructor(httpClient: HttpClient, messageService: MessageService) {
        super(readableUUID(ModelTreeRoot.name));
        
        Container.set('httpClient', httpClient);
        Container.set('toaster', messageService);

        this.addEventListener(MainEventsEnum.selectedMenuPageChange, async (id: AppMenuRouteEnum) => {
            await this.selectPage(id);
        });

        this.addEventListener(MainEventsEnum.clientPageRequested, async () => {
            this.menuPanel = undefined;
            this.selectedPage = undefined;
            this.selectedClient = undefined;
            localStorage.removeItem("companyId");
            await this.getAccessPage();
        });

        this.addEventListener(ClientPageEventsEnum.selectedClientChanged, async (client: ClientVM) => {
            await this.selectClient(client, true);
        });

        this.addEventListener(BuildingsVM.blueprintRequestedEvent, async (floorId: number) => {
            await this.selectPage(AppMenuRouteEnum.layout_realestate_floors, floorId);
        });

        this.addEventListener(RealEstateMapInteraction.onBuildingMarkerClick, async (buildingId: number) => {
            await this.selectPage(AppMenuRouteEnum.layout_realestate_buildings, buildingId);
        });

        this.addEventListener(MainEventsEnum.nestedToolbarAvailable, (nestedToolbar: any) => {
            this.nestedToolbar = nestedToolbar;
        });
    }

    openSideMenu(e: MenuItemCommandEvent): void {
        this.emitEventAsync(MainEventsEnum.sideMenuOpeningRequested);
    }

    async getAccessPage(): Promise<void> {
        // Charge la liste de clients sur lesquels l'utilisateur est habilité
        const tmp = new ClientsVM();
        await tmp.load();

        switch (tmp.clients.length) {
            case 0:
                // TODO : faire une page par défaut indiquant de contacter l'administrateur
                // pour avoir une habilitation
                break;
            case 1:
                // S'il n'y en a qu'un il est automatiquement sélectionné
                await this.selectClient(tmp.clients[0], false);
                break;
            default:
                // Si le local storage contient un companyId on tente de charger le client correspondant
                const previousClientId = localStorage.getItem("companyId");
                if (previousClientId != null) {
                    // l'idenfiant trouvé doit exister dans la liste des clients précédemment chargée
                    const c = tmp.clients.find(x=>x.companyId === previousClientId);
                    if (c) {
                        await this.selectClient(c, true);
                        return;
                    }
                }

                // S'il y en a plusieurs, on affiche la page des clients
                this.selectedPage = tmp;
                break;
        }
    }

    private async selectClient(client: ClientVM, hasMoreClient: boolean): Promise<void> {
        // Un client a été sélectionné
        this.selectedClient = client;
        // On stocke son identifiant dans le DI injector
        Container.set('companyId', client.companyId);
        Container.set('client', client);

        localStorage.setItem('companyId', client.companyId);
        // On charge les habilitations spécifiques de l'utilisateur dans le cas où il s'agit d'un invité
        const s = Container.get(AppUserService);
        const me = await s.getMe();
        if (me == null) {
            // TODO : enregistrer l'exception
            return;
        }
        // On stocke l'identifiant du user
        Container.set('userId', me.apUsId);
        Container.set('userCode', me.apUsCode);

        // On charge le paramètre de culture
        // TODO : combiner avec le chargement du user en un seul appel à l'API
        const as = Container.get(AppService);
        const p = await as.getParam(ZAppParameterEnum.CurrentCulture);
        let culture = "fr-FR";
        if (p) {
            culture = p.apPaValue;
        }
        Container.set('culture', culture);

        // On charge les routes avec leur traduction dans la locale courante
        const t = Container.get(DyntService);
        this.routesView = await t.downloadTable<ZAppMenuRouteView>(ViewsNames.ZAppMenuRouteView);
        
        // On réinitialise le menu qui sera rechargé à la prochaine ouverture
        this.menuPanel = await MenuPanelVM.newAsync(client, hasMoreClient);

        // On charge la dernière page utilisée
        // Par défaut, on charge la page de l'immobilier
        let lastRoute: string = AppMenuRouteEnum.welcome;
        if (me.apUsLastRoute != null) {
            // la dernière route ne doit pas correspondre à une entrée de menu inactive
            var lastMenuItem = this.menuPanel?.menu.getMenuItem(me.apUsLastRoute);
            if (lastMenuItem && lastMenuItem.enable) {
                lastRoute = me.apUsLastRoute;
            }
        }

        //PageRouter.set(me.apUsId, lastRouteId);

        await this.selectPage(lastRoute);

        if (this.selectedPage) {
            this.menuPanel?.updateSelected(this.selectedPage.route);
        }
    }

    private async selectPage(route: string, args?: any): Promise<void> {
        if (this.selectedPage) {
            // Vide le callback events store de tous les listeners enregistrés
            // sur  la page précédemment affichée
            this.selectedPage.removeListeners();
            this.selectedPage = undefined;

            // Retire la nested toolbar
            this.nestedToolbar = undefined;
        }

        const menuRoute = PageRouter.geAppMenuRoute(route);
        let subRoute = "";
        if (menuRoute) {
            subRoute = route.substring(menuRoute.length + 1);
        }
        
        let routeDef = this.routesView.find(x=> x.zApMeRoName === menuRoute);
        if (!routeDef) {
            // S'il n'y a pas de route c'est le premier accès de l'utilisateur
            // on lui affiche l'écran de bienvenue
            routeDef = this.routesView.find(x=> x.zApMeRoName === AppMenuRouteEnum.welcome);
            
            //routeDef = this.routesView.find(x=> x.zApMeRoName === AppMenuRouteEnum.layout_realestate_sites);
        } else {
            this.breadcrumbSegments = routeDef.zApMeRoDisplayName.split("/").map(x=> ({ label: x}));
        }
        
        switch (menuRoute) {
            case AppMenuRouteEnum.clients:
                break;
            case AppMenuRouteEnum.welcome:
                this.selectedPage = await WelcomeScreenVM.newAsync();
                break;
            case AppMenuRouteEnum.administration_useraccount:
                this.selectedPage = await UserAccountAdminVM.newAsync();
                break;
            case AppMenuRouteEnum.administration_layergrants:
                this.selectedPage = await LayersGrantsVM.newAsync();
                break;
            case AppMenuRouteEnum.administration_roles:
                this.selectedPage = await RolesAdminVM.newAsync();
                break;
            case AppMenuRouteEnum.administration_maildomains:
                this.selectedPage = await GrantedDomainsVM.newAsync();
                break;
            case AppMenuRouteEnum.layout_inventory_catalogs:
                this.selectedPage = await CatalogPage.newAsync();
                break;
            case AppMenuRouteEnum.layout_inventory_purchases:
                this.selectedPage = await PurchasesListVM.newAsync();
                break;
            case AppMenuRouteEnum.layout_inventory_stores:
                this.selectedPage = await StoresManage.newAsync();
                break;
            case AppMenuRouteEnum.layout_inventory_providers:
                this.selectedPage = await ProvidersManage.newAsync();
                break;
            case AppMenuRouteEnum.layout_realestate_map:
                this.selectedPage = await RealEstateMapInteraction.newAsync();
                break;
            case AppMenuRouteEnum.layout_realestate_buildings:
                this.selectedPage = await BuildingsVM.newAsync(args);
                break;
            case AppMenuRouteEnum.layout_realestate_floors:
                this.selectedPage = await FloorsVM.newAsync(args);
                break;
            case AppMenuRouteEnum.layout_realestate_perimeters:
                this.selectedPage = await CustomPerimetersManageVM.newAsync();
                break;
            case AppMenuRouteEnum.layout_realestate_layouttypes:
                this.selectedPage = await LayoutTypeContainerVM.newAsync();
                break;
            case AppMenuRouteEnum.layout_realestate_cities:
                this.selectedPage = await RefCityAdminVM.newAsync();
                break;
            case AppMenuRouteEnum.layout_realestate_addresses:
                this.selectedPage = await AddressesAdminVM.newAsync();
                break;
            case AppMenuRouteEnum.layout_realestate_workplacetypes:
                this.selectedPage = await WorkplacesAdminVM.newAsync();
                break;
            case AppMenuRouteEnum.layout_organization_dashboard:
                this.selectedPage = await BusinessUnitsBrowserVM.newAsync();
                break;
            case AppMenuRouteEnum.layout_organization_upload:
                this.selectedPage = await BusinessUnitsUpload.newAsync();
                break;
            case AppMenuRouteEnum.planning_mobility_projects:
                this.selectedPage = await MobilityBrowserVM.newAsync();
                break;
            case AppMenuRouteEnum.planning_mobility_contributors:
                this.selectedPage = await MobilityContributors.newAsync();
                break;
            case AppMenuRouteEnum.planning_mobility_companies:
                this.selectedPage = await MobilityFacilityCompanies.newAsync();
                break;
            case AppMenuRouteEnum.planning_mobility_functions:
                this.selectedPage = await MobilityFunctions.newAsync();
                break;
            case AppMenuRouteEnum.planning_mobility_jobtasks:
                this.selectedPage = await MobilityJobTasks.newAsync();
                break;
            case AppMenuRouteEnum.planning_mobility_jobs:
                this.selectedPage = await MobilityJobs.newAsync();
                break;
            case AppMenuRouteEnum.planning_mobility_companytasks:
                this.selectedPage = await MobilityCompanyTasks.newAsync();
                break;
            case AppMenuRouteEnum.planning_mobility_projecttasks:
                this.selectedPage = await MobilityProjectTaskTypes.newAsync();
                break;
            case AppMenuRouteEnum.planning_strategy_simulations:
                this.selectedPage = await StrategyEditorVM.newAsync();
                break;
            case AppMenuRouteEnum.planning_strategy_leases:
                this.selectedPage = await LeaseEditorVM.newAsync();
                break;
            case AppMenuRouteEnum.planning_strategy_referentials_leases:
                this.selectedPage = await LeasesList.newAsync();
                break;
            case AppMenuRouteEnum.planning_strategy_referentials_leaseitems:
                this.selectedPage = await LeaseItems.newAsync();
                break;
            case AppMenuRouteEnum.planning_strategy_referentials_durationtypes:
                this.selectedPage = await LeaseDurationTypes.newAsync();
                break;
            case AppMenuRouteEnum.planning_strategy_referentials_contractors:
                this.selectedPage = await LeaseContractors.newAsync();
                break;
            case AppMenuRouteEnum.planning_strategy_referentials_leasetypes:
                this.selectedPage = await LeaseTypes.newAsync();
                break;
            case AppMenuRouteEnum.planning_maintenance:
                this.selectedPage = await MaintenanceBrowserVM.newAsync();
                break;
            case AppMenuRouteEnum.graphicworks_blueprints:
                this.selectedPage = new CadConverterContainerVM();
                break;
            case AppMenuRouteEnum.graphicworks_pictograms:
                const tmp = new DrawingContainerModel();
                await tmp.initialize();
                this.selectedPage = tmp;
                break;
            // case "helpdesk":
            //     const hdModel = new HelpdeskMainVM();
            //     this.selectedPage = hdModel;
            //     break;
            // Si la route n'existe pas on charge par défaut la page des sites
            case AppMenuRouteEnum.layout_realestate_sites:
            default:
                this.selectedPage = await SitesVM.newAsync();
                // this.selectedPage = await RealEstateBrowserVM.newAsync(subRoute);
                break;
        }
    }
}