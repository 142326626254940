import { missingListener } from "../services/logging-service";

export abstract class EventModel {

/**
 * @deprecated The method should not be used
 */
raiseEvent(event: any, arg1?: any, arg2?: any, arg3?: any, arg4?: any): any {
        if (event) {
            if (arg1 !== undefined && arg2 !== undefined && arg3 !== undefined && arg4 !== undefined) {
                return event(arg1, arg2, arg3, arg4);
            }
            if (arg1 !== undefined && arg2 !== undefined && arg3 !== undefined) {
                return event(arg1, arg2, arg3);
            }
            if (arg1 !== undefined && arg2 !== undefined) {
                return event(arg1, arg2);
            }
            if (arg1 !== undefined) {
                return event(arg1);
            }
            return event();
        } else {
            missingListener(`${event} n'était pas écouté`);
        }
    }

/**
 * @deprecated The method should not be used
 */
async raiseEventAsync(event: any, arg1?: any, arg2?: any, arg3?: any, arg4?: any): Promise<any> {
        if (event) {
            if (arg1 !== undefined && arg2 !== undefined && arg3 !== undefined && arg4 !== undefined) {
                return await event(arg1, arg2, arg3, arg4);
            }
            if (arg1 !== undefined && arg2 !== undefined && arg3 !== undefined) {
                return await event(arg1, arg2, arg3);
            }
            if (arg1 !== undefined && arg2 !== undefined) {
                return await event(arg1, arg2);
            }
            if (arg1 !== undefined) {
                return await event(arg1);
            }
            return event();
        } else {
            missingListener(`${event} n'était pas écouté`);
        }
    }

    // async executeAsync(event: any, arg1?: any, arg2?: any, arg3?: any, arg4?: any): Promise<any> {
    //     if (event) {
    //         if (arg1 !== undefined && arg2 !== undefined && arg3 !== undefined && arg4 !== undefined) {
    //             return Promise.resolve(event(arg1, arg2, arg3, arg4));
    //         }
    //         if (arg1 !== undefined && arg2 !== undefined && arg3 !== undefined) {
    //             return Promise.resolve(event(arg1, arg2, arg3));
    //         }
    //         if (arg1 !== undefined && arg2 !== undefined) {
    //             return Promise.resolve(event(arg1, arg2));
    //         }
    //         if (arg1 !== undefined) {
    //             return Promise.resolve(event(arg1));
    //         }
    //         return Promise.resolve(event());
    //     } else {
    //         LoggerService.missingListener(`${event} n'était pas écouté`);
    //     }
    // }
}