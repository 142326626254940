import { PageModel } from "src/app/ui/main/model/page-model";
import { BusinessUnitsTreeModel } from "./business-units-tree-model";
import { AppMenuRouteEnum } from "src/app/core/model/data-model/enums/app-menu-route-enum";
import { BusinessUnitsToolbarVM } from "./business-units-toolbar-vm";
import { MainEventsEnum } from "src/app/ui/main/model/main-events-enum";
import { TreeNode } from "primeng/api";
import { BusinessUnit } from "src/app/core/model/data-model/tables/business-unit";
import { BusinessUnitTreeDataDTO } from "src/app/core/services/backend-services/dto/business-unit-tree-data-dto";
import { TabPageContentTypeEnum } from "../../real-estate/tab-page-content-type-enum";
import { ITabPageContent } from "src/app/components-lib/tab-page-container/model/i-tab-page-content";
import { BusinessUnitsEventsEnum } from "./business-units-events-enum";
import Container from "typedi";
import { BusinessUnitService } from "src/app/core/services/backend-services/business-unit-service";
import { BusinessUnitView } from "src/app/core/model/data-model/views/business-unit-view";
import { ArrayUtils } from "src/app/core/model/static-functions/array-utils";
import { RealEstateChartTypeEnum } from "../../real-estate/charts/shared-model/real-estate-chart-type-enum";
import { DynviewSet } from "src/app/core/model/data-model/sets/dyn-view-set";
import { DynTableVM } from "src/app/components-lib/dyn-grid/model/dyn-table-vm";
import { DirectoryService } from "src/app/core/services/backend-services/directory-service";
import { DirectoryTable } from "src/app/core/model/db-model/tables/directory-table";
import { DynTableVMBuilder } from "src/app/components-lib/dyn-grid/model/dyn-table-vm-builder";
import { DyntService } from "src/app/core/services/backend-services/dynt-service";
import { Directory } from "src/app/core/model/data-model/tables/directory";
import { ReportGridItem } from "src/app/components-lib/report-grid/model/report-grid-item";
import { ReportGridNumberItem } from "src/app/components-lib/report-grid/model/report-grid-number-item";
import { TablesNamesEnum } from "src/app/core/model/db-model/tables-names-enum";
import { BusinessUnitTable } from "src/app/core/model/db-model/tables/business-unit-table";
import { DbModelUtils } from "src/app/core/model/static-functions/db-model-utils";
import { ApiResponseCodeEnum } from "src/app/core/services/api-response-code-enum";
import { DonutChartWrapperVM } from "src/app/ui/shared/charts/donut/model/donut-chart-wrapper-vm";
import { ReferentialReportWrapper } from "src/app/ui/shared/charts/referential/referential-report-wrapper";
import { TableReportWrapper } from "src/app/ui/shared/charts/table/table-report-wrapper";
import { BusinessUnitChartDataBuilder } from "./business-unit-chart-data-builder";
import { Paginator } from "src/app/components-lib/dyn-grid/model/paginator";

export class BusinessUnitsBrowserVM extends PageModel  {
    businessUnitsTreeModel: BusinessUnitsTreeModel;

    selectedTreeNode: TreeNode<BusinessUnit> | undefined;
    treeDataDTO: BusinessUnitTreeDataDTO | undefined;
    name: string = "NA";
    subEntitiesCount: number = 0;
    selectedBuArea: number = 0;
    selectedBuWorkplacesCount: number = 0;
    selectedBuPeopleCount: number = 0;
    tabPageContentTypeEnum = TabPageContentTypeEnum;

    selectedOption: {id: string, label: string, chartType: string} | undefined;
    content: ITabPageContent | undefined;

    toolbar: BusinessUnitsToolbarVM | undefined;

    private constructor() {
        super(AppMenuRouteEnum.layout_organization_dashboard, 0, "37157765-9885-4e20-8d1e-e8035fae5b0c");

        this.businessUnitsTreeModel = new BusinessUnitsTreeModel();
        
        this.addEventListener(BusinessUnitsEventsEnum.selectedOptionChange, async (option: {id: string, label: string, chartType: string}) => {
            this.selectedOption = option;
            this.content = await this.loadContent();
        });

        this.addEventListener(BusinessUnitsTreeModel.seletedNodeChange, async (node: TreeNode<BusinessUnit>) => {
            this.selectedTreeNode = node;
            this.content = await this.loadContent();
        });
    }

    static async newAsync(): Promise<BusinessUnitsBrowserVM> {
        const result = new BusinessUnitsBrowserVM();
        result.toolbar = await BusinessUnitsToolbarVM.newAsync();
        await result.initialize();
        result.emitEventAsync(MainEventsEnum.nestedToolbarAvailable, result.toolbar);
        return result;
    }

    async initialize(): Promise<void> {
        await this.businessUnitsTreeModel.loadTree();
        await this.loadRefData();
        await this.businessUnitsTreeModel.selectRoot();
    }

    async loadRefData(): Promise<void> {
        const s = Container.get(BusinessUnitService);
        const data = await s.loadBusTreeDatas();
        if (!data) return;
        this.treeDataDTO = data;

        // Consolide les surfaces et les effectifs des feuilles vers les branches
        const maxDepth = this.treeDataDTO.businessUnitView.reduce((prev, current) => (prev.buUnDepth > current.buUnDepth) ? prev : current).buUnDepth;
        const leaves = this.treeDataDTO.businessUnitView.filter(x=> x.buUnDepth === maxDepth);
        this.feedParentsAreaAndWorkforce(leaves);
    }

    feedParentsAreaAndWorkforce(nodes: BusinessUnitView[]): void {
        if (!this.treeDataDTO) return;

        const distinctParentIds = ArrayUtils.DistinctValueBy<BusinessUnitView, number>(nodes, "buUnParentId");
        distinctParentIds.forEach(id => {
            if (id != null) {
                const parentLeaves = nodes.filter(x=> x.buUnParentId === id);
                const parentLeavesArea = ArrayUtils.sumBy(parentLeaves, "roAlArea");
                const parentLeavesWorkforce = ArrayUtils.sumBy(parentLeaves, "buUnViOccupancy");
                const parent = this.treeDataDTO?.businessUnitView.find(x=> x.buUnId === id);
                if (parent) {
                    parent.roAlArea = parentLeavesArea;
                    parent.buUnViOccupancy = parentLeavesWorkforce;
                }
            }
        });

        if (distinctParentIds.length > 0) {
            const parents = this.treeDataDTO.businessUnitView.filter(x=> distinctParentIds.includes(x.buUnId));
            this.feedParentsAreaAndWorkforce(parents);
        }
    }

    async loadContent(): Promise<ITabPageContent | undefined> {
        const node = this.selectedTreeNode;
        if (!node || !node.data || !this.treeDataDTO) return;
        if (!this.selectedOption) return;

        this.content = undefined;

        switch (this.selectedOption.id) {
            case RealEstateChartTypeEnum.workforce:
                // TODO : améliorer cette section
                let paginator: Paginator | null;
                let datas: DynviewSet;
                let table: DynTableVM;
                const id = node.data.buUnId === 0 ? null : node.data.buUnId;
                const d = Container.get(DirectoryService);
                paginator = await d.getBuWorkforcePaginator(id);
                datas = await d.getBuWorkforce(id, 0, paginator?.pageItemsCount);
                table = DynTableVMBuilder.getVM(datas, DirectoryTable.databaseTableName, paginator);
                table.newPageDataRequested = async (pageIndex: number) => {
                    const px = await d.getBuWorkforce(id, pageIndex, paginator?.pageItemsCount);
                    table.setDataSource(px.viewData);
                }
                table.csvExtractRequested = async () => {
                    // TODO : auditer les opérations d'extraction de données
                    if (node.data!.buUnId === 0) {
                        // Pour l'extraction, on charge la totalité des données
                        const t = Container.get(DyntService);
                        const p = await t.downloadTable<Directory>(table.sourceViewName);
                        const data = DynTableVMBuilder.getRows(p, table.columns, table.primaryColumnName, table.definitions, table.constraints);
                        DynTableVMBuilder.exportToCsv(table.columns, data, table.sourceViewName);
                    } else {
                        const d = Container.get(DirectoryService);
                        const dp = await d.getBuWorkforce(node.data!.buUnId);
                        const data = DynTableVMBuilder.getRows(dp.viewData, table.columns, table.primaryColumnName, table.definitions, table.constraints);
                        DynTableVMBuilder.exportToCsv(table.columns, data, table.sourceViewName);
                    }
                }
                table.pdfExtractRequested = async () => {
                    alert("Bientôt disponible !");
                }
                const tmp = new TableReportWrapper();
                tmp.set(table);
                return tmp;
            case RealEstateChartTypeEnum.referential:
                this.name = node.data.buUnName;
                this.subEntitiesCount = node.children? node.children.length : 0;
                this.selectedBuWorkplacesCount = this.treeDataDTO.workplaceView.length;
                const bu = this.treeDataDTO.businessUnitView.find(x=> x.buUnId === node.data!.buUnId);
                if (bu) {
                    this.selectedBuArea = bu.roAlArea;
                    this.selectedBuPeopleCount = bu.buUnViOccupancy;
                } else {
                    const maxDepth = this.treeDataDTO.businessUnitView.reduce((prev, current) => (prev.buUnDepth > current.buUnDepth) ? prev : current).buUnDepth;
                    const lastBus = this.treeDataDTO.businessUnitView.filter(x=> x.buUnDepth === maxDepth);
                    this.selectedBuArea = ArrayUtils.sumBy(lastBus, "roAlArea");
                    this.selectedBuPeopleCount = ArrayUtils.sumBy(lastBus, "buUnViOccupancy");
                }

                const rrw = await ReferentialReportWrapper.newAsync(this.selectedOption.id, "", TablesNamesEnum.BusinessUnit, node.data.buUnId);
                const reportItems: ReportGridItem<any>[] = [];
                reportItems.push(new ReportGridNumberItem("Sous-entités", this.subEntitiesCount));
                reportItems.push(new ReportGridNumberItem("Collaborateurs", this.selectedBuPeopleCount));
                reportItems.push(new ReportGridNumberItem("Surface occupée", this.selectedBuArea, "1.0-2", "m²"));
                reportItems.push(new ReportGridNumberItem("Positions de travail", this.selectedBuWorkplacesCount));
                rrw.setReportGrid(reportItems);
                rrw.saveRequested = async (tableName: string, primaryColumnName: string, rowId: any, columnName: string, value: any): Promise<string| null> => {
                    return await this.saveMethod(tableName, primaryColumnName, rowId, columnName, value);
                }
                rrw.insertRequested = async (values: {}): Promise<any> => {
                    return await this.insetMethod(values);
                }
                rrw.deleteRequested = async (tableName: string, rowId: any): Promise<boolean> => {
                    return await this.deleteMethod(tableName, rowId);
                }

                return rrw;
            default:
                return await this.loadDonutChart(this.selectedOption.id, node);
        }

    }

    async saveMethod(tableName: string, primaryColumnName: string, rowId: any, columnName: string, value: any): Promise<string | null> {
        const s = Container.get(DyntService);
        const dto: any = {
            "TableName": tableName,
            [primaryColumnName]: rowId,
            [columnName]: value
        }
        const result = await s.patch(tableName, rowId, dto);
        if (result != null) {
            await this.emitEventAsync(BusinessUnitsEventsEnum.treeUpdateRequested, rowId, DbModelUtils.key(BusinessUnitTable, columnName), value);
        }
        return result;
    }

    async insetMethod(values : {}): Promise<any> {
        if (!this.selectedTreeNode || !this.selectedTreeNode.data) return;
        const realId = this.selectedTreeNode.data.buUnId === 0 ? null : this.selectedTreeNode.data.buUnId;

        // L'ajout d'une entité qui n'est pas de dernier niveau entraîne
        // l'ajout d'entités filles récursivement jusqu'au dernier niveau
        // Ce traitement est géré par le backend

        // On ajoute le parentId et le depth au résultat de la saisie
        values = { ...values, [BusinessUnitTable.buUnParentId]: realId }
        values = { ...values, [BusinessUnitTable.buUnDepth]: this.selectedTreeNode.data.buUnDepth + 1 }
        const s = Container.get(BusinessUnitService);
        const result = await s.createItem(values);
        if (result != null) {
            // Demande le rechargement du treeview
            await this.emitEventAsync(BusinessUnitsEventsEnum.treeRefreshRequested);
            //this.raiseEvent(this.treeRefreshRequested);
            // Actualise la grille de saisie avec le résultat
            // Le backend retourne toutes les entités créées
            // la première étant l'entité qui a été saisie par l'utilisateur
            // les autres étant les éventuelles entités filles en cascade
            return result[0].convertBack(); // la DynGrid a besoin des données brutes retournées par le backend
        }
        return null;
    }

    async deleteMethod(tableName: string, rowId: any): Promise<boolean> {
        const s = Container.get(DyntService);
        const result = await s.delete(tableName, rowId);
        if (result != null && result.statusCode === ApiResponseCodeEnum.success) {
            // Demande le rechargement du treeview
            await (BusinessUnitsEventsEnum.treeRefreshRequested);
            //this.raiseEvent(this.treeRefreshRequested);
            // Retire la ligne de la grille de saisie
            return true;
        }
        return false;
    }

    async loadDonutChart(id: string, node: TreeNode<BusinessUnit>): Promise<ITabPageContent | undefined> {
        if (!this.treeDataDTO || !node.data) return;

        const chart = new DonutChartWrapperVM();

        switch (id) {
            case RealEstateChartTypeEnum.anatomy:
                chart.set(BusinessUnitChartDataBuilder.loadAnatomyChartData(this.treeDataDTO, node.data.buUnId, node.data.buUnName, node.data.buUnDepth));
                break;
            case RealEstateChartTypeEnum.businessunits:
                chart.set(BusinessUnitChartDataBuilder.loadStructureChartData(this.treeDataDTO, node.data, node.children? node.children.map(x=> x.data!) : []));
                break;
            case RealEstateChartTypeEnum.buildings:
                chart.set(BusinessUnitChartDataBuilder.loadMapChartData(this.treeDataDTO, node.data));
                break;
            case RealEstateChartTypeEnum.occupation:
                chart.set(BusinessUnitChartDataBuilder.loadWorkplacesChartData(this.treeDataDTO, node.data));
                break;
            default:
                break;
        }

        return Promise.resolve(chart);
    }
}