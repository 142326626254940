import { SvgTransform } from "src/app/core/model/svg-model/svg-transform.model";

export class HbarBarItem {
    id: number;
    label: string;
    value: number;
    step: number;
    color: string;
    level: number;
    transform: SvgTransform | undefined;
    scaleX: number = 0;
    transitionDuration: number = 0.3;
    children: HbarBarItem[] = [];

    constructor(id: number, label: string, level: number, value: number, color: string, step: number) {
        this.id = id;
        this.label = label;
        this.value = value;
        this.color = color;
        this.level = level;
        this.step = step;
        this.transitionDuration = (Math.random() * (0.6 - 0.3 + 0.1)) + 0.3;
    }

}