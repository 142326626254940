import { TablesNamesEnum } from './../tables-names-enum';
export const SimulationTable = {
    databaseTableName: TablesNamesEnum.Simulation,
    siId: "Si_Id",
    siRoot: "Si_Root",
    siParent: "Si_Parent",
    siProjectTaskId: "Si_ProjectTaskId",
    siStartDate: "Si_StartDate",
    siEndDate: "Si_EndDate",
    siName: "Si_Name",
    siDescription: "Si_Description",
    siIsActive: "Si_IsActive",
    siDepth: "Si_Depth"
}