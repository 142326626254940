import { MessageService } from "primeng/api";
import Container from "typedi";
import { MessageSeverityEnum } from "./message-severity-enum";

export function toastInfo(message: string, title: string = "Info") {
    toast(message, title, MessageSeverityEnum.info);
}

export function toastSuccess(message: string, title: string = "Info") {
    toast(message, title, MessageSeverityEnum.success);
}

export function toastSecondary(message: string, title: string = "Info") {
    toast(message, title, MessageSeverityEnum.secondary);
}

export function toastWarn(message: string, title: string = "Attention !") {
    toast(message, title, MessageSeverityEnum.warn);
}

export function toastError(message: string, title: string = "Erreur !") {
    toast(message, title, MessageSeverityEnum.error);
}

export function toastContrast(message: string, title: string = "Info") {
    toast(message, title, MessageSeverityEnum.contrast);
}

function toast(message: string, title: string, severity: MessageSeverityEnum) {
    const t = Container.get<MessageService>('toaster');
    if (t) {
        t.add({ severity: severity, summary: title, detail: message });
    }
}