import { SvgEntity } from "src/app/core/model/svg-model/svg-entity.model";
import { SelectableSvgEntity } from "./selectable-svg-entity";
import { Point } from "src/app/core/model/geometry-model/point.model";
import { SvgEntityPoint } from "../../shared/gizmos/model/svg-entity-point";
import { SvgLineService } from "src/app/core/model/svg-model/svg-line-service";
import { SvgLine } from "src/app/core/model/svg-model/svg-line.model";
import { SvgEntityPointStyleEnum } from "../../shared/gizmos/model/svg-entity-point-style-enum";
import { Vector } from "src/app/core/model/geometry-model/vector.model";
import { SvgEntityTypesEnum } from "src/app/core/model/svg-model/svg-entity-type-enum";
import { SvgEntityParser } from "../../shared/gizmos/model/svg-entity-parser";
import { createRandomString } from "./selectable-entity-builder";
import { XcMaths } from "src/app/core/model/static-functions/xc-maths";
import { SvgGeometryService } from "./svg-geometry-service";

export class SelectableSvgLine extends SelectableSvgEntity {
    x1: number;
    y1: number;
    x2: number;
    y2: number;

    constructor(dtoData: any) {
        super(dtoData);
        this.x1 = SvgEntity.getNumber(dtoData, "x1");
        this.y1 = SvgEntity.getNumber(dtoData, "y1");
        this.x2 = SvgEntity.getNumber(dtoData, "x2");
        this.y2 = SvgEntity.getNumber(dtoData, "y2");
        this._startPoint = new Point(this.x1, this.y1);
        this._endPoint = new Point(this.x2, this.y2);
    }

    override getSelectablePoints(): SvgEntityPoint[] {
        const s = SvgLineService.geometry(this as SvgLine);
        //const a = XcMaths.toDeg(s.angle());
        const points = s.points();
        const result = SvgEntityPoint.toStyle(points, SvgEntityPointStyleEnum.end, this.entityId);
        result.push(SvgGeometryService.getOrientedEntityPoint(this, s.midPoint(), SvgEntityPointStyleEnum.middle));
        return result;
    }

    override getStatement(): string {
        const attributesStatement = SvgEntityParser.getAttributesStatement([["x1", this.x1], ["y1", this.y1], ["x2", this.x2], ["y2", this.y2], ["stroke-width", this.strokeWidth], ["stroke", this.stroke]]);
        return SvgEntityParser.getTagStatement("line", attributesStatement);
    }

    translate(vector: Vector): void {
        this.startPoint = this._startPoint.translate(vector);
        this.endPoint = this._endPoint.translate(vector);
    }

    private _startPoint: Point;
    get startPoint(): Point {
        return this._startPoint;
    }
    set startPoint(value: Point) {
        this.x1 = value.x;
        this.y1 = value.y;
        this._startPoint = value;
    }

    private _endPoint: Point;
    get endPoint(): Point {
        return this._endPoint;
    }
    set endPoint(value: Point) {
        this.x2 = value.x;
        this.y2 = value.y;
        this._endPoint = value;
    }

    isEndPoint(p: Point): boolean {
        return this.startPoint.equals(p) || this.endPoint.equals(p);
    }

    static fromValues(startPoint: Point, endPoint: Point, strokeWidth: number, stroke: string = "black"): SelectableSvgLine {
        return new SelectableSvgLine({entityType: SvgEntityTypesEnum.line, entityId: createRandomString(8), x1: startPoint.x, y1: startPoint.y, x2: endPoint.x, y2: endPoint.y, strokeWidth: strokeWidth, stroke: stroke});
    }
}