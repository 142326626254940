import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EquipmentUpdateGizmoVM } from '../../model/equipment-update-gizmo-vm';
import { EquimentSelectionOverlayComponent } from '../equiment-selection-overlay/equiment-selection-overlay.component';
import { EquimentSelectionRotationLabelComponent } from '../equiment-selection-rotation-label/equiment-selection-rotation-label.component';

@Component({
  selector: 'g[xc-equipment-update-gizmo-overlay]',
  standalone: true,
  imports: [CommonModule, EquimentSelectionOverlayComponent, EquimentSelectionRotationLabelComponent],
  templateUrl: './equipment-update-gizmo-overlay.component.svg',
  styleUrls: ['./equipment-update-gizmo-overlay.component.scss']
})
export class EquipmentUpdateGizmoOverlayComponent {
  @Input() model: EquipmentUpdateGizmoVM | undefined;
}
