import { AfterViewInit, Component, Input } from '@angular/core';
import { BpSvgLabel } from '../../model/bp-svg-label';
import { CommonModule } from '@angular/common';
import { SvgDOM } from '../../../blueprint-viewer-content-panel/itself/model/interaction/svg-dom';
import { BpSvgCountBadgeComponent } from '../bp-svg-count-badge/bp-svg-count-badge.component';
import { BlueprintSvgEntityTypeEnum } from '../../model/blueprint-svg-entity-type-enum';
import { BpSvgLeaderLineComponent } from '../bp-svg-leader-line/bp-svg-leader-line.component';
import { BpSvgMeasureLinesComponent } from '../bp-svg-measure-lines/bp-svg-measure-lines.component';

@Component({
  selector: 'g[xc-bp-svg-label]',
  standalone: true,
  imports: [CommonModule, BpSvgCountBadgeComponent, BpSvgLeaderLineComponent, BpSvgMeasureLinesComponent],
  templateUrl: './bp-svg-label.component.svg',
  styleUrl: './bp-svg-label.component.scss'
})
export class BpSvgLabelComponent implements AfterViewInit {
  @Input() model: BpSvgLabel | undefined;
  blueprintSvgEntityTypeEnum = BlueprintSvgEntityTypeEnum;

  ngAfterViewInit(): void {
    // Actualisation de l'arrière plan de l'étiquette
    setTimeout(() => {
      if (this.model) {
        this.model.setBackgroundSize(SvgDOM.getLabelTextSize(this.model.floorDataId));
      }
    }, 0);
  }
}
