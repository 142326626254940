@if (model) {
    <p-accordion>
        <p-accordionTab [disabled]="!model.selectedTask" 
            [header]="!model.selectedTask ? 'Sélectionnez une tâche' : model.selectedTask.taTypeId === 1 ? model.selectedTask.taName : model.selectedTask.businessTypeView.taBuTyDisplayName != null ? model.selectedTask.businessTypeView.taBuTyDisplayName + ' - ' + model.selectedTask.taName : model.selectedTask.taName">
            <xc-task-properties [model]="model.taskProperties"/>
        </p-accordionTab>
        <p-accordionTab header="Contributeurs" [disabled]="!model.selectedTask || model.selectedTask.taTypeId === 1" class="p-1">
            <xc-task-contributors [model]="model.taskContributors"/>
        </p-accordionTab>
        <p-accordionTab header="Ajout d'un nouvel élément">
            <xc-task-creation-stepper [model]="model.creationStepper"/>
        </p-accordionTab>
    </p-accordion>
}
