import { SvgTransform } from "src/app/core/model/svg-model/svg-transform.model";

export namespace SvgEntityParser {
    export function getTagStatement(tagName: string, attributesStatement: string, value: string | undefined = undefined): string {
        let end = "/>";
        if (value) {
            end = `>${value}</${tagName}>`;
        }
        return `<${tagName} ${attributesStatement}${end}`;
    }
    
    export function getAttributesStatement(values: [string, any][], transform: SvgTransform | undefined = undefined): string {
        let result: string = "";
        values.forEach(v => {
            result += getAttributeStatement(v[0], v[1]) + " ";
        });
        if (transform) {
            result += getTransformAttributeStatement(transform);
        }
        return result.trimEnd();
    }
    
    export function getAttributeStatement(name: string, value: any): string {
        if (value === undefined) {
            return "";
        }
        return `${name}="${value}"`;
    }
    
    export function getTransformAttributeStatement(value: SvgTransform | undefined): string {
        if (value === undefined) {
            return "";
        }
        return getAttributeStatement("transform", value.transformString);
    }
    }