import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import Container from 'typedi';
import { BuildingTable } from 'src/app/core/model/db-model/tables/building-table';
import { SelectableRealEstateTreeNode } from './selectable-real-estate-tree-node';
import { SiteTable } from 'src/app/core/model/db-model/tables/site-table';
import { BuildingsAndBusBuildingsDTO } from 'src/app/core/services/backend-services/dto/buildings-and-bus-buildings-dto';
import { SimulationService } from 'src/app/core/services/backend-services/simulation-service';
import { logError } from 'src/app/core/services/logging-service';


export class SelectableRealEstateTreeVM {
    treeControl = new NestedTreeControl<SelectableRealEstateTreeNode>(node => node.children);
    dataSource = new MatTreeNestedDataSource<SelectableRealEstateTreeNode>();
    
    readonly: boolean = false;
    selectedNode: SelectableRealEstateTreeNode | undefined;

    reData: BuildingsAndBusBuildingsDTO | undefined;

    constructor(readonly: boolean = false) {
        this.readonly = readonly
    }

    async loadTree(busIds: number[]): Promise<void> {
        const s = Container.get(SimulationService);
        const data = await s.getBuildingsAndBusBuildings(busIds);
        if (data != null) {
            this.reData = data;
            this.dataSource.data = this.getNodeChildren(this.reData.realEstateView, undefined);
            this.listenToSelectionChange();

            // Si le jeu de données comprend une préselection par les entités, il faut préselectionner les immeubles correspondants
            if (this.reData.buildingAllocations.length > 0) {
                this.selectBuildings(this.reData.buildingAllocations.map(x=> x.flBuildingId));
            }
        }
    }

    selectBuildings(ids: number[]): void {
        const buildingNodes = this.getBuildingNodes(this.dataSource.data);
        buildingNodes.forEach(n => {
            if (ids.includes(n.id)) {
                n.setSelected(true);
            }
        });
    }

    getNodeChildren(data: any[], parentNode: SelectableRealEstateTreeNode | undefined): SelectableRealEstateTreeNode[] {
        let result: SelectableRealEstateTreeNode[] = [];
        data.forEach(s => {
            const n = new SelectableRealEstateTreeNode(s.realEstateTableName, s.id, s.parentId, s.name, s.latitude, s.longitude);
            n.parentNode = parentNode;
            result.push(n);

            if (s.children.length > 0 && s.realEstateTableName !== BuildingTable.databaseTableName) {
                n.setChildren(this.getNodeChildren(s.children, n));
            }
        });

        return result;
    }

    selectedBuildingsChanged?: (nodes: SelectableRealEstateTreeNode[]) => void;
    listenToSelectionChange(): void {
        this.dataSource.data.forEach(n => {
            n.selectedChange = () => {
                if (this.selectedBuildingsChanged) {
                    this.selectedBuildingsChanged(this.getSelectedBuildings());
                } else {
                    logError("SelectableRealEstateTreeVM.selectionChanged n'est pas écouté");
                }
            }
        });
    }

    getBuildingNodes(nodes: SelectableRealEstateTreeNode[]): SelectableRealEstateTreeNode[] {
        let result: SelectableRealEstateTreeNode[] = [];

        nodes.forEach(n => {
            switch (n.databaseTableName) {
                case SiteTable.databaseTableName:
                    result = result.concat(this.getBuildingNodes(n.children));
                    break;
                case BuildingTable.databaseTableName:
                    result.push(n);
                    break;
                default:
                    break;
            }
        });

        return result;
    }

    getSelectedBuildings(): SelectableRealEstateTreeNode[] {
        return this.getBuildingNodes(this.dataSource.data).filter(x=> x.selected);
    }

    getNode(data: SelectableRealEstateTreeNode[], id: number, tableName: string): SelectableRealEstateTreeNode | null {
        for (const e of data) {
            if (e.databaseTableName === tableName && e.id === id) {
                return e;
            }
            if (e.children != null) {
                const result = this.getNode(e.children, id, tableName);
                if (result != null) {
                    return result;
                }
            }
        }

        return null;
    }

    childrenPartiallySelected(): boolean {
        let result = false;

        this.dataSource.data.forEach(l => {
            if (l.childrenPartiallySelected) {
                result = true;
            }
        });
        
        return result;
    }

    hasChild = (_: number, node: SelectableRealEstateTreeNode) => !!node.children && node.children.length > 0;
}
