import { Point } from "src/app/core/model/geometry-model/point.model";
import { SvgEntityTypesEnum } from "src/app/core/model/svg-model/svg-entity-type-enum";
import { SvgEntity } from "src/app/core/model/svg-model/svg-entity.model";
import { BpSvgEntity } from "./bp-svg-entity";
import { BpSvgTspan } from "./bp-svg-tspan";

export class BpSvgText extends BpSvgEntity {
    x: number | undefined;
    y: number | undefined;
    fontSize: number = 1;
    originalFontSize: number = 1;
    textAnchor: string | undefined = "start";
    text: string | undefined;
    tSpans: BpSvgTspan[] = [];

    constructor(dtoData: any) {
        super(dtoData);
        this.x = SvgEntity.getNumber(dtoData, "x");
        this.y = SvgEntity.getNumber(dtoData, "y");
        this.textAnchor = SvgEntity.getValue(dtoData, "textAnchor", "start", false);
        this.fontSize = SvgEntity.getNumber(dtoData, "fontSize", 1);
        this.originalFontSize = this.fontSize;
        this.text = SvgEntity.getValue(dtoData, "text", undefined, false);
        if (dtoData.tSpans) {
            dtoData.tSpans.forEach((ts: any) => {
                this.tSpans.push(new BpSvgTspan(ts));
            });
        }
    }

    position(): Point {
        return new Point(this.x, this.y);
    }

    moveTo(newPosition: Point): void {
        this.x = newPosition.x;
        this.y = newPosition.y;
    }

    mainText(): string {
        if (this.text && this.text !== '') return this.text;
        if (this.tSpans.length > 0)return this.tSpans[0].text;
        return "";
    }

    static fromValues(x: number, y: number, text: string, fontSize: number): BpSvgText {
        return new BpSvgText({x: x, y: y, text: text, fontSize: fontSize, entityType: SvgEntityTypesEnum.text});
    }
}
