import { Container } from 'typedi';
import { TaskService } from 'src/app/core/services/backend-services/task-service';
import { TaskStatusEnum } from "src/app/core/model/data-model/enums/task-status-enum";
import { Task } from "src/app/core/model/data-model/tables/task";
import { TaskStatus } from "src/app/core/model/data-model/tables/task-status";
import { TaskValidation } from 'src/app/core/model/data-model/tables/task-validation';
import { TaskWorkflowCommand } from './task-workflow-command';
import { TasksValidationsRefAreCheck } from './task-validation-ref-area-check';
import { TaskFunctionEnum } from 'src/app/core/model/data-model/enums/task-function-enum';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { TaskContributorTabView } from './task-contributor-tab-view';
import { TaskChangeLogView } from 'src/app/core/model/data-model/views/task-change-log-view';
import { FloorTask } from '../../../../shared-model/floor-task';
import { TaskWorkflowProcessing } from 'src/app/core/model/data-processing/task-workflow-processing';
import { FloorBlueprintEventsEnum } from '../../../../../container/model/floor-blueprint-events-enum';
import { isAnyHowEnded } from 'src/app/core/model/data-processing/task-processing';
import { EventListener } from 'src/app/core/events/event-listener';
import { readableUUID } from 'src/app/core/events/event-listener-uuid';
import { ClientVM } from 'src/app/ui/main/model/client-vm';

export class FloorTasksTabModel extends EventListener {
    static floorTasksLoadedEvent = "floorTasksLoadedEvent";

    floorId: number = 0;

    //floorTasks: FloorTask[] = [];
    clientHasMobilitySubscriptionPlan: boolean = false;

    selectedTask: FloorTask | undefined;
    deadline: Date | undefined;
    validations: TaskValidation[] = [];
    contributors: TaskContributorTabView[] = [];
    changeLogs: TaskChangeLogView[] = [];
    reconcileTasks: Task[] = [];
    taskStatus: TaskStatus[] = [];
    workflowCommands: TaskWorkflowCommand[] = [];
    validationComment: string | null = null; // doit être initialisé à null pour être accepté par le backend lorsque aucun commentaire n'est saisi
    refAreaIsIntentionalyUpdated: boolean = false;
    validationCheck: TasksValidationsRefAreCheck | null = null;
    variantCreateToggleIsChecked: boolean = false;
    variantTaskName: string = "";
    taskVariants: Task[] = [];

    TaskStatusEnum = TaskStatusEnum;

    service: TaskService;

    constructor() {
        super(readableUUID(FloorTasksTabModel.name));
        this.service = Container.get(TaskService);
        //this.initializeContent(floorId);
        const client = Container.get("client");
        this.clientHasMobilitySubscriptionPlan = (client as ClientVM).hasMobility();

        this.addEventListener(FloorBlueprintEventsEnum.selectedTaskChanged, async (task: FloorTask | undefined) => {
            this.selectedTask = task;
            if (task) {
                await this.loadTaskDatas(task.taskId);
            }
        });
    }

    // async initializeContent(floorId: number): Promise<void> {
    //     this.floorId = floorId;
    //     //this.floorTasks = await this.service.loadFloorActiveTasks(floorId);
    //     this.clear();
    // }

    clear(): void {
        this.selectedTask = undefined;
        this.taskStatus.splice(0);
        this.validations.splice(0);
        this.contributors.splice(0);
        this.reconcileTasks.splice(0);
        this.changeLogs.splice(0);
        this.taskVariants.splice(0);
        this.variantCreateToggleIsChecked = false;
    }

    async loadTaskDatas(taskId: number): Promise<void> {
        const taskDataset = await this.service.getTaskDataset(taskId);
        if (taskDataset) {
            this.taskStatus = taskDataset.taskStatusView;
            this.validations = taskDataset.taskValidations;
            this.contributors = taskDataset.taskContributorView;
            this.reconcileTasks = taskDataset.tasks;
            this.changeLogs = taskDataset.taskChangeLogView;
        }
        await this.loadTaskVariants(taskId);

        // Mappe les validations dans les contributeurs
        this.contributors.forEach(c => {
            const validation = this.validations.find(x=> x.taVaUserId === c.taCrContributorId);
            c.validation = validation;
        });

        // Override la fonction de validation si nécessaire sur les space planners
        const validatorExist = this.contributors.find(x=> x.hasValidationFunction);
        if(!validatorExist) {
            const spacePlanners = this.contributors.filter(x=> x.taCrFunctionId === TaskFunctionEnum.SpacePlanning);
            spacePlanners.forEach(sp => {
                sp.hasDefaultValidationFunction = true;
            });
        }

        // Constitue le tableau des commandes de workflow
        this.updateWorkflowCommands();

        // Constitue le tableau des commandes de validation (si nécessaire)
        await this.updateValidationCommands();
    }

    async loadTaskVariants(taskId: number): Promise<void> {
        this.taskVariants = await this.service.getTaskVariants(taskId);
    }

    // Nota
    // Une tâche de space planning n'est jamais passée manuellement à 'Terminé'
    // mais seulement à l'occasion de la validation qui provoque le récolement
    // il n'est donc pas nécessaire de prévoir la possibilité de changer le statut lorsqu'elle est terminée

    updateWorkflowCommands(): void {
        if (!this.selectedTask) return;

        this.workflowCommands.splice(0);
        const updatableStatus = TaskWorkflowProcessing.nextAvailableStatus(this.taskStatus, this.selectedTask.taskStatus, false);
        updatableStatus.forEach(ts => {
            this.workflowCommands.push(new TaskWorkflowCommand(ts))
        });
    }

    async updateValidationCommands(): Promise<void> {
        if (!this.selectedTask) return;
        // NOTA : Il n'y a pas de commande de workflow si l'étude est "A valider"
        this.validationCheck = await this.service.refAreaCheck(this.selectedTask.taskId);
    }

    hasOtherValidator(): boolean {
        const userCode = Container.get("userCode");
        return this.contributors.find(x=> x.taCrFunctionId === TaskFunctionEnum.Validation && x.diCode !== userCode) !== undefined;
    }

    userCanValidate(): boolean {
        if (this.validationCheck == null) return false;
        // IMPORTANT : isUniqueOrLastValidation est true s'il y a un valideur qui n'est pas le user actuel
        // dans ce cas il ne faut évidemment pas permettre la validation
        if (this.hasOtherValidator() && this.validationCheck.isUniqueOrLastValidation) return false;
        if (!this.validationCheck.isUniqueOrLastValidation || (this.validationCheck.isRefAreaWillBeUpdated && !this.validationCheck.hasRefAreaUpdateGrant)) return false;
        return true;
    }

    async onWorkflowCommandClick(c: TaskWorkflowCommand): Promise<void> {
        if (!this.selectedTask) return;

        const result = await this.service.updateTaskStatus(this.selectedTask.taskId, c.taskStatus.taStId);
        if (result != null) {
            if (isAnyHowEnded(c.taskStatus.taStId)) {
                // Recharge les études disponibles
                //await this.initializeContent(this.floorId);
                // Déclenche le retrait de l'étude sur le plan et le rechargement de la liste des études dans la toolbar
                await this.emitEventAsync(FloorBlueprintEventsEnum.taskAnyHowEnded, null);
                this.clear();
                this.selectedTask = undefined;
                return;
            }
            // Recharge les datas
            this.selectedTask.taskStatus = c.taskStatus.taStId;
            this.selectedTask.taskStatusColor = c.taskStatus.taStColor;
            this.selectedTask.taskStatusName = c.taskStatus.taStName;

            this.updateWorkflowCommands();
            
            this.changeLogs = await this.service.downloadTaskChangeLog(this.selectedTask.taskId);
        }
    }

    async onValidateButtonClick(): Promise<void> {
        if (!this.selectedTask) return;

        // La proposition d'aménagement est approuvée
        // S'il s'agit de la dernière validation, le récolement va avoir lieu
        const result = await this.service.validateTask(this.selectedTask.taskId, true, this.validationComment, this.refAreaIsIntentionalyUpdated === true ? true : false)
        if (result != null) {
            if (result.isLastValidation) {
                // L'étude va être retirée du plan et la liste des études rechargée
                this.selectedTask = undefined;
                await this.emitEventAsync(FloorBlueprintEventsEnum.planningTaskValidated);
            }

            if (!result.isLastValidation) {
                // S'il ne s'agit pas de la dernière validation, actualise l'affichage des informations
                await this.updateValidationCommands();
            }
        }
    }

    async onRejectButtonClick(): Promise<void> {
        if (!this.selectedTask) return;

        // La proposition d'aménagement est rejetée, l'étude ne change pas de statut, rien n'est modifié
        await this.service.validateTask(this.selectedTask.taskId, false, this.validationComment);
        // Recharge les validations
        this.validations = await this.service.downloadTaskValidationByTaskId(this.selectedTask.taskId);
        // Actualise les commandes de validation
        await this.updateValidationCommands();
    }

    async onAddRoomToTaskButtonClick(): Promise<void> {
        await this.emitEventAsync(FloorBlueprintEventsEnum.planningTaskRoomExtensionRequested);
    }

    variantCreateToggleChange(e: MatSlideToggleChange): void {
        this.variantCreateToggleIsChecked = e.checked;
    }

    async onCreateVariantButtonClick(variantTaskName: string, showVariantTaskNow: boolean): Promise<void> {
        if (!this.selectedTask) return;

        if (window.confirm("Créer la variante " + variantTaskName + " ?")) {
            const result = await this.service.createVariantTask(this.selectedTask.taskId, variantTaskName);

            // On réinitialise le formulaire de création de variante
            this.variantTaskName = "";
            this.variantCreateToggleIsChecked = false;

            if (showVariantTaskNow) {
                // On charge immédiatement la variante à l'écran à la place de l'étude affichée
                await this.emitEventAsync(FloorBlueprintEventsEnum.planningTaskVariantLoadRequested, result['task']['Ta_Id']);
            }

            // On ne charge pas immédiatement la variante
            // alors on actualise la liste des variantes de l'étude courante
            await this.loadTaskVariants(this.selectedTask.taskId);
        }
    }
}