import Container from 'typedi';
import { PageModel } from 'src/app/ui/main/model/page-model';
import { AppMenuRouteEnum } from 'src/app/core/model/data-model/enums/app-menu-route-enum';
import { RealEstateView } from 'src/app/core/services/backend-services/dto/real-estate-view';
import { RealEstateMap } from './real-estate-map';
import { MapToolbarVM } from './map-toolbar-vm';
import { MapEventsEnum } from './map-events-enum';
import { MainEventsEnum } from 'src/app/ui/main/model/main-events-enum';

export class RealEstateMapInteraction extends PageModel {
    static onBuildingMarkerClick = "onBuildingMarkerClick";
    static onClusterMarkerClick = "onClusterMarkerClick";

    toolbar: MapToolbarVM | undefined;

    companyId: string | undefined;
    reFlatHierarchy: RealEstateView[] = [];
    defaultLatitude: number = 46.227638;
    defaultLongitude: number = 2.213749;
    defaultZoomFactor: number = 6;
    currentLatitude: number = this.defaultLatitude;
    currentLongitude: number = this.defaultLongitude;
    currentZoomFactor: number = this.defaultZoomFactor;
    waitingForLocation: boolean = false;
    map: RealEstateMap | undefined;

    private constructor() {
        super(AppMenuRouteEnum.layout_realestate_map, 0, "81eb2682-97de-42f7-8199-3af5c260d163");

        this.addEventListener(MapEventsEnum.reFlatHierarchyLoaded, (reFlatHierarchy: RealEstateView[]) => {
            this.reFlatHierarchy = reFlatHierarchy;
        });

        this.addEventListener(MapEventsEnum.navigationRequired, (target: RealEstateView) => {
            this.navigateTo(target.latitude, target.longitude, 14);
            const tmp = this.map!.markers!.getLayers().find(x=> (x.getPopup() as any).getContent().id === target.id.toString());
            if (tmp) {
                tmp.openPopup();
            }
        });
    }

    static async newAsync(): Promise<RealEstateMapInteraction> {
        const tmp = new RealEstateMapInteraction();
        tmp.toolbar = await MapToolbarVM.newAsync();
        tmp.emitEventAsync(MainEventsEnum.nestedToolbarAvailable, tmp.toolbar);
        return tmp;
    }

    initMap(): void {
        this.map = new RealEstateMap(this.defaultLatitude, this.defaultLongitude, this.defaultZoomFactor);
    }

    destroyMap(): void {
        this.map = undefined;
    }
    
    navigateTo(latitude: number, longitude: number, zoomFactor: number): void {
        this.currentLatitude = latitude;
        this.currentLongitude = longitude;
        this.currentZoomFactor = zoomFactor;
        this.map?.setView(latitude, longitude, zoomFactor);
    }

    setMarkers(): void {
        this.map?.setMarkers(this.reFlatHierarchy, Container.get('companyId'));
    }
}