import { ZColumnConstraintView } from "src/app/core/model/data-model/views/z-column-constraint-view";
import { ZColumnView } from "src/app/core/model/data-model/views/z-column-view";
import { Field } from "./field";
import { FieldTypeEnum } from "./field-type-enum";
import { ZConstraintModeEnum } from "src/app/core/model/data-model/enums/z-constraint-mode-enum";
import { ZConstraintTypeEnum } from "src/app/core/model/data-model/enums/z-constraint-type-enum";
import { NOT_FOUND_IMG, SecuredImageComponent } from "../secured-image/secured-image.component";
import { logError, logWarn, missingListener } from "src/app/core/services/logging-service";

export class ImageFileField extends Field<string> {
    acceptedFiles: string = "";
    maxSize: number = 100;
    updating: boolean = false;
    isSvg: boolean = false;

    constructor(colId: string, rowId: any, value: string, definition: ZColumnView, constraints: ZColumnConstraintView[], readOnlyByScope: boolean) {
        super(FieldTypeEnum.string, colId, rowId, value, definition, constraints, readOnlyByScope);
        
        const acceptedFilesContraint = this.constraints.find(x=> x.coCoConstraintMode === ZConstraintModeEnum.Constant && x.coCoConstraintType === ZConstraintTypeEnum.AllowedFileExtensions);
        if (!acceptedFilesContraint) {
            logError("Extensions autorisées non spécifiées");
        } else {
            this.acceptedFiles = acceptedFilesContraint.coCoValue;
        }

        const maxSizeConstraint = this.constraints.find(x=> x.coCoConstraintMode === ZConstraintModeEnum.Constant && x.coCoConstraintType === ZConstraintTypeEnum.Maximum);
        if (!maxSizeConstraint) {
            logWarn("Taille maximum du fichier non spécifiée");
        } else {
            this.maxSize = Number(maxSizeConstraint.coCoValue);
        }

        if (value == null || value == '') {
            value = NOT_FOUND_IMG;
        }

        this.isSvg = value.toLocaleLowerCase().endsWith(".svg");

        this.errorsCheck = this.specificErrorsCheck;
    }

    imageUploadRequested?: (file: File, inserting: boolean, maxSize: number) => Promise<string>;
    async fileSelected(file: File): Promise<void> {
        // Un fichier a été sélectionné
        // Si on est en insertion de ligne, l'image est uploadée temporairement dans le sas en attendant la saisie complète
        // sinon il s'agit d'une mise à jour et la nouvelle image est uploadée en remplacement de celle qui existe
        if (this.imageUploadRequested) {
            this.updating = true;
            const uploadResult = await this.imageUploadRequested(file, this.inserting, this.maxSize);
            if (uploadResult != "") {
                this.value = "none";
                setTimeout(() => {
                    this.value = uploadResult;
                });
            }
            this.updating = false;
        } else {
            missingListener("ImageFileField.imageUploadRequested");
        }

        //this.raiseSaveRequestIfNecessary();
    }

    specificErrorsCheck(): string[] {
        return [];
    }
}