import { FloorModelEnum } from 'src/app/core/model/data-model/enums/floor-model-enum';
import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BlueprintViewerSidePanelModule } from '../../../side-panel-module';
import { BpeSidePanelContent } from '../../model/bpe-side-panel-content';
import { BlueprintSidePanelTabEnum } from '../../model/bp-side-panel-tab.enum';

@Component({
  selector: 'xc-bp-viewer-side-panel-content',
  standalone: true,
  imports: [CommonModule, BlueprintViewerSidePanelModule],
  templateUrl: './bp-viewer-side-panel-content.component.html',
  styleUrls: ['./bp-viewer-side-panel-content.component.scss']
})
export class BpViewerSidePanelContentComponent {
  @Input() model: BpeSidePanelContent | undefined;
  @Input() selectedTabIndex: number | string | undefined;
  FloorModelEnum = FloorModelEnum;
  BlueprintSidePanelTabEnum = BlueprintSidePanelTabEnum;
}
