import { getEntities } from './svg-entity-builder';
import { SvgEntity } from './svg-entity.model';

export class SvgGroup extends SvgEntity{
    entities: SvgEntity[] = [];

    constructor(dtoData: any) {
        super(dtoData);
        
        this.entities = getEntities(dtoData.entities);
    }
}
