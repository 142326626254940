@if (model) {
    <div class="flex flex-column gap-3">
        <p-fieldset legend="Nouveau" [toggleable]="true" [collapsed]="true">
            <div class="flex flex-column gap-3">
                <p-dropdown 
                    [style]="{'width':'100%'}"
                    [options]="model.contributorsDirectory"
                    [(ngModel)]="model.selectedContributorsDirectory" 
                    [showClear]="true" 
                    placeholder="Contributeur">
                    <ng-template pTemplate="selectedItem">
                        @if (model.selectedContributorsDirectory) {
                            <div>{{ model.taskContributorDirectoryItemDisplayName(model.selectedContributorsDirectory, false, false) }}</div>
                        }
                    </ng-template>
                    <ng-template let-c pTemplate="item">
                        <div>{{ model.taskContributorDirectoryItemDisplayName(c, false, false) }}</div>
                    </ng-template>
                </p-dropdown>
                <p-dropdown 
                    [style]="{'width':'100%'}"
                    [options]="model.taskFunctions" 
                    [(ngModel)]="model.selectedTaskFunction" 
                    optionLabel="displayName" 
                    [showClear]="true" 
                    placeholder="Fonction">
                </p-dropdown>
                <div>
                    <span>Charge</span>
                    <p-inputNumber [(ngModel)]="model.workload" 
                        inputStyleClass="w-full"
                        inputId="inputnew" 
                        mode="decimal" 
                        [min]="0.1"
                        [minFractionDigits]="2" 
                        [maxFractionDigits]="2"> 
                    </p-inputNumber>
                </div>
                <div class="flex justify-content-end">
                    <p-button 
                        label="Ajouter" 
                        [link]="true" 
                        [disabled]="!(model.selectedContributorsDirectory && model.selectedTaskFunction)"
                        (click)="model.newContributorButtonClick()">
                    </p-button>
                </div>
            </div>
        </p-fieldset>
        <p-dataView #dv [value]="model.contributors">
            <ng-template pTemplate="list" let-products>
                <div class="grid grid-nogutter">
                    @for (c of model.contributors; track $index; let first = $first) {
                        <div class="col-12">
                            <div class="flex flex-column xl:flex-row xl:align-items-start p-1 gap-1" [ngClass]="{ 'border-top-1 surface-border': !first }">
                                <p-avatar icon="pi pi-user" styleClass="mr-2" size="large"></p-avatar>
                                <div class="flex flex-column sm:flex-row justify-content-between align-items-center xl:align-items-start flex-1 gap-4">
                                    <div class="flex flex-column align-items-center sm:align-items-start gap-1">
                                        <div class="text-lg font-bold text-900">{{ model.directoryItemDisplayName(c.contributor, false, false) }}</div>
                                        <div class="flex align-items-center">
                                            <span class="flex align-items-center gap-2">
                                                @if (c.editing) {
                                                    <p-inputNumber [(ngModel)]="c.contributor.taCrLoad"
                                                        class="c-workload-input"
                                                        [inputId]="'input-' + c.contributor.taCrTaskId + '-' + c.contributor.taCrContributorId" 
                                                        mode="decimal" 
                                                        [class.inputValueChanged]="c.contributor.taCrLoad !== c.initialLoad"
                                                        [minFractionDigits]="2" 
                                                        [maxFractionDigits]="2" 
                                                        (focusout)="model.contributorWorkloadFocusOut(c)" 
                                                        (keydown)="model.contributorWorkloadkeyDown($event, c)"> 
                                                    </p-inputNumber>
                                                } @else {
                                                    <span class="text-base cursor-pointer" (click)="model.contributorWorkloadClick(c)">{{ c.contributor.taCrLoad }}</span>
                                                }
                                                <span class="font-semibold">{{ c.contributor.laItText }}</span>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="flex sm:flex-column align-items-center sm:align-items-end gap-3 sm:gap-2">
                                        @if (model.contributors.length > 1) {
                                            <p-button 
                                                icon="pi pi-times" 
                                                size="small" 
                                                [rounded]="true" 
                                                [text]="true" 
                                                severity="danger" 
                                                (click)="model.deleteContributorButtonClick(c.contributor)"
                                                class="md:align-self-end mb-2">
                                            </p-button>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </ng-template>
        </p-dataView>
    </div>
}
