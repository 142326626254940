import { Component } from "@angular/core";
import { MVVMEventDestroyLifeCycle } from "../../shared-model/mvvm-events-destroy-lifecycle";

@Component({ template: '' })
export abstract class ResizableLeftPanelVM extends MVVMEventDestroyLifeCycle {
    readonly sidePanelMinWidth: number = 30;
    readonly sidePanelMaxResizingWidth: number = 550;
    readonly sidePanelMinResizingWidth: number = 300;
    readonly sidePanelDefaultWidth: number = 350;
    sidePanelCurrentDefaultWidth: number = this.sidePanelDefaultWidth;
    resizeHandleWidth: number = 4; 
    sidePanelWidth: number = this.sidePanelCurrentDefaultWidth;
    sidePanelResizing: boolean = false;
    sidePanelIsFold: boolean = false;
    sidenavResizingInitiaWidth: number = 0;
    resizingInitialClientX: number = 0;
  
    foldSidePanel(unfold: boolean = false): void {
      let targetWidth: number = this.sidePanelMinWidth;
     
      if (unfold) {
          targetWidth = this.sidePanelCurrentDefaultWidth;
      }
  
      this.sidePanelWidth = targetWidth;
      this.sidePanelIsFold = !unfold;
    }
     
    onSidenavResizing(e: MouseEvent): void {
      e.stopPropagation();
      if (this.sidePanelResizing && e.buttons === 1) {
          const newWidth = this.sidenavResizingInitiaWidth + e.clientX - this.resizingInitialClientX;
          if (newWidth >= this.sidePanelMinResizingWidth && newWidth <= this.sidePanelMaxResizingWidth) {
          this.sidePanelWidth = newWidth;
          }
      }
    }
     
    initializeSidenavRezising(e: MouseEvent): void {
      e.stopPropagation();
      this.sidePanelResizing = true;
      this.sidenavResizingInitiaWidth = this.sidePanelWidth;
      this.resizingInitialClientX = e.clientX;
  }
  
    onSidenavEndResizing(e: MouseEvent): void {
        e.stopPropagation();
        if (this.sidePanelResizing) {
            this.sidePanelResizing = false;
            this.sidePanelCurrentDefaultWidth = this.sidePanelWidth;
        }
    }
      
    onPinClick(): void {
        this.sidePanelIsFold = !this.sidePanelIsFold;
        
        if (!this.sidePanelIsFold) {
            this.foldSidePanel(true);
        } else {
            this.foldSidePanel();
        }
    }
}