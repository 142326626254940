@if (model) {
  <div class="flex align-items-center justify-content-center h-full w-full">
        @if (model.clients && model.clients.length > 1) {
            <p-carousel [value]="model.clients" [numVisible]="3" [numScroll]="3" [circular]="false">
                <ng-template let-client pTemplate="item">
                    <div class="border-1 surface-border border-round m-2 text-center py-5 px-3">
                        <div class="mb-3">
                            <xc-secured-image class="logo" [src]="client.companyId + '/logo.jpg'" imgHeight="150px"/>
                        </div>
                        <div>
                            <h4 class="mb-1">{{ client.companyName }}</h4>
                            <p-tag [value]="!client.isAvailable ? 'En mainteance' : 'En activité'" [severity]="client.isAvailable ? 'success' : 'warning'"></p-tag>
                            <div class="mt-5 flex align-items-center justify-content-center gap-2">
                                <p-button label="Sélectionner" icon="pi pi-check" [disabled]="!client.isAvailable" (onClick)="model.select(client)"/>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </p-carousel>
        } @else {
            <p>Vous n'êtes habilité(e) sur aucun client.</p>
        }
  </div>
}
