import { SiteTable } from 'src/app/core/model/db-model/tables/site-table';
import { DefinedModelBase } from '../defined-model-base';

export class Site extends DefinedModelBase {
    stId!: number;
    stParentId!: number | null;
    stDepth!: number;
    stCode!: string;
    stName!: string;
    stIsActive!: boolean;
    stIsFictive!: boolean;
    stLocation!: string | null;
    stDefaultPerimeterId!: number;

    constructor(dtoData: any, tableDef: any[] = []) {
        super(dtoData, [SiteTable].concat(tableDef));
    }
}