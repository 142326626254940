import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GanttSvgTaskLinkBase } from '../../model/gantt-svg-task-link-base';

@Component({
  selector: 'g[xc-svg-gantt-task-link]',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './svg-gantt-task-link.component.svg',
  styleUrls: ['./svg-gantt-task-link.component.scss']
})
export class SvgGanttTaskLinkComponent {
  @Input() model: GanttSvgTaskLinkBase | undefined;
}
