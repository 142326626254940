import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from 'src/app/core/material.module';
import { LabelingFormModel } from '../../model/labeling-form-model';
import { FormsModule } from '@angular/forms';
import { MVVMEventDestroyLifeCycle } from 'src/app/components-lib/shared-model/mvvm-events-destroy-lifecycle';

@Component({
  standalone: true,
  imports: [CommonModule, MaterialModule, FormsModule],
  selector: 'xc-labeling-list-form',
  templateUrl: './labeling-list-form.component.html',
  styleUrls: ['./labeling-list-form.component.scss']
})
export class LabelingListFormComponent extends MVVMEventDestroyLifeCycle {
  @Input() override model: LabelingFormModel | undefined;
}
