import { ReportGridItem } from "./report-grid-item";

export class ReportGridNumberItem extends ReportGridItem<number> {
    numberFormat: string = "1.0-0";
    suffix: string = "";

    constructor(label: string, value: number, numberFormat?: string, suffix?: string) {
        super(label, value, 'number');
        if (numberFormat) this.numberFormat = numberFormat;
        if (suffix) this.suffix = suffix;
    }
}