import { PerimeterView } from "src/app/core/model/data-model/views/perimeter-view";

export class RoleGrantedPerimeter {
    perimeter: PerimeterView;
    isRoleGranted: boolean = false;

    constructor(perimeter: PerimeterView) {
        this.perimeter = perimeter;
    }

    setRoleGrants(grants: number[]): void {
        this.isRoleGranted = this.perimeter.peReadGrantId != null && grants.includes(this.perimeter.peReadGrantId);
    }
}