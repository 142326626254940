import { SimulationWorkforceTable } from "../../db-model/tables/simulation-workforce-table";
import { DefinedModelBase } from "../defined-model-base";

export class SimulationWorkforce extends DefinedModelBase {
    siWoPhoto!: number;
    siWoSimulationBusinessUnit!: number;
    siWoFlexibilityRatio!: number;
    siWoVacancyRatio!: number;
    siWoWorkforceCount!: number;
    siWoArea!: number;

    constructor(dtoData: any, tableDef: any[] = []) {
        super(dtoData, [SimulationWorkforceTable].concat(tableDef));
    }
}