import { BuildingAnatomyDbView } from '../../db-model/views/building-anatomy-db-view';
import { DefinedModelBase } from '../defined-model-base';

export class BuildingAnatomyView extends DefinedModelBase {
    buSiteId!: number;
    flBuildingId!: number;
    roLaTyId!: number;
    roLaTyCode!: string;
    roLaTyName!: string;
    roLaTyColor!: string;
    roLaTyIsNUA!: boolean;
    roLaTyParentId!: number;
    buLaViArea!: number;
    buLaViRoundedArea!: number;
    buLaViLayoutTypeTree!: string;

    constructor(dtoData: any) {
        super(dtoData, [BuildingAnatomyDbView]);
    }
}