import { readableUUID } from "src/app/core/events/event-listener-uuid";
import { AppMenuRouteEnum } from "src/app/core/model/data-model/enums/app-menu-route-enum";
import { PageModel } from "src/app/ui/main/model/page-model";
import { LeasesTreeVM } from "./leases-tree-vm";

export class LeaseEditorVM extends PageModel {
    tree!: LeasesTreeVM;

    private constructor() {
        super(AppMenuRouteEnum.planning_strategy_leases, 0, readableUUID(LeaseEditorVM.name));
    }

    static async newAsync(): Promise<LeaseEditorVM> {
        const result = new LeaseEditorVM();
        result.tree = await LeasesTreeVM.newAsync();
        return result;
    }
}