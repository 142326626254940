import { FloorActivityStatusDbView } from '../../db-model/views/floor-activity-status-db-view';
import { DefinedModelBase } from '../defined-model-base';

export class FloorActivityStatusView extends DefinedModelBase {
    buSiteId!: number;
    flBuildingId!: number;
    flId!: number;
    flDaTaskId!: number;
    roActivityStatusTypeId!: number;
    roAcTyName!: string;
    roAcTyDisplayNameId!: number;
    roAcTyViDisplayName!: string;
    roAcTyColor!: string;
    flAcStViArea!: number;
    flAcStViRoundedArea!: number;
    roViIsCurrentState!: boolean;

    constructor(dtoData: any) {
        super(dtoData, [FloorActivityStatusDbView]);
    }
}
