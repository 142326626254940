<div id="creationHeader">
    <span>Création d'une nouvelle simulation</span>
    <button mat-icon-button (click)="model?.cancelButtonClick()" matTooltip="Annuler la création">
        <mat-icon class="material-icons-outlined">cancel</mat-icon>
    </button>
</div>
@if (model) {
<mat-stepper linear #stepper (selectionChange)="model.selectedStepChange($event)">
    <mat-step [completed]="model.stepOne.completed">
        <ng-template matStepLabel>Informations générales</ng-template>
        <ng-template matStepContent>
            <div class="stepContainer">
                <xc-creation-step-one [model]="model.stepOne"></xc-creation-step-one>
                <button mat-button matStepperNext [disabled]="!model.stepOne.completed">Suivant</button>
            </div>
        </ng-template>
    </mat-step>
    @if (!model.creationData.busSelection) {
<mat-step>
        <ng-template matStepLabel>Sélection des immeubles</ng-template>
        <ng-template matStepContent>
            <div class="stepContainer">
                <xc-creation-step-two [model]="model.stepBuildingSelection"></xc-creation-step-two>
                <div id="stepTwoCommands">
                    <button mat-button matStepperPrevious>Précédent</button>
                    <button mat-button matStepperNext [disabled]="model.creationData.selectedBuildings.length === 0">Suivant</button>
                </div>
            </div>
        </ng-template>
    </mat-step>
}
    @if (model.creationData.busSelection) {
<mat-step>
        <ng-template matStepLabel>Sélection des entités</ng-template>
        <ng-template matStepContent>
            <div class="stepContainer">
                <xc-creation-step-three [model]="model.stepBusSelection"></xc-creation-step-three>
                <div id="stepThreeCommands">
                    <button mat-button matStepperPrevious>Précédent</button>
                    <button mat-button matStepperNext [disabled]="model.creationData.selectedBus.length === 0">Suivant</button>
                </div>
            </div>
        </ng-template>
    </mat-step>
}
    <mat-step>
        <ng-template matStepLabel>Récapitulatif et validation</ng-template>
        <ng-template matStepContent>
            <div class="stepContainer">
                <xc-creation-step-four [model]="model.stepFour"></xc-creation-step-four>
                <div id="stepFourCommands">
                    <button mat-button matStepperPrevious>Précédent</button>
                    <button mat-button (click)="model.createSimulation()">Créer la simulation</button>
                </div>
            </div>
        </ng-template>
    </mat-step>
  </mat-stepper>
}
