import { SimulationTable } from '../../db-model/tables/simulation-table';
import { DefinedModelBase } from '../defined-model-base';

export class Simulation extends DefinedModelBase {
    siId!: number;
    siRoot!: number | null;
    siParent!: number | null;
    siProjectTaskId!: number;
    siStartDate!: Date;
    siEndDate!: Date;
    siName!: string;
    siDescription!: string | null;
    siIsActive!: boolean;
    siDepth!: number;

    constructor(dtoData: any, tableDef: any[] = []) {
        super(dtoData, [SimulationTable].concat(tableDef));
        // Il faut convertir explicitement les dates en Date parce qu'elles arrivent nativement comme string
        this.siStartDate = new Date(this.siStartDate);
        this.siEndDate = new Date(this.siEndDate);
    }
}