import { LayoutTypeContentComponent } from './../layout-type-content/layout-type-content.component';
import { LayoutTypeContainerVM } from '../../model/layout-type-container-vm';
import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SplitterModule } from 'primeng/splitter';
import { MVVMEventDestroyLifeCycle } from 'src/app/components-lib/shared-model/mvvm-events-destroy-lifecycle';
import { LayoutTypesTreeComponent } from '../layout-types-tree/layout-types-tree.component';

@Component({
  selector: 'xc-layout-type',
  standalone: true,
  imports: [CommonModule, SplitterModule, LayoutTypeContentComponent, LayoutTypesTreeComponent],
  templateUrl: './layout-type-container.component.html',
  styleUrls: ['./layout-type-container.component.scss']
})
export class LayoutTypeComponent extends MVVMEventDestroyLifeCycle {
  @Input() override model: LayoutTypeContainerVM | undefined;
}
