import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BuildingAxono } from '../../model/building-axono';

@Component({
  selector: 'xc-building-axono',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './building-axono.component.html',
  styleUrls: ['./building-axono.component.scss']
})
export class BuildingAxonoComponent {
  @Input() model: BuildingAxono | undefined;
}
