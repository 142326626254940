import { SimulationAreaTable } from "../../db-model/tables/simulation-area-table";
import { DefinedModelBase } from "../defined-model-base";

export class SimulationArea extends DefinedModelBase {
    siArPhoto!: number;
    siArSimulationFloor!: number;
    siArAttributionTypeId!: number;
    siArActivityStatusTypeId!: number;
    siArArea!: number;

    constructor(dtoData: any, tableDef: any[] = []) {
        super(dtoData, [SimulationAreaTable].concat(tableDef));
    }
}