import { Container } from 'typedi';
import { SvgDOM } from './svg-dom';
import { EquipmentFormVM } from './../../../../blueprint-viewer-side-panel/subitems/properties/model/equipment-form-vm';
import { FloorBlueprint } from "../floor-blueprint";
import { EditableLayer } from "./editable-layer";
import { InteractionCommand } from "./interaction-command";
import { LayerInteraction } from "./layer-interaction";
import { EquipmentsSelectionSet } from './equipments-selection-set';
import { EquipmentUpdateGizmoVM } from '../../../subitems/gizmos/equipment-update-gizmo/model/equipment-update-gizmo-vm';
import { SelectionInteraction } from './selection-interaction';
import { Point } from 'src/app/core/model/geometry-model/point.model';
import { HtmlConstants } from 'src/app/core/model/html-model/html-constants.model';
import { FloorDataStateEnum } from 'src/app/core/model/data-model/enums/floor-data-state-enum';
import { EquipmentTransient } from '../../../subitems/gizmos/equipment-update-gizmo/model/equipment-transient';
import { Segment } from 'src/app/core/model/geometry-model/segment.model';
import { XcMaths } from 'src/app/core/model/static-functions/xc-maths';
import { BlueprintLayer } from '../../../../svg-entities/model/layers/blueprint-layer';
import { getEndPoints } from 'src/app/core/model/geometry-model/geometric-elements-builder';
import { Polygon } from 'src/app/core/model/geometry-model/polygon.model';
import { Vector } from 'src/app/core/model/geometry-model/vector.model';
import { FloorModelEnum } from 'src/app/core/model/data-model/enums/floor-model-enum';
import { EquipmentPlanning } from 'src/app/core/model/data-model/tables/equipment-planning';
import { BpSvgLabel } from '../../../../svg-entities/model/bp-svg-label';
import { BpSvgWorkplaceLabel } from '../../../../svg-entities/model/bp-svg-workplace-label';
import { BpSvgPeopleLabel } from '../../../../svg-entities/model/bp-svg-people-label';
import { BlueprintEquipmentLayer } from '../../../../svg-entities/model/layers/blueprint-equipment-layer';
import { MagnetData } from '../../../subitems/gizmos/equipment-update-gizmo/model/magnet-data';
import { EquipmentSelectionOverlay } from '../../../subitems/gizmos/equipment-update-gizmo/model/equipment-selection-overlay';
import { EquipmentSelectionSideHandles } from '../../../subitems/gizmos/equipment-update-gizmo/model/equipment-selection-side-handles';
import { BpSvgEquipmentCatalogGroup } from '../../../../blueprint-viewer-side-panel/subitems/equipments/model/bp-svg-equipment-catalog-group';
import { BpSvgDef } from '../../../../bp-svg-core-model/bp-svg-def';
import { BpSvgUse } from '../../../../bp-svg-core-model/bp-svg-use';
import { PolygonService } from 'src/app/core/model/geometry-model/polygon-service';
import { GripsDetection } from './grips-detection';
import { BpEquipmentService } from 'src/app/core/services/backend-services/bp-equipment-service';

export class EquipmentInteraction implements LayerInteraction {
    layerId = 0;
    layerName: string = "NA";

    floorBlueprint: FloorBlueprint;
    editableLayers: EditableLayer[] = [];
    currentCommand: InteractionCommand;
    
    selectionSet: EquipmentsSelectionSet = new EquipmentsSelectionSet();
    isEditable: boolean;

    updateGizmo: EquipmentUpdateGizmoVM;
    selectionInteraction: SelectionInteraction | undefined;
    selectionCount: number = 0;

    propertiesForm: EquipmentFormVM;

    constructor(layerId: number, command: InteractionCommand, blueprint: FloorBlueprint, editableLayers: EditableLayer[]) {
        this.layerId = layerId;
        this.isEditable = editableLayers.find(x=> x.layerId === layerId) != null;
        this.updateGizmo =  new EquipmentUpdateGizmoVM(this.isEditable);
        
        this.floorBlueprint = blueprint;
        this.editableLayers = editableLayers;
        this.currentCommand = command;

        this.propertiesForm = new EquipmentFormVM();

        const layer = editableLayers.find(x=> x.layerId === this.layerId);
        if (layer) {
            this.layerName = layer.layerName;
        }

        this.layerId = layerId;
        this.listenToEquipmentGizmoEvents();
        this.listenToEquipmentReplaceEvents();
    }

    async activate(uses: BpSvgUse[], toRemove: boolean): Promise<void> {
        this.selectionSet = new EquipmentsSelectionSet();
        this.selectionSet.addOrRemove(uses, !toRemove);
        this.updateGizmo.show(this.selectionSet);
        this.selectionSet.setChanged = () => this.onSelectionSetChanged();
        this.propertiesForm.initialize(this.selectionSet, this.isEditable, this.floorBlueprint.hasPlanningTask(), this.floorBlueprint.topMostTaskId());
    }
    
    deactivate(): void {
        this.selectionSet.clear();
        // Remets la couleur par défaut de l'overlay
        this.updateGizmo.selectionOverlay.fill = EquipmentSelectionOverlay.defaultColor;
        this.updateGizmo.targetGrips.clear();
        if(this.updateGizmo.transientPosition) {
            this.updateGizmo.transientPosition.hide();
        }
        //this.usesSelectionSet.previousSelectionSetIds.splice(0);
        //this.usesSelectionSet.clearSelectedItems();    
    }

    beginInsert(shape: BpSvgEquipmentCatalogGroup): void {
        this.currentCommand.set(InteractionCommand.equipmentInsertionCommand);
        const topMostTaskId = this.floorBlueprint.topMostTaskId();
        this.floorBlueprint.layersController.updateCursor(this.editableLayers, topMostTaskId, false);
        this.updateGizmo.showTransient(shape);
    }

    endInsert(): void {
        this.currentCommand.clear();
        // Réactive le curseur de sélection sur les items de l'étude
        const topMostTaskId = this.floorBlueprint.topMostTaskId();
        this.floorBlueprint.layersController.updateCursor(this.editableLayers, topMostTaskId, true);
        this.updateGizmo.hideTransient();
    }

    abort(): void {
        // IMPORTANT ! Il faut retirer les éléments copiés du jeu de données du calque AVANT de vider la sélection
        // parce que si cette sélection est une copie, le vidage de la sélection fait dispaître toute trace de la copie et il n'est plus possible
        // de purger le calque
    
        // if (this.selectionSet.selectedItemsCopy.length > 0)
        // {
        //     // S'il y a des éléments dans le set de copie on doit les supprimer du jeu de données du calque
        //     this.floorBlueprint.removeItemsCopy(this.usesSelectionSet, this.layerId);
        // }

        // Rétabli l'affichage des enfants
        this.hideChildren(false);

        if (this.currentCommand.isEquipmentInsertionCommand()) {
            this.endInsert();
        }

        // Si la commande est FreeTranslation, on remet les éléments dans leur état initial
        if (this.currentCommand.isFreeTranslationCommand()) {
            this.selectionSet.reset();
            // Replace le gizmo
            this.updateGizmo.show(this.selectionSet);
        }

        // Si la commande est FreeRotation, on remet les éléments dans leur état initial
        if (this.currentCommand.isFreeRotationCommand()) {
            this.selectionSet.reset();
            this.updateGizmo.selectionOverlayRotationLabel.hide();
        }

        // Si la commande est GripTranslation, on remet les éléments dans leur état initial et on arrête le timer
        if (this.currentCommand.isGripTranslationCommand()) {
            this.selectionSet.reset();
            // Replace le gizmo
            this.updateGizmo.show(this.selectionSet);
        }

        if (this.currentCommand.isSideFreeMoveCommand()) {
            this.selectionSet.reset();
            // Replace le gizmo
            this.updateGizmo.show(this.selectionSet);
        }

        if (this.currentCommand.isSideConstraintTranslationCommand()) {
            this.selectionSet.reset();
            // Replace le gizmo
            this.updateGizmo.show(this.selectionSet);
        }

        // Rétabli l'affichage de la poignée de rotation libre qui peut avoir été masquée par une commande de copie
        this.updateGizmo.selectionOverlay.rotationHandle.show();
    }

    selectionDeleted?: () => void;
    raiseSelectionDeleted(): void {
        if (this.selectionDeleted) {
            this.selectionDeleted();
        }
    }

    onSelectionSetChanged(): void {
        if (this.propertiesForm.selectedEquipments == null) {
            this.propertiesForm.initialize(this.selectionSet, this.isEditable, this.floorBlueprint.hasPlanningTask(), this.floorBlueprint.topMostTaskId());
        } else {
            this.propertiesForm.onSelectionChange();
        }

        this.updateGizmo.selectionSetChange();
    }

    refreshThemeNeeded?: () => void;
    askForThemerefresh(): void {
        setTimeout(() => {
            if (this.refreshThemeNeeded) {
                this.refreshThemeNeeded();
            }
        }, 0);
    }
    
    async deleteSelectedItems(): Promise<void> {
        if (this.selectionSet != null) {
            const ids = this.selectionSet.items.map(x=> x.floorDataId);
            const s = Container.get(BpEquipmentService);
            const result = await s.deleteEquipments(ids);
            
            // Les items dont le dataState et planningState sont null ont été supprimés de la base, on les supprime du plan
            const reelDeletedItems = result.filter(x=> x.dataStateId == null);
            reelDeletedItems.forEach(d => {
                const e = this.selectionSet.items.find(x=> x.floorDataId === d.id);
                if (e) {
                    // Contrôle supplémentaire : les items réellement supprimés sont ceux qui n'appartiennent qu'à l'étude
                    // et qui n'ont donc pas de sourceId
                    if (e.sourceId != null) {
                        console.log("Un élément ayant un sourceId ne peut pas être réellement supprimé");
                    }
                    if (!this.floorBlueprint.layersController.removeElement(e)) {
                        console.log("Impossible de supprimer l'élément du plan. id : " + e.floorDataId + ", layer : " + e.floorModelId);
                    }
                }
            });

            // Actualisation des états sur les autres éléments
            const fakeDeletedItems = result.filter(x=> x.dataStateId != null);
            fakeDeletedItems.forEach(d => {
                const e = this.selectionSet.items.find(x=> x.floorDataId === d.id);
                if (e) {
                    e.updateState(d);
                } 
            });

            const wpl = this.floorBlueprint.layersController.workplacesLabelsLayer(this.floorBlueprint.topMostTaskId());
            const pll = this.floorBlueprint.layersController.peopleLabelsLayer(this.floorBlueprint.topMostTaskId());
            const wplDeleted = result.filter(x=> x.floorModelId === FloorModelEnum.WorkplaceLabels); 
            const pplDeleted = result.filter(x=> x.floorModelId === FloorModelEnum.PeopleLabels); 
            if (wpl) {
                wplDeleted.forEach(w => {
                    if (w.dataStateId == null) {
                        wpl.remove(w.id);
                    } else {
                        wpl.updateDataState(w.id, w.dataStateId);
                    }
                });
            }
            if (pll) {
                pplDeleted.forEach(p => {
                    if (p.dataStateId == null) {
                        pll.remove(p.id);
                    } else {
                        pll.updateDataState(p.id, p.dataStateId);
                    }
                });
            }

            this.selectionSet.clear();
            this.raiseSelectionDeleted();

            // Demande l'actualisation du thème s'il est affiché
            this.askForThemerefresh();
        }
    }

    listenToEquipmentGizmoEvents(): void {
        // Abonnement au MouseDown event des poignées latérales de l'overlay de sélection
        this.updateGizmo.selectionOverlay.onSideHandled = (handleId: string, clientPoint: Point) => {
            // Le user a cliqué sur une poignée latérale, on initialise la commande de translation latérale
            this.initializeSideMouvement(handleId, clientPoint);
        }

        // Abonnement au MouseDown de la poignée de translation libre située au centre de l'overlay
        this.updateGizmo.selectionOverlay.onFreeTranslationHandled = (clientPoint: Point) => {
            // Le user a cliqué sur la poignée de translation libre, on initialise la commande correspondante
            this.initializeFreeTranslation(clientPoint);
        }

        // Abonnement au MouseDown de la poignée de rotation située à l'extérieur de l'overlay de sélection
        this.updateGizmo.selectionOverlay.onFreeRotationHandled = (clientPoint: Point) => {
            // Le user a cliqué sur la poignée de translation libre, on initialise la commande correspondante
            this.initializeFreeRotation(clientPoint);
        }

        // Abonnement au MouseDown d'un grip de l'overlay
        this.updateGizmo.selectionOverlay.onGripTranslation = (gripPoint: Point) => {
            // Le user a cliqué sur la poignée de translation libre, on initialise la commande correspondante
            this.initializeGripTranslation(gripPoint);
        }

    }

    listenToEquipmentReplaceEvents(): void {
        this.propertiesForm.equipmentReplaced = (uses: BpSvgUse[], floorCatalog: BpSvgDef[]) => {
            // Insère les définitions si elles n'existent pas
            floorCatalog.forEach(fc => {
                if (this.floorBlueprint.definitions.find(x=> x.id === fc.id) == null) {
                    this.floorBlueprint.definitions.push(fc);
                }
            });

            // Référence les définitions dans les uses
            // et rend les nouveaux éléments sélectionnables
            const usesLayer = this.floorBlueprint.layersController.layer<BlueprintEquipmentLayer>(this.layerId, this.floorBlueprint.topMostTaskId());
            if (!usesLayer) return;

            uses.forEach(u => {
                const def = floorCatalog.find(x=> x.id === u.floorCatalogId);
                u.def = def;
                usesLayer.insert(u);

                u.cursor = HtmlConstants.styleCursorPointer;
            });

            // Passe la sélection actuelle au statut "Supprimé" si les éléments ne sont pas au statut "Ajouté"
            // sinon les éléments sont retirés du plan car ils ont effectivement été supprimé dans la base
            this.selectionSet.items.forEach(item => {
                if (item.dataStateId === FloorDataStateEnum.Added) {
                    usesLayer.remove(item.floorDataId);
                } else {
                    usesLayer.updateDataState(item.floorDataId, FloorDataStateEnum.Deleted);
                }
            });

            // Vide la sélection
            this.selectionSet.clear();

            // Demande l'actualisation du thème si nécessaire
            this.askForThemerefresh();
        }
    }

    /**
     * Initialise la commande de translation latérale. En fonction de la forme de la poignée, la translation est contrainte
     * c'est-à-dire que la sélection se déplace perpendiculairement au côté, ou bien la translation est composite, c'est-à-dire que la sélection
     * suit les mouvements de la souris en s'orientant dans la direction du déplacement
     * Les events suivants, MouseMove et MouveUp, sont capturés par FloorBlueprint
     * @param handleId 
     * @param clientPoint 
     */
    initializeSideMouvement(handleId: string, clientPoint: Point): void
    {
        this.currentCommand.initialClientMousePosition = clientPoint;
        this.selectionSet.storeSelectionSetInitialTransform();
        const svgMousePosition = SvgDOM.getPointPosition(clientPoint.x, clientPoint.y);
        if (svgMousePosition == null) {
            return;
        }

        this.currentCommand.handleId = handleId;
        // Initialise le modèle de proposition de placement pour qu'il puisse être affiché le cas échéant
        this.updateGizmo.transientPosition = new EquipmentTransient(this.updateGizmo.selectionOverlay);

        const tbb = this.updateGizmo.selectionOverlay.transformedBboxCenter();
        const smp = this.updateGizmo.selectionOverlay.getSideMidPoint(handleId);
        if (!tbb || !smp) return;
        
        this.currentCommand.initializeTransformUpdate(tbb, smp);
        if (!this.currentCommand.initialBboxCenter) return;

        if (this.updateGizmo.selectionOverlay.sideHandles.handlesAreTranslateRotate)
        {
            this.currentCommand.set(InteractionCommand.sideFreeMoveCommand);

            this.currentCommand.initialSvgMousePosition = svgMousePosition;
            
            const mouseSegment = new Segment(this.currentCommand.initialBboxCenter, svgMousePosition);
            this.currentCommand.initialMouseAngle = XcMaths.get360DegreeAngle(90 - XcMaths.toDeg(mouseSegment.angle()), 2);
        }
        else
        {
            if (!this.currentCommand.initialSideMidPoint) return;
            this.currentCommand.set(InteractionCommand.sideConstraintTranslationCommand);

            // Le point de départ du déplacement est la projection orthogonale de la position de la souris sur le radiusSegment
            const overlayRadiusSegment = new Segment(this.currentCommand.initialBboxCenter, this.currentCommand.initialSideMidPoint);
            //const overlayRadiusSegment = this.gizmoOverlay.selectionOverlay.getSideRadiusSegment(handleId);
            this.currentCommand.initialSvgMousePosition = overlayRadiusSegment.getOrthogonalProjection(svgMousePosition);
        }
    }

    /**
     * Initialize la commande de translation libre
     * @param clientPoint 
     */
    initializeFreeTranslation(clientPoint: Point): void {
        this.currentCommand.set(InteractionCommand.freeTranslationCommand);
        this.currentCommand.initialClientMousePosition = clientPoint;
        this.selectionSet.storeSelectionSetInitialTransform();
        const svgMousePosition = SvgDOM.getPointPosition(clientPoint.x, clientPoint.y);
        this.currentCommand.initialSvgMousePosition = svgMousePosition;
        this.hideChildren(true);
    }

    /**
     * Initialise la commande de rotation libre
     * @param clientPoint 
     */
    initializeFreeRotation(clientPoint: Point): void {
        this.currentCommand.set(InteractionCommand.freeRotationCommand);
        this.currentCommand.initialClientMousePosition = clientPoint;
        this.selectionSet.storeSelectionSetInitialTransform();
        const svgMousePosition = SvgDOM.getPointPosition(clientPoint.x, clientPoint.y);
        this.currentCommand.initialSvgMousePosition = svgMousePosition;

        const tbb = this.updateGizmo.selectionOverlay.transformedBboxCenter();
        if (!tbb || !this.updateGizmo.selectionOverlay.transform) return;
        this.currentCommand.initialBboxCenter = tbb;
        // la poignée de rotation est dans la direction du côté haut (considéré hors transformation) de l'overlay
        const topSideMiddle = this.updateGizmo.selectionOverlay.sideHandles.getSideMidPoint(EquipmentSelectionSideHandles.sideHandle1Id, this.updateGizmo.selectionOverlay.transform);
        const handleSegment = new Segment(this.currentCommand.initialBboxCenter, topSideMiddle);
        this.currentCommand.initialMouseAngle = XcMaths.get360DegreeAngle(90 - XcMaths.toDeg(handleSegment.angle()), 2);

        this.updateGizmo.selectionOverlayRotationLabel.initialize(this.currentCommand.initialBboxCenter, this.currentCommand.initialMouseAngle.toString());
    }

    initializeGripTranslation(gripPoint: Point): void {
        this.currentCommand.set(InteractionCommand.gripTranslationCommand);
        this.selectionSet.storeSelectionSetInitialTransform();
        this.currentCommand.initialSvgMousePosition = gripPoint;
    }

    detectUseTargetGrips(hitPoint: Point, equipmentInteraction: EquipmentInteraction): void {
        if(!hitPoint) {
            return;
        }

        let result = SvgDOM.getElementFromSvgPoint(hitPoint, equipmentInteraction.selectionSet.getSelectedItemsIds());

        if(!result){
            return;
        }

        switch (result.tagName)
        {
            case "use":
                if (result.floorDataId != null)
                {
                    equipmentInteraction.setUseTargetGrips(result.floorDataId, hitPoint);
                }
                break;
            case "line":
                if (result.floorDataId != null && result.floorModelId === 5)
                {
                    equipmentInteraction.setUseWallTargetGrips(result.floorDataId, hitPoint);
                }
                break;
            default:
                break;
        }

        equipmentInteraction.currentCommand.magnetAttraction = 0.15;
    }

    detectUseTargetPosition(hitPoint: Point, ei: EquipmentInteraction): void {
        if(!hitPoint || !ei.currentCommand.handleId) {
            return;
        }

        // La pointe de la poignée (ou le milieu de l'arc si la poignée est un demi-cercle)
        //var handleEdge = this.gizmoOverlay.selectionOverlay.sideHandles.getHandleEdge(this.currentCommand.handleId, this.gizmoOverlay.selectionOverlay);
        // La distance du centre de la boite englobante au milieu du côté de translation
        var targetItemBboxCenterDistance = ei.updateGizmo.selectionOverlay.sideHandles.getbboxCenterDistanceToSideMiddle(ei.currentCommand.handleId, ei.updateGizmo.selectionOverlay);

        const reticle = ei.getAimingReticle(hitPoint);

        // Recherche un élément de mobilier sur lequel s'aligner
        const fl = ei.floorBlueprint.layersController.layer(FloorModelEnum.Furniture, ei.floorBlueprint.topMostTaskId()) as BlueprintEquipmentLayer;
        let intersects = fl.selectIntersectsByContour(reticle);
        const selectionIds = ei.selectionSet.floorDataIds();
        intersects = intersects.filter(x=> !selectionIds.includes(x.floorDataId!));
        if (intersects.length > 0) {
            // L'intersection prioritaire est celle où le segment cible est intégralement contenu dans le réticule de visée
            const fullInside = intersects.find(x=> x.intersections[0].point == null);
            const favorite = fullInside != null ? fullInside : intersects[0];
            const ts = favorite.segment;
            const uses = ei.floorBlueprint.layersController.layer<BlueprintEquipmentLayer>(FloorModelEnum.Furniture, ei.floorBlueprint.topMostTaskId())?.typedData();
            if (uses) {
                const use = uses.filter(x => x.floorDataId === favorite.floorDataId)[0];
                ei.currentCommand.magnetData = new MagnetData(
                    ts,
                    use.getBboxCenter(),
                    use.transform!,
                    targetItemBboxCenterDistance,
                    ei.currentCommand.initialBboxCenter!,
                    ts.midPoint(),
                    0);
            }

            if(ei.currentCommand.magnetData != null)
            {
                ei.currentCommand.magnetAttraction = 0.25;
                ei.showTransientPosition(ei.currentCommand.magnetData);
            }
            return;
        }

        // Recherche une cloison sur laquelle s'aligner
        const wl = ei.floorBlueprint.layersController.wallsLayer(ei.floorBlueprint.topMostTaskId());
        if (!wl) return;
        const wallSegments = wl.getActiveWallsSegments(true, []);
        const wi = BlueprintLayer.getIntersects(reticle, wallSegments, wl.id, wl.taskId);
        if (wi.length > 0) {
            const w = wl.getFromSegment(wi[0].segment);
            if (w) {
                const wlm = ei.getWallMagnetData(w.floorDataId, targetItemBboxCenterDistance, hitPoint);
                if(wlm != null)
                {
                    ei.currentCommand.magnetData = wlm;
                    ei.currentCommand.magnetAttraction = 0.25;
                    ei.showTransientPosition(ei.currentCommand.magnetData);
                    return;
                }
            }
        }

        // Recherche un accrochage sur la façade
        const il = ei.floorBlueprint.layersController.insideContoursLayer();
        if (!il) return;
        const ii = BlueprintLayer.getIntersects(reticle, il.segments, wl.id, wl.taskId);
        if (ii.length > 0) {
            const ilm = ei.getInsideContourMagnetData(ii[0].segment, targetItemBboxCenterDistance, hitPoint);
            if(ilm != null)
            {
                ei.currentCommand.magnetData = ilm;
                ei.currentCommand.magnetAttraction = 0.25;
                ei.showTransientPosition(ei.currentCommand.magnetData);
                return;
            }
        }

        // Recherche un accrochage sur les contours structurels et de noyau
        const cl = ei.floorBlueprint.layersController.coreContoursLayer();
        if (!cl) return;
        const ci = BlueprintLayer.getIntersects(reticle, cl.segments, wl.id, wl.taskId);
        if (ci.length > 0) {
            const clm = ei.getCoreContourMagnetData(ci[0].segment, targetItemBboxCenterDistance, hitPoint);
            if(clm != null)
            {
                ei.currentCommand.magnetData = clm;
                ei.currentCommand.magnetAttraction = 0.25;
                ei.showTransientPosition(ei.currentCommand.magnetData);
                return;
            }
        }

        // let result = PocSvgTools.getElementFromSvgPoint(hitPoint, ei.selectionSet.getSelectedItemsIds());

        // if(!result){
        //     return;
        // }

        // // La pointe de la poignée (ou le milieu de l'arc si la poignée est un demi-cercle)
        // //var handleEdge = this.gizmoOverlay.selectionOverlay.sideHandles.getHandleEdge(this.currentCommand.handleId, this.gizmoOverlay.selectionOverlay);
        // // La distance du centre de la boite englobante au milieu du côté de translation
        // var targetItemBboxCenterDistance = ei.updateGizmo.selectionOverlay.sideHandles.getbboxCenterDistanceToSideMiddle(ei.currentCommand.handleId, ei.updateGizmo.selectionOverlay);

        // switch (result.tagName)
        // {
        //     case "use":
        //         if (result.floorDataId != null)
        //         {
        //             const magnetData = ei.getUseMagnetData(result.floorDataId, hitPoint, targetItemBboxCenterDistance, hitPoint);
        //             if(magnetData != null)
        //             {
        //                 ei.currentCommand.magnetAttraction = 0.25;
        //                 ei.showTransientPosition(magnetData);
        //             }
        //         }
        //         break;
        //     case "line":
        //         if (result.floorDataId != null && result.floorModelId === 5)
        //         {
        //             const magnetData = ei.getWallMagnetData(result.floorDataId, targetItemBboxCenterDistance, hitPoint);
        //             if(magnetData != null)
        //             {
        //                 ei.currentCommand.magnetAttraction = 0.25;
        //                 ei.showTransientPosition(magnetData);
        //             }
        //         }
        //         break;
        //     default:
        //         break;
        // }
    }

    setUseWallTargetGrips(floorDataId: number, hitPoint: Point): void {
        const walls = this.floorBlueprint.layersController.wallsLayer(this.floorBlueprint.topMostTaskId())?.typedData();
        if (!walls) return;

        const wall = walls.filter(x => x.floorDataId === floorDataId)[0];

        if(!wall) {
            return;
        }

        let wallWidth = 0.0;
        if (wall.strokeWidth)
        {
            wallWidth = Number(wall.strokeWidth);
        }

        const segment = wall.segment();

        const grips: Point[] = [wall.startPoint, wall.endPoint, segment.midPoint()];

        // Décale les points de la largeur de la cloison
        let offsetGrips: Point[] = [];
        const sourceIsLeftHand = segment.isLeftHand(hitPoint);
        let offset = wallWidth / 2;
        if (sourceIsLeftHand)
        {
            offset = -offset;
        }

        grips.forEach(g => {
            offsetGrips.push(segment.getOrthogonalOffset(g, offset));
        });

        const nearestGrip = Point.getNearest(offsetGrips, hitPoint);
        this.updateGizmo.targetGrips.loadFromPoints(offsetGrips);
        this.updateGizmo.targetGrips.select(nearestGrip);
    }

    setUseTargetGrips(floorDataId: number, hitPoint: Point): void {
        const contourVertices = GripsDetection.getUseTargetGrips(this.floorBlueprint, floorDataId, this.currentCommand.selectedGripOptions, hitPoint);

        // let minDistanceIndex = 0;
        // let minDistance = 1000.0;
        // for (let i = 0; i < contourVertices.length; i++) {
        //     const v = contourVertices[i];
        //     var gripDistance = hitPoint.distanceTo(v);
        //     if (gripDistance < minDistance)
        //     {
        //         minDistance = gripDistance;
        //         minDistanceIndex = i;
        //     }
        // }
        const nearest = Point.getNearest(contourVertices, hitPoint);

        this.updateGizmo.targetGrips.loadFromPoints(contourVertices);
        this.updateGizmo.targetGrips.select(nearest);
    }

    // getUseMagnetData(floorDataId: number, handleEdge: PocPoint, targetItemBboxCenterDistance: number, hitPoint: PocPoint): PocSvgMagnetData | null {
    //     const uses = this.floorBlueprint.layer(FloorModelEnum.Furniture, this.floorBlueprint.topMostTaskId()).data as PocSvgUse[];
    //     const use = uses.filter(x => x.id === floorDataId)[0];
    //     //const contour = use.def.defsGeometry[0] as PocSvgPath;
    //     //const contourGeometry = contour.geometry;

    //     // Définition d'un réticule de sélection à partir de la position de la souris
    //     var selectionPolygon = this.getAimingReticle(handleEdge);

    //     // Détection des intersections avec le contour de l'équipement
    //     const intersects = use.contourIntersects(selectionPolygon);
    //     if (intersects.length > 0) {
    //         const ts = intersects[0].segment;
    //         this.currentCommand.magnetData = new PocSvgMagnetData(
    //             ts,
    //             use.getBboxCenter(),
    //             use.transform,
    //             targetItemBboxCenterDistance,
    //             this.currentCommand.initialBboxCenter,
    //             ts.midPoint(),
    //             // ts.getOrthogonalProjection(hitPoint),
    //             0);
    //         return this.currentCommand.magnetData;
    //     }

    //     // for(let s of getSegments(contourGeometry)){
    //     //     const ts = use.transform.transformedSegment(s);
    //     //     if (selectionPolygon.getIntersects([ts]).length > 0)
    //     //     {
    //     //         this.currentCommand.magnetData = new PocSvgMagnetData(
    //     //             ts,
    //     //             use.getBboxCenter(),
    //     //             use.transform,
    //     //             targetItemBboxCenterDistance,
    //     //             this.currentCommand.initialBboxCenter,
    //     //             ts.midPoint(),
    //     //             // ts.getOrthogonalProjection(hitPoint),
    //     //             0);
    //     //         return this.currentCommand.magnetData;
    //     //     }
    //     // }

    //     return null;
    // }

    getWallMagnetData(floorDataId: number, targetItemBboxCenterDistance: number, hitPoint: Point) : MagnetData | null {
        if (!this.currentCommand.initialBboxCenter) return null;

        const walls = this.floorBlueprint.layersController.wallsLayer(this.floorBlueprint.topMostTaskId())?.typedData();
        if (!walls) return null;
        const wall = walls.filter(x => x.floorDataId === floorDataId)[0];

        if(!wall) {
            return null;
        }

        let wallWidth = 0.0;
        if (wall.strokeWidth)
        {
            wallWidth = Number(wall.strokeWidth);
        }

        const segment = new Segment(wall.startPoint, wall.endPoint);

        this.currentCommand.magnetData = new MagnetData(
            segment,
            null,
            null,
            targetItemBboxCenterDistance + (wallWidth / 2),
            this.currentCommand.initialBboxCenter,
            segment.getOrthogonalProjection(hitPoint),
            wallWidth / 2);

        return this.currentCommand.magnetData;
    }

    getInsideContourMagnetData(s: Segment, targetItemBboxCenterDistance: number, hitPoint: Point): MagnetData | null {
        if (!this.currentCommand.initialBboxCenter) return null;
        this.currentCommand.magnetData = new MagnetData(
            s,
            null,
            null,
            targetItemBboxCenterDistance,
            this.currentCommand.initialBboxCenter,
            s.getOrthogonalProjection(hitPoint),
            0);

        return this.currentCommand.magnetData;
    }

    getCoreContourMagnetData(s: Segment, targetItemBboxCenterDistance: number, hitPoint: Point): MagnetData | null {
        if (!this.currentCommand.initialBboxCenter) return null;
        this.currentCommand.magnetData = new MagnetData(
            s,
            null,
            null,
            targetItemBboxCenterDistance,
            this.currentCommand.initialBboxCenter,
            s.getOrthogonalProjection(hitPoint),
            0);

        return this.currentCommand.magnetData;
    }

    showTransientPosition(magnetData: MagnetData): void {
        if (!this.updateGizmo.transientPosition || !this.currentCommand.initialBboxCenter) return;

        // Le delta d'angle ramené à une rotation toujours positive
         var magnetSegmentAngle = this.calculateTransientBboxAngle();

        // Le delta de translation entre le bboxCenter et le ghost center
        var deltaTranslate = this.calculateTransientBboxDeltaTranslate();

        if (deltaTranslate) {
            this.updateGizmo.transientPosition.rotateTranslate(this.currentCommand.initialBboxCenter, magnetSegmentAngle, deltaTranslate.vector());
            //this.gizmoOverlay.transientPosition.rotateTranslate(PocPoint.origin(), targetAngleDeg, rotatedTargetTranslate);
            this.updateGizmo.transientPosition.show();
        }
    }

    getAimingReticle(point: Point): Polygon {
        const reticleBoxHalfSize = 0.15;
        return PolygonService.centeredSquare(point, reticleBoxHalfSize);
    }
     
    calculateTransientBboxAngle(): number {
        // Calcule l'angle entre le côté de déplacement et le segment cible en utilisant les demis-segments partant des milieux des segments
        // et se dirigeant dans la même direction générale, cette direction étant déterminée par le fait que les endPoints se trouvent tous les deux
        // ou à droite ou à gauche de la droite joignant le selectionSet BboxCenter au milieu du côté de déplacement

        const magnetData = this.currentCommand.magnetData;

        if (!magnetData || !this.currentCommand.handleId || !this.currentCommand.initialBboxCenter) return 0;

        // Le milieu du magnet segment
        const magnetSegmentMidPoint = magnetData.segment.midPoint();
        // Le côté du déplacement
        var bboxSide = this.updateGizmo.selectionOverlay.getSide(this.currentCommand.handleId);
        // Le milieu du côté de déplacement
        var moveSideMidPoint = bboxSide.midPoint();
        // La droite joignant le milieu du côté de déplacement au milieu magnet segment
        var moveSegment = new Segment(moveSideMidPoint, magnetSegmentMidPoint);
        // Les points du magnet segment
        var magnetSegmentEnds = magnetData.segment.points();
        // Les points du côté de déplacement
        var bboxSideEnds = bboxSide.points();
        // Le point du magnet segment qui est sur la droite du radius segment
        var magnetSegmentRightEnd = magnetSegmentEnds.filter(x => moveSegment.isAbsoluteRightHand(x))[0];
        if (magnetSegmentRightEnd == null)
        {
            // debug: normalement ce cas ne doit pas se produire
            throw("unable to find absolute right hand end point");
        }
        // Le point du côté de déplacement qui est sur la droite du radius segment
        var bboxSideRightEnd = bboxSideEnds.filter(x => moveSegment.isAbsoluteRightHand(x))[0];
        if (bboxSideRightEnd == null)
        {
            // debug: normalement ce cas ne doit pas se produire
            throw("unable to find absolute right hand end point");
        }
        // Le demi-segment du magnet allant du milieu vers la droite
        var magnetRightHalfSegment = new Segment(magnetSegmentMidPoint, magnetSegmentRightEnd);
        // Le demi-segment du côté de déplacement allant du milieu du côté vers la droite
        var bboxRightHalfSegment = new Segment(moveSideMidPoint, bboxSideRightEnd);
        // L'angle entre les deux demi-segments
        var radiansMagnetAngle = bboxRightHalfSegment.angleWith(magnetRightHalfSegment);
        var degreeMagnetAngle = XcMaths.toDeg(radiansMagnetAngle);

        // Dans le cas d'une SideFreeMoveCommand il faut ajouter l'angle entre la bbox initiale et la bbox actuelle
        if (this.currentCommand.isSideFreeMoveCommand() && this.currentCommand.tempSvgMousePosition != null)
        {
            var mouseSegment = new Segment(this.currentCommand.initialBboxCenter, this.currentCommand.tempSvgMousePosition);
            var mouseAngle = XcMaths.get360DegreeAngle(90 - XcMaths.toDeg(mouseSegment.angle()));
            //var mouseAngle = PocMaths.get360DegreeAngle(90 - PocMaths.round(PocMaths.toDeg(mouseSegment.angle()), 0));
            var deltaAngle = (mouseAngle - this.currentCommand.initialMouseAngle) % 360;
            degreeMagnetAngle+= deltaAngle;
        }

        // Le delta d'angle ramené à une rotation toujours positive
        return XcMaths.get360DegreeAngle(degreeMagnetAngle);
    }

    calculateTransientBboxDeltaTranslate(): Point | null {
        // Le transientPosition correspond à la defBbox du jeu de sélection orientée comme le segment cible

        const currentCommand = this.currentCommand;
        const magnetData = currentCommand.magnetData;

        if (!magnetData || !currentCommand.initialBboxCenter) return null;

        // Le centre de la bbox du ghost
        var ghostCenter = magnetData.targetItemBboxCenter;
        // Le delta de translation entre le bboxCenter et le ghost center
        return new Point(ghostCenter.x - currentCommand.initialBboxCenter.x, ghostCenter.y - currentCommand.initialBboxCenter.y);
    }

    fitSelectionItemsToTransientPosition(): void {
        if (!this.currentCommand.initialBboxCenter) return;

        // Le delta d'angle ramené à une rotation toujours positive
        var magnetSegmentAngle = this.calculateTransientBboxAngle();

        // Le delta de translation entre le bboxCenter et le ghost center
        var deltaTranslate = this.calculateTransientBboxDeltaTranslate();

        // Transformation de la sélection
        if (deltaTranslate) {
            this.rotateTranslateSelectedItems(this.currentCommand.initialBboxCenter, magnetSegmentAngle, deltaTranslate.vector());
        }
    }

    rotateTranslateSelectedItems(rotationCenter: Point, deltaAngle: number, deltaTranslate: Vector): void {
        // Actualise la translation du jeu de sélection
        this.selectionSet.rotateTranslate(rotationCenter, deltaAngle, deltaTranslate);
        // Actualise la translation de l'overlay
        this.updateGizmo.selectionOverlay.rotateTranslate(rotationCenter, deltaAngle, deltaTranslate);
        // Actualise la translation des contours
        //this.updateGizmo.updateSelectedContours(this.selectionSet.selectedItems);
    }

    translateSelectedItems(delta: Vector): void {
        // Actualise la translation du jeu de sélection
        this.selectionSet.translate(delta);
        // Actualise la translation de l'overlay
        this.updateGizmo.selectionOverlay.translate(delta);
        // Actualise la translation des contours
        //this.updateGizmo.updateSelectedContours(this.usesSelectionSet.selectedItems);
    }

    hideChildren(hidden: boolean): void {
        const ids = this.selectionSet.floorDataIds();

        // Bascule l'affichage de l'informatique
        const hardwareLayer = this.floorBlueprint.layersController.layer(FloorModelEnum.Hardware, this.floorBlueprint.topMostTaskId());
        if (hardwareLayer != null) {
            hardwareLayer.hideChildrenByProcess(ids, hidden);
        }

        // Bascule l'affichage des positions de travail
        const workplacesLayer = this.floorBlueprint.layersController.workplacesLabelsLayer(this.floorBlueprint.topMostTaskId());
        if (workplacesLayer != null) {
            const workplacesIds = workplacesLayer.hideChildrenByProcess(ids, hidden);

            // Bascule l'affichage des étiquettes de personne
            const peopleLayer = this.floorBlueprint.layersController.peopleLabelsLayer(this.floorBlueprint.topMostTaskId());
            if (peopleLayer != null) {
                peopleLayer.hideChildrenByProcess(workplacesIds, hidden);
            }
        }
    }

    updateChildren(saveResult: { svgUses: BpSvgUse[], svgLabels: BpSvgLabel[], equipmentPlanningItems: EquipmentPlanning[] }): void {
        saveResult.svgUses.forEach(u => {
            const selectedItem = this.selectionSet.items.find(x=> x.floorDataId === u.floorDataId);
            const ep = saveResult.equipmentPlanningItems.find(x=> x.eqPlFloorDataId === u.floorDataId);
            if (selectedItem && ep) {
                selectedItem.equipmentPlanning = ep;
                selectedItem.planningStateId = ep.eqPlPlanningStateId;
            }
        });
        this.updateSelectionDataState(saveResult.svgUses.filter(x=> x.floorModelId === FloorModelEnum.Furniture));
        this.updateHardware(saveResult.svgUses.filter(x=> x.floorModelId === FloorModelEnum.Hardware));
        this.updateChildrenDataStateAndPosition(saveResult.svgLabels);
    }

    updateSelectionDataState(saveResult: BpSvgUse[]): void {
        saveResult.forEach(sr => {
            const selectedItem = this.selectionSet.items.find(x=> x.floorDataId === sr.floorDataId);
            if (selectedItem) {
                selectedItem.dataStateId = sr.dataStateId;
                //selectedItem.planningStateId = sr.planningStateId;
            }
        });
    }

    updateHardware(saveResult: BpSvgUse[]): void {
        const hardwareLayer = this.floorBlueprint.layersController.layer<BlueprintEquipmentLayer>(FloorModelEnum.Hardware, this.floorBlueprint.topMostTaskId());
        if (hardwareLayer != null) {
            saveResult.forEach(sr => {
                const h = hardwareLayer.data.find(x=> x.floorDataId === sr.floorDataId);
                if (h) {
                    h.dataStateId = sr.dataStateId;
                    //h.planningStateId = sr.planningStateId;
                    h.transform = sr.transform;
                }
            });
        }
    }

    updateChildrenDataStateAndPosition(saveResult: BpSvgLabel[]): void {
        const workplacesLayer = this.floorBlueprint.layersController.workplacesLabelsLayer(this.floorBlueprint.topMostTaskId());
        const peopleLayer = this.floorBlueprint.layersController.peopleLabelsLayer(this.floorBlueprint.topMostTaskId());
        
        if (workplacesLayer != null) {
            const workplaceResult = saveResult.filter(x=> x.floorModelId === FloorModelEnum.WorkplaceLabels);
            workplaceResult.forEach(wr => {
                const w = (workplacesLayer.data as BpSvgWorkplaceLabel[]).find(x=> x.floorDataId === wr.floorDataId);
                if (w) {
                    w.dataStateId = wr.dataStateId;
                    w.planningStateId = wr.planningStateId;
                    w.updatePosition(wr.text.position());
    
                    if (peopleLayer != null) {
                        const peopleResult = saveResult.filter(x=> x.parentId === w.floorDataId);
                        peopleResult.forEach(pr => {
                            const p = (peopleLayer.data as BpSvgPeopleLabel[]).find(x=> x.floorDataId === pr.floorDataId);
                            if (p) {
                                p.dataStateId = pr.dataStateId;
                                p.planningStateId = pr.planningStateId;
                                p.updatePosition(pr.text.position());
                                // Actualise les lignes de repère
                                p.updateLeaderLine(w.backgroundRectangle);
                            }
                        });
                    }
                }
            });

        }
    }

    async endFreeRotationCommand(): Promise<void> {
        // Termine la commande
        this.currentCommand.clear();

        // Enregistre la modification
        const s = Container.get(BpEquipmentService);
        const result = await s.transformEquipments(this.selectionSet.items as BpSvgUse[]);
        if (result != null) {
            this.updateChildren(result);
            this.hideChildren(false);
        } else {
            this.abort();
        }

        // Demande l'actualisation du thème si nécessaire
        this.askForThemerefresh();

        // Masque l'étiquette de rotation
        this.updateGizmo.selectionOverlayRotationLabel.hide();
    }

    async mouseDown(e: MouseEvent, hitPoint: Point): Promise<boolean> {
        if (this.currentCommand.isEquipmentInsertionCommand()) {
            const item = this.updateGizmo.transientShape;
            if (!item) return false;
            const floorCatalogId = Number(item.floorCatalogId);
            const s = Container.get(BpEquipmentService);
            const result = await s.insertEquipment(this.floorBlueprint.topMostTaskId(), floorCatalogId, hitPoint);
            if (result != null) {
                // Insère le calque s'il n'existe pas
                if (this.floorBlueprint.layersController.layers.find(x=> x.id === this.layerId && x.taskId === this.floorBlueprint.topMostTaskId()) == null) {
                    this.floorBlueprint.layersController.insertLayer(result.floorModel);
                }

                // Insère la définition si elle n'existe pas
                if (this.floorBlueprint.definitions.find(x=> x.id === floorCatalogId) == null) {
                    this.floorBlueprint.definitions.push(result.floorCatalog);
                }

                result.use.def = result.floorCatalog;
                result.use.equipmentPlanning = result.equipmentPlanning;
                result.use.planningStateId = result.equipmentPlanning.eqPlPlanningStateId;
                result.use.floorDataState = result.floorDataState;
                result.use.equipmentPlanningState = result.equipmentPlanningState;
                this.floorBlueprint.layersController.layer(this.layerId, this.floorBlueprint.topMostTaskId())?.insert(result.use);

                if (this.currentCommand.magnetAttraction > 0) {
                    // Repositionne l'élément
                }

                this.askForThemerefresh();

            } else {
                this.abort();
            }

            // La commande continue jusqu'à l'utilisation de la touche Escape
            return true;
        }

        return false;
    }

    timer: number | undefined;
    async mouseMove(e: MouseEvent, hitPoint: Point): Promise<void> {
        if (this.currentCommand.isEquipmentInsertionCommand()) {
            this.updateGizmo.moveTransientShape(hitPoint);
            return;
        }

        if (this.currentCommand.isFreeTranslationCommand() && this.currentCommand.initialSvgMousePosition != null) {
            const delta = new Point(hitPoint.x - this.currentCommand.initialSvgMousePosition.x, hitPoint.y - this.currentCommand.initialSvgMousePosition.y).vector();
            // Actualise la translation du jeu de sélection
            this.translateSelectedItems(delta);
            return;
        }
      
        if (this.currentCommand.isGripTranslationCommand() && this.currentCommand.initialSvgMousePosition != null) {
            // Arrête le timer
            clearTimeout(this.timer);

            // Le magnetAttraction sert à empêcher tout mouvement tant que la souris ne s'est pas déplacée d'une distance minimale
            // lorsqu'un attracteur est affiché
            // Cela permet à l'utilisateur de bien voir l'attracteur avant de décider ou non de relâcher la souris
            // sans que le moindre minuscule mouvement ne le fasse disparaître ou changer de place
            if (this.currentCommand.tempSvgMousePosition != null && hitPoint.distanceTo(this.currentCommand.tempSvgMousePosition) < this.currentCommand.magnetAttraction) {
                return;
            }
      
            // La souris est sortie du périmètre d'attraction, on masque les grips
            if (this.currentCommand.magnetAttraction > 0) {
                this.updateGizmo.targetGrips.clear();
                this.currentCommand.magnetAttraction = 0;
            }
      
            // On actualise la position courante de la souris pour le contrôle de l'attraction lors du prochain MouseMove
            this.currentCommand.tempSvgMousePosition = hitPoint;
        
            const delta = new Point(hitPoint.x - this.currentCommand.initialSvgMousePosition.x, hitPoint.y - this.currentCommand.initialSvgMousePosition.y).vector();
            // Actualise la translation du jeu de sélection
            this.translateSelectedItems(delta);
      
            // Redemarre le timer
            this.timer = window.setTimeout(this.detectUseTargetGrips, 150, hitPoint, this);
      
            return;
        }
      
        if (this.currentCommand.isFreeRotationCommand()) {
            // NOTA : la poignée de rotation libre est masquée lorsque le jeu de sélection est une copie
      
            if (this.currentCommand.initialBboxCenter === undefined || this.updateGizmo.selectionOverlay.transform === undefined) return;

            const initialBboxCenter = this.currentCommand.initialBboxCenter;
            const mouseSegment = new Segment(initialBboxCenter, hitPoint);
            const mouseSegmentAngle = Math.round(XcMaths.toDeg(mouseSegment.angle()));
            const mouseAngle = XcMaths.get360DegreeAngle(90 - mouseSegmentAngle);
      
            // Angle de rotation minimum de 1°
            const step = 1;
            const stepMouseAngle = Math.round((mouseAngle / step) * step);
      
            const deltaAngle = (stepMouseAngle - this.currentCommand.initialMouseAngle) % 360;
      
            // Actualise la rotation du jeu de sélection
            this.selectionSet.rotate(initialBboxCenter, deltaAngle);
            // Actualise la rotation de l'overlay
            this.updateGizmo.selectionOverlay.rotate(initialBboxCenter, deltaAngle);
            // Actualise la rotation des contours
            //this.updateGizmo.updateSelectedContours(this.usesSelectionSet.selectedItems);
            // Actualise l'étiquette affichant l'angle de rotation
            this.updateGizmo.selectionOverlayRotationLabel.show(this.updateGizmo.selectionOverlay.transform.rotationAngle.toString());
            return;
        }
      
        if (this.currentCommand.isSideFreeMoveCommand() && this.currentCommand.initialSvgMousePosition != null) {
            // Arrête le timer
            clearTimeout(this.timer);

            if (!this.currentCommand.initialBboxCenter || !this.currentCommand.initialMouseAngle || !this.currentCommand.handleId) return;

            // Le magnetAttraction sert à empêcher tout mouvement tant que la souris ne s'est pas déplacée d'une distance minimale
            // lorsqu'un attracteur est affiché
            // Cela permet à l'utilisateur de bien voir l'attracteur avant de décider ou non de relâcher la souris
            // sans que le moindre minuscule mouvement ne le fasse disparaître ou changer de place
            if (this.currentCommand.tempSvgMousePosition != null && hitPoint.distanceTo(this.currentCommand.tempSvgMousePosition) < this.currentCommand.magnetAttraction) {
              return;
            }
      
            // Efface la proposition de placement
            //this.updateGizmo.transientPosition.hide();
      
            // La souris est sortie du périmètre d'attraction, on masque l'attracteur
            if (this.currentCommand.magnetAttraction > 0) {
              this.updateGizmo.transientPosition?.hide();
              this.currentCommand.magnetAttraction = 0;
            }
      
            // On actualise la position courante de la souris pour le contrôle de l'attraction lors du prochain MouseMove
            this.currentCommand.tempSvgMousePosition = hitPoint;
      
            // On déplace la sélection en la pivotant pour que le bboxCenter soit aligné derrière le side midPoint par rapport mousePosition
      
            const deltaTranslate = new Point(hitPoint.x - this.currentCommand.initialSvgMousePosition.x, hitPoint.y - this.currentCommand.initialSvgMousePosition.y).vector();
      
            const mouseSegment = new Segment(this.currentCommand.initialBboxCenter, hitPoint);
            const mouseAngle = XcMaths.get360DegreeAngle(90 - Math.round(XcMaths.toDeg(mouseSegment.angle())));
            const deltaAngle = (mouseAngle - this.currentCommand.initialMouseAngle) % 360;
      
            // Actualise la position
            this.rotateTranslateSelectedItems(this.currentCommand.initialSvgMousePosition, deltaAngle, deltaTranslate);
      
            // Redemarre le timer
            const handleEdge = this.updateGizmo.selectionOverlay.sideHandles.getHandleEdge(this.currentCommand.handleId, this.updateGizmo.selectionOverlay);
            this.timer = window.setTimeout(this.detectUseTargetPosition, 150, handleEdge, this);
      
            return;
        }
      
        if (this.currentCommand.isSideConstraintTranslationCommand() && this.currentCommand.initialSvgMousePosition != null) {
            if (!this.currentCommand.initialBboxCenter || ! this.currentCommand.handleId || !this.currentCommand.initialSideMidPoint) return;
            //this.userInteraction.gizmoOverlay.debugPoints.splice(0);
      
            // Arrête le timer
            clearTimeout(this.timer);
      
            // Le magnetAttraction sert à empêcher tout mouvement tant que la souris ne s'est pas déplacée d'une distance minimale
            // lorsqu'un attracteur est affiché
            // Cela permet à l'utilisateur de bien voir l'attracteur avant de décider ou non de relâcher la souris
            // sans que le moindre minuscule mouvement ne le fasse disparaître ou changer de place
            if (this.currentCommand.tempSvgMousePosition != null &&
              hitPoint.distanceTo(this.currentCommand.tempSvgMousePosition) < this.currentCommand.magnetAttraction) {
              return;
            }
      
            // Efface la proposition de placement
            this.updateGizmo.transientPosition?.hide();
      
            // La souris est sortie du périmètre d'attraction, on masque l'attracteur
            if (this.currentCommand.magnetAttraction > 0) {
              this.updateGizmo.transientPosition?.hide();
              this.currentCommand.magnetAttraction = 0;
            }
      
            // On actualise la position courante de la souris pour le contrôle de l'attraction lors du prochain MouseMove
            this.currentCommand.tempSvgMousePosition = hitPoint;
      
            // On projette orthogonalement le point de la souris sur le radiusSegment
            const radiusSegment = new Segment(this.currentCommand.initialBboxCenter, this.currentCommand.initialSideMidPoint);
            const orthoProjection = radiusSegment.getOrthogonalProjection(hitPoint);
      
            // et on déplace la sélection
            const delta = new Point(orthoProjection.x - this.currentCommand.initialSvgMousePosition.x, orthoProjection.y - this.currentCommand.initialSvgMousePosition.y).vector();
            // Actualise la translation du jeu de sélection
            this.translateSelectedItems(delta);
      
            // Redemarre le timer
            const handleEdge = this.updateGizmo.selectionOverlay.sideHandles.getHandleEdge(this.currentCommand.handleId, this.updateGizmo.selectionOverlay);
            this.timer = window.setTimeout(this.detectUseTargetPosition, 150, handleEdge, this);
      
            return;
        }
    }

    async CopyEquipments(hitPoint: Point): Promise<void> {
        const s = Container.get(BpEquipmentService);
        const delta = new Point(hitPoint.x - this.currentCommand.initialSvgMousePosition!.x, hitPoint.y - this.currentCommand.initialSvgMousePosition!.y).vector();
        const result = await s.copyEquipments(delta.u, delta.v, this.selectionSet.sourceFloorDataIds());
        if (result != null) {
            result.forEach(i => {
                i.cursor = HtmlConstants.styleCursorPointer;
            });
            this.floorBlueprint.layersController.layer(this.layerId, this.floorBlueprint.topMostTaskId())?.insert(result);

            this.selectionSet.setCopyResult(result);
            // L'overlay retrouve sa couleur normale
            this.updateGizmo.selectionOverlay.setColor(EquipmentSelectionOverlay.defaultColor);
            // On réaffiche la poignée de rotation
            this.updateGizmo.selectionOverlay.rotationHandle.show();
        }
    }

    async mouseUp(e: MouseEvent, hitPoint: Point): Promise<void> {
        // Si les éléments sélectionnés étaient des copies, il faut d'abord les enregistrer

        if (this.currentCommand.isFreeTranslationCommand()) {
            // Vide l'éventuelle copie
            //this.usesSelectionSet.selectedItemsCopy.splice(0);
            // Enregistre la modification
            if (this.selectionSet.isCopy) {
                await this.CopyEquipments(hitPoint);
            } else {
                const s = Container.get(BpEquipmentService);
                const result = await s.transformEquipments(this.selectionSet.items as BpSvgUse[]);
                if (result != null) {
                    this.updateChildren(result);
                    this.hideChildren(false);
                } else {
                    this.abort();
                }
            }

            // Termine la commande
            this.currentCommand.clear();

            // Demande l'actualisation du thème si nécessaire
            this.askForThemerefresh();
            
            return;
        }
  
        if (this.currentCommand.isFreeRotationCommand()) {
            await this.endFreeRotationCommand();
            return;
        }
  
        if (this.currentCommand.isSideConstraintTranslationCommand() || this.currentCommand.isSideFreeMoveCommand()) {
            // Arrête le timer
            clearTimeout(this.timer);
            // Efface le jeu de grips cible
            this.updateGizmo.targetGrips.clear();
    
            // S'il y a une proposition de positionnement affichée, elle est appliquée à la sélection
            if (this.currentCommand.magnetAttraction > 0) {
                // Le jeu de sélection doit être positionné comme le transientPosition
                this.fitSelectionItemsToTransientPosition();
                // Masque la proposition de placement
                this.updateGizmo.transientPosition?.hide();
            }

            // Enregistre la modification
            if (this.selectionSet.isCopy) {
                await this.CopyEquipments(hitPoint);
            } else {
                const s = Container.get(BpEquipmentService);
                const result = await s.transformEquipments(this.selectionSet.items as BpSvgUse[]);
                if (result != null) {
                    this.updateChildren(result);
                    this.hideChildren(false);
                } else {
                    this.abort();
                }
            }

            // Vide des données de commande
            this.currentCommand.clear();

            // Demande l'actualisation du thème si nécessaire
            this.askForThemerefresh();

            // Vide l'éventuelle copie
            //this.usesSelectionSet.selectedItemsCopy.splice(0);
            return;
        }
  
        if (this.currentCommand.isGripTranslationCommand() && this.currentCommand.initialSvgMousePosition != null) {
            if (this.updateGizmo.targetGrips.items.length > 0) {
                // S'il y a des grips de positionnement affichés, déplace le jeu de sélection sur le grip le plus proche
                const nearestGrip = this.updateGizmo.targetGrips.getSelected();
                if (nearestGrip) {
                    const delta = new Point(nearestGrip.point.x - this.currentCommand.initialSvgMousePosition.x, nearestGrip.point.y - this.currentCommand.initialSvgMousePosition.y).vector();
                    this.translateSelectedItems(delta);
                }
            }

            // Enregistre la modification
            if (this.selectionSet.isCopy) {
                await this.CopyEquipments(hitPoint);
            } else {
                const s = Container.get(BpEquipmentService);
                const result = await s.transformEquipments(this.selectionSet.items as BpSvgUse[]);
                if (result != null) {
                    this.updateChildren(result);
                    this.hideChildren(false);
                } else {
                    this.abort();
                }
            }

            this.currentCommand.clear();

            // Demande l'actualisation du thème si nécessaire
            this.askForThemerefresh();

            // Vide l'éventuelle copie
            //this.usesSelectionSet.selectedItemsCopy.splice(0);
            // Efface le jeu de grips cible
            this.updateGizmo.targetGrips.clear();
            // Arrête le timer
            clearTimeout(this.timer);
            return;
        }
    }

    async keyDown(e: KeyboardEvent): Promise<void> {
        if(e.key.toLowerCase() === "a" && this.currentCommand.isNoneCommand()){
            // On ne peut changer les poignées si on est déjà en commande
            this.updateGizmo.selectionOverlay.sideHandles.switchShape();
            return;
        }
            
        if(e.key.toLowerCase() === "c"){
            if (this.currentCommand.isNoneCommand() && this.selectionSet.count > 0){
                //this.userInteraction.floorBlueprint.copySelectedItems(this.userInteraction.usesSelectionSet, this.userInteraction.currentCommand.currentLayerId);
                this.selectionSet.copy();
                //this.floorBlueprint.copySelectedItems(this.usesSelectionSet, this.layerId);
                // L'overlay change de couleur lorsqu'il est constitué d'éléments copiés
                this.updateGizmo.selectionOverlay.setColor(EquipmentSelectionOverlay.copiedItemsColor);
                // La poignée de rotation libre n'est pas affichée lorsque des éléments viennent d'être copiés sur eux-mêmes
                this.updateGizmo.selectionOverlay.rotationHandle.hide();
                return;
            }
        }

        if (e.key.toLowerCase() === "r") {
            if (this.currentCommand.isNoneCommand() && this.selectionSet.count > 0){
                //this.initializeFreeRotation(new Point());
                this.currentCommand.set(InteractionCommand.freeRotationCommand);

                this.currentCommand.initialSvgMousePosition = new Point();
                this.updateGizmo.selectionOverlay.initialTransform = this.updateGizmo.selectionOverlay.transform?.clone();
                this.selectionSet.storeSelectionSetInitialTransform();

                const tbb = this.updateGizmo.selectionOverlay.transformedBboxCenter();
                if (!tbb || !this.updateGizmo.selectionOverlay.transform) return;
                // la poignée de rotation est dans la direction du côté haut (considéré hors transformation) de l'overlay
                const topSideMiddle = this.updateGizmo.selectionOverlay.sideHandles.getSideMidPoint(EquipmentSelectionSideHandles.sideHandle1Id, this.updateGizmo.selectionOverlay.transform);
                const handleSegment = new Segment(tbb, topSideMiddle);
                this.currentCommand.initialMouseAngle = XcMaths.get360DegreeAngle(90 - XcMaths.toDeg(handleSegment.angle()), 2);
        
                // Actualise la rotation du jeu de sélection
                this.selectionSet.rotate(tbb, 90);
                // Actualise la rotation de l'overlay
                this.updateGizmo.selectionOverlay.rotate(tbb, 90);
                
                await this.endFreeRotationCommand();

                return;
            }
        }
    }
}