import { readableUUID } from "src/app/core/events/event-listener-uuid";
import { AppMenuRouteEnum } from "src/app/core/model/data-model/enums/app-menu-route-enum";
import { PageModel } from "src/app/ui/main/model/page-model";
import { GrantedLayer } from "./granted-layer";
import Container from "typedi";
import { DyntService } from "src/app/core/services/backend-services/dynt-service";
import { FloorModelView } from "src/app/core/model/data-model/views/floor-model-view";
import { ViewsNames } from "src/app/core/model/db-model/views-names-enum";
import { FloorModelTable } from "src/app/core/model/db-model/tables/floor-model-table";
import { FloorModelCategoryEnum } from "src/app/core/model/data-model/enums/floor-model-category-enum";
import { CheckboxChangeEvent } from "primeng/checkbox";
import { FloorModelService } from "src/app/core/services/backend-services/floor-model-service";
import { GrantTypeEnum } from "src/app/core/model/data-model/enums/grant-type-enum";
import { GrantService } from "src/app/core/services/backend-services/grant-service";
import { MainEventsEnum } from 'src/app/ui/main/model/main-events-enum';
import { toastSuccess } from 'src/app/core/services/toast-service';

export class LayersGrantsVM extends PageModel {
    layers: GrantedLayer[] = [];
    grantTypes = GrantTypeEnum;

    private constructor() {
        super(AppMenuRouteEnum.administration_layergrants, 0, readableUUID(LayersGrantsVM.name));
    }

    static async newAsync(): Promise<LayersGrantsVM> {
        const result = new LayersGrantsVM();
        await result.loadLayers();
        return result;
    }

    async loadLayers(): Promise<void> {
        const s = Container.get(DyntService);
        const datas = await s.downloadTable<FloorModelView>(ViewsNames.FloorModelView, undefined, FloorModelTable.flMoCategoryId, FloorModelCategoryEnum.Equipment.toString());
        this.layers = datas.map(x=> new GrantedLayer(x)).sort((a, b) => a.layer.flMoDisplayName.localeCompare(b.layer.flMoDisplayName));
    }

    async grantChange(e: CheckboxChangeEvent, layer: FloorModelView, grantType: GrantTypeEnum): Promise<void> {
        if (e.checked) {
            const s = Container.get(FloorModelService);
            const newGrantId = await s.createLayerGrant(layer.flMoId, grantType);
            toastSuccess("L'habilitation a été créée 🔒", "Info");
            if (grantType === GrantTypeEnum.Read) {
                layer.flMoReadGrantId = newGrantId;
            } else {
                layer.flMoUpdateGrantId = newGrantId;
            }
        } else {
            const s = Container.get(GrantService);
            if (grantType === GrantTypeEnum.Read) {
                await s.deleteGrant(layer.flMoReadGrantId!);
                layer.flMoReadGrantId = null;
            } else {
                await s.deleteGrant(layer.flMoUpdateGrantId!);
                layer.flMoUpdateGrantId = null;
            }
            toastSuccess("L'habilitation a été suprimée ❌", "Info");
        }
    }
}