import { DynTableVM } from "src/app/components-lib/dyn-grid/model/dyn-table-vm";
import { DynTableVMBuilder } from "src/app/components-lib/dyn-grid/model/dyn-table-vm-builder";
import { DynviewSet } from "src/app/core/model/data-model/sets/dyn-view-set";
import { DirectoryService } from "src/app/core/services/backend-services/directory-service";
import Container from "typedi";

export class MoveListBuilder {
    static async getPeopleList(taskId: number): Promise<DynTableVM> {
        const s = Container.get(DirectoryService);
        const datas = await s.getPeopleMoveList(taskId);
        return DynTableVMBuilder.getVM(datas, "", null);
    }

    static async getEquipmentList(taskId: number): Promise<DynTableVM> {
        const datas = new DynviewSet({});
        return DynTableVMBuilder.getVM(datas, "", null);
    }
}