import { NestedTreeControl } from "@angular/cdk/tree";
import { MatTreeNestedDataSource } from "@angular/material/tree";
import { GrantTypeEnum } from "src/app/core/model/data-model/enums/grant-type-enum";
import { InventoryService } from "src/app/core/services/backend-services/inventory-service";
import Container from "typedi";
import { InventoryTreeNode } from "./inventory-tree-node";
import { InventoryTreeSet } from 'src/app/core/model/data-model/sets/inventory-tree-set';
import { MatDialog } from '@angular/material/dialog';
import { GrantService } from 'src/app/core/services/backend-services/grant-service';
import { FloorModelService } from 'src/app/core/services/backend-services/floor-model-service';
import { EventListener } from 'src/app/core/events/event-listener';
import { readableUUID } from 'src/app/core/events/event-listener-uuid';

export class InventoryTreeModel extends EventListener {
  static inventoryTreeNodeChangeEvent = "inventoryTreeNodeChangeEvent";

  treeControl = new NestedTreeControl<InventoryTreeNode>(node => node.children);
  dataSource = new MatTreeNestedDataSource<InventoryTreeNode>();
  
  selectedNode: InventoryTreeNode | undefined;
  selectedParentNode: InventoryTreeNode | undefined;

  inventoryTreeSet: InventoryTreeSet = new InventoryTreeSet(null);
  dialog: MatDialog | undefined;
  
  private constructor() {
    super(readableUUID(InventoryTreeModel.name));
  }

  static async newAsync(): Promise<InventoryTreeModel> {
    const result = new InventoryTreeModel();
    await result.loadTree();
    return result;
  }

  async loadTree(): Promise<void> {
      const s = Container.get(InventoryService);
      this.inventoryTreeSet = await s.loadInventoryView();

      const treeData: InventoryTreeNode[] = [];
      this.inventoryTreeSet.floorModelView.sort((a, b) => a.flMoDisplayName.localeCompare(b.flMoDisplayName)).forEach(l => {
          const cat = this.inventoryTreeSet.equipmentCategories.find(x=> x.eqCaName === l.flMoName);
          if (cat != null) {
              const lNode = new InventoryTreeNode(0, cat.eqCaId, l.flMoDisplayName, l.flMoDisplayName, l.flMoId, true, l.flMoReadGrantId, l.flMoUpdateGrantId);
              treeData.push(lNode);
      
              const lCats = this.inventoryTreeSet.equipmentCategories.filter(x=> x.eqCaParentId === lNode.id);
              lNode.children = lCats.map(x=> {return new InventoryTreeNode(lNode.id, x.eqCaId, x.eqCaDisplayName, l.flMoDisplayName + " / " + x.eqCaDisplayName, null, true)});
      
              lNode.children.forEach(c => {
                  const lcCats = this.inventoryTreeSet.equipmentCategories.filter(x=> x.eqCaParentId === c.id);
                  c.children = lcCats.map(x=> {return  new InventoryTreeNode(c.id, x.eqCaId, x.eqCaDisplayName, c.concatName + " / " + x.eqCaDisplayName)});
          
                  // c.children.forEach(cc => {
                  //     const eqs = this.inventoryTreeSet.equipmentCatalog.filter(x=> x.eqCgCategoryId === cc.id);
                  //     const pvdIds = [... new Set(eqs.map(x=> x.eqCgProviderId))];
                  //     const pvds = this.inventoryTreeSet.providerCompanies.filter(x=> pvdIds.includes(x.prCoId));
                  //     if (pvds.length > 0) {
                  //         cc.children = pvds.map(x=> {return new InventoryTreeNode(cc.id, x.prCoId, x.prCoName, c.concatName + " / " + x.prCoName)});
                  //     } else {
                  //         // S'il n'y a aucune entrée pour la catégorie, on génère une entrée fictive
                  //         const fakeProvider = new InventoryTreeNode(cc.id, 0, "Aucun élément", c.concatName + " / Aucun élément");
                  //         cc.children = [fakeProvider];
                  //     }
                  // });
              });
          }
      });
  
      this.dataSource.data = treeData;
  }

  getParentNode(node: InventoryTreeNode): void {
      // Cette méthode n'est utile que pour les catégories de dernier niveau
      // il est donc inutile de chercher le parent pour les niveaux supérieurs
      if (node.children.length === 0) {
        let gotIt: boolean = false;
        for (const layer of this.dataSource.data) {
          for (const mediumCat of layer.children) {
            for (const lastCat of mediumCat.children) {
              for (const provider of lastCat.children) {
                if (provider.id === node.id && node.parentId === lastCat.id) {
                  this.selectedParentNode = lastCat;
                  gotIt = true;
                  break;
                }
                if (gotIt) break;
              }
              if (gotIt) break;
            }
            if (gotIt) break;
          }
          if (gotIt) break;
        }
      }
  }
  
  nodeMouseEnter(node:InventoryTreeNode):void {
    node.isHovering = true;
  }
    
  nodeMouseLeave(node:InventoryTreeNode):void {
    node.isHovering = false;
  }
  
  selectNode(branche: InventoryTreeNode[], node: InventoryTreeNode): void {
    branche.forEach(e => {
        e.selected = (e.id === node.id);
        this.selectNode(e.children, node);
    });
}

  //seletedNodeChange?: (node: InventoryTreeNode, parentNode: InventoryTreeNode | undefined) => void;
  nodeClick(e: MouseEvent, node:InventoryTreeNode):void {
      e.stopPropagation();

      this.selectedNode = node;
      this.getParentNode(node);
      this.selectNode(this.dataSource.data, this.selectedNode);
      // if (node.children != null) {
      //   await this.setReport(node);
      // } else {
      //   this.selectedProvider = this.providersList.find(x=> x.id === node.id);
      //   // Actualise la liste des fournisseurs à partir du parent node sélectionné au début de cette fonction
      //   this.updateProviderList();
      //   await this.getProviderCatalog(node.parentId, node.id);
      // }

      this.emitEventAsync(InventoryTreeModel.inventoryTreeNodeChangeEvent, node, this.selectedParentNode);
      // if (this.seletedNodeChange) {
      //     this.seletedNodeChange(node, this.selectedParentNode);
      // } else {
      //     LoggerService.error("InventoryTreeModel.selectedNodeChange n'était pas écouté");
      // }
  }
  
  // addCategoryButtonClick(node: InventoryTreeNode):void {
  //   if (!this.dialog) return;

  //   const dialogRef = this.dialog.open(CategoryEditorComponent, {
  //     maxWidth: '90%',
  //     minWidth: '30%',
  //     data: {
  //       parentCategoryId: node.id
  //     }
  //   });

  //           // Le .pipe(take(1)) s'assure que le traitement ne sera exécuté qu'une seule fois
  //           dialogRef.afterClosed().pipe(take(1)).subscribe(() => {
  //   });
  // }
  
  async toggleLayerGrant(node: InventoryTreeNode, grantType: GrantTypeEnum): Promise<void> {
    switch (grantType) {
      case GrantTypeEnum.Read:
        if (node.layerReadGrantId != null) {
          await this.deleteFloorModelGrant(node, grantType);
        } else {
          await this.createFloorModelGrant(node, grantType);
        }
        break;
      case GrantTypeEnum.Update:
        if (node.layerUpdateGrantId != null) {
          await this.deleteFloorModelGrant(node, grantType);
        } else {
          await this.createFloorModelGrant(node, grantType);
        }
        break;
    }
  }

  async createFloorModelGrant(node: InventoryTreeNode, grantType: number): Promise<void> {
    if (!node.layerId) return;

    const s = Container.get(FloorModelService);
    const result = await s.createLayerGrant(node.layerId, grantType);
    if (result != null) {
      if (grantType === GrantTypeEnum.Read) {
        node.layerReadGrantId = result;
      }else {
        node.layerUpdateGrantId = result;
      }

      //this.snackbarService.displayMessage("L'habilitation sur le calque a été créée");
      return;
    }

    //this.snackbarService.displayError("Une erreur s'est produite lors de la création de l'habilitation");
  }

  async deleteFloorModelGrant(node: InventoryTreeNode, grantType: number): Promise<void> {
    let grantId: number | null;
    if (grantType === GrantTypeEnum.Read) {
      grantId = node.layerReadGrantId;
    }else {
      grantId = node.layerUpdateGrantId;
    }

    if (!grantId) return;
    
    const s = Container.get(GrantService);
    const result = await s.deleteGrant(grantId);
    if (result != null) {
      if (grantType === GrantTypeEnum.Read) {
        node.layerReadGrantId = null;
      }else {
        node.layerUpdateGrantId = null;
      }

      //this.snackbarService.displayMessage("L'habilitation sur le calque a été supprimée");
      return;
    }

    //this.snackbarService.displayError("Une erreur s'est produite lors de la suppression de l'habilitation");
  }

  hasChild = (_: number, node: InventoryTreeNode) => !!node.children && node.children.length > 0;
}