import { AfterViewInit, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserAccountAdminVM } from '../../model/user-account-admin-vm';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { MVVMEventDestroyLifeCycle } from 'src/app/components-lib/shared-model/mvvm-events-destroy-lifecycle';
import { DynTableComponent } from 'src/app/components-lib/dyn-table/dyn-table.component';
import { logError } from 'src/app/core/services/logging-service';

@Component({
  selector: 'xc-user-account-admin',
  standalone: true,
  imports: [CommonModule, DynTableComponent, MatButtonModule, ProgressSpinnerModule],
  templateUrl: './user-account-admin.component.html',
  styleUrls: ['./user-account-admin.component.scss']
})
export class UserAccountAdminComponent extends MVVMEventDestroyLifeCycle implements AfterViewInit {
  @Input() override model: UserAccountAdminVM | undefined;
  
  constructor(protected dialog: MatDialog) {
    super();
  }

  ngAfterViewInit(): void {
    // Injecte le matDialog dans le modèle
    if (this.model) {
      this.model.dialog = this.dialog;
    } else {
      logError("UserAccountAdminComponent : model.dialog n'a pas pu être initialisé");
    }
  }

}
