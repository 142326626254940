import { BlueprintViewerToolbar } from './../../../content/blueprint-viewer-content-panel/itself/model/blueprint-viewer-toolbar';
import { Component, Input } from '@angular/core';
import { GripsOptionsSelectorComponent } from 'src/app/ui/shared/drawing/grips-options-selector/grips-options-selector.component';
import { DropdownModule } from 'primeng/dropdown';
import { ToolbarModule } from 'primeng/toolbar';
import { FormsModule } from '@angular/forms';
import { MVVMEventDestroyLifeCycle } from 'src/app/components-lib/shared-model/mvvm-events-destroy-lifecycle';
import { CommonModule } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { TooltipModule } from 'primeng/tooltip';
import { SplitButtonModule } from 'primeng/splitbutton';
import { BpSvgGroupComponent } from '../../../content/svg-entities/view/bp-svg-group/bp-svg-group.component';

@Component({
  selector: 'xc-blueprint-editor-toolbar',
  standalone: true,
  imports: [CommonModule, 
    FormsModule, 
    GripsOptionsSelectorComponent, 
    ToolbarModule, 
    DropdownModule, 
    ButtonModule, 
    TooltipModule,
    SplitButtonModule,
    BpSvgGroupComponent],
  templateUrl: './blueprint-editor-toolbar.component.html',
  styleUrl: './blueprint-editor-toolbar.component.scss'
})
export class BlueprintEditorToolbarComponent extends MVVMEventDestroyLifeCycle {
  @Input() override model: BlueprintViewerToolbar | undefined;
}
