import { FacilityJobTable } from "../../db-model/tables/facility-job-table";
import { DefinedModelBase } from "../defined-model-base";

export class FacilityJob extends DefinedModelBase {
    faJoId!: number;
    faJoName!: string;

    constructor(dtoData: any, tableDef: any[] = []) {
        super(dtoData, [FacilityJobTable].concat(tableDef));
    }
}