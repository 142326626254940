<div id="dialogContainer">
  <h3 mat-dialog-title class="header">
    Création d'un nouveau compte utilisateur
  </h3>
  <mat-dialog-content class="mat-typography">
    <form [formGroup]="addAppUserFormGroup">
      <mat-form-field class="full-width" appearance="outline">
        <mat-label>Collaborateur</mat-label>
        <input
          type="text"
          matInput
          placeholder="Commencez à saisir le nom"
          [matAutocomplete]="auto"
          formControlName="directoryCtrl"
          (keyup)="data.onSearchChange($event)"
        />
        <mat-autocomplete
          #auto="matAutocomplete"
          requireSelection
          [displayWith]="data.displayFn"
        >
          @for (option of data.filteredDirectory; track option) {
          <mat-option [value]="option">
            {{ option.diFirstName }} {{ option.diLastName }}
          </mat-option>
          }
        </mat-autocomplete>
      </mat-form-field>
      <mat-form-field appearance="outline" class="full-width">
        <mat-label>Rôle</mat-label>
        <mat-select formControlName="appRoleCtrl">
          @for (role of data.appRoles; track role) {
          <mat-option [value]="role">{{ role.apRoName }}</mat-option>
          }
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline" class="full-width">
        <mat-label>Périmètre</mat-label>
        <mat-select formControlName="perimeterCtrl">
          @for (perimeter of data.perimeters; track perimeter) {
          <mat-option [value]="perimeter">{{ perimeter.peName }}</mat-option>
          }
        </mat-select>
      </mat-form-field>
      <mat-form-field class="full-width" appearance="outline">
        <mat-label>Email</mat-label>
        <input matInput formControlName="emailCtrl" autocomplete="off" />
      </mat-form-field>

      <mat-checkbox class="checkbox-margin" formControlName="companyAdminCtrl"
        >Administrateur</mat-checkbox
      >
    </form>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button
      class="create-command"
      mat-button
      color="primary"
      [disabled]="!addAppUserFormGroup.valid"
      (click)="submitForm()"
    >
      Ok
    </button>
    <button mat-button color="warn" (click)="cancel()">Annuler</button>
  </mat-dialog-actions>
</div>
