import { Building } from "src/app/core/model/data-model/tables/building";
import { BuildingLayoutView } from "src/app/core/model/data-model/views/building-layout-view";
import { RoomActivityStatusTypeView } from "src/app/core/model/data-model/views/room-activity-status-type-view";
import { RoomAttributionTypeView } from "src/app/core/model/data-model/views/room-attribution-type-view";
import { RoomLayoutTypeStatusView } from "src/app/core/model/data-model/views/room-layout-type-status-view";
import { RoomLayoutTypeView } from "src/app/core/model/data-model/views/room-layout-type-view";
import { BuildingTable } from "src/app/core/model/db-model/tables/building-table";
import { BuildingLayoutDbView } from "src/app/core/model/db-model/views/building-layout-db-view";
import { RoomActivityStatusTypeDbView } from "src/app/core/model/db-model/views/room-activity-status-type-db-view";
import { RoomAttributionTypeDbView } from "src/app/core/model/db-model/views/room-attribution-type-db-view";
import { RoomLayoutTypeDbView } from "src/app/core/model/db-model/views/room-layout-type-db-view";
import { RoomLayoutTypeStatusDbView } from "src/app/core/model/db-model/views/room-layout-type-status-db-view";

export class LayoutTypeTreeDataDTO {
    roomLayoutTypesView: RoomLayoutTypeView[] = [];
    roomActivityStatusTypes: RoomActivityStatusTypeView[] = [];
    roomAttributionTypes: RoomAttributionTypeView[] = [];
    buildings: Building[] = [];
    buildingsLayoutView: BuildingLayoutView[] = [];
    layoutTypeStatusView: RoomLayoutTypeStatusView[] = [];

    constructor(dtoData: any) {
        if (dtoData) {
            dtoData[RoomLayoutTypeDbView.databaseTableName].forEach((e: any) => {
                this.roomLayoutTypesView.push(new RoomLayoutTypeView(e));
            });
            dtoData[RoomActivityStatusTypeDbView.databaseTableName].forEach((e: any) => {
                this.roomActivityStatusTypes.push(new RoomActivityStatusTypeView(e));
            });
            dtoData[RoomAttributionTypeDbView.databaseTableName].forEach((e: any) => {
                this.roomAttributionTypes.push(new RoomAttributionTypeView(e));
            });
            dtoData[BuildingTable.databaseTableName].forEach((e: any) => {
                this.buildings.push(new Building(e));
            });
            dtoData[BuildingLayoutDbView.databaseTableName].forEach((e: any) => {
                this.buildingsLayoutView.push(new BuildingLayoutView(e));
            });
            dtoData[RoomLayoutTypeStatusDbView.databaseTableName].forEach((e: any) => {
                this.layoutTypeStatusView.push(new RoomLayoutTypeStatusView(e));
            });
        }
    }
}