import { RealEstateEquipmentProviderDbView } from "../../db-model/views/real-estate-equipment-provider-db-view";
import { DefinedModelBase } from "../defined-model-base";

export class RealEstateEquipmentProviderView extends DefinedModelBase {
    eqCgProviderId!: number;
    prCoName!: string;
    prCoColor!: string;
    reEsEqPrViEquipmentValue!: number;
    reEsEqPrViEquipmentCount!: number;

    constructor(dtoData: any) {
        super(dtoData, [RealEstateEquipmentProviderDbView]);
    }
}