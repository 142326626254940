import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from 'src/app/core/material.module';
import { LayersTreeModel } from '../../model/layers-tree-model';
import { MVVMEventDestroyLifeCycle } from 'src/app/components-lib/shared-model/mvvm-events-destroy-lifecycle';

@Component({
  standalone: true,
  imports: [CommonModule, MaterialModule],
  selector: 'xc-layers-form',
  templateUrl: './layers-form.component.html',
  styleUrls: ['./layers-form.component.scss']
})
export class LayersFormComponent extends MVVMEventDestroyLifeCycle {
  @Input() override model: LayersTreeModel | undefined;
}
