@if (model && model.selectedTheme) {
<div id="header">
  @if (model.selectedTheme != null && model.selectedTheme.themeView.diThId !== model.displayThemeEnum.None) {
  <div>
    <div id="show-all">
      <button
        class="toolbarIcon"
        id="show-all-button"
        mat-icon-button
        matTooltip="Tout afficher"
        aria-label="Tout afficher"
        [disabled]="!model.hasHiddenItems()"
        (click)="model.onShowAllButtonClick()"
      >
        <mat-icon class="material-icons-outlined">visibility</mat-icon>
      </button>
    </div>
  </div>
  }
</div>
} @if (model && model.selectedTheme) {
<mat-list>
  @for (item of model.displayableCaptions(); track item) {
  <mat-list-item>
    <div class="caption-row">
      <!-- <div class="color-pin" 
                [style.background]="item.pattern == null ? item.color : 'repeating-linear-gradient(45deg, ' + item.pattern.firstColor + ' 0px, ' + item.pattern.secondColor + ' 4px)'">
            </div> -->
      <div>
        <button
          class="themeIcon themeColorIcon"
          [class.disable]="item.hidden"
          mat-icon-button
          matTooltip="{{ item.hidden ? 'Afficher' : 'Isoler' }}"
          (click)="model.onThemeItemDisplayButtonClick(item)"
        >
          <mat-icon
            class="material-icons"
            [style.background]="
              item.pattern == null
                ? item.color
                : 'repeating-linear-gradient(45deg, ' +
                  item.pattern.firstColor +
                  ' 0px, ' +
                  item.pattern.secondColor +
                  ' 4px)'
            "
          >
            square</mat-icon
          >
        </button>
        <button
          class="themeIcon"
          [disabled]="item.hidden"
          mat-icon-button
          matTooltip="Masquer"
          aria-label="Masquer"
          (click)="model.onThemeItemHideButtonClick(item)"
        >
          <mat-icon class="material-icons-outlined">visibility_off</mat-icon>
        </button>
      </div>
      <div [class.disable]="item.hidden" class="caption-labels">
        <span class="caption-main-label">{{ item.mainLabel }}</span>
        <span class="caption-sub-label">{{ item.subLabel }}</span>
      </div>
    </div>
  </mat-list-item>
  }
</mat-list>
}
