import { TablesNamesEnum } from './../tables-names-enum';
export const SimulationPhotoTable = {
    databaseTableName: TablesNamesEnum.SimulationPhoto,
    siPhId: "SiPh_Id",
    siPhSimulation: "SiPh_Simulation",
    siPhPhotoDate: "SiPh_PhotoDate",
    siPhIsActive: "SiPh_IsActive",
    siPhName: "SiPh_Name",
    siPhDescription: "SiPh_Description",
    siPhSourceId: "SiPh_SourceId"
}