import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { FormsModule } from '@angular/forms';
import { EquipmentMoveListComponent } from '../equipment-move-list/equipment-move-list.component';
import { MoveListsContainerVM } from '../move-lists-container-vm';
import { PeopleMoveListComponent } from '../people-move-list/people-move-list.component';

@Component({
  selector: 'xc-move-lists-container',
  standalone: true,
  imports: [CommonModule, 
    MatFormFieldModule, 
    MatSelectModule, 
    MatRadioModule, 
    FormsModule,
    PeopleMoveListComponent,
    EquipmentMoveListComponent],
  templateUrl: './move-lists-container.component.html',
  styleUrl: './move-lists-container.component.scss'
})
export class MoveListsContainerComponent {
  @Input() model: MoveListsContainerVM | undefined;
}
