import { DxfLayer } from "src/app/core/model/data-model/tables/dxf-layer";
import { HtmlConstants } from "src/app/core/model/html-model/html-constants.model";
import { DxfSvgEntity } from "src/app/ui/pages/graphic-works/blueprint/model/svg/dxf-svg-entity";
import { getDxfEntities } from "src/app/ui/pages/graphic-works/blueprint/model/svg/dxf-svg-entity-builder";

export class DxfLayerDTO extends DxfLayer {
    entities: DxfSvgEntity [] = [];
    //display: string;
    current: boolean = false;

    constructor(dtoData: any) {
        super(dtoData);
        this.entities = getDxfEntities(dtoData["entities"]);
        this.lock(this.dxLaLocked);
        this.setColor(this.dxLaColor);
        //this.display = this.dxLaHidden ? "none" : "block";
    }

    lock(lock: boolean): void {
        this.dxLaLocked = lock;
        const cursor = lock ? HtmlConstants.styleCursorInherit : HtmlConstants.styleCursorPointer;
        this.entities.forEach(e => {
            e.cursor = cursor;
            e.locked = lock;
        });
    }

    switchLock(): void {
        this.lock(!this.dxLaLocked);
    }

    setVisibility(hidden: boolean): void {
        this.dxLaHidden = hidden
        this.entities.forEach(e => {
            e.visible = !hidden;
        });
    }

    switchVisibility(): void {
        this.setVisibility(!this.dxLaHidden);
    }

    setColor(color: string): void {
        this.entities.forEach(e => {
            e.stroke = color;
        });
    }

    remove(entities: DxfSvgEntity[]): void {
        const tmp: DxfSvgEntity[] = [];
        this.entities.forEach(le => {
            const de = entities.find(x=> x.entityId === le.entityId);
            if (!de) {
                tmp.push(le);
            }
        });
        this.entities = tmp;
    }

    mouseEnter(): void {
        this.setColor("blue");
    }

    mouseLeave(): void {
        this.setColor(this.dxLaColor);
    }
}