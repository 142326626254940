@if (model) {
    <p-selectButton 
        [options]="model.options" 
        [(ngModel)]="model.selectedOption" 
        (onOptionClick)="model.onGripOptionClick($event)">
        <ng-template let-item pTemplate>
            <img class="icon" [class.switch-button]="item.value === 0" alt="dropdown icon" [src]="'/assets/icons/' + item.icon + '.svg'" [pTooltip]="item.label" tooltipPosition="bottom">
        </ng-template>
    </p-selectButton>
}
