import { Point } from "src/app/core/model/geometry-model/point.model";
import { SvgEntityTypesEnum } from "src/app/core/model/svg-model/svg-entity-type-enum";
import { SelectableSvgCircle } from "../../svg/selectable-svg-circle";
import { InteractionCommand } from "./interaction-command";
import { CircleGizmo } from "../../gizmo/model/circle-gizmo";
import { EntityInteraction } from "./entity-interaction";

export class CircleInteraction extends EntityInteraction<SelectableSvgCircle, CircleGizmo> {
    // entityType: SvgEntityTypesEnum = SvgEntityTypesEnum.circle;
    // selectionSet: SelectableSvgCircle[] = [];
    // selectionSetCopy: SelectableSvgCircle[] = [];
    // selectionInteraction: SelectionInteraction | undefined;
    // currentCommand: InteractionCommand;
    // updateGizmo: CircleGizmo;

    constructor(command: InteractionCommand, gizmo: CircleGizmo) {
        super(SvgEntityTypesEnum.circle, command, gizmo);
        // this.currentCommand = command;
        // this.updateGizmo = gizmo;
        // this.selectionSet = gizmo.entities;
    }
    
    override async mouseDown(e: MouseEvent, hitPoint: Point): Promise<boolean> {
        throw new Error("Method not implemented.");
    }
    
    override async mouseMove(e: MouseEvent, hitPoint: Point): Promise<void> {
        if (this.currentCommand.isEntityTranslationCommand() && this.currentCommand.initialSvgMousePosition) {
            const delta = hitPoint.minus(this.currentCommand.initialSvgMousePosition);
            this.updateGizmo.translateCircles(delta);
            return;
        }

        if (this.currentCommand.isCircleRadiusChangeCommand() && this.currentCommand.initialSvgMousePosition) {
            const delta = hitPoint.minus(this.currentCommand.initialSvgMousePosition);
            this.updateGizmo.changeRadius(delta);
            return;
        }
    }
    
    override async mouseUp(e: MouseEvent, hitPoint: Point | undefined): Promise<void> {
        if (this.currentCommand.isEntityTranslationCommand()) {
            if (hitPoint && this.currentCommand.gripClicked) {
                const delta = hitPoint.minus(this.currentCommand.gripClicked.point);
                this.updateGizmo.translateCircles(delta);
            }
            this.updateGizmo.endUpdate();       
            this.updateGizmo.clearCurrent();   
            return;         
        }

        if (this.currentCommand.isCircleRadiusChangeCommand() && this.currentCommand.initialSvgMousePosition) {
            if (hitPoint && this.currentCommand.gripClicked) {
                const delta = hitPoint.minus(this.currentCommand.gripClicked.point);
                this.updateGizmo.changeRadius(delta);
            }
            this.updateGizmo.endUpdate();       
            this.updateGizmo.clearCurrent();   
            return;         
        }
    }
    
    override keyDown(e: KeyboardEvent): Promise<void> {
        throw new Error("Method not implemented.");
    }

}