import { InventoryDefaultPageComponent } from './../inventory-default-page/inventory-default-page.component';
import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InventoryTabPagerVM } from '../../model/inventory-tab-pager-vm';
import { MatTabsModule } from '@angular/material/tabs';
import { FormsModule } from '@angular/forms';
import { InventoryStateComponent } from '../inventory-state/inventory-state.component';
import { InventoryReferentialComponent } from '../inventory-referential/inventory-referential.component';
import { InventoryDocumentsComponent } from '../inventory-documents/inventory-documents.component';
import { InventoryMapComponent } from '../inventory-map/inventory-map.component';

@Component({
  selector: 'xc-inventory-tab-pager',
  standalone: true,
  imports: [CommonModule, 
    MatTabsModule, 
    FormsModule, 
    InventoryStateComponent, 
    InventoryMapComponent, 
    InventoryReferentialComponent, 
    InventoryDocumentsComponent, 
    InventoryDefaultPageComponent],
  templateUrl: './inventory-tab-pager.component.html',
  styleUrls: ['./inventory-tab-pager.component.scss']
})
export class InventoryTabPagerComponent {
  @Input() model: InventoryTabPagerVM | undefined;
}
