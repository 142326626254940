import { FloorLayoutDbView } from '../../db-model/views/floor-layout-db-view';
import { DefinedModelBase } from '../defined-model-base';

export class FloorLayoutView extends DefinedModelBase {
    buSiteId!: number;
    flBuildingId!: number;
    flId!: number;
    flDaTaskId!: number;
    roLaTyId!: number;
    roLaTyCode!: string;
    roLaTyName!: string;
    roLaTyColor!: string;
    roLaTyIsNUA!: boolean;
    roLaTyParentId!: number;
    flLaViArea!: number;
    flLaViRoundedArea!: number;
    flLaViLayoutTypeTree!: string;

    constructor(dtoData: any) {
        super(dtoData, [FloorLayoutDbView]);
    }
}