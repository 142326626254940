import { Point } from "src/app/core/model/geometry-model/point.model";
import { Segment } from "src/app/core/model/geometry-model/segment.model";

export class IntersectedSegment {
    layerId: number | undefined;
    taskId: number | undefined;
    floorDataId: number | undefined;
    segment: Segment;
    intersectedSegment: Segment | undefined;
    // TODO : coder un objet Intersection pour porter ces datas
    intersections: {segment: Segment, point: Point}[];

    constructor(segment: Segment, intersections: {segment: Segment, point: Point}[]) {
        this.segment = segment;
        this.intersections = intersections;
    }

    setLayerSource(layerId: number, taskId: number): void {
        this.layerId = layerId;
        this.taskId = taskId;
    }

    nearest(p: Point): Point {
        return Point.getNearest(this.segment.points(), p);
    }

    static getMainPoints(intersectedSegments: IntersectedSegment[], withMidPoint: boolean): Point[] {
        const result: Point[] = [];

        intersectedSegments.forEach(s => {
            result.push(s.segment.startPoint);
            if (withMidPoint) {
                result.push(s.segment.midPoint());
            }
            result.push(s.segment.endPoint);
      });

        return result;
    }

    static getNearest(intersectedSegments: IntersectedSegment[], p: Point): IntersectedSegment {
        let minDistanceIndex = 0;
        let minDistance = 1000.0;
        for (let i = 0; i < intersectedSegments.length; i++) {
            const is = intersectedSegments[i];
            
            var pointDistance = p.distanceTo(Point.getNearest(is.intersections.map(x=> x.point), p));
            if (pointDistance < minDistance)
            {
                minDistance = pointDistance;
                minDistanceIndex = i;
            }
        }

        return intersectedSegments[minDistanceIndex];
    }

    static setLayerSource(intersectedSegments: IntersectedSegment[], layerId: number, taskId: number): void {
        intersectedSegments.forEach(s => {
            s.setLayerSource(layerId, taskId);
        });
    }
}