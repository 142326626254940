import { ContinentTable } from "../../db-model/tables/continent-table";
import { DefinedModelBase } from "../defined-model-base";

export class Continent extends DefinedModelBase {
    coId!: number;
    coIsoCode!: string;
    coName!: string;
    coDisplayNameId!: number;

    constructor(dtoData: any, tableDef: any[] = []) {
        super(dtoData, [ContinentTable].concat(tableDef));
    }
}