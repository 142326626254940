import { EquipmentCatalogTable } from '../tables/equipment-catalog-table';
import { EquipmentItemTable } from '../tables/equipment-item-table';
import { EquipmentPlanningTable } from '../tables/equipment-planning-table';
import { EquipmentPurchaseTable } from '../tables/equipment-purchase-table';
import { EquipmentStorageAreaTable } from '../tables/equipment-storage-area-table';
import { TaskTable } from '../tables/task-table';
import { ViewsNames } from '../views-names-enum';
export const EquipmentPlanningTaskDbView = {
    ...[EquipmentPlanningTable],
    databaseTableName: ViewsNames.EquipmentPlanningTaskView,
    taId: TaskTable.taId,
    taName: TaskTable.taName,
    eqPuPurchaseRef: EquipmentPurchaseTable.eqPuPurchaseRef,
    eqStArName: EquipmentStorageAreaTable.eqStArName,
    eqPlTaViLocation: "EqPlTaVi_Location",
    eqItCatalogId: EquipmentItemTable.eqItCatalogId,
    eqCgFloorCatalogId: EquipmentCatalogTable.eqCgFloorCatalogId
}
