import { Component, Input } from '@angular/core';
import { BpSvgCountBadge } from '../../model/bp-svg-count-badge';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'g[xc-bp-svg-count-badge]',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './bp-svg-count-badge.component.svg',
  styleUrl: './bp-svg-count-badge.component.scss'
})
export class BpSvgCountBadgeComponent {
  @Input() model: BpSvgCountBadge | undefined;
}
