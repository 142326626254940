import { MaterialModule } from 'src/app/core/material.module';
import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectableBusTreeVM } from '../../model/selectable-bus-tree-vm';

@Component({
  selector: 'xc-selectable-bu-tree',
  standalone: true,
  imports: [CommonModule, MaterialModule],
  templateUrl: './selectable-bu-tree.component.html',
  styleUrls: ['./selectable-bu-tree.component.scss']
})
export class SelectableBuTreeComponent {
  @Input() model: SelectableBusTreeVM | undefined;
}
