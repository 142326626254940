import { SvgBsFloorDataItem } from './svg-bs-floor-data-item';
import { stackFloor } from "./stack-floor";

export class StackChartDataVM {
    title: string = "";
    subTitle: string = "";
    labelsFontSize: number = 3;
    data: any;
    floors: stackFloor[] = [];
    floorHeight: number = 20;
    viewBox: string = "";

    constructor(title: string, subTitle: string, fontSize: number = 3) {
        this.title = title;
        this.subTitle = subTitle;
        this.labelsFontSize = fontSize;
    }

    arrange(): void {
        this.floors.forEach(f => {
            f.scaleX = 1;
        });
    }

    isolate(id: number, show: boolean): void {
        this.floors.forEach(f => {
            f.graphics?.data.isolate(id, show);
        });
    }

    hide(id: number): void {
        this.floors.forEach(f => {
            f.graphics?.data.hide(id);
        });
    }

    reportItemMouseover(e: MouseEvent, d: SvgBsFloorDataItem): void {

    }
}