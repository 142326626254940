import { SvgPathDTO } from './svg-path-dto';
import { SvgEntityTypesEnum } from './../../../model/svg-model/svg-entity-type-enum';
import { SvgEntityDTO } from './svg-entity-dto';

export class SvgEntityDTOBuilder {
    static getDTOSvgEntities(dtoData: any[]): SvgEntityDTO[] {
        const result: SvgEntityDTO[] = [];

        if (dtoData && Array.isArray(dtoData)) {
            dtoData.forEach(e => {
                switch (e["entityType"]) {
                    case SvgEntityTypesEnum.path:
                        result.push(new SvgPathDTO(e));
                        break;
                
                    default:
                        break;
                }
            });
        }

        return result;
    }
}