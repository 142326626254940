import { Component, Input } from '@angular/core';
import { AddressesAdminVM } from '../../model/addresses-admin-vm';
import { MVVMEventDestroyLifeCycle } from 'src/app/components-lib/shared-model/mvvm-events-destroy-lifecycle';
import { DynTableComponent } from 'src/app/components-lib/dyn-table/dyn-table.component';

@Component({
  selector: 'xc-addresses-admin',
  standalone: true,
  imports: [DynTableComponent],
  templateUrl: './addresses-admin.component.html',
  styleUrl: './addresses-admin.component.scss'
})
export class AddressesAdminComponent extends MVVMEventDestroyLifeCycle {
  @Input() override model: AddressesAdminVM | undefined;
}
