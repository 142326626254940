import { BpSvgGroup } from "../../bp-svg-core-model/bp-svg-group";
import { BpSvgLine } from "../../bp-svg-core-model/bp-svg-line";
import { BlueprintSvgEntityTypeEnum } from "./blueprint-svg-entity-type-enum";
import { SvgEntityTypesEnum } from "src/app/core/model/svg-model/svg-entity-type-enum";

export class BpSvgLeaderLine extends BpSvgGroup {
    leaderlineBase: BpSvgLine | undefined;
    leaderlineConnector: BpSvgLine | undefined;
    hidden: boolean = true;

    constructor(leaderlineBase: BpSvgLine | undefined, leaderlineConnector: BpSvgLine | undefined) {
        super({entityType: SvgEntityTypesEnum.g}, BlueprintSvgEntityTypeEnum.leaderLine);

        this.leaderlineBase = leaderlineBase;
        this.leaderlineConnector = leaderlineConnector;

        if (leaderlineBase) {
            this.entities.push(leaderlineBase);
        }

        if (leaderlineConnector) {
            this.entities.push(leaderlineConnector);
        }
    }
}
