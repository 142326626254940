import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutTypeContentVM } from '../../model/layout-type-content-vm';
import { DonutChartWrapperComponent } from 'src/app/ui/shared/charts/donut/view/donut-chart-wrapper/donut-chart-wrapper.component';
import { MatTabsModule } from '@angular/material/tabs';
import { FormsModule } from '@angular/forms';
import { MVVMEventDestroyLifeCycle } from 'src/app/components-lib/shared-model/mvvm-events-destroy-lifecycle';

@Component({
  selector: 'xc-layout-type-content',
  standalone: true,
  imports: [CommonModule, MatTabsModule, FormsModule, DonutChartWrapperComponent],
  templateUrl: './layout-type-content.component.html',
  styleUrls: ['./layout-type-content.component.scss']
})
export class LayoutTypeContentComponent extends MVVMEventDestroyLifeCycle {
  @Input() override model: LayoutTypeContentVM | undefined;
}
