import { ApiControllers, ApiEndpoints } from './../api-endpoints';
import { ApiService } from './../api-service';
import { Service } from 'typedi';
import { AppUser } from '../../model/data-model/tables/app-user';
import { TablesNamesEnum } from '../../model/db-model/tables-names-enum';
import { AppUserTable } from '../../model/db-model/tables/app-user-table';

@Service({ global: true })  
export class AppUserService extends ApiService {
    private deleteEndpoint(userId: number): string { return `${ApiControllers.appUsers}/${userId}` };
    private createEndpoint: string = `${ApiControllers.appUsers}`;
    private meEndpoint: string = `${ApiControllers.appUsers}/me`;

    constructor() {
        super();
    }

    async getMe(): Promise<AppUser | null> {
        return await this.getSingleAsync<AppUser>(TablesNamesEnum.AppUser, this.endPoint(this.meEndpoint));
    }

    async deleteUserAccount(userId: number): Promise<string | null> {
        const result = await this.deleteAsync<string>(this.endPoint(this.deleteEndpoint(userId)));
        if (result) {
            // ATTENTION : c'est une anomalie que le nombre de lignes supprimées soit retourné dans le champs id
            // il faut changer ça côté backend processing
            return result.message[0];
        }
        return null;
    }

    async createUserAccount(data: {DirectoryId: number, RoleId: number, PerimeterId: number | undefined, Email: string, IsCompanyAdmin: boolean, Caller: string}): Promise<string | null> {
        // const dto = {
        //     "DirectoryId": data.directoryId,
        //     "RoleId": data.roleId,
        //     "PerimeterId": data.perimeterId ? data.perimeterId : null,
        //     "Email": data.email,
        //     "IsCompanyAdmin": data.isAdmin
        // }
        const result = await this.postAsync(this.endPoint(this.createEndpoint), data);
        if (result) {
            return result.message as string;
        }
        return null;
    }

    async saveCurrentPage(userId: number, route: string): Promise<void> {
        const dto: any = {
            "TableName": AppUserTable.databaseTableName,
            [AppUserTable.apUsId]: userId,
            [AppUserTable.apUsLastRoute]: route
        }
        await this.patchAsync<boolean>(this.endPoint(ApiControllers.dynT), dto, undefined, undefined, '');
    }
}