import { SvgPhotoMarkerVM } from "./svg-photo-marker-vm";
import { SimulationPhoto } from "src/app/core/model/data-model/tables/simulation-photo";
import { logError } from "src/app/core/services/logging-service";

export class SvgPhotoMarkerGizmoVM {
    marker!: SvgPhotoMarkerVM;
    visible: boolean = false;
    fill: string = "#165b9a05";

    show(marker: SvgPhotoMarkerVM, visible: boolean): void {
        this.marker = marker;
        this.visible = visible;
    }

    lockPhotoRequested?: (photo: SimulationPhoto) => void;
    lockPhotoCommandButtonClick(): void {
        if (this.lockPhotoRequested) {
            this.lockPhotoRequested(this.marker.photo);
        } else {
            logError("SvgPhotoMarkerGizmoVM.lockPhotoRequested n'est pas écouté");
        }
    }

    addPhotoRequested?: (marker: SvgPhotoMarkerVM) => void;
    AddPhotoCommandButtonClick(): void {
        if (this.addPhotoRequested) {
            this.addPhotoRequested(this.marker);
        } else {
            logError("SvgPhotoMarkerGizmoVM.addPhotoRequested n'est pas écouté");
        }
    }
    
    deletePhotoRequested?: (marker: SvgPhotoMarkerVM) => void;
    deletePhotoCommandButtonClick(): void {
        if (this.deletePhotoRequested) {
            this.deletePhotoRequested(this.marker);
        } else {
            logError("SvgPhotoMarkerGizmoVM.deletePhotoRequested n'est pas écouté");
        }
    }

    createVariantRequested?: (marker: SvgPhotoMarkerVM) => void;
    createVariantCommandButtonClick(): void {
        if (this.createVariantRequested) {
            this.createVariantRequested(this.marker);
        } else {
            logError("SvgPhotoMarkerGizmoVM.createVariantRequested n'est pas écouté");
        }
    }
}