import { TaskValidationTable } from './../../db-model/tables/task-validation-table';
import { DefinedModelBase } from '../defined-model-base';

export class TaskValidation extends DefinedModelBase {
    taVaId!: number;
    taVaTaskId!: number;
    taVaUserId!: number;
    taVaIsApproved!: boolean;
    taVaTimeStamp!: Date;
    taVaComment!: string | null;

    constructor(dtoData: any, tableDef: any[] = []) {
        super(dtoData, [TaskValidationTable].concat(tableDef));
    }
}