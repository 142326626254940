import { DoorStyle } from './../../../../shared-model/door-style';
import { BpSvgRoomLabel } from '../../../../svg-entities/model/bp-svg-room-label';
import { BlueprintService } from 'src/app/core/services/backend-services/blueprint-service';
import { Container } from 'typedi';
import { SvgDOM } from './svg-dom';
import { InteractionCommand } from './interaction-command';
import { SelectionInteraction } from './selection-interaction';
import { FloorModelEnum } from 'src/app/core/model/data-model/enums/floor-model-enum';
import { LayerInteraction } from './layer-interaction';
import { EditableLayer } from './editable-layer';
import { SelectedLayerEntities } from './selected-layer-entities';
import { WorkplaceInteraction } from './workplace-interaction';
import { HtmlConstants } from 'src/app/core/model/html-model/html-constants.model';
import { EquipmentsSelectionSet } from './equipments-selection-set';
import { RoomInteraction } from './room-interaction';
import { FloorBlueprint } from '../floor-blueprint';
import { FloorWallStyle } from 'src/app/core/model/data-model/tables/floor-wall-style';
import { WallInteraction } from './wall-interaction';
import { DoorInteraction } from './door-interaction';
import { EquipmentInteraction } from './equipment-interaction';
import { PeopleInteraction } from './people-interaction';
import { MeasurementInteraction } from './measurement-interaction';
import { BpSvgEntity } from '../../../../bp-svg-core-model/bp-svg-entity';
import { BpSvgGroup } from '../../../../bp-svg-core-model/bp-svg-group';
import { BpSvgUse } from '../../../../bp-svg-core-model/bp-svg-use';
import { Point } from 'src/app/core/model/geometry-model/point.model';
import { FloorDataStateEnum } from 'src/app/core/model/data-model/enums/floor-data-state-enum';
import { LabelingFormModel } from '../../../../blueprint-viewer-side-panel/subitems/labeling/model/labeling-form-model';
import { FloorModelCategoryEnum } from 'src/app/core/model/data-model/enums/floor-model-category-enum';
import { AttributeObserver } from 'src/app/components-lib/attribute-observer';
import { PanzoomController } from 'src/app/components-lib/svg/panzoom-controller';
import { EventListener } from 'src/app/core/events/event-listener';
import { BpRoomService } from '../../services/bp-room-service';
import { SimilarRooms } from '../similar-room/similar-rooms';
import { SimilarRoom } from '../similar-room/similar-room';
import { BpSvgBoundingBox } from '../../../../bp-svg-core-model/bp-svg-bounding-box';
import { BpSvgRoom } from '../../../../svg-entities/model/bp-svg-room';
import { SvgPathService } from 'src/app/core/model/svg-model/svg-path-service';
import { Segment } from 'src/app/core/model/geometry-model/segment.model';
import { BpEquipmentService } from 'src/app/core/services/backend-services/bp-equipment-service';
import { GripsOptions } from 'src/app/ui/shared/drawing/grips-options';
import { FloorBlueprintEventsEnum } from '../../../../../container/model/floor-blueprint-events-enum';
import { logError } from 'src/app/core/services/logging-service';

export class UserInteraction extends EventListener {
    blueprint!: FloorBlueprint;
    panzoomController: PanzoomController | undefined;
    currentCommand: InteractionCommand = new InteractionCommand();
    selectionInteraction!: SelectionInteraction;
    currentInteraction: LayerInteraction | undefined;
    editableLayers: EditableLayer[] = [];
    viewboxObserver: AttributeObserver | undefined;
    gripsOptions: GripsOptions;

    showLabelInput: boolean = false;
    labelInputPos: Point | undefined;
    labelInputValue: number | undefined;

    similarRooms: SimilarRooms | null = null;
    similarTolerance: number = 0.1;
    similarCurrentRoomId: number | undefined;

    kd = this.keyDown.bind(this);

    constructor() {
        super("35d84587-569f-4bd3-b7e5-72cf7a16fd21");
        // this.blueprint = new FloorBlueprint();
        // this.panzoomController = new PanzoomController();
        // // Branche le blueprint listener sur le zoom event
        // this.panzoomController.zoomChange = (scale: number) => {
        //     this.blueprint.updateStrokeWidth(scale);
        //     this.endInput();
        // };
        // // Branche le blueprint listener sur le viewboxChanged event
        // this.panzoomController.viewboxChange = (viewbox: string) => this.blueprint.viewbox = viewbox;
        // this.selectionInteraction = new SelectionInteraction(this.currentCommand, this.blueprint);
        // this.listenToUserLayerItemsSelection();
        // this.listenToUserLayerItemsSelectionError();
        this.initializeListeners();
        this.gripsOptions = new GripsOptions();
    }

    initializeListeners(): void {
        this.addEventListener(LabelingFormModel.roomInsertRequest, (code: string) => {
            this.initializeRoomInsertion(code);
        });
        this.addEventListener(LabelingFormModel.workplaceRenumberRequest, (labelingForm: LabelingFormModel) => {
            this.initializeWorkplaceRenumber(labelingForm);
        });
        this.addEventListener(LabelingFormModel.workplaceInsertRequest, (labelingForm: LabelingFormModel) => {
            this.initializeWorkplaceInsertion(labelingForm);
        });
        this.addEventListener(LabelingFormModel.measurementInsertRequest, () => {
            this.initializeMeasurementInsertion();
        });
        this.addEventListener(FloorBlueprintEventsEnum.wallInsertRequested, (item: FloorWallStyle) => {
            this.initializeWallInsertion(item);
        });
        this.addEventListener(FloorBlueprintEventsEnum.doorInserRequested, (item: DoorStyle) => {
            this.initializeDoorInsertion(item);
        });
        this.addEventListener(GripsOptions.selectedGripOptionsChangeEvent, (gripOptions: number[]) => {
            this.currentCommand.selectedGripOptions = gripOptions;
        });
        this.addEventListener(FloorBlueprintEventsEnum.planningTaskRoomExtensionRequested, () => {
            this.currentCommand.set(InteractionCommand.updateTaskRoomExtensionCommand);
            this.setLayerInteraction(FloorModelEnum.RoomLabels);
            const i = this.currentInteraction;
            if (i) {
                (i as RoomInteraction).beguinTaskRoomExtension();
            }
        })
    }

    initializeViewboxObserver(): void {
        this.viewboxObserver = new AttributeObserver(document.getElementById("svgImage"), "viewBox");
        this.viewboxObserver.attributeChanged = () => {
        }
    }

    initializePanZoom(): void {
        this.panzoomController = new PanzoomController();
        // Branche le blueprint listener sur le zoom event
        this.panzoomController.zoomChange = (scale: number) => {
            this.blueprint.updateStrokeWidth(scale);
            this.endInput();
        };
        // Branche le blueprint listener sur le viewboxChanged event
        this.panzoomController.viewboxChange = (viewbox: string) => this.blueprint.viewbox = viewbox;

        this.panzoomController?.initialize("floorblueprint");
        SvgDOM.initialize("floorblueprint");
        document.addEventListener('keydown', this.kd, true);
        this.panzoomController?.resetOriginalViewbox();
    }

    async setBlueprint(floorId: number): Promise<FloorBlueprint> {
        if (!this.blueprint) {
            this.blueprint = new FloorBlueprint();
            this.selectionInteraction = new SelectionInteraction(this.currentCommand, this.blueprint);
        }
        this.listenToUserLayerItemsSelection();
        this.listenToUserLayerItemsSelectionError();
        
        this.selectionInteraction.selectedEntities.clear();
        this.currentCommand.clear();
        this.currentInteraction = undefined;

        await this.blueprint.loadFloorBlueprint(floorId);
        await this.setEditableLayers();

        //this.abortCommand();
        return this.blueprint;
    }

    //editableLayersChanged?: (editableLayers: EditableLayer[]) => void;
    async setEditableLayers(): Promise<void> {
        const tmp: EditableLayer[] = [];

        // S'il n'y a pas d'étude chargée, les calques éditables sont (paramètre OutsideProjectUpdatableFloorModelIds) :
        // - étiquettes des surfaces (paramètre AllowRoomLabelUpdateOutsideProjectGrantId)
        // - étiquettes de position de travail (paramètres AllowWPUpdateOutsideProjectGrantId et AllowWPDeleteInsertOutsideProjectGrantId)
        // - allocation des positions de travail (paramètre AllowWPAllocationOutsideProjectGrantId)
        // - marqueurs de documentation (TODO)
        // - marqueurs d'allocation d'équipement (paramètre AllowEquipmentAllocationOutsideProjectGrantId)

        // - NOTA : changer la typologie ou l'allocation des surfaces (paramètres AllowRoomTypeUpdateOutsideProjectGrantId et AllowRoomAllocationOutsideProjectGrantId) 
        // est différent de l'édition du calque des surfaces qui correspond au cloisonnement et donc ne figure pas dans la liste des calque éditable

        // S'il y a une ou plusieurs études chargées, les calques éditables sont ceux de la dernière étude, croisé avec les droits du user
        let processedTaskId = 0;
        const s = Container.get(BlueprintService);
        if (this.blueprint.updateTasks.length === 0) {
            // Il n'y a pas d'étude chargée
            processedTaskId = this.blueprint.taskId;
            const grantedOutsideProjectEditableLayers = await s.loadGrantedOutsideProjectEditableLayers();
            grantedOutsideProjectEditableLayers.forEach(l => {
                if (l.flMoId !== FloorModelEnum.Rooms) {
                    // Le calque des surfaces peut être éditable en tant que tel mais il n'est jamais
                    // sélectionnable directement sur le plan mais seulement par son étiquette
                    tmp.push(new EditableLayer(l.flMoId, l.flMoDisplayName, l.flMoCategoryId === FloorModelCategoryEnum.Equipment))
                }
            });
        } else {
            // Il y a au moins une étude chargée
            processedTaskId = this.blueprint.topMostTaskId();
            const taskGrantedEditableLayers = await s.loadTaskGrantedEditableLayers(processedTaskId);
            taskGrantedEditableLayers.forEach(l => {
                if (l.flMoId !== FloorModelEnum.Rooms) {
                    // Le calque des surfaces peut être éditable en tant que tel mais il n'est jamais
                    // sélectionnable directement sur le plan mais seulement par son étiquette
                    tmp.push(new EditableLayer(l.flMoId, l.flMoDisplayName, l.flMoCategoryId === FloorModelCategoryEnum.Equipment))
                }
            });
        }

        // TODO : pour l'UI, on a besoin que certains calques soient nommés différemment
        // Etiquettes de position de travail --> Positions de travail
        // Etiquettes de personne -- > Occupants
        // Etiquettes de locaux --> Locaux
        // Il faudra trouver comment stocker les traductions supplémentaires et en disposer
        const worplaceLayer = tmp.find(x => x.layerId === FloorModelEnum.WorkplaceLabels);
        if (worplaceLayer) worplaceLayer.layerName = "Positions de travail";
        const peopleLayer = tmp.find(x => x.layerId === FloorModelEnum.PeopleLabels);
        if (peopleLayer) peopleLayer.layerName = "Occupants";
        const roomLayer = tmp.find(x => x.layerId === FloorModelEnum.RoomLabels);
        if (roomLayer) roomLayer.layerName = "Locaux";

        // Actualise le tableau des calques éditables
        this.editableLayers = tmp;
        this.selectionInteraction.editableLayers = tmp;

        // Change le curseur sur les items de ces calques pour que le user sache ce qu'il peut sélectionner
        this.blueprint.layersController.updateCursor(this.editableLayers, processedTaskId, true);

        // Passe le tableau au modèle d'insertion d'équipements
        //this.equipmentList.setEditableLayers(tmp.filter(x=> x.isEquipmentLayer).sort((a, b) => a.layerName.localeCompare(b.layerName)));
        await this.emitEventAsync(FloorBlueprintEventsEnum.editableLayersChanged, this.editableLayers);
        // if (this.editableLayersChanged) {
        //     this.editableLayersChanged(this.editableLayers);
        // } else {
        //     LoggerService.error("UserInteraction.editableLayersChanged n'est pas écouté");
        // }
    }

    isEditableLayer(layerId: number): boolean {
        return this.editableLayers.find(x => x.layerId === layerId) != null;
    }

    isElementEditable(layerId: number, taskId: number): boolean {
        return this.isEditableLayer(layerId) && this.blueprint.topMostTaskId() === taskId;
    }

    async setSelection(itemAtClientPoint: BpSvgEntity, shiftKey: boolean): Promise<void> {
        const layerId = itemAtClientPoint.floorModelId;
        const taskId = itemAtClientPoint.taskId;

        // S'il y a déjà un ou plusieurs éléments sélectionnés, il y a un modèle d'interaction chargé
        // et il suffit de vérifier que le nouvel élément est sur le même calque
        if (this.currentInteraction && this.currentInteraction.layerId === layerId) {
            this.currentInteraction.selectionSet.addOrRemove([itemAtClientPoint], !shiftKey);
            if (this.currentInteraction.selectionSet.items.length === 0) {
                // S'il ne reste plus rien dans la sélection, on décommissionne l'interaction courante
                this.currentInteraction = undefined;
            }
            return;
        }

        // Si on arrive ici c'est qu'il n'y a pas encore d'interaction en cours
        // ou que l'élément cliqué n'est pas sur le même calque que l'interaction en cours

        // On vérifie que l'item cliqué est cliquable au sens où le curseur indique au user qu'il peut le sélectionner
        // et que l'item cliqué est sur un calque éditable par le user
        if (itemAtClientPoint.cursor === HtmlConstants.styleCursorPointer) {
        //if (itemAtClientPoint.cursor === HtmlConstants.styleCursorPointer && this.isElementEditable(layerId, taskId)) {
            if (this.currentInteraction && this.currentInteraction.layerId !== layerId) {
                // S'il y a déjà une interaction en cours et que l'item cliqué n'est pas sur le même calque, on sort
                // mais avant on regarde s'il s'agit d'une configuration de demande de sélection étendue dans le même local
                // Cette configuration se produit lorsque le user a sélectionné un item d'équipement unique (ou plusieurs de même defId)
                // et clique ensuite sur l'étiquette du local pour signifier qu'il veut sélectionner tous les équipements identiques
                // dans le local cliqué
                // On tient compte du DataState. Si la sélection actuelle est "Deleted" on ne peut ajouter que des éléments "Deleted" et inversement

                const layer = this.editableLayers.find(x => x.layerId === this.currentInteraction!.layerId);
                if (layer && layer.isEquipmentLayer && (this.currentInteraction.selectionSet as EquipmentsSelectionSet).isHomogeneous() && layerId === FloorModelEnum.RoomLabels) {
                    const equipmentDefId = (this.currentInteraction.selectionSet.items[0] as BpSvgUse).def!.id;
                    const roomId = (itemAtClientPoint as BpSvgRoomLabel).svgRoom!.floorDataId;
                    const sameEquipmentsInRoom = this.blueprint.layersController.equipments(taskId).filter(x => x.parentId === roomId && x.def!.id === equipmentDefId);
                    this.currentInteraction.selectionSet.addOrRemove(sameEquipmentsInRoom, true);
                }

                return;
            }
            this.setLayerInteraction(layerId);
            await this.currentInteraction!.activate([itemAtClientPoint], false);
        }
    }

    currentInteractionChanged?: (i: LayerInteraction | undefined) => void;
    listenToUserLayerItemsSelection(): void {
        this.selectionInteraction.userLayerSelect = async (selectedLayerEntities: SelectedLayerEntities | null) => {
            // Le user a sélectionné des éléments sur un calque, ou parce que les éléments sélectionnés étaient tous sur le même calque,
            // ou en cliquant sur un des boutons de sélection parmi les calques des éléments sélectionnés
            // On active le modèle d'interaction correspondant
            if (selectedLayerEntities != null) {
                if (this.currentInteraction === undefined) {
                    this.setLayerInteraction(selectedLayerEntities.layerId);
                }

                if (this.currentInteraction) {
                    await this.currentInteraction.activate(selectedLayerEntities.entities, selectedLayerEntities.toRemove);
                    if (this.currentInteraction.selectionSet.items.length === 0) {
                        // S'il ne reste plus rien dans la sélection, on décommissionne l'interaction courante
                        this.currentInteraction = undefined;
                    }
                }

                this.raiseCurentInteractionChanged();
            }
        }
    }

    raiseCurentInteractionChanged(): void {
        if (this.currentInteractionChanged) {
            this.currentInteractionChanged(this.currentInteraction);
        } else {
            logError("UserInteraction.currentInteractionChanged n'est pas écouté");
        }
    }

    listenToUserLayerItemsSelectionError(): void {
        this.selectionInteraction.userLayerSelectionError = () => {
            //this.pocService.sendSnackBarMessage("Il n'est pas possible de mélanger des éléments supprimés avec d'autres dans une même sélection");
        }
    }

    directTaskCreated?: (taskId: number) => void;
    setLayerInteraction(layerId: number): void {
        switch (layerId) {
            case FloorModelEnum.WorkplaceLabels:
                const wi = new WorkplaceInteraction(this.currentCommand, this.blueprint, this.editableLayers);
                //wi.setLabelingForm();
                this.currentInteraction = wi;
                break;
            case FloorModelEnum.PeopleLabels:
                this.currentInteraction = new PeopleInteraction(this.currentCommand, this.blueprint, this.editableLayers);
                break;
            case FloorModelEnum.RoomLabels:
                const ri = new RoomInteraction(this.currentCommand, this.blueprint, this.editableLayers);
                ri.similarRoomsRequested = async (roomId: number | undefined) => {
                    this.similarCurrentRoomId = roomId;
                    await this.searchSimilarRooms();
                }
                //ri.setLabellingForm();
                this.currentInteraction = ri;
                break;
            case FloorModelEnum.Walls:
                this.currentInteraction = new WallInteraction(this.currentCommand, this.blueprint, this.editableLayers);
                break;
            case FloorModelEnum.Doors:
                this.currentInteraction = new DoorInteraction(this.currentCommand, this.blueprint, this.editableLayers);
                (this.currentInteraction as DoorInteraction).updateGizmo.doorWidthLabelUpdateRequested = (clientPoint: Point, width: number) => {
                    this.showLabelInput = true;
                    this.labelInputPos = clientPoint;
                    this.labelInputValue = width;
                }
                break;
            case FloorModelEnum.Measurement:
                this.currentInteraction = new MeasurementInteraction(this.currentCommand, this.blueprint, this.editableLayers);
                break;
            default:
                this.currentInteraction = new EquipmentInteraction(layerId, this.currentCommand, this.blueprint, this.editableLayers);
                break;
        }

        this.listenToThemeRefreshRequests();
        this.listenToSelectionDeletion();

        this.raiseCurentInteractionChanged();
    }

    initializeRoomInsertion(code: string): void {
        // Réception d'une demande d'insertion de local
        // N'est valable que s'il y a une étude chargée et que si cette étude permet le cloisonnement
        if (!this.blueprint.hasPlanningTask) return;
        if (!this.isEditableLayer(FloorModelEnum.RoomLabels)) return;

        // Le code ne doit pas être déjà utilisé
        const taskIds = this.blueprint.topAndBottomTaskIds();
        const rooms = this.blueprint.layersController.topBottomDistinctSvgRooms(taskIds[0], taskIds[1]);
        const existing = rooms.find(x => x.roomSet?.roomSet.dataSet.roCode === code && x.dataStateId !== FloorDataStateEnum.Deleted);
        if (existing) {
            alert("Ce numéro de local est déjà utilisé");
            return;
        }

        // Désactive le curseur de sélection sur les items de l'étude
        const topMostTaskId = this.blueprint.topMostTaskId();
        this.blueprint.layersController.updateCursor(this.editableLayers, topMostTaskId, false);

        this.abortCommand();
        this.setLayerInteraction(FloorModelEnum.RoomLabels);
        this.currentInteraction?.beginInsert(code);
    }

    initializeWallInsertion(item: FloorWallStyle): void {
        // Réception d'une demande d'insertion de cloison
        // N'est valable que s'il y a une étude chargée et que si cette étude permet le cloisonnement
        if (!this.blueprint.hasPlanningTask) return;
        if (!this.isEditableLayer(FloorModelEnum.Walls)) return;

        // Désactive le curseur de sélection sur les items de l'étude
        const topMostTaskId = this.blueprint.topMostTaskId();
        this.blueprint.layersController.updateCursor(this.editableLayers, topMostTaskId, false);

        // Rend les surfaces transparentes pour laisser apparaître la trame de cloisonnement
        //this.floorTaskData.floorBlueprint.roomsLayer(topMostTaskId).showRooms(false);

        // Affiche la trame de cloisonnement
        this.blueprint.layersController.partitioningFrameLayer()?.show(true);

        this.abortCommand();
        this.setLayerInteraction(FloorModelEnum.Walls);
        //this.userInteraction.currentInteraction = new UserWallInteraction(this.pocService, this.userInteraction.currentCommand, this.userInteraction.floorBlueprint, this.userInteraction.editableLayers);
        this.currentInteraction?.beginInsert(item);
    }

    initializeDoorInsertion(item: DoorStyle): void {
        // Réception d'une demande d'insertion de porte
        // N'est valable que s'il y a une étude chargée et que si cette étude permet le cloisonnement
        if (!this.blueprint.hasPlanningTask) return;
        if (!this.isEditableLayer(FloorModelEnum.Doors)) return;

        // Désactive le curseur de sélection sur les items de l'étude
        const topMostTaskId = this.blueprint.topMostTaskId();
        this.blueprint.layersController.updateCursor(this.editableLayers, topMostTaskId, false);

        this.abortCommand();
        this.setLayerInteraction(FloorModelEnum.Doors);
        this.currentInteraction?.beginInsert(item);
    }

    initializeEquipmentInsertion(item: BpSvgGroup): void {
        // Réception d'une demande d'insertion d'équipement
        // N'est valable que s'il y a une étude chargée et que si cette étude le permet
        if (!this.blueprint.hasPlanningTask) return;
        if (!this.isEditableLayer(item.floorModelId)) return;

        // Désactive le curseur de sélection sur les items de l'étude
        const topMostTaskId = this.blueprint.topMostTaskId();
        this.blueprint.layersController.updateCursor(this.editableLayers, topMostTaskId, false);

        this.abortCommand();
        this.setLayerInteraction(item.floorModelId);
        this.currentInteraction?.beginInsert(item);
    }

    initializeWorkplaceInsertion(labelingForm: LabelingFormModel): void {
        // Réception d'une demande d'insertion de position de travail
        
        // Désactive le curseur de sélection sur les items de l'étude
        const topMostTaskId = this.blueprint.topMostTaskId();
        this.blueprint.layersController.updateCursor(this.editableLayers, topMostTaskId, false);
        // Active le curseur d'insertion sur les items de mobilier
        this.blueprint.layersController.setCursor(topMostTaskId, [FloorModelEnum.Furniture], "cell");
        // TODO : limiter au éléments de type table et sièges

        this.abortCommand();
        this.setLayerInteraction(FloorModelEnum.WorkplaceLabels);
        this.currentInteraction?.beginInsert(labelingForm);
    }

    initializeWorkplaceRenumber(labelingForm: LabelingFormModel): void {
        // Réception d'une demande d'insertion de position de travail
        
        // Désactive le curseur de sélection sur les items de l'étude
        const topMostTaskId = this.blueprint.topMostTaskId();
        this.blueprint.layersController.updateCursor(this.editableLayers, topMostTaskId, false);
        // Active le curseur d'insertion sur les items de mobilier
        this.blueprint.layersController.setCursor(topMostTaskId, [FloorModelEnum.Furniture], "cell");

        this.abortCommand();
        this.setLayerInteraction(FloorModelEnum.WorkplaceLabels);
        (this.currentInteraction as WorkplaceInteraction).beginRenumber(labelingForm);
    }

    initializeMeasurementInsertion(): void {
        // Réception d'une demande d'insertion de cote

        // Désactive le curseur de sélection sur les items de l'étude
        const topMostTaskId = this.blueprint.topMostTaskId();
        this.blueprint.layersController.updateCursor(this.editableLayers, topMostTaskId, false);

        this.abortCommand();
        this.setLayerInteraction(FloorModelEnum.Measurement);
        this.currentInteraction?.beginInsert({});
    }

    refreshThemeNeeded?: () => void;
    listenToThemeRefreshRequests(): void {
        if (this.currentInteraction) {
            this.currentInteraction.refreshThemeNeeded = () => {
                this.askForThemeRefresh();
            }
        } else {
            logError("UserInteraction.currentInteraction n'était pas défini");
        }
    }

    listenToSelectionDeletion(): void {
        if (this.currentInteraction) {
            this.currentInteraction.selectionDeleted = () => {
                this.currentInteraction = undefined;
                this.raiseCurentInteractionChanged();
                this.askForThemeRefresh();
            }
        } else {
            logError("UserInteraction.currentInteraction n'était pas défini");
        }
    }

    askForThemeRefresh(): void {
        if (this.refreshThemeNeeded) {
            this.refreshThemeNeeded();
        } else {
            logError("UserInteraction.refreshThemeNeeded n'était pas écouté");
        }
    }

    clearCommandSelection(): void {
        this.selectionInteraction.clear();
        this.currentCommand.clear();
        this.raiseCurentInteractionChanged();
    }

    abortCommand(): void {
        if (this.currentCommand.isContextMenuCommand()) {
            //this.closeContextMenu();
            return;
        }

        this.similarCurrentRoomId = undefined;
        this.similarRooms = null;
        this.removeSimilarRoomTempCopy();

        if (this.currentCommand.isUpdateTaskRoomExtensionCommand()) {
            //(this.currentInteraction as UserRoomInteraction).endTaskRoomExtension();
        }

        this.selectionInteraction.selectedEntities.clear();

        if (this.currentInteraction) {
            this.currentInteraction.abort();
            this.currentInteraction = undefined;
        }

        this.clearCommandSelection();
        this.endInput();
    }

    endInput(): void {
        this.showLabelInput = false;
        this.labelInputPos = undefined;
        this.labelInputValue = undefined;
    }

    async saveInput(): Promise<void> {
        if (this.currentInteraction instanceof DoorInteraction && this.labelInputValue) {
            await (this.currentInteraction as DoorInteraction).updateGizmo.saveInput(this.labelInputValue);
        }
    }

    async focusOut(): Promise<void> {
        this.endInput();
    }

    async inputKeyDown(e: KeyboardEvent): Promise<void> {
        if (e.code.toLowerCase() === "enter" || e.code.toLowerCase() === "numpadenter") {
            await this.saveInput();
            this.endInput();
        }
    }

    async searchSimilarRooms(): Promise<void> {
        if (!this.similarCurrentRoomId) return;

        const s = Container.get(BpRoomService);
        const result = await s.getSimilarRooms(this.similarCurrentRoomId, this.similarTolerance);
        if (result != null) {
            this.similarRooms = result;
        }
    }

    removeSimilarRoomTempCopy(): void {
        let l = this.blueprint.layersController.equipmentLayers(this.blueprint.topMostTaskId()).find(x=> x.id === FloorModelEnum.Furniture);
        if (l) {
            const tmp = l.data.filter(x=> x.floorDataId === 0);
            tmp.forEach(e => {
                l?.remove(0);
            });
        }
    }

    async onSimilarRoomSearchButtonClick(): Promise<void> {
        if (this.currentInteraction?.layerId === FloorModelEnum.RoomLabels) {
            await this.searchSimilarRooms();
        }
    }

    onSimilarRoomCloseButtonClick(): void {
        this.similarCurrentRoomId = undefined;
        this.similarRooms = null;
        this.removeSimilarRoomTempCopy();
    }

    // onSimilarRoomOrientationButtonClick(): void {
    //     this.similarRooms!.rotate = !this.similarRooms!.rotate;
    // }

    onSimilarRoomRotateButtonClick(room: SimilarRoom): void {
        room.rotate();
    }

    async onSimilarRoomGetButtonClick(room: SimilarRoom): Promise<void> {
        this.similarRooms!.validatingId = room.room.floorDataId;

        // récupère le calque de mobilier
        let l = this.blueprint.layersController.equipmentLayers(this.blueprint.topMostTaskId()).find(x=> x.id === FloorModelEnum.Furniture);
        if (!l) {
            // Cas où le calque de mobilier n'existe pas encore sur le plan
            this.blueprint.layersController.insertLayer(this.similarRooms!.layer);
            l = this.similarRooms!.layer;
        }

        // la bbox ajustée du local source
        const stBbox = this.similarRooms!.sourceTightBbox;
        // la bbox du local source
        const sBbox = BpSvgBoundingBox.fromPolygon(stBbox.bbox());
        const sc = sBbox.center();

        // la position initiale de chaque élément est calculée relativement au coin bas/gauche de la bbox transformée
        const oBbox = room.orientedBbox;
        const lb = oBbox.bottomLeft();
        const c = oBbox.center();

        // la position relative des deux bbox
        const relativeP = sBbox.bottomLeft().vector().minus(lb);

        const i = this.currentInteraction as RoomInteraction;
        const currentRoom = i.selectionSet.single<BpSvgRoomLabel>().svgRoom as BpSvgRoom;
        const currentP = SvgPathService.getPolygon(currentRoom.d!, true);

        room.furniture.forEach(f => {
            const fCopy = new BpSvgUse(JSON.parse(JSON.stringify(f)));
            fCopy.def = f.def;
            fCopy.floorDataId = 0;
            fCopy.parentId = this.similarCurrentRoomId!;
            fCopy.sourceId = f.floorDataId;

            fCopy.initialTransform = fCopy.transform?.clone();
            fCopy.translate(relativeP);
            fCopy.initialTransform = fCopy.transform!.clone();
            fCopy.rotate(sc, room.transform.rotationAngle);

            // Elimine les éléments dont le centre de la bbox n'est pas dans le local
            // pour ça on regarde si un segment qui va du centre de la bbox de l'élément au centre de la bbox du local
            // coupe le polygone de surface
            const fCopySeg = new Segment(fCopy.getBboxCenter()!, sc);
            if (currentP.getIntersects(fCopySeg).length === 0) {
                l!.insert([fCopy]);
            }
        });
    }

    async onSimilarRoomValidateButtonClick(room: SimilarRoom): Promise<void> {
        // valide la copie des éléments
        const i = this.currentInteraction as RoomInteraction;
        const taskId = i.selectionSet.single<BpSvgRoomLabel>().taskId;
        
        this.similarCurrentRoomId = undefined;
        this.similarRooms = null;

        let l = this.blueprint.layersController.equipmentLayers(this.blueprint.topMostTaskId()).find(x=> x.id === FloorModelEnum.Furniture);
        const entities = l!.data.filter(x=> x.floorDataId === 0) as BpSvgUse[];
        const values: {floorDataId: number, x: number, y: number, a: number}[] = [];
        entities.forEach(e => {
            const et = e.transform!;
            values.push({floorDataId: e.sourceId!, x: et.translate.x, y: et.translate.y, a: et.rotationAngle});
        });

        this.removeSimilarRoomTempCopy();

        const s = Container.get(BpEquipmentService);
        const result = await s.copySimilarEquipments(taskId, values);
        if (result != null) {
            // Insère les définitions si elle n'existent pas
            result.floorCatalog.forEach(def => {
                if (this.blueprint.definitions.find(x=> x.id === def.id) == null) {
                    this.blueprint.definitions.push(def);
                }
            });

            result.uses.forEach(u => {
                u.cursor = HtmlConstants.styleCursorPointer;
            
                u.def = result.floorCatalog.find(x=> x.id === u.floorCatalogId);
                u.equipmentPlanning = result.equipmentsPlanning.find(x=> x.eqPlFloorDataId === u.floorDataId);
                u.planningStateId = u.equipmentPlanning!.eqPlPlanningStateId;
                u.floorDataState = result.floorDataState;
                u.equipmentPlanningState = result.equipmentPlanningState;
            });

            l!.insert(result.uses);
            this.askForThemeRefresh();
        }
    }

    onSimilarRoomCancelButtonClick(room: SimilarRoom): void {
        // Retire les éléments copiés
        this.similarRooms!.validatingId = undefined;
        this.removeSimilarRoomTempCopy();
    }

    async mouseDown(e: MouseEvent): Promise<void> {
        // On ne traite que les clics du bouton gauche
        if (e.buttons !== 1) return;

        // Si le menu contextuel est affiché, on le ferme et on sort
        if (this.currentCommand.isContextMenuCommand()) {
            //this.closeContextMenu();
            return;
        }

        // Si on est déjà dans une commande de sélection rectangulaire, c'est que c'est actuellement le second MouseDown et il termine la commande
        if (this.currentCommand.isDrawingRectangularSelectionCommand()) {
            return;
        }

        const hitPoint = SvgDOM.getPointPosition(e.clientX, e.clientY);
        if (hitPoint == null) {
            logError("UserInteraction : impossible de récupérer les coordonnées du clic");
            return;
        }

        // On stocke la position initiale du clic pour pouvoir neutraliser dans le MouseMove le déplacement involontaire au démarrage
        // de la commande de sélection rectangulaire, ce mouvement se produisant avant le MouseUp
        this.currentCommand.initialSvgMousePosition = hitPoint;

        // S'il y a une interaction en cours, on lui passe l'event
        if (this.currentInteraction) {
            const result = await this.currentInteraction.mouseDown(e, hitPoint);
            // Si l'interaction courante a retourné true on sort
            if (result) return;
        }

        // Si on est en cours de copie d'allocations, on sort immédiatement
        // on ne veut pas sélectionner la surface cliquée
        if (this.currentCommand.isRoomAllocationCopyCommand()) {
            return;
        }

        // Si on clique sur un élément c'est qu'on veut le sélectionner
        // La commande est immédiate, on n'attend rien de MouseMove ni de MouseUp
        // Si l'élément est sur un calque éditable, le calque devient le calque courant en édition
        // Les surfaces ne sont pas directement sélectionnables mais seulement par leur étiquette
        // sinon, TODO, on affiche la fiche de renseignements ?
        const itemAtClientPoint = this.blueprint.layersController.getSvgEntityFromClientPoint(e.clientX, e.clientY);
        if (itemAtClientPoint != null && itemAtClientPoint.floorModelId !== FloorModelEnum.Rooms && itemAtClientPoint.taskId === this.blueprint.topMostTaskId()) {
            await this.setSelection(itemAtClientPoint, e.shiftKey);
            return;
        }

        // A ce stade on sait qu'on commence une sélection rectangulaire
        // On mémorise le point cliqué comme premier point du path de sélection
        this.currentCommand.set(InteractionCommand.drawingRectangularSelectionCommand);
        this.selectionInteraction.selectionPathPoints.push(hitPoint);

        // if (this.initialSvgMousePosition == null) {
        //     this.initialSvgMousePosition = hitPoint;
        // }
    }

    timer: any;
    async mouseMove(e: MouseEvent): Promise<void> {
        if (this.currentCommand.isNoneCommand()) {
            return;
        }

        const hitPoint = SvgDOM.getPointPosition(e.clientX, e.clientY);
        if (hitPoint == null) {
            logError("Erreur inattendue : impossible de récupérer les coordonnées du clic");
            return;
        }

        if (this.currentInteraction) {
            this.currentInteraction.mouseMove(e, hitPoint);
        }

        if (this.currentCommand.initialSvgMousePosition == null) {
            return;
        }

        if (this.currentCommand.isDrawingRectangularSelectionCommand()) {
            // On actualise le contour de sélection avec le point sous la souris, ainsi que la collection des éléments compris dans ce contour
            this.selectionInteraction.updateSelectionPathPoints(hitPoint, this.currentInteraction == null ? null : this.currentInteraction.layerId, e.shiftKey);
        }

    }

    async mouseUp(e: MouseEvent): Promise<void> {
        if (this.currentCommand.isNoneCommand()) {
            return;
        }

        const hitPoint = SvgDOM.getPointPosition(e.clientX, e.clientY);
        if (hitPoint == null) {
            logError("Erreur inattendue : impossible de récupérer les coordonnées du clic");
            return;
        }

        if (this.currentCommand.isDrawingRectangularSelectionCommand()) {
            this.selectionInteraction.gizmo.show(true);
            this.currentCommand.clear();
            this.selectionInteraction.selectionPathPoints.splice(0);
        }

        if (this.currentInteraction) {
            await this.currentInteraction.mouseUp(e, hitPoint);
        }

        if (this.currentCommand.isSelectingSingleItemCommand()) {
            // Fin de la commande de sélection d'un élément
            this.currentCommand.clear();
            return;
        }
    }

    switchGripTypeRequested?: () => void;
    async keyDown(e: KeyboardEvent) {
        switch (e.key.toLowerCase()) {
            case "a":
                // NOTA : on utilise la même touche pour changer le type d'accrochage et le type de poignée sur les équipements
                if (!this.currentCommand.isNoneCommand()) {
                    // On ne peut changer le type d'accrochage que si on est en commande
                    if (this.switchGripTypeRequested) {
                        this.switchGripTypeRequested();
                    } else {
                        logError("UserInteraction.switchGripTypeRequested n'est pas écouté");
                    }
                } 
                this.currentInteraction?.keyDown(e);
                break;
            case "w":
                this.panzoomController?.resetView();
                break;
            case "escape":
                if (this.showLabelInput) {
                    this.endInput();
                } else {
                    this.abortCommand();
                }
                break;
            case "delete":
                // Sur le plan courant il est possible de supprimer les positions de travail et les allocations de ces positions
                // ainsi que les allocations directes aux locaux
                // à condition que le user ait les droits nécessaires

                // Sur le plan d'étude ce sont les calques éditables et le rôle de space planner qui
                // autorisent la suppression

                if (this.currentInteraction) {
                    if (this.blueprint.hasPlanningTask() || this.currentInteraction.layerId === FloorModelEnum.WorkplaceLabels || this.currentInteraction.layerId === FloorModelEnum.PeopleLabels) {
                        await this.currentInteraction.deleteSelectedItems();
                    }
                }
                break;
            default:
                if (this.currentInteraction) {
                    await this.currentInteraction.keyDown(e);
                }
                break;
        }
    }
    
    override removeListeners(): void {
        this.blueprint.removeListeners();
    }
}