@if (model && model.task) {
    <div class="properties item-properties">
        <div class="prop-item">
            <div class="prop prop-key">Elément</div>
            <div class="prop prop-value">{{model.task.taskType.taTyViDisplayName}}</div>
        </div>
        <div class="prop-item">
            <div class="prop prop-key">Nom</div>
            <input id="taskNameInput" class="prop prop-value input-prop" 
                [class.inputValueChanged]="model.task.taName !== model.initialTaskName"
                type="text" 
                [(ngModel)]="model.task.taName" 
                (focusout)="model.taskNameFocusOut()" 
                (keydown)="model.taskNameKkeyDown($event)"/>
        </div>
        <!-- La modification du workflow n'est pas possible pour les modèles -->
        @if (!model.task.taIsTemplate) {
            <div class="prop-item">
                <div class="prop prop-key">Workflow</div>
                <mat-select id="workflow-select" class="prop prop-value status-prop" 
                    [style.--pincolor]="model.selectedWorkflow ? model.selectedWorkflow.taStColor : '#ffffff'"
                    [(ngModel)]="model.selectedWorkflow"
                    (ngModelChange)='model.selecteWorkflowChange($event)'
                    [compareWith]="model.selectedWorkflowCompare">
                    @for (wo of model.workflowOptions; track wo.taStId) {
                        <mat-option class="prop-option" [style.--pincolor]="wo.taStColor" [value]="wo">{{model.workflowActionName(wo.taStId)}}</mat-option>
                    }
                </mat-select>
            </div>
        }
    @switch (model.task.taTypeId) {
            @case (model.TaskTypeEnum.Project) {
                <div>
                </div>
            }
            @case (model.TaskTypeEnum.Task) {
                <div>
                    <div class="prop-item">
                        <div class="prop prop-key">Tâche</div>
                        <div class="prop prop-value">{{model.task.businessTypeView.taBuTyDisplayName}}</div>
                    </div>
                    @if (model.taskFacility) {

                        <div class="prop-item">
                            <div class="prop prop-key">Société</div>
                            <div class="prop prop-value">{{model.taskFacility.company.faCoName}}</div>
                        </div>
                        <div class="prop-item">
                            <div class="prop prop-key">Intervention</div>
                            <div class="prop prop-value">{{model.taskFacility.jobTask.faJoTaName}}</div>
                        </div>
                        <div class="prop-item">
                            <div class="prop prop-key">{{'Qté (' + model.taskFacility.unitOfWork.unOfWoCode + ')'}}</div>
                            <input id="unitOfWorkInput" class="prop prop-value input-prop"
                                [class.inputValueChanged]="model.facilityAmountHasChanged()"
                                type="number"
                                [(ngModel)]="model.facilityAmount"
                                (keydown)="model.unitOfWorkInputkeyDown($event)">
                        </div>
                    }
                    @if (model.task.taskFloor.taFlFloorId) {
                        <div class="prop-item">
                            <div class="prop prop-key">Etage</div>
                            <div class="prop prop-value">{{model.task.floor.flName}}</div>
                        </div>
                        <div class="prop-item">
                            <div class="prop prop-key">Couches</div>
                            <div class="prop prop-value">{{model.floorModelsNames}}</div>
                        </div>
                    }
                </div>
            }
            @case (model.TaskTypeEnum.Milestone) {
                <div>
                </div>
            }
        }
    </div>
}

