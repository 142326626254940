import { TabPageContent } from "src/app/components-lib/tab-page-container/model/tab-page-content";
import { MobilityProjectTreeNode } from "../../shared-model/mobility-project-tree-node";
import { readableUUID } from "src/app/core/events/event-listener-uuid";
import { MobilityProjectsGanttBrowserTabsEnum } from "./mobility-projects-gantt-browser-tabs-enum";
import { TaskView } from "src/app/core/model/data-model/views/task-view";
import { MobilityProjectsTreeBuilder } from "../../shared-model/mobility-projects-tree-builder";
import { TreeNode } from "primeng/api";
import { TaskTreeBuilder } from "../../../../move-work/main/model/task-tree-builder";
import { MobilityProjectsGanttTreeEventsEnum } from "./mobility-projects-gantt-tree-events-enum";
import { ScheduleEventsEnum } from "../../../nested/shared/model/schedule-events-enum";
import { TaskCreateResultDTO } from "src/app/core/services/backend-services/dto/task-create-result-dto";
import { MobilityProjectsGanttEditorEventsEnum } from './mobility-projects-gantt-editor-events-enum';
import { TaskStatusView } from 'src/app/core/model/data-model/views/task-status-view';
import { TaskTypeEnum } from 'src/app/core/model/data-model/enums/task-type-enum';
import { TaskStatusEnum } from 'src/app/core/model/data-model/enums/task-status-enum';
import { MobilityTemplateTreeNode } from '../../shared-model/mobility-template-tree-node';
import { toastWarn } from 'src/app/core/services/toast-service';

export class MobilityProjectsGanttTreeVM extends TabPageContent<MobilityProjectTreeNode[]> {
    nodes: MobilityProjectTreeNode[] = [];
    dateRange: [startDate: Date, duration: number] | undefined;
    selectedNode: any;
    displayTemplates: boolean = false;

    constructor() {
        super(readableUUID("MobilityProjectsGanttTreeVM"), MobilityProjectsGanttBrowserTabsEnum.projects);

        this.addEventListener(ScheduleEventsEnum.scheduleTaskSelectedByUser, (taskId: number) => {
            this.updateSelected(this.nodes, taskId);
            const node = this.getTaskNode(this.nodes, taskId);
            if (node && node.data.taTypeId === TaskTypeEnum.Project) {
                node.expanded = true;
                this.emitEventAsync(MobilityProjectsGanttTreeEventsEnum.projectExpanded, node);
            }
        });

        this.addEventListener(ScheduleEventsEnum.taskCreated, (result: TaskCreateResultDTO[]) => {
            // Le résultat de la création peut être soit une tâche
            // soit un projet et une tâche
            this.injectCreationResult(result);
        });

        this.addEventListener(MobilityProjectsGanttEditorEventsEnum.workflowUpdated, (updates: {item1: number, item2: number}[], taskStatus: TaskStatusView[]) => {
            this.propagateStatus(this.nodes, updates, taskStatus);
        });
    }

    propagateStatus(nodes: MobilityProjectTreeNode[], values: {item1: number, item2: number}[], taskStatus: TaskStatusView[]): void {
        nodes.forEach(n => {
            this.applyUpdatedStatus(n, values, taskStatus);

            if (n.data.taTypeId === TaskTypeEnum.Project) {
                this.propagateStatus(n.children, values, taskStatus);
            }
        });
    }

    applyUpdatedStatus(node: MobilityProjectTreeNode, values: {item1: number, item2: number}[], taskStatus: TaskStatusView[]): void {
        const t = node.data;
        const value = values.find(x=> x.item1 === t.taId);
        if (value) {
            const statusView = taskStatus.find(x=> x.taStId === value.item2);
            if (statusView) {
                t.statusView = statusView;
                t.taStatusId = value.item2;
            }
        }
    }
    
    setTree(projects: TaskView[]): void {
        this.dateRange = TaskTreeBuilder.getDateRange(projects);
        let nodeType = MobilityProjectTreeNode.name;
        if (this.displayTemplates) nodeType = MobilityTemplateTreeNode.name;
        this.nodes = MobilityProjectsTreeBuilder.loadProjectsTreeNodes(nodeType, projects);
        setTimeout(() => {
            this.emitEventAsync(MobilityProjectsGanttTreeEventsEnum.projectsLoaded, this.nodes, this.displayTemplates);
        }, 0);
    }

    private GetNode(t: TaskView): MobilityProjectTreeNode {
        if (t.taIsTemplate) {
            return new MobilityTemplateTreeNode(t);
        }
        return new MobilityProjectTreeNode(t);
    }

    injectCreationResult(result: TaskCreateResultDTO[]): void {
        // Le résultat de la création peut être soit une tâche
        // soit un projet et une tâche

        const single = result[0];
        // Le résultat est une tâche simple, il faut donc l'insérer dans son projet parent
        const newTaskParentId = single.task.taParentId;

        if (newTaskParentId == null) {
            // La première tâche retournée n'a pas de parent, il s'agit donc de la création d'un projet racine
            const p = result[0].task;
            const t = result[1].task
            const newProject = this.GetNode(p);
            newProject.children.push(this.GetNode(t));
            // Le nouveau noeud est ajouté à l'arborescence
            this.nodes.push(newProject);
            // Le nouveau projet est déroulé
            newProject.expanded = true;
            // Les projets sont retriés par ordre de date
            this.nodes = this.nodes.sort((a, b) => a.data.taStartDate.getTime() - b.data.taStartDate.getTime());
            // Le calendrier est notifié pour insertion du nouveau projet
            this.emitEventAsync(MobilityProjectsGanttTreeEventsEnum.addScheduleProjectRequested, newProject);
        } else {
            // La première tâche retournée a un parent, il s'agit donc de l'insertion dans un projet existant
            const parentProject = this.getTaskNode(this.nodes, newTaskParentId);
            if (!parentProject) {
                toastWarn("Impossible d'actualiser l'affichage, merci de rechargez la page");
                return;
            }

            // Le projet parent est nécessairement déroulé
            parentProject.expanded = true;

            const newTaskNode = this.GetNode(single.task);
            if (result.length === 2) {
                // Si le résultat contient deux éléments c'est qu'il s'agit de la création d'un sous-projet
                newTaskNode.children.push(this.GetNode(result[1].task));
                // Le sous-projet est déroulé
                newTaskNode.expanded = true;
            }

            // Le nouveau noeud est ajouté aux enfants
            parentProject.children.push(newTaskNode);
            // Les enfants sont retriés par ordre de date
            parentProject.children = parentProject.children.sort((a, b) => a.data.taStartDate.getTime() - b.data.taStartDate.getTime());
            // On recherche le projet racine, le parent pouvant être nesté
            let rootProject: MobilityProjectTreeNode| undefined = parentProject;
            if (parentProject.data.taParentId != null) {
                rootProject = this.getTaskNode(this.nodes, parentProject.data.taRootTaskId!);
            }
            if (!rootProject) {
                toastWarn("Impossible d'actualiser l'affichage, merci de rechargez la page");
                return;
            }
            // Le calendrier est notifié pour actualisation du projet racine
            this.emitEventAsync(MobilityProjectsGanttTreeEventsEnum.refreshProjectRequested, rootProject, parentProject);
        }

    }

    getTaskNode(nodes: MobilityProjectTreeNode[], taskId: number): MobilityProjectTreeNode | undefined {
        let result: MobilityProjectTreeNode | undefined;

        // Le noeud à été trouvé on ne continue pas
        if (result) return result;

        for (const n of nodes) {
            if (n.data.taId === taskId) {
                result = n;
                break;
            }
            if (n.children.length > 0 && !result) {
                result = this.getTaskNode(n.children, taskId);
            }
        }
        return result;
    }

    updateSelected(nodes: MobilityProjectTreeNode[], selectedKey?: string | number): void {
        nodes.forEach(n => {
            if (typeof selectedKey === "string") {
                n.selected = n.key === selectedKey;
            } else {
                n.selected = n.data.taId === selectedKey;
            }
            if (n.selected) {
                this.selectedNode = n;
                this.emitEventAsync(MobilityProjectsGanttTreeEventsEnum.taskSelectedByrocess, n.data);
            }
            this.updateSelected(n.children, selectedKey);
        });
    }

    isRealyPending(node: MobilityProjectTreeNode): boolean {
        if (node.data.taStatusId !== TaskStatusEnum.Pending) return false;
        let result = true;
        node.children.forEach(c => {
            result = this.isRealyPending(c);
        });
        return result;
    }

    unselect(): void {
        this.selectedNode = undefined;
        this.updateSelected(this.nodes, undefined);
        this.emitEventAsync(MobilityProjectsGanttTreeEventsEnum.taskSelectedByrocess, undefined);
    }

    remove(node: MobilityProjectTreeNode, nodesSet: MobilityProjectTreeNode[] = this.nodes): boolean {
        let result: boolean = false;

        // On commence par rechercher si le noeud à supprimer est dans le set initial
        const index = nodesSet.findIndex(x=> x.key === node.key);
        if (index > -1) {
            // Si c'est le cas on le retire et on sort
            nodesSet.splice(index, 1);
            return true;
        }

        // Le noeud à supprimer n'était pas dans le set initial
        // On recherche dans les enfants du set initial
        for (const n of nodesSet) {
            result = this.remove(node, n.children);
            if (result) break;
        }

        return result;
    }

    async nodeSelect(e: {originalEvent: Event, node: TreeNode}): Promise<void> {
        this.updateSelected(this.nodes, e.node.key);
        this.emitEventAsync(MobilityProjectsGanttTreeEventsEnum.taskSelectedByUser, e.node);
    }

    async nodeExpand(e: {originalEvent: Event, node: TreeNode}): Promise<void> {
        this.emitEventAsync(MobilityProjectsGanttTreeEventsEnum.projectExpanded, e.node);
    }

    async nodeCollapse(e: {originalEvent: Event, node: TreeNode}): Promise<void> {
        this.emitEventAsync(MobilityProjectsGanttTreeEventsEnum.projectCollapsed, e.node);
    }

    async onTemplatesSwitchChange(): Promise<void> {
        this.emitEventAsync(MobilityProjectsGanttTreeEventsEnum.templateSwitchChange, this.displayTemplates);
    }
}