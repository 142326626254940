import { NestedTreeControl } from "@angular/cdk/tree";
import { MatTreeNestedDataSource } from "@angular/material/tree";
import Container from "typedi";
import { LayoutTypeTreeNode } from "./layout-type-tree-node";
import { RoomLayoutTypeTable } from "src/app/core/model/db-model/tables/room-layout-type-table";
import { DyntService } from "src/app/core/services/backend-services/dynt-service";
import { LoggerService } from "src/app/core/services/logger.service";
import { RoomLayoutTypeDbView } from "src/app/core/model/db-model/views/room-layout-type-db-view";
import { RoomLayoutTypeView } from "src/app/core/model/data-model/views/room-layout-type-view";
import { ArrayUtils } from "src/app/core/model/static-functions/array-utils";
import { EventListener } from "src/app/core/events/event-listener";
import { readableUUID } from "src/app/core/events/event-listener-uuid";

export class LayoutTypesTreeModel extends EventListener {
    static LayoutTypeSelectedNodeChangeEvent = "LayoutTypeSelectedNodeChangeEvent";

    treeControl = new NestedTreeControl<LayoutTypeTreeNode>(node => node.children);
    dataSource = new MatTreeNestedDataSource<LayoutTypeTreeNode>();
    
    selectedNode: LayoutTypeTreeNode | undefined;

    ltHierarchy: RoomLayoutTypeView[] = [];

    private constructor() {
        super(readableUUID(LayoutTypesTreeModel.name));
    }

    static async newAsync(): Promise<LayoutTypesTreeModel> {
        const result = new LayoutTypesTreeModel();
        await result.loadTree();
        return result;
    }

    async loadTree(): Promise<void> {
        const s = Container.get(DyntService);
        this.ltHierarchy = await s.downloadTable<RoomLayoutTypeView>(RoomLayoutTypeDbView.databaseTableName);

        // Consolide les totaux des feuilles vers les branches
        const maxDepth = this.ltHierarchy.reduce((prev, current) => (prev.roLaTyDepth > current.roLaTyDepth) ? prev : current).roLaTyDepth;
        const leaves = this.ltHierarchy.filter(x=> x.roLaTyDepth === maxDepth);
        this.feedParentsTotals(leaves);
        
        const rootNode = this.getRootNode();
        rootNode.children = this.getNodeChildren(null)
        this.aggregateRootNode(rootNode);
        this.dataSource.data = [rootNode];
        this.treeControl.expand(rootNode);
    }

    feedParentsTotals(leaves: RoomLayoutTypeView[]) {
        const distinctParentIds = ArrayUtils.DistinctValueBy<RoomLayoutTypeView, number>(leaves, "roLaTyParentId");
        distinctParentIds.forEach(id => {
            if (id != null) {
                const parentLeaves = leaves.filter(x=> x.roLaTyParentId === id);
                const parentLeavesCount = ArrayUtils.sumBy(parentLeaves, "roLaTyViCount");
                const parentLeavesTotalArea = ArrayUtils.sumBy(parentLeaves, "roLaTyViTotalArea");
                const parentLeavesMinArea = Math.min(...parentLeaves.map(x=> x.roLaTyViMinArea));
                const parentLeavesMaxArea = Math.max(...parentLeaves.map(x=> x.roLaTyViMaxArea));
                const parentLeavesAvgArea = parentLeavesTotalArea / parentLeavesCount;
                const parent = this.ltHierarchy.find(x=> x.roLaTyId === id);
                if (parent) {
                    parent.roLaTyViTotalArea = parentLeavesTotalArea;
                    parent.roLaTyViMinArea = parentLeavesMinArea;
                    parent.roLaTyViMaxArea = parentLeavesMaxArea;
                    parent.roLaTyViAvgArea = parentLeavesAvgArea;
                    parent.roLaTyViCount = parentLeavesCount;
                }
            }
        });

        if (distinctParentIds.length > 0) {
            const parents = this.ltHierarchy.filter(x=> distinctParentIds.includes(x.roLaTyId));
            this.feedParentsTotals(parents);
        }
    }

    getRootNode(): LayoutTypeTreeNode {
        const fakeLt = new RoomLayoutTypeView({
            [RoomLayoutTypeTable.roLaTyId]: 0,
            [RoomLayoutTypeTable.roLaTyCode]: "ROOT",
            [RoomLayoutTypeTable.roLaTyName]: "Surfaces",
            [RoomLayoutTypeTable.roLaTyDepth]: "-1",
            [RoomLayoutTypeTable.roLaTyParentId]: null,
            [RoomLayoutTypeTable.roLaTyColor]: "#ffffff",
            [RoomLayoutTypeTable.roLaTyIsActive]: true,
            [RoomLayoutTypeTable.roLaTyScopeId]: 0,
            [RoomLayoutTypeTable.roLaTyIsNUA]: false,
            [RoomLayoutTypeDbView.roLaTyViTotalArea]: 0,
            [RoomLayoutTypeDbView.roLaTyViMaxArea]: 0,
            [RoomLayoutTypeDbView.roLaTyViMinArea]: 0,
            [RoomLayoutTypeDbView.roLaTyViAvgArea]: 0,
            [RoomLayoutTypeDbView.roLaTyViCount]: 0
        });
        return new LayoutTypeTreeNode(fakeLt);
    }

    aggregateRootNode(node: LayoutTypeTreeNode): void {
        const lts = node.children.map(x=> x.lt);
        node.lt.roLaTyViCount = ArrayUtils.sumBy(lts, "roLaTyViCount");
        node.lt.roLaTyViTotalArea = ArrayUtils.sumBy(lts, "roLaTyViTotalArea");
        node.lt.roLaTyViMinArea = Math.min(...lts.map(x=> x.roLaTyViMinArea));
        node.lt.roLaTyViMaxArea = Math.max(...lts.map(x=> x.roLaTyViMaxArea));
        node.lt.roLaTyViAvgArea = node.lt.roLaTyViTotalArea / node.lt.roLaTyViCount;
    }

    getNodeChildren(parentId: number | null): LayoutTypeTreeNode[] {
        const lts = this.ltHierarchy.filter(x=> x.roLaTyParentId === parentId);
        const temp: LayoutTypeTreeNode[] = [];
        lts.forEach(lt => {
            if (lt.roLaTyParentId == null) {
                // Cas où les types sont de premier niveau donc directement sous le root node
                lt.roLaTyParentId = 0;
            }
            const newNode = new LayoutTypeTreeNode(lt);
            const children = this.getNodeChildren(lt.roLaTyId);
            newNode.children = children;
            children.forEach(ch => {
                ch.children = this.getNodeChildren(ch.lt.roLaTyId);
            });
            temp.push(newNode);
        });
        return temp;
    }

    async selectRoot(): Promise<void> {
        this.selectedNode = this.dataSource.data[0];
        this.selectNode(this.dataSource.data, this.selectedNode);
        await this.emitEventAsync(LayoutTypesTreeModel.LayoutTypeSelectedNodeChangeEvent, this.selectedNode);

        //this.raiseSelectedNodeChange(this.dataSource.data[0]);
    }

    selectNode(branche: LayoutTypeTreeNode[], node: LayoutTypeTreeNode): void {
        branche.forEach(e => {
            e.selected = (e.lt.roLaTyId === node.lt.roLaTyId);
            this.selectNode(e.children, node);
        });
    }

    //seletedNodeChange?: (node:LayoutTypeTreeNode) => void;
    async nodeClick(e: MouseEvent, node:LayoutTypeTreeNode):Promise<void> {
        e.stopPropagation();

        this.selectedNode = node;
        this.selectNode(this.dataSource.data, node);
        await this.emitEventAsync(LayoutTypesTreeModel.LayoutTypeSelectedNodeChangeEvent, node);
       // this.raiseSelectedNodeChange(node);
    }

    // raiseSelectedNodeChange(node:LayoutTypeTreeNode): void {
    //     if (this.seletedNodeChange) {
    //         this.seletedNodeChange(node);
    //     } else {
    //         LoggerService.error("LayoutTypesTreeModel.selectedNodeChange n'était pas écouté");
    //     }
    // }

    hasChild = (_: number, node: LayoutTypeTreeNode) => !!node.children && node.children.length > 0;
}