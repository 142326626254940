@if (model) {
    <div #scheduleHScroller class="schedule-h-scroller" 
        [class.processing]="model.taskMovingProcess.processing || model.taskInsertingProcess.processing || model.taskRangeProcess.processing || model.taskLinkingProcess.processing"
        (mousedown)="model.mouseDown($event)"
        (mousemove)="model.mouseMove($event)"
        (mouseup)="model.mouseUp($event)">
        <div #scheduleContainer class="schedule-container" [style.--duration]="model.duration * model.currentUnitWidth">
            <div class="schedule-header" (wheel)="headerMouseWheel($event)">
                <xc-schedule-header [model]="model.header"/>
            </div>
            <div class="schedule-content" 
                (wheel)="contentMouseWheel($event)" 
                (click)="model.mouseClick($event)">
                <div>
                    @for (c of model.cols; track c) {
                        <div
                            [attr.epoch-number]="c.getTime() / 1000"
                            [class.schedule-today-col]="model.isToday(c)" 
                            class="day-col" 
                            [class.we]="[0, 6].includes(c.getDay())" 
                            [style.--unit-width]="model.currentUnitWidth">
                        </div>
                    }
                </div>
                <!-- Masquage du contenu lors du scroll vers le haut pour ne pas venir cacher le header -->
                <div class="scroll-mask">
                    <!-- 40 est la hauteur du header du calendrier. TODO : remplacer par une variable -->
                    <div class="schedule-tasks" [style.top.px]="model.tasksContainerTop - model.yScrollOffset - 40">
                        <ul>
                            @for (t of model.ganttRootProjects; track t) {
                                <li>
                                    <xc-gantt-root-project-container [model]="t" [class.inserting]="model.taskInsertingProcess.processing"/>
                                </li>
                            }
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="insert-transient" 
            [class.visible]="model.taskInsertingProcess.processing" 
            [style.--unit-width]="model.currentUnitWidth"
            [style.height.px]="model.taskDefaultHeight"
            [style.top.px]="model.taskInsertingProcess.transientTaskPos.y"
            [style.left.px]="model.taskInsertingProcess.transientTaskPos.x">
        </div>
    </div>
}
