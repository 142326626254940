import { ITabPageContent } from "src/app/components-lib/tab-page-container/model/i-tab-page-content";
import { PurchaseTabEnum } from "./purchase-tab-enum";
import { DateUtils } from "src/app/core/model/static-functions/date-utils";
import Container from "typedi";
import { DyntService } from "src/app/core/services/backend-services/dynt-service";
import { EquipmentPurchase } from "src/app/core/model/data-model/tables/equipment-purchase";
import { TablesNamesEnum } from "src/app/core/model/db-model/tables-names-enum";
import { PurchasesService } from "src/app/core/services/backend-services/purchases-service";
import { PurchaseDetailDTO } from "src/app/core/services/backend-services/dto/purchase-detail-dto";
import { EquipmentStorageArea } from "src/app/core/model/data-model/tables/equipment-storage-area";
import { EquipmentCatalogTable } from "src/app/core/model/db-model/tables/equipment-catalog-table";
import { EquipmentStorageAreaTable } from "src/app/core/model/db-model/tables/equipment-storage-area-table";
import { FloorDataTable } from "src/app/core/model/db-model/tables/floor-data-table";
import { EventEmitter } from "src/app/core/events/event-emitter";
import { PurchaseEventsEnum } from "./purchase-events-enum";

export class PurchasesReceiptVM extends EventEmitter implements ITabPageContent {
    contentType:  PurchaseTabEnum = PurchaseTabEnum.receipt;
    content: any;

    //startDate: Date;
    //endDate: Date;
    purchaseId: number;
    hasReceipt: boolean;
    purchases: EquipmentPurchase[] = [];
    selectedPurchaseDetails: PurchaseDetailDTO[] = [];
    selectedPurchaseHasReceiptTask: boolean = false;
    selectedItems: PurchaseDetailDTO[] = [];
    storageLocations: EquipmentStorageArea[] = [];

    //detailsTable: InventoryReportRow[] = [];
    displayedColumns: string[] = ['index', 'picto', 'reserved', 'store'];

    receiptDate: Date | null;
    receiptMaxDate: Date = DateUtils.today();
    selectedStorageLocation: EquipmentStorageArea | null = null;

    constructor(purchaseId: number, hasReceipt: boolean) {
        super();

        this.purchaseId = purchaseId;
        this.hasReceipt = hasReceipt;
        this.receiptDate = DateUtils.today();
        //const today = DateUtils.today();
        //this.startDate = new Date(today.getFullYear(), 0, 1);
        //this.endDate = today;

        // Il faudra pouvoir filtrer aussi par exemple par fournisseur ou par type d'équipement ou encore par référence de commande
        // et s'assurer que le nombre de résultats n'est pas trop élevé
        //this.loadPurchases();
        this.loadDetails();
    }

    set(data: any): void {
    }

    // async loadPurchases(): Promise<void> {
    //     const s = Container.get(DyntService);
    //     this.purchases = await s.downloadTable<EquipmentPurchase>(TablesNamesEnum.EquipmentPurchase, undefined); 
    // }

    async loadDetails(): Promise<void> {
        const s = Container.get(PurchasesService);
        this.selectedPurchaseDetails = await s.loadDetails(this.purchaseId);
        const hasReceiptTask = await s.hasReceiptTask([this.purchaseId]);
        if (hasReceiptTask.length > 0) {
            this.selectedPurchaseHasReceiptTask = hasReceiptTask[0].hasReceiptTask;
        }

        const t = Container.get(DyntService);
        this.storageLocations = await t.downloadTable<EquipmentStorageArea>(TablesNamesEnum.EquipmentStorageArea);
    }

    async receiptConfirmed(): Promise<void> {
        if (this.receiptDate == null || this.selectedStorageLocation == null) return;

        // Dans un premier temps on considère que tous les items commandés sont livrés en même temps, sans erreur
        // TODO : implémenter le cas d'une réception partielle et/ou incorrecte

        const items: {}[] = [];
        this.selectedPurchaseDetails.forEach(i => {
            items.push({
                [EquipmentCatalogTable.eqCgId]: i.equipmentCatalog.equipmentCatalog.eqCgId,
                [EquipmentStorageAreaTable.eqStArId]: this.selectedStorageLocation!.eqStArId,
                [FloorDataTable.flDaId]: i.floorDataId
            });
        });

        const s = Container.get(PurchasesService);
        const result = await s.registerReceipt(this.purchaseId, this.receiptDate, items);
        if (result) {
            this.hasReceipt = true;
            this.selectedPurchaseDetails.forEach(i => {
                i.storeAreaTargetName = this.selectedStorageLocation!.eqStArName;
            });
            this.emitEventAsync(PurchaseEventsEnum.receiptConfirmed, this.purchaseId, this.receiptDate);
        }
    }
}