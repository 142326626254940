import { BoardToolbarCommand } from "./board-toolbar-command";
import { BoardToolbarCommandEnum } from "./board-toolbar-command-enum";

export class BoardToolbarCommands {
    items: BoardToolbarCommand[] = [];

    polylineMenuItem: BoardToolbarCommand;

    constructor() {
        this.polylineMenuItem = new BoardToolbarCommand(BoardToolbarCommandEnum.polyline, "Polyligne", "polyline");
        this.items.push(this.polylineMenuItem);
    }
}