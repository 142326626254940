import { DisplayThemeView } from 'src/app/core/model/data-model/views/display-theme-view';
import { DisplayThemeCaption } from './display-theme-caption';

export class DisplayThemeSet {
    id: number;
    label: string;
    value: number;
    themeView: DisplayThemeView;
    categoryName?: string;
    captions: DisplayThemeCaption[] = [];
    onlyTopMostTask: boolean = false;

    constructor(themeView: DisplayThemeView) {
        this.themeView = themeView;
        this.value = themeView.diThId;
        this.id = themeView.diThId;
        this.label = themeView.diThDisplayName;
    }
}