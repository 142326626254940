export const BuildingDbView = {
    buViLatitude: "BuVi_Latitude",
    buViLongitude: "BuVi_Longitude",
    buViAltitude: "BuVi_Altitude",
    buViGLA: "BuVi_GLA",
    buViNUA: "BuVi_NUA",
    buViFloorsCount: "BuVi_FloorsCount",
    buViMailingAddress: "BuVi_MailingAddress",
    buViDeliveryAddress: "BuVi_DeliveryAddress",
    buViParkingAddress: "BuVi_ParkingAddress",
    buViLobbyAddress: "BuVi_LobbyAddress"
}