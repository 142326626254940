import { DonutChartDataVM } from '../../model/donut-chart-data-vm';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'xc-donut-chart',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './donut-chart.component.svg',
  styleUrls: ['./donut-chart.component.scss']
})
export class DonutChartComponent implements OnChanges {
  @Input() model: DonutChartDataVM | undefined;

  ngOnChanges(changes: SimpleChanges): void {
    if (this.model && this.model instanceof DonutChartDataVM) {
      setTimeout( () => {
        // Ce petit délai asynchrone permet l'animation d'entrée
        // Ce n'est pas la durée du délai qui compte mais simplement le fait qu'il soit asynchrone
        // et donc que le traitement soit déclenché après que la vue soit entièrement chargée
        // AfterViewInit ne suffit pas, ni OnChanges sans le timeout
        this.model?.arrange();
      }, 1);
    }
  }
}
