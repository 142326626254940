import { ITabPageContent } from "src/app/components-lib/tab-page-container/model/i-tab-page-content";
import { StoresTabEnum } from "./stores-tab-enum";
import { EquipmentStorageArea } from "src/app/core/model/data-model/tables/equipment-storage-area";
import { DynTableVM } from "src/app/components-lib/dyn-grid/model/dyn-table-vm";
import Container from "typedi";
import { PaginationService } from "src/app/core/services/backend-services/pagination-service";
import { DyntService } from "src/app/core/services/backend-services/dynt-service";
import { DynTableVMBuilder } from "src/app/components-lib/dyn-grid/model/dyn-table-vm-builder";
import { TablesNamesEnum } from "src/app/core/model/db-model/tables-names-enum";
import { UploadService } from "src/app/core/services/backend-services/upload-service";
import { ApiEndpoints } from "src/app/core/services/api-endpoints";
import { EquipmentStorageAreaTable } from "src/app/core/model/db-model/tables/equipment-storage-area-table";

export class StoresDashboardVM implements ITabPageContent {
    contentType:  StoresTabEnum = StoresTabEnum.dashboard;
    content: any;
    stores: EquipmentStorageArea[] = [];
    selectedStore: EquipmentStorageArea | undefined;
    equipmentsList: DynTableVM | undefined;

    constructor(stores: EquipmentStorageArea[]) {
        this.stores = stores;
    }

    set(data: any): void {
    }

    async loadEquipmentList(): Promise<void> {
        const viewName = "equipment_storage_dynview";
        const p = Container.get(PaginationService);
        const paginator = await p.getPaginator(viewName, this.selectedStore?.eqStArId.toString());
        const s = Container.get(DyntService);
        const viewSet = await s.contextedDataSet(viewName, 0, EquipmentStorageAreaTable.eqStArId, this.selectedStore?.eqStArId, paginator?.pageItemsCount);
        const table = DynTableVMBuilder.getVM(viewSet, viewName, paginator);
        
        table.newPageDataRequested = async (pageIndex: number) => {
            const px = await s.contextedDataSet(viewName, pageIndex, EquipmentStorageAreaTable.eqStArId, this.selectedStore?.eqStArId, table.paginator?.pageItemsCount);
            table.setDataSource(px.viewData);
        }

        table.imageUploadRequested = async (file: File, inserting: boolean, maxSize: number, rowId: number): Promise<string> => {
            const s = Container.get(UploadService);
            if (inserting) {
                return await s.uploadTempFile(file, `${ApiEndpoints.endPoint(ApiEndpoints.fsToAirlock)}`, maxSize);
            } else {
                return await s.uploadUpdateFile(file, `${ApiEndpoints.endPoint(ApiEndpoints.fsProviderLogo)}`, rowId);
            }
        }

        this.equipmentsList = table;
    }

    async onSelectedStoreChange(): Promise<void> {
        if (!this.selectedStore) return;
        await this.loadEquipmentList();
    }
}