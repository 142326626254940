import { DxfSvgEntity } from "../svg/dxf-svg-entity";
import { logError } from "src/app/core/services/logging-service";

export class EntityInteraction {
    geoTileUpdateRequested?: (entities: DxfSvgEntity[]) => void;
    raiseGeoTileUpdateRequested(entities: DxfSvgEntity[]): void {
        if (this.geoTileUpdateRequested) {
            this.geoTileUpdateRequested(entities);
        } else {
            logError("EntityInteraction.geoTileUpdateRequested n'est pas écouté");
        }
    }

}