import { SimulationPhotoDTO } from "src/app/core/services/backend-services/dto/simulation-photo-dto";
import { SvgBuildingStackContainerVM } from "./svg-building-stack-container-vm";
import { SvgDOMBase } from "src/app/core/model/svg-model/abstract/svg-dom-base";
import { Point } from "src/app/core/model/geometry-model/point.model";
import { SvgTransform } from "src/app/core/model/svg-model/svg-transform.model";
import { SvgStackSettings } from "./svg-stack-settings";
import { SvgText } from "src/app/core/model/svg-model/svg-text.model";
import { SvgRectangle } from "src/app/core/model/svg-model/svg-rectangle.model";
import { SvgBsFloorDataItem } from "src/app/ui/shared/charts/stack/model/svg-bs-floor-data-item";

export class SvgStrategyStackingVM extends SvgDOMBase {
    static svgId: string = "svgStrategyStacking";

    simulationPhoto: SimulationPhotoDTO;
    totalWidth!: number;
    totalHeight: number;
    maxArea: number = 0;
    maxFloors: number = 0;
    dropZoneX: number | undefined;
    dragging: boolean = false;
    xMargins: number = 5;
    draggingItemId: string | undefined;

    tooltip: SvgText;
    tooltipVisible: boolean = false;

    areaDragging: boolean = false;
    areaDraggingStart: Point | undefined;
    draggedArea: SvgRectangle = SvgRectangle.fromValues(0, 0, 0, 0);
    draggedAreaInitialPosition: Point | undefined;
    draggedAreaTransform: SvgTransform | undefined;

    guttersPositions: number[] = [];
    buildingStackContainers: SvgBuildingStackContainerVM[] = [];

    constructor(sp: SimulationPhotoDTO) {
        super(SvgStrategyStackingVM.svgId, "204c20bf-8c96-4d65-9c26-c1940355e970");

        this.simulationPhoto = sp;
        this.calculateBounds(sp);
        this.totalHeight = SvgStackSettings.headerHeight + (this.maxFloors * SvgStackSettings.floorHeight);
        this.tooltip = SvgText.fromValues(0, 0, "", 14);

        let xPos: number = this.xMargins;
        let index: number = 0;
        sp.buildingView.forEach(b => {
            const newStackContainer = new SvgBuildingStackContainerVM(xPos, this.totalHeight, b, index);
            this.buildingStackContainers.push(newStackContainer);
            xPos += newStackContainer.width;
            index++;

            this.listenToBuildingDropZoneRequests(newStackContainer);
            this.listenToBuildingStartDragging(newStackContainer);
            this.listenToBuildingEndDraggingRequests(newStackContainer);
            this.listenToTooltipRequests(newStackContainer);
            this.listenToAreaStartDraggingRequests(newStackContainer);

            this.getGutterPositions();
        });
    }

    listenToBuildingDropZoneRequests(e: SvgBuildingStackContainerVM): void {
        e.dropZoneRequested = (x: number, item: SvgBuildingStackContainerVM) => {
            // Recherche la gouttière la plus proche
            const gutterDistances: [index: number, distance: number][] = [];
            this.guttersPositions.forEach(gp => {
                if (gp !== item.initialPosition.x && gp !== item.initialPosition.x + item.width) {
                    gutterDistances.push([this.guttersPositions.indexOf(gp), Math.abs(x - gp)]);
                }
            });

            const nearestPosition = gutterDistances.sort((a, b) => a[1] - b[1])[0];
            if (nearestPosition[1] < item.width / 2) {
                this.dropZoneX = this.guttersPositions[nearestPosition[0]];
            } else {
                this.dropZoneX = undefined;
            }
        }
    }

    listenToBuildingStartDragging(e: SvgBuildingStackContainerVM): void {
        e.draggingStarted = (id: string) => {
            this.draggingItemId = id;
            this.dragging = true;
        }
    }

    listenToBuildingEndDraggingRequests(e: SvgBuildingStackContainerVM): void {
        e.draggingEnded = (item: SvgBuildingStackContainerVM) => {
            this.dragging = false;

            if (this.dropZoneX !== undefined) {
                const gutterIndex = this.guttersPositions.indexOf(this.dropZoneX);

                let targetIndex: number = gutterIndex;
                if (targetIndex > item.index) {
                    // Cas d'un déplacement vers la droite
                    targetIndex -=1;
                }

                const targetItem = this.buildingStackContainers.find(x=> x.index === targetIndex);
                if (targetItem) {
                    if (gutterIndex > item.index) {
                        // Déplacement vers la droite
                        for (let i = item.index + 1; i <= targetIndex; i++) {
                            const element = this.buildingStackContainers.find(x=> x.index === i);
                            if (element) {
                                this.switchStacks(item, element);
                            }
                        }
                    } else {
                        // Déplacement vers la gauche
                        for (let i = item.index - 1; i >= targetIndex; i--) {
                            const element = this.buildingStackContainers.find(x=> x.index === i);
                            if (element) {
                                this.switchStacks(element, item);
                            }
                        }
                    }

                    // On recalcule les positions des gouttières
                    this.getGutterPositions();
                }
            }

            this.draggingItemId = undefined;
            this.dropZoneX = undefined;
        }
    }

    listenToTooltipRequests(e: SvgBuildingStackContainerVM): void {
        e.tooltipRequested = (e: MouseEvent, d: SvgBsFloorDataItem | undefined) => {
            if (d && !this.areaDragging) {
                const hitPoint = this.getPointPosition(e.clientX, e.clientY);
                if (hitPoint) {
                    this.tooltip.text = d.label;
                    this.tooltip.x = hitPoint.x;
                    this.tooltip.y = hitPoint.y;
                    this.tooltipVisible = true;
                }
            } else {
                this.tooltipVisible = false;
            }
        }
    }

    listenToAreaStartDraggingRequests(bsc: SvgBuildingStackContainerVM): void {
        bsc.areaStartDraggingRequested = (e: MouseEvent, d: SvgBsFloorDataItem) => {
            this.tooltipVisible = false;
            const hitPoint = this.getPointPosition(e.clientX, e.clientY);
            if (hitPoint) {
                this.draggedArea.x = 0;
                this.draggedArea.y = 0;
                this.draggedArea.width = d.width;
                this.draggedArea.height = d.height;
                this.draggedArea.fill = d.fill;
                this.draggedArea.fillOpacity = d.fillOpacity;
                this.areaDraggingStart = hitPoint;
                this.draggedAreaInitialPosition = new Point(d.x + bsc.initialPosition.x, d.y);
                this.draggedAreaTransform = new SvgTransform({translate: this.draggedAreaInitialPosition});
                this.areaDragging = true;
            }
        }
    }

    overlayMouseup(e: MouseEvent): void {
        this.areaDragging = false;
        this.draggedAreaTransform = new SvgTransform({translate: this.draggedAreaInitialPosition});
    }

    overlayMousemouve(e: MouseEvent): void {
        const hitPoint = this.getPointPosition(e.clientX, e.clientY);
        if (hitPoint && this.draggedAreaTransform && this.draggedAreaInitialPosition && this.areaDraggingStart) {
            this.draggedAreaTransform = this.draggedAreaTransform.translateUpdate(this.draggedAreaInitialPosition.translate(hitPoint.minus(this.areaDraggingStart)));
        }
    }

    switchStacks(s1: SvgBuildingStackContainerVM, s2: SvgBuildingStackContainerVM): void {
        // s1 est toujours l'élément situé à gauche
        // s2 prend la position de s1
        // et la position de s1 est recalculée en fonction de la largeur de s2
        const s2Index = s2.index;

        s2.initialPosition = s1.initialPosition;
        s2.transform = s1.transform.clone();
        s2.index = s1.index;

        s1.initialPosition = new Point(s1.initialPosition.x + s2.width, s1.initialPosition.y);
        s1.transform = new SvgTransform({translate: s1.initialPosition});
        s1.index = s2Index;
    }

    getGutterPositions(): void {
        this.guttersPositions.splice(0);
        const indexSortedStacks = this.buildingStackContainers.sort((a, b) => a.index - b.index);
        indexSortedStacks.forEach(bc => {
            this.guttersPositions.push(bc.initialPosition.x);
        });
        const lastItem = indexSortedStacks[indexSortedStacks.length - 1];
        this.guttersPositions.push(lastItem.initialPosition.x + lastItem.width);
    }

    calculateBounds(sp: SimulationPhotoDTO): void {
        // La largeur totale du svg est égale à la sommes des plus grandes largeurs des immeubles simulés
        // + une marge à droite et à gauche pour les séparer visuellement
        let result: number = 0;
        let maxFloor: number = 0;
        sp.buildingView.forEach(b => {
            const maxFloorArea = b.maxFloorArea() * SvgStackSettings.xScale;
            result += maxFloorArea + SvgStackSettings.floorLabelsWidth + (SvgStackSettings.hMargins * 2) + (this.xMargins * 2);
            if (maxFloorArea > this.maxArea) {
                this.maxArea = maxFloorArea;
            }
            if (b.floors.length > maxFloor) {
                maxFloor = b.floors.length;
            }
        });
        this.totalWidth = result;
        this.maxFloors = maxFloor;
    }

}