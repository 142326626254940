import { SvgEntityTypesEnum } from "src/app/core/model/svg-model/svg-entity-type-enum";
import { DxfSvgUse } from "../../svg/dxf-svg-use";
import { UpdateGizmo } from "./update-gizmo";
import { Vector } from "src/app/core/model/geometry-model/vector.model";

export class UseGizmo extends UpdateGizmo<DxfSvgUse> {
    constructor(pixelUnit: number) {
        super(pixelUnit, SvgEntityTypesEnum.use);
    }

    translateUses(delta: Vector): void {
        this.entities.forEach(e => {
            e.translate(delta);
        });
        this.grips.items.forEach(g => {
            g.point = g.point.translate(delta);
        });
    }

    endTranslate(): void {
        this.entities.forEach(e => {
            e.updateSelectablePoints();
        });
    }
}