import { SvgTransform } from './../../../model/svg-model/svg-transform.model';
import { SvgEntityTypesEnum } from './../../../model/svg-model/svg-entity-type-enum';

export class SvgEntityDTO {
    entityType: SvgEntityTypesEnum;
    transform: SvgTransform;
    attributes: any;

    constructor(dtoData: any) {
        this.entityType = dtoData["entityType"];
        this.transform = new SvgTransform(dtoData["transform"]);
        this.attributes = dtoData["attributes"];
    }
}