import { TabPageItem } from "src/app/components-lib/tab-page-container/model/tab-page-item";
import { TabPager } from "src/app/components-lib/tab-page-container/model/tab-pager";
import { LayoutTypeTreeDataDTO } from "src/app/core/services/backend-services/dto/layout-type-tree-data-dto";
import { LayoutTypeService } from "src/app/core/services/backend-services/layout-type-service";
import Container from "typedi";
import { LayoutTypeTreeNode } from "./layout-type-tree-node";
import { LayoutTypeChartDataBuilder } from "./layout-type-chart-data-builder";
import { DonutChartWrapperVM } from "src/app/ui/shared/charts/donut/model/donut-chart-wrapper-vm";
import { readableUUID } from "src/app/core/events/event-listener-uuid";
import { ITabPageContent } from "src/app/components-lib/tab-page-container/model/i-tab-page-content";
import { LayoutTypesTreeModel } from "./layout-types-tree-model";
import { RealEstateChartTypeEnum } from "../../charts/shared-model/real-estate-chart-type-enum";
import { TabPageContentTypeEnum } from "../../tab-page-content-type-enum";

export class LayoutTypeContentVM extends TabPager {
    name: string = "NA";
    subTypesCount: number = 0;
    treeDataDTO: LayoutTypeTreeDataDTO | undefined;
    selectedTreeNode: LayoutTypeTreeNode | undefined;
    tabPageContentTypeEnum = TabPageContentTypeEnum;

    private constructor() {
        super(readableUUID("LayoutTypeContentVM"));

        this.addEventListener(LayoutTypesTreeModel.LayoutTypeSelectedNodeChangeEvent, async (node: LayoutTypeTreeNode) => {
            this.selectedTreeNode = node;
            if (this.selectedTab) this.selectedTab.content = await this.loadContent();
        });
    }

    static async newAsync(): Promise<LayoutTypeContentVM> {
        const result = new LayoutTypeContentVM();
        await result.initialize();
        return result;
    }
    
    async initialize(): Promise<void> {
        this.prepareCharts();
        await this.loadRefData();
        await this.selectTabPage(0);
        //this.selectedTab = this.tabs[0];
        //await this.loadContent();
    }
    
    async loadRefData(): Promise<void> {
        const s = Container.get(LayoutTypeService);
        const data = await s.loadTreeDatas();
        if (!data) return;
        this.treeDataDTO = data;
    }

    async loadLtData(node: LayoutTypeTreeNode): Promise<void> {
        //if (this.treeDataDTO) {
            this.selectedTreeNode = node;
            this.name = node.lt.roLaTyName

            await this.setSelectedContent();
            //await this.loadContent();
        //}
    }

    prepareCharts(): void {
        this.tabs.push(new TabPageItem(RealEstateChartTypeEnum.attributions, "Attributions", TabPageContentTypeEnum.donutchart));
        this.tabs.push(new TabPageItem(RealEstateChartTypeEnum.activities, "Activités", TabPageContentTypeEnum.donutchart));
        this.tabs.push(new TabPageItem(RealEstateChartTypeEnum.buildings, "Cartographie", TabPageContentTypeEnum.donutchart));
    }

    override async loadContent(): Promise<ITabPageContent | undefined>{
        const node = this.selectedTreeNode;
        if (!node || !this.treeDataDTO) return;
        if (!this.selectedTab) return;

        const tmp = new DonutChartWrapperVM();

        let data: any | undefined = undefined;
        switch (this.selectedTab.id) {
            case RealEstateChartTypeEnum.buildings:
                tmp.set(LayoutTypeChartDataBuilder.loadMapChartData(this.treeDataDTO, node.lt));
                break;
            case RealEstateChartTypeEnum.attributions:
                tmp.set(LayoutTypeChartDataBuilder.loadAttributionChartData(this.treeDataDTO, node.lt));
                break;
            case RealEstateChartTypeEnum.activities:
                tmp.set(LayoutTypeChartDataBuilder.loadActivityChartData(this.treeDataDTO, node.lt));
                break;
            default:
                break;
        }

        return tmp;
    }

    // async selecteChartChange(e: MatTabChangeEvent): Promise<void> {
    //     this.selectedTab = this.tabs[e.index];
    //     if (this.selectedTab === undefined) return;
    //     await this.loadContent();
    // }
}