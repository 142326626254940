import { GeometricElementType } from "src/app/core/model/geometry-model/geometric-element-type.model";
import { Segment } from "src/app/core/model/geometry-model/segment.model";
import { BpSvgGroup } from "../../../bp-svg-core-model/bp-svg-group";
import { BpSvgPath } from "../../../bp-svg-core-model/bp-svg-path";
import { BlueprintLayer } from "./blueprint-layer";

export class BlueprintInsideContoursLayer extends BlueprintLayer<BpSvgGroup> {
    segments: Segment[] = [];

    constructor(dtoData: any, taskId: number) {
        super(dtoData, taskId);
        // La géométrie est fournie par le backend
        this.getSegments();
    }

    getSegments(): void {
        this.segments = [];
        // Ce calque contient un seul groupe
        // qui contient lui-même les paths de contours non simulables
        const group = this.data[0] as BpSvgGroup;
        group.entities.forEach(c => {
            const g = (c as BpSvgPath).geometry;
            g.forEach(element => {
                if (element.elementType === GeometricElementType.Segment) {
                    const newSegment = Segment.fromDto(element);
                    if (newSegment) {
                        this.segments.push(newSegment);
                    }
        }
            });
        });
    }
}
