export class DoorData {
    type: number; //SvgDoorOpeningTypeEnum
    leafCount: number;
    openingDirection: number;
    openingWallSide: number;
    wallAngle: number;
    doorWidth: number;
    wallThickness: number;
    openingAngle: number;
    swinging: boolean;

    constructor(doorDataStr: string) {
        const splitted = doorDataStr.split(' ');
        this.type = Number(splitted[0]);
        this.leafCount = Number(splitted[1]);
        this.openingDirection = Number(splitted[2]);
        this.openingWallSide = Number(splitted[3]);
        this.wallAngle = Number(splitted[4]);
        this.doorWidth = Number(splitted[5]);
        this.wallThickness = Number(splitted[6]);
        this.openingAngle = Number(splitted[7]);
        this.swinging = !!Number(splitted[8]);
    }
}
  