import { TaskContributorDirectoryTable } from '../../db-model/tables/task-contributor-directory-table';
import { DefinedModelBase } from '../defined-model-base';

export class TaskContributorDirectory extends DefinedModelBase {
    taCrDiId!: number;
    taCrDiCode!: string;
    taCrDiTitle!: string;
    taCrDiFirstName!: string;
    taCrDiLastName!: string;
    taCrDiIsActive!: boolean;

    constructor(dtoData: any, tableDef: any[] = []) {
        super(dtoData, [TaskContributorDirectoryTable].concat(tableDef));
    }
  }
  