import { ITabPageContent } from "./i-tab-page-content";

export class TabPageItem {
    id: string;
    name: string;
    content: ITabPageContent | undefined;
    contentType: string;

    constructor(id: string, name: string, contentType: string = "any") {
        this.id = id;
        this.name = name;
        this.contentType = contentType;
    }
}