import { TaskTypeTable } from './../../db-model/tables/task-type-table';
import { DefinedModelBase } from '../defined-model-base';

export class TaskType extends DefinedModelBase {
    taTyId!: number;
    taTyName!: string;
    taTyDisplayNameId!: number;
    taTyColor!: string;

    constructor(dtoData: any, tableDef: any[] = []) {
        super(dtoData, [TaskTypeTable].concat(tableDef));
    }
}