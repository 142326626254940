import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StrategyStackingVM } from '../../model/strategy-stacking-vm';
import { SvgStrategyStackingComponent } from '../svg-strategy-stacking/svg-strategy-stacking.component';

@Component({
  selector: 'xc-strategy-stacking',
  standalone: true,
  imports: [CommonModule, SvgStrategyStackingComponent],
  templateUrl: './strategy-stacking.component.html',
  styleUrls: ['./strategy-stacking.component.scss']
})
export class StrategyStackingComponent {
  @Input() model: StrategyStackingVM | undefined;
}
