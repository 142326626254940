export enum BlueprintSvgEntityTypeEnum {
    none = 0,
    roomLabel,
    workplaceLabel,
    measurementLabel,
    peopleLabel,
    door,
    countBadge,
    leaderLine,
    measureLines
}