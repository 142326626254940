import { DisplayThemeCategoryView } from 'src/app/core/model/data-model/views/display-theme-category-view';
import { DisplayThemeSet } from './display-theme-set';

export class DisplayThemeCategorySet extends DisplayThemeCategoryView {
    id: number;
    label: string;
    value: number;
    items: DisplayThemeSet[] = [];

    themes: DisplayThemeSet[] = [];
    visible: boolean = false;

    constructor(dtoData: any) {
        super(dtoData);

        this.id = this.diThCaId;
        this.label = this.diThCaDisplayName;
        this.value = this.diThCaId;
    }
}