import { TabItem } from "src/app/components-lib/shared-model/tab-item-model";
import { Tabs } from "src/app/components-lib/shared-model/tabs-model";
import { BlueprintSidePanelTabEnum } from "./bp-side-panel-tab.enum";
import { ThemingModel } from "../../subitems/theming/model/theming-model";
import { DisplayThemeSet } from "../../subitems/theming/model/display-theme-set";
import { DisplayThemeEnum } from "src/app/core/model/data-model/enums/display-theme-enum";
import { LayersTreeModel } from "../../subitems/layer-tree/model/layers-tree-model";
import { FloorBlueprintEventsEnum } from "../../../../container/model/floor-blueprint-events-enum";
import { EditableLayer } from "../../../blueprint-viewer-content-panel/itself/model/interaction/editable-layer";

export class BpeSidePanelTabs extends Tabs {
    constructor(tabsInvariantId: string) {
        super(-1, tabsInvariantId);
    }

    override initialize(): void {
        super.initialize();

        this.tabItems.push(new TabItem(BlueprintSidePanelTabEnum.tasks, "architecture", "Etude"));
        this.tabItems.push(new TabItem(BlueprintSidePanelTabEnum.masterPlan, "table_rows", "Schéma directeur"));
        this.tabItems.push(new TabItem(BlueprintSidePanelTabEnum.layers, "layers", "Calques"));
        this.tabItems.push(new TabItem(BlueprintSidePanelTabEnum.displayThemes, "palette", "Thèmes"));
        this.tabItems.push(new TabItem(BlueprintSidePanelTabEnum.properties, "highlight_alt", "Propriétés"));
        this.tabItems.push(new TabItem(BlueprintSidePanelTabEnum.equipments, "event_seat", "Equipements"));
        this.tabItems.push(new TabItem(BlueprintSidePanelTabEnum.labeling, "font_download", "Etiquetage"));

        // Les onglets utilisés exclusivement sur les tâches sont inactivés
        this.setEnable(false);

        this.addEventListener(ThemingModel.selectedThemeChange, (item: DisplayThemeSet) => {
            this.setThemesTabBadge(item != null && item.themeView.diThId !== DisplayThemeEnum.None);
        });

        this.addEventListener(LayersTreeModel.layersVisibilityChanged, (childrenPartiallyVisible: boolean) => {
            this.setLayersTabBadge(childrenPartiallyVisible);
        });

        this.addEventListener(FloorBlueprintEventsEnum.editableLayersChanged, (layers: EditableLayer[]) => {
            this.enable(BlueprintSidePanelTabEnum.equipments, layers.filter(x=> x.isEquipmentLayer).length > 0);
        });
    }

    setEnable(forTask: boolean): void {
        this.enable(BlueprintSidePanelTabEnum.equipments, forTask);
        //this.enable(BlueprintSidePanelTabEnum.labeling, forTask);
    }

    enable(tabIndex: BlueprintSidePanelTabEnum, enable: boolean): void {
        const tab = this.tabItems.find(x=> x.index === tabIndex);
        if (tab) {
            tab.enabled = enable;
        }
    }

    // TODO: faire un enum des tabs et mutualiser les méthodes suivantes
    setTasksTabBadge(display: boolean): void {
        this.tabItems[0].displayBadge = display;
    }

    setLayersTabBadge(display: boolean): void {
        this.tabItems[2].displayBadge = display;
    }

    setThemesTabBadge(display: boolean): void {
        this.tabItems[3].displayBadge = display;
    }

    setPropertiesTabBadge(display: boolean): void {
        this.tabItems[4].displayBadge = display;
    }
}