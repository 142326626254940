import { TabPageContent } from "src/app/components-lib/tab-page-container/model/tab-page-content";
import { readableUUID } from "src/app/core/events/event-listener-uuid";
import { TaskView } from "src/app/core/model/data-model/views/task-view";
import { MobilityProjectsGanttBrowserTabsEnum } from "./mobility-projects-gantt-browser-tabs-enum";
import { MobilityProjectsGanttTreeEventsEnum } from "./mobility-projects-gantt-tree-events-enum";
import { TaskPropertiesVM } from "./task-properties-vm";
import { TaskContributorsVM } from "./task-contributors-vm";
import { TaskCreationFormVM } from "./task-creation-form-vm";

export class MobilityProjectsGanttEditorVM extends TabPageContent<TaskView> {
    selectedTask: TaskView | undefined;
    taskProperties: TaskPropertiesVM | undefined;
    creationStepper: TaskCreationFormVM;
    taskContributors: TaskContributorsVM;

    constructor() {
        super(readableUUID("MobilityProjectsGanttEditorVM"), MobilityProjectsGanttBrowserTabsEnum.edition);

        this.creationStepper = new TaskCreationFormVM();
        this.taskContributors = new TaskContributorsVM();

        this.addEventListener(MobilityProjectsGanttTreeEventsEnum.taskSelectedByrocess, (task: TaskView | undefined) => {
            this.selectedTask = task;
            if (task) {
                this.taskProperties = new TaskPropertiesVM();
                this.taskProperties.set(task);
                this.taskContributors.set(task);
            }
            else {
                this.taskProperties = undefined;
            }
        });
    }
}