import { ZConstraintTypeTable } from '../../db-model/tables/z-constraint-type-table';
import { DefinedModelBase } from '../defined-model-base';

export class ZConstraintType extends DefinedModelBase {
    coTyId!: number;
    coTyName!: string;

    constructor(dtoData: any) {
        super(dtoData, [ZConstraintTypeTable]);
    }
}