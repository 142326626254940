import { BuildingView } from "src/app/core/model/data-model/views/building-view";
import { FloorTable } from "src/app/core/model/db-model/tables/floor-table";
import { FloorAxonoDTO } from './floor-axono-dto';

export class BuildingAxonoDTO {
    buildingView!: BuildingView;
    floors: FloorAxonoDTO[] = [];

    constructor(dtoData: any) {
        if (dtoData) {
            this.buildingView = new BuildingView(dtoData);
            dtoData[FloorTable.databaseTableName].forEach((e: any) => {
                this.floors.push(new FloorAxonoDTO(e));
            });
        }
    }
}