import { FloorModelEnum } from "src/app/core/model/data-model/enums/floor-model-enum";
import { BlueprintCoreContoursLayer } from "./blueprint-core-contour-layer";
import { BlueprintDoorsLayer } from "./blueprint-doors-layer";
import { BlueprintEquipmentLayer } from "./blueprint-equipment-layer";
import { BlueprintInsideContoursLayer } from "./blueprint-inside-contour-layer";
import { BlueprintLabelLayer } from "./blueprint-label-layer";
import { BlueprintLayer } from "./blueprint-layer";
import { BlueprintPartitioningFrameLayer } from "./blueprint-partitioning-frame-layer";
import { BlueprintRoomLayer } from "./blueprint-room-layer";
import { BlueprintWallsLayer } from "./blueprint-walls-layer";
import { BlueprintRoomLabelLayer } from "./blueprint-room-label-layer";
import { BlueprintPeopleLabelLayer } from "./blueprint-people-label-layer";
import { BlueprintWorkplaceLabelLayer } from "./blueprint-workplace-label-layer";

export function getLayer(dtoData: any, taskId: number): BlueprintLayer {
    if (dtoData.isUseLayer) {
        return new BlueprintEquipmentLayer(dtoData, taskId);
    }

    switch (dtoData.id) {
        case FloorModelEnum.FloorCoreContours:
            return new BlueprintCoreContoursLayer(dtoData, taskId);
        case FloorModelEnum.FloorInsideContours:
            return new BlueprintInsideContoursLayer(dtoData, taskId);
        case FloorModelEnum.Walls:
            return new BlueprintWallsLayer(dtoData, taskId);
        case FloorModelEnum.Doors:
            return new BlueprintDoorsLayer(dtoData, taskId);
        case FloorModelEnum.PartitioningFrame:
            return new BlueprintPartitioningFrameLayer(dtoData, taskId);
        case FloorModelEnum.Rooms:
            return new BlueprintRoomLayer(dtoData, taskId);
        case FloorModelEnum.WorkplaceLabels:
            return new BlueprintWorkplaceLabelLayer(dtoData, taskId);
        case FloorModelEnum.PeopleLabels:
            return new BlueprintPeopleLabelLayer(dtoData, taskId);
        case FloorModelEnum.RoomLabels:
            return new BlueprintRoomLabelLayer(dtoData, taskId);
        case FloorModelEnum.Measurement:
            return new BlueprintLabelLayer(dtoData, taskId);
        default:
            return new BlueprintLayer(dtoData, taskId);
    }
}
