@if (model) {
<div id="board-toolbar-container">
    <mat-button-toggle-group #group="matButtonToggleGroup" [value]="model.selectedCommandId" (change)="model.selectedCommandClick($event)">
        @for (m of model.drawingCommands.items; track m) {
  
            <mat-button-toggle [value]="m.id" [matTooltip]="m.name">
              <mat-icon [ngClass]="{'ellipse-cmd' : m.id === 4}" class="material-icons-outlined">{{m.icon}}</mat-icon>
            </mat-button-toggle>
          
}
      </mat-button-toggle-group>
      
    <mat-button-toggle-group #group="matButtonToggleGroup" [value]="model.selectedGripStyleId" (change)="model.selectedGripStyleClick($event)">
        @for (m of model.gripStylesCommands.items; track m) {
  
            <mat-button-toggle [value]="m.id" [matTooltip]="m.name">
              <mat-icon [ngClass]="{'quadrant-cmd' : m.id === 6}" class="material-icons-outlined">{{m.icon}}</mat-icon>
            </mat-button-toggle>
          
}
      </mat-button-toggle-group>
      
    <mat-button-toggle-group multiple #group="matButtonToggleGroup" (change)="model.selectedToolClick($event)">
        @for (m of model.toolsCommands.items; track m) {
  
            <mat-button-toggle [attr.tool-id]="'tool' + m.id" [value]="m.id" [matTooltip]="m.name">
              <mat-icon class="material-icons-outlined">{{m.icon}}</mat-icon>
            </mat-button-toggle>
          
}
      </mat-button-toggle-group>
</div>
}
@if (model && model.extension) {
<div id="board-toolbar-extension">
  <div class="grid-options" [style.left.px]="model.optionsLeft">
    <mat-button-toggle-group #group="matButtonToggleGroup" 
      [value]="model.selectedGridOptionId" 
      (change)="model.selectedGridOptionClick($event)">
      @for (m of model.gridOptionsCommands.items; track m) {
  
          <mat-button-toggle [value]="m.id" [matTooltip]="m.name">
            <mat-icon [ngClass]="{'align-grid-option' : m.id === 2}" class="material-icons-outlined">{{m.icon}}</mat-icon>
          </mat-button-toggle>
        
}
    </mat-button-toggle-group>
    @if (model.gridUnitLengthVisible) {
<input id="gridUnitLengthInput" 
      
      matInput
      type="text" inputmode="numeric" 
      [(ngModel)]="model.gridUnitLength"
      (focusout)="model.gridUnitLengthFocusOut()"
      (keydown)="model.gridUnitLengthKeyDown($event)">
}
    <button mat-icon-button matTooltip="Réinitialiser" (click)="model.gridResetCommandClick($event)">
      <mat-icon>restart_alt</mat-icon>
    </button>
  </div>
</div>
}
