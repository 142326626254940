import { TabItem } from "src/app/components-lib/shared-model/tab-item-model";
import { Tabs } from "src/app/components-lib/shared-model/tabs-model";
import { BlueprintGWTabEnum } from "./blueprint-gw-tab-enum";

export class SidePanelTabs extends Tabs {
    constructor(invariantId: string) {
        super(1, invariantId);
    }
    
    override initialize(): void {
        super.initialize();
        this.tabItems.push(new TabItem(BlueprintGWTabEnum.imports, "sync", "Imports"));
        this.tabItems.push(new TabItem(BlueprintGWTabEnum.layers, "layers", "Calques"));
        this.tabItems.push(new TabItem(BlueprintGWTabEnum.pictos, "camera", "Pictogrammes"));
        this.tabItems[0].isSelected = true;
    }
}