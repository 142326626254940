import { SvgEntity } from './svg-entity.model';

export class SvgUse extends SvgEntity {
    href: string | undefined;

    constructor(dtoData: any) {
        super(dtoData);
        this.href = SvgEntity.getValue(dtoData, "href");
    }
}
