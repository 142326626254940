import { FloorWallStyleEnum } from "src/app/core/model/data-model/enums/floor-wall-style-enum";
import { FloorWallStyle } from "src/app/core/model/data-model/tables/floor-wall-style";
import { Arc } from "src/app/core/model/geometry-model/arc.model";
import { Point } from "src/app/core/model/geometry-model/point.model";
import { Segment } from "src/app/core/model/geometry-model/segment.model";
import { HtmlConstants } from "src/app/core/model/html-model/html-constants.model";
import { XcMaths } from "src/app/core/model/static-functions/xc-maths";
import { SvgTransform } from "src/app/core/model/svg-model/svg-transform.model";
import { BpSvgDoor } from "../../../../../svg-entities/model/bp-svg-door";
import { BpSvgWall } from "../../../../../svg-entities/model/bp-svg-wall";
import { BlueprintDoorsLayer } from "../../../../../svg-entities/model/layers/blueprint-doors-layer";
import { EntitiesSelectionSet } from "../../../../itself/model/interaction/entities-selection-set";
import { SelectionRotationLabelVM } from "../../../selection/model/selection-rotation-label-vm";
import { UpdateGizmo } from "../../update-gizmo.model";
import Container from 'typedi';
import { BpSvgGroup } from '../../../../../bp-svg-core-model/bp-svg-group';
import { Grips } from 'src/app/components-lib/svg/grips/grips';
import { BpWallService } from 'src/app/core/services/backend-services/bp-wall-service';
import { logError } from 'src/app/core/services/logging-service';

export class WallUpdateGizmoVM implements UpdateGizmo {
    selectionSet: EntitiesSelectionSet = new EntitiesSelectionSet();

    // La cloison lorsqu'il n'y en a qu'une sélectionnée
    selectedWall: BpSvgWall | undefined;
    // Le calque des portes
    doorsLayer: BlueprintDoorsLayer | null = null;
    // Les cloisons qui sont connectées au point de départ de la cloison
    selectedWallStartPointConnectedWalls: BpSvgWall[] = [];
    // Les cloisons qui sont connectées au point d'arrivée de la cloison
    selectedWallEndPointConnectedWalls: BpSvgWall[] = [];

    isEditable: boolean = false;

    pivotGroupDisplay: string = HtmlConstants.styleDisplayNone;
    pivotPath: string | undefined;
    targetGrips: Grips = new Grips("purple", "purple");

    selectedEndPointIsStartPoint: boolean = false;
    selectedEndPoint: Point | undefined;
    selectedAngle: number = 0;
    //widthBackgroundLine: PocSegment;
    //widthArrowPath: string;
    sideArrowTransform: SvgTransform | undefined;
    sideTranslationArrowPath: string | undefined;
    
    freeTranslationStartHandlePath: string | undefined;
    freeTranslationEndHandlePath: string | undefined;
    freeTranslationStartpointTransform: SvgTransform | undefined;
    freeTranslationEndpointTransform: SvgTransform | undefined;
    
    constraintTranslationStartHandlePath: string | undefined;
    constraintTranslationEndHandlePath: string | undefined;
    constraintTranslationStartpointTransform: SvgTransform | undefined;
    constraintTranslationEndpointTransform: SvgTransform | undefined;

    lengthLabel: SelectionRotationLabelVM = new SelectionRotationLabelVM();
    pivotLabel: SelectionRotationLabelVM = new SelectionRotationLabelVM();

    initialSegment: Segment | undefined;
    private arrowSize: number = 0.07;
    private sideTranslationArrowOffset: number = 0.1;

    inserting: boolean = false;
    transientSegment: Segment = Segment.null();
    transientSegmentWidth: number = 0;
    transientSegmentColor: string = "#ffffff";
    transientStyle: FloorWallStyle | undefined;
    transientHotSpot: Point | undefined;

    constructor(editable: boolean) {
        this.isEditable = editable;
     }

    show(args: {walls: EntitiesSelectionSet, isEditable: boolean}): void {
        this.selectionSet = args.walls;
        this.isEditable = args.isEditable;
        
        if (this.selectionSet.count === 1) {
            this.setUniqueSelection();
        }
    }

    hide(): void {
        this.selectedWall = undefined;
        this.selectedEndPoint = undefined;
        this.targetGrips.clear();
        this.lengthLabel.hide();
        this.hidePivotPath();

        // this.sideTranslationArrowPath = undefined;

        // this.freeTranslationStartHandlePath = undefined;
        // this.freeTranslationEndHandlePath = undefined;
        // this.freeTranslationStartpointTransform = undefined;
        // this.freeTranslationEndpointTransform = undefined;

        // this.constraintTranslationStartHandlePath = undefined;
        // this.constraintTranslationEndHandlePath = undefined;
        // this.constraintTranslationStartpointTransform = undefined;
        // this.constraintTranslationEndpointTransform = undefined;
    }

    showTransient(wallStyle: FloorWallStyle): void {
        this.transientStyle = wallStyle;
        this.inserting = true;
        if (wallStyle.flWaStId === FloorWallStyleEnum.Virtual) {
            this.transientSegmentWidth = 0.01;
        } else {
            this.transientSegmentWidth = 0.05;
        }
        this.transientSegmentColor = wallStyle.flWaStColor;
    }

    onSelectionSetChanged(): void {
        if (this.selectionSet.count === 1) {
            this.setUniqueSelection();
        }
    }

    setUniqueSelection(): void {
        this.selectedWall = this.selectionSet.items[0] as BpSvgWall;

        this.calculateAllHandles();
        const s = this.selectedWall.segment();
        this.lengthLabel.initialize(s.getEndPointAt(s.length() / 3, false), XcMaths.round(s.length(), 2).toString());
    }

    showDoors(show: boolean): void {
        if (!this.doorsLayer) return;
        const doors = this.doorsLayer.data.filter(x=> x.parentId === this.selectedWall?.floorDataId) as BpSvgDoor[];
        doors.forEach(d => {
            d.show(show); 
        });
    }

    updateTransient(hitPoint: Point, reticleHalfSize: number): void {
        this.transientHotSpot = hitPoint;
        this.transientSegment = new Segment(hitPoint, new Point(hitPoint.x, hitPoint.y + 1));
    }

    hideTransient(): void {
        this.inserting = false;
    }

    setPivotWallsRef(startPointConnectedWalls: BpSvgWall[], endPointConnectedWalls: BpSvgWall[]): void {
        this.selectedWallStartPointConnectedWalls = startPointConnectedWalls;
        this.selectedWallEndPointConnectedWalls = endPointConnectedWalls;
    }

    showPivotPath(endPoint: Point): void {
        if (!this.selectedWall) return;
        const s = this.selectedWall.segment();

        this.pivotGroupDisplay = HtmlConstants.styleDisplayNone;
        // if (this.selectedWallStartPointConnectedWalls.length + this.selectedWallEndPointConnectedWalls.length === 0) {
        //     this.pivotGroupDisplay = PocHtmlConstants.styleDisplayNone;
        //     return;
        // }

        // S'il n'y a qu'un seul segment au pivot, l'arc est un cercle complet
        // sinon c'est un arc de cercle joignant les segments formant les angles les plus petits
        if (endPoint.roundedEquals(s.startPoint)) {
            if (this.selectedWallStartPointConnectedWalls.length > 0) {
                // La première cloison au pivot, quelle qu'elle soit
                const firstWall = this.selectedWallStartPointConnectedWalls[0];
                this.setPivot(endPoint, s, firstWall);

                if  (this.selectedWallStartPointConnectedWalls.length === 1) {

                }
                this.pivotGroupDisplay = HtmlConstants.styleDisplayBlock;
            }
        } else {
            if (this.selectedWallEndPointConnectedWalls.length > 0) {
                // La première cloison au pivot, quelle qu'elle soit
                const firstWall = this.selectedWallEndPointConnectedWalls[0];
                this.setPivot(endPoint, s, firstWall);

                if  (this.selectedWallEndPointConnectedWalls.length === 1) {

                }
                this.pivotGroupDisplay = HtmlConstants.styleDisplayBlock;
            }
        }
    }

    hidePivotPath(): void {
        this.pivotGroupDisplay = HtmlConstants.styleDisplayNone;
    }

    setPivot(endPoint: Point, selectedWallSegment: Segment, pivotWall: BpSvgWall): void {
        const firstWallSegment = pivotWall.segment();
        let orientedselectedWallSegment = selectedWallSegment.orientedFrom(endPoint);
        let orientedfirstWallSegment = firstWallSegment;

        // Si le pivot est un endpoint on oriente le segment comme la cloison sélectionnée
        if  (firstWallSegment.isAnEndPoint(endPoint)) {
            orientedfirstWallSegment = firstWallSegment.orientedFrom(endPoint);
        } else {
            orientedfirstWallSegment = new Segment(endPoint, firstWallSegment.startPoint);
        }
        
        // Le rayon de l'arc est de 1m si la longeur du segment est supérieure à 2m
        // et égal à la moitié de la longueur du segment sinon
        let radius = 1;
        if (selectedWallSegment.length() < 2) {
            radius = selectedWallSegment.length() / 2;
        }

        // L'angle
        const angle = orientedselectedWallSegment.angleWith(orientedfirstWallSegment);
        // Si l'angle est inférieur à 0, on inverse le tracé
        const isNegative = angle < 0;
        
        // Le point de départ de l'arc de pivot
        const arcStartPoint = firstWallSegment.getPointAt(endPoint, firstWallSegment.startPoint, radius);
        // Le point d'arrivée de l'arc de pivot
        const arcEndPoint = selectedWallSegment.getPointAt(endPoint, orientedselectedWallSegment.endPoint, radius);

        if (arcStartPoint.hasNaN() || arcEndPoint.hasNaN()) {
            this.pivotPath = "M0 0";
            return;
        }

        const arc = Arc.fromValues(
            arcStartPoint,
            arcEndPoint,
            radius,
            angle,
            false,
            !isNegative);
        const labelPosition = arc.midPoint();

        this.pivotPath = "M" + arcStartPoint.x + " " + arcStartPoint.y + " A " + radius + " " + radius + " 0 0 " + Number(isNegative) + " " + arcEndPoint.x + " " + arcEndPoint.y;
        this.pivotLabel.initialize(labelPosition, XcMaths.round(XcMaths.toDeg(Math.abs(angle)), 2).toString() + "°");
}
    
    updateDoors(updatedDoors: BpSvgGroup[], deletedIds: number[]): void {
        if (!this.doorsLayer) return;
        // Après une modification de cloison, les ouvertures ont également subi des modifications
        // Le backend se charge du traitement et retourne les portes modifiées et les identifiants des portes supprimées
        // lorsque la cloison est devenue trop courte pour contenir les ouvertures
        const doors = this.doorsLayer.data.filter(x=> x.parentId === this.selectedWall?.floorDataId) as BpSvgDoor[];
        updatedDoors.forEach(d => {
            const door = doors.find(x=> x.floorDataId === d.floorDataId);
            if (door) {
                door.transform = d.transform;                    
                door.dataStateId = d.dataStateId;                    
            }
        });

        deletedIds.forEach(id => {
            this.doorsLayer?.remove(id);
        });
    }

    async saveWallStretch(finalPosition: Point | undefined): Promise<void> {
        if (!this.selectedWall || finalPosition === undefined) return;
        const s = Container.get(BpWallService);
        const result = await s.stretchWall(this.selectedWall?.floorDataId, finalPosition, this.selectedEndPointIsStartPoint);
        if (result != null) {
            //const wallResult = result.walls.find(x=> x.floorModelId === FloorModelEnum.Walls);
            this.selectedWall.dataStateId = result.walls[0].dataStateId;

            //const doorsResult = result.doors.filter(x=> x.floorModelId === FloorModelEnum.Doors);
            this.updateDoors(result.doors, result.removedFloorDataIds)
        } else {
            this.abortEndpointTranslation();
            return;
        }
    }

    async freeTranslateEndpoint(currentHitPoint: Point, absolutePosition: boolean, save: boolean): Promise<void> {
        if (!this.selectedWall || !this.selectedEndPoint) return;
        // Le mouvement consiste en une translation et une rotation combinées
        // La distance du pivot au currentHitPoint est reportée sur le segment initial
        // et pivotée de la valeur de l'angle

        // Si absolutePosition est true c'est qu'on veut placer le endpoint exactement à la position donnée
        // ce qui est le cas lorsqu'on s'accroche à un grip

        const s = this.selectedWall.segment();
        let pivotPoint = s.startPoint;
        if (this.selectedEndPointIsStartPoint) {
            pivotPoint = s.endPoint;
        }

        let finalPosition = currentHitPoint;
        if (!absolutePosition) {
            const initialSegment = new Segment(pivotPoint, this.selectedEndPoint);
    
            const hitPointDistance = pivotPoint.distanceTo(currentHitPoint);
            const hitPointOffset = initialSegment.getEndPointAt(hitPointDistance, false);
    
            const currentMovedPoint = s.otherEnd(pivotPoint);
            // Contrainte de rotation par pas de 1° minimum
            const orientedSegment = new Segment(pivotPoint, currentHitPoint);
            const angle = XcMaths.get360DegreeAngle(90 - XcMaths.toDeg(orientedSegment.angle()), 2);

            const step = 1;
            const stepAngle = XcMaths.round((angle / step) * step, 0);
            const deltaAngle = (stepAngle - this.selectedAngle) % 360;
    
            finalPosition = hitPointOffset.rotate(pivotPoint, XcMaths.toRad(deltaAngle));
        }

        if (save) {
            await this.saveWallStretch(finalPosition);
        }
  
        if (this.selectedEndPointIsStartPoint) {
            this.selectedWall.startPoint = finalPosition;
        } else {
            this.selectedWall.endPoint = finalPosition;
        }

        this.selectedWall?.updateAttributesFromEndPoints();
        this.calculateAllHandles();
        
        this.lengthLabel.initialize(s.getEndPointAt(s.length() / 3, false), XcMaths.round(s.length(), 2).toString());
        this.showPivotPath(pivotPoint);
    }

    async constraintTranslateEndpoint(initialHitPoint: Point, currentHitPoint: Point, save: boolean): Promise<void> {
        if (!this.selectedWall) return;
        // Les point de départ et d'arrivée sont projetés orthogonalement sur le segment de la cloison
        const segment = this.selectedWall.segment();
        const orthoInitialHitpoint = segment.getOrthogonalProjection(initialHitPoint);
        const orthoCurrentHitpoint = segment.getOrthogonalProjection(currentHitPoint);
        const delta = new Point(orthoCurrentHitpoint.x - orthoInitialHitpoint.x, orthoCurrentHitpoint.y - orthoInitialHitpoint.y).vector();

        const finalPosition = this.selectedEndPoint?.translate(delta);
        
        if (save) {
            await this.saveWallStretch(finalPosition);
        }

        if (this.selectedEndPointIsStartPoint) {
            this.selectedWall.startPoint = finalPosition;
        } else {
            this.selectedWall.endPoint = finalPosition;
        }

        this.selectedWall.updateAttributesFromEndPoints();
        this.calculateAllHandles();
        
        const s = this.selectedWall.segment();
        this.lengthLabel.initialize(s.getEndPointAt(s.length() / 3, false), XcMaths.round(s.length(), 2).toString());
        this.showPivotPath(s.otherEnd(currentHitPoint));
    }

    // Déplacement libre de la cloison dans toutes les direction
    translateWall(initialHitPoint: Point, currentHitPoint: Point): void {
        if (!this.selectedWall || !this.initialSegment) return;
        const delta = new Point(currentHitPoint.x - initialHitPoint.x, currentHitPoint.y - initialHitPoint.y).vector();
        this.selectedWall.startPoint = this.initialSegment.startPoint.translate(delta);
        this.selectedWall.endPoint = this.initialSegment.endPoint.translate(delta);

        this.selectedWall.updateAttributesFromEndPoints();
        const s = this.selectedWall.segment();
        this.lengthLabel.initialize(s.getEndPointAt(s.length() / 3, false), XcMaths.round(s.length(), 2).toString());
        this.calculateAllHandles();
        
    }

    // Déplacement de la cloison contraint dans la direction du côté
    sideTranslateWall(initialHitPoint: Point, currentHitPoint: Point): void {
        if (!this.selectedWall || !this.initialSegment) return;
        // Les point de départ et d'arrivée sont projetés orthogonalement sur le segment perpendiculaire à la cloison
        const orthoSegment = new Segment(this.initialSegment.midPoint(), this.initialSegment.getOffsetMidPoint(1));
        const orthoInitialHitpoint = orthoSegment.getOrthogonalProjection(initialHitPoint);
        const orthoCurrentHitpoint = orthoSegment.getOrthogonalProjection(currentHitPoint);
        const delta = new Point(orthoCurrentHitpoint.x - orthoInitialHitpoint.x, orthoCurrentHitpoint.y - orthoInitialHitpoint.y).vector();

        this.selectedWall.startPoint = this.initialSegment.startPoint.translate(delta);
        this.selectedWall.endPoint = this.initialSegment.endPoint.translate(delta);
        
        this.selectedWall.updateAttributesFromEndPoints();
        this.calculateAllHandles();
        const s = this.selectedWall.segment();
        this.lengthLabel.initialize(s.getEndPointAt(s.length() / 3, false), XcMaths.round(s.length(), 2).toString());
        
    }

    calculateAllHandles(): void {
        //this.calculateWidthArrow();
        this.calculateSideTranslationArrow();
        this.calculateFreeTranslationEndpointHandle();
        this.calculateConstraintTranslationEndpointHandle();
    }

    calculateSideTranslationArrow(): void {
        if (!this.selectedWall) return;
        const segment = this.selectedWall.segment();
        const width = this.selectedWall.strokeWidth ?? 0;
        // La pointe des flèches se trouve contre la face de la cloison
        const p1 = segment.getOffsetMidPoint((width / 2) + this.sideTranslationArrowOffset);
        // Calule un point décalé de la face de la cloison
        const p2 = segment.getOffsetMidPoint((width / 2) + this.sideTranslationArrowOffset - this.arrowSize);
        // Calcule la rotation de ce point dans une direction et dans la direction opposée
        const p3 = p2.rotate(p1, XcMaths.toRad(30));
        const p4 = p2.rotate(p1, XcMaths.toRad(-30));

        this.sideTranslationArrowPath = "M" + p1.x + " " + p1.y + " " + p3.x + " " + p3.y + " " + p4.x + " " + p4.y + "z";
        this.sideArrowTransform = new SvgTransform({rotationCenter: segment.midPoint(), rotationAngle: 180});    
    }

    calculateFreeTranslationEndpointHandle(): void {
        if (!this.selectedWall) return;
        const segment = this.selectedWall.segment();
        const p1 = segment.startPoint;
        const p2 = segment.endPoint;
        this.freeTranslationStartHandlePath = "M" + (p1.x + 0.05) + " " + p1.y + " " + (p1.x - 0.05) + " " + p1.y + " A 0.05 0.05 0 0 0 " + (p1.x + 0.05) + " " + p1.y + "z";
        this.freeTranslationEndHandlePath = "M" + (p2.x - 0.05) + " " + p2.y + " " + (p2.x + 0.05) + " " + p2.y + " A 0.05 0.05 0 0 0 " + (p2.x - 0.05) + " " + p2.y + "z";
        this.freeTranslationStartpointTransform = new SvgTransform({ rotationCenter: p1, rotationAngle: 270 - XcMaths.toDeg(segment.angle()) });
        this.freeTranslationEndpointTransform = new SvgTransform({ rotationCenter: p2, rotationAngle: 270 - XcMaths.toDeg( segment.angle()) });
    }

    calculateConstraintTranslationEndpointHandle(): void {
        if (!this.selectedWall) return;
        const segment = this.selectedWall.segment();
        const p1 = segment.getEndPointAt(-0.01, false);
        const p2 = segment.getEndPointAt(-0.01, true);
        this.constraintTranslationStartHandlePath = "M" + (p1.x + 0.05) + " " + p1.y + " " + (p1.x - 0.05) + " " + p1.y + " " + p1.x + " " + (p1.y - 0.07) + "z";
        this.constraintTranslationEndHandlePath = "M" + (p2.x - 0.05) + " " + p2.y  + " " + (p2.x + 0.05) + " " + p2.y + " " + p2.x + " " + (p2.y + 0.07) + "z";
        this.constraintTranslationStartpointTransform = new SvgTransform({ rotationCenter: p1, rotationAngle: 270 - XcMaths.toDeg(segment.angle()) });
        this.constraintTranslationEndpointTransform = new SvgTransform({ rotationCenter: p2, rotationAngle: 270 - XcMaths.toDeg( segment.angle()) });
    }

    abortEndpointTranslation(): void {
        if (!this.selectedWall) return;
        if (this.selectedEndPoint) {
            if (this.selectedEndPointIsStartPoint) {
                this.selectedWall.startPoint = this.selectedEndPoint;
            } else {
                this.selectedWall.endPoint = this.selectedEndPoint;
            }
    
            this.selectedWall.updateAttributesFromEndPoints();
        } else {
            if (this.initialSegment) {
                this.selectedWall.startPoint = this.initialSegment.startPoint;
                this.selectedWall.endPoint = this.initialSegment.endPoint;

                this.selectedWall.updateAttributesFromEndPoints();
            }
        }

        this.calculateAllHandles();
        const s = this.selectedWall.segment();
        this.lengthLabel.initialize(s.getEndPointAt(s.length() / 3, false), XcMaths.round(s.length(), 2).toString());

        //this.selectedWall = undefined;
        this.initialSegment = undefined;
        this.hidePivotPath();
        //this.lengthLabel.hide();
        this.showDoors(true);
    }

    onEndHandled?: (clientPoint: Point) => void
    onStartPointHandleMouseDown(e: MouseEvent) {
        e.stopPropagation();
        if (!this.selectedWall) return;
        this.selectedEndPoint = this.selectedWall.startPoint;
        this.selectedEndPointIsStartPoint = true;
        this.selectedAngle = XcMaths.get360DegreeAngle(90 - XcMaths.toDeg(this.selectedWall.segment().reversed().angle()), 3);
        if (this.onEndHandled) {
            this.onEndHandled(new Point(e.clientX, e.clientY));
        } else {
            logError("WallUpdateGizmoVM.onEndHandled n'est pas écouté");
        }
        this.showDoors(false);
    }

    onEndPointHandleMouseDown(e: MouseEvent) {
        e.stopPropagation();
        if (!this.selectedWall) return;
        this.selectedEndPoint = this.selectedWall.endPoint;
        this.selectedEndPointIsStartPoint = false;
        this.selectedAngle = XcMaths.get360DegreeAngle(90 - XcMaths.toDeg(this.selectedWall.segment().angle()), 3);
        if (this.onEndHandled) {
            this.onEndHandled(new Point(e.clientX, e.clientY));
        } else {
            logError("WallUpdateGizmoVM.onEndHandled n'est pas écouté");
        }
        this.showDoors(false);
    }

    onEndConstraintHandled?: (clientPoint: Point) => void
    onConstraintStartHandleMouseDown(e: MouseEvent) {
        e.stopPropagation();
        if (!this.selectedWall) return;
        this.selectedEndPoint = this.selectedWall.startPoint;
        this.selectedEndPointIsStartPoint = true;
        this.selectedAngle = XcMaths.get360DegreeAngle(90 - XcMaths.toDeg(this.selectedWall.segment().reversed().angle()));
        if (this.onEndConstraintHandled) {
            this.onEndConstraintHandled(new Point(e.clientX, e.clientY));
        } else {
            logError("WallUpdateGizmoVM.onEndConstraintHandled n'est pas écouté");
        }
        this.showDoors(false);
    }

    onConstraintEndHandleMouseDown(e: MouseEvent) {
        e.stopPropagation();
        if (!this.selectedWall) return;
        this.selectedEndPoint = this.selectedWall.endPoint;
        this.selectedEndPointIsStartPoint = false;
        this.selectedAngle = XcMaths.get360DegreeAngle(90 - XcMaths.toDeg(this.selectedWall.segment().angle()));
        if (this.onEndConstraintHandled) {
            this.onEndConstraintHandled(new Point(e.clientX, e.clientY));
        } else {
            logError("WallUpdateGizmoVM.onEndConstraintHandled n'est pas écouté");
        }
        this.showDoors(false);
    }

    wallMouseDown?: (clientPoint: Point) => void
    onWallMouseDown(e: MouseEvent) {
        if (this.selectedWall && this.selectionSet.count === 1 && this.isEditable) {
            e.stopPropagation();
            this.initialSegment = this.selectedWall.segment();
            if (this.wallMouseDown) {
                this.wallMouseDown(new Point(e.clientX, e.clientY));
            } else {
                logError("WallUpdateGizmoVM.wallMouseDown n'est pas écouté");
            }
            this.showDoors(false);
        }
    }

    freeTranslationHandleMouseDown?: (clientPoint: Point) => void
    onFreeTranslationHandleMouseDown(e: MouseEvent) {
        e.stopPropagation();
        if (!this.selectedWall) return;
        this.initialSegment = this.selectedWall.segment();
        if (this.freeTranslationHandleMouseDown) {
            this.freeTranslationHandleMouseDown(new Point(e.clientX, e.clientY));
        } else {
            logError("WallUpdateGizmoVM.freeTranslationHandleMouseDown n'est pas écouté");
        }
        this.showDoors(false);
    }

    sideConstraintTranslationHandleMouseDown?: (clientPoint: Point) => void
    onSideConstraintTranslationHandleMouseDown(e: MouseEvent) {
        e.stopPropagation();
        if (!this.selectedWall) return;
        this.initialSegment = this.selectedWall.segment();
        if (this.sideConstraintTranslationHandleMouseDown) {
            this.sideConstraintTranslationHandleMouseDown(new Point(e.clientX, e.clientY));
        } else {
            logError("WallUpdateGizmoVM.sideConstraintTranslationHandleMouseDown n'est pas écouté");
        }
        this.showDoors(false);
    }

    onLengthUpdateMouseDown(e: MouseEvent) {

    }
}
