export class EquipmentFormCategory {
    id: number;
    name: string;
    layerName: string;
    items: {id: number, name: string, count: number}[] = [];

    constructor (id: number, name: string, layerName: string) {
        this.id = id;
        this.name = name;
        this.layerName = layerName;
    }
}