import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BpSvgDef } from '../../../bp-svg-core-model/bp-svg-def';

@Component({
  selector: 'g[xc-bp-svg-def]',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './bp-svg-def.component.svg',
  styleUrls: ['./bp-svg-def.component.scss']
})
export class BpSvgDefComponent {
  @Input() def: BpSvgDef | undefined;
}
