@if (model) { @if (model.floorTasks.length > 0) {
<div class="movelists-container">
  <div class="movelists-header">
    <mat-form-field appearance="outline" class="task-select">
      <mat-label>Etudes courantes</mat-label>
      <mat-select
        #select
        [(value)]="model.selectedTask"
        [compareWith]="model.taskCompare"
        (selectionChange)="model.onTaskSelectionChange()"
      >
        <mat-select-trigger>
          {{ select.value?.taskName }}
        </mat-select-trigger>
        @for (task of model.floorTasks; track task) {
        <mat-option [value]="task">
          <div>
            <h4 class="tasks-menu-header">{{ task.taskName }}</h4>
            @if (task.projectName) {
            <h5 class="tasks-menu-subheader">{{ task.projectName }}</h5>
            } @if (task.taskDeadLine) {
            <h5 class="tasks-menu-subheader">
              Echéance : {{ task.taskDeadLine | date : "dd/MM/yy" }}
            </h5>
            }
            <div class="tasks-status-row">
              <div
                class="tasks-status-shape"
                [style.background-color]="task.taskStatusColor"
              ></div>
              <span class="tasks-status-name">{{ task.taskStatusName }}</span>
            </div>
          </div>
        </mat-option>
        }
      </mat-select>
    </mat-form-field>

    <mat-radio-group
      class="movelists-type"
      [disabled]="model.selectedTask === undefined"
      [(ngModel)]="model.selectedListType"
      (change)="model.selecteListTypeChange()"
    >
      <mat-radio-button [value]="model.moveListsEnum.people"
        >Personnes</mat-radio-button
      >
      <mat-radio-button [value]="model.moveListsEnum.equipment"
        >Equipements</mat-radio-button
      >
    </mat-radio-group>
  </div>

  @if (model.selectedTask) {
  <div class="movelists-content">
    @switch (model.selectedListType) { @case (model.moveListsEnum.people) {
    <xc-people-move-list [model]="model.listData" />
    } @case (model.moveListsEnum.equipment) {
    <xc-equipment-move-list [model]="model.listData" />
    } }
  </div>
  } @else {
  <div class="movelists-content">
    <p>Sélectionnez une étude</p>
  </div>
  }
</div>
} @else {
<div class="movelists-content">
  <p>Il n'y a actuellement aucune étude sur cet étage</p>
</div>
} }
