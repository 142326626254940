import { Component, Input } from '@angular/core';
import { ProvidersDashboardVM } from '../../model/providers-dashboard-vm';

@Component({
  selector: 'xc-providers-dashboard',
  standalone: true,
  imports: [],
  templateUrl: './providers-dashboard.component.html',
  styleUrl: './providers-dashboard.component.scss'
})
export class ProvidersDashboardComponent {
  @Input() model: ProvidersDashboardVM | undefined;
}
