import { BusinessUnitTable } from 'src/app/core/model/db-model/tables/business-unit-table';
import { BusinessUnit } from 'src/app/core/model/data-model/tables/business-unit';
import { BuildingAllocationView } from 'src/app/core/model/data-model/views/building-allocation-view';
import { BuildingAllocationDbView } from 'src/app/core/model/db-model/views/building-allocation-db-view';

export class BusAndBuildingsBusDTO {
    buildingAllocations: BuildingAllocationView[] = [];
    businessUnits: BusinessUnit[] = [];
    
    constructor(dtoData: any) {
        dtoData[BuildingAllocationDbView.databaseTableName].forEach((e: any) => {
            this.buildingAllocations.push(new BuildingAllocationView(e));
        });
        dtoData[BusinessUnitTable.databaseTableName].forEach((e: any) => {
            this.businessUnits.push(new BusinessUnit(e));
        });
    }
}