import { SelectableSvgEntity } from "../../svg/selectable-svg-entity";
import { Point } from "src/app/core/model/geometry-model/point.model";
import { SvgEntityTypesEnum } from "src/app/core/model/svg-model/svg-entity-type-enum";
import { ToolCommandEnum } from "../../commands/tool-command-enum";
import { SvgReticle } from "../../../shared/gizmos/model/svg-reticle";
import { UpdateGizmo } from "./update-gizmo";
import { LineGizmo } from "./line-gizmo";
import { CircleGizmo } from "./circle-gizmo";
import { PathGizmo } from "./path-gizmo";
import { EllipseGizmo } from "./ellipse-gizmo";
import { Grip } from "../../model/grips/grip";
import { BpSvgBoundingBox } from "src/app/ui/pages/layout/real-estate/floor-blueprint/content/bp-svg-core-model/bp-svg-bounding-box";
import { logError } from "src/app/core/services/logging-service";

export class UpdateGizmos {
    entities: SelectableSvgEntity[] = [];
    gizmos: UpdateGizmo<SelectableSvgEntity>[] = [];
    lineGizmo: LineGizmo;
    circleGizmo: CircleGizmo;
    pathGizmo: PathGizmo;
    ellipseGizmo: EllipseGizmo;
    gripClicked?: (point: Point, grip: Grip, entity: SelectableSvgEntity | undefined) => void;
    singleClicked?: (e: SelectableSvgEntity) => void;
    initialGripClickedPosition: Point | undefined = undefined;
    //gridGizmo: GridGizmo;
    reticle: SvgReticle;

    constructor(pixelUnit: number) {
        this._drawingExtents = new BpSvgBoundingBox(0, 0, 10, 10);
        //this.gridGizmo = new GridGizmo(this.drawingExtents);
        this.reticle = new SvgReticle();
        this.lineGizmo = new LineGizmo(pixelUnit);
        this.circleGizmo = new CircleGizmo(pixelUnit);
        this.pathGizmo = new PathGizmo(pixelUnit);
        this.ellipseGizmo = new EllipseGizmo(pixelUnit);
        this.gizmos.push(this.lineGizmo);
        this.gizmos.push(this.circleGizmo);
        this.gizmos.push(this.pathGizmo);
        this.gizmos.push(this.ellipseGizmo);
        this.gizmos.forEach(g => {
            g.gripClicked = (point: Point, grip: Grip, entity: SelectableSvgEntity | undefined) => {
                this.raiseGripClicked(point, grip, entity);
            }
            g.singleClicked = (e: SelectableSvgEntity) => {
                if (this.singleClicked) {
                    this.singleClicked(e);
                } else {
                    logError("UpdateGizmos.singleClicked n'est pas écouté");
                }
            }
        });
    }

    private _drawingExtents: BpSvgBoundingBox;
    get drawingExtents(): BpSvgBoundingBox {
        return this._drawingExtents;
    }
    set drawingExtents(value: BpSvgBoundingBox) {
        this._drawingExtents = value;
        //this.gridGizmo.extents = value;
    }

    raiseGripClicked(point: Point, grip: Grip, entity: SelectableSvgEntity | undefined): void {
        if (this.gripClicked) {
            this.gripClicked(point,grip, entity);
            this.initialGripClickedPosition = grip.point;
        } else {
            logError("UpdateGizmos.gripClicked n'est pas écouté");
        }
    }

    setSelection(entities: SelectableSvgEntity[]): void {
        this.entities = entities;
        this.gizmos.forEach(g => {
            g.setSelection(entities.filter(x=> x.entityType === g.entityType));
        });
    }

    setTool(id: ToolCommandEnum, enabled: boolean): void {
        switch (id) {
            case ToolCommandEnum.grid:
                //this.gridGizmo.displayed = enabled;
                break;
            default:
                break;
        }
    }

    updateNonScalingElementsSize(size: number): void {
        this.gizmos.forEach(g => {
            g.updateSize(size);
        });
        this.reticle.pixelUnit = size;
    }

    getGizmo<T extends UpdateGizmo<SelectableSvgEntity>>(entityType: SvgEntityTypesEnum): T | undefined {
        return this.gizmos.find(x=> x.entityType === entityType) as T;
    }
}