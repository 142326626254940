import { EventListener } from "src/app/core/events/event-listener";
import { ITabPageContent } from "./i-tab-page-content";

export class TabPageContent<T> extends EventListener implements ITabPageContent {
    contentType: string;
    content: T | undefined;

    constructor(uuid: string, contentType: string) {
        super(uuid);
        this.contentType = contentType;
    }

    set(data: T | undefined): void {
        this.content = data;
    }

}