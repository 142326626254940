import { FloorDataImportTable } from '../../db-model/tables/floor-data-import-table';
import { DefinedModelBase } from '../defined-model-base';

export class FloorDataImport extends DefinedModelBase {
    flDaImRequestId!: number;
    flDaImRequestTimestamp!: Date;
    flDaImTempFolderPath!: string;
    flDaImTaskId!: number;
    flDaImVarianteTaskId!: number | null;

    constructor(dtoData: any) {
        super(dtoData, [FloorDataImportTable]);
    }
}