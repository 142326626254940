import { DisplayThemeTable } from './../../db-model/tables/dispaly-theme-table';
import { DefinedModelBase } from "../defined-model-base"

export class DisplayTheme extends DefinedModelBase {
    diThId!: number;
    diThName!: string
    diThCategoryId!: number;
    diThDisplayNameId!: number;

    constructor(dtoData: any, tableDef: any[] = []) {
        super(dtoData, [DisplayThemeTable].concat(tableDef));
    }
}
  