import { Point } from "src/app/core/model/geometry-model/point.model";
import { DxfSvgEntity } from "./dxf-svg-entity";
import { SvgEntity } from "src/app/core/model/svg-model/svg-entity.model";
import { SvgLineService } from "src/app/core/model/svg-model/svg-line-service";
import { SvgLine } from "src/app/core/model/svg-model/svg-line.model";
import { Vector } from "src/app/core/model/geometry-model/vector.model";
import { SvgEntityTypesEnum } from "src/app/core/model/svg-model/svg-entity-type-enum";
import { SvgEntityPoint } from "../../../shared/gizmos/model/svg-entity-point";
import { SvgEntityPointStyleEnum } from "../../../shared/gizmos/model/svg-entity-point-style-enum";
import { SvgEntityParser } from "../../../shared/gizmos/model/svg-entity-parser";

export class DxfSvgLine extends DxfSvgEntity {
    x1: number;
    y1: number;
    x2: number;
    y2: number;

    constructor(dtoData: any) {
        super(dtoData);
        this.x1 = SvgEntity.getNumber(dtoData, "x1");
        this.y1 = SvgEntity.getNumber(dtoData, "y1");
        this.x2 = SvgEntity.getNumber(dtoData, "x2");
        this.y2 = SvgEntity.getNumber(dtoData, "y2");
        this._startPoint = new Point(this.x1, this.y1);
        this._endPoint = new Point(this.x2, this.y2);
    }

    protected override getSelectablePoints(): SvgEntityPoint[] {
        const s = SvgLineService.geometry(this as SvgLine);
        const points = s.points();
        const result = SvgEntityPoint.toStyle(points, SvgEntityPointStyleEnum.end);
        result.push(SvgEntityPoint.fromPoint(s.midPoint(), SvgEntityPointStyleEnum.middle, this.entityId));
        return result;
    }

    protected override getStatement(): string {
        const attributesStatement = SvgEntityParser.getAttributesStatement([["x1", this.x1], ["y1", this.y1], ["x2", this.x2], ["y2", this.y2], ["stroke-width", this.strokeWidth], ["stroke", this.stroke]]);
        return SvgEntityParser.getTagStatement("line", attributesStatement);
        }

    translate(vector: Vector): void {
        this.startPoint = this._startPoint.translate(vector);
        this.endPoint = this._endPoint.translate(vector);
    }

    private _startPoint: Point;
    get startPoint(): Point {
        return this._startPoint;
    }
    set startPoint(value: Point) {
        this.x1 = value.x;
        this.y1 = value.y;
        this._startPoint = value;
    }

    private _endPoint: Point;
    get endPoint(): Point {
        return this._endPoint;
    }
    set endPoint(value: Point) {
        this.x2 = value.x;
        this.y2 = value.y;
        this._endPoint = value;
    }

    static fromValues(layerId: number, startPoint: Point, endPoint: Point, strokeWidth: number): DxfSvgLine {
        return new DxfSvgLine({entityType: SvgEntityTypesEnum.line, entityId: 0, layerId: layerId, x1: startPoint.x, y1: startPoint.y, x2: endPoint.x, y2: endPoint.y, strokeWidth: strokeWidth});
    }
}