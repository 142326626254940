import { BlueprintLayersController } from './blueprint-layers-controller';
import { SvgTransform } from 'src/app/core/model/svg-model/svg-transform.model';
import { BlueprintService } from "src/app/core/services/backend-services/blueprint-service";
import { RealEstateService } from "src/app/core/services/backend-services/real-estate-service";
import Container from "typedi";
import { FloorDataStateEnum } from 'src/app/core/model/data-model/enums/floor-data-state-enum';
import { SvgPattern } from 'src/app/core/model/svg-model/svg-pattern.model';
import { FloorTask } from '../../../shared-model/floor-task';
import { BpSvgDef } from '../../../bp-svg-core-model/bp-svg-def';
import { EventListener } from 'src/app/core/events/event-listener';
import { readableUUID } from 'src/app/core/events/event-listener-uuid';
import { FloorBlueprintEventsEnum } from '../../../../container/model/floor-blueprint-events-enum';
import { logError } from 'src/app/core/services/logging-service';

export class FloorBlueprint extends EventListener {
    floorId: number = 0;
    floorName: string = "";
    viewbox!: string;
    transform: SvgTransform = new SvgTransform({ });
    definitions: BpSvgDef[] = [];
    layersController: BlueprintLayersController = new BlueprintLayersController();
    updateTasks: FloorTask[] = [];
    taskId: number = 0;
    static zoomScale: number = 1;
    //updateTaskChange?: (task: FloorTask, removed: boolean) => void;
    patterns: SvgPattern[] = [];

    bpService: BlueprintService;
    reService: RealEstateService;

    constructor() {
        super(readableUUID(FloorBlueprint.name));

        this.bpService = Container.get(BlueprintService);
        this.reService = Container.get(RealEstateService);
     }
    
    /**
     * Actualise l'épaisseur des traits en fonction du facteur de zoom. Méthode branchée sur le panzoomController.zoomChanged event
     * @param zoomScale 
     * @returns 
     */
    updateStrokeWidth(zoomScale: number): void {
        FloorBlueprint.zoomScale = zoomScale;
        this.layersController.updateStrokeWidth();
        this.layersController.updateFontSize();
    }

    async loadFloorBlueprint(floorId: number): Promise<void> {
        // On vide les tâches puisqu'un autre étage peut avoir été chargé précédemment
        this.updateTasks.splice(0);
        this.floorId = floorId;
        // Charge l'étage
        const floor = await this.reService.loadFloor(floorId);
        // Charge le plan d'étage
        if (floor) {
            this.taskId = floor.flTaskId;
            this.floorName = floor.flName;
            // Télécharge les données graphiques et associées
            const mainTaskData = await this.bpService.loadFloorTaskData(floor.flTaskId);
            this.viewbox = mainTaskData.floorBlueprint.viewbox;
            this.definitions.splice(0);
            this.loadDefinitions(mainTaskData.floorBlueprint);
            this.layersController.intialize(mainTaskData.floorBlueprint, floor.flTaskId);
            this.layersController.nestDefinitions(this.definitions, floor.flTaskId);
            //await this.loadFloorData([floor.taskId]);
            this.updateStrokeWidth(1);

            this.emitEventAsync(FloorBlueprintEventsEnum.blueprintLayersChange);

        } else {
            logError("L'étage [" + floorId + "] n'a pas pu être récupéré");
        }
    }

    async loadBlueprintTask(task: FloorTask): Promise<void> {
        const mainTaskData = await this.bpService.loadFloorTaskData(task.taskId);
        this.loadDefinitions(mainTaskData.floorBlueprint);
        this.layersController.setDatas(mainTaskData.floorBlueprint, task.taskId);
        
        // Les portes qui sont dans des cloisons périphérique de la zone d'étude
        // héritent de leur propriété iTaskZoneBound
        const taskWallsLayer = this.layersController.wallsLayer(task.taskId);
        const taskDoorsLayer = this.layersController.doorsLayer(task.taskId);
        if (taskWallsLayer != null && taskDoorsLayer != null) {
            const zoneBoundWalls = taskWallsLayer.getTaskZoneBoundWalls();
            const zoneBoundWallsIds = zoneBoundWalls.map(x=> x.floorDataId);
            taskDoorsLayer.setTaskZoneBoundDoors(zoneBoundWallsIds);
        }

        this.layersController.nestDefinitions(this.definitions, task.taskId);
        this.updateTasks.push(task);
        // Eclairci les calques de space planning courant pour mettre en évidence la zone de l'étude
        this.layersController.lightenCurrentLayers(0.3);
        // Masque les surfaces, leurs équipements et leur cloisonnement, du plan courant
        this.layersController.displayTaskZone(task.taskId, false);
        // Actualise l'affichage des entités en fonction de leur état
        this.layersController.showDataState(task.taskId, FloorDataStateEnum.Deleted, false);

        this.emitEventAsync(FloorBlueprintEventsEnum.blueprintLayersChange);

        //this.raiseUpdateTaskChange(task, false);
    }

    loadDefinitions(data: any): void {
        // Ajoute les nouvelles définitions
        data.definitions.forEach((d: any) => {
          const existingDef = this.definitions.find(x=> x.id === d.id);
          if (!existingDef) {
            this.definitions.push(new BpSvgDef(d));
          }
        });
    }

    removeTopMostTask(): void {
        if (this.updateTasks.length === 0) return;
        const topMostTask = this.updateTasks[this.updateTasks.length - 1];
        const topMostTaskId = topMostTask.taskId;

        // Retire l'identifiant de la tache
        this.updateTasks.splice(this.updateTasks.length - 1, 1);

        // S'il n'y a plus d'étude sur le plan, repasse l'opacité des calques courant à 1
        //const taskRooms = this.layersController.roomDatas.filter(x=> x.roomSet.dataSet.flDaTaskId === task.taskId);
        if (this.updateTasks.length === 0){
            this.layersController.lightenCurrentLayers(1);
            this.layersController.displayTaskZone(topMostTaskId, true);
        }

        this.layersController.removeUpdateTaskGraphicsData(topMostTaskId);
        this.emitEventAsync(FloorBlueprintEventsEnum.blueprintLayersChange);

        //this.raiseUpdateTaskChange(topMostTask, true);
    }


    setPatterns(patterns: SvgPattern[]): void {
        this.patterns = patterns;
    }
   
    /**
     * 
     * @returns Retourne l'identifiant de la tâche affichée au dessus de toutes les autres. S'il n'y a pas d'étude chargée, retourne l'identifiant de la tâche courante de l'étage
     */
    topMostTaskId(): number {
        if (this.updateTasks.length > 0) {
            return this.updateTasks[this.updateTasks.length - 1].taskId;
        }

        return this.taskId;
    }

    topAndBottomTaskIds(): number[] {
        if (this.updateTasks.length > 0) {
            return [ this.taskId, this.updateTasks[this.updateTasks.length - 1].taskId ]
        }
        return [ this.taskId ];
    }

    allTaskIds(): number[] {
        return [ this.taskId ].concat(this.updateTasks.map(x=> x.taskId));
    }

    hasPlanningTask(): boolean {
        return this.updateTasks.length > 0;
    }

    // raiseUpdateTaskChange(task: FloorTask, removed: boolean): void {
    //     if (this.updateTaskChange) {
    //         this.updateTaskChange(task, removed);
    //     } else {
    //         LoggerService.missingListener("FloorBlueprint.raiseUpdateTaskChange");
    //     }
    // }
}