import { BpSvgDef } from "../../../../bp-svg-core-model/bp-svg-def";
import { BpSvgUse } from "../../../../bp-svg-core-model/bp-svg-use";
import { BpSvgRoom } from "../../../../svg-entities/model/bp-svg-room";
import { SimilarRoom } from "./similar-room";
import { SvgTransform } from "src/app/core/model/svg-model/svg-transform.model";
import { TightBbox } from "./tight-bbox";
import { BlueprintEquipmentLayer } from "../../../../svg-entities/model/layers/blueprint-equipment-layer";

export class SimilarRooms {
    rooms: SimilarRoom[] = [];
    sourceTightBbox: TightBbox;
    sourceAngle: number = 0;
    layer: BlueprintEquipmentLayer;
    validatingId: number | undefined;

    constructor(defs: BpSvgDef[], rooms: BpSvgRoom[], furniture: BpSvgUse[], sourceTightBbox: TightBbox, layer: BlueprintEquipmentLayer) {
        this.sourceTightBbox = sourceTightBbox;
        this.layer = layer;
        this.sourceAngle = this.sourceTightBbox.angle();

        defs.forEach(d => {
            const defIdUses = furniture.filter((x: BpSvgUse)=> x.floorCatalogId === d.id);
            // Ajoute une référence à la définition dans les uses
            defIdUses.forEach((u: BpSvgUse) => {
                u.def = d;
                u.floorModelName = "Mobilier";
            });
        });

        rooms.forEach(r => {
            const f = furniture.filter(x=> x.parentId === r.floorDataId);
            if (f.length > 0) {
                const defsIds = [...new Set(furniture.map(x=> x.def!.id))];
                const d = defs.filter(x=> defsIds.includes(x.id));
                // il faut tourner le local cible du delta entre les angles des bbox ajustées
                const deltaAngle = r.tightBbox!.angle() - this.sourceAngle
                const t = new SvgTransform({rotationAngle: deltaAngle, rotationCenter: r.tightBbox!.center()});
                this.rooms.push(new SimilarRoom(r, f, d, t));
            }
        });
    }
}