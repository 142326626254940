import { Field } from "./field";
import { FieldTypeEnum } from "./field-type-enum";
import { ImageFileField } from "./image-file-field";
import { EventModel } from "../../core/events/event-model";
import { ZDbTypeEnum } from "src/app/core/model/data-model/enums/z-db-type-enum";
import { ListField } from "./list-field";
import { FieldListItem } from "./field-list-item";

export class FieldSet extends EventModel {
    fields: Field<any>[] = [];
    primaryColumnName: string = "";
    rowId: any;
    hasError: boolean = true;
    errorMessages: string[] = [];
    readOnly: boolean = true;
    editing: boolean = false;

    // loadOptionsRequested?: (f: ListField, rowId: number) => Promise<FieldListItem[]>;

    // constructor() {
    //     super();
    //     this.loadOptionsRequested = async (f: ListField, rowId: number): Promise<FieldListItem[]> => {
    //         return [];
    //     }
    // }

    setPrimary(primaryColumnName: string, rowId: any): void {
        this.primaryColumnName = primaryColumnName;
        this.rowId = rowId;
    }

    setFields(fields: Field<any>[], readOnlyByScope: boolean): void {
        this.fields = fields;
        this.listenToSaveRequests();
        this.listenToTabRequest();
        this.listenToErrorsCheck();
        this.listenToImageUploadRequest();
        //this.listenToLoadOptionsRequest();
        //this.listenToCellValueChanged();
        this.readOnly = readOnlyByScope;
    }

    // cellValueChanged?: (f: Field<any>) => void;
    // listenToCellValueChanged(): void {
    //     this.fields.forEach(f => {
    //         f.valueChanged = () => {
    //             this.raiseEvent(this.cellValueChanged, f);
    //         }
    //     });
    // }

    // listenToLoadOptionsRequest(): void {
    //     this.fields.forEach(f => {
    //         if (f.fieldType === FieldTypeEnum.list) {
    //             (f as ListField).loadOptionsRequested = async () => {
    //                 return await this.raiseEventAsync(this.loadOptionsRequested, f, this.rowId);
    //             }
    //         }
    //     });
    // }

    imageUploadRequested?: (file: File, inserting: boolean, maxSize: number, rowId: number) => Promise<string>;
    listenToImageUploadRequest(): void {
        this.fields.forEach(f => {
            if(f.fieldType === FieldTypeEnum.string && f.dbType === ZDbTypeEnum.ImageFile) {
                (f as ImageFileField).imageUploadRequested = async (file: File, inserting: boolean, maxSize: number): Promise<string> => {
                    return await this.raiseEventAsync(this.imageUploadRequested, file, inserting, maxSize, this.rowId);
                }
            }
        });
    }

    saveRequested?:(field: Field<any>) => void;
    listenToSaveRequests(): void {
        this.fields.forEach(f => {
            f.saveRequested = () => {
                this.raiseEvent(this.saveRequested, f);
            }
        });
    }

    listenToTabRequest(): void {
        this.fields.forEach(f => {
            f.tabRequested = () => {
                const fieldIndex = this.fields.indexOf(f);
                let nextIndex = this.getNextEditableFieldIndex(fieldIndex, fieldIndex);
                this.fields[nextIndex].editing = true;
            }
        });
    }

    listenToErrorsCheck(): void {
        this.fields.forEach(c => {
            c.errorsCheckRequested = () => {
                this.checkErrors();
            }
        });
    }

    checkErrors(): void {
        let result = false;
        let messages: string[] = [];
        this.fields.forEach(c => {
            // si le champ est en lecture seule, inutile de contrôler ses containtes
            if (!c.readOnly) {
                const hasError = c.errorsCheck();
                if (hasError.length > 0) {
                    messages = messages.concat(hasError);
                    result = true;
                }
            }
        });
        this.hasError = result;
        this.errorMessages = messages;
    }

    values(): any {
        const result: any = {};

        result[this.primaryColumnName] = this.rowId;
        this.fields.forEach(c => {
            result[c.colId] = c.value;
        });

        return result;
    }

    private getNextEditableFieldIndex(startIndex: number, index: number): number {
        let nextIndex = this.getNextCellIndex(index);
        if (nextIndex === startIndex) return startIndex;
        const nextField = this.fields[nextIndex];
        if (nextField.readOnly || nextField.fieldType === FieldTypeEnum.list) {
            nextIndex = this.getNextEditableFieldIndex(startIndex, nextIndex);
        }
        return nextIndex;
    }

    private getNextCellIndex(index: number): number {
        let nextIndex = index + 1;
        if (nextIndex > this.fields.length -1) {
            return 0;
        }
        return nextIndex;
    }

    field(colId: string): Field<any> | undefined {
        return this.fields.find(x=> x.colId === colId);
    }

    fieldClick(field: Field<any>): void {
        field.inputClicked();
    }

    insertionRequested?: () => void;
    saveButtonClick(): void {
        this.raiseEvent(this.insertionRequested);
    }

    deletionRequested?: () => void;
    deleteButtonClick(): void {
        this.raiseEvent(this.deletionRequested);
    }

    insertionCanceled?: () => void;
    cancelButtonClick(): void {
        this.raiseEvent(this.insertionCanceled);
    }
}