import { MobilityBrowserSidePanelContentComponent } from './../mobility-browser-side-panel-content/mobility-browser-side-panel-content.component';
import { Component, Input } from '@angular/core';
import { MobilityBrowserVM } from '../../model/mobility-browser-vm';
import { MobilityProjectsArchiveViewerComponent } from '../../../nested/projects-archives/view/mobility-projects-archive-viewer/mobility-projects-archive-viewer.component';
import { MobilityProjectsScheduleComponent } from '../../../nested/projects-schedule/view/mobility-projects-schedule/mobility-projects-schedule.component';
import { MobilityContributorsWorkloadDashboardComponent } from '../../../nested/contributors-workload/view/mobility-contributors-workload-dashboard/mobility-contributors-workload-dashboard.component';
import { SplitterModule } from 'primeng/splitter';
import { MVVMEventDestroyLifeCycle } from 'src/app/components-lib/shared-model/mvvm-events-destroy-lifecycle';
import { MobilityProjectsGanttBrowserComponent } from '../../../side-panel/projects-gantt/view/mobility-projects-gantt-browser/mobility-projects-gantt-browser.component';

@Component({
  selector: 'xc-mobility-browser',
  standalone: true,
  imports: [
    SplitterModule,
    MobilityProjectsArchiveViewerComponent,
    MobilityProjectsScheduleComponent,
    MobilityContributorsWorkloadDashboardComponent,
    MobilityProjectsGanttBrowserComponent
  ],
  templateUrl: './mobility-browser.component.html',
  styleUrl: './mobility-browser.component.scss'
})
export class MobilityBrowserComponent extends MVVMEventDestroyLifeCycle {
  @Input() override model: MobilityBrowserVM | undefined;
}
