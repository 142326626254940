import { Component, Input } from '@angular/core';
import { MapToolbarVM } from '../map-toolbar-vm';
import { MVVMEventDestroyLifeCycle } from 'src/app/components-lib/shared-model/mvvm-events-destroy-lifecycle';
import { IconFieldModule } from 'primeng/iconfield';
import { InputIconModule } from 'primeng/inputicon';
import { InputTextModule } from 'primeng/inputtext';

@Component({
  selector: 'xc-map-toolbar',
  standalone: true,
  imports: [IconFieldModule, InputIconModule, InputTextModule],
  templateUrl: './map-toolbar.component.html',
  styleUrl: './map-toolbar.component.scss'
})
export class MapToolbarComponent extends MVVMEventDestroyLifeCycle {
  @Input() override model: MapToolbarVM | undefined;
}
