import { EquipmentStorageDbView } from "../../db-model/views/equipment-storage-db-view";
import { EquipmentItem } from "../tables/equipment-item";
import { EquipmentStorageArea } from "../tables/equipment-storage-area";

export class EquipmentStorageView extends EquipmentStorageArea {
    flCgId!: number;
    equipmentItem: EquipmentItem;

    constructor(dtoData: any) {
        super(dtoData, [EquipmentStorageDbView]);
        this.equipmentItem = new EquipmentItem(dtoData);
    }
}