import { TaskStatusTable } from './../../db-model/tables/task-status-table';
import { DefinedModelBase } from '../defined-model-base';

export class TaskStatus extends DefinedModelBase {
    taStId!: number;
    taStCode!: string;
    taStName!: string;
    taStDisplayNameId!: number;
    taStColor!: string;

    constructor(dtoData: any, tableDef: any[] = []) {
        super(dtoData, [TaskStatusTable].concat(tableDef));
    }
}  