import { map } from 'rxjs';
import { Point } from "src/app/core/model/geometry-model/point.model";
import { BlueprintEquipmentLayer } from "../../../../svg-entities/model/layers/blueprint-equipment-layer";
import { FloorModelEnum } from "src/app/core/model/data-model/enums/floor-model-enum";
import { BpSvgPath } from "../../../../bp-svg-core-model/bp-svg-path";
import { getEndPoints, getMiddlePoints } from "src/app/core/model/geometry-model/geometric-elements-builder";
import { FloorBlueprint } from "../floor-blueprint";
import { WallInteraction } from "./wall-interaction";
import { IntersectedSegment } from "src/app/core/model/geometry-model/intersected-segment";
import { PolygonService } from "src/app/core/model/geometry-model/polygon-service";
import { BpSvgWall } from "../../../../svg-entities/model/bp-svg-wall";
import { BlueprintLayer } from "../../../../svg-entities/model/layers/blueprint-layer";
import { SvgDOM } from "./svg-dom";
import { SvgEntityPointStyleEnum } from "src/app/ui/pages/graphic-works/shared/gizmos/model/svg-entity-point-style-enum";
import { IntersectionService } from "src/app/core/model/geometry-model/intersection-service";
import { SvgPathService } from 'src/app/core/model/svg-model/svg-path-service';
import { Segment } from 'src/app/core/model/geometry-model/segment.model';

export class GripsDetection {
    static getUseTargetGrips(blueprint: FloorBlueprint, floorDataId: number, gripOptions: number[], hitPoint: Point): Point[] {
        const uses = blueprint.layersController.layer<BlueprintEquipmentLayer>(FloorModelEnum.Furniture, blueprint.topMostTaskId())?.typedData();
        if (!uses) return [];

        const use = uses.filter(x => x.floorDataId === floorDataId)[0];
        const contour = use.def?.defsGeometry[0] as BpSvgPath;

        if (!use || !contour) return [];

        let temp: Point[] = [];
        if (gripOptions.includes(SvgEntityPointStyleEnum.end)) {
            temp.push(...getEndPoints(contour.geometry));
        }
        if (gripOptions.includes(SvgEntityPointStyleEnum.middle)) {
            temp.push(...getMiddlePoints(contour.geometry));
        }
        
        let contourVertices: Point[] = [];
        temp.forEach(p => {
            const tp = use.transform?.transformedPoint(p);
            if (tp) {
                contourVertices.push(tp);
            }
        });

        return contourVertices;
    }

    static detectWallExtentsGrip(clientHitPoint: Point, svgHitPoint: Point, wallInteraction: WallInteraction): void {
        if(!clientHitPoint || 
            wallInteraction.planningTaskWallsLayer == null ||
            wallInteraction.updateGizmo.selectedWall == null ||
            wallInteraction.partitioningFrameLayer == null ||
            wallInteraction.insideContoursLayer == null ||
            wallInteraction.coreContoursLayer == null) {
            return;
        }

        const wall = wallInteraction.updateGizmo.selectedWall;
        const selectedWallSegment = wall.segment().stretched(1);
        let selectedHandlePoint = wall.endPoint;

        if (wallInteraction.updateGizmo.selectedEndPointIsStartPoint) {
            selectedHandlePoint = wall.startPoint;
        }


        const reticle = PolygonService.centeredSquare(selectedHandlePoint, 0.5);
        const wallSegments = wallInteraction.planningTaskWallsLayer.getActiveWallsSegments(true, [wall.floorDataId]);
        const reticleWallsSegments = BlueprintLayer.getIntersectedSegments(reticle, wallSegments, wallInteraction.planningTaskWallsLayer.id, wallInteraction.planningTaskWallsLayer.taskId);

        // Retourne l'intersection projetée entre la cloison modifiée et la cloison détectée
        // Les segments sont étirés de façon à trouver éventuellement l'intersection projetée
        reticleWallsSegments.forEach(rw => {
            let targetSegment = rw.stretched(1);
            const intersectionPoint = targetSegment.getIntersect(selectedWallSegment);
            if (intersectionPoint != null) {
                wallInteraction.updateGizmo.targetGrips.loadFromPoints([intersectionPoint]);
                wallInteraction.currentCommand.magnetAttraction = 0.5;
            } else {
                // Si l'intersection n'a pas été trouvée c'est peut être que les segments sont colinéaires
                if (selectedWallSegment.isColinearTo(targetSegment)) {
                    // Dans ce cas il n'y a qu'un grip proposé qui est le endpoint le plus proche
                    targetSegment = wall.segment();
                    const nearestEnd = targetSegment.nearestEndPoint(selectedHandlePoint);
                    wallInteraction.updateGizmo.targetGrips.loadFromPoints([nearestEnd]);
                }
            }
        });

        // Le réticule détecte une trame de cloisonnement
        const partitioningFrameSegments = wallInteraction.partitioningFrameLayer.segments;
        const partitioningFrames = BlueprintLayer.getIntersectedSegments(reticle, partitioningFrameSegments, wallInteraction.partitioningFrameLayer.id, wallInteraction.partitioningFrameLayer.taskId);
        partitioningFrames.forEach(pf => {
            let targetSegment = pf.stretched(1);
            const intersectionPoint = targetSegment.getIntersect(selectedWallSegment);
            if (intersectionPoint != null) {
                wallInteraction.updateGizmo.targetGrips.loadFromPoints([intersectionPoint]);
            }
        });

        // Le réticule détecte la façade
        const insideSegments = wallInteraction.insideContoursLayer.segments;
        const insideIntersects = BlueprintLayer.getIntersectedSegments(reticle, insideSegments, wallInteraction.insideContoursLayer.id, wallInteraction.insideContoursLayer.taskId);
        insideIntersects.forEach(ii => {
            let targetSegment = ii.stretched(1);
            const intersectionPoint = targetSegment.getIntersect(selectedWallSegment);
            if (intersectionPoint != null) {
                wallInteraction.updateGizmo.targetGrips.loadFromPoints([intersectionPoint]);
            }
        });

        // Le réticule détecte un contour de noyau
        const coreSegments = wallInteraction.coreContoursLayer.segments;
        const coreIntersects = BlueprintLayer.getIntersectedSegments(reticle, coreSegments, wallInteraction.coreContoursLayer.id, wallInteraction.coreContoursLayer.taskId);
        coreIntersects.forEach(ci => {
            let targetSegment = ci.stretched(1);
            const intersectionPoint = targetSegment.getIntersect(selectedWallSegment);
            if (intersectionPoint != null) {
                wallInteraction.updateGizmo.targetGrips.loadFromPoints([intersectionPoint]);
            }
        });

        wallInteraction.updateGizmo.targetGrips.selectNearest(selectedHandlePoint);
        wallInteraction.currentCommand.magnetAttraction = wallInteraction.magnetAttraction;
    }

    static detectWallInsertTargetGrips(svgHitPoint: Point, wallInteraction: WallInteraction): void {
        if (wallInteraction.planningTaskWallsLayer == null || 
            wallInteraction.partitioningFrameLayer == null ||
            wallInteraction.insideContoursLayer == null ||
            wallInteraction.coreContoursLayer == null) return;
        // Défini le réticule de détection
        const reticle = PolygonService.centeredSquare(svgHitPoint, 0.15);

        // Recherche les segments de cloison, de contours techniques ou de trame de cloisonnement traversant le réticule
        
        // Lorsqu'on s'accroche sur la trame de cloisonnement, le segment en cours d'insertion s'oriente comme le segment de trame
        // Lorsqu'on s'accroche sur un segment de contour ou une autre cloison, le segment en cours d'insertion s'oriente, selon le cas
        // perpendiculairement ou dans le prolongement du segment détecté
        // - perpendiculairement si aucune extrémité du segment détecté n'est dans le réticule
        // - dans le prolongement si une extrémité du segment détecté est dans le réticule

        // L'accrochage à une autre cloison est prioritaire
        // Le réticule détecte une autre cloison
        const wallSegments = wallInteraction.planningTaskWallsLayer.getActiveWallsSegments(true, []);
        const wallIntersects = BlueprintLayer.getIntersectedSegments(reticle, wallSegments, wallInteraction.planningTaskWallsLayer.id, wallInteraction.planningTaskWallsLayer.taskId);
        if (wallIntersects.length === 1) {
            const rooms = wallInteraction.floorBlueprint.layersController.rooms(wallInteraction.floorBlueprint.topMostTaskId()).map(x=>SvgPathService.getPolygon(x.d!, true));
            const wi = wallIntersects[0];
            // si un endpoint de la cloison détectée est dans le réticule
            // la cloison est insérée dans le prolongement
            // sinon elle est insérée perpendiculairement

            let gotIt: boolean = false;

            // en cas d'insertion dans le prolongement
            // le segment inséré est tronqué lorsque le second point est hors de la zone d'étude
            // et l'insertion n'est pas réalisée si le segment résultant est trop petit
            let startPoint: Point = wallInteraction.updateGizmo.transientSegment.startPoint;
            let endPoint: Point = wallInteraction.updateGizmo.transientSegment.endPoint;
            if (reticle.contains(wi.startPoint)) {
                // Insertion dans le prolongement
                let tmp = wi.getEndPointAt(-1, false);
                const endPosition = PolygonService.endsPositions(rooms, [tmp]);
                const tmpSeg = new Segment(wi.startPoint, tmp);
                // wallInteraction.updateGizmo.transientSegment.startPoint = wi.startPoint;
                // wallInteraction.updateGizmo.transientSegment.endPoint = endPoint;     
                if (!endPosition[0].isInside) {
                    const bounds = wallInteraction.planningTaskWallsLayer.getTaskZoneBoundWalls().map(x=> x.segment());
                    const boundIntersect = IntersectionService.getSegmentsIntersects(bounds, tmpSeg);
                    if (boundIntersect.length === 1 && tmpSeg.length() >= 0.05) {
                        endPoint = boundIntersect[0];
                        startPoint = wi.startPoint;
                        gotIt = true;
                    }
                } else {
                    endPoint = tmp;
                    startPoint = wi.startPoint;
                    gotIt = true;
                }
            }

            if (reticle.contains(wi.endPoint)) {
                // Insertion dans le prolongement
                let tmp = wi.getEndPointAt(-1, true);
                const endPosition = PolygonService.endsPositions(rooms, [tmp]);
                const tmpSeg = new Segment(wi.endPoint, tmp);
                // wallInteraction.updateGizmo.transientSegment.startPoint = wi.endPoint;
                // wallInteraction.updateGizmo.transientSegment.endPoint = endPoint;
                if (!endPosition[0].isInside) {
                    const bounds = wallInteraction.planningTaskWallsLayer.getTaskZoneBoundWalls().map(x=> x.segment());
                    const boundIntersect = IntersectionService.getSegmentsIntersects(bounds, tmpSeg);
                    if (boundIntersect.length === 1 && tmpSeg.length() >= 0.05) {
                        endPoint = boundIntersect[0]
                        startPoint = wi.endPoint;
                        gotIt = true;
                    }
                } else {
                    endPoint = tmp;
                    startPoint = wi.endPoint;
                    gotIt = true;
                }
            }

            if (gotIt) {
                wallInteraction.updateGizmo.transientSegment.startPoint = startPoint;
                wallInteraction.updateGizmo.transientSegment.endPoint = endPoint;
                wallInteraction.currentCommand.magnetAttraction = wallInteraction.magnetAttraction;
                return;
            }

            // Calcul du côté où se trouve le réticule par rapport à la cloison détectée
            const isRigthHand = wi.isRightHand(svgHitPoint);
            let offsetValue = 1;
            if (!isRigthHand) {
                offsetValue = -1;
            }

            // Insertion perpendiculaire
            // celle-ci n'est possible que si on est du bon côté lorsque la cloison détectée est en périphérie de la zone d'étude
            const orthogonalOffsetPoint = wi.getOrthogonalOffset(wallInteraction.updateGizmo.transientSegment.startPoint, offsetValue);
            const endPosition = PolygonService.endsPositions(rooms, [orthogonalOffsetPoint]);
            if (endPosition[0].isInside) {
                wallInteraction.updateGizmo.transientSegment.startPoint = wi.getOrthogonalProjection(svgHitPoint);
                wallInteraction.updateGizmo.transientSegment.endPoint = orthogonalOffsetPoint;
            }

            wallInteraction.currentCommand.magnetAttraction = wallInteraction.magnetAttraction;
            return;
        }

        // Le réticule détecte une trame de cloisonnement
        const partitioningFrameSegments = wallInteraction.partitioningFrameLayer.segments;
        const partitioningIntersects = BlueprintLayer.getIntersects(reticle, partitioningFrameSegments, wallInteraction.partitioningFrameLayer.id, wallInteraction.partitioningFrameLayer.taskId);
        if (partitioningIntersects.length > 0) {
            // Le segment est accroché au point le plus proche et orienté comme la ligne de trame
            // Il n'est pas utile d'afficher les grips
            const nearestIntersect = IntersectedSegment.getNearest(partitioningIntersects, svgHitPoint);
            wallInteraction.updateGizmo.transientSegment.startPoint = nearestIntersect.segment.startPoint;
            wallInteraction.updateGizmo.transientSegment.endPoint = nearestIntersect.segment.endPoint;

            // La cloison insérée ne doit pas sortir de la zone d'étude
            // alors que la trame de cloisonnement peut être beaucoup plus étendue
            // on va récupérer les éléments de cloison bordant la zone d'étude
            // pour tronquer le segment créé sur la base de la ligne de trame

            const bounds = wallInteraction.planningTaskWallsLayer.getTaskZoneBoundWalls().map(x=> x.segment());
            const boundIntersect = IntersectionService.getSegmentsIntersects(bounds, wallInteraction.updateGizmo.transientSegment);
            // s'il y a deux intersections, il suffit de retourner le segment entre les deux points d'intersection
            if (boundIntersect.length === 2) {
                wallInteraction.updateGizmo.transientSegment.startPoint = boundIntersect[0];
                wallInteraction.updateGizmo.transientSegment.endPoint = boundIntersect[1];
            }
            // s'il n'y a qu'une intersection c'est plus compliqué
            // il faut savoir de quel côté tronquer le segment
            // le plus simple est de procéder par élimination en trouvant d'abord le point qui est à l'inérieur de la zone d'étude
            // puis en constituant un segment avec celui-ci et le point d'intersection trouvé
            if (boundIntersect.length === 1) {
                const rooms = wallInteraction.floorBlueprint.layersController.rooms(wallInteraction.floorBlueprint.topMostTaskId()).map(x=>SvgPathService.getPolygon(x.d!, true));
                const endsPositions = PolygonService.endsPositions(rooms, wallInteraction.updateGizmo.transientSegment.points());
                if (endsPositions[0].isInside) {
                    // le startpoint est dans la zone d'étude
                    wallInteraction.updateGizmo.transientSegment.startPoint = endsPositions[0].p;
                    wallInteraction.updateGizmo.transientSegment.endPoint = boundIntersect[0];
                    } else {
                    // le endpoint est dans la zone d'étude
                    wallInteraction.updateGizmo.transientSegment.startPoint = boundIntersect[0];
                    wallInteraction.updateGizmo.transientSegment.endPoint = endsPositions[1].p;
                }
            }

            wallInteraction.currentCommand.magnetAttraction = wallInteraction.magnetAttraction;
            return;
        }

        // Le réticule détecte la façade
        const insideSegments = wallInteraction.insideContoursLayer.segments;
        const insideIntersects = BlueprintLayer.getIntersects(reticle, insideSegments, wallInteraction.insideContoursLayer.id, wallInteraction.insideContoursLayer.taskId).map(x=> x.segment);

        // TODO : s'il y deux segments dans le réticule
        // ils peuvent former un angle et dans ce cas il faudrait placer la cloison
        // à l'intersection des deux segments avec un 1/2 angle ?
        
        if (insideIntersects.length === 1) {
            // Le segment est accroché sur un endpoint s'il y en a un à l'intérieur du réticule
            // sinon sur la projection du hitpoint sur la façade
            const wi = insideIntersects[0];

            if (wallInteraction.currentCommand.selectedGripOptions.includes(SvgEntityPointStyleEnum.end)) {
                // Si l'option d'accrochage extrémité est activée
            } else {
                const isRigthHand = wi.isRightHand(svgHitPoint);
                let offsetValue = 1;
                if (!isRigthHand) {
                    offsetValue = -1;
                }

                const rooms = wallInteraction.floorBlueprint.layersController.rooms(wallInteraction.floorBlueprint.topMostTaskId()).map(x=>SvgPathService.getPolygon(x.d!, true));
                const orthogonalOffsetPoint = wi.getOrthogonalOffset(wallInteraction.updateGizmo.transientSegment.startPoint, offsetValue);
                const endPosition = PolygonService.endsPositions(rooms, [orthogonalOffsetPoint]);
                if (endPosition[0].isInside) {
                    wallInteraction.updateGizmo.transientSegment.startPoint = wi.getOrthogonalProjection(svgHitPoint);
                    wallInteraction.updateGizmo.transientSegment.endPoint = orthogonalOffsetPoint;

                    wallInteraction.currentCommand.magnetAttraction = wallInteraction.magnetAttraction;
                    return;
                }
            }
        }

        // Le réticule détecte un contour de noyau
        const coreSegments = wallInteraction.coreContoursLayer.segments;
        const coreIntersects = BlueprintLayer.getIntersects(reticle, coreSegments, wallInteraction.coreContoursLayer.id, wallInteraction.coreContoursLayer.taskId).map(x=> x.segment);
        if (coreIntersects.length === 1) {
            // Le segment est accroché sur un endpoint s'il y en a un à l'intérieur du réticule
            // sinon sur la projection du hitpoint sur le noyau
            const wi = insideIntersects[0];

            if (wallInteraction.currentCommand.selectedGripOptions.includes(SvgEntityPointStyleEnum.end)) {
                // Si l'option d'accrochage extrémité est activée
            } else {
                const isRigthHand = wi.isRightHand(svgHitPoint);
                let offsetValue = 1;
                if (!isRigthHand) {
                    offsetValue = -1;
                }

                const rooms = wallInteraction.floorBlueprint.layersController.rooms(wallInteraction.floorBlueprint.topMostTaskId()).map(x=>SvgPathService.getPolygon(x.d!, true));
                const orthogonalOffsetPoint = wi.getOrthogonalOffset(wallInteraction.updateGizmo.transientSegment.startPoint, offsetValue);
                const endPosition = PolygonService.endsPositions(rooms, [orthogonalOffsetPoint]);
                if (endPosition[0].isInside) {
                    wallInteraction.updateGizmo.transientSegment.startPoint = wi.getOrthogonalProjection(svgHitPoint);
                    wallInteraction.updateGizmo.transientSegment.endPoint = orthogonalOffsetPoint;

                    wallInteraction.currentCommand.magnetAttraction = wallInteraction.magnetAttraction;
                    return;
                }
            }
        }
        
        // const intersects = coreIntersects.concat(insideIntersects).concat(wallIntersects);
        // if (intersects.length > 0) {
        //     wallInteraction.wallUpdateGizmo.targetGrips.loadFromPoints(IntersectedSegment.getMainPoints(intersects, true));
        //     wallInteraction.currentCommand.magnetAttraction = 0.5;
        // }
    }
}