import { EquipmentCatalogViewSet } from "src/app/core/model/data-model/view-set/equipment-catalog-view-set";
import { EquipmentCatalogTable } from "src/app/core/model/db-model/tables/equipment-catalog-table";
import { FloorDataTable } from "src/app/core/model/db-model/tables/floor-data-table";

export class PurchaseDetailDTO {
    equipmentCatalog: EquipmentCatalogViewSet;
    index: number;
    planningReservedTaskName: string;
    floorDataId: number;
    taskId: number;
    storeAreaTargetName: string = "NA";
    storeAreaTargetId: number | null = null;

    constructor(dtoData: any) {
        this.equipmentCatalog = new EquipmentCatalogViewSet(dtoData[EquipmentCatalogTable.databaseTableName]);
        this.index = dtoData["Index"];
        this.planningReservedTaskName = dtoData["PlanningReserved"];
        this.floorDataId = dtoData[FloorDataTable.flDaId];
        this.taskId = dtoData["planningTaskId"];
    }
}