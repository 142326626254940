import { SvgEntityTypesEnum } from "src/app/core/model/svg-model/svg-entity-type-enum";
import { UpdateGizmo } from "./update-gizmo";
import { Vector } from "src/app/core/model/geometry-model/vector.model";
import { SelectableSvgEllipse } from "../../svg/selectable-svg-ellipse";

export class EllipseGizmo extends UpdateGizmo<SelectableSvgEllipse> {
    isXRadiusGrip: boolean = false;

    constructor(pixelUnit: number) {
        super(pixelUnit, SvgEntityTypesEnum.ellipse);
    }

    translateEllipses(delta: Vector): void {
        this.entities.forEach(e => {
            e.translate(delta);
            e.updateSelectablePoints();
            this.grips.resetPoints(e);
        });
        this.grips.items.forEach(g => {
            g.point = g.point.translate(delta);
        });
    }

    endUpdate(): void {
        this.entities.forEach(e => {
            e.updateSelectablePoints();
            this.grips.resetPoints(e);
        });
    }

    startChangeRadius(): void {
        if (!this.currentEntity || !this.currentGrip) return;
        this.isXRadiusGrip = this.currentEntity.isXRadiusGrip(this.currentGrip.point);
    }

    changeRadius(delta: Vector): void {
        if (!this.currentEntity || !this.currentGrip) return;

        // Les changements ne s'appliquent qu'aux grips de quadrant
        // Le changement de rayon est calculé sur l'entité courante
        const newGripPos = this.currentGrip.point.translate(delta);
        console.log(this.isXRadiusGrip);
        const newRadius = this.currentEntity.center().distanceTo(newGripPos);
        const radiusDelta = this.currentEntity.radiusDelta(newRadius, this.isXRadiusGrip);
        this.entities.forEach(e => {
            e.changeRadius(radiusDelta, this.isXRadiusGrip);
            e.updateSelectablePoints();
            this.grips.resetPoints(e);

            // Les grips de quadrant sont dans l'odre de récupération des points de quadrant
            // right, top, left, bottom
            // const grips = this.grips.items.filter(x=> x.parentId === e.entityId && !x.point.equals(e.center()));
            // grips[0].point = new Point(e.cx + e.r, e.cy);
            // grips[1].point = new Point(e.cx, e.cy + e.r);
            // grips[2].point = new Point(e.cx - e.r, e.cy);
            // grips[3].point = new Point(e.cx, e.cy - e.r);
        });
    }
}