import { SvgLine } from "src/app/core/model/svg-model/svg-line.model";
import { SvgPhotoMarkerVM } from "./svg-photo-marker-vm";
import { SimulationPhoto } from 'src/app/core/model/data-model/tables/simulation-photo';
import { DateUtils } from 'src/app/core/model/static-functions/date-utils';
import { PathBuilder } from 'src/app/core/model/svg-model/svg-path-builder';
import { Point } from 'src/app/core/model/geometry-model/point.model';
import { SimulationTimelinesDTO } from "src/app/core/services/backend-services/dto/simulation-timelines-dto";
import { logError } from "src/app/core/services/logging-service";

export class SvgTimelineVM {
    timelineDTO: SimulationTimelinesDTO;
    y: number;
    startOffset: number;
    dayWidth: number;
    minDate: Date;
    line: SvgLine;
    textMaxWidth: number;
    timelineZonePadding: number;
    photosMarkers: SvgPhotoMarkerVM[] = [];
    endMilestonePath: string;

    constructor(timelineDTO: SimulationTimelinesDTO, labelMaxWidth: number, timelineZonePadding: number, startOffset: number, minDate: Date, dayWidth: number, yPos: number) {
        this.timelineDTO = timelineDTO;
        this.textMaxWidth = labelMaxWidth;
        this.timelineZonePadding = timelineZonePadding;
        this.y = yPos;
        this.startOffset = startOffset;
        this.minDate = minDate;
        this.dayWidth = dayWidth;

        const step = DateUtils.dateDiffInDays(this.minDate, timelineDTO.siStartDate);
        this.line = SvgLine.fromValues(labelMaxWidth + timelineZonePadding + (step * this.dayWidth), this.y, labelMaxWidth + timelineZonePadding + 100, this.y);

        this.timelineDTO.photos.forEach(p => {
            this.addPhotoMarker(p);
        });
        this.photosMarkers[0].isCurrentOccupation = true;
        this.photosMarkers[this.photosMarkers.length - 1].isLast = true;

        // Jalon de fin de simulation
        this.endMilestonePath = PathBuilder.getPath([
            new Point(-1, 0),
            new Point(0, -1),
            new Point(1, 0),
            new Point(0, 1)
        ], true);
    }

    addPhoto(photo: SimulationPhoto): void {
        // La photo précédente a été forcée en inactive par le backend
        this.timelineDTO.photos[this.timelineDTO.photos.length - 1].siPhIsActive = false;
        this.photosMarkers[this.photosMarkers.length - 1].isLast = false;

        this.timelineDTO.photos.push(photo);
        this.addPhotoMarker(photo, true);
    }

    removeLastPhoto(): void {
        this.timelineDTO.photos.splice(this.timelineDTO.photos.length - 1);
        this.photosMarkers.splice(this.photosMarkers.length - 1);
    }

    photoLabelEditRequested?: (pm: SvgPhotoMarkerVM, clientX: number, clientY: number) => void;
    addPhotoMarker(photo: SimulationPhoto, isLast: boolean = false): void {
        const step = DateUtils.dateDiffInDays(this.minDate, photo.siPhPhotoDate);
        const newPhoto = new SvgPhotoMarkerVM(photo, this.textMaxWidth + this.timelineZonePadding + (step * this.dayWidth), this.y);
        newPhoto.isLast = isLast;
        this.photosMarkers.push(newPhoto);
        newPhoto.labelEditRequested = (pm: SvgPhotoMarkerVM, clientX: number, clientY: number) => {
            if (this.photoLabelEditRequested) {
                this.photoLabelEditRequested(pm, clientX, clientY);
            } else {
                logError("SvgTimelineVM.photoLabelEditRequested n'est pas écouté");
            }
            }
    }

    simulationLabelEditRequested?: (s: SvgTimelineVM) => void;
    simulationLabelClick(e: MouseEvent): void {
        if (this.simulationLabelEditRequested) {
            this.simulationLabelEditRequested(this);
        } else {
            logError("SvgTimelineVM.simulationLabelEditRequested n'est pas écouté");
        }
    }

    stackingViewRequested?: (p: SvgPhotoMarkerVM) => void;
    photoMarkerClick(p: SvgPhotoMarkerVM): void {
        // Demande d'affichage de la vue de stacking de la photo
        if (this.stackingViewRequested) {
            this.stackingViewRequested(p);
        } else {
            logError("SvgTimelineVM.stackingViewRequested n'est pas écouté");
        }
    }

    photoMarkerGizmoRequested?: (p: SvgPhotoMarkerVM, show: boolean) => void;
    photoMarkerMouseEnter(p: SvgPhotoMarkerVM): void {
        if (this.photoMarkerGizmoRequested) {
            if (p.selectable) {
                this.photoMarkerGizmoRequested(p, true);
            }
        } else {
            logError("SvgTimelineVM.photoMarkerGizmoRequested n'est pas écouté");
        }
    }
}