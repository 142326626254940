import { TaskChangeLogTable } from './../../db-model/tables/task-change-log-table';
import { DefinedModelBase } from "../defined-model-base"

export class TaskChangeLog extends DefinedModelBase {
    taChLoId!: number; 
    taChLoTaskId!: number; 
    taChLoUserId!: number;
    taChLoSourceStatus!: number;
    taChLoTargetStatus!: number;
    taChLoTimeStamp!: Date;

    constructor(dtoData: any, tableDef: any[] = []) {
        super(dtoData, [TaskChangeLogTable].concat(tableDef));
    }
}