import { ClientService } from 'src/app/core/services/backend-services/client-service';
import { PageModel } from 'src/app/ui/main/model/page-model';
import Container from 'typedi';
import { ClientPageEventsEnum } from './client-page-events-enum';
import { ClientVM } from 'src/app/ui/main/model/client-vm';
import { AppMenuRouteEnum } from 'src/app/core/model/data-model/enums/app-menu-route-enum';

export class ClientsVM extends PageModel {
    clients: ClientVM[] = [];
    selectedClient: ClientVM | undefined;

    constructor() {
        super(AppMenuRouteEnum.clients, -1, "0d323842-74f0-4c9c-b458-6fe42e0b05ef");
    }

    async load(): Promise<void> {
        const s = Container.get(ClientService);
        const grantsView = await s.getClients();
        this.clients = grantsView.map(x=> new ClientVM(x));
    }

    async select(client: ClientVM): Promise<void> {
        //if (client.isAvailable) {
            this.selectedClient = client;
            await this.emitEventAsync(ClientPageEventsEnum.selectedClientChanged, client);
        //}
    }

    // La page clients n'enregistre pas sa route
    // override async save(): Promise<void> {
    //     return;
    // }
}