import { ZLanguageItemTable } from 'src/app/core/model/db-model/tables/z-language-item-table';
import { AppRoleTable } from './../tables/app-role-table';
import { AppUserTable } from "../tables/app-user-table";
import { TaskContributorTable } from "../tables/task-contributor-table";
import { TaskFunctionTable } from '../tables/task-function-table';

export const TaskContributorDbView = {
    ...TaskContributorTable,
    ...AppUserTable,
    ...TaskFunctionTable,
    databaseTableName: "task_contributor_view",
    apRoName: AppRoleTable.apRoName,
    laItText: ZLanguageItemTable.laItText
}