import { RealEstateView } from "src/app/core/services/backend-services/dto/real-estate-view";
import { RealEstateService } from "src/app/core/services/backend-services/real-estate-service";
import { NestedToolbarBase } from "src/app/ui/main/model/nested-toolbar-base";
import { NestedToolbarTypeEnum } from "src/app/ui/main/model/nested-toolbar-type-enum";
import Container from "typedi";
import { MapEventsEnum } from "./map-events-enum";
import { RealEstateProcessing } from "src/app/core/model/data-processing/real-estate-processing";

export class MapToolbarVM extends NestedToolbarBase {
    reFlatHierarchy: RealEstateView[] = [];

    private constructor() {
        super(NestedToolbarTypeEnum.map);
    }

    static async newAsync(): Promise<MapToolbarVM> {
        const tmp = new MapToolbarVM();
        tmp.reFlatHierarchy = await tmp.loadReFlatHierarchy();
        tmp.emitEventAsync(MapEventsEnum.reFlatHierarchyLoaded, tmp.reFlatHierarchy);
        return tmp;
    }

    async loadReFlatHierarchy(): Promise<RealEstateView[]> {
        const s = Container.get(RealEstateService);
        return RealEstateProcessing.getBuildingItems(await s.loadFlatHierarchy());
      }
  
    timer: number | undefined;
    searchForBuilding(value: string): void {
        clearTimeout(this.timer);
        this.timer = window.setTimeout((that: MapToolbarVM) => {
            const result = that.reFlatHierarchy.find(x=> x.name.toLowerCase().includes(value));
            if (result) {
                this.emitEventAsync(MapEventsEnum.navigationRequired, result);
            }
        }, 250, this);
    }
}