import { XcMaths } from "../static-functions/xc-maths";
import { Circle } from "./circle.model";
import { GeometricElementType } from "./geometric-element-type.model";
import { GeometricElement } from "./geometric-element.model";
import { Point } from "./point.model";
import { Segment } from "./segment.model";
import { Vector } from "./vector.model";

export class Arc implements GeometricElement {
    startPoint: Point = Point.origin();
    endPoint: Point = Point.origin();
    radius: number = 0;
    angle: number = 0;
    isLargArc: boolean = false;
    isClockWise: boolean = false;
    elementType = GeometricElementType.Arc;

    constructor(startPoint: Point, endPoint: Point, radius: number, angle: number, isClockWise: boolean, isLargArc: boolean) {
        this.startPoint = startPoint,
        this.endPoint = endPoint;
        this.radius = radius;
        this.angle = angle;
        this.isClockWise = isClockWise;
        this.isLargArc = isLargArc;
    }
        
    static loadFromDto(dtoData: any): Arc | null {
        if(dtoData) {
            const arc = new Arc(new Point(dtoData.startPoint.x, dtoData.startPoint.y),
                new Point(dtoData.endPoint.x, dtoData.endPoint.y),
                dtoData.radius,
                dtoData.angle,
                dtoData.isClockWise,
                dtoData.isLargArc);
            return arc;
        }

        return null;
    }

    static fromValues(
        startPoint: Point, 
        endPoint: Point,
        radius: number,
        angle: number,
        isLargArc: boolean,
        isClockWise: boolean): Arc {
            const arc = new Arc(
                startPoint,
                endPoint,
                radius,
                angle,
                isClockWise,
                isLargArc);
            return arc;
    }

    equals(a: Arc): boolean{
        return this.startPoint.equals(a.startPoint) && 
        this.endPoint.equals(a.endPoint) && 
        this.radius === a.radius && 
        this.angle === a.angle && 
        this.isLargArc === a.isLargArc && 
        this.isClockWise === a.isClockWise;
    }

    // Retourne le milieu de l'arc
    midPoint(): Point {
        const chord = this.chord();
        let offset = this.sagitta(chord, this.radius);

        if (this.isLargArc)
        {
            offset = this.radius + this.radius - offset;
        }

        if (this.isClockWise)
        {
            offset = -offset;
        }

        return chord.getOffsetMidPoint(offset);
}

    // Retourne la corde
    chord(): Segment {
        return new Segment(this.startPoint, this.endPoint);
    }

    // Retourne la sagitta
    sagitta(chord: Segment, radius: number): number {
        const hl = chord.length() / 2;
        // Il peut arriver que radius² - hl² soit négatif à cause de l'approximation
        // de double lorsque la valeur est très proche de zéro
        // d'où le Math.Max
        return radius - Math.sqrt(Math.max(0, (radius * radius) - (hl * hl)));
    
    }

    // Retourne l'apothème
    apothem(): number {
        return this.radius - this.sagitta(this.chord(), this.radius);
    }

    center(): Point
    {
        var chord = this.chord();

        if (XcMaths.round((chord.length() / 2) - this.radius, 3) === 0)
        {
            return chord.midPoint();
        }

        var offset = this.apothem();

        if (!((this.isClockWise && !this.isLargArc) || (!this.isClockWise && this.isLargArc)))
        {
            offset = -offset;
        }

        return chord.getOffsetMidPoint(offset);
    }

    startAngle(): number
    {
        return Arc.arcAngle(this.startPoint, this.center());
    }

    endAngle(): number
    {
        return Arc.arcAngle(this.endPoint, this.center());
    }

    translate (translate: Vector): Arc {
        const result = new Arc(this.startPoint.translate(translate),
            this.endPoint.translate(translate),
            this.radius,
            this.angle,
            this.isClockWise,
            this.isLargArc);
        return result;
}

    rotate(centerPoint: Point, angleInRadians: number): Arc {
        const result = new Arc(this.startPoint.rotate(centerPoint, angleInRadians),
            this.endPoint.rotate(centerPoint, angleInRadians),
            this.radius,
            this.angle,
            this.isClockWise,
            this.isLargArc);
        return result;
    }

    contains(point: Point, tolerance: number = Number.EPSILON): boolean
    {
        if (point.equals(this.startPoint, tolerance)) return true;
        if (point.equals(this.endPoint, tolerance)) return true;

        var center = this.center();
        // ligne horizontale
        var hLine = new Segment(center, new Point(center.x + 1, center.y));
        // ligne joignant le centre au point
        var iLine = new Segment(center, point);

        var startAngle = this.startAngle();
        var endAngle = this.endAngle();
        var itemAngle = hLine.angleWith(iLine);

        // Les deux extrémités de l'arc sont sur l'axe des x
        if (startAngle === 0 && endAngle === Math.PI)
        {
            if (this.isClockWise)
            {
                return itemAngle <= 0;
            }
            return itemAngle >= 0;
        }

        // Les deux extrémités de l'arc sont sur l'axe des x
        if (startAngle === Math.PI && endAngle === 0)
        {
            if (this.isClockWise)
            {
                return itemAngle >= 0;
            }
            return itemAngle <= 0;
        }

        if (this.isClockWise)
        {
            // Les deux angles sont positifs
            if (startAngle >= 0 && endAngle >= 0)
            {
                if (this.isLargArc)
                {
                    return itemAngle <= startAngle || itemAngle >= endAngle;
                }
                return itemAngle <= startAngle && itemAngle >= endAngle;
            }

            // Les deux angles sont négatifs
            if (startAngle < 0 && endAngle < 0)
            {
                if (this.isLargArc)
                {
                    return itemAngle >= endAngle || itemAngle <= startAngle;
                }
                return itemAngle <= startAngle && itemAngle >= endAngle;
            }

            // L'angle de départ est positif et l'angle d'arrivée négatif
            if (startAngle > 0 && endAngle < 0)
            {
                return itemAngle <= startAngle && itemAngle >= endAngle;
            }

            // L'angle de départ est négatif et l'angle d'arrivée est positif
            return itemAngle >= endAngle || itemAngle <= startAngle;
        }
        else
        {
            // Les deux angles sont positifs
            if (startAngle >= 0 && endAngle >= 0)
            {
                if (this.isLargArc)
                {
                    return itemAngle <= endAngle || itemAngle >= startAngle;
                }
                return itemAngle >= startAngle && itemAngle <= endAngle;
            }

            // Les deux angles sont négatifs
            if (startAngle < 0 && endAngle < 0)
            {
                if (this.isLargArc)
                {
                    return itemAngle >= startAngle || itemAngle <= endAngle;
                }
                return itemAngle <= endAngle && itemAngle >= startAngle;
            }

            // L'angle de départ est positif et l'angle d'arrivée négatif
            if (startAngle > 0 && endAngle < 0)
            {
                return itemAngle >= startAngle || itemAngle <= endAngle;
            }

            // L'angle de départ est négatif et l'angle d'arrivée est positif
            return itemAngle <= endAngle && itemAngle >= startAngle;
        }
    }

    getIntersects(s: Segment | Arc, tolerance: number = Number.EPSILON): Point[] {
        const result: Point[] = [];

        const center = this.center();
        const circle = new Circle(center, this.radius);

        const points = circle.getIntersects(s);
        points.forEach(p => {
            if (this.contains(p, tolerance)) {
                result.push(p);
            }
        });
        // var circleIntersectPoints = circle.getIntersects(s);

        // circleIntersectPoints.forEach(i => {
        //     if (this.contains(i))
        //     {
        //         result.push(i);
        //     }
        // });

        return result
    }

    static arcAngle(point: Point, center: Point): number
    {
        // ligne horizontale
        var hLine = new Segment(center, new Point(center.x + 1, center.y));
        // ligne joignant le centre au point de départ
        var sLine = new Segment(center, point);

        //
        if (hLine.endPoint.x - sLine.endPoint.x === center.x)
        {
            return Math.PI;
        }

        return hLine.angleWith(sLine);
    }

}