import { SvgEntityTypesEnum } from 'src/app/core/model/svg-model/svg-entity-type-enum';
import { SvgGroup } from 'src/app/core/model/svg-model/svg-group.model';
import { SvgRectangle } from 'src/app/core/model/svg-model/svg-rectangle.model';
import { StackAnonymousUnitData } from '../../../shared-model/stack-anonymous-unit-data';
import { SvgBsFloorDataItem } from './svg-bs-floor-data-item';

export class SvgBsFloorData extends SvgGroup {
    floorArea: number;
    buildingFloorMaxArea: number;
    fullBackground: SvgRectangle;
    fitBackground: SvgRectangle = SvgRectangle.fromValues(0, 0, 0, 0);
    data: SvgBsFloorDataItem[] = [];

    constructor(x: number, y: number, width: number, height: number, floorArea: number, buildingFloorMaxArea: number) {
        super({entityType: SvgEntityTypesEnum.g});

        this.buildingFloorMaxArea = buildingFloorMaxArea;
        this.floorArea = floorArea;

        this.fullBackground = SvgRectangle.fromValues(x, y, width, height);
        this.fullBackground.fill = "#e6f7ff";
        this.fullBackground.fillOpacity = 0.3;
        //this.fullBackground.stroke = "#80d4ff";
        //this.fullBackground.strokeWidth = 0.1;

        this.fitBackground = SvgRectangle.fromValues(x, y, floorArea * width / buildingFloorMaxArea, height);
        this.fitBackground.fill = "#e6f7ff";
        this.fitBackground.fillOpacity = 1;
        this.fitBackground.stroke = "#80d4ff";
        this.fitBackground.strokeWidth = 0.1;
    }

    loadData(data: StackAnonymousUnitData[]): void {
        const temp = data.sort((a, b) => a.label.localeCompare(b.label));
        let x = this.fullBackground.x;
        temp.forEach(d => {
            const ratio = d.value / this.floorArea;
            const length = this.fitBackground.width * ratio;
            const a = new SvgBsFloorDataItem(x, this.fullBackground.y, length, this.fullBackground.height, d.id, d.label);
            a.fill = d.color;
            this.data.push(a);
            x += length;
        });
    }

    isolate(id: number, show: boolean): void {
        this.data.forEach(d => {
            if (d.id === id) {
                if (d.fillOpacity === 0.1) {
                    d.fillOpacity = 1;
                }
            } else {
                if (!show) {
                    d.fillOpacity = 0.1;
                }
            }
        });
    }

    hide(id: number): void {
        this.data.forEach(d => {
            if (d.id === id) {
                d.fillOpacity = 0.1;
            }
        });
    }
}
