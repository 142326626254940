@if (model && model.selectedOption) {
    <div class="h-full">
        @switch (model.selectedOption.id) {
            @case (model.optionTypesEnum.anatomy) {
                <xc-donut-chart-wrapper [model]="$any(model.currentView)"/>
            }
            @case (model.optionTypesEnum.activities) {
                <xc-donut-chart-wrapper [model]="$any(model.currentView)"/>
            }
            @case (model.optionTypesEnum.attributions) {
                <xc-donut-chart-wrapper [model]="$any(model.currentView)"/>
            }
            @case (model.optionTypesEnum.inventory) {
                <xc-donut-chart-wrapper [model]="$any(model.currentView)"/>
            }
            @case (model.optionTypesEnum.referential) {
                <xc-properties-editor [model]="$any(model.currentView)"/>
            }
        }
    </div>
}