@if (model) {
<div id="strategySchedulingContainer" (mousemove)="model.mouseMove($event)">
    <xc-strategy-schedule [model]="model.schedule"></xc-strategy-schedule>
    @if (model.schedule && model.schedule.showLabelInput) {
<input id="label-input" matInput
       
        type="text" 
        xcAutoFocus
        [style.left.px]="model.schedule.labelInputPos?.x"
        [style.top.px]="model.schedule.labelInputPos?.y"
        [(ngModel)]="model.schedule.labelInputValue"
        (focusout)="model.schedule.focusOut()" 
        (keydown)="model.schedule.inputKeyDown($event)">
}
</div>
}
