import { Building } from "../tables/building";
import { BusinessUnit } from "../tables/business-unit";
import { Floor } from "../tables/floor";
import { FloorData } from "../tables/floor-data";
import { Room } from "../tables/room";
import { RoomAllocation } from "../tables/room-allocation";
import { RoomView } from "../views/room-view";
import { getView } from "../views/view-builder";

export class RoomAllocationViewSet {
    dataSet: (Building & Floor & FloorData & RoomView & Room & RoomAllocation & BusinessUnit);

    constructor(dtoData: any) {
        this.dataSet = getView(dtoData, Building, Floor, FloorData, RoomView, Room, RoomAllocation, BusinessUnit);
    }
}