import { GeometricElement } from './../../../model/geometry-model/geometric-element.model';
import { SvgEntityDTO } from './svg-entity-dto';

export class SvgPathDTO extends SvgEntityDTO {
    data: string;
    area: number;
    geometry: GeometricElement[];

    constructor(dtoData: any) {
        super(dtoData);
        this.data = dtoData["data"];
        this.area = dtoData["area"];
        this.geometry = dtoData["geometry"];
    }
}