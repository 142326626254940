import { Directory } from './../tables/directory';
import { FloorData } from "../tables/floor-data";
import { Workplace } from "../tables/workplace";
import { PeopleLocation } from "../tables/people-location";
import { getView } from "../views/view-builder";

export class WorkplaceAllocationViewSet {
    dataSet: (Workplace & PeopleLocation & FloorData & Directory);

    constructor(dtoData: any) {
        this.dataSet = getView(dtoData, Workplace, PeopleLocation, FloorData, Directory);
    }

    combinedId(): string {
        return `${this.dataSet.peLoFloorDataId}-${this.dataSet.peLoDirectoryId}`;
    }
}