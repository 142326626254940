@if (model) {
<div class="p-3">
    <div class="flex w-full justify-content-center">
        <div>
        </div>
        <mat-form-field appearance="outline" class="select">
            <mat-label>Continent</mat-label>
            <mat-select [(ngModel)]="model.selectedContinent" (selectionChange)="model.selectedContinentChange()">
                @for (c of model.continents; track c) {
                <mat-option [value]="c">{{c.coViDisplayName}}</mat-option>
                }
            </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline" class="select">
            <mat-label>Pays</mat-label>
            <mat-select [(ngModel)]="model.selectedCountry" (selectionChange)="model.selectedCountryChange()"
                [disabled]="model.selectedContinent === undefined">
                @for (c of model.countries; track c) {
                <mat-option [value]="c">{{c.cnViDisplayName}}</mat-option>
                }
            </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline" class="select">
            <mat-label>Ville</mat-label>
            <mat-select [(ngModel)]="model.selectedCity" [disabled]="model.selectedCountry === undefined">
                @for (c of model.cities; track c) {
                <mat-option [value]="c">{{c.name}}</mat-option>
                }
            </mat-select>
        </mat-form-field>

        <button mat-icon-button matTooltip="Ajouter cette ville" (click)="model.insertCityButtonClick()"
            [disabled]="model.selectedCity === undefined">
            <mat-icon>add_circle_outline</mat-icon>
        </button>

    </div>
    <div class="max-h-full w-full flex justify-content-center p-3">
        <xc-dyn-table [model]="model.adminTable" />
    </div>
</div>
}