import { DefinedModelBase } from 'src/app/core/model/data-model/defined-model-base';
import { RealEstateViewDef } from './definitions/real-estate-view-def';

export class RealEstateView extends DefinedModelBase {
    realEstateTableName!: string;
    id!: number;
    name!: string;
    parentId!: number | null;
    latitude!: number;
    longitude!: number;
    isActive!: boolean;
    isFictive!: boolean;
    thumbnailDocumentId!: string;
    children: RealEstateView[];

    constructor(dtoData: any) {
        super(dtoData, [RealEstateViewDef]);
        this.children = [];
        dtoData[RealEstateViewDef.children].forEach((c: any) => {
            this.children.push(new RealEstateView(c));
        });
    }
}