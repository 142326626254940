import { Floor } from 'src/app/core/model/data-model/tables/floor';
import { FloorEquipmentProviderDbView } from '../../db-model/views/floor-equipment-provider-db-view';

export class FloorEquipmentProviderView extends Floor {
    eqCgProviderId!: number;
    prCo_Name!: string;
    prCo_Color!: string;
    equipmentValue!: number;
    equipmentCount!: number;

    constructor(dtoData: any) {
        super(dtoData, [FloorEquipmentProviderDbView]);
    }
}