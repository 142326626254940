import { DxfLayerDTO } from "src/app/core/services/backend-services/dto/dxf-layer-dto";
import { InteractionCommand } from "../interaction-command";
import { DxfSvgEntity } from "../../svg/dxf-svg-entity";

export abstract class InsertInteraction {
    layers: DxfLayerDTO[] = [];
    currentCommand: InteractionCommand;
    insertionCompleted?: (entity: DxfSvgEntity) => void;

    constructor(command: InteractionCommand, layers: DxfLayerDTO[]) {
        this.currentCommand = command;
        this.layers = layers;
    }
}