import { SvgEntity } from "src/app/core/model/svg-model/svg-entity.model";
import { SvgEntityPoint } from "../../shared/gizmos/model/svg-entity-point";
import { ISelectableSvgEntity } from "./i-selectable-svg-entity";
import { BpSvgBoundingBox } from "../../../layout/real-estate/floor-blueprint/content/bp-svg-core-model/bp-svg-bounding-box";

export abstract class SelectableSvgEntity extends SvgEntity implements ISelectableSvgEntity {
    entityId: string;

    constructor(dtoData: any) {
        super(dtoData);
        this.entityId = dtoData.entityId;
    }

    private _selectablePoints: SvgEntityPoint[] = [];
    get selectablePoints() {
        if (this._selectablePoints.length === 0) {
            this._selectablePoints = this.getSelectablePoints();
        }
        return this._selectablePoints;
    }
    set selectablePoints(value: SvgEntityPoint[]) {
        this._selectablePoints = value
    }

    private _bbox: BpSvgBoundingBox | undefined;
    get bbox() {
        if (!this._bbox) {
            const xValues = this._selectablePoints.map(x=> x.x);
            const yValues = this._selectablePoints.map(x=> x.y);
            const xMin = Math.min(...xValues);
            const xMax = Math.max(...xValues);
            const yMin = Math.min(...yValues);
            const yMax = Math.max(...yValues);
            this._bbox = new BpSvgBoundingBox(xMin, yMin, xMax, yMax);
        }
        return this._bbox;
    }

    updateSelectablePoints(): void {
        this._selectablePoints = this.getSelectablePoints();
        this._bbox = undefined;
    }

    /**
     * Doit être overridé par chaque type d'entité
     * @returns Tableau des points sélectionnables pour une entité donnée
     */
    getSelectablePoints(): SvgEntityPoint[] {
        return [];
    }

    /**
     * Doit être overridé par chaque type d'entité
     * @returns Représentation HTML de l'entité
     */
    getStatement(): string {
        return "";
    }
}