import { StackAnonymousUnitData } from './stack-anonymous-unit-data';

export class StackAnonymousRowData {
    id: number;
    name: string;
    value: number;
    rowMaxValue: number;
    level: number;
    units: StackAnonymousUnitData[] = [];

    constructor(id: number, name: string, level: number, value: number, maxValue: number) {
        this.id = id;
        this.name = name;
        this.value = value;
        this.rowMaxValue = maxValue;
        this.level = level;
    }
}