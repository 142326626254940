import { NgModule } from "@angular/core";
import { BpSvgDefComponent } from "../svg-entities/view/bp-svg-def/bp-svg-def.component";
import { BpSvgGroupComponent } from "../svg-entities/view/bp-svg-group/bp-svg-group.component";
import { LayersPipe } from "./itself/pipes/layers-pipe";
import { DoorUpdateGizmoComponent } from "./subitems/gizmos/door-update-gizmo/view/door-update-gizmo/door-update-gizmo.component";
import { EquipmentUpdateGizmoComponent } from "./subitems/gizmos/equipment-update-gizmo/view/equipment-update-gizmo/equipment-update-gizmo.component";
import { MeasurementUpdateGizmoComponent } from "./subitems/gizmos/measurement-update-gizmo/view/measurement-update-gizmo/measurement-update-gizmo.component";
import { PeopleUpdateGizmoComponent } from "./subitems/gizmos/people-update-gizmo/view/people-update-gizmo/people-update-gizmo.component";
import { RoomUpdateGizmoComponent } from "./subitems/gizmos/room-update-gizmo/ui/room-update-gizmo/room-update-gizmo.component";
import { VariousSelectionGizmoComponent } from "./subitems/gizmos/various-selection-gizmo/ui/various-selection-gizmo.component";
import { WallUpdateGizmoComponent } from "./subitems/gizmos/wall-update-gizmo/view/wall-update-gizmo/wall-update-gizmo.component";
import { WorkplaceUpdateGizmoComponent } from "./subitems/gizmos/workplace-update-gizmo/ui/workplace-update-gizmo.component";
import { SvgSelectionPathComponent } from "src/app/components-lib/svg/svg-selection-path/svg-selection-path.component";

@NgModule({
    imports: [
        BpSvgGroupComponent,
        SvgSelectionPathComponent,
        WorkplaceUpdateGizmoComponent,
        RoomUpdateGizmoComponent,
        WallUpdateGizmoComponent,
        DoorUpdateGizmoComponent,
        EquipmentUpdateGizmoComponent,
        MeasurementUpdateGizmoComponent,
        PeopleUpdateGizmoComponent,
        BpSvgDefComponent,
        VariousSelectionGizmoComponent
    ],
    exports: [
        BpSvgGroupComponent,
        SvgSelectionPathComponent,
        WorkplaceUpdateGizmoComponent,
        RoomUpdateGizmoComponent,
        WallUpdateGizmoComponent,
        DoorUpdateGizmoComponent,
        EquipmentUpdateGizmoComponent,
        PeopleUpdateGizmoComponent,
        MeasurementUpdateGizmoComponent,
        BpSvgDefComponent,
        VariousSelectionGizmoComponent,
        LayersPipe
    ],
    declarations: [
      LayersPipe
    ]
  })
  export class BlueprintModule { }
  