import { FacilityJobTaskDbView } from "../../db-model/views/facility_job_task_db_view";
import { DefinedModelBase } from "../defined-model-base";
import { FacilityJob } from "../tables/facility-job";
import { FacilityJobTask } from "../tables/facility-job-task";
import { UnitOfWork } from "../tables/unit_of_work";

export class FacilityJobTaskView extends DefinedModelBase {
    job: FacilityJob;
    JobTask: FacilityJobTask;
    unitOfWork: UnitOfWork;

    constructor(dtoData: any) {
        super(dtoData, [FacilityJobTaskDbView]);
        this.job = new FacilityJob(dtoData);
        this.JobTask = new FacilityJobTask(dtoData);
        this.unitOfWork = new UnitOfWork(dtoData);
    }
}