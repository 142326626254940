@if (model) {
    <div>
        <p-dropdown 
            [options]="model.stores" 
            [(ngModel)]="model.selectedStore" 
            (onChange)="model.onSelectedStoreChange()"
            optionLabel="eqStArName" 
            [showClear]="true" 
            placeholder="Sélectionnez un lieu de stockage">
        </p-dropdown>
        @if (model.equipmentsList) {
            <xc-dyn-table [model]="model.equipmentsList"/>
        }
    </div>
}