import { RealEstateAttributionDbView } from "../../db-model/views/real-estate-attribution-db-view";
import { DefinedModelBase } from "../defined-model-base";

export class RealEstateAttributionView extends DefinedModelBase {
    roAttributionTypeId!: number;
    roAtTyName!: string;
    roAtTyColor!: string;
    roAtTyDisplayNameId!: number;
    roAtTyViDisplayName!: string;
    reEsAtViArea!: number;
    reEsAtViRoundedArea!: number;

    constructor(dtoData: any) {
        super(dtoData, [RealEstateAttributionDbView]);
    }
}