import { TaskTypeEnum } from "src/app/core/model/data-model/enums/task-type-enum";
import { TaskView } from "src/app/core/model/data-model/views/task-view";
import { Point } from "src/app/core/model/geometry-model/point.model";
import { DateUtils } from "src/app/core/model/static-functions/date-utils";
import { PathBuilder } from "src/app/core/model/svg-model/svg-path-builder";
import { MobilityProjectTreeNode } from '../../../side-panel/shared-model/mobility-project-tree-node';
import { GanttSvgTaskLink } from './gantt-svg-task-link';
import { TaskLinkView } from 'src/app/core/model/data-model/views/task-link-type-view';
import { isAnyHowEnded } from "src/app/core/model/data-processing/task-processing";
import { XcMaths } from "src/app/core/model/static-functions/xc-maths";
import { TaskLinkTypeEnum } from "src/app/core/model/data-model/enums/task-link-type-enum";

export class GanttSvgTaskVM {
    node: MobilityProjectTreeNode;
    parent: GanttSvgTaskVM | undefined;
    task: TaskView;
    startDate: Date // date de début du projet racine
    currentUnitWidth: number
    xDaysOffset: number;
    yOffset: number = 0;
    taskDefaultHeight: number;
    path!: string;
    children: GanttSvgTaskVM[] = [];
    links: GanttSvgTaskLink[] = [];
    xPos: number = 0;
    width: number = 0;
    handleWidth: number = 0;

    initialXPos: number;
    initialWidth: number;
    initialDuration: number;
    initialStartDate: Date;

    constructor(node: MobilityProjectTreeNode, startDate: Date, currentUnitWidth: number, taskDefaultHeight: number, parent?: GanttSvgTaskVM) {
        this.node = node;
        this.parent = parent;
        this.task = node.data;
        this.startDate = startDate;
        this.currentUnitWidth = currentUnitWidth;
        // Le nombre de jours depuis le début du projet racine
        this.xDaysOffset = DateUtils.getDays(this.startDate, this.task.taStartDate);
        this.taskDefaultHeight = taskDefaultHeight * 0.8;
        this.setGeometry();
        this.setPath();

        this.initialXPos = this.xPos;
        this.initialWidth = this.width;
        this.initialDuration = this.task.taDuration;
        this.initialStartDate = this.task.taStartDate;
    }
    addChild(child: GanttSvgTaskVM): void {
        this.children.push(child);
        if (child.task.taTypeId === TaskTypeEnum.Project) {
            child.sizeUpdateRequested = (minDelta: number, maxDelta: number) => {
                if (minDelta !== 0 || maxDelta !== 0) {
                    this.updateSizeFromChildren();
                }
            }
        } else {
            // child.parentSizeUpdateRequested = (validate: boolean) => {
            //     this.updateSizeFromChildren(validate);
            // }
        }
    }

    initializeSvgLinks(links: TaskLinkView[]): void {
        // Si la tâche à des liens c'est qu'elle est du type project
        // elle a donc des enfants
        links.forEach(l => {
            const sourceTask = this.children.find(x=> x.task.taId === l.taLiSourceTaskId);
            const targetTask = this.children.find(x=> x.task.taId === l.taLiTargetTaskId);
            if (sourceTask && targetTask) {
                const newLink = new GanttSvgTaskLink(l, sourceTask, targetTask, this.currentUnitWidth);
                this.links.push(newLink);
                sourceTask.links.push(newLink);
                targetTask.links.push(newLink);
            }
        });
    }

    removeLinks(taskId: number): void {
        const linksToRemove = this.links.filter(x=> x.sourceTask.task.taId === taskId || x.targetTask.task.taId === taskId);
        linksToRemove.forEach(l => {
            const lIndex = this.links.indexOf(l);
            if (lIndex > -1) {
                this.links.splice(lIndex, 1);
            }
        });
    }

    // selectLink(linkId: number, propagateToChildren: boolean = false): void {
    //     if (this.parent && !propagateToChildren) {
    //         this.parent.selectLink(linkId);
    //     } else {
    //         this.children.forEach(c => {
    //             if (c.isProject()) {
    //                 this.links.forEach(l => {
    //                     l.selected = l.id === linkId;
    //                  });
    //                 c.selectLink(linkId, true);
    //             }
    //         });
    //     }
    // }

    addLink(link: GanttSvgTaskLink): void {
        this.links.push(link);
        this.resetLinks();
        if (this.parent) {
            this.parent.addLink(link);
        }
    }

    linkRemoved?: (linkId: number) => void;
    removeLink(linkId: number): void {
        const index = this.links.findIndex(x=> x.id === linkId);
        this.links.splice(index, 1);
        if (this.parent) {
            this.parent.removeLink(linkId);
        } else {
            if (this.linkRemoved) {
                this.linkRemoved(linkId);
            }
        }
    }

    resetLinks(): void {
        this.links.forEach(l => {
            l.calculatePathData();
        });
    }

    setGeometry(): void {
        // TODO : centraliser la largeur par défaut d'un jour (20) avec le style.css (--xc-schedule-col-default-width)
        this.xPos = XcMaths.round(this.xDaysOffset * this.currentUnitWidth * 20, 0);
        this.width = this.task.taDuration * this.currentUnitWidth * 20;
        this.handleWidth = Math.min(this.width * 0.07, 10);
        
        this.initialXPos = this.xPos;
        this.initialWidth = this.width;
    }

    rightPos(): number {
        return this.xPos + this.width;
    }

    initialRightPos(): number {
        return this.initialXPos + this.initialWidth;
    }

    sizeUpdateRequested?: (minDelta: number, maxDelta: number) => void;
    updateSizeFromChildren(): void {
        const oldRightPos = this.rightPos();
        const newMin = this.childrenMinLeftPos();
        const minDelta = newMin - this.xPos;
        this.xPos = newMin;
        const newRightPos = this.childrenMaxRightPos();
        const maxDelta = newRightPos - oldRightPos;
        this.width = newRightPos - newMin;

        this.setPath();

        if (this.sizeUpdateRequested) {
            this.sizeUpdateRequested(minDelta, maxDelta);
        }
    }

    compensateTasksPosition(exludedTask: GanttSvgTaskVM, delta: number): void {
        // Cas où le projet appelant est lui-même la tâche à exclure - le projet racine
        if (this.task.taId === exludedTask.task.taId) return;

        this.children.forEach(c => {
            if (c.isProject()) {
                c.compensateTasksPosition(exludedTask, delta);
            } else {
                if (c.task.taId !== exludedTask.task.taId) {
                    c.xPos = c.initialXPos + delta;
                    if (c.xPos < 0) c.xPos = 0;
                }
            }
        });
        this.updateSizeFromChildren();
        this.updateLinks();
    }

    move(delta: number, parentCaller: boolean = false): void {
        if (this.isProject()) {
            this.children.forEach(c => {
                c.move(delta, true);
            });
            this.updateSizeFromChildren();
            this.updateLinks();
        } else {
            this.xPos = this.initialXPos + delta;
            if (this.xPos < 0) this.xPos = 0;
            if (!parentCaller) {
                // Si la demande de déplacement n'est pas initié par le parent
                // on actualise la taille du parent et les liens
                this.parent?.updateSizeFromChildren();
                this.parent?.updateLinks();
            }
        }
    }

    extendByStart(delta: number, widthDelta: number = delta): boolean {
        // Une tâche ne peut pas avoir une durée inférieure à un jour
        if (this.initialWidth - widthDelta < this.currentUnitWidth * 20) return false;

        this.xPos = this.initialXPos + delta;
        if (this.xPos < 0) this.xPos = 0;
        this.width = this.initialWidth - widthDelta;

        this.parent?.updateSizeFromChildren();
        this.parent?.updateLinks();

        return true;
}

    extendByDuration(delta: number): boolean {
        if (!this.decreaseDuration(delta)) return false;

        this.parent?.updateSizeFromChildren();
        this.parent?.updateLinks();

        return true;
    }

    decreaseDuration(delta: number): boolean {
        // Une tâche ne peut pas avoir une durée inférieure à un jour
        if (this.initialWidth - delta < this.currentUnitWidth * 20) return false;

        this.width = this.initialWidth - delta;

        return true;
    }

    reset(): void {
        this.children.forEach(c => {
            if (!c.isProject()) {
                c.xPos = c.initialXPos;
                c.width = c.initialWidth;
            } else {
                c.reset();
                c.updateSizeFromChildren();
                c.updateLinks();
            }
        });
    }

    childrenMinLeftPos(): number {
        let result: number = Number.MAX_SAFE_INTEGER;
        this.children.forEach(c => {
            result = Math.min(result, c.xPos);
            // Il est inutile de parcourir les enfants de sous-projets
        });
        return result;
    }

    childrenMaxRightPos(): number {
        let result: number = 0
        this.children.forEach(c => {
            result = Math.max(result, c.rightPos());
        });
        return result;
    }

    setPath(): void {
        if (this.task.taTypeId === TaskTypeEnum.Project) {
            const thickness = this.taskDefaultHeight / 4;
            const start = this.xPos;
            const width = this.width;
            const pinWidth = Math.min(thickness, width / 10);
            const pts: Point[] = [];
            pts.push(new Point(start, this.yOffset));
            pts.push(new Point(start + width, this.yOffset));
            pts.push(new Point(start + width, this.yOffset + this.taskDefaultHeight));
            pts.push(new Point(start + width - pinWidth, this.yOffset + thickness));
            pts.push(new Point(start + pinWidth, this.yOffset + thickness));
            pts.push(new Point(start, this.yOffset + this.taskDefaultHeight));
            this.path = PathBuilder.getPath(pts, true);
        }
    }

    updateLinks(): void {
        this.links.forEach(l => {
            l.setScale(this.currentUnitWidth);
        })
    }

    updateLinksRecursively(): void {
        this.updateLinks();
        this.children.forEach(c => {
            if (c.isProject()) {
                c.updateLinksRecursively();
            }
        });
    }

    setScale(currentUnitWidth: number): void {
        this.currentUnitWidth = currentUnitWidth;
        this.setGeometry();
        this.setPath();

        this.children.forEach(c => {
            c.setScale(currentUnitWidth);
        });

        this.updateLinks();
    }

    setYOffset(indexPos: number): void {
        this.yOffset = indexPos * this.taskDefaultHeight / 0.8;
        this.setPath();
    }

    isProject(): boolean {
        return this.task.taTypeId === TaskTypeEnum.Project;
    }

    find(taskId: number): GanttSvgTaskVM | undefined {
        if (this.task.taId === taskId) return this;

        let result: GanttSvgTaskVM | undefined;

        for(const c of this.children) {
            if (c.task.taId === taskId) {
                result = c;
                break;
            } else {
                if (c.children.length > 0) {
                    result = c.find(taskId);
                }
            }
        }

        return result;
    }

    getTree(): GanttSvgTaskVM[] {
        const result: GanttSvgTaskVM[] = [];
        
        result.push(this);
        if (this.parent) result.push(...this.parent.getTree());

        return result;
    }

    hasPositionUpdates(): boolean {
        return this.initialXPos !== this.xPos || this.initialWidth !== this.width;
    }

    hasDatesUpdates(): boolean {
        return this.task.taStartDate.getTime() !== this.initialStartDate.getTime() || this.task.taDuration !== this.initialDuration;
    }

    validateSave(): void {
        this.initialStartDate = this.task.taStartDate;
        this.initialDuration = this.task.taDuration;
        if (this.children.length > 0) {
            this.children.forEach(c => {
                c.validateSave();
            });
        }
    }
    
    isBackwardExtendable(todayXOffset: number, fromStart?: boolean, delta?: number): boolean {
        if (fromStart !== undefined && !fromStart && delta && delta < 0) {
            // S'il s'agit de réduire la durée de la tâche à partir de sa fin
            // la seule chose qu'il faut vérifier c'est qu'elle a toujours au moins une durée d'un jour
            return this.width > this.currentUnitWidth * 20;
        }

        if (isAnyHowEnded(this.task.taStatusId)) return false;

        let updatable: boolean = true;

        if (this.isProject()) {
            // Un projet peut reculer dans le temps tant que sa date de début est au moins égale à la date courante
            // Calcule la date de la position cible
            const targetXDayOffset = XcMaths.round(this.xPos / (20 * this.currentUnitWidth), 0);
            const targetXPos = targetXDayOffset * this.currentUnitWidth * 20;
            return targetXPos > todayXOffset;
        }

        // Liens end to start en amont
        const etsLinks = this.links.filter(x=> x.targetTask.task.taId === this.task.taId && x.taskLink.taLiLinkType === TaskLinkTypeEnum.EndToStart);
        etsLinks.forEach(l => {
            const other = l.otherTask(this.task.taId);
            if (other.rightPos() >= this.xPos) {
                // Le début de la tâche à modifier dépend de la fin d'une autre tâche et les dates sont identiques
                // la modification envisagée est impossible
                updatable = false;
                return;
            }
        });

        // La modification est impossible, inutile de vérifier le reste
        if (!updatable) return updatable;

        // Si la tâche a un lien start to start, la tâche liée doit être modifiée également
        // mais ça n'est possible que si la tâche liée n'a pas de contrainte de liaison l'interdisant
        const stsLinks = this.links.filter(x=> x.taskLink.taLiLinkType === TaskLinkTypeEnum.StartToStart);
        stsLinks.forEach(l => {
            if (!l.otherTask(this.task.taId).isBackwardExtendable(todayXOffset, fromStart, delta)) {
                updatable = false;
                return;
            }
        });
        
        return updatable;
    }

    isForwardExtendable(fromStart?: boolean, delta?: number): boolean {
        if (fromStart !== undefined && fromStart && delta && delta > 0) {
            // S'il s'agit de réduire la durée de la tâche à partir de son début
            // la seule chose qu'il faut vérifier c'est qu'elle a toujours au moins une durée d'un jour
            return this.width > this.currentUnitWidth * 20;
        }

        if (isAnyHowEnded(this.task.taStatusId)) return false;

        let updatable: boolean = true;

        if (this.isProject()) {
            return true;
        }

        // Liens end to start en aval
        const etsLinks = this.links.filter(x=> x.sourceTask.task.taId === this.task.taId && x.taskLink.taLiLinkType === TaskLinkTypeEnum.EndToStart);
        etsLinks.forEach(l => {
            const other = l.otherTask(this.task.taId);
            if (other.xPos <= this.rightPos()) {
                // La fin de la tâche à modifier déclenche le début d'une autre tâche et les dates sont identiques
                // la modification envisagée est impossible
                updatable = false;
            }
        });

        // La modification est impossible, inutile de vérifier le reste
        if (!updatable) return updatable;

        // Si la tâche a un lien end to end, la tâche liée doit être modifiée également
        // mais ça n'est possible que si la tâche liée n'a pas de contrainte de liaison l'interdisant
        const eteLinks = this.links.filter(x=> x.taskLink.taLiLinkType === TaskLinkTypeEnum.EndToEnd);
        eteLinks.forEach(l => {
            if (!l.otherTask(this.task.taId).isForwardExtendable(fromStart, delta)) {
                updatable = false;
            }
        });

        return updatable;
    }

    validate(startDate: Date): void {
        if (this.isProject()) {
            this.children.forEach(c => {
                c.validate(startDate);
            });
            this.updateSizeFromChildren();
            this.updateLinks();
        }

            this.startDate = startDate;
        //if (!this.isProject()) {
            if (this.hasPositionUpdates()) {
                // Ajuste la position à un jour entier
                // Se produit lorsque l'utilisateur a fini de déplacer la tâche
                this.xDaysOffset = XcMaths.round(this.xPos / (20 * this.currentUnitWidth), 0);
                this.xPos = XcMaths.round(this.xDaysOffset * this.currentUnitWidth * 20, 0);
                this.initialXPos = this.xPos;
                this.task.taStartDate = DateUtils.addDays(this.startDate, this.xDaysOffset);

                this.task.taDuration = XcMaths.round(this.width / (20 * this.currentUnitWidth), 0);
                this.width = this.task.taDuration * this.currentUnitWidth * 20;
                this.initialWidth = this.width;
            }
            if (this.xPos === 0) {
                // Lorsqu'une tâche est à la position zéro relativement au conteneur
                // sa date de début n'est pas modifiée si le conteneur bouge
                // il faut donc forcer cette date avec celle du conteneur
                this.task.taStartDate = startDate;
            }
        // } else {
        // }

    }

    moveStartDate(days: number): void {
        this.task.taStartDate = DateUtils.addDays(this.task.taStartDate, days);
        if (this.children.length > 0) {
            this.children.forEach(c => {
                c.moveStartDate(days);
            });
        }
    }

    endDate(): Date {
        return DateUtils.addDays(this.task.taStartDate, this.task.taDuration);
    }
}