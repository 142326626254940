import { TablesNamesEnum } from './../tables-names-enum';
export const FacilityJobTaskTable = {
    databaseTableName: TablesNamesEnum.FacilityJobTask,
    faJoTaId: 'FaJoTa_Id',
    faJoTaName: 'FaJoTa_Name',
    faJoTaIsActive: 'FaJoTa_IsActive',
    faJoTaFacilityJob: 'FaJoTa_FacilityJob',
    faJoTaUnitOfWork: 'FaJoTa_UnitOfWork',
    faJoTaUnitPrice: 'FaJoTa_UnitPrice',
  };
  