import { RoomAttributionTypeTable } from '../../db-model/tables/room-attribution-type-table';
import { DefinedModelBase } from '../defined-model-base';

export class RoomAttributionType extends DefinedModelBase {
    roAtTyId!: number;
    roAtTyName!: string;
    roAtTyDisplayNameId!: number;
    roAtTyColor!: string;

    constructor(dtoData: any, tableDef: any[] = []) {
        super(dtoData, [RoomAttributionTypeTable].concat(tableDef));
    }
}