import { FormBuilder, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MaterialModule } from 'src/app/core/material.module';
import { CreationStepOneVM } from './../../model/creation-step-one-vm';
import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'xc-creation-step-one',
  standalone: true,
  imports: [CommonModule, MaterialModule, FormsModule, ReactiveFormsModule],
  templateUrl: './creation-step-one.component.html',
  styleUrls: ['./creation-step-one.component.scss']
})
export class CreationStepOneComponent {
  @Input() model: CreationStepOneVM | undefined;
}
