import { QuadrantPosition } from './quadrant-position.model';
import { GeometricElementType } from './geometric-element-type.model';
import { GeometricElement } from './geometric-element.model';
import { Vector } from './vector.model';
import { XcMaths } from '../static-functions/xc-maths';
import { Segment } from './segment.model';

export class Point implements GeometricElement {
    readonly x: number;
    readonly y: number;
    elementType = GeometricElementType.Point;

    constructor(x = 0, y = 0) {
        this.x = x;
        this.y = y;
    }
    
    equals(p: Point, tolerance: number = Number.EPSILON): boolean{
        if(!p) {
            return false;
        }
        return Math.abs(Math.abs(this.x) - Math.abs(p.x)) <= tolerance && Math.abs(Math.abs(this.y) - Math.abs(p.y)) <= tolerance;
    }

    hasNaN(): boolean {
        return Number.isNaN(this.x) || Number.isNaN(this.y);
    }

    roundedEquals(p: Point): boolean {
        if(!p) {
            return false;
        }
        return this.rounded().equals(p.rounded());
    }

    minus(p: Point): Vector {
        return new Vector(this.x - p.x, this.y - p.y);
    }

    clone(): Point {
        return new Point(this.x, this.y);
    }

    static clone(points: Point[]): Point[] {
        const result: Point[] = [];
        points.forEach(p => {
            result.push(p.clone());
        });
        return result;
    }

    /**
     * Le type générique permet d'utiliser la fonction avec des type étendus
     * @param points 
     * @param refPoint 
     * @returns Retourne le point le plus proche dans un tableau de points
     */
    static getNearest<T extends Point>(points: T[], refPoint: Point): T {
        let minDistanceIndex = 0;
        let minDistance = 1000.0;
        for (let i = 0; i < points.length; i++) {
            const og = points[i];
            
            var gripDistance = refPoint.distanceTo(og);
            if (gripDistance < minDistance)
            {
                minDistance = gripDistance;
                minDistanceIndex = i;
            }
        }

        return points[minDistanceIndex];
    }

    static rotateAll(points: Point[], center: Point, angle: number): Point[] {
        const result: Point[] = [];
        points.forEach(p => {
            result.push(p.rotate(center, angle));
        });
        return result;
    }

    vector(): Vector {
        return new Vector(this.x, this.y);
    }

    // Retourne la distance avec le point passé en argument
    distanceTo(p: Point): number {
        return Math.sqrt(Math.pow(p.x - this.x, 2) + Math.pow(p.y - this.y, 2));
    }

    orthogonalDistanceTo(s: Segment): number {
        // Le vecteur de la droite
        const v = s.vector();

        // Le vecteur orthogonal à la droite
        const orthoV = v.getOrthogonalVector();

        // La ligne allant du point vers un point de la droite
        const l1 = new Segment(this, s.startPoint);
        // le vecteur de cette ligne
        const myV = l1.vector();

        return Math.abs((v.u * myV.v) - (orthoV.u * myV.u)) / v.norm();
    }

    // Retourne un point dont les coordonnées sont arrondies
    // Par défaut le nombre est arrondi à trois décimales
    rounded(decimalCount: number = 3): Point {
        return new Point(XcMaths.round(this.x, decimalCount), XcMaths.round(this.y, decimalCount));
    }

    /**
     * 
     * @param rotationCenter Centre de rotation
     * @param angleInRad Angle de rotation
     * @returns Nouveau point égal au point de référence ayant subi une rotation
     */
    rotate(rotationCenter: Point, angleInRad: number): Point {
        const cos = Math.cos(angleInRad);
        const sin = Math.sin(angleInRad);
        return new Point(cos * (this.x - rotationCenter.x) - sin * (this.y - rotationCenter.y) + rotationCenter.x, sin * (this.x - rotationCenter.x) + cos * (this.y - rotationCenter.y) + rotationCenter.y);
    }

    /**
     * 
     * @param translate Vecteur de translation
     * @returns Nouveau point égal au point de référence ayant subi une translation
     */
    translate(translate: Vector): Point {
        return new Point(this.x + translate.u, this.y + translate.v);
    }

    transform(rotationCenter: Point, rotationAngleRad: number, translate: Vector): Point {
        const result = this.rotate(rotationCenter, rotationAngleRad);
        return result.translate(translate);
    }

    static origin(): Point {
        return new Point(0, 0);
    }

    static getFromString(value: string): Point | null {
        const values = value.split(' ');
        if(values.length === 2){
            return new Point(Number(values[0]), Number(values[1]));
        }

        return null;
    }

    isOrigin(): boolean {
        return this.x === 0 && this.y === 0;
    }

    isNear(p: Point, tolerance: number = Number.EPSILON): boolean {
        return this.distanceTo(p) <= tolerance;
    }

    getQuadrantPositionRelativeTo(p: Point): QuadrantPosition {
        if (this.x == p.x)
        {
            if (this.y < p.y)
            {
                return QuadrantPosition.BottomAxis;
            }

            if (this.y > p.y)
            {
                return QuadrantPosition.TopAxis;
            }
        }

        if (this.y == p.y)
        {
            if (this.x > p.x)
            {
                return QuadrantPosition.RightAxis;
            }

            if (this.x < p.x)
            {
                return QuadrantPosition.LeftAxis;
            }
        }

        if (this.x > p.x && this.y < p.y)
        {
            return QuadrantPosition.BottomRight;
        }

        if (this.x > p.x && this.y > p.y)
        {
            return QuadrantPosition.TopRight;
        }

        if (this.x < p.x && this.y < p.y)
        {
            return QuadrantPosition.BottomLeft;
        }

        if (this.x < p.x && this.y > p.y)
        {
            return QuadrantPosition.TopLeft;
        }

        return QuadrantPosition.None;
    }
}