import { Point } from 'src/app/core/model/geometry-model/point.model';
import { Segment } from 'src/app/core/model/geometry-model/segment.model';
import { XcMaths } from 'src/app/core/model/static-functions/xc-maths';
import { SvgTransform } from 'src/app/core/model/svg-model/svg-transform.model';
import { EntitiesSelectionSet } from '../../../../itself/model/interaction/entities-selection-set';
import { EquipmentSelectionRotationLabel } from '../../equipment-update-gizmo/model/equipment-selection-rotation-label';
import { UpdateGizmo } from './../../update-gizmo.model';
import { Grips } from 'src/app/components-lib/svg/grips/grips';
import { BpSvgMeasurementLabel } from '../../../../../svg-entities/model/bp-svg-measurement-label';
import { Grip } from 'src/app/components-lib/svg/grips/grip';
import { logError } from 'src/app/core/services/logging-service';

export class MeasurementUpdateGizmoWM implements UpdateGizmo {
    selectionSet: EntitiesSelectionSet = new EntitiesSelectionSet();
    // La cote lorsqu'il n'y en a qu'une sélectionnée
    single: BpSvgMeasurementLabel | undefined;

    isEditable: boolean = false;

    inserting: boolean = false;
    transientSegment: Segment = Segment.null();
    startExt: Segment = Segment.null();
    endExt: Segment = Segment.null();
    startMark: Segment = Segment.null();
    endMark: Segment = Segment.null();
    reticlePosition: SvgTransform | undefined;
    lengthLabel: EquipmentSelectionRotationLabel = new EquipmentSelectionRotationLabel()
    extTransform: SvgTransform | undefined;
    targetGrips: Grips = new Grips("purple", "purple");
    freeTranslationStartGrip: Grip | undefined;
    freeTranslationEndGrip: Grip | undefined;
    selectedSingleEndPointIsStartPoint: boolean = false;
    selectedSingleEndPoint: Point | undefined;

    show(labels: EntitiesSelectionSet): void {
        this.selectionSet = labels;
        this.selectionSetChanged();
    }

    hide(): void {
    }

    showTransient(): void {
        this.inserting = true;
    }

    hideTransient(): void {
        this.inserting = false;
    }

    selectionSetChanged(): void {
        if (this.selectionSet.count === 1) {
            this.setUniqueSelection();
            this.isEditable = true;
        }
    }

    setUniqueSelection(): void {
        this.single = this.selectionSet.items[0] as BpSvgMeasurementLabel;

        this.calculateFreeTranslationEndpointHandle();
    }

    calculateFreeTranslationEndpointHandle(): void {
        if (!this.single || !this.single.measureLine) return;
        const segment = this.single.measureLine.segment();
        const p1 = segment.startPoint;
        const p2 = segment.endPoint;
        this.freeTranslationStartGrip = new Grip({ entityType: 4, transform: null, d: "", attributes: null}, 0.05);
        this.freeTranslationStartGrip.point = p1;
        this.freeTranslationEndGrip = new Grip({ entityType: 4, transform: null, d: "", attributes: null}, 0.05);
        this.freeTranslationEndGrip.point = p2;
    }

    setTransientSegmentStartPoint(position: Point): void {
        this.transientSegment.startPoint = position;
        // On place le endpoint au même endroit pour commencer
        this.transientSegment.endPoint = position;
        this.lengthLabel.initialize(position, "0");
        this.updateExtensions();
        this.updateMarks();
    }

    setTransientSegmentEndPoint(position: Point): void {
        this.transientSegment.endPoint = position;
        const length = this.transientSegment.length();
        this.lengthLabel.initialize(this.transientSegment.getEndPointAt(length / 2, false), XcMaths.round(length, 2).toString());
        this.hideReticle();
        this.updateExtensions();
        this.updateMarks();
    }

    updateExtensions(): void {
        const e1 = this.transientSegment.getEndPointAt(-BpSvgMeasurementLabel.extLength, false);
        this.startExt.startPoint = this.transientSegment.startPoint;
        this.startExt.endPoint = e1;
        const e2 = this.transientSegment.getEndPointAt(-BpSvgMeasurementLabel.extLength, true);
        this.endExt.startPoint = this.transientSegment.endPoint;
        this.endExt.endPoint = e2;
    }

    updateMarks(): void {
        this.startMark = BpSvgMeasurementLabel.getStartMarkSegment(this.transientSegment);
        this.endMark = BpSvgMeasurementLabel.getEndMarkSegment(this.transientSegment);
    }

    showReticle(position: Point): void {
        this.reticlePosition = new SvgTransform({translate: position});
    }

    hideReticle(): void {
        this.reticlePosition = undefined;
    }

    moveReticle(position: Point): void {
        this.reticlePosition = new SvgTransform({translate: position});
    }

    moveEndpoint(p: Point): void {
        this.single?.moveEndpoint(p, this.selectedSingleEndPointIsStartPoint);
        this.calculateFreeTranslationEndpointHandle();
    }

    resetEndpoint(): void {
        this.single?.moveEndpoint(this.selectedSingleEndPoint!, this.selectedSingleEndPointIsStartPoint);
    }

    onEndHandled?: (clientPoint: Point) => void
    onStartPointHandleMouseDown(e: MouseEvent) {
        e.stopPropagation();
        this.selectedSingleEndPoint = this.single!.measureLine!.segment().startPoint;
        this.selectedSingleEndPointIsStartPoint = true;
        this.raiseEndHandled(new Point(e.clientX, e.clientY));
    }

    onEndPointHandleMouseDown(e: MouseEvent) {
        e.stopPropagation();
        this.selectedSingleEndPoint = this.single!.measureLine!.segment().endPoint;
        this.selectedSingleEndPointIsStartPoint = false;
        this.raiseEndHandled(new Point(e.clientX, e.clientY));
    }

    raiseEndHandled(clientPoint: Point): void {
        if (this.onEndHandled) {
            this.onEndHandled(clientPoint)
        } else {
            logError("MeasurementUpdateGizmoWM.onEndHandled n'est pas écouté");
        }
    }
}