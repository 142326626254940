@if (model) {
    <div class="flex flex-column h-full w-full">
        <div>
            <xc-board-toolbar [model]="model.boardToolbar"/>
        </div>
        <div class="h-full"
            (mousedown)="model.userInteraction.panzoomController.mouseDown($event)"
            (mousemove)="model.userInteraction.panzoomController.mouseMove($event)"
            (mouseleave)="model.userInteraction.panzoomController.mouseLeave($event)"
            (wheel)="model.userInteraction.panzoomController.mouseWheel($event)">

            <xc-drawing id="drawing" class="content" [model]="model.userInteraction"/>
        </div>
    </div>
}
