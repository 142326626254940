import { Point } from "src/app/core/model/geometry-model/point.model";

export class GanttSvgTaskLinkBase {
    id: number = 0;
    pathData: string | undefined;
    firstPoint!: Point;
    lastPoint!: Point;
    selected: boolean = false;
    deleteCommandPosition: Point = Point.origin();

    targetHandle: {x: number, y: number, width: number, height: number} | undefined;

    mouseClick(e: MouseEvent): void {
    }

    deleteCommandClick(): void {
    }
}