import { Point } from "src/app/core/model/geometry-model/point.model";
import { PolygonService } from "src/app/core/model/geometry-model/polygon-service";
import { SvgEntityTypesEnum } from "src/app/core/model/svg-model/svg-entity-type-enum";
import { SvgLineService } from "src/app/core/model/svg-model/svg-line-service";
import { UserInteraction } from "./user-interaction";
import { SvgGeometryService } from "../../svg/svg-geometry-service";
import { SelectableSvgLine } from "../../svg/selectable-svg-line";
import { SvgEntityPoint } from "../../../shared/gizmos/model/svg-entity-point";
import { SvgEntityPointStyleEnum } from "../../../shared/gizmos/model/svg-entity-point-style-enum";
import { LineInteraction } from './line-interaction';
import { IntersectionService } from "src/app/core/model/geometry-model/intersection-service";
import { SelectableSvgCircle } from "../../svg/selectable-svg-circle";
import { SvgCircleService } from "src/app/core/model/svg-model/svg-circle.service";
import { ArrayUtils } from "src/app/core/model/static-functions/array-utils";
import { SelectableSvgPath } from "../../svg/selectable-svg-path";
import { SvgPathService } from "src/app/core/model/svg-model/svg-path-service";
import { GripsService } from "../grips/grips-service";
import { PathGizmo } from "../../gizmo/model/path-gizmo";
import { PathInteraction } from "./path-interaction";
import { GeometricElement } from "src/app/core/model/geometry-model/geometric-element.model";
import { GeometricElementType } from "src/app/core/model/geometry-model/geometric-element-type.model";
import { Segment } from "src/app/core/model/geometry-model/segment.model";
import { Arc } from "src/app/core/model/geometry-model/arc.model";

export class TargetGripsDetection {
    // Cette méthode est appelée par le callback du timer de détection d'accrochage
    static detectTargetGrips(svgHitPoint: Point, ui: UserInteraction): void {
        // Défini le réticule de détection
        const reticle = PolygonService.centeredSquare(svgHitPoint, ui.getSvgPixelUnit() * 15);
        const grips: SvgEntityPoint[] = [];
        let selectedIds = ui.selectionInteraction.selectedEntities.map(x=> x.entityId);
        
        const entityClicked = ui.selectionInteraction.currentCommand.entityClicked;
        const gripClicked = ui.selectionInteraction.currentCommand.gripClicked;
        if (!entityClicked || !gripClicked) return;

        // toutes les entités du dessin
        const all = ui.drawing.entities;
        // Recherche les entités qui traversent le réticule
        // la ou les entités actuellement sélectionnées sont exclues de la recherche
        // nota: le cas où un élément est entièrement compris dans le réticule n'est pag géré
        // mais il est très improbable puisqu'il ne concerne que les éléments très petits
        // et si on veut s'accrocher à ces petits éléments il faut zoomer ce qui les rend détectables par le réticule
        const reticleEntities = SvgGeometryService.getIntersectedElements(all, reticle, selectedIds);
        
        // l'intersection n'a de sens que s'il n'y a qu'une seule entité sélectionnée
        if (ui.selectedGripsOptions.includes(SvgEntityPointStyleEnum.intersection) && selectedIds.length === 1) {
            let interEnts = SvgGeometryService.getGeometry([entityClicked]);
            if (entityClicked.entityType === SvgEntityTypesEnum.path) {
                // lorsque l'entité sélectionnée est un path, on doit considérer la ou les sous-entités
                // auxquelles appartient le grip cliqué
                interEnts = ((ui.currentInteractions[0] as PathInteraction).updateGizmo as PathGizmo).initialSubs;
            }

            if (ui.selectedGripsOptions.includes(SvgEntityPointStyleEnum.extension)) {
                // si l'accrochage extension est activé on étend les sous-entités
                const tmp: GeometricElement[] = [];
                interEnts.forEach(e => {
                    switch (e.elementType) {
                        case GeometricElementType.Segment:
                            tmp.push((e as Segment).stretched(1));
                            break;
                        case GeometricElementType.Arc:
                        default:
                            tmp.push(e);
                            break;
                    }
                });
                interEnts = tmp;
            }
    
            grips.push(...SvgGeometryService.getIntersects(reticleEntities, interEnts, 0.001));
        } 
    
        reticleEntities.forEach(ii => {
            const pts: SvgEntityPoint[] = []
            if (ui.selectedGripsOptions.includes(SvgEntityPointStyleEnum.end)) {
                pts.push(...ii.selectablePoints.filter(x=> x.gripStyle === SvgEntityPointStyleEnum.end));
            }
            if (ui.selectedGripsOptions.includes(SvgEntityPointStyleEnum.center)) {
                pts.push(...ii.selectablePoints.filter(x=> x.gripStyle === SvgEntityPointStyleEnum.center));
            }
            if (ui.selectedGripsOptions.includes(SvgEntityPointStyleEnum.middle)) {
                pts.push(...ii.selectablePoints.filter(x=> x.gripStyle === SvgEntityPointStyleEnum.middle));
            }
            if (ui.selectedGripsOptions.includes(SvgEntityPointStyleEnum.quadrant)) {
                pts.push(...ii.selectablePoints.filter(x=> x.gripStyle === SvgEntityPointStyleEnum.quadrant));
            }
            if (ui.selectedGripsOptions.includes(SvgEntityPointStyleEnum.orthogonal)) {
                // Cas des points calculés par rapport à l'élément en cours de modification
                // ce point pouvant être soit un grip soit le premier point d'une insertion en cours
                let p: Point | undefined;
                if (ui.currentCommand.isInsertionCommand()) {
                    // si on est en insertion il faut ajouter
                    p = ui.currentCommand.initialSvgMousePosition;
                } else {
                    p = ui.currentCommand.gripClicked?.point;
                }
        
                if (p) {
                    let pointToProject: Point | undefined;
                    // TODO : traitement pour un segment de path
                    if (ui.currentCommand.isInsertionCommand()) {
                        pointToProject = p;
                    } else {
                        if (entityClicked && entityClicked.entityType === SvgEntityTypesEnum.line) {
                            pointToProject = SvgLineService.otherEnd((entityClicked as SelectableSvgLine), p);
                        }
                    }
                    if (pointToProject) {
                        const op = SvgGeometryService.getOrthogonalProjection(ii, pointToProject);
                        if (op) {
                            pts.push(op);
                        }
                    }
                }
            }
            grips.push(...pts);
        });

        if (entityClicked && selectedIds.length === 1 && ui.selectedGripsOptions.includes(SvgEntityPointStyleEnum.extension)) {
            //const single = ui.selectionInteraction.single()!;
            switch (entityClicked.entityType) {
                case SvgEntityTypesEnum.line:
                    //if (intersectedItems.length === 1) {
                        //const intersectedItem = intersectedItems[0];
                        //const currentSegment = SvgLineService.geometry(single as SelectableSvgLine);
                        const interaction = ui.currentInteractions.find(x=> x.entityType === SvgEntityTypesEnum.line);
                        if (interaction) {
                            const originalEntity = (interaction as LineInteraction).getOriginal(entityClicked.entityId);
                            if (originalEntity) {
                                const originalSegment = SvgLineService.geometry(originalEntity);
                                reticleEntities.forEach(ii => {
                                    switch (ii.entityType) {
                                        case SvgEntityTypesEnum.line:
                                            const intersectedSegment = SvgLineService.geometry(ii as SelectableSvgLine);
                                            const tmp = intersectedSegment.stretched(1).getIntersect(originalSegment.stretched(1));
                                            if (tmp) {
                                                grips.push(new SvgEntityPoint(SvgEntityPointStyleEnum.extension, tmp));
                                            }
                                            break;
                                        case SvgEntityTypesEnum.circle:
                                            const cc = SvgCircleService.geometry(ii as SelectableSvgCircle);
                                            const ctmp = IntersectionService.getEntitiesIntersects([originalSegment.stretched(1), cc]);
                                            ctmp.forEach(p => {
                                                grips.push(new SvgEntityPoint(SvgEntityPointStyleEnum.extension, p));
                                            });
                                            break;
                                        case SvgEntityTypesEnum.ellipse:
                                            break;
                                        case SvgEntityTypesEnum.path:
                                            break;
                                        default:
                                            break;
                                    }
                                });
                            }
                        }
                    //}
                    break;
                case SvgEntityTypesEnum.path:
                    // Le cas d'une polyligne est plus complexe
                    // il faut distinguer selon que le segment terminal en cours de modification est un arc ou un segment de droite
                    // dans le second cas on est ramené au cas de la ligne
                    break;
                default:
                    break;
            }
        }


        // On élimine le grip utilisé lui-même dans le tableau de résultat
        const sameGripIndex = grips.findIndex(x=> x.equals(gripClicked!.point));
        grips.splice(sameGripIndex, 1);

        // Si la grille est affichée, on recherche l'intersection sous le réticule
        // if (ui.updateGizmos.gridGizmo.displayed && ui.currentGripStyle === SvgEntityPointStyleEnum.intersection) {
        //     const gi = ui.updateGizmos.gridGizmo.getTransformedNearestIntersect(svgHitPoint);
        //     if (gi) {
        //         grips.push(gi);
        //     }
        // }

        if (grips.length > 0) {
            const nearestGrip = Point.getNearest(grips, svgHitPoint);
            if (ui.showAllTargetGrips) {
                ui.targetGrips.loadFromPoints(grips);
            } else {
                // On affiche le point le plus proche de chaque type
                // d'abord on sépare les grips détectés en groupes de type
                const types = ArrayUtils.DistinctValueBy(grips, "gripStyle");
                const groups: SvgEntityPoint[][] = [];
                types.forEach(t => {
                    groups.push(grips.filter(x=> x.gripStyle === t));
                });
                // ensuite, on cherche le plus proche de chaque groupe
                const nearests: SvgEntityPoint[] = []
                groups.forEach(g => {
                    nearests.push(Point.getNearest(g, svgHitPoint));
                });
                ui.targetGrips.loadFromPoints(nearests);
            }
            ui.currentCommand.magnetAttraction = ui.getSvgPixelUnit() * 5;
            ui.targetGrips.select(nearestGrip);
        }
    }
}