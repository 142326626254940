import { TaskTypeEnum } from 'src/app/core/model/data-model/enums/task-type-enum';
import { Point } from 'src/app/core/model/geometry-model/point.model';
import { RealEstateView } from 'src/app/core/services/backend-services/dto/real-estate-view';
import { SelectOptionItem } from './select-option-item';

export class TaskCreateInternalDTO {
    clickPoint!: Point;
    type!: TaskTypeEnum
    name!: string;
    template: SelectOptionItem | undefined;
    businessType: SelectOptionItem | undefined;
    facilityTask: SelectOptionItem | undefined;
    floor: RealEstateView | undefined;
    layers: SelectOptionItem[] = [];
    startNewProject: boolean = false;
    isTemplate: boolean = false;

    constructor() {}

    setType(item: number): void {
        this.type = item;

        this.template = undefined;
        this.businessType = undefined;
        this.facilityTask = undefined;
        this.floor = undefined;
        this.layers.splice(0);
    }

    setTemplate(item: SelectOptionItem): void {
        this.template = item;
    }

    setBusinessType(item: SelectOptionItem): void {
        this.businessType = item;

        this.template = undefined;
        this.facilityTask = undefined;
        this.floor = undefined;
        this.layers.splice(0);
    }

    setFacilityTask(item: SelectOptionItem): void {
        this.facilityTask = item;

        this.floor = undefined;
        this.layers.splice(0);
    }
}