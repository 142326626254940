import { Component, Input } from '@angular/core';
import { BusinessUnitsToolbarVM } from '../../model/business-units-toolbar-vm';
import { MVVMEventDestroyLifeCycle } from 'src/app/components-lib/shared-model/mvvm-events-destroy-lifecycle';
import { RadioButtonModule } from 'primeng/radiobutton';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'pb-business-units-toolbar',
  standalone: true,
  imports: [FormsModule, RadioButtonModule],
  templateUrl: './business-units-toolbar.component.html'
})
export class BusinessUnitsToolbarComponent extends MVVMEventDestroyLifeCycle {
  @Input() override model: BusinessUnitsToolbarVM | undefined;
}
