import { EquipmentCatalogTable } from 'src/app/core/model/db-model/tables/equipment-catalog-table';
import { FloorTable } from "../tables/floor-table";
import { ProviderCompanyTable } from '../tables/provider-company-table';

export const FloorEquipmentProviderDbView = {
    ...FloorTable,
    databaseTableName: "floor_equipment_provider_view",
    eqCgProviderId: EquipmentCatalogTable.eqCgProviderId,
    prCo_Name: ProviderCompanyTable.prCoName,
    prCo_Color: ProviderCompanyTable.prCoColor,
    equipmentValue: "EquipmentValue",
    equipmentCount: "EquipmentCount"
}