import { SvgEntityTypesEnum } from "src/app/core/model/svg-model/svg-entity-type-enum";
import { SvgRectangle } from "src/app/core/model/svg-model/svg-rectangle.model";

export class SvgBsFloorDataItem extends SvgRectangle {
    id: number;
    label: string;

    constructor(x: number, y: number, width: number, height: number, id: number, label: string) {
        super({x: x, y: y, width: width, height: height, entityType: SvgEntityTypesEnum.rect});

        this.id = id;
        this.label = label;
    }
}
