export enum SvgEntityTypesEnum {
    undefined = 0,
    circle = 1,
    ellipse = 2,
    line = 3,
    path = 4,
    text = 5,
    rect = 6,
    g = 10,
    use = 20
  }
  