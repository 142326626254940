import { TaskView } from "src/app/core/model/data-model/views/task-view";
import { TaskTypeEnum } from "src/app/core/model/data-model/enums/task-type-enum";
import { Task } from "src/app/core/model/data-model/tables/task";
import { DateUtils } from "src/app/core/model/static-functions/date-utils";
import { MoveWorkTreeNode } from "../../nested/side-panel/model/move-work-tree-node";
import { MoveWorkTreeNodeTypeEnum } from "../../nested/side-panel/model/move-work-tree-node-type-enum";

export class TaskTreeBuilder {

    // static async loadTree(): Promise<MoveWorkTreeNode[]> {
    //     const s = Container.get(PlanningService);
    //     const moves = await s.downloadMoves(true, false, false, false);
    //     const startDateSortedMoves = moves.sort((a, b) => a.taStartDate.getTime() - b.taStartDate.getTime());
    //     return this.loadMovesTree(startDateSortedMoves);
    // }

    static loadMovesTree(tasks: TaskView[]): MoveWorkTreeNode[] {
        const tmp: MoveWorkTreeNode[] = [];

        const rootTasks = tasks.filter(x=> x.taDepth === 1);
        rootTasks.forEach(t => {
            const taskNode = new MoveWorkTreeNode(MoveWorkTreeNodeTypeEnum.project, t.taId, t.taName, t);
            taskNode.setChildren(this.loadChildrenTasks(tasks, t));
            tmp.push(taskNode);
        });

        return tmp;
    }

    private static loadChildrenTasks(tasks: TaskView[], t: Task): MoveWorkTreeNode[] {
        const result: MoveWorkTreeNode[] = [];

        const children = tasks.filter(x=> x.taParentId === t.taId);
        children.forEach(c => {
            const type = c.taTypeId === TaskTypeEnum.Project ? MoveWorkTreeNodeTypeEnum.project : MoveWorkTreeNodeTypeEnum.task;
            const taskNode = new MoveWorkTreeNode(type, c.taId, this.getNodeName(c), c);
            taskNode.setChildren(this.loadChildrenTasks(tasks, c));
            result.push(taskNode);
        });

        return result;
    }

    static getNodeName(task: TaskView): string {
        let name = task.taName;
        const type = task.taTypeId === TaskTypeEnum.Project ? MoveWorkTreeNodeTypeEnum.project : MoveWorkTreeNodeTypeEnum.task;
        if (type === MoveWorkTreeNodeTypeEnum.task) name = task.businessTypeView.taBuTyDisplayName + " - " + name;
        return name;
    }

    static getDateRange(tasks: TaskView[]): [Date, number] {
        if (tasks.length === 0) return [DateUtils.today(), 1];

        // On trie les projets par date pour la détermination de la plage de durée du calendrier
        tasks = tasks.sort((a, b) => a.taStartDate.getTime() - b.taStartDate.getTime());

        const firstProject = tasks[0];
        const sd = firstProject.taStartDate;
        const sdt = sd.getTime();
        let ed =  1;

        // Sachant que les projets sont triés par date de début
        tasks.forEach(t => {
            const timeDelta = t.taStartDate.getTime() - sdt;
            const daysDelta = timeDelta / (1000 * 3600 * 24);
            if (ed < daysDelta) ed = daysDelta;
        });

        const lastProject = tasks[tasks.length -1];
        ed = ed + lastProject.taDuration;

        ed = Math.ceil(ed);

        return [sd, ed];
    }

    
    static flatNodes(tree: MoveWorkTreeNode[]): MoveWorkTreeNode[] {
        const tmp: MoveWorkTreeNode[] = [];
        tree.forEach(r => {
            tmp.push(r);
            tmp.push(...this.childrenTaskNodes(r));
        });
        return tmp;
    }

    private static childrenTaskNodes(parent: MoveWorkTreeNode): MoveWorkTreeNode[] {
        const result: MoveWorkTreeNode[] = [];
        parent.children.forEach(c => {
                result.push(c);
                result.push(...this.childrenTaskNodes(c));
        });
        return result;
    }

}