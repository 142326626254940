import { Polygon } from 'src/app/core/model/geometry-model/polygon.model';
import { GeometricElementType } from "./geometric-element-type.model";
import { GeometricElement } from "./geometric-element.model";
import { Point } from "./point.model";
import { Segment } from "./segment.model";
import { Circle } from './circle.model';
import { Rectangle } from './rectangle.model';
import { Ellipse } from './ellipe.model';
import { Arc } from './arc.model';
import { logError } from '../../services/logging-service';

export class IntersectionService {
    /**
     * Retourne les points d'intersection en un élément géométrique et un rectangle, typiquement un réticule de sélection
     * @param e 
     * @param rectangle 
     * @returns 
     */
    static getRectangleIntersects(e: GeometricElement, rectangle: Rectangle): Point[] {
        const result: Point[] = [];

        rectangle.getSegments().forEach(s => {
            result.push(...this.getIntersects(e, s));
        });

        return result;
    }

    /**
     * Retourne les points d'intersection entre un élément géométrique et un segment de droite
     * @param e Elément géométrique à tester
     * @param segment Segment de droite 
     * @returns 
     */
    static getIntersects(e: GeometricElement, segment: Segment): Point[] {
        let result: Point[] = [];

        //elements.forEach(e => {
            switch (e.elementType) {
                case GeometricElementType.Segment:
                    const sr = (e as Segment).getIntersect(segment);
                    if (sr) result.push(sr);
                    break;
                case GeometricElementType.Polygon:
                    result.push(...(e as Polygon).getIntersects(segment));
                    break;
                case GeometricElementType.Circle:
                    result.push(...(e as Circle).getIntersects(segment));
                    break;
                case GeometricElementType.Ellipse:
                    result.push(...(e as Ellipse).getIntersects(segment));
                    break;
                case GeometricElementType.Arc:
                    result.push(...(e as Arc).getIntersects(segment));
                    break;
                default:
                    break;
            }
        //});

        return result;
    }

    static getEntitiesIntersects(elements: GeometricElement[], tolerance: number = Number.EPSILON): Point[] {
        const result: Point[] = [];
        if (elements.length !== 2) {
            logError("Le nombre d'éléments passés à IntersectionService.getEntitiesIntersects est incorrect");
            return result;
        }

        const e1 = elements[0];
        const e2 = elements[1];
        const types = elements.map(x=> x.elementType);

        if (e1.elementType === GeometricElementType.Segment && e2.elementType === GeometricElementType.Segment) {
            const ssi = (e1 as Segment).getIntersect((e2 as Segment));
            return ssi !== null ? [ssi] : [];
        }

        if (e1.elementType === GeometricElementType.Arc && e2.elementType === GeometricElementType.Segment) {
            return (e1 as Arc).getIntersects((e2 as Segment), tolerance);
        }

        if (e1.elementType === GeometricElementType.Segment && e2.elementType === GeometricElementType.Arc) {
            return (e2 as Arc).getIntersects((e1 as Segment), tolerance);
        }

        if (e1.elementType === GeometricElementType.Arc && e2.elementType === GeometricElementType.Arc) {
            return (e2 as Arc).getIntersects((e1 as Arc), tolerance);
        }

        if (types.includes(GeometricElementType.Segment) && types.includes(GeometricElementType.Circle)) {
            const s = elements.find(x=> x.elementType === GeometricElementType.Segment) as Segment;
            const c = elements.find(x=> x.elementType === GeometricElementType.Circle) as Circle;
            if (s && c) {
                return IntersectionService.getSegmentCircleIntersects(s, c);
            }
        }

        return result;

    }

    static getSegmentsIntersects(segments: Segment[], segmentToTest: Segment): Point[] {
        let result: Point[] = [];

        segments.forEach(s => {
            const i = s.getIntersect(segmentToTest);
            if (i != null) {
                result.push(i);
            }
        });

        return result;
    }


    // TODO : il y a une erreur quelque part, dans certains cas on ne récupère pas les deux points attendus
    static getSegmentCircleIntersects(seg: Segment, ccl: Circle): Point[] {
        const result: Point[] = [];
        
        const baX = seg.endPoint.x - seg.startPoint.x;
        const baY = seg.endPoint.y - seg.startPoint.y;
        const caX = ccl.center.x - seg.startPoint.x;
        const caY = ccl.center.y - seg.startPoint.y;

        const a = baX * baX + baY * baY;
        const bBy2 = baX * caX + baY * caY;
        const c = caX * caX + caY * caY - ccl.radius * ccl.radius;

        const pBy2 = bBy2 / a;
        const q = c / a;

        const d = pBy2 * pBy2 - q;

        if (d < 0)
        {
            return [];
        }

        const tmpSqrt = Math.sqrt(d);
        const abScalingFactor1 = -pBy2 + tmpSqrt;
        const abScalingFactor2 = -pBy2 - tmpSqrt;

        const p1 = new Point(seg.startPoint.x - baX * abScalingFactor1, seg.startPoint.y - baY * abScalingFactor1);
        if (seg.contains(p1))
        {
            result.push(p1);
        }
        if (d === 0)
        {
            return result;
        }

        const p2 = new Point(seg.startPoint.x - baX * abScalingFactor2, seg.startPoint.y - baY * abScalingFactor2);
        if (seg.contains(p2))
        {
            result.push(p2);
        }

        return result;
    }

    // Conic sections
    // http://www.nabla.hr/Z_MemoHU-029.htm
}