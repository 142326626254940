import { RoomAttributionTypeEnum } from "src/app/core/model/data-model/enums/room-attribution-type-enum";
import { PeopleLocation } from "src/app/core/model/data-model/tables/people-location";
import { Point } from "src/app/core/model/geometry-model/point.model";
import { HtmlConstants } from "src/app/core/model/html-model/html-constants.model";
import { UpdateGizmo } from "../../update-gizmo.model";
import { RoomUpdateGizmoArgs } from './room-update-gizmo-args';
import Container from 'typedi';
import { MatDialog } from '@angular/material/dialog';
import { EntitiesSelectionSet } from '../../../../itself/model/interaction/entities-selection-set';
import { BpRoomService } from '../../../../itself/services/bp-room-service';
import { RoomContourDataDTO } from '../../../../itself/services/dto/room-contour-data-dto';
import { WorkplaceAllocationsEditorComponent } from '../../../../../dialog/workplace-allocation-editor/view/workplace-allocations-editor/workplace-allocations-editor.component';
import { BpSvgRoomLabel } from '../../../../../svg-entities/model/bp-svg-room-label';
import { BpSvgText } from '../../../../../bp-svg-core-model/bp-svg-text';
import { SvgEntityTypesEnum } from 'src/app/core/model/svg-model/svg-entity-type-enum';
import { PeopleLocationDisplay } from '../../../../../dialog/workplace-allocation-editor/model/people-location-display';
import { take } from 'rxjs';
import { logError } from 'src/app/core/services/logging-service';

export class RoomUpdateGizmoVM implements UpdateGizmo {
    display: string = HtmlConstants.noneValue;
    isPlanningTask: boolean = false;
    canUpdateFurnitureLayer: boolean = false;

    isEditable: boolean = false;

    selectionSet: EntitiesSelectionSet = new EntitiesSelectionSet();

    initialMousePosition: Point | undefined;
    commandsPosition: Point = Point.origin();
    peopleCommandPosition: Point = Point.origin();
    recalculateCommandPosition: Point = Point.origin();
    findSimilarCommandPosition: Point = Point.origin();
    
    //newContourPathData: string;
    recalculateResult: RoomContourDataDTO | undefined;
    
    transientLabel: BpSvgText | undefined;
    inserting: boolean = false;

    RoomAttributionTypeEnum = RoomAttributionTypeEnum;
    workplaceAllocations: PeopleLocation[] = [];

    dialog: MatDialog | undefined;

    constructor() {}

    show(args: RoomUpdateGizmoArgs): void {
        this.isPlanningTask = args.isPlanningTask;
        this.selectionSet = args.selectionSet;
        this.canUpdateFurnitureLayer = args.canUpdateFurnitureLayer;
        this.isEditable = args.isEditable;

        this.setCommandButtons();
    }

    hide(): void {
        this.display = HtmlConstants.styleDisplayNone;
        this.transientLabel = undefined;
    }

    showTransient(code: string): void {
        const data = { text: code, x: 0, y: 0, fontSize: '0.5', entityType: SvgEntityTypesEnum.text }
        this.transientLabel = new BpSvgText(data);
    }

    hideTransient(): void {
        this.transientLabel = undefined;
        this.inserting = false;
    }

    moveTransientLabel(hitPoint: Point): void {
        this.transientLabel?.moveTo(hitPoint);
    }

    onSelectionSetChanged(): void {
        this.setCommandButtons();
    }

    setCommandButtons(): void {
        // Le milieu en haut de la largeur de l'étiquette
        if (this.selectionSet?.items.length === 1) {
            const label = this.selectionSet.items[0] as BpSvgRoomLabel;
            const labelTopMidPointX = Number(label.backgroundRectangle.x) + (label.backgroundRectangle.width / 2);
            const labelTopMidPointY = Number(label.backgroundRectangle.y) - 0.25;
            this.commandsPosition = new Point(labelTopMidPointX - 0.12, labelTopMidPointY);
           // TODO : Ecriture "à plat" à optimiser. Supprimer les doublons de code
            if (label.svgRoom?.room().roAttributionTypeId === RoomAttributionTypeEnum.Allocation) {
                if (this.isPlanningTask) {
                    // La commande de similarité n'est disponible que lorsque l'étude comprend le calque mobilier
                    if (this.canUpdateFurnitureLayer) {
                        // Les trois commandes sont affichées
                        this.peopleCommandPosition = new Point(labelTopMidPointX - 0.12 - 0.31, labelTopMidPointY);
                        this.recalculateCommandPosition = new Point(labelTopMidPointX - 0.12, labelTopMidPointY);
                        this.findSimilarCommandPosition = new Point(labelTopMidPointX + 0.12 + 0.08, labelTopMidPointY);
                    } else {
                        this.peopleCommandPosition = new Point(labelTopMidPointX - 0.28, labelTopMidPointY);
                        this.recalculateCommandPosition = new Point(labelTopMidPointX + 0.04, labelTopMidPointY);
                    }
                } else {
                    // Seul la commande de flex allocation est affichée
                    this.peopleCommandPosition = new Point(labelTopMidPointX - 0.12, labelTopMidPointY);
                }
            } else {
                if (this.isPlanningTask) {
                    // La commande de similarité n'est disponible que lorsque l'étude comprend le calque mobilier
                    if (this.canUpdateFurnitureLayer) {
                        // Les commandes de recalcul et de recherche de similarité sont affichées
                        this.recalculateCommandPosition = new Point(labelTopMidPointX - 0.28, labelTopMidPointY);
                        this.findSimilarCommandPosition = new Point(labelTopMidPointX + 0.04, labelTopMidPointY);
                    } else {
                        this.recalculateCommandPosition = new Point(labelTopMidPointX - 0.12, labelTopMidPointY);
                    }
                } else {
                    // Aucune commande n'est affichée
                }
            }
        } else {
            if (this.transientLabel !== undefined) {
                const p = this.transientLabel.position();
                this.commandsPosition = new Point(p.x - 0.12, p.y - 0.7);
            }
        }
    }

    // showValidationContourGizmo(pathData: string): void {
    //     this.newContourPathData = pathData;
    // }

    hideContourValidationGizmo(): void {
        this.recalculateResult = undefined;
    }


    initializeTranslation(initialMousePosition: Point): void {
        this.initialMousePosition = initialMousePosition;
        if (this.selectionSet.count === 1) {
            this.selectionSet.single<BpSvgRoomLabel>().initializeTranslation();
        }
    }

    translate(hitPoint: Point): void {
        if (!this.initialMousePosition) return;
        const delta = new Point(hitPoint.x - this.initialMousePosition.x, hitPoint.y - this.initialMousePosition.y).vector();
        if (this.selectionSet.count === 1) {
            this.selectionSet.single<BpSvgRoomLabel>().translate(delta);
            this.setCommandButtons();
        }
    }

    cancelTranslation(): void {
        if (this.selectionSet.count === 1) {
            this.selectionSet.single<BpSvgRoomLabel>().cancelTranslation();
        }
    }

    async updateButtonMouseDown(e: MouseEvent) {
        e.stopPropagation();

        if (this.selectionSet.count === 1) {
            const label = this.selectionSet.items[0] as BpSvgRoomLabel;

            const labelCenter = label.backgroundRectangle.center();
            const s = Container.get(BpRoomService);
            const result = await s.getSvgContour(label.taskId, labelCenter.x, labelCenter.y, true);
            if (result != null) {
                // La détection de coutour a réussi, on demande une validation au user
                this.recalculateResult = result;
                // Les deux lignes suivantes servent à générer l'apparition progressive à l'écran via le css transition
                this.recalculateResult.setTransformOrigin(labelCenter);
                this.recalculateResult.scale = 1;
            } else {
                // La détection de contour a échoué
                alert("Impossible de détecter le contour du local. Vérifiez les jonctions des cloisons.");
            }
        }

    }

    roomRecalculated?: () => void;
    roomCreationValidated?: (code: string, path: string, wallsIds: number[], position: Point) => void;
    async validateContourButtonMouseDown(e: MouseEvent) {
        e.stopPropagation();

        if (this.selectionSet != null && this.selectionSet.items.length === 1) {
            // Cas où il s'agit de la validation d'un recalcul d'une surface existante
            const roomLabel = this.selectionSet.single<BpSvgRoomLabel>();
            if (roomLabel && roomLabel.svgRoom && roomLabel.svgRoom.roomSet && this.recalculateResult) {
                const roomId = roomLabel.svgRoom.floorDataId;
                const roomPosition = roomLabel.text.position();
                const s = Container.get(BpRoomService);
                const result = await s.reCalculateRoom(roomId, this.recalculateResult.svgPathDTO.d!, roomPosition.x, roomPosition.y, this.recalculateResult.wallIds);
                if (result != null) {
                    roomLabel.svgRoom.updatePath(this.recalculateResult.svgPathDTO.d!);
                    roomLabel.svgRoom.room().roArea = result.room.roArea;
                    if (this.roomRecalculated) {
                        this.roomRecalculated();
                    } else {
                        logError("RoomUpdateGizmoVM.roomRecalculated n'était pas écouté");
                    }
                    }
            }
        } else {
            if (this.transientLabel != null && this.recalculateResult) {
                // Cas où il s'agit de la validation d'une création de local
                if (this.roomCreationValidated) {
                    this.roomCreationValidated(this.transientLabel.text!, 
                        this.recalculateResult.svgPathDTO.d!, 
                        this.recalculateResult.wallIds!,
                        this.transientLabel.position());
                } else {
                    logError("RoomUpdateGizmoVM.roomCreationValidated n'était pas écouté");
                }
            }
        }

        this.hideContourValidationGizmo();
    }

    findSimilarRoomRequested?: () => void;
    async findSimilarButtonMouseDown(e: MouseEvent): Promise<void> {
        e.stopPropagation();
        if (this.findSimilarRoomRequested) {
            this.findSimilarRoomRequested();
        } else {
            logError("RoomUpdateGizmoVM.findSimilarRoomRequested n'était pas écouté");
        }
    }

    cancelContourButtonMouseDown(e: MouseEvent) {
        e.stopPropagation();
        // L'utilisateur a annulé le recalcul de surface, on ne fait rien
        this.hideContourValidationGizmo();
    }

    peopleLocationsChange?: (e: PeopleLocationDisplay[]) => void;
    async onLocationsChange(result: PeopleLocationDisplay[]): Promise<void> {
        if (this.peopleLocationsChange) {
            this.peopleLocationsChange(result);
        } else {
            logError("RoomUpdateGizmoVM.peopleLocationsChange n'était pas écouté");
        }
    }

    overlayMouseDown?: (e: MouseEvent) => void;
    onOverlayMouseDown(e: MouseEvent): void {
        if (this.overlayMouseDown) {
            this.overlayMouseDown(e);
        } else {
            logError("RoomUpdateGizmoVM.overlayMouseDown n'était pas écouté");
        }
    }

    async peopleLocationButtonMouseDown(e: MouseEvent): Promise<void> {
        e.stopPropagation();
        if (this.selectionSet.count === 1 && this.dialog) {
            const label = this.selectionSet.single<BpSvgRoomLabel>();
            //const s = Container.get(DyntService);
            //const directory = await s.downloadTable<DirectoryViewSet>(ViewsNames.DirectoryView, TablesSetsEnum.DirectoryViewSet);
            // const ds = Container.get(DyntService);
            // const currentAllocations = await ds.downloadTable<PeopleLocationViewSet>(PeopleLocationDbView.databaseTableName, 
            //     TablesSetsEnum.PeopleLocationViewSet, 
            //     PeopleLocationDbView.parentId,
            //     label.floorDataId);

            let dialogRef = this.dialog.open<WorkplaceAllocationsEditorComponent, any, PeopleLocationDisplay[]>(WorkplaceAllocationsEditorComponent, { data: { workplaceId: label.floorDataId, currentAllocations: label.peopleLocations, useRatio: false, title: "Localisation des personnes" }, width: "800px" });
            // Le .pipe(take(1)) s'assure que le traitement ne sera exécuté qu'une seule fois
            dialogRef.afterClosed().pipe(take(1)).subscribe(async result => {
                if (result != null) {
                    // Si le résultat n'est pas null c'est que les allocations ont été modifiées et que le user a cliqué sur Ok
                    await this.onLocationsChange(result);
                }
            });
        }
    }
}