import { Point } from "src/app/core/model/geometry-model/point.model";
import { SvgEntityTypesEnum } from "src/app/core/model/svg-model/svg-entity-type-enum";
import { IEntityInteraction } from "./i-entity-interaction";
import { SelectionInteraction } from "./selection-interaction";
import { InteractionCommand } from "./interaction-command";
import { DxfLayerDTO } from "src/app/core/services/backend-services/dto/dxf-layer-dto";
import { EntityInteraction } from "./entity-interaction";
import { LineGizmo } from "../gizmos/update/line-gizmo";
import { DxfSvgLine } from "../svg/dxf-svg-line";

export class LineInteraction extends EntityInteraction implements IEntityInteraction<DxfSvgLine> {
    entityType: SvgEntityTypesEnum = SvgEntityTypesEnum.line;
    layers: DxfLayerDTO[] = [];
    currentCommand: InteractionCommand;
    selectionSet: DxfSvgLine[] = [];
    selectionInteraction: SelectionInteraction | undefined;
    updateGizmo: LineGizmo;

    constructor(command: InteractionCommand, layers: DxfLayerDTO[], gizmo: LineGizmo) {
        super();
        this.currentCommand = command;
        this.layers = layers;
        this.updateGizmo = gizmo;
    }
    
    activate(entities: DxfSvgLine[], toRemove: boolean): Promise<void> {
        throw new Error("Method not implemented.");
    }
    
    deactivate(): void {
        throw new Error("Method not implemented.");
    }
    
    abort(): void {
        throw new Error("Method not implemented.");
    }
    
    deleteSelectedItems(): Promise<void> {
        throw new Error("Method not implemented.");
    }
    
    beginInsert(args: any): void {
        throw new Error("Method not implemented.");
    }
    
    selectionDeleted?: (() => void) | undefined;
    raiseSelectionDeleted(): void {
        throw new Error("Method not implemented.");
    }
    
    async mouseDown(e: MouseEvent, hitPoint: Point): Promise<boolean> {
        throw new Error("Method not implemented.");
    }
    
    async mouseMove(e: MouseEvent, hitPoint: Point): Promise<void> {
        if (this.currentCommand.isLineEndpointTranslationCommand()) {
            // Actualise la position courante de la souris pour le contrôle de l'attraction lors du prochain MouseMove
            this.currentCommand.tempSvgMousePosition = hitPoint;
      
            this.updateGizmo.freeTranslateEndpoint(hitPoint, false);

            return;
        }

        if (this.currentCommand.isEntityTranslationCommand() && this.currentCommand.initialSvgMousePosition) {
            const delta = hitPoint.minus(this.currentCommand.initialSvgMousePosition);
            this.updateGizmo.translateLines(delta);
        }
    }
    
    async mouseUp(e: MouseEvent, hitPoint: Point | undefined): Promise<void> {
        if (this.currentCommand.isLineEndpointTranslationCommand() && this.updateGizmo.single) {
            if (hitPoint) {
                this.updateGizmo.freeTranslateEndpoint(hitPoint, false);
            }
            this.currentCommand.clear();
            this.updateGizmo.endFreeTranslateEndpoint();       
            this.raiseGeoTileUpdateRequested([this.updateGizmo.single]);           

            return;         
        }

        if (this.currentCommand.isEntityTranslationCommand()) {
            if (hitPoint && this.currentCommand.gripClicked) {
                const delta = hitPoint.minus(this.currentCommand.gripClicked.point);
                this.updateGizmo.translateLines(delta);
            }
            this.updateGizmo.endTranslate();       
            this.updateGizmo.clearCurrent();   
            return;         
        }
    }
    
    keyDown(e: KeyboardEvent): Promise<void> {
        throw new Error("Method not implemented.");
    }
    
}