import { SimulationFloorDbView } from "../../db-model/views/simulation-floor-db-view";
import { DefinedModelBase } from "../defined-model-base";

export class SimulationFloorView extends DefinedModelBase {
    flCode!: string;
    flElevation!: number;
    siFlViId!: number;
    siFlViRootSimulation!: number;
    siFlViFloor!: number;
    siFlViArea!: number;
    siFlViBuildingId!: number;

    constructor(dtoData: any) {
        super(dtoData, [SimulationFloorDbView]);
    }
}