import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TaskPropertiesVM } from '../../model/task-properties-vm';
import { FormsModule } from '@angular/forms';
import { MaterialModule } from 'src/app/core/material.module';

@Component({
  selector: 'xc-task-properties',
  standalone: true,
  imports: [CommonModule, FormsModule, MaterialModule],
  templateUrl: './task-properties.component.html',
  styleUrls: ['./task-properties.component.scss']
})
export class TaskPropertiesComponent {
  @Input() model: TaskPropertiesVM | undefined;
}
