import { DefinedModelBase } from "src/app/core/model/data-model/defined-model-base";

export class LeaseTreeNode {
    id: number;
    name: string;
    startDate: Date;
    duration: number;
    color: string;
    children: LeaseTreeNode[] = [];
    data: DefinedModelBase;

    constructor(id: number, name: string, startDate: Date, duration: number, color: string, data: DefinedModelBase) {
        this.id = id;
        this.name = name;
        this.startDate = startDate;
        this.duration = duration;
        this.color = color;
        this.data = data;
    }
}