@if (model) {
    <p-toast position="top-center"/>

    <div [style]="{display: 'contents'}">
        @if (model.selectedClient) {
            <xc-menu-panel id="menuPanel" [class.mouseover]="model.menuPanel?.mouseIsOver" [model]="model.menuPanel" />
        }
        @if (model.selectedPage) {
            <div class="flex h-full" [style]="{flexFlow: 'column'}">
                @if (model.selectedClient) {
                    <div [style]="{flex: '0 1 auto'}">
                        <p-toolbar styleClass="py-1 px-2">
                            <div class="p-toolbar-group-start">
                                <p-breadcrumb [model]="model.breadcrumbSegments" [home]="model.breadcrumbHome"></p-breadcrumb>
                            </div>
                            <div class="p-toolbar-group-center">
                                <xc-nested-toolbar [model]="model.nestedToolbar"/>
                            </div>
                            <div class="p-toolbar-group-end mr-2">
                                <p-button (click)="op.toggle($event)" icon="pi pi-question" [rounded]="true" [text]="true" size="small" />
                                <p-overlayPanel #op [showCloseIcon]="true" dismissable="false" >
                                    <div class="flex flex-column gap-3 w-25rem" [style.maxHeight.vh]="90">
                                        <h1>Aide contextuelle</h1>
                                    </div>
                                </p-overlayPanel>
                            </div>
                        </p-toolbar>
                    </div>
                    <!-- <div class="flex align-items-center w-full px-5 " [style]="{backgroundColor: '#f9fafb'}">
                    </div> -->
                }
                <div [style]="{flex: '1 1 auto', overflow: 'hidden'}">
                    @switch (model.selectedPage.route) {
                        @case (model.routes.clients) {
                            <xc-clients [model]="$any(model.selectedPage)" />
                        } 
                        @case (model.routes.welcome) {
                            <xc-welcome-screen [model]="$any(model.selectedPage)" />
                        } 
                        @case (model.routes.administration_useraccount) {
                            <xc-user-account-admin [model]="$any(model.selectedPage)"/>
                        }
                        @case (model.routes.administration_layergrants) {
                            <xc-layers-grants [model]="$any(model.selectedPage)"/>
                        }
                        @case (model.routes.administration_roles) {
                            <xc-roles-admin [model]="$any(model.selectedPage)"/>
                        }
                        @case (model.routes.administration_maildomains) {
                            <xc-domains [model]="$any(model.selectedPage)"/>
                        }
                        @case (model.routes.layout_inventory_catalogs) {
                            <xc-catalog-page [model]="$any(model.selectedPage)"/>
                        }
                        @case (model.routes.layout_inventory_purchases) {
                            <xc-purchases-list [model]="$any(model.selectedPage)"/>
                        }
                        @case (model.routes.layout_inventory_stores) {
                            <xc-stores-manage [model]="$any(model.selectedPage)"/>
                        }
                        @case (model.routes.layout_inventory_providers) {
                            <xc-providers-manage [model]="$any(model.selectedPage)"/>
                        }
                        @case (model.routes.layout_realestate_map) {
                            <xc-map [model]="$any(model.selectedPage)"/>
                        }
                        @case (model.routes.layout_realestate_sites) {
                            <xc-sites [model]="$any(model.selectedPage)"/>
                        }
                        @case (model.routes.layout_realestate_buildings) {
                            <xc-buildings [model]="$any(model.selectedPage)"/>
                        }
                        @case (model.routes.layout_realestate_floors) {
                            <xc-floors [model]="$any(model.selectedPage)"/>
                        }
                        @case (model.routes.layout_realestate_perimeters) {
                            <xc-custom-perimeters-manage [model]="$any(model.selectedPage)"/>
                        }
                        @case (model.routes.layout_realestate_layouttypes) {
                            <xc-layout-type [model]="$any(model.selectedPage)"/>
                        }
                        @case (model.routes.layout_realestate_cities) {
                            <xc-ref-city-admin [model]="$any(model.selectedPage)"/>
                        }
                        @case (model.routes.layout_realestate_addresses) {
                            <xc-addresses-admin [model]="$any(model.selectedPage)"/>
                        }
                        @case (model.routes.layout_realestate_workplacetypes) {
                            <xc-workplaces-admin [model]="$any(model.selectedPage)"/>
                        }
                        <!-- @case (model.routes.layout_realestate_assets) {
                            <xc-real-estate-browser class="extended" [model]="$any(model.selectedPage)" />
                        }  -->
                        @case (model.routes.layout_organization_dashboard) {
                            <pb-business-units-browser [model]="$any(model.selectedPage)" />
                        } 
                        @case (model.routes.layout_organization_upload) {
                            <xc-business-units-upload class="extended" [model]="$any(model.selectedPage)" />
                        } 
                        @case (model.routes.planning_mobility_projects) {
                            <xc-mobility-browser class="extended" [model]="$any(model.selectedPage)" />
                        } 
                        @case (model.routes.planning_mobility_contributors) {
                            <xc-mobility-contributors [model]="$any(model.selectedPage)" />
                        } 
                        @case (model.routes.planning_mobility_companies) {
                            <xc-mobility-facility-companies [model]="$any(model.selectedPage)" />
                        } 
                        @case (model.routes.planning_mobility_functions) {
                            <xc-mobility-functions [model]="$any(model.selectedPage)" />
                        } 
                        @case (model.routes.planning_mobility_jobtasks) {
                            <xc-mobility-job-tasks [model]="$any(model.selectedPage)" />
                        } 
                        @case (model.routes.planning_mobility_jobs) {
                            <xc-mobility-jobs [model]="$any(model.selectedPage)" />
                        } 
                        @case (model.routes.planning_mobility_companytasks) {
                            <xc-mobility-company-tasks [model]="$any(model.selectedPage)" />
                        } 
                        @case (model.routes.planning_mobility_projecttasks) {
                            <xc-mobility-project-task-types [model]="$any(model.selectedPage)" />
                        } 
                        @case (model.routes.planning_strategy_simulations) {
                            <xc-strategy-editor class="extended" [model]="$any(model.selectedPage)" />
                        } 
                        @case (model.routes.planning_strategy_leases) {
                            <xc-lease-editor class="extended" [model]="$any(model.selectedPage)" />
                        } 
                        @case (model.routes.planning_strategy_referentials_leases) {
                            <xc-lease-list [model]="$any(model.selectedPage)" />
                        } 
                        @case (model.routes.planning_strategy_referentials_leaseitems) {
                            <xc-lease-items [model]="$any(model.selectedPage)" />
                        } 
                        @case (model.routes.planning_strategy_referentials_durationtypes) {
                            <xc-lease-duration-types [model]="$any(model.selectedPage)" />
                        } 
                        @case (model.routes.planning_strategy_referentials_contractors) {
                            <xc-lease-contractors [model]="$any(model.selectedPage)" />
                        } 
                        @case (model.routes.planning_strategy_referentials_leasetypes) {
                            <xc-lease-types [model]="$any(model.selectedPage)" />
                        } 
                        @case (model.routes.planning_maintenance) {
                            <xc-maintenance-browser class="extended" [model]="$any(model.selectedPage)" />
                        } 
                        @case (model.routes.graphicworks_blueprints) {
                            <xc-cad-converter-container class="extended" [model]="$any(model.selectedPage)" />
                        } 
                        @case (model.routes.graphicworks_pictograms) {
                            <xc-drawing-container class="extended" [model]="$any(model.selectedPage)" />
                        }
                        <!-- @case ("helpdesk") {
                            <xc-helpdesk-main class="extended" [model]="$any(model.selectedPage)" />
                        } -->
                    }
                </div>
                <footer [style]="{flex: '0 1 1px'}">
                </footer>
            </div>
        }
    </div>
}