import { RoomAllocationTable } from './../tables/room-allocation-table';
import { BusinessUnitTable } from './../tables/business-unit-table';
import { FloorDataTable } from './../tables/floor-data-table';
import { FloorTable } from './../tables/floor-table';
import { BuildingTable } from './../tables/building-table';

export const FloorAllocationDbView = {
  databaseTableName: 'floor_allocation_view',
  buSiteId: BuildingTable.buSiteId,
  flBuildingId: FloorTable.flBuildingId,
  flId: FloorTable.flId,
  flDaTaskId: FloorDataTable.flDaTaskId,
  buUnName: BusinessUnitTable.buUnName,
  roAlBusinessUnitId: RoomAllocationTable.roAlBusinessUnitId,
  buUnCode: BusinessUnitTable.buUnCode,
  buUnColor: BusinessUnitTable.buUnColor,
  flAlViArea: 'FlAlVi_Area',
  flAlViRoundedArea: 'FlAlVi_RoundedArea',
  roViIsCurrentState: "RoVi_IsCurrentState"
};
