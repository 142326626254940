import { TaskContributorTable } from '../../db-model/tables/task-contributor-table';
import { DefinedModelBase } from '../defined-model-base';

export class TaskContributor extends DefinedModelBase {
    taCrTaskId!: number;
    taCrContributorId!: number;
    taCrLoad!: number;
    taCrFunctionId!: number;

    constructor(dtoData: any, tableDef: any[] = []) {
        super(dtoData, [TaskContributorTable].concat(tableDef));
    }
}
  