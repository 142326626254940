import { SelectItem } from "primeng/api";
import { SelectButtonOptionClickEvent } from "primeng/selectbutton";
import { EventEmitter } from "src/app/core/events/event-emitter";
import { SvgEntityPointStyleEnum } from "../../pages/graphic-works/shared/gizmos/model/svg-entity-point-style-enum";

export class GripsOptions extends EventEmitter {
    options: SelectItem[] = [];
    selectedOptions: number[] = [];
    static selectedGripOptionsChangeEvent: string = "selectedGripOptionsChangeEvent";

    constructor() {
        super();

        this.options.push({label: "Extrémité", value: SvgEntityPointStyleEnum.end, icon: "grip_end"});
        this.options.push({label: "Milieu", value: SvgEntityPointStyleEnum.middle, icon: "grip_middle"});
        this.options.push({label: "Centre", value: SvgEntityPointStyleEnum.center, icon: "grip_center"});
        this.options.push({label: "Quadrant", value: SvgEntityPointStyleEnum.quadrant, icon: "grip_quadrant"});
        this.options.push({label: "Orthogonal", value: SvgEntityPointStyleEnum.orthogonal, icon: "grip_orthogonal"});
        this.options.push({label: "Intersection", value: SvgEntityPointStyleEnum.intersection, icon: "grip_intersect"});
        this.options.push({label: "Extension", value: SvgEntityPointStyleEnum.extension, icon: "grip_extends"});
        this.options.push({label: "Tout", value: SvgEntityPointStyleEnum.none, icon: "select_all"});
    }
    
    async onGripOptionClick(e: SelectButtonOptionClickEvent): Promise<void> {
        if (e.option.value === SvgEntityPointStyleEnum.none) {
            // Le user a cliqué sur le switch
            // l'id du switch vient d'être ajouté au tableau selectedOptions
            // donc si le tableau ne contient qu'une valeur c'est forcément 0
            if (this.selectedOptions.length === 1) {
                // s'il n'y a aucun grip sélectionné on sélectionne tout
                this.selectedOptions = [...this.options.filter(x=> x.value !== SvgEntityPointStyleEnum.none).map(x=> x.value)];
            } else {
                // dans tous les autres cas on déselectionne tout
                this.selectedOptions.splice(0);
            }
        }

        await this.emitEventAsync(GripsOptions.selectedGripOptionsChangeEvent, this.selectedOptions);
    }
}