export const SiteAttributionDbView = {
    databaseTableName: "site_attribution_view",
    buSiteId: "Bu_SiteId",
    roAttributionTypeId: "Ro_AttributionTypeId",
    roAtTyName: "RoAtTy_Name",
    roAtTyColor: "RoAtTy_Color",
    roAtTyDisplayNameId: "RoAtTy_DisplayNameId",
    roAtTyViDisplayName: "RoAtTyVi_DisplayName",
    stAtViArea: "StAtVi_Area",
    stAtViRoundedArea: "StAtVi_RoundedArea"
}