import { DateUtils } from "src/app/core/model/static-functions/date-utils";
import { SvgTimelineVM } from "./svg-timeline-vm";
import { SimulationTimelinesDTO } from "src/app/core/services/backend-services/dto/simulation-timelines-dto";
import { SimulationPhoto } from "src/app/core/model/data-model/tables/simulation-photo";
import { ArrayUtils } from "src/app/core/model/static-functions/array-utils";
import { SvgPhotoMarkerVM } from "./svg-photo-marker-vm";
import { SvgPhotoMarkerGizmoVM } from "./svg-photo-marker-gizmo-vm";
import { SvgAddPhotoGizmoVM } from "./svg-add-photo-gizmo-vm";
import { SvgDOMBase } from "src/app/core/model/svg-model/abstract/svg-dom-base";
import Container from "typedi";
import { SimulationService } from "src/app/core/services/backend-services/simulation-service";
import { DyntService } from "src/app/core/services/backend-services/dynt-service";
import { SimulationPhotoTable } from 'src/app/core/model/db-model/tables/simulation-photo-table';
import { Point } from 'src/app/core/model/geometry-model/point.model';
import { SimulationTable } from 'src/app/core/model/db-model/tables/simulation-table';
import { SvgLine } from 'src/app/core/model/svg-model/svg-line.model';
import { logError } from "src/app/core/services/logging-service";

export class StrategyScheduleVM extends SvgDOMBase {
    headerHeight: number = 17;
    simulationsLabelsWidth: number = 20;
    timelinesWidth: number = 100;
    timelineHeight: number = 4;
    timelineZonePadding: number = 1.5;

    totalWidth: number = this.simulationsLabelsWidth + this.timelinesWidth + (this.timelineZonePadding * 2);
    totalHeight: number = 0;
    dayWidth: number = 1;
    minDate: Date = DateUtils.today();
    maxDate: Date = DateUtils.today();

    rootSimulation!: SimulationTimelinesDTO;

    timelines: SvgTimelineVM[] = [];
    verticalLinesPos: number[] = [];
    variantsVerticalLines: SvgLine[] = [];

    photoMarkerGizmo: SvgPhotoMarkerGizmoVM = new SvgPhotoMarkerGizmoVM();
    addPhotoGizmo: SvgAddPhotoGizmoVM | undefined;
    photoInserting: boolean = false;

    showLabelInput: boolean = false;
    labelInputPos: Point | undefined;
    labelInputValue: string | undefined;
    editedSimulation: SvgTimelineVM | undefined;
    editedPhoto: SvgPhotoMarkerVM | undefined;

    kd = this.keyDown.bind(this);

    constructor(svgElementId: string) {
        super(svgElementId, "4ce2e668-f9ca-42d7-9f6e-f3f04d4ac301");
    }

    draw(rootSimulation: SimulationTimelinesDTO): void {
        this.rootSimulation = rootSimulation;
        this.minDate = rootSimulation.siStartDate;
        this.maxDate = rootSimulation.siEndDate;
        const duration = DateUtils.dateDiffInDays(this.minDate, this.maxDate);
        this.dayWidth = 100 / duration;
        this.totalHeight = ((this.headerHeight + this.timelineHeight) * rootSimulation.timelinesCount) + (this.timelineZonePadding * 2);

        this.drawVerticalLines();

        this.addPhotoGizmo = new SvgAddPhotoGizmoVM(this.minDate, this.dayWidth, this.simulationsLabelsWidth + this.timelineZonePadding);

        this.drawTimeline(this.rootSimulation);

        this.calculateVariantLinksLines();

        this.plugListeners();
    }

    getAllPhotos(simulation: SimulationTimelinesDTO): SimulationPhoto[] {
        let result: SimulationPhoto[] = simulation.photos;
        simulation.variants.forEach(v => {
            result = result.concat(v.photos);
            if (v.variants.length > 0) {
                result = result.concat(this.getAllPhotos(v));
            }
        });
        return result;
    }

    drawVerticalLines(): void {
        this.verticalLinesPos.splice(0);
        const allPhotos = this.getAllPhotos(this.rootSimulation);
        const distinctDates = ArrayUtils.DistinctValueBy<SimulationPhoto, Date>(allPhotos, "siPhPhotoDate");
        distinctDates.forEach(d => {
            this.verticalLinesPos.push((this.simulationsLabelsWidth + this.timelineZonePadding + DateUtils.dateDiffInDays(this.minDate, d) * this.dayWidth));
        });
        // On ajoute une ligne de terminaison
        this.verticalLinesPos.push(this.simulationsLabelsWidth + this.timelineZonePadding + this.timelinesWidth);
    }

    yOffset = 0;
    drawTimeline(sim: SimulationTimelinesDTO): void {
        const newTimeline = new SvgTimelineVM(sim, this.simulationsLabelsWidth, this.timelineZonePadding, 0, this.minDate, this.dayWidth, this.yOffset + this.headerHeight + (this.timelineZonePadding + this.timelineHeight / 2));
        this.timelines.push(newTimeline);
        newTimeline.simulationLabelEditRequested = (s: SvgTimelineVM) => {
            const labelPos = new Point(0, s.y - 1.5);
            const inputPos = this.getClientPointFromSvgPoint(labelPos);
            if (inputPos !== null) {
                this.editedSimulation = s;
                this.labelInputPos = new Point(inputPos.x, inputPos.y);
                this.showLabelInput = true;
                this.labelInputValue = s.timelineDTO.siName;
            }
        }
        newTimeline.photoLabelEditRequested = (pm: SvgPhotoMarkerVM, clientX: number, clientY: number) => {
            const labelPos = new Point(pm.x + 1, pm.y - 4);
            const inputPos = this.getClientPointFromSvgPoint(labelPos);
            if (inputPos !== null) {
                this.editedPhoto = pm;
                this.labelInputPos = new Point(inputPos.x, inputPos.y);
                this.showLabelInput = true;
                this.labelInputValue = pm.photo.siPhName;
            }
        }
        sim.variants.forEach(v => {
            this.yOffset += this.headerHeight;
            this.drawTimeline(v);
        });
    }

    calculateVariantLinksLines(): void {
        this.variantsVerticalLines.splice(0);
        this.addLinkLines(this.rootSimulation);
    }

    addLinkLines(s: SimulationTimelinesDTO): void {
        s.variants.forEach(v => {
            const stl = this.timelines.find(x=> x.timelineDTO.siId === this.rootSimulation.siId);
            const ttl = this.timelines.find(x=> x.timelineDTO.siId === v.siId);
            if (stl && ttl) {
                const x = this.simulationsLabelsWidth + this.timelineZonePadding + DateUtils.dateDiffInDays(this.minDate, v.siStartDate) * this.dayWidth;
                this.variantsVerticalLines.push(SvgLine.fromValues(x, stl.y, x, ttl.y));
            }
            if (v.variants.length > 0) {
                this.addLinkLines(v);
            }
        });
    }

    plugListeners(): void {
        this.listenToStackingViewRequests();
        this.listenToPhotoMarkerGizmoRequests();
        this.listenToAddPhotoRequests();
        this.listenToLockPhotoRequests();
        this.listenToDeletePhotoRequests();
        this.listenToCreateVariantRequests();
    }

    endInserting(): void {
        this.photoInserting = false;
        document.removeEventListener('keydown', this.kd, true);
    }

    stackingViewRequested?: (p: SvgPhotoMarkerVM) => void;
    listenToStackingViewRequests(): void {
        this.timelines.forEach(t => {
            t.stackingViewRequested = (p: SvgPhotoMarkerVM) => {
                this.photoMarkerGizmo.show(p, false);
                if (this.stackingViewRequested) {
                    this.stackingViewRequested(p);
                } else {
                    logError("StrategyScheduleVM.stackingViewRequested n'est pas écouté");
                    
                }
            }
        });
    }

    listenToPhotoMarkerGizmoRequests(): void {
        this.timelines.forEach(t => {
            t.photoMarkerGizmoRequested = (pm: SvgPhotoMarkerVM, show: boolean) => {
                if (!this.photoInserting && pm.photo) {
                    this.photoMarkerGizmo.show(pm, show);
                }
            }
        });
    }

    listenToLockPhotoRequests(): void {
        this.photoMarkerGizmo.lockPhotoRequested = async (photo: SimulationPhoto) => {
            const s = Container.get(DyntService);
            const dto: any = {
                "TableName": SimulationPhotoTable.databaseTableName,
                [SimulationPhotoTable.siPhId]: photo.siPhId,
                [SimulationPhotoTable.siPhIsActive]: !photo.siPhIsActive
            }
            const result = await s.patch(SimulationPhotoTable.databaseTableName, photo.siPhId, dto);
            if (result !== null) {
                photo.siPhIsActive = !photo.siPhIsActive;
            }
        }
    }

    listenToAddPhotoRequests(): void {
        this.photoMarkerGizmo.addPhotoRequested = (marker: SvgPhotoMarkerVM) => {
            this.photoInserting = true;
            document.addEventListener('keydown', this.kd, true);

            if (this.addPhotoGizmo) {
                this.addPhotoGizmo.initialize(marker.y);
                this.addPhotoGizmo.addPhotoRequested = async (date: Date) => {
                    // Le user a cliqué sur le transient marker pour créer une nouvelle photo
                    this.endInserting();
                    
                    const s = Container.get(SimulationService);
                    const result = await s.createPhoto(marker.photo.siPhSimulation, "Nouvelle photo", date, marker.photo.siPhId);
                    if (result !== null) {
                        const timeline = this.timelines.find(x=> x.timelineDTO.siId === marker.photo.siPhSimulation);
                        if (timeline) {
                            timeline.addPhoto(result);
                            this.drawVerticalLines();
                        }
                    }
                }
            }
        }
    }

    listenToDeletePhotoRequests(): void {
        this.photoMarkerGizmo.deletePhotoRequested = async (marker: SvgPhotoMarkerVM) => {
            if (window.confirm("Supprimer la photo " + marker.photo.siPhName + " ?")) {
                const s = Container.get(SimulationService);
                const result = await s.deletePhoto(marker.photo.siPhId);
                if (result !== null) {
                    const timeline = this.timelines.find(x=> x.timelineDTO.siId === marker.photo.siPhSimulation);
                    if (timeline) {
                        timeline.removeLastPhoto();
                        this.drawVerticalLines();
                    }
                }
            }
        }
    }

    listenToCreateVariantRequests(): void {
        this.photoMarkerGizmo.createVariantRequested = async (marker: SvgPhotoMarkerVM) => {
            if (window.confirm("Créer une variante à partir de cette photo ?")) {
                const s = Container.get(SimulationService);
                const result = await s.createVariant(marker.photo.siPhId);
                if (result !== null) {
                    this.rootSimulation.variants.push(result);
                    this.drawTimeline(this.rootSimulation);
                    this.calculateVariantLinksLines();
                }
            }
        }
    }

    photoMarkerGizmoMouseLeave(): void {
        this.photoMarkerGizmo.visible = false;
    }

    mouseMove(e: MouseEvent): void {
        const hitPoint = this.getPointPosition(e.clientX, e.clientY);
        if (hitPoint && hitPoint.x > this.simulationsLabelsWidth + this.timelineZonePadding + this.dayWidth && hitPoint.x < this.totalWidth - this.timelineZonePadding - this.dayWidth) {
            this.addPhotoGizmo?.move(hitPoint.x);
        }
    }

    keyDown(e: KeyboardEvent): void {
        if (e.code.toLowerCase() === "escape") {
            this.endInserting();
        }
    }

    endInput(): void {
        this.showLabelInput = false;
        this.labelInputPos = undefined;
        this.labelInputValue = undefined;
        this.editedSimulation = undefined;
        this.editedPhoto = undefined;
    }

    simulationLabelUpdated?: (s: SimulationTimelinesDTO) => void;
    async saveInput(): Promise<void> {
        const s = Container.get(DyntService);
        if (this.editedSimulation && this.editedSimulation.timelineDTO.siName !== this.labelInputValue!) {
            const dto: any = {
                "TableName": SimulationTable.databaseTableName,
                [SimulationTable.siId]: this.editedSimulation.timelineDTO.siId,
                [SimulationTable.siName]: this.labelInputValue
            }
            const result = await s.patch(SimulationTable.databaseTableName, this.editedSimulation.timelineDTO.siId, dto);
            if (result !== null) {
                this.editedSimulation.timelineDTO.siName = this.labelInputValue!;
            }

            if (this.simulationLabelUpdated) {
                this.simulationLabelUpdated(this.editedSimulation.timelineDTO);
            } else {
                logError("StrategyScheduleVM.simulationLabelUpdated n'est pas écouté");
            }

            return;
        }

        if (this.editedPhoto && this.editedPhoto.photo.siPhName !== this.labelInputValue!) {
            const dto: any = {
                "TableName": SimulationPhotoTable.databaseTableName,
                [SimulationPhotoTable.siPhId]: this.editedPhoto.photo.siPhId,
                [SimulationPhotoTable.siPhName]: this.labelInputValue
            }
            const result = await s.patch(SimulationPhotoTable.databaseTableName, this.editedPhoto.photo.siPhId, dto);
            if (result !== null) {
                this.editedPhoto.photo.siPhName = this.labelInputValue!;
            }
        }
    }

    async focusOut(): Promise<void> {
        await this.saveInput();
        this.endInput();
    }

    async inputKeyDown(e: KeyboardEvent): Promise<void> {
        switch (e.code.toLowerCase()) {
            case "enter" ||"numpadenter":
                await this.saveInput();
                this.endInput();
                break;
            case "escape":
                this.endInput();
                break;
            default:
                break;
        }
    }
}