import { FormsModule } from '@angular/forms';
import { MaterialModule } from 'src/app/core/material.module';
import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RefCityAdminVM } from '../../model/ref-city-admin-vm';
import { DynTableComponent } from 'src/app/components-lib/dyn-table/dyn-table.component';

@Component({
  selector: 'xc-ref-city-admin',
  standalone: true,
  imports: [CommonModule, MaterialModule, FormsModule, DynTableComponent],
  templateUrl: './ref-city-admin.component.html',
  styleUrls: ['./ref-city-admin.component.scss']
})
export class RefCityAdminComponent {
  @Input() model: RefCityAdminVM | undefined;
}
