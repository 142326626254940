import Container from "typedi";
import { SvgStrategyStackingVM } from "./svg-strategy-stacking-vm";
import { SimulationService } from "src/app/core/services/backend-services/simulation-service";

export class StrategyStackingVM {
    svgStacking: SvgStrategyStackingVM | undefined;

    async loadStack(photoId: number): Promise<void> {
        const s = Container.get(SimulationService);
        const result = await s.getPhotoOccupation(photoId);
        if (result !== null) {
            this.svgStacking = new SvgStrategyStackingVM(result);
        }
    }
}