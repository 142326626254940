import { SelectableBusTreeVM } from './../selectable-bu-tree/model/selectable-bus-tree-vm';
import { SelectableRealEstateTreeVM } from './../selectable-real-estate-tree/model/selectable-real-estate-tree-vm';
import { SimulationCreationData } from "./simulation-creation-data";

export class CreationStepFourVM {
    creationData: SimulationCreationData;
    reTree: SelectableRealEstateTreeVM;
    busTree: SelectableBusTreeVM;

    constructor(creationData: SimulationCreationData, reTree: SelectableRealEstateTreeVM, busTree: SelectableBusTreeVM) {
        this.creationData = creationData;
        this.reTree = reTree;
        this.busTree = busTree;
    }

    async loadDatas(): Promise<void> {
        this.reTree.readonly = true;
        this.busTree.readonly = true;
        if (this.creationData.busSelection) {
            const selectedBusIds = this.creationData.selectedBus.map(x=> x.bu.buUnId);
            await this.reTree.loadTree(selectedBusIds);
            this.creationData.selectedBuildings = this.reTree.getSelectedBuildings();
        } else {
            const selectedBuildingsIds = this.creationData.selectedBuildings.map(x=> x.id);
            await this.busTree.loadTree(selectedBuildingsIds);
            this.creationData.selectedBus = this.busTree.getSelectedBus();
        }
    }
}