import { BuildingAxonoComponent } from '../building-axono/building-axono.component';
import { BuildingAxonoWrapper } from '../../model/building-axono-wrapper';
import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'xc-building-axono-wrapper',
  standalone: true,
  imports: [CommonModule, BuildingAxonoComponent],
  templateUrl: './building-axono-wrapper.component.html',
  styleUrls: ['./building-axono-wrapper.component.scss']
})
export class BuildingAxonoWrapperComponent {
  @Input() model: BuildingAxonoWrapper | undefined;
}
