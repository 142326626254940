import { RoomLayoutTypeDbView } from "../../db-model/views/room-layout-type-db-view";
import { RoomLayoutType } from "../tables/room-layout-type";

export class RoomLayoutTypeView extends RoomLayoutType {
    roLaTyViTotalArea!: number; 
    roLaTyViMinArea!: number; 
    roLaTyViMaxArea!: number; 
    roLaTyViAvgArea!: number; 
    roLaTyViCount!: number; 

    constructor(dtoData: any) {
        super(dtoData, [RoomLayoutTypeDbView]);
    }
}