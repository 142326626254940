import { logError } from '../../services/logging-service';
import { DataModelMap } from "./data-model-map";

export class DataModelBuilder {
    static instance<T>(tableName: string, dtoData: any): T {
        const x = DataModelMap.getModel(tableName);
        if (!x) {
            logError(`DataModelBuilder : La table ${tableName} n'est pas définie`);
        }
        return new x(dtoData) as T;
    }
}