import { Service } from "typedi";
import { Point } from 'src/app/core/model/geometry-model/point.model';
import { HttpParams } from '@angular/common/http';
import { TaskFloorModelTable } from "src/app/core/model/db-model/tables/task-floor-model-table";
import { ApiControllers, ApiEndpoints } from "src/app/core/services/api-endpoints";
import { ApiService } from "src/app/core/services/api-service";
import { CreateDoorDTO } from "src/app/ui/pages/layout/real-estate/floor-blueprint/content/services/dto/create-door-dto";
import { UpdatedFloorDataDTO } from "src/app/ui/pages/layout/real-estate/floor-blueprint/content/services/dto/updated-floor-data-dto";
import { DoorStyle } from "src/app/ui/pages/layout/real-estate/floor-blueprint/content/shared-model/door-style";
import { BpSvgDoor } from "src/app/ui/pages/layout/real-estate/floor-blueprint/content/svg-entities/model/bp-svg-door";
import { BlueprintLayer } from "src/app/ui/pages/layout/real-estate/floor-blueprint/content/svg-entities/model/layers/blueprint-layer";

@Service({ global: true })  
export class BpDoorService extends ApiService {
    private openingDirectionEndpoint = `${ApiControllers.floorDataDoors}/DoorOpeningDirection`;
    private wallSideOrientationEndpoint = `${ApiControllers.floorDataDoors}/DoorWallSideOrientation`;
    private openingAngleEndpoint = `${ApiControllers.floorDataDoors}/dooropeningangle`;
    private widthEndpoint = `${ApiControllers.floorDataDoors}/doorwidth`;
    private styleEndpoint = `${ApiControllers.floorDataDoors}/doorstyle`;
    private translateEndpoint = `${ApiControllers.floorDataDoors}/translate`;
    
    constructor() {
        super();
    }

    async downloadDoorStyles(): Promise<DoorStyle[]> {
        const result = await this.getAsync<any[]>(this.endPoint(ApiEndpoints.floorDataDoorStyles));
          if (result != null) {
            return result.payload.map(item => {
                return new DoorStyle(item);
            })
          }
          return [];
    }

    private async patchAndGetDoorArray(url: string, dto: any): Promise<BpSvgDoor[]> {
        const result = await this.patchAsync<any[]>(url, dto);
        if (result) {
            return result.payload.map(item => {
                return new BpSvgDoor(item);
            })
          }
        return [];
    }

    async updateDoorsOpeningDirection(floorDataIds: number[], openingDirection: number): Promise<BpSvgDoor[]> {
        const dto: any = {};
        dto['DoorIds'] = floorDataIds;
        dto['UpdateValue'] = openingDirection;
        return this.patchAndGetDoorArray(this.endPoint(this.openingDirectionEndpoint), dto);
      }
    
      async updateDoorsWallSideOrientation(floorDataIds: number[], wallSideOrientation: number): Promise<BpSvgDoor[]> {
        const dto: any = {};
        dto['DoorIds'] = floorDataIds;
        dto['UpdateValue'] = wallSideOrientation;
        return this.patchAndGetDoorArray(this.endPoint(this.wallSideOrientationEndpoint), dto);
      }
    
      async updateDoorsOpeningAngle(floorDataIds: number[], openingAngle: number): Promise<BpSvgDoor[]> {
        const dto: any = {};
        dto['DoorIds'] = floorDataIds;
        dto['UpdateValue'] = openingAngle;
        return this.patchAndGetDoorArray(this.endPoint(this.openingAngleEndpoint), dto);
      }
    
      async updateDoorsWidth(floorDataIds: number[], width: number): Promise<BpSvgDoor[]> {
        const dto: any = {};
        dto['DoorIds'] = floorDataIds;
        dto['UpdateValue'] = width;
        return this.patchAndGetDoorArray(this.endPoint(this.widthEndpoint), dto);
      }
    
      async updateDoorsStyle(floorDataIds: number[], doorStyleId: number): Promise<BpSvgDoor[]> {
        const dto: any = {};
        dto['DoorIds'] = floorDataIds;
        dto['UpdateValue'] = doorStyleId;
        return this.patchAndGetDoorArray(this.endPoint(this.styleEndpoint), dto);
      }
    
      async translateDoor(doorId: number, point: Point): Promise<BpSvgDoor | null> {
        const params = new HttpParams()
          .set('doorId', String(doorId))
          .set('x', String(point.x))
          .set('y', String(point.y));
        const result = await this.patchAsync<any[]>(this.endPoint(this.translateEndpoint), null, undefined, params);
        if (result) {
            return new BpSvgDoor(result.payload);
        }
        return null
      }
    
    
      async createDoor(wallId: number, insertionPoint: Point, props: CreateDoorDTO): Promise<{ door: BpSvgDoor, layer?: BlueprintLayer } | null> {
        const dto: any = {};
        dto['WallId'] = wallId;
        dto['X'] = insertionPoint.x;
        dto['Y'] = insertionPoint.y;
        dto['DoorWidth'] = props.doorWidth;
        dto['OpeningAngle'] = props.openingAngle;
        dto['DoorStyleId'] = props.doorStyleId;
        dto['OpeningDirection'] = props.openingDirection;
        dto['WallSideOrientation'] = props.wallSideOrientation;
    
        const result = await this.postAsync<any>(this.endPoint(ApiControllers.floorDataDoors), dto);
        if (result) {
            let taskLayer;
            if (result.payload[TaskFloorModelTable.databaseTableName] != null) {
              const taskId = Number(result.payload[TaskFloorModelTable.databaseTableName][TaskFloorModelTable.taFloMoTaskId]);
              taskLayer = new BlueprintLayer(result.payload[TaskFloorModelTable.databaseTableName], taskId);
            }
            return {
              door: new BpSvgDoor(result.payload['SvgGroupDTO']),
              layer: taskLayer,
            };
        }
        return null;
      }
    
      async deleteDoors(doorIds: number[]): Promise<UpdatedFloorDataDTO[]> {
        let params = new HttpParams();
        doorIds.forEach(id => {
            params = params.append(`id`, String(id));
        });
        const result = await this.deleteAsync<UpdatedFloorDataDTO[]>(this.endPoint(ApiControllers.floorDataDoors), params);
        if (result != null) {
            return result.payload.map(item => {
                return new UpdatedFloorDataDTO(item);
            })
        }
        return [];
      }
        
}