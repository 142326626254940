<!-- @if (model && model.floorTasks && model.floorTasks.length > 0) {

<button
  mat-button
  class="load-task-button"
  [matMenuTriggerFor]="tasksMenu"
  aria-label="Charger une étude"
>
  <mat-icon class="material-icons-outlined">add_task</mat-icon>
  Charger une étude
</button>
<mat-menu #tasksMenu="matMenu">
  @for (task of model.floorTasks; track task) {
  <button
    class="tasks-menu"
    [disabled]="task.taskId === model.selectedTask?.taskId"
    mat-menu-item
    (click)="model.onTaskMenuItemClick(task)"
  >
    <div>
      <h4 class="tasks-menu-header">{{ task.taskName }}</h4>
      @if (task.projectName) {
      <h5 class="tasks-menu-subheader">{{ task.projectName }}</h5>
      } @if (task.taskDeadLine) {
      <h5 class="tasks-menu-subheader">
        Echéance : {{ task.taskDeadLine | date : "dd/MM/yy" }}
      </h5>
      }
      <div class="tasks-status-row">
        <div
          class="tasks-status-shape"
          [style.background-color]="task.taskStatusColor"
        ></div>
        <span class="tasks-status-name">{{ task.taskStatusName }}</span>
      </div>
    </div>
  </button>
  }
</mat-menu>

} @else { Il n'y a aucune étude en cours sur l'étage }  -->
@if (model && model.selectedTask) {
<div>
    <!-- <h3 class="header">{{model.selectedTask.taskName}}</h3> -->

    <!-- Propriétés de l'étude -->
    <div id="task-infos">
        <div class="property-row">
            <span class="property-name">Projet </span>
            <span class="property-value">{{ model.selectedTask.projectName }}</span>
        </div>
        <div>
            <span class="property-name">Echéance </span>
            <span class="property-value">{{
                model.selectedTask.taskDeadLine | date : "dd/MM/yy"
                }}</span>
        </div>
        <div>
            <span class="property-name">Statut </span>
            <div class="status-row property-value">
                <div class="status-shape" [style.background-color]="model.selectedTask.taskStatusColor"></div>
                <span class="status-name">{{ model.selectedTask.taskStatusName }}</span>
            </div>
        </div>
    </div>

    <p-accordion [activeIndex]="0">
        <p-accordionTab header="Contributeurs">
            <!-- Zone d'affichage des contributeurs et de leurs fonctions -->
            <ul>
                @for (c of model.contributors; track c) {
                <li class="list">
                    <span>{{ c.diFirstName }}</span>
                    <span>{{ c.diLastName }}</span>
                    <span> - {{ c.taFuName }}</span>
                    @if (c.hasValidationFunction || c.hasDefaultValidationFunction) {
                    <div class="validation">
                        <span>Validation :
                            {{
                            c.validation == null
                            ? model.selectedTask.taskStatus ===
                            model.TaskStatusEnum.Validating
                            ? "En attente"
                            : "Non requise à ce stade"
                            : "Validé"
                            }}</span>
                    </div>
                    }
                </li>
                }
            </ul>
        </p-accordionTab>
        @if (model.clientHasMobilitySubscriptionPlan && model.selectedTask.taskStatus !== model.TaskStatusEnum.Validating) {
            <p-accordionTab header="Variantes">
                <!-- Zone d'affichage des variantes -->
                <ul>
                    @if (model.taskVariants.length === 0) {
                    <li>Cette étude n'a pas de variante</li>
                    } @for (tv of model.taskVariants; track tv) {
                    <li class="list">
                        <span>{{ tv.taName }}</span>
                        <!-- TODO: Ajouter un bouton d'accès direct à la variante -->
                    </li>
                    }
                </ul>
        
                <div id="create-variant-panel">
                    <mat-slide-toggle [checked]="model.variantCreateToggleIsChecked"
                        (change)="model.variantCreateToggleChange($event)" value="primary">Créer une variante</mat-slide-toggle>
                    @if (model.variantCreateToggleIsChecked) {
                    <form>
                        <mat-form-field>
                            <mat-label>Nom de la vaiante</mat-label>
                            <input matInput [(ngModel)]="model.variantTaskName" name="name" #name="ngModel" required
                                placeholder="Nom de la variante" autocomplete="off"
                                pattern="[0-9a-zA-Zàéèëêîïôûç \- \/ _]{1,50}" />
                        </mat-form-field>
                        <div [hidden]="name.valid">
                            Entre 1 et 50 car. alphanumériques + (- / _)
                        </div>
                        <div>
                            <mat-checkbox #showVariantTaskNow>Afficher immédiatement</mat-checkbox>
                        </div>
                        <button mat-button color="primary" [disabled]="!name.valid" (click)="
                    model.onCreateVariantButtonClick(
                      name.model,
                      showVariantTaskNow.checked
                    )
                  ">
                            Créer
                        </button>
                    </form>
                    }
                </div>
            </p-accordionTab>
        }
        <p-accordionTab header="Workflow">
            <!-- Zone d'affichage du workflow -->
            <ul>
                @if (model.changeLogs == null || model.changeLogs.length === 0) {
                    <li>Aucun pour le moment</li>
                } 
                @for (l of model.changeLogs; track l) {
                    <li class="validation changelog">
                        <span>{{ l.taCrDiFirstName }}</span>
                        <span>{{ l.taCrDiLastName }}</span>
                        <span> - {{ l.taChLoTimeStamp | date : "dd/MM/yy" }}</span>
                        <div class="changelog-workflow">
                            <div class="status-block">
                                <div class="status-shape" [style.background-color]="l.sourceStatusColor"></div>
                                <span class="status-name">{{ l.sourceStatusName }}</span>
                            </div>
                            <span class="status-change">--></span>
                            <div class="status-block">
                                <div class="status-shape" [style.background-color]="l.targetStatusColor"></div>
                                <span class="status-name">{{ l.targetStatusName }}</span>
                            </div>
                        </div>
                    </li>
                }
            </ul>
        </p-accordionTab>
        @if (model.selectedTask.taskStatus === model.TaskStatusEnum.Validating && model.validationCheck != null && model.userCanValidate()) {
            <p-accordionTab header="Validation">
            <!-- Zone de validation -->
                @if (!model.validationCheck.isRefAreaWillBeUpdated) { 
                    <div>
                        @if (model.validationCheck.isUniqueOrLastValidation) {
                            <div id="validation-info">
                                <p>Attention !</p>
                                <p>
                                    Dernière ou unique validation de cette étude. Les changements seront
                                    récolés immédiatement
                                </p>
                            </div>
                        }
                        <form>
                            <mat-form-field appearance="outline">
                                <mat-label>Commentaire</mat-label>
                                <input matInput [(ngModel)]="model.validationComment" name="comment" #comment="ngModel"
                                    pattern="[0-9a-zA-Zéèçàêâîû \- \/ _]{1,250}" autocomplete="off" />
                            </mat-form-field>
                            <div [hidden]="comment.valid">
                                Entre 1 et 250 caractères alphanumériques + (- / _)
                            </div>
                            @if (model.validationCheck.isRefAreaWillBeUpdated) {
        
                            <p>
                                Vous allez modifier la surface de référence du plateau; actuelle :
                                {{ model.validationCheck.currentFloorRefArea }} / modifiée :
                                {{ model.validationCheck.currentTaskFloorRefArea }}
                            </p>
                            <mat-checkbox [ngModel]="model.refAreaIsIntentionalyUpdated">Oui, c'est intentionnel</mat-checkbox>
        
                            }
                            <button mat-button color="primary" [disabled]="
                        model.validationCheck.isRefAreaWillBeUpdated &&
                        (!model.validationCheck.hasRefAreaUpdateGrant ||
                        !model.refAreaIsIntentionalyUpdated)
                    " (click)="model.onValidateButtonClick()">
                                Valider
                            </button>
                            <button mat-button color="warn" (click)="model.onRejectButtonClick()">
                                Rejeter
                            </button>
                        </form>
                    </div>
                } @else {
                <div>
                    <div id="validation-info">
                        <p>Attention !</p>
                        <p>
                            Le contrôle d'intégrité a détecté que la surface de référence du
                            plateau serait modifiée par la validation de cette étude, mais vous
                            n'avez pas les habilitations requises pour modifier la surface de
                            référence.
                        </p>
                    </div>
                </div>
                } 
            </p-accordionTab>
        }
        @if (model.selectedTask.taskStatus === model.TaskStatusEnum.Started && model.clientHasMobilitySubscriptionPlan) {
            <p-accordionTab header="Extension de l'étude">
                <span>Ajouter un local à l'étude</span>
                <p-button label="Sélectionner" (onClick)="model.onAddRoomToTaskButtonClick()"></p-button>
            </p-accordionTab>
        }
    </p-accordion>

    <!-- Boutons de commande de changement de statut -->
    <!-- Le contenu de la liste dépend du statut actuel de la tâche -->
    <mat-list>
        @for (c of model.workflowCommands; track c) {
            <mat-list-item class="command-option" (click)="model.onWorkflowCommandClick(c)">
                <div class="workflow-commands-button-row">
                    <div class="status-shape" [style.background-color]="c.taskStatus.taStColor"></div>
                    <span class="status-name">{{ c.actionName }}</span>
                </div>
            </mat-list-item>
        }
    </mat-list>
</div>
}