import { Component, Input } from '@angular/core';
import { MobilityProjectsGanttEditorVM } from '../../model/mobility-projects-gantt-editor-vm';
import { AccordionModule } from 'primeng/accordion';
import { TaskPropertiesComponent } from '../task-properties/task-properties.component';
import { CreationStepperComponent } from '../creation-stepper/creation-stepper.component';
import { TaskContributorsComponent } from '../task-contributors/task-contributors.component';

@Component({
  selector: 'xc-mobility-project-gantt-editor',
  standalone: true,
  imports: [AccordionModule, TaskPropertiesComponent, CreationStepperComponent, TaskContributorsComponent],
  templateUrl: './mobility-project-gantt-editor.component.html',
  styleUrl: './mobility-project-gantt-editor.component.scss'
})
export class MobilityProjectGanttEditorComponent {
  @Input() model: MobilityProjectsGanttEditorVM | undefined;
}
