import { Component, Input, AfterViewInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImportsPanel } from '../../../model/side-panel/imports-panel';
import { MaterialModule } from 'src/app/core/material.module';
import { FormsModule } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { logError } from 'src/app/core/services/logging-service';

@Component({
  selector: 'xc-imports-panel',
  standalone: true,
  imports: [CommonModule, MaterialModule, FormsModule],
  templateUrl: './imports-panel.component.html',
  styleUrls: ['./imports-panel.component.scss']
})
export class ImportsPanelComponent implements AfterViewInit {
  @Input() model: ImportsPanel | undefined;

  constructor(protected dialog: MatDialog) {}

  ngAfterViewInit(): void {
    // Injecte le matDialog dans le modèle
    if (this.model) {
      this.model.dialog = this.dialog;
    } else {
      logError("ImportsPanelComponent : model.dialog n'a pas pu être initialisé");
    }
  }
}
