import { PageModel } from "src/app/ui/main/model/page-model";
import { AppMenuRouteEnum } from "src/app/core/model/data-model/enums/app-menu-route-enum";
import { readableUUID } from "src/app/core/events/event-listener-uuid";
import { ITabPageContent } from "src/app/components-lib/tab-page-container/model/i-tab-page-content";
import Container from "typedi";
import { DyntService } from "src/app/core/services/backend-services/dynt-service";
import { TablesNamesEnum } from "src/app/core/model/db-model/tables-names-enum";
import { Building } from "src/app/core/model/data-model/tables/building";
import { BuildingTable } from "src/app/core/model/db-model/tables/building-table";
import { BuildingAxonoWrapper } from "src/app/ui/shared/charts/axono/model/building-axono-wrapper";
import { RealEstateService } from "src/app/core/services/backend-services/real-estate-service";
import { StackChartWrapperVM } from "src/app/ui/shared/charts/stack/model/stack-chart-wrapper";
import { DonutChartWrapperVM } from 'src/app/ui/shared/charts/donut/model/donut-chart-wrapper-vm';
import { BuildingAnatomyView } from 'src/app/core/model/data-model/views/building-anatomy-view';
import { ViewsNames } from 'src/app/core/model/db-model/views-names-enum';
import { BuildingActivityStatusView } from 'src/app/core/model/data-model/views/building-activity-status-view';
import { BuildingAllocationView } from 'src/app/core/model/data-model/views/building-allocation-view';
import { BuildingAttributionView } from 'src/app/core/model/data-model/views/building-attribution-view';
import { BuildingEquipmentProviderView } from 'src/app/core/model/data-model/views/building-equipment-provider-view';
import { FloorTable } from 'src/app/core/model/db-model/tables/floor-table';
import { ChartBuilder } from 'src/app/ui/shared/shared-model/chart-builder';
import { ReferentialReportWrapper } from 'src/app/ui/shared/charts/referential/referential-report-wrapper';
import { ReportGridItem } from 'src/app/components-lib/report-grid/model/report-grid-item';
import { ReportGridNumberItem } from 'src/app/components-lib/report-grid/model/report-grid-number-item';
import { ReportGridStringItem } from 'src/app/components-lib/report-grid/model/report-grid-string-item';
import { BuildingView } from 'src/app/core/model/data-model/views/building-view';
import { XcMaths } from 'src/app/core/model/static-functions/xc-maths';
import { RealEstateChartTypeEnum } from '../charts/shared-model/real-estate-chart-type-enum';
import { TabPageContentTypeEnum } from '../tab-page-content-type-enum';
import { AnonymousChartDataBuilder } from '../charts/anonymous-chart-data-builder';
import { BuildingAnonymousDonutChartDataBuilder } from './building-anonymous-donut-chart-data-builder';
import { BuildingAnonymousStackChartDataBuilder } from './building-anonymous-stack-chart-data-builder';
import { BuildingsToolbarVM } from './buildings-toolbar-vm';
import { MainEventsEnum } from 'src/app/ui/main/model/main-events-enum';
import { BuildingsEventsEnum } from './buildings-events-enum';
import { DynTableRow } from "src/app/components-lib/dyn-grid/model/dyn-table-row";

export class BuildingsVM extends PageModel {
    static blueprintRequestedEvent = "blueprintRequested";

    toolbar: BuildingsToolbarVM | undefined;

    selectedBuilding: Building | null = null;

    selectedOption: {id: string, label: string} | undefined;

    optionTypesEnum = RealEstateChartTypeEnum;
    contentTypeEnum = TabPageContentTypeEnum;

    selectedChartType: {id: string, icon: string} | null = null;

    currentView: ITabPageContent | undefined;

    private constructor() {
        super(AppMenuRouteEnum.layout_realestate_buildings, 0, readableUUID(BuildingsVM.name));

        this.addEventListener(BuildingsEventsEnum.selectedBuildingChange, async (selectedBuilding: Building | null) => {
            this.selectedBuilding = selectedBuilding;
            await this.loadView();
        });

        this.addEventListener(BuildingsEventsEnum.selectedOptionChange, async (selectedOption: {id: string, label: string} | undefined) => {
            this.selectedOption = selectedOption;
            await this.loadView();
        });

        this.addEventListener(BuildingsEventsEnum.selectedChartChange, async (selectedChart: {id: string, icon: string} | null) => {
            this.selectedChartType = selectedChart;
            await this.loadView();
        });
    }

    static async newAsync(buildingId?: number): Promise<BuildingsVM> {
        const tmp = new BuildingsVM();
    
        tmp.toolbar = await BuildingsToolbarVM.newAsync(buildingId);
        tmp.emitEventAsync(MainEventsEnum.nestedToolbarAvailable, tmp.toolbar);
 
        return tmp;
    }

    async loadView(): Promise<void> {
        if (!this.selectedOption) return;

        switch (this.selectedOption.id) {
            case RealEstateChartTypeEnum.anatomy:
            case RealEstateChartTypeEnum.activities:
            case RealEstateChartTypeEnum.attributions:
            case RealEstateChartTypeEnum.inventory:
            case RealEstateChartTypeEnum.businessunits:
                if (this.selectedChartType == null) break;

                switch (this.selectedChartType.id) {
                    case TabPageContentTypeEnum.donutchart:
                        await this.loadDonutChart();
                        break;
                    case TabPageContentTypeEnum.buildingstack:
                        await this.loadStackChart();
                        break;
                    case TabPageContentTypeEnum.buildingaxono:
                        await this.loadAxonoChart();
                        break;
                }
                break;
            case RealEstateChartTypeEnum.referential:
                await this.loadReferential();
                break;
        }
    }

    async loadDonutChart(): Promise<void> {
        if (!this.selectedBuilding) return;
        if (!this.selectedOption) return;
        const buId = this.selectedBuilding.buId;

        const s = Container.get(DyntService);
        //const id = crypto.randomUUID();
        const chart = new DonutChartWrapperVM();

        switch (this.selectedOption.id) {
            case RealEstateChartTypeEnum.anatomy:
                const ana = await s.downloadTable<BuildingAnatomyView>(ViewsNames.BuildingAnatomyView, undefined, FloorTable.flBuildingId, buId);
                const anaChartData = await AnonymousChartDataBuilder.getAnatomyData(ana, 3);
                chart.set(anaChartData);
                break;
            case RealEstateChartTypeEnum.activities:
                const ac = await s.downloadTable<BuildingActivityStatusView>(ViewsNames.BuildingActivityStatusView, undefined, FloorTable.flBuildingId, buId);
                const acAnonymous = BuildingAnonymousDonutChartDataBuilder.getActivityData(ac);
                const pieChartData = ChartBuilder.getActivityDonutChartData("", acAnonymous);
                chart.set(pieChartData);
                break;
            case RealEstateChartTypeEnum.attributions:
                const at = await s.downloadTable<BuildingAttributionView>(ViewsNames.BuildingAttributionView, undefined, FloorTable.flBuildingId, buId);
                const atAnonymous = BuildingAnonymousDonutChartDataBuilder.getAttributionData(at);
                const attrPieChartData = ChartBuilder.getAttributionDonutChartData("", atAnonymous);
                chart.set(attrPieChartData);
                break;
            case RealEstateChartTypeEnum.inventory:
                const inv = await s.downloadTable<BuildingEquipmentProviderView>(ViewsNames.BuildingEquipmentProviderView, undefined, BuildingTable.buId, buId);
                const invAnonymous = BuildingAnonymousDonutChartDataBuilder.getInventoryData(inv);
                const invChartData = ChartBuilder.getInventoryDonutChartData("", invAnonymous, "€");
                chart.set(invChartData);
                break;
            case RealEstateChartTypeEnum.businessunits:
                const bus = await s.downloadTable<BuildingAllocationView>(ViewsNames.BuildingAllocationView, undefined, FloorTable.flBuildingId, buId);
                const busAnonymous = BuildingAnonymousDonutChartDataBuilder.getBusinessUnitsData(bus);
                const busChartData = ChartBuilder.getBusinessUnitsChartData("", busAnonymous);
                chart.set(busChartData);
                break;
            default:
                break;
        }

        this.currentView = chart;
    }

    async loadAxonoChart(): Promise<void | undefined> {
        if (!this.selectedBuilding) return;
        if (!this.selectedOption) return;

        const id = crypto.randomUUID();
        const chart = new BuildingAxonoWrapper(id, "");
        chart.blueprintRequested = async (floorId: number) => {
            await this.emitEventAsync(BuildingsVM.blueprintRequestedEvent, floorId);
        }
        await chart.loadBuilding(this.selectedBuilding.buId);
        await chart.setColorMap(this.selectedBuilding.buId, this.selectedOption.id);
        this.currentView = chart;
    }

    async loadStackChart(): Promise<void | undefined> {
        if (!this.selectedBuilding) return;
        if (!this.selectedOption) return;
        const buId = this.selectedBuilding.buId;

        const s = Container.get(RealEstateService);
        const chart = new StackChartWrapperVM();

        switch (this.selectedOption.id) {
            case RealEstateChartTypeEnum.anatomy:
                const an = await s.loadBuildingLayoutTypeView(buId);
                if (an) {
                    const aan = BuildingAnonymousStackChartDataBuilder.getAnatomyRowData(an);
                    chart.setBuildingStack(buId, aan);
                }
                break;
            case RealEstateChartTypeEnum.activities:
                const ac = await s.loadBuildingActivityView(buId);
                if (ac) {
                    const aac = BuildingAnonymousStackChartDataBuilder.getActivityRowData(ac);
                    chart.setBuildingStack(buId, aac);
                }
                break;
            case RealEstateChartTypeEnum.attributions:
                const at = await s.loadBuildingAttributionView(buId);
                if (at) {
                    const aat = BuildingAnonymousStackChartDataBuilder.getAttributionRowData(at);
                    chart.setBuildingStack(buId, aat);
                }
                break;
            case RealEstateChartTypeEnum.inventory:
                const inv = await s.loadBuildingEquipmentProviderView(buId);
                if (inv) {
                    const ainv = BuildingAnonymousStackChartDataBuilder.getInventoryRowData(inv);
                    chart.setBuildingStack(buId, ainv);
                }
                break;
            case RealEstateChartTypeEnum.businessunits:
                const bus = await s.loadBuildingAllocations(buId);
                if (bus) {
                    const ainv = BuildingAnonymousStackChartDataBuilder.getAllocationsRowData(bus);
                    chart.setBuildingStack(buId, ainv);
                }
                break;
            default:
                break;
        }

        chart.onFloorLabelClick = async (floorId: number) => {
            await this.emitEventAsync(BuildingsVM.blueprintRequestedEvent, floorId);
        }

        this.currentView = chart;
    }

    async loadReferential(): Promise<void> {
        if (!this.selectedBuilding) return;
        const buId = this.selectedBuilding.buId;

        const s = Container.get(DyntService);
        const bw = await s.downloadRow<BuildingView>(ViewsNames.BuildingView, undefined, BuildingTable.buId, buId);
        if (bw) {
            const grossArea = XcMaths.round(bw.buViGLA, 0);
            const netArea = XcMaths.round(bw.buViNUA, 0);
            const utilRatio = XcMaths.round(netArea * 100 / grossArea, 2);

            const rrw = await ReferentialReportWrapper.newAsync(RealEstateChartTypeEnum.referential, "", TablesNamesEnum.Building, buId);
            const reportItems: ReportGridItem<any>[] = [];
            reportItems.push(new ReportGridNumberItem("Etages", bw.buViFloorsCount));
            reportItems.push(new ReportGridNumberItem("Surface brute", grossArea, "1.0-2", "m²"));
            reportItems.push(new ReportGridNumberItem("Surface nette", netArea, "1.0-2", "m²"));
            reportItems.push(new ReportGridNumberItem("Ratio utile", utilRatio, "1.0-2", "%"));
            reportItems.push(new ReportGridStringItem("Courrier", bw.buViMailingAddress));
            reportItems.push(new ReportGridStringItem("Accès parkings", bw.buViParkingAddress));
            reportItems.push(new ReportGridStringItem("Accueil", bw.buViLobbyAddress));
            reportItems.push(new ReportGridStringItem("Livraisons", bw.buViDeliveryAddress));
            rrw.setReportGrid(reportItems);
    
            if (rrw.adminTable) {
                const at = rrw.adminTable;
                at.newRowSaveRequested = async (values : any) => {
                    let result: any;
                    const t = Container.get(RealEstateService);
                    result = await t.createNewFloor(
                        buId, 
                        values[FloorTable.flCode], 
                        values[FloorTable.flName], 
                        values[FloorTable.flIsActive], 
                        values[FloorTable.flIsFictive], 
                        values[FloorTable.flElevation]
                    );
                    return result;
                }
            }

            this.currentView = rrw;
        }

    }
}