import { SvgEntityTypesEnum } from 'src/app/core/model/svg-model/svg-entity-type-enum';
import { SvgEntity } from './svg-entity.model';

export class SvgLine extends SvgEntity {
    x1: number;
    y1: number;
    x2: number;
    y2: number;

    constructor(dtoData: any) {
        super(dtoData);
        this.x1 = SvgEntity.getNumber(dtoData, "x1");
        this.y1 = SvgEntity.getNumber(dtoData, "y1");
        this.x2 = SvgEntity.getNumber(dtoData, "x2");
        this.y2 = SvgEntity.getNumber(dtoData, "y2");
    }

    static fromValues(x1: number, y1: number, x2: number, y2: number): SvgLine {
        return new SvgLine({"x1": x1, "y1": y1, "x2": x2, "y2": y2, "entityType": SvgEntityTypesEnum.line});
    }
}

