import { DxfLayerDTO } from "src/app/core/services/backend-services/dto/dxf-layer-dto";
import { logError } from "src/app/core/services/logging-service";

export class CadConverterLayersPanelVM {
    layers: DxfLayerDTO[] = [];
    clearSelectionRequested?: () => void;

    layerClick(l: DxfLayerDTO): void {
        const current = this.layers.find(x=> x.current === true);
        if (current) current.current = false;
        l.current = true;
    }
    
    switchLayerVisibility(l: DxfLayerDTO): void {
        l.switchVisibility();
        this.raiseClearSelectionRequest();
        // TODO : enregistrer l'état
    }
    
    switchLayerLock(l: DxfLayerDTO): void {
        l.switchLock()
        this.raiseClearSelectionRequest();
        // TODO : enregistrer l'état
    }

    focusSingleLayer(l: DxfLayerDTO): void {
        this.layers.forEach(la => {
            la.setVisibility(true);
        });
        l.setVisibility(false);
        this.raiseClearSelectionRequest();
    }

    raiseClearSelectionRequest(): void {
        if (this.clearSelectionRequested) {
            this.clearSelectionRequested();
        } else {
            logError("CadConverterLayersPanelVM.clearSelectionRequested n'est pas écouté");
        }
    }

    globalLayersDisplay(): void {
        this.layers.forEach(l => {
            l.setVisibility(false);
        });
    }

    globalLayersUnlock(): void {
        this.layers.forEach(l => {
            l.lock(true);
        });
    }

}