import { SimulationPhoto } from "src/app/core/model/data-model/tables/simulation-photo";
import { SimulationBuildingDTO } from "./simulation-building-dto";
import { SimulationBuildingDbView } from "src/app/core/model/db-model/views/simulation-building-db-view";
import { ZHierarchyDepthNameTable } from "src/app/core/model/db-model/tables/z-hierarchy-depth-name-table";
import { ZHierarchyDepthName } from "src/app/core/model/data-model/tables/z-hierarchy-depth-name";

export class SimulationPhotoDTO extends SimulationPhoto {
    buildingView: SimulationBuildingDTO[] = [];
    sandboxView: any[] = [];
    businessUnitJigDetail: any[] = [];
    hierarchyDepthNames: ZHierarchyDepthName[] = [];

    constructor(dtoData: any) {
        super(dtoData);
        dtoData[ZHierarchyDepthNameTable.databaseTableName].forEach((e: any) => {
            this.hierarchyDepthNames.push(new ZHierarchyDepthName(e));
        });
        dtoData[SimulationBuildingDbView.databaseTableName].forEach((e: any) => {
            this.buildingView.push(new SimulationBuildingDTO(e, this.hierarchyDepthNames));
        });
    }
}