import { DefinedModelBase } from '../defined-model-base';
import { DxfLayerTable } from '../../db-model/tables/dxf-layer-table';
export class DxfLayer extends DefinedModelBase {
    dxLaId!: number;
    dxLaImportId!: number;
    dxLaName!: string;
    dxLaColor!: string;
    dxLaLocked!: boolean;
    dxLaHidden!: boolean;

    constructor(dtoData: any, tableDef: any[] = []) {
        super(dtoData, [DxfLayerTable].concat(tableDef));
    }
}