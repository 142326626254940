import { Component, Input } from '@angular/core';
import { MVVMEventDestroyLifeCycle } from 'src/app/components-lib/shared-model/mvvm-events-destroy-lifecycle';
import { LeaseTypes } from '../../model/lease_types';
import { DynTableComponent } from 'src/app/components-lib/dyn-table/dyn-table.component';

@Component({
  selector: 'xc-lease-types',
  standalone: true,
  imports: [DynTableComponent],
  templateUrl: './lease-types.component.html',
  styleUrl: './lease-types.component.scss'
})
export class LeaseTypesComponent extends MVVMEventDestroyLifeCycle {
  @Input() override model: LeaseTypes | undefined;
}
