import { Component, Input } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { SelectButtonModule } from 'primeng/selectbutton';
import { TooltipModule } from 'primeng/tooltip';
import { InsertOptions } from '../../model/insert-options';
import { MVVMEventDestroyLifeCycle } from 'src/app/components-lib/shared-model/mvvm-events-destroy-lifecycle';

@Component({
  selector: 'xc-insert-options-selector',
  standalone: true,
  imports: [FormsModule, SelectButtonModule, TooltipModule],
  templateUrl: './insert-options-selector.component.html',
  styleUrl: './insert-options-selector.component.scss'
})
export class InsertOptionsSelectorComponent  extends MVVMEventDestroyLifeCycle{
  @Input() override model: InsertOptions | undefined;
}
