import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BlueprintViewerVM } from '../../model/blueprint-viewer-vml';
import { FloorBlueprintComponent } from '../../../content/blueprint-viewer-content-panel/itself/view/floor-blueprint/floor-blueprint.component';
import { BpViewerSidePanelComponent } from '../../../content/blueprint-viewer-side-panel/itself/view/bp-viewer-side-panel/bp-viewer-side-panel.component';
import { FormsModule } from '@angular/forms';
import { AutoFocusDirective } from 'src/app/components-lib/directives/auto-focus.directive';
import { CarouselModule } from 'primeng/carousel';
import { SliderModule } from 'primeng/slider';
import { ButtonModule } from 'primeng/button';
import { BpSvgDefComponent } from '../../../content/svg-entities/view/bp-svg-def/bp-svg-def.component';
import { MatInputModule } from '@angular/material/input';
import { BlueprintEditorToolbarComponent } from '../blueprint-editor-toolbar/blueprint-editor-toolbar.component';
import { MVVMEventDestroyLifeCycle } from 'src/app/components-lib/shared-model/mvvm-events-destroy-lifecycle';

@Component({
  selector: 'xc-floor-blueprint-editor',
  standalone: true,
  imports: [CommonModule, 
    MatInputModule, 
    FormsModule, 
    FloorBlueprintComponent,
    BpViewerSidePanelComponent, 
    AutoFocusDirective, 
    CarouselModule,
    SliderModule,
    ButtonModule,
    BpSvgDefComponent,
    BlueprintEditorToolbarComponent],
  templateUrl: './floor-blueprint-editor.component.html',
  styleUrls: ['./floor-blueprint-editor.component.scss']
})
export class FloorBlueprintEditorComponent extends MVVMEventDestroyLifeCycle {
  @Input() override model: BlueprintViewerVM | undefined;
}
