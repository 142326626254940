import { EquipmentRemovalCaseTable } from "../../db-model/tables/equipment-removal-case-table";
import { DefinedModelBase } from "../defined-model-base";

export class EquipmentRemovalCase extends DefinedModelBase {
    eqReCaId!: number;
    eqReCaName!: string;
    eqReCaDisplayNameId!: number;
    eqReCaScopeId!: number;

    constructor(dtoData: any, tableDef: any[] = []) {
        super(dtoData, [EquipmentRemovalCaseTable].concat(tableDef));
    }
}