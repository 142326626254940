import { Point } from "src/app/core/model/geometry-model/point.model";

export class SvgReticle {
    current: number  = 0;
    position: Point = Point.origin();
    pixelUnit: number = 1;

    set(current: number, position: Point = Point.origin()): void {
        this.current = current;
        this.position = position;
    }

    move(position: Point): void {
        this.position = position;
    }

    updateSize(pixelUnit: number): void {
        this.pixelUnit = pixelUnit;
    }
}