import { HttpParams } from "@angular/common/http";
import { Service } from "typedi";
import { PeopleLocation } from "src/app/core/model/data-model/tables/people-location";
import { WorkplaceAllocationViewSet } from "src/app/core/model/data-model/view-set/workplace-allocation-view-set";
import { DirectoryTable } from "src/app/core/model/db-model/tables/directory-table";
import { FloorDataTable } from "src/app/core/model/db-model/tables/floor-data-table";
import { FloorModelTable } from "src/app/core/model/db-model/tables/floor-model-table";
import { UsageContextIdEnum } from "src/app/core/model/usage-context-id-enum";
import { ApiService } from "src/app/core/services/api-service";
import { ApiControllers, ApiEndpoints } from "src/app/core/services/api-endpoints";
import { PeopleLocationTypeEnum } from "src/app/core/model/data-model/enums/people-location-type-enum";
import { PeopleLocationViewSet } from "src/app/core/model/data-model/view-set/people-location-view-set";
import { DynviewSet } from "../../model/data-model/sets/dyn-view-set";
import { Paginator } from "src/app/components-lib/dyn-grid/model/paginator";
import { UpdatedFloorDataDTO } from "src/app/ui/pages/layout/real-estate/floor-blueprint/content/services/dto/updated-floor-data-dto";
import { BpSvgPeopleLabel } from "src/app/ui/pages/layout/real-estate/floor-blueprint/content/svg-entities/model/bp-svg-people-label";
import { BlueprintPeopleLabelLayer } from "src/app/ui/pages/layout/real-estate/floor-blueprint/content/svg-entities/model/layers/blueprint-people-label-layer";
import { PeopleLocationTable } from "../../model/db-model/tables/people-location-table";
import { PeopleLocationDisplay } from "src/app/ui/pages/layout/real-estate/floor-blueprint/content/dialog/workplace-allocation-editor/model/people-location-display";

@Service({ global: true })  
export class DirectoryService extends ApiService {
    private buWorkforceEndPoint: string  = `${ApiControllers.directory}/buworkforce`;
    private buWorkforcePaginatorEndPoint: string  = `${ApiControllers.directory}/buworkforce/paginator`;
    private reWorkforceEndPoint(tableName: string, reId: number): string  { return `${ApiControllers.directory}/reworkforce/${tableName}/${reId}`};
    private reWorkforcePaginatorEndPoint(tableName: string, buId: number): string  { return `${ApiControllers.directory}/reworkforce/${tableName}/${buId}/paginator`};
    private peopleMoveListEndPoint(taskId: number): string  { return `${ApiControllers.directory}/movelist/${taskId}`};
    private createEndPoint: string = `${ApiControllers.directory}/location`;
    private updateRatioEndPoint: string = `${ApiControllers.directory}/location/ratio`;
    private deleteEndPoint: string = `${ApiControllers.directory}/locations`;
    
    constructor() {
        super();
    }

    // async getPeopleLocations<T>(floorDataId: number): Promise<T[]> {
    //     let params = new HttpParams();
    //     params = params.set('primaryColumnName', WorkplaceTable.woFloorDataId);
    //     params = params.set('primaryFilterId', floorDataId);
    //     const result = await this.getAsync<T[]>(this.dynt(PeopleLocationDbView.databaseTableName), UsageContextIdEnum.none, params);
    //     if (result != null) {
    //         return result.payload.map(item => {
    //             return DataModelBuilder.instance<T>(tableName, item);
    //             return new WorkplaceAllocationViewSet(item);
    //         })
    //     }
    //     return [];
    // }

    async getBuWorkforce(buId: number | null, pageIndex: number = 0, pageSize?: number): Promise<DynviewSet> {
        let params = new HttpParams();
        params = params.set('pageIndex', pageIndex);
        if (pageSize) {
            params = params.set('pageSize', pageSize);
        }
        if (buId != null) {
            params = params.set('buId', buId);
        }
        const result = await this.getAsync<any[]>(this.endPoint(this.buWorkforceEndPoint), UsageContextIdEnum.ReferentialAdministration, params);
        return new DynviewSet(result);
    }

    async getBuWorkforcePaginator(buId: number | null): Promise<Paginator | null> {
        let params: HttpParams | undefined ;
        if (buId != null) {
            params = new HttpParams();
            params = params.set('buId', buId);
        }

        const result = await this.getAsync<any>(this.endPoint(this.buWorkforcePaginatorEndPoint), UsageContextIdEnum.ReferentialAdministration, params);
        if (result != null) {
            return new Paginator(result.payload);
        }
        return null;
    }

    async getReWorkforce(tableName: string, reId: number, pageIndex: number = 0, pageSize?: number): Promise<DynviewSet> {
        let params = new HttpParams();
        params = params.set('pageIndex', pageIndex);
        if (pageSize) {
            params = params.set('pageSize', pageSize);
        }
        const result = await this.getAsync<any[]>(this.endPoint(this.reWorkforceEndPoint(tableName, reId)), UsageContextIdEnum.ReferentialAdministration, params);
        return new DynviewSet(result);
    }

    async getReWorkforcePaginator(tableName: string, reId: number): Promise<Paginator | null> {
        const result = await this.getAsync<any>(this.endPoint(this.reWorkforcePaginatorEndPoint(tableName, reId)), UsageContextIdEnum.ReferentialAdministration);
        if (result != null) {
            return new Paginator(result.payload);
        }
        return null;
    }

    async getPeopleMoveList(taskId: number, pageIndex: number = 0, pageSize?: number): Promise<DynviewSet> {
        let params = new HttpParams();
        params = params.set('pageIndex', pageIndex);
        if (pageSize) {
            params = params.set('pageSize', pageSize);
        }
        const result = await this.getAsync<any[]>(this.endPoint(this.peopleMoveListEndPoint(taskId)), UsageContextIdEnum.none, params);
        return new DynviewSet(result);
    }

    async createPeopleLocation(floorParentDataId: number, 
        locationType: PeopleLocationTypeEnum, 
        allocation: PeopleLocationDisplay, 
        taskId: number): Promise<{label: BpSvgPeopleLabel, allocation: WorkplaceAllocationViewSet, layer: BlueprintPeopleLabelLayer | null} | PeopleLocationViewSet | null> {
        const dto = {
            [DirectoryTable.diId]: allocation.directoryId,
            [FloorDataTable.flDaId]: floorParentDataId,
            [PeopleLocationTable.peLoRatio]: allocation.ratio,
            [PeopleLocationTable.peLoLocationTypeId]: locationType
        };
        const result = await this.postAsync<any>(this.endPoint(this.createEndPoint), dto);
        if (result) {
            switch (locationType) {
                case PeopleLocationTypeEnum.Workplace:
                    let layer = null;
                    if (result.payload[FloorModelTable.databaseTableName]) {
                      layer = new BlueprintPeopleLabelLayer(result.payload[FloorModelTable.databaseTableName], taskId)
                    }
                    return {
                        label: new BpSvgPeopleLabel(result.payload['SvgGroupDTO']),
                        allocation: new WorkplaceAllocationViewSet(result.payload[PeopleLocationTable.databaseTableName]),
                        layer: layer
                    };
                default:
                    return new PeopleLocationViewSet(result.payload[PeopleLocationTable.databaseTableName]);
            }
        }
        return null;
    }
  
    async updatePeopleLocationRatio(allocationFloorDataId: number, directoryId: number, ratio: number): Promise<{label: BpSvgPeopleLabel, allocation: PeopleLocation} | null> {
      const dto = {
        [PeopleLocationTable.peLoDirectoryId]: directoryId,
        [PeopleLocationTable.peLoFloorDataId]: allocationFloorDataId,
        [PeopleLocationTable.peLoRatio]: ratio
      };
      const result = await this.patchAsync<any>(this.endPoint(this.updateRatioEndPoint), dto);
        if (result) {
            return {
                label: new BpSvgPeopleLabel(result.payload['SvgGroupDTO']),
                allocation: new PeopleLocation(result.payload[PeopleLocationTable.databaseTableName])
            };
        }
      return null;
    }
        
    async deleteAllocations(peopleAllocationIds: string[]): Promise<UpdatedFloorDataDTO[]> {
        let params = new HttpParams();
        peopleAllocationIds.forEach(id => {
            params = params.append(`id`, id);
        });
        const result = await this.deleteAsync<UpdatedFloorDataDTO[]>(this.endPoint(this.deleteEndPoint), params);
        if (result) {
            return result.payload.map(item => {
                return new UpdatedFloorDataDTO(item);
            })
        }
        return [];
    }
}