import { InteractionCommand } from "../interaction-command";
import { SvgEntityTypesEnum } from "src/app/core/model/svg-model/svg-entity-type-enum";
import { InsertInteraction } from "./insert-interaction";
import { CircleInsertGizmo } from "../../../gizmo/insert/model/circle-insert-gizmo";
import { SelectableSvgCircle } from "../../../svg/selectable-svg-circle";
import { logError } from "src/app/core/services/logging-service";

export class CircleInsertInteraction extends InsertInteraction<CircleInsertGizmo> {
    constructor(command: InteractionCommand) {
        super(command, new CircleInsertGizmo(), SvgEntityTypesEnum.circle);
        this.gizmo.insertionCompleted = () => {
            if (this.insertionCompleted) {
                this.insertionCompleted(SelectableSvgCircle.fromValues(this.gizmo.centerPoint, this.gizmo.radius, 0.001, "black"));
            } else {
                logError("CircleInsertInteraction.insertionCompleted n'est pas écouté");
            }
        }
    }
}