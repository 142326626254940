import { AppGrantView } from "src/app/core/model/data-model/views/app-grant-view";
import { GrantAdminCategoryEnum } from "./grant-admin-category-enum";
import { GrantAdminPageModel } from "./grant-admin-page-model";
import { AppRoleGrant } from "src/app/core/model/data-model/tables/app-role-grant";
import { GrantOption } from "./grant-option";
import Container from "typedi";
import { GrantService } from "src/app/core/services/backend-services/grant-service";

export class BlueprintUpdateGrantsVM extends GrantAdminPageModel {
    roleId: number;
    roleGrants: AppRoleGrant[];
    allGrants: GrantOption[] = [];

    constructor(roleId: number, allGrants: AppGrantView[], roleGrants: AppRoleGrant[]) {
        super(GrantAdminCategoryEnum.blueprintUpdate);
        this.roleId = roleId;
        this.roleGrants = roleGrants;
        this.setOptions(allGrants);
    }

    setOptions(allGrants: AppGrantView[]): void {
        const tmp: GrantOption[] = [];
        allGrants.forEach(g => {
            tmp.push(new GrantOption(g.apGrId, g.laItText, this.isGranted(g.apGrId)));
        });
        this.allGrants = tmp;
    }

    async toggleGrant(option: GrantOption): Promise<void> {
        const s = Container.get(GrantService);
        if (option.selected) {
            // L'option a été sélectionnée, on ajoute le grant au rôle
            const result = await s.CreateAppRoleGrant(this.roleId, option.id);
            if (result == null) {
                // TODO : Une erreur s'est produite côté backend, on recharge la liste
            }
        } else {
            // l'option a été desélectionnée, on retire le grant du rôle
            const result = await s.deleteRoleGrants(this.roleId, [option.id]);
            if (result == null) {
                // TODO : Une erreur s'est produite côté backend, on recharge la liste
            }
        }
    }

    private isGranted(id: number): boolean {
        return this.roleGrants.find(g => g.apRoGrGrantId === id) !== undefined;
    }

    // isDisabled(id: number): boolean {
    //     return false;
    // }
}