import { TablesSetsEnum } from 'src/app/core/model/data-model/tables-sets-enum';
import { Container } from 'typedi';
import { FloorDataStateEnum } from "src/app/core/model/data-model/enums/floor-data-state-enum";
import { ProviderCompany } from "src/app/core/model/data-model/tables/provider-company";
import { ArrayUtils } from "src/app/core/model/static-functions/array-utils";
import { EquipmentsSelectionSet } from "../../../../blueprint-viewer-content-panel/itself/model/interaction/equipments-selection-set";
import { EntitiesFormModel } from "./entities-form-model";
import { EquipmentFormCatalogView } from "./equipment-form-catalog-view";
import { EquipmentFormCategory } from "./equipment-form-category";
import { EquipmentCatalogViewSet } from 'src/app/core/model/data-model/view-set/equipment-catalog-view-set';
import { EquipmentCategoryTable } from 'src/app/core/model/db-model/tables/equipment-category-table';
import { ViewsNames } from 'src/app/core/model/db-model/views-names-enum';
import { DyntService } from 'src/app/core/services/backend-services/dynt-service';
import { BpSvgDef } from '../../../../bp-svg-core-model/bp-svg-def';
import { BpSvgUse } from '../../../../bp-svg-core-model/bp-svg-use';
import { FloorModelCategoryEnum } from 'src/app/core/model/data-model/enums/floor-model-category-enum';
import { DisplayThemeEnum } from 'src/app/core/model/data-model/enums/display-theme-enum';
import { EventEmitter } from 'src/app/core/events/event-emitter';
import { EquipmentPlanningStateView } from 'src/app/core/model/data-model/views/equipment-planning-state-view';
import { EquipmentPlanningStateCommand } from './equipment-planning-state-command';
import { EquipmentPlanningStateEnum } from 'src/app/core/model/data-model/enums/equipment-planning-state-enum';
import { EquipmentReconcileService } from 'src/app/core/services/backend-services/equipment-reconcile-service';
import { EquipmentStorageArea } from 'src/app/core/model/data-model/tables/equipment-storage-area';
import { TablesNamesEnum } from 'src/app/core/model/db-model/tables-names-enum';
import { EquipmentStorageAreaTable } from 'src/app/core/model/db-model/tables/equipment-storage-area-table';
import { ZScopeEnum } from 'src/app/core/model/data-model/enums/z-scope-enum';
import { EquipmentPlanningStateChoice } from './equipment-planning-state-choice';
import { EquipmentRemovalCaseView } from 'src/app/core/model/data-model/views/equipment-removal-case-view';
import { getInventoryStateCase, hasEnoughReferences, invertoryStatesAreSame } from 'src/app/core/model/data-processing/equipment-planning-processing';
import { EquipmentPlanningTaskView } from 'src/app/core/model/data-model/views/equipment-planning-task-view';
import { TaskTable } from 'src/app/core/model/db-model/tables/task-table';
import { EquipmentService } from 'src/app/core/services/backend-services/equipment-service';
import { BpEquipmentService } from 'src/app/core/services/backend-services/bp-equipment-service';
import { logError } from 'src/app/core/services/logging-service';

export class EquipmentFormVM extends EventEmitter implements EntitiesFormModel {
    static applyStateRequest = "applyStateRequest";

    hasPlanningTask: boolean = false;
    taskId: number = 0;
    layerId: number = FloorModelCategoryEnum.Equipment;
    isEditable: boolean = false;

    categories: EquipmentFormCategory[] = [];
    selectedCategoryCatalog: EquipmentFormCatalogView[] = [];
    selectedCategoryProviders: ProviderCompany[] = [];
    selectedProvider: ProviderCompany | undefined;
    selectedProviderCatalog: EquipmentFormCatalogView[] = [];

    selectedDataStateId: number | undefined;
    selectedDataStateName: string | undefined;
    selectedDataStateColor: string | undefined;
    selectedDataStateThemeId: number | undefined;
    selectedPlanningStateId: number = 0;
    selectedPlanningStateName: string | undefined;
    selectedPlanningStateColor: string | undefined;
    selectedPlanningStateThemeId: number | undefined;

    planningStates: EquipmentPlanningStateView[] = [];
    planningStateChoices: EquipmentPlanningStateChoice[] = [];

    selectedEquipments: EquipmentsSelectionSet = new EquipmentsSelectionSet();

    async initialize(items: EquipmentsSelectionSet, isEditable: boolean, hasPlanningTask: boolean, taskId: number): Promise<void> {
        this.isEditable = isEditable;
        this.hasPlanningTask = hasPlanningTask;
        this.taskId = taskId;
        this.selectedEquipments = items;
        await this.updateCurrentValues();
    }

    async updateCurrentValues(): Promise<void> {
        const s = Container.get(DyntService);
        this.planningStates = await s.downloadTable<EquipmentPlanningStateView>(ViewsNames.EquipmentPlanningStateView);

        if (this.selectedEquipments.count > 1) {
            // Isole les catégories distinctes
            const cats = (this.selectedEquipments.items as BpSvgUse[]).map(x=> new EquipmentFormCategory(x.def!.equipmentCategoryId, x.def!.equipmentCategoryName, x.floorModelName!));
            const distinctCats = ArrayUtils.DistinctBy<EquipmentFormCategory>(cats, "id");
            distinctCats.forEach(c => {
                // Les équipements de la catégorie
                const equipmentItems = (this.selectedEquipments.items as BpSvgUse[]).filter(x=> x.def!.equipmentCategoryId === c.id);
                const items = equipmentItems.map(x=> ({id: x.def!.id, name: x.def!.name + ' / ' + x.def!.name, count: 0}));
                const distinctItems = ArrayUtils.DistinctBy<({id: number, name: string, count: number})>(items, "id");
                distinctItems.forEach(di => {
                    const count = equipmentItems.filter(x=> x.def!.id === di.id);
                    di.count = count.length;
                });
                c.items = distinctItems;
            });
            this.categories = distinctCats;
        } else {
            this.categories = [];
        }

        // S'il n'y a qu'un élément sélectionné ou qu'une seule catégorie d'éléments
        // on charge les entrées de catalogue pour cette catégorie
        if (this.selectedEquipments.count === 1 || this.categories.length === 1) {
            const categoryId = this.getSelectedCategoryId();
            const s = Container.get(DyntService);
            const result = await s.downloadTable<EquipmentCatalogViewSet>(ViewsNames.EquipmentCatalogView, TablesSetsEnum.EquipmentCatalogViewSet, EquipmentCategoryTable.eqCaId, categoryId);
            this.selectedCategoryCatalog.splice(0);
            result.forEach(e => {
                this.selectedCategoryCatalog.push(new EquipmentFormCatalogView(e));
            });
            const providers = result.map(x=>x.providerCompany);
            this.selectedCategoryProviders = ArrayUtils.DistinctBy<ProviderCompany>(providers, "prCoId");
            this.selectedProviderCatalog = [];
        }

        await this.updatePlanningValues();
    }

    async updatePlanningValues(): Promise<void> {
        const uses = (this.selectedEquipments.items as BpSvgUse[]);
        if (!this.hasPlanningTask || uses.length === 0) return;

        const first = uses[0];
        if (uses.length > 1) {
            const distinctDataStates = [...new Set(uses.map(u => u.dataStateId))];
            const distinctPlanningStates = [...new Set(uses.map(u => u.planningStateId))];
            if (distinctDataStates.length > 1) {
                this.selectedDataStateId = 0;
                this.selectedDataStateName = "Divers";
                this.selectedDataStateColor = "lightgrey";
                this.selectedDataStateThemeId = DisplayThemeEnum.InstallationInstalled;
            } else {
                this.selectedDataStateId = first.floorDataState?.flDaStId;
                this.selectedDataStateName = first.floorDataState?.flDaStDisplayName;
                this.selectedDataStateColor = first.floorDataState?.flDaStColor;
                if (first.floorDataState?.flDaStId === FloorDataStateEnum.Deleted) {
                    this.selectedDataStateThemeId = DisplayThemeEnum.InstallationUninstalled;
                } else {
                    this.selectedDataStateThemeId = DisplayThemeEnum.InstallationInstalled;
                }
            }
            if (distinctPlanningStates.length > 1) {
                this.selectedPlanningStateId = 0;
                this.selectedPlanningStateName = "Divers";
                this.selectedPlanningStateColor = "lightgrey";
                this.selectedPlanningStateThemeId = DisplayThemeEnum.ReconcileInstalled;
            } else {
                this.selectedPlanningStateId = first.equipmentPlanningState ? first.equipmentPlanningState.eqPlStId : 0;
                this.setPlanningStateName(first);
                this.selectedPlanningStateColor = first.equipmentPlanningState?.eqPlStColor;
                if (first.floorDataState?.flDaStId === FloorDataStateEnum.Deleted) {
                    this.selectedPlanningStateThemeId = DisplayThemeEnum.ReconcileUninstalled;
                } else {
                    this.selectedPlanningStateThemeId = DisplayThemeEnum.ReconcileUninstalled;
                }
            }
        } else {
            this.selectedDataStateId = first.floorDataState?.flDaStId;
            this.selectedDataStateName = first.floorDataState?.flDaStDisplayName;
            this.selectedDataStateColor = first.floorDataState?.flDaStColor;
            this.selectedPlanningStateId = first.equipmentPlanningState ? first.equipmentPlanningState.eqPlStId : 0;
            this.setPlanningStateName(first);
            this.selectedPlanningStateColor = first.equipmentPlanningState?.eqPlStColor;
            if (first.floorDataState?.flDaStId === FloorDataStateEnum.Deleted) {
                this.selectedDataStateThemeId = DisplayThemeEnum.InstallationUninstalled;
                this.selectedPlanningStateThemeId = DisplayThemeEnum.ReconcileUninstalled;
            } else {
                this.selectedDataStateThemeId = DisplayThemeEnum.InstallationInstalled;
                this.selectedPlanningStateThemeId = DisplayThemeEnum.ReconcileUninstalled;
            }
        }

        await this.setPlanningStateCommands(first);
    }

    setPlanningStateName(use: BpSvgUse): void {
        if (use.equipmentPlanningState?.eqPlStId === EquipmentPlanningStateEnum.FromMove && use.equipmentPlanning?.eqPlFloorDataId === use.equipmentPlanning?.eqPlMoveCaseTwinId) {
            this.selectedPlanningStateName = "Déplacé sur la zone";
        } else {
            this.selectedPlanningStateName = use.equipmentPlanningState?.eqPlStDisplayName;
        }
    }


    hasMixedInventoryState(): boolean {
        if (this.selectedEquipments.items.length === 0) return false;

        const first = this.selectedEquipments.items[0];
        const firstState = getInventoryStateCase(first.equipmentPlanningState, first.equipmentPlanning);
        let result = false;
       
        this.selectedEquipments.items.forEach(i => {
            const s = getInventoryStateCase(i.equipmentPlanningState, i.equipmentPlanning);
            if (!invertoryStatesAreSame(firstState, s)) result = true;
        });

        return result;
    }

    async setPlanningStateCommands(use: BpSvgUse): Promise<void> {
        this.planningStateChoices.splice(0);

        // On ne peut pas mélanger les cas
        // si parmi les éléments sélectionnnés il y a des éléments avec différentes assignations ou certains sans assignation (en attente), on n'affiche pas de commande
        if (this.hasMixedInventoryState()) {
            return;
        }

        const s = Container.get(EquipmentReconcileService);
        const catIds = [...new Set(this.selectedEquipments.items.map(x=> x.floorCatalogId))];

        switch (this.selectedDataStateId) {
            case FloorDataStateEnum.Added:
                // Eléments ajoutés sur le plan
                if (this.selectedPlanningStateId !== EquipmentPlanningStateEnum.StandingBy) {
                    this.planningStateChoices.push(new EquipmentPlanningStateChoice("En attente", this.planningStates.find(x=> x.eqPlStId === EquipmentPlanningStateEnum.StandingBy)));
                }
                const fromStoreCommand = new EquipmentPlanningStateChoice("Vient d'un stock", this.planningStates.find(x=> x.eqPlStId === EquipmentPlanningStateEnum.FromStore))
                this.planningStateChoices.push(fromStoreCommand);
                const fromPurchaseCommand = new EquipmentPlanningStateChoice("En commande", this.planningStates.find(x=> x.eqPlStId === EquipmentPlanningStateEnum.FromPurchase));
                this.planningStateChoices.push(fromPurchaseCommand);
                const fromMoveCommand = new EquipmentPlanningStateChoice("Transféré depuis un autre lieu", this.planningStates.find(x=> x.eqPlStId === EquipmentPlanningStateEnum.FromMove), "Aucune provenance trouvée sur le parc");
                this.planningStateChoices.push(fromMoveCommand);
                
                const sourceStorageAreas = await s.getSourceStorageAreas(catIds);
                if (sourceStorageAreas.storageAreas.length === 0) {
                    fromStoreCommand.setChildren([[0, "Indisponible en stock"]]);
                } else {
                    // S'il y a déjà des éléments déclarés comme provenant du stock, il faut vérifier qu'il y en a suffisamment de disponibles
                    fromStoreCommand.setChildren(sourceStorageAreas.storageAreas.map(x=> [x.eqStArId, x.eqStArName]));
                }
                const fromStorageIds = [...new Set(this.selectedEquipments.items.map(x=> x.equipmentPlanning?.eqPlStoreCaseStorageAreaId))];
                if (fromStorageIds.length === 1) {
                    fromStoreCommand.setSelected(fromStorageIds[0]!)
                }
                
                const purchases = await s.getPurchases(this.taskId, catIds);
                if (purchases.purchases.length === 0) {
                    fromPurchaseCommand.setChildren([[0, "Aucune commande enregistrée"]]);
                } else {
                    // Si les éléments sélectionnés proviennent de plusieurs commandes on ne peut que les repasser "en attente"
                    if (purchases.purchases.length > 1) {
                        fromPurchaseCommand.setChildren([[0, "Diverses commandes"]]);
                    } else {
                        
                        // Si on arrive là c'est qu'il y a une seule commande comprenant la ou les références d'équipement sélectionnées
                        // Les équipements sélectionnés sont ou tous déjà assignés à la commande ou tous non encore assignés
                        // Si les équipements sélectionnés ne sont pas encore assignés à une commande, il faut vérifier qu'il y a suffisament de disponibilité
                        // sur la ou les commandes du projet
                        const singlePurchaseId = purchases.purchases[0].eqPuId;
                        const singlePurchaseRef = purchases.purchases[0].eqPuPurchaseRef;
                        const singlePurchaseDetails = purchases.purchasesDetails;
                        const alreadyAssigned = this.selectedEquipments.items.filter(x=> x.equipmentPlanning && x.equipmentPlanning.eqPlPurchaseCasePurchaseId != null).length > 0;

                        fromPurchaseCommand.setChildren([[singlePurchaseId, singlePurchaseRef]]);
                        
                        if (!alreadyAssigned) {
                            // Les identifiants des équipements déjà assigné à la commande
                            const floorCatalogIds = this.selectedEquipments.items.map(x=> x.floorCatalogId);
                            const distinctFloorCatalogIds = [...new Set(floorCatalogIds)];
                            const distinctCount = ArrayUtils.dictinctCount(floorCatalogIds);

                            // Puisque à ce stade les équipements n'existent pas encore réellement, on n'a pas les références catalogue dans le jeu de données
                            // il faut les charger séparément
                            const eqs = Container.get(EquipmentService);
                            const eqRefs = await eqs.getEquipmentsCatalog(distinctFloorCatalogIds);
                            //const eqCatIds = eqRefs.map(x=> x.equipmentCatalog.eqCgId);

                            let hasEnoughRefs: boolean = true;
                            
                            // Les items de commande déjà réservés
                            const st = Container.get(DyntService);
                            const currentPlanningTask = await st.downloadTable<EquipmentPlanningTaskView>(ViewsNames.EquipmentPlanningTaskView, undefined, TaskTable.taId, this.taskId);
                            
                            // Les références en commande
                            const purchasesRefsIds = [...new Set(singlePurchaseDetails.map(x=> x.eqPuDeEquipmentCatalogId))];
                            purchasesRefsIds.forEach(async prid => {
                                const purchaseRefCount = singlePurchaseDetails.find(x=> x.eqPuDeEquipmentCatalogId === prid)!.eqPuDeQuantity;
                                // Correspondance référence catalogue / référence pictogramme
                                const catRef = eqRefs.find(x=> x.equipmentCatalog.eqCgId === prid);
                                const catFloorCatalogId = catRef?.equipmentCatalog.eqCgFloorCatalogId;
                                const selectedCount = distinctCount.find(x=> x.value === catFloorCatalogId);

                                // Références déjà réservées
                                const unavailable = currentPlanningTask.filter(x=> x.eqPlPurchaseCasePurchaseId === singlePurchaseId && x.eqItCatalogId === prid).length;

                                if (selectedCount && selectedCount.count > purchaseRefCount - unavailable) {
                                    hasEnoughRefs = false;
                                }
                            });

                            if (!hasEnoughRefs) {
                                // La commande ne contient pas assez de références, elle est inactive
                                fromPurchaseCommand.children[0].name += " (quantités insuffisantes)";
                                fromPurchaseCommand.children[0].enabled = false;
                            }
                        } else {
                            const fromPurchaseIds = [...new Set(this.selectedEquipments.items.map(x=> x.equipmentPlanning?.eqPlPurchaseCasePurchaseId))];
                            if (fromPurchaseIds.length === 1) {
                                fromPurchaseCommand.setSelected(fromPurchaseIds[0]!)
                            }
                        }
                    }
                }

                const fromTwinTasks = await s.getTwinTasks(this.taskId, this.selectedPlanningStateId, catIds);
                fromMoveCommand.disabled = fromTwinTasks.length === 0;
                break;
            case FloorDataStateEnum.Updated:
                // Eléments modifiés sur le plan
                if (this.selectedPlanningStateId !== EquipmentPlanningStateEnum.StandingBy && 
                    !(use.equipmentPlanningState?.eqPlStId === EquipmentPlanningStateEnum.FromMove && use.equipmentPlanning?.eqPlFloorDataId === use.equipmentPlanning?.eqPlMoveCaseTwinId)) {
                    this.planningStateChoices.push(new EquipmentPlanningStateChoice("Réinitialiser", this.planningStates.find(x=> x.eqPlStId === EquipmentPlanningStateEnum.StandingBy)));
                }
                break;
            case FloorDataStateEnum.Deleted:
                // Eléments supprimés sur le plan
                if (this.selectedPlanningStateId !== EquipmentPlanningStateEnum.StandingBy) {
                    const waitingCommand = new EquipmentPlanningStateChoice("En attente", this.planningStates.find(x=> x.eqPlStId === EquipmentPlanningStateEnum.StandingBy));
                    waitingCommand.setChildren([[EquipmentPlanningStateEnum.StandingBy, "Réinitialiser"]]);
                    this.planningStateChoices.push(waitingCommand);
                }

                const toStoreCommand = new EquipmentPlanningStateChoice("Retourne au stock", this.planningStates.find(x=> x.eqPlStId === EquipmentPlanningStateEnum.ToStore));
                this.planningStateChoices.push(toStoreCommand);
                const toMoveCommand = new EquipmentPlanningStateChoice("Est déplacé ailleurs", this.planningStates.find(x=> x.eqPlStId === EquipmentPlanningStateEnum.ToMove));
                this.planningStateChoices.push(toMoveCommand);
                const removalCommand = new EquipmentPlanningStateChoice("Sort de l'inventaire", this.planningStates.find(x=> x.eqPlStId === EquipmentPlanningStateEnum.ToRemove));
                this.planningStateChoices.push(removalCommand);
                
                const ts = Container.get(DyntService);
                const removalCases = await ts.downloadTable<EquipmentRemovalCaseView>(ViewsNames.EquipmentRemovalCaseView);
                removalCommand.setChildren(removalCases.map(x=> [x.eqReCaId, x.eqReCaDisplayName]));
                const removalIds = [...new Set(this.selectedEquipments.items.map(x=> x.equipmentPlanning?.eqPlRemoveCaseDeletionCaseId))];
                if (removalIds.length === 1) {
                    removalCommand.setSelected(removalIds[0]!)
                }


                const storageAreas = await ts.downloadTable<EquipmentStorageArea>(TablesNamesEnum.EquipmentStorageArea, undefined, EquipmentStorageAreaTable.eqStArScope, ZScopeEnum.Client);
                toStoreCommand.disabled = storageAreas.length === 0;
                const toTwinTasks = await s.getTwinTasks(this.taskId, this.selectedPlanningStateId, catIds);
                if (toTwinTasks.length > 0) {
                    toMoveCommand.setChildren(toTwinTasks.map(x=> [x.taId, x.taName]));
                } else {
                    toMoveCommand.setChildren([[0, "Aucune destination identifiée"]]);
                }

                if (storageAreas.length > 0) {
                    toStoreCommand.setChildren(storageAreas.map(x=> [x.eqStArId, x.eqStArName]));
                } else {
                    toStoreCommand.setChildren([[0, "Aucun lieu de stockage défini"]]);
                }
                const toStoreIds = [...new Set(this.selectedEquipments.items.map(x=> x.equipmentPlanning?.eqPlStoreCaseStorageAreaId))];
                if (toStoreIds.length === 1) {
                    toStoreCommand.setSelected(toStoreIds[0]!)
                }
                break;
            default:
                break;
        }
    }

    async onSelectionChange(): Promise<void> {
        await this.updateCurrentValues();
    }

    getSelectedCategoryId(): number {
        if (this.selectedEquipments.count === 0) {
            return 0;
        }

        if (this.selectedEquipments.count === 1) {
            return this.selectedEquipments.single<BpSvgUse>().def!.equipmentCategoryId;
        }

        if (this.categories.length > 1) {
            return 0;
        }

        return this.categories[0].id;
    }

    onSelectedProviderChange(e: ProviderCompany): void {
        this.selectedProviderCatalog = this.selectedCategoryCatalog.filter(x=> x.providerCompany.prCoId === e.prCoId);
    }

    equipmentReplaced?: (uses: BpSvgUse[], floorCatalog: BpSvgDef[]) => void;
    async onReplaceEquipmentClick(e: MouseEvent, ecv: EquipmentFormCatalogView): Promise<void> {
        // Remplace les éléments sélectionnés
        // A condition qu'ils ne soient pas au DataState Deleted
        // et qu'il ne soit pas de même type que l'entrée de catalogue sélectionnée en remplacement

        const replacableItems = (this.selectedEquipments.items as BpSvgUse[]).filter(x=> x.dataStateId !== FloorDataStateEnum.Deleted && x.floorCatalogId !== ecv.floorCatalog.flCgId);
        if (replacableItems.length > 0) {
            var floorDataIds = replacableItems.map(x=> x.floorDataId);
            const s = Container.get(BpEquipmentService);
            const result = await s.replaceEquipments(floorDataIds, ecv.floorCatalog.flCgId);
            if (result != null) {
                if (this.equipmentReplaced) {
                    this.equipmentReplaced(result.use, result.floorCatalog);
                } else {
                    logError("EquipmentFormVM.equipmentReplaced n'est pas écouté");
                }
            }
        }

    }


    async statePinClick(e: MouseEvent, themeId: number | undefined): Promise<void> {
        if (themeId) {
            await this.emitEventAsync(EquipmentFormVM.applyStateRequest, themeId);
        }
    }

    async planningStateCommandClick(e: MouseEvent, choice: EquipmentPlanningStateChoice, cmd: EquipmentPlanningStateCommand): Promise<void> {
        const ids = this.selectedEquipments.floorDataIds();
        const s = Container.get(EquipmentReconcileService);
        let twinId: number | undefined;
        let purchaseId: number | undefined;
        let removeId: number | undefined;
        let storeId: number | undefined;
        switch (choice.planningState?.eqPlStId) {
            case EquipmentPlanningStateEnum.FromMove:
            case EquipmentPlanningStateEnum.ToMove:
                twinId = cmd.id;
                break;
            case EquipmentPlanningStateEnum.FromPurchase:
                purchaseId = cmd.id;
                break
            case EquipmentPlanningStateEnum.ToRemove:
                removeId = cmd.id;
                break
            case EquipmentPlanningStateEnum.FromStore:
            case EquipmentPlanningStateEnum.ToStore:
                storeId = cmd.id;
                break
            default:
                break;
        }
        const result = await s.updateInventoryState(ids, choice.planningState!.eqPlStId, twinId, purchaseId, removeId, storeId);
        // Actualise le planning state des équipements
        result.forEach(r => {
            const item = this.selectedEquipments.items.find(x=> x.floorDataId === r.eqPlFloorDataId);
            if (item) {
                item.equipmentPlanning = r;
                item.equipmentPlanningState = choice.planningState;
                item.planningStateId = choice.planningState!.eqPlStId;
            }
        });
        // Actualise le formulaire
        await this.updatePlanningValues();
        // Demande l'actualisation du thème
        await this.emitEventAsync(EquipmentFormVM.applyStateRequest, this.selectedPlanningStateThemeId);
    }
}