import { SiteEquipmentProviderDbView } from '../../db-model/views/site-equipment-provider-db-view';
import { DefinedModelBase } from '../defined-model-base';

export class SiteEquipmentProviderView extends DefinedModelBase {
    buSiteId!: number;
    eqCgProviderId!: number;
    prCoName!: string;
    prCoColor!: string;
    stEqPrViEquipmentValue!: number;
    stEqPrViEquipmentCount!: number;

    constructor(dtoData: any) {
        super(dtoData, [SiteEquipmentProviderDbView]);
    }
}