import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MaterialModule } from 'src/app/core/material.module';
import { WorkplaceFormModel } from '../../model/workplace-form-model';

@Component({
  standalone: true,
  imports: [CommonModule, MaterialModule, FormsModule],
  selector: 'xc-workplace-properties-form',
  templateUrl: './workplace-properties-form.component.html',
  styleUrls: ['./workplace-properties-form.component.scss']
})
export class WorkplacePropertiesFormComponent implements OnInit {
  @Input() model: WorkplaceFormModel | undefined;

  constructor() { }

  ngOnInit(): void {
  }

}
