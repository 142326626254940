@if (model) {

<div class="h-full">
    <!-- <div id="viewOptions">
    <mat-tab-group
      (selectedTabChange)="model.selectedTabChange($event)"
      fitInkBarToContent
      mat-stretch-tabs="false"
      mat-align-tabs="center"
    >
      @for (c of model.tabs; track c) {
      <mat-tab [label]="c.name">
        <ng-template matTabContent>
          <div id="viewContent"> -->
    @switch (model.requiredChart) {
        @case (model.contentTypeEnum.donutchart) {
            <xc-donut-chart-wrapper [model]="$any(model.currentView)" />
        } @case (model.contentTypeEnum.referential) {
            <xc-properties-editor [model]="$any(model.currentView)" />
        } @case (model.contentTypeEnum.none) {
            <xc-dyn-table class="table" [model]="$any(model.currentView)?.content" />
        } @case (model.contentTypeEnum.moveLists) {
            <xc-move-lists-container [model]="$any(model.currentView)" />
        } 
    }
    <!-- </div>
        </ng-template>
      </mat-tab>
      }
    </mat-tab-group>
  </div> -->
</div>

}