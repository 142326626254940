import { Point } from "src/app/core/model/geometry-model/point.model";
import { DxfSvgEntity } from "./dxf-svg-entity";
import { SvgEntity } from "src/app/core/model/svg-model/svg-entity.model";
import { Vector } from "src/app/core/model/geometry-model/vector.model";
import { SvgEntityTypesEnum } from "src/app/core/model/svg-model/svg-entity-type-enum";
import { SvgTransform } from "src/app/core/model/svg-model/svg-transform.model";
import { Segment } from "src/app/core/model/geometry-model/segment.model";
import { XcMaths } from "src/app/core/model/static-functions/xc-maths";
import { SvgEntityPoint } from "../../../shared/gizmos/model/svg-entity-point";
import { SvgEntityPointStyleEnum } from "../../../shared/gizmos/model/svg-entity-point-style-enum";
import { SvgEllipseService } from "src/app/core/model/svg-model/svg-ellipse-service";
import { SvgEllipse } from "src/app/core/model/svg-model/svg-ellipse";
import { SvgEntityParser } from "../../../shared/gizmos/model/svg-entity-parser";

export class DxfSvgEllipse extends DxfSvgEntity {
    cx: number;
    cy: number;
    rx: number;
    ry: number;

    constructor(dtoData: any) {
        super(dtoData);

        this.cx = SvgEntity.getNumber(dtoData, "cx");
        this.cy = SvgEntity.getNumber(dtoData, "cy");
        this.rx = SvgEntity.getNumber(dtoData, "rx");
        this.ry = SvgEntity.getNumber(dtoData, "ry");
    }

    centerPoint(): SvgEntityPoint {
        return new SvgEntityPoint(SvgEntityPointStyleEnum.center, new Point(this.cx, this.cy));
    }

    majorAxis(): Segment {
        const a = XcMaths.toRad(this.transform ? this.transform.rotationAngle : 0);
        const tmp = new Point(this.cx + this.rx, this.cy);
        const p1 = tmp.rotate(this.centerPoint(), a);
        const p2 = tmp.rotate(this.centerPoint(), a + Math.PI);
        return new Segment(p1, p2);
    }

    protected override getSelectablePoints(): SvgEntityPoint[] {
        const result = [new SvgEntityPoint(SvgEntityPointStyleEnum.center, new Point(this.cx, this.cy))];
        const e = SvgEllipseService.geometry(this as SvgEllipse);
        const t = this.transform;
        const a = XcMaths.toRad(t? t.rotationAngle : 0);
        result.push(...SvgEntityPoint.toStyle(Point.rotateAll(e.getQuadrants(), e.center, a), SvgEntityPointStyleEnum.quadrant, this.entityId));
        return result;
    }

    protected override getStatement(): string {
        const attributesStatement = SvgEntityParser.getAttributesStatement([["cx", this.cx], ["cy", this.cy], ["rx", this.rx], ["ry", this.ry], ["stroke-width", this.strokeWidth], ["stroke", this.stroke]], this.transform);
        return SvgEntityParser.getTagStatement("ellipse", attributesStatement);
    }

    translate(delta: Vector): void {
        const c = new Point(this.cx, this.cy);
        const cn = c.translate(delta);
        this.cx = cn.x;
        this.cy = cn.y;

        if (this.transform && this.transform.rotationCenter) {
            this.transform = this.transform.translateRotationCenter(delta);
        }
    }

    static fromValues(layerId: number, centerPoint: Point, rx: number, ry: number, strokeWidth: number, transform: SvgTransform): DxfSvgEllipse {
        const result = new DxfSvgEllipse({entityType: SvgEntityTypesEnum.ellipse, entityId: 0, layerId: layerId, cx: centerPoint.x, cy: centerPoint.y, rx: rx, ry: ry, strokeWidth: strokeWidth});
        result.transform = transform;
        return result;
    }
}