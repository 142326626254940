import { getEndPoints } from "src/app/core/model/geometry-model/geometric-elements-builder";
import { Point } from "src/app/core/model/geometry-model/point.model";
import { Segment } from "src/app/core/model/geometry-model/segment.model";
import { SvgTransform } from "src/app/core/model/svg-model/svg-transform.model";
import { BpSvgEquipmentCatalogGroup } from "../../../../../blueprint-viewer-side-panel/subitems/equipments/model/bp-svg-equipment-catalog-group";
import { BpSvgBoundingBox } from "../../../../../bp-svg-core-model/bp-svg-bounding-box";
import { BpSvgPath } from "../../../../../bp-svg-core-model/bp-svg-path";
import { BpSvgUse } from "../../../../../bp-svg-core-model/bp-svg-use";
import { EntitiesSelectionSet } from "../../../../itself/model/interaction/entities-selection-set";
import { UpdateGizmo } from "../../update-gizmo.model";
import { EquipmentSelectionOverlay } from "./equipment-selection-overlay";
import { EquipmentSelectionRotationLabel } from "./equipment-selection-rotation-label";
import { EquipmentTransient } from "./equipment-transient";
import { Grips } from "src/app/components-lib/svg/grips/grips";

export class EquipmentUpdateGizmoVM implements UpdateGizmo {
    selectionSet: EntitiesSelectionSet = new EntitiesSelectionSet();

    isEditable: boolean = false;

    selectionOverlay: EquipmentSelectionOverlay = new EquipmentSelectionOverlay();
    selectionOverlayRotationLabel: EquipmentSelectionRotationLabel = new EquipmentSelectionRotationLabel()
    transform: SvgTransform | undefined;
    targetGrips: Grips = new Grips("purple", "purple");
    selectedContours: BpSvgPath[] = [];
    transientPosition: EquipmentTransient | undefined;
    debugSegments: Segment[] = [];
    debugPoints: Point[] = [];
  
    stroke: string = "#00cc7a";
    fill: string = "#00ff99";
  
    transientShape: BpSvgEquipmentCatalogGroup | undefined;
    inserting: boolean = false;
  
    constructor(editable: boolean) {
        this.isEditable = editable
    }

    show(selectionSet: EntitiesSelectionSet): void {
        this.selectionSet = selectionSet;
        this.update(selectionSet.items as BpSvgUse[])
    }
  
    hide(): void {
  
    }
  
    showTransient(shape: BpSvgEquipmentCatalogGroup): void {
        this.transientShape = shape;
        this.inserting = true;
    }
  
    hideTransient(): void {
        this.transientShape = undefined;
        this.inserting = false;
    }
  
    moveTransientShape(hitPoint: Point): void {
        if (!this.transientShape) return;
        this.transientShape.transform = new SvgTransform({ translate: hitPoint, rotationCenter: null, rotationAngle: 0});
    }
  
    selectionSetChange(): void {
        this.update(this.selectionSet.items as BpSvgUse[]);
    }
  
    /**
     * Actualise les contours transformés ainsi que le transform de l'overlay du jeu de sélection
     * @param selectedUses Jeu de sélection
     */
    update(selectedUses: BpSvgUse[]): void {
        this.updateSelectedContours(selectedUses);
        this.updateGrips(selectedUses);
        this.selectionOverlay.update(selectedUses);
    }
  
    updateSelectedContours(selectedUses: BpSvgUse[]): void {
        if (selectedUses.length > 0) {
            this.getSelectedContours(selectedUses);
        }
        else {
            this.selectedContours.splice(0);
        }
    }
  
    updateGrips(selectedUses: BpSvgUse[]): void {
        if (selectedUses.length > 0) {
            var firstOne = selectedUses[0];
            if (selectedUses.length === 1 && firstOne.def) {
                const contourPath = firstOne.def.defsGeometry[0] as BpSvgPath;
                // La géométrie du contour est un tableau d'éléments géométriques élémentaires, segments, arcs
                const contourPathGeometry = contourPath.geometry;
                const contourPathGeometryPoints = getEndPoints(contourPathGeometry);
                this.selectionOverlay.grips.resetPoints(contourPathGeometryPoints);
            }
            else {
                // Pour permettre le déplacement d'un groupe d'éléments basé sur les contours les plus extérieurs des éléments
                // il faut générer la plus petite bbox tournée et non la plus petite bbox droite
                const tmp = this.getBlobalBbx(selectedUses);
                if (tmp) {
                    const bbox = new BpSvgBoundingBox(0, 0, tmp.maxX - tmp.minX, tmp.maxY - tmp.minY);
        
                    this.selectionOverlay.grips.resetPoints([
                    bbox.bottomLeft(),
                    bbox.bottom().midPoint(),
                    bbox.topLeft(),
                    bbox.top().midPoint(),
                    bbox.bottomRight(),
                    bbox.right().midPoint(),
                    bbox.topRight(),
                    bbox.left().midPoint()
                    ]);
                }
            }
        }
        else {
            this.selectionOverlay.grips.clear();
        }
    }
  
    getSelectedContours(selectedUses: BpSvgUse[]): void {
        const result: BpSvgPath[] = [];
  
        selectedUses.forEach(element => {
        if (element.def && element.transform) {
            const contourPath = element.def.defsGeometry[0] as BpSvgPath;
            const selectedContourPath = contourPath.clone();
            selectedContourPath.transform = element.transform.clone();
            result.push(selectedContourPath);
        }
    });
  
      this.selectedContours = result;
    }
  
    getBlobalBbx(selectedUses: BpSvgUse[]): BpSvgBoundingBox | null {
        let result = selectedUses[0].transformedBbox();
        if (result) {
            for (let i = 1; i < selectedUses.length; i++) {
                const use = selectedUses[i];
                const bbox = use.transformedBbox();
                if (bbox) {
                    result.add(bbox);
                }
            }
        }
        return result;
    }
}