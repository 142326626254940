import { Point } from "src/app/core/model/geometry-model/point.model";
import { SvgEntityTypesEnum } from "src/app/core/model/svg-model/svg-entity-type-enum";
import { DxfLayerDTO } from "src/app/core/services/backend-services/dto/dxf-layer-dto";
import { EntityInteraction } from "./entity-interaction";
import { IEntityInteraction } from "./i-entity-interaction";
import { InteractionCommand } from "./interaction-command";
import { SelectionInteraction } from "./selection-interaction";
import { TextGizmo } from "../gizmos/update/text-gizmo";
import { DxfSvgText } from "../svg/dxf-svg-text";
import { logError } from "src/app/core/services/logging-service";

export class TextInteraction extends EntityInteraction implements IEntityInteraction<DxfSvgText> {
    entityType: SvgEntityTypesEnum = SvgEntityTypesEnum.text;
    layers: DxfLayerDTO[] = [];
    selectionSet: DxfSvgText[] = [];
    selectionInteraction: SelectionInteraction | undefined;
    currentCommand: InteractionCommand;
    updateGizmo: TextGizmo;

    labelInputValue: string | undefined;
    labelBbox: DOMRect | undefined;

    constructor(command: InteractionCommand, layers: DxfLayerDTO[], gizmo: TextGizmo) {
        super();
        this.currentCommand = command;
        this.layers = layers;
        this.updateGizmo = gizmo;
    }

    beginUpdate(text: string, x: number, y: number, width: number, height: number): void {
        this.labelInputValue = text;
        this.labelBbox = new DOMRect(x, y, width, height);
    }

    updateLabelBbox(x: number, y: number, width: number, height: number): void {
        this.labelBbox = new DOMRect(x, y, width, height);
    }
    
    labelUpdateEnded?: (t: DxfSvgText) => void;
    async focusOut(): Promise<void> {
        const e = this.updateGizmo.single;
        if (e) {
            e.text = this.labelInputValue;
            if (this.labelUpdateEnded) {
                this.labelUpdateEnded(e);
            } else {
                logError("TextInteraction.labelUpdateEnded n'est pas écouté");
            }
        }
    }

    async inputKeyDown(e: KeyboardEvent): Promise<void> {
        if (e.code.toLowerCase() === "enter" || e.code.toLowerCase() === "numpadenter") {
            await this.focusOut();
        }
    }

    activate(entities: DxfSvgText[], toRemove: boolean): Promise<void> {
        throw new Error("Method not implemented.");
    }
    
    deactivate(): void {
        throw new Error("Method not implemented.");
    }
    
    abort(): void {
        throw new Error("Method not implemented.");
    }
    
    deleteSelectedItems(): Promise<void> {
        throw new Error("Method not implemented.");
    }
    
    beginInsert(args: any): void {
        throw new Error("Method not implemented.");
    }
    
    selectionDeleted?: (() => void) | undefined;
    raiseSelectionDeleted(): void {
        throw new Error("Method not implemented.");
    }
    
    async mouseDown(e: MouseEvent, hitPoint: Point): Promise<boolean> {
        throw new Error("Method not implemented.");
    }
    
    async mouseMove(e: MouseEvent, hitPoint: Point): Promise<void> {
        if (this.currentCommand.isEntityTranslationCommand() && this.currentCommand.initialSvgMousePosition) {
            const delta = hitPoint.minus(this.currentCommand.initialSvgMousePosition);
            this.updateGizmo.translateTexts(delta);
        }
    }
    
    async mouseUp(e: MouseEvent, hitPoint: Point | undefined): Promise<void> {
        if (this.currentCommand.isEntityTranslationCommand()) {
            if (hitPoint && this.currentCommand.gripClicked) {
                const delta = hitPoint.minus(this.currentCommand.gripClicked.point);
                this.updateGizmo.translateTexts(delta);
            }
            this.updateGizmo.endTranslate();       
            this.updateGizmo.clearCurrent();   
            return;         
        }
    }
    
    keyDown(e: KeyboardEvent): Promise<void> {
        throw new Error("Method not implemented.");
    }

}