@if (model && model.canCreateTask) {
<div id="insert-panel">
  <div id="creation-form-header">
    <p>Ajouter un nouvel élément</p>
  </div>

  <mat-form-field #taskType class="select-wrapper" appearance="outline">
    <mat-label>Type d'élément à ajouter</mat-label>
    <mat-select
      class="type-select"
      [(value)]="model.selectedTaskType"
      [compareWith]="model.taskTypeCompare"
      (selectionChange)="model.onSelectedTaskTypeChange()"
    >
      @for (tt of model.taskTypes; track tt) {
      <mat-option [value]="tt">{{ tt.taTyViDisplayName }}</mat-option>
      }
    </mat-select>
  </mat-form-field>
  
  @if (model.selectedTaskType?.taTyId === model.TaskTypeEnum.Task) {
    <mat-checkbox id="startNewProject" [(ngModel)]="model.startNewProject" color="primary"
      (ngModelChange)="model.onStartNewProjectChange()">
      Initier un nouveau projet
    </mat-checkbox>
    <mat-checkbox id="fromTemplate" [(ngModel)]="model.fromTemplate" color="primary"
      (ngModelChange)="model.onFromTemplateChange()">
      Utiliser un modèle
    </mat-checkbox>
  } 
  @if (model.selectedTaskType?.taTyId !== model.TaskTypeEnum.Milestone) { 
    @if (model.selectedTaskType?.taTyId === model.TaskTypeEnum.Project || model.fromTemplate) {
    <mat-form-field class="select-wrapper" appearance="outline">
      <mat-label>Modèle à utiliser</mat-label>
      <mat-select
        #taskTemplate
        class="template-select"
        [(value)]="model.selectedTemplate"
        [compareWith]="model.selectedTemplateCompare"
        (selectionChange)="model.onSelectedTemplateChange()"
      >
        @for (tp of model.templates; track tp) {
        <mat-option [value]="tp">{{ tp.name }}</mat-option>
        }
      </mat-select>
    </mat-form-field>
    } @else {
    <mat-form-field class="select-wrapper" appearance="outline">
      <mat-label>Type d'action à planifier</mat-label>
      <mat-select
        [(value)]="model.selectedBusinessType"
        (selectionChange)="model.onSelectedBusinessTypeChange()"
        [compareWith]="model.selectedTemplateCompare"
      >
        @for (bt of model.businessTypes; track bt) {
        <mat-option [value]="bt">{{ bt.name }}</mat-option>
        }
      </mat-select>
    </mat-form-field>

    @if (model.selectedBusinessTypeIsFacilityType()) {
    <mat-form-field class="select-wrapper" appearance="outline">
      <mat-label>Prestation</mat-label>
      <mat-select
        [(value)]="model.selectedFacilityCompanyTask"
        [compareWith]="model.selectedTemplateCompare"
        (selectionChange)="model.onSelectecFacillityTaskChange()"
      >
        @for (fc of model.facilityCompaniesTasks; track fc) {

        <span class="facility-company">{{ fc.name }}</span>
        @for (jt of fc.tasks; track jt) {
        <mat-option class="facility_task" [value]="jt">{{ jt.name }}</mat-option>
        } }
      </mat-select>
    </mat-form-field>
    }

    <!-- TODO : régler le problème de réinitialisation de la sélection au changement de recherche -->
    @if (model.selectedBusinessTypeIsSpacePlanningType()) {

    <mat-form-field #floorSelect class="select-wrapper" appearance="outline">
      <mat-label>Etage concerné par l'étude</mat-label>
      <input
        #floorInput
        type="text"
        class="floorSearch"
        matInput
        (input)="model.onRealEstateSearchChange()"
        (focus)="model.onRealEstateSearchChange()"
        [(ngModel)]="model.realEstateFilterValue"
        [matAutocomplete]="autoGroup"
      />
      <mat-autocomplete
        #autoGroup="matAutocomplete"
        requireSelection="true"
        (optionSelected)="model.onRealEstateSelectionChange($event)"
      >
        @for (b of model.filteredRealEstate; track b) {
        <mat-optgroup [label]="b.name">
          @for (f of b.children; track f) {
          <mat-option [value]="f">
            {{ f.name }}
          </mat-option>
          }
        </mat-optgroup>
        }
      </mat-autocomplete>
    </mat-form-field>

    <mat-form-field class="select-wrapper" appearance="outline">
      <mat-label>Calques modifiables dans l'étude</mat-label>
      <mat-select
        multiple
        [(value)]="model.selectedLayers"
        [compareWith]="model.selectedTemplateCompare"
        (selectionChange)="model.onSelectecLayersChange()"
      >
        @for (c of model.layersCategories; track c) {
        <mat-optgroup [label]="c.name">
          @for (l of c.children; track l) {
          <mat-option [value]="l">
            {{ l.name }}
          </mat-option>
          }
        </mat-optgroup>
        }
      </mat-select>
    </mat-form-field>

    } 
  } 
}

  <button
    mat-button
    color="primary"
    (click)="model.addTaskButtonClick($event)"
    [disabled]="!model.canCreate"
  >
    Ajouter l'élément
  </button>
</div>
}
