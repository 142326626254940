import { AppMenuLicencingEnum } from "src/app/core/model/data-model/enums/app-menu-licencing-enum";

export class ClientGrantsVM {
    hasOccupancy: boolean;
    hasMobility: boolean;
    hasStrategy: boolean;
    hasMaintenance: boolean;
    isPlayground: boolean;

    licencing: number[] = [AppMenuLicencingEnum.none];

    constructor(hasOccupancy: boolean, hasMobility: boolean, hasStrategy: boolean, hasMaintenance: boolean, isPlayground: boolean) {
        this.hasOccupancy = hasOccupancy;
        this.hasMobility = hasMobility;
        this.hasStrategy = hasStrategy;
        this.hasMaintenance = hasMaintenance;
        this.isPlayground = isPlayground;

        if (hasOccupancy) this.licencing.push(AppMenuLicencingEnum.occupancy);
        if (hasMobility) this.licencing.push(AppMenuLicencingEnum.mobility);
        if (hasStrategy) this.licencing.push(AppMenuLicencingEnum.strategy);
        if (hasMaintenance) this.licencing.push(AppMenuLicencingEnum.maintenance);
    }
}