import { DefinedModelBase } from '../defined-model-base';
import { DisplayThemeItemTable } from './../../db-model/tables/display-theme-item-table';

export class DisplayThemeItem extends DefinedModelBase {
    diThItDisplayThemeId!: number;
    diThItDisplayThemableId!: number;

    constructor(dtoData: any) {
        super(dtoData, [DisplayThemeItemTable]);
    }
}
  