import { TaskView } from "src/app/core/model/data-model/views/task-view";
import { MobilityProjectTreeNode } from "./mobility-project-tree-node";
import { TaskTypeEnum } from "src/app/core/model/data-model/enums/task-type-enum";
import { DateUtils } from "src/app/core/model/static-functions/date-utils";
import { MobilityTemplateTreeNode } from "./mobility-template-tree-node";

export class MobilityProjectsTreeBuilder {
    static loadProjectsTreeNodes<T extends MobilityProjectTreeNode>(nodeType: string, tasks: TaskView[]): T[] {
        const tmp: T[] = [];

        //const rootTasks = tasks.filter(x=> x.taDepth === 1);
        const rootTasks = tasks.filter(x=> x.taParentId == null).sort((a, b) => a.taStartDate.getTime() - b.taStartDate.getTime());
        rootTasks.forEach(t => {
            const taskNode = this.GetNode<T>(nodeType, t);
            taskNode.setChildren(this.loadChildrenTasks(nodeType, tasks, t));
            tmp.push(taskNode);
        });

        return tmp;
    }

    private static loadChildrenTasks<T extends MobilityProjectTreeNode>(nodeType: string, tasks: TaskView[], t: TaskView): T[] {
        const result: T[] = [];

        //const children = tasks.filter(x=> x.taParentId === t.taId);
        const children = tasks.filter(x=> x.taParentId === t.taId && x.taParentId != null).sort((a, b) => a.taStartDate.getTime() - b.taStartDate.getTime() || a.taDepth - b.taDepth);
        children.forEach(c => {
            const type = c.taTypeId === TaskTypeEnum.Project ? TaskTypeEnum.Project : TaskTypeEnum.Task;
            const taskNode = this.GetNode<T>(nodeType, c);
            taskNode.setChildren(this.loadChildrenTasks(nodeType, tasks, c));
            result.push(taskNode);
        });

        return result;
    }

    private static GetNode<T>(nodeType: string, t: TaskView): T {
        if (nodeType === MobilityTemplateTreeNode.name) {
            return new MobilityTemplateTreeNode(t) as T;
        }
        return new MobilityProjectTreeNode(t) as T;
    }

    static getNodeName(task: TaskView): string {
        let name = task.taName;
        const type = task.taTypeId === TaskTypeEnum.Project ? TaskTypeEnum.Project : TaskTypeEnum.Task;
        if (type === TaskTypeEnum.Task) name = task.businessTypeView.taBuTyDisplayName + " - " + name;
        return name;
    }

    static getDateRange(tasks: TaskView[]): [Date, number] {
        if (tasks.length === 0) return [DateUtils.today(), 1];

        const firstProject = tasks[0];
        const sd = firstProject.taStartDate;
        const sdt = sd.getTime();
        let ed =  1;

        // Sachant que les projets sont déjà triés par date de début
        tasks.forEach(t => {
            const timeDelta = t.taStartDate.getTime() - sdt;
            const daysDelta = timeDelta / (1000 * 3600 * 24);
            if (ed < daysDelta) ed = daysDelta;
        });

        const lastProject = tasks[tasks.length -1];
        ed = ed + lastProject.taDuration;

        ed = Math.ceil(ed);

        return [sd, ed];
    }

    
    static flatNodes(tree: MobilityProjectTreeNode[]): MobilityProjectTreeNode[] {
        const tmp: MobilityProjectTreeNode[] = [];
        tree.forEach(r => {
            tmp.push(r);
            tmp.push(...this.childrenTaskNodes(r));
        });
        return tmp;
    }

    private static childrenTaskNodes(parent: MobilityProjectTreeNode): MobilityProjectTreeNode[] {
        const result: MobilityProjectTreeNode[] = [];
        parent.children.forEach(c => {
                result.push(c);
                result.push(...this.childrenTaskNodes(c));
        });
        return result;
    }

}