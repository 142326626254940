import { DynTableVM } from "src/app/components-lib/dyn-grid/model/dyn-table-vm";
import { DynTableVMBuilder } from "src/app/components-lib/dyn-grid/model/dyn-table-vm-builder";
import { FieldTypeEnum } from "src/app/components-lib/shared-model/field-type-enum";
import { readableUUID } from "src/app/core/events/event-listener-uuid";
import { AppMenuRouteEnum } from "src/app/core/model/data-model/enums/app-menu-route-enum";
import { DyntService } from "src/app/core/services/backend-services/dynt-service";
import { PaginationService } from "src/app/core/services/backend-services/pagination-service";
import { PageModel } from "src/app/ui/main/model/page-model";
import Container from "typedi";

export class WorkplacesAdminVM extends PageModel {
    adminTable: DynTableVM | undefined;
    fieldTypeEnum = FieldTypeEnum;

    private constructor() {
        super(AppMenuRouteEnum.layout_realestate_workplacetypes, 0, readableUUID(WorkplacesAdminVM.name));
    }

    static async newAsync(): Promise<WorkplacesAdminVM> {
        const result = new WorkplacesAdminVM();
        result.adminTable = await result.loadAdminData("workplace_type_dynview");
        return result;
    }

    async loadAdminData(tableName: string): Promise<DynTableVM> {
        const s = Container.get(DyntService);
        const viewSet = await s.contextedDataSet(tableName);
        const p = Container.get(PaginationService);
        const paginator = await p.getPaginator(tableName);
        const adminTable = DynTableVMBuilder.getVM(viewSet, tableName, paginator);
        adminTable.documentName = "WorkplaceTypes";
        adminTable.documentTitle = "Typologie des positions de travail";
        return adminTable;
    }
}