import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TextGizmo } from '../../../../model/gizmos/update/text-gizmo';

@Component({
  selector: 'g[xc-text-gizmo]',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './text-gizmo.component.svg',
  styleUrls: ['./text-gizmo.component.scss']
})
export class TextGizmoComponent {
  @Input() model: TextGizmo | undefined;
}
