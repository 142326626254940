import { EquipmentCatalog } from "src/app/core/model/data-model/tables/equipment-catalog";
import { EquipmentCategory } from "src/app/core/model/data-model/tables/equipment-category";
import { FloorCatalog } from "src/app/core/model/data-model/tables/floor-catalog";
import { ProviderCompany } from "src/app/core/model/data-model/tables/provider-company";
import { EquipmentCatalogViewSet } from "src/app/core/model/data-model/view-set/equipment-catalog-view-set";
import { EquipmentCategoryView } from "src/app/core/model/data-model/views/equipment-category-view";
import { BpSvgBoundingBox } from "../../../../bp-svg-core-model/bp-svg-bounding-box";

export class EquipmentFormCatalogView {
    equipmentCatalog: EquipmentCatalog;
    providerCompany: ProviderCompany;
    floorCatalog: FloorCatalog;
    equipmentCategoryView: EquipmentCategoryView;
    rootId: number;
    flatTreeDisplayName: string;
    width: number = 0;
    height: number = 0;

    constructor(e: EquipmentCatalogViewSet) {
            this.equipmentCatalog = e.equipmentCatalog;
            this.providerCompany = e.providerCompany;
            this.floorCatalog = e.floorCatalog;
            this.equipmentCategoryView = e.equipmentCategoryView;
            this.rootId = e.equipmentCategoryView.eqCaRootId;
            this.flatTreeDisplayName = e.equipmentCategoryView.eqCaFlatTreeDisplayName;

            const bbox = BpSvgBoundingBox.getFromString(this.floorCatalog.flCgSvgBoundingBox);
            if (bbox != null) {
                this.width = bbox.width();
                this.height = bbox.height();
            }
    }
}