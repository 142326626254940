@if (model) {
<div>
    <input 
        type="number"
        minlength="model.minLength" 
        maxlength="model.maxlength"
        [(ngModel)]="model.value"
        [disabled]="model.definition.dbCoIsReadOnly"
        (focusout)="model.focusOut()" (keydown)="model.keyDown($event)">
</div>
}

