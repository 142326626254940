export class InventoryTreeNode {
    children: InventoryTreeNode[] = [];
    selected: boolean = false;
    childrenPartiallyVisible: boolean = false;
    parentNode: InventoryTreeNode | undefined;
 
    parentId: number;
    id: number;
    name: string;
    layerId: number | null;
    concatName: string;
    canInsertChild: boolean;
    isHovering?: boolean;
    layerReadGrantId: number | null;
    layerUpdateGrantId: number | null;

    constructor(
        parentId: number, 
        id: number, 
        name: string, 
        concatName: string, 
        layerId: number | null = null, 
        canInsertChild: boolean = false, 
        layerReadGrantId: number | null = null, 
        layerUpdateGrantId: number | null = null) 
        {
        this.parentId = parentId;
        this.id = id;
        this.name = name;
        this.concatName = concatName;
        this.layerId = layerId;
        this.canInsertChild = canInsertChild;
        this.layerReadGrantId = layerReadGrantId;
        this.layerUpdateGrantId = layerUpdateGrantId;
    }
  }