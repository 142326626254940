import { Point } from "src/app/core/model/geometry-model/point.model";
import { SvgEntityPointStyleEnum } from "./svg-entity-point-style-enum";

export class SvgEntityPoint extends Point {
    parentId: string | number | undefined;
    angle: number = 0;
    gripStyle: SvgEntityPointStyleEnum;

    constructor(style: SvgEntityPointStyleEnum, p: Point = Point.origin(), parentId?: string | number, angle: number = 0) {
        super(p.x, p.y);
        this.gripStyle = style;
        this.parentId = parentId;
        this.angle = angle;
    }

    static toStyle(points: Point[], style: SvgEntityPointStyleEnum, parentId?: string | number, angle: number = 0): SvgEntityPoint[] {
        const result: SvgEntityPoint[] = [];
        points.forEach(p => {
            result.push(new SvgEntityPoint(style, p, parentId, angle));
        });
        return result;
    }

    static fromPoint(point: Point, style: SvgEntityPointStyleEnum, parentId?: string | number, angle: number = 0): SvgEntityPoint {
        return new SvgEntityPoint(style, point, parentId, angle);
    }
}