import { Component, Input } from '@angular/core';
import { GrantedDomainsVM } from '../../model/granted-domains-vm';
import { MVVMEventDestroyLifeCycle } from 'src/app/components-lib/shared-model/mvvm-events-destroy-lifecycle';
import { DynTableComponent } from 'src/app/components-lib/dyn-table/dyn-table.component';

@Component({
  selector: 'xc-domains',
  standalone: true,
  imports: [DynTableComponent],
  templateUrl: './domains.component.html',
  styleUrl: './domains.component.scss'
})
export class DomainsComponent extends MVVMEventDestroyLifeCycle {
  @Input() override model: GrantedDomainsVM | undefined;
}
