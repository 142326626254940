import { Point } from "src/app/core/model/geometry-model/point.model";
import { Segment } from "src/app/core/model/geometry-model/segment.model";
import { SvgTransform } from "src/app/core/model/svg-model/svg-transform.model";
import { BpSvgBoundingBox } from "../../../../../bp-svg-core-model/bp-svg-bounding-box";
import { EquipmentSelectionOverlay } from "./equipment-selection-overlay";
import { EquipmentSelectionSideHandle } from "./equipment-selection-side-handle";

export class EquipmentSelectionSideHandles {
    static sideHandle1Id: string = "sideDragHandle1";
    static sideHandle2Id: string = "sideDragHandle2";
    static sideHandle3Id: string = "sideDragHandle3";
    static sideHandle4Id: string = "sideDragHandle4";

    items: EquipmentSelectionSideHandle[] = [];
    topSegment: Segment | undefined;
    bottomSegment: Segment | undefined;
    leftSegment: Segment | undefined;
    rightSegment: Segment | undefined;
    handlesAreTranslateRotate: boolean = true;
    handlesSize: number = 0.1;
    currentOverlayBbox: BpSvgBoundingBox = BpSvgBoundingBox.default();

    constructor() {
        const h1 = new EquipmentSelectionSideHandle(EquipmentSelectionSideHandles.sideHandle1Id, "h1");
        const h2 = new EquipmentSelectionSideHandle(EquipmentSelectionSideHandles.sideHandle2Id, "h2");
        const h3 = new EquipmentSelectionSideHandle(EquipmentSelectionSideHandles.sideHandle3Id, "h3");
        const h4 = new EquipmentSelectionSideHandle(EquipmentSelectionSideHandles.sideHandle4Id, "h4");
        this.items.push(h1);
        this.items.push(h2);
        this.items.push(h3);
        this.items.push(h4);

        this.update(BpSvgBoundingBox.default());
    }

    switchShape(): void {
        this.handlesAreTranslateRotate = !this.handlesAreTranslateRotate;
        this.update(this.currentOverlayBbox);
    }

    update(overlayBbox: BpSvgBoundingBox): void {
        this.currentOverlayBbox = overlayBbox;

        this.items[0].path = this.h1Path(overlayBbox);
        this.items[1].path = this.h2Path(overlayBbox);
        this.items[2].path = this.h3Path(overlayBbox);
        this.items[3].path = this.h4Path(overlayBbox);

        this.topSegment = overlayBbox.top();
        this.rightSegment = overlayBbox.right();
        this.bottomSegment = overlayBbox.bottom();
        this.leftSegment = overlayBbox.left();
    }

    h1Path(overlayBbox: BpSvgBoundingBox): string {
        const bboxHalfWidth = overlayBbox.width() / 2;
        const startX = overlayBbox.minX + bboxHalfWidth - this.handlesSize;
        const startY = overlayBbox.minY;
        const frontX = overlayBbox.minX + bboxHalfWidth;
        const frontY = overlayBbox.minY - this.handlesSize;
        const endX = overlayBbox.minX + bboxHalfWidth + this.handlesSize;
        const endY = overlayBbox.minY;

        if (!this.handlesAreTranslateRotate)
        {
            return this.getSharpArrowHeadPathStatement(startX, startY, frontX, frontY, endX, endY);
        }
        else
        {
            const r = this.handlesSize / 2;
            return this.getRoundedArrowHeadPathStatement(startX, startY, r, endX, endY, 1);
        }
    }

    h2Path(overlayBbox: BpSvgBoundingBox): string {
        const bboxHalfHeight = overlayBbox.height() / 2;
        const startX = overlayBbox.minX;
        const startY = overlayBbox.minY + bboxHalfHeight - this.handlesSize;
        const frontX = overlayBbox.minX - this.handlesSize;
        const frontY = overlayBbox.minY + bboxHalfHeight;
        const endX = overlayBbox.minX;
        const endY = overlayBbox.minY + bboxHalfHeight + this.handlesSize;

        if (!this.handlesAreTranslateRotate)
        {
            return this.getSharpArrowHeadPathStatement(startX, startY, frontX, frontY, endX, endY);
        }
        else
        {
            const r = this.handlesSize / 2;
            return this.getRoundedArrowHeadPathStatement(startX, startY, r, endX, endY, 0);
        }
    }

    h3Path(overlayBbox: BpSvgBoundingBox): string {
        const bboxHalfHeight = overlayBbox.height() / 2;
        const startX = overlayBbox.minX + overlayBbox.width();
        const startY = overlayBbox.minY + bboxHalfHeight - this.handlesSize;
        const frontX = overlayBbox.minX + overlayBbox.width() + this.handlesSize;
        const frontY = overlayBbox.minY + bboxHalfHeight;
        const endX = overlayBbox.minX + overlayBbox.width();
        const endY = overlayBbox.minY + bboxHalfHeight + this.handlesSize;

        if (!this.handlesAreTranslateRotate)
        {
            return this.getSharpArrowHeadPathStatement(startX, startY, frontX, frontY, endX, endY);
        }
        else
        {
            const r = this.handlesSize / 2;
            return this.getRoundedArrowHeadPathStatement(startX, startY, r, endX, endY, 1);
        }
    }

    h4Path(overlayBbox: BpSvgBoundingBox): string {
        const bboxHalfWidth = overlayBbox.width() / 2;
        var startX = overlayBbox.minX + bboxHalfWidth - this.handlesSize;
        var startY = overlayBbox.minY + overlayBbox.height();
        var frontX = overlayBbox.minX + bboxHalfWidth;
        var frontY = overlayBbox.minY + overlayBbox.height() + this.handlesSize;
        var endX = overlayBbox.minX + bboxHalfWidth + this.handlesSize;
        var endY = overlayBbox.minY + overlayBbox.height();

        if (!this.handlesAreTranslateRotate)
        {
            return this.getSharpArrowHeadPathStatement(startX, startY, frontX, frontY, endX, endY);
        }
        else
        {
            const r = this.handlesSize / 2;
            return this.getRoundedArrowHeadPathStatement(startX, startY, r, endX, endY, 0);
        }
    }

    getSharpArrowHeadPathStatement(startX: number, startY: number, frontX: number, frontY: number, endX: number, endY: number): string {
        return "M" + startX + " " + startY + " " + frontX + " " + frontY + " " + endX + " " + endY + " Z";
    }

    getRoundedArrowHeadPathStatement(startX: number, startY: number, r: number, endX: number, endY: number, direction: number): string {
        return "M" + startX + " " + startY + " A" + r + " " + r + " 0 0 " + direction + " " + endX + " " + endY + " Z";
    }

    getSideMidPoint(handleId: string, transform: SvgTransform): Point {
        const bbox = this.currentOverlayBbox;
        switch(handleId)
        {
            case EquipmentSelectionSideHandles.sideHandle1Id:
                return transform.transformedPoint(bbox.top().midPoint());
            case EquipmentSelectionSideHandles.sideHandle2Id:
                return transform.transformedPoint(bbox.left().midPoint());
            case EquipmentSelectionSideHandles.sideHandle3Id:
                return transform.transformedPoint(bbox.right().midPoint());
            case EquipmentSelectionSideHandles.sideHandle4Id:
                return transform.transformedPoint(bbox.bottom().midPoint());
            default:
                return Point.origin();
        }
    }

    getSideRadiusSegment(handleId: string, transform: SvgTransform): Segment {
        const bbox = this.currentOverlayBbox;
        var bboxCenter = transform.transformedPoint(bbox.center());
        return new Segment(bboxCenter, this.getSideMidPoint(handleId, transform));
    }

    getTransformedSide(handleId: string, transform: SvgTransform): Segment {
        const bbox = this.currentOverlayBbox;
        switch(handleId)
        {
            case EquipmentSelectionSideHandles.sideHandle1Id:
                return transform.transformedSegment(bbox.top());
            case EquipmentSelectionSideHandles.sideHandle2Id:
                return transform.transformedSegment(bbox.left());
            case EquipmentSelectionSideHandles.sideHandle3Id:
                return transform.transformedSegment(bbox.right());
            case EquipmentSelectionSideHandles.sideHandle4Id:
                return transform.transformedSegment(bbox.bottom());
            default:
                return Segment.null();
        }
    }
    /**
     * Retourne la pointe de la poignée de translation (ou le centre de l'arc si la poignée est arrondie)
     * @param handleId identifiant de la poignée de translation
     * @param selectionOverlay overlay de la sélection courante
     * @returns 
     */
    getHandleEdge(handleId: string, selectionOverlay: EquipmentSelectionOverlay): Point
    {
        const bbox = this.currentOverlayBbox;
        const transform = selectionOverlay.transform;
        if (! transform) return Point.origin();
        switch (handleId)
        {
            case EquipmentSelectionSideHandles.sideHandle1Id:
                return transform.transformedPoint(bbox.top().getOffsetMidPoint(this.handlesSize));
            case EquipmentSelectionSideHandles.sideHandle2Id:
                return transform.transformedPoint(bbox.left().getOffsetMidPoint(this.handlesSize));
            case EquipmentSelectionSideHandles.sideHandle3Id:
                return transform.transformedPoint(bbox.right().getOffsetMidPoint(this.handlesSize));
            case EquipmentSelectionSideHandles.sideHandle4Id:
                return transform.transformedPoint(bbox.bottom().getOffsetMidPoint(this.handlesSize));
            default:
                return Point.origin();
        }
    }

    /**
     * Retourne la distance entre le centre et le milieu du côté de l'overlay bbox
     * @param handleId identifiant de la poignée de translation
     * @param overlayBbox overlay de la sélection courante
     * @returns 
     */
    getbboxCenterDistanceToSideMiddle(handleId: string, selectionOverlay: EquipmentSelectionOverlay): number
    {
        if (!selectionOverlay.transform) return 0;
        var bboxCenter = selectionOverlay.transformedBboxCenter();
        if (!bboxCenter) return 0;
        var sideMiddle = this.getSideMidPoint(handleId, selectionOverlay.transform);
        return bboxCenter.distanceTo(sideMiddle);
    }

}
