import { EquipmentRemovalCaseView } from 'src/app/core/model/data-model/views/equipment-removal-case-view';
import { DtoNamesEnum } from './../../services/backend-services/dto/definitions/dto-names-enum';
import { TaskLink } from './tables/task-link';
import { TaskBusinessTypeView } from './views/task-business-type-view';
import { TaskBusinessType } from './tables/task-business-type';
import { AppGrantGroupView } from './views/app-grant-group-view';
import { ZTableView } from './views/z-table-view';
import { FloorCatalogImportItemView } from 'src/app/core/model/data-model/views/floor-catalog-import-item-view';
import { FloorDataImportLog } from './tables/floor-data-import-log';
import { FloorDataImportItem } from './tables/floor-data-import-item';
import { FloorDataImport } from './tables/floor-data-import';
import { FloorCatalogImportLog } from 'src/app/core/model/data-model/tables/floor-catalog-import-log';
import { PolicyCity } from './policy/tables/policy-city';
import { CountryView } from './views/country-view';
import { ContinentView } from './views/continent-view';
import { CityView } from './views/city-view';
import { ZColumnConstraintView } from './views/z-column-constraint-view';
import { ZConstraintType } from './tables/z-constraint-type';
import { ZConstraintMode } from './tables/z-constraint-mode';
import { ZColumnConstraint } from './tables/z-column-constraint';
import { ZDbView } from './tables/z-db-view';
import { ZColumnView } from './views/z-column-view';
import { ZScope } from './tables/z-scope';
import { ContextUIDynViewSet } from './sets/context-ui-view-set';
import { ZContextUITableUser } from './tables/z-context-ui-table-user';
import { ZContextUITable } from './../db-model/tables/z-context-ui-table';
import { ZDbColumn } from './tables/z-db-column';
import { LeaseView } from './views/lease-view';
import { LeaseStatusView } from './views/lease-status-view';
import { LeaseStatus } from './tables/lease-status';
import { TaskFloor } from './tables/task-floor';
import { FloorLayoutView } from './views/floor-layout-view';
import { BuildingAttributionView } from './views/building-attribution-view';
import { BuildingView } from 'src/app/core/model/data-model/views/building-view';
import { SiteAnatomyView } from './views/site-anatomy-view';
import { SiteEquipmentProviderView } from './views/site-equipment-provider-view';
import { SiteAttributionView } from './views/site-attribution-view';
import { SiteActivityStatusView } from './views/site-activity-status-view';
import { SiteView } from './views/site-view';
import { ViewsNames } from 'src/app/core/model/db-model/views-names-enum';
import { TablesSetsEnum } from './tables-sets-enum';
import { Country } from './tables/country';
import { Continent } from './tables/continent';
import { City } from './tables/city';
import { PeopleLocation } from './tables/people-location';
import { TaskValidation } from './tables/task-validation';
import { TaskStatus } from './tables/task-status';
import { TaskFunction } from './tables/task-function';
import { TaskContributorDirectory } from './tables/task-contributor-directory';
import { TaskChangeLog } from './tables/task-change-log';
import { RoomSharing } from './tables/room-sharing';
import { RoomAttributionType } from './tables/room-attribution-type';
import { RoomAllocation } from './tables/room-allocation';
import { RoomActivityStatusType } from './tables/room-activity-status-type';
import { ProviderCompany } from './tables/provider-company';
import { Perimeter } from './tables/perimeter';
import { FloorWallStyle } from './tables/floor-wall-style';
import { FloorData } from './tables/floor-data';
import { EquipmentPlanning } from './tables/equipment-planning';
import { Task } from './tables/task';
import { Lease } from './tables/lease';
import { TablesNamesEnum } from './../db-model/tables-names-enum';
import { RoomLayoutType } from 'src/app/core/model/data-model/tables/room-layout-type';
import { Address } from './tables/address';
import { AppRole } from './tables/app-role';
import { AppUser } from './tables/app-user';
import { Building } from './tables/building';
import { BusinessUnit } from './tables/business-unit';
import { DisplayThemable } from './tables/display-themable';
import { DisplayTheme } from './tables/display-theme';
import { DisplayThemeCategory } from './tables/display-theme-category';
import { DisplayThemeItem } from './tables/display-theme-item';
import { DocumentVersion } from './tables/document-version';
import { EquipmentCatalog } from './tables/equipment-catalog';
import { FloorCatalog } from './tables/floor-catalog';
import { FloorModelCategory } from './tables/floor-model-category';
import { FloorModel } from './tables/floor-model';
import { Floor } from './tables/floor';
import { Room } from './tables/room';
import { TaskContributor } from './tables/task-contributor';
import { Workplace } from './tables/workplace';
import { WorkplaceType } from './tables/workplace-type';
import { AppGrant } from './tables/app-grant';
import { AppGrantGroup } from './tables/app-grant-group';
import { AppRoleGrant } from './tables/app-role-grant';
import { Directory } from './tables/directory';
import { LayerTreeSet } from './sets/layer-tree-set';
import { ZAppParameter } from './tables/z-app-parameter';
import { DirectoryViewSet } from './view-set/directory-view-set';
import { WorkplaceTypeView } from './views/workplace-type-view';
import { EquipmentCatalogViewSet } from './view-set/equipment-catalog-view-set';
import { Site } from './tables/site';
import { BuildingAnatomyView } from './views/building-anatomy-view';
import { BuildingActivityStatusView } from './views/building-activity-status-view';
import { BuildingEquipmentProviderView } from './views/building-equipment-provider-view';
import { FloorActivityStatusView } from './views/floor-activity-status-view';
import { FloorAttributionView } from './views/floor-attribution-view';
import { FloorEquipmentProviderView } from './views/floor-equipment-provider-view';
import { TaskFloorModelView } from './views/task-floor-model-view';
import { TaskView } from './views/task-view';
import { ZSortDirection } from './tables/z-sort-direction';
import { DynviewSet } from './sets/dyn-view-set';
import { ZDbViewColumnView } from './views/z-db-view-column-view';
import { ZDbViewColumn } from './views/z-db-view-column';
import { FloorCatalogImport } from './tables/floor-catalog-import';
import { FloorCatalogImportItem } from './tables/floor-catalog-import-item';
import { TaskTypeView } from './views/task-type-view';
import { ZDbTable } from './tables/z-db-table';
import { BuildingAllocationView } from './views/building-allocation-view';
import { BuildingsAndBusBuildingsDTO } from '../../services/backend-services/dto/buildings-and-bus-buildings-dto';
import { BusAndBuildingsBusDTO } from '../../services/backend-services/dto/bus-and-buildings-bus-dto';
import { Simulation } from './tables/simulation';
import { SimulationPhoto } from './tables/simulation-photo';
import { SimulationFloor } from './tables/simulation-floor';
import { SimulationArea } from './tables/simulation-area';
import { SimulationBusinessUnit } from './tables/simulation-business-unit';
import { SimulationFloorAnatomy } from './tables/simulation-floor-anatomy';
import { SimulationOccupation } from './tables/simulation-occupation';
import { SimulationSandbox } from './tables/simulation-sandbox';
import { SimulationWorkforce } from './tables/simulation-workforce';
import { SimulationTimelinesDTO } from '../../services/backend-services/dto/simulation-timelines-dto';
import { SimulationFloorView } from './views/simulation-floor-view';
import { SimulationBuildingView } from './views/simulation-building-view';
import { SimulationPhotoDTO } from '../../services/backend-services/dto/simulation-photo-dto';
import { ZHierarchyDepthName } from './tables/z-hierarchy-depth-name';
import { FloorAllocationView } from './views/floor-allocation-view';
import { DxfEntity } from './tables/dxf-entity';
import { DxfImport } from './tables/dxf-import';
import { DxfLayer } from './tables/dxf-layer';
import { RoomAllocationViewSet } from './view-set/room-allocation-view-set';
import { WorkplaceAllocationViewSet } from './view-set/workplace-allocation-view-set';
import { PeopleLocationViewSet } from './view-set/people-location-view-set';
import { RoomAttributionTypeView } from './views/room-attribution-type-view';
import { RoomLayoutTypeStatusView } from './views/room-layout-type-status-view';
import { RoomLayoutTypeView } from './views/room-layout-type-view';
import { FacilityCompany } from './tables/facility-company';
import { FacilityCompanyTask } from './tables/facility-company-task';
import { FacilityJob } from './tables/facility-job';
import { FacilityJobTask } from './tables/facility-job-task';
import { FacilityCompanyTaskView } from './views/facility_company_task_view';
import { TaskFacilityView } from './views/task-facility-view';
import { TaskContributorView } from './views/task-contributor-view';
import { TaskStatusView } from './views/task-status-view';
import { RealEstateView } from '../../services/backend-services/dto/real-estate-view';
import { FloorModelView } from './views/floor-model-view';
import { AppGrantView } from './views/app-grant-view';
import { FloorView } from './views/floor-view';
import { FloorAnatomyView } from './views/floor-anatomy-view';
import { RealEstateAnatomyView } from './views/real-estate-anatomy-view';
import { RealEstateActivityStatusView } from './views/real-estate-activity-status-view';
import { RealEstateAttributionView } from './views/real-estate-attribution-view';
import { RealEstateEquipmentProviderView } from './views/real-estate-equipment-provider-view';
import { EquipmentCategoryView } from './views/equipment-category-view';
import { Request } from '../db-model/helpdesk/tables/request';
import { EquipmentStorageArea } from './tables/equipment-storage-area';
import { FloorModelUserOption } from './tables/floor-model-user-option';
import { EquipmentPlanningState } from './tables/equipment-planning-state';
import { EquipmentPlanningStateView } from './views/equipment-planning-state-view';
import { FloorDataState } from './tables/floor-data-state';
import { FloorDataStateView } from './views/floor-data-state-view';
import { EquipmentPurchase } from './tables/equipment-purchase';
import { EquipmentPurchaseView } from './views/equipment-purchase-view';
import { EquipmentRemovalCase } from './tables/equiment-removal-case';
import { EquipmentPurchaseDetail } from './tables/equipment-purchase-detail';
import { UnitOfWork } from './tables/unit_of_work';
import { EquipmentPlanningTaskView } from './views/equipment-planning-task-view';
import { EquipmentStorageView } from './views/equipment-storage-view';
import { TaskLinkView } from './views/task-link-type-view';
import { TaskFunctionView } from './views/task-function-view';
import { ZAppMenuView } from './views/z-app-menu-view';
import { EmailGrantedDomain } from './tables/email-granted-domain';
import { PerimeterDetail } from './tables/perimeter-detail';
import { PerimeterDetailView } from './views/perimeter-detail-view';
import { PerimeterView } from './views/perimeter-view';
import { ZAppMenuRoute } from './tables/z-app-menu-route';
import { ZAppMenuRouteView } from './views/z-app-menu-route-view';
import { CieUserGrantsViewDTO } from '../../services/backend-services/dto/cie-user-grants-view-dto';

export class DataModelMap {
    static tables: [tableName: string, tableModel: any][] = [
        [TablesNamesEnum.Address, Address],
        [TablesNamesEnum.AppGrant, AppGrant],
        [TablesNamesEnum.AppGrantGroup, AppGrantGroup],
        [TablesNamesEnum.AppRoleGrant, AppRoleGrant],
        [TablesNamesEnum.AppRole, AppRole],
        [TablesNamesEnum.AppUser, AppUser],
        [TablesNamesEnum.Building, Building],
        [TablesNamesEnum.BusinessUnit, BusinessUnit],
        [TablesNamesEnum.City, City],
        [TablesNamesEnum.Continent, Continent],
        [TablesNamesEnum.PolicyCity, PolicyCity],
        [TablesNamesEnum.Country, Country],
        [TablesNamesEnum.Directory, Directory],
        [TablesNamesEnum.DisplayTheme, DisplayTheme],
        [TablesNamesEnum.DisplayThemable, DisplayThemable],
        [TablesNamesEnum.DisplayThemeCategory, DisplayThemeCategory],
        [TablesNamesEnum.DisplayThemeItem, DisplayThemeItem],
        [TablesNamesEnum.Document, Document],
        [TablesNamesEnum.DocumentVersion, DocumentVersion],
        [TablesNamesEnum.DxfEntity, DxfEntity],
        [TablesNamesEnum.DxfImport, DxfImport],
        [TablesNamesEnum.DxfLayer, DxfLayer],
        [TablesNamesEnum.EmailGrantedDomain, EmailGrantedDomain],
        [TablesNamesEnum.EquipmentCatalog, EquipmentCatalog],
        [TablesNamesEnum.EquipmentPlanningState, EquipmentPlanningState],
        [TablesNamesEnum.EquipmentPlanning, EquipmentPlanning],
        [TablesNamesEnum.EquipmentPurchase, EquipmentPurchase],
        [TablesNamesEnum.EquipmentPurchaseDetail, EquipmentPurchaseDetail],
        [TablesNamesEnum.EquipmentRemovalCase, EquipmentRemovalCase],
        //[TablesNamesEnum.EquipmentRemoval, EquipmentRemoval],
        [TablesNamesEnum.EquipmentStorageArea, EquipmentStorageArea],
        //[TablesNamesEnum.EquipmentStorage, EquipmentStorage],
        [TablesNamesEnum.FacilityCompany, FacilityCompany],
        [TablesNamesEnum.FacilityCompanyTask, FacilityCompanyTask],
        [TablesNamesEnum.FacilityJob, FacilityJob],
        [TablesNamesEnum.FacilityJobTask, FacilityJobTask],
        [TablesNamesEnum.FloorData, FloorData],
        [TablesNamesEnum.FloorDataImport, FloorDataImport],
        [TablesNamesEnum.FloorDataImportItem, FloorDataImportItem],
        [TablesNamesEnum.FloorDataImportLog, FloorDataImportLog],
        [TablesNamesEnum.FloorDataState, FloorDataState],
        [TablesNamesEnum.FloorCatalog, FloorCatalog],
        [TablesNamesEnum.FloorCatalogImport, FloorCatalogImport],
        [TablesNamesEnum.FloorCatalogImportItem, FloorCatalogImportItem],
        [TablesNamesEnum.FloorCatalogImportLog, FloorCatalogImportLog],
        [TablesNamesEnum.FloorData, FloorData],
        //[TablesNamesEnum.FloorDoorStyle, FloorDoorStyle],
        [TablesNamesEnum.FloorModelCategory, FloorModelCategory],
        [TablesNamesEnum.FloorModel, FloorModel],
        [TablesNamesEnum.FloorModelUserOption, FloorModelUserOption],
        [TablesNamesEnum.Floor, Floor],
        [TablesNamesEnum.FloorWallStyle, FloorWallStyle],
        [TablesNamesEnum.Lease, Lease],
        [TablesNamesEnum.LeaseStatus, LeaseStatus],
        [TablesNamesEnum.Perimeter, Perimeter],
        [TablesNamesEnum.ProviderCompany, ProviderCompany],
        [TablesNamesEnum.PerimeterDetail, PerimeterDetail],
        [TablesNamesEnum.RoomActivityStatusType, RoomActivityStatusType],
        [TablesNamesEnum.RoomAllocation, RoomAllocation],
        [TablesNamesEnum.RoomAttributionType, RoomAttributionType],
        [TablesNamesEnum.RoomLayoutType, RoomLayoutType],
        [TablesNamesEnum.RoomSharing, RoomSharing],
        [TablesNamesEnum.Room, Room],
        [TablesNamesEnum.Simulation, Simulation],
        [TablesNamesEnum.SimulationArea, SimulationArea],
        [TablesNamesEnum.SimulationBusinessUnit, SimulationBusinessUnit],
        [TablesNamesEnum.SimulationFloor, SimulationFloor],
        [TablesNamesEnum.SimulationFloorAnatomy, SimulationFloorAnatomy],
        [TablesNamesEnum.SimulationOccupation, SimulationOccupation],
        [TablesNamesEnum.SimulationPhoto, SimulationPhoto],
        [TablesNamesEnum.SimulationSandbox, SimulationSandbox],
        [TablesNamesEnum.SimulationWorkforce, SimulationWorkforce],
        [TablesNamesEnum.Site, Site],
        [TablesNamesEnum.Task, Task],
        [TablesNamesEnum.TaskBusinessType, TaskBusinessType],
        [TablesNamesEnum.TaskFloor, TaskFloor],
        [TablesNamesEnum.TaskChangeLog, TaskChangeLog],
        [TablesNamesEnum.TaskContributor, TaskContributor],
        [TablesNamesEnum.TaskContributorDirectory, TaskContributorDirectory],
        //[TablesNamesEnum.TaskFloor, TaskFloor],
        //[TablesNamesEnum.TaskFloorModel, TaskFloorModel],
        [TablesNamesEnum.TaskFunction, TaskFunction],
        [TablesNamesEnum.TaskLink, TaskLink],
        [TablesNamesEnum.TaskStatus, TaskStatus],
        [TablesNamesEnum.TaskValidation, TaskValidation],
        [TablesNamesEnum.PeopleLocation, PeopleLocation],
        [TablesNamesEnum.Workplace, Workplace],
        [TablesNamesEnum.WorkplaceType, WorkplaceType],
        [TablesNamesEnum.UnitOfWork, UnitOfWork],
        [TablesNamesEnum.ZAppParameter, ZAppParameter],
        [TablesNamesEnum.ZAppMenuRoute, ZAppMenuRoute],
        [TablesNamesEnum.ZDbColumn, ZDbColumn],
        [TablesNamesEnum.ZDbTable, ZDbTable],
        [TablesNamesEnum.ZDbView, ZDbView],
        [TablesNamesEnum.ZDbViewColumn, ZDbViewColumn],
        [TablesNamesEnum.ZColumnConstraint, ZColumnConstraint],
        [TablesNamesEnum.ZConstraintMode, ZConstraintMode],
        [TablesNamesEnum.ZConstraintType, ZConstraintType],
        [TablesNamesEnum.ZContextUITable, ZContextUITable],
        [TablesNamesEnum.ZContextUITableUser, ZContextUITableUser],
        [TablesNamesEnum.ZHierarchyDepthName, ZHierarchyDepthName],
        [TablesNamesEnum.ZScope, ZScope],
        [TablesNamesEnum.ZSortDirection, ZSortDirection],
        //[TablesNamesEnum.ZLanguageItem, ZLanguageItem],
        [ViewsNames.AppGrantView, AppGrantView],
        [ViewsNames.AppGrantGroupView, AppGrantGroupView],
        [ViewsNames.BuildingView, BuildingView],
        [ViewsNames.BuildingAllocationView, BuildingAllocationView],
        [ViewsNames.BuildingAnatomyView, BuildingAnatomyView],
        [ViewsNames.BuildingActivityStatusView, BuildingActivityStatusView],
        [ViewsNames.BuildingAttributionView, BuildingAttributionView],
        [ViewsNames.BuildingEquipmentProviderView, BuildingEquipmentProviderView],
        [ViewsNames.CityView, CityView],
        [ViewsNames.ContinentView, ContinentView],
        [ViewsNames.CountryView, CountryView],
        [ViewsNames.EquipmentCategoryView, EquipmentCategoryView],
        [ViewsNames.EquipmentPlanningStateView, EquipmentPlanningStateView],
        [ViewsNames.EquipmentPlanningTaskView, EquipmentPlanningTaskView],
        [ViewsNames.EquipmentPurchaseView, EquipmentPurchaseView],
        [ViewsNames.EquipmentRemovalCaseView, EquipmentRemovalCaseView],
        [ViewsNames.EquipmentStorageView, EquipmentStorageView],
        [ViewsNames.FacilityCompanyTaskView, FacilityCompanyTaskView],
        [ViewsNames.FloorAnatomyView, FloorAnatomyView],
        [ViewsNames.FloorView, FloorView],
        [ViewsNames.FloorLayoutView, FloorLayoutView],
        [ViewsNames.FloorCatalogImportItemView, FloorCatalogImportItemView],
        [ViewsNames.FloorActivityStatusView, FloorActivityStatusView],
        [ViewsNames.FloorAllocationView, FloorAllocationView],
        [ViewsNames.FloorAttributionView, FloorAttributionView],
        [ViewsNames.FloorDataStateView, FloorDataStateView],
        [ViewsNames.FloorEquipmentProviderView, FloorEquipmentProviderView],
        [ViewsNames.FloorModelView, FloorModelView],
        [ViewsNames.LeaseView, LeaseView],
        [ViewsNames.LeaseStatusView, LeaseStatusView],
        [ViewsNames.PerimeterView, PerimeterView],
        [ViewsNames.PerimeterDetailView, PerimeterDetailView],
        [ViewsNames.RealEstateView, RealEstateView],
        [ViewsNames.RealEstateActivityStatusView, RealEstateActivityStatusView],
        [ViewsNames.RealEstateAnatomyView, RealEstateAnatomyView],
        [ViewsNames.RealEstateAttributionView, RealEstateAttributionView],
        [ViewsNames.RealEstateEquipmentProviderView, RealEstateEquipmentProviderView],
        [ViewsNames.RoomAttributionTypeView, RoomAttributionTypeView],
        [ViewsNames.RoomLayoutTypeStatusView, RoomLayoutTypeStatusView],
        [ViewsNames.RoomLayoutTypeView, RoomLayoutTypeView],
        [ViewsNames.SimulationFloorView, SimulationFloorView],
        [ViewsNames.SimulationBuildingView, SimulationBuildingView],
        [ViewsNames.SiteView, SiteView],
        [ViewsNames.SiteActivityStatusView, SiteActivityStatusView],
        [ViewsNames.SiteAnatomyView, SiteAnatomyView],
        [ViewsNames.SiteAttributionView, SiteAttributionView],
        [ViewsNames.SiteEquipmentProviderView, SiteEquipmentProviderView],
        [ViewsNames.TaskBusinessTypeView, TaskBusinessTypeView],
        [ViewsNames.TaskContributorView, TaskContributorView],
        [ViewsNames.TaskFacilityView, TaskFacilityView],
        [ViewsNames.TaskFloorModelView, TaskFloorModelView],
        [ViewsNames.TaskFunctionView, TaskFunctionView],
        [ViewsNames.TaskLinkView, TaskLinkView],
        [ViewsNames.TaskStatusView, TaskStatusView],
        [ViewsNames.TaskTypeView, TaskTypeView],
        [ViewsNames.TaskView, TaskView],
        [ViewsNames.WorkplaceTypeView, WorkplaceTypeView],
        [ViewsNames.ZAppMenuView, ZAppMenuView],
        [ViewsNames.ZAppMenuRouteView, ZAppMenuRouteView],
        [ViewsNames.ZDbColumnView, ZColumnView],
        [ViewsNames.ZDbViewColumnView, ZDbViewColumnView],
        [ViewsNames.ZColumnConstraintView, ZColumnConstraintView],
        [ViewsNames.ZTableView, ZTableView],
        [TablesSetsEnum.LayerTreeSet, LayerTreeSet],
        [TablesSetsEnum.DirectoryViewSet, DirectoryViewSet],
        [TablesSetsEnum.EquipmentCatalogViewSet, EquipmentCatalogViewSet],
        [TablesSetsEnum.ContextUIViewSet, ContextUIDynViewSet],
        [TablesSetsEnum.DynviewSet, DynviewSet],
        [TablesSetsEnum.RoomAllocationViewSet, RoomAllocationViewSet],
        [TablesSetsEnum.WorkplaceAllocationViewSet, WorkplaceAllocationViewSet],
        [TablesSetsEnum.PeopleLocationViewSet, PeopleLocationViewSet],
        [DtoNamesEnum.BuildingsAndBusBuildingsDTO, BuildingsAndBusBuildingsDTO],
        [DtoNamesEnum.BusAndBuildingsBusDTO, BusAndBuildingsBusDTO],
        [DtoNamesEnum.CieUserGrantsViewDTO, CieUserGrantsViewDTO],
        [DtoNamesEnum.SimulationTimelinesDTO, SimulationTimelinesDTO],
        [DtoNamesEnum.SimulationPhotoDTO, SimulationPhotoDTO],
        ["Request", Request]
    ];

    static getModel(tableName: string): any {
        return DataModelMap.tables.find(x=> x[0] === tableName)?.[1];
    }
}