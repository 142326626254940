@if (model) {
    <div class="header-line">
        @for (c of model.firstLineCells; track c) {
            <div class="header-cell" [style.--duration]="c.duration * model.currentUnitWidth">
                <span class="label">{{c.label}}</span>
            </div>
        }
    </div>
    <div class="header-line">
        @for (c of model.secondLineCells; track c) {
            <div [class.schedule-today-header-col]="model.isToday(c.startDate)" 
                class="header-cell" 
                [class.we]="[0, 6].includes(c.startDate.getDay()) && c.duration === 1" 
                [style.--duration]="c.duration * model.currentUnitWidth">
                <span class="label">{{c.label}}</span>
            </div>
        }
    </div>
}
