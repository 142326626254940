import { Component, Input } from '@angular/core';
import { MVVMEventDestroyLifeCycle } from 'src/app/components-lib/shared-model/mvvm-events-destroy-lifecycle';
import { WelcomeScreenVM } from './welcome-screen-vm';
import { MarkdownModule } from 'ngx-markdown';
import { SplitterModule } from 'primeng/splitter';
import { TreeModule } from 'primeng/tree';
import { ButtonModule } from 'primeng/button';

@Component({
  selector: 'xc-welcome-screen',
  standalone: true,
  imports: [MarkdownModule, SplitterModule, TreeModule, ButtonModule],
  templateUrl: './welcome-screen.component.html',
  styles: ':host ::ng-deep .p-tree .p-tree-container .p-treenode .p-treenode-content { padding: 0 }'
})
export class WelcomeScreenComponent extends MVVMEventDestroyLifeCycle {
  @Input() override model: WelcomeScreenVM | undefined;
}
