import { DocumentVersionView } from './../views/document-version-view';
import { EquipmentCategoryView } from './../views/equipment-category-view';
import { ProviderCompany } from './../tables/provider-company';
import { EquipmentCatalog } from './../tables/equipment-catalog';
import { getView } from '../views/view-builder';
import { FloorCatalog } from '../tables/floor-catalog';

export class EquipmentCatalogViewSet {
    dataSet: (EquipmentCatalog & ProviderCompany & EquipmentCategoryView & FloorCatalog & DocumentVersionView);
    equipmentCatalog: EquipmentCatalog;
    providerCompany: ProviderCompany;
    equipmentCategoryView: EquipmentCategoryView;
    floorCatalog: FloorCatalog;
    documentVersionView: DocumentVersionView;

    constructor(dtoData: any) {
        this.dataSet = getView(dtoData, EquipmentCatalog, ProviderCompany, EquipmentCategoryView, FloorCatalog, DocumentVersionView);
        
        this.equipmentCatalog = new EquipmentCatalog(dtoData);
        this.providerCompany = new ProviderCompany(dtoData);
        this.equipmentCategoryView = new EquipmentCategoryView(dtoData);
        this.floorCatalog = new FloorCatalog(dtoData);
        this.documentVersionView = new DocumentVersionView(dtoData);
    }
}