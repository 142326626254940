import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MeasurementUpdateGizmoWM } from '../../model/measurement-update-gizmo-vm';
import { EquimentSelectionRotationLabelComponent } from '../../../equipment-update-gizmo/view/equiment-selection-rotation-label/equiment-selection-rotation-label.component';
import { BpSvgGroupComponent } from '../../../../../../svg-entities/view/bp-svg-group/bp-svg-group.component';

@Component({
  selector: 'g[xc-measurement-update-gizmo]',
  standalone: true,
  imports: [CommonModule, EquimentSelectionRotationLabelComponent, BpSvgGroupComponent],
  templateUrl: './measurement-update-gizmo.component.svg',
  styleUrls: ['./measurement-update-gizmo.component.scss']
})
export class MeasurementUpdateGizmoComponent {
  @Input() model: MeasurementUpdateGizmoWM | undefined;
}
