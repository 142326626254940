import { BuildingLayoutDbView } from "../../db-model/views/building-layout-db-view";
import { DefinedModelBase } from "../defined-model-base";

export class BuildingLayoutView extends DefinedModelBase {
    buSiteId!: number;
    flBuildingId!: number;
    roLaTyId!: number;
    roLaTyCode!: string;
    roLaTyName!: string;
    roLaTyColor!: string;
    roLaTyIsNUA!: boolean;
    roLaTyParentId!: number;
    buLaViArea!: number;
    buLaViRoundedArea!: number;
    buLaViLayoutTypeTree!: string;

    constructor(dtoData: any) {
        super(dtoData, [BuildingLayoutDbView]);
    }
}