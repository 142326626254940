import { Component, Input } from '@angular/core';
import { SitesToolbarComponent } from 'src/app/ui/pages/layout/real-estate/sites/sites-toolbar/sites-toolbar.component';
import { NestedToolbarTypeEnum } from '../../model/nested-toolbar-type-enum';
import { MVVMEventDestroyLifeCycle } from 'src/app/components-lib/shared-model/mvvm-events-destroy-lifecycle';
import { BuildingsToolbarComponent } from 'src/app/ui/pages/layout/real-estate/buildings/buildings-toolbar/buildings-toolbar.component';
import { MapToolbarComponent } from 'src/app/ui/pages/layout/real-estate/map/map-toolbar/map-toolbar.component';
import { FloorsToolbarComponent } from 'src/app/ui/pages/layout/real-estate/floors/floors-toolbar/floors-toolbar.component';
import { BusinessUnitsToolbarComponent } from 'src/app/ui/pages/layout/organization/view/business-units-toolbar/business-units-toolbar.component';

@Component({
  selector: 'xc-nested-toolbar',
  standalone: true,
  imports: [
    MapToolbarComponent, 
    SitesToolbarComponent, 
    BuildingsToolbarComponent, 
    FloorsToolbarComponent,
    BusinessUnitsToolbarComponent
  ],
  templateUrl: './nested-toolbar.component.html',
  styleUrl: './nested-toolbar.component.scss'
})
export class NestedToolbarComponent extends MVVMEventDestroyLifeCycle {
  @Input() override model: any | undefined;
  NestedToolbarType = NestedToolbarTypeEnum;
}
