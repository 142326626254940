import { TaskView } from "src/app/core/model/data-model/views/task-view";
import { MobilityProjectTreeNode } from "./mobility-project-tree-node";
import { DateUtils } from "src/app/core/model/static-functions/date-utils";

export class MobilityTemplateTreeNode extends MobilityProjectTreeNode {
    override children: MobilityTemplateTreeNode[] = [];
    override parent: MobilityTemplateTreeNode | undefined;
    tmpStartDate: Date;
    rootStartDate: Date;
    dayOffset: number = 0;

    constructor(data: TaskView) {
        super(data);

        this.rootStartDate = this.data.taStartDate;
        this.tmpStartDate = this.data.taStartDate;
    }

    setTransientDate(): void {
        const today = DateUtils.today();
        this.dayOffset = DateUtils.dateDiffInDays(today, this.rootStartDate);
        this.data.taStartDate = DateUtils.addDays(this.data.taStartDate, this.dayOffset);
    }

    override setChildren(children: MobilityTemplateTreeNode[]): void {
        if (children.length === 0) return;
        this.setTransientDate();
        this.children = children;
        children.forEach(c => {
            c.parent = this;
            c.rootStartDate = this.rootStartDate;
            c.setTransientDate();
        });
    }
}