import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { PurchasesListVM } from '../../model/purchases-list-vm';
import { PurchasesManageComponent } from '../purchases-manage/purchases-manage.component';
import { SplitterModule } from 'primeng/splitter';
import { MVVMEventDestroyLifeCycle } from 'src/app/components-lib/shared-model/mvvm-events-destroy-lifecycle';
import { DynTableComponent } from 'src/app/components-lib/dyn-table/dyn-table.component';

@Component({
  selector: 'xc-purchases-list',
  standalone: true,
  imports: [CommonModule, DynTableComponent, PurchasesManageComponent, SplitterModule],
  templateUrl: './purchases-list.component.html',
  styleUrl: './purchases-list.component.scss'
})
export class PurchasesListComponent extends MVVMEventDestroyLifeCycle {
  @Input() override model: PurchasesListVM | undefined;
}
