import { EquipmentItem } from 'src/app/core/model/data-model/tables/equipment-item';
import { EquipmentPlanning } from 'src/app/core/model/data-model/tables/equipment-planning';
import { IntersectedSegment } from 'src/app/core/model/geometry-model/intersected-segment';
import { Polygon } from 'src/app/core/model/geometry-model/polygon.model';
import { HtmlConstants } from 'src/app/core/model/html-model/html-constants.model';
import { BpSvgUse } from '../../../bp-svg-core-model/bp-svg-use';
import { BlueprintLayer } from './blueprint-layer';
import { EquipmentPlanningStateView } from 'src/app/core/model/data-model/views/equipment-planning-state-view';
import { FloorDataStateView } from 'src/app/core/model/data-model/views/floor-data-state-view';

export class BlueprintEquipmentLayer extends BlueprintLayer<BpSvgUse> {
    constructor(dtoData: any, taskId: number) {
        super(dtoData, taskId);
    }

    setEquipmentPlanning(dtoData: any[]): void {
        // TODO : optimiser par la fourniture directe de la data par le backend
        (this.typedData()).forEach(u => {
            const e = dtoData.find(x=> x['EqPl_FloorDataId'] === u.floorDataId);
            if (e) {
                u.equipmentPlanning = new EquipmentPlanning(e);
                u.equipmentItem = new EquipmentItem(e);
                u.equipmentPlanningState = new EquipmentPlanningStateView(e);
                u.floorDataState = new FloorDataStateView(e);
                u.inventoryStateId = u.equipmentItem.eqItInventoryStateId;
                u.planningStateId = u.equipmentPlanning.eqPlPlanningStateId;
            }
        });
    }

    resetEquipmentsColor(): void {
        (this.typedData()).forEach(u => {
            u.resetThemeColor();
        });
    }

    selectByCenter(polygon: Polygon): BpSvgUse[] {
        const result: BpSvgUse[] = [];

        (this.typedData()).forEach(u => {
            if (u.display === HtmlConstants.styleDisplayBlock) {
                const bbc = u.getBboxCenter();
                if (bbc && polygon.contains(bbc)) {
                    result.push(u);
                }
            }
        });

        return result;
    }

    selectUsesByContour(polygon: Polygon): BpSvgUse[] {
        const result: BpSvgUse[] = [];

        (this.typedData()).forEach(u => {
            if (u.display === HtmlConstants.styleDisplayBlock) {
                const intersects = u.contourIntersects(polygon);
                if (intersects.length > 0) {
                    result.push(u);
                }
            }
        });

        return result;
    }

    selectIntersectsByContour(polygon: Polygon): IntersectedSegment[] {
        let result: IntersectedSegment[] = [];

        (this.typedData()).forEach(u => {
            if (u.display === HtmlConstants.styleDisplayBlock) {
                const intersects = u.contourIntersects(polygon);
                result = result.concat(intersects);
            }
        });

        return result;
    }
}