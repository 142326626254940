export const RealEstateAnatomyDbView = {
    databaseTableName: "realestate_layout_view",
    roLaTyId: "RoLaTy_Id",
    roLaTyCode: "RoLaTy_Code",
    roLaTyName: "RoLaTy_Name",
    roLaTyColor: "RoLaTy_Color",
    roLaTyIsNUA: "RoLaTy_IsNUA",
    roLaTyParentId: "RoLaTy_ParentId",
    reEsLaVi_Area: "ReEsLaVi_Area",
    reEsLaViRoundedArea: "ReEsLaVi_RoundedArea",
    reEsLaViLayoutTypeTree: "ReEsLaVi_LayoutTypeTree"
}