@if (model) {
    <p-splitter [panelSizes]="[15, 75]" [minSizes]="[15, 60]" class="w-full" styleClass="h-full mb-5">
        <ng-template pTemplate>
            <div>
                <xc-leases-tree [model]="model.tree"/>
            </div>
        </ng-template>
        <ng-template pTemplate>
            <div class="col flex align-items-center justify-content-center">
                <xc-wip-icon></xc-wip-icon>
            </div>
        </ng-template>
    </p-splitter>
}
