import { DrawingVM } from "../drawing-vm";
import { InteractionCommand } from "./interaction-command";
import { SelectionInteraction } from "./selection-interaction";
import { SvgDOMBase } from "src/app/core/model/svg-model/abstract/svg-dom-base";
import { BoardToolbarCommandEnum } from "../board-toolbar-command-enum";
import { PanzoomController } from "src/app/components-lib/svg/panzoom-controller";
import Container from "typedi";
import { FloorCatalogService } from "src/app/core/services/backend-services/floor-catalog-service";
import { HtmlConstants } from "src/app/core/model/html-model/html-constants.model";
import { Point } from "src/app/core/model/geometry-model/point.model";
import { SvgEntityTypesEnum } from "src/app/core/model/svg-model/svg-entity-type-enum";
import { SelectableSvgEntity } from "../../svg/selectable-svg-entity";
import { createRandomString, getSelectableEntities } from "../../svg/selectable-entity-builder";
import { UpdateGizmos } from "../../gizmo/model/update-gizmos";
import { IEntityInteraction } from "./i-entity-interaction";
import { LineInteraction } from "./line-interaction";
import { LineGizmo } from "../../gizmo/model/line-gizmo";
import { AttributeObserver } from "src/app/components-lib/attribute-observer";
import { TargetGripsDetection } from "./target-grips-detection";
import { SelectableSvgLine } from "../../svg/selectable-svg-line";
import { CircleGizmo } from "../../gizmo/model/circle-gizmo";
import { PathGizmo } from "../../gizmo/model/path-gizmo";
import { EllipseGizmo } from "../../gizmo/model/ellipse-gizmo";
import { CircleInteraction } from "./circle-interaction";
import { EllipseInteraction } from "./ellipse-interaction";
import { PathInteraction } from "./path-interaction";
import { SelectableSvgCircle } from "../../svg/selectable-svg-circle";
import { CanvasGizmo } from "../../gizmo/model/canvas-gizmo";
import { GripsOptions } from "../../../../../shared/drawing/grips-options";
import { InsertOptions } from "../../commands/model/insert-options";
import { IInsertInteraction } from "./insert/i-insert-interaction";
import { LineInsertInteraction } from "./insert/line-insert-interaction";
import { InsertGizmo } from "../../gizmo/insert/model/insert-gizmo";
import { CircleInsertInteraction } from "./insert/circle-insert-interaction";
import { EllipseInsertInteraction } from "./insert/ellipse-insert-interaction";
import { PathInsertInteraction } from "./insert/path-insert-interaction";
import { DyntService } from "src/app/core/services/backend-services/dynt-service";
import { FloorCatalogTable } from "src/app/core/model/db-model/tables/floor-catalog-table";
import { UndoService } from "../undo/undo-service";
import { UndoCUDOpEnum } from "../undo/undo-cud-op-enum";
import { Grips } from '../grips/grips';
import { SvgEntityPoint } from '../../../shared/gizmos/model/svg-entity-point';
import { SvgEntityPointStyleEnum } from '../../../shared/gizmos/model/svg-entity-point-style-enum';
import { Grip } from '../grips/grip';
import { SelectableSvgEllipse } from '../../svg/selectable-svg-ellipse';
import { BpSvgBoundingBox } from "src/app/ui/pages/layout/real-estate/floor-blueprint/content/bp-svg-core-model/bp-svg-bounding-box";
import { logError } from "src/app/core/services/logging-service";

export class UserInteraction extends SvgDOMBase {
    drawing: DrawingVM;
    panzoomController: PanzoomController;
    currentCommand: InteractionCommand = new InteractionCommand();
    selectionInteraction: SelectionInteraction;
    insertInteraction: IInsertInteraction<InsertGizmo> | undefined;
    currentInteractions: IEntityInteraction<SelectableSvgEntity>[] = [];
    updateGizmos: UpdateGizmos;
    canvasGizmo: CanvasGizmo | undefined;
    targetGrips: Grips = new Grips("purple", "purple");
    viewboxObserver: AttributeObserver | undefined;
    selectedGripsOptions: number[] = [];
    showAllTargetGrips: boolean = false;
    detectDelay: number = 30;
    undoService: UndoService;

    kd = this.keyDown.bind(this);

    constructor() {
        super("svgImage", "21edb585-b9e9-4b36-ba09-520e7f981f07");
        this.undoService = new UndoService(100);
        this.undoService.deleteRequested = (id: string) => {
            const i = this.drawing.entities.findIndex(x=> x.entityId === id);
            this.drawing.entities.splice(i, 1);
            if (this.selectionInteraction.selectedEntities.length > 0) {
                const i = this.selectionInteraction.selectedEntities.findIndex(x=> x.entityId === id);
                this.selectionInteraction.selectedEntities.splice(i, 1);
            }
            this.updateDrawingExtents();
        }
        this.undoService.createRequested = (entity: any) => {
            this.drawing.insert(entity);
            this.updateDrawingExtents();
        }


        this.updateGizmos = new UpdateGizmos(1);
        this.updateGizmos.gripClicked = (point: Point, grip: Grip, entity: SelectableSvgEntity | undefined) => {
            // Les grips de milieu de segment de polyligne servent de commande
            // et ne modifient le segment que s'il s'agit d'un segment d'arc
            if (grip.point.gripStyle === SvgEntityPointStyleEnum.plineMiddle) return;

            this.currentCommand.initialSvgMousePosition = this.getPointPosition(point.x, point.y);
            this.currentCommand.gripClicked = grip;
            this.currentCommand.initialgripClickedPosition = grip.point;
            this.currentCommand.entityClicked = entity;

            if (!this.selectionInteraction.areSame()) {
                this.currentCommand.set(InteractionCommand.entityTranslationCommand);
            } else {
                if (entity) {
                    switch (entity.entityType) {
                        case SvgEntityTypesEnum.circle:
                            if ((entity as SelectableSvgCircle).isCenter(grip.point)) {
                                this.currentCommand.set(InteractionCommand.entityTranslationCommand);
                            } else {
                                this.currentCommand.set(InteractionCommand.circleRadiusChangeCommand);
                            }
                            break;
                        case SvgEntityTypesEnum.line:
                            if ((entity as SelectableSvgLine).isEndPoint(grip.point)) {
                                this.currentCommand.set(InteractionCommand.lineEndpointTranslationCommand);
                            } else {
                                this.currentCommand.set(InteractionCommand.entityTranslationCommand);
                            }
                            break;
                        case SvgEntityTypesEnum.ellipse:
                            if ((entity as SelectableSvgEllipse).isCenter(grip.point)) {
                                this.currentCommand.set(InteractionCommand.entityTranslationCommand);
                            } else {
                                this.currentCommand.set(InteractionCommand.ellipseRadiusChangeCommand);
                                this.updateGizmos.ellipseGizmo.startChangeRadius();
                            }
                            break;
                        case SvgEntityTypesEnum.path:
                            this.currentCommand.set(InteractionCommand.pathPointTranslationCommand);
                            this.updateGizmos.pathGizmo.initializePointTranslation(grip.point);
                            break;
                        default:
                            break;
                    }
                }
            }

            if (this.currentInteractions.length > 0) {
                this.undoService.start(this.selectionInteraction.selectedEntities);
            }
        }

        this.updateGizmos.singleClicked = (e: SelectableSvgEntity) => {
            // if (e.entityType === SvgEntityTypesEnum.text) {
            //     const t = (e as DxfSvgText);
            //     const bbox = DxfSvgDOM.getEntityBbox(t.entityId);
            //     const clientPos = this.getClientPointFromSvgPoint(new Point(bbox?.x, bbox?.y));
            //     const pu = this.getSvgPixelUnit();
            //     if (clientPos && bbox) {
            //         this.currentCommand.set(InteractionCommand.textInputCommand);
            //         (this.currentInteractions[0] as TextInteraction).beginUpdate(t.text!, clientPos.x, clientPos.y, bbox.width / pu, bbox.height / pu);
            //     }
            //     return;
            // }
        }
        
        this.drawing = new DrawingVM();
        this.panzoomController = new PanzoomController();
        // Branche le listener sur le zoom event
        this.panzoomController.zoomChange = (scale: number) => {
            this.drawing.updateStrokeWidth(this.getSvgPixelUnit());
        };
        // Branche le listener sur le viewboxChanged event
        this.panzoomController.viewboxChange = (viewbox: string) => this.drawing.viewbox = viewbox;
        this.selectionInteraction = new SelectionInteraction(this.currentCommand, this.drawing);
        this.selectionInteraction.selectionChanged = (selection: SelectableSvgEntity[]) => {
            this.setInteractions(selection);
        }

        const defaultBbox = BpSvgBoundingBox.getFromString("-0.5 -0.5;0.5 0.5")!;
        this.updateGizmos.drawingExtents = defaultBbox;
        this.canvasGizmo = new CanvasGizmo(defaultBbox);

        this.addEventListener(GripsOptions.selectedGripOptionsChangeEvent, (options: number[]) => {
            this.selectedGripsOptions = options;
            this.currentCommand.selectedGripsOptions = options;
        });

        this.addEventListener(InsertOptions.selectedInsertOptionChangeEvent, (option: number) => {
            this.abortCommand();
            switch (option) {
                case SvgEntityTypesEnum.line:
                    this.currentCommand.set(InteractionCommand.lineInsertionCommand);
                    this.insertInteraction = new LineInsertInteraction(this.currentCommand);
                    break;
                case SvgEntityTypesEnum.circle:
                    this.currentCommand.set(InteractionCommand.circleInsertionCommand);
                    this.insertInteraction = new CircleInsertInteraction(this.currentCommand);
                    break;
                case SvgEntityTypesEnum.ellipse:
                    this.currentCommand.set(InteractionCommand.ellipseInsertionCommand);
                    this.insertInteraction = new EllipseInsertInteraction(this.currentCommand);
                    break;
                case SvgEntityTypesEnum.path:
                    this.currentCommand.set(InteractionCommand.pathInsertionCommand);
                    this.insertInteraction = new PathInsertInteraction(this.currentCommand);
                    break;
                default:
                    break;
            }

            if (this.insertInteraction) {
                this.insertInteraction.insertionCompleted = async (entity: SelectableSvgEntity) => {
                    await this.drawing.insert(entity);
                    this.updateDrawingExtents();
                    // La commande est répétitive jusqu'à Escape ou changement de commande
                    this.insertInteraction?.gizmo.reset();
                    this.targetGrips.clear();

                    this.undoService.start([entity], UndoCUDOpEnum.create);
                }
            }
        });
    }

    initializePanZoom(): void {
        this.panzoomController?.initialize("drawing");
        window.addEventListener('keydown', this.kd, true);
        this.panzoomController.resetOriginalViewbox();
    }

    initializeViewboxObserver(): void {
        this.viewboxObserver = new AttributeObserver(document.getElementById("svgImage"), "viewBox");
        this.viewboxObserver.attributeChanged = () => {
            const pu = this.getSvgPixelUnit() * 15;
            this.updateGizmos.updateNonScalingElementsSize(pu);
            this.updateGripSize(pu);
            this.drawing.updateStrokeWidth(this.getSvgPixelUnit());
        }
    }

    updateGripSize(size: number): void {
        this.targetGrips.updateSize(size);
    }

    async loadPicto(floorCatalogId: number): Promise<void> {
        await this.abortCommand();
        this.undoService.clear();
        const s = Container.get(FloorCatalogService);
        const result = await s.getFloorCatalogItem(floorCatalogId);
        if (result != null) {
            const bbox = BpSvgBoundingBox.getFromString(result.boundingBoxString!);
            if (bbox) {
                this.drawing.set(floorCatalogId, bbox);
                this.panzoomController.resetOriginalViewbox();
                this.updateGizmos.drawingExtents = BpSvgBoundingBox.getFromString(this.drawing.extents)!;
                this.canvasGizmo = new CanvasGizmo(bbox);

                this.drawing.entities = getSelectableEntities(result.entities);
                this.drawing.entities.forEach(e => {
                    e.fill = HtmlConstants.noneValue;
                    e.cursor = "pointer",
                    e.entityId = createRandomString(8);
                });

                this.drawing.updateStrokeWidth(this.getSvgPixelUnit());
            }
        }
    }

    setCommand(selectedCommand: BoardToolbarCommandEnum) {

    }

    abortCommandRequested?: () => void;
    async abortCommand(): Promise<void> {
        this.selectionInteraction.selectedEntities.splice(0);
        this.targetGrips.clear();
        this.undoService.abort();

        this.currentInteractions.forEach(i => {
            i.abort();
        });

        if (this.currentCommand.isInsertionCommand()) {
            //await this.updateDrawingExtents();
            this.insertInteraction = undefined;
            await this.emitEventAsync(InsertOptions.clearInsertOptionEvent);
        }

        this.endCommand();
        if (this.abortCommandRequested) {
            this.abortCommandRequested();
        } else {
            logError("UserInteraction.abortCommandRequested n'est pas écouté");
        }
    }

    endCommand(): void {
        this.currentCommand.clear();
        this.updateGizmos.reticle.set(0);
    }

    setSelection(entities: SelectableSvgEntity[]): void {
        this.selectionInteraction.addSelection(entities);
    }

    setInteractions(entities: SelectableSvgEntity[]): void {
        this.updateGizmos.setSelection(entities);
        this.currentInteractions.splice(0);
        this.updateGizmos.gizmos.forEach(g => {
            if (g.entities.length > 0) {
                switch (g.entityType) {
                    case SvgEntityTypesEnum.line:
                        this.currentInteractions.push(new LineInteraction(this.currentCommand, g as LineGizmo));
                        break;
                    case SvgEntityTypesEnum.circle:
                        this.currentInteractions.push(new CircleInteraction(this.currentCommand, g as CircleGizmo));
                        break;
                    case SvgEntityTypesEnum.path:
                        this.currentInteractions.push(new PathInteraction(this.currentCommand, g as PathGizmo));
                        break;
                    case SvgEntityTypesEnum.ellipse:
                        this.currentInteractions.push(new EllipseInteraction(this.currentCommand, g as EllipseGizmo));
                        break;
                    default:
                        break;
                }
            }
        });
    }

    updateDrawingExtents(): void {
        // Le timeout est nécessaire pour que la récupération de la bbox
        // se produise bien après les évènements d'insertion
        setTimeout(async () => {
            const svgBbox = this.getSvgBbox("svgCanvas");
            if (svgBbox) {
                this.canvasGizmo?.update(svgBbox);
                return await this.drawing.updateExtents(svgBbox);
            }
            return false;
        }, 0);
    }
    
    getSvgEntitiesFromClientPoint(x: number, y: number): SelectableSvgEntity[] {
        const result: SelectableSvgEntity[] = [];
        const items = document.elementsFromPoint(x, y);
        for (const item of items) {
            const entityId = item.getAttribute("entity-id");
            if (entityId !== null) {
                const entity = this.drawing.entities.find(x=> x.entityId === entityId);
                if (entity) {
                    result.push(entity);
                }
            }
        }

        return result;
    }

    deleteSelected(): void {
        this.undoService.start(this.selectionInteraction.selectedEntities, UndoCUDOpEnum.delete);
        this.drawing.delete(this.selectionInteraction.selectedEntities)
        this.selectionInteraction.selectedEntities.splice(0);
        this.updateDrawingExtents();
    }

    async save(): Promise<void> {
        // La polyligne fermée ayant la plus grande surface est considérée comme le contour
        // tous les autres éléments sont combinés en un path unique
        // à l'exception des ellipses qui restent individualisées (ou alors il faut les tesseller)
        const pictoGroupStatement = this.drawing.combinedSet();
        if (pictoGroupStatement) {
            const s = Container.get(DyntService);

            if (this.drawing.pictoId) {
                // Enregistremennt de la modification du pictogramme existant
                await s.patch(FloorCatalogTable.databaseTableName, this.drawing.pictoId, 
                    {
                        "TableName": FloorCatalogTable.databaseTableName,
                        [FloorCatalogTable.flCgId]: this.drawing.pictoId,
                        [FloorCatalogTable.flCgSvgStatement]: pictoGroupStatement,
                        [FloorCatalogTable.flCgSvgBoundingBox]: this.drawing.extents
                    }
                );
            } else {
                // Enregitrement d'un nouveau pictogramme
            }
        }
    }

    async mouseDown(e: MouseEvent): Promise<void> {
        // On ne traite que les clics du bouton gauche
        if (e.buttons !== 1) return;

        // Si on est déjà dans une commande de sélection rectangulaire, c'est que c'est actuellement le second MouseDown et il termine la commande
        if (this.currentCommand.isDrawingRectangularSelectionCommand()) {
            return;
        }

        const hitPoint = this.getPointPosition(e.clientX, e.clientY);
        if (hitPoint == null) {
            logError("UserInteraction : impossible de récupérer les coordonnées du clic");
            return;
        }

        // if (this.currentCommand.isGridCommand()) {
        //     if (this.currentCommand.isGridRotationCommand()) {
        //         const itemsAtClientPoint = this.drawing.layersController.getSvgEntitiesFromClientPoint(e.clientX, e.clientY);
        //         if (itemsAtClientPoint.length > 0) {
        //             this.updateGizmos.gridGizmo.align(SvgGeometryService.getGeometry(itemsAtClientPoint)[0]);
        //         }
        //     }

        //     if (this.currentCommand.isGridUnitLengthCommand()) {
        //         let tmp = hitPoint;
        //         const nearestGrip = this.targetGrips.getSelected();
        //         if (nearestGrip) tmp = nearestGrip.point;
        //         if (this.currentCommand.initialSvgMousePosition) {
        //             // Définition du second point, recalcul de la grille et sortie de la commande
        //             this.updateGizmos.gridGizmo.unitLength = tmp.distanceTo(this.currentCommand.initialSvgMousePosition);
        //             this.targetGrips.clear();

        //             this.endCommand();
        //             this.raiseToolbarCommandEnded();
        //         } else {
        //             // Définition du premier point de la mesure
        //             this.currentCommand.initialSvgMousePosition = tmp;
        //         }
        //     }
        //     return;
        // }

        // On stocke la position initiale du clic pour pouvoir neutraliser dans le MouseMove le déplacement involontaire au démarrage
        // de la commande de sélection rectangulaire, ce mouvement se produisant avant le MouseUp
        this.currentCommand.initialSvgMousePosition = hitPoint;
      
        if (this.currentCommand.isInsertionCommand() && this.insertInteraction) {
            let targetPoint = this.targetGrips.getSelected()?.point;
            if (!targetPoint) targetPoint = new SvgEntityPoint(SvgEntityPointStyleEnum.none, hitPoint);
            this.insertInteraction.define(targetPoint);
            return;
        }

        // Si on clique sur un élément c'est qu'on veut le sélectionner
        // La commande est immédiate, on n'attend rien de MouseMove ni de MouseUp
        const itemsAtClientPoint = this.getSvgEntitiesFromClientPoint(e.clientX, e.clientY);
        if (itemsAtClientPoint.length > 0) {
            this.setSelection(itemsAtClientPoint);
            return;
        }


        // A ce stade on sait qu'on commence une sélection rectangulaire
        // On mémorise le point cliqué comme premier point du path de sélection
        this.currentCommand.set(InteractionCommand.drawingRectangularSelectionCommand);
        this.selectionInteraction.selectionPathPoints.push(hitPoint);

    }

    timer: number | undefined;
    async mouseMove(e: MouseEvent): Promise<void> {
        if (e.buttons !== 1 && !this.currentCommand.isInsertionCommand() && !this.currentCommand.isGridUnitLengthCommand() && this.updateGizmos.reticle.current === 0) {
            return;
        }
      
        if (this.currentCommand.isNoneCommand() && this.updateGizmos.reticle.current === 0) {
            return;
        }
      
        const hitPoint = this.getPointPosition(e.clientX, e.clientY);
        if (hitPoint == null) {
            logError("Erreur inattendue : impossible de récupérer les coordonnées du clic");
            return;
        }

        this.updateGizmos.reticle.move(hitPoint);
      
        if (this.currentCommand.isNoneCommand()) {
            return;
        }

        if (this.currentCommand.isGridTranslationCommand()) {
            // Arrête le timer
            clearTimeout(this.timer);

            if (this.currentCommand.tempSvgMousePosition &&
                hitPoint.distanceTo(this.currentCommand.tempSvgMousePosition) < this.currentCommand.magnetAttraction) {
                return;
            }
        
            // La souris est sortie du périmètre d'attraction, on masque l'attracteur
            if (this.currentCommand.magnetAttraction > 0) {
                this.targetGrips.clear();
                this.currentCommand.magnetAttraction = 0;
            }
        
            // Actualise la position courante de la souris pour le contrôle de l'attraction lors du prochain MouseMove
            this.currentCommand.tempSvgMousePosition = hitPoint;
            
            // Redémarre le timer
            this.timer = window.setTimeout(TargetGripsDetection.detectTargetGrips, 150, hitPoint, this);

            return;
        }

        if (this.currentCommand.isInsertionCommand() && this.insertInteraction) {
            // Arrête le timer
            clearTimeout(this.timer);
            
            if (this.currentCommand.tempSvgMousePosition &&
                hitPoint.distanceTo(this.currentCommand.tempSvgMousePosition) < this.currentCommand.magnetAttraction) {
                return;
            }
        
            // La souris est sortie du périmètre d'attraction, on masque l'attracteur
            if (this.currentCommand.magnetAttraction > 0) {
                this.targetGrips.clear();
                this.currentCommand.magnetAttraction = 0;
            }
        
            // Actualise la position courante de la souris pour le contrôle de l'attraction lors du prochain MouseMove
            this.currentCommand.tempSvgMousePosition = hitPoint;
            
            this.insertInteraction.mouseMove(e, hitPoint);
            
            // Redémarre le timer
            this.timer = window.setTimeout(TargetGripsDetection.detectTargetGrips, this.detectDelay, hitPoint, this);

            return;
        }

        if (this.currentCommand.isGridUnitLengthCommand()) {
            // Arrête le timer
            clearTimeout(this.timer);
            
            if (this.currentCommand.tempSvgMousePosition &&
                hitPoint.distanceTo(this.currentCommand.tempSvgMousePosition) < this.currentCommand.magnetAttraction) {
                return;
            }
        
            // La souris est sortie du périmètre d'attraction, on masque l'attracteur
            if (this.currentCommand.magnetAttraction > 0) {
                this.targetGrips.clear();
                this.currentCommand.magnetAttraction = 0;
            }
        
            // Actualise la position courante de la souris pour le contrôle de l'attraction lors du prochain MouseMove
            this.currentCommand.tempSvgMousePosition = hitPoint;
            
            // Redémarre le timer
            this.timer = window.setTimeout(TargetGripsDetection.detectTargetGrips, 150, hitPoint, this);

            return;
        }

        if (this.currentCommand.initialSvgMousePosition == null) {
            return;
        }

        if (this.currentCommand.isDrawingRectangularSelectionCommand()) {
            // On actualise le contour de sélection avec le point sous la souris, ainsi que la collection des éléments compris dans ce contour
            this.selectionInteraction.updateSelectionPathPoints(hitPoint, e.shiftKey);
            return;
        }
      
        if (this.currentCommand.isEntityTranslationCommand() || 
            this.currentCommand.isLineEndpointTranslationCommand() ||
            this.currentCommand.isPathPointTranslationCommand() ||
            this.currentCommand.isCircleRadiusChangeCommand()) {
            // Arrête le timer
            clearTimeout(this.timer);
        
            // Le magnetAttraction sert à empêcher tout mouvement tant que la souris ne s'est pas déplacée d'une distance minimale
            // lorsqu'un attracteur est affiché
            // Cela permet à l'utilisateur de bien voir l'attracteur avant de décider ou non de relâcher la souris
            // sans que le moindre minuscule mouvement ne le fasse disparaître ou changer de place
            if (this.currentCommand.tempSvgMousePosition &&
                hitPoint.distanceTo(this.currentCommand.tempSvgMousePosition) < this.currentCommand.magnetAttraction) {
                return;
            }
        
            // La souris est sortie du périmètre d'attraction, on masque l'attracteur
            if (this.currentCommand.magnetAttraction > 0) {
                this.targetGrips.clear();
                this.currentCommand.magnetAttraction = 0;
            }
        
            // Actualise la position courante de la souris pour le contrôle de l'attraction lors du prochain MouseMove
            this.currentCommand.tempSvgMousePosition = hitPoint;
            this.currentInteractions.forEach(async i => {
                await i.mouseMove(e, hitPoint);
            });
            this.currentCommand.initialSvgMousePosition = hitPoint;
            
            // Redémarre le timer
            this.timer = window.setTimeout(TargetGripsDetection.detectTargetGrips, this.detectDelay, hitPoint, this);

            return;
        }

        this.currentInteractions.forEach(async i => {
            await i.mouseMove(e, hitPoint);
        });
    }

    async mouseUp(e: MouseEvent): Promise<void> {
        // Arrête le timer
        clearTimeout(this.timer);

        if (this.currentCommand.isNoneCommand()) {
            return;
        }
      
        let hitPoint: Point | undefined = undefined;;

        if (this.currentCommand.isDrawingRectangularSelectionCommand()) {
            //this.selectionInteraction.gizmo.show(true);
            this.endCommand();
            this.selectionInteraction.selectionPathPoints.splice(0);
            return;
        }
    
        if (this.currentCommand.isSelectingSingleItemCommand()) {
            // Fin de la commande de sélection d'un élément
            this.endCommand();
            return;
        }

        if (this.currentCommand.isGridTranslationCommand() && this.targetGrips.items.length > 0) {
            const nearestGrip = this.targetGrips.getSelected();
            if (nearestGrip) {
                //this.updateGizmos.gridGizmo.move(nearestGrip.point);
            }
            return;
        }

        if (this.currentCommand.isEntityTranslationCommand() || 
            this.currentCommand.isLineEndpointTranslationCommand() ||
            this.currentCommand.isPathPointTranslationCommand() ||
            this.currentCommand.isCircleRadiusChangeCommand()) {
            
            if (this.targetGrips.items.length > 0) {
                // S'il y a des grips de positionnement affichés, déplace l'extrémité de la cloison sur le grip le plus proche
                const nearestGrip = this.targetGrips.getSelected();
                if (nearestGrip) {
                    hitPoint = nearestGrip.point;
                }
            } 

            this.targetGrips.clear();

            this.currentInteractions.forEach(async i => {
                await i.mouseUp(e, hitPoint!);
            });

            this.undoService.store(this.selectionInteraction.selectedEntities);
                        
            //this.endCommand();

            //const s = Container.get(DxfService);
            //await s.update(this.selectionInteraction.selectedEntities);
            this.updateDrawingExtents();
            //if (!await this.updateDrawingExtents()) {
                //await this.drawing.updateGeoTiling(this.selectionInteraction.selectedEntities);
            //}

            this.currentCommand.clear();
            
            return;
        }

        this.currentInteractions.forEach(async i => {
            await i.mouseUp(e, hitPoint!);
        });

        this.undoService.store(this.selectionInteraction.selectedEntities);
    }

    async keyDown(e: KeyboardEvent): Promise<void> {
        e.stopPropagation();
        if (e.ctrlKey && e.key.toLowerCase() ==="s") {
            e.preventDefault();
            await this.save();
            return;
        }

        if (e.ctrlKey && e.key.toLowerCase() ==="z") {
            e.preventDefault();
            this.undoService.undoLast(this.selectionInteraction.selectedEntities.map(x=> x.entityId));
            this.currentInteractions.forEach(i => {
                // On doit recharger les points de grip parce qu'on perd le type SvgEntityPoint avec le DrawingActionswitchState
                i.updateGizmo.refreshGrips();
            });
            return;
        }

        if (e.ctrlKey && e.key.toLowerCase() ==="y") {
            e.preventDefault();
            this.undoService.redoLast(this.selectionInteraction.selectedEntities.map(x=> x.entityId));
            this.currentInteractions.forEach(i => {
                // On doit recharger les points de grip parce qu'on perd le type SvgEntityPoint avec le DrawingActionswitchState
                i.updateGizmo.refreshGrips();
            });
            return;
        }

        switch (e.key.toLowerCase()) {
            case "w":
                this.panzoomController.resetView();
                break;
            case "escape":
                await this.abortCommand();
                break;
            case "delete":
                this.deleteSelected();
                break;
            default:
                await this.insertInteraction?.keyDown(e);
                break;
        }
    }

}