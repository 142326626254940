import { NestedToolbarBase } from "src/app/ui/main/model/nested-toolbar-base";
import { NestedToolbarTypeEnum } from "src/app/ui/main/model/nested-toolbar-type-enum";
import { RealEstateChartTypeEnum } from "../charts/shared-model/real-estate-chart-type-enum";
import { TabPageContentTypeEnum } from "../tab-page-content-type-enum";
import { BuildingsEventsEnum } from "./buildings-events-enum";
import { RadioButtonClickEvent } from "primeng/radiobutton";
import { SelectButtonOptionClickEvent } from "primeng/selectbutton";
import { Building } from "src/app/core/model/data-model/tables/building";
import { AutoCompleteCompleteEvent, AutoCompleteSelectEvent } from "primeng/autocomplete";
import { TablesNamesEnum } from "src/app/core/model/db-model/tables-names-enum";
import { BuildingTable } from "src/app/core/model/db-model/tables/building-table";
import { DyntService } from "src/app/core/services/backend-services/dynt-service";
import Container from "typedi";
import { BuildingsVM } from "./buildings-vm";

export class BuildingsToolbarVM extends NestedToolbarBase {
    buildings: Building[] = [];
    selectedBuilding: Building | null = null;

    options: {id: string, label: string}[] = [];
    selectedOption: {id: string, label: string} | undefined;

    chartTypes: {id: string, icon: string}[] = [];
    selectedChartType: {id: string, icon: string} | null = null;

    private constructor() {
        super(NestedToolbarTypeEnum.buildings);

        this.options.push({id: RealEstateChartTypeEnum.anatomy, label: "Anatomie"});
        this.options.push({id: RealEstateChartTypeEnum.activities, label: "Activités"});
        this.options.push({id: RealEstateChartTypeEnum.attributions, label: "Attributions"});
        this.options.push({id: RealEstateChartTypeEnum.businessunits, label: "Entités"});
        this.options.push({id: RealEstateChartTypeEnum.inventory, label: "Inventaire"});
        this.options.push({id: RealEstateChartTypeEnum.referential, label: "Référentiel"});
        this.selectedOption = this.options[0];

        this.chartTypes.push({id: TabPageContentTypeEnum.donutchart, icon: "pi pi-chart-pie"});
        this.chartTypes.push({id: TabPageContentTypeEnum.buildingstack, icon: "pi pi-align-justify"});
        this.chartTypes.push({id: TabPageContentTypeEnum.buildingaxono, icon: "pi pi-box"});
        this.selectedChartType = this.chartTypes[2];

    }

    static async newAsync(buildingId?: number): Promise<BuildingsToolbarVM> {
        const tmp = new BuildingsToolbarVM();
    
        tmp.emitEventAsync(BuildingsEventsEnum.selectedOptionChange, tmp.selectedOption);
        tmp.emitEventAsync(BuildingsEventsEnum.selectedChartChange, tmp.selectedChartType);

        if (buildingId) {
            tmp.selectedBuilding = await tmp.getBuilding(buildingId);
            tmp.emitEventAsync(BuildingsEventsEnum.selectedBuildingChange, tmp.selectedBuilding);
        }
        return tmp;
    }
    
    async optionChange(e: RadioButtonClickEvent): Promise<void> {
        this.emitEventAsync(BuildingsEventsEnum.selectedOptionChange, e.value);
    }

    optionDisabled(optionId: string): boolean {
        if (!this.selectedBuilding) return true;
        if (this.selectedChartType?.id === TabPageContentTypeEnum.buildingstack) {
            return optionId === RealEstateChartTypeEnum.referential;
        }
        if (this.selectedChartType?.id === TabPageContentTypeEnum.buildingaxono) {
            return optionId === RealEstateChartTypeEnum.inventory || optionId === RealEstateChartTypeEnum.referential;
        }
        return false;
    }

    async getBuilding(buildingId: number): Promise<Building | null> {
        const s = Container.get(DyntService);
        return await s.downloadRow<Building>(TablesNamesEnum.Building, undefined, BuildingTable.buId, buildingId);
    }

    async onSelectedChartTypeChange(e: SelectButtonOptionClickEvent): Promise<void> {
        if (this.selectedChartType?.id !== TabPageContentTypeEnum.donutchart && this.selectedOption?.id === RealEstateChartTypeEnum.referential) {
            this.selectedOption = this.options[0];
        }
        if (this.selectedChartType?.id === TabPageContentTypeEnum.buildingaxono && this.selectedOption?.id === RealEstateChartTypeEnum.inventory) {
            this.selectedOption = this.options[0];
        }
        this.emitEventAsync(BuildingsEventsEnum.selectedChartChange, e.option);
    }

    timer: number | undefined;
    searchForBuilding(value: AutoCompleteCompleteEvent): void {
        clearTimeout(this.timer);
        this.timer = window.setTimeout(async (that: BuildingsToolbarVM) => {
            const s = Container.get(DyntService);
            that.buildings = await s.search<Building>(TablesNamesEnum.Building, value.query, [BuildingTable.buName]);
        }, 500, this);
    }

    async onSearchResultSelect(e: AutoCompleteSelectEvent): Promise<void> {
        this.emitEventAsync(BuildingsEventsEnum.selectedBuildingChange, e.value);
    }
}