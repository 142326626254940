import { PerimeterTable } from './../../db-model/tables/perimeter-table';
import { DefinedModelBase } from '../defined-model-base';

export class Perimeter extends DefinedModelBase {
    peId!: number;
    peName!: string;
    peDescription!: string | null;
    peTypeId!: number;
    peIsSharingAreaItem!: boolean;
    peReadGrantId!: number | null;

    constructor(dtoData: any, tableDef: any[] = []) {
        super(dtoData, [PerimeterTable].concat(tableDef));
    }
}