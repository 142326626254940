import { Pipe, PipeTransform } from "@angular/core";
import { FloorModelEnum } from "src/app/core/model/data-model/enums/floor-model-enum";
import { BlueprintLayer } from "../../../svg-entities/model/layers/blueprint-layer";
import { FloorTask } from "../../../shared-model/floor-task";

@Pipe({
  name: 'layerspipe'
  // Petit truc qui m'a fait chercher un moment, nommer le pipe comme la classe
  // ça fait qu'il n'est pas trouvé, exactement comme s'il n'était pas déclaré dans le module
})
export class LayersPipe implements PipeTransform {
  transform(value: BlueprintLayer[] | undefined, layerIds: number[], floorCurrentTask: number | undefined, updateTasks: FloorTask[] | undefined): BlueprintLayer[] {
    const result: BlueprintLayer[] = [];

    // Les calques qui ne sont pas dans la stack sont affichés en premier
    const others = value?.filter(x=> !layerIds.includes(x.id));
    if (others) {
      result.push(...others);
    }

    layerIds.forEach(id => {
      const l = value?.find(x => x.id === id && x.taskId === floorCurrentTask);
      if (l && l.id !== FloorModelEnum.PartitioningFrame) {
        result.push(l);
      }
    });

    updateTasks?.forEach(t => {
      layerIds.forEach(id => {
        const l = value?.find(x => x.id === id && x.taskId === t.taskId);
        if (l) {
          result.push(l);
        }
      });
    });

    // La trame de cloisonnement est placée au dessus pour être visible lors du cloisonnement
    const partitionningFrame = value?.find(x => x.id === FloorModelEnum.PartitioningFrame && x.taskId === floorCurrentTask);
    if (partitionningFrame != null) {
          result.push(partitionningFrame);
    }

    return result;
  }
}
