import { BusinessUnit } from 'src/app/core/model/data-model/tables/business-unit';
import { logError } from 'src/app/core/services/logging-service';

export class SelectableBuTreeNode {
    bu: BusinessUnit
    children: SelectableBuTreeNode[] = [];
    selected: boolean = false;
    childrenPartiallySelected: boolean = false;
    parentNode: SelectableBuTreeNode | undefined;

    constructor(bu: BusinessUnit) {
        this.bu = bu;
    }

    setChildren(children: SelectableBuTreeNode[]): void {
        this.children = children;
        this.setChildrenSelectedChangeListner(children);
    }

    setChildrenSelectedChangeListner(children: SelectableBuTreeNode[]): void {
        children.forEach(c => {
            c.selectedChange = () => {
                this.checkChildrenSelected();
            }
        });
    }

    checkChildrenSelected(): void {
        let allValuesAreSame: boolean = true;
        let tmp = this.children[0].selected;
        for (const child of this.children) {
            if (child.selected !== tmp || child.childrenPartiallySelected) {
                this.childrenPartiallySelected = true;
                this.selected = false;
                allValuesAreSame = false;
                break;
            }
        }

        if (allValuesAreSame) {
            this.childrenPartiallySelected = false;
            this.selected = tmp;
        }

        this.raiseSelectedChangedEvent();
    }

    setSelected(selected: boolean): void {
        this.selected = selected;
        if (this.children != null) {
            this.children.forEach(c => {
                c.setSelected(this.selected);
            });
        }
        this.raiseSelectedChangedEvent();
    }

    raiseSelectedChangedEvent(): void {
        if (this.selectedChange) {
            this.selectedChange();
        } else {
            logError("SelectableBuTreeNode.selectedChange n'est pas écouté");
        }
    }

    selectedChange?: () => void;
    onSelectedChange(): void {
        this.setSelected(!this.selected);

        this.raiseSelectedChangedEvent();
    }
}