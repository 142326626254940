import { FloorModelEnum } from "src/app/core/model/data-model/enums/floor-model-enum";
import { SvgEntityTypesEnum } from "src/app/core/model/svg-model/svg-entity-type-enum";
import { BpSvgEntity } from "../../bp-svg-core-model/bp-svg-entity";
import { getBpEntities } from "../../bp-svg-core-model/bp-svg-entity-builder";
import { BpSvgDoor } from "./bp-svg-door";
import { BpSvgLabel } from "./bp-svg-label";
import { BpSvgMeasurementLabel } from "./bp-svg-measurement-label";
import { BpSvgPeopleLabel } from "./bp-svg-people-label";
import { BpSvgRoom } from "./bp-svg-room";
import { BpSvgRoomLabel } from "./bp-svg-room-label";
import { BpSvgWall } from "./bp-svg-wall";
import { BlueprintSvgEntityTypeEnum } from "./blueprint-svg-entity-type-enum";

export function getLayerEntities(layerId: FloorModelEnum, entities: any): BpSvgEntity[] {
    if (entities != null) {
        const layerEntities = entities.filter((x: { floorModelId: FloorModelEnum; })=> x.floorModelId === layerId);
        switch(layerId){
            case FloorModelEnum.Walls:
                return getWalls(layerEntities);
            case FloorModelEnum.Doors:
                return getDoors(layerEntities);
            case FloorModelEnum.Rooms:
                return getRooms(layerEntities);
            case FloorModelEnum.RoomLabels:
                return getRoomLabels(layerEntities);
            case FloorModelEnum.WorkplaceLabels:
                return getLabels(layerEntities, BlueprintSvgEntityTypeEnum.workplaceLabel);
            case FloorModelEnum.PeopleLabels:
                return getPeopleLabels(layerEntities);
            case FloorModelEnum.Measurement:
                return getMeasurementLabels(layerEntities);
            default:
                return getBpEntities(entities);
        }
    } else {
        return [];
    }
}

function getWalls(entities: any): BpSvgWall[] {
    let result = new Array<BpSvgWall>();
    if(entities){
        entities.forEach((element: { entityType: SvgEntityTypesEnum; entities: any; }) => {
            if(element.entityType === SvgEntityTypesEnum.g) {
                const groupEntities = getBpEntities(element.entities);
                // Un groupe cloison ne contient qu'une entité ligne
                const newWall = new BpSvgWall(groupEntities[0], element);
                result.push(newWall);
            }
        });
    }

    return result;
}

function getDoors(entities: any): BpSvgDoor[] {
    let result = new Array<BpSvgDoor>();
    if(entities){
        entities.forEach((element: { entityType: SvgEntityTypesEnum; }) => {
            if(element.entityType === SvgEntityTypesEnum.g) {
                const newDoor = new BpSvgDoor(element);
                result.push(newDoor);
            }
        });
    }

    return result;
}

function getRooms(entities: any): BpSvgRoom[] {
    let result = new Array<BpSvgRoom>();
    if(entities) {
        entities.forEach((element: { entityType: SvgEntityTypesEnum; entities: any; }) => {
            if(element.entityType === SvgEntityTypesEnum.g) {
                const groupEntities = getBpEntities(element.entities);
                // Un groupe surface ne contient qu'un path
                const newRoom = new BpSvgRoom(groupEntities[0], element);
                result.push(newRoom);
            }
        });
    }

    return result;
}

function getLabels(entities: any, blueprintSvgEntityType: BlueprintSvgEntityTypeEnum): BpSvgLabel[] {
    let result = new Array<BpSvgLabel>();
    if(entities) {
        entities.forEach((element: any) => {
            const newLabel = new BpSvgLabel(element, blueprintSvgEntityType);
            if (newLabel) {
                result.push(newLabel);
            }
        });
    }

    return result;
}

function getPeopleLabels(entities: any): BpSvgPeopleLabel[] {
    let result = new Array<BpSvgPeopleLabel>();
    if(entities) {
        entities.forEach((element: any) => {
            const newLabel = new BpSvgPeopleLabel(element);
            if (newLabel) {
                result.push(newLabel);
            }
        });
    }

    return result;
}

function getMeasurementLabels(entities: any): BpSvgMeasurementLabel[] {
    let result = new Array<BpSvgMeasurementLabel>();
    if(entities) {
        entities.forEach((element: any) => {
            const newLabel = new BpSvgMeasurementLabel(element);
            if (newLabel) {
                result.push(newLabel);
            }
        });
    }

    return result;
}

function getRoomLabels(entities: any): BpSvgRoomLabel[] {
    let result = new Array<BpSvgRoomLabel>();
    if(entities) {
        entities.forEach((element: { entityType: SvgEntityTypesEnum; }) => {
            if(element.entityType === SvgEntityTypesEnum.g) {
                const newLabel = new BpSvgRoomLabel(element);
                result.push(newLabel);
            }
        });
    }

    return result;
}
