import { CieUserGrantsView } from "src/app/core/model/data-model/policy/views/cie-user-grants-view";
import { buildModel } from "src/app/core/model/db-model/model-builder";

export class CieUserGrantsViewDTO extends CieUserGrantsView {
    companyName!: string;

    static override dbMap = {
        ...CieUserGrantsView.dbMap,
        companyName: 'companyName'
    }

    constructor(dtoData: any, tableDef: any[] = []) {
        super(dtoData);
        buildModel(this, dtoData, [CieUserGrantsViewDTO.dbMap].concat(tableDef));
    }
}