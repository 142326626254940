import { ZDbColumn } from '../tables/z-db-column';
import { ZColumnDbView } from '../../db-model/views/z-column-db-view';
import { ZScope } from '../tables/z-scope';

export class ZColumnView extends ZDbColumn {
    coViColumnDisplayName!: string;
    coViColumnCategoryDisplayName!: string;
    scope: ZScope;

    constructor(dtoData: any, tableDef: any[] = []) {
        super(dtoData, [ZColumnDbView].concat(tableDef));
        this.scope = new ZScope(dtoData);
    }
}