import { ViewsNames } from 'src/app/core/model/db-model/views-names-enum';
import { TaskFloorModelView } from './../../model/data-model/views/task-floor-model-view';
import { TablesSetsEnum } from './../../model/data-model/tables-sets-enum';
import { LayerTreeSet } from './../../model/data-model/sets/layer-tree-set';
import { ApiEndpoints } from './../api-endpoints';
import { Service } from "typedi";
import { ApiService } from "../api-service";

@Service({ global: true })  
export class BlueprintService extends ApiService {
    constructor() {
        super();
    }

    async loadFloorTaskData(taskId: number): Promise<any> {
        const result = await this.getAsync(this.endPoint(ApiEndpoints.floorTaskData(taskId)));
        if (result) {
            return result.payload;
        }
    }

    async loadFloorModelTree(): Promise<LayerTreeSet> {
        const result = await this.getSingleAsync<LayerTreeSet>(TablesSetsEnum.LayerTreeSet, this.endPoint(ApiEndpoints.floorModelView));
        if (result != null) {
            return result;
        }
        return new LayerTreeSet(null);
    }

    async loadGrantedOutsideProjectEditableLayers(): Promise<TaskFloorModelView[]> {
        return await this.getArrayAsync<TaskFloorModelView>(ViewsNames.TaskFloorModelView, this.endPoint(ApiEndpoints.grantedOutsideProjectEditableLayers));
      }
    
    async loadTaskGrantedEditableLayers(taskId: number): Promise<TaskFloorModelView[]> {
        return await this.getArrayAsync<TaskFloorModelView>(ViewsNames.TaskFloorModelView, this.endPoint(ApiEndpoints.taskGrantedEditableLayers(taskId)));
    }
}