@if (model) {
<div class="file-image" [class]="{editable: !model.readOnly, updated: model.value !== model.initialValue, editing: model.editing, isSvg: model.isSvg}">
    <div class="mandatory" [style.display]="model.inserting && !model.allowsNull && model.value.length === 0 ? 'block' : 'none'">*</div>
    <xc-secured-image class="img" [src]="model.value" (click)="click()"/>
    <input #fileInput class="file-input" type="file" [attr.accept]="model.acceptedFiles" (change)="onFileSelected()"/>
    @if (model.updating) {
        <p-progressSpinner ariaLabel="loading"/>
    }
</div>
}
