import { MaterialModule } from 'src/app/core/material.module';
import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StrategyToolbarVM } from '../../model/strategy-toolbar-vm';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule } from '@angular/forms';
import { MVVMEventDestroyLifeCycle } from 'src/app/components-lib/shared-model/mvvm-events-destroy-lifecycle';

@Component({
  selector: 'xc-strategy-toolbar',
  standalone: true,
  imports: [CommonModule, FormsModule, MaterialModule, DropdownModule],
  templateUrl: './strategy-toolbar.component.html',
  styleUrls: ['./strategy-toolbar.component.scss']
})
export class StrategyToolbarComponent extends MVVMEventDestroyLifeCycle {
  @Input() override model: StrategyToolbarVM | undefined;
}
