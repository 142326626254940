export class SliceAnonymousData {
    id: number;
    parentId: number;
    color: string;
    name: string;
    value: number;
    level: number;
    children: SliceAnonymousData[] = [];

    constructor(id: number, color: string, name: string, value: number, level = 1, parentId: number = 0) {
        this.id = id;
        this.color = color;
        this.name = name;
        this.value = value;
        this.level = level;
        this.parentId = parentId;
    }
}