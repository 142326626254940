import { Point } from "src/app/core/model/geometry-model/point.model";
import { SvgEntityTypesEnum } from "src/app/core/model/svg-model/svg-entity-type-enum";
import { SelectableSvgPath } from "../../svg/selectable-svg-path";
import { InteractionCommand } from "./interaction-command";
import { PathGizmo } from "../../gizmo/model/path-gizmo";
import { EntityInteraction } from "./entity-interaction";
import { SvgEntityPointStyleEnum } from "../../../shared/gizmos/model/svg-entity-point-style-enum";

export class PathInteraction extends EntityInteraction<SelectableSvgPath, PathGizmo> {
    // entityType: SvgEntityTypesEnum = SvgEntityTypesEnum.path;
    // selectionSet: SelectableSvgPath[] = [];
    // selectionInteraction: SelectionInteraction | undefined;
    // currentCommand: InteractionCommand;
    // updateGizmo: PathGizmo;

    constructor(command: InteractionCommand, gizmo: PathGizmo) {
        super(SvgEntityTypesEnum.path, command, gizmo);
        // this.currentCommand = command;
        // this.updateGizmo = gizmo;
        // this.selectionSet = gizmo.entities;
    }

    override async mouseDown(e: MouseEvent, hitPoint: Point): Promise<boolean> {
        throw new Error("Method not implemented.");
    }

    override async mouseMove(e: MouseEvent, hitPoint: Point): Promise<void> {
        if (this.currentCommand.isPathPointTranslationCommand()) {
            // Actualise la position courante de la souris pour le contrôle de l'attraction lors du prochain MouseMove
            //this.currentCommand.tempSvgMousePosition = hitPoint;

            this.updateGizmo.translateEndpoint(hitPoint, this.currentCommand.selectedGripsOptions.includes(SvgEntityPointStyleEnum.extension));

            return;
        }

        if (this.currentCommand.isEntityTranslationCommand() && this.currentCommand.initialSvgMousePosition) {
            const delta = hitPoint.minus(this.currentCommand.initialSvgMousePosition);
            this.updateGizmo.translatePaths(delta);
        }
    }

    override async mouseUp(e: MouseEvent, hitPoint: Point | undefined): Promise<void> {
        if (this.currentCommand.isPathPointTranslationCommand() && this.updateGizmo.single) {
            this.currentCommand.clear();
            if (hitPoint) {
                this.updateGizmo.translateEndpoint(hitPoint, this.currentCommand.selectedGripsOptions.includes(SvgEntityPointStyleEnum.extension));
            }
            this.updateGizmo.endEndpointTranslation();       
            return;         
        }

        if (this.currentCommand.isEntityTranslationCommand()) {
            if (hitPoint && this.currentCommand.gripClicked) {
                const delta = hitPoint.minus(this.currentCommand.gripClicked.point);
                this.updateGizmo.translatePaths(delta);
            }
            this.updateGizmo.endPathsTranslation();       
            this.updateGizmo.clearCurrent();   
            return;         
        }
    }

    override keyDown(e: KeyboardEvent): Promise<void> {
        throw new Error("Method not implemented.");
    }
    
}