import { RoomTable } from './../tables/room-table';
import { FloorDataTable } from './../tables/floor-data-table';
import { FloorTable } from './../tables/floor-table';
import { BuildingTable } from './../tables/building-table';
import { RoomAttributionTypeTable } from '../tables/room-attribution-type-table';
import { RoomAttributionTypeDbView } from './room-attribution-type-db-view';

export const FloorAttributionDbView = {
  databaseTableName: 'floor_attribution_view',
  buSiteId: BuildingTable.buSiteId,
  flBuildingId: FloorTable.flBuildingId,
  flId: FloorTable.flId,
  flDaTaskId: FloorDataTable.flDaTaskId,
  roAttributionTypeId: RoomTable.roAttributionTypeId,
  roAtTyName: RoomAttributionTypeTable.roAtTyName,
  roAtTyColor: RoomAttributionTypeTable.roAtTyColor,
  roAtTyDisplayNameId: RoomAttributionTypeTable.roAtTyDisplayNameId,
  roAtTyViDisplayName: RoomAttributionTypeDbView.roAtTyViDisplayName,
  flAtViArea: 'FlAtVi_Area',
  flAtViRoundedArea: 'FlAtVi_RoundedArea',
  roViIsCurrentState: "RoVi_IsCurrentState"
};