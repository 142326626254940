import { Container } from 'typedi';
import { FloorModelEnum } from "src/app/core/model/data-model/enums/floor-model-enum";
import { FloorWallStyle } from "src/app/core/model/data-model/tables/floor-wall-style";
import { EntitiesSelectionSet } from "../../../../blueprint-viewer-content-panel/itself/model/interaction/entities-selection-set";
import { BpSvgDoor } from "../../../../svg-entities/model/bp-svg-door";
import { BpSvgWall } from "../../../../svg-entities/model/bp-svg-wall";
import { BlueprintDoorsLayer } from "../../../../svg-entities/model/layers/blueprint-doors-layer";
import { EntitiesFormModel } from "./entities-form-model";
import { MatCheckboxChange } from '@angular/material/checkbox';
import { WallService } from 'src/app/core/services/backend-services/wall-service';
import { BpWallService } from 'src/app/core/services/backend-services/bp-wall-service';

export class WallFormVM implements EntitiesFormModel {
    hasPlanningTask: boolean = false;
    layerId: number = FloorModelEnum.Walls;
    themeRefreshRequested?: (() => void) | undefined;
    isEditable: boolean = false;

    selectedWalls: EntitiesSelectionSet = new EntitiesSelectionSet();
  
    sharedWidth: number = 0;
    sharedStyle: FloorWallStyle | undefined;
    sharedSpaceBound: boolean = false;
  
    wallStyles: FloorWallStyle[] = [];
    doorsLayer: BlueprintDoorsLayer | null;
  
    constructor(doorsLayer: BlueprintDoorsLayer | null) { 
        this.doorsLayer = doorsLayer;
    }

    async loadReferenceDatas(): Promise<void> {
        if (this.wallStyles.length === 0) {
            const s = Container.get(WallService);
            this.wallStyles = await s.downloadWallStyles();
        }
    }

    async initialize(walls: EntitiesSelectionSet, editable: boolean): Promise<void> {
        this.isEditable = editable;
        await this.loadReferenceDatas();
        this.selectedWalls = walls;
        this.updateCurrentValues();
    }

    onSelectionChange(): void {
        this.updateCurrentValues();
    }

    updateCurrentValues(): void {
        let widths = (this.selectedWalls.items as BpSvgWall[]).map(x=> Number(x.strokeWidth));
        widths = [...new Set(widths)];
        if (widths.length === 1) {
            this.sharedWidth = widths[0];
        } else {
            this.sharedWidth = 0;
        }

        let styles = (this.selectedWalls.items as BpSvgWall[]).map(x=> x.wallStyle);
        styles = [...new Set(styles)];
        if (styles.length === 1) {
            this.sharedStyle = this.wallStyles.find(x=> x.flWaStId === styles[0]);
        } else {
            this.sharedStyle = undefined;
        }

        let spaceBounds = (this.selectedWalls.items as BpSvgWall[]).map(x=> Boolean(x.isSpaceBound));
        spaceBounds = [...new Set(spaceBounds)];
        if (spaceBounds.length === 1) {
            this.sharedSpaceBound = spaceBounds[0];
        } else {
            this.sharedSpaceBound = false;
        }
    }

    wallStyleCompare( option: FloorWallStyle, value: FloorWallStyle ) : boolean {
        return option.flWaStId === value.flWaStId;
    }
    
    async onStyleChange(updateValue: FloorWallStyle) {
        const ids = this.selectedWalls.floorDataIds();
        const s = Container.get(BpWallService);
        const result = await s.updateWallsStyle(ids, updateValue.flWaStId);
        if (result && result.length > 0) {
            (this.selectedWalls.items as BpSvgWall[]).forEach(w => {
                w.setStyle(updateValue.flWaStId);
                w.dataStateId = result[0].dataStateId;
                w.stroke = result[0].stroke;
                w.setWidthAttribute(result[0].strokeWidth ?? 0);
                w.setSpaceBoundAttribute(result[0].isSpaceBound);
            });
        }
    }

    async onWidthChange(updateValue: number): Promise<void> {
        // TODO : 
        // Il est impossible de modifier réellement la largeur d'une cloison existante
        // ça n'est possible qu'au momement où on spécifie un nouveau cloisonnement
        // sur une cloison existante ça implique suppression de l'existant et création d'une nouvelle
        const ids = this.selectedWalls.floorDataIds();
        const s = Container.get(BpWallService);
        const result = await s.updateWallsWidth(ids, updateValue);
        if (result && result.length > 0) {
            const walls = result.filter(x=> x.floorModelId === FloorModelEnum.Walls);
            const doors = result.filter(x=> x.floorModelId === FloorModelEnum.Doors) as BpSvgDoor[];
            walls.forEach(wr => {
                const w = this.selectedWalls.items.find(x=> x.floorDataId === wr.floorDataId) as BpSvgWall;
                w.setWidthAttribute(updateValue);
                w.dataStateId = wr.dataStateId;
            });

            doors.forEach(dr => {
                // On remplace le graphisme actuel de la porte par sa mise à jour
                let d = this.doorsLayer?.data.find(x=> x.floorDataId === dr.floorDataId) as BpSvgDoor;
                d = dr;
            });
        }
    }

    async onSpaceBoundChange(e: MatCheckboxChange) {
        const ids = this.selectedWalls.floorDataIds();
        const s = Container.get(BpWallService);
        const result = await s.updateWallsSpaceBound(ids, e.checked);
        if (result && result.length > 0) {
            (this.selectedWalls.items as BpSvgWall[]).forEach(w => {
                w.dataStateId = result[0].dataStateId;
                w.isSpaceBound = e.checked;
            });
        }
    }
}