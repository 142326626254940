import { RoomSharingTable } from '../../db-model/tables/room-sharing-table';
import { DefinedModelBase } from '../defined-model-base';

export class RoomSharing extends DefinedModelBase {
    roShFloorDataId!: number;
    roShPerimeterId!: number;

    constructor(dtoData: any, tableDef: any[] = []) {
        super(dtoData, [RoomSharingTable].concat(tableDef));
    }
}