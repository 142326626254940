import { Ellipse } from "../geometry-model/ellipe.model";
import { Point } from "../geometry-model/point.model";
import { SvgEllipse } from "./svg-ellipse";

export class SvgEllipseService {
    static centerPoint(e: SvgEllipse): Point {
        return new Point(e.cx, e.cy);
    }

    static geometry(e: SvgEllipse): Ellipse {
        const a = e.transform ? e.transform.rotationAngle : 0;
        return new Ellipse(SvgEllipseService.centerPoint(e), e.rx, e.ry, -a);
    }
}