import { ZContextUITableUserColumnTable } from "../../db-model/tables/z-context-ui-table-user-column-table";
import { DefinedModelBase } from "../defined-model-base";

export class ZContextUITableUserColumn extends DefinedModelBase {
    coUITaUsCoId!: number;
    coUITaUsCoContextUITableUserId!: number;
    coUITaUsCoColumnName!: string; 
    coUITaUsCoColumnAlias!: string | null; 
    coUITaUsCoDisplayOrder!: number; 
    coUITaUsCoIsVisible!: boolean; 
    coUITaUsCoSortDirection!: number;

constructor(dtoData: any, tableDef: any[] = []) {
    super(dtoData, [ZContextUITableUserColumnTable].concat(tableDef));
}

}