import { SelectableSvgEntity } from "../../svg/selectable-svg-entity";
import { UndoCUDOpEnum } from "./undo-cud-op-enum";
import { UndoStateEnum } from "./undo-state-enum";

export class DrawingAction {
    entity: SelectableSvgEntity;
    prevState: SelectableSvgEntity | undefined;
    nextState: SelectableSvgEntity | undefined;
    status: UndoStateEnum = UndoStateEnum.pending;
    cudOp: UndoCUDOpEnum;
    
    constructor(e: SelectableSvgEntity, cudOp: UndoCUDOpEnum) { 
        this.entity = e;
        this.cudOp = cudOp;
        this.prevState = this.cloneState(e);
    }

    store(e: SelectableSvgEntity): boolean {
        this.nextState = this.cloneState(e);
        this.status = UndoStateEnum.restored;
        // return false if the entity has no changes
        return this.prevState !== this.nextState;
    }

    undo(): void {
        this.switchState(UndoStateEnum.undone);
    }

    redo(): void {
        this.switchState(UndoStateEnum.restored);
    }

    private switchState(status: UndoStateEnum): void {
        const tmp = this.prevState;
        Object.assign(this.entity, this.prevState);
        this.prevState = this.nextState;
        this.nextState = tmp;
        this.status = status;
    }

    private cloneState(e: SelectableSvgEntity): any {
        return e.clone();
    }
}