import { SimulationBusinessUnitTable } from "../../db-model/tables/simulation-business-unit-table";
import { DefinedModelBase } from "../defined-model-base";

export class SimulationBusinessUnit extends DefinedModelBase {
    siBuUnId!: number;
    siBuUnBusinessUnitId!: number;
    siBuUnColor!: string;
    siBuUnRootSimulation!: number;

    constructor(dtoData: any, tableDef: any[] = []) {
        super(dtoData, [SimulationBusinessUnitTable].concat(tableDef));
    }
}