import { Point } from '../geometry-model/point.model';
import { SvgTspan } from './svg-tspan.model';
import { SvgEntity } from './svg-entity.model';
import { SvgEntityTypesEnum } from './svg-entity-type-enum';

export class SvgText extends SvgEntity {
    x: number | undefined;
    y: number | undefined;
    fontSize: number = 1;
    originalFontSize: number = 1;
    textAnchor: string | undefined = "start";
    text: string | undefined;
    tSpans: SvgTspan[] = [];

    constructor(dtoData: any) {
        super(dtoData);
        this.x = SvgEntity.getNumber(dtoData, "x");
        this.y = SvgEntity.getNumber(dtoData, "y");
        this.textAnchor = SvgEntity.getValue(dtoData, "textAnchor", "start", false);
        this.fontSize = SvgEntity.getNumber(dtoData, "fontSize", 1);
        this.originalFontSize = this.fontSize;
        this.text = SvgEntity.getValue(dtoData, "text", undefined, false);
        if (dtoData.tSpans) {
            dtoData.tSpans.forEach((ts: any) => {
                this.tSpans.push(new SvgTspan(ts));
            });
        }
    }

    position(): Point {
        return new Point(this.x, this.y);
    }

    moveTo(newPosition: Point): void {
        this.x = newPosition.x;
        this.y = newPosition.y;
    }

    static fromValues(x: number, y: number, text: string, fontSize: number): SvgText {
        return new SvgText({x: x, y: y, text: text, fontSize: fontSize, entityType: SvgEntityTypesEnum.text});
    }
}
