@if (model) {
    <div class="flex flex-column flex-wrap align-content-center w-full">
        <h3>Import d'un fichier annuaire des entités</h3>
        <p-fileUpload 
            #ctrl
            mode="basic" 
            chooseLabel="Sélectionnez un fichier csv" 
            name="buUploadData" 
            [auto]="true"
            customUpload="true"
            accept=".csv" 
            [maxFileSize]="1000000" 
            (uploadHandler)="model.uploadHandler($event, ctrl)">
        </p-fileUpload>
        <p>{{model.importedFile}}</p>
        <p>
            @for (m of model.uploadResult; track $index) {
                {{m}}
                <br>
            }
        </p>
    </div>
} 