@if (model) {
    <div class="h-full overflow-auto max-w-30rem">
        <p-dataView #dv [value]="model.perimeters" [emptyMessage]="'il n\'existe pas de périmètre à accès restreint'">
            <ng-template pTemplate="header">
                <div class="flex flex-column">
                    <div class="col-12 p-0 flex justify-content-between align-items-center">
                        <span>Périmètre</span>
                        <span>Habilité</span>
                    </div>
                </div>
            </ng-template>
            <ng-template let-perimeters pTemplate="list">
                <div class="grid grid-nogutter">
                    @for (perimeter of model.perimeters; track $index) {
                        <div class="col-12 flex justify-content-between p-2 align-items-center">
                            <span>{{ perimeter.perimeter.peName }}</span>
                            <p-checkbox [(ngModel)]="perimeter.isRoleGranted" 
                                (onChange)="model.grantChange($event, perimeter)" 
                                [binary]="true" 
                                [inputId]="perimeter.perimeter.peId + 'rolegrant'">
                            </p-checkbox>
                        </div>
                    }
                </div>
            </ng-template>
        </p-dataView>
    </div>
}
