import { SimulationOccupationTable } from "../../db-model/tables/simulation-occupation-table";
import { DefinedModelBase } from "../defined-model-base";

export class SimulationOccupation extends DefinedModelBase {
    siOcPhoto!: number;
    siOcSimulationFloor!: number;
    siOcSimulationBusinessUnit!: number;
    siOcArea!: number;

    constructor(dtoData: any, tableDef: any[] = []) {
        super(dtoData, [SimulationOccupationTable].concat(tableDef));
    }
}