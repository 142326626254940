import { FloorAllocationDbView } from '../../db-model/views/floor-allocation-db-view';
import { DefinedModelBase } from '../defined-model-base';

export class FloorAllocationView extends DefinedModelBase {
    buSiteId!: number;
    flBuildingId!: number;
    flId!: number;
    flDaTaskId!: number;
    roAlBusinessUnitId!: number;
    buUnName!: string;
    buUnCode!: string;
    buUnColor!: string;
    flAlViArea!: number;
    flAlViRoundedArea!: number;
    roViIsCurrentState!: boolean;

    constructor(dtoData: any) {
        super(dtoData, [FloorAllocationDbView]);
    }
}