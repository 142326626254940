import { FacilityCompanyTable } from "../../db-model/tables/facility-company-table";
import { DefinedModelBase } from "../defined-model-base";

export class FacilityCompany extends DefinedModelBase {
    faCoId!: number;
    faCoName!: string;
    faCoIsActive!: boolean;

    constructor(dtoData: any, tableDef: any[] = []) {
        super(dtoData, [FacilityCompanyTable].concat(tableDef));
    }
}