import { StrategyToolbarComponent } from '../../../nested/toolbar/view/strategy-toolbar/strategy-toolbar.component';
import { StrategyEditorVM } from '../../model/strategy-editor-vm';
import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditorMainContainerComponent } from '../editor-main-container/editor-main-container.component';

@Component({
  selector: 'xc-strategy-editor',
  standalone: true,
  imports: [CommonModule, StrategyToolbarComponent, EditorMainContainerComponent],
  templateUrl: './strategy-editor.component.html',
  styleUrls: ['./strategy-editor.component.scss']
})
export class StrategyEditorComponent {
  @Input() model: StrategyEditorVM | undefined;
}
