import { Component, Input } from '@angular/core';
import { MVVMEventDestroyLifeCycle } from 'src/app/components-lib/shared-model/mvvm-events-destroy-lifecycle';
import { LeaseItems } from '../../model/lease_items';
import { DynTableComponent } from 'src/app/components-lib/dyn-table/dyn-table.component';

@Component({
  selector: 'xc-lease-items',
  standalone: true,
  imports: [DynTableComponent],
  templateUrl: './lease-items.component.html',
  styleUrl: './lease-items.component.scss'
})
export class LeaseItemsComponent extends MVVMEventDestroyLifeCycle {
  @Input() override model: LeaseItems | undefined;
}
