import { Grip } from "src/app/components-lib/svg/grips/grip";
import { Point } from "src/app/core/model/geometry-model/point.model";
import { DxfSvgEntity } from "../svg/dxf-svg-entity";

export class InteractionCommand {
    static noneCommand = "None";

    static selectingSingleItemCommand = "Sélection unitaire";
    static unSelectingSingleItemCommand = "Déselection unitaire";
    static drawingRectangularSelectionCommand = "Tracé d'un rectangle de sélection";

    static lineEndpointTranslationCommand = "Déplacement de l'extrémité d'une ligne";
    static pathPointTranslationCommand = "Déplacement d'un point d'une polyligne";
    
    static entityTranslationCommand = "Déplacement d'un élément";

    static lineInsertionCommand = "Insertion d'une ligne";
    static pathInsertionCommand = "Insertion d'une polyligne";
    static circleInsertionCommand = "Insertion d'un cercle";
    static ellipseInsertionCommand = "Insertion d'une ellipse";
    static textInsertionCommand = "Insertion d'un text";

    static textInputCommand = "Saisie d'un text";

    static gridTranslationCommand = "Déplacement de la grille";
    static gridRotationCommand = "Rotation de la grille";
    static gridUnitLengthCommand = "Taille unitaire de la grille";

    private name: string = InteractionCommand.noneCommand;
    initialSvgMousePosition: Point | undefined;
    gripClicked: Grip | undefined;
    entityClicked: DxfSvgEntity | undefined;
    tempSvgMousePosition: Point | undefined;
    magnetAttraction: number = 0;

    /**
     * Vide la commande en cours (name, tempSvgMousePosition et magnetAttraction)
     */
    clear(): void {
        this.name = InteractionCommand.noneCommand;
        this.entityClicked = undefined;
        this.gripClicked = undefined;
        this.initialSvgMousePosition = undefined;
    }

    set(commandName: string): void {
        this.name = commandName;
    }

    isCommand(name: string): boolean {
        return this.name === name;
    }

    isNoneCommand(): boolean {
        return this.name === InteractionCommand.noneCommand;
    }

    isSelectingSingleItemCommand(): boolean {
        return this.isCommand(InteractionCommand.selectingSingleItemCommand);
    }

    isUnselectingSingleItemCommand(): boolean {
        return this.isCommand(InteractionCommand.unSelectingSingleItemCommand);
    }

    isDrawingRectangularSelectionCommand(): boolean {
        return this.isCommand(InteractionCommand.drawingRectangularSelectionCommand);
    }

    isLineEndpointTranslationCommand(): boolean {
        return this.isCommand(InteractionCommand.lineEndpointTranslationCommand);
    }

    isPathPointTranslationCommand(): boolean {
        return this.isCommand(InteractionCommand.pathPointTranslationCommand);
    }

    isEntityTranslationCommand(): boolean {
        return this.isCommand(InteractionCommand.entityTranslationCommand);
    }

    isLineInsertionCommand(): boolean {
        return this.isCommand(InteractionCommand.lineInsertionCommand);
    }

    isPathInsertionCommand(): boolean {
        return this.isCommand(InteractionCommand.pathInsertionCommand);
    }

    isCircleInsertionCommand(): boolean {
        return this.isCommand(InteractionCommand.circleInsertionCommand);
    }

    isEllipseInsertionCommand(): boolean {
        return this.isCommand(InteractionCommand.ellipseInsertionCommand);
    }

    isTextInsertionCommand(): boolean {
        return this.isCommand(InteractionCommand.textInsertionCommand);
    }

    isTextInputCommand(): boolean {
        return this.isCommand(InteractionCommand.textInputCommand);
    }

    isGridTranslationCommand(): boolean {
        return this.isCommand(InteractionCommand.gridTranslationCommand);
    }

    isGridRotationCommand(): boolean {
        return this.isCommand(InteractionCommand.gridRotationCommand);
    }

    isGridUnitLengthCommand(): boolean {
        return this.isCommand(InteractionCommand.gridUnitLengthCommand);
    }

    isSelectionCommand(): boolean {
        return this.isDrawingRectangularSelectionCommand() ||
        this.isSelectingSingleItemCommand() ||
        this.isUnselectingSingleItemCommand();
    }

    isInsertionCommand(): boolean {
        return this.isLineInsertionCommand() ||
        this.isPathInsertionCommand() ||
        this.isCircleInsertionCommand() ||
        this.isEllipseInsertionCommand() ||
        this.isTextInsertionCommand();
    }

    isGridCommand(): boolean {
        return this.isGridTranslationCommand() ||
        this.isGridRotationCommand() ||
        this.isGridUnitLengthCommand();
    }

}