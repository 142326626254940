import { Component, Input } from '@angular/core';
import { MVVMEventDestroyLifeCycle } from 'src/app/components-lib/shared-model/mvvm-events-destroy-lifecycle';
import { FloorsVM } from '../floors-vm';
import { FormsModule } from '@angular/forms';
import { FloorBlueprintEditorComponent } from '../../floor-blueprint/container/view/floor-blueprint-editor/floor-blueprint-editor.component';
import { FloorDashboardComponent } from '../floor-dashboard/floor-dashboard.component';

@Component({
  selector: 'xc-floors',
  standalone: true,
  imports: [
    FormsModule, 
    FloorBlueprintEditorComponent,
    FloorDashboardComponent
  ],
  templateUrl: './floors.component.html',
  styleUrl: './floors.component.scss'
})
export class FloorsComponent extends MVVMEventDestroyLifeCycle {
  @Input() override model: FloorsVM | undefined;
}
