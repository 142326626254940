import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SvgPointDebugVM } from '../model/svg-point-debug-vm';

@Component({
  selector: 'g[xc-svg-point-debug]',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './svg-point-debug.component.svg',
  styleUrls: ['./svg-point-debug.component.scss']
})
export class SvgPointDebugComponent {
  @Input() model: SvgPointDebugVM | undefined;
}
