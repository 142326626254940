import { PointPosition } from './point-position.model';
import { GeometricElementType } from './geometric-element-type.model';
import { GeometricElement } from './geometric-element.model';
import { Point } from './point.model';
import { Vector } from './vector.model';
import { QuadrantPosition } from './quadrant-position.model';

export class Segment implements GeometricElement {
    startPoint: Point;
    endPoint: Point;
    elementType = GeometricElementType.Segment;

    constructor(startPoint: Point, endPoint: Point) {
        this.startPoint = startPoint;
        this.endPoint = endPoint;
    }
    
    static fromDto(dtoData: any): Segment | null {
        if(dtoData) {
            const startPoint = new Point(dtoData.startPoint.x, dtoData.startPoint.y);
            const endPoint = new Point(dtoData.endPoint.x, dtoData.endPoint.y);
            return new Segment(startPoint, endPoint);
        }

        return null;
    }

    static null(): Segment {
        return new Segment(Point.origin(), Point.origin());
    }

    equals(s: Segment): boolean{
        return this.startPoint.equals(s.startPoint) && this.endPoint.equals(s.endPoint);
    }

    hasNaN(): boolean {
        return this.startPoint.hasNaN() || this.endPoint.hasNaN();
    }

    points(): Point[] {
        return [ this.startPoint, this.endPoint ];
    }

    midPoint(): Point {
        return new Point((this.startPoint.x + this.endPoint.x) / 2, (this.startPoint.y + this.endPoint.y) / 2);
    }

    otherEnd(p: Point): Point {
        if (p.equals(this.startPoint)) {
            return this.endPoint;
        }

        return this.startPoint;
    }


    transform(rotationCenter: Point, rotationAngleRad: number, translate: Vector): Segment {
        const sp = this.startPoint.transform(rotationCenter, rotationAngleRad, translate);
        const ep = this.endPoint.transform(rotationCenter, rotationAngleRad, translate);
        return new Segment(sp, ep);
    }

    getDeterminant(p: Point): number {
        return (this.endPoint.x - this.startPoint.x) * (p.y - this.startPoint.y) - (this.endPoint.y - this.startPoint.y) * (p.x - this.startPoint.x);
    }

    getPointPosition(p: Point): PointPosition {
        const determinant = this.getDeterminant(p);
        if (determinant === 0)
        {
            return PointPosition.On;
        }

        if (determinant > 0.0)
        {
            return PointPosition.Left;
        }

        return PointPosition.Right;
    }

    length(): number {
        return this.endPoint.distanceTo(this.startPoint);
    }

    angleWith(s: Segment): number {
        return this.vector().angleWith(s.vector());
    }

    // Retourne un point orthogonalement décalé par rapport au milieu du segment
    getOffsetMidPoint(offsetValue: number): Point {
        return this.getOrthogonalOffset(this.midPoint(), offsetValue);
    }

    // Retourne le point de décalage orthogonal d'un point d'un segment
    getOrthogonalOffset(p: Point, offsetValue: number): Point {
        // https://stackoverflow.com/questions/1560492/how-to-tell-whether-a-point-is-to-the-right-or-left-side-of-a-line
        // https://stackoverflow.com/questions/17195055/calculate-a-perpendicular-offset-from-a-diagonal-line
        const orthogonalVector = this.vector().getOrthogonalVector();
        const unit = orthogonalVector.getUnit();
        return new Point(p.x + (offsetValue * unit.u), p.y + (offsetValue * unit.v));
    }

    // Retourne un vecteur à partir du segment
    vector(): Vector {
        return new Vector(this.endPoint.x - this.startPoint.x, this.endPoint.y - this.startPoint.y);
    }

    // Retourne la projection orthogonale d'un point sur un segment
    getOrthogonalProjection(p: Point): Point {
        const x = this.startPoint.x;
        const y = this.startPoint.y;
        const x2 = this.endPoint.x;
        const y2 = this.endPoint.y;
        const x3 = p.x;
        const y3 = p.y;
        const num = (0.0 - ((x - x3) * (x2 - x) + (y - y3) * (y2 - y))) / (Math.pow(x2 - x, 2.0) + Math.pow(y2 - y, 2.0));
        const x4 = x + num * (x2 - x);
        const y4 = y + num * (y2 - y);
        return new Point(x4, y4);
    }

    /**
     * Calcule l'angle du segment avec l'horizontale
     * @returns angle retourné en radians
     */
    angle(): number {
        const source2 = new Segment(this.startPoint, new Point(this.startPoint.x + 1.0, this.startPoint.y));
        return this.vector().angleWith(source2.vector());
    }

    contains(p: Point, tolerance: number = Number.EPSILON): boolean {
        if (this.isAnEndPoint(p, tolerance))
        {
            return true;
        }

        const num = this.length();
        const orthogonalProjection = this.getOrthogonalProjection(p);
        const num2 = this.startPoint.distanceTo(orthogonalProjection);
        const num3 = this.endPoint.distanceTo(orthogonalProjection);
        if (p.distanceTo(orthogonalProjection) > tolerance)
        {
            return false;
        }

        return num2 + num3 - num <= tolerance / 2.0;
    }

    containsAll(pts: Point[], tolerance: number = Number.EPSILON): boolean {
        let result = true;
        pts.forEach(pt => {
            if (!this.contains(pt, tolerance)) {
                result = false;
                return;
            }
        });
        return result;
    }

    isLeftHand(p: Point): boolean {
        if (this.contains(p))
        {
            return false;
        }

        return this.getDeterminant(p) > 0.0;
    }

    isRightHand(p: Point): boolean {
        if (this.contains(p))
        {
            return false;
        }

        return this.getDeterminant(p) < 0.0;
    }

    isAbsoluteRightHand(p: Point): boolean {
        if (this.contains(p))
        {
            return false;
        }

        const num = ((!this.isRigthOriented()) ? this.reversed().getDeterminant(p) : this.getDeterminant(p));
        return num < 0.0;
    }

    isRigthOriented(): boolean {
        const quadrantOrientation = this.getQuadrantOrientation();
        if (quadrantOrientation != QuadrantPosition.TopRight && quadrantOrientation != QuadrantPosition.BottomRight)
        {
            return quadrantOrientation == QuadrantPosition.RightAxis;
        }

        return true;
    }

    getQuadrantOrientation(): QuadrantPosition {
        return this.endPoint.getQuadrantPositionRelativeTo(this.startPoint);
    }

    isAnEndPoint(p: Point, tolerance: number = Number.EPSILON): boolean {

        if (tolerance === Number.EPSILON)
        {
            if (!(p.equals(this.startPoint)))
            {
                return p.equals(this.endPoint);
            }

            return true;
        }

        if (!p.isNear(this.startPoint, tolerance))
        {
            return p.isNear(this.endPoint, tolerance);
        }

        return true;
    }

    reversed(): Segment {
        return new Segment(this.endPoint, this.startPoint);
    }

    getIntersect(s: Segment): Point | null {
        const x = this.startPoint.x;
        const y = this.startPoint.y;
        const x2 = this.endPoint.x;
        const y2 = this.endPoint.y;
        const x3 = s.startPoint.x;
        const y3 = s.startPoint.y;
        const x4 = s.endPoint.x;
        const y4 = s.endPoint.y;
        const num = ((x - x3) * (y3 - y4) - (y - y3) * (x3 - x4)) / ((x - x2) * (y3 - y4) - (y - y2) * (x3 - x4));
        const num2 = (0.0 - ((x - x2) * (y - y3) - (y - y2) * (x - x3))) / ((x - x2) * (y3 - y4) - (y - y2) * (x3 - x4));
        if (!(0.0 <= num) || !(num <= 1.0))
        {
            return null;
        }

        if (!(0.0 <= num2) || !(num2 <= 1.0))
        {
            return null;
        }

        const x5 = x + num * (x2 - x);
        const y5 = y + num * (y2 - y);
        return new Point(x5, y5);
    }

    // Retourne le point sur le segment situé à une distance donnée à partir d'une des extrémités
    getEndPointAt(distance: number, fromEndPoint: boolean): Point {
        // const num: number = this.length();
        // const num2: number = distance / num;
        let point: Point = this.startPoint;
        let point2: Point = this.endPoint;
        if (fromEndPoint)
        {
            point = this.endPoint;
            point2 = this.startPoint;
        }

        return this.getPointAt(point, point2, distance);
        // const x: number = (1.0 - num2) * point.x + num2 * point2.x;
        // const y: number = (1.0 - num2) * point.y + num2 * point2.y;
        // return new PocPoint(x, y);
    }

    getPointAt(fromPoint: Point, towardEndPoint: Point, distance: number): Point {
        const num: number = fromPoint.distanceTo(towardEndPoint);
        if (num === 0) {
            return fromPoint;
        }

        const num2: number = distance / num;

        const x: number = (1.0 - num2) * fromPoint.x + num2 * towardEndPoint.x;
        const y: number = (1.0 - num2) * fromPoint.y + num2 * towardEndPoint.y;
        return new Point(x, y);
    }

    // Retourne un segment étiré des deux côtés de la longeur désirée
    stretched(length: number): Segment {

        if (Math.abs(length) === Number.EPSILON)
        {
            return this;
        }

        // length doit toujours être négative
        if (length > 0)
        {
            length = -length;
        }

        var startPoint = this.getEndPointAt(length, false);
        var endPoint = this.getEndPointAt(length, true);

        return new Segment(startPoint, endPoint);
    }

    stretchedStart(length: number): Segment {
        if (Math.abs(length) === Number.EPSILON)
        {
            return this;
        }

        // length doit toujours être négative
        if (length > 0)
        {
            length = -length;
        }

        var startPoint = this.getEndPointAt(length, false);
        return new Segment(startPoint, this.endPoint);
    }

    stretchedEnd(length: number): Segment {
        if (Math.abs(length) === Number.EPSILON)
        {
            return this;
        }

        // length doit toujours être négative
        if (length > 0)
        {
            length = -length;
        }

        var endPoint = this.getEndPointAt(length, true);
        return new Segment(this.startPoint, endPoint);
    }

    ajustEnd(length: number): Segment {
        if (Math.abs(length) === Number.EPSILON)
        {
            return this;
        }

        var endPoint = this.getEndPointAt(length, false);
        return new Segment(this.startPoint, endPoint);
    }

    inverted(): Segment {
        return new Segment(this.endPoint, this.startPoint);
    }

    orientedFrom(p: Point): Segment {
        if (p.equals(this.startPoint)) {
            return this;
        }
        return this.inverted();
    }

    isColinearTo(s: Segment): boolean {
        return this.vector().isColinearTo(s.vector());
    }

    nearestEndPoint(p: Point): Point {
        const startDistance = p.distanceTo(this.startPoint);
        const endDistance = p.distanceTo(this.endPoint);
        if (startDistance < endDistance) {
            return this.startPoint;
        }
        return this.endPoint;
    }

    getOverlapPoints(s: Segment, tolerance: number = Number.EPSILON): Point[] {
        const result: Point[] = [];
        const itemspInMe = this.contains(s.startPoint, tolerance); 
        if (itemspInMe) result.push(s.startPoint);
        const itemepInMe = this.contains(s.endPoint, tolerance); 
        if (itemepInMe) result.push(s.endPoint);
        const spInItem = s.contains(this.startPoint, tolerance); 
        if (spInItem) result.push(this.startPoint);
        const epInItem = s.contains(this.endPoint, tolerance);
        if (epInItem) result.push(this.endPoint);

        // si les segments sont entièrement superposés, le résultat contient 4 points
        // si le résultat contient 2 points :
        //   --> si la distance entre les deux points est égale à zéro, les segments sont dans le prolongement l'un de l'autre
        //   --> sinon ils sont partiellement superposés
        return result;
    }

    getOverlapSegment(s: Segment, tolerance: number = Number.EPSILON): Segment| null {
        const op = this.getOverlapPoints(s, tolerance);
        if (op.length === 4) return s;
        if (op.length === 2) {
            if (op[0].distanceTo(op[1]) > 0) {
                return new Segment(op[0], op[1]);
            }
        }
        return null;
    }

    rounded(decimalsCount: number = 3): Segment {
        return new Segment(this.startPoint.rounded(decimalsCount), this.endPoint.rounded(decimalsCount));
    }
}