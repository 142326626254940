import { TaskInsertingProcess } from './../../projects-schedule/model/task-inserting-process';
import { MobilityProjectTreeNode } from "../../../side-panel/shared-model/mobility-project-tree-node";
import { GanttRootProjectContainerVM } from "./gantt-root-project-container-vm";
import { ScheduleBase } from "./schedule-base";
import { MobilityProjectsGanttTreeEventsEnum } from "../../../side-panel/projects-gantt/model/mobility-projects-gantt-tree-events-enum";
import { GanttSvgTaskVM } from "./gantt-svg-task-vm";
import { TaskLinkView } from "src/app/core/model/data-model/views/task-link-type-view";
import { HtmlEcsyConstants } from "src/app/core/model/html-model/html-ecsy-constants.model";
import { ScheduleEventsEnum } from "./schedule-events-enum";
import { DateUtils } from "src/app/core/model/static-functions/date-utils";
import { Point } from "src/app/core/model/geometry-model/point.model";
import { TaskTypeEnum } from 'src/app/core/model/data-model/enums/task-type-enum';
import { TaskMovingProcess } from '../../projects-schedule/model/task-moving-process';
import { TaskExtensionProcess } from '../../projects-schedule/model/task-extension-process';
import { TaskLinkingProcess } from '../../projects-schedule/model/task-linking-process';
import { TaskService } from 'src/app/core/services/backend-services/task-service';
import Container from 'typedi';
import { TaskLinkingDetection } from '../../projects-schedule/model/task-linking-detection';
import { MobilityTemplateTreeNode } from '../../../side-panel/shared-model/mobility-template-tree-node';
import { toastError, toastInfo, toastWarn } from 'src/app/core/services/toast-service';

export class GanttScheduleBaseVM extends ScheduleBase {
    ganttRootProjects: GanttRootProjectContainerVM[] = [];
    tasksContainerTop: number = 0;
    yScrollOffset: number = 0;
    taskDefaultHeight: number = 0;
    taskInsertingProcess: TaskInsertingProcess;
    taskMovingProcess: TaskMovingProcess;
    taskRangeProcess: TaskExtensionProcess;
    taskLinkingProcess: TaskLinkingProcess;

    constructor(startDate: Date, duration: number) {
        super(startDate, duration);

        this.taskInsertingProcess = new TaskInsertingProcess();
        this.taskMovingProcess = new TaskMovingProcess();
        this.taskRangeProcess = new TaskExtensionProcess();
        this.taskLinkingProcess = new TaskLinkingProcess();

        this.addEventListener(MobilityProjectsGanttTreeEventsEnum.projectsTreeScrolled, (scrollTop: number) => {
            this.yScrollOffset = scrollTop;
        });

        this.addEventListener(MobilityProjectsGanttTreeEventsEnum.refreshProjectRequested, (rootProject: MobilityProjectTreeNode, parentProject: MobilityProjectTreeNode) => {
            const container = this.ganttRootProjects.find(x=> x.rootProject.key === rootProject.key);
            if (!container) {
                toastWarn("Impossible d'actualiser l'affichage, merci de rechargez la page");
                return;
            }
            // Actualise le contenu du container de projet
            container.refreshContent();
            // Actualise les décalages verticaux
            this.setYOffset(1, container.svgRootProject.children);

            // Actualise les liens du projet parent
            const svgItem = container.svgRootProject.find(parentProject.data.taId);
            svgItem?.updateLinks();
        });

        this.addEventListener(MobilityProjectsGanttTreeEventsEnum.addScheduleProjectRequested, (project: MobilityProjectTreeNode) => {
            // Le conteneur est ajouté. Aucun lien n'existe à ce stade
            const newContainer = new GanttRootProjectContainerVM(project, [], this.taskDefaultHeight, this.startDate, this.currentUnitWidth);
            this.ganttRootProjects.push(newContainer);
            // Les conteneurs sont retriés par date de début
            this.ganttRootProjects = this.ganttRootProjects.sort((a, b) => a.startDate.getTime() - b.startDate.getTime());
            // Actualise les décalages verticaux
            this.setYOffset(1, newContainer.svgRootProject.children);
        });
    }

    initialize(rootProjects: MobilityProjectTreeNode[], links: TaskLinkView[], taskDefaultHeight: number): void {
        this.ganttRootProjects.splice(0);
        this.taskDefaultHeight = taskDefaultHeight;
        rootProjects.forEach(p => {
            const pLinks = links.filter(x=> x.taRootTaskId === p.data.taId);
            this.ganttRootProjects.push(new GanttRootProjectContainerVM(p, pLinks, taskDefaultHeight, this.startDate, this.currentUnitWidth));
        });

        // Scroll jusqu'à aujourd'hui
        const todayCol = document.getElementsByClassName('schedule-today-col');
        if (todayCol.length === 1) {
            todayCol[0].scrollIntoView({behavior: "smooth", block: 'nearest', inline: "center"});
        }
    }

    override setScale(zoomDelta: number): void {
        super.setScale(zoomDelta);

        this.ganttRootProjects?.forEach(p => {
            p.setScale(this.currentUnitWidth);
        });
    }

    setRootYOffset(): void {
        this.ganttRootProjects.forEach(p => {
            this.setYOffset(1, p.svgRootProject.children);
        });
    }

    setYOffset(indexPos: number, svgTasks: GanttSvgTaskVM[]): number {
        svgTasks.forEach(t => {
            t.setYOffset(indexPos);
            if (t.node.expanded) {
                indexPos = this.setYOffset(indexPos + 1, t.children);
            } else {
                indexPos ++;
            }
        });
        return indexPos;
    }

    isToday(d: Date): boolean {
        return DateUtils.equals(DateUtils.today(), d);
    }

    processing(): boolean {
        return this.taskInsertingProcess?.processing ||
        this.taskMovingProcess?.processing ||
        this.taskRangeProcess?.processing;
    }

    find(taskId: number): GanttSvgTaskVM | undefined {
        let result: GanttSvgTaskVM | undefined = undefined;
        for (const t of this.ganttRootProjects) {
            result = t.svgRootProject.find(taskId);
            if (result) break;
        }
        return result;
    }

    override calculateGrid(zoomDelta: number): void {
        // Interdit le scaling pendant les opérations sur les tâches
        if (this.processing()) return;
        super.calculateGrid(zoomDelta);
    }

    async saveDatesAndDurations(rootId: number): Promise<void> {
        // Récupère un tableau des tâches modifiées
        const container = this.ganttRootProjects.find(x=> x.svgRootProject.task.taId === rootId);
        if (!container) return;
        const valuesToUpdate = this.getDateUpdatedTasks(container.svgRootProject);

        if (valuesToUpdate.length === 0) return;
        
        const s = Container.get(TaskService);
        const result = await s.updateDatesAndDurations(valuesToUpdate);
        if (!result) {
            toastError("Une erreur s'est produite pendant la mise à jour");
        } else {
            toastInfo(`Les débuts et/ou durées ont été actualisées sur ${valuesToUpdate.length} élément(s)`)
            // Valide la mise à jour sur les éléments gantt
            container.svgRootProject.validateSave();
        }
    }

    getDateUpdatedTasks(task: GanttSvgTaskVM): { taskId: number, startDate: Date, duration: number }[] {
        const result: { taskId: number, startDate: Date, duration: number }[] = [];

        if (task.hasDatesUpdates()) {
            // S'il s'agit d'un modèle il faut inverser le décalage de date introduit au chargement
            let startDate = task.task.taStartDate
            if (task.task.taIsTemplate) {
                startDate = DateUtils.addDays(startDate, -(task.node as MobilityTemplateTreeNode).dayOffset);
            }
            result.push({ taskId: task.task.taId, startDate: startDate, duration: task.task.taDuration });
        }

        if (task.children.length > 0) {
            task.children.forEach(t => {
                result.push(...this.getDateUpdatedTasks(t));
            });
        }

        return result;
    }

    async mouseClick(e: MouseEvent): Promise<void> {
        if (this.taskInsertingProcess.processing) {
            const htmlNodes = document.elementsFromPoint(e.clientX, e.clientY);

            // L'utilisateur n'a pas indiqué qu'il veut créer un nouveau projet
            // Il s'agit donc de l'insertion d'une tâche dans un projet existant
            if (!this.taskInsertingProcess.dto?.startNewProject) {
                // On recherche en premmier lieu si l'utilisateur a cliqué sur un projet
                const taskElement = htmlNodes.find(x=> x.attributes.getNamedItem('task-id') && x.attributes.getNamedItem('task-type-id')?.value === TaskTypeEnum.Project.toString());
                if (taskElement) {
                    // Référence le projet cible en vue du traitement d'insertion
                    this.taskInsertingProcess.targetProjectId = Number(taskElement.attributes.getNamedItem('task-id')!.value)
                } else {
                    toastWarn("Cliquez sur le projet dans lequel vous voulez ajouter la tâche");
                    return;
                }
            }

            // L'utilisateur a indiqué qu'il veut créer un nouveau projet
            // Ce projet peut être un projet racine ou venir s'insérer dans un projet existant
            // On recherche un éventuel projet sous la souris
            const taskElement = htmlNodes.find(x=> x.attributes.getNamedItem('task-id') && x.attributes.getNamedItem('task-type-id')?.value === TaskTypeEnum.Project.toString());
            if (taskElement) {
                // Référence le projet cible en vue du traitement d'insertion
                this.taskInsertingProcess.targetProjectId = Number(taskElement.attributes.getNamedItem('task-id')!.value)
            }
            
            // On recherche ensuite la colonne du jour cliqué
            const dayElement = htmlNodes.find(x=> x.attributes.getNamedItem('epoch-number'));
            if (dayElement) {
                const epochNumber = Number(dayElement.attributes.getNamedItem('epoch-number')!.value)
                const d = new Date(epochNumber * 1000);
                const createdTask = await this.taskInsertingProcess.createTask(d);
                if (createdTask != null) {
                    this.emitEventAsync(ScheduleEventsEnum.taskCreated, createdTask);
                }
            }

            return;
        }

        const htmlNodes = document.elementsFromPoint(e.clientX, e.clientY);
        const taskElement = htmlNodes.find(x=> x.attributes.getNamedItem(HtmlEcsyConstants.xcTaskId));
        if (taskElement) {
            this.emitEventAsync(ScheduleEventsEnum.scheduleTaskSelectedByUser, Number(taskElement.attributes.getNamedItem(HtmlEcsyConstants.xcTaskId)!.value));
        }
    }

    mouseDown(e: MouseEvent): void {
        if (!this.taskInsertingProcess.processing) {
            const htmlNodes = document.elementsFromPoint(e.clientX, e.clientY);
            const handle = htmlNodes[0];
            if (handle.classList.contains("task-handle")) {
                const taskId = handle.attributes.getNamedItem("task-id")?.value;
                const rootId = handle.attributes.getNamedItem("task-root-id")?.value;
                if (taskId && rootId) {
                    const rootProject = this.ganttRootProjects.find(x=> x.rootProject.data.taId === Number(rootId));
                    const task = rootProject?.svgRootProject.find(Number(taskId));
                    if (rootProject && task) {
                        const todayDaysOffset = DateUtils.dateDiffInDays(DateUtils.today(), this.startDate);
                        const todayXOffset = todayDaysOffset * this.currentUnitWidth * 20;
                        if (handle.classList.contains("translation-handle")) {
                            this.taskMovingProcess.initializeProcess(rootProject, task, e.clientX, todayXOffset);
                            return;
                        }
                        if (handle.classList.contains("start-handle") || handle.classList.contains("end-handle")) {
                            this.taskRangeProcess.initializeProcess(rootProject, task, e.clientX, handle.classList.contains("start-handle"), todayXOffset);
                            return;
                        }
                        if (handle.classList.contains("link-handle")) {
                            const svgNode = document.getElementById('svgRootProject' + rootId);
                            if (svgNode) {
                                this.taskLinkingProcess.initializeProcess(svgNode as any as SVGSVGElement, rootProject, task, new Point(e.clientX, e.clientY), handle.classList.contains("link-start-handle"));
                            }
                            return;
                        }
                    }
                }
            }
            return;
        }
    }

    timer: number | undefined;
    async mouseMove(e: MouseEvent): Promise<void> {
        if (this.taskInsertingProcess.processing) {
            this.taskInsertingProcess.transientTaskPos = new Point(e.clientX, e.clientY);
            return;
        }

        if (this.taskMovingProcess.processing) {
            this.taskMovingProcess.move(e.clientX);
            return;
        }

        if (this.taskRangeProcess.processing) {
            this.taskRangeProcess.extend(e.clientX);
            return;
        }

        if (this.taskLinkingProcess.processing) {
            const hitPoint = new Point(e.clientX, e.clientY);
            // const hitPoint = this.taskLinkingProcess.getSvgPoint(new Point(e.clientX, e.clientY));
            // if (hitPoint == null) {
            //     return;
            // }
    
            // Arrête le timer
            clearTimeout(this.timer);

            if (this.taskLinkingProcess.tempMousePosition != null &&
                hitPoint.distanceTo(this.taskLinkingProcess.tempMousePosition) < this.taskLinkingProcess.magnetAttraction) {
                return;
            }
        
            // La souris est sortie du périmètre d'attraction, on masque l'attracteur
            if (this.taskLinkingProcess.magnetAttraction > 0) {
                this.taskLinkingProcess.clearTarget();
            }
        
            // Actualise la position courante de la souris pour le contrôle de l'attraction lors du prochain MouseMove
            this.taskLinkingProcess.tempMousePosition = hitPoint;
        
            this.taskLinkingProcess.move(hitPoint, false);
        
            // Redémarre le timer
            this.timer = window.setTimeout(TaskLinkingDetection.detectTargetTask, 150, hitPoint, this);

            return;
        }
    }

    async mouseUp(e: MouseEvent): Promise<void> {
        if (this.taskMovingProcess.processing) {
            const rootId = this.taskMovingProcess.validate();
            if (rootId) await this.saveDatesAndDurations(rootId);
            return;
        }

        if (this.taskRangeProcess.processing) {
            const rootId = this.taskRangeProcess.validate(e.clientX);
            if (rootId) await this.saveDatesAndDurations(rootId);
            return;
        }

        if (this.taskLinkingProcess.processing) {
            if (this.taskLinkingProcess.targetTask) {
                if (!await this.taskLinkingProcess.saveLink(this.currentUnitWidth)) {
                    toastError("Une erreur s'est produite lors de l'enregistrement du lien");
                }
            }
            this.taskLinkingProcess.cleanProcess();
            return;
        }
    }
}