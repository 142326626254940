import { FormsModule } from '@angular/forms';
import { MaterialModule } from 'src/app/core/material.module';
import { BooleanField } from '../../../shared-model/boolean-field';
import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'xc-boolean-property',
  standalone: true,
  imports: [CommonModule, MaterialModule, FormsModule],
  templateUrl: './boolean-property.component.html',
  styleUrls: ['./boolean-property.component.scss']
})
export class BooleanPropertyComponent {
  @Input() model: BooleanField | undefined;
}
