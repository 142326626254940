import { BuildingEquipmentProviderDbView } from './../../../model/db-model/views/building-equipment-provider-db-view';
import { BuildingActivityStatusDbView } from './../../../model/db-model/views/building-activity-status-db-view';
import { BuildingAttributionDbView } from './../../../model/db-model/views/building-attribution-db-view';
import { BuildingAnatomyDbView } from './../../../model/db-model/views/building-anatomy-db-view';
import { FloorLayoutView } from './../../../model/data-model/views/floor-layout-view';
import { BuildingEquipmentProviderView } from 'src/app/core/model/data-model/views/building-equipment-provider-view';
import { BuildingAnatomyView } from 'src/app/core/model/data-model/views/building-anatomy-view';
import { BuildingAttributionView } from 'src/app/core/model/data-model/views/building-attribution-view';
import { BuildingActivityStatusView } from 'src/app/core/model/data-model/views/building-activity-status-view';
import { Floor } from 'src/app/core/model/data-model/tables/floor';
import { FloorActivityStatusDbView } from "src/app/core/model/db-model/views/floor-activity-status-db-view";
import { FloorAllocationDbView } from "src/app/core/model/db-model/views/floor-allocation-db-view";
import { FloorAttributionDbView } from "src/app/core/model/db-model/views/floor-attribution-db-view";
import { FloorLayoutDbView } from "src/app/core/model/db-model/views/floor-layout-db-view";

export class DashboardDto {
    floor: Floor[] = []; 
    buildingAllocation: any[]; 
    floorAllocation: any[]; 
    buildingLayout: BuildingAnatomyView[] = []; 
    floorLayout: FloorLayoutView[] = [];
    buildingAttribution: BuildingAttributionView[] = [];
    floorAttribution: any[];
    buildingActivityStatus: BuildingActivityStatusView[] = [];
    floorActivityStatus: any[];
    buildingEquipmentProvider: BuildingEquipmentProviderView[] = [];
    floorEquipmentProvider: any[];

    constructor(data: any) {
        data["floor"].forEach((f: any) => {
            this.floor.push(new Floor(f));
        });
        this.buildingAllocation = data["BuildingAllocationView"];
        this.floorAllocation = data[FloorAllocationDbView.databaseTableName];
        data[BuildingAnatomyDbView.databaseTableName].forEach((b: any) => {
            this.buildingLayout.push(new BuildingAnatomyView(b));
        });
        data[FloorLayoutDbView.databaseTableName].forEach((b: any) => {
            this.floorLayout.push(new FloorLayoutView(b));
        });
        data[BuildingAttributionDbView.databaseTableName].forEach((b: any) => {
            this.buildingAttribution.push(new BuildingAttributionView(b));
        });
        this.floorAttribution = data[FloorAttributionDbView.databaseTableName];
        data[BuildingActivityStatusDbView.databaseTableName].forEach((b: any) => {
            this.buildingActivityStatus.push(new BuildingActivityStatusView(b));
        });
        this.floorActivityStatus = data[FloorActivityStatusDbView.databaseTableName];
        data[BuildingEquipmentProviderDbView.databaseTableName].forEach((b: any) => {
            this.buildingEquipmentProvider.push(new BuildingEquipmentProviderView(b));
        });
        this.floorEquipmentProvider = data["FloorEquipmentProviderView"];
    }
}
