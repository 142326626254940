import { SimulationTimelinesDTO } from 'src/app/core/services/backend-services/dto/simulation-timelines-dto';
import { EditorMainContainerVM } from './editor-main-container-vm';
import { StrategyMenuCommandEnum } from '../../nested/toolbar/model/strategy-menu-command-enum';
import { StrategyToolbarVM } from '../../nested/toolbar/model/strategy-toolbar-vm';
import { SvgPhotoMarkerVM } from '../../../schedule/model/svg-photo-marker-vm';
import { PageModel } from 'src/app/ui/main/model/page-model';
import { AppMenuRouteEnum } from 'src/app/core/model/data-model/enums/app-menu-route-enum';
import { readableUUID } from 'src/app/core/events/event-listener-uuid';
import { logError } from 'src/app/core/services/logging-service';

export class StrategyEditorVM extends PageModel {
    toolbar!: StrategyToolbarVM;
    mainContainer!: EditorMainContainerVM;

     private constructor() {
        super(AppMenuRouteEnum.planning_strategy_simulations, 0, readableUUID(StrategyEditorVM.name));
    }

    static async newAsync(): Promise<StrategyEditorVM> {
        const result = new StrategyEditorVM();
        result.toolbar = await StrategyToolbarVM.newAsync();
        result.toolbar.selectMenuCommand(StrategyMenuCommandEnum.schedule);
        result.mainContainer = new EditorMainContainerVM();
        result.listenToStackingViewRequests();
        result.listenToCreationRequests();
        result.listenToCreationEnd();
        result.listenToSelectedViewChangeRequest();
        result.listenToSimulationLabelUpdate();
        return result;
    }

    async loadSimulation(simulationId: number): Promise<void> {
        await this.mainContainer.loadSimulation(simulationId);
    }

    listenToSelectedViewChangeRequest(): void {
        this.toolbar.selectedMenuChanged = (selectedCommand: StrategyMenuCommandEnum) => {
            this.mainContainer.setView(selectedCommand);
        }
    }

    listenToCreationRequests(): void {
        this.toolbar.creationRequested = () => {
            this.toolbar.creating = true;
            this.mainContainer.beginCreation();
        }
    }

    listenToCreationEnd(): void {
        this.mainContainer.creationEnded = () => {
            this.toolbar.creating = false;
        }
    }
    
    listenToStackingViewRequests(): void {
        this.mainContainer.stackingViewRequested = (p: SvgPhotoMarkerVM) => {
            this.toolbar.selectMenuCommand(StrategyMenuCommandEnum.stacking);
        }
    }

    simulationLabelUpdated?: (s: SimulationTimelinesDTO) => void;
    listenToSimulationLabelUpdate(): void {
        this.mainContainer.simulationLabelUpdated = (s: SimulationTimelinesDTO) => {
            if (this.simulationLabelUpdated) {
                this.simulationLabelUpdated(s);
            } else {
                logError("StrategyEditorVM.simulationLabelUpdated n'est pas écouté");
            }
        }
    }
}