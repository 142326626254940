import { FileUpload, FileUploadHandlerEvent } from "primeng/fileupload";
//import { FileUpload } from "src/app/components-lib/primeng-fix/fileupload";
import { readableUUID } from "src/app/core/events/event-listener-uuid";
import { AppMenuRouteEnum } from "src/app/core/model/data-model/enums/app-menu-route-enum";
import { BusinessUnitService } from "src/app/core/services/backend-services/business-unit-service";
import { PageModel } from "src/app/ui/main/model/page-model";
import Container from "typedi";

export class BusinessUnitsUpload extends PageModel {
    importedFile: string | undefined;
    uploadResult: string[] = [];

    private constructor() {
        super(AppMenuRouteEnum.layout_organization_upload, 0, readableUUID(BusinessUnitsUpload.name));
    }

    static async newAsync(): Promise<BusinessUnitsUpload> {
        const result = new BusinessUnitsUpload();
        //await result.initialize();
        return result;
    }

    async uploadHandler(e: FileUploadHandlerEvent, ctrl: FileUpload): Promise<void> {
        if (e.files.length !== 1) return;
        this.importedFile = e.files[0].name;
        const s = Container.get(BusinessUnitService);
        this.uploadResult = await s.loadFileData(e.files[0]);
        ctrl.clear();
        ctrl.uploadedFileCount = 0;
    }
}