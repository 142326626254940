import { RealEstateService } from 'src/app/core/services/backend-services/real-estate-service';
import { Container } from 'typedi';
import { DisplayThemeSet } from './display-theme-set';
import { DisplayThemeCategorySet } from './display-theme-category-set';
import { DisplayThemeEnum } from "src/app/core/model/data-model/enums/display-theme-enum";
import { RoomLayoutType } from "src/app/core/model/data-model/tables/room-layout-type";
import { DisplayThemeCaption } from './display-theme-caption';
import { DisplayThemeCategoryEnum } from 'src/app/core/model/data-model/enums/display-theme-category-enum';
import { WorkplaceTypeEnum } from 'src/app/core/model/data-model/enums/workplace-type-enum';
import { ThemingDTO } from 'src/app/core/services/backend-services/dto/theming-dto';
import { FloorBlueprint } from '../../../../blueprint-viewer-content-panel/itself/model/floor-blueprint';
import { TaskService } from 'src/app/core/services/backend-services/task-service';
import { TaskView } from 'src/app/core/model/data-model/views/task-view';
import { TaskBusinessTaskEnum } from 'src/app/core/model/data-model/enums/task-business-type-enum';
import { DropdownChangeEvent } from 'primeng/dropdown';
import { EventListener } from 'src/app/core/events/event-listener';
import { readableUUID } from 'src/app/core/events/event-listener-uuid';

export class ThemingModel extends EventListener {
    static selectedThemeChange = "selectedThemeChange";
    static refreshThemeNeeded = "refreshThemeNeeded";

    blueprint: FloorBlueprint;
    reconcileTasks: TaskView[] = [];
    roomLayoutTypes: RoomLayoutType[] = [];
    displayThemeCategories: DisplayThemeCategorySet[] = [];
    displayThemes: DisplayThemeSet[] = [];
    displayThemeCaptions: DisplayThemeCaption[] = [];
    selectedThemeId: number | undefined;

    displayThemeEnum = DisplayThemeEnum;

    constructor(blueprint: FloorBlueprint) {
        super(readableUUID(ThemingModel.name));
        this.blueprint = blueprint;
    }

    selectedTheme(): DisplayThemeSet | undefined {
        if (!this.selectedThemeId) return undefined;
        return this.displayThemes.find(x=> x.value === this.selectedThemeId);
    }

    async loadOrUpdate(): Promise<void> {
        const ths = Container.get(RealEstateService);
        const themingDto = await ths.loadTheming();
        if (themingDto == null) return;

        // Récupère les tâches de réconciliation d'inventaire
        const ps = Container.get(TaskService);
        this.reconcileTasks = await ps.loadReconcileTasks(this.blueprint.topMostTaskId());

        this.loadDisplayThemeData(themingDto);
        // Le thème "Aucun" est sélectionné par défaut
        if (!this.selectedThemeId) {
            this.setDefaultTheme();
        } else {
            const previous = this.displayThemes.find(x=> x.themeView.diThId === this.selectedThemeId);
            if (previous) {
                this.selectedThemeId = previous.value;
                await this.emitEventAsync(ThemingModel.selectedThemeChange, this.selectedTheme());
            } else {
                this.setDefaultTheme();
            }
        }
        this.setAvailableThemes();

        this.roomLayoutTypes = themingDto.roomLayoutTypes;
    }

    async selectTheme(themeId: DisplayThemeEnum): Promise<void> {
        this.selectedThemeId = themeId;
        await this.emitEventAsync(ThemingModel.selectedThemeChange, this.selectedTheme());
    }

    private setDefaultTheme(): void {
        this.selectedThemeId = this.displayThemes.find(x=> x.themeView.diThId === DisplayThemeEnum.None)?.value;
    }

    isThemeMustBeAvailable(themeCategoryId: number, planningTaskLoaded: boolean): boolean {
        switch (themeCategoryId) {
            case DisplayThemeCategoryEnum.EquipmentUpdates:
                return planningTaskLoaded;
            case DisplayThemeCategoryEnum.EquipmentMoveReconcile:
                return this.reconcileTasks.find(x=> x.taBusinessTypeId === TaskBusinessTaskEnum.ReconcileEquipmentPrepare) !== undefined;
            case DisplayThemeCategoryEnum.EquipmentScanReconcile:
                return this.reconcileTasks.find(x=> x.taBusinessTypeId === TaskBusinessTaskEnum.ReconcileEquipmentScan) !== undefined;
            case DisplayThemeCategoryEnum.ImportReconcile:
                return false; // TODO : charger la tâche éventuelle de réconciliation d'import
            default:
                return true;
        }
    }

    setAvailableThemes(): void {
        this.displayThemeCategories.forEach(c => {
            c.visible = this.isThemeMustBeAvailable(c.diThCaId, this.blueprint.updateTasks.length > 0);
        });
    }

    isAvailableTheme(themeId: number): boolean {
        for (const c of this.displayThemeCategories) {
            if (c.themes.find(x=> x.themeView.diThId === themeId) != null && !c.visible) {
                return false;
            }
        }
        return true;
    }

    availableThemes(): DisplayThemeCategorySet[] {
        return this.displayThemeCategories.filter(x=> x.visible);
    }

    loadDisplayThemeData(themingDto: ThemingDTO): void {
        this.displayThemeCategories = [];
        themingDto.displayThemeCategories.forEach((x: any) => {
            this.displayThemeCategories.push(x as DisplayThemeCategorySet);
        });
        this.displayThemes = [];
        themingDto.displayThemes.forEach((x: any) => {
            this.displayThemes.push(new DisplayThemeSet(x));
        });

        // Ajoute les thèmes comme enfants dans les catégories
        this.displayThemeCategories.forEach(c => {
            const catThemes = this.displayThemes.filter(x=> x.themeView.diThCategoryId === c.diThCaId);

            c.themes = catThemes;
            c.items = catThemes;
            // insère le nom de la catégorie dans les thèmes enfants
            catThemes.forEach(ct => {
                ct.categoryName = c.diThCaDisplayName;
            });

            // S'il n'y a qu'un theme dans la catégorie, il porte le nom de la catégorie
            if (catThemes.length === 1) {
                catThemes[0].themeView.diThDisplayName = c.diThCaDisplayName;
            }
        });
        this.displayThemeCaptions = [];
        themingDto.displayThemeItems.forEach(x => {
            this.displayThemeCaptions.push(new DisplayThemeCaption(x.dataSet));
        });

        // Ajoute les entrées de légende comme enfant dans les thèmes
        this.displayThemes.forEach(t => {
            let themeCaptions = this.displayThemeCaptions.filter(x=> x.themeId === t.themeView.diThId);
            // On retire explicitement le cas 'NR' des positions de travail
            if (t.themeView.diThId === DisplayThemeEnum.WorkplaceTypes) {
                themeCaptions = themeCaptions.filter(x=> x.id !== WorkplaceTypeEnum.NR);
            }
            t.captions = themeCaptions;
        });
    }
    async onThemeMenuItemClick(e: DropdownChangeEvent) {
        await this.selectTheme(e.value);
        //await this.emitEventAsync(ThemingModel.selectedThemeChange, e.value);
    }
}