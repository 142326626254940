import { ZColumnConstraintTable } from './../../db-model/tables/z_column_constraint-table';
import { DefinedModelBase } from '../defined-model-base';

export class ZColumnConstraint extends DefinedModelBase {
    coCoId!: number;
    coCoTableName!: string;
    coCoColumnName!: string;
    coCoConstraintType!: number;
    coCoConstraintMode!: number;
    coCoValue!: string;
    coCoIndex!: number;
    coCoTriggeredId!: number | null;
    coCoMessageId!: number | null;

    constructor(dtoData: any, tableDef: any[] = []) {
        super(dtoData, [ZColumnConstraintTable].concat(tableDef));
    }
}