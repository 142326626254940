export enum DisplayThemeEnum {
    LayoutType = 1,
    Allocations = 2,
    Attributions = 3,
    ActivityStatus = 4,
    Mixed = 5,
    WorkplaceTypes = 6,
    InstallationInstalled = 7,
    InstallationUninstalled = 8,
    ReconcileInstalled = 9,
    ReconcileUninstalled = 10,
    None = 11,
    Identification = 12,
    ImportReconcile = 13,
    EquipmentsProviders = 14
  }
  