import { SvgEntity } from "src/app/core/model/svg-model/svg-entity.model";
import { DxfSvgEntity } from "./dxf-svg-entity";
import { Point } from "src/app/core/model/geometry-model/point.model";
import { Vector } from "src/app/core/model/geometry-model/vector.model";
import { SvgEntityTypesEnum } from "src/app/core/model/svg-model/svg-entity-type-enum";
import { SvgEntityPoint } from "../../../shared/gizmos/model/svg-entity-point";
import { SvgEntityPointStyleEnum } from "../../../shared/gizmos/model/svg-entity-point-style-enum";
import { SvgCircleService } from "src/app/core/model/svg-model/svg-circle.service";
import { SvgCircle } from "src/app/core/model/svg-model/svg-circle.model";
import { SvgEntityParser } from "../../../shared/gizmos/model/svg-entity-parser";

export class DxfSvgCircle extends DxfSvgEntity {
    cx: number;
    cy: number;
    r: number;

    constructor(dtoData: any) {
        super(dtoData);
        this.cx = SvgEntity.getNumber(dtoData, "cx");
        this.cy = SvgEntity.getNumber(dtoData, "cy");
        this.r = SvgEntity.getNumber(dtoData, "r");
    }

    protected override getSelectablePoints(): SvgEntityPoint[] {
        const result = [new SvgEntityPoint(SvgEntityPointStyleEnum.center, new Point(this.cx, this.cy))];
        const c = SvgCircleService.geometry(this as SvgCircle);
        result.push(...SvgEntityPoint.toStyle(c.getQuadrants(), SvgEntityPointStyleEnum.quadrant, this.entityId));
        return result;
    }

    protected override getStatement(): string {
        const attributesStatement = SvgEntityParser.getAttributesStatement([["cx", this.cx], ["cy", this.cy], ["r", this.r], ["stroke-width", this.strokeWidth], ["stroke", this.stroke]]);
        return SvgEntityParser.getTagStatement("circle", attributesStatement);
    }

    translate(delta: Vector): void {
        const c = new Point(this.cx, this.cy);
        const cn = c.translate(delta);
        this.cx = cn.x;
        this.cy = cn.y;
    }

    isCenter(p: Point): boolean {
        return p.x === this.cx && p.y === this.cy;
    }

    static fromValues(layerId: number, centerPoint: Point, radius: number, strokeWidth: number): DxfSvgCircle {
        return new DxfSvgCircle({entityType: SvgEntityTypesEnum.circle, entityId: 0, layerId: layerId, cx: centerPoint.x, cy: centerPoint.y, r: radius, strokeWidth: strokeWidth});
    }
}