export enum ZConstraintTypeEnum {
    Minimum = 1,
    Maximum = 2,
    Step = 3,
    Value = 4,
    List = 5,
    AllowsDiacritics = 6,
    AllowsNull = 7,
    IsUnique = 8,
    DefaultValue = 9,
    RoundedValue = 10,
    Dependency = 11,
    IsReadOnlyAfterCreate = 12,
    HasDisplayName = 13,
    IsReadOnlyOnApplicationScope = 14,
    AllowedFileExtensions = 15
}