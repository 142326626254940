import { Container } from 'typedi';
import { FloorModelEnum } from "src/app/core/model/data-model/enums/floor-model-enum";
import { Point } from "src/app/core/model/geometry-model/point.model";
import { EditableLayer } from "./editable-layer";
import { EntitiesSelectionSet } from "./entities-selection-set";
import { InteractionCommand } from "./interaction-command";
import { LayerInteraction } from "./layer-interaction";
import { SelectionInteraction } from "./selection-interaction";
import { SvgDOM } from './svg-dom';
import { LabelingFormModel } from '../../../../blueprint-viewer-side-panel/subitems/labeling/model/labeling-form-model';
import { RoomFormVM } from '../../../../blueprint-viewer-side-panel/subitems/properties/model/room-form-vm';
import { RoomUpdateGizmoArgs } from '../../../subitems/gizmos/room-update-gizmo/model/room-update-gizmo-args';
import { RoomUpdateGizmoVM } from '../../../subitems/gizmos/room-update-gizmo/model/room-update-gizmo-vm';
import { BpRoomService } from '../../services/bp-room-service';
import { FloorBlueprint } from '../floor-blueprint';
import { BpSvgRoomLabel } from '../../../../svg-entities/model/bp-svg-room-label';
import { BlueprintRoomLayer } from '../../../../svg-entities/model/layers/blueprint-room-layer';
import { RoomAttributionTypeEnum } from 'src/app/core/model/data-model/enums/room-attribution-type-enum';
import { PeopleLocationDisplay } from '../../../../dialog/workplace-allocation-editor/model/people-location-display';
import { PeopleLocationTypeEnum } from 'src/app/core/model/data-model/enums/people-location-type-enum';
import { PeopleLocationViewSet } from 'src/app/core/model/data-model/view-set/people-location-view-set';
import { DyntService } from 'src/app/core/services/backend-services/dynt-service';
import { PeopleLocationDbView } from 'src/app/core/model/db-model/views/people-location-db-view';
import { TablesSetsEnum } from 'src/app/core/model/data-model/tables-sets-enum';
import { DirectoryService } from 'src/app/core/services/backend-services/directory-service';
import { WorkplaceService } from 'src/app/core/services/backend-services/workplace-service';
import { toastWarn } from 'src/app/core/services/toast-service';

export class RoomInteraction implements LayerInteraction {
    layerId = FloorModelEnum.RoomLabels;
    layerName: string = "NA";
    selectionSet: EntitiesSelectionSet = new EntitiesSelectionSet();

    isEditable: boolean;

    selectionInteraction: SelectionInteraction | undefined;
    selectionCount: number = 0;

    floorBlueprint: FloorBlueprint;
    editableLayers: EditableLayer[] = [];
    currentCommand: InteractionCommand;

    labelingForm: LabelingFormModel = new LabelingFormModel();
    propertiesForm: RoomFormVM | undefined;
    updateGizmo: RoomUpdateGizmoVM | undefined;
    
    //similarRooms: SimilarRoom[] = [];

    constructor(command: InteractionCommand, blueprint: FloorBlueprint, editableLayers: EditableLayer[]) {
        this.floorBlueprint = blueprint;
        this.currentCommand = command;
        this.editableLayers = editableLayers;

        this.isEditable = editableLayers.find(x=> x.layerId === this.layerId) != null;

        const layer = editableLayers.find(x=> x.layerId === this.layerId);
        if (layer) {
            this.layerName = layer.layerName;
        }

        this.propertiesForm = new RoomFormVM();
        this.updateGizmo = new RoomUpdateGizmoVM();
        this.listenToGizmoOverlayMouseDown();
        this.listenToThemableDataChange();
        this.listenToAllocationsCopyRequest();
        this.listenToRoomRecalculated();
        this.listenToRoomCreationValidated();
        this.listenToFindSimilarRoomsRequest();
        this.listenToPeopleAllocationChange();
    }

    // setLabellingForm(): void {
    //     this.labelingForm.workplaceRenumberRequest = (code: string, refine: boolean) => {
    //         // Désactive le curseur de sélection sur les items de l'étude
    //         const topMostTaskId = this.floorBlueprint.topMostTaskId();
    //         this.floorBlueprint.layersController.updateCursor(this.editableLayers, topMostTaskId, false);
    //         // Active le curseur d'insertion sur les positions de travail
    //         this.floorBlueprint.layersController.setCursor(topMostTaskId, [FloorModelEnum.WorkplaceLabels], "cell");

    //         this.currentCommand.set(InteractionCommand.workplaceRenumberCommand);
    //         this.updateGizmo?.showTransient(code);
    //     }
    // }

    async activate(labels: BpSvgRoomLabel[], toRemove: boolean): Promise<void> {
        this.selectionSet.addOrRemove(labels, !toRemove);
        const isEditable = this.isEditable && !this.selectionSet.hasHeterogenousDataState() && !this.selectionSet.isDataStateDeleted();
        this.updateGizmo?.show(new RoomUpdateGizmoArgs(this.selectionSet, this.floorBlueprint.hasPlanningTask(), this.editableLayers.find(x=> x.layerId === FloorModelEnum.Furniture) != null, isEditable));
        await this.propertiesForm?.initialize(this.selectionSet, this.floorBlueprint.floorId, this.floorBlueprint.floorName, this.floorBlueprint.hasPlanningTask(), isEditable);
    
        this.selectionSet.setChanged = () => this.onSelectionSetChanged();
}

    deactivate(): void {
        this.selectionSet.clear();
    }

    abort(): void {
        if (this.currentCommand.isRommLabelTranslateCommand()) {
            this.updateGizmo?.cancelTranslation();
            return;
        }

        this.updateGizmo?.hide();
        this.currentCommand.clear();
    }

    beginInsert(code: string): void {
        this.currentCommand.set(InteractionCommand.roomInsertionCommand);
        this.updateGizmo?.showTransient(code);
    }

    endInsert(toBeValidated: boolean): void {
        if (toBeValidated && this.updateGizmo) {
            this.currentCommand.set(InteractionCommand.roomInsertValidationCommand);
            this.updateGizmo.setCommandButtons();
        } else {
            this.currentCommand.clear();
            alert("Impossible de détecter le contour du local. Vérifiez les jonctions des cloisons.");
        }
    }

    insertValidated(): void {
        // Réactive le curseur de sélection sur les items de l'étude
        const topMostTaskId = this.floorBlueprint.topMostTaskId();
        this.floorBlueprint.layersController.updateCursor(this.editableLayers, topMostTaskId, true);
        this.updateGizmo?.hideTransient();
    }

    beginAllocationCopy(): void {
        this.currentCommand.set(InteractionCommand.roomAllocationCopyCommand);
    }

    beguinTaskRoomExtension(): void {
        const roomLaberCurrentLayer = this.floorBlueprint.layersController.roomLabelsLayer(this.floorBlueprint.taskId);
        if (!roomLaberCurrentLayer) return;
        const topMostTaskId = this.floorBlueprint.topMostTaskId();

        // Désactive le curseur de sélection sur les items de l'étude 
        this.floorBlueprint.layersController.updateCursor(this.editableLayers, topMostTaskId, false);

        // Active le curseur de sélection sur les étiquettes de surfaces du plan courant qui ne sont pas dans l'étude
        const updateTaskRooms = this.floorBlueprint.layersController.layer<BlueprintRoomLayer>(FloorModelEnum.Rooms, topMostTaskId)?.typedData();
        if (updateTaskRooms) {
            const updateTaskRoomsIds = updateTaskRooms.map((x: { floorDataId: any; })=> x.floorDataId);
            roomLaberCurrentLayer.setItemsSelectableExcept(updateTaskRoomsIds, true);
        }

        // Rétabli l'opacité du calque des étiquettes de surface du plan courant
        roomLaberCurrentLayer.opacity = 1;

        this.currentCommand.set(InteractionCommand.updateTaskRoomExtensionCommand);
    }

    endTaskRoomExtension(): void {
        const roomLaberCurrentLayer = this.floorBlueprint.layersController.roomLabelsLayer(this.floorBlueprint.taskId);
        if (!roomLaberCurrentLayer) return;
        const topMostTaskId = this.floorBlueprint.topMostTaskId();

        // Réactive le curseur de sélection sur les items de l'étude
        this.floorBlueprint.layersController.updateCursor(this.editableLayers, topMostTaskId, true);

        // Désactive le curseur de sélection sur les étiquettes de surfaces du plan courant
        roomLaberCurrentLayer.setItemsSelectable(false);

        // Diminue l'opacité du calque des étiquetes de surface du plan courant
        roomLaberCurrentLayer.opacity = 0.3;

        // La commande a déjà été annulée par le caller
    }

    onSelectionSetChanged(): void {
        this.updateGizmo?.onSelectionSetChanged();
        this.propertiesForm?.onSelectionSetChanged();
    }

    selectionDeleted?: () => void;
    raiseSelectionDeleted(): void {
        if (this.selectionDeleted) {
            this.selectionDeleted();
        }
    }

    refreshThemeNeeded?: (reloadCaptions: boolean) => void;
    askForThemerefresh(reloadCaptions: boolean): void {
        setTimeout(() => {
            if (this.refreshThemeNeeded) {
                this.refreshThemeNeeded(reloadCaptions);
            }
        }, 0);
    }

    listenToGizmoOverlayMouseDown(): void {
        if (!this.updateGizmo) return;
        this.updateGizmo.overlayMouseDown = (e: MouseEvent) => {
            this.currentCommand.set(InteractionCommand.roomLabelTranslateCommand);
            const hitPoint = SvgDOM.getPointPosition(e.clientX, e.clientY);
            if (hitPoint != null) {
                this.updateGizmo?.initializeTranslation(hitPoint);
            }
        }
    }

    listenToThemableDataChange(): void {
        if (!this.propertiesForm) return;
        this.propertiesForm.themeRefreshRequested = (reloadCaptions: boolean) => {
            this.askForThemerefresh(reloadCaptions);
        }
    }

    listenToAllocationsCopyRequest(): void {
        if (!this.propertiesForm) return;
        this.propertiesForm.allocationsCopyRequested = () => {
            this.currentCommand.set(InteractionCommand.roomAllocationCopyCommand);
        }
    }

    listenToRoomRecalculated(): void {
        if (!this.updateGizmo) return;
        this.updateGizmo.roomRecalculated = () => {
            if (!this.propertiesForm) return;
            this.propertiesForm.calculateTotalArea();
        }
    }

    listenToRoomCreationValidated(): void {
        if (!this.updateGizmo) return;
        this.updateGizmo.roomCreationValidated = async (code: string, path: string, wallsIds: number[], position: Point) => {
            if (!this.updateGizmo) return;
            const s = Container.get(BpRoomService);
            const result = await s.createRoom(
                this.floorBlueprint.topMostTaskId(),
                code,
                path,
                wallsIds,
                position,
                false)
            if (result != null) {
                const newRoom = result.svgRoom;
                const newLabel = result.svgLabel;
                newLabel.svgRoom = newRoom;
                newRoom.roomSet = result.roomSet;

                this.floorBlueprint.layersController.roomsLayer(this.floorBlueprint.topMostTaskId())?.insert(newRoom);
                this.floorBlueprint.layersController.roomLabelsLayer(this.floorBlueprint.topMostTaskId())?.insert(newLabel);
                // doit se situer après l'insertion dans les calques puisque ça réactive le curseur de sélection
                this.insertValidated();
                await this.activate([newLabel], false);
                this.askForThemerefresh(true);

            }
        }
    }

    similarRoomsRequested?: (roomId: number) => void;
    listenToFindSimilarRoomsRequest(): void {
        if (!this.updateGizmo) return;
        this.updateGizmo.findSimilarRoomRequested = async () => {
            if (this.selectionSet.count !== 1) {
                return;
            }
    
            const single = this.selectionSet.single<BpSvgRoomLabel>();
            const selectedRoomId = single.svgRoom?.room().roFloorDataId;
    
            if (this.similarRoomsRequested) {
                this.similarRoomsRequested(selectedRoomId!);
            }
        }
    }

    listenToPeopleAllocationChange(): void {
        if (!this.updateGizmo) return;
        this.updateGizmo.peopleLocationsChange = async (locs: PeopleLocationDisplay[]) => {
            if (this.selectionSet.count !== 1) {
                throw "Erreur non gérée : le changement de localisation ne peut être effectué que sur une sélection unitaire";
            }
    
            const uniqueLabel = this.selectionSet.items[0] as BpSvgRoomLabel;
            const taskId = uniqueLabel.taskId;
            const currentAllocations = uniqueLabel.peopleLocations != null ? uniqueLabel.peopleLocations : [];
            const deletedIds: string[] = [];
            const pls = Container.get(DirectoryService);

            // Enregistrement des changements différentiels
            currentAllocations.forEach(async ca => {
                const newAllocation = locs.find(x=> x.directoryId === ca.dataSet.peLoDirectoryId);
                if (newAllocation == null) {
                    // La localisation courante n'a pas été trouvée dans les nouvelles localisations
                    // elle doit donc être supprimée
                    deletedIds.push(ca.combinedId());
                } else {
                    if (ca.dataSet.peLoRatio !== newAllocation.ratio) {
                        // Le ratio d'occupation de l'allocation courante a été modifié
                        ca.dataSet.peLoRatio = newAllocation.ratio;
                        await pls.updatePeopleLocationRatio(ca.dataSet.peLoFloorDataId, ca.dataSet.peLoDirectoryId, newAllocation.ratio);
                    }
                }
            });

            if (deletedIds.length > 0) {
                await pls.deleteAllocations(deletedIds);
            }
            deletedIds.forEach(id => {
                // Actualise le tableau de localisation de personnes sur l'étiquette du local
                const allocationIndex = currentAllocations.findIndex(x=> x.combinedId() === id);
                currentAllocations.splice(allocationIndex, 1);
                // Retire l'étiquette de la personne sur le plan
                //this.floorBlueprint.layersController.peopleLabelsLayer(this.floorBlueprint.topMostTaskId())?.remove(id);
            });
    
            locs.forEach(async na => {
                const currentAllocation = currentAllocations.find(x=> x.dataSet.peLoDirectoryId === na.directoryId);
                if (!currentAllocation) {
                    // La nouvelle localisation n'a pas été trouvée dans les localisations courantes
                    // elle doit donc être créée
                    const createResult = await pls.createPeopleLocation(uniqueLabel.floorDataId, PeopleLocationTypeEnum.Room, na, taskId);
                    if (createResult != null && createResult instanceof PeopleLocationViewSet) {
                        // Actualise le tableau d'allocations sur l'étiquette de position de travail
                        currentAllocations.push(createResult);
                    }
                }
            });

            // Recharge les localisations du local
            const ds = Container.get(DyntService);
            const reloadedAllocations = await ds.downloadTable<PeopleLocationViewSet>(PeopleLocationDbView.databaseTableName, 
                TablesSetsEnum.PeopleLocationViewSet, 
                PeopleLocationDbView.peLoFloorDataId,
                uniqueLabel.floorDataId);
            uniqueLabel.setPeopleLocations(reloadedAllocations);
        }
    }

    
    async deleteSelectedItems(): Promise<void> {
        if (!this.floorBlueprint.hasPlanningTask()) {
            // La suppression de local n'est possible que dans une étude d'implantation
            return;
        }

        if (this.selectionSet != null) {
            const ids = this.selectionSet.floorDataParentIds();
            const s = Container.get(BpRoomService);
            const result = await s.deleteRooms(ids);
            if (result != null) {
                // Le local est traité par le backend comme les autres éléments du plan
                // c'est-à-dire qu'il n'est réellement supprimé de la base que s'il n'existe que dans l'étude
                this.selectionSet.items.forEach(r => {
                    const resultItem = result.find(x=> x.id === r.floorDataId);
                    r.updateState(resultItem);
                });

                // Suppression du local du jeu de données du plan si nécessaire
                // Le backend a retourné les ids des étiquettes et des surfaces supprimées
                // dans cet ordre, par paires
                // Pour mémoire, l'id de la surface est toujours supérieur d'une unité à l'id de la surface
                const fullDeleted = result.filter(x=> x.dataStateId == null);
                for (let i = 0; i < fullDeleted.length - 1; i+=2) {
                    const labelId = fullDeleted[i].id;
                    const roomId = fullDeleted[i + 1].id;
                    
                    const bpDelResult = this.floorBlueprint.layersController.removeRoom(labelId, roomId, this.floorBlueprint.topMostTaskId());
                    if (!bpDelResult) {
                        console.log("Une erreur s'est produite pendant le nettoyage des données");
                    }
                }
            }

            this.selectionSet.clear();
            this.raiseSelectionDeleted();
        }
    }

    async mouseDown(e: MouseEvent, hitPoint: Point): Promise<boolean> {
        // Il s'agit du clic de création de local
        if (this.currentCommand.isRoomInsertionCommand() && this.updateGizmo) {
            //this.beginInsert(this.labelingForm.roomInsertCode);
            const s = Container.get(BpRoomService);
            const result = await s.getSvgContour(this.floorBlueprint.topMostTaskId(), hitPoint.x, hitPoint.y, true);
            if (result != null) {
                // La détection de coutour a réussi, on demande une validation au user
                this.updateGizmo.recalculateResult = result;
                this.updateGizmo.recalculateResult.setTransformOrigin(hitPoint);
                this.updateGizmo.recalculateResult.scale = 1;
                this.endInsert(true);
            } else {
                this.endInsert(false);
                // La détection de contour a échoué
            }

            //this.askForThemerefresh();

            // Indique au caller (userInteraction) d'interrompre immédiatement son propre mouseDown
            return true;
        }

        if (this.currentCommand.isRoomAllocationCopyCommand()) {
            const sourceRoomLabel = this.selectionSet.single<BpSvgRoomLabel>();
            const targetRoomdata =  SvgDOM.getDOMRoomFromClientPoint(e.clientX, e.clientY);
            if (targetRoomdata != null) {
                const layer = this.floorBlueprint.layersController.roomLabelsLayer(this.floorBlueprint.topMostTaskId());
                if (layer != null) {
                    const targetRoomLabel = layer.typedData().find(x=> x.floorDataId === targetRoomdata.roomLabelId);
                    // Si l'étiquette cliquée correspond à une surface allouable
                    if (targetRoomLabel && targetRoomLabel.svgRoom && targetRoomLabel.svgRoom.room().roAttributionTypeId === RoomAttributionTypeEnum.Allocation) {
                        const allocations = sourceRoomLabel.svgRoom!.allocations();
                        if (allocations != null && allocations.length > 0) {
                            const s = Container.get(BpRoomService);
                            const result = await s.updateRoomAllocations([targetRoomLabel.parentId!], allocations);
                            if (result != null) {
                                // L'enregistrement a réussi, le backend a retourné les nouvelles allocations
                                targetRoomLabel.svgRoom.setAllocations(result);
                            }
                            this.askForThemerefresh(false);
                        }
                    } else {
                        toastWarn("Cette surface n'est pas allouable");
                    }
                }
            }
            // Indique au caller (userInteraction) d'interrompre immédiatement son propre mouseDown
            return true;
        }

        if (this.currentCommand.isUpdateTaskRoomExtensionCommand()) {
            const updateTaskId = this.floorBlueprint.topMostTaskId();

            const roomdata =  SvgDOM.getDOMRoomFromClientPoint(e.clientX, e.clientY);
            if (roomdata != null && roomdata.taskId !== updateTaskId) {
                const layer = this.floorBlueprint.layersController.roomLabelsLayer(this.floorBlueprint.taskId);
                if (layer) {
                    const roomLabel = layer.typedData().find(x=> x.floorDataId === roomdata.roomLabelId);
                    if (roomLabel && roomLabel.svgRoom) {
                        const s = Container.get(BpRoomService);
                        const addResult = await s.addRoomToTask(roomLabel.svgRoom.room().roFloorDataId, updateTaskId);
                        if (addResult != null) {
                            // Le backend retourne la totalité des datas de l'étude
                            // TODO : voir s'il est mieux de seulement ajouter les nouvelles datas plutôt que de remplacer tout
        
                            this.floorBlueprint.layersController.removeUpdateTaskGraphicsData(updateTaskId);
                            this.floorBlueprint.loadBlueprintTask(addResult);
        
                            // Initialise à nouveau le contexte de la commande
                            this.beguinTaskRoomExtension();
                        }
                    }
                }
            }
            return true;
        }

        return false;
    }

    mouseMove(e: MouseEvent, hitPoint: Point): void {
        // La commande d'insertion de position de travail n'utilise pas le mousemove
        if (this.currentCommand.isRoomInsertionCommand()) {
            this.updateGizmo?.moveTransientLabel(hitPoint);
            return;
        }

        if (this.currentCommand.isRommLabelTranslateCommand()) {
            this.updateGizmo?.translate(hitPoint);
            return;
        }
    }

    async mouseUp(e: MouseEvent): Promise<void> {
        // La commande de création de local n'utilise pas le mouseup
        if (this.currentCommand.isRoomInsertionCommand()) {
            return;
        }

        if (this.currentCommand.isRoomAllocationCopyCommand()) {
            // La commande de copie d'allocation reste active tant que la touche escape n'a pas été utilisée
            return;
        }

        if (this.currentCommand.isUpdateTaskRoomExtensionCommand()) {
            // La commande d'ajout de surface dans l'étude reste active tant que la touche escape n'a pas été utilisée
            return;
        }

        // Enregistre le déplacement
        if (this.currentCommand.isRommLabelTranslateCommand() && this.updateGizmo) {
            if (this.updateGizmo.selectionSet.items.length === 1) {
                const label = this.updateGizmo.selectionSet.items[0] as BpSvgRoomLabel;

                const initialPosition = label.initialTextPosition;
                const currentPosition = label.text.position();
                const finalDeltaTranslation = new Point(currentPosition.x - initialPosition.x, currentPosition.y - initialPosition.y).vector();
                const s = Container.get(WorkplaceService);
                const result = await s.updatePosition(label.floorDataId, finalDeltaTranslation);
                if (result != null) {
                    if (this.floorBlueprint.hasPlanningTask()) {
                        // S'il y a une étude chargée, la translation a nécessairement eu lieu sur les positions de travail de l'étude
                        // il faut donc actualiser le floorDataState
                        label.dataStateId = result[0].dataStateId;
                    }
                } else {
                    this.updateGizmo.cancelTranslation();
                }
            }
        }

        this.currentCommand.clear();
    }

    async keyDown(e: KeyboardEvent): Promise<void> {
    }
}