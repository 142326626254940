import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CadConverterSidePanel } from '../../../model/side-panel/cad-converter-side-panel';
import { ImportsPanelComponent } from '../imports-panel/imports-panel.component';
import { CadConverterLayersPanelComponent } from '../../cad-converter-layers-panel/cad-converter-layers-panel.component';
import { PictosPanelComponent } from '../pictos-panel/pictos-panel.component';

@Component({
  selector: 'xc-cad-converter-side-panel',
  standalone: true,
  imports: [CommonModule, ImportsPanelComponent, CadConverterLayersPanelComponent, PictosPanelComponent],
  templateUrl: './cad-converter-side-panel.component.html',
  styleUrls: ['./cad-converter-side-panel.component.scss']
})
export class CadConverterSidePanelComponent {
  @Input() model: CadConverterSidePanel | undefined;
  @Input() selectedTabIndex: number | undefined;
}
