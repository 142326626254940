import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DonutChartComponent } from '../donut-chart/donut-chart.component';
import { DonutChartWrapperVM } from '../../model/donut-chart-wrapper-vm';

@Component({
  selector: 'xc-donut-chart-wrapper',
  standalone: true,
  imports: [CommonModule, DonutChartComponent],
  templateUrl: './donut-chart-wrapper.component.html',
  styleUrls: ['./donut-chart-wrapper.component.scss']
})
export class DonutChartWrapperComponent {
  @Input() model: DonutChartWrapperVM | undefined;
}
