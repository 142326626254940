import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InventoryReferentialVM } from '../../model/inventory-referential-vm';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DynTableComponent } from 'src/app/components-lib/dyn-table/dyn-table.component';

@Component({
  selector: 'xc-inventory-referential',
  standalone: true,
  imports: [CommonModule, MatIconModule, MatTableModule, MatSelectModule, FormsModule, DynTableComponent],
  templateUrl: './inventory-referential.component.html',
  styleUrls: ['./inventory-referential.component.scss']
})
export class InventoryReferentialComponent implements OnChanges {
  @Input() model: InventoryReferentialVM | undefined;

  constructor(protected dialog: MatDialog) {}

  ngOnChanges(e: SimpleChanges): void {
    if (e["model"] && e["model"].currentValue) {
      e["model"].currentValue.dialog = this.dialog;
    }
  }  
}
