import { Point } from "../geometry-model/point.model";

export class PathBuilder {
    static getPath(points: Point[], closed: boolean): string {
        let result = "M";

        points.forEach(p => {
            result += p.x + " " + p.y + " ";
        });

        result = result.trimEnd();

        if (closed) {
            result += "z";
        }

        return result;
    }

    static getBezierPath(startPoint: Point | undefined, firstHandle: Point, lastHandle: Point, endPoint: Point): string {
        let sp = "";
        if (startPoint) {
            sp = `M ${startPoint.x}, ${startPoint.y} `;
        }
        return `${sp} C ${firstHandle.x} ${firstHandle.y}, ${lastHandle.x} ${lastHandle.y}, ${endPoint.x} ${endPoint.y}`;
    }

    /**
     * Retourne le data path d'un secteur
     * @param c Centre de l'arc
     * @param p1 Premier point sur l'arc
     * @param p2 Second point sur l'arc
     * @param r Rayon du cercle circonscrit
     * @param largArc Option de parcours, 1 pour le grand arc, 0 pour le petit arc
     * @param direction Direction du tracé, 1 pour antihoraire, 0 pour horaire
     * @returns 
     */
    static getPieSlicePath(c: Point, p1: Point, p2: Point, r: number, largArc: number, direction: number): string {
        const arcPath = PathBuilder.getArcPath(p1, p2, r, largArc, direction);
        return "M" + c.x + " " + c.y + " " + arcPath + "z";
    }

    static getDoughnutSlicePath(p1: Point, p2: Point, p3: Point, p4: Point, topRadius: number, bottomRadius: number, largArc: number, direction: number): string {
        const topArcPath = PathBuilder.getArcPath(p1, p2, topRadius, largArc, direction);
        const bottomArcPath = PathBuilder.getArcPath(p3, p4, bottomRadius, largArc, 0);
        return "M" + topArcPath + " L" + bottomArcPath + "z";
    }

    static getArcPath(p1: Point, p2: Point, r: number, largArc: number, direction: number): string {
        return p1.x + " " + p1. y + "A " + r + " " + r + " 0 " + largArc + " " + direction + " " + p2.x + " " + p2.y;
    }
}
