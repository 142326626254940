@if (model) {
  <div class="flex justify-content-end align-items-center gap-2">
    <label for="templatesSwitch">Modèles</label>
    <p-inputSwitch 
      inputId="templatesSwitch" 
      [(ngModel)]="model.displayTemplates" 
      [style.scale]="0.8"
      (onChange)="model.onTemplatesSwitchChange()">
    </p-inputSwitch>
  </div>
  <p-divider styleClass="divider"></p-divider>
    @if (model.nodes.length === 0) {
      @if (model.displayTemplates) {
        <span>Aucun projet</span>
      } @else {
        <span>Aucun modèle de projet</span>
      }
    } @else {
      <p-tree id="mobility-projects-tree" class="w-full md:w-30rem" selectionMode="single" [(selection)]="model.selectedNode" [value]="model.nodes"
        (onNodeExpand)="model.nodeExpand($event)" 
        (onNodeCollapse)="model.nodeCollapse($event)" 
        (onNodeSelect)="model.nodeSelect($event)">
        <ng-template let-node pTemplate="task">
          <div class="node-item" 
            [attr.id]="node.key" 
            >{{node.displayName()}}
          </div>
        </ng-template>
      </p-tree>
    }
}
