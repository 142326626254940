export enum ZDbTypeEnum {
    none = 0,
    BooleanTinyint = 1,
    ColorChar = 2,
    DateTimeDatetime = 3,
    DoubleDouble = 4,
    Int32Int = 5,
    StringVarchar = 6,
    StringChar = 7,
    ByteArrayGeometry = 8,
    StringText = 9,
    Int64Int = 10,
    DecimalDecimal = 11,
    DateTimeTimestamp = 12,
    ImageFile = 13
}