import { TaskFloor } from './../tables/task-floor';
import { TaskStatusView } from './task-status-view';
import { Floor } from './../tables/floor';
import { Task } from './../tables/task';
import { TaskBusinessTypeView } from './task-business-type-view';
import { TaskTypeView } from './task-type-view';

export class TaskView extends Task {
    taskFloor: TaskFloor;
    taskType: TaskTypeView;
    floor: Floor;
    statusView: TaskStatusView;
    businessTypeView: TaskBusinessTypeView;

    constructor(dtoData: any) {
        super(dtoData);
        this.taskType = new TaskTypeView(dtoData);
        this.taskFloor = new TaskFloor(dtoData);
        this.floor = new Floor(dtoData);
        this.statusView = new TaskStatusView(dtoData);
        this.businessTypeView = new TaskBusinessTypeView(dtoData);
    }
}