import { NgModule } from "@angular/core";
import { ChatFormComponent } from "./subitems/chat/ui/chat-form/chat-form.component";
import { EquipmentListFormComponent } from "./subitems/equipments/ui/equipment-list-form/equipment-list-form.component";
import { PlanningTaskFormComponent } from "./subitems/floor-task/ui/planning-task-form/planning-task-form.component";
import { LabelingListFormComponent } from "./subitems/labeling/ui/labeling-list-form/labeling-list-form.component";
import { LayersFormComponent } from "./subitems/layer-tree/ui/layers-form/layers-form.component";
import { MasterPlanFormComponent } from "./subitems/master-plan/ui/master-plan-form/master-plan-form.component";
import { DoorPropertiesFormComponent } from "./subitems/properties/ui/door-properties-form/door-properties-form.component";
import { EquipmentPropertiesFormComponent } from "./subitems/properties/ui/equipment-properties-form/equipment-properties-form.component";
import { PeoplePropertiesFormComponent } from "./subitems/properties/ui/people-properties-form/people-properties-form.component";
import { RoomPropertiesFormComponent } from "./subitems/properties/ui/room-properties-form/room-properties-form.component";
import { WallPropertiesFormComponent } from "./subitems/properties/ui/wall-properties-form/wall-properties-form.component";
import { WorkplacePropertiesFormComponent } from "./subitems/properties/ui/workplace-properties-form/workplace-properties-form.component";
import { ThemingComponent } from "./subitems/theming/ui/theming/theming.component";

@NgModule({
    declarations: [],
    imports: [
        ChatFormComponent,
        DoorPropertiesFormComponent,
        EquipmentListFormComponent,
        EquipmentPropertiesFormComponent,
        LabelingListFormComponent,
        LayersFormComponent,
        MasterPlanFormComponent,
        PeoplePropertiesFormComponent,
        PlanningTaskFormComponent,
        ThemingComponent,
        RoomPropertiesFormComponent,
        WallPropertiesFormComponent,
        WorkplacePropertiesFormComponent
    ],
    exports: [
        ChatFormComponent,
        DoorPropertiesFormComponent,
        EquipmentListFormComponent,
        EquipmentPropertiesFormComponent,
        LabelingListFormComponent,
        LayersFormComponent,
        MasterPlanFormComponent,
        PeoplePropertiesFormComponent,
        PlanningTaskFormComponent,
        ThemingComponent,
        RoomPropertiesFormComponent,
        WallPropertiesFormComponent,
        WorkplacePropertiesFormComponent
    ]
  })
  export class BlueprintViewerSidePanelModule {
  }
  