import { SelectableBusTreeVM } from './../selectable-bu-tree/model/selectable-bus-tree-vm';
import { SimulationCreationData } from "./simulation-creation-data";
import { SelectableBuTreeNode } from '../selectable-bu-tree/model/selectable-bu-tree-node';

export class CreationStepThreeVM {
    creationData: SimulationCreationData;
    buTree: SelectableBusTreeVM = new SelectableBusTreeVM();

    constructor(creationData: SimulationCreationData) {
        this.creationData = creationData
        this.buTree.seletedNodeChange = (selectedBus: SelectableBuTreeNode[]) => {
            this.creationData.selectedBus = selectedBus;
        }
    }

    async loadDatas(): Promise<void> {
        await this.buTree.loadTree([]);
        //await this.buTree.loadTree(this.creationData.selectedbuildings.map(x=> x.id));
        //this.creationData.selectedBus = this.buTree.getSelectedBus();
        
        // On mappe les éléments éventuellement précédemment sélectionnés
        // en cas de retour arrière
        this.buTree.selectBus(this.creationData.selectedBus.map(x=> x.bu.buUnId));
}
}