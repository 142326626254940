import { ZColumnConstraintView } from "src/app/core/model/data-model/views/z-column-constraint-view";
import { ZColumnView } from "src/app/core/model/data-model/views/z-column-view";
import { Field } from "./field";
import { FieldTypeEnum } from "./field-type-enum";
import { ZDbViewColumnView } from "src/app/core/model/data-model/views/z-db-view-column-view";
import { ZConstraintTypeEnum } from "src/app/core/model/data-model/enums/z-constraint-type-enum";

export class NumberField extends Field<number> {

    constructor(colId: string, rowId: any, value: number, definition: ZColumnView | ZDbViewColumnView, constraints: ZColumnConstraintView[], readOnlyByScope: boolean) {
        super(FieldTypeEnum.number, colId, rowId, value, definition, constraints, readOnlyByScope);
        
        this.errorsCheck = this.specificErrorsCheck;
    }

    specificErrorsCheck(): string[] {
        let result: string[] = [];

        this.constraints.forEach(c => {
            switch (c.coCoConstraintType) {
                case ZConstraintTypeEnum.Minimum:
                    if (Number(c.coCoValue) > this.value) {
                        result.push(`${this.colId}: ${c.coCoVidisplayName}`);
                    }
                    break;
                case ZConstraintTypeEnum.Maximum:
                    if (Number(c.coCoValue) < this.value) {
                        result.push(`${this.colId}: ${c.coCoVidisplayName}`);
                    }
                    break;
                case ZConstraintTypeEnum.AllowsNull:
                    if (c.coCoValue === "false" && this.value == null) {
                        result.push(`${this.colId}: ${c.coCoVidisplayName}`);
                    }
                    break;
                default:
                    break;
            }
        });

        return result;
    }
}