import { TaskChangeLogTable } from "../tables/task-change-log-table";
import { TaskContributorDirectoryTable } from "../tables/task-contributor-directory-table";

export const TaskChangeLogDbView = {
    ...TaskChangeLogTable,
    ...TaskContributorDirectoryTable,
    databaseTableName: "task_change_log_view",
    sourceStatusName: "SourceStatusName",
    sourceStatusColor: "SourceStatusColor",
    targetStatusName: "TargetStatusName",
    targetStatusColor: "TargetStatusColor"
}