import { RoomTable } from '../../db-model/tables/room-table';
import { DefinedModelBase } from '../defined-model-base';

export class Room extends DefinedModelBase {
    roFloorDataId!: number;
    roCode!: string;
    roArea!: number;
    roLayoutTypeId!: number;
    roAttributionTypeId!: number;
    roActivityStatusTypeId!: number;

    constructor(dtoData: any, tableDef: any[] = []) {
        super(dtoData, [RoomTable].concat(tableDef));
      }
}