import Container from "typedi";
import { EventCallbackStore } from "./event-callback-store";
import { EventProcess } from "./event-process";
import { closeErrorsGroup, logError, logInfo, openErrorsGroup } from "../services/logging-service";

export abstract class EventEmitter {
    async emitEventAsync(eventName: string, arg1?: any, arg2?: any, arg3?: any, arg4?: any): Promise<any> {
        const ps = Container.get(EventCallbackStore);
        const processes = ps.getProcesses(eventName);
        if (processes.length === 0) {
            logError(`${eventName} n'était pas écouté`);
            return undefined;
        }
        if (processes.length > 1) {
            openErrorsGroup(`event was received by (${processes.length}) listeners`)
            processes.forEach(p => {
                logInfo(`${p.listenerId} is listening to ${p.eventName}`);
            });
            closeErrorsGroup();
        }

        // L'émetteur ne peut récupérer de valeur de retour que s'il n'y a qu'un seul listener
        // sinon il y a potentiellement plusieurs valeurs de retour
        if (processes.length === 1) {
            const p = processes[0];
            return await this.executeProcess(p, arg1, arg2, arg3, arg4);
        }

        processes.forEach(async p => {
            await this.executeProcess(p, arg1, arg2, arg3, arg4);
        });

        // processes.forEach(async p => {
        //     if (arg1 !== undefined && arg2 !== undefined && arg3 !== undefined && arg4 !== undefined) {
        //         return Promise.resolve(p.method(arg1, arg2, arg3, arg4));
        //     }
        //     if (arg1 !== undefined && arg2 !== undefined && arg3 !== undefined) {
        //         return Promise.resolve(p.method(arg1, arg2, arg3));
        //     }
        //     if (arg1 !== undefined && arg2 !== undefined) {
        //         return Promise.resolve(p.method(arg1, arg2));
        //     }
        //     if (arg1 !== undefined) {
        //         return Promise.resolve(p.method(arg1));
        //     }
        //     return Promise.resolve(p.method());
        // });
    }

    private async executeProcess(p: EventProcess, arg1?: any, arg2?: any, arg3?: any, arg4?: any): Promise<any> {
        if (arg1 !== undefined && arg2 !== undefined && arg3 !== undefined && arg4 !== undefined) {
            return await p.method(arg1, arg2, arg3, arg4);
        }
        if (arg1 !== undefined && arg2 !== undefined && arg3 !== undefined) {
            return await p.method(arg1, arg2, arg3);
        }
        if (arg1 !== undefined && arg2 !== undefined) {
            return await p.method(arg1, arg2);
        }
        if (arg1 !== undefined) {
            return await p.method(arg1);
        }
        return await p.method();
}
}