import { Component, Inject, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DirectorySelectVM } from '../../model/directory-select-vm';
import { FormsModule, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { Directory } from 'src/app/core/model/data-model/tables/directory';

@Component({
  selector: 'xc-directory-select',
  standalone: true,
  imports: [CommonModule, MatDialogModule, MatInputModule, MatButtonModule, MatFormFieldModule, MatSelectModule, MatCheckboxModule, MatAutocompleteModule, FormsModule, ReactiveFormsModule],
  templateUrl: './directory-select.component.html',
  styleUrl: './directory-select.component.scss'
})
export class DirectorySelectComponent {
  addAppUserFormGroup: UntypedFormGroup;

  constructor(public dialogRef: MatDialogRef<DirectorySelectComponent>, @Inject(MAT_DIALOG_DATA) public data: DirectorySelectVM) {
    this.addAppUserFormGroup = new UntypedFormGroup({
      directoryCtrl: new UntypedFormControl(Directory, [Validators.required])
    });
  }

  cancel(): void {
    this.dialogRef.close();
  }

  submitForm(): void {
    console.log(location);
    // const constributorCreateDTO = {
    //   Directory: (this.addAppUserFormGroup.controls['directoryCtrl'].value as Directory),
    // };
    this.dialogRef.close(this.addAppUserFormGroup.controls['directoryCtrl'].value as Directory);
  }
}
