import { Component, Input } from '@angular/core';
import { BpSvgMeasureLines } from '../../model/bp-svg-measure-lines';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'g[xc-bp-svg-measure-lines]',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './bp-svg-measure-lines.component.svg',
  styleUrl: './bp-svg-measure-lines.component.scss'
})
export class BpSvgMeasureLinesComponent {
  @Input() model: BpSvgMeasureLines | undefined;
}
