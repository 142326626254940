import { ViewsNames } from 'src/app/core/model/db-model/views-names-enum';
import { DyntService } from 'src/app/core/services/backend-services/dynt-service';
import { Container } from 'typedi';
import { DynTableVM } from "src/app/components-lib/dyn-grid/model/dyn-table-vm";
import { PolicyCity } from 'src/app/core/model/data-model/policy/tables/policy-city';
import { ContinentView } from 'src/app/core/model/data-model/views/continent-view';
import { CountryView } from 'src/app/core/model/data-model/views/country-view';
import { CountryTable } from 'src/app/core/model/db-model/tables/country-table';
import { AddressService } from 'src/app/core/services/backend-services/address-service';
import { PageModel } from 'src/app/ui/main/model/page-model';
import { AppMenuRouteEnum } from 'src/app/core/model/data-model/enums/app-menu-route-enum';
import { readableUUID } from 'src/app/core/events/event-listener-uuid';
import { PaginationService } from 'src/app/core/services/backend-services/pagination-service';
import { DynTableVMBuilder } from 'src/app/components-lib/dyn-grid/model/dyn-table-vm-builder';

export class RefCityAdminVM extends PageModel {
    adminTable: DynTableVM | undefined;
    continents: ContinentView[] = [];
    selectedContinent: ContinentView | undefined;
    countries: CountryView[] = [];
    selectedCountry: CountryView | undefined;
    cities: PolicyCity[] = [];
    selectedCity: PolicyCity | undefined;

    private constructor() {
        super(AppMenuRouteEnum.layout_realestate_cities, 0, readableUUID(RefCityAdminVM.name));
    }

    static async newAsync(): Promise<RefCityAdminVM> {
        const result = new RefCityAdminVM();
        result.adminTable = await result.loadAdminData("city_dynview");
        await result.loadContinents();
        return result;
    }

    async loadContinents(): Promise<void> {
        const s = Container.get(DyntService);
        const tmp = await s.downloadTable<ContinentView>(ViewsNames.ContinentView);
        this.continents = tmp.sort((a, b) => a.coViDisplayName.localeCompare(b.coViDisplayName));
    }

    async loadCountries(continentId: number): Promise<void> {
        const s = Container.get(DyntService);
        const tmp = await s.downloadTable<CountryView>(ViewsNames.CountryView, undefined, CountryTable.cnContinentId, continentId);
        this.countries = tmp.sort((a, b) => a.cnViDisplayName.localeCompare(b.cnViDisplayName));
        this.cities = [];
        this.selectedCity = undefined;
    }

    async loadCities(countryId: number): Promise<void> {
        const s = Container.get(AddressService);
        const tmp = await s.getRefCities(countryId);
        this.cities = tmp.sort((a, b) => a.name.localeCompare(b.name));
    }

    async loadAdminData(tableName: string): Promise<DynTableVM> {
        const s = Container.get(DyntService);
        const viewSet = await s.contextedDataSet(tableName);
        const p = Container.get(PaginationService);
        const paginator = await p.getPaginator(tableName);
        const adminTable = DynTableVMBuilder.getVM(viewSet, tableName, paginator);

        // adminTable.saveRequested = async (tableName: string, primaryColumnName: string, rowId: any, columnName: string, value: any): Promise<string | null> => {
        //     const s = Container.get(DyntService);
        //     const dto: any = {
        //         "TableName": tableName,
        //         [primaryColumnName]: rowId,
        //         [columnName]: value
        //     }
        //     return await s.patch(tableName, rowId, dto);
        // }

        // // TODO : cette méthode peut être mutualisée avec toutes les autres écoutes de ce type
        // adminTable.insertionRequested = async (values : {}): Promise<any> => {
        //     const s = Container.get(DyntService);
        //     return await s.post<any>("", values);
        // }

        // // TODO : cette méthode peut être mutualisée avec toutes les autres écoutes de ce type
        // adminTable.deletionRequested = async (tableName: string, rowId: any): Promise<boolean> =>  {
        //     const s = Container.get(DyntService);
        //     const result = await s.delete(tableName, rowId);
        //     if (result) {
        //         return true;
        //     }
        //     return false;
        // }

        return adminTable;
    }

    async selectedContinentChange(): Promise<void> {
        if (this.selectedContinent) {
            await this.loadCountries(this.selectedContinent.coId);
            this.selectedCountry = undefined;
        }
    }

    async selectedCountryChange(): Promise<void> {
        if (this.selectedCountry) {
            await this.loadCities(this.selectedCountry.cnId);
            this.selectedCity = undefined;
        }
    }

    async insertCityButtonClick(): Promise<void> {
        if (this.selectedCity) {
            const s = Container.get(AddressService);
            const result = await s.insertPolicyCity(this.selectedCity.convertBack());
            if (result && this.adminTable) {
                this.adminTable.addRow(result);
            }
        }
    }
}