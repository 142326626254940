import { EquipmentPlanningStateEnum } from "../data-model/enums/equipment-planning-state-enum";
import { EquipmentPlanning } from "../data-model/tables/equipment-planning";
import { EquipmentPlanningState } from "../data-model/tables/equipment-planning-state";
import { EquipmentPlanningTaskView } from "../data-model/views/equipment-planning-task-view";

export function getInventoryStateCase(planningState: EquipmentPlanningState | undefined, planning: EquipmentPlanning | undefined): {planningState: number | undefined, targetId: number | undefined} {
    const ps = planningState?.eqPlStId
    switch (ps) {
        case EquipmentPlanningStateEnum.StandingBy:
            return {planningState: ps, targetId: undefined};
        case EquipmentPlanningStateEnum.FromMove:
        case EquipmentPlanningStateEnum.ToMove:
            if (planning?.eqPlMoveCaseTwinId != null) {
                return {planningState: ps, targetId: planning.eqPlMoveCaseTwinId};
            }
            // Ce cas ne doit normalement jamais se produire
            return {planningState: ps, targetId: undefined};
        case EquipmentPlanningStateEnum.FromPurchase:
            if (planning?.eqPlPurchaseCasePurchaseId != null) {
                return {planningState: ps, targetId: planning.eqPlPurchaseCasePurchaseId};
            }
            // Ce cas ne doit normalement jamais se produire
            return {planningState: ps, targetId: undefined};
        case EquipmentPlanningStateEnum.FromStore:
        case EquipmentPlanningStateEnum.ToStore:
            if (planning?.eqPlStoreCaseStorageAreaId != null) {
                return {planningState: ps, targetId: planning.eqPlStoreCaseStorageAreaId};
            }
            // Ce cas ne doit normalement jamais se produire
            return {planningState: ps, targetId: undefined};
        case EquipmentPlanningStateEnum.ToRemove:
            if (planning?.eqPlRemoveCaseDeletionCaseId != null) {
                return {planningState: ps, targetId: planning.eqPlRemoveCaseDeletionCaseId};
            }
            // Ce cas ne doit normalement jamais se produire
            return {planningState: ps, targetId: undefined};
        default:
            return {planningState: ps, targetId: undefined};
    }
}

export function invertoryStatesAreSame(s1: {planningState: number | undefined, targetId: number | undefined}, s2: {planningState: number | undefined, targetId: number | undefined}): boolean {
    return s1.planningState === s2.planningState && s1.targetId === s2.targetId;
}

export function hasEnoughReferences(purchaseId: number, planningTask: EquipmentPlanningTaskView[], refs: {floorCatalogId: number, count: number}[]): boolean {
    let result = true;
    refs.forEach(ref => {
        const tmp = planningTask.filter(x=> x.eqCgFloorCatalogId === ref.floorCatalogId && x.eqPlPurchaseCasePurchaseId === purchaseId);
        if (tmp.length < ref.count) {
            result = false;
            return;
        }
    });
    return result;
}
