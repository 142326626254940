import { FloorCatalogImportLogTable } from '../../db-model/tables/floor-catalog-import-log-table';
import { DefinedModelBase } from '../defined-model-base';

export class FloorCatalogImportLog extends DefinedModelBase {
    flCgImLoId!: number;
    flCgImLoImportId!: number;
    flCgImLoImportItemId!: number | null;
    flCgImLoText!: string;
    flCgImLoRequestId!: string;

    constructor(dtoData: any, tableDef: any[] = []) {
        super(dtoData, [FloorCatalogImportLogTable].concat(tableDef));
    }
}