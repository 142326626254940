import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PathInsertGizmo } from '../../model/path-insert-gizmo';

@Component({
  selector: 'g[xc-picto-path-insert-gizmo]',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './path-insert-gizmo.component.svg',
  styleUrls: ['./path-insert-gizmo.component.scss']
})
export class PathInsertGizmoComponent {
  @Input() model: PathInsertGizmo | undefined;
}
