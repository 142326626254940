import { Point } from 'src/app/core/model/geometry-model/point.model';
import { logError } from 'src/app/core/services/logging-service';

export class PanzoomController {
    svgElement!: SVGSVGElement | null;
    dragStartPoint: Point | undefined;
    dragStartViewbox: { x:number, y: number, w: number, h: number } | undefined;
    originalViewbox: { x:number, y: number, w: number, h: number } | undefined;
    clientToSvgUnit: number = 1;
    currentZoomFactor: number = 1;
    viewboxChange?: (viewbox: string) => void;

    constructor() {
    }

    initialize(id: string): void {
        const temp = document.getElementById(id);
        if (temp == null) {
            logError("Impossible de récupérer le HTMLElement " + id);
        }
        this.svgElement = temp?.firstChild as unknown as SVGSVGElement;
        this.currentZoomFactor = 1;
        const cvb = this.svgElement.viewBox.baseVal;
        this.raiseViewboxChanged(`${cvb!.x} ${cvb!.y} ${cvb!.width} ${cvb!.height}`)
    }

    resetView(): void {
        if (this.svgElement && this.originalViewbox) {
            const cvb = this.originalViewbox;
            this.raiseViewboxChanged(`${cvb.x} ${cvb.y} ${cvb.w} ${cvb.h}`)
            this.currentZoomFactor = 1;
            this.raiseZoomChange();
        }
    }

    resetOriginalViewbox(): void {
        this.originalViewbox = undefined;
        this.currentZoomFactor = 1;
    }

    raiseViewboxChanged(viewbox: string): void {
        if (this.viewboxChange) {
            this.viewboxChange(viewbox);
        } else {
            logError("PanzoomController.viewboxChanged n'est pas écouté");
        }
    }

    raiseZoomChange(): void {
        if (this.zoomChange) {
            this.zoomChange(this.currentZoomFactor);
        } else {
            logError("PanzoomController.zoomChange n'est pas écouté");
        }
    }

    private setOriginalViewbox(): void {
        if (this.svgElement && this.originalViewbox == undefined) {
            const cvb = this.svgElement.viewBox.baseVal;
            this.originalViewbox = { x: cvb.x, y: cvb.y, w: cvb.width, h: cvb.height };
        }
    }

    zoomChange?: (scale: number) => void;
    zoom(e: any): void {
        if (this.svgElement) {
            this.setOriginalViewbox();

            const cvb = this.svgElement.viewBox.baseVal;
            const mouseSvgPoint = this.getSvgPointFromClientPoint(this.svgElement, e.clientX, e.clientY);
            const zoomFactor = e.deltaY < 0 ? 1 / 1.2 : 1.2

            // Actualise le zoom total courant
            this.currentZoomFactor /= zoomFactor;
      
            // La taille de la viewbox après application du facteur de zoom
            const targetWidth = cvb.width * zoomFactor;
            const targetHeight = cvb.height * zoomFactor;
            // Le delta d'agrandissement
            const deltaX = cvb.width - targetWidth;
            const deltaY = cvb.height - targetHeight;

            // Puisque la taille change de la quantité delta, la vue se décale d'autant à partir de son point d'origine
            // donc vers le bas et la droite si on ne change pas le point d'origine
            // pour la maintenir centrée sur la position de la souris, il faut modifier également le point d'origine de la viewbox

            // La distance de la souris au point d'origine
            const mouseDistX = Math.sqrt(Math.pow(mouseSvgPoint.x - cvb.x, 2));
            const mouseDistY = Math.sqrt(Math.pow(mouseSvgPoint.y - cvb.y, 2));
            // L'éloigment de la souris par rapport au point d'origine en pourcentage de la largeur du dessin
            const mouseDistXRatio = mouseDistX / cvb.width;
            const mouseDistYRatio = mouseDistY / cvb.height;
            // Le changement appliqué à viewbox.x
            const vbx = cvb.x + deltaX * mouseDistXRatio;
            const vby = cvb.y + deltaY * mouseDistYRatio;

            this.raiseZoomChange();
            this.raiseViewboxChanged(`${vbx} ${vby} ${targetWidth} ${targetHeight}`);
        }
    }

    // setZoom(zoomFactor: number): void {
    //         // Actualise le zoom total courant
    //         this.currentZoomFactor /= zoomFactor;
      
    //         // La taille de la viewbox après application du facteur de zoom
    //         const targetWidth = cvb.width * zoomFactor;
    //         const targetHeight = cvb.height * zoomFactor;
    //         // Le delta d'agrandissement
    //         const deltaX = cvb.width - targetWidth;
    //         const deltaY = cvb.height - targetHeight;

    //         // Puisque la taille change de la quantité delta, la vue se décale d'autant à partir de son point d'origine
    //         // donc vers le bas et la droite si on ne change pas le point d'origine
    //         // pour la maintenir centrée sur la position de la souris, il faut modifier également le point d'origine de la viewbox

    //         // La distance de la souris au point d'origine
    //         const mouseDistX = Math.sqrt(Math.pow(mouseSvgPoint.x - cvb.x, 2));
    //         const mouseDistY = Math.sqrt(Math.pow(mouseSvgPoint.y - cvb.y, 2));
    //         // L'éloigment de la souris par rapport au point d'origine en pourcentage de la largeur du dessin
    //         const mouseDistXRatio = mouseDistX / cvb.width;
    //         const mouseDistYRatio = mouseDistY / cvb.height;
    //         // Le changement appliqué à viewbox.x
    //         const vbx = cvb.x + deltaX * mouseDistXRatio;
    //         const vby = cvb.y + deltaY * mouseDistYRatio;

    //         this.raiseZoomChange();
    //         this.raiseViewboxChanged(`${vbx} ${vby} ${targetWidth} ${targetHeight}`);
    // }

    startPan(e: MouseEvent): void {
        if (this.svgElement) {
            this.setOriginalViewbox();

            const cvb = this.svgElement?.viewBox.baseVal;
            this.dragStartPoint = new Point(e.clientX, e.clientY);
            this.dragStartViewbox = { x: cvb.x, y: cvb.y, w: cvb.width, h: cvb.height };

            // Pour déplacer le plan on a besoin de connaître l'équivalent sur le plan d'un déplacement d'un pixel
            // en coordonnées client
            const p1 = this.getSvgPointFromClientPoint(this.svgElement, e.clientX, e.clientY);
            const p2 = this.getSvgPointFromClientPoint(this.svgElement, e.clientX + 1, e.clientY);
            this.clientToSvgUnit = Math.sqrt(Math.pow(p2.x - p1.x, 2));
        }
    }

    pan(e: MouseEvent): void {
        if (this.svgElement && this.dragStartPoint && this.dragStartViewbox) {
            const dragPoint = new Point(e.clientX, e.clientY);

            const deltaX = (dragPoint.x - this.dragStartPoint.x) * this.clientToSvgUnit;
            const deltaY = (dragPoint.y - this.dragStartPoint.y) * this.clientToSvgUnit;

            const x = this.dragStartViewbox.x - deltaX;
            const y = this.dragStartViewbox.y - deltaY;

            const cvb = this.svgElement.viewBox.baseVal;
            this.raiseViewboxChanged(`${x} ${y} ${cvb.width} ${cvb.height}`);
        }
    }

    getSvgPointFromClientPoint(svg: SVGSVGElement, x: number, y: number): DOMPoint {
        const point = svg.createSVGPoint();
        point.x = x;
        point.y = y;
        const ctm = svg.getScreenCTM();
        const ctmi = ctm?.inverse();
        return point.matrixTransform(ctmi);
    }

    endPan(): void {
        this.dragStartPoint = undefined;
    }

    mouseDown(e: MouseEvent): void {
        e.preventDefault();
        if (e.buttons === 4) {
            this.startPan(e);
        }
    }

    touchStart(e: TouchEvent): void {
    }

    mouseMove(e: MouseEvent): void {
        e.preventDefault();
        if (e.buttons === 4 && this.dragStartPoint) {
            this.pan(e);
        }
    }

    touchMove(e: TouchEvent): void {
    }

    mouseUp(e: MouseEvent): void {
        e.preventDefault();
        this.endPan();
    }

    touchUp(e: Event): void {
    }

    mouseLeave(e: MouseEvent): void {
    }

    mouseWheel(e: any): void {
        e.preventDefault();
        this.zoom(e);
    }

}