import { SvgEntityPointStyleEnum } from './../../../ui/pages/graphic-works/shared/gizmos/model/svg-entity-point-style-enum';
import { Point } from "../geometry-model/point.model";
import { SvgConstants } from "./svg-constants";
import { SvgPath } from "./svg-path.model";
import { XcMaths } from "../static-functions/xc-maths";
import { SvgPathValues } from "./svg-path-values";
import { GeometricElement } from "../geometry-model/geometric-element.model";
import { Segment } from "../geometry-model/segment.model";
import { GeometricElementType } from "../geometry-model/geometric-element-type.model";
import { Arc } from "../geometry-model/arc.model";
import { Polygon } from "../geometry-model/polygon.model";
import { SvgCircle } from "./svg-circle.model";
import { SvgEntity } from "./svg-entity.model";
import { EllipticalArc } from "../geometry-model/elliptical-arc";
import { SvgEntityTypesEnum } from "./svg-entity-type-enum";
import { SvgLine } from "./svg-line.model";
import { SvgCircleService } from "./svg-circle.service";
import { SvgEntityPoint } from "src/app/ui/pages/graphic-works/shared/gizmos/model/svg-entity-point";
import { IntersectionService } from '../geometry-model/intersection-service';
import { Rectangle } from '../geometry-model/rectangle.model';
import { SvgGeometryService } from 'src/app/ui/pages/graphic-works/picto/svg/svg-geometry-service';
import { GeometryService } from '../geometry-model/geometry-service';

export class SvgPathService {
    /**
     * A n'utiliser que pour les paths qui sont des arcs elliptiques autonomes
     * @param statement
     */
    static getPathEllipticalArcValues(statement: string): SvgPathValues | undefined {
        let sliceIndex: number = 0;
        const sp = SvgPathService.getPathStartPoint(statement);
        sliceIndex = SvgPathService.getNextCharIndex(statement, SvgConstants.PathArcFlagName, 0);
        statement = statement.substring(sliceIndex);
        const xRadius = SvgPathService.getValue(statement);
        sliceIndex = SvgPathService.getNextSpaceIndex(statement, sliceIndex);
        statement = statement.substring(sliceIndex);
        const yRadius = SvgPathService.getValue(statement);
        sliceIndex = SvgPathService.getNextSpaceIndex(statement, sliceIndex);
        statement = statement.substring(sliceIndex);
        const xAxisRotation = SvgPathService.getValue(statement);
        sliceIndex = SvgPathService.getNextSpaceIndex(statement, sliceIndex);
        statement = statement.substring(sliceIndex);
        const isLargArc = SvgPathService.getValue(statement);
        sliceIndex = SvgPathService.getNextSpaceIndex(statement, sliceIndex);
        statement = statement.substring(sliceIndex);
        const isClockwise = SvgPathService.getValue(statement);
        sliceIndex = SvgPathService.getNextSpaceIndex(statement, sliceIndex);
        statement = statement.substring(sliceIndex);
        const ep = SvgPathService.getPoint(statement);
        return new SvgPathValues(sp!, xRadius, yRadius, xAxisRotation, isLargArc, isClockwise, ep);
    }
    
    static getPoint(statement: string): Point
    {
        const x = SvgPathService.getValue(statement);
        const nextSpaceIndex = SvgPathService.getNextSpaceIndex(statement, 0);
        const y = SvgPathService.getValue(statement.slice(nextSpaceIndex));
        return new Point(x, y);
    }

    public static getValue(statement: string): number
    {
        const sub = statement.trimStart();

        const firstFlagIndex = SvgPathService.getNextFlagIndex(sub, 0);
        let firstSpace = sub.indexOf(' ');

        if (firstFlagIndex > 0 && firstFlagIndex < firstSpace) {
            firstSpace = firstFlagIndex;
        }

        if (firstSpace === -1)
        {
            firstSpace = sub.length;
        }
        const value = Number(sub.slice(0, firstSpace));
        return value;
    }

    public static getRadius(statement: string): [major: number, minor: number] {
        let major: number = 0;
        let minor: number = 0;

        const aFlagIndex = SvgPathService.getNextCharIndex(statement, SvgConstants.PathArcFlagName, 0);
        if (aFlagIndex > -1) {
            let sub = statement.substring(aFlagIndex + 1);
            major = SvgPathService.getValue(sub);
            const nextSpaceIndex = SvgPathService.getNextSpaceIndex(sub, 1);
            sub = sub.substring(nextSpaceIndex);
            minor = SvgPathService.getValue(sub);
        }

        return [major, minor];
    }

    static getNextFlagIndex(statement: string, startIndex: number): number
    {
        let result = startIndex;

        const array =  ['A', 'L', 'M'] ;
        while (result < statement.length && !array.includes(statement.slice(result, result + 1)))
        {
            result += 1;
        }

        return result;
    }

    static getNextSpaceIndex(statement: string, startIndex: number): number
    {
        const trimed = statement.trimStart();
        const deltaLength = statement.length - trimed.length;

        const nextSpaceIndex = SvgPathService.getNextCharIndex(trimed, ' ', startIndex) + deltaLength;
        const nextFlagIndex = SvgPathService.getNextFlagIndex(statement, startIndex);

        return Math.min(nextFlagIndex, nextSpaceIndex);
    }

    static getNextCharIndex(statement: string, searchForChar: string, startIndex: number): number
    {
        let result = startIndex;

        const length = statement.length;

        while (result < length && statement[result] !== searchForChar)
        {
            result += 1;
        }

        return result;
    }

    static getPathEntities(pathStatement: string): GeometricElement[]
    {
        const result: GeometricElement[] = [];
        if (!pathStatement) return result;

        const subPaths = SvgPathService.getSubPathsStatements(pathStatement);
        subPaths.forEach(subPath => {
            let currentPoint: Point | undefined;
            let firstPoint: Point | undefined;

            let sliceIndex = 0;
            while (sliceIndex < subPath.length)
            {
                const firstChar = subPath[sliceIndex];
                switch (firstChar)
                {
                    case SvgConstants.PathAbsoluteMoveToFlagName:
                        sliceIndex += 1;
                        currentPoint = SvgPathService.getPoint(subPath.slice(sliceIndex));
                        sliceIndex = SvgPathService.getNextFlagIndex(subPath, sliceIndex);
                        if (!firstPoint) firstPoint = currentPoint;
                        break;
                    case SvgConstants.PathAbsoluteLineToFlagName:
                        sliceIndex += 1;
                        const lp = SvgPathService.getPoint(subPath.slice(sliceIndex));
                        sliceIndex = SvgPathService.getNextFlagIndex(subPath, sliceIndex);
                        //const newLine = SvgLine.fromValues(currentPoint!.x, currentPoint!.y, lp.x, lp.y);
                        result.push(new Segment(currentPoint!, lp));
                        currentPoint = lp;
                        break;
                    case SvgConstants.PathArcFlagName:
                        sliceIndex += 1;
                        const xRadius = SvgPathService.getValue(subPath.slice(sliceIndex));
                        sliceIndex = SvgPathService.getNextSpaceIndex(subPath, sliceIndex + 1);
                        const yRadius = SvgPathService.getValue(subPath.slice(sliceIndex));
                        sliceIndex = SvgPathService.getNextSpaceIndex(subPath, sliceIndex + 1);
                        const angle = SvgPathService.getValue(subPath.slice(sliceIndex));
                        sliceIndex = SvgPathService.getNextSpaceIndex(subPath, sliceIndex + 1);
                        const isLargArc = SvgPathService.getValue(subPath.slice(sliceIndex));
                        sliceIndex = SvgPathService.getNextSpaceIndex(subPath, sliceIndex + 1);
                        const isClockWise = SvgPathService.getValue(subPath.slice(sliceIndex));
                        sliceIndex = SvgPathService.getNextSpaceIndex(subPath, sliceIndex + 1);
                        const ap = SvgPathService.getPoint(subPath.slice(sliceIndex));
                        sliceIndex = SvgPathService.getNextFlagIndex(subPath, sliceIndex);
                        //const newPath = SvgPath.fromStatement(SvgPathService.getPathEllipticalArcStatement(currentPoint!, ap, xRadius, yRadius, angle, isClockWise, isLargArc));
                        // La direction de l'arc est inversée à cause du système de coordonnées du svg
                        if (xRadius === yRadius) {
                            result.push(new Arc(currentPoint!, ap, xRadius, angle, !Boolean(isClockWise), Boolean(isLargArc)));
                        } else {
                            result.push(new EllipticalArc(currentPoint!, ap, xRadius, yRadius, angle, !Boolean(isClockWise), Boolean(isLargArc)));
                        }
                        currentPoint = ap;
                        break;
                    case SvgConstants.PathCloseFlagName:
                        // On passe ici lorsqu'il y a une fermeture à l'intérieur du path
                        //const endLine = SvgLine.fromValues(currentPoint!.x, currentPoint!.y, firstPoint!.x, firstPoint!.y);
                        result.push(new Segment(currentPoint!, firstPoint!));
                        sliceIndex += 1;
                        break;
                    default:
                        break;
                }
            }

            if (subPath.endsWith(SvgConstants.PathCloseFlagName))
            {
                //const endLine = SvgLine.fromValues(currentPoint!.x, currentPoint!.y, firstPoint!.x, firstPoint!.y);
                result.push(new Segment(currentPoint!, firstPoint!));
            }
        });

        return result;
    }

    static getEntitiesAt(pathStatement: string, p: Point): GeometricElement[]
    {
        const result: GeometricElement[] = [];
        if (!pathStatement) return result;

        return GeometryService.getElementsAt(this.getPathEntities(pathStatement), p);
    }

    static getSubPathsStatements(pathStatement: string | undefined): string[]
    {
        const result: string[] = [];
        if (!pathStatement) return result;

        const tmp = pathStatement.split("M");
        return tmp.filter(x=> x !== '').map(x=> 'M' + x);
    }

    static getPathStartPoint(pathStatement: string | undefined): Point | undefined
    {
        if (pathStatement && pathStatement[0] === SvgConstants.PathAbsoluteMoveToFlagName)
        {
            return SvgPathService.getPoint(pathStatement.slice(1));
        }

        return undefined;
    }

    static getPathEndPoint(pathStatement: string | undefined): Point | undefined {
        if (!pathStatement) return undefined;
        const lastSpaceIndex = pathStatement.lastIndexOf(' ');
        const previousSpaceIndex = pathStatement.lastIndexOf(' ', lastSpaceIndex - 1);
        return SvgPathService.getPoint(pathStatement.slice(previousSpaceIndex));
    }

    static getVertices(pathStatement: string | undefined): Point[]
    {
        let result: Point[] = [];
        if (!pathStatement) return result;

        // const sp = SvgPathService.getPathStartPoint(pathStatement);
        // if (sp) {
        //     result.push(sp);
        // }
        
        const pathEntities = SvgPathService.getPathEntities(pathStatement);
        pathEntities.forEach(pe => {
            switch (pe.elementType) {
                case GeometricElementType.Segment:
                    const s = pe as Segment;
                    const lp = s.points();
                    if (result.length === 0 || (result.length > 0 && !result[result.length - 1].equals(lp[0]))) {
                        result.push(lp[0]);
                    }
                    result.push(lp[1]);
                    break;
                case GeometricElementType.Arc:
                    const a = pe as Arc;
                    const sp = a.startPoint;
                    if (sp) {
                        if (result.length === 0 || (result.length > 0 && !result[result.length - 1].equals(sp))) {
                            result.push(sp);
                        }
                    }
                    const ep = a.endPoint;
                    if (ep) result.push(ep);
                    break;
                default:
                    break;
            }
        });

        if (result.length > 2 && result[0].equals(result[result.length - 1])) {
            result = result.slice(0, result.length - 1);
        }
        return result;
    }

    static getMiddlePoints(entityId: string, pathStatement: string | undefined): SvgEntityPoint[] {
        let result: SvgEntityPoint[] = [];
        if (!pathStatement) return result;

        const pathEntities = SvgPathService.getPathEntities(pathStatement);
        pathEntities.forEach(pe => {
            switch (pe.elementType) {
                case GeometricElementType.Segment:
                    const s = pe as Segment;
                    const sa = XcMaths.toDeg(s.angle());
                    result.push(new SvgEntityPoint(SvgEntityPointStyleEnum.plineMiddle, s.midPoint(), entityId, -sa));
                    break;
                case GeometricElementType.Arc:
                    const a = pe as Arc;
                    let aa = XcMaths.toDeg(a.chord().angle());
                    result.push(new SvgEntityPoint(SvgEntityPointStyleEnum.plineMiddle, a.midPoint(), entityId, -aa));
                    break;
                default:
                    break;
            }
        });

        return result;
    }

    static getArcSegmentCenter(source: string | GeometricElement[], reticle: Rectangle): Point[] {
        let entities: GeometricElement[] = [];
        if (typeof source === "string") {
            entities = this.getPathEntities(source);
        } else {
            entities = source;
        }

        const result: Point[] = [];
        entities.forEach(g => {
            result.push(...IntersectionService.getRectangleIntersects(g, reticle));
        });

        return result;
}

    static getPathAbsoluteMoveToStatement(x: number, y: number): string
    {
        return `${SvgConstants.PathAbsoluteMoveToFlagName}${x} ${y}`;
    }

    static getPathAbsoluteLineToStatement(x2: number, y2: number): string
    {
        return `${SvgConstants.PathAbsoluteLineToFlagName}${x2} ${y2}`;
    }

    static isClosed(d: string): boolean {
        return d.toLowerCase().trimEnd().endsWith('z');
    }

    static tryGetCircle(path: SvgPath): SvgEntity {
        const d = path.d;
        if (d) {
            if (this.isClosed(d)) {
                // Le path est un cercle s'il est fermé et qu'il
                // a deux (et seulement deux) segments d'arc de même rayon et qui tournent dans le même sens
                const entities = this.getPathEntities(d);
                const arcs = (entities.filter(x=> x.elementType === GeometricElementType.Arc) as Arc[]);
                if (arcs.length === 2 && arcs[0].radius === arcs[1].radius && arcs[0].isClockWise === arcs[1].isClockWise) {
                    const center = arcs[0].center();
                    return SvgCircle.create(center.x, center.y, arcs[0].radius);
                }
            }
        }
        return path;
    }
    
    static tryGetLine(path: SvgPath): SvgEntity {
        const d = path.d;
        if (d) {
            // Le path est une ligne s'il a deux points et rien d'autre
            const entities = this.getPathEntities(d);
            if (entities.length === 1 && entities[0].elementType === GeometricElementType.Segment) {
                const l = entities[0] as Segment;
                return SvgLine.fromValues(l.startPoint.x, l.startPoint.y, l.endPoint.x, l.endPoint.y);
            }
        }
        return path;
    }

    static getSubEntity(path: SvgPath): SvgEntity {
        const d = path.d;
        
        const c = this.tryGetCircle(path);
        if (c.entityType === SvgEntityTypesEnum.circle) {
            return c;
        }

        const l = this.tryGetLine(path);
        if (l.entityType === SvgEntityTypesEnum.line) {
            return l;
        }


        return path;
    }

    static getPathArcStatement(rx: number, ry: number, xAxisRotationInDegrees: number, isLargArc: number, isClockwise: number, x: number, y: number): string
    {
        return `${SvgConstants.PathArcFlagName}${rx} ${ry} ${xAxisRotationInDegrees} ${isLargArc} ${isClockwise} ${x} ${y}`;
    }

    static getPathEllipticalArcStatement(startPoint: Point, endPoint: Point, majorRadius: number, minorRadius: number, rotationAngleInDegrees: number, isClockWise: number, isLargeArc: number): string
    {
        var rad = XcMaths.round(XcMaths.toRad(rotationAngleInDegrees), 3);

        // Si startPoint == endPoint alors il s'agit d'une ellipse complète
        // dans ce cas il faut générer un statement pour deux demi-ellipses
        if (startPoint.equals(endPoint))
        {
            var midPoint = new Point(startPoint.x + majorRadius * 2, startPoint.y).rotate(startPoint, rad).rounded(3);
            var half1 = SvgPathService.getPathAbsoluteMoveToStatement(startPoint.x, startPoint.y) + " " + SvgPathService.getPathArcStatement(majorRadius, minorRadius, rotationAngleInDegrees, 0, isClockWise, midPoint.x, midPoint.y);
            var half2 = SvgPathService.getPathArcStatement(majorRadius, minorRadius, rotationAngleInDegrees, 0, isClockWise, startPoint.x, startPoint.y);
            return half1 + " " + half2;
        }

        return SvgPathService.getPathAbsoluteMoveToStatement(startPoint.x, startPoint.y) + " " + SvgPathService.getPathArcStatement(majorRadius, minorRadius, rotationAngleInDegrees, isLargeArc, isClockWise, endPoint.x, endPoint.y);
    }

    static getPolygon(pathStatement: string, closed: boolean): Polygon {
        const pnts = this.getVertices(pathStatement);
        const result = new Polygon(pnts);
        if (closed) result.close();
        return result; 
    }

    static getLineStatement(line: SvgLine, decimalsCount: number = 3): string {
        return `${this.getPathAbsoluteMoveToStatement(XcMaths.round(line.x1, decimalsCount), XcMaths.round(line.y1, decimalsCount))} ${this.getPathAbsoluteLineToStatement(XcMaths.round(line.x2, decimalsCount), XcMaths.round(line.y2, decimalsCount))}`;
    }

    static getCircleStatement(circle: SvgCircle, decimalsCount: number = 3): string {
        const g = SvgCircleService.geometry(circle);
        const qds = g.getQuadrants();
        const ql = qds[2].rounded(decimalsCount);
        const qr = qds[0].rounded(decimalsCount);
        const r = XcMaths.round(circle.r, decimalsCount);
        return `${this.getPathAbsoluteMoveToStatement(ql.x, ql.y)} ${this.getPathArcStatement(r, r, 0, 0, 0, qr.x, qr.y)} ${this.getPathArcStatement(r, r, 0, 0, 0, ql.x, ql.y)} Z`;
    }

    static fromEntities(entities: SvgEntity[], decimalsCount: number = 3): string {
        let result: string = "";
        entities.forEach(e => {
            if (result.length > 0) result += ' ';
            switch (e.entityType) {
                case SvgEntityTypesEnum.line:
                    result += this.getLineStatement(e as SvgLine, decimalsCount);
                    break;
                case SvgEntityTypesEnum.path:
                    result += (e as SvgPath).d;
                    break;
                case SvgEntityTypesEnum.circle:
                    result += this.getCircleStatement(e as SvgCircle, decimalsCount);
                    break;
                default:
                    break;
            }
        });

        return result;
    }
}
