import { ZContextUITableUserTable } from './../../model/db-model/tables/z-context-ui-table-user-table';
import { Service } from "typedi";
import { ApiService } from "../api-service";
import { Paginator } from "../../../components-lib/dyn-grid/model/paginator";
import { ApiControllers } from "../api-endpoints";
import { ZContextUITableUser } from "../../model/data-model/tables/z-context-ui-table-user";
import { UsageContextIdEnum } from '../../model/usage-context-id-enum';
import { HttpParams } from '@angular/common/http';

@Service({ global: true })  
export class PaginationService extends ApiService {
    private tableItemsCountEndpoint(tableName: string): string { return `${ApiControllers.pagination}/tableItemsCount/${tableName}` };
    private paginatorEndpoint(tableName: string): string { return `${ApiControllers.pagination}/paginator/${tableName}` };
    private updateDefaultEndpoint: string = `${ApiControllers.pagination}/updateDefault`;
    private updatepageSizeEndpoint: string = `${ApiControllers.pagination}/updatepagesize`;

    constructor() {
        super();
    }

    async getPaginator(tableName: string, primaryFilter?: string): Promise<Paginator | null> {
        let params: HttpParams | undefined;
        if (primaryFilter) {
            params = new HttpParams();
            params = params.set('primaryFilter', primaryFilter);
        }
        const result = await this.getAsync<any>(this.endPoint(this.paginatorEndpoint(tableName)), UsageContextIdEnum.ReferentialAdministration, params);
        if (result != null) {
            return new Paginator(result.payload);
        }
        return null;
    }

    async updatepageSize(contextTableUserId: number, pageSize: number): Promise<boolean> {
        const dto: any = {
            [ZContextUITableUserTable.coUITaUsId]: contextTableUserId,
            [ZContextUITableUserTable.coUITaUsPageSize]: pageSize
        }
        const result = await this.patchAsync<boolean>(this.endPoint(this.updatepageSizeEndpoint), dto);
        if (result != null) {
            return result.payload;
        }
        return false;
    }
}