import { Point } from "src/app/core/model/geometry-model/point.model";
import { SvgEntityTypesEnum } from "src/app/core/model/svg-model/svg-entity-type-enum";
import { SvgEntity } from "src/app/core/model/svg-model/svg-entity.model";
import { BpSvgEntity } from "./bp-svg-entity";

export class BpSvgCircle extends BpSvgEntity {
    cx: number | undefined;
    cy: number | undefined;
    r: number | undefined;

    constructor(dtoData: any) {
        super(dtoData);
        this.cx = SvgEntity.getNumber(dtoData, "cx");
        this.cy = SvgEntity.getNumber(dtoData, "cy");
        this.r = SvgEntity.getNumber(dtoData, "r");
    }

    static create(cx: number, cy: number, r: number): BpSvgCircle {
        const result = new BpSvgCircle({ entityType: SvgEntityTypesEnum.circle });
        result.cx = cx;
        result.cy = cy;
        result.r = r;
        return result;
    }

    centerPoint(): Point {
        return new Point(this.cx, this.cy);
    }
}