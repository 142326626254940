import { SelectableRealEstateTreeVM } from '../../model/selectable-real-estate-tree-vm';
import { MaterialModule } from 'src/app/core/material.module';
import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'xc-selectable-real-estate-tree',
  standalone: true,
  imports: [CommonModule, MaterialModule],
  templateUrl: './selectable-real-estate-tree.component.html',
  styleUrls: ['./selectable-real-estate-tree.component.scss']
})
export class SelectableRealEstateTreeComponent {
  @Input() model: SelectableRealEstateTreeVM | undefined;
}
