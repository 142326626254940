export class SvgDOMItem {
    tagName: string;
    floorModelId: number;
    floorDataId: number;

    constructor(tagName: string, floorModelId: string | null, floorDataId: string | null) {
        this.tagName = tagName;
        this.floorModelId = Number(floorModelId);
        this.floorDataId = Number(floorDataId);
    }
}