import { DynTableVMBuilder } from "src/app/components-lib/dyn-grid/model/dyn-table-vm-builder";
import { TabPageItem } from "src/app/components-lib/tab-page-container/model/tab-page-item";
import { TabPager } from "src/app/components-lib/tab-page-container/model/tab-pager";
import { readableUUID } from "src/app/core/events/event-listener-uuid";
import { ApiEndpoints } from "src/app/core/services/api-endpoints";
import { DyntService } from "src/app/core/services/backend-services/dynt-service";
import { PaginationService } from "src/app/core/services/backend-services/pagination-service";
import { ProviderCompanyService } from "src/app/core/services/backend-services/provider-company-service";
import { UploadService } from "src/app/core/services/backend-services/upload-service";
import Container from "typedi";
import { ProvidersTabEnum } from "./providers-tab-enum";
import { ITabPageContent } from "src/app/components-lib/tab-page-container/model/i-tab-page-content";
import { TableReportWrapper } from "src/app/ui/shared/charts/table/table-report-wrapper";
import { ProvidersDashboardVM } from "./providers-dashboard-vm";

export class ProvidersManagePager extends TabPager {
    tableName: string = "provider_company";
    providersTabEnum = ProvidersTabEnum;

    private constructor() {
        super(readableUUID("ProvidersManageVM"));
    }
    
    static async newAsync(): Promise<ProvidersManagePager> {
        const result = new ProvidersManagePager();
        await result.initialize();
        return result;
    }

    async initialize(): Promise<void> {
        this.prepareTabs();
        await this.selectTabPage(0);
    }

    prepareTabs(): void {
        this.tabs.push(new TabPageItem(ProvidersTabEnum.list, "Liste"));
        this.tabs.push(new TabPageItem(ProvidersTabEnum.dashboard, "Suivi"));
    }

    override async loadContent(): Promise<ITabPageContent | undefined> {
        if (!this.selectedTab) return;

        switch (this.selectedTab.id) {
            case ProvidersTabEnum.list:
                return await this.loadAdminData();
            case ProvidersTabEnum.dashboard:
                return new ProvidersDashboardVM();
            default:
                break;
        }

        return undefined;
    }

    async loadAdminData(): Promise<TableReportWrapper> {
        const p = Container.get(PaginationService);
        const paginator = await p.getPaginator(this.tableName);
        const s = Container.get(DyntService);
        const viewSet = await s.contextedDataSet(this.tableName, 0, undefined, undefined, paginator?.pageItemsCount);
        const adminTable = DynTableVMBuilder.getVM(viewSet, this.tableName, paginator);

        adminTable.imageUploadRequested = async (file: File, inserting: boolean, maxSize: number, rowId: number): Promise<string> => {
            const s = Container.get(UploadService);
            if (inserting) {
                return await s.uploadTempFile(file, `${ApiEndpoints.endPoint(ApiEndpoints.fsToAirlock)}`, maxSize);
            } else {
                return await s.uploadUpdateFile(file, `${ApiEndpoints.endPoint(ApiEndpoints.fsProviderLogo)}`, rowId);
            }
        }

        adminTable.newRowSaveRequested = async (values: any): Promise<any> => {
            const s = Container.get(ProviderCompanyService);
            return await s.createNewProviderCompany(values);
        }

        adminTable.rowDeleteRequested = async (tableName: string, rowId: any): Promise<boolean> =>  {
            let result: boolean = false;
            
            const s = Container.get(ProviderCompanyService);
            const p = await s.deleteProviderCompany(rowId);
            if (p) {
                result = true;
            }

            return result;
        }
        
        const result = new TableReportWrapper();
        result.set(adminTable);
        return result;
    }
}