import { DynTableVM } from 'src/app/components-lib/dyn-grid/model/dyn-table-vm';
import { DynTableVMBuilder } from 'src/app/components-lib/dyn-grid/model/dyn-table-vm-builder';
import { PropertyGridVM } from 'src/app/components-lib/property-grid/model/property-grid-vm';
import { ReportGridItem } from 'src/app/components-lib/report-grid/model/report-grid-item';
import { ReportGridVM } from 'src/app/components-lib/report-grid/model/report-grid-vm';
import { DefinedModelBase } from 'src/app/core/model/data-model/defined-model-base';
import { TablesNamesEnum } from 'src/app/core/model/db-model/tables-names-enum';
import { BuildingTable } from 'src/app/core/model/db-model/tables/building-table';
import { BusinessUnitTable } from 'src/app/core/model/db-model/tables/business-unit-table';
import { FloorTable } from 'src/app/core/model/db-model/tables/floor-table';
import { DyntService } from 'src/app/core/services/backend-services/dynt-service';
import { PaginationService } from 'src/app/core/services/backend-services/pagination-service';
import { Container } from 'typedi';

export class PropertiesEditorVM<T extends DefinedModelBase> {
    tableName: string;
    itemId: number | null;
    propertyGridVM: PropertyGridVM<T> | undefined;
    adminTable: DynTableVM | undefined;
    reportGrid: ReportGridVM | undefined;

    constructor(tableName: string, itemId: number) {
        this.tableName = tableName;
        this.itemId = itemId === 0 ? null : itemId;
        this.loadItemDatas();
    }

    async loadItemDatas(): Promise<void> {
        if (this.itemId != null) {
            const s = Container.get(DyntService);
            const datas = await s.downloadRowWithRefDatas<T>(this.tableName, this.itemId);
            if (datas) {
                this.propertyGridVM = new PropertyGridVM<T>(this.tableName, datas);
                this.listenToSaveRequests(this.propertyGridVM);
            }
        }

        const childrenSet = this.childrenSet();
        if (childrenSet) {
            this.adminTable = await this.loadAdminData(childrenSet.tableName, childrenSet.columnName);
        }
    }

    protected childrenSet(): {tableName: string, columnName: string | undefined} | undefined {
        switch (this.tableName) {
            case TablesNamesEnum.Site:
                if (this.itemId == null) {
                    return {tableName: TablesNamesEnum.Site, columnName: undefined};
                }
                return {tableName: TablesNamesEnum.Building, columnName: BuildingTable.buSiteId};
            case TablesNamesEnum.Building:
                return {tableName: TablesNamesEnum.Floor, columnName: FloorTable.flBuildingId};
            case TablesNamesEnum.Floor:
                return undefined;
            case TablesNamesEnum.BusinessUnit:
                if (this.itemId == null) {
                    return {tableName: TablesNamesEnum.BusinessUnit, columnName: BusinessUnitTable.buUnParentId};
                }
                return {tableName: TablesNamesEnum.BusinessUnit, columnName: BusinessUnitTable.buUnParentId};
            default:
                return undefined;
        }

        // if (this.itemId == null) {
        //     return {tableName: TablesNamesEnum.Site, columnName: undefined};
        // }
        // switch (this.tableName) {
        //     case TablesNamesEnum.Site:
        //         return {tableName: TablesNamesEnum.Building, columnName: BuildingTable.buSiteId};
        //     case TablesNamesEnum.Building:
        //         return {tableName: TablesNamesEnum.Floor, columnName: FloorTable.flBuildingId};
        //     case TablesNamesEnum.Floor:
        //         return undefined;
        //     default:
        //         return undefined;
        // }
    }

    setReportGrid(rows: ReportGridItem<any>[]): void {
        this.reportGrid = new ReportGridVM(rows);
    }

    listenToSaveRequests(pg: PropertyGridVM<T>): void {
        pg.saveRequested = async (tableName: string, primaryColumnName: string, rowId: any, columnName: string, value: any): Promise<string | null> => {
            const s = Container.get(DyntService);
            const dto: any = {
                "TableName": tableName,
                [primaryColumnName]: rowId,
                [columnName]: value
            }
            return await s.patch(tableName, rowId, dto);
        }
    }

    saveRequested?: (tableName: string, primaryColumnName: string, rowId: any, columnName: string, value: any) => Promise<string | null>;
    insertRequested?: (values : {}) => Promise<any>;
    deleteRequested?: (tableName: string, rowId: any) => Promise<boolean>;
    async loadAdminData(tableName: string, columnName: string | undefined): Promise<DynTableVM> {
        const s = Container.get(DyntService);
        const p = Container.get(PaginationService);
        const paginator = await p.getPaginator(tableName);
        const viewSet = await s.contextedDataSet(tableName, 0, columnName, this.itemId, paginator?.pageItemsCount);
        const adminTable = DynTableVMBuilder.getVM(viewSet, tableName, paginator);

        // adminTable.newPageDataRequested = async (pageIndex: number) => {
        //     const px = await s.contextedDataSet(tableName, pageIndex, columnName, this.itemId, paginator?.pageItemsCount);
        //     adminTable.setDataSource(px.viewData);
        // }

        // adminTable.saveRequested = async (tableName: string, primaryColumnName: string, rowId: any, columnName: string, value: any): Promise<string | null> => {
        //     if (this.saveRequested) {
        //         return await this.saveRequested(tableName, primaryColumnName, rowId, columnName, value);
        //     } else {
        //         const s = Container.get(DyntService);
        //         const dto: any = {
        //             "TableName": tableName,
        //             [primaryColumnName]: rowId,
        //             [columnName]: value
        //         }
        //         return await s.patch(tableName, rowId, dto);
        //     }
        // }

        // TODO : cette méthode peut être mutualisée avec toutes les autres écoutes de ce type
        // adminTable.insertionRequested = async (values : {}): Promise<any> => {
        //     if (this.insertRequested) {
        //         return await this.insertRequested(values);
        //     } else {
        //         const s = Container.get(DyntService);
        //         return await s.post<any>("", values);
        //     }
        // }

        // TODO : cette méthode peut être mutualisée avec toutes les autres écoutes de ce type
        // adminTable.deletionRequested = async (tableName: string, rowId: any): Promise<boolean> =>  {
        //     if (this.deleteRequested) {
        //         return await this.deleteRequested(tableName, rowId);
        //     } else {
        //         const s = Container.get(DyntService);
        //         const result = await s.delete(tableName, rowId);
        //         if (result) {
        //             return true;
        //         }
        //         return false;
        //     }
        // }

        return adminTable;
    }

    // async loadAdminData(tableName: string, columnName: string | undefined): Promise<DynTableVM> {
    //     const s = Container.get(DyntService);
    //     const viewSet = await s.contextedDataSet(tableName, undefined, columnName, this.itemId);
    //     const p = Container.get(PaginationService);
    //     const paginator = await p.getPaginator(tableName);
    //     const adminTable = DynTableVMBuilder.getVM(viewSet, tableName, paginator);

    //     adminTable.saveRequested = async (tableName: string, primaryColumnName: string, rowId: any, columnName: string, value: any): Promise<string | null> => {
    //         const s = Container.get(DyntService);
    //         const dto: any = {
    //             "TableName": tableName,
    //             [primaryColumnName]: rowId,
    //             [columnName]: value
    //         }
    //         return await s.patch(tableName, rowId, dto);
    //     }

    //     // TODO : cette méthode peut être mutualisée avec toutes les autres écoutes de ce type
    //     adminTable.insertionRequested = async (values : {}): Promise<any> => {
    //         const s = Container.get(DyntService);
    //         return await s.post<any>("", values);
    //     }

    //     // TODO : cette méthode peut être mutualisée avec toutes les autres écoutes de ce type
    //     adminTable.deletionRequested = async (tableName: string, rowId: any): Promise<boolean> =>  {
    //         const s = Container.get(DyntService);
    //         const result = await s.delete(tableName, rowId);
    //         if (result) {
    //             return true;
    //         }
    //         return false;
    //     }

    //     return adminTable;
    // }
}