export class ScheduleHeaderCell {
    label: string;
    startDate: Date;
    duration: number;

    constructor(label: string, startDate: Date, duration: number) {
        this.label = label;
        this.startDate = startDate;
        this.duration = duration;
    }
}