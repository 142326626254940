import { SvgTransform } from "./svg-transform.model";
import { HtmlConstants } from '../html-model/html-constants.model';
import { StringUtils } from "../static-functions/string-utils";
import { logError } from "../../services/logging-service";

export class SvgEntity {
    entityType: number;
    transform: SvgTransform | undefined;

    stroke: string | undefined;
    strokeWidth: number | undefined;
    strokeDasharray: string | undefined;
    fill: string | undefined;
    fillOpacity: number | undefined;
    fillRule: string | undefined = "evenodd";
    display: string = HtmlConstants.styleDisplayBlock;
    cursor: string = HtmlConstants.styleCursorInherit;
    opacity: number = 1;

    hiddenByProcess: boolean = false;

    // initialTransform sert lors de la gestion des transformations
    initialTransform: SvgTransform | undefined;

    constructor(dtoData: any) {
        this.entityType = SvgEntity.getValue(dtoData, "entityType");
        this.transform = new SvgTransform(SvgEntity.getValue(dtoData, "transform", undefined, false));

        this.stroke = SvgEntity.getValue(dtoData, "stroke", undefined, false);
        this.strokeWidth = SvgEntity.getValue(dtoData, "strokeWidth", undefined, false);
        this.strokeDasharray = SvgEntity.getValue(dtoData, "strokeDasharray", undefined, false);
        this.fill = SvgEntity.getValue(dtoData, "fill", undefined, false);
        this.fillOpacity = SvgEntity.getValue(dtoData, "fillOpacity", undefined, false);
        this.fillRule = SvgEntity.getValue(dtoData, "fillRule", "evenodd", false);
        this.display = SvgEntity.getValue(dtoData, "display", HtmlConstants.styleDisplayBlock, false);
        this.cursor = SvgEntity.getValue(dtoData, "cursor", HtmlConstants.styleCursorInherit, false);
    }

    show(display: boolean): void {
        if (display) {
            this.display = HtmlConstants.styleDisplayBlock;
        } else {
            this.display = HtmlConstants.styleDisplayNone;
        }
    }

    clone(): any {
        const result: any = {};
        const allPropertyNames = Object.getOwnPropertyNames(this);
        allPropertyNames.forEach(p => {
            result[p] = Object.getOwnPropertyDescriptor(this, p)?.value;
        });
        return result;
    }

    static getValue(dtoData: any, propertyName: string, defaultValue: any | undefined = undefined, mandatory: boolean = true): any | undefined {
        if (dtoData[propertyName] != undefined) {
            return dtoData[propertyName];
        } else {
            if (dtoData.attributes) {
                // Si la propriété n'a pas été trouvée dans dtoData, on la recherche dans une éventuelle propriété "attributes"
                if (dtoData.attributes[propertyName]) {
                    return dtoData.attributes[propertyName];
                }
            }
            if (mandatory) {
                logError("SvgEntity : La propriété requise [" + propertyName + "] n'était pas présente dans le jeu de données");
            }
        }
        return defaultValue;
    }

    static getBoolean(dtoData: any, propertyName: string, defaultValue: boolean, mandatory: boolean = true): boolean {
        const result = SvgEntity.getValue(dtoData, propertyName, defaultValue, mandatory);
        return StringUtils.toBoolean(result);
    }

    static getNumber(dtoData: any, propertyName: string, defaultValue: number = 0, mandatory: boolean = true): number {
        const result = SvgEntity.getValue(dtoData, propertyName, defaultValue, mandatory);
        return Number(result);
    }

    static getNullableNumber(dtoData: any, propertyName: string, defaultValue: number | null = null, mandatory: boolean = true): number | null {
        const result = SvgEntity.getValue(dtoData, propertyName, defaultValue, mandatory);
        return Number(result);
    }

    static getFacultativeNumber(dtoData: any, propertyName: string, defaultValue: number | undefined = 0): number | undefined {
        const result = SvgEntity.getValue(dtoData, propertyName, defaultValue, false);
        if (result) return Number(result);
        return undefined;
    }
}
