import { Point } from "src/app/core/model/geometry-model/point.model";
import { SimulationBuildingDTO } from "src/app/core/services/backend-services/dto/simulation-building-dto";
import { SvgStackSettings } from "./svg-stack-settings";
import { SvgStackFloorVM } from "./svg-stack-floor-vm";
import { SvgBsFloorDataItem } from "src/app/ui/shared/charts/stack/model/svg-bs-floor-data-item";
import { logError } from "src/app/core/services/logging-service";

export class SvgBuildingStackVM {
    bottomMargin: number = 40;
    buildingDTO: SimulationBuildingDTO;
    floors: SvgStackFloorVM[] = [];

    position: Point;
    width: number;
    height: number;

    onFloorLabelClick?: (floorId: number) => void;

    constructor(sb: SimulationBuildingDTO, positionX: number, bottomY: number) {
        this.buildingDTO = sb;
        this.height = sb.floors.length * SvgStackSettings.floorHeight;
        this.position = new Point(positionX, bottomY - this.height - this.bottomMargin);
        this.width =  SvgStackSettings.floorLabelsWidth + (sb.maxFloorArea() * SvgStackSettings.xScale);

        const maxArea = sb.maxFloorArea();

        let yPos: number = this.position.y + this.height - SvgStackSettings.floorHeight;
        sb.floors.forEach(f => {
            const sf = new SvgStackFloorVM(f, 0, yPos, f.siFlViArea * SvgStackSettings.xScale, SvgStackSettings.floorHeight, f.flCode, f.siFlViArea, maxArea);
            this.floors.push(sf);
            yPos -= SvgStackSettings.floorHeight;

            sf.floorLabelClick = () => { 
                if (this.onFloorLabelClick) {
                    this.onFloorLabelClick(sf.floorDTO.siFlViId); 
                } else {
                    logError("SvgBuildingStackVM.onFloorLabelClick n'est pas écouté");
                }
            };

            sf.loadData();
        });
    }

    arrange(): void {
        this.floors.forEach(f => {
            f.scaleX = 1;
        });
    }

    raiseTooltipRequest(e: MouseEvent, d: SvgBsFloorDataItem | undefined): void {
        if (this.tooltipRequested) {
            this.tooltipRequested(e, d);
        } else {
            logError("SvgBuildingStackVM.tooltipRequested n'est pas écouté");
        }
    }

    tooltipRequested?: (e: MouseEvent, d: SvgBsFloorDataItem | undefined) => void;
    reportItemMouseover(e: MouseEvent, d: SvgBsFloorDataItem): void {
        this.raiseTooltipRequest(e, d);
    }

    areaStartDraggingRequested?: (e: MouseEvent, d: SvgBsFloorDataItem) => void;
    reportItemMouseDown(e: MouseEvent, d: SvgBsFloorDataItem): void {
        if (e.buttons !== 1) return;
        if (this.areaStartDraggingRequested) {
            this.areaStartDraggingRequested(e, d);
        } else {
            logError("SvgBuildingStackVM.areaStartDraggingRequested n'est pas écouté");
        }
    }

    bgMousemove(e: MouseEvent): void {
        this.raiseTooltipRequest(e, undefined);
    }

    floorLabelMousemouve(e: MouseEvent): void {
        this.raiseTooltipRequest(e, undefined);
    }
}