export class FieldListItem {
    id: number | null;
    value: any;
    fileUrl: string | null;

    constructor(id: number | null, value: any, fileUrl: string | null) {
        this.id = id;
        this.value = value;
        this.fileUrl = fileUrl;
    }
}