import { Point } from "src/app/core/model/geometry-model/point.model";
import { Segment } from "src/app/core/model/geometry-model/segment.model";
import { Vector } from "src/app/core/model/geometry-model/vector.model";
import { SvgTransform } from "src/app/core/model/svg-model/svg-transform.model";
import { BpSvgBoundingBox } from "../../../../../bp-svg-core-model/bp-svg-bounding-box";
import { BpSvgUse } from "../../../../../bp-svg-core-model/bp-svg-use";
import { EquipmentSelectionFreeTranslationHandle } from "./equipment-selection-free-rotation-handle";
import { EquipmentSelectionRotationHandle } from "./equipment-selection-rotation-handle";
import { EquipmentSelectionSideHandles } from "./equipment-selection-side-handles";
import { Grips } from "src/app/components-lib/svg/grips/grips";
import { logError } from "src/app/core/services/logging-service";

export class EquipmentSelectionOverlay {
    transform: SvgTransform | undefined;
    initialTransform: SvgTransform | undefined;
    minX: number = 0;
    minY: number = 0;
    width: number = 0;
    height: number = 0;
    stroke: string = "#00cc7a";
    fill: string = EquipmentSelectionOverlay.defaultColor;

    static defaultColor = "#00ff99";
    static copiedItemsColor = "#ff00ff";
    
    sideHandles: EquipmentSelectionSideHandles = new EquipmentSelectionSideHandles();
    freeTranslationHandle: EquipmentSelectionFreeTranslationHandle = new EquipmentSelectionFreeTranslationHandle();
    rotationHandle: EquipmentSelectionRotationHandle = new EquipmentSelectionRotationHandle();
    grips: Grips = new Grips("orange", "orange");

    // TODO : Mutualiser avec PocSvgTransientPosition les propriétés et méthodes qui se ramènent à celles d'une bbox
    constructor() {

    }

    setColor(color: string): void {
        this.fill = color;
        this.sideHandles.items.forEach(x => x.setFill(color));
        this.freeTranslationHandle.fill = color;
    }

    /**
     * Actualise l'overlay du jeu de sélection ainsi que les poignées, latérales, de rotation libre et de translation libre
     * @param selectedUses Jeu de sélection
     */
    update(selectedUses: BpSvgUse[]): void {
        this.updateSelectionOverlay(selectedUses);
        const bbox = this.bBox();
        this.sideHandles.update(bbox);
        this.freeTranslationHandle.update(bbox);
        this.rotationHandle.update(bbox);
    }

    rotate(rotationCenter: Point, deltaAngle: number): void {
        this.rotateTranslate(rotationCenter, deltaAngle);
    }

    translate(deltaTranslate: Vector): void {
        //let newT = this.initialTransform.clone();
        //newT.translate = newT.translate.translate(deltaTranslate);
        this.transform = this.initialTransform?.addTranslation(deltaTranslate);
    }
    
    rotateTranslate(rotationCenter: Point, deltaAngle: number, deltaTranslate?: Vector): void {
        let newT = this.initialTransform?.clone().rotateTranslate(rotationCenter, deltaAngle, deltaTranslate);
        this.transform = newT;
    }
    
    /**
     * Actualise l'overlay du jeu de sélection
     * @param selectedUses Jeu de sélection
     */
    private updateSelectionOverlay(selectedUses: BpSvgUse[]): void {
        if (selectedUses.length > 0)
        {
            // S'il n'y a qu'un élément sélectionné, l'overlay colle à sa bbox, c'est-à-dire qu'il est
            // affiché avec la même rotation
            // Sinon l'overlay est affiché comme une bbox globale non tournée
    
            var firstOne = selectedUses[0];
            if (selectedUses.length === 1 && firstOne.def?.bBox)
            {
                this.transform = firstOne.transform;
                this.setRectValues(firstOne.def.bBox);
            }
            else
            {
                // TODO : Pour permettre le déplacement d'un groupe d'éléments basé sur les contours les plus extérieurs des éléments
                // il faudrait générer la plus petite bbox tournée et non la plus petite bbox droite
                // On pourrait aussi, et ce serait peut être plus simple, autoriser une "sélection" complémentaire permettant au user 
                // d'indiquer uen c'est tel élément du jeu de sélection qui va servir à conduire la transformation.
                // par exemple en cliquant une nouvelle fois sur un item de la sélection, il fait apparaître les grips de contour de cet item
                // à la place des grips de contour de l'overlay
                const tmp = this.getGlobalTransformedBbox(selectedUses);
                if (tmp) {
                    this.transform = new SvgTransform({translate: new Point(tmp.minX, tmp.minY), rotationCenter: Point.origin(), rotationAngle: 0});
                    this.setRectValues(new BpSvgBoundingBox(0, 0, tmp.maxX - tmp.minX, tmp.maxY - tmp.minY));
                }
            }
    
        }
        else
        {
            this.setRectValues(BpSvgBoundingBox.default());
            this.transform = undefined;
        }
    }

    /**
     * Défini les valeurs du rectangle svg représentant la boite englobante du jeu de sélection
     * @param b Boite englobante du jeu de sélection
     */
    setRectValues(b: BpSvgBoundingBox): void {
        this.minX = b.minX;
        this.minY = b.minY;
        this.width = b.width();
        this.height = b.height();
    }

    /**
     * 
     * @param selectedUses jeu de sélection
     * @returns Boite englobante transformée du jeu de sélection
     */
    getGlobalTransformedBbox(selectedUses: BpSvgUse[]): BpSvgBoundingBox | null {
        var result = selectedUses[0].transformedBbox();
        if (result) {
            for (let i = 1; i < selectedUses.length; i++) {
                const use = selectedUses[i];
                const bbox = use.transformedBbox();
                if (bbox) {
                    result.add(bbox);
                }
            }
        }
        return result;
    }

    /**
     * 
     * @returns boite englobante non transformée
     */
    bBox(): BpSvgBoundingBox {
        return new BpSvgBoundingBox(this.minX, this.minY, this.minX + this.width, this.minY + this.height);
    }

    transformedBbox(): BpSvgBoundingBox | null {
        if (this.transform) {
            return this.bBox().transformNew(this.transform);
        }
        return null;
    }

    /**
     * 
     * @returns Centre de la boite englobante transformée
     */
    transformedBboxCenter(): Point | null {
        if (this.transform) {
            return this.transform.transformedPoint(this.bBox().center());
            //return this.bBox().center().transform(this.transform.rotationCenter, this.transform.rotationAngle, this.transform.translate.vector());
        }
        return null;
    }

    getSide(handleId: string): Segment {
        if (this.transform) {
            return this.sideHandles.getTransformedSide(handleId, this.transform);
        }
        return Segment.null();
    }

    /**
     * 
     * @param handleId identifiant de la poignée latérale
     * @returns milieu du côté de la bbox transformée
     */
    getSideMidPoint(handleId: string): Point | null {
        if (this.transform) {
            return this.sideHandles.getSideMidPoint(handleId, this.transform);
        }
        return null;
    }

    getSideRadiusSegment(handleId: string): Segment {
        if (this.transform) {
            return this.sideHandles.getSideRadiusSegment(handleId, this.transform);
        }
        return Segment.null();
    }

    // getSideDirectionRelativeAngle(handleId: string): number
    // {
    //     switch (handleId)
    //     {
    //         case "sideDragHandle1":
    //             return 0;
    //         case "sideDragHandle2":
    //             return 90;
    //         case "sideDragHandle3":
    //             return 270;
    //         case "sideDragHandle4":
    //             return 180;
    //         default:
    //             return 0;
    //     }
    // }

    // getSideDirectionRelativeTransformedAngle(handleId): number {
    //     return PocMaths.get360DegreeAngle(this.getSideDirectionRelativeAngle(handleId) + this.transform.rotationAngle);
    // }

    public onSideHandled?: (handleCode: string, clientPoint: Point) => void
    sideHandleMouseDown(e: MouseEvent): void {
        e.stopPropagation();
        const handle = e.currentTarget as SVGPathElement;
        const handleId = handle.attributes.getNamedItem("id")?.value;
        if (!handleId) return;
        // Passe l'évènement au modèle
        const clientPoint = new Point(e.clientX, e.clientY);

        if (this.onSideHandled) {
            this.onSideHandled(handleId, clientPoint);
        } else {
            logError("EquipmentSelectionOverlay:onSideHandled n'est pas écouté");
        }
        // Il va y avoir une translation du jeu de sélection et on va devoir visualiser la translation de l'overlay
        // peut-etre à la suite d'une précédente transformation ayant laissé l'overlay avec un certain transform
        // on stoke donc le transform initial
        this.initialTransform = this.transform?.clone();
    }

    public onFreeTranslationHandled?: (clientPoint: Point) => void
    freeTranslationHandleMouseDown(e: MouseEvent): void {
        e.stopPropagation();

        const clientPoint = new Point(e.clientX, e.clientY)
        if (this.onFreeTranslationHandled) {
            this.onFreeTranslationHandled(clientPoint);
        } else {
            logError("EquipmentSelectionOverlay:onFreeTranslationHandled n'est pas écouté");
        }
        // Il va y avoir une translation du jeu de sélection et on va devoir visualiser la translation de l'overlay
        // peut-etre à la suite d'une précédente transformation ayant laissé l'overlay avec un certain transform
        // on stoke donc le transform initial
        this.initialTransform = this.transform?.clone();
    }

    public onFreeRotationHandled?: (clientPoint: Point) => void
    freeRotationHandleMouseDown(e: MouseEvent): void {
        e.stopPropagation();

        const clientPoint = new Point(e.clientX, e.clientY);
        if (this.onFreeRotationHandled) {
            this.onFreeRotationHandled(clientPoint);
        } else {
            logError("EquipmentSelectionOverlay:onFreeRotationHandled n'est pas écouté");
        }
        // Il va y avoir une rotation du jeu de sélection et on va devoir visualiser la rotation de l'overlay
        // on stoke donc le transform initial
        this.initialTransform = this.transform?.clone();
    }

    onGripTranslation?: (gripPoint: Point) => void
    gripMouseDown(e: MouseEvent): void {
        e.stopPropagation();

        if (!this.transform) return;

        // Le point qui est passé est le centre du grip
        const el = e.target as Element;
        const index = Number(el.getAttribute("index"));
        const grip = this.grips.items.filter(x=>x.index === index)[0];
        const transformedPoint = this.transform.transformedPoint(grip.point);

        if (this.onGripTranslation) {
            this.onGripTranslation(transformedPoint);
        } else {
            logError("EquipmentSelectionOverlay:onGripTranslation n'est pas écouté");
        }
        // Il va y avoir une translation du jeu de sélection et on va devoir visualiser la translation de l'overlay
        // peut-etre à la suite d'une précédente transformation ayant laissé l'overlay avec un certain transform
        // on stoke donc le transform initial
        this.initialTransform = this.transform?.clone();
    }
}