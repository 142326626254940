import { Service } from "typedi";
import { ApiControllers } from "../api-endpoints";
import { ApiService } from "../api-service";
import { HttpParams } from "@angular/common/http";

@Service({ global: true })  
export class DownloadService extends ApiService {
    private blueprintDownloadEndpoint(blueprintTaskId: number): string  { return `${ApiControllers.floors}/blueprintdownload/${blueprintTaskId}` };

    constructor() {
        super();
    }

    async dowloadBlueprintFile(blueprintTaskId: number, extension: string, fileName: string, allLayers: boolean = false): Promise<boolean> {
        let params = new HttpParams();
        params = params.append("all", allLayers);
        params = params.append("extension", extension);

        return await this.downloadFileAsync(this.endPoint(this.blueprintDownloadEndpoint(blueprintTaskId)), fileName, params);
    }
}