import { SvgEntityTypesEnum } from "src/app/core/model/svg-model/svg-entity-type-enum";
import { DxfSvgPath } from "../../svg/dxf-svg-path";
import { UpdateGizmo } from "./update-gizmo";
import { Point } from "src/app/core/model/geometry-model/point.model";
import { Vector } from "src/app/core/model/geometry-model/vector.model";

export class PathGizmo extends UpdateGizmo<DxfSvgPath> {
    constructor(pixelUnit: number) {
        super(pixelUnit, SvgEntityTypesEnum.path);
    }

    translateEndpoint(target: Point): void {
        if (this.single && this.currentGrip) {
            this.single.translatePoint(this.currentGrip.point, target);
            this.currentGrip.point = target;
        }
    }

    endEndpointTranslation(): void {
        if (this.single) {
            this.single.updateSelectablePoints();
        }
    }

    translatePaths(delta: Vector): void {
        this.entities.forEach(e => {
            e.translate(delta);
        });
        this.grips.items.forEach(g => {
            g.point = g.point.translate(delta);
        });
    }

    endPathsTranslation(): void {
        this.entities.forEach(e => {
            e.updateSelectablePoints();
        });
    }
}