@if (model) {
<div id="board-header">
    <xc-cad-conv-drawing-board-toolbar [model]="model.boardToolbar"/>
</div>
}
@if (model) {
<div id="board-content"
    (mousedown)="model.userInteraction.panzoomController.mouseDown($event)"
    (mousemove)="model.userInteraction.panzoomController.mouseMove($event)"
    (mouseleave)="model.userInteraction.panzoomController.mouseLeave($event)"
    (wheel)="model.userInteraction.panzoomController.mouseWheel($event)">

    <xc-cad-conv-drawing id="drawing" [model]="model.userInteraction"/>
    @if (model.userInteraction.currentCommand.isTextInputCommand()) {
<input id="dxf-text-label-input" matInput
       
        type="text" 
        xcAutoFocus
        autocomplete="off"
        [style.left.px]="$any(model.userInteraction.currentInteractions[0]).labelBbox?.x"
        [style.top.px]="$any(model.userInteraction.currentInteractions[0]).labelBbox?.y"
        [style.width.px]="$any(model.userInteraction.currentInteractions[0]).labelBbox?.width"
        [style.height.px]="$any(model.userInteraction.currentInteractions[0]).labelBbox?.height"
        [style.font-size.px]="$any(model.userInteraction.currentInteractions[0]).labelBbox?.height * 80 / 100"
        [(ngModel)]="$any(model.userInteraction.currentInteractions[0]).labelInputValue"
        (focusout)="$any(model.userInteraction.currentInteractions[0]).focusOut()" 
        (keydown)="$any(model.userInteraction.currentInteractions[0]).inputKeyDown($event)">
}
</div>
}

