import { EquipmentItemTable } from '../../db-model/tables/equipment-item-table';
import { DefinedModelBase } from '../defined-model-base';

export class EquipmentItem extends DefinedModelBase {
    eqItId!: number;
    eqItMaterialId!: string; 
    eqItCreationDate!: Date; 
    eqItCatalogId!: number; 
    eqItInventoryStateId!: number; 
    eqItPurchaseId: number | null = null;

    constructor(dtoData: any, tableDef: any[] = []) {
        super(dtoData, [EquipmentItemTable].concat(tableDef));
    }
}