import { Component, Input, SimpleChanges, OnChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SvgBuildingStackVM } from '../../model/svg-building-stack-vm';

@Component({
  selector: 'g[xc-svg-building-stack]',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './svg-building-stack.component.svg',
  styleUrls: ['./svg-building-stack.component.scss']
})
export class SvgBuildingStackComponent implements OnChanges {
  @Input() model: SvgBuildingStackVM | undefined;

  ngOnChanges(changes: SimpleChanges): void {
    if (this.model && this.model instanceof SvgBuildingStackVM) {
      setTimeout( () => {
        // Ce petit délai asynchrone permet l'animation d'entrée
        // Ce n'est pas la durée du délai qui compte mais simplement le fait qu'il soit asynchrone
        // et donc que le traitement soit déclenché après que la vue soit entièrement chargée
        // AfterViewInit ne suffit pas, ni OnChanges sans le timeout
        this.model?.arrange();
      }, 1);
    }
  }
}
