import { MatDialog } from '@angular/material/dialog';
import { RoomUpdateGizmoVM } from './../../model/room-update-gizmo-vm';
import { AfterViewInit, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { logError } from 'src/app/core/services/logging-service';

@Component({
  selector: 'g[xc-room-update-gizmo]',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './room-update-gizmo.component.svg',
  styleUrls: ['./room-update-gizmo.component.scss']
})
export class RoomUpdateGizmoComponent implements AfterViewInit {
  @Input() model: RoomUpdateGizmoVM | undefined;

  constructor(protected dialog: MatDialog) { }

  ngAfterViewInit(): void {
    // Injecte le matDialog dans le modèle
    if (this.model) {
      this.model.dialog = this.dialog;
    } else {
      logError("RoomUpdateGizmoComponent : model.dialog n'a pas pu être initialisé");
    }
  }
}
