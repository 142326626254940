import { GanttSvgTaskVM } from "./gantt-svg-task-vm";
import { TaskLinkView } from "src/app/core/model/data-model/views/task-link-type-view";
import { GanttTaskLinkPathBuilder } from "./gantt-task-link-path-builder";
import { GanttSvgTaskLinkBase } from "./gantt-svg-task-link-base";
import { Point } from "src/app/core/model/geometry-model/point.model";

export class GanttSvgTaskLink extends GanttSvgTaskLinkBase {
    taskLink: TaskLinkView;
    sourceTask: GanttSvgTaskVM;
    targetTask: GanttSvgTaskVM;
    currentUnitWidth: number;
    // firstPoint!: Point;
    // lastPoint!: Point;
    // transientFirstPoint: Point | undefined;
    // transientLastPoint: Point | undefined;
    // isStartPointMoving: boolean = false;

    constructor(taskLink: TaskLinkView, sourceTask: GanttSvgTaskVM, targetTask: GanttSvgTaskVM, currentUnitWidth: number) {
        super();
        this.taskLink = taskLink;
        this.id = taskLink.taLiId;
        this.sourceTask = sourceTask;
        this.targetTask = targetTask;
        this.currentUnitWidth = currentUnitWidth;
        this.calculatePathData();
    }

    setCurrentUnitWidth(value: number): void {
        this.currentUnitWidth = value;
        this.calculatePathData();
    }

    // isLinkable(newTask: GanttSvgTaskVM, toStart: boolean): boolean {
    //     // end to end implique que les deux tâches aient la même date de fin
    //     // start to start implique que les deux tâches aient la même date de début
    //     // end to start implique que la date de début de la tâche cible soit égale ou supérieur la la date de fin de la tâche source
    //     // start to end ne semble pas avoir vraiment de sens et ne devrait pas se produire

    //     if (newTask.task.taId === this.sourceTask.task.taId && !this.isStartPointMoving) return false;
    //     if (newTask.task.taId === this.targetTask.task.taId && this.isStartPointMoving) return false;

    //     switch (this.taskLink.taLiLinkType) {
    //         case TaskLinkTypeEnum.EndToEnd:
    //             if (!this.isStartPointMoving) {
    //                 if (toStart) {
    //                     return this.sourceTask.endDate() <= newTask.task.taStartDate;
    //                 } else {
    //                     return this.sourceTask.endDate().getTime() === newTask.endDate().getTime();
    //                 }
    //             } else {
    //                 if (toStart) {
    //                     return false;
    //                 } else {
    //                     return this.targetTask.endDate().getTime() === newTask.endDate().getTime();
    //                 }
    //             }
    //         case TaskLinkTypeEnum.StartToStart:
    //             if (this.isStartPointMoving) {
    //                 if (!toStart) {
    //                     return this.targetTask.task.taStartDate >= newTask.endDate();
    //                 } else {
    //                     return this.targetTask.task.taStartDate.getTime() === newTask.task.taStartDate.getTime();
    //                 }
    //             } else {
    //                 if (toStart) {
    //                     return this.sourceTask.task.taStartDate.getTime() === newTask.task.taStartDate.getTime();
    //                 } else {
    //                     return false;
    //                 }
    //             }
    //         case TaskLinkTypeEnum.EndToStart:
    //             if (this.isStartPointMoving) {
    //                 if (toStart) {
    //                     return this.targetTask.task.taStartDate.getTime() === newTask.task.taStartDate.getTime();
    //                 } else {
    //                     return this.targetTask.task.taStartDate >= newTask.endDate();
    //                 }
    //             } else {
    //                 if (toStart) {
    //                     return this.sourceTask.endDate() <= newTask.task.taStartDate;
    //                 } else {
    //                     return this.sourceTask.endDate().getTime() === newTask.endDate().getTime();
    //                 }
    //             }
    //         default:
    //             return false;
    //     }
    // }

    // endDate(task: GanttSvgTaskVM): Date {
    //     return DateUtils.addDays(task.task.taStartDate, task.task.taDuration);
    // }

    otherTask(taskId: number): GanttSvgTaskVM {
        if (this.sourceTask.task.taId === taskId) return this.targetTask;
        return this.sourceTask;
    }

    contains(taskId: number): boolean {
        return this.sourceTask.task.taId === taskId || this.targetTask.task.taId === taskId;
    }

    tasksIds(): number[] {
        return [this.sourceTask.task.taId, this.targetTask.task.taId];
    }

    // initializeEndMove(): void {
    //     this.transientFirstPoint = this.firstPoint;
    //     this.transientLastPoint = this.lastPoint;
    // }

    // transientMoved?: () => void;
    // finishEndMove(): void {
    //     this.transientFirstPoint = undefined;
    //     this.transientLastPoint = undefined;
    //     this.calculatePathData();
    //     if (this.transientMoved) {
    //         this.transientMoved();
    //     } else {
    //         LoggerService.missingListener("SvgGanttTaskLink.transientMoved");
    //     }
    // }

    // transientMoving?: (startPoint: Point, firstHandle: Point, lastHandle: Point, endPoint: Point) => void;
    // moveTransientStart(delta: Vector): void {
    //     this.isStartPointMoving = true;
    //     this.firstPoint = this.transientFirstPoint!.translate(delta);
    //     const firstHandle = new Point(this.firstPoint.x, this.lastPoint.y);
    //     const lastHandle = new Point(this.lastPoint.x, this.firstPoint.y);
    //     this.pathData = PathBuilder.getBezierPath(this.firstPoint, firstHandle, lastHandle, this.lastPoint);
    //     this.raiseTransientMoving(firstHandle, lastHandle);
    // }

    // moveTransientEnd(delta: Vector): void {
    //     this.isStartPointMoving = false;
    //     this.lastPoint = this.transientLastPoint!.translate(delta);
    //     const firstHandle = new Point(this.firstPoint.x, this.lastPoint.y);
    //     const lastHandle = new Point(this.lastPoint.x, this.firstPoint.y);
    //     this.pathData = PathBuilder.getBezierPath(this.firstPoint, firstHandle, lastHandle, this.lastPoint);
    //     this.raiseTransientMoving(firstHandle, lastHandle);
    // }

    // raiseTransientMoving(firstHandle: Point, lastHandle: Point): void {
    //     if (this.transientMoving) {
    //         this.transientMoving(this.firstPoint, firstHandle, lastHandle, this.lastPoint);
    //     } else {
    //         LoggerService.missingListener("SvgGanttTaskLink.transientMoving");
    //     }
    // }

    calculatePathData(): void {
        const tmp = GanttTaskLinkPathBuilder.calculatePathData(this.taskLink.taLiLinkType, 
            this.sourceTask.xPos, 
            this.sourceTask.yOffset,
            this.sourceTask.width,
            this.sourceTask.taskDefaultHeight,
            this.targetTask.xPos,
            this.targetTask.yOffset,
            this.targetTask.taskDefaultHeight);
        this.firstPoint = tmp.firstPoint;
        this.lastPoint = tmp.lastPoint;
        this.pathData = tmp.pathData;
        this.deleteCommandPosition = new Point(tmp.secondPoint.x - 12, tmp.secondPoint.y + 30);
    }

    setScale(currentUnitWidth: number): void {
        this.currentUnitWidth = currentUnitWidth;
        this.calculatePathData();
    }

    maxY(): number {
        return Math.max(this.firstPoint.y, this.lastPoint.y);
    }

    override deleteCommandClick(): void {
        this.sourceTask.removeLink(this.id);
    }
}