import { TablesNamesEnum } from './../tables-names-enum';
export const FloorModelTable = {
    databaseTableName: TablesNamesEnum.FloorModel,
    flMoId: 'FlMo_Id',
    flMoName: 'FlMo_Name',
    flMoCategoryId: "FlMo_CategoryId",
    flMoDisplayNameId: 'FlMo_DisplayNameId',
    flMoBlueprintAttributes: 'FlMo_BlueprintAttributes',
    flMoDisplayOrder: 'FlMo_DisplayOrder',
    flMoScope: 'FlMo_Scope',
    flMoIsActive: 'FlMo_IsActive',
    flMoTableName: 'FlMo_TableName',
    flMoReadGrantId: 'FlMo_ReadGrantId',
    flMoUpdateGrantId: 'FlMo_UpdateGrantId'
  }