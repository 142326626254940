import { TablesNamesEnum } from './../tables-names-enum';
export const TaskValidationTable = {
    databaseTableName: TablesNamesEnum.TaskValidation,
    taVaId: 'TaVa_Id',
    taVaTaskId: 'TaVa_TaskId',
    taVaUserId: 'TaVa_UserId',
    taVaIsApproved: 'TaVa_IsApproved',
    taVaTimeStamp: 'TaVa_TimeStamp',
    taVaComment: 'TaVa_Comment',
  };
  