import { RealEstateAnatomyDbView } from "../../db-model/views/real-estate-anatomy-db-view";
import { DefinedModelBase } from "../defined-model-base";

export class RealEstateAnatomyView extends DefinedModelBase {
    roLaTyId!: number;
    roLaTyCode!: string;
    roLaTyName!: string;
    roLaTyColor!: string;
    roLaTyIsNUA!: boolean;
    roLaTyParentId!: number;
    reEsLaViArea!: number;
    reEsLaViRoundedArea!: number;
    reEsLaViLayoutTypeTree!: string;

    constructor(dtoData: any) {
        super(dtoData, [RealEstateAnatomyDbView]);
    }
}