import { TaskFloorModelDbView } from './../../db-model/views/task-floor-model-db-view';
import { FloorModelView } from './floor-model-view';

export class TaskFloorModelView extends FloorModelView {
    taFloMoTaskId!: number;
    taFloMoFloorModelId!: number;

    constructor(dtoData: any) {
        super(dtoData, [TaskFloorModelDbView]);
    }
}