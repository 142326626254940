@if (model) {
    <p-splitter [panelSizes]="[25, 75]" [style]="{ height: '100%', width: '100%' }" styleClass="mb-5">
        <ng-template pTemplate>
            <div>
                <xc-inventory-tree [model]="model.tree"/>
            </div>
        </ng-template>
        <ng-template pTemplate>
            <div class="col flex align-items-center justify-content-center">
                <xc-inventory-tab-pager [model]="model.pager"/>
            </div>
        </ng-template>
    </p-splitter>
}
