import { WallFormVM } from './../../../../blueprint-viewer-side-panel/subitems/properties/model/wall-form-vm';
import { Container } from 'typedi';
import { WallUpdateGizmoVM } from './../../../subitems/gizmos/wall-update-gizmo/model/wall-update-gizmo-vm';
import { BlueprintWallsLayer } from './../../../../svg-entities/model/layers/blueprint-walls-layer';
import { SvgDOM } from './svg-dom';
import { FloorModelEnum } from "src/app/core/model/data-model/enums/floor-model-enum";
import { Point } from "src/app/core/model/geometry-model/point.model";
import { BpSvgWall } from "../../../../svg-entities/model/bp-svg-wall";
import { FloorBlueprint } from "../floor-blueprint";
import { EditableLayer } from "./editable-layer";
import { EntitiesSelectionSet } from "./entities-selection-set";
import { InteractionCommand } from "./interaction-command";
import { LayerInteraction } from "./layer-interaction";
import { SelectionInteraction } from "./selection-interaction";
import { BlueprintLayer } from '../../../../svg-entities/model/layers/blueprint-layer';
import { BlueprintCoreContoursLayer } from '../../../../svg-entities/model/layers/blueprint-core-contour-layer';
import { BlueprintInsideContoursLayer } from '../../../../svg-entities/model/layers/blueprint-inside-contour-layer';
import { BlueprintPartitioningFrameLayer } from '../../../../svg-entities/model/layers/blueprint-partitioning-frame-layer';
import { BpSvgDoor } from '../../../../svg-entities/model/bp-svg-door';
import { PolygonService } from 'src/app/core/model/geometry-model/polygon-service';
import { SvgPathService } from 'src/app/core/model/svg-model/svg-path-service';
import { Segment } from 'src/app/core/model/geometry-model/segment.model';
import { GripsDetection } from './grips-detection';
import { PartitioningDetection } from './partitioning-detection';
import { BpWallService } from 'src/app/core/services/backend-services/bp-wall-service';
import { logError } from 'src/app/core/services/logging-service';
import { toastWarn } from 'src/app/core/services/toast-service';

export class WallInteraction implements LayerInteraction {
    layerId = FloorModelEnum.Walls;
    layerName: string = "NA";
    selectionSet: EntitiesSelectionSet = new EntitiesSelectionSet();
    isEditable: boolean;

    floorBlueprint: FloorBlueprint;
    editableLayers: EditableLayer[] = [];
    currentCommand: InteractionCommand;

    selectionInteraction: SelectionInteraction | undefined;
    selectionCount: number = 0;
    
    updateGizmo: WallUpdateGizmoVM;
    propertiesForm: WallFormVM;

    planningTaskWallsLayer: BlueprintWallsLayer | null;
    planningTaskDoorsLayer: BlueprintLayer | null;
    coreContoursLayer: BlueprintCoreContoursLayer | null;
    insideContoursLayer: BlueprintInsideContoursLayer | null;
    partitioningFrameLayer: BlueprintPartitioningFrameLayer | null;
    floorCurrentTaskWallsLayer: BlueprintWallsLayer | null;

    magnetAttraction = 0.25;

    constructor(command: InteractionCommand, blueprint: FloorBlueprint, editableLayers: EditableLayer[]) {
        this.floorBlueprint = blueprint;
        this.editableLayers = editableLayers;
        this.currentCommand = command;

        this.isEditable = editableLayers.find(x=> x.layerId === this.layerId) != null;

        this.updateGizmo = new WallUpdateGizmoVM(this.isEditable);
        this.propertiesForm = new WallFormVM(this.floorBlueprint.layersController.doorsLayer(this.floorBlueprint.topMostTaskId()));

        const layer = editableLayers.find(x=> x.layerId === this.layerId);
        if (layer) {
            this.layerName = layer.layerName;
        }
        
        this.planningTaskWallsLayer = this.floorBlueprint.layersController.wallsLayer(this.floorBlueprint.topMostTaskId());
        this.planningTaskDoorsLayer = this.floorBlueprint.layersController.doorsLayer(this.floorBlueprint.topMostTaskId());
        this.coreContoursLayer = this.floorBlueprint.layersController.coreContoursLayer();
        this.insideContoursLayer = this.floorBlueprint.layersController.insideContoursLayer();
        this.partitioningFrameLayer = this.floorBlueprint.layersController.partitioningFrameLayer();
        this.floorCurrentTaskWallsLayer = this.floorBlueprint.layersController.wallsLayer(this.floorBlueprint.taskId);
        

        this.listenToWallGizmoEvents();
        //this.selectionSet.setChanged
    }

    // Méthode appelée lors de la sélection d'une cloison sur un plan d'étude
    async activate(walls: BpSvgWall[], toRemove: boolean): Promise<void> {
        //this.planningTaskWallsLayer = value.planningTaskWallsLayer;
        //this.planningTaskDoorsLayer = value.planningTaskDoorsLayer;
        //this.floorCurrentTaskWallsLayer = value.floorCurrentTaskWallsLayer;

        // Recherche les portes qui sont dans la cloison
        //const doors = value.planningTaskDoorsLayer.data.filter(x=> x.parentId === value.selectedWall.floorDataId);

        //this.updateGizmo.show(new WallUpdateGizmoArgs([value.selectedWall], doors));

        // Si le calque est éditable, c'est-à-dire si on est dans une étude
        // les seules cloisons sélectionnables sont celles qu'il est possible de sélectionner
        // Les cloisons périphériques ne sont pas sélectionnables

        this.selectionSet.addOrRemove(walls, !toRemove);

        const isEditable = this.isEditable && !this.selectionSet.hasHeterogenousDataState() && !this.selectionSet.isDataStateDeleted();

        // Affiche la trame de cloisonnement si le calque est éditable
        if (isEditable) {
            this.floorBlueprint.layersController.partitioningFrameLayer()?.show(true);
        }

        await this.propertiesForm.initialize(this.selectionSet, isEditable);
        this.updateGizmo.show({walls: this.selectionSet, isEditable: isEditable});
        this.setPivotWalls();
        
        this.selectionSet.setChanged = () => this.onSelectionSetChanged();
    }

    deactivate(): void {
        this.selectionSet.clear();

        // Masque la trame de cloisonnement
        this.floorBlueprint.layersController.partitioningFrameLayer()?.show(false);
    }

    abort(): void {
        // Masque la trame de cloisonnement
        this.floorBlueprint.layersController.partitioningFrameLayer()?.show(false);
            
        if (this.currentCommand.isWallInsertionCommand()) {
            this.endWallInsertCommand();
        }
    
        if (this.currentCommand.isWallEndpointFreeTranslationCommand()) {
            this.updateGizmo.abortEndpointTranslation();
        }

        //this.updateGizmo.hide();
    }

    selectionDeleted?: () => void;
    raiseSelectionDeleted(): void {
        if (this.selectionDeleted) {
            this.selectionDeleted();

            // Masque la trame de cloisonnement
            this.floorBlueprint.layersController.partitioningFrameLayer()?.show(false);
        } else {
            console.log("Attention, wall selectionDeleted n'est pas écouté !")
        }
    }

    onSelectionSetChanged(): void {
        if (this.propertiesForm.selectedWalls == null) {
            this.propertiesForm.initialize(this.selectionSet, this.isEditable);
        } else {
            this.propertiesForm.onSelectionChange();
        }

        const isEditable = this.isEditable && !this.selectionSet.hasHeterogenousDataState() && !this.selectionSet.isDataStateDeleted();

        if (this.updateGizmo.selectedWall == null) {
            this.updateGizmo.show({walls: this.selectionSet, isEditable: isEditable});
            this.setPivotWalls();
        } else {
            this.updateGizmo.onSelectionSetChanged();
        }
    }

    refreshThemeNeeded?: () => void;
    askForThemerefresh(): void {
        if (this.refreshThemeNeeded) {
            this.refreshThemeNeeded();
        } else {
            logError("WallInteraction.refreshThemeNedeed n'est pas écouté");
        }
    }
    
    beginInsert(wallStyleId: any): void {
        this.currentCommand.set(InteractionCommand.wallInsertionCommand);
        this.updateGizmo.showTransient(wallStyleId);
    }

    endInsert(): void {
        this.updateGizmo.hideTransient();
    }

    endWallInsertCommand(): void {
        this.endInsert();

        // Réactive le curseur de sélection sur les items de l'étude
        const topMostTaskId = this.floorBlueprint.topMostTaskId();
        this.floorBlueprint.layersController.updateCursor(this.editableLayers, topMostTaskId, true);

        // Réaffiche les surfaces
        this.floorBlueprint.layersController.roomsLayer(topMostTaskId)?.showRooms(true);

        // Masque la trame de cloisonnement
        this.floorBlueprint.layersController.partitioningFrameLayer()?.show(false);
    }

    async createWallFromTransient(): Promise<BpSvgWall | null> {
        if (this.updateGizmo.transientStyle == undefined || this.planningTaskWallsLayer == null) return null;
        // La fixation du point de départ entraîne la création de la cloison
        const s = Container.get(BpWallService);
        const returnValue = await s.createWall(
            this.floorBlueprint.topMostTaskId(),
            this.updateGizmo.transientSegment.startPoint,
            this.updateGizmo.transientSegment.endPoint,
            this.updateGizmo.transientStyle.flWaStId,
            this.updateGizmo.transientSegmentWidth,
            true);
        if (returnValue != null) {
            return this.planningTaskWallsLayer.insert(returnValue);
        }
        return null;
    }

    async deleteSelectedItems(): Promise<void> {
        // Si la cloison est au planning state 'Added' elle est supprimée du DOM
        // sinon on actualise son statut
        const ids = this.selectionSet.floorDataIds();
        const s = Container.get(BpWallService);
        const result = await s.deleteWall(ids);
        if (result != null) {
            (this.selectionSet.items as BpSvgWall[]).forEach(w => {
                const itemResult = result.find(x=> x.id === w.floorDataId);
                if (itemResult) {
                    if (itemResult.dataStateId == null) {
                        // Masque le gizmo de sélection (et vide la sélection)
                        this.updateGizmo.hide();
                        // La cloison est totalement supprimée du plan
                        this.planningTaskWallsLayer?.remove(w.floorDataId);
                    } else {
                        // La cloison est marquée avec le statut retourné par le backend
                        this.planningTaskWallsLayer?.updateDataState(w.floorDataId, itemResult.dataStateId);
                    }
                }
            });

            const doors = result.filter(x=> x.floorModelId === FloorModelEnum.Doors);
            doors.forEach(d => {
                if (d.dataStateId == null) {
                    // La porte est totalement supprimée du plan
                    this.planningTaskDoorsLayer?.remove(d.id);
                } else {
                    // La cloison est marquée avec le statut retourné par le backend
                    this.planningTaskDoorsLayer?.updateDataState(d.id, d.dataStateId);
                }
        });

            this.selectionSet.clear();
            this.raiseSelectionDeleted();
        }
    }

    moveTransient(hitPoint: Point): void {
        this.updateGizmo.updateTransient(hitPoint, 0.15);
    }

    setPivotWalls(): void {
        // Recherche les cloisons connectées aux extrémités de la cloison
        if (this.selectionSet.items.length === 1) {
            const wall = this.selectionSet.items[0] as BpSvgWall;
            const s = wall.segment();
            this.updateGizmo.setPivotWallsRef(this.getPivotWalls(wall, s.startPoint), this.getPivotWalls(wall, s.endPoint));
        }
    }

    getPivotWalls(selectedWall: BpSvgWall, endPoint: Point): BpSvgWall[] {
        const s = selectedWall.segment();
        return this.planningTaskWallsLayer?.data.filter(x=> {
            if (x.floorDataId === selectedWall.floorDataId) {
                return false;
            }

            const xSegment = (x as BpSvgWall).segment();

            const isAnEndpoint = xSegment.isAnEndPoint(endPoint.rounded());
            if (isAnEndpoint) {
                return true;
            }

            const i = xSegment.getIntersect(s.stretched(0.01));
            if (!i) {
                return false;
            }
            return i.roundedEquals(endPoint);
        }) as BpSvgWall[];
}

    listenToWallGizmoEvents(): void {
        // Abonnement au MouseDown de la poignée de translation lbre d'une extrémité
        this.updateGizmo.onEndHandled = (clientPoint: Point) => {
            // Le user a cliqué sur la poignée de translation libre
            this.initializeCommand(InteractionCommand.wallEndpointFreeTranslationCommand, clientPoint);
        }

        // Abonnement au MouseDown de la poignée de translation contrainte d'une extrémité
        this.updateGizmo.onEndConstraintHandled = (clientPoint: Point) => {
            // Le user a cliqué sur la poignée de translation contrainte
            this.initializeCommand(InteractionCommand.wallEndpointConstraintTranslationCommand, clientPoint);
        }

        // Abonnement au MouseDown de la poignée de translation libre de la cloison
        this.updateGizmo.wallMouseDown = (clientPoint: Point) => {
            // Le user a cliqué sur la poignée de translation libre
            this.initializeCommand(InteractionCommand.wallFreeTranslationCommand, clientPoint);
        }

        // Abonnement au MouseDown de la poignée de translation latérale contrainte de la cloison
        this.updateGizmo.sideConstraintTranslationHandleMouseDown = (clientPoint: Point) => {
            // Le user a cliqué sur la poignée de translation latérale contrainte
            this.initializeCommand(InteractionCommand.wallSideConstraintTranslationCommand, clientPoint);
        }
    }

    initializeCommand(commandName: string, clientPoint: Point): void {
        this.currentCommand.set(commandName);
        this.currentCommand.initialClientMousePosition = clientPoint;
        const svgMousePosition = SvgDOM.getPointPosition(clientPoint.x, clientPoint.y);
        this.currentCommand.initialSvgMousePosition = svgMousePosition;
        this.updateGizmo.doorsLayer = this.floorBlueprint.layersController.doorsLayer(this.floorBlueprint.topMostTaskId());
    }

    // Cette méthode est appelée par le callback du timer de détection d'accrochage
    // déclenché lors de la pause de mouvement sur SvgComponent.MouseMove
    // Cette méthode est donc équivalente à une méthode statique et n'a pas accès à this.
    detectWallTargetGrips(clientHitPoint: Point, svgHitPoint: Point, wallInteraction: WallInteraction): void {
        if(!clientHitPoint || wallInteraction.updateGizmo.selectedWall == null) {
            return;
        }

        let result = SvgDOM.getDOMWallIdFromClientPoint(clientHitPoint.x, clientHitPoint.y, [wallInteraction.updateGizmo.selectedWall.floorDataId]);

        if(!result){
            return;
        }

        if (result != null)
        {
            wallInteraction.setWallTargetGrips(result, svgHitPoint);
            wallInteraction.currentCommand.magnetAttraction = 0.5;
        }
    }

    setWallTargetGrips(floorDataId: number, hitPoint: Point): void {
        if (this.planningTaskWallsLayer == null) return;
        const walls = this.planningTaskWallsLayer.data as BpSvgWall[];
        const wall = walls.find(x => x.floorDataId === floorDataId);

        if(!wall) {
            return;
        }

        const grips: Point[] = [wall.startPoint, wall.endPoint];

        const nearestGrip = Point.getNearest(grips, hitPoint);
        this.updateGizmo.targetGrips.loadFromPoints(grips);
        this.updateGizmo.targetGrips.select(nearestGrip);
    }

    async save(): Promise<void> {
        const wall = this.selectionSet.single<BpSvgWall>();
        let finalPosition: Point;
        if (this.updateGizmo.selectedEndPointIsStartPoint) {
            finalPosition = wall.startPoint;
        } else {
            finalPosition = wall.endPoint;
        }

        await this.updateGizmo.saveWallStretch(finalPosition);
    }

    wallIsInsideTaskZone(wallSegment: Segment | undefined): boolean {
        if (!wallSegment) return false;

        // Récupère les surfaces sources de celles de l'étude
        // à partir desquelles on va pouvoir déterminer si une cloison est bien insérée à l'intérieur de la zome de l'étude
        const taskRooms = this.floorBlueprint.layersController.rooms(this.floorBlueprint.topMostTaskId());
        const taskRoomsSourcesIds = taskRooms.map(x=> x.sourceId);
        const sourceRooms = this.floorBlueprint.layersController.rooms(this.floorBlueprint.taskId).filter(x=> taskRoomsSourcesIds.includes(x.floorDataId));
        const sourcePolygons = sourceRooms.map(x=> SvgPathService.getPolygon(x.d!, true));
        const sp = wallSegment.startPoint.rounded(3);
        const ep = wallSegment.endPoint.rounded(3);

        const endsPositions = PolygonService.endsPositions(sourcePolygons, [sp, ep]);
        const endsNotInsideAContour = endsPositions.filter(x=> !x.isInside);
        return endsNotInsideAContour.length === 0;
    }

    async mouseDown(e: MouseEvent, hitPoint: Point): Promise<boolean> {
        if (this.currentCommand.isWallInsertionCommand()) {
            let targetPoint = hitPoint;
            if (this.updateGizmo.targetGrips.items.length > 0) {
            // S'il y a des grips de positionnement affichés, déplace l'extrémité de la cloison sur le grip le plus proche
            const nearestGrip = this.updateGizmo.targetGrips.getSelected();
            if (nearestGrip) {
                targetPoint = nearestGrip.point;
            }
            this.updateGizmo.targetGrips.clear();
            }

            // Récupère les surfaces sources de celles de l'étude
            // à partir desquelles on va pouvoir déterminer si une cloison est bien insérée à l'intérieur de la zome de l'étude
            // const taskRooms = this.floorBlueprint.layersController.rooms(this.floorBlueprint.topMostTaskId());
            // const taskRoomsSourcesIds = taskRooms.map(x=> x.sourceId);
            // const sourceRooms = this.floorBlueprint.layersController.rooms(this.floorBlueprint.taskId).filter(x=> taskRoomsSourcesIds.includes(x.floorDataId));
            // const sourcePolygons = sourceRooms.map(x=> SvgPathService.getPolygon(x.d!, true));
            const isInsideTaskZone = this.wallIsInsideTaskZone(this.updateGizmo.transientSegment);
            // const sp = this.updateGizmo.transientSegment.startPoint.rounded(3);
            // const ep = this.updateGizmo.transientSegment.endPoint.rounded(3);
            // sourcePolygons.forEach(p => {
            //     if (p.containsAll([sp, ep])) {
            //         isInsideTaskZone = true;
            //     }
            // });

            // const endsPositions = PolygonService.endsPositions(sourcePolygons, [sp, ep]);
            // const endsNotInsideAContour = endsPositions.filter(x=> !x.isInside);
            // isInsideTaskZone = endsNotInsideAContour.length === 0;

            // Les deux extrémités de la cloisons doivent se trouver dans la zone de l'étude
            // ou une seulement sur la périphérie
            // ou les deux sur la limite entre deux contours de la zone
            // Le segment de cloison ne doit pas couper la périphérie de la zone
            // const endsPositions = PolygonService.endsPositions(sourcePolygons, [sp, ep]);
            // const endsNotInsideAContour = endsPositions.filter(x=> !x.isInside);
            // const bothEndsAreInsideAContour = endsNotInsideAContour.length === 0;
            // const oneEndIsInsideAContour = endsNotInsideAContour.length === 1;
            // if (!bothEndsAreInsideAContour) {
            //     if (oneEndIsInsideAContour) {
            //         // Une seule des deux extrémités de la cloison est à l'intérieur d'un contour
            //     }
            // }

            // Attention, le fait de vérifier que les points du segment de cloison sont bien à l'intérieur des polygones de la zone d'étude ne suffit pas,
            // la cloison pouvant être à la jonction de deux surfaces existantes
            // On veut pouvoir accepter un placement entre deux surfaces à l'intérieur de la zone d'étude
            // mais pas en périphérie de celle-ci
            // On a donc besoin d'identifier les segments de contour superposés parmi les contours de surfaces de la zone d'étude
            // C'est seulement sur ceux-ci qu'on pourra placer une cloison
            // if (!isInsideTaskZone) {
            //     // Récupération des segments superposés parmi les contours de la zone d'étude
            //     const overlapedSegments = PolygonService.separateOverlapedSegments(sourcePolygons);
            //     if (SegmentService.containsPoint(overlapedSegments, ep) && SegmentService.containsPoint(overlapedSegments, sp)) {
            //         isInsideTaskZone = true;
            //     }
            // }

            // On veut pouvoir enfin accepter le placement depuis la périphérie vers l'intérieur de la zone d'étude
            // on aura donc un point quelque part sur un segment de contour et un autre point à l'intérieur d'un contour
    
            // Fin de la commande
            // le mouseUp n'est pas utilisé
            let newWall: BpSvgWall | null = null;
            if (isInsideTaskZone) {
                newWall = await this.createWallFromTransient();
            } else {
                toastWarn("Il n'est pas possible de placer une cloison à l'extérieur de la zone d'étude");
            }
    
            // Termine la commande
            this.currentCommand.clear();
            this.endWallInsertCommand();
            document.documentElement.style.cursor = "default";
    
            // Selectionne la nouvelle cloison pour continuer immédiatement l'implantation
            if (newWall != null) {
                this.selectionSet.addOrRemove([newWall], true);
                //this.selectItem(newWall);
                this.onSelectionSetChanged();
            }
            
            // Indique au caller (userInteraction) de sortir immédiatement de son propre mouseDown
            return true;
        }
        return false;
    }

    timer: number | undefined;
    async mouseMove(e: MouseEvent, hitPoint: Point): Promise<void> {
      
        if (this.currentCommand.isWallInsertionCommand()) {
            // Arrête le timer
            clearTimeout(this.timer);
        
            // Le magnetAttraction sert à empêcher tout mouvement tant que la souris ne s'est pas déplacée d'une distance minimale
            // lorsqu'un attracteur est affiché
            // Cela permet à l'utilisateur de bien voir l'attracteur avant de décider ou non de relâcher la souris
            // sans que le moindre minuscule mouvement ne le fasse disparaître ou changer de place
            if (this.currentCommand.tempSvgMousePosition != null &&
                hitPoint.distanceTo(this.currentCommand.tempSvgMousePosition) < this.currentCommand.magnetAttraction) {
                return;
            }
        
            // La souris est sortie du périmètre d'attraction, on masque l'attracteur
            if (this.currentCommand.magnetAttraction > 0) {
                this.updateGizmo.targetGrips.clear();
                this.currentCommand.magnetAttraction = 0;
            }
        
            // Actualise la position courante de la souris pour le contrôle de l'attraction lors du prochain MouseMove
            this.currentCommand.tempSvgMousePosition = hitPoint;
        
            this.moveTransient(hitPoint);
        
            // Redémarre le timer
            this.timer = window.setTimeout(GripsDetection.detectWallInsertTargetGrips, 150, hitPoint, this);
        
            return;
        }
      
        if (this.currentCommand.isWallEndpointFreeTranslationCommand()) {
            // Arrête le timer
            clearTimeout(this.timer);
      
            // Le magnetAttraction sert à empêcher tout mouvement tant que la souris ne s'est pas déplacée d'une distance minimale
            // lorsqu'un attracteur est affiché
            // Cela permet à l'utilisateur de bien voir l'attracteur avant de décider ou non de relâcher la souris
            // sans que le moindre minuscule mouvement ne le fasse disparaître ou changer de place
            if (this.currentCommand.tempSvgMousePosition != null &&
              hitPoint.distanceTo(this.currentCommand.tempSvgMousePosition) < this.currentCommand.magnetAttraction) {
              return;
            }
      
            // La souris est sortie du périmètre d'attraction, on masque l'attracteur
            if (this.currentCommand.magnetAttraction > 0) {
              this.updateGizmo.targetGrips.clear();
              this.currentCommand.magnetAttraction = 0;
            }
      
            // Actualise la position courante de la souris pour le contrôle de l'attraction lors du prochain MouseMove
            this.currentCommand.tempSvgMousePosition = hitPoint;
      
            //await this.freeTranslateEndpoint(hitPoint);
            await this.updateGizmo.freeTranslateEndpoint(hitPoint, false, false);

      
            // Redémarre le timer
            this.timer = window.setTimeout(this.detectWallTargetGrips, 150, new Point(e.clientX, e.clientY), hitPoint, this);
      
            return;
        }
      
        if (this.currentCommand.isWallEndpointConstraintTranslationCommand()) {
            if (this.currentCommand.initialSvgMousePosition === undefined) return;
            // Arrête le timer
            clearTimeout(this.timer);
      
            // Le magnetAttraction sert à empêcher tout mouvement tant que la souris ne s'est pas déplacée d'une distance minimale
            // lorsqu'un attracteur est affiché
            // Cela permet à l'utilisateur de bien voir l'attracteur avant de décider ou non de relâcher la souris
            // sans que le moindre minuscule mouvement ne le fasse disparaître ou changer de place
            if (this.currentCommand.tempSvgMousePosition != null &&
              hitPoint.distanceTo(this.currentCommand.tempSvgMousePosition) < this.currentCommand.magnetAttraction) {
              return;
            }
      
            // La souris est sortie du périmètre d'attraction, on masque l'attracteur
            if (this.currentCommand.magnetAttraction > 0) {
              this.updateGizmo.targetGrips.clear();
              this.currentCommand.magnetAttraction = 0;
            }
      
            // Actualise la position courante de la souris pour le contrôle de l'attraction lors du prochain MouseMove
            this.currentCommand.tempSvgMousePosition = hitPoint;
      
            await this.updateGizmo.constraintTranslateEndpoint(this.currentCommand.initialSvgMousePosition, hitPoint, false);
      
            // Redemarre le timer
            this.timer = window.setTimeout(GripsDetection.detectWallExtentsGrip, 150, new Point(e.clientX, e.clientY), hitPoint, this);
      
            return;
        }
      
        if (this.currentCommand.isWallFreeTranslationCommand()) {
            if (this.currentCommand.initialSvgMousePosition === undefined) return;
            // Arrête le timer
            clearTimeout(this.timer);
      
            // Le magnetAttraction sert à empêcher tout mouvement tant que la souris ne s'est pas déplacée d'une distance minimale
            // lorsqu'un attracteur est affiché
            // Cela permet à l'utilisateur de bien voir l'attracteur avant de décider ou non de relâcher la souris
            // sans que le moindre minuscule mouvement ne le fasse disparaître ou changer de place
            if (this.currentCommand.tempSvgMousePosition != null &&
                hitPoint.distanceTo(this.currentCommand.tempSvgMousePosition) < this.currentCommand.magnetAttraction) {
                return;
            }
      
            // La souris est sortie du périmètre d'attraction, on masque l'attracteur
            if (this.currentCommand.magnetAttraction > 0) {
                this.updateGizmo.targetGrips.clear();
                this.currentCommand.magnetAttraction = 0;
            }
        
            // Actualise la position courante de la souris pour le contrôle de l'attraction lors du prochain MouseMove
            this.currentCommand.tempSvgMousePosition = hitPoint;
          
            this.updateGizmo.translateWall(this.currentCommand.initialSvgMousePosition, hitPoint);
      
            // Redemarre le timer
            this.timer = window.setTimeout(PartitioningDetection.detectPartitioningFrame, 150, hitPoint, this);

            return;
        }
      
        if (this.currentCommand.isWallSideConstraintTranslationCommand()) {
            if (this.currentCommand.initialSvgMousePosition === undefined) return;
            // Arrête le timer
            clearTimeout(this.timer);
      
            // Le magnetAttraction sert à empêcher tout mouvement tant que la souris ne s'est pas déplacée d'une distance minimale
            // lorsqu'un attracteur est affiché
            // Cela permet à l'utilisateur de bien voir l'attracteur avant de décider ou non de relâcher la souris
            // sans que le moindre minuscule mouvement ne le fasse disparaître ou changer de place
            if (this.currentCommand.tempSvgMousePosition != null &&
                hitPoint.distanceTo(this.currentCommand.tempSvgMousePosition) < this.currentCommand.magnetAttraction) {
                return;
            }
      
            // La souris est sortie du périmètre d'attraction, on masque l'attracteur
            if (this.currentCommand.magnetAttraction > 0) {
                this.updateGizmo.targetGrips.clear();
                this.currentCommand.magnetAttraction = 0;
            }
        
            // Actualise la position courante de la souris pour le contrôle de l'attraction lors du prochain MouseMove
            this.currentCommand.tempSvgMousePosition = hitPoint;
          
            this.updateGizmo.sideTranslateWall(this.currentCommand.initialSvgMousePosition, hitPoint);
      
            // Redemarre le timer
            this.timer = window.setTimeout(PartitioningDetection.detectPartitioningFrame, 150, hitPoint, this);

            return;
        }
    }

    async mouseUp(e: MouseEvent): Promise<void> {
        if (this.currentCommand.isWallEndpointFreeTranslationCommand()) {
            // Fin de la commande de translation libre d'une extrémité de cloison
            this.currentCommand.clear();
            
            if (this.updateGizmo.targetGrips.items.length > 0) {
                // S'il y a des grips de positionnement affichés, déplace l'extrémité de la cloison sur le grip le plus proche
                const nearestGrip = this.updateGizmo.targetGrips.getSelected();
                if (nearestGrip) {
                    await this.updateGizmo.freeTranslateEndpoint(nearestGrip.point, true, true);
                }
            }

            if (this.updateGizmo.selectedWall!.segment().length() < 0.1) {
                toastWarn("La cloison ne peut pas avoir moins de 10 cm de longueur");
                this.updateGizmo.abortEndpointTranslation();
                return;
            }

            const isInsideTaskZone = this.wallIsInsideTaskZone(this.updateGizmo.selectedWall?.segment());
            if (isInsideTaskZone) {
                await this.save();
        
                this.updateGizmo.selectedEndPoint = undefined;
                this.updateGizmo.targetGrips.clear();
                //this.userInteraction.wallInteraction.wallUpdateGizmo.lengthLabel.hide();
                this.updateGizmo.hidePivotPath();
                this.setPivotWalls();
        
                this.updateGizmo.showDoors(true);
            } else {
                toastWarn("Il n'est pas possible de déplacer l'extrémité d'une cloison à l'extérieur de la zone d'étude");
                this.updateGizmo.abortEndpointTranslation();
            }

            return;
        }
  
        if (this.currentCommand.isWallEndpointConstraintTranslationCommand()) {
            // Fin de la commande de translation contrainte d'une extrémité de cloison
            this.currentCommand.clear();
            
            if (this.updateGizmo.targetGrips.items.length > 0) {
                // S'il y a des grips de positionnement affichés, déplace l'extrémité de la cloison sur le grip le plus proche
                const nearestGrip = this.updateGizmo.targetGrips.getSelected();
                if (nearestGrip) {
                    await this.updateGizmo.freeTranslateEndpoint(nearestGrip.point, true, true);
                }
            }

            if (this.updateGizmo.selectedWall!.segment().length() < 0.1) {
                toastWarn("La cloison ne peut pas avoir moins de 10 cm de longueur");
                this.updateGizmo.abortEndpointTranslation();
                return;
            }

            const isInsideTaskZone = this.wallIsInsideTaskZone(this.updateGizmo.selectedWall?.segment());
            if (isInsideTaskZone) {
                await this.save();
        
                this.updateGizmo.selectedEndPoint = undefined;
                //this.userInteraction.wallInteraction.wallUpdateGizmo.lengthLabel.hide();
                this.updateGizmo.targetGrips.clear();
                this.updateGizmo.hidePivotPath();
                this.setPivotWalls();
        
                this.updateGizmo.showDoors(true);
            } else {
                toastWarn("Il n'est pas possible de déplacer l'extrémité d'une cloison à l'extérieur de la zone d'étude");
                this.updateGizmo.abortEndpointTranslation();
            }
            
            return;
        }
  
        if (this.currentCommand.isWallFreeTranslationCommand() || this.currentCommand.isWallSideConstraintTranslationCommand()) {
            // Fin de la commande de translation libre ou constrainte d'une cloison
            this.currentCommand.clear();
            
            const isInsideTaskZone = this.wallIsInsideTaskZone(this.updateGizmo.selectedWall?.segment());
            if (isInsideTaskZone) {
                if (this.updateGizmo.initialSegment === undefined) return;
                var wall = this.selectionSet.single<BpSvgWall>();
                const delta = new Point(wall.startPoint.x - this.updateGizmo.initialSegment.startPoint.x, wall.startPoint.y - this.updateGizmo.initialSegment.startPoint.y).vector();
                const s = Container.get(BpWallService);
                const saveResult = await s.translateWall(wall.floorDataId, delta);
                if (saveResult.length > 0) {
                    const wallResult = saveResult.find(x=> x.floorModelId === FloorModelEnum.Walls);
                    if (wallResult !== undefined) {
                        wall.dataStateId = wallResult.dataStateId;
        
                        const doorsResult = saveResult.filter(x=> x.floorModelId === FloorModelEnum.Doors) as BpSvgDoor[];
                        const doors = this.floorBlueprint.layersController.doorsLayer(this.floorBlueprint.topMostTaskId())?.data.filter(x=> x.parentId === wall.floorDataId) as BpSvgDoor[];
                        doorsResult.forEach(d => {
                            const door = doors.find(x=> x.floorDataId === d.floorDataId);
                            if (door !== undefined) {
                                door.transform = d.transform;                    
                                door.dataStateId = d.dataStateId;
                            }
                        });
                    }
                } 
                else {
                    this.abort();
                }
        
                this.updateGizmo.showDoors(true);
                this.setPivotWalls();
            } else {
                toastWarn("Il n'est pas possible de déplacer une cloison à l'extérieur de la zone d'étude");
                this.updateGizmo.abortEndpointTranslation();
            }

            return;
        }
    }

    async keyDown(e: KeyboardEvent): Promise<void> {
    }
}
