import { FloorDataStateEnum } from 'src/app/core/model/data-model/enums/floor-data-state-enum';
import { HtmlEcsyConstants } from 'src/app/core/model/html-model/html-ecsy-constants.model';
import { SvgEntity } from 'src/app/core/model/svg-model/svg-entity.model';
import { UpdatedFloorDataDTO } from '../services/dto/updated-floor-data-dto';
import { BlueprintSvgEntityTypeEnum } from '../svg-entities/model/blueprint-svg-entity-type-enum';

export class BpSvgEntity extends SvgEntity {
    floorDataId!: number;
    taskId!: number;
    parentId!: number | null;
    sourceId!: number | null;
    dataStateId!: number;
    floorModelId!: number;
    planningStateId!: number;
    xcFunc!: string;
    blueprintEntityType: BlueprintSvgEntityTypeEnum;

    constructor(dtoData: any, blueprintEntityType: BlueprintSvgEntityTypeEnum = BlueprintSvgEntityTypeEnum.none) {
        super(dtoData);
        this.blueprintEntityType = blueprintEntityType;
        this.floorDataId = SvgEntity.getNumber(dtoData, "id", 0, false); 
        this.taskId = SvgEntity.getNumber(dtoData, "taskId", 0, false); 
        this.parentId = SvgEntity.getNullableNumber(dtoData, "parentId", null, false);
        this.sourceId = SvgEntity.getNullableNumber(dtoData, "sourceId", null, false);
        this.dataStateId = SvgEntity.getNumber(dtoData, "dataStateId", 0, false);
        this.floorModelId = SvgEntity.getNumber(dtoData, "floorModelId", 0, false);
        this.planningStateId = SvgEntity.getNumber(dtoData, "planningStateId", 0, false);
        this.xcFunc = SvgEntity.getValue(dtoData, HtmlEcsyConstants.xcFunc, undefined, false);
    }

    isLabel(): boolean {
        return this.blueprintEntityType === BlueprintSvgEntityTypeEnum.roomLabel ||
        this.blueprintEntityType === BlueprintSvgEntityTypeEnum.workplaceLabel ||
        this.blueprintEntityType === BlueprintSvgEntityTypeEnum.peopleLabel ||
        this.blueprintEntityType === BlueprintSvgEntityTypeEnum.measurementLabel;
    }
    
    /**
     * Actualise les états à partir du retour du backend et masque ou affiche l'entité en fonction de son dataState
     * @param updates DTO de retour du backend contenant les identifiants des états
     */
    updateState(updates: UpdatedFloorDataDTO | undefined): void {
        if (updates === undefined) return;
        this.dataStateId = updates.dataStateId;
        this.planningStateId = updates.planningStateId;

        this.show(this.dataStateId !== FloorDataStateEnum.Deleted); 
    }
}