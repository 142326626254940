import { Point } from "../geometry-model/point.model";
import { Segment } from "../geometry-model/segment.model";
import { SvgLine } from "./svg-line.model";

export class SvgLineService {
    static geometry(l: SvgLine): Segment {
        return new Segment(SvgLineService.startPoint(l), SvgLineService.endPoint(l));
    }

    static startPoint(l: SvgLine): Point {
        return new Point(l.x1, l.y1);
    }

    static endPoint(l: SvgLine): Point {
        return new Point(l.x2, l.y2);
    }

    static otherEnd(l: SvgLine, p: Point): Point {
        const s = SvgLineService.geometry(l);
        return s.otherEnd(p);
    }

}