import { ITabPageContent } from "src/app/components-lib/tab-page-container/model/i-tab-page-content";
import { readableUUID } from "src/app/core/events/event-listener-uuid";
import { AppMenuRouteEnum } from "src/app/core/model/data-model/enums/app-menu-route-enum";
import { Floor } from "src/app/core/model/data-model/tables/floor";
import { PageModel } from "src/app/ui/main/model/page-model";
import { RealEstateChartTypeEnum } from "../charts/shared-model/real-estate-chart-type-enum";
import { BlueprintViewerVM } from "../floor-blueprint/container/model/blueprint-viewer-vml";
import { TabPageContentTypeEnum } from "../tab-page-content-type-enum";
import { FloorDashboardVM } from "./floor-dashboard-vm";
import { FloorsToolbarVM } from "./floors-toolbar-vm";
import { FloorsEventsEnum } from "./floors-events-enum";
import { MainEventsEnum } from "src/app/ui/main/model/main-events-enum";

export class FloorsVM extends PageModel {
    static blueprintRequestedEvent = "blueprintRequested";

    toolbar: FloorsToolbarVM | undefined;

    floors: Floor[] = [];
    selectedFloor: Floor | null = null;

    blueprintEditor: BlueprintViewerVM | undefined;
    dashboard: FloorDashboardVM  | undefined;

    selectedOption: {id: RealEstateChartTypeEnum, label: string} | undefined;

    optionTypesEnum = RealEstateChartTypeEnum;
    contentTypeEnum = TabPageContentTypeEnum;

    selectedChartType: {id: string, icon: string} | null = null;

    currentView: ITabPageContent | undefined;

    private constructor() {
        super(AppMenuRouteEnum.layout_realestate_floors, 0, readableUUID(FloorsVM.name));

        this.addEventListener(FloorsEventsEnum.selectedFloorChange, async (floor: Floor) => {
            this.selectedFloor = floor;
            await this.loadView();
        });

        this.addEventListener(FloorsEventsEnum.selectedChartChange, async (chart: {id: string, icon: string} | null) => {
            this.selectedChartType = chart;
            await this.loadView();
        });

        this.addEventListener(FloorsEventsEnum.selectedOptionChange, async (option: {id: RealEstateChartTypeEnum, label: string}) => {
            this.selectedOption = option;
            await this.loadView();
        });
    }

    static async newAsync(floorId?: number): Promise<FloorsVM> {
        const tmp = new FloorsVM();
        tmp.toolbar = await FloorsToolbarVM.newAsync(floorId);
        tmp.emitEventAsync(MainEventsEnum.nestedToolbarAvailable, tmp.toolbar);
        return tmp;
    }

    async loadView(): Promise<void> {
        if (!this.selectedFloor) return;
        if (!this.selectedOption) return;
        if (this.selectedChartType?.id === "blueprint") {
            this.blueprintEditor = new BlueprintViewerVM()
            await this.blueprintEditor.setContent(this.selectedFloor.flId);
        } else {
            this.dashboard = await FloorDashboardVM.newAsync(this.selectedFloor.flId);
            await this.dashboard.loadContent(this.selectedOption.id);
        }
    }
}