import { EquipmentItemTable } from "../tables/equipment-item-table";
import { EquipmentStorageTable } from "../tables/equipment-storage-table";
import { FloorCatalogTable } from "../tables/floor-catalog-table";
import { ViewsNames } from "../views-names-enum";

export const EquipmentStorageDbView = {
    ...[EquipmentStorageTable, EquipmentItemTable],
    databaseTableName: ViewsNames.EquipmentStorageView,
    flCgId: FloorCatalogTable.flCgId,
}
