import { Polygon } from "src/app/core/model/geometry-model/polygon.model";
import { Segment } from "src/app/core/model/geometry-model/segment.model";
import { HtmlConstants } from "src/app/core/model/html-model/html-constants.model";
import { BlueprintLayer } from "./blueprint-layer";
import { FloorDataStateEnum } from "src/app/core/model/data-model/enums/floor-data-state-enum";
import { FloorWallStyleEnum } from "src/app/core/model/data-model/enums/floor-wall-style-enum";
import { BpSvgWall } from "../bp-svg-wall";
import { Point } from "src/app/core/model/geometry-model/point.model";

export class BlueprintWallsLayer extends BlueprintLayer<BpSvgWall> {
    constructor(dtoData: any, taskId: number) {
        super(dtoData, taskId);
    }

    getActiveWalls(withVirtual: boolean): BpSvgWall[] {
        let result: BpSvgWall[] = [];

        (this.typedData()).forEach(w => {
            if (w.dataStateId !== FloorDataStateEnum.Deleted) {
                if (!withVirtual && w.wallStyle === FloorWallStyleEnum.Virtual) {
                    return;
                }
                result.push(w);
            }
        });

        return result;
    }

    getTaskZoneBoundWalls(): BpSvgWall[] {
        return this.typedData().filter(x=> x.isTaskZoneBound);
    }

    // getTaskBoundContour(): Polygon | null {
    //     const bounds = this.getTaskZoneBoundWalls();
    //     if (bounds.length < 3) return null;

    //     const tol = 0.01;
    //     const vertices: Point[] = [];
    //     const first = bounds[0];
    //     vertices.push(first.startPoint);
    //     vertices.push(first.endPoint);
    //     let lastPoint: Point = first.endPoint;
    //     for (let i = 1; i < bounds.length; i++) {
    //         const w = bounds[i];
    //         // Recherche le segment qui a une extrémité jointe
    //         if (lastPoint.equals(w.startPoint, tol)) {
    //             vertices.push(w.startPoint);
    //             vertices.push(w.endPoint);
    //             lastPoint = w.endPoint;
    //         }
    //         if (lastPoint.equals(w.endPoint, tol)) {
    //             vertices.push(w.endPoint);
    //             vertices.push(w.startPoint);
    //             lastPoint = w.startPoint;
    //         }
    //     }

    //     return new Polygon(vertices);
    // }

    getActiveWallsSegments(withVirtual: boolean, excluded: number[]): Segment[] {
        let result: Segment[] = [];

        (this.typedData()).forEach(w => {
            if (w.dataStateId !== FloorDataStateEnum.Deleted && !excluded.includes(w.floorDataId)) {
                if (!withVirtual && w.wallStyle === FloorWallStyleEnum.Virtual) {
                    return;
                }
                result.push(w.segment());
            }
        });

        return result;
    }

    getFromSegment(s: Segment): BpSvgWall | null {
        for (const w of this.typedData()) {
            if (w.startPoint.equals(s.startPoint) && w.endPoint.equals(s.endPoint)) {
                return w;
            }
        }
        return null;
    }

    override insert(w: BpSvgWall): BpSvgWall {
        //const groupEntities = getEntities(g.entities);
        // Un groupe cloison ne contient qu'une entité ligne
        //const newWall = new BpSvgWall(groupEntities[0], g);
        this.data.push(w)
        return w;
    }

    setColor(color: string): void {
        (this.typedData()).forEach(w => {
            w.setColor(color);
        });
    }

    resetColor(): void {
        (this.typedData()).forEach(w => {
            w.resetColor();
        });
    }

    /**
     * Override la méthode de base pour permettre le traitement des cloison périphériques de la zone d'étude
     * @param selectable 
     */
    override setItemsSelectable(selectable: boolean): void {
        (this.typedData()).forEach(e => {
            if (selectable && !e.isTaskZoneBound) {
                e.cursor = HtmlConstants.styleCursorPointer;
            } else {
                e.cursor = HtmlConstants.styleCursorInherit;
            }
        });
    }

    select(polygon: Polygon): BpSvgWall[] {
        const result: BpSvgWall[] = [];

        (this.typedData()).forEach(w => {
            // Les cloisons en périphérie de la zone d'étude ne sont pas sélectionnables car non modifiables
            if (w.display === HtmlConstants.styleDisplayBlock && !w.isTaskZoneBound) {
                if (polygon.contains(w.startPoint) && polygon.contains(w.endPoint)) {
                    result.push(w);
                }
            }
        });

        return result;
    }
}
