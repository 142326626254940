import { BuildingDbView } from './../../db-model/views/building-db-view';
import { Building } from './../tables/building';

export class BuildingView extends Building {
    buViLatitude!: number;
    buViLongitude!: number;
    buViAltitude!: number;
    buViGLA!: number;
    buViNUA!: number;
    buViFloorsCount!: number;
    buViMailingAddress!: string | null;
    buViDeliveryAddress!: string | null;
    buViParkingAddress!: string | null;
    buViLobbyAddress!: string | null;

    constructor(dtoData: any) {
        super(dtoData, [BuildingDbView]);
    }
}