import { BpSvgGroupComponent } from './../../../../../../svg-entities/view/bp-svg-group/bp-svg-group.component';
import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DoorUpdateGizmoVM } from '../../model/door-update-gizmo-vm';
import { SelectionRotationLabelComponent } from '../../../../selection/view/selection-rotation-label/selection-rotation-label.component';
import { SvgSegmentDebugComponent } from 'src/app/components-lib/svg/debug/view/svg-segment-debug/svg-segment-debug.component';
import { SvgPointDebugComponent } from 'src/app/components-lib/svg/debug/svg-point-debug/svg-point-debug.component';

@Component({
  selector: 'g[xc-door-update-gizmo]',
  standalone: true,
  imports: [CommonModule, BpSvgGroupComponent, SelectionRotationLabelComponent, SvgSegmentDebugComponent, SvgPointDebugComponent],
  templateUrl: './door-update-gizmo.component.svg',
  styleUrls: ['./door-update-gizmo.component.scss']
})
export class DoorUpdateGizmoComponent {
  @Input() model: DoorUpdateGizmoVM | undefined;
}
