import { TablesNamesEnum } from './../tables-names-enum';
export const EquipmentCategoryTable = {
    databaseTableName: TablesNamesEnum.EquipmentCategory,
    eqCaId: 'EqCa_Id',
    eqCaName: 'EqCa_Name',
    eqCaDisplayNameId: 'EqCa_DisplayNameId',
    eqCaDepth: 'EqCa_Depth',
    eqCaParentId: 'EqCa_ParentId',
    eqCaIsActive: 'EqCa_IsActive',
    eqCaScope: 'EqCa_Scope'
}