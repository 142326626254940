import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RolesAdminVM } from '../../model/roles-admin-vm';
import { MVVMEventDestroyLifeCycle } from 'src/app/components-lib/shared-model/mvvm-events-destroy-lifecycle';
import { TabViewModule } from 'primeng/tabview';
import { GrantsAdminComponent } from '../../grants/view/grants-admin/grants-admin.component';
import { DynTableComponent } from 'src/app/components-lib/dyn-table/dyn-table.component';

@Component({
  selector: 'xc-roles-admin',
  standalone: true,
  imports: [CommonModule, DynTableComponent, TabViewModule, GrantsAdminComponent],
  templateUrl: './roles-admin.component.html',
  styleUrls: ['./roles-admin.component.scss']
})
export class RolesAdminComponent extends MVVMEventDestroyLifeCycle {
  @Input() override model: RolesAdminVM | undefined;
}
