import { SvgEntityTypesEnum } from "src/app/core/model/svg-model/svg-entity-type-enum";
import { SvgGroup } from "src/app/core/model/svg-model/svg-group.model";
import { SvgRectangle } from "src/app/core/model/svg-model/svg-rectangle.model";
import { SvgText } from "src/app/core/model/svg-model/svg-text.model";

export class SvgBsFloorLabel extends SvgGroup {
    background: SvgRectangle;
    label: SvgText;

    constructor(x: number, y: number, width: number, height: number, label: string, fontSize: number) {
        super({entityType: SvgEntityTypesEnum.g});
        
        this.background = SvgRectangle.fromValues(x, y, width, height);
        this.background.fill = "#ffffff";
        this.background.fillOpacity = 0.1;
        // Le texte de l'étiquette est dimensionné par rapport à la hauteur du rectangle d'arrière plan
        this.label = SvgText.fromValues(x + width - 5, y + (height / 2) + (fontSize / 2), label, fontSize);
        // Le texte de l'étiquette est aligné à droite
        this.label.textAnchor = "end";
    }
}
