import { BuildingAllocationView } from "src/app/core/model/data-model/views/building-allocation-view";
import { BuildingAllocationDbView } from "src/app/core/model/db-model/views/building-allocation-db-view";
import { RealEstateView } from "./real-estate-view";

export class BuildingsAndBusBuildingsDTO {
    buildingAllocations: BuildingAllocationView[] = [];
    realEstateView: RealEstateView[] = [];

    constructor(dtoData: any) {
        dtoData[BuildingAllocationDbView.databaseTableName].forEach((e: any) => {
            this.buildingAllocations.push(new BuildingAllocationView(e));
        });

        dtoData["RealEstateViewItem"].forEach((e: any) => {
            this.realEstateView.push(new RealEstateView(e));
        });
    }
}