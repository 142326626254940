import { ZConstraintTypeEnum } from "src/app/core/model/data-model/enums/z-constraint-type-enum";
import { ZDbTypeEnum } from "src/app/core/model/data-model/enums/z-db-type-enum";
import { ZColumnConstraintView } from "src/app/core/model/data-model/views/z-column-constraint-view";
import { ZColumnView } from "src/app/core/model/data-model/views/z-column-view";
import { FieldBuilder } from "./field-builder";
import { FieldTypeEnum } from "./field-type-enum";
import { ZDbViewColumnView } from "src/app/core/model/data-model/views/z-db-view-column-view";
import { logError } from "src/app/core/services/logging-service";

export abstract class Field<T> {
    fieldType: FieldTypeEnum;
    dbType: ZDbTypeEnum;
    definition: ZColumnView; // db column definition
    constraints: ZColumnConstraintView[] = []; // column constraints
    colId: string; // db column name
    rowId: any; // db row id

    initialValue: T;
    value: T;

    readOnly: boolean = true;
    readOnlyAfterCreate: boolean = false;
    allowsNull: boolean;
    browsable: boolean = false;

    editing: boolean = false;
    inserting: boolean = false;

    errorsCheckRequested?: () => void;
    errorsCheck:() => string[];

    valueChanged?: () => void;

    constructor(fieldType: FieldTypeEnum, colId: string, rowId: any, value: T, definition: ZColumnView | ZDbViewColumnView, constraints: ZColumnConstraintView[], readOnlyByScope: boolean) {
        this.colId = colId;
        this.rowId = rowId;
        this.fieldType = fieldType;
        this.dbType = definition.dbCoType;
        this.value = value;
        this.initialValue = value;

        // Comme ZDbViewColumnView hérite de ZColumnView il faut vérifier d'abord ZDbViewColumnView
        
        if (definition instanceof ZDbViewColumnView && definition.dbViewColumn.dbViCoIsReadOnly) {
            // La définition est de type ZDbViewColumnView lorsqu'on est dans une data grid
            // mais definition.dbViewColumn est vide si les données ne proviennent pas d'une dynview
            this.readOnly = definition.dbViewColumn.dbViCoIsReadOnly || readOnlyByScope;
            this.browsable = definition.dbViewColumn.dbViCoIsBrowsable;
        } else {
            // La définition est de type ZColumnView lorsqu'on est dans une property grid
            this.readOnly = definition.dbCoIsReadOnly || readOnlyByScope;
            this.browsable = definition.dbCoIsBrowsable;
        }

        this.constraints = constraints;
        this.definition = definition;
        this.allowsNull = FieldBuilder.getConstraint<boolean>(this.constraints, ZConstraintTypeEnum.AllowsNull, false);

        // // Le champ n'est pas modifiable s'il a la contrainte IsReadOnlyOnApplicationScope
        // const isReadOnlyOnApplicationScope = FieldBuilder.getConstraint<boolean>(this.constraints, ZConstraintTypeEnum.IsReadOnlyOnApplicationScope, false);
        // if (isReadOnlyOnApplicationScope) {
        //     this.readOnly = true;
        // }

        if (this.constraints.find(x=> x.coCoConstraintType === ZConstraintTypeEnum.IsReadOnlyAfterCreate)) {
            this.readOnlyAfterCreate = FieldBuilder.getConstraint<boolean>(this.constraints, ZConstraintTypeEnum.IsReadOnlyAfterCreate, false);
            if (this.readOnlyAfterCreate) {
                this.readOnly = this.readOnlyAfterCreate;
            }
        }

        const defautErrorMessages: string[] = [];
        if (colId !== "none") {
            defautErrorMessages.push(`${colId}: La vérification des contraintes n'est pas branchée`);
        }
        this.errorsCheck = () => { return defautErrorMessages};
    }

    // raiseValueChanged(): void {
    //     if (this.valueChanged) {
    //         this.valueChanged();
    //     }
    // }

    updated(): boolean {
        return this.value !== this.initialValue;
    }

    saveSucceded(success: boolean): void {
        if (success) {
            this.initialValue = this.value;
        } else {
            this.value = this.initialValue;
        }
    }

    async inputClicked(): Promise<void> {
        this.editing = true;
    }

    focusOut(): void {
        this.editing = false;
        this.raiseSaveRequestIfNecessary();
    }

    tabRequested?: () => void;
    keyDown(e: KeyboardEvent): void {
        if (e.key === 'Enter' || e.key === 'Escape' || e.key === 'Tab') {
            this.editing = false;
            this.raiseSaveRequestIfNecessary();
            
            if (e.key === 'Tab') {
                e.preventDefault();
                if (this.tabRequested) {
                    this.tabRequested();
                } else {
                    logError("Field.tabRequested n'est pas écouté");
                }
            }
            
            return;
        }

        if (e.ctrlKey && e.key.toLowerCase() === 'z') {
            this.value = this.initialValue;
            return;
        }
    }

    saveRequested?:() => void;
    raiseSaveRequestIfNecessary(): void {
        if (this.inserting) {
            if (this.errorsCheckRequested) {
                this.errorsCheckRequested();
            } else {
                logError("Field.errorsCheckRequested n'est pas écouté");
            }
        } else {
            if (this.updated() && this.errorsCheck().length === 0) {
                if (this.saveRequested) {
                    this.saveRequested();
                } else {
                    logError("Field.saveRequested n'est pas écouté");
                }
            }
        }
    }

}