import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectionRotationLabelVM } from '../../model/selection-rotation-label-vm';

@Component({
  selector: 'g[xc-selection-rotation-label]',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './selection-rotation-label.component.svg',
  styleUrls: ['./selection-rotation-label.component.scss']
})
export class SelectionRotationLabelComponent {
  @Input() model: SelectionRotationLabelVM = new SelectionRotationLabelVM();
}
