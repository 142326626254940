import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { PurchasesReceiptVM } from '../../model/purchases-receipt-vm';
import { FormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { SecuredImageComponent } from 'src/app/components-lib/secured-image/secured-image.component';
import { TableModule } from 'primeng/table';
import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';
import { ButtonModule } from 'primeng/button';
import { ConfirmPopup, ConfirmPopupModule } from 'primeng/confirmpopup';
import { ToastModule } from 'primeng/toast';
import { ConfirmationService, MessageService } from 'primeng/api';

@Component({
  selector: 'xc-purchases-receipt',
  standalone: true,
  imports: [
    CommonModule, 
    FormsModule, 
    MatSelectModule, 
    TableModule, 
    SecuredImageComponent, 
    CalendarModule, 
    DropdownModule, 
    ButtonModule, 
    ConfirmPopupModule, 
    ToastModule],
  templateUrl: './purchases-receipt.component.html',
  styleUrl: './purchases-receipt.component.scss',
  providers: [ConfirmationService, MessageService]
})
export class PurchasesReceiptComponent {
  @Input() model: PurchasesReceiptVM | undefined;

  constructor(private confirmationService: ConfirmationService, private messageService: MessageService) {}

  @ViewChild(ConfirmPopup) confirmPopup!: ConfirmPopup;

  accept() {
      this.confirmPopup.accept();
      this.model?.receiptConfirmed();
  }

  reject() {
      this.confirmPopup.reject();
  }

  confirm(event: Event) {
      this.confirmationService.confirm({
          target: event.target as EventTarget,
          message: 'Les éléments en commande vont être intégrés à l\'inventaire.',
          accept: () => {
              this.messageService.add({ severity: 'info', summary: 'Confirmation', detail: 'Réception de commande validée', life: 3000 });
          },
          reject: () => {
              this.messageService.add({ severity: 'error', summary: 'Annulation', detail: 'Réception de commande annulée', life: 3000 });
          }
      });
  }
}
