import { DefinedModelBase } from '../defined-model-base';
import { EquipmentCategoryTable } from "../../db-model/tables/equipment-category-table";

export class EquipmentCategory extends DefinedModelBase {
    eqCaId!: number;
    eqCaName!: string;
    eqCaDisplayNameId!: number;
    eqCaDepth!: number;
    eqCaParentId!: number | null;
    eqCaIsActive!: boolean;
    eqCaScope!: number;

    constructor(dtoData: any, tableDef: any[] = []) {
        super(dtoData, [EquipmentCategoryTable].concat(tableDef));
    }
}