import { ContinentView } from './continent-view';
import { CityDbView } from './../../db-model/views/city-db-view';
import { City } from './../tables/city';
import { CountryView } from './country-view';

export class CityView extends City {
    continent: ContinentView;
    Country: CountryView;
    
    constructor(dtoData: any) {
        super(dtoData, [CityDbView]);
        this.continent = new ContinentView(dtoData);
        this.Country = new CountryView(dtoData);
    }
}