import { Component, Inject, Input } from '@angular/core';
import { CommonModule, DOCUMENT } from '@angular/common';
import { MenuPanelVM } from '../../model/menu-panel-vm';
import { ButtonModule } from 'primeng/button';
import { TooltipModule } from 'primeng/tooltip';
import { SecuredImageComponent } from 'src/app/components-lib/secured-image/secured-image.component';
import { TreeModule } from 'primeng/tree';
import { MVVMEventDestroyLifeCycle } from 'src/app/components-lib/shared-model/mvvm-events-destroy-lifecycle';
import { AuthService } from '@auth0/auth0-angular';

@Component({
  selector: 'xc-menu-panel',
  standalone: true,
  imports: [
    CommonModule, 
    ButtonModule, 
    TooltipModule, 
    SecuredImageComponent,
    TreeModule
  ],
  templateUrl: './menu-panel.component.html',
  styleUrls: ['./menu-panel.component.scss']
})
export class MenuPanelComponent extends MVVMEventDestroyLifeCycle {
  @Input() override model: MenuPanelVM | undefined;

  constructor(@Inject(DOCUMENT) public document: Document, public auth: AuthService) {
    super();
  }
}
