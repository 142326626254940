export class EditableLayer {
    layerId: number;
    layerName: string;
    selected: boolean;
    isEquipmentLayer: boolean;

    constructor(layerId: number, layerName: string, isEquipmentLayer: boolean, selected: boolean = false) {
        this.layerId = layerId;
        this.layerName = layerName;
        this.isEquipmentLayer = isEquipmentLayer;
        this.selected = selected;
    }

    static readOnlyMenuItem: EditableLayer = new EditableLayer(-2, "Lecture", false);
    static noEditableLayersAvailableMenuItem: EditableLayer = new EditableLayer(-1, "Aucun calque n'est éditable", false);

    static getInitialArray(): EditableLayer[] {
        const result = [EditableLayer.readOnlyMenuItem, EditableLayer.noEditableLayersAvailableMenuItem];
        result[0].selected = true;
        return result;
    }

    static contains(editableLayers: EditableLayer[], layerId: number): boolean {
        return editableLayers?.find(x=> x.layerId === layerId) != null;
    }

    static containsEquipmentLayers(editableLayers: EditableLayer[]): boolean {
        return editableLayers?.filter(x=> x.isEquipmentLayer === true).length > 0;
    }
}
