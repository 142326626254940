import { UndefinedModelBase } from '../undefined-model-base';
import { ZColumnConstraintView } from './../views/z-column-constraint-view';
import { ContextUIDynViewSet } from "./context-ui-view-set";

export class DynviewSet {
    columnsConstraints: ZColumnConstraintView[] = [];
    contextUIDynViewSet: ContextUIDynViewSet[] = [];
    viewData: UndefinedModelBase[] = [];
    primaryColumnName: string = "";

    constructor(dtoData: any) {
        dtoData["contextUserColumns"]?.forEach((e: any) => {
            this.contextUIDynViewSet.push(new ContextUIDynViewSet(e));
        });
        dtoData["columnsConstraints"]?.forEach((e: any) => {
            this.columnsConstraints.push(new ZColumnConstraintView(e));
        });
        dtoData["payload"]?.forEach((e: any[]) => {
            this.viewData.push(new UndefinedModelBase(e));
        });
    }

    isFromDynView(): boolean {
        if (this.contextUIDynViewSet.length === 0) return false;
        return this.contextUIDynViewSet[0].contextUITable.coUITaTableName.endsWith("_dynview");
    }

    // getPrimaryColumnName(): string {
    //     // La clé primaire d'un jeu de données potentiellement issu d'une vue est toujours la clé primaire de la table principale
    //     const mainTable = this.mainTable();

    //     // On constitue un tableau des valeurs possibles à plat
    //     const values = this.contextUIDynViewSet.flatMap(x=> ({tableName: x.dbColumn.dbCoTableName, columnName: x.dbColumn.dbCoColumnName, isPrimary: x.dbColumn.dbCoIsPrimaryKey}))
    //     .concat(this.contextUIDynViewSet.flatMap(x=> ({tableName: x.dbColumnView.dbCoTableName, columnName: x.dbColumnView.dbCoColumnName, isPrimary: x.dbColumnView.dbCoIsPrimaryKey})));
        
    //     // On recherche le champ correspondant
    //     let primaryField = values.find(x=> x.tableName === mainTable && x.isPrimary);

    //     if (primaryField) {
    //         return primaryField.columnName;
    //     }

    //     return "";
    //     // if (this.isFromDynView()) {
    //     //     const primaryCol = this.contextUIDynViewSet[0].dbColumnView.dbView.dbViPrimaryFilterKey;
    //     //     return primaryCol ?? "notFound";
    //     // } else {
    //     //     const primaryColDef = this.contextUIDynViewSet.find(x => x.dbColumn.dbCoIsPrimaryKey === true);
    //     //     return primaryColDef ? primaryColDef.dbColumn.dbCoColumnName : "notFound";
    //     // }
    // }

    mainTable(): string {
        if (this.contextUIDynViewSet.length === 0) return "";
        if (this.isFromDynView()) {
            return this.contextUIDynViewSet[0].dbColumnView.dbView.dbViMainTableName;
        } else {
            return this.contextUIDynViewSet[0].contextUITable.coUITaTableName;
        }
    }

    allowUpdate(): boolean {
        if (this.contextUIDynViewSet.length === 0) return false;
        return this.contextUIDynViewSet[0].contextUITable.coUITaAllowsUpdate;
    }

    allowInsert(): boolean {
        if (this.contextUIDynViewSet.length === 0) return false;
        return this.contextUIDynViewSet[0].contextUITable.coUITaAllowsInsert;
    }

    allowDelete(): boolean {
        if (this.contextUIDynViewSet.length === 0) return false;
        return this.contextUIDynViewSet[0].contextUITable.coUITaAllowsDelete;
    }
}