import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayerCommandButtonModel } from '../model/layer-command-button-model';

@Component({
  selector: 'g[xc-layer-command-button]',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './layer-command-button.component.svg',
  styleUrls: ['./layer-command-button.component.scss']
})
export class LayerCommandButtonComponent {
  @Input() model: LayerCommandButtonModel | undefined;
}
