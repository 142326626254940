import { SvgEntityTypesEnum } from 'src/app/core/model/svg-model/svg-entity-type-enum';
import { SvgGroup } from 'src/app/core/model/svg-model/svg-group.model';
import { SvgRectangle } from 'src/app/core/model/svg-model/svg-rectangle.model';
import { StackAnonymousUnitData } from '../../../shared-model/stack-anonymous-unit-data';
import { SvgBsFloorData } from './svg-bs-floor-data';
import { SvgBsFloorLabel } from './svg-bs-floor-label';

export class SvgBsFloor extends SvgGroup {
    floorArea: number;
    buildingFloorMaxArea: number;
    label: SvgBsFloorLabel;
    data: SvgBsFloorData;
    background: SvgRectangle;
    
    constructor(x: number, y: number, width: number, height: number, label: string, floorArea: number, buildingFloorMaxArea: number) {
        super({entityType: SvgEntityTypesEnum.g});

        this.buildingFloorMaxArea = buildingFloorMaxArea;
        this.floorArea = floorArea;

        this.background = SvgRectangle.fromValues(x, y, width, height);
        this.background.fill = "#ffffff";
        this.background.fillOpacity = 0.1;

        // L'étiquette est placée à gauche et fait 10% de la longeur totale
        this.label = new SvgBsFloorLabel(x, y, width / 10, height, label, height / 2);

        this.data = new SvgBsFloorData(x + width / 10, y, width / 10 * 9, height, floorArea, buildingFloorMaxArea);
    }

    loadData(data: StackAnonymousUnitData[]): void {
        this.data.loadData(data);
    }
}
