import { MatListModule } from '@angular/material/list';
import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BlueprintUpdateGrantsVM } from '../../model/blueprint-update-grants-vm';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'xc-blueprint-update-grants',
  standalone: true,
  imports: [CommonModule, MatSelectModule, MatListModule, FormsModule],
  templateUrl: './blueprint-update-grants.component.html',
  styleUrls: ['./blueprint-update-grants.component.scss']
})
export class BlueprintUpdateGrantsComponent {
  @Input() model: BlueprintUpdateGrantsVM | undefined;
}
