import { DocumentVersionTable } from './../../db-model/tables/document-version-table';
import { DefinedModelBase } from '../defined-model-base';

export class DocumentVersion extends DefinedModelBase {
    doVeId!: number;
    doVeCreationDate!: Date;
    doVeIndex!: number;
    doVeIndexCode!: string;
    doVeDocumentId!: number;
    doVeMemo!: string | null;

    constructor(dtoData: any, tableDef: any[] = []) {
        super(dtoData, [DocumentVersionTable].concat(tableDef));
    }
}