import { SelectOptionItem } from "./select-option-item";

export class FacilityCompanyTaskSelectItem {
    id: number;
    name: string;
    isVisible: boolean;
    tasks: SelectOptionItem[];

    constructor(companyId: number, companyName: string, tasks: SelectOptionItem[], isVisible: boolean = true) {
        this.id = companyId;
        this.name = companyName;
        this.isVisible = isVisible;
        this.tasks = tasks;
    }
}