import { ZContextUITableUserTable } from '../../db-model/tables/z-context-ui-table-user-table';
import { DefinedModelBase } from '../defined-model-base';

export class ZContextUITableUser extends DefinedModelBase {
    coUITaUsId!: number;
    coUITaUsContextUITableId!: number;
    coUITaUsUserId!: number;
    coUITaUsPageSize!: number;
    coUITaUsCurrentFilterId!: number | null;
    
    constructor(dtoData: any, tableDef: any[] = []) {
        super(dtoData, [ZContextUITableUserTable].concat(tableDef));
    }
}