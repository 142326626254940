import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CircleInsertGizmo } from '../../../../model/gizmos/insert/circle-insert-gizmo';

@Component({
  selector: 'g[xc-circle-insert-gizmo]',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './circle-insert-gizmo.component.svg',
  styleUrls: ['./circle-insert-gizmo.component.scss']
})
export class CircleInsertGizmoComponent {
  @Input() model: CircleInsertGizmo | undefined;
}
