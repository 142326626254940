import { TreeNode } from "primeng/api";
import { TaskTypeEnum } from "src/app/core/model/data-model/enums/task-type-enum";
import { TaskView } from "src/app/core/model/data-model/views/task-view";

export class MobilityProjectTreeNode implements TreeNode<TaskView> {
    key: string;
    label: string;
    children: MobilityProjectTreeNode[] = [];
    parent: MobilityProjectTreeNode | undefined;
    selected: boolean = false;
    expanded: boolean = false;
    data: TaskView;
    type: string = "task";
    clientY: number = 0;
    clientHeight: number = 0;

    constructor(data: TaskView) {
        this.key = crypto.randomUUID();
        if (data.taTypeId === TaskTypeEnum.Project) {
            this.label = data.taName;
        } else {
            this.label = data.businessTypeView.taBuTyDisplayName;
            if (data.taName !== "NR") {
                this.label = data.taName + ' - ' + this.label;
            }
        }
        this.data = data;
    }

    displayName(): string {
        if (this.data.taTypeId === TaskTypeEnum.Project) {
            return this.data.taName;
        } else {
            let tmp = this.data.businessTypeView.taBuTyDisplayName;
            if (this.data.taName !== "NR") {
                tmp = this.data.taName + ' - ' + tmp;
            }
            return tmp;
        }
    }

    setChildren(children: MobilityProjectTreeNode[]): void {
        this.children = children;
        children.forEach(c => {
            c.parent = this;
        });
    }
}