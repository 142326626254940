import { SvgEntity } from "src/app/core/model/svg-model/svg-entity.model";
import { DxfSvgEntity } from "./dxf-svg-entity";
import { Vector } from "src/app/core/model/geometry-model/vector.model";
import { SvgEntityParser } from "../../../shared/gizmos/model/svg-entity-parser";

export class DxfSvgTspan extends DxfSvgEntity {
    text: string;
    x: number | undefined;
    y: number | undefined;
    dx: number | undefined;
    dy: number | undefined;

    constructor(dtoData: any) {
        super(dtoData);
        this.text = SvgEntity.getValue(dtoData, "text");
        this.x = SvgEntity.getFacultativeNumber(dtoData, "x", undefined);
        this.y = SvgEntity.getFacultativeNumber(dtoData, "y", undefined);
        this.dx = SvgEntity.getFacultativeNumber(dtoData, "dx", undefined);
        this.dy = SvgEntity.getFacultativeNumber(dtoData, "dy", undefined);
    }

    protected override getStatement(): string {
        const attributesStatement = SvgEntityParser.getAttributesStatement([["x", this.x], ["y", this.y], ["dx", this.dx], ["dy", this.dy]]);
        return SvgEntityParser.getTagStatement("tspan", attributesStatement, this.text);
    }
    
    translate(delta: Vector): void {
        if (this.x) {
            this.x = this.x + delta.u;
        }
        if (this.y) {
            this.y = this.y + delta.v;
        }
    }
}