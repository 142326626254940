import { DynTableVM } from "src/app/components-lib/dyn-grid/model/dyn-table-vm";
import { DynTableVMBuilder } from "src/app/components-lib/dyn-grid/model/dyn-table-vm-builder";
import { FieldListItem } from "src/app/components-lib/shared-model/field-list-item";
import { ListField } from "src/app/components-lib/shared-model/list-field";
import { readableUUID } from "src/app/core/events/event-listener-uuid";
import { AppMenuRouteEnum } from "src/app/core/model/data-model/enums/app-menu-route-enum";
import { FacilityJobTask } from "src/app/core/model/data-model/tables/facility-job-task";
import { TablesNamesEnum } from "src/app/core/model/db-model/tables-names-enum";
import { FacilityCompanyTaskTable } from "src/app/core/model/db-model/tables/facility-company-task-table";
import { FacilityJobTaskTable } from "src/app/core/model/db-model/tables/facility-job-task-table";
import { DbModelUtils } from "src/app/core/model/static-functions/db-model-utils";
import { DyntService } from "src/app/core/services/backend-services/dynt-service";
import { PaginationService } from "src/app/core/services/backend-services/pagination-service";
import { PageModel } from "src/app/ui/main/model/page-model";
import Container from "typedi";

export class MobilityCompanyTasks extends PageModel {
    adminTable: DynTableVM | undefined;

    private constructor() {
        super(AppMenuRouteEnum.planning_mobility_companytasks, 0, readableUUID(MobilityCompanyTasks.name));
    }

    static async newAsync(): Promise<MobilityCompanyTasks> {
        const result = new MobilityCompanyTasks();
        result.adminTable = await result.loadAdminData("facility_company_task_dynview");
        return result;
    }

    async loadAdminData(tableName: string): Promise<DynTableVM> {
        const s = Container.get(DyntService);
        const p = Container.get(PaginationService);
        const paginator = await p.getPaginator(tableName);
        const viewSet = await s.contextedDataSet(tableName, 0, undefined, undefined, paginator?.pageItemsCount);
        const adminTable = DynTableVMBuilder.getVM(viewSet, tableName, paginator);

        // Chargement des valeurs actuelles pour les interventions
        const jobsIds = viewSet.viewData.map(x=> (x as any)[FacilityCompanyTaskTable.faCoTaJobTask]);
        const jobs = await s.downloadTable<FacilityJobTask>(TablesNamesEnum.FacilityJobTask, undefined, FacilityJobTaskTable.faJoTaId, jobsIds);
        adminTable.setCurrentSelectOption(FacilityCompanyTaskTable.faCoTaJobTask, DbModelUtils.key(FacilityJobTaskTable, FacilityJobTaskTable.faJoTaId), DbModelUtils.key(FacilityJobTaskTable, FacilityJobTaskTable.faJoTaName), jobs);
        
        adminTable.loadOptionsRequested = async (f: ListField, rowId: number) => {
            // Sélectionne les interventions
            const jobs = await s.downloadTable<FacilityJobTask>(TablesNamesEnum.FacilityJobTask);
            return jobs.map(x=> new FieldListItem(x.faJoTaId, x.faJoTaName, null));
        }
        
        adminTable.newPageDataRequested = async (pageIndex: number) => {
            const px = await s.contextedDataSet(tableName, pageIndex, undefined, undefined, adminTable.paginator?.pageItemsCount);
            adminTable.setDataSource(px.viewData);
        }

        adminTable.saveRequested = async (tableName: string, primaryColumnName: string, rowId: any, columnName: string, value: any): Promise<string | null> => {
            const s = Container.get(DyntService);
            const dto: any = {
                "TableName": tableName,
                [primaryColumnName]: rowId,
                [columnName]: value
            }
            return await s.patch(tableName, rowId, dto);
        }

        // TODO : cette méthode peut être mutualisée avec toutes les autres écoutes de ce type
        adminTable.insertionRequested = async (values : {}): Promise<any> => {
            const s = Container.get(DyntService);
            return await s.post<any>("", values);
        }

        // TODO : cette méthode peut être mutualisée avec toutes les autres écoutes de ce type
        adminTable.deletionRequested = async (tableName: string, rowId: any): Promise<boolean> =>  {
            const s = Container.get(DyntService);
            const result = await s.delete(tableName, rowId);
            if (result) {
                return true;
            }
            return false;
        }

        return adminTable;
    }
}