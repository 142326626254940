import { TablesNamesEnum } from './../tables-names-enum';
export const EquipmentPurchaseTable = {
    databaseTableName: TablesNamesEnum.EquipmentPurchase,
    eqPuId: 'EqPu_Id',
    eqPuPurchaseDate: 'EqPu_PurchaseDate',
    eqPuPurchaseRef: 'EqPu_PurchaseRef',
    eqPuTargetDate: 'EqPu_TargetDate',
    eqPuDeliveryDate: 'EqPu_DeliveryDate',
    eqPuTaskId: 'EqPu_TaskId'
  }
  