import { Point } from "src/app/core/model/geometry-model/point.model";
import { DrawingVM } from "../drawing-vm";
import { InteractionCommand } from "./interaction-command";
import { SelectableSvgEntity } from "../../svg/selectable-svg-entity";
import { Polygon } from "src/app/core/model/geometry-model/polygon.model";
import { logError } from "src/app/core/services/logging-service";

export class SelectionInteraction {
    currentCommand: InteractionCommand;
    drawing: DrawingVM;
    selectionPathPoints: Point[] = [];
    selectedEntities: SelectableSvgEntity[] = [];
    selectionChanged?: (selection: SelectableSvgEntity[]) => void;

    constructor(command: InteractionCommand, drawing: DrawingVM) {
        this.currentCommand = command;
        this.drawing = drawing;
    }

    single(): SelectableSvgEntity | undefined {
        if (this.selectedEntities.length === 1) {
            return this.selectedEntities[0];
        }
        return undefined;
    }

    areSame(): boolean {
        return [...new Set(this.selectedEntities.map(x=> x.entityType))].length === 1;
    }

    updateSelectionPathPoints(p: Point, toRemove: boolean): void {
        if (this.selectionPathPoints.length === 0)
        {
            return;
        }

        if (this.currentCommand.isDrawingRectangularSelectionCommand())
        {
            const firstPoint = this.selectionPathPoints[0];
            const temp: Point[] = [];

            temp.push(firstPoint);
            const secondPoint = new Point(p.x, firstPoint.y);
            temp.push(secondPoint);
            temp.push(p);
            const lastPoint = new Point(firstPoint.x, p.y);
            temp.push(lastPoint);

            this.selectionPathPoints = temp;
        }
        else
        {
            var distance = this.selectionPathPoints[this.selectionPathPoints.length - 1].distanceTo(p);
            if (distance > 0.3)
            {
                this.selectionPathPoints.push(p);
            }
        }

        this.selectedEntities = this.getZoneSelection();
        this.raiseSelectionChange();
    }

    getZoneSelection(): SelectableSvgEntity[] {
        const result: SelectableSvgEntity[] = [];
        // On réctifie le rectanble de sélection
        const topLeftSquare = Polygon.topLeftOriginSquare(this.selectionPathPoints);
        // On recherche alors les entités qui ont tous leurs points dans le rectangle de sélection
        const polygon = new Polygon(this.selectionPathPoints);
        polygon.close();
        this.drawing.entities.forEach(de => {
            const pts = de.selectablePoints;
            if (polygon.containsAll(pts)) {
                result.push(de);
            }
        });

        return result;
    }

    setSelection(items: SelectableSvgEntity[]): void {
        this.selectedEntities = items;
        this.raiseSelectionChange();
    }

    addSelection(items: SelectableSvgEntity[]): void {
        items.forEach(i => {
            const alreadyIn = this.selectedEntities.find(x=> x.entityId === i.entityId) !== undefined;
            if (!alreadyIn) {
                this.selectedEntities.push(i);
            }
        });
        this.raiseSelectionChange();
    }

    clear(): void {
        this.selectionPathPoints.splice(0);
        //this.gizmo.show(false);
        // if (this.userLayerSelect) {
        //     this.userLayerSelect(null);
        // } else {
        //     LoggerService.error("SelectionInteraction.userLayerSelect n'est pas écouté");
        // }
    }

    raiseSelectionChange(): void {
        if (this.selectionChanged) {
            this.selectionChanged(this.selectedEntities);
        } else {
            logError("SelectionInteraction.selectionChanged n'est pas écouté");
        }
    }
}