import { SvgEntity } from "src/app/core/model/svg-model/svg-entity.model";
import { BpSvgEntity } from "./bp-svg-entity";

export class BpSvgTspan extends BpSvgEntity {
    text: string;

    constructor(dtoData: any) {
        super(dtoData);
        this.text = SvgEntity.getValue(dtoData, "text");
    }
}
