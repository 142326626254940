import { FloorModelEnum } from 'src/app/core/model/data-model/enums/floor-model-enum';
import { Floor } from 'src/app/core/model/data-model/tables/floor';
import { SvgPath } from 'src/app/core/model/svg-model/svg-path.model';
import { FloorAxonoDTO } from 'src/app/core/services/backend-services/dto/floor-axono-dto';
import { RoomColorMapDTO } from 'src/app/core/services/backend-services/dto/room-color-map-dto';
import { BpSvgRoom } from 'src/app/ui/pages/layout/real-estate/floor-blueprint/content/svg-entities/model/bp-svg-room';

export class AxonoFloor {
    viewbox: string;
    absoluteElevation!: number;
    extents?: SvgPath;
    floor: Floor;
    svgRooms: BpSvgRoom[] = [];
    isSelected: boolean = false;
    isNotSelectedAbove: boolean = false;
    isNotSelectedBelow: boolean = false;
    overX: number = 0;
    notOverAboveZ: number = 0;
    notOverBelowZ: number = 0;

    constructor(dto: FloorAxonoDTO, infraCount: number) { 
        this.floor = dto.floor;
        this.absoluteElevation = this.floor.flElevation + infraCount;
        this.viewbox = dto.viewbox;
        const extentsGroup = dto.groups.find(x=> (x.floorModelId === FloorModelEnum.FloorOutsideContours));
        if (extentsGroup) {
            this.extents = new SvgPath(extentsGroup.entities[0]);
            this.extents.fill = "#265c7f"
        }
        const roomGroups = dto.groups.filter(x=> (x.floorModelId === FloorModelEnum.Rooms));
        roomGroups.forEach(g => {
            const svgRoom = new BpSvgRoom(g.entities[0], g);
            svgRoom.setFill("#ffffff");
            this.svgRooms.push(svgRoom);
        });
    }

    setColorMap(mapType: string, colorMap: RoomColorMapDTO[]): void {
        this.svgRooms.forEach(r => {
            const mapItem = colorMap.find(x=> x.roomId === r.floorDataId);
            if (mapItem) {
                r.setFill(mapItem.color);
            }
        });
    }
}