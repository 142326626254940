import { Room } from './../tables/room';
import { FloorData } from '../tables/floor-data';
import { Building } from '../tables/building';
import { Floor } from '../tables/floor';
import { getView } from '../views/view-builder';
import { RoomView } from '../views/room-view';

export class RoomViewSet {
    dataSet: (Room & FloorData & Floor & Building & RoomView);

    constructor(dtoData: any) {
        this.dataSet = getView(dtoData, Room, FloorData, Floor, Building, RoomView);
    }
}