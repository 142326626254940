import { FloorDataTable } from 'src/app/core/model/db-model/tables/floor-data-table';
import { DefinedModelBase } from '../defined-model-base';

export class FloorData extends DefinedModelBase {
    flDaId!: number;
    flDaFloorCatalogId!: number | null;
    flDaSvgStatement!: string;
    flDaBoundingBox!: string;
    flDaFloorModelId!: number;
    flDaTaskId!: number;
    flDaDataStateId!: number;
    flDaParentId!: number | null;
    flDaSourceId!: number | null;
    flDaAnchorId!: number | null;

    constructor(dtoData: any) {
        super(dtoData, [FloorDataTable]);
    }
}