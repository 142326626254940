import { BlueprintLayer } from '../../../../svg-entities/model/layers/blueprint-layer';
import { missingListener } from 'src/app/core/services/logging-service';

export class LayerTreeNode {
    id: number;
    parentId: number | null;
    name: string;
    layer: BlueprintLayer | undefined;
    children: LayerTreeNode[] = [];
    isVisible: boolean = true;
    childrenPartiallyVisible: boolean = false;

    constructor(id: number, name: string, parentId: number | null, layer?: BlueprintLayer) {
        this.id = id;
        this.parentId = parentId;
        this.name = name;
        this.layer = layer;
    }

    setChildren(children: LayerTreeNode[]): void {
        this.children = children;
        this.setChildrenVisibilityChangeListner(children);
    }

    addChildren(children: LayerTreeNode[]): void {
        this.children = this.children.concat(children).sort((a, b) => a.name.localeCompare(b.name));
        this.setChildrenVisibilityChangeListner(children);
    }

    setChildrenVisibilityChangeListner(children: LayerTreeNode[]): void {
        children.forEach(c => {
            c.visibilityChange = () => {
                this.checkChildrenVisibility();
            }
        });
    }

    checkChildrenVisibility(): void {
        let allValuesAreSame: boolean = true;
        let tmp = this.children[0].isVisible;
        for (const child of this.children) {
            if (child.isVisible !== tmp || child.childrenPartiallyVisible) {
                this.childrenPartiallyVisible = true;
                this.isVisible = false;
                allValuesAreSame = false;
                break;
            }
        }

        if (allValuesAreSame) {
            this.childrenPartiallyVisible = false;
            this.isVisible = tmp;
        }

        this.raiseVisibilityChange(false);
    }

    userOptionUpdateRequested?: (id: number, visible: boolean) => void;
    setVisibility(visible: boolean, byUser: boolean): void {
        this.isVisible = visible;
        if (this.layer) {
            this.layer.hideByUser(!visible);
            
            if (byUser) {
                if (this.userOptionUpdateRequested) {
                    this.userOptionUpdateRequested(this.id, this.isVisible);
                } else {
                    missingListener("LayerTreeNode.userOptionUpdateRequested");
                }
            }
        }

        if (this.children != null) {
            this.children.forEach(c => {
                c.setVisibility(this.isVisible, byUser);
            });
        }
    }

    raiseVisibilityChange(byUser: boolean): void {
        if (this.visibilityChange) {
            this.visibilityChange(byUser);
        } else {
            missingListener("LayerTreeNode.visibilityChange");
        }
    }

    visibilityChange?: (byUser: boolean) => void;
    onVisibilityCheckChange(): void {
        this.setVisibility(!this.isVisible, true);

        if (this.parentId != null) {
            this.raiseVisibilityChange(true);
        }
    }
    
}
