import { Component, Input, OnInit } from '@angular/core';
import { ClientsVM } from '../../model/clients-vm';
import { CarouselModule } from 'primeng/carousel';
import { TagModule } from 'primeng/tag';
import { ButtonModule } from 'primeng/button';
import { SecuredImageComponent } from 'src/app/components-lib/secured-image/secured-image.component';

@Component({
  selector: 'xc-clients',
  standalone: true,
  imports: [CarouselModule, TagModule, ButtonModule, SecuredImageComponent],
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.scss']
})
export class ClientsComponent implements OnInit {
  @Input() model: ClientsVM | undefined;

  constructor() { }

  ngOnInit(): void {
  }
}
