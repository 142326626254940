import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { forkJoin, Subject } from 'rxjs';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UploadDialogData } from '../../model/upload-dialog-data';
import { FileUploadComponent } from '../file-upload/file-upload.component';
import { MaterialModule } from 'src/app/core/material.module';

@Component({
  selector: 'xc-upload-dialog',
  standalone: true,
  imports: [CommonModule, MaterialModule, FileUploadComponent],
  templateUrl: './upload-dialog.component.html',
  styleUrls: ['./upload-dialog.component.scss']
})
export class UploadDialogComponent {
  canBeClosed = true;
  primaryButtonText = 'Téléverser';
  showCancelButton = true;
  uploadListEmpty = true;

  beginUpload = new Subject<boolean>();

  constructor(public dialogRef: MatDialogRef<UploadDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public dialogData: UploadDialogData) {}

  successfulUpload(completeEvent: any) {
    this.dialogRef.close(completeEvent);
    this.dialogRef.disableClose = false;
    this.showCancelButton = true;
    this.canBeClosed = true;
  }

  startUpload() {
    this.showCancelButton = false;
    this.beginUpload.next(true);
  }

  onFileAdded(count: number): void {
    // console.log(count);
    this.uploadListEmpty = count < 1;
  }

  onUploading() {
    this.canBeClosed = false;
    this.dialogRef.disableClose = true;
  }
}
