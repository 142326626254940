import { AnyField } from './../../shared-model/any-field';
import { ZColumnView } from 'src/app/core/model/data-model/views/z-column-view';
import { ListField } from './../../shared-model/list-field';
import { StringField } from './../../shared-model/string-field';
import { ZConstraintTypeEnum } from 'src/app/core/model/data-model/enums/z-constraint-type-enum';
import { PageEvent } from '@angular/material/paginator';
import { DynTableColumn } from "./dyn-table-column";
import { DynTableRow } from "./dyn-table-row";
import { FieldTypeEnum } from '../../shared-model/field-type-enum';
import { ZDbTypeEnum } from 'src/app/core/model/data-model/enums/z-db-type-enum';
import { Field } from '../../shared-model/field';
import { Paginator } from 'src/app/components-lib/dyn-grid/model/paginator';
import { DynTableVMBuilder } from './dyn-table-vm-builder';
import { ZDbViewColumnView } from 'src/app/core/model/data-model/views/z-db-view-column-view';
import { ZColumnConstraintView } from 'src/app/core/model/data-model/views/z-column-constraint-view';
import { BooleanField } from '../../shared-model/boolean-field';
import { NumberField } from '../../shared-model/number-field';
import { ColorField } from '../../shared-model/color-field';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import Container from 'typedi';
import { ContextService } from 'src/app/core/services/backend-services/context-service';
import { ZContextUITableUserColumnTable } from 'src/app/core/model/db-model/tables/z-context-ui-table-user-column-table';
import { ImageFileField } from '../../shared-model/image-file-field';
import { PaginationService } from 'src/app/core/services/backend-services/pagination-service';
import { DateField } from '../../shared-model/date-field';
import { FieldListItem } from '../../shared-model/field-list-item';
import { DefinedModelBase } from 'src/app/core/model/data-model/defined-model-base';
import { DyntService } from 'src/app/core/services/backend-services/dynt-service';
import { IInitRowEditable } from './i-init-row-editable';
import { FieldBuilder } from '../../shared-model/field-builder';
import { DynTableDefaultProcessing } from './dyn-table-default-processing';
import { TableRowSelectEvent } from 'primeng/table';
import { DateUtils } from 'src/app/core/model/static-functions/date-utils';
import { missingListener } from 'src/app/core/services/logging-service';
import { toastInfo } from 'src/app/core/services/toast-service';

export class DynTableVM {
    viewData: any[];
    columns: DynTableColumn[] = [];
    displayedColumns: string[];
    expandedRow: DynTableRow | null = null;
    dataSource: DynTableRow[] = [];
    mainTableName: string;
    sourceViewName: string;
    primaryColumnName: string;
    allowUpdate: boolean;
    allowInsert: boolean;
    allowDelete: boolean;
    allowExtract: boolean;
    definitions: ZDbViewColumnView[] = [];
    constraints: ZColumnConstraintView[] = []
    contextTableUserId: number;

    paginator: Paginator | null;

    inserting: boolean = false;

    withSelectableRows: boolean;
    selectedRow: DynTableRow | undefined;

    fieldTypeEnum = FieldTypeEnum;
    documentName: string;
    documentTitle: string;
    // listFieldsDataMethods: {colId: string, method: () => Promise<FieldListItem[]>}[] = [];
    firstItemIndex: number = 0;

    newRowAddRequested: (table?: IInitRowEditable) => Promise<any>;
    onBlankRowInserted?: (row: DynTableRow) => void;
    newRowSaveRequested: (values: any) => Promise<any>;
    rowUpdateSaveRequested: (tableName: string, rowId: number, dto: any) => Promise<any>;
    rowDeleteRequested: (tableName: string, rowId: number) => Promise<any>;
    imageUploadRequested: (file: File, inserting: boolean, maxSize: number, rowId: number) => Promise<string>;
    selectedRowChanged: (row: DynTableRow) => Promise<any>;
    loadOptionsRequested: (f: ListField, rowId: number) => Promise<FieldListItem[]>;

    constructor(
        columns: DynTableColumn[], 
        viewData: any[], 
        mainTableName: string,
        sourceViewName: string,
        primaryColumnName: string, 
        allowUpdate: boolean, 
        allowInsert: boolean, 
        allowDelete: boolean,
        paginator: Paginator | null,
        definitions: ZDbViewColumnView[],
        constraints: ZColumnConstraintView[],
        contextTableUserId: number,
        withSelectableRows: boolean,
        documentName: string = "unknown",
        documentTitle: string = "Sans titre"
        ) 
        {
        this.viewData = viewData;
        this.withSelectableRows = withSelectableRows;
        this.mainTableName = mainTableName;
        this.sourceViewName = sourceViewName;
        this.primaryColumnName = primaryColumnName;
        this.allowUpdate = allowUpdate;
        this.allowInsert = allowInsert;
        this.allowDelete = allowDelete;
        // TODO : implémenter l'habilitation dans la base
        this.allowExtract = true;
        this.definitions = definitions;
        this.constraints = constraints;
        this.contextTableUserId = contextTableUserId;
        this.columns = columns;
        this.setDataSource(viewData);
        this.injectCommandsCells();
        this.documentName = documentName;
        this.documentTitle = documentTitle;

        this.paginator = paginator;
        this.paginator?.setPageReport(this.dataSource.length);

        if (this.withSelectableRows) {
            this.injectSelectCells();
        }

        this.displayedColumns = this.columns.map(c => c.id);

        // Branche les méthodes CRUD par défaut
        // ces méthodes peuvent être overridées pour les cas particuliers
        this.newRowAddRequested = async (table?: IInitRowEditable): Promise<any> => {
            return await this.defaultNewRowInsert(table);
        }

        this.newRowSaveRequested = async (values: any): Promise<any> => {
            return await DynTableDefaultProcessing.saveNewRow(values);
        }

        this.rowUpdateSaveRequested = async (tableName: string, rowId: number, dto: any): Promise<any> => {
            return await DynTableDefaultProcessing.saveRowUpdate(tableName, rowId, dto);
        }

        this.rowDeleteRequested = async (tableName: string, rowId: number): Promise<any> => {
            return await DynTableDefaultProcessing.deleteRow(tableName, rowId);
        }

        this.loadOptionsRequested = async (f: ListField, rowId: number): Promise<FieldListItem[]> => {
            return [];
        }

        // Les méthodes suivantes doivent nécessairement être overridée par chaque owner
        this.imageUploadRequested = async (file: File, inserting: boolean, maxSize: number, rowId: number): Promise<string> => {
            return "La méthode n'a pas été initialisée";
        }

        this.selectedRowChanged = async (row: DynTableRow): Promise<any> => {
            return "La méthode n'a pas été initialisée";
        };

        
    }

    setDataSource(viewData: any[]): void {
        this.dataSource = DynTableVMBuilder.getRows(viewData, this.columns, this.primaryColumnName, this.definitions, this.constraints);
        this.listenToSaveRequests();
        this.listenToDeletionRequests();
        this.listenToImageFileUploadRequests();
        //this.listenToLoadOptionsRequests();
        //this.listenToCellValueChange();
    }

    setSelectCurrentOptions(columnName: string, options: FieldListItem[]): void {
        this.dataSource.forEach(r => {
            const c = r.fields.find(x=> x.colId === columnName);
            if (c && c.fieldType === FieldTypeEnum.list) {
                const option = options.find(x=> x.id === c.value);
                if (option) {
                    (c as ListField).loadCurrentValue(option);
                }
            }
        });
    }

    saveRequested?:(tableName: string, primaryColumnName: string, rowId: any, columnName: string, value: any) => Promise<string | null>;
    listenToSaveRequests(): void {
        this.dataSource.forEach(r => {
            this.listenToRowSaveRequest(r);
        });
    }

    // listenToLoadOptionsRequests(): void {
    //     this.dataSource.forEach(r => {
    //         r.loadOptionsRequested = async (f: ListField, rowId: number) => {
    //             return await this.loadOptionsRequested(f, rowId);
    //         }
    //     });
    // }

    listenToImageFileUploadRequests(): void {
        this.dataSource.forEach(r => {
            r.imageUploadRequested = async (file: File, inserting: boolean, maxSize: number, rowId: number): Promise<string> => {
                return await this.imageUploadRequested(file, inserting, maxSize, rowId);
            }
        });
    }

    listenToRowSaveRequest(r: DynTableRow): void {
        r.saveRequested = async (cell: Field<any>) => {
            if (this.saveRequested) {
                const result = await this.saveRequested(this.mainTableName, this.primaryColumnName, r.rowId, cell.colId, cell.value);
                cell.saveSucceded(result === "U");
            } else {
                missingListener("DynTableVM.saveRequested");
            }
        }
    }

    deletionRequested?: (tableName: string, rowId: any) => Promise<boolean>;
    listenToDeletionRequests(): void {
        this.dataSource.forEach(r => {
            this.listenToRowDeleteRequest(r);
        })
    }

    listenToRowDeleteRequest(r: DynTableRow): void {
        r.deletionRequested = async () => {
            if (this.deletionRequested) {
                const result = await this.deletionRequested(this.mainTableName, r.rowId);
                if (result) {
                    // La ligne a bien été supprimée de la base, on la retire de la datagrid
                    const rowIndex = this.dataSource.indexOf(r);
                    const tmp = this.dataSource;
                    tmp.splice(rowIndex, 1);
                    // Il faut refaire le tableau pour que la ligne disparaisse de l'affichage
                    this.dataSource = tmp.map(x=> x);
                } else {
                    // Une erreur s'est produite
                }
            } else {
                missingListener("DynTableVM.deletionRequested");
            }
        }
    }

    // cellValueChange?: (cell: Field<any>) => void;
    // listenToCellValueChange(): void {
    //     this.dataSource.forEach(r => {
    //         r.fields.forEach(f => {
    //             f.valueChanged = () => {
    //                 if (this.cellValueChange) {
    //                     this.cellValueChange(f);
    //                 }
    //             }
    //         });
    //         // r.cellValueChanged = (cell: Field<any>) => {
    //         //     if (this.cellValueChange) {
    //         //         this.cellValueChange(cell);
    //         //     }
    //         // }
    //     });
    // }

    commandsColumn(): DynTableColumn {
        return new DynTableColumn(FieldTypeEnum.none, ZDbTypeEnum.none, "none", "", true, new ZColumnView({}), [], 0);
    }

    selectColumn(): DynTableColumn {
        return new DynTableColumn(FieldTypeEnum.selectCommand, ZDbTypeEnum.none, "select", "", true, new ZColumnView({}), [], 0);
    }

    injectCommandsCells(): void {
        if (this.allowInsert || this.allowDelete) {
            this.columns.push(this.commandsColumn());

            this.dataSource.forEach(r => {
                r.fields.push(new AnyField("none", r.rowId, "", new ZColumnView({}), [], false));
            });
        }
    }

    injectSelectCells(): void {
        if (this.withSelectableRows) {
            this.columns = [this.selectColumn()].concat(this.columns);

            this.dataSource.forEach(r => {
                r.fields = [new AnyField("select", r.rowId, "", new ZColumnView({}), [], false)].concat(r.fields);
            });
        }
    }
    
    newPageDataRequested?: (pageIndex: number) => void;
    async handlePageEvent(e: PageEvent): Promise<void> {
        if (this.paginator == null) return;

        if (e.pageSize !== this.paginator.pageItemsCount) {
            this.paginator.pageItemsCount = e.pageSize;
            const p = Container.get(PaginationService);
            await p.updatepageSize(this.contextTableUserId, e.pageSize);
        }

        if (this.newPageDataRequested) {
            this.newPageDataRequested(e.pageIndex);
        } else {
            missingListener("DynTableVM.newPageDataRequested");
        }
    }

    // La methode d'insertion peut être substituée si nécessaire
    // lorsqu'il faut par exemple utiliser un formulaire de saisie spécifique
    // comme c'est le cas pour appUser
    overrideInsertMethod(customMethod: any): void {
        this.insertMethod = customMethod;
    }

    private insertMethod: any = () => {
        this.inserting = true;
        const newCells = this.getRowCells();

        const newRow = new DynTableRow(this.primaryColumnName, 0, newCells, false);
        newRow.imageUploadRequested = async (file: File, inserting: boolean, maxSize: number, rowId: number): Promise<string> => {
            if (this.imageUploadRequested) {
                return await this.imageUploadRequested(file, inserting, maxSize, rowId);
            } else {
                missingListener("DynTableVM.imageUploadRequested");
                return "";
            }
        }

        // newRow.loadOptionsRequested = async (f: ListField, rowId: number) => {
        //     if (this.loadOptionsRequested) {
        //         return await this.loadOptionsRequested(f, rowId);
        //     } else {
        //         LoggerService.missingListener("DynTableVM.loadOptionsRequested");
        //         return [];
        //     }
        // }

        this.dataSource = [newRow].concat(this.dataSource);
        newRow.checkErrors();

        newRow.insertionCanceled = () => {
            this.dataSource = this.dataSource.slice(1);
            this.inserting = false;
        }

        newRow.insertionRequested = async () => {
            if (this.insertionRequested) {
                const values: any = {};

                values["TableName"] = this.mainTableName;
                values["ViewName"] = this.sourceViewName;
                values[this.primaryColumnName] = newRow.rowId;
                newRow.fields.forEach(c => {
                    if (!c.readOnly) {
                        // Empêche les string et les dates vides d'être envoyées au backend
                        // ces colonnes seront valorisées à null
                        if (!(c.fieldType === FieldTypeEnum.string && c.value === '') && !(c.fieldType === FieldTypeEnum.date && c.value === '')) {
                            values[c.colId] = c.value;
                        }
                    }
                });

                const result = await this.insertionRequested(values);
                if (result !== null) {
                    this.feedRow(newRow, result);
                } else {
                    this.dataSource = this.dataSource.slice(1);
                }
            } else {
                missingListener("DynTableVM.insertionRequested");
            }
            this.inserting = false;
        }
    }

    insertionRequested?: (values : {}) => Promise<any>;
    insertButtonClick(): void {
        this.insertMethod();
    }

    addRow(dto: any): void {
        const cells = this.getRowCells();
        const newRow = new DynTableRow(this.primaryColumnName, 0, cells, false);
        this.dataSource = [newRow].concat(this.dataSource);
        this.feedRow(newRow, dto);
    }

    feedRow(r: DynTableRow, dto: any): void {
        if (!dto) return;
        r.rowId = dto[this.primaryColumnName];
        r.fields.forEach(c => {
            c.value = dto[c.colId];
            c.initialValue = dto[c.colId];
        });

        this.listenToRowDeleteRequest(r);
        this.listenToRowSaveRequest(r);
    }

    // to remove after refac
    getRowCells(): Field<any>[] {
        const result: Field<any>[] = [];
        this.columns.forEach(c => {
            const newCell = this.getCell(c);
            newCell.inserting = true;
            newCell.readOnly = c.readOnly;
            const nullConstraint = c.constraints.find(x=> x.coCoConstraintType === ZConstraintTypeEnum.AllowsNull);
            newCell.allowsNull = nullConstraint === undefined || nullConstraint.coCoValue === "true";
            if (newCell.fieldType === FieldTypeEnum.list) {
                (newCell as ListField).loadCurrentValue(new FieldListItem(0, "Sélectionnez...", null));
            }
            result.push(newCell);
        });
        return result;
    }

    getNewRowCells(): Field<any>[] {
        const result: Field<any>[] = [];
        this.columns.forEach(c => {
            const newCell = this.getCell(c);
            newCell.inserting = true;
            const readOnlyAfterCreateConstraint = c.constraints.find(x=> x.coCoConstraintType === ZConstraintTypeEnum.IsReadOnlyAfterCreate);
            const IsReadOnlyAfterCreate = readOnlyAfterCreateConstraint ? FieldBuilder.getConstraint<boolean>(this.constraints, ZConstraintTypeEnum.IsReadOnlyAfterCreate, false) : false;
            newCell.readOnly = c.readOnly && !IsReadOnlyAfterCreate;
            const nullConstraint = c.constraints.find(x=> x.coCoConstraintType === ZConstraintTypeEnum.AllowsNull);
            newCell.allowsNull = nullConstraint === undefined || nullConstraint.coCoValue === "true";
            // if (newCell.fieldType === FieldTypeEnum.list) {
            //     (newCell as ListField).loadCurrentValue(new FieldListItem(0, "Sélectionnez...", null));
            // }
            result.push(newCell);
        });
        return result;
    }

    getCell(c: DynTableColumn): Field<any> {
        // La colonne a peut être une valeur par défaut
        let defaultValue: any = undefined;
        const defaultConstraint = c.constraints.find(x=> x.constraintType.coTyId === ZConstraintTypeEnum.DefaultValue);
        if (defaultConstraint) {
            defaultValue = defaultConstraint.coCoValue;
        } 

        switch (c.cellType) {
            case FieldTypeEnum.string:
                return new StringField(c.id, 0, defaultValue === undefined ? "" : defaultValue, c.definitions, c.constraints, false);
            case FieldTypeEnum.boolean:
                return new BooleanField(c.id, 0, defaultValue === undefined ? false : Boolean(defaultValue), c.definitions, c.constraints, false);
            case FieldTypeEnum.list:
                return new ListField(c.id, 0, defaultValue === undefined ? 0 : defaultValue, c.definitions, c.constraints, false);
            case FieldTypeEnum.number:
                return new NumberField(c.id, 0, defaultValue === undefined ? 0 : defaultValue, c.definitions, c.constraints, false);
            case FieldTypeEnum.color:
                return new ColorField(c.id, 0, defaultValue === undefined ? "#ffffff" : defaultValue, c.definitions, c.constraints, false);
            case FieldTypeEnum.imageFile:
                return new ImageFileField(c.id, 0, defaultValue === undefined ? "" : defaultValue, c.definitions, c.constraints, false);
            case FieldTypeEnum.date:
                return new DateField(c.id, 0, defaultValue === undefined ? "" : defaultValue, c.definitions, c.constraints, false);
    
            default:
                return new AnyField(c.id, 0, defaultValue === undefined ? "" : defaultValue, c.definitions, c.constraints, false);
        }
    }

    setCurrentSelectOption(fieldColName: string, optionIdColName: string, optionValueColName: string, data?: DefinedModelBase[]): void {
        let currentOptions: FieldListItem[];
        if (data) {
            currentOptions = data.map((x: { [x: string]: any; })=> new FieldListItem(x[optionIdColName], x[optionValueColName], null));
        } else {
            currentOptions = this.viewData.map(x=> new FieldListItem(x[optionIdColName], x[optionValueColName], null));
        }
        this.setSelectCurrentOptions(fieldColName, currentOptions);
    }

    csvExtractRequested?: () => void;
    async csvExtractButtonClick(): Promise<void> {
        if (this.csvExtractRequested) {
            this.csvExtractRequested();
        } else {
            missingListener("DynTableVM.csvExtractRequested");
        }
    }

    pdfExtractRequested?: () => void;
    async pdfExtractButtonClick(): Promise<void> {
        if (this.pdfExtractRequested) {
            this.pdfExtractRequested();
        } else {
            missingListener("DynTableVM.pdfExtractRequested");
        }
    }
  
    async dropColumn(e: CdkDragDrop<string[]>) {
        // Réorganise les colonnes
        moveItemInArray(this.displayedColumns, e.previousIndex, e.currentIndex);
        // Enregistre le résultat
        const dto:{}[] = [];
        for (let i = 0; i < this.displayedColumns.length; i++) {
            const id = this.displayedColumns[i];
            const c = this.columns.find(x=> x.id === id);
            dto.push({[ZContextUITableUserColumnTable.coUITaUsCoId]: c?.contextTableUerColumnId, [ZContextUITableUserColumnTable.coUITaUsCoDisplayOrder]: i});
        }
        const s = Container.get(ContextService);
        await s.saveUserColumnsOrder(dto);
    }

    onSelectedRowChanged(row: DynTableRow): void {
        this.selectedRow = row;
        this.selectedRowChanged(row);
    }



    async loadListsOptions(fields: Field<any>[]): Promise<void> {
        const listCells = fields.filter(x => x.fieldType === FieldTypeEnum.list);
        if (listCells.length > 0) {
            const s = Container.get(DyntService);
            listCells.forEach(async c => {
                (c as ListField).loadOptions(await this.loadOptionsRequested((c as ListField), c.rowId));
                (c as ListField).loadOptionsRequested = async (): Promise<FieldListItem[]> => {
                    return await this.loadOptionsRequested((c as ListField), c.rowId);
                }
            });
        }
    }

    onListFieldValueChange(row: DynTableRow, field: ListField): void {
        field.selectedItemChange();
        row.checkErrors();
    }

    async onRowEditInit(row: DynTableRow) {
        row.editing = true;
        await this.loadListsOptions(row.fields);
        row.checkErrors();
    }
    
    async onRowEditSave(row: DynTableRow): Promise<void> {
        row.editing = false;
        const s = Container.get(DyntService);
        if (this.inserting) {
            const values = row.values();
            values["TableName"] = this.mainTableName;
            values["ViewName"] = this.sourceViewName;
            const result = await this.newRowSaveRequested(values);
            if (result) {
                row.rowId = result[row.primaryColumnName];
            }
    
            this.inserting = false;
        } else {
            const dto: any = {
                "TableName": this.mainTableName,
                [row.primaryColumnName]: row.rowId
            }
            let hasUpdates: boolean = false;
            row.fields.filter(x=> x.updated()).forEach(c => {
                if (c.fieldType === FieldTypeEnum.date) {
                    dto[c.colId] = DateUtils.toNullableBackendString(c.value);

                } else {
                    dto[c.colId] = c.value;
                }
                hasUpdates = true;
            });
            if (hasUpdates) {
                const result = await this.rowUpdateSaveRequested(this.mainTableName, row.rowId, dto);
                row.fields.filter(x=> x.initialValue !== x.value).forEach(c => {
                    c.saveSucceded(result !== undefined);
                });
            } else {
                toastInfo("Aucune valeur n'a été modifiée");
            }
            row.editing = false;
        }
    }


    async deleteRow(row: DynTableRow, index: number): Promise<void> {
        const response = await this.rowDeleteRequested(this.mainTableName, row.rowId);
        if (response.message) {
            toastInfo(response.message);
        }
        this.dataSource.splice(index, 1);
    }

    async insertRow(table: IInitRowEditable): Promise<void> {
        await this.newRowAddRequested(table);
        // this.inserting = true;
        // const newCells = this.getNewRowCells();

        // await this.loadListsOptions(newCells);

        // const newRow = new DynTableRow(this.primaryColumnName, 0, newCells, false);
        // // Relaie les demandes d'upload des champs image
        // newRow.imageUploadRequested = async (file: File, inserting: boolean, maxSize: number, rowId: number): Promise<string> => {
        //     return await this.imageUploadRequested(file, inserting, maxSize, rowId);
        // }

        // this.dataSource = [newRow].concat(this.dataSource);
        // newRow.editing = true;
        // this.inserting = true;
        // table.initRowEdit(newRow);
    }

    async defaultNewRowInsert(table?: IInitRowEditable): Promise<void> {
        this.inserting = true;
        const newCells = this.getNewRowCells();

        await this.loadListsOptions(newCells);

        const newRow = new DynTableRow(this.primaryColumnName, 0, newCells, false);
        // Relaie les demandes d'upload des champs image
        newRow.imageUploadRequested = async (file: File, inserting: boolean, maxSize: number, rowId: number): Promise<string> => {
            return await this.imageUploadRequested(file, inserting, maxSize, rowId);
        }

        if (this.onBlankRowInserted) {
            this.onBlankRowInserted(newRow);
        }

        this.dataSource = [newRow].concat(this.dataSource);
        newRow.editing = true;
        this.inserting = true;
        table?.initRowEdit(newRow);
    }

    onRowEditCancel(row: DynTableRow, index: number) {
        if (row.rowId === 0) {
            this.dataSource = this.dataSource.slice(1);
            this.inserting = false;
        } else {
            row.editing = false;
            row.fields.filter(x=> x.initialValue !== x.value).forEach(c => {
                c.value = c.initialValue;
            });
        }
    }

    onShowErrors(row: DynTableRow): void {

    }

    exportPdf() {
        import('jspdf').then((jsPDF) => {
            import('jspdf-autotable').then((x) => {
                const doc = new jsPDF.default('p', 'px', 'a4');
                var pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
                doc.text(this.documentTitle, pageWidth / 2, 40, {align: 'center'});
                const exportColumns = this.columns.map((col) => ({ title: col.headerLabel, dataKey: col.id }));
                const rowsMap = this.dataSource.map(x=> x.values());
                (doc as any).autoTable(exportColumns, rowsMap, {startY: 60});
                doc.save(`${this.documentName}.pdf`);
            });
        });
    }

    async newPageRequested(e: any): Promise<void> {
        const s = Container.get(DyntService);

        if (e.rows !== this.paginator!.pageItemsCount) {
            this.paginator!.pageItemsCount = e.rows;
            const p = Container.get(PaginationService);
            await p.updatepageSize(this.contextTableUserId, e.rows);
        }

        const pageIndex = e.first / this.paginator!.pageItemsCount;
        const px = await s.contextedDataSet(this.sourceViewName, pageIndex, undefined, undefined, this.paginator?.pageItemsCount);
        this.setDataSource(px.viewData);
        this.paginator!.pageIndex = pageIndex;
        this.paginator?.setPageReport(this.dataSource.length);
    }

    selectedRowChange(e: TableRowSelectEvent): void {
        this.selectedRowChanged(e.data);
    }
}