import { Container } from 'typedi';
import { SvgDOM } from './svg-dom';
import { FloorModelEnum } from "src/app/core/model/data-model/enums/floor-model-enum";
import { EntitiesFormModel } from "../../../../blueprint-viewer-side-panel/subitems/properties/model/entities-form-model";
import { BpSvgPeopleLabel } from "../../../../svg-entities/model/bp-svg-people-label";
import { FloorBlueprint } from "../floor-blueprint";
import { EditableLayer } from "./editable-layer";
import { EntitiesSelectionSet } from "./entities-selection-set";
import { InteractionCommand } from "./interaction-command";
import { LayerInteraction } from "./layer-interaction";
import { SelectionInteraction } from "./selection-interaction";
import { Point } from 'src/app/core/model/geometry-model/point.model';
import { PeopleUpdateGizmoVM } from '../../../subitems/gizmos/people-update-gizmo/model/people-update-gizmo-vm';
import { WorkplaceService } from 'src/app/core/services/backend-services/workplace-service';

export class PeopleInteraction implements LayerInteraction {
    layerId = FloorModelEnum.PeopleLabels;
    layerName: string = "NA";
    selectionSet: EntitiesSelectionSet = new EntitiesSelectionSet();
    isEditable: boolean;

    floorBlueprint: FloorBlueprint;
    editableLayers: EditableLayer[] = [];
    currentCommand: InteractionCommand;

    selectionInteraction: SelectionInteraction | undefined;
    selectionCount: number = 0;
    updateGizmo: PeopleUpdateGizmoVM;
    // TODO: monter un properties form
    propertiesForm: EntitiesFormModel | undefined;

    constructor(command: InteractionCommand, blueprint: FloorBlueprint, editableLayers: EditableLayer[]) {
        this.floorBlueprint = blueprint;
        this.editableLayers = editableLayers;
        this.currentCommand = command;

        this.isEditable = editableLayers.find(x=> x.layerId === this.layerId) != null;

        const layer = editableLayers.find(x=> x.layerId === this.layerId);
        if (layer) {
            this.layerName = layer.layerName;
        }
        
        this.updateGizmo = new PeopleUpdateGizmoVM();
        this.listenToGizmoOverlayMouseDown();
    }

    async activate(labels: BpSvgPeopleLabel[], toRemove: boolean): Promise<void> {
        this.selectionSet.addOrRemove(labels, !toRemove);
        //this.selectionSet.setChanged = () => this.onSelectionSetChanged();

        this.updateGizmo.show(this.selectionSet);
    }

    deactivate(): void {
        throw new Error('Method not implemented.');
    }

    abort(): void {
        if (this.currentCommand.isPeopleLabelTranslationCommand()) {
            this.updateGizmo.cancelTranslation();
            return;
        }
    }

    async deleteSelectedItems(): Promise<void> {
        if (this.selectionSet != null) {
            // const ids = this.selectionSet.items.map(x=> x.floorDataId);
            // const s = Container.get(PeopleLocationService);
            // const result = await s.deleteAllocations(ids);
            // if (result != null) {
            //     // Dans tous les cas on actualise les références d'allocation sur les positions de travail
            //     this.selectionSet.items.forEach(label => {
            //         const workplaces = this.floorBlueprint.layersController.workplaceLabels(this.floorBlueprint.topMostTaskId());
            //         const workplace = workplaces.find(x=> x.floorDataId === label.parentId);
            //         if (workplace) {
            //             const index = workplace.workplaceAllocations.findIndex(x=> x.dataSet.peLoFloorDataId === label.floorDataId);
            //             if (index > -1) {
            //                 workplace.workplaceAllocations.splice(index, 1);
            //             }
            //         }
            //     });

            //     if (this.floorBlueprint.hasPlanningTask()) {
            //         // On est dans une étude, on actualise les états
            //         this.selectionSet.items.forEach(label => {
            //             const resultItem = result.find(x => x.id === label.floorDataId);
            //             if (resultItem?.dataStateId == null) {
            //                 // Il n'y a pas de dataState, l'étiquette est supprimée du plan
            //                 this.floorBlueprint.layersController.peopleLabelsLayer(this.floorBlueprint.topMostTaskId())?.remove(label.floorDataId);
            //             } else {
            //                 // Il y a un dataState, on l'actualise
            //                 label.dataStateId = resultItem.dataStateId;
            //             }
            //         });
            //     } else {
            //         // On n'est pas dans une étude, on supprime la position de travail
            //         this.selectionSet.items.forEach(label => {
            //             this.floorBlueprint.layersController.peopleLabelsLayer(this.floorBlueprint.topMostTaskId())?.remove(label.floorDataId);
            //         });
            //     }
            // }

            // this.selectionSet.clear();
            // this.raiseSelectionDeleted();
        }
    }

    beginInsert(args: any): void {
        throw new Error('Method not implemented.');
    }
    
    listenToGizmoOverlayMouseDown(): void {
        this.updateGizmo.overlayMouseDown = (e: MouseEvent) => {
            this.currentCommand.set(InteractionCommand.peopleLabelTranslateCommand);
            const hitPoint = SvgDOM.getPointPosition(e.clientX, e.clientY);
            if (hitPoint) {
                if (this.selectionSet.count === 1 ) {
                    const uniqueLabel = (this.selectionSet.items[0] as BpSvgPeopleLabel)
                    const workplaces = this.floorBlueprint.layersController.workplaceLabels(this.floorBlueprint.topMostTaskId());
                    const workplace = workplaces.find(x=> x.floorDataId === uniqueLabel.parentId);
                    if (workplace) {
                        this.updateGizmo.initializeTranslation(hitPoint, workplace);
                    }
                }
            }
        }
    }

    refreshThemeNeeded?: (() => void) | undefined;
    askForThemerefresh(): void {
        throw new Error('Method not implemented.');
    }

    selectionDeleted?: (() => void) | undefined;
    raiseSelectionDeleted(): void {
        if (this.selectionDeleted) {
            this.selectionDeleted();
        }
    }

    async mouseDown(e: MouseEvent, hitPoint: Point): Promise<boolean> {
        return false;
    }

    mouseMove(e: MouseEvent, hitPoint: Point): void {
        if (this.currentCommand.isPeopleLabelTranslationCommand()) {
            // Déplace l'étiquette
            this.updateGizmo.translate(hitPoint);
            return;
        }
    }

    async mouseUp(e: MouseEvent): Promise<void> {
        // Enregistre le déplacement
        if (this.currentCommand.isPeopleLabelTranslationCommand()) {
            this.currentCommand.clear();
            
            const uniqueLabel = (this.selectionSet.items[0] as BpSvgPeopleLabel)
            const initialPosition = uniqueLabel.initialTextPosition;
            const currentPosition = uniqueLabel.text.position();
            const finalDeltaTranslation = new Point(currentPosition.x - initialPosition.x, currentPosition.y - initialPosition.y).vector();
            const s = Container.get(WorkplaceService);
            const result = await s.updatePosition(uniqueLabel.floorDataId, finalDeltaTranslation);
            if (result != null) {
                if (this.floorBlueprint.hasPlanningTask()) {
                    // S'il y a une étude chargée, la translation a nécessairement eu lieu sur les étiquettes de l'étude
                    // il faut donc actualiser le floorDataState
                    uniqueLabel.dataStateId = result[0].dataStateId;
                }
            } else {
                this.updateGizmo.cancelTranslation();
            }
        }
    }

    async keyDown(e: KeyboardEvent): Promise<void> {
    }
}