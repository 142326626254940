import { FloorModelView } from "src/app/core/model/data-model/views/floor-model-view";

export class GrantedLayer {
    layer: FloorModelView;
    readGranted: boolean;
    updateGranted: boolean;

    constructor(layer: FloorModelView) {
        this.layer = layer;
        this.readGranted = layer.flMoReadGrantId != null;
        this.updateGranted = layer.flMoUpdateGrantId != null;
    }
}