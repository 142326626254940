import { ZScopeTable } from '../../db-model/tables/z-scope-table';
import { DefinedModelBase } from '../defined-model-base';

export class ZScope extends DefinedModelBase {
    scId!: number;
    scName!: string;

    constructor(dtoData: any) {
        super(dtoData, [ZScopeTable]);
    }
}