import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { ScheduleHeaderComponent } from '../schedule-header/schedule-header.component';
import { MVVMEventDestroyLifeCycle } from 'src/app/components-lib/shared-model/mvvm-events-destroy-lifecycle';
import { GanttScheduleBaseVM } from '../../model/gantt-schedule-base-vm';
import { GanttRootProjectContainerComponent } from '../gantt-root-project-container/gantt-root-project-container.component';

@Component({
  selector: 'xc-gantt-schedule-base',
  standalone: true,
  imports: [ScheduleHeaderComponent, GanttRootProjectContainerComponent],
  templateUrl: './gantt-schedule-base.component.html',
  styleUrl: './gantt-schedule-base.component.scss'
})
export class GanttScheduleBaseComponent extends MVVMEventDestroyLifeCycle {
  @Input() override model: GanttScheduleBaseVM | undefined;

  @ViewChild('scheduleContainer') scheduleContainer: ElementRef | undefined;
  @ViewChild('scheduleHScroller') scheduleHScroller: ElementRef | undefined;

  headerMouseWheel(e: WheelEvent) {
    if (this.model) {
      const oldClientWidth = this.scheduleHScroller?.nativeElement.scrollWidth;
      const scrollLeftPrecent = this.scheduleHScroller?.nativeElement.scrollLeft / oldClientWidth;
      this.model.calculateGrid(-e.deltaY / 1000);
      // Replace le scroll dans la même position (à peut près)
      // TODO : à améliorer pour obtenir une position vraiment fixe par rapport à la souris
      // On met ça dans un timeout pour que ça prenne place après le recalcul de la grille
      setTimeout(() => {
        const newClientWidth = this.scheduleHScroller?.nativeElement.scrollWidth;
        const clientWidthDelta = newClientWidth * scrollLeftPrecent;
        this.scheduleHScroller!.nativeElement.scrollLeft = clientWidthDelta;
      }, 0);
    }
  }

  contentMouseWheel(e: WheelEvent) {
    e.preventDefault();
    if (this.scheduleHScroller) {
      this.scheduleHScroller.nativeElement.scrollLeft += e.deltaY;
      //this.model?.viewScrolled(e.deltaY);
    }
  }
}
