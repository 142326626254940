import { SvgEntity } from "src/app/core/model/svg-model/svg-entity.model";
import { DxfSvgEntity } from "./dxf-svg-entity";
import { Point } from "src/app/core/model/geometry-model/point.model";
import { DxfSvgTspan } from "./dxf-svg-tspan";
import { Vector } from "src/app/core/model/geometry-model/vector.model";
import { SvgEntityTypesEnum } from "src/app/core/model/svg-model/svg-entity-type-enum";
import { SvgEntityPoint } from "../../../shared/gizmos/model/svg-entity-point";
import { SvgEntityParser } from "../../../shared/gizmos/model/svg-entity-parser";
import { SvgEntityPointStyleEnum } from "../../../shared/gizmos/model/svg-entity-point-style-enum";

export class DxfSvgText extends DxfSvgEntity {
    x: number | undefined;
    y: number | undefined;
    fontSize: number = 1;
    originalFontSize: number = 1;
    textAnchor: string | undefined = "start";
    text: string | undefined;
    tSpans: DxfSvgTspan[] = [];

    constructor(dtoData: any) {
        super(dtoData);
        this.x = SvgEntity.getNumber(dtoData, "x");
        this.y = SvgEntity.getNumber(dtoData, "y");
        this.textAnchor = SvgEntity.getValue(dtoData, "textAnchor", "start", false);
        this.fontSize = SvgEntity.getNumber(dtoData, "fontSize", 1);
        this.originalFontSize = this.fontSize;
        this.text = SvgEntity.getValue(dtoData, "text", undefined, false);
        if (dtoData.tSpans) {
            dtoData.tSpans.forEach((ts: any) => {
                this.tSpans.push(new DxfSvgTspan(ts));
            });
        }

        // On annule l'effet du stroke provenant du calque
        this.strokeWidth = 0;
    }

    position(): Point {
        return new Point(this.x, this.y);
    }

    protected override getSelectablePoints(): SvgEntityPoint[] {
        return SvgEntityPoint.toStyle([this.position()], SvgEntityPointStyleEnum.insert, this.entityId);
    }

    protected override getStatement(): string {
        let value: string | undefined = "";
        if (this.tSpans.length > 0) {
            this.tSpans.forEach(ts => {
                value += ts.statement;
            });
        } else {
            value = this.text;
        }

        const attributesStatement = SvgEntityParser.getAttributesStatement([["x", this.x], ["y", this.y], ["font-size", this.fontSize], ["text-anchor", this.textAnchor], ["fill", this.fill]], this.transform);
        return SvgEntityParser.getTagStatement("text", attributesStatement, value);
    }
    
    translate(delta: Vector): void {
        const c = new Point(this.x, this.y);
        const cn = c.translate(delta);
        this.x = cn.x;
        this.y = cn.y;
        
        this.tSpans.forEach(ts => {
            ts.translate(delta);
        });
    }

    static fromValues(layerId: number, position: Point, text: string): DxfSvgText {
        return new DxfSvgText({entityType: SvgEntityTypesEnum.text, entityId: 0, layerId: layerId, x: position.x, y: position.y, textAnchor: "start", fontSize: 0.1, stroke: "none", fill: "black", text: text});
    }
}