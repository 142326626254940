import { FormsModule } from '@angular/forms';
import { WallFormVM } from './../../model/wall-form-vm';
import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MaterialModule } from 'src/app/core/material.module';

@Component({
  standalone: true,
  imports: [CommonModule, MaterialModule, FormsModule],
  selector: 'xc-wall-properties-form',
  templateUrl: './wall-properties-form.component.html',
  styleUrls: ['./wall-properties-form.component.scss']
})
export class WallPropertiesFormComponent implements OnInit {
  @Input() model: WallFormVM | undefined;

  ngOnInit(): void {
  }
}
