import { TablesNamesEnum } from 'src/app/core/model/db-model/tables-names-enum';
import { EquipmentCatalogTable } from 'src/app/core/model/db-model/tables/equipment-catalog-table';
import { EquipmentCategoryReportDTO } from './dto/equipment-category-report-dto';
import { ApiControllers, ApiEndpoints } from './../api-endpoints';
import { Service } from "typedi";
import { ApiService } from "../api-service";
import { EquipmentCatalogDbView } from '../../model/db-model/views/equipment-catalog-db-view';
import { EquipmentCatalogViewSet } from '../../model/data-model/view-set/equipment-catalog-view-set';
import { EquipmentItem } from '../../model/data-model/tables/equipment-item';
import { HttpParams } from '@angular/common/http';
import { EquipmentCatalog } from '../../model/data-model/tables/equipment-catalog';
import { ViewsNames } from '../../model/db-model/views-names-enum';

@Service({ global: true })  
export class EquipmentService extends ApiService {
  private catalogEndpoint: string = `${ApiControllers.floorDataEquipments}/catalog`;

  constructor() {
        super();
    }

    async getEquipmentCatalogView(): Promise<EquipmentCatalogViewSet[]> {
        const result = await this.getAsync<any[]>(this.dynt(EquipmentCatalogDbView.databaseTableName));
          if (result != null) {
            return result.payload.map(item => {
                return new EquipmentCatalogViewSet(item);
            })
          }
          return [];
    }

    async getEquipmentItems(categoryId: number, providerId: number): Promise<EquipmentItem[]> {
        const result = await this.getAsync<any[]>(this.endPoint(ApiEndpoints.floorDataEquipment(categoryId, providerId)));
          if (result != null) {
            return result.payload.map(item => {
                return new EquipmentItem(item);
            })
          }
          return [];
    }

    async getEquipmentCategoryReport(categoryId: number): Promise<EquipmentCategoryReportDTO | null> {
        const result = await this.getAsync<any[]>(this.endPoint(ApiEndpoints.floorDataEquipmentReport(categoryId)));
          if (result != null && result.payload != null) {
            return new EquipmentCategoryReportDTO(result.payload);
        }
          return null;
    }
    
    async getEquipmentCatalogItem(productId: string): Promise<EquipmentCatalog | null> {
      let params = new HttpParams();
      params = params.set('primaryColumnName', EquipmentCatalogTable.eqCgProductId);
      params = params.set('primaryFilterId', productId);
      return await this.getSingleFromArrayAsync<EquipmentCatalog>(TablesNamesEnum.EquipmentCatalog, this.dynt(EquipmentCatalogDbView.databaseTableName), undefined, params);
    }
    
    async getEquipmentsCatalog(floorCatalogId: number[]): Promise<EquipmentCatalogViewSet[]> {
      let params = new HttpParams();
      floorCatalogId.forEach(id => {
        params = params.append('floorCatalogId', id);
      });

      const result = await this.getAsync<any[]>(this.endPoint(this.catalogEndpoint), undefined, params);
      if (result != null) {
        return result.payload.map(item => {
            return new EquipmentCatalogViewSet(item);
        })
      }
      return [];
    }

    async updateCatalogItem(id: number, productId: string, name: string, minQty: number, unitPrice: number): Promise<string | null> {
      const dto: any = {};
      dto["TableName"] = EquipmentCatalogTable.databaseTableName;
      dto[EquipmentCatalogTable.eqCgId] = id;
      dto[EquipmentCatalogTable.eqCgProductId] = productId;
      dto[EquipmentCatalogTable.eqCgName] = name;
      dto[EquipmentCatalogTable.eqCgMinQty] = minQty;
      dto[EquipmentCatalogTable.eqCgUnitPrice] = unitPrice;
      const result = await this.patchAsync(this.endPoint(ApiControllers.dynT), dto);
      if (result != null) {
          return result.message as string;
      }
      return null;
  }
}