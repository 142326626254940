@if (model) {
<div class="prop-main">
    @for (p of model.browsableFields; track p) {
  <div class="prop-item">
        <div class="prop-key">{{p.definition.coViColumnDisplayName}}</div>
        <div class="prop-value">
@switch (p.fieldType) {
            @case (model.PropertyTypeEnum.boolean) {
  <xc-boolean-property class="boolprop" [model]="$any(p)"></xc-boolean-property>
}
            @case (model.PropertyTypeEnum.string) {
  <xc-string-property class="stringprop" [model]="$any(p)"></xc-string-property>
}
            @case (model.PropertyTypeEnum.list) {
  <xc-select-property class="selectprop" [model]="$any(p)"></xc-select-property>
}
            @case (model.PropertyTypeEnum.number) {
  <xc-number-property class="numberprop" [model]="$any(p)"></xc-number-property>
}
            @case (model.PropertyTypeEnum.date) {
  <xc-date-property class="dateprop" [model]="$any(p)"></xc-date-property>
}
        }
</div>
    </div>
}
</div>
}
