import { FloorLayoutView } from 'src/app/core/model/data-model/views/floor-layout-view';
import { FloorLayoutDbView } from 'src/app/core/model/db-model/views/floor-layout-db-view';
import { Floor } from '../../../model/data-model/tables/floor';

export class FloorLayoutTypeDTO extends Floor {
    layoutTypes: FloorLayoutView[] = [];

    constructor(dtoData: any) {
        super(dtoData);
        if (dtoData[FloorLayoutDbView.databaseTableName]) {
            dtoData[FloorLayoutDbView.databaseTableName].forEach((e: any) => {
                this.layoutTypes.push(new FloorLayoutView(e));
            });
        }
    }
}