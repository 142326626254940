import { BpSvgRoom } from "../bp-svg-room";
import { BlueprintLayer } from "./blueprint-layer";

export class BlueprintRoomLayer extends BlueprintLayer<BpSvgRoom> {
    constructor(dtoData: any, taskId: number) {
        super(dtoData, taskId);
    }

    resetRoomsColor(): void {
        (this.typedData()).forEach(r => {
            r.setFill("#ffffff");
        });
    }

    showRooms(display: boolean): void {
        (this.typedData()).forEach(r => {
            r.show(display);
        });
    }
}
