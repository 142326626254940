import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InventoryStateVM } from '../../model/inventory-state-vm';
import { DonutChartWrapperComponent } from 'src/app/ui/shared/charts/donut/view/donut-chart-wrapper/donut-chart-wrapper.component';
import { MatTableModule } from '@angular/material/table';

@Component({
  selector: 'xc-inventory-state',
  standalone: true,
  imports: [CommonModule, MatTableModule, DonutChartWrapperComponent],
  templateUrl: './inventory-state.component.html',
  styleUrls: ['./inventory-state.component.scss']
})
export class InventoryStateComponent {
  @Input() model: InventoryStateVM | undefined;
}
