import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SvgTimelineVM } from '../../model/svg-timeline-vm';
import { SVGTextEllipsisDirective } from 'src/app/components-lib/directives/svg-text-ellipsis-directive';

@Component({
  selector: 'g[xc-svg-timeline]',
  standalone: true,
  imports: [CommonModule, SVGTextEllipsisDirective],
  templateUrl: './svg-timeline.component.svg',
  styleUrls: ['./svg-timeline.component.scss']
})
export class SvgTimelineComponent {
  @Input() model: SvgTimelineVM | undefined;
}
