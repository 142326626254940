@if (model) {
    <div class="h-full">
        @switch (model.selectedChartType?.id) {
            @case (model.contentTypeEnum.buildingaxono) {
                <xc-building-axono-wrapper class="chart100" [model]="$any(model.currentView)"/>
            }
            @case (model.contentTypeEnum.donutchart) {
                @if (model.selectedOption?.id === model.optionTypesEnum.referential) {
                    <xc-properties-editor [model]="$any(model.currentView)"/>
                } @else {
                    <xc-donut-chart-wrapper class="chart75" [model]="$any(model.currentView)"/>
                }
            }
            @case (model.contentTypeEnum.buildingstack) {
                <xc-stack-chart-wrapper class="chart75 v-center" [model]="$any(model.currentView)"/>
            }
        }
    </div>
}