import { SidePanelTabs } from "./side-panel-tabs";
import { BlueprintGWTabEnum } from "./blueprint-gw-tab-enum";
import { ImportsPanel } from "./imports-panel";
import { PictosPanel } from "./pictos-panel";
import { DxfImport } from "src/app/core/model/data-model/tables/dxf-import";
import { DxfLayerDTO } from "src/app/core/services/backend-services/dto/dxf-layer-dto";
import { SvgEntityTypesEnum } from "src/app/core/model/svg-model/svg-entity-type-enum";
import { DxfSvgDef } from "../svg/dxf-svg-def";
import Container from "typedi";
import { DxfService } from "src/app/core/services/backend-services/dxf-service";
import { CadConverterLayersPanelVM } from "../cad-converter-layers-panel-vm";
import { TabItem } from "src/app/components-lib/shared-model/tab-item-model";
import { EventListener } from "src/app/core/events/event-listener";
import { Tabs } from "src/app/components-lib/shared-model/tabs-model";
import { logError } from "src/app/core/services/logging-service";

export class CadConverterSidePanel extends EventListener {
    tabsInvariantId: string = "3bf8ea3d-bd38-43b5-b4ff-c632f9b9347b";
    tabs: SidePanelTabs = new SidePanelTabs(this.tabsInvariantId);
    selectedSidePanel: BlueprintGWTabEnum | string = BlueprintGWTabEnum.imports;
    SidePanelEnum = BlueprintGWTabEnum;
    importsPanel: ImportsPanel |undefined;
    layersPanel: CadConverterLayersPanelVM = new CadConverterLayersPanelVM;
    pictosPanel: PictosPanel = new PictosPanel();
    selectedImportChange?: (selectedImport: DxfImport, layers: DxfLayerDTO[]) => void;
    waiting: boolean = false;

    constructor() {
        super("18ac8cc4-aca3-434b-b896-54d3d1513a70");

        this.addEventListener(`${this.tabsInvariantId}.${Tabs.selectedTabChanged}`, async (item: TabItem): Promise<void> => {
            await this.setSelectedPanel(item.index);
        });

        this.tabs.initialize();
        this.setSelectedPanel(BlueprintGWTabEnum.imports);
    }

    async setSelectedPanel(selectedSidePanel: BlueprintGWTabEnum | string): Promise<void> {
        this.selectedSidePanel = selectedSidePanel;
        switch (selectedSidePanel) {
            case BlueprintGWTabEnum.imports:
                if (!this.importsPanel) {
                    this.importsPanel = new ImportsPanel();
                    await this.importsPanel.loadImports();
                    this.importsPanel.selectedImportChange = async (selectedImport: DxfImport) => {
                        this.waiting = true;
                        const s = Container.get(DxfService);
                        const layers = await s.getImportLayers(selectedImport.dxImId);
                        if (layers.length > 0) {
                            layers[0].current = true;
                        }
            
                        this.layersPanel.layers = layers;

                        const l0 = layers.find(x=> x.dxLaName === "0");
                        if (l0) {
                            const defs = l0.entities.filter(x=> x.entityType === SvgEntityTypesEnum.g) as DxfSvgDef[];
                            this.pictosPanel.defs = defs.sort((a, b) => a.id.localeCompare(b.id));
                        }

                        this.waiting = false;

                        if (this.selectedImportChange) {
                            this.selectedImportChange(selectedImport, layers);
                        } else {
                            logError("CadConverterSidePanel.selectedImportChange n'est pas écouté");
                        }
                    }
                }
                break;
            case BlueprintGWTabEnum.layers:
                break;
            case BlueprintGWTabEnum.pictos:
                break;
            default:
                break;
        }
    }
}