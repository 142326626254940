import { FieldSet } from './../../shared-model/field-set';
import { Field } from '../../shared-model/field';

export class DynTableRow extends FieldSet {
    mouseOver: boolean = false;

    constructor(primaryColumnName: string, rowId: any, cells: Field<any>[], readOnlyByScope: boolean) {
        super();
        this.setPrimary(primaryColumnName, rowId);
        this.setFields(cells, readOnlyByScope);
    }

    mouseEnter(): void {
        this.mouseOver = true;
    }

    mouseLeave(): void {
        this.mouseOver = false;
    }
}