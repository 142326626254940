import { NumberField } from './../../../shared-model/number-field';
import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from 'src/app/core/material.module';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'xc-number-property',
  standalone: true,
  imports: [CommonModule, MaterialModule, FormsModule],
  templateUrl: './number-property.component.html',
  styleUrls: ['./number-property.component.scss']
})
export class NumberPropertyComponent {
  @Input() model: NumberField | undefined;
}
