import { BusinessUnit } from "src/app/core/model/data-model/tables/business-unit";
import { SimulationBusinessUnit } from "src/app/core/model/data-model/tables/simulation-business-unit";
import { SimulationOccupation } from "src/app/core/model/data-model/tables/simulation-occupation";

export class SimulationOccupationDTO extends SimulationOccupation {
    simulationBusinessUnit: SimulationBusinessUnit;
    businessUnit: BusinessUnit;

    constructor(dtoData: any) {
        super(dtoData);

        this.simulationBusinessUnit = new SimulationBusinessUnit(dtoData);
        this.businessUnit = new BusinessUnit(dtoData);
    }

}