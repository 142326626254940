import { DefinedModelBase } from '../defined-model-base';
import { EquipmentCatalogTable } from "../../db-model/tables/equipment-catalog-table";

export class EquipmentCatalog extends DefinedModelBase {
    eqCgId!: number;
    eqCgProductId!: string;
    eqCgName!: string;
    eqCgDescription!: string | null;
    eqCgProviderId!: number;
    eqCgCategoryId!: number;
    eqCgFloorCatalogId!: number;
    eqCgUnitPrice!: number;
    eqCgMinQty!: number;
    eqCgAvgQty!: number;

    constructor(dtoData: any) {
        super(dtoData, [EquipmentCatalogTable]);
    }
}