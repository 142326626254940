<div id="dialogContainer">
  <h3 mat-dialog-title class="header">
    Ajout d'un nouveau contributeur
  </h3>
  <mat-dialog-content class="mat-typography">
    <form [formGroup]="addAppUserFormGroup">
      <mat-form-field class="full-width" appearance="outline">
        <mat-label>Collaborateur</mat-label>
        <input
          type="text"
          matInput
          placeholder="Commencez à saisir le nom"
          [matAutocomplete]="auto"
          formControlName="directoryCtrl"
          (keyup)="data.onSearchChange($event)"
        />
        <mat-autocomplete
          #auto="matAutocomplete"
          requireSelection
          [displayWith]="data.displayFn"
        >
          @for (option of data.filteredDirectory; track option) {
          <mat-option [value]="option">
            {{ option.diFirstName }} {{ option.diLastName }}
          </mat-option>
          }
        </mat-autocomplete>
      </mat-form-field>
    </form>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button
      class="create-command"
      mat-button
      color="primary"
      [disabled]="!addAppUserFormGroup.valid"
      (click)="submitForm()"
    >
      Ok
    </button>
    <button mat-button color="warn" (click)="cancel()">Annuler</button>
  </mat-dialog-actions>
</div>
