import { SelectItem } from "primeng/api";
import { SelectButtonOptionClickEvent } from "primeng/selectbutton";
import { EventListener } from "src/app/core/events/event-listener";
import { readableUUID } from "src/app/core/events/event-listener-uuid";
import { SvgEntityTypesEnum } from "src/app/core/model/svg-model/svg-entity-type-enum";

export class InsertOptions extends EventListener {
    options: SelectItem[] = [];
    selectedOption: SelectItem[] | null = null;
    static selectedInsertOptionChangeEvent: string = "selectedInsertOptionChangeEvent";
    static clearInsertOptionEvent: string = "clearInsertOptionEvent";

    constructor() {
        super(readableUUID(InsertOptions.name));

        this.options.push({label: "Ligne", value: SvgEntityTypesEnum.line, icon: "line"});
        this.options.push({label: "Cercle", value: SvgEntityTypesEnum.circle, icon: "grip_center"});
        this.options.push({label: "Ellipse", value: SvgEntityTypesEnum.ellipse, icon: "ellipse"});
        this.options.push({label: "Polyligne", value: SvgEntityTypesEnum.path, icon: "path"});

        this.addEventListener(InsertOptions.clearInsertOptionEvent, () => {
            this.selectedOption = null;
        });
    }

    async onGripOptionClick(e: SelectButtonOptionClickEvent): Promise<void> {
        await this.emitEventAsync(InsertOptions.selectedInsertOptionChangeEvent, this.selectedOption);
    }
}