@if (model) {
<div id="roomLayoutTypeContainer">
    <!-- Panneau latéral -->
    <!-- <div id="red-side" class="red-panel">
        <div class="header info">
            <span class="property">Surface type :</span>
            <span class="value stringValue">{{model.name}}</span>
        </div>
        <div class="infos">
            <div class="info">
                <span class="property">Surface tot. :</span>
                <span class="value numberValue">{{model.selectedTreeNode?.lt?.roLaTyViTotalArea | number:'1.0-2':'fr-FR'}}</span>
            </div>
            <div class="info">
                <span class="property">Surface min. :</span>
                <span class="value numberValue">{{model.selectedTreeNode?.lt?.roLaTyViMinArea | number:'1.0-2':'fr-FR'}}</span>
            </div>
            <div class="info">
                <span class="property">Surface max. :</span>
                <span class="value numberValue">{{model.selectedTreeNode?.lt?.roLaTyViMaxArea | number:'1.0-2':'fr-FR'}}</span>
            </div>
            <div class="info">
                <span class="property">Surface moy. :</span>
                <span class="value numberValue">{{model.selectedTreeNode?.lt?.roLaTyViAvgArea | number:'1.0-2':'fr-FR'}}</span>
            </div>
            <div class="info">
                <span class="property">Occurences :</span>
                <span class="value numberValue">{{model.selectedTreeNode?.lt?.roLaTyViCount | number:'1.0':'fr-FR'}}</span>
            </div>
        </div> -->

        <!-- Options de graphique -->
        <!-- <mat-radio-group class="reports" [(ngModel)]="model.selectedChart" (change)="model.selecteChartChange($event)" aria-label="Choisissez un rapport">
            <mat-radio-button *ngFor="let c of model.charts" [value]="c">{{c.name}}</mat-radio-button>
        </mat-radio-group>
    </div> -->
    <!-- Panneau principal -->
    @if (model.selectedTab) {
<div id="red-main" class="red-panel">
        <div id="viewOptions">
            <mat-tab-group (selectedTabChange)="model.selectedTabChange($event)" fitInkBarToContent mat-stretch-tabs="false" mat-align-tabs="center">
                @for (c of model.tabs; track c) {
  <mat-tab [label]="c.name">
                    <ng-template matTabContent>
                        <div id="viewContent">
@switch (c.contentType) {
                            @case (model.tabPageContentTypeEnum.donutchart) {
  <xc-donut-chart-wrapper class="chart75" [model]="$any(c.content)"/>
}
                        }
</div>
                    </ng-template>
                </mat-tab>
}
            </mat-tab-group>
        </div>
    </div>
}

    <!-- <div id="red-main" class="red-panel" *ngIf="model.selectedChart">
        <ng-container [ngSwitch]="model.selectedChart.contentType">
            <xc-donut-chart-wrapper class="chart" *ngSwitchCase="model.ChartTypeEnum.donutchart" [model]="$any(model.selectedChart)"></xc-donut-chart-wrapper> -->
            <!-- <xc-dyn-grid class="table" *ngSwitchCase="model.ChartTypeEnum.None" [model]="$any(model.selectedChart).data" class="center-center"></xc-dyn-grid> -->
        <!-- </ng-container>
    </div> -->
</div>
}


