@if (model) {
<div>
@switch (model.selectedSidePanel) {
    @case (model.SidePanelEnum.imports) {
  <xc-imports-panel [model]="model.importsPanel"/>
}
    @case (model.SidePanelEnum.layers) {
  <xc-cad-converter-layers-panel [model]="model.layersPanel"/>
}
    @case (model.SidePanelEnum.pictos) {
  <xc-pictos-panel [model]="model.pictosPanel"/>
}
}
</div>
}
