import { FloorWallStyleEnum } from 'src/app/core/model/data-model/enums/floor-wall-style-enum';
import { SvgEntity } from 'src/app/core/model/svg-model/svg-entity.model';
import { BpSvgLine } from '../../bp-svg-core-model/bp-svg-line';

export class BpSvgWall extends BpSvgLine {
    wallStyle: number = FloorWallStyleEnum.Standard;
    isSpaceBound: boolean = true;
    isTaskZoneBound: boolean = false;
    defaultStyleColor: string = "#ffffff";

    constructor(dtoData: any, group: any) {
        super(dtoData);
        if(dtoData){
            this.wallStyle = SvgEntity.getNumber(group, 'xcWallStyle');
            this.stroke = SvgEntity.getValue(group, "stroke");
            this.defaultStyleColor = SvgEntity.getValue(group, "stroke");
            if(this.wallStyle === FloorWallStyleEnum.Virtual){
                this.strokeWidth = 0.01;
            }
            this.floorModelId = SvgEntity.getValue(group, "floorModelId", null, false);
            if (group.id) {
                this.floorDataId = group.id;
            } else {
                if (group.floorDataId) {
                    this.floorDataId = group.floorDataId;
                }
            }
            this.parentId = group.parentId;
            this.sourceId = group.sourceId;
            this.dataStateId = group.dataStateId;
            this.taskId = group.taskId;
            this.isSpaceBound = SvgEntity.getBoolean(group, 'xcSpaceBound', true, false);
            this.isTaskZoneBound = SvgEntity.getBoolean(group, 'xc-task-zone-bound', false, false);
        }
    }

    updateAttributesFromEndPoints(): void {
        this.x1 = this.startPoint.x;
        this.y1 = this.startPoint.y;
        this.x2 = this.endPoint.x;
        this.y2 = this.endPoint.y;
    }

    /**
     * Actualise la valeur de l'attribut à partir d'une valeur qui peut être nulle
     * @param value si la valeur passée est nulle est est fixée par défaut à 0.01
     */
    setWidthAttribute(value: number): void {
        this.strokeWidth = value == null ? 0.01 : value;
    }

    /**
     * Actualise la valeur de l'attribut à partir d'une valeur qui peut être nulle
     * @param value si la valeur est nulle, elle est fixée par défaut à true
     */
    setSpaceBoundAttribute(value: boolean): void {
        this.isSpaceBound = value;
        //this.isSpaceBound = value == null ? true : value === "true";
    }

    setStyle(styleId: number): void {
        // TODO : ça laisse une incohérence dans le jeu de données
        // puisque l'attribut xyWallStyle qui est sur le groupe parent n'est pas actualisé
        this.wallStyle = styleId;
    }

    setColor(color: string): void {
        this.stroke = color;
    }

    resetColor(): void {
        this.stroke = this.defaultStyleColor;
    }
}
