@if (model) {
<div id="importsPanel">
    <mat-form-field appearance="outline" class="drop-down">
        <mat-label>Imports</mat-label>
        <mat-select [(ngModel)]="model.selectedImport" (selectionChange)="model.onSelectedImportChange()">
            @for (i of model.imports; track i) {
  <mat-option [value]="i">{{i.dxImFileName}}</mat-option>
}
        </mat-select>
    </mat-form-field>

    <button mat-button id="importButton"
        aria-label="Importer un DXF"
        matTooltip='Importer un DXF'
        (click)='model.onImportButtonClick()'>
        Importer un fichier DXF
    </button>
</div>
}
