import { Service } from "typedi";
import { EventProcess } from "./event-process";
import { closeErrorsGroup, logError, logInfo, openErrorsGroup } from "../services/logging-service";

@Service({ global: true })  
export class EventCallbackStore {
    processes: EventProcess[] = [];

    addEventListener(listenerId: string, eventName: string, method: any): void {
        const process = this.getProcess(listenerId, eventName);
        if (process) {
            process.method = method;
        } else {
            this.processes.push(new EventProcess(listenerId, eventName, method));
            logInfo(`👂${listenerId} is now listening to ${eventName}`);
        }
    }

    cleanStore(listenerId: string, eventName?: string): void {
        let listenersToRemove = this.processes.filter(x=> x.listenerId === listenerId);
        if (eventName) {
            listenersToRemove = listenersToRemove.filter(x=> x.eventName === eventName);
        }
        openErrorsGroup(`❌ Remove ${listenerId} event listners (${listenersToRemove.length})`);
        for (let i = this.processes.length - 1; i >= 0; i--) {
            const p = this.processes[i];
            let eName = eventName ?? p.eventName;
            if (p.listenerId === listenerId && p.eventName === eName) {
                this.processes.splice(i, 1);
                logInfo(`Remove ${p.eventName}`);
            }
        }
        closeErrorsGroup();
        openErrorsGroup(`Remaining listeners : ${this.processes.length}`);
        this.processes.forEach(p => {
            logInfo(`${p.listenerId} listening to ${p.eventName}`);
        });
        closeErrorsGroup();
    }

    private getProcess(listenerId: string, methodName: string): EventProcess | undefined {
        return this.processes.find(x=> x.listenerId === listenerId && x.eventName === methodName);
    }

    getProcesses(eventName: string): EventProcess[] {
        return this.processes.filter(x=> x.eventName === eventName);
    }
}