import { SimulationArea } from "src/app/core/model/data-model/tables/simulation-area";
import { SimulationFloorAnatomyView } from "src/app/core/model/data-model/views/simulation-floor-anatomy-view";
import { SimulationFloorView } from "src/app/core/model/data-model/views/simulation-floor-view";
import { SimulationAreaTable } from "src/app/core/model/db-model/tables/simulation-area-table";
import { SimulationFloorAnatomyDbView } from "src/app/core/model/db-model/views/simulation-floor-anatomy-db-view";
import { SimulationOccupationDTO } from "./simulation-occupation-dto";
import { ZHierarchyDepthName } from "src/app/core/model/data-model/tables/z-hierarchy-depth-name";
import { SimulationOccupationDbView } from "src/app/core/model/db-model/views/simulation-occupation-db-view";

export class SimulationFloorDTO extends SimulationFloorView {
    // simulation_floor_anatomy_activitystatus_pivot_procedure
    // simulation_floor_anatomy_attribution_pivot_procedure
    // simulation_area_pivot_procedure
    floorAnatomy: SimulationFloorAnatomyView[] = [];
    areas: SimulationArea[] = [];
    sharingGauge!: number;
    occupation: SimulationOccupationDTO[][] = [];
    hierarchyDepthNames: ZHierarchyDepthName[] = [];

    constructor(dtoData: any, hierarchyDepthNames: ZHierarchyDepthName[]) {
        super(dtoData);
        this.hierarchyDepthNames = hierarchyDepthNames;
        dtoData[SimulationFloorAnatomyDbView.databaseTableName].forEach((e: any) => {
            this.floorAnatomy.push(new SimulationFloorAnatomyView(e));
        });
        dtoData[SimulationAreaTable.databaseTableName].forEach((e: any) => {
            this.areas.push(new SimulationArea(e));
        });
        if (dtoData["SharingGauge"]) {
            this.sharingGauge = dtoData["SharingGauge"];
        }
        this.hierarchyDepthNames.forEach(hdn => {
            const data = dtoData[SimulationOccupationDbView.databaseTableName][hdn.hiDeNaDepth.toString()];
            const occ: SimulationOccupationDTO[] = [];
            data.forEach((o: any) => {
                occ.push(new SimulationOccupationDTO(o));
            });
            this.occupation.push(occ);
        });
    }
}