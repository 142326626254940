import { FieldSet } from './../../shared-model/field-set';
import { FieldTypeEnum } from '../../shared-model/field-type-enum';
import { ItemPropertiesSet } from './../../../core/model/data-model/sets/item-properties-set';
import { ZColumnConstraintView } from 'src/app/core/model/data-model/views/z-column-constraint-view';
import { ZColumnView } from 'src/app/core/model/data-model/views/z-column-view';
import { DefinedModelBase } from 'src/app/core/model/data-model/defined-model-base';
import { FieldBuilder } from '../../shared-model/field-builder';
import { Field } from '../../shared-model/field';
import { DbModelUtils } from 'src/app/core/model/static-functions/db-model-utils';
import { ZScopeEnum } from 'src/app/core/model/data-model/enums/z-scope-enum';
import { logError } from 'src/app/core/services/logging-service';

export class PropertyGridVM<T extends DefinedModelBase> {
    tableName: string;
    primaryColumnName: string;
    rowId: any;
    item: T;
    definitions: ZColumnView[] = [];
    constraints: ZColumnConstraintView[] = [];
    fieldSet: FieldSet;
    browsableFields: Field<any>[] = [];

    PropertyTypeEnum = FieldTypeEnum;

    constructor(tableName: string, itemPropertiesSet: ItemPropertiesSet<T>) {
        this.tableName = tableName;
        this.item = itemPropertiesSet.item;
        this.definitions = itemPropertiesSet.columnsDefinitions.sort((a, b) => a.dbCoDisplayOrder - b.dbCoDisplayOrder);
        this.constraints = itemPropertiesSet.columnsConstraints;

        this.primaryColumnName = FieldBuilder.getPrimary(this.definitions);
        this.rowId = this.item.getValue(this.primaryColumnName);

        const scopeColumn = DbModelUtils.scopeColumn(this.item);
        const readOnlyByScope: boolean = (scopeColumn !== undefined && (this.item as any)[scopeColumn] === ZScopeEnum.Application) ;

        this.fieldSet = FieldBuilder.getSetFromModelObject(this.item, this.definitions, this.constraints, readOnlyByScope);
        this.browsableFields = this.fieldSet.fields.filter(x=> x.browsable === true);

        this.listenToSaveRequests();
    }

    saveRequested?:(tableName: string, primaryColumnName: string, rowId: any, columnName: string, value: any) => Promise<string | null>;
    listenToSaveRequests(): void {
        this.fieldSet.saveRequested = async (field: Field<any>) => {
            if (this.saveRequested) {
                const result = await this.saveRequested(this.tableName, this.primaryColumnName, this.rowId, field.colId, field.value);
                field.saveSucceded(result === "U");
            } else {
                logError("PropertyGridVM.saveRequested n'est pas écouté");
            }
        }
}
}