import { Point } from "./point.model";

export class Vector {
    readonly u: number;
    readonly v: number;

    constructor(u: number, v: number)
    {
        this.u = u;
        this.v = v;
    }

    static null(): Vector {
        return new Vector(0, 0);
    }

    equals(item: Vector) : boolean
    {
        if (item == null)
        {
            return false;
        }

        if (this.u === item.u)
        {
            return this.v === item.v;
        }

        return false;
    }

    // Retourne la norme du vecteur
    norm(): number {
        return Math.sqrt(this.u * this.u + this.v * this.v);
    }

    // Retourn le vecteur orthogonal
    getOrthogonalVector(): Vector {
        return new Vector(this.v, -this.u);
    }

    // Retourne le vecteur unitaire
    getUnit(): Vector {
        var norme = this.norm();
        var v1 = this.u / norme;
        var v2 = this.v / norme;

        return new Vector(v1, v2);
    }

    // Retourne le produit vectoriel de deux vecteurs
    crossProductWith(v: Vector): number {
        return this.u * v.v - this.v * v.u;
    }

    // Retourne le produit scalaire de deux vecteurs
    dotProductWith(v: Vector): number {
        return this.u * v.u + this.v * v.v;
    }

    /**
     * Retourne l'angle formé entre deux vecteurs
     * @param v 
     * @returns angle retourné en radians
     */
    angleWith(v: Vector): number {
        const num = this.crossProductWith(v) / (this.norm() * v.norm());
        const num2 = Math.acos(this.dotProductWith(v) / (this.norm() * v.norm()));
        if (num < 0.0)
        {
            return 0.0 - num2;
        }

        return num2;
    }

    isColinearTo(v: Vector): boolean {
        return (v.u * this.v) - (this.u * v.v) === 0;
    }

    plus(v: Vector | Point): Vector {
        if (v instanceof Vector) {
            return new Vector(this.u + v.u, this.v + v.v);
        }
        return new Vector(this.u + v.x, this.v + v.y);
    }

    minus(v: Vector | Point): Vector {
        if (v instanceof Vector) {
            return new Vector(this.u - v.u, this.v - v.v);
        }
        return new Vector(this.u - v.x, this.v - v.y);
    }

    toPoint(): Point {
        return new Point(this.u, this.v);
    }

    scale(k: number): Vector {
        return new Vector(k * this.u, k * this.v);
    }

    rotate(a: number): Vector {
        const sin = Math.sin(a);
        const cos = Math.cos(a);
        const u = this.u * cos + this.v * sin;
        const v = this.v * cos - this.u * sin;
        return new Vector(u, v);
    }

    static fromPoints(p1: Point, p2: Point): Vector {
        return new Vector(p2.x - p1.x, p2.y - p1.y);
    }
}
