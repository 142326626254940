import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CadConvDrawingComponent } from '../cad-conv-drawing/cad-conv-drawing.component';
import { MaterialModule } from 'src/app/core/material.module';
import { CadConvDrawingBoardToolbarComponent } from '../cad-conv-drawing-board-toolbar/cad-conv-drawing-board-toolbar.component';
import { FormsModule } from '@angular/forms';
import { AutoFocusDirective } from 'src/app/components-lib/directives/auto-focus.directive';
import { DrawingBoard } from '../../model/drawing-board';

@Component({
  selector: 'xc-cad-conv-drawing-board',
  standalone: true,
  imports: [CommonModule, FormsModule, CadConvDrawingComponent, MaterialModule, CadConvDrawingBoardToolbarComponent, AutoFocusDirective],
  templateUrl: './cad-conv-drawing-board.component.html',
  styleUrls: ['./cad-conv-drawing-board.component.scss']
})
export class CadConvDrawingBoardComponent {
  @Input() model: DrawingBoard | undefined;
}
