import { PerimeterDetailTable } from "../../db-model/tables/perimeter-detail-table";
import { DefinedModelBase } from "../defined-model-base";

export class PerimeterDetail extends DefinedModelBase {
    peDeId!: number;
    peDePerimeterId!: number;
    peDeFloorId!: number;

    constructor(dtoData: any, tableDef: any[] = []) {
        super(dtoData, [PerimeterDetailTable].concat(tableDef));
    }
}