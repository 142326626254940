export enum TablesSetsEnum {
    DirectoryViewSet = "DirectoryViewSet",
    InventoryTreeSet = "InventoryTreeSet",
    LayerTreeSet = "LayerTreeSet",
    RoomAllocationViewSet = "RoomAllocationViewSet",
    EquipmentCatalogViewSet = "EquipmentCatalogViewSet",
    ContextUIViewSet = "ContextUIViewSet",
    DynviewSet = "DynviewSet",
    WorkplaceAllocationViewSet = "WorkplaceAllocationViewSet",
    PeopleLocationViewSet = "PeopleLocationViewSet"
}