import { FloorTable } from './../../model/db-model/tables/floor-table';
import { ApiControllers, ApiEndpoints } from './../api-endpoints';
import { Service } from "typedi";
import { UsageContextIdEnum } from "../../model/usage-context-id-enum";
import { ApiService } from "../api-service";
import { HttpParams } from '@angular/common/http';
import { Floor } from '../../model/data-model/tables/floor';
import { ThemingDTO } from './dto/theming-dto';
import { Perimeter } from '../../model/data-model/tables/perimeter';
import { DashboardDto } from 'src/app/core/services/backend-services/dto/dashboard-dto';
import { FloorLayoutTypeDTO } from './dto/floor-layout-type-dto';
import { FloorActivityStatusDTO } from './dto/floor-activity-status-dto';
import { FloorAttributionDTO } from './dto/floor-attribution-dto';
import { FloorEquipmentProviderDTO } from './dto/floor-equipment-provider-dto';
import { FloorAllocationDTO } from './dto/floor-allocation-dto';
import { RealEstateView } from './dto/real-estate-view';
import { ViewsNames } from '../../model/db-model/views-names-enum';
import { PerimeterTable } from '../../model/db-model/tables/perimeter-table';
import { PerimeterDetailTable } from '../../model/db-model/tables/perimeter-detail-table';
import { Site } from '../../model/data-model/tables/site';
import { SiteTable } from '../../model/db-model/tables/site-table';
import { BuildingTable } from '../../model/db-model/tables/building-table';
import { Building } from '../../model/data-model/tables/building';

@Service({ global: true })  
export class RealEstateService extends ApiService {
    private createCustomPerimeterEndpoint: string = `${ApiControllers.perimeters}/custom`;
    private createPerimeterGrantEndpoint(perimeterId: number): string { return `${ApiControllers.perimeters}/${perimeterId}/addgrant`};
    private createSiteEndpoint: string = `${ApiControllers.realestate}/site`;
    private createBuildingEndpoint: string = `${ApiControllers.realestate}/building`;
    private createFloorEndpoint: string = `${ApiControllers.realestate}/floor`;

    constructor() {
        super();
    }

    async loadTheming(): Promise<ThemingDTO | null> {
        const result = await this.getAsync<any>(this.endPoint(ApiEndpoints.realEstateTheming));
        if (result != null) {
            return new ThemingDTO(result.payload);
        }
        return null;
    }

    async loadFlatHierarchy(): Promise<RealEstateView[]> {
        let params = new HttpParams();
        params = params.set('flat', false);
        const result = await this.getArrayAsync<RealEstateView>(ViewsNames.RealEstateView, this.endPoint(ApiEndpoints.realEstateItems), UsageContextIdEnum.none, params);
        if (result != null) {
            return result;
        }
        return [];
    }

    async loadFloor(floorId: number): Promise<Floor | null> {
        let params = new HttpParams();
        params = params.set('primaryColumnName', "Fl_Id");
        params = params.set('primaryFilterId', floorId);
        const result = await this.getAsync<Floor[]>(this.dynt(FloorTable.databaseTableName), UsageContextIdEnum.none, params);
        if (result != null) {
            return new Floor(result.payload[0]);
        }
        return null;
    }
    
    async loadBuildingFloorsDashboardData(buildingId: number): Promise<DashboardDto | null> {
        const result = await this.getAsync<DashboardDto>(this.endPoint(ApiEndpoints.realEstateDashboard(buildingId)));
        if (result) {
            return new DashboardDto(result.payload);
        }
        return null;
    }
    
    async loadBuildingLayoutTypeView(buildingId: number): Promise<FloorLayoutTypeDTO[]> {
        const result = await this.getAsync<any[]>(this.endPoint(ApiEndpoints.buildingLayoutType(buildingId)));
        if (result) {
            return result.payload.map(item => {
                return new FloorLayoutTypeDTO(item);
            })
        }
        return [];
    }
     
    async loadBuildingActivityView(buildingId: number): Promise<FloorActivityStatusDTO[]> {
        const result = await this.getAsync<any[]>(this.endPoint(ApiEndpoints.buildingActivity(buildingId)));
        if (result) {
            return result.payload.map(item => {
                return new FloorActivityStatusDTO(item);
            })
        }
        return [];
    }
     
    async loadBuildingAttributionView(buildingId: number): Promise<FloorAttributionDTO[]> {
        const result = await this.getAsync<any[]>(this.endPoint(ApiEndpoints.buildingAttribution(buildingId)));
        if (result) {
            return result.payload.map(item => {
                return new FloorAttributionDTO(item);
            })
        }
        return [];
    }
   
    async loadBuildingAllocations(buildingId: number): Promise<FloorAllocationDTO[]> {
        const result = await this.getAsync<any[]>(this.endPoint(ApiEndpoints.buildingAllocations(buildingId)));
        if (result) {
            return result.payload.map(item => {
                return new FloorAllocationDTO(item);
            })
        }
        return [];
    }
    
    async loadBuildingEquipmentProviderView(buildingId: number): Promise<FloorEquipmentProviderDTO[]> {
        const result = await this.getAsync<any[]>(this.endPoint(ApiEndpoints.buildingEquipmentProvider(buildingId)));
        if (result) {
            return result.payload.map(item => {
                return new FloorEquipmentProviderDTO(item);
            })
        }
        return [];
    }

    async floorPerimeters(floorId: number): Promise<Perimeter[]> {
        const result = await this.getAsync<any[]>(this.endPoint(ApiEndpoints.floorPerimeters(floorId)));
        if (result) {
            return result.payload.map(item => {
                return new Perimeter(item);
            })
        }
        return [];
    }
    
    async createNewPerimeter(name: string, description: string | null, isAreaSharing: boolean, isGranted: boolean, floorsIds: number[]): Promise<Perimeter | null> {
        const dto = {
            [PerimeterTable.peName]: name,
            [PerimeterTable.peDescription]: description,
            [PerimeterTable.peIsSharingAreaItem]: isAreaSharing,
            WithGrantId: isGranted,
            [PerimeterDetailTable.peDeFloorId]: floorsIds
        }
        const result = await this.postAsync<Perimeter>(this.endPoint(this.createCustomPerimeterEndpoint), dto);
        if (result &&  result.payload) {
            return result.payload;
        }
        return null;
    }

    async createNewSite(code: string, name: string, isActive: boolean, isFictive: boolean, location: string): Promise<Site | null> {
        const dto = {
            [SiteTable.stCode]: code,
            [SiteTable.stName]: name,
            [SiteTable.stIsActive]: isActive,
            [SiteTable.stIsFictive]: isFictive,
            [SiteTable.stLocation]: location
        }
        const result = await this.postAsync<Site>(this.endPoint(this.createSiteEndpoint), dto);
        if (result &&  result.payload) {
            return result.payload;
        }
        return null;
    }

    async createNewBuilding(
        siteId: number, 
        code: string, 
        name: string, 
        isActive: boolean, 
        isFictive: boolean, 
        location: string, 
        deliveryAdressId: number | null = null,
        lobbyAdressId: number | null = null,
        parkingAdressId: number | null = null,
        mailingAdressId: number | null = null): Promise<Building | null> {
        const dto = {
            [BuildingTable.buSiteId]: siteId,
            [BuildingTable.buCode]: code,
            [BuildingTable.buName]: name,
            [BuildingTable.buIsActive]: isActive,
            [BuildingTable.buIsFictive]: isFictive,
            [BuildingTable.buLocation]: location,
            [BuildingTable.buDeliveryAddressId]: deliveryAdressId,
            [BuildingTable.buLobbyAddressId]: lobbyAdressId,
            [BuildingTable.buParkingAddressId]: parkingAdressId,
            [BuildingTable.buMailingAddressId]: mailingAdressId
        }
        const result = await this.postAsync<Building>(this.endPoint(this.createBuildingEndpoint), dto);
        if (result &&  result.payload) {
            return result.payload;
        }
        return null;
    }

    async createNewFloor(
        buildingId: number,
        code: string, 
        name: string, 
        isActive: boolean, 
        isFictive: boolean, 
        elevation: number, location: string | null = null, area: number = 0): Promise<Floor | null> {
        const dto = {
            [FloorTable.flBuildingId]: buildingId,
            [FloorTable.flCode]: code,
            [FloorTable.flName]: name,
            [FloorTable.flIsActive]: isActive,
            [FloorTable.flIsFictive]: isFictive,
            [FloorTable.flElevation]: elevation,
            [FloorTable.flLocation]: location,
            [FloorTable.flArea]: area
        }
        const result = await this.postAsync<Floor>(this.endPoint(this.createFloorEndpoint), dto);
        if (result &&  result.payload) {
            return result.payload;
        }
        return null;
    }

    async createGrantForPerimeter(perimeterId: number): Promise<number | null> {
        const result = await this.postAsync<number>(this.endPoint(this.createPerimeterGrantEndpoint(perimeterId)), undefined);
        if (result && result.payload) {
            return result.payload;
        }
        return null;
    }

    async updatePerimeterValue(perimeterId: number, columnName: string, value: string | null): Promise<string | null> {
        const dto: any = {
            "TableName": PerimeterTable.databaseTableName,
            [PerimeterTable.peId]: perimeterId,
            [columnName]: value
        }
        const result = await this.patchAsync<string>(this.endPoint(ApiControllers.dynT), dto, undefined, undefined, '');
        if (result && typeof result.message === 'string') {
            return result.message;
        }
        return null;
    }
}