import { TaskTypeEnum } from "src/app/core/model/data-model/enums/task-type-enum";
import { TaskView } from "src/app/core/model/data-model/views/task-view";
import { TaskFacilityView } from "src/app/core/model/data-model/views/task-facility-view";
import Container from "typedi";
import { DyntService } from "src/app/core/services/backend-services/dynt-service";
import { ViewsNames } from "src/app/core/model/db-model/views-names-enum";
import { TaskFacilityTable } from "src/app/core/model/db-model/tables/task-facility-table";
import { TaskService } from "src/app/core/services/backend-services/task-service";
import { TaskFloorModelView } from "src/app/core/model/data-model/views/task-floor-model-view";
import { TaskFloorModelDbView } from "src/app/core/model/db-model/views/task-floor-model-db-view";
import { TaskContributorView } from "src/app/core/model/data-model/views/task-contributor-view";
import { TaskStatusView } from "src/app/core/model/data-model/views/task-status-view";
import { TaskWorkflowProcessing } from "src/app/core/model/data-processing/task-workflow-processing";
import { EventListener } from "src/app/core/events/event-listener";
import { readableUUID } from "src/app/core/events/event-listener-uuid";
import { businessTypeIsFacilityType, businessTypeIsSpacePlanningType, isAnyHowEnded } from "src/app/core/model/data-processing/task-processing";
import { MobilityProjectsGanttEditorEventsEnum } from "./mobility-projects-gantt-editor-events-enum";
import { directoryItemDisplayName } from "src/app/core/model/data-processing/people-processing";

export class TaskPropertiesVM extends EventListener {
    task: TaskView | undefined;
    taskStatus: TaskStatusView[] = [];
    workflowOptions: TaskStatusView[] = [];
    selectedWorkflow!: TaskStatusView;
    taskFacility: TaskFacilityView | undefined;
    floorModels: TaskFloorModelView[] | undefined;
    floorModelsNames: string = "";
    facilityAmount: number = 0;
    TaskTypeEnum = TaskTypeEnum;

    initialTaskName: string = "";

    constructor() {
        super(readableUUID("TaskPropertiesVM"));

        // this.addEventListener(MoveWorkPageEventsEnum.ganttTaskClick, async (task: SvgGanttTask) => {
        //     await this.set(task.planningTask);
        // });

        // this.addEventListener(MoveWorkPageEventsEnum.selectedNodeChange, async (item: MoveWorkTreeNode, clientYPos: number) => {
        //     await this.set(item.data);
        // });

        // this.addEventListener(MoveWorkPageEventsEnum.ganttTaskClick, async (task: SvgGanttTask) => {
        //     await this.set(task.planningTask);
        // });

        // this.addEventListener(MoveWorkPageEventsEnum.listTaskClick, async (task: SvgGanttTask) => {
        //     await this.set(task.planningTask);
        // });
    }
    
    async set(task: TaskView): Promise<void> {
        this.task = task;
        this.initialTaskName = task.taName;

        this.taskFacility = undefined;
        this.floorModels = undefined;

        await this.loadWorkflow();
        
        if (this.task.taTypeId === TaskTypeEnum.Task && businessTypeIsFacilityType(this.task.taBusinessTypeId!)) {
            const s = Container.get(DyntService);
            const result = await s.downloadRow<TaskFacilityView>(ViewsNames.TaskFacilityView, undefined, TaskFacilityTable.taFaTaskId, this.task.taId);
            if (result) {
                this.taskFacility = result;
                this.facilityAmount = result.taFaUnitOfWorkAmount;
            }
        }
        if (this.task.taTypeId === TaskTypeEnum.Task && businessTypeIsSpacePlanningType(this.task.taBusinessTypeId!)) {
            this.floorModelsNames = "";
            const s = Container.get(DyntService);
            const result = await s.downloadTable<TaskFloorModelView>(ViewsNames.TaskFloorModelView, undefined, TaskFloorModelDbView.taFloMoTaskId, this.task.taId);
            this.floorModels = result;
            const names = result.map(x=> x.flMoDisplayName);
            names.forEach(n => {
                this.floorModelsNames += n + ';'
            });
        }
    }

    async loadWorkflow(): Promise<void> {
        if (!this.task) return;
        if (this.taskStatus.length === 0) {
            const s = Container.get(DyntService);
            this.taskStatus = await s.downloadTable<TaskStatusView>(ViewsNames.TaskStatusView);
        }
        this.selectedWorkflow = this.task.statusView;

    // NOTA : un projet est commencé lorsqu'une de ses tâches est commencée
    // Un projet est non commencé si toutes ses tâches sont non commencées
    // Un projet est annulé si toutes les tâches le sont
    // On peut annuler un projet. Dans ce cas toutes les tâches sont annulées
    // On peut réouvrir un projet qui a été annulé
    // La clôture et la terminaison doivent être effectuées par une commande dédiée après contrôle de l'état des tâches filles
    this.workflowOptions = TaskWorkflowProcessing.nextAvailableStatus(this.taskStatus, this.selectedWorkflow.taStId, this.task.taTypeId === 1).concat(this.selectedWorkflow);
    }

    selectedWorkflowCompare(a: TaskStatusView, b: TaskStatusView): boolean {
        return a.taStId === b.taStId;
    }

    async taskNameFocusOut(): Promise<void> {
        await this.saveTaskName();
    }

    async taskNameKkeyDown(e: KeyboardEvent): Promise<void> {
        if (e.key.toLowerCase() === "enter") {
            await this.saveTaskName();
            return;
        }
        
        if (e.key.toLowerCase() === "escape") {
            this.task!.taName = this.initialTaskName;
            const el = document.getElementById("taskNameInput");
            if (el) {
                el.blur();
            }
            return;
        }

        if (e.ctrlKey && e.key.toLowerCase() === 'z') {
            this.task!.taName = this.initialTaskName;
            return;
        }
    }

    async saveTaskName(): Promise<void> {
        if (!this.task) return;
        if (this.initialTaskName === this.task.taName) return;
        const t = Container.get(TaskService);
        await t.updateTaskName(this.task.taId, this.task.taName);
        this.initialTaskName = this.task.taName;
    }

    async selecteWorkflowChange(e: TaskStatusView): Promise<void> {
        if (!this.task) return;
        const s = Container.get(TaskService);
        const result = await s.updateTaskStatus(this.task.taId, e.taStId);
        // TODO : ajouter la propagation vers les projets parents
        // via le task processing
        if (result != null) {
            // Même annulé on laisse le projet dans le gantt pour la session
            // Comme ça, si l'annulation est une erreur le user pourra revenir en arrière

            this.task.statusView = e;
            this.task.taStatusId = e.taStId;

            // Le résultat est un tableau de tuple<number, number> avec <taskId, statusId>
            // il faut éventuellement propager les valeurs vers les tâches filles et/ou les projets parents
            this.emitEventAsync(MobilityProjectsGanttEditorEventsEnum.workflowUpdated, result, this.taskStatus);
        }
        // Recharge le contenu de la dropdown
        this.loadWorkflow();
    }

    workflowActionName(statusId: number): string {
        if (statusId === this.selectedWorkflow.taStId) {
            return this.selectedWorkflow.taStViDisplayName;
        }
        return TaskWorkflowProcessing.actionName(statusId);
    }

    contributorName(c: TaskContributorView): string {
        return directoryItemDisplayName(c, false, false);
    }

    facilityAmountHasChanged(): boolean {
        return this.taskFacility !== undefined && this.taskFacility.taFaUnitOfWorkAmount !== this.facilityAmount;
    }

    async unitOfWorkInputkeyDown(e: KeyboardEvent): Promise<void> {
        if (e.key === 'Enter') {
            const s = Container.get(TaskService);
            const result = await s.updateTasksFacility(this.taskFacility!.taFaTaskId, this.taskFacility!.taFaCompanyTaskId, this.facilityAmount);      
            if (result == null) {
                // La mise à jour a échoué
                this.facilityAmount = this.taskFacility!.taFaUnitOfWorkAmount;
            } else {
                this.taskFacility!.taFaUnitOfWorkAmount = this.facilityAmount;
            }
            return;
        }
        if (e.key === 'Escape') {
            this.facilityAmount = this.taskFacility!.taFaUnitOfWorkAmount;
            const el = document.getElementById("unitOfWorkInput");
            if (el) {
                el.blur();
            }
            return;
        }

        if (e.ctrlKey && e.key.toLowerCase() === 'z') {
            this.facilityAmount = this.taskFacility!.taFaUnitOfWorkAmount;
            return;
        }
    }
}

