import { Component, ElementRef, Input, OnChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BehaviorSubject, map, Observable, switchMap, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import config from "../../core/auth/auth-config.json";

export const NOT_FOUND_IMG = 'https://static.echo-on.fr/public/img/default-image.svg';

@Component({
  selector: 'xc-secured-image',
  standalone: true,
  imports: [CommonModule, ProgressSpinnerModule],
  templateUrl: './secured-image.component.html',
  styleUrls: ['./secured-image.component.scss']
})
export class SecuredImageComponent implements OnChanges {
  @Input() public src: string | undefined;
  @Input() imgWidth: string = "";
  @Input() imgHeight: string = "";
  isSvgFile: boolean = false;

  // This code block just creates an rxjs stream from the src
  // this makes sure that we can handle source changes
  // or even when the component gets destroyed
  // So basically turn src into src$
  private src$: BehaviorSubject<string | undefined>;

  // this stream will contain the actual url that our img tag will load
  // everytime the src changes, the previous call would be canceled and the
  // new resource would be loaded
  dataUrl$: Observable<any>;

  constructor(private http: HttpClient,
              public elementRef: ElementRef,
              private domSanitizer: DomSanitizer) {
    this.src$ = new BehaviorSubject(this.src);
    this.dataUrl$ = this.src$.pipe(switchMap(url => 
      this.loadImage(`${config.apiUri}fsapi?documentPath=/var/fs/planb/${url}`)
    ));
  }

  ngOnChanges(): void {
    this.src$.next(this.src);
  }

  private loadImage(url: string |undefined): Observable<any> {
    if (url != null) {
      this.isSvgFile = url ? url.endsWith(".svg") : false;
      return this.http.get(url, {responseType: 'blob'}).pipe(
        map(data => this.domSanitizer.bypassSecurityTrustUrl(URL.createObjectURL(data)))
      );
    }

    return of(NOT_FOUND_IMG);
  }

  imgErrorHandler(event: any) {
    event.target.src = NOT_FOUND_IMG;
    this.isSvgFile = true;
  }
}
