import { DefinedModelBase } from '../../model/data-model/defined-model-base';
import { ApiEndpoints } from 'src/app/core/services/api-endpoints';
import { ApiControllers } from './../api-endpoints';
import { UsageContextIdEnum } from './../../model/usage-context-id-enum';
import { HttpParams } from "@angular/common/http";
import { Service } from "typedi";
import { ApiService } from "../api-service";
import { DynviewSet } from '../../model/data-model/sets/dyn-view-set';
import { DataModelBuilder } from '../../model/data-model/data-model.builder';
import { ItemPropertiesSet } from '../../model/data-model/sets/item-properties-set';
import { ApiResponseCodeEnum } from '../api-response-code-enum';
import { ApiData } from '../api-data';
import { Paginator } from 'src/app/components-lib/dyn-grid/model/paginator';
import { ZDbColumn } from '../../model/data-model/tables/z-db-column';
import { TablesNamesEnum } from '../../model/db-model/tables-names-enum';
import { ZDbColumnTable } from '../../model/db-model/tables/z-db-column-table';
import { logError } from '../logging-service';

@Service({ global: true })  
export class DyntService extends ApiService {
    private patchEndpoint(tableName: string, rowId: number): string { return `${ApiControllers.dynT}/${tableName}/${rowId}` };
    private deleteEndpoint(tableName: string, rowId: number): string { return `${ApiControllers.dynT}/${tableName}/${rowId}` };
    private itemPropertiesEndpoint(tableName: string, rowId: number): string { return `${ApiControllers.dynT}/${tableName}/${rowId}/properties` };
    private deleteMultipleEndpoint(tableName: string): string { return `${ApiControllers.dynT}/${tableName}` };
    private paginatorEndpoint(tableName: string): string { return `${ApiControllers.dynT}/${tableName}/paginator` };

    constructor() {
        super();
    }

    async paginator(tableName: string, primaryColumnValue?: any): Promise<Paginator | null> {
        let params = new HttpParams();
        if (primaryColumnValue) {
            params = params.set('primaryFilterId', primaryColumnValue);
        }
        const result = await this.getAsync<Paginator>(this.endPoint(this.paginatorEndpoint(tableName)), UsageContextIdEnum.none, params);
        if (result != null) {
            return result.payload;
        }
        return null;
    }

    async downloadTable<T>(tableName: string, className?: string, primaryColumnName?: string, primaryColumnValue?: any): Promise<T[]> {
        let params = new HttpParams();
        if (primaryColumnName) {
            params = params.set('primaryColumnName', primaryColumnName);
            params = params.set('primaryFilterId', primaryColumnValue);
        }
        if (!className) className = tableName;
        return await this.getArrayAsync<T>(className, this.dynt(tableName), UsageContextIdEnum.none, params);
    }

    async downloadRow<T>(tableName: string, className?: string, primaryColumnName?: string, primaryColumnValue?: any): Promise<T | null> {
        let params = new HttpParams();
        if (primaryColumnName) {
            params = params.set('primaryColumnName', primaryColumnName);
            params = params.set('primaryFilterId', primaryColumnValue);
        }
        if (!className) className = tableName;
        const result = await this.getArrayAsync<T>(className, this.dynt(tableName), UsageContextIdEnum.none, params);
        if (result.length === 1) {
            return result[0];
        }
        if (result.length > 1) {
            logError("La requête a retourné plus d'une ligne");
        }
        return null;
    }

    async downloadRowWithRefDatas<T extends DefinedModelBase>(tableName: string, itemId: number): Promise<ItemPropertiesSet<T> | null> {
        const result = await this.getAsync<any>(this.endPoint(this.itemPropertiesEndpoint(tableName, itemId)));
        if (result) {
            return new ItemPropertiesSet<T>(result.payload, tableName);
        }
        return null;
    }

    async search<T>(tableName: string, value: string, columnName: string[], className?: string): Promise<T[]> {
        let params = new HttpParams();
        params = params.set('value', value);
        columnName.forEach(cn => {
            params = params.append('columnName', cn);
        });
        if (!className) className = tableName;
        return await this.getArrayAsync<T>(className, this.endPoint(ApiEndpoints.dyntSearch(tableName)), UsageContextIdEnum.none, params);
    }

    async contextedDataSet(viewName: string, pageIndex: number = 0, primaryColumnName?: string, primaryColumnValue?: any, pageSize?: number): Promise<DynviewSet> {
        let params = new HttpParams();
        params = params.set('pageIndex', pageIndex);
        if (primaryColumnName) {
            params = params.set('primaryColumnName', primaryColumnName);
            params = params.set('primaryFilterId', primaryColumnValue);
        }
        if (pageSize) {
            params = params.set('pageSize', pageSize);
        }
        const resultData = await this.getAsync<any>(this.dynt(viewName), UsageContextIdEnum.ReferentialAdministration, params);
        const result = new DynviewSet(resultData);

        // TODO : Temporaire. Refactorer le payload de l'api
        const mainTable = result.mainTable();
        const mainTableDef = await this.downloadTable<ZDbColumn>(TablesNamesEnum.ZDbColumn, undefined, ZDbColumnTable.dbCoTableName, mainTable);
        const primaryDef = mainTableDef.find(x=> x.dbCoIsPrimaryKey);
        if (primaryDef) {
            result.primaryColumnName = primaryDef.dbCoColumnName;
        }

        return result;
    }
    
    async patch(tableName: string, rowId: number, dto: any): Promise<string | null> {
        // const dto: any = {
        //     TableName: EQUIPMENT_ITEM.databaseTableName,
        //     [EQUIPMENT_ITEM.id]: row[EQUIPMENT_ITEM.id],
        //     [EQUIPMENT_ITEM.materialId]: code
        //   };
        const result = await this.patchAsync<any>(this.endPoint(this.patchEndpoint(tableName, rowId)), dto);
        if (result) {
            return result.message[0];
        }
        return null;
    }
    
    async post<T>(tableName: string, object: any, params?: HttpParams): Promise<T | null> {
        const result = await this.postAsync<any>(this.endPoint(ApiControllers.dynT), object, params);
        if (result) {
            if (tableName === "") {
                return result.payload;
            } else {
                return DataModelBuilder.instance<T>(tableName, result.payload);
            }
        }
        return null;
    }

    async delete(tableName: string, rowId: number, successMessage?: string): Promise<ApiData<unknown>> {
        return await this.deleteAsync(this.endPoint(this.deleteEndpoint(tableName, rowId)), undefined, successMessage);
    }

    async deleteMultiple(tableName: string, rowId: number[]): Promise<number | null> {
        let params = new HttpParams();
        rowId.forEach(id => {
            params = params.append('id', id);
        });

        const result = await this.deleteAsync(this.endPoint(this.deleteMultipleEndpoint(tableName)), params);
        if (result) {
            return result.id;
        }
        return  null;;
    }
}