import { Segment } from "src/app/core/model/geometry-model/segment.model";
import { HtmlConstants } from "src/app/core/model/html-model/html-constants.model";
import { BpSvgBoundingBox } from "../../../../../bp-svg-core-model/bp-svg-bounding-box";

export class EquipmentSelectionRotationHandle {
    cx: number = 0;
    cy: number = 0;
    fill: string = "#00cc7a";
    fillOpacity: number = 0.3;
    stroke: string = "#00ff99";
    link : Segment = Segment.null();
    display: string = HtmlConstants.styleDisplayBlock;

    constructor() {}

    update(overlayBbox: BpSvgBoundingBox): void {
        const topSegment = overlayBbox.top();
        const offsetMidPoint = topSegment.getOffsetMidPoint(0.25);
        // Les coordonnées du offsetMidPoint sont NaN lorsque l'overlay est équivalent à une bbox 0,0,0,0
        if(Number.isNaN(offsetMidPoint.x)) {
            this.cx = 0;
            this.cy = 0;
            this.link = Segment.null();
        }else {
            this.cx = offsetMidPoint.x;
            this.cy = offsetMidPoint.y;
            this.link = new Segment(topSegment.getOffsetMidPoint(0.1), topSegment.getOffsetMidPoint(0.175))
        }
    }

    show(): void {
        this.display = HtmlConstants.styleDisplayBlock;
    }

    hide(): void {
        this.display = HtmlConstants.styleDisplayNone;
    }
}
