import { Point } from "../geometry-model/point.model";

export class SvgPathValues {
    startPoint: Point;
    endPoint: Point;
    xRadius: number;
    yRadius: number;
    xAxisRotation: number;
    isLargArc: number;
    isClockwise: number;

    constructor(startPoint: Point, xRadius: number, yRadius: number, xAxisRotation: number, isLargArc: number, isClockwise: number, endPoint: Point) {
        this.startPoint = startPoint;
        this.endPoint = endPoint;
        this.xRadius = xRadius;
        this. yRadius = yRadius;
        this.xAxisRotation = xAxisRotation;
        this.isLargArc = isLargArc;
        this.isClockwise = isClockwise;
    }
}