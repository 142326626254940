import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SvgAddPhotoGizmoVM } from '../../model/svg-add-photo-gizmo-vm';

@Component({
  selector: 'g[xc-svg-add-photo-gizmo]',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './svg-add-photo-gizmo.component.svg',
  styleUrls: ['./svg-add-photo-gizmo.component.scss']
})
export class SvgAddPhotoGizmoComponent {
  @Input() model: SvgAddPhotoGizmoVM | undefined;
}
