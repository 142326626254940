import { SvgEntity } from 'src/app/core/model/svg-model/svg-entity.model';
import { Segment } from '../geometry-model/segment.model';
import { Vector } from '../geometry-model/vector.model';
import { Point } from '../geometry-model/point.model';
import { XcMaths } from '../static-functions/xc-maths';

export class SvgTransform {
    readonly rotationAngle: number = 0;
    readonly rotationCenter: Point = Point.origin();
    readonly translate: Point = Point.origin();
    readonly scaleX: number = 1;
    readonly scaleY: number = 1;
    matrix: string | undefined;
    transformString: string | undefined;

    constructor(dtoData: any) {
        if(dtoData) {
            if (dtoData.matrix) {
                this.matrix = dtoData.matrix;
            } else {
                this.rotationAngle = SvgEntity.getValue(dtoData, "rotationAngle", 0, false);
                if(dtoData.rotationCenter) {
                    this.rotationCenter = new Point(dtoData.rotationCenter.x, dtoData.rotationCenter.y);
                }else{
                    this.rotationCenter = Point.origin();
                }
                if(dtoData.translate) {
                    this.translate = new Point(dtoData.translate.x, dtoData.translate.y);
                }
                if (dtoData.scaleX) {
                    this.scaleX = dtoData.scaleX;
                }
                if (dtoData.scaleY) {
                    this.scaleY = dtoData.scaleY;
                }
                this.transformString = this.getTransformString();
            }
        }
    }

    getTransformString(): string {
        if (this.matrix) {
            return `matrix(${this.matrix})`
        }
        let str = '';
        if (this.translate) {
            str += `translate(${this.translate.x},${this.translate.y}) `;
        }
        if (this.rotationAngle) {
            if (this.rotationCenter) {
                str += `rotate(${this.rotationAngle} ${this.rotationCenter.x} ${this.rotationCenter.y}) `;
            } else {
                str += `rotate(${this.rotationAngle}) `;
            }
        }
        if (this.scaleX !== 1 || this.scaleY !==1) {
            str += `scale(${this.scaleX} ${this.scaleY})`
        }
        return str;
    }

    updateMatrix(matrix: string): void {
        this.matrix = matrix;
        this.transformString = this.getTransformString();
    }

    translateUpdate(translate: Point): SvgTransform {
        return new SvgTransform({ rotationCenter: this.rotationCenter, rotationAngle: this.rotationAngle, translate: translate, scaleX: this.scaleX, scaleY: this.scaleY});
    }

    addTranslation(translation: Vector): SvgTransform {
        return new SvgTransform({ rotationCenter: this.rotationCenter, rotationAngle: this.rotationAngle, translate: this.translate.translate(translation), scaleX: this.scaleX, scaleY: this.scaleY});
    }

    clone(): SvgTransform {
        return new SvgTransform(this);
    }

    transformedPoint(p: Point): Point {
        return SvgTransform.transformPoint(p, this);
    }

    transformedSegment(s: Segment): Segment {
        return new Segment(SvgTransform.transformPoint(s.startPoint, this), SvgTransform.transformPoint(s.endPoint, this));
    }

    rotateTranslate(rotationCenter: Point, deltaAngle: number, deltaTranslate?: Vector): SvgTransform {
        const rotatedInsertionPoint = this.translate.rotate(rotationCenter, XcMaths.toRad(deltaAngle));
        const totalAngle = XcMaths.get360DegreeAngle(this.rotationAngle + deltaAngle);
    
        let tmp = new SvgTransform({ rotationCenter: this.rotationCenter, rotationAngle: totalAngle, translate: rotatedInsertionPoint, scaleX: this.scaleX, scaleY: this.scaleY});
        if (deltaTranslate != null)
        {
            tmp = tmp.addTranslation(deltaTranslate);
        }
    
        return tmp;
    }

    translateRotationCenter(delta: Vector): SvgTransform {
        const rn = this.rotationCenter.translate(delta);
        return new SvgTransform({ rotationCenter: rn, rotationAngle: this.rotationAngle, translate: this.translate, scaleX: this.scaleX, scaleY: this.scaleY});
    }

    static transformPoint(p: Point, t: SvgTransform): Point {
        let result = p.clone();

        const rotationCenter = t.rotationCenter;
        const rotationAngle = t.rotationAngle;
        const translate =  t.translate;
        
        if(rotationCenter != null && rotationAngle !== 0) {
            result = result.rotate(rotationCenter, XcMaths.toRad(rotationAngle));
        }
        if(translate != null) {
            return result.translate(translate.vector());
        }
        return result;
    }
}
