@if (model) {
<div id="menuMainContainer" (mouseover)="model.mouseover()" (mouseleave)="model.mouseleave()">
    <div class="menuContainer">
        <div id="menuHeader">
            <div id="clientZone">
                <div id="clientZoneContainer">
                    <div id="clientZoneClient">
                        <xc-secured-image mat-card-image class="logo" [src]="model.client.companyId + '/logo.jpg'">
                        </xc-secured-image>
                        <div class="client-label">
                            <span class="clientName">{{ model.client.companyName }}</span>
                            <span class="appVersion">{{ model.appVersion }}</span>
                            @if (auth.user$ | async; as user) {
                                <span class="appVersion">{{ user.email }}</span>
                            }
                        </div>
                    </div>
                    <div>
                        <p-button icon="pi pi-sign-out" [rounded]="true" [text]="true" pTooltip="Se déconnecter" tooltipPosition="bottom" (click)="auth.logout({ logoutParams: { returnTo: document.location.origin } })"/>
                        @if (model.hasMoreClients) {
                            <p-button icon="pi pi-sync" [rounded]="true" [text]="true" pTooltip="Changer de client" tooltipPosition="bottom" (click)="auth.logout({ logoutParams: { returnTo: document.location.origin } })"/>
                        }
                    </div>
                </div>
            </div>
            <div id="tips">
                <div class="tips">
                    <span class="material-icons-outlined tipIcon">tips_and_updates</span>
                    <span class="tipLabel">F11 pour travailler en plein écran</span>
                </div>
                <div class="tips">
                    <span class="material-icons-outlined tipIcon">help_outline</span>
                    <a href="#" (click)="model.welcomeLinkClick()" [style]="{color: 'rgba(255, 255, 255, 0.8588235294)', marginLeft: '10px'}">
                        Bienvenue !
                    </a>
                </div>
                <!-- <div class="tips">
                    <span class="material-icons-outlined tipIcon">healing</span>
                    <a mat-button class="tipLabel link" href="#" (click)="model.healingButtonClick()">Assistance</a>
                </div> -->
            </div>
        </div>

        <p-tree [style]="{height: 'calc(100% - 200px)'}" [value]="model.menu.items" selectionMode="single"
            [(selection)]="model.selectedMenuItem" (onNodeSelect)="model.newMenuClick($event)">
            <ng-template let-node pTemplate="default">
                <span [class.opacity-30]="!node.enable">{{ node.label }}</span>
            </ng-template>
        </p-tree>
    </div>
</div>
}