import { DyntService } from 'src/app/core/services/backend-services/dynt-service';
import { Container } from 'typedi';
import { FormsModule } from '@angular/forms';
import { MaterialModule } from 'src/app/core/material.module';
import { Component, Inject, Input, OnInit, AfterViewInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RoomAllocation } from 'src/app/core/model/data-model/tables/room-allocation';
import { BusinessUnit } from 'src/app/core/model/data-model/tables/business-unit';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { TablesNamesEnum } from 'src/app/core/model/db-model/tables-names-enum';
import { ArrayUtils } from 'src/app/core/model/static-functions/array-utils';
import { RoomAllocationDisplay } from '../../model/room-allocation-display';
import { RoomAllocationViewSet } from 'src/app/core/model/data-model/view-set/room-allocation-view-set';
import { DateUtils } from 'src/app/core/model/static-functions/date-utils';

interface BuTreeNode {
  name: string;
  id: number;
  depth: number;
  color: string;
  children?: BuTreeNode[];
}

@Component({
  selector: 'xc-room-allocation-editor',
  standalone: true,
  imports: [CommonModule, MaterialModule, FormsModule],
  templateUrl: './room-allocation-editor.component.html',
  styleUrls: ['./room-allocation-editor.component.scss']
})
export class RoomAllocationEditorComponent implements AfterViewInit {
  businessUnits : BusinessUnit[] = [];
  displayAllocations: RoomAllocationDisplay[] = [];
  buTree: BuTreeNode[] = [];
  treeControl = new NestedTreeControl<BuTreeNode>(node => node.children);
  dataSource = new MatTreeNestedDataSource<BuTreeNode>();
  canSave: boolean = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: { allocations: RoomAllocation[] }, 
    public dialogRef: MatDialogRef<RoomAllocationEditorComponent>) { 
  }

  async ngAfterViewInit(): Promise<void> {
    // Télécharge les entités
    await this.loadReferenceData();
  }

  private async loadReferenceData(): Promise<void> {
    const s = Container.get(DyntService);
    this.businessUnits = await s.downloadTable<BusinessUnit>(TablesNamesEnum.BusinessUnit);
    const rootItems = this.businessUnits.filter(x=> x.buUnDepth === 1);
    rootItems.forEach(r => {
      const newNode = this.getBuNode(r);
      this.buTree.push(newNode);
      this.getChildren(newNode);
    });

    if (this.data.allocations) {
      this.data.allocations.forEach(a => {
        const da = this.getDisplayAllocation(a);
        if (da) {
          this.displayAllocations.push(da);
        }
      });
    }

    this.dataSource.data = this.buTree;
  }

  getChildren(parentNode: BuTreeNode): void {
      const children = this.businessUnits.filter(x=> x.buUnParentId === parentNode.id);
      if (children.length > 0) {
        children.forEach(c => {
          const newNode = this.getBuNode(c);
          parentNode.children?.push(newNode);
          this.getChildren(newNode);
        });
      }
  }


  getBuNode(bu: BusinessUnit): BuTreeNode {
    return { 
      id: bu.buUnId,
      name: bu.buUnName,
      depth: bu.buUnDepth,
      color: bu.buUnColor,
      children: []
    }
  }

  getDisplayAllocation(a: RoomAllocation | RoomAllocationViewSet): RoomAllocationDisplay | null {
    let buId: number = 0;
    let buArea: number = 0;
    let buRatio: number = 0;
    if (a instanceof RoomAllocation) {
      buId = a.roAlBusinessUnitId;
      buArea = a.roAlArea;
      buRatio = a.roAlRate;
    } else {
      buId = a.dataSet.roAlBusinessUnitId;
      buArea = a.dataSet.roAlArea;
      buRatio = a.dataSet.roAlRate;
    }
    const bu = this.businessUnits.find(x=> x.buUnId === buId);
    if (bu) {
        return new RoomAllocationDisplay(bu.buUnName, bu.buUnId, bu.buUnColor, buArea, buRatio);
    }
    return null;
  }

  hasChildren = (_: number, node: BuTreeNode) => !!node.children && node.children.length > 0;

  totalRate(): number {
    return ArrayUtils.sumBy(this.displayAllocations, "ratio");
  }

  addBuClick(e: MouseEvent, n: BuTreeNode) {
    const totalRate = this.totalRate();
    if (totalRate === 100) return;

    const selectedBu = this.businessUnits.find(x=> x.buUnId === n.id);
    if (selectedBu) {
        const rate = 100 - totalRate;
        const newAllocation = RoomAllocation.fromValues(0, selectedBu.buUnId, 0, rate, DateUtils.today());
        const newDisplayAllocation = this.getDisplayAllocation(newAllocation);
        if (newDisplayAllocation) {
          this.displayAllocations.push(newDisplayAllocation);
        }
    }

    this.updateCanSave();
  }

  deleteBuClick(e: MouseEvent, n: RoomAllocationDisplay) {
    const index = this.displayAllocations.indexOf(n);
    this.displayAllocations.splice(index, 1);

    this.updateCanSave();
  }

  hasChanges(): boolean {
    if (this.data.allocations) {
      for (const a of this.data.allocations) {
        const d = this.displayAllocations.find(x=> x.businessUnitId === a.roAlBusinessUnitId);
        // Cas où une allocation a été supprimée
        if (d == null) return true;
  
        // Cas où une allocation a été modidfiée
        if (d.ratio !== a.roAlRate) return true;
      }
    }

    for (const d of this.displayAllocations) {
      const a = this.data.allocations?.find(x=> x.roAlBusinessUnitId === d.businessUnitId);
      // Cas où une allocation a été ajoutée
      if (a == null) return true;
    }

    // Aucun changement dans les allocations
    return false;
  }

  contains(item: BuTreeNode): boolean {
    return this.displayAllocations.find(x=> x.businessUnitId === item.id) != null;
  }

  updateCanSave(): void {
    const hasError = this.displayAllocations.filter(x=> x.hasError === true).length > 0;
    this.canSave = this.totalRate() === 100 && this.hasChanges() && !hasError;
  }

  rateChange(e: number, a: RoomAllocationDisplay) {
    // Se produit lorsqu'on saisit le séparateur décimal
    if (e == null) return;
    
    if (e > 0 && e <= 100) {
      a.hasError = false;
    } else {
      a.hasError = true;
    }

    this.updateCanSave();
  }

  save() {
    this.dialogRef.close(this.displayAllocations);
  }

  cancel() {
    this.dialogRef.close();
  }
}
