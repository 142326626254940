import Container, { Service } from "typedi";
import { ApiControllers } from "../api-endpoints";
import { ApiService } from "../api-service";
import { TaskContributorView } from "../../model/data-model/views/task-contributor-view";
import { HttpParams } from "@angular/common/http";
import { ViewsNames } from "../../model/db-model/views-names-enum";
import { ApiResponseCodeEnum } from "../api-response-code-enum";
import { TaskContributorTable } from '../../model/db-model/tables/task-contributor-table';

@Service({ global: true })  
export class TaskContributorService extends ApiService {
    private hasValidationFunctionEndpoint(taskId: number): string { return `${ApiControllers.taskContributors}/HasValidationFunctionEndpoint/${taskId}` };
    private hasRefAreaUpdateGrantEndpoint(taskId: number): string { return `${ApiControllers.taskContributors}/HasRefAreaUpdateGrant/${taskId}` };
    private canCreateTaskEndpoint: string = `${ApiControllers.taskContributors}/CanCreateTask`;
    private tasksContributorsEndpoint: string = `${ApiControllers.taskContributors}/taskscontributors`;
    private workloadUpdateEndpoint(contributorId: number, taskId: number, functionId: number, workload: number): string { 
        return `${ApiControllers.taskContributors}/workload/${contributorId}/${taskId}/${functionId}/${workload}` };
    private deleteEndpoint(taskId: number, contributorId: number, functionId: number): string { 
        return `${ApiControllers.taskContributors}/${taskId}/${contributorId}/${functionId}` };

    constructor() {
        super();
    }
    
    async hasValidationFunction(taskId: number): Promise<boolean> {
        const result = await this.getAsync<boolean>(this.endPoint(this.hasValidationFunctionEndpoint(taskId)));
        if (result) {
            return result.payload;
        }
        return false;
    }
    
    async hasRefAreaUpdateGrant(taskId: number): Promise<boolean> {
        const result = await this.getAsync<boolean>(this.endPoint(this.hasRefAreaUpdateGrantEndpoint(taskId)));
        if (result) {
            return result.payload;
        }
        return false;
    }
    
    async canCreateTask(): Promise<boolean> {
        const result = await this.getAsync<boolean>(this.endPoint(this.canCreateTaskEndpoint));
        if (result) {
            return result.payload;
        }
        return false;
    }
    
    async tasksContributors(taskIds: number[]): Promise<TaskContributorView[]> {
        let params = new HttpParams();
        taskIds.forEach(id => {
            params = params.append(`taskIds`, id);
        });
        const result = await this.getArrayAsync<TaskContributorView>(ViewsNames.TaskContributorView, this.endPoint(this.tasksContributorsEndpoint), undefined, params);
        if (result) {
            return result;
        }
        return [];
    }

    async updateWorkload(contributorId: number, taskId: number, functionId: number, workload: number): Promise<boolean> {
        const result = await this.patchAsync(this.endPoint(this.workloadUpdateEndpoint(contributorId, taskId, functionId, workload)));
        if (result?.statusCode === ApiResponseCodeEnum.success && result.payload === 1) {
            return true;
        }
        
        return false;
    }

    async createNewContributor(taskId: number, contributorId: number, functionId: number, workload: number): Promise<TaskContributorView | null> {
        const dto = {
            [TaskContributorTable.taCrTaskId]: taskId,
            [TaskContributorTable.taCrContributorId]: contributorId,
            [TaskContributorTable.taCrFunctionId]: functionId,
            [TaskContributorTable.taCrLoad]: workload
        }
        const result = await this.postAsync<TaskContributorView>(this.endPoint(ApiControllers.taskContributors), dto);
        if (result != null) {
            return new TaskContributorView(result.payload);
        }

        return null;
    }

    async removeContributor(taskId: number, contributorId: number, functionId: number): Promise<boolean> {
        const result =  await this.deleteAsync<boolean>(this.endPoint(this.deleteEndpoint(taskId, contributorId, functionId)));
        if (result) {
            return result.payload;
        }

        return false;
    }
}