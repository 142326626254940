import { Circle } from "../geometry-model/circle.model";
import { Point } from "../geometry-model/point.model";
import { SvgCircle } from "./svg-circle.model";

export class SvgCircleService {
    static centerPoint(c: SvgCircle): Point {
        return new Point(c.cx, c.cy);
    }

    static geometry(c: SvgCircle): Circle {
        return new Circle(SvgCircleService.centerPoint(c), c.r);
    }
}