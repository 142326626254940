import config from '../auth/auth-config.json';

//export const ApiUrl = environment.apiUrl;

export const ApiControllers = {
    dynT: 'dynt',
    dynH: 'dynh',
    app: "app",
    appUsers: "appusers",
    appRoleGrants: "AppRoleGrants",
    bus: "bus",
    address: "address",
    constraints: "constraints",
    context: "context",
    directory: "directory",
    doc: "doc",
    dwg: "dwg",
    dxf: "dxf",
    equipmentPlanning: "equipmentplanning",
    equipmentReconcile: "equipmentreconcile",
    floors: "floors",
    floorData: "floordata",
    floorDataWalls: "floordata/walls",
    floorDataDoors: "floordata/doors",
    floorDataEquipments: "floordata/equipments",
    floorDataMeasurement: "floordata/measurement",
    floorDataRoom: "floordata/room",
    floorCatalog: "floorcatalog",
    floorModel: "floormodel",
    fs: "fs",
    graphics: "graphics",
    helpdesk: "helpdesk",
    inventory: "inventory",
    pagination: "pagination",
    perimeters: "perimeters",
    policyGeneric: "policygeneric",
    projects: "projects",
    providerCompany: 'providercompany',
    purchase: "purchase",
    realestate: "realestate",
    roomLayoutTypes: "roomlayouttypes",
    simulations: "simulations",
    simulationPhotos: "simulationphotos",
    sites: "sites",
    tasks: "tasks",
    taskLinks: "tasklinks",
    taskContributors: "taskcontributors"
}

// TODO : Répartir les endpoints dans les services respectifs qui les utilisent

export const ApiEndpoints = {
    apiUrl: config.apiUri,
    endPoint: (url: string): string => ApiEndpoints.apiUrl + url,
    dynt: (tableName: string): string => ApiEndpoints.endPoint(`${ApiControllers.dynT}/${tableName}`),
    dynh: (tableName: string): string => ApiEndpoints.endPoint(`${ApiControllers.dynH}/${tableName}`),
    dynhLastItems: (tableName: string): string => `${ApiEndpoints.dynh(tableName)}/lastitems`,
    dynview: (tableName: string): string => ApiEndpoints.dynt(tableName) + "_dynview",
    appParameter: (parameterName: string): string => `${ApiControllers.app}/parameter/${parameterName}`,
    appUserGrants: `${ApiControllers.app}/usergrants`,
    dxfToBlueprintSvg: `${ApiControllers.dxf}/toblueprintsvg`,
    dxfToPictogramSvg: `${ApiControllers.dxf}/topictogramsvg`,
    ecsyFile: 'ecsyfile/items',
    fsProviderLogo: `${ApiControllers.fs}/providerlogo`,
    fsToAirlock: `${ApiControllers.fs}/toairlock`,
    realEstateItems: 'realestate/items',
    realEstateTheming: 'realestate/theming',
    realEstateDashboard: (buildingId: number): string => `buildings/${buildingId}/dashboard`,
    buildingLayoutType: (buildingId: number): string => `buildings/${buildingId}/layouttype`,
    buildingActivity: (buildingId: number): string => `buildings/${buildingId}/activity`,
    buildingAllocations: (buildingId: number): string => `buildings/${buildingId}/allocations`,
    buildingAttribution: (buildingId: number): string => `buildings/${buildingId}/attribution`,
    buildingEquipmentProvider: (buildingId: number): string => `buildings/${buildingId}/equipmentprovider`,
    floorPerimeters: (floorId: number): string => `perimeters/floor/${floorId}/full`,
    busItems: `${ApiControllers.bus}/items`,
    busCurrentView: `${ApiControllers.bus}/currentview`,
    busTreeDatas: `${ApiControllers.bus}/treeDatas`,
    cadProjectsDirectTask: 'cadprojects/directtask',
    floorCatalogImportUrl: 'Dwg/CatalogFile',
    floorTaskData: (taskId: number): string => `floors/${taskId}`,
    floorRoomReferenceDatas: (floorId: number) => `floors/referencedatas/${floorId}`,
    floorDataDoorStyles: `${ApiControllers.floorData}/doors/doorStyles`,
    floorDataEquipment: (categoryId: number, providerId: number): string => `${ApiControllers.floorData}/equipments/${categoryId}/${providerId}`,
    floorDataEquipmentReport: (categoryId: number): string => `${ApiControllers.floorData}/equipments/${categoryId}/report`,
    floorDataRoom: () => `${ApiControllers.floorData}/room`,
    floorCatalogImageUrl: `${ApiControllers.floorCatalog}/pictogram`,
    floorCatalogTempImage: `${ApiControllers.floorCatalog}/temp`,
    addRoomToTask: 'cadprojects/addroom',
    updateContour: (roomId: number): string => `${ApiEndpoints.floorDataRoom()}/${roomId}/updatecontour`,
    workplaces: `${ApiControllers.floorData}/workplaces`,
    workplacesAllocation: `${ApiControllers.floorData}/workplaces/allocation`,
    workplacesAllocations: `${ApiControllers.floorData}/workplaces/allocations`,
    workplaceUpdateLabelCode: (floorDataId: number, code: string): string => `floorData/Workplaces/${floorDataId}/code/${code}`,
    workplaceCodeNextValue: (code: string, step: number): string => `floordata/Workplace/NextValue/${code}/${step}`,
    workplaceCodeNextValues: (code: string, step: number, count: number): string => `floordata/Workplace/NextValues/${code}/${step}/${count}`,
    workplaceUpdateType: 'floorData/Workplaces/type',
    moveLabel: (floorDataId: number): string => `${ApiControllers.floorData}/movelabel/${floorDataId}`,
    grantedOutsideProjectEditableLayers: 'floormodel/AllowedUpdateOutsideProject',
    svgContour: 'floordata/contour',
    taskGrantedEditableLayers: (taskId: number): string => `CADProjects/AllowedUpdateLayers/${taskId}`,
    inventoryView: 'inventory/inventoryview',
    floorModelView: 'floormodel/viewset',
    roomSharing: 'roomsharing',
    roomSharingShare: 'roomsharing/share',
    roomAllocate: () => `${ApiEndpoints.floorDataRoom()}/allocate`,
    tasks: "tasks",
    tasksVariant: 'tasks/variant',
    taskVariants: (taskId: number): string => `tasks/${taskId}/variants`,
    taskDataset: (taskId: number): string => `tasks/${taskId}/dataset`,
    tasksValidations: 'tasks/validations',
    taskValidationsRefAreaCheck: (taskId: number): string => `tasks/validations/${taskId}/RefAreaCheck`,
    dyntSearch: (tableName: string): string => `${ApiControllers.dynT}/search/${tableName}`
}

