import { Point } from "src/app/core/model/geometry-model/point.model";
import { SvgEntityTypesEnum } from "src/app/core/model/svg-model/svg-entity-type-enum";
import { UpdateGizmo } from "../../gizmo/model/update-gizmo";
import { SelectableSvgEntity } from "../../svg/selectable-svg-entity";
import { IEntityInteraction } from "./i-entity-interaction";
import { InteractionCommand } from "./interaction-command";
import { SelectionInteraction } from "./selection-interaction";
import { SvgEntityPoint } from "../../../shared/gizmos/model/svg-entity-point";

export abstract class EntityInteraction<T extends SelectableSvgEntity, U extends UpdateGizmo<T>> implements IEntityInteraction<T> {
    entityType: SvgEntityTypesEnum;
    selectionSet: T[] = [];
    selectionSetCopy: T[] = [];
    selectionInteraction: SelectionInteraction | undefined;
    currentCommand: InteractionCommand;
    updateGizmo: U;
    
    constructor(entityType: SvgEntityTypesEnum, command: InteractionCommand, gizmo: U) {
        this.entityType = entityType;
        this.currentCommand = command;
        this.updateGizmo = gizmo;
        this.selectionSet = gizmo.entities;
        this.cloneEntities();
    }

    cloneEntities(): void {
        this.selectionSetCopy = [];
        this.selectionSet.forEach(e => {
            this.selectionSetCopy.push(e.clone() as T);
        });
    }

    selectablePoints(): SvgEntityPoint[] {
        const result: SvgEntityPoint[] = [];

        this.selectionSet.forEach(e => {
            e.updateSelectablePoints();
            result.push(...e.selectablePoints);
        });

        return result;
    }

    activate(entities: T[], toRemove: boolean): Promise<void> {
        throw new Error("Method not implemented.");
    }
    deactivate(): void {
        throw new Error("Method not implemented.");
    }
    abort(): void {
        this.updateGizmo.grips.clear();
    }
    
    deleteSelectedItems(): Promise<void> {
        throw new Error("Method not implemented.");
    }
    beginInsert(args: any): void {
        throw new Error("Method not implemented.");
    }
    selectionDeleted?: (() => void) | undefined;
    raiseSelectionDeleted(): void {
        throw new Error("Method not implemented.");
    }
    mouseDown(e: MouseEvent, hitPoint: Point): Promise<boolean> {
        throw new Error("Method not implemented.");
    }
    mouseMove(e: MouseEvent, hitPoint: Point): Promise<void> {
        throw new Error("Method not implemented.");
    }
    mouseUp(e: MouseEvent, hitPoint: Point | undefined): Promise<void> {
        throw new Error("Method not implemented.");
    }
    keyDown(e: KeyboardEvent): Promise<void> {
        throw new Error("Method not implemented.");
    }

}