import { Component, Input } from '@angular/core';
import { MVVMEventDestroyLifeCycle } from 'src/app/components-lib/shared-model/mvvm-events-destroy-lifecycle';
import { MobilityJobs } from '../../model/mobility-jobs';
import { DynTableComponent } from 'src/app/components-lib/dyn-table/dyn-table.component';

@Component({
  selector: 'xc-mobility-jobs',
  standalone: true,
  imports: [DynTableComponent],
  templateUrl: './mobility-jobs.component.html',
  styleUrl: './mobility-jobs.component.scss'
})
export class MobilityJobsComponent extends MVVMEventDestroyLifeCycle {
  @Input() override model: MobilityJobs | undefined;
}
