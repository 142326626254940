import { FloorCatalogImportItemTable } from '../../db-model/tables/floor-catalog-import-item-table';
import { DefinedModelBase } from '../defined-model-base';

export class FloorCatalogImportItem extends DefinedModelBase {
    flCgImItId!: number;
    flCgImItImportId!: number;
    flCgImItTempId!: number;
    flCgImItCode!: string;
    flCgImItLayerName!: string;
    flCgImItIsNew!: boolean;
    flCgImItValidatedCode!: string;
    flCgImItIsValidated!: boolean;
    flCgImItFileWeight!: number;
    flCgImItFilePath!: string;
    flCgImItWidth!: number;
    flCgImItHeight!: number;

    constructor(dtoData: any, tableDef: any[] = []) {
        super(dtoData, [FloorCatalogImportItemTable].concat(tableDef));
    }
}