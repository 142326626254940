import { RoomReferenceDataSet } from './../../model/data-model/sets/room-reference-data-set';
import { ApiControllers, ApiEndpoints } from './../api-endpoints';
import { Service } from "typedi";
import { ApiService } from "../api-service";
import { ApiResponseCodeEnum } from '../api-response-code-enum';
import { RoomActivityStatusTypeEnum } from '../../model/data-model/enums/room-activity-status-type-enum';
import { RoomSharing } from '../../model/data-model/tables/room-sharing';
import { SvgGroup } from '../../model/svg-model/svg-group.model';

@Service({ global: true })  
export class RoomService extends ApiService {
  private floorRoomReferenceDatasEndpoint(floorId: number): string { return `${ApiControllers.floors}/referencedatas/${floorId}`};
  private updateLayoutEndpoint: string = `${ApiControllers.floorDataRoom}/layout`;
  private discardEndpoint: string = `${ApiControllers.floorDataRoom}/discard`;
  private sharingEndpoint: string = `${ApiControllers.floorDataRoom}/share`;
  private wipeEndpoint: string = `${ApiControllers.floorDataRoom}/wipe`;
  private updateLabelEndpoint(floorDataId: number): string { return `${ApiControllers.floorDataRoom}/${floorDataId}/label`};

  constructor() {
        super();
    }

    async loadReferenceDatas(floorId: number): Promise<RoomReferenceDataSet> {
      const result = await this.getAsync<any>(this.endPoint(this.floorRoomReferenceDatasEndpoint(floorId)));
        if (result != null) {
          return new RoomReferenceDataSet(result.payload);
        }
        return new RoomReferenceDataSet(null);
    }
    
    async updateRoomLabel(floorDataId: number, code: string): Promise<SvgGroup | null> {
      const result = await this.patchAsync<SvgGroup>(this.endPoint(this.updateLabelEndpoint(floorDataId)), code);
      if (result != null) {
        return result.payload;
      }
      return null;
    }
  
    async updateRoomLayoutType(floorDataIds: number[], layoutTypeId: number): Promise<boolean> {
      const dto: any = {};
      dto['RoomIds'] = floorDataIds;
      dto['UpdateValue'] = layoutTypeId;
      const result = await this.patchAsync<boolean>(this.endPoint(this.updateLayoutEndpoint), dto);
      if (result && result.statusCode === ApiResponseCodeEnum.success) {
        return result.payload;
      }
      return false;
    }
      
    async updateRoomsSharing(floorDataIds: number[], perimeterId: number): Promise<RoomSharing[]> {
      const dto: any = {};
      dto['RoomIds'] = floorDataIds;
      dto['UpdateValue'] = perimeterId;
      const result = await this.patchAsync<RoomSharing[]>(this.endPoint(this.sharingEndpoint), dto);
      if (result && result.statusCode === ApiResponseCodeEnum.success) {
        return result.payload.map(item => {
          return new RoomSharing(item);
        })
      }
      return [];
    }
  
    async discardRooms(floorDataIds: number[], activityStatusTypeId: number): Promise<boolean> {
      if (activityStatusTypeId === RoomActivityStatusTypeEnum.UnderConstruction
        || activityStatusTypeId === RoomActivityStatusTypeEnum.Empty) {
        const dto: any = {};
        dto['RoomIds'] = floorDataIds;
        dto['UpdateValue'] = activityStatusTypeId;
        const result = await this.patchAsync<boolean>(this.endPoint(this.discardEndpoint), dto);
        if (result && result.statusCode === ApiResponseCodeEnum.success) {
          return result.payload;
        }
      }
      return false;
    }
  
    async wipeRooms(floorDataIds: number[]): Promise<boolean> {
      const dto: any = {};
      dto['RoomIds'] = floorDataIds;
      const result = await this.patchAsync<boolean>(this.endPoint(this.wipeEndpoint), dto);
      if (result && result.statusCode === ApiResponseCodeEnum.success) {
        return result.payload;
      }
      return false;
    }
            
    // async updateRoomAllocations(floorDataIds: number[], roomAllocations: displayAllocation[] | PocRoomAllocation[]): Promise<PocRoomAllocation[]> {
    //   if (roomAllocations.length > 0) {
    //   const allocations: [] = [];
    //   roomAllocations.forEach((e: displayAllocation | PocRoomAllocation) => {
    //       const item = {};
    //       item[ROOM_ALLOCATION.businessUnitId] = e.businessUnitId;
    //       item[ROOM_ALLOCATION.rate] = e.rate;
    //       allocations.push(item);
    //   });
    //   const dto = {};
    //   dto['RoomIds'] = floorDataIds;
    //   dto['UpdateValue'] = allocations;
    //   return await lastValueFrom(this.patch(`${API_ENDPOINTS.floorDataRoom}/allocate`, dto).pipe(
    //       map((data: ApiData) => {
    //           if (data.statusCode === ApiResponseCodeEnum.success) {
    //               return data.payload.map(item => {
    //                   return new PocRoomAllocation(item);
    //               })
    //           } else {
    //               return null;
    //           }
    //         })
    //       ));
    //   }
    // }
      
}