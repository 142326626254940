import { TablesNamesEnum } from './../tables-names-enum';
export const TaskTable = {
    databaseTableName: TablesNamesEnum.Task,
    taId: 'Ta_Id',
    taName: 'Ta_Name',
    taDescription: 'Ta_Description',
    taParentId: 'Ta_ParentId',
    taDepth: 'Ta_Depth',
    taStartDate: 'Ta_StartDate',
    taDuration: 'Ta_Duration',
    taIsTemplate: 'Ta_IsTemplate',
    taLoad: 'Ta_Load',
    taStatusId: 'Ta_StatusId',
    taFulfilled: 'Ta_Fulfilled',
    taTypeId: 'Ta_TypeId',
    taRootTaskId: 'Ta_RootTaskId',
    taBusinessId: 'Ta_BusinessId',
    taBusinessTypeId: 'Ta_BusinessTypeId',
    taCanHaveChildren: 'Ta_CanHaveChildren',
  };
  