import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DrawingBoardVM } from '../../model/drawing-board-vm';
import { DrawingComponent } from '../drawing/drawing.component';
import { BoardToolbarComponent } from '../board-toolbar/board-toolbar.component';
import { MVVMEventDestroyLifeCycle } from 'src/app/components-lib/shared-model/mvvm-events-destroy-lifecycle';

@Component({
  selector: 'xc-drawing-board',
  standalone: true,
  imports: [CommonModule, DrawingComponent, BoardToolbarComponent],
  templateUrl: './drawing-board.component.html',
  styleUrls: ['./drawing-board.component.scss']
})
export class DrawingBoardComponent extends MVVMEventDestroyLifeCycle {
  @Input() override model: DrawingBoardVM | undefined;
}
