import { Component, Input } from '@angular/core';
import { MVVMEventDestroyLifeCycle } from 'src/app/components-lib/shared-model/mvvm-events-destroy-lifecycle';
import { MobilityFunctions } from '../../model/mobility-functions';
import { DynTableComponent } from 'src/app/components-lib/dyn-table/dyn-table.component';

@Component({
  selector: 'xc-mobility-functions',
  standalone: true,
  imports: [DynTableComponent],
  templateUrl: './mobility-functions.component.html',
  styleUrl: './mobility-functions.component.scss'
})
export class MobilityFunctionsComponent extends MVVMEventDestroyLifeCycle {
  @Input() override model: MobilityFunctions | undefined;
}
