@if (model) {
  <div class="w-full h-full">

    @switch (model.currentView) {
      @case (model.StrategyViewsEnum.creationStepper) {
        <xc-creation-stepper [model]="model.creationStepper"></xc-creation-stepper>
      }
      @case (model.StrategyViewsEnum.schedule) {
        <xc-strategy-scheduling [model]="model.scheduling"></xc-strategy-scheduling>
      }
      @case (model.StrategyViewsEnum.workforce) {
        <xc-strategy-workforce [model]="model.workforce"></xc-strategy-workforce>
      }
      @case (model.StrategyViewsEnum.stacking) {
        <xc-strategy-stacking [model]="model.stacking"></xc-strategy-stacking>
      }
    }

  </div>
}