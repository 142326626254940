@if (model) {
<mat-select 
    [(ngModel)]="model.selectedItem" 
    [class]="{editable: !model.readOnly}" 
    (selectionChange)="model.selectedItemChange()">
    @for (i of model.selectItems; track i) {
  <mat-option [value]="i">
        {{i.value}}
    </mat-option>
}
</mat-select>
}

