import { TablesNamesEnum } from './../tables-names-enum';
export const LeaseTable = {
    databaseTableName: TablesNamesEnum.Lease,
    leId: 'Le_Id',
    leParentId: 'Le_ParentId',
    leInternalTypeId: 'Le_InternalTypeId',
    lePurpose: 'Le_Purpose',
    lePerimeterId: 'Le_PerimeterId',
    leLessorId: 'Le_LessorId',
    leTenantId: 'Le_TenantId',
    leDateOfSignature: 'Le_DateOfSignature',
    leLeaseStatusId: 'Le_LeaseStatusId',
    leEffectiveDate: 'Le_EffectiveDate',
    leAmendmentCount: 'Le_AmendmentCount',
    leTerm: 'Le_Term',
    leDurationTypeId: 'Le_DurationTypeId',
    leNoticeLength: 'Le_NoticeLength'
  };
  