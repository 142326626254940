import { Floor } from "../tables/floor";
import { Perimeter } from "../tables/perimeter";
import { PerimeterDetail } from "../tables/perimeter-detail";

export class PerimeterDetailView extends PerimeterDetail {
    perimeter!: Perimeter;
    floor!: Floor;

    constructor(dtoData: any) {
        super(dtoData);
        this.perimeter = new Perimeter(dtoData);
        this.floor = new Floor(dtoData);
    }
}