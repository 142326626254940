import { FacilityJobTaskTable } from "../../db-model/tables/facility-job-task-table";
import { DefinedModelBase } from "../defined-model-base";

export class FacilityJobTask extends DefinedModelBase {
    faJoTaId!: number;
    faJoTaName!: string;
    faJoTaIsActive!: boolean;
    faJoTaFacilityJob!: number;
    faJoTaUnitOfWork!: number;
    faJoTaUnitPrice!: number;

    constructor(dtoData: any, tableDef: any[] = []) {
        super(dtoData, [FacilityJobTaskTable].concat(tableDef));
    }
}