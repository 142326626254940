import { DynTableVM } from "src/app/components-lib/dyn-grid/model/dyn-table-vm";
import { DynTableVMBuilder } from "src/app/components-lib/dyn-grid/model/dyn-table-vm-builder";
import { FieldListItem } from "src/app/components-lib/shared-model/field-list-item";
import { ListField } from "src/app/components-lib/shared-model/list-field";
import { readableUUID } from "src/app/core/events/event-listener-uuid";
import { AppMenuRouteEnum } from "src/app/core/model/data-model/enums/app-menu-route-enum";
import { FacilityJob } from "src/app/core/model/data-model/tables/facility-job";
import { UnitOfWork } from "src/app/core/model/data-model/tables/unit_of_work";
import { TablesNamesEnum } from "src/app/core/model/db-model/tables-names-enum";
import { FacilityJobTable } from "src/app/core/model/db-model/tables/facility-job-table";
import { FacilityJobTaskTable } from "src/app/core/model/db-model/tables/facility-job-task-table";
import { UnitOfWorkTable } from "src/app/core/model/db-model/tables/unit-of-work-table";
import { DbModelUtils } from "src/app/core/model/static-functions/db-model-utils";
import { DyntService } from "src/app/core/services/backend-services/dynt-service";
import { PaginationService } from "src/app/core/services/backend-services/pagination-service";
import { PageModel } from "src/app/ui/main/model/page-model";
import Container from "typedi";

export class MobilityJobTasks extends PageModel {
    adminTable: DynTableVM | undefined;

    private constructor() {
        super(AppMenuRouteEnum.planning_mobility_jobtasks, 0, readableUUID(MobilityJobTasks.name));
    }

    static async newAsync(): Promise<MobilityJobTasks> {
        const result = new MobilityJobTasks();
        result.adminTable = await result.loadAdminData("facility_job_task_dynview");
        return result;
    }

    async loadAdminData(tableName: string): Promise<DynTableVM> {
        const s = Container.get(DyntService);
        const p = Container.get(PaginationService);
        const paginator = await p.getPaginator(tableName);
        const viewSet = await s.contextedDataSet(tableName, 0, undefined, undefined, paginator?.pageItemsCount);
        const adminTable = DynTableVMBuilder.getVM(viewSet, tableName, paginator);

        // Chargement des valeurs actuelles pour les métiers
        const jobsIds = viewSet.viewData.map(x=> (x as any)[FacilityJobTaskTable.faJoTaFacilityJob]);
        const jobs = await s.downloadTable<FacilityJob>(TablesNamesEnum.FacilityJob, undefined, FacilityJobTable.faJoId, jobsIds);
        adminTable.setCurrentSelectOption(FacilityJobTaskTable.faJoTaFacilityJob, DbModelUtils.key(FacilityJobTable, FacilityJobTable.faJoId), DbModelUtils.key(FacilityJobTable, FacilityJobTable.faJoName), jobs);
        
        // Chargement des valeurs actuelles pour les unités d'oeuvre
        const uowIds = viewSet.viewData.map(x=> (x as any)[FacilityJobTaskTable.faJoTaUnitOfWork]);
        const uows = await s.downloadTable<UnitOfWork>(TablesNamesEnum.UnitOfWork, undefined, UnitOfWorkTable.unOfWoId, uowIds);
        adminTable.setCurrentSelectOption(FacilityJobTaskTable.faJoTaUnitOfWork, DbModelUtils.key(UnitOfWorkTable, UnitOfWorkTable.unOfWoId), DbModelUtils.key(UnitOfWorkTable, UnitOfWorkTable.unOfWoName), uows);
        
        adminTable.loadOptionsRequested = async (f: ListField, rowId: number) => {
            if (f.colId === FacilityJobTaskTable.faJoTaFacilityJob) {
                // Sélectionne les métier
                const jobs = await s.downloadTable<FacilityJob>(TablesNamesEnum.FacilityJob);
                return jobs.map(x=> new FieldListItem(x.faJoId, x.faJoName, null));
            }

            // Sélectionne les unités d'oeuvre
            const uows = await s.downloadTable<UnitOfWork>(TablesNamesEnum.UnitOfWork);
            return uows.map(x=> new FieldListItem(x.unOfWoId, x.unOfWoName, null));
        }
        
        adminTable.newPageDataRequested = async (pageIndex: number) => {
            const px = await s.contextedDataSet(tableName, pageIndex, undefined, undefined, adminTable.paginator?.pageItemsCount);
            adminTable.setDataSource(px.viewData);
        }

        adminTable.saveRequested = async (tableName: string, primaryColumnName: string, rowId: any, columnName: string, value: any): Promise<string | null> => {
            const s = Container.get(DyntService);
            const dto: any = {
                "TableName": tableName,
                [primaryColumnName]: rowId,
                [columnName]: value
            }
            return await s.patch(tableName, rowId, dto);
        }

        // TODO : cette méthode peut être mutualisée avec toutes les autres écoutes de ce type
        adminTable.insertionRequested = async (values : {}): Promise<any> => {
            const s = Container.get(DyntService);
            return await s.post<any>("", values);
        }

        // TODO : cette méthode peut être mutualisée avec toutes les autres écoutes de ce type
        adminTable.deletionRequested = async (tableName: string, rowId: any): Promise<boolean> =>  {
            const s = Container.get(DyntService);
            const result = await s.delete(tableName, rowId);
            if (result) {
                return true;
            }
            return false;
        }

        return adminTable;
    }
}