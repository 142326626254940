import { CanvasGizmo } from './../../model/canvas-gizmo';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'g[xc-canvas-gizmo]',
  standalone: true,
  imports: [],
  templateUrl: './canvas-gizmo.component.svg',
  styleUrl: './canvas-gizmo.component.scss'
})
export class CanvasGizmoComponent {
  @Input() model: CanvasGizmo | undefined;
}
