import { Field } from './field';
import { FieldTypeEnum } from './field-type-enum';
import { ZColumnView } from 'src/app/core/model/data-model/views/z-column-view';
import { ZColumnConstraintView } from 'src/app/core/model/data-model/views/z-column-constraint-view';
import { ZConstraintTypeEnum } from 'src/app/core/model/data-model/enums/z-constraint-type-enum';

export class DateField extends Field<Date | null> {

    constructor(colId: string, rowId: any, value: Date | null, definition: ZColumnView, constraints: ZColumnConstraintView[], readOnlyByScope: boolean) {
        super(FieldTypeEnum.date, colId, rowId, value, definition, constraints, readOnlyByScope);
        
        this.errorsCheck = this.specificErrorsCheck;
    }

    specificErrorsCheck(): string[] {
        let result: string[] = [];

        if (this.readOnly) return result;
        
        this.constraints.forEach(c => {
            switch (c.coCoConstraintType) {
                case ZConstraintTypeEnum.Minimum:
                    break;
                case ZConstraintTypeEnum.Maximum:
                    break;
                case ZConstraintTypeEnum.AllowsNull:
                    if (c.coCoValue === "false" && this.value == null) {
                        result.push(`${this.colId}: ${c.coCoVidisplayName}`);
                    }
                    break;
                default:
                    break;
            }
        });

        return result;
    }

    parseDate(dateString: string): Date {
        return new Date(dateString);
    }

    click(): void {
        this.editing = true;
    }
}