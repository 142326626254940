import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayersGrantsVM } from '../../model/layers-grants-vm';
import { TreeModule } from 'primeng/tree';
import { DataViewModule } from 'primeng/dataview';
import { CheckboxModule } from 'primeng/checkbox';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'xc-layers-grants',
  standalone: true,
  imports: [CommonModule,
    DataViewModule,
    CheckboxModule,
    FormsModule,
    TreeModule
  ],
  templateUrl: './layers-grants.component.html',
  styleUrls: ['./layers-grants.component.scss']
})
export class LayersGrantsComponent {
  @Input() model: LayersGrantsVM | undefined;
}
