import { SimulationTimelinesDTO } from 'src/app/core/services/backend-services/dto/simulation-timelines-dto';
import Container from "typedi";
import { SimulationService } from "src/app/core/services/backend-services/simulation-service";
import { StrategyViewsEnum } from "./strategy-views-enum";
import { StrategyWorkforceVM } from "../../../workforce/model/strategy-workforce-vm";
import { StrategyMenuCommandEnum } from "../../nested/toolbar/model/strategy-menu-command-enum";
import { CreationStepperVM } from '../../../creation-stepper/model/creation-stepper-vm';
import { SimulationCreationData } from '../../../creation-stepper/model/simulation-creation-data';
import { StrategySchedulingVM } from '../../../schedule/model/strategy-scheduling-vm';
import { SvgPhotoMarkerVM } from '../../../schedule/model/svg-photo-marker-vm';
import { StrategyStackingVM } from '../../../stack/model/strategy-stacking-vm';
import { EventListener } from 'src/app/core/events/event-listener';
import { readableUUID } from 'src/app/core/events/event-listener-uuid';
import { StrategyToolbarVM } from '../../nested/toolbar/model/strategy-toolbar-vm';
import { logError } from 'src/app/core/services/logging-service';

export class EditorMainContainerVM extends EventListener {
    creationStepper: CreationStepperVM | undefined;
    previousView: StrategyViewsEnum = StrategyViewsEnum.none;
    currentView: StrategyViewsEnum = StrategyViewsEnum.none;
    StrategyViewsEnum = StrategyViewsEnum;
    scheduling: StrategySchedulingVM;
    workforce: StrategyWorkforceVM;
    stacking: StrategyStackingVM;
    selectedSimulationId: number | null = null;

    constructor() {
        super(readableUUID(EditorMainContainerVM.name));

        this.scheduling = new StrategySchedulingVM();
        this.listenToStackingViewRequests();
        this.listenToSimulationLabelUpdate();
        this.workforce = new StrategyWorkforceVM();
        this.stacking = new StrategyStackingVM();

        this.setView(StrategyMenuCommandEnum.schedule);

        this.addEventListener(StrategyToolbarVM.selectedSimulationChangeEvent, async (id: number | null) => {
            await this.loadSimulation(id);
        });
    }

    setView(selectedCommand: StrategyMenuCommandEnum): void {
        switch (selectedCommand) {
            case StrategyMenuCommandEnum.schedule:
                this.changeView(StrategyViewsEnum.schedule);
                break;
            case StrategyMenuCommandEnum.workforce:
                this.changeView(StrategyViewsEnum.workforce);
                break;
            case StrategyMenuCommandEnum.stacking:
                this.changeView(StrategyViewsEnum.stacking);
                break;
            default:
                break;
        }
    }

    changeView(newView: StrategyViewsEnum): void {
        this.previousView = this.currentView;
        this.currentView = newView;
    }

    async loadSimulation(simulationId: number | null): Promise<void> {
        this.selectedSimulationId = simulationId;
        if (simulationId != null) {
            await this.loadView();
        } else {
            this.changeView(StrategyViewsEnum.none);
        }
    }

    async loadView(): Promise<void> {
        switch (this.currentView) {
            case StrategyViewsEnum.schedule:
                await this.scheduling.loadSimulation(this.selectedSimulationId!);
                break;
            case StrategyViewsEnum.workforce:
                await this.workforce.loadSimulation(this.selectedSimulationId!);
                break;
            // case StrategyViewsEnum.stacking:
            //     await this.stacking.loadStack(this.selectedSimulationId!);
            //     break;
            default:
                break;
        }
    }

    beginCreation(): void {
        this.changeView(StrategyViewsEnum.creationStepper);
        this.creationStepper = new CreationStepperVM();
        this.listenToCreationEndRequest(this.creationStepper);
    }

    creationEnded?: () => void;
    listenToCreationEndRequest(stepper: CreationStepperVM): void {
        stepper.creationEndRequested = async (creationData?: SimulationCreationData) => {
            this.changeView(this.previousView);
            this.creationStepper = undefined;

            if (creationData !== undefined) {
                const s = Container.get(SimulationService);
                const selectedBuildingsIds = creationData.selectedBuildings.map(x=> x.id);
                const selectedBusIds = creationData.selectedBus.map(x=> x.bu.buUnId);
                const result =  await s.createSimulation(creationData.name, creationData.endDate, null, selectedBuildingsIds, selectedBusIds);
                if (result !== null) {
                    const photoId = result;
                }
            }

            this.raiseCreationEndedEvent();
        }
    }

    raiseCreationEndedEvent(): void {
        if (this.creationEnded) {
            this.creationEnded();
        } else {
            logError("EditorMainContainerVM.creationEnded n'est pas écouté");
        }
    }

    stackingViewRequested?: (p: SvgPhotoMarkerVM) => void;
    listenToStackingViewRequests(): void {
        this.scheduling.stackingViewRequested = async (p: SvgPhotoMarkerVM) => {
            this.changeView(StrategyViewsEnum.stacking);
            await this.stacking.loadStack(p.photo.siPhId);
            if (this.stackingViewRequested) {
                this.stackingViewRequested(p);
            } else {
                logError("EditorMainContainerVM.stackingViewRequested n'est pas écouté");
            }
        }
    }

    simulationLabelUpdated?: (s: SimulationTimelinesDTO) => void;
    listenToSimulationLabelUpdate(): void {
        this.scheduling.simulationLabelUpdated = (s: SimulationTimelinesDTO) => {
            if (this.simulationLabelUpdated) {
                this.simulationLabelUpdated(s);
            } else {
                logError("EditorMainContainerVM.simulationLabelUpdated n'est pas écouté");
            }
        }
    }
}