import { readableUUID } from "src/app/core/events/event-listener-uuid";
import { AppMenuRouteEnum } from "src/app/core/model/data-model/enums/app-menu-route-enum";
import { PageModel } from "src/app/ui/main/model/page-model";
import { ProvidersManagePager } from "./providers-manage-pager";

export class ProvidersManage extends PageModel {
    pager: ProvidersManagePager | undefined;

    private constructor() {
        super(AppMenuRouteEnum.layout_inventory_providers, 0, readableUUID(ProvidersManage.name));
    }

    static async newAsync(): Promise<ProvidersManage> {
        const result = new ProvidersManage();
        result.pager = await ProvidersManagePager.newAsync();
        return result;
    }
}