import { Point } from "src/app/core/model/geometry-model/point.model";
import { DxfSvgEntity } from "./dxf-svg-entity";
import { SvgEntity } from "src/app/core/model/svg-model/svg-entity.model";
import { SvgPathService } from "src/app/core/model/svg-model/svg-path-service";
import { Vector } from "src/app/core/model/geometry-model/vector.model";
import { SvgEntityTypesEnum } from "src/app/core/model/svg-model/svg-entity-type-enum";
import { SvgEntityPoint } from "../../../shared/gizmos/model/svg-entity-point";
import { SvgEntityPointStyleEnum } from "../../../shared/gizmos/model/svg-entity-point-style-enum";
import { SvgEntityParser } from "../../../shared/gizmos/model/svg-entity-parser";

export class DxfSvgPath extends DxfSvgEntity {
    d: string;

    constructor(dtoData: any) {
        super(dtoData);
        this.d = SvgEntity.getValue(dtoData, "d");
    }

    protected override getSelectablePoints(): SvgEntityPoint[] {
        return SvgEntityPoint.toStyle(SvgPathService.getVertices(this.d), SvgEntityPointStyleEnum.end, this.entityId);
    }

    protected override getStatement(): string {
        const attributesStatement = SvgEntityParser.getAttributesStatement([["d", this.d], ["stroke-width", this.strokeWidth], ["stroke", this.stroke]]);
        return SvgEntityParser.getTagStatement("path", attributesStatement);
    }

    startPoint(): Point | undefined {
        return SvgPathService.getPathStartPoint(this.d);
    }

    translate(delta: Vector): void {
        let tmp = this.d;
        let index = 0;
        let part = this.d.substring(index);
        this.selectablePoints.forEach(p => {
            const pn = p.translate(delta);
            const ps = `${p.x} ${p.y}`;
            const pi = part.indexOf(ps);
            const pns = `${pn.x} ${pn.y}`;
            const partn = part.replace(ps, pns);
            tmp = tmp.replace(part, partn);
            index = pi + ps.length;
            part = part.substring(index);
        });
        this.d = tmp;
        this.updateSelectablePoints();
    }

    translatePoint(p: Point, target: Point): void {
        let tmp = this.d;
        const ps = `${p.x} ${p.y}`;
        const pns = `${target.x} ${target.y}`;
        tmp = tmp.replace(ps, pns);
        this.d = tmp;
        this.updateSelectablePoints();
    }

    static fromValues(layerId: number, d: string, strokeWidth: number): DxfSvgPath {
        return new DxfSvgPath({entityType: SvgEntityTypesEnum.path, entityId: 0, layerId: layerId, d: d, strokeWidth: strokeWidth});
    }
}