import { WallUpdateGizmoVM } from './../../model/wall-update-gizmo-vm';
import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectionRotationLabelComponent } from '../../../../selection/view/selection-rotation-label/selection-rotation-label.component';

@Component({
  selector: 'g[xc-wall-update-gizmo]',
  standalone: true,
  imports: [CommonModule, SelectionRotationLabelComponent],
  templateUrl: './wall-update-gizmo.component.svg',
  styleUrls: ['./wall-update-gizmo.component.scss']
})
export class WallUpdateGizmoComponent {
  @Input() model: WallUpdateGizmoVM | undefined;
}
