import { SliceAnonymousData } from "src/app/ui/shared/shared-model/slice-anonymous-data";
import { InventoryReportRow } from "./inventory-report-row";

export class InventoryChartBuilder {
    static getAnonymousData(r: InventoryReportRow): SliceAnonymousData[] {
        const result: SliceAnonymousData[] = [];

        result.push(new SliceAnonymousData(1, "#33ffbd", "Installés", r.roomed));
        result.push(new SliceAnonymousData(1, "#33d7ff", "En stock", r.stored));

        return result;
    }
}