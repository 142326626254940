import { Service } from "typedi";
import { Point } from 'src/app/core/model/geometry-model/point.model';
import { HttpParams } from '@angular/common/http';
import { FloorModelEnum } from 'src/app/core/model/data-model/enums/floor-model-enum';
import { Vector } from 'src/app/core/model/geometry-model/vector.model';
import { FloorDataTable } from "src/app/core/model/db-model/tables/floor-data-table";
import { FloorWallStyleTable } from "src/app/core/model/db-model/tables/floor-wall-style-table";
import { ApiControllers } from "src/app/core/services/api-endpoints";
import { ApiService } from "src/app/core/services/api-service";
import { BpSvgGroup } from "src/app/ui/pages/layout/real-estate/floor-blueprint/content/bp-svg-core-model/bp-svg-group";
import { UpdatedFloorDataDTO } from "src/app/ui/pages/layout/real-estate/floor-blueprint/content/services/dto/updated-floor-data-dto";
import { BlueprintSvgEntityTypeEnum } from "src/app/ui/pages/layout/real-estate/floor-blueprint/content/svg-entities/model/blueprint-svg-entity-type-enum";
import { BpSvgDoor } from "src/app/ui/pages/layout/real-estate/floor-blueprint/content/svg-entities/model/bp-svg-door";
import { BpSvgWall } from "src/app/ui/pages/layout/real-estate/floor-blueprint/content/svg-entities/model/bp-svg-wall";

@Service({ global: true })  
export class BpWallService extends ApiService {
    private wallStretchEndpoint = `${ApiControllers.floorDataWalls}/stretch`;
    private wallStyleEndpoint = `${ApiControllers.floorDataWalls}/wallstyle`;
    private wallWidthEndpoint = `${ApiControllers.floorDataWalls}/wallwidth`;
    private wallSpaceBoundEndpoint = `${ApiControllers.floorDataWalls}/spacebound`;
    private wallTranslateEndpoint = (floorDataId: number): string => `${ApiControllers.floorDataWalls}/${floorDataId}/translate`;

    constructor() {
        super();
    }

    async stretchWall(wallId: number, newPosition: Point, startPoint: boolean): Promise<{walls: BpSvgWall[], doors: BpSvgDoor[], removedFloorDataIds: number[]} | null> {
        const params = new HttpParams()
            .set('wallId', String(wallId))
            .set('x', String(newPosition.x))
            .set('y', String(newPosition.y))
            .set('startPoint', String(startPoint));
        const result = await this.patchAsync<any>(this.endPoint(this.wallStretchEndpoint), null, undefined, params); 
        if (result) {
            const walls: BpSvgWall[] = [];
            const doors: BpSvgDoor[] = [];
            result.payload['SvgGroupDTO'].map((item: any) => {
                const e = item['entities'][0];
                if (item.floorModelId === FloorModelEnum.Walls) {
                    walls.push(new BpSvgWall(item['entities'][0], item));
                } else {
                    doors.push(new BpSvgDoor(item));
                }
            });
            return {
              walls: walls,
              doors: doors,
              removedFloorDataIds: result.payload['RemovedFloorDataIds'] as number[],
            };
        } 
        return null;
    }
        
    async updateWallsStyle(floorDataIds: number[], wallStyleId: number): Promise<BpSvgWall[]> {
        const dto: any = {};
        dto['WallIds'] = floorDataIds;
        dto['UpdateValue'] = wallStyleId;
        const result = await this.patchAsync<any[]>(this.endPoint(this.wallStyleEndpoint), dto);
        if (result) {
            return result.payload.map(item => {
                const g = item;
                return new BpSvgWall(g['entities'][0], g);
                //return new BpSvgGroup(item);
            })
        }
        return [];
    }
    
    async updateWallsWidth(floorDataIds: number[], width: number): Promise<BpSvgGroup[]> {
      const dto: any = {};
      dto['WallIds'] = floorDataIds;
      dto['UpdateValue'] = width;
      const result = await this.patchAsync<any[]>(this.endPoint(this.wallWidthEndpoint), dto);
      if (result) {
          return result.payload.map(item => {
            if (item.floorModelId === FloorModelEnum.Walls) {
                return new BpSvgGroup(item, BlueprintSvgEntityTypeEnum.none);
              }
              return new BpSvgDoor(item);
          })
      }
      return [];
    }
    
    async updateWallsSpaceBound(floorDataIds: number[], spaceBound: boolean): Promise<BpSvgWall[]> {
    const dto: any = {};
    dto['WallIds'] = floorDataIds;
    dto['UpdateValue'] = spaceBound;
    const result = await this.patchAsync<any[]>(this.endPoint(this.wallSpaceBoundEndpoint), dto);
    if (result) {
        return result.payload.map(item => {
            //const g = new BpSvgGroup(item, BlueprintSvgEntityTypeEnum.none);
            return new BpSvgWall(item['entities'][0], item);
            //return new BpSvgGroup(item);
        })
    }
    return [];
}
    
    async translateWall(floorDataId: number, delta: Vector): Promise<BpSvgGroup[]> {
        const result = await this.patchAsync<any[]>(this.endPoint(this.wallTranslateEndpoint(floorDataId)), delta);
        if (result) {
            return result.payload.map(item => {
              if (item.floorModelId === FloorModelEnum.Walls) {
                  return new BpSvgGroup(item, BlueprintSvgEntityTypeEnum.none);
                }
                return new BpSvgDoor(item);
            })
        }
        return [];
    }
    
    async createWall(taskId: number, startPoint: Point, endPoint: Point, wallStyleId: number, wallWidth: number, spaceBound: boolean): Promise<BpSvgWall | null> {
        const dto: any = {};
        dto[FloorDataTable.flDaTaskId] = taskId;
        dto['StartPoint'] = {
            X: startPoint.x,
            Y: startPoint.y
        };
        dto['EndPoint'] = {
            X: endPoint.x,
            Y: endPoint.y
        };
        dto[FloorWallStyleTable.flWaStId] = wallStyleId;
        dto['Width'] = wallWidth;
        dto['SpaceBound'] = spaceBound;
        const result = await this.postAsync<any>(this.endPoint(ApiControllers.floorDataWalls), dto);
        if (result) {
            const g = result.payload['SvgGroupDTO'];
            return new BpSvgWall(g['entities'][0], g);
        }
        return null;
    }
    
    async deleteWall(wallIds: number[]): Promise<UpdatedFloorDataDTO[]> {
        let params = new HttpParams();
        wallIds.forEach(id => {
            params = params.append(`id`, String(id));
        });
        const result = await this.deleteAsync<UpdatedFloorDataDTO[]>(this.endPoint(ApiControllers.floorDataWalls), params);
        if (result != null) {
            return result.payload.map(item => {
                return new UpdatedFloorDataDTO(item);
            })
        }
        return [];
    }
}