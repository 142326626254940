import { BusinessUnitsTreeComponent } from '../business-units-tree/business-units-tree.component';
import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ResizableLeftPanelVM } from 'src/app/components-lib/resizable-left-panel/model/resizable-left-panel-vm';
import { BusinessUnitsBrowserVM } from '../../model/business-units-browser-vm';
import { SplitterModule } from 'primeng/splitter';
import { DynTableComponent } from 'src/app/components-lib/dyn-table/dyn-table.component';
import { ReportGridComponent } from 'src/app/components-lib/report-grid/view/report-grid/report-grid.component';
import { DonutChartWrapperComponent } from 'src/app/ui/shared/charts/donut/view/donut-chart-wrapper/donut-chart-wrapper.component';
import { PropertiesEditorComponent } from '../../../real-estate/properties-editor/view/properties-editor/properties-editor.component';

@Component({
  selector: 'pb-business-units-browser',
  standalone: true,
  imports: [
    CommonModule, 
    SplitterModule, 
    BusinessUnitsTreeComponent, 
    DonutChartWrapperComponent, 
    DynTableComponent, 
    PropertiesEditorComponent, 
    ReportGridComponent
  ],
  templateUrl: './business-units-browser.component.html'
})
export class BusinessUnitsBrowserComponent extends ResizableLeftPanelVM {
  @Input() override model: BusinessUnitsBrowserVM | undefined;
}
