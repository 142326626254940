import { FloorModelTable } from 'src/app/core/model/db-model/tables/floor-model-table';
import { ApiControllers } from 'src/app/core/services/api-endpoints';
import { FloorDataTable } from 'src/app/core/model/db-model/tables/floor-data-table';
import { Point } from 'src/app/core/model/geometry-model/point.model';
import { ApiService } from "src/app/core/services/api-service";
import { Service } from "typedi";
import { BpSvgMeasurementLabel } from 'src/app/ui/pages/layout/real-estate/floor-blueprint/content/svg-entities/model/bp-svg-measurement-label';
import { BlueprintLabelLayer } from 'src/app/ui/pages/layout/real-estate/floor-blueprint/content/svg-entities/model/layers/blueprint-label-layer';

@Service({ global: true })  
export class MeasurementService extends ApiService {
  private updateEndPoint: string = `${ApiControllers.floorDataMeasurement}/update`;

  constructor() {
      super();
  }
  
  async insertNew(taskId: number, startPoint: Point, endPoint: Point, startFloorDataId: number | null, endFloorDataId: number | null): Promise<{label: BpSvgMeasurementLabel, layer?: BlueprintLabelLayer } | null> {
    const dto: any = {};
    dto[FloorDataTable.flDaTaskId] = taskId;
    dto['x1'] = startPoint.x;
    dto['y1'] = startPoint.y;
    dto['x2'] = endPoint.x;
    dto['y2'] = endPoint.y;
    dto['StartFloorDataId'] = startFloorDataId;
    dto['EndFloorDataId'] = endFloorDataId;

    const result = await this.postAsync<any>(this.endPoint(ApiControllers.floorDataMeasurement), dto);
    if (result) {
        let taskLayer;
        const label = new BpSvgMeasurementLabel(result.payload['SvgGroupDTO'][0])
        if (result.payload[FloorModelTable.databaseTableName] != null) {
          //const taskId = Number(result.payload[FloorModelTable.databaseTableName][FloorDataTable.flDaTaskId]);
          taskLayer = new BlueprintLabelLayer(result.payload[FloorModelTable.databaseTableName], label.taskId);
        }
        return {
          label: label,
          layer: taskLayer,
        };
    }
    return null;
  }

  async update(floorDataId: number, startPoint: Point, endPoint: Point, startFloorDataId: number | null, endFloorDataId: number | null): Promise<BpSvgMeasurementLabel | null> {
    const dto: any = {};
    dto[FloorDataTable.flDaId] = floorDataId;
    dto['x1'] = startPoint.x;
    dto['y1'] = startPoint.y;
    dto['x2'] = endPoint.x;
    dto['y2'] = endPoint.y;
    dto['StartFloorDataId'] = startFloorDataId;
    dto['EndFloorDataId'] = endFloorDataId;

    const result = await this.patchAsync<any>(this.endPoint(this.updateEndPoint), dto);
    if (result) {
      return new BpSvgMeasurementLabel(result.payload);
    }

    return null;
  }
}