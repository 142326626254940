@if (model != null) {
    <p-tree 
        [value]="model.contents" 
        class="w-full" 
        styleClass="p-0 border-0"
        selectionMode="single" 
        [(selection)]="model.selectedContent"
        (selectionChange)="model.selectedContentChange()">
        <ng-template let-node pTemplate="default">
            <div >
                <div class="pinColor" 
                  [style.background-color]="node.data.buUnColor">
                </div>
                <span>{{node.data.buUnName}}</span>
              </div>
        </ng-template>
    </p-tree>
}

