import { TaskFunctionTable } from '../../db-model/tables/task-function-table';
import { DefinedModelBase } from '../defined-model-base';

export class TaskFunction extends DefinedModelBase {
    taFuId!: number;
    taFuName!: string;
    taFuIsActive!: boolean;
    taFuDisplayNameId!: number;
    taFuScope!: number;

    constructor(dtoData: any, tableDef: any[] = []) {
        super(dtoData, [TaskFunctionTable].concat(tableDef));
    }
}