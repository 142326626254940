import { Vector } from "src/app/core/model/geometry-model/vector.model";
import { SvgCircle } from "src/app/core/model/svg-model/svg-circle.model";
import { SvgCircleService } from "src/app/core/model/svg-model/svg-circle.service";
import { SvgEntityTypesEnum } from "src/app/core/model/svg-model/svg-entity-type-enum";
import { SvgEntity } from "src/app/core/model/svg-model/svg-entity.model";
import { SvgEntityPoint } from "../../shared/gizmos/model/svg-entity-point";
import { SvgEntityPointStyleEnum } from "../../shared/gizmos/model/svg-entity-point-style-enum";
import { SvgEntityParser } from "../../shared/gizmos/model/svg-entity-parser";
import { SelectableSvgEntity } from "./selectable-svg-entity";
import { Point } from "src/app/core/model/geometry-model/point.model";
import { createRandomString } from "./selectable-entity-builder";

export class SelectableSvgCircle extends SelectableSvgEntity {
    cx: number;
    cy: number;
    r: number;

    quadrants: Point[] = [];

    constructor(dtoData: any) {
        super(dtoData);
        this.cx = SvgEntity.getNumber(dtoData, "cx");
        this.cy = SvgEntity.getNumber(dtoData, "cy");
        this.r = SvgEntity.getNumber(dtoData, "r");
    }

    override getSelectablePoints(): SvgEntityPoint[] {
        const result = [new SvgEntityPoint(SvgEntityPointStyleEnum.center, new Point(this.cx, this.cy))];
        const c = SvgCircleService.geometry(this as SvgCircle);
        this.quadrants = c.getQuadrants();
        result.push(...SvgEntityPoint.toStyle(this.quadrants, SvgEntityPointStyleEnum.quadrant, this.entityId));
        return result;
    }

    override getStatement(): string {
        const attributesStatement = SvgEntityParser.getAttributesStatement([["cx", this.cx], ["cy", this.cy], ["r", this.r], ["stroke-width", this.strokeWidth], ["stroke", this.stroke]]);
        return SvgEntityParser.getTagStatement("circle", attributesStatement);
    }

    translate(delta: Vector): void {
        const cn = this.center().translate(delta);
        this.cx = cn.x;
        this.cy = cn.y;
    }

    center(): Point {
        return new Point(this.cx, this.cy);
    }

    isCenter(p: Point): boolean {
        return p.x === this.cx && p.y === this.cy;
    }

    changeRadius(delta: number): void {
        if (this.r + delta > 0) {
            this.r += delta;
        }
    }

    static fromValues(centerPoint: Point, radius: number, strokeWidth: number, stroke: string = "black", fill: string = "none"): SelectableSvgCircle {
        return new SelectableSvgCircle({entityType: SvgEntityTypesEnum.circle, entityId: createRandomString(8), cx: centerPoint.x, cy: centerPoint.y, r: radius, strokeWidth: strokeWidth, stroke: stroke, fill: fill});
    }
}