export class EquipmentCategoryReportDTO {
    roomed!: number; 
    stored!: number; 
    removed!: number; 
    roomedValue!: number; 
    storedValue!: number; 
    removedValue!: number; 
    unidentifiedCount!: number;

    constructor(dtoData: any) {
        this.roomed = dtoData.roomed; 
        this.stored = dtoData.stored; 
        this.removed = dtoData.removed; 
        this.roomedValue = dtoData.roomedValue; 
        this.storedValue = dtoData.storedValue; 
        this.removedValue = dtoData.removedValue; 
        this.unidentifiedCount = dtoData.unidentifiedCount;
    }
}