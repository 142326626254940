import { getGeometry } from 'src/app/core/model/geometry-model/geometric-elements-builder';
import { SvgEntityTypesEnum } from "src/app/core/model/svg-model/svg-entity-type-enum";
import { InsertGizmo } from "./insert-gizmo";
import { Point } from "src/app/core/model/geometry-model/point.model";
import { SvgPathService } from "src/app/core/model/svg-model/svg-path-service";
import { SvgEntity } from "src/app/core/model/svg-model/svg-entity.model";
import { SvgLine } from "src/app/core/model/svg-model/svg-line.model";
import { SvgConstants } from "src/app/core/model/svg-model/svg-constants";
import { SvgPath } from "src/app/core/model/svg-model/svg-path.model";
import { Vector } from "src/app/core/model/geometry-model/vector.model";
import { PathBuilder } from "src/app/core/model/svg-model/svg-path-builder";
import { XcMaths } from "src/app/core/model/static-functions/xc-maths";
import { Segment } from "src/app/core/model/geometry-model/segment.model";
import { SvgLineService } from 'src/app/core/model/svg-model/svg-line-service';
import { IntersectionService } from 'src/app/core/model/geometry-model/intersection-service';
import { Arc } from 'src/app/core/model/geometry-model/arc.model';
import { logError } from 'src/app/core/services/logging-service';

export class PathInsertGizmo extends InsertGizmo {
    segments: SvgEntity[] = [];
    arcTempSecondPoint: Point | undefined;
    currentSegmentType: SvgEntityTypesEnum = SvgEntityTypesEnum.line;
    currentPoint: Point | undefined;
    strokeWidth: number = 0.001;
    startPointIsDefined: boolean = false;
    isClosed: boolean = false;
    SvgEntityTypesEnum = SvgEntityTypesEnum;
    drawTangent: boolean = true;

    constructor() {
        super(SvgEntityTypesEnum.path);
        this.initialize();
    }

    protected override initialize(): void {
        this.segments.splice(0);
        this.segments.push(SvgLine.fromValues(0, 0, 0, 0));
        this.currentSegmentType = SvgEntityTypesEnum.line;
        this.startPointIsDefined = false;
        this.isClosed = false;
        this.completed = false;
    }

    override update(point: Point): void {
        this.currentPoint = point;

        if (!this.startPointIsDefined) {
            this.updateFakeSegment(point);
        } else {

            const last = this.segments[this.segments.length - 1];
            switch (last.entityType) {
                case SvgEntityTypesEnum.line:
                    const l = last as SvgLine;
                    l.x2 = point.x;
                    l.y2 = point.y;
                    break;
                case SvgEntityTypesEnum.path:
                    const p = last as SvgPath;
                    const datas = SvgPathService.getPathEllipticalArcValues(p.d!);
                    if (datas) {
                        const cep = `${datas.startPoint.x} ${datas.startPoint.y}`;
                        const nep = `${point.x} ${point.y}`;

                        const dist = XcMaths.round(datas.startPoint.distanceTo(point), 3);
                        let r = Number.isNaN(datas.xRadius) ? 0.01 : datas.xRadius;
                        if (!this.drawTangent) r = dist * 4;
                        let dir = datas.isClockwise;
                        let larg = datas.isLargArc;

                        if (this.segments.length > 1) {
                            const prev = this.segments[this.segments.length - 2];
                            let prevSeg: Segment = Segment.null();
                            let prevSide = 1;
                            if (prev.entityType === SvgEntityTypesEnum.line) {
                                prevSeg = SvgLineService.geometry(prev as SvgLine);
                            } else {
                                // le segment précédent est un arc
                                // on considère la tangente à son point final
                                const a = prev as SvgPath;
                                const arc = SvgPathService.getPathEntities(a.d!)[0] as Arc;
                                // on est en svg donc l'arc tourne dans l'autre sens
                                arc.isClockWise = !arc.isClockWise;
                                const c = arc.center();
                                const ar = new Segment(c, arc.endPoint);
                                // quand l'arc est clockwise, le centre est à droite de la tangente
                                // quand l'arc est anti-clockwise, le centre est à gauche
                                const t = arc.isClockWise ? -10 : 10;
                                prevSeg = new Segment(ar.getOrthogonalOffset(arc.endPoint, t), arc.endPoint);
                            }
                            prevSide = prevSeg.isRightHand(point) ? 1 : -1;
                
                            if (this.drawTangent) {
                                // le centre du cercle recherché est tel que
                                // sa projection orthogonale sur le segment précédent tombe sur le point de terminaison de ce segment
                                // et sa projection orthogonale sur la corde tombe au milieu de celle-ci
                                const chord = new Segment(datas.startPoint, point);
                                const chodhMid = chord.midPoint();
                                const endSegProj = new Segment(prevSeg.endPoint, prevSeg.getOrthogonalOffset(prevSeg.endPoint, 1)).stretched(100);
                                const chordProj = new Segment(chodhMid, chord.getOrthogonalOffset(chodhMid, 1)).stretched(100);
                                const intersect = chordProj.getIntersect(endSegProj);
                                if (!prevSeg.isRightHand(point)) dir = 1;
                                const prevOrtho = prevSeg.getOrthogonalProjection(point);
                                const ext = prevSeg.stretchedStart(100);
                                if (ext.contains(prevOrtho, 0.01)) {
                                    larg = 1;
                                }

                                if (intersect) {
                                    r = intersect.distanceTo(prevSeg.endPoint);
                                }
                            }
                        }
                        p.d = `M${cep}A${r} ${r} ${datas.xAxisRotation} ${larg} ${dir} ${nep}`;
                        }
                    break;
                default:
                    break;
            }
        } 
    }

    updateFakeSegment(point: Point): void {
        if (this.segments.length === 1) {
            const ep = point.translate(new Vector(0.05, -0.05));
            if (this.segments[0].entityType === SvgEntityTypesEnum.line) {
                const l = this.segments[0] as SvgLine;
                l.x1 = point.x;
                l.y1 = point.y;
                l.x2 = ep.x;
                l.y2 = ep.y;
            } else {
                const p = this.segments[0] as SvgPath;
                const psp = SvgPathService.getPathStartPoint(p.d);
                const pep = SvgPathService.getPathEndPoint(p.d);
                if (psp && pep) {
                    const csp = `${psp.x} ${psp.y}`;
                    const cep = `${pep.x} ${pep.y}`;
                    const nsp = `${point.x} ${point.y}`;
                    const nep = `${ep.x} ${ep.y}`;
                    p.d = p.d?.replace(csp, nsp);
                    p.d = p.d?.replace(cep, nep);
                }
            }
        }
    }

    getGlobalStatement(): string {
        let tmp: string = "";
        if (this.segments.length > 0) {
            const size = this.segments.length - 1;
            const s1 = this.segments[0];
            tmp = this.getEntityStatement(s1, true);
            if (size === 1) return tmp;
            for (let i = 0; i < size; i++) {
                const e = this.segments[i];
                tmp += " " + this.getEntityStatement(e);
            }

            if (this.isClosed) {
                const last = this.segments[size];
                if (last.entityType === SvgEntityTypesEnum.path) {
                    let sp: Point | undefined = undefined;
                    if (s1.entityType === SvgEntityTypesEnum.line) {
                        const l = s1 as SvgLine;
                        sp = new Point(l.x1, l.y1);
                    } else {
                        const p = s1 as SvgPath;
                        sp = SvgPathService.getPathStartPoint(p.d!);
                    }
                    tmp += ` ${SvgConstants.PathAbsoluteLineToFlagName}${sp!.x} ${sp!.y}`;
                } else {
                    tmp += " Z";
                }
            }
        }
        return tmp;
    }

    getEntityStatement(entity: SvgEntity, first: boolean = false): string {
        switch (entity.entityType) {
            case SvgEntityTypesEnum.line:
                return this.getLineStatement(first ? SvgConstants.PathAbsoluteMoveToFlagName : SvgConstants.PathAbsoluteLineToFlagName, entity as SvgLine);
            case SvgEntityTypesEnum.path:
                return this.getPathStatement(entity as SvgPath, first);
            default:
                return "";
        }
    }

    getLineStatement(flag: string, line: SvgLine): string {
        if (flag === SvgConstants.PathAbsoluteMoveToFlagName) {
            return `${flag}${line.x1} ${line.y1} ${SvgConstants.PathAbsoluteLineToFlagName}${line.x2} ${line.y2}`;
        }
        return `${flag}${line.x2} ${line.y2}`;
    }

    getPathStatement(path: SvgPath, first: boolean): string {
        if (first) {
         return path.d!;
       }
       const aFlagIndex = path.d!.indexOf(SvgConstants.PathArcFlagName);
       return path.d!.substring(aFlagIndex);
    }

    override define(point: Point): void {
        if (this.startPointIsDefined) {
            const last = this.segments[this.segments.length - 1];
            if (this.currentSegmentType === SvgEntityTypesEnum.line) {
                const l = last as SvgLine;
                const lep = new Point(l.x2, l.y2);
                this.segments.push(SvgLine.fromValues(lep.x, lep.y, point.x, point.y));
            } else {
                const p = last as SvgPath;
                const pep = SvgPathService.getPathEndPoint(p.d);
                if (pep) {
                    const dist = XcMaths.round(pep.distanceTo(point), 3);
                    const ap = PathBuilder.getArcPath(pep, point, dist, 0, 1);
                    this.segments.push(SvgPath.fromStatement(`${SvgConstants.PathAbsoluteMoveToFlagName}${ap}`));
                }
            }
        } else {
            this.startPointIsDefined = true;
        }

        this.currentPoint = point;
    }

    setCurrentType(type: SvgEntityTypesEnum): void {
        this.currentSegmentType = type;
        let last = this.segments.splice(this.segments.length - 1, 1)[0];
        if (last.entityType !== type) {
            if (type === SvgEntityTypesEnum.line) {
                const p = last as SvgPath;
                const sp = SvgPathService.getPathStartPoint(p.d);
                const ep = SvgPathService.getPathEndPoint(p.d);
                if (sp && ep) {
                    this.segments.push(SvgLine.fromValues(sp.x, sp.y, ep.x, ep.y));
                }

            } else {
                const l = last as SvgLine;
                const sp = new Point(l.x1, l.y1);
                const ep = new Point(l.x2, l.y2);
                const dist = XcMaths.round(sp.distanceTo(ep), 3);
                const ap = PathBuilder.getArcPath(sp, ep, dist, 0, 1);
                this.segments.push(SvgPath.fromStatement(`${SvgConstants.PathAbsoluteMoveToFlagName}${ap}`));
            }

            this.update(this.currentPoint!);
        }
    }

    close(): void {
        if (this.segments.length >= 2) {
            switch (this.currentSegmentType) {
                case SvgEntityTypesEnum.line:
                    break;
                case SvgEntityTypesEnum.path:
                    break;
                default:
                    break;
            }
            this.isClosed = true;
            this.raiseInsertionCompleted();
        }
    }

    end(): void {

        this.raiseInsertionCompleted();
    }
    
    raiseInsertionCompleted(): void {
        if (this.insertionCompleted) {
            this.insertionCompleted();
        } else {
            logError("PathInsertGizmo.insertionCompleted n'est pas écouté");
        }
    }
}