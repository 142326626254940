import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MaterialModule } from 'src/app/core/material.module';
import { FloorTasksTabModel } from '../../model/floor-tasks-tab-model';
import { AccordionModule } from 'primeng/accordion';
import { ButtonModule } from 'primeng/button';

@Component({
  standalone: true,
  imports: [CommonModule, MaterialModule, FormsModule, AccordionModule, ButtonModule],
  selector: 'xc-planning-task-form',
  templateUrl: './planning-task-form.component.html',
  styleUrls: ['./planning-task-form.component.scss']
})
export class PlanningTaskFormComponent {
  @Input() model: FloorTasksTabModel | undefined;
}
