import { Component, Input } from '@angular/core';
import { PictosBrowser } from '../../model/picto-browser';
import { DropdownModule } from 'primeng/dropdown';
import { ListboxModule } from 'primeng/listbox';
import { FormsModule } from '@angular/forms';
import { SecuredImageComponent } from 'src/app/components-lib/secured-image/secured-image.component';
import { MVVMEventDestroyLifeCycle } from 'src/app/components-lib/shared-model/mvvm-events-destroy-lifecycle';

@Component({
  selector: 'xc-pictos-browser',
  standalone: true,
  imports: [DropdownModule, FormsModule, ListboxModule, SecuredImageComponent],
  templateUrl: './pictos-browser.component.html',
  styleUrls: ['./pictos-browser.component.scss']
})
export class PictosBrowserComponent extends MVVMEventDestroyLifeCycle {
  @Input() override model: PictosBrowser | undefined;
}
