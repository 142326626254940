import Container from 'typedi';
import { BusinessUnitService } from 'src/app/core/services/backend-services/business-unit-service';
import { BusinessUnit } from 'src/app/core/model/data-model/tables/business-unit';
import { BusinessUnitTable } from 'src/app/core/model/db-model/tables/business-unit-table';
import { EventListener } from 'src/app/core/events/event-listener';
import { TreeNode } from 'primeng/api';
import { BusinessUnitsEventsEnum } from './business-units-events-enum';

export class BusinessUnitsTreeModel extends EventListener {
    static seletedNodeChange = "seletedNodeChange";

    contents: TreeNode<BusinessUnit>[] = [];
    selectedContent: TreeNode<BusinessUnit> | null | undefined = null;

    buHierarchy: BusinessUnit[] = [];

    constructor() {
        super("ef3faabc-4d40-4a97-92f8-de620832b0f1");

        this.addEventListener(BusinessUnitsEventsEnum.treeRefreshRequested, async () => {
            //await this.refreshTree();
        });

        this.addEventListener(BusinessUnitsEventsEnum.treeUpdateRequested, (id: number, columnName: string, value: any) => {
            this.updateNode(id, columnName, value);
        });
    }

    async loadTree(): Promise<void> {
        const s = Container.get(BusinessUnitService);
        this.buHierarchy = await s.loadGrantedItems(true);
        const rootNode = this.getRootNode();
        rootNode.children = this.getNodeChildren(null)
        this.contents = [rootNode];
        rootNode.expanded = true;
        //this.treeControl.expand(rootNode);
    }

    // async refreshTree(): Promise<void> {
    //     const currentSelectId = this.selectedNode?.bu.buUnId;
    //     await this.loadTree();
    //     if (currentSelectId) {
    //         const node = this.getNode(this.dataSource.data, currentSelectId);
    //         if (node) {
    //             node.selected = true;
    //             this.selectedNode = node;
    //             this.expandRecursively(node);
    //         }
    //     }
    // }

    // expandRecursively(node: BusinessUnitTreeNode): void {
    //     this.treeControl.expand(node);
    //     const parentId = node.bu.buUnParentId;
    //     const parentNode = this.getNode(this.dataSource.data, parentId == null ? 0 : parentId);
    //     if (parentNode && !this.treeControl.isExpanded(parentNode)) {
    //         this.expandRecursively(parentNode);
    //     }
    // }

    updateNode(id: number, columnName: string, value: any): void {
        // const node = this.getNode(this.dataSource.data, id);
        // if (node) {
        //     (node.bu as any)[columnName] = value;
        // }
    }

    getRootNode(): TreeNode<BusinessUnit> {
        const fakeBu = new BusinessUnit({
            [BusinessUnitTable.buUnId]: 0,
            [BusinessUnitTable.buUnCode]: "ROOT",
            [BusinessUnitTable.buUnName]: "Organisation",
            [BusinessUnitTable.buUnDepth]: "0",
            [BusinessUnitTable.buUnParentId]: null,
            [BusinessUnitTable.buUnColor]: "#ffffff",
            [BusinessUnitTable.buUnIsActive]: true,
            [BusinessUnitTable.buUnIsFictive]: false,
            [BusinessUnitTable.buUnVacancyRatio]: 0,
            [BusinessUnitTable.buUnFlexibilityRatio]: 0,
            [BusinessUnitTable.buUnOccupancyRatio]: 0,
            [BusinessUnitTable.buUnAnatomyId]: null,
            [BusinessUnitTable.buUnReadGrantId]: null
        });
        return {label: "Organisation", data: fakeBu};
    }

    getNodeChildren(parentId: number | null): TreeNode[] {
        const bus = this.buHierarchy.filter(x=> x.buUnParentId === parentId);
        const temp: TreeNode[] = [];
        bus.forEach(bu => {
            if (bu.buUnParentId == null) {
                // Cas où les entités sont de premier niveau donc directement sous le root node
                //bu.buUnParentId = 0;
            }
            const newNode: TreeNode = {label: "Organisation", data: bu};
            const children = this.getNodeChildren(bu.buUnId);
            if (children.length > 0) {
                newNode.children = children;
                children.forEach(ch => {
                    ch.children = this.getNodeChildren(ch.data.buUnId);
                });
            }
            temp.push(newNode);
        });
        return temp;
    }

    getNode(branche: TreeNode<BusinessUnit>[], buId: number): TreeNode<BusinessUnit> | undefined {
        let result: TreeNode<BusinessUnit> | undefined = undefined;
        branche.forEach(e => {
            if (e.data?.buUnId === buId) {
                result = e;
                return;
            }
            if (!result && e.children) result = this.getNode(e.children, buId);
        });
        return result;
    }
    
    async selectRoot(): Promise<void> {
        this.selectedContent = this.contents[0];
        //this.selectNode(this.dataSource.data, this.selectedNode);
        await this.emitEventAsync(BusinessUnitsTreeModel.seletedNodeChange, this.selectedContent);
        //this.raiseEvent(this.seletedNodeChange, this.dataSource.data[0]);
        //this.raiseSelectedNodeChange(this.dataSource.data[0]);
    }

    selectNode(branche: TreeNode<BusinessUnit>[], node: TreeNode<BusinessUnit>): void {
        this.selectedContent = node;
        // branche.forEach(e => {
        //     //e.selected = (e.bu.buUnId === node.bu.buUnId);
        //     this.selectNode(e.children, node);
        // });
    }

    //seletedNodeChange?: (node:BusinessUnitTreeNode) => void;
    // async nodeClick(e: MouseEvent, node:BusinessUnitTreeNode):Promise<void> {
    //     e.stopPropagation();

    //     this.selectedNode = node;
    //     this.selectNode(this.dataSource.data, node);
    //     await this.emitEventAsync(BusinessUnitsTreeModel.seletedNodeChange, node);
    //     //this.raiseEvent(this.seletedNodeChange, node);
    //     //this.raiseSelectedNodeChange(node);
    // }

    async selectedContentChange(): Promise<void> {
        if (!this.selectedContent?.data) return;
        await this.emitEventAsync(BusinessUnitsTreeModel.seletedNodeChange, this.selectedContent);
    }

    // raiseSelectedNodeChange(node:BusinessUnitTreeNode): void {
    //     if (this.seletedNodeChange) {
    //         this.seletedNodeChange(node);
    //     } else {
    //         LoggerService.error("BusinessUnitsTreeModel.selectedNodeChange n'était pas écouté");
    //     }
    // }

    // nodeEditRequested?: (node:BusinessUnitTreeNode) => void;
    // editButtonClick(e: MouseEvent, node: BusinessUnitTreeNode): void {
    //     e.stopPropagation();
    //     this.raiseEvent(this.nodeEditRequested, node);
    //     this.selectedNode = node;
    //     this.selectNode(this.dataSource.data, node);
    // }
}