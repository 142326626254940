import { ZLanguageItemTable } from 'src/app/core/model/db-model/tables/z-language-item-table';
import { TaskFunctionTable } from './../../db-model/tables/task-function-table';
import { TaskContributorTable } from './../../db-model/tables/task-contributor-table';
import { TaskTable } from '../../db-model/tables/task-table';
import { Directory } from '../tables/directory';

export class TaskContributorView extends Directory {
    taCrTaskId!: number;
    taCrContributorId!: number; 
    taCrLoad!: number; 
    taCrFunctionId!: number; 
    taFuId!: number; 
    taFuName!: string; 
    taFuIsActive!: boolean; 
    taFuDisplayNameId!: number; 
    taFuScope!: number; 
    laItText!: string;
    taName!: string;
    taStartDate!: Date;
    taDuration!: number;
    taTypeId!: number;

    constructor(dtoData: any) {
        super(dtoData, [TaskContributorTable, TaskFunctionTable, ZLanguageItemTable, TaskTable]);
        // Il faut convertir explicitement la date en Date parce qu'elle arrive nativement comme string
        this.taStartDate = new Date(this.taStartDate);
    }
}