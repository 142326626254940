import { InteractionCommand } from "../interaction-command";
import { SvgEntityTypesEnum } from "src/app/core/model/svg-model/svg-entity-type-enum";
import { InsertInteraction } from "./insert-interaction";
import { SelectableSvgLine } from "../../../svg/selectable-svg-line";
import { LineInsertGizmo } from "../../../gizmo/insert/model/line-insert-gizmo";
import { logError } from "src/app/core/services/logging-service";

export class LineInsertInteraction extends InsertInteraction<LineInsertGizmo> {
    constructor(command: InteractionCommand) {
        super(command, new LineInsertGizmo(), SvgEntityTypesEnum.line);
        this.gizmo.insertionCompleted = () => {
            if (this.insertionCompleted) {
                this.insertionCompleted(SelectableSvgLine.fromValues(this.gizmo.startPoint, this.gizmo.endPoint, 0.001));
            } else {
                logError("LineInsertGizmo.insertionCompleted n'est pas écouté");
            }
        }
    }
}