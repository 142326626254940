import Container from "typedi";
import { AppUserService } from "src/app/core/services/backend-services/app-user-service";
import { AppMenuRouteEnum } from "src/app/core/model/data-model/enums/app-menu-route-enum";
import { logError } from "src/app/core/services/logging-service";

export class PageRouter {
    private static separator = '/';
    //private static userId: number = 0;
    static segments: string[] = [];

    // static set(userId: number, route: string): void {
    //     //this.userId = userId;
    //     this.segments = route.split(this.separator);
    //     // Le premier segment doit nécessairement appartenir à mainPageEnum
    //     const fs = this.firstSegment();
    //     if (!(Object.values(AppMenuRouteEnum) as string[]).includes(fs)) {
    //         LoggerService.error("Erreur de routage. Initialisation sur la page par défaut");
    //         //this.segments = [AppMenuRouteEnum.layout_realestate_assets];
    //     }
    // }

    static firstSegment(): string {
        if (this.segments.length > 0) {
            return this.segments[0];
        }
        logError("Erreur de routage. La page par défaut a été retournée");
        //return AppMenuRouteEnum.layout_realestate_assets;
        return "";
    }

    /**
     * 
     * @param level le niveau est passé comme index de tableau, donc de base 0
     * @returns 
     */
    static nthSegment(level: number): string {
        if (this.segments.length >= 0) {
            return this.segments[level];
        }
        logError("Erreur de routage.");
        return "";
    }


    static lastSegment(): string {
        if (this.segments.length > 0) {
            return this.segments[this.segments.length - 1];
        }
        logError("Erreur de routage.");
        return "";
    }

    static pageSegment(level: number, kind: object, defaultValue: string): string {
        return this.nthSegmentIsKindOf(level, kind) ? PageRouter.nthSegment(level) : defaultValue;
    }

    static idSegment(level: number, defaultValue: number): number {
        const value = Number(PageRouter.nthSegment(level));
        return isNaN(value) ? defaultValue : value;
    }

    static nthSegmentIsKindOf(level: number, type: object): boolean {
        return Object.values(type).includes(this.nthSegment(level));
    }

    private static concatened(): string {
        return this.segments.join(this.separator);
    }

    static async append(segment: string, level: number): Promise<void> {
        // cas de la page clients qui n'est pas routée, on ne fait rien
        if (level < 0) return

        // Si le niveau n'est pas atteint, on ne fait rien
        if (this.segments.length < level) return;

        // Si le segment existe déjà à ce niveau on ne fait rien
        const existing = this.nthSegment(level);
        if (existing === segment) return;

        if (segment !== "" && !this.segments.includes(segment)) {
            if (this.segments.length >= level + 1) {
                // Il y a déjà un segment de route à ce niveau
                // dans ce cas on le supprime
                this.segments.splice(level);
            }
            // On ajoute le segment de route
            this.segments.push(segment);
            await this.save();
        }
    }

    private static async save(): Promise<void> {
        if (this.firstSegment() !== "clients") {
            const s = Container.get(AppUserService);
            await s.saveCurrentPage(Container.get("userId"), this.concatened());
        }
    }

    static geAppMenuRoute(route: string): AppMenuRouteEnum | undefined {
        return Object.values(AppMenuRouteEnum).find(x=> route.startsWith(x));
    }
}