@if (model) {
    <div class="infos">
        @for (row of model.rows; track row) {
            <div class="info">

                @switch (row.valueType) {
                    @case ('number') {
                        <div class="property-box">
                            <span class="label">{{row.label}} :</span>
                            <div class="value-box">
                                <span class="value numberValue">{{row.value | number: $any(row).numberFormat : 'fr-FR'}}</span>
                                <span class="suffix">{{$any(row).suffix}}</span>
                            </div>
                        </div>
                    }
                    @case ('string') {
                        <div class="property-box">
                            <span class="label">{{row.label}} :</span>
                            <div class="value-box">
                                <span class="value stringValue">{{row.value}}</span>
                                <span class="suffix"></span>
                            </div>
                        </div>
                    }
                }

            </div>
        }
    </div>
}