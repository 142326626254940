import { SelectableBuTreeNode } from './../selectable-bu-tree/model/selectable-bu-tree-node';
import { SelectableRealEstateTreeNode } from "../selectable-real-estate-tree/model/selectable-real-estate-tree-node";
import { DateUtils } from 'src/app/core/model/static-functions/date-utils';

export class SimulationCreationData {
    readonly defaultNameValue = "Nouvelle simulation";

    name: string = this.defaultNameValue;
    selectedMoveWorkTaskId: number | undefined;
    defaultStartDate = DateUtils.today();
    endDate: Date = DateUtils.today();

    selectedBuildings: SelectableRealEstateTreeNode[] = [];
    selectedBus: SelectableBuTreeNode[] = [];
    
    busSelection: boolean = false;

    constructor() {
    }

    checkStepOneCompleted(): boolean {
        if (this.name === this.defaultNameValue || this.name.length === 0) return false;
        if (this.endDate === undefined) return false;
        return true;
    }
}