import { FormsModule } from '@angular/forms';
import { MaterialModule } from './../../../../core/material.module';
import { Component, Input, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListField } from '../../../shared-model/list-field';

@Component({
  selector: 'xc-select-property',
  standalone: true,
  imports: [CommonModule, MaterialModule, FormsModule],
  templateUrl: './select-property.component.html',
  styleUrls: ['./select-property.component.scss'],
  // Encapsulation has to be disabled in order for the
  // component style to apply to the select panel.
  encapsulation: ViewEncapsulation.None,
})
export class SelectPropertyComponent {
  @Input() model: ListField | undefined;
}
