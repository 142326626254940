import { TaskLinkTypeEnum } from "src/app/core/model/data-model/enums/task-link-type-enum";
import { Point } from "src/app/core/model/geometry-model/point.model";
import { PathBuilder } from "src/app/core/model/svg-model/svg-path-builder";

export class GanttTaskLinkPathBuilder {
    static smallSegmentWidth: number = 25;

    static calculatePathData(linkType: TaskLinkTypeEnum, sourceX: number, sourceY: number, sourceWidth: number, sourceHeight: number, targetX: number, targetY: number, targetHeight: number): {firstPoint: Point, secondPoint: Point, lastPoint: Point, pathData: string} {
        const points: Point[] = [];
        const firstPoint = this.getFirstPoint(linkType, sourceX, sourceY, sourceWidth, sourceHeight);
        const secondPoint = this.getSecondPoint(firstPoint, linkType);
        points.push(firstPoint);
        points.push(secondPoint);
        points.push(this.getThirdPoint(points[1], linkType, sourceX, sourceY, sourceWidth, targetX, targetY, targetHeight));
        points.push(this.getFourthPoint(points[2], linkType, sourceX, sourceWidth, targetX, targetY, targetHeight));
        if (this.isFiveSegmentsLink(linkType, sourceX, sourceWidth, targetX)) {
            points.push(new Point(points[3].x, targetY + (targetHeight / 2)));
            points.push(new Point(targetX, targetY + (targetHeight / 2)));
        }

        const lastPoint = points[points.length - 1];
        const pathData = PathBuilder.getPath(points, false);

        return {firstPoint, secondPoint, lastPoint, pathData};
    }

    private static getFirstPoint(linkType: TaskLinkTypeEnum, sourceX: number, sourceY: number, sourceWidth: number, sourceHeight: number): Point {
        if (this.isSourceEndLink(linkType)) {
            return new Point(sourceX + sourceWidth, sourceY + (sourceHeight / 2));
        }
        return new Point(sourceX, sourceY + (sourceHeight / 2));
    }

    private static getSecondPoint(firstPoint: Point, linkType: TaskLinkTypeEnum): Point {
        if (this.isSourceEndLink(linkType)) {
            return new Point(firstPoint.x + this.smallSegmentWidth, firstPoint.y);
        }
        return new Point(firstPoint.x - this.smallSegmentWidth, firstPoint.y);
    }

    private static getThirdPoint(secondPoint: Point, linkType: TaskLinkTypeEnum, sourceX: number, sourceY: number, sourceWidth: number, targetX: number, targetY: number, targetHeight: number): Point {
        if (this.isFiveSegmentsLink(linkType, sourceX, sourceWidth, targetX)) {
            return new Point(secondPoint.x, this.halfYDistance(sourceY, targetY, targetHeight));
        }
        return new Point(secondPoint.x, targetY + (targetHeight / 2));
    }

    private static getFourthPoint(thirdPoint: Point, linkType: TaskLinkTypeEnum, sourceX: number, sourceWidth: number, targetX: number, targetY: number, targetHeight: number): Point {
        if (this.isFiveSegmentsLink(linkType, sourceX, sourceWidth, targetX)) {
            return new Point(targetX - this.smallSegmentWidth, thirdPoint.y);
        }
        return new Point(targetX, targetY + (targetHeight / 2));
    }

    private static halfYDistance(sourceY: number, targetY: number, targetHeight: number): number {
        return ((targetY + sourceY) / 2) + (targetHeight / 2);
    }

    private static isSourceEndLink(linkType: TaskLinkTypeEnum): boolean {
        return linkType === TaskLinkTypeEnum.EndToEnd || linkType === TaskLinkTypeEnum.EndToStart;
    }

    private static isFiveSegmentsLink(linkType: TaskLinkTypeEnum, sourceX: number, sourceWidth: number, targetX: number): boolean {
        return (linkType === TaskLinkTypeEnum.EndToStart && targetX <= sourceX + sourceWidth + (this.smallSegmentWidth * 2)) ||
            (linkType === TaskLinkTypeEnum.StartToEnd);
    }

}