
// <summary>
// Enumération des positions possibles d'un point par rapport à un segment de droite orienté
// </summary>
export enum PointPosition
    {
        // <summary>
        // Le point est sur le segment
        // </summary>
        On,
        // <summary>
        // Le point est à droite du segment
        // </summary>
        Right,
        // <summary>
        // Le point est à gauche du segment
        // </summary>
        Left
    }