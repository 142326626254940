import { TaskLinkDbView } from "../../db-model/views/task-link-db-view";
import { TaskLink } from "../tables/task-link";

export class TaskLinkView extends TaskLink {
    taLiTyName!: string;
    taLiTyDisplayNameId!: number;
    taLiTyViDisplayName!: string;
    taRootTaskId!: number;

    constructor(dtoData: any) {
        super(dtoData, [TaskLinkDbView]);
    }
}