import { FloorModelCategoryTable } from './../../db-model/tables/floor-model-category.table';
import { DefinedModelBase } from "../defined-model-base";

export class FloorModelCategory extends DefinedModelBase {
    flMoCaId!: number;
    flMoCaName!: string;
    flMoCaDisplayNameId!: number;
    flMoCaDepth!: number;
    flMoCaParentId!: number | null;
    flMoCaIsActive!: boolean;
    flMoCaScope!: number;

    constructor(dtoData: any, tableDef: any[] = []) {
        super(dtoData, [FloorModelCategoryTable].concat(tableDef));
    }
}