export const SiteAnatomyDbView = {
    databaseTableName: "site_layout_view",
    buSiteId: "Bu_SiteId",
    roLaTyId: "RoLaTy_Id",
    roLaTyCode: "RoLaTy_Code",
    roLaTyName: "RoLaTy_Name",
    roLaTyColor: "RoLaTy_Color",
    roLaTyIsNUA: "RoLaTy_IsNUA",
    roLaTyParentId: "RoLaTy_ParentId",
    stLaViArea: "StLaVi_Area",
    stLaViRoundedArea: "StLaVi_RoundedArea",
    stLaViLayoutTypeTree: "StLaVi_LayoutTypeTree"
}