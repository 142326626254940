import { getGeometry } from "src/app/core/model/geometry-model/geometric-elements-builder";
import { SvgEntity } from "src/app/core/model/svg-model/svg-entity.model";
import { BpSvgBoundingBox } from "./bp-svg-bounding-box";
import { BpSvgPath } from "./bp-svg-path";

export class BpSvgDef {
    id: number;
    defsGeometry: SvgEntity[] = [];
    contour: BpSvgPath;
    inside: SvgEntity[] = [];
    attributes: any = {};
    boundingBoxString: string;
    bBox: BpSvgBoundingBox | null;
    name: string;
    equipmentCategoryId: number;
    equipmentCategoryName: string;
    equipmentProviderId: number;
    equipmentProviderColor: string;
    equipmentProviderName: string;

    private _zoomFactor: number = 0;
    get zoomFactor(): number {
        return this._zoomFactor;
    }
    set zoomFactor(value: number) {
        this._zoomFactor = value;

        if(this.defsGeometry && value && value !== 0) {
            // Actualise le stroke-width de la géométrie
            this.attributes["strokeWidth"] = 0.01 / value;
        }
    }

    constructor(dtoData: any) {
        this.id = dtoData.id;
        this.name =dtoData.floorCatalogName;
        this.boundingBoxString = dtoData.boundingBoxString;
        this.bBox = BpSvgBoundingBox.getFromString(this.boundingBoxString);
        this.equipmentCategoryId = dtoData.equipmentCategoryId;
        this.equipmentCategoryName = dtoData.equipmentCategoryName;
        this.equipmentProviderId = dtoData.equipmentProviderId;
        this.equipmentProviderColor = dtoData.equipmentProviderColor;
        this.equipmentProviderName = dtoData.equipmentProviderName;

        this.defsGeometry = new Array<BpSvgPath>();
        dtoData.defsGeometry.forEach((element: { path: any; geometry: any; }) => {
            const newPath = new BpSvgPath(element.path);
            newPath.geometry = getGeometry(element.geometry) ;
            this.defsGeometry.push(newPath);
        });
        
        this.attributes = dtoData.attributes;

        this.contour = this.defsGeometry[0] as BpSvgPath;
        this.inside = this.defsGeometry.slice(1);
    }
}
