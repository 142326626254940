import { Polygon } from "src/app/core/model/geometry-model/polygon.model";
import { BpSvgDoor } from "../bp-svg-door";
import { BlueprintLayer } from "./blueprint-layer";
import { HtmlConstants } from "src/app/core/model/html-model/html-constants.model";

export class BlueprintDoorsLayer extends BlueprintLayer<BpSvgDoor> {
    constructor(dtoData: any, taskId: number) {
        super(dtoData, taskId);
    }

    select(polygon: Polygon): BpSvgDoor[] {
        const result: BpSvgDoor[] = [];

        (this.typedData()).forEach(d => {
            if (d.insertionPoint && polygon.contains(d.insertionPoint)) {
                result.push(d);
            }
        });

        return result;
    }

    setTaskZoneBoundDoors(wallIds: number[]): void {
        (this.typedData()).forEach(d => {
            if (wallIds.includes(d.parentId!)) {
                d.isTaskZoneBound = true;
            }
        });
    }

    /**
     * Override la méthode de base pour permettre le traitement des portes périphériques de la zone d'étude
     * @param selectable 
     */
    override setItemsSelectable(selectable: boolean): void {
        (this.typedData()).forEach(e => {
            if (selectable && !e.isTaskZoneBound) {
                e.cursor = HtmlConstants.styleCursorPointer;
            } else {
                e.cursor = HtmlConstants.styleCursorInherit;
            }
        });
    }
}
