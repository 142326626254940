import { TablesNamesEnum } from './../tables-names-enum';
export const SiteTable = {
    databaseTableName: TablesNamesEnum.Site,
    stId: 'St_Id',
    stParentId: 'St_ParentId',
    stDepth: 'St_Depth',
    stCode: 'St_Code',
    stName: 'St_Name',
    stIsActive: 'St_IsActive',
    stIsFictive: 'St_IsFictive',
    stLocation: 'St_Location',
    stDefaultPerimeterId: 'St_DefaultPerimeterId'
  };
  