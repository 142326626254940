import { ZSortDirectionTable } from './../../db-model/tables/z-sort-direction';
import { DefinedModelBase } from '../defined-model-base';

export class ZSortDirection extends DefinedModelBase {
    soDiId!: number;
    soDiValue!: string;

    constructor(dtoData: any, tableDef: any[] = []) {
        super(dtoData, [ZSortDirectionTable].concat(tableDef));
    }
}