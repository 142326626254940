import { TablesNamesEnum } from './../../model/db-model/tables-names-enum';
import { ApiService } from './../api-service';
import { Service } from 'typedi';
import { ZAppParameter } from '../../model/data-model/tables/z-app-parameter';
import { ApiControllers, ApiEndpoints } from '../api-endpoints';

@Service({ global: true })  
export class AppService extends ApiService {
    private addTableGrantEndpoint(tableName: string, grantIdColumnName: string): string { return `${ApiControllers.app}/${tableName}/AddGrant/${grantIdColumnName}` };

    constructor() {
        super();
    }

    async getParam(name: string): Promise<ZAppParameter | null> {
        return await this.getSingleAsync<ZAppParameter>(TablesNamesEnum.ZAppParameter, this.endPoint(ApiEndpoints.appParameter(name)));
    }

    async addTableGrant(tableName: string, grantIdColumnName: string): Promise<number | null> {
        const result = await this.postAsync<number>(this.endPoint(this.addTableGrantEndpoint(tableName, grantIdColumnName)), null);
        if (result) {
            return result.payload;
        }
        return null;
    }
}