import { ConstraintRequestItemDyntable } from './../../db-model/dynamic/constraint-request-item-dyntable';
import { DefinedModelBase } from '../defined-model-base';

export class ConstraintRequestItem extends DefinedModelBase {
    id!:  number;
    value!:  any;
    fileUrl!: string | null;
    
    constructor(dtoData: any) {
        super(dtoData, [ConstraintRequestItemDyntable]);
    }
}