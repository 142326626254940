import { AppMenuRouteEnum } from "src/app/core/model/data-model/enums/app-menu-route-enum";
import { PerimeterTypeEnum } from "src/app/core/model/data-model/enums/perimeter-type-enum";
import { Floor } from "src/app/core/model/data-model/tables/floor";
import { Perimeter } from "src/app/core/model/data-model/tables/perimeter";
import { PerimeterDetailView } from 'src/app/core/model/data-model/views/perimeter-detail-view';
import { TablesNamesEnum } from "src/app/core/model/db-model/tables-names-enum";
import { AppGrantTable } from 'src/app/core/model/db-model/tables/app-grant-table';
import { PerimeterDetailTable } from 'src/app/core/model/db-model/tables/perimeter-detail-table';
import { PerimeterTable } from "src/app/core/model/db-model/tables/perimeter-table";
import { ViewsNames } from 'src/app/core/model/db-model/views-names-enum';
import { DyntService } from "src/app/core/services/backend-services/dynt-service";
import { RealEstateService } from "src/app/core/services/backend-services/real-estate-service";
import { toastSuccess } from "src/app/core/services/toast-service";
import { PageModel } from "src/app/ui/main/model/page-model";
import Container from "typedi";

export class CustomPerimetersManageVM extends PageModel {
    perimeters: Perimeter[] = [];
    selectedPerimeter: Perimeter | undefined;
    selectedPerimeterName: string | undefined;
    selectedPerimeterDesc: string | null | undefined;
    selectedPerimeterFloors: PerimeterDetailView[] = [];
    
    newPerimeterName: string | undefined;
    newPerimeterDesc: string | null = null;
    newPerimeterIsSharingArea: boolean = false;
    newPerimeterIsGranted: boolean = false;

    floors: Floor[] = [];
    selectedFloors: Floor[] = [];

    private constructor() {
        super(AppMenuRouteEnum.layout_realestate_perimeters, 0, "2e5751a5-67fe-43c3-b652-877d73d0cc87");
    }

    static async newAsync(): Promise<CustomPerimetersManageVM> {
        const result = new CustomPerimetersManageVM();
        await result.loadData();
        return result;
    }

    async loadData(): Promise<void> {
        await this.loadPerimeters();
        const s = Container.get(DyntService);
        this.floors = (await s.downloadTable<Floor>(TablesNamesEnum.Floor, undefined)).sort((a, b) => a.flName.localeCompare(b.flName));
    }

    async loadPerimeters(): Promise<void> {
        const s = Container.get(DyntService);
        this.perimeters = await s.downloadTable<Perimeter>(TablesNamesEnum.Perimeter, undefined, PerimeterTable.peTypeId, PerimeterTypeEnum.custom);
    }

    canCreateNewPerimeter(): boolean {
        if (this.newPerimeterDesc != null && this.newPerimeterDesc.length === 0) this.newPerimeterDesc = null;
        const nameValid = this.newPerimeterName ? this.newPerimeterName.length > 3 : false;
        const descValid = this.newPerimeterDesc == null || this.newPerimeterDesc.length > 3;
        const floorsValid = this.selectedFloors.length > 0;
        return nameValid && descValid && floorsValid;
    }

    clearForm(): void {
        this.newPerimeterName = undefined;
        this.newPerimeterDesc = null;
        this.newPerimeterIsSharingArea = false;
        this.newPerimeterIsGranted = false;
        this.selectedFloors = [];
    }

    async onSelectedPerimeterChange(): Promise<void> {
        this.selectedPerimeterName = this.selectedPerimeter?.peName;
        this.selectedPerimeterDesc = this.selectedPerimeter?.peDescription;
        const s = Container.get(DyntService);
        this.selectedPerimeterFloors = await s.downloadTable<PerimeterDetailView>(ViewsNames.PerimeterDetailView, undefined, PerimeterDetailTable.peDePerimeterId, this.selectedPerimeter?.peId);
    }

    async onCreatePerimeterGrantButtonClick(): Promise<void> {
        if (!this.selectedPerimeter) return;
        const s = Container.get(RealEstateService);
        const result = await s.createGrantForPerimeter(this.selectedPerimeter.peId);
        if (result != null) {
            this.selectedPerimeter.peReadGrantId = result;
        }
    }

    async onRemovePerimeterGrantButtonClick(): Promise<void> {
        if (!this.selectedPerimeter || this.selectedPerimeter.peReadGrantId == null) return;
        const s = Container.get(DyntService);
        const result = await s.delete(AppGrantTable.databaseTableName,  this.selectedPerimeter.peReadGrantId, "La restriction a été supprimée");
        if (result && result.payload != null) {
            this.selectedPerimeter.peReadGrantId = null;
        }
    }

    async onPerimeterNameUpdateButtonClick(): Promise<void> {
        if (!this.selectedPerimeter || !this.selectedPerimeterName) return;
        const s = Container.get(RealEstateService);
        const result = await s.updatePerimeterValue(this.selectedPerimeter.peId, PerimeterTable.peName, this.selectedPerimeterName);
        if (result != null) {
            this.selectedPerimeter.peName = this.selectedPerimeterName;
        }
    }

    async onPerimeterDescUpdateButtonClick(): Promise<void> {
        if (!this.selectedPerimeter || !this.selectedPerimeterDesc) return;
        const s = Container.get(RealEstateService);
        const result = await s.updatePerimeterValue(this.selectedPerimeter.peId, PerimeterTable.peDescription, this.selectedPerimeterDesc);
        if (result != null) {
            this.selectedPerimeter.peDescription = this.selectedPerimeterDesc;
        }
    }

    async onCreatePerimeterButtonClick(): Promise<void> {
        const s = Container.get(RealEstateService);
        const result = await s.createNewPerimeter(this.newPerimeterName!, this.newPerimeterDesc, this.newPerimeterIsSharingArea, this.newPerimeterIsGranted, this.selectedFloors.map(x=> x.flId));
        if (result) {
            toastSuccess("Le nouveau périmètre a été créé");
            // vide le formulaire
            this.clearForm();
            // recharge la liste de choix des périmètres (premier onglet)
            await this.loadPerimeters();
        }
    }
}