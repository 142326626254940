import { Service } from "typedi";
import { ApiService } from "../api-service";
import { ApiControllers } from "../api-endpoints";
import { SvgGroupDTO } from "./dto/svg-group-dto";

@Service({ global: true })  
export class FloorCatalogService extends ApiService {
    private catalogEndpoint(floorCatalogId: number): string { return `${ApiControllers.floorCatalog}/pictogram/${floorCatalogId}` };

    constructor() {
          super();
    }
    
    async getFloorCatalogItem(floorCatalogId: number): Promise<SvgGroupDTO | null> {
        const result = await this.getAsync<SvgGroupDTO>(this.endPoint(this.catalogEndpoint(floorCatalogId)));
        if (result) {
            return result.payload;
        }
        return null;
    }
  }