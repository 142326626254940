import { Point } from "src/app/core/model/geometry-model/point.model";
import { Vector } from "src/app/core/model/geometry-model/vector.model";
import { SvgEntityTypesEnum } from "src/app/core/model/svg-model/svg-entity-type-enum";
import { SvgTspan } from "src/app/core/model/svg-model/svg-tspan.model";
import { GraphicalDefaultValues } from 'src/app/core/model/html-model/graphical-default-values.model';
import { BpSvgGroup } from '../../bp-svg-core-model/bp-svg-group';
import { BpSvgRectangle } from '../../bp-svg-core-model/bp-svg-rectangle';
import { BpSvgText } from '../../bp-svg-core-model/bp-svg-text';
import { BlueprintSvgEntityTypeEnum } from './blueprint-svg-entity-type-enum';
import { logError } from 'src/app/core/services/logging-service';

export class BpSvgLabel extends BpSvgGroup {
    static textWidthOverflow = 0.1;

    backgroundRectangle: BpSvgRectangle;
    text: BpSvgText;

    initialBackgroundPosition!: Point;
    initialTextPosition!: Point;

    constructor(dtoData: any, blueprintEntityType: BlueprintSvgEntityTypeEnum) {
        super(dtoData, blueprintEntityType);
        this.text = this.entities.find(x=> x.entityType === SvgEntityTypesEnum.text) as BpSvgText;
        // Dans le cas d'une étiquette, le rectangle d'arrière plan n'est pas calculé par le backend
        // puisque sa taille dépend de celle du texte
        // C'est donc le font qui le calcule
        this.backgroundRectangle = this.entities.find(x=> x.entityType === SvgEntityTypesEnum.rect) as BpSvgRectangle;
        this.backgroundRectangle.fill = "grey";
        this.storeCurrentPosition();
        //this.xcFunc = dtoData
    }

    backgroundSizeChanged?: () => void;
    setBackgroundSize(size: DOMRect | null): void {
        if (size != null) {
            this.backgroundRectangle.moveTo(new Point(size.x - BpSvgLabel.textWidthOverflow, size.y));
            this.backgroundRectangle.resize(size.width + (BpSvgLabel.textWidthOverflow * 2), size.height);
            if (this.backgroundSizeChanged) {
                this.backgroundSizeChanged();
            }
        }
    }

    groups(): BpSvgGroup[] {
        return this.entities.filter(x=> x.entityType === SvgEntityTypesEnum.g) as BpSvgGroup[];
    }

    mainSpan(): SvgTspan {
        return this.text.tSpans[0];
    }

    mainText(): string {
        return this.mainSpan().text;
    }

    isNumeric(): boolean {
        return Number(this.mainText()) != null;
    }

    numericValue(): number {
        return Number(this.mainText());
    }

    storeCurrentPosition(): void {
        this.initialBackgroundPosition = this.backgroundRectangle.position();
        this.initialTextPosition = this.text.position();
    }

    initializeTranslation(): void {
        this.initialBackgroundPosition = this.backgroundRectangle.position();
        this.initialTextPosition = this.text.position();
    }

    translated?: () => void;
    translate(delta: Vector): void {
        if (this.initialTextPosition && this.initialBackgroundPosition) {
            this.text.moveTo(this.initialTextPosition.translate(delta));
            this.backgroundRectangle.moveTo(this.initialBackgroundPosition.translate(delta));
            if (this.translated) {
                this.translated();
            }
        } else {
            logError("BpSvgLabel.translate : la translation n'a pas été initialisée");
        }
    }

    updatePosition(position: Point): void {
        this.storeCurrentPosition();
        const delta = position.minus(this.text.position());
        this.translate(delta);
    }

    updateCode(code: string, dataStateId: number): void {
        this.mainSpan().text = code;
        this.dataStateId = dataStateId;
    }

    cancelTranslation(): void {
        if (this.initialTextPosition && this.initialBackgroundPosition) {
            this.text.moveTo(this.initialTextPosition);
            this.backgroundRectangle.moveTo(this.initialBackgroundPosition);
        } else {
            logError("BpSvgLabel.translate : la translation n'a pas été initialisée");
        }
    }

    setDisplay(color: string, opacity: number): void {
        this.backgroundRectangle.fill = color;
        this.backgroundRectangle.fillOpacity = opacity;
    }

    resetDisplay(): void {
        this.setDisplay("grey", GraphicalDefaultValues.LabelBackgroundOpacity);
    }

}
