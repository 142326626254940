import { GanttRootProjectContainerVM } from "../../shared/model/gantt-root-project-container-vm";
import { GanttSvgTaskVM } from "../../shared/model/gantt-svg-task-vm";
import { Point } from "src/app/core/model/geometry-model/point.model";
import { GanttSvgTaskLinkTransient } from "../../shared/model/gantt-svg-task-link-transient";
import Container from "typedi";
import { TaskService } from "src/app/core/services/backend-services/task-service";
import { GanttSvgTaskLink } from "../../shared/model/gantt-svg-task-link";
import { TaskLinkView } from "src/app/core/model/data-model/views/task-link-type-view";
import { TaskLinkTypeEnum } from "src/app/core/model/data-model/enums/task-link-type-enum";

export class TaskLinkingProcess {
    svgNode: SVGSVGElement | undefined;
    rootProject: GanttRootProjectContainerVM | undefined;
    task: GanttSvgTaskVM | undefined;
    targetTask: GanttSvgTaskVM | undefined;
    processing: boolean = false;
    initialMousePosition: Point | undefined;
    startLink: boolean = false;
    targetStartLink: boolean = false;
    transientLink: GanttSvgTaskLinkTransient | undefined;
    magnetAttraction: number = 0;
    magnetAttractionValue: number = 20;
    tempMousePosition: Point | null = null;


    initializeProcess(svgNode: SVGSVGElement, rootProject: GanttRootProjectContainerVM, task: GanttSvgTaskVM, initialMousePosition: Point, startLink: boolean): void {
        this.svgNode = svgNode;
        this.rootProject = rootProject;
        this.task = task;
        this.initialMousePosition = this.getSvgPoint(initialMousePosition);
        this.startLink = startLink;
        this.processing = true;
        this.transientLink = new GanttSvgTaskLinkTransient(this.task, this.initialMousePosition, startLink);
        this.rootProject.transientLink = this.transientLink;
    }

    async saveLink(unitWidth: number): Promise<boolean> {
        const s = Container.get(TaskService);
        let linkType = TaskLinkTypeEnum.StartToStart;
        if (!this.startLink) {
            if (this.targetStartLink) {
                linkType = TaskLinkTypeEnum.EndToStart;
            } else {
                linkType = TaskLinkTypeEnum.EndToEnd;
            }
        }

        const beResult = await s.createLink(this.task!.task.taId, this.targetTask!.task.taId, linkType);
        if (beResult != null) {
            const newLink = new GanttSvgTaskLink(beResult, this.task!, this.targetTask!, unitWidth);
            this.task!.parent?.addLink(newLink);
            return true;
        }
        return false;
    }

    clearTarget(): void {
        this.targetTask = undefined;
        this.transientLink!.targetHandle = undefined;
        this.magnetAttraction = 0;
    }

    cleanProcess(): void {
        this.rootProject!.transientLink = undefined;
        this.rootProject = undefined;
        this.task = undefined;
        this.targetTask = undefined;
        this.initialMousePosition = undefined;
        this.processing = false;
        this.transientLink = undefined;
    }

    setTargetTask(targetTask: GanttSvgTaskVM, targetHandle: {x: number, y: number, width: number, height: number} | undefined, targetStartLink: boolean): void {
        this.targetTask = targetTask;
        this.targetStartLink = targetStartLink;
        this.transientLink!.targetHandle = targetHandle;
        this.magnetAttraction = this.magnetAttractionValue;
    }

    getSvgPoint(p: Point): Point {
        if(this.svgNode) {
            const ctm = this.svgNode.getScreenCTM();
            if(ctm) {
                return new Point((p.x - ctm.e) / ctm.a, (p.y - ctm.f) / ctm.d);
            }
        }
        return p;
    }

    move(p: Point, dayPerfect: boolean): void {
        if (!this.rootProject) return;
        // Le point est recalculé en fonction du décalage de position de la tâche
        this.transientLink?.move(this.getSvgPoint(p));
    }

    abort(): void {
        if (!this.task) return;
        // Remet la tâche à sa position de départ
        //this.task.move(0, true);
        // TODO : si le projet parent à été étendu, il faut aussi lui redonner sa taille initiale
        this.cleanProcess();
    }
}