@if (model) {
<xc-resizable-left-panel 
    [content]="sidePanelContent" 
    [contentModel]="model.sidePanel"
    [showGutter]="true"
    [tabs]="model.sidePanel.tabs"/>
}

@if (model) {
<div id="fixedContentPanel">
    @if (model.selectedImport === undefined && !model.sidePanel.waiting) {
<xc-cad-conv-default-view/>
}
    @if (model.selectedImport !== undefined) {
<xc-cad-conv-drawing-board class="content" [model]="model.drawingBoard"/>
}
</div>
}

@if (model && model.sidePanel.waiting) {
<div class="progress-spinner">
    <mat-progress-spinner
        mode="indeterminate">
    </mat-progress-spinner>
</div>
}



