@if (model) {
<div class="h-full w-full flex flex-column align-items-center">
    <div>
        <h3>Administration des rôles</h3>
    </div>
    <div>
        <p-tabView (onChange)="model.selectedTabChange($event)">
            <p-tabPanel header="Rôles">
                <xc-dyn-table [model]="model.adminTable"/>
            </p-tabPanel>
            <p-tabPanel header="Habilitations">
                <xc-grants-admin [model]="model.grants"/>
            </p-tabPanel>
        </p-tabView>
    </div>
</div>
}

