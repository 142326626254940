import { SvgEntityTypesEnum } from "src/app/core/model/svg-model/svg-entity-type-enum";
import { SvgGroup } from "src/app/core/model/svg-model/svg-group.model";
import { SvgRectangle } from "src/app/core/model/svg-model/svg-rectangle.model";
import { SvgStackSettings } from "./svg-stack-settings";
import { SimulationFloorDTO } from "src/app/core/services/backend-services/dto/simulation-floor-dto";
import { SvgBsFloorData } from "src/app/ui/shared/charts/stack/model/svg-bs-floor-data";
import { SvgBsFloorLabel } from "src/app/ui/shared/charts/stack/model/svg-bs-floor-label";
import { StackAnonymousUnitData } from "src/app/ui/shared/shared-model/stack-anonymous-unit-data";

export class SvgStackFloorVM extends SvgGroup {
    floorDTO: SimulationFloorDTO;
    floorArea: number;
    buildingFloorMaxArea: number;
    label: SvgBsFloorLabel;
    data: SvgBsFloorData;
    background: SvgRectangle;
    scaleX: number = 0;
    transitionDuration: number = 0.3;

    constructor(dto: SimulationFloorDTO, x: number, y: number, width: number, height: number, label: string, floorArea: number, buildingFloorMaxArea: number) {
        super({entityType: SvgEntityTypesEnum.g});

        this.floorDTO = dto;
        this.buildingFloorMaxArea = buildingFloorMaxArea;
        this.floorArea = floorArea;

        this.background = SvgRectangle.fromValues(x + SvgStackSettings.floorLabelsWidth, y, width, height);
        this.background.fill = "#ffffff";
        this.background.fillOpacity = 0.1;

        this.transitionDuration = (Math.random() * (0.6 - 0.3 + 0.1)) + 0.3;

        this.label = new SvgBsFloorLabel(x + SvgStackSettings.hMargins, y, SvgStackSettings.floorLabelsWidth, height, label, 16);

        this.data = new SvgBsFloorData(x + SvgStackSettings.hMargins + SvgStackSettings.floorLabelsWidth, y, width, height, floorArea, buildingFloorMaxArea);
    }

    loadData(): void {
        const data: StackAnonymousUnitData[] = [];
        const lastOcc = this.floorDTO.occupation[this.floorDTO.hierarchyDepthNames.length - 1];
        lastOcc.forEach(o => {
            const u = new StackAnonymousUnitData(o.siOcSimulationFloor, o.siOcSimulationBusinessUnit, o.businessUnit.buUnName, o.siOcArea, o.simulationBusinessUnit.siBuUnColor);
            data.push(u);
        });

        this.data.loadData(data);
    }

    floorLabelClick(): void {

    }
}