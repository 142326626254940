import { SelectableBuTreeComponent } from './../../selectable-bu-tree/view/selectable-bu-tree/selectable-bu-tree.component';
import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CreationStepThreeVM } from '../../model/creation-step-three-vm';

@Component({
  selector: 'xc-creation-step-three',
  standalone: true,
  imports: [CommonModule, SelectableBuTreeComponent],
  templateUrl: './creation-step-three.component.html',
  styleUrls: ['./creation-step-three.component.scss']
})
export class CreationStepThreeComponent {
  @Input() model: CreationStepThreeVM | undefined;
}
