import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'xc-tab-icon',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './tab-icon.component.svg',
  styleUrls: ['./tab-icon.component.scss']
})
export class TabIconComponent {

}
