import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from 'src/app/core/material.module';
import { FormsModule } from '@angular/forms';
import { DrawingToolbar } from '../../model/drawing-toolbar';

@Component({
  selector: 'xc-cad-conv-drawing-board-toolbar',
  standalone: true,
  imports: [CommonModule, MaterialModule, FormsModule],
  templateUrl: './cad-conv-drawing-board-toolbar.component.html',
  styleUrls: ['./cad-conv-drawing-board-toolbar.component.scss']
})
export class CadConvDrawingBoardToolbarComponent {
  @Input() model: DrawingToolbar | undefined;
}
