import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GridGizmo } from '../../../model/gizmos/grid-gizmo';

@Component({
  selector: 'g[xc-grid-gizmo]',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './grid-gizmo.component.svg',
  styleUrls: ['./grid-gizmo.component.scss']
})
export class GridGizmoComponent {
  @Input() model: GridGizmo | undefined;
}
