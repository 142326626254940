<input #file class="file-input" type="file" [attr.accept]="acceptedFiles" (change)="onFilesAdded()" [attr.multiple]='multi' />
<div class="container" fxLayout="column" fxLayoutAlign="space-evenly stretch">
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <h3>Sélectionner {{multi ? 'des fichiers' : 'un fichier'}}</h3>
    @if (!simpleIcon) {
<button
      [disabled]="(uploading | async) || uploadSuccessful"
      mat-fab
      color="accent"
      class="add-files-btn mat-elevation-z0"
      (click)="addFiles()">
      <mat-icon>upload_file</mat-icon>
    </button>
}

    @if (simpleIcon) {
<button
            [disabled]="(uploading | async) || uploadSuccessful"
            mat-icon-button
            color="accent"
            class="add-files-btn"
            (click)="addFiles()">
      <mat-icon>upload_file</mat-icon>
    </button>
}
  </div>

  <!-- This is the content of the upload-dialog, containing a list of the files to upload -->
  <mat-list>
    @for (file of files; track file) {
  <mat-list-item>
      <div class="file-item">
        <mat-icon mat-list-icon>{{progressIcon}}</mat-icon>
        <span class="file-name">{{file.name}}</span>
        @if (progress) {
<pre mat-line>{{progress[file.name].progress | async}}</pre>
}
      </div>
      @if (progress) {
<mat-progress-bar
       
        mode="determinate"
        [value]="progress[file.name].progress | async"
      ></mat-progress-bar>
}
    </mat-list-item>
}
  </mat-list>
</div>

