import { MobilityProjectsGanttEditorVM } from './mobility-projects-gantt-editor-vm';
import { TabPageItem } from "src/app/components-lib/tab-page-container/model/tab-page-item";
import { TabPager } from "src/app/components-lib/tab-page-container/model/tab-pager";
import { readableUUID } from "src/app/core/events/event-listener-uuid";
import { MobilityProjectsGanttBrowserTabsEnum } from "./mobility-projects-gantt-browser-tabs-enum";
import Container from "typedi";
import { MobilityProjectsGanttTreeVM } from './mobility-projects-gantt-tree-vm';
import { ITabPageContent } from 'src/app/components-lib/tab-page-container/model/i-tab-page-content';
import { ScheduleEventsEnum } from '../../../nested/shared/model/schedule-events-enum';
import { MobilityProjectTreeNode } from '../../shared-model/mobility-project-tree-node';
import { TaskStatusEnum } from 'src/app/core/model/data-model/enums/task-status-enum';
import { TaskTypeEnum } from 'src/app/core/model/data-model/enums/task-type-enum';
import { TaskService } from 'src/app/core/services/backend-services/task-service';
import { MobilityProjectsGanttTreeEventsEnum } from './mobility-projects-gantt-tree-events-enum';
import { MobilityProjectsCategoryEnum } from './mobility-projects-category-enum';
import { MobilityProjectsGanttEditorEventsEnum } from './mobility-projects-gantt-editor-events-enum';
import { toastWarn } from 'src/app/core/services/toast-service';

export class MobilityProjectsGanttBrowserVM extends TabPager {
    //projects: TaskView[] = [];
    //dateRange: [startDate: Date, duration: number] | undefined;

    mobilityProjectsGanttBrowserTabsEnum = MobilityProjectsGanttBrowserTabsEnum;
    projectTreeview: MobilityProjectsGanttTreeVM;
    projectsEditor: MobilityProjectsGanttEditorVM;
    projectsCategory: MobilityProjectsCategoryEnum = MobilityProjectsCategoryEnum.currents;

    private constructor() {
        super(readableUUID(MobilityProjectsGanttBrowserVM.name));

        this.tabs.push(new TabPageItem(MobilityProjectsGanttBrowserTabsEnum.projects, "Projets"));
        this.tabs.push(new TabPageItem(MobilityProjectsGanttBrowserTabsEnum.edition, "Edition"));

        // Les deux onglets sont chargé immédiatement et le restent
        // celui de l'édition dépendant de l'item sélectionné dans celui du treeview
        this.projectTreeview = new MobilityProjectsGanttTreeVM();
        this.projectsEditor = new MobilityProjectsGanttEditorVM();

        this.addEventListener(ScheduleEventsEnum.escapeKeyStroke, () => {
            // Sur l'onglet d'édition, la touche echap est utilisée pour sortir des zones de saisie
            // donc lorsqu'on est sur l'onglet d'édition la touche echap ne désélectionne pas la tâche
            if (this.selectedTab?.id === MobilityProjectsGanttBrowserTabsEnum.projects) {
                this.projectTreeview.unselect();
            }
        });

        this.addEventListener(MobilityProjectsGanttTreeEventsEnum.templateSwitchChange, async (displayTemplates: boolean) => {
            if (displayTemplates) {
                await this.loadTemplates();
            } else {
                await this.loadProjects();
            }
        });

        this.addEventListener(ScheduleEventsEnum.deleteKeyStroke, async () => {
            // Demande de suppression d'une tâche
            // N'est possible que si la tâche n'est n'a jamais été démarée
            if (this.projectTreeview.selectedNode) {
                const node = this.projectTreeview.selectedNode as MobilityProjectTreeNode;
                const task = node.data;
                if (task.taStatusId !== TaskStatusEnum.Pending) {
                    toastWarn("Il n'est pas possible de supprimer cette tâche");
                    return;
                }

                // En théorie un projet est au statut non démarré si toutes ses tâches filles le sont
                // mais il vaut mieux vérifier
                // Et en réalité il ne sera pas possible de le supprimer s'il y a déjà un historique
                // Le backend se charge de contrôler ça
                if (task.taTypeId === TaskTypeEnum.Project && !this.projectTreeview.isRealyPending(node)) {
                    toastWarn("Il n'est pas possible de supprimer ce projet");
                    return;
                }

                const s = Container.get(TaskService);
                const result = await s.deleteTask(task.taId);
                if (result.length === 0) {
                    toastWarn("Il n'est pas possible de supprimer cette tâche (ou projet), probablement parce qu'elle a un historique");
                    return;
                }

                // On retire les tâches supprimées du treeview et du calendrier
                result.forEach(id => {
                    const n = this.projectTreeview.getTaskNode(this.projectTreeview.nodes, id);
                    if (n) {
                        this.projectTreeview.remove(n);
                        this.emitEventAsync(MobilityProjectsGanttTreeEventsEnum.taskRemoved, id);
                    }
                });
            }
        });
    }

    static async newAsync(): Promise<MobilityProjectsGanttBrowserVM> {
        const result = new MobilityProjectsGanttBrowserVM;
        await result.loadProjects();
        result.selectTabPage(0);
        return result;
    }

    private async loadProjects(): Promise<void> {
        const s = Container.get(TaskService);
        const tmp = await s.downloadMoves(true, false, false, false);
        this.projectTreeview.setTree(tmp);
        this.emitEventAsync(MobilityProjectsGanttEditorEventsEnum.projectsCategoryChanged, MobilityProjectsCategoryEnum.currents);
    }

    async loadTemplates(): Promise<void> {
        const s = Container.get(TaskService);
        const tmp = await s.downloadTemplates();
        this.projectTreeview.setTree(tmp);
        this.emitEventAsync(MobilityProjectsGanttEditorEventsEnum.projectsCategoryChanged, MobilityProjectsCategoryEnum.templates);
    }

    override async loadContent(): Promise<ITabPageContent | undefined> {
        if (!this.selectedTab) return;

        switch (this.selectedTab.id) {
            case MobilityProjectsGanttBrowserTabsEnum.projects:
                return this.projectTreeview;
            case MobilityProjectsGanttBrowserTabsEnum.edition:
                return this.projectsEditor;
        }
        return undefined;
    }

    // Se substitue à la méthode de base pour éliminer les listener des enfants dont
    // le composant de vue ne gère pas le events life cycle
    override removeListeners(): void {
        super.removeListeners();
        this.projectTreeview.removeListeners();
        this.projectsEditor.removeListeners();
    }
}