import { BusinessUnit } from './../tables/business-unit';
import { BusinessUnitDbView } from './../../db-model/views/business-unit-db-view';

export class BusinessUnitView extends BusinessUnit {
    roViIsCurrentState!: boolean;
    buUnViBuTree!: string;
    roAlArea!: number;
    buUnViRoundedArea!: number;
    buUnViOccupancy!: number;

    constructor(dtoData: any) {
        super(dtoData, [BusinessUnitDbView]);
    }
}