import { DxfLayerDTO } from "src/app/core/services/backend-services/dto/dxf-layer-dto";
import { ArrayUtils } from "src/app/core/model/static-functions/array-utils";
import { DxfSvgEntity } from "./svg/dxf-svg-entity";

export class LayersController {
    layers: DxfLayerDTO[] = [];

    getAllEntities(): DxfSvgEntity[] {
        const result: DxfSvgEntity[] = [];

        this.layers.forEach(l => {
            result.push(...l.entities);
        });

        return result;
    }
    
    getSvgEntitiesFromClientPoint(x: number, y: number): DxfSvgEntity[] {
        const result: DxfSvgEntity[] = [];
        const itemsData = this.getSvgItemsFromClientPoint(x, y);

        //const layerDistinctIds = ArrayUtils.DistinctValueBy<{entityId: number, layerId: number}, number>(itemsData, "layerId");
        itemsData.forEach(e => {
            const layer = this.layers.find(x=> x.dxLaId === e.layerId);
            if (layer) {
                const le = layer.entities.find(x=> x.entityId === e.entityId);
                if (le) {
                    result.push(le);
                }
            }
        });

        return result;
    }

    getSvgItemsFromClientPoint(x: number, y: number): {entityId: number, layerId: number}[] {
        const result: {entityId: number, layerId: number}[] = [];
        const items = document.elementsFromPoint(x, y);
        for (const item of items) {
            const entityId = item.getAttribute("entity-id");
            const layerId = item.getAttribute("layer-id");
            if (entityId !== null && layerId !== null) {
                result.push({entityId: Number(entityId), layerId: Number(layerId)});
            }
        }

        return result;
    }

    insert(layer: DxfLayerDTO, entity: DxfSvgEntity): void {
        layer.entities.push(entity);
    }

    delete(entities: DxfSvgEntity[]): void {
        const layersIds = ArrayUtils.DistinctValueBy<DxfSvgEntity, number>(entities, "layerId");
        layersIds.forEach(lid => {
            const lentities = entities.filter(x=> x.layerId === lid);
            const l = this.layers.find(x=> x.dxLaId === lid)!;
            l.remove(lentities);
        }); 
    }
}