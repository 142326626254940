import { readableUUID } from "src/app/core/events/event-listener-uuid";
import { AppMenuRouteEnum } from "src/app/core/model/data-model/enums/app-menu-route-enum";
import { PageModel } from "src/app/ui/main/model/page-model";
import { InventoryTreeModel } from "src/app/ui/pages/layout/inventory/catalog/model/inventory-tree-model";
import { InventoryTabPagerVM } from "src/app/ui/pages/layout/inventory/catalog/pager/model/inventory-tab-pager-vm";

export class CatalogPage extends PageModel {
    tree: InventoryTreeModel | undefined;
    pager: InventoryTabPagerVM | undefined;

    private constructor() {
        super(AppMenuRouteEnum.layout_inventory_catalogs, 0, readableUUID(CatalogPage.name));
    }

    static async newAsync(): Promise<CatalogPage> {
        const result = new CatalogPage();
        result.tree = await InventoryTreeModel.newAsync();
        result.pager = await InventoryTabPagerVM.newAsync();
        return result;
    }

    override removeListeners(): void {
        this.tree?.removeListeners();
        this.pager?.removeListeners();
    }
}