import { CommandGroup } from "./command-group";
import { ICommand } from "./i-command";
import { ToolGridOptionsEnum } from "./tool-grid-options-enum";

export class ToolGridOptionsCommands extends CommandGroup<ToolGridOptionsEnum> {
    gridTranslateCommand: ICommand;
    gridAlignCommand: ICommand;
    gridSizeCommand: ICommand;
    //gridResetCommand: ICommand;

    constructor() {
        super();
        this.gridTranslateCommand = this.addCommand(ToolGridOptionsEnum.position, "Placement de la grille", "adjust", false);
        this.gridAlignCommand = this.addCommand(ToolGridOptionsEnum.align, "Alignement de la grille", "grid_3x3", false);
        this.gridSizeCommand = this.addCommand(ToolGridOptionsEnum.size, "Taille de la grille", "aspect_ratio", false);
        //this.gridResetCommand = this.addCommand(ToolGridOptionsEnum.reset, "Réinitialisation de la grille", "restart_alt", false);
    }
}