import { logError } from '../../services/logging-service';

export class StringUtils {
    static fixedDigitsFormat(valueToFormat: number, digitsCount: number = 2): string {
        // Cette fonction n'est pertinente que pour les nombres entiers
        if (!Number.isInteger) {
            logError("StringUtils.fixedDigitsFormat ne doit être appelée que pour un nombre entier");
        }

        // digitsCount doit nécessairement être supérieur ou égale au nombre de position de la valeur à formater
        const l = valueToFormat.toString().length;

        if (l >= digitsCount) return valueToFormat.toString();

        const t = valueToFormat + Math.pow(10, digitsCount);
        return t.toString().substring(1);
    }

    static toBoolean(value: string | boolean): boolean {
        if (typeof value === 'boolean') return value;
        if (value.toLocaleUpperCase() === "TRUE" || value === "1") return true;
        return false;
    }
}