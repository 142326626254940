import { SimulationFloorAnatomyDbView } from "../../db-model/views/simulation-floor-anatomy-db-view";
import { RoomLayoutType } from "../tables/room-layout-type";
import { SimulationFloorAnatomy } from "../tables/simulation-floor-anatomy";
import { RoomActivityStatusTypeView } from "./room-activity-status-type-view";
import { RoomAttributionTypeView } from "./room-attribution-type-view";

export class SimulationFloorAnatomyView extends SimulationFloorAnatomy {
    activityStatusType: RoomActivityStatusTypeView;
    attributionType: RoomAttributionTypeView;
    layoutType: RoomLayoutType;

    constructor(dtoData: any) {
        super(dtoData, [SimulationFloorAnatomyDbView]);
        this.activityStatusType = new RoomActivityStatusTypeView(dtoData);
        this.attributionType = new RoomAttributionTypeView(dtoData);
        this.layoutType = new RoomLayoutType(dtoData);
    }
}