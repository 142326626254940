import { Component, AfterViewInit, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LeafletMarkerClusterModule } from '@asymmetrik/ngx-leaflet-markercluster';
import { MVVMEventDestroyLifeCycle } from 'src/app/components-lib/shared-model/mvvm-events-destroy-lifecycle';
import { RealEstateMapInteraction } from '../real-estate-map-interaction';

@Component({
  selector: 'xc-map',
  standalone: true,
  imports: [CommonModule, LeafletMarkerClusterModule],
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent extends MVVMEventDestroyLifeCycle implements AfterViewInit {
  @Input() override model: RealEstateMapInteraction | undefined;

  constructor() {
    super();
  }
  
  ngAfterViewInit(): void {
    if (this.model) {
      this.model.initMap();
      this.model.setMarkers();
    }
  }
}
