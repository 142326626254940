export abstract class ReportGridItem<T> {
    valueType: string;
    label: string;
    value: T | null;

    constructor(label: string, value: T | null, valueType: string) {
        this.label = label;
        this.value = value;
        this.valueType = valueType;
    }

    // getValueType(): string {
    //     if (this.isNumeric()) return "number";
    //     if (this.isString()) return "string";
    //     if (this.isBoolean()) return "boolean";
    //     if (this.isDate()) return "date";
    //     console.log("ReportGridItem : Type non pris en charge");
    //     return "";
    // }

    // isNumeric(): boolean {
    //     return typeof this.value === 'number';
    // }

    // isString(): boolean {
    //     return typeof this.value === 'string';
    // }

    // isBoolean(): boolean {
    //     return typeof this.value === 'boolean';
    // }

    // isDate(): boolean {
    //     return this.value instanceof Date;
    // }
}