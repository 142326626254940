import { ZDbViewColumnTable } from './../../db-model/tables/z-db-view-column-table';
import { DefinedModelBase } from '../defined-model-base';

export class ZDbViewColumn extends DefinedModelBase {
    dbViCoId!: number;
    dbViCoViewId!: number;
    dbViCoTableName!: string;
    dbViCoTableAlias!: string;
    dbViCoColumnName!: string;
    dbViCoColumnAlias!: string | null;
    dbViCoScope!: number;
    dbViCoDisplayNameId!: number;
    dbViCoAggregate!: string | null;
    dbViCoIsBrowsable!: boolean;
    dbViCoIsReadOnly!: boolean;
    dbViCoMainColumnOverride!: string | null;

    constructor(dtoData: any, tableDef: any[] = []) {
        super(dtoData, [ZDbViewColumnTable].concat(tableDef));
    }
}