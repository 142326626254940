import { TaskView } from './../../../model/data-model/views/task-view';
import { TaskFloorTable } from './../../../model/db-model/tables/task-floor-table';
import { TaskContributorTable } from './../../../model/db-model/tables/task-contributor-table';
import { TaskTable } from 'src/app/core/model/db-model/tables/task-table';
import { TaskFloor } from './../../../model/data-model/tables/task-floor';
import { TaskContributorView } from 'src/app/core/model/data-model/views/task-contributor-view';
import { TaskFunction } from './../../../model/data-model/tables/task-function';
import { TaskFunctionTable } from 'src/app/core/model/db-model/tables/task-function-table';

export class TaskCreateResultDTO {
    task: TaskView;
    taskContributors: TaskContributorView[] = [];
    taskFunctions: TaskFunction[] = [];
    taskFloor: TaskFloor | undefined;

    constructor(dtoData: any) {
        this.task = new TaskView(dtoData[TaskTable.databaseTableName]);
        dtoData[TaskContributorTable.databaseTableName].forEach((e: any) => {
            this.taskContributors.push(new TaskContributorView(e));
        });
        dtoData[TaskFunctionTable.databaseTableName].forEach((e: any) => {
            this.taskFunctions.push(new TaskFunction(e));
        });
        if (dtoData[TaskFloorTable.databaseTableName]) {
            this.taskFloor = new TaskFloor(dtoData[TaskFloorTable.databaseTableName]);
        }
    }
}