import { EquipmentPurchaseDbView } from './../../model/db-model/views/equipment-purchase-db-view';
import { ViewsNames } from 'src/app/core/model/db-model/views-names-enum';
import { Service } from "typedi";
import { ApiService } from "../api-service";
import { EquipmentStorageArea } from "../../model/data-model/tables/equipment-storage-area";
import { ApiControllers } from "../api-endpoints";
import { HttpParams } from "@angular/common/http";
import { EquipmentPurchaseView } from "../../model/data-model/views/equipment-purchase-view";
import { TaskView } from "../../model/data-model/views/task-view";
import { EquipmentPlanning } from "../../model/data-model/tables/equipment-planning";
import { TablesNamesEnum } from "../../model/db-model/tables-names-enum";
import { EquipmentPlanningTable } from "../../model/db-model/tables/equipment-planning-table";
import { EquipmentPurchaseDetail } from "../../model/data-model/tables/equipment-purchase-detail";
import { EquipmentStorageView } from '../../model/data-model/views/equipment-storage-view';
import { EquipmentPlanningTaskView } from '../../model/data-model/views/equipment-planning-task-view';

@Service({ global: true })  
export class EquipmentReconcileService extends ApiService {
    private sourceStorageAreasEndpoint: string = `${ApiControllers.equipmentReconcile}/sourcestorageareas`;
    private purchasesEndpoint: string = `${ApiControllers.equipmentReconcile}/purchases`;
    private twinTasksEndpoint: string = `${ApiControllers.equipmentReconcile}/twintasks`;
    private inventoryStateUpdateEndpoint: string = `${ApiControllers.equipmentPlanning}/PlanningState`;

    constructor() {
        super();
    }

    async getSourceStorageAreas(catalogIds: number[]): Promise<{storageAreas: EquipmentStorageArea[], storageDetails: EquipmentStorageView[]}> {
        let params = new HttpParams();
        catalogIds.forEach(id => {
            params = params.append('floorCatalogIds', id);
        });

        const result = await this.getAsync<any>(this.endPoint(this.sourceStorageAreasEndpoint), undefined, params);
          if (result != null && result.payload[TablesNamesEnum.EquipmentStorageArea]) {
            const sResult = result.payload[TablesNamesEnum.EquipmentStorageArea].map((s: any) => { return new EquipmentStorageArea(s); });
            const dResult = result.payload[ViewsNames.EquipmentStorageView].map((d: any) => { return new EquipmentStorageView(d); });
            return {storageAreas: sResult, storageDetails: dResult};
          }
          return {storageAreas: [], storageDetails: []};
    }
    
    async getPurchases(taskId: number, catalogIds: number[]): Promise<{purchases: EquipmentPurchaseView[], purchasesDetails: EquipmentPurchaseDetail[]}> {
        let params = new HttpParams();
        params = params.set("taskId", taskId);
        catalogIds.forEach(id => {
            params = params.append('floorCatalogIds', id);
        });

        const result = await this.getAsync<any>(this.endPoint(this.purchasesEndpoint), undefined, params);
          if (result != null && result.payload[ViewsNames.EquipmentPurchaseView]) {
            const pResult =  result.payload[ViewsNames.EquipmentPurchaseView].map((p: any) => { return new EquipmentPurchaseView(p); });
            const dResult = result.payload[TablesNamesEnum.EquipmentPurchaseDetail].map((d: any) => { return new EquipmentPurchaseDetail(d); });
            return {purchases: pResult, purchasesDetails: dResult};
          }
          return {purchases: [], purchasesDetails: []};
    }
    
    async getTwinTasks(taskId: number, equipmentPlanningStateId: number, catalogIds: number[]): Promise<TaskView[]> {
        let params = new HttpParams();
        params.append("taskId", taskId);
        params.append("equipmentPlanningStateId", equipmentPlanningStateId);
        catalogIds.forEach(id => {
            params = params.append('floorCatalogIds', id);
        });

        const result = await this.getAsync<any[]>(this.endPoint(this.twinTasksEndpoint), undefined, params);
          if (result != null) {
            return result.payload.map(item => {
                return new TaskView(item);
            })
          }
          return [];
    }

    async getEquipmentPlanningTaskView(taskId: number[]): Promise<EquipmentPlanningTaskView[] | null> {
      // Retourne le détail des assignations des équipements de la tâche
      return null;
    }

    async updateInventoryState(floorDataIds: number [], planingStateId: number, twinId?: number, purchaseId?: number, removeId?: number, storeId?: number): Promise<EquipmentPlanning[]> {
        const dto: any = {};
        dto[EquipmentPlanningTable.eqPlFloorDataId] = floorDataIds;
        dto[EquipmentPlanningTable.eqPlPlanningStateId] = planingStateId;
        if (twinId) dto[EquipmentPlanningTable.eqPlMoveCaseTwinId] = twinId;
        if (purchaseId) dto[EquipmentPlanningTable.eqPlPurchaseCasePurchaseId] = purchaseId;
        if (removeId) dto[EquipmentPlanningTable.eqPlRemoveCaseDeletionCaseId] = removeId;
        if (storeId) dto[EquipmentPlanningTable.eqPlStoreCaseStorageAreaId] = storeId;
        return await this.patchAsyncAndGetRowsArray<EquipmentPlanning>(TablesNamesEnum.EquipmentPlanning, this.endPoint(this.inventoryStateUpdateEndpoint), dto);
    }
}