@if (model) {
    <div class="h-full w-full flex flex-column align-content-center flex-wrap">
        <div class="h-3rem">
            <h3>Périmètres spécifiques</h3>
        </div>
        <div [style]="{height: 'calc(100% - 3rem)'}">
            <p-tabView>
                <p-tabPanel header="Existants">
                    <div class="flex flex-column gap-3 mt-3">
                        @if (model.perimeters.length === 0) {
                            <p>Vous n'avez pas encore créé de périmètre spécifique</p>
                        } @else {
                            <p-floatLabel>
                                <!-- il est nécessaire d'utiliser le template de la dropdown pour avoir l'actualisation -->
                                <!-- du nom en cas de mise à jour -->
                                <p-dropdown [options]="model.perimeters" 
                                    inputId="perimeterChoice"
                                    [(ngModel)]="model.selectedPerimeter" 
                                    optionLabel="peName" 
                                    [showClear]="true" 
                                    placeholder="Sélectionnez un périmètre"
                                    (onChange)="model.onSelectedPerimeterChange()">
                                    <ng-template pTemplate="selectedItem">
                                        <div>{{ model.selectedPerimeter?.peName }}</div>
                                    </ng-template>
                                    <ng-template let-perimeter pTemplate="item">
                                        <div>{{ perimeter.peName }}</div>
                                    </ng-template>
                                </p-dropdown>
                                <label for="perimeterChoice">Sélectionnez un périmètre</label>
                            </p-floatLabel>
                        }
                        @if (model.selectedPerimeter) {
                            <div class="flex flex-column gap-3">
                                <div class="flex flex-column gap-3">
                                    <div class="flex gap-3">
                                        <input pInputText id="pname" 
                                            type="text" 
                                            placeholder="Nom du périmètre" 
                                            minlength="3" 
                                            maxlength="45" 
                                            [(ngModel)]="model.selectedPerimeterName" />
                                        <p-button icon="pi pi-check" 
                                            [disabled]="model.selectedPerimeter.peName === model.selectedPerimeterName || model.selectedPerimeterName!.length < 3"
                                            (onClick)="model.onPerimeterNameUpdateButtonClick()">
                                        </p-button>
                                    </div>
                                    <div class="flex gap-3">
                                        <input pInputText id="pdesc" 
                                            type="text" 
                                            placeholder="Descriptif (facultatif)" 
                                            minlength="3" 
                                            maxlength="150" 
                                            [(ngModel)]="model.selectedPerimeterDesc" />
                                        <p-button icon="pi pi-check"
                                            [disabled]="model.selectedPerimeter.peDescription === model.selectedPerimeterDesc || (model.selectedPerimeterDesc != null && model.selectedPerimeterDesc.length < 3)"
                                            (onClick)="model.onPerimeterDescUpdateButtonClick()">
                                        </p-button>
                                    </div>
                                    <p-checkbox label="Est utilisé pour la mutualisation des surfaces" 
                                        [ngModel]="model.selectedPerimeter.peIsSharingAreaItem" 
                                        [binary]="true" 
                                        inputId="psharing" 
                                        [disabled]="true">
                                    </p-checkbox>
                                    @if (model.selectedPerimeter.peReadGrantId != null) {
                                        <p-checkbox label="Visibilité restreinte" 
                                            [ngModel]="true" 
                                            [binary]="true" 
                                            inputId="pgranted"
                                            [disabled]="true">
                                        </p-checkbox>
                                        <p-button label="Supprimer la restriction" (onClick)="model.onRemovePerimeterGrantButtonClick()"></p-button>
                                    } @else {
                                        <p-button label="Restreindre la visibilité" (onClick)="model.onCreatePerimeterGrantButtonClick()"></p-button>
                                    }
                                </div>
                                <span>Niveaux du périmètre</span>
                                <p-listbox 
                                    [options]="model.selectedPerimeterFloors" 
                                    optionLabel="floor.flName" 
                                    [style]="{'width':'15rem'}" 
                                    [listStyle]="{'max-height': '220px'}">
                                </p-listbox>
                            </div>
                        }
                    </div>
                </p-tabPanel>
                <p-tabPanel header="Nouveau">
                    <div class="flex flex flex-wrap gap-3">
                        <div class="flex flex-column gap-3">
                            <input pInputText id="npname" type="text" placeholder="Nom du nouveau périmètre" minlength="3" maxlength="45" required [(ngModel)]="model.newPerimeterName" />
                            <input pInputText id="npdesc" 
                                type="text" 
                                placeholder="Descriptif (facultatif)" 
                                minlength="3" 
                                maxlength="150" 
                                [(ngModel)]="model.newPerimeterDesc" 
                                />
                            <p-checkbox label="Sera utilisé pour la mutualisation des surfaces" [(ngModel)]="model.newPerimeterIsSharingArea" [binary]="true" inputId="npsharing"></p-checkbox>
                            <p-checkbox label="Sera à accès restreint" [(ngModel)]="model.newPerimeterIsGranted" [binary]="true" inputId="npgranted"></p-checkbox>
                        </div>
                        <div class="flex flex-wrap">
                            <p-pickList [source]="model.floors" 
                                [target]="model.selectedFloors" 
                                sourceHeader="Niveaux du parc" 
                                targetHeader="Niveaux du périmètre" 
                                [dragdrop]="true" 
                                [responsive]="true" 
                                [sourceStyle]="{ height: '20rem' }"
                                [targetStyle]="{ height: '30rem' }" 
                                filterBy="flName" 
                                sourceFilterPlaceholder="Rechercher..." 
                                breakpoint="1400px"
                                [showTargetFilter]="false"
                                [showSourceControls]="false"
                                [showTargetControls]="false">
                                <ng-template let-floor pTemplate="item">
                                    <div class="flex flex-wrap p-2 align-items-center gap-3">
                                        <span class="font-bold">{{ floor.flName }}</span>
                                    </div>
                                </ng-template>
                            </p-pickList>
                        </div>
                        <p-button label="Créer" [disabled]="!model.canCreateNewPerimeter()" (onClick)="model.onCreatePerimeterButtonClick()"></p-button>
                    </div>
                </p-tabPanel>
            </p-tabView>        
        </div>
    </div>
}
