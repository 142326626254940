import { TaskLinkTypeEnum } from './../../model/data-model/enums/task-link-type-enum';
import { TaskFloorModelTable } from 'src/app/core/model/db-model/tables/task-floor-model-table';
import { TaskContributorTable } from './../../model/db-model/tables/task-contributor-table';
import { TaskTypeEnum } from 'src/app/core/model/data-model/enums/task-type-enum';
import { DbModelUtils } from 'src/app/core/model/static-functions/db-model-utils';
import { ApiControllers } from './../api-endpoints';
import { TablesNamesEnum } from './../../model/db-model/tables-names-enum';
import { TaskChangeLogView } from './../../model/data-model/views/task-change-log-view';
import { TaskChangeLogDbView } from './../../model/db-model/views/task-change-log-db-view';
import { TaskChangeLogTable } from './../../model/db-model/tables/task-change-log-table';
import { TaskValidationTable } from './../../model/db-model/tables/task-validation-table';
import { TaskTable } from './../../model/db-model/tables/task-table';
import { Service } from "typedi";
import { ApiEndpoints } from "../api-endpoints";
import { ApiService } from "../api-service";
import { HttpParams } from '@angular/common/http';
import { TaskValidation } from '../../model/data-model/tables/task-validation';
import { UsageContextIdEnum } from '../../model/usage-context-id-enum';
import { Task } from '../../model/data-model/tables/task';
import { TaskStatusEnum } from '../../model/data-model/enums/task-status-enum';
import { TaskCreateResultDTO } from './dto/task-create-result-dto';
import { TaskLinkTable } from '../../model/db-model/tables/task-link-table';
import { TaskFacilityTable } from '../../model/db-model/tables/task-facility-table';
import { TaskFloorTable } from '../../model/db-model/tables/task-floor-table';
import { TaskView } from '../../model/data-model/views/task-view';
import { DateUtils } from '../../model/static-functions/date-utils';
import { TaskLinkView } from '../../model/data-model/views/task-link-type-view';
import { ViewsNames } from '../../model/db-model/views-names-enum';
import { TaskValidationDTO } from 'src/app/ui/pages/layout/real-estate/floor-blueprint/content/blueprint-viewer-content-panel/itself/services/dto/task-validation-dto';
import { TaskDataset } from 'src/app/ui/pages/layout/real-estate/floor-blueprint/content/blueprint-viewer-side-panel/subitems/floor-task/model/task-dataset';
import { TasksValidationsRefAreCheck } from 'src/app/ui/pages/layout/real-estate/floor-blueprint/content/blueprint-viewer-side-panel/subitems/floor-task/model/task-validation-ref-area-check';
import { FloorTask } from 'src/app/ui/pages/layout/real-estate/floor-blueprint/content/shared-model/floor-task';

@Service({ global: true })  
export class TaskService extends ApiService {
    private flatTreeEndpoint: string = `${ApiControllers.tasks}/flattree`;
    private linksEndpoint: string = `${ApiControllers.tasks}/links`;
    private datasetEndpoint(taskId: number): string { return `${ApiControllers.tasks}/${taskId}/dataset`};
    private scheduleEndpoint: string = `${ApiControllers.tasks}/schedule`;
    private workflowEndpoint: string = `${ApiControllers.tasks}/workflow`;
    private taskFacilityUpdateEndpoint: string = `${ApiControllers.tasks}/taskfacility`;
    private taskDatesAndDurationsUpdateEndpoint: string = `${ApiControllers.tasks}/dates`;
    private deleteTaskEndpoint(taskId: number): string { return `${ApiControllers.tasks}/${taskId}`};
    private floorActiveTasksEndpoint(floorId: number): string  {return `${ApiControllers.projects}/FloorFlatActiveTasks/${floorId}`};
    private reconcileTasksEndpoint: string = `${ApiControllers.projects}/ReconcileTasks`;
    private templatesEndpoint: string = `${ApiControllers.tasks}/templates`;
    private templatesLinksEndpoint: string = `${ApiControllers.tasks}/TemplatesLinks`;

    constructor() {
        super();
    }

    async downloadMoves(active: boolean, canceled: boolean, closed: boolean, ended: boolean): Promise<TaskView[]> {
        let params = new HttpParams();
        params = params.set('active', active);
        params = params.set('canceled', canceled);
        params = params.set('closed', closed);
        params = params.set('ended', ended);

        return await this.getArrayAsync<TaskView>(ViewsNames.TaskView, this.endPoint(this.flatTreeEndpoint), UsageContextIdEnum.none, params);
    }

    async downloadLinks(active: boolean, canceled: boolean, closed: boolean, ended: boolean): Promise<TaskLinkView[]> {
        let params = new HttpParams();
        params = params.set('active', active);
        params = params.set('canceled', canceled);
        params = params.set('closed', closed);
        params = params.set('ended', ended);

        return await this.getArrayAsync<TaskLinkView>(ViewsNames.TaskLinkView, this.endPoint(this.linksEndpoint), UsageContextIdEnum.none, params);
    }

    async downloadTemplatesLinks(): Promise<TaskLinkView[]> {
        return await this.getArrayAsync<TaskLinkView>(ViewsNames.TaskLinkView, this.endPoint(this.templatesLinksEndpoint));
    }
 
    async downloadDataset(taskId: number): Promise<TaskDataset | null> {
        const result = await this.getAsync<TaskDataset>(this.endPoint(this.datasetEndpoint(taskId)));
        if (result != null) {
            return new TaskDataset(result.payload);
        }
        return null;
    }

    async loadReconcileTasks(taskId: number): Promise<TaskView[]> {
        let params = new HttpParams().append('blueprintTaskId', taskId);
        const result = await this.getAsync<FloorTask[]>(this.endPoint(this.reconcileTasksEndpoint), undefined, params);
        if (result != null) {
            return result.payload.map(item => {
                return new TaskView(item);
            })
        }
        return [];
    }

    async loadFloorActiveTasks(floorId: number): Promise<FloorTask[]> {
        const result = await this.getAsync<FloorTask[]>(this.endPoint(this.floorActiveTasksEndpoint(floorId)));
        if (result != null) {
            return result.payload.map(item => {
                return new FloorTask(item);
            })
        }
        return [];
    }

    async downloadTaskChangeLog(taskId: number): Promise<TaskChangeLogView[]> {
        let params = new HttpParams();
        params = params.set('primaryColumnName', TaskChangeLogTable.taChLoTaskId);
        params = params.set('primaryFilterId', taskId);
        const result = await this.getAsync<any[]>(this.dynt(TaskChangeLogDbView.databaseTableName), UsageContextIdEnum.none, params);
        if (result) {
            return result.payload.map(item => {
                return new TaskChangeLogView(item);
            })
        }
        return [];
    }

    async downloadTaskValidationByTaskId(taskId: number): Promise<TaskValidation[]> {
        const param = new HttpParams().set('taskId', taskId.toString());
        const result = await this.getAsync<any[]>(this.endPoint(ApiEndpoints.tasksValidations), undefined, param);
        if (result) {
            return result.payload.map(item => {
                return new TaskValidation(item);
            })
        }
        return [];
    }

    async downloadTemplates(): Promise<TaskView[]> {
        return await this.getArrayAsync<TaskView>(ViewsNames.TaskView, this.endPoint(this.templatesEndpoint));
    }

    async getTaskDataset(taskId: number): Promise<TaskDataset | null> {
        const result = await this.getAsync(this.endPoint(ApiEndpoints.taskDataset(taskId)));
        if (result) {
            return new TaskDataset(result.payload);
        }
        return null;
    }

    async getTaskVariants(taskId: number): Promise<Task[]> {
        const result = await this.getAsync<any[]>(this.endPoint(ApiEndpoints.taskVariants(taskId)));
        if (result) {
            return result.payload.map(item => {
                return new Task(item);
            })
        }
        return [];
    }

    async refAreaCheck(taskId: number): Promise<TasksValidationsRefAreCheck | null> {
        const result = await this.getAsync(this.endPoint(ApiEndpoints.taskValidationsRefAreaCheck(taskId)));
        if (result) {
            return new TasksValidationsRefAreCheck(result.payload);
        }
        return null;
    }

    async updateTaskStatus(taskId: number, statusId: number): Promise<{item1: number, item2: number}[] | null> {
        const dto: any = {};
        dto[TaskTable.taId] = taskId;
        dto[TaskTable.taStatusId] = statusId;
        const result = await this.patchAsync(this.endPoint(this.workflowEndpoint), dto);
        if (result) {
            return result.payload as {item1: number, item2: number}[];
        }
        return null;
    }

    async updateTaskName(taskId: number, name: string): Promise<string | null> {
        const dto: any = {
            "TableName": TaskTable.databaseTableName,
            [TaskTable.taId]: taskId,
            [TaskTable.taName]: name
        }
        const result = await this.patchAsync(this.endPoint(ApiControllers.dynT), dto);
        if (result && result.message && result.message.length > 0) {
            return result.message[0];
        }
        return null;
    }

    async updateTaskSchedule(taskId: number, startDateString: string, duration: number): Promise<string | null> {
        const dto: any = {};
        dto[TaskTable.taId] = taskId;
        dto[TaskTable.taStartDate] = startDateString;
        dto[TaskTable.taDuration] = duration;
        dto['TableName'] = TaskTable.databaseTableName;
        const result = await this.patchAsync(this.endPoint(ApiEndpoints.tasks), dto);
        if (result) {
            return result.statusCode as string;
        }
        return null;
    }

    async updateTasksSchedule(datas: {taskId: number, startDate: string, duration: number}[]): Promise<number | null> {
        const dto: {}[] = [];
        datas.forEach(data => {
            const d: any = {};
            d[TaskTable.taId] = data.taskId;
            d[TaskTable.taStartDate] = data.startDate;
            d[TaskTable.taDuration] = data.duration;
            dto.push(d);
        });
        const result = await this.patchAsync(this.endPoint(this.scheduleEndpoint), dto);
        if (result) {
            return result.payload as number;
        }
        return null;
    }

    async updateTasksFacility(taskId: number, companyTaskId: number, amount: number): Promise<number | null> {
        const dto: any = {};
        dto[TaskFacilityTable.taFaTaskId] = taskId;
        dto[TaskFacilityTable.taFaCompanyTaskId] = companyTaskId;
        dto[TaskFacilityTable.taFaUnitOfWorkAmount] = amount;
        const result = await this.patchAsync(this.endPoint(this.taskFacilityUpdateEndpoint), dto);
        if (result) {
            return result.payload as number;
        }
        return null;
    }
    
    async updateDatesAndDurations(values: { taskId: number, startDate: Date, duration: number }[]): Promise<boolean> {
        const dto: { TaskId: number, StartDate: string, Duration: number }[] = [];
        values.forEach(v => {
            dto.push({ TaskId: v.taskId, StartDate: DateUtils.toBackendString(v.startDate), Duration: v.duration });
        });
        const result = await this.patchAsync(this.endPoint(this.taskDatesAndDurationsUpdateEndpoint), dto);
        if (result) {
            return result.payload as boolean;
        }
        return false;
    }

    async validateTask(taskId: number, isApproved: boolean, comment: string | null, updateRefArea: boolean = false): Promise<TaskValidationDTO | null> {
        const dto: any = {};
        dto[TaskValidationTable.taVaTaskId] = taskId;
        dto[TaskValidationTable.taVaIsApproved] = isApproved;
        dto[TaskValidationTable.taVaComment] = comment;
        dto["UpdateRefArea"] = updateRefArea;

        const result = await this.postAsync(this.endPoint(ApiEndpoints.tasksValidations), dto);
        if (result) {
            return new TaskValidationDTO(result.payload);
        }
        return null;
    }

    async createVariantTask(taskId: number, taskName: string): Promise<any> {
        const dto: any = {};
        dto[TaskTable.taId] = taskId;
        dto[TaskTable.taName] = taskName;
        const result = await this.postAsync<any>(this.endPoint(ApiEndpoints.tasksVariant), dto);
        // TODO : typer le retour
        // Le backend retourne un dictionnaire avec trois entrées :
        // [0] task
        // [1] task_floor
        // [2] task_link
        // A priori seul le Ta_Id est utile
        if (result) {
            return result.payload;
        }
        return null;
    }

    async createDirectTask(roomIds: number[], taskName: string, layersIds: number[]): Promise<Task | null> {
        let params = new HttpParams().append('floorModelId', layersIds.join(', '));
        const dto = {
            RoomIds: roomIds,
            [TaskTable.taName] : taskName,
        };
        return await this.postAsyncAndGetSingle<Task>(TablesNamesEnum.Task, this.endPoint(ApiEndpoints.cadProjectsDirectTask), dto, params);
    }

    // NOTA : depth est géré par le backend à partir du parent
    async createTask(
        name: string, 
        startDateString: string, 
        duration: number, 
        typeId: TaskTypeEnum, 
        taIsTemplate: boolean, 
        parentId: number | null,
        businessTypeId: number | null,
        contributor: any[],
        floorId: number | null,
        floorModels: any[],
        taskFacilityDto?: {},
        sourceTaskId?: number,
        taskLinkTypeId?: TaskLinkTypeEnum): Promise<TaskCreateResultDTO | null> {
        const dto = {
            [TaskTable.taName]: name,
            [TaskTable.taDescription]: null,
            [TaskTable.taStartDate]: startDateString,
            [TaskTable.taDuration]: duration,
            [TaskTable.taLoad]: 1,
            [TaskTable.taTypeId]: typeId,
            [TaskTable.taIsTemplate]: taIsTemplate,
            [TaskTable.taStatusId]: TaskStatusEnum.Pending,
            [TaskTable.taParentId]: parentId,
            [TaskTable.taBusinessTypeId]: businessTypeId,
            [TaskContributorTable.databaseTableName]: contributor,
            [TaskFloorModelTable.databaseTableName]: floorModels
        };

        if (sourceTaskId && taskLinkTypeId) {
            DbModelUtils.setProperty(dto, TaskLinkTable.taLiSourceTaskId, sourceTaskId);
            DbModelUtils.setProperty(dto, TaskLinkTable.taLiLinkType, taskLinkTypeId);
        }

        if (taskFacilityDto) {
            DbModelUtils.setProperty(dto, TaskFacilityTable.databaseTableName, taskFacilityDto);
        }

        if (floorId !== null) {
            DbModelUtils.setProperty(dto, TaskFloorTable.taFlFloorId, floorId);
            DbModelUtils.setProperty(dto, TaskFloorTable.taFlIsSourceFloor, true);
            DbModelUtils.setProperty(dto, TaskFloorTable.taFlIsTargetFloor, true);
        }

        const result = await this.postAsync<any>(this.endPoint(ApiControllers.tasks), dto);
        if (result) return new TaskCreateResultDTO(result.payload);
        return null;
    }

    async createLink(sourceTaskId: number, targetTaskId: number, linkTypeId: number): Promise<TaskLinkView | null> {
        const dto = {
            [TaskLinkTable.taLiSourceTaskId] : sourceTaskId,
            [TaskLinkTable.taLiTargetTaskId] : targetTaskId,
            [TaskLinkTable.taLiLinkType] : linkTypeId
        };
        return await this.postAsyncAndGetSingle<TaskLinkView>(ViewsNames.TaskLinkView, this.endPoint(ApiControllers.taskLinks), dto);
    }

    async deleteTask(taskId: number): Promise<number[]> {
        const result = await this.deleteAsync<number[]>(this.endPoint(this.deleteTaskEndpoint(taskId)));
        if (result) return result.payload;
        return [];
    }
}