import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PathGizmo } from '../../model/path-gizmo';

@Component({
  selector: 'g[xc-picto-path-gizmo]',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './path-gizmo.component.svg',
  styleUrls: ['./path-gizmo.component.scss']
})
export class PathGizmoComponent {
  @Input() model: PathGizmo | undefined;
}
