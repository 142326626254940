@if (model) {
    <div class="h-full">
        <p-tabView styleClass="h-full" (onChange)="model.selectedTabChange($event)">
            @for (tab of model.tabs; track tab.id) {
                <p-tabPanel [header]="tab.name">
                    <div class="projectsTabContent">
                        @switch (tab.id) {
                            @case (model.mobilityProjectsGanttBrowserTabsEnum.projects) {
                                <xc-mobility-projects-gantt-tree [model]="$any(tab.content)" />
                            }
                            @case (model.mobilityProjectsGanttBrowserTabsEnum.edition) {
                                <xc-mobility-project-gantt-editor [model]="$any(tab.content)" />
                            }
                        }
                    </div>
                </p-tabPanel>
            }
        </p-tabView>
    </div>
}