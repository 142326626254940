import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MaterialModule } from 'src/app/core/material.module';
import { ThemeCaptions } from '../../model/theme-captions';

@Component({
  standalone: true,
  imports: [CommonModule, MaterialModule],
  selector: 'xc-theming',
  templateUrl: './theming.component.html',
  styleUrls: ['./theming.component.scss']
})
export class ThemingComponent {
  @Input() model: ThemeCaptions | undefined;
}
