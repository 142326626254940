import { TablesNamesEnum } from './../tables-names-enum';

export const AppUserTable = {
    databaseTableName: TablesNamesEnum.AppUser,
    apUsId: 'ApUs_Id',
    apUsDirectoryId: 'ApUs_DirectoryId',
    apUsCode: 'ApUs_Code',
    apUsTitle: 'ApUs_Title',
    apUsFirstName: 'ApUs_FirstName',
    apUsLastName: 'ApUs_LastName',
    apUsRoleId: 'ApUs_RoleId',
    apUsIsActive: 'ApUs_IsActive',
    apUsPolicyId: 'ApUs_PolicyId',
    apUsPerimeterId: 'ApUs_PerimeterId',
    apUsLastRoute: 'ApUs_LastRoute'
  };
  