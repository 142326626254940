import { BuildingTable } from '../../db-model/tables/building-table';
import { DefinedModelBase } from '../defined-model-base';

export class Building extends DefinedModelBase {
    buId!: number;
    buCode!: string;
    buName!: string;
    buSiteId!: number;
    buIsActive!: boolean;
    buIsFictive!: boolean;
    buLocation!: string | null;
    buDefaultPerimeterId!: number;
    buMailingAddressId!: number | null; 
    buDeliveryAddressId!: number | null;
    buParkingAddressId!: number | null;
    buLobbyAddressId!: number | null;
    
    constructor(dtoData: any, tableDef: any[] = []) {
        super(dtoData, [BuildingTable].concat(tableDef));
    }
}