import { BpSvgEntity } from './bp-svg-entity';
import { SvgEntity } from 'src/app/core/model/svg-model/svg-entity.model';
import { GeometricElement } from 'src/app/core/model/geometry-model/geometric-element.model';

export class BpSvgPath extends BpSvgEntity {
    d: string | undefined;
    geometry: GeometricElement[] = [];

    constructor(dtoData: any) {
        super(dtoData);
        this.d = SvgEntity.getValue(dtoData, "d");
        this.geometry = SvgEntity.getValue(dtoData, "geometry", undefined, false);
    }

    updatePath(dAttribute: string): void {
        this.d = dAttribute;
    }

    override clone(): BpSvgPath {
        return new BpSvgPath(super.clone());
    }
}