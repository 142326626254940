import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IViewModel } from 'src/app/components-lib/shared-model/i-view-model';
import { BusinessUnitsTreeModel } from '../../model/business-units-tree-model';
import { TreeModule } from 'primeng/tree';

@Component({
  selector: 'pb-business-units-tree',
  standalone: true,
  imports: [CommonModule, TreeModule],
  templateUrl: './business-units-tree.component.html',
  styleUrls: ['./business-units-tree.component.scss']
})
export class BusinessUnitsTreeComponent implements IViewModel {
  @Input() model: BusinessUnitsTreeModel | undefined;
}
