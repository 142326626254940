import { SimulationSandboxTable } from "../../db-model/tables/simulation-sandbox-table";
import { DefinedModelBase } from "../defined-model-base";

export class SimulationSandbox extends DefinedModelBase {
    siSaId!: number;
    siSaSimulationPhoto!: number;
    siSaSimulationBusinessUnit!: number;
    siSaArea!: number;

    constructor(dtoData: any, tableDef: any[] = []) {
        super(dtoData, [SimulationSandboxTable].concat(tableDef));
    }
}