import { TabItem } from "./tab-item-model";
import { PageRouter } from "src/app/ui/main/model/router/page-router";
import { EventListener } from "src/app/core/events/event-listener";

export abstract class Tabs extends EventListener {
    static selectedTabChanged = "selectedTabChanged";

    tabItems: TabItem[] = [];
    selectedTab: TabItem | undefined;
    routingLevel: number = -1;

    /**
     * Si les onglets ne sont pas routés, il faut passer -1
     * @param routingLevel Le niveau du membre de route des onglets
     */
    constructor(routingLevel: number, invariantId: string) {
        super(invariantId);

        this.routingLevel = routingLevel;
    }

    initialize(): void {
        this.selectedTab = undefined;
        this.tabItems.splice(0);
    }

    async selectTabItem(index: number | string): Promise<void> {
        let hasChanged: boolean = false;
        if (index !== this.selectedTab?.index) {
            hasChanged = true;
            this.tabItems.forEach(async t => {
                if (t.index === index) {
                    t.isSelected = true;
                    this.selectedTab = t;
                    if (this.routingLevel > -1) {
                        await PageRouter.append(isNaN(index as any) ? index as string : "", this.routingLevel);
                    }

                } else {
                    t.isSelected = false;
                }
            });
        }

        await this.emitEventAsync(`${this.invariantId}.${Tabs.selectedTabChanged}`, this.selectedTab, hasChanged);
    }

    selectedTabClick(t: TabItem): void {
        this.selectTabItem(t.index);
    }
}