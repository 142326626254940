@if (model && model.pager) {
    <div id="red-main" class="red-panel">
        <div id="viewOptions">
          <mat-tab-group (selectedTabChange)="model.pager.selectedTabChange($event)" fitInkBarToContent mat-stretch-tabs="false"
            mat-align-tabs="center">
            @for (c of model.pager.tabs; track c) {
            <mat-tab [label]="c.name">
              <ng-template matTabContent>
                <div id="viewContent">
                  @switch (c.id) {
                    @case (model.pager.providersTabEnum.list) {
                      <div class="flex justify-content-center py-3" [style]="{maxHeight: 'calc(100vh - 48px - 3rem)'}">
                        <xc-dyn-table [model]="$any(c.content?.content)" />
                      </div>
                    }
                    @case (model.pager.providersTabEnum.dashboard) {
                        <xc-providers-dashboard class="extended" [model]="$any(c.content)" />
                    }
                  }
                </div>
              </ng-template>
            </mat-tab>
            }
          </mat-tab-group>
        </div>
      </div>
}
