import { TaskTable } from './../../db-model/tables/task-table';
import { DefinedModelBase } from '../defined-model-base';

export class Task extends DefinedModelBase {
    taId!: number;
    taName!: string;
    taDescription!: string | null;
    taParentId!: number | null;
    taDepth!: number;
    taStartDate!: Date;
    taDuration!: number;
    taIsTemplate!: boolean;
    taLoad!: number;
    taStatusId!: number;
    taFulfilled!: number;
    taTypeId!: number;
    taRootTaskId!: number | null;
    taBusinessId!: number | null;
    taBusinessTypeId!: number | null;

    constructor(dtoData: any) {
        super(dtoData, [TaskTable]);
        // Il faut convertir explicitement la date en Date parce qu'elle arrive nativement comme string
        this.taStartDate = new Date(this.taStartDate);
    }
}
  