import { DatePropertyComponent } from './../date-property/date-property.component';
import { NumberPropertyComponent } from './../number-property/number-property.component';
import { SelectPropertyComponent } from './../select-property/select-property.component';
import { StringPropertyComponent } from './../string-property/string-property.component';
import { BooleanPropertyComponent } from './../boolean-property/boolean-property.component';
import { PropertyGridVM } from './../../model/property-grid-vm';
import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'xc-property-grid',
  standalone: true,
  imports: [CommonModule, BooleanPropertyComponent, StringPropertyComponent, SelectPropertyComponent, NumberPropertyComponent, DatePropertyComponent],
  templateUrl: './property-grid.component.html',
  styleUrls: ['./property-grid.component.scss']
})
export class PropertyGridComponent {
  @Input() model: PropertyGridVM<any> | undefined;
}
