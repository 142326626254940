import { SvgEntityTypesEnum } from "src/app/core/model/svg-model/svg-entity-type-enum";
import { InsertGizmo } from "./insert-gizmo";
import { Point } from "src/app/core/model/geometry-model/point.model";
import { SvgPathService } from "src/app/core/model/svg-model/svg-path-service";
import { SvgEntity } from "src/app/core/model/svg-model/svg-entity.model";
import { SvgLine } from "src/app/core/model/svg-model/svg-line.model";
import { SvgConstants } from "src/app/core/model/svg-model/svg-constants";
import { SvgPath } from "src/app/core/model/svg-model/svg-path.model";
import { Vector } from "src/app/core/model/geometry-model/vector.model";
import { PathBuilder } from "src/app/core/model/svg-model/svg-path-builder";
import { XcMaths } from "src/app/core/model/static-functions/xc-maths";
import { logError } from "src/app/core/services/logging-service";

export class PathInsertGizmo extends InsertGizmo {
    segments: SvgEntity[] = [];
    //d: string = "";
    arcTempSecondPoint: Point | undefined;
    currentSegmentType: SvgEntityTypesEnum = SvgEntityTypesEnum.line;
    currentPoint: Point | undefined;
    strokeWidth: number = 0.01;
    startPointIsDefined: boolean = false;
    endPointIsDefined: boolean = false;
    isClosed: boolean = false;
    SvgEntityTypesEnum = SvgEntityTypesEnum;

    constructor() {
        super(SvgEntityTypesEnum.path);
        this.initialize();
    }

    protected override initialize(): void {
        this.segments.splice(0);
        this.segments.push(SvgLine.fromValues(0, 0, 0, 0));
        this.currentSegmentType = SvgEntityTypesEnum.line;
        this.startPointIsDefined = false;
        this.endPointIsDefined = false;
        this.isClosed = false;
        this.completed = false;
    }

    update(point: Point): void {
        this.currentPoint = point;

        if (!this.startPointIsDefined) {
            this.updateFakeSegment(point);
        } else {
            const last = this.segments[this.segments.length - 1];
            if (!this.endPointIsDefined) {
                switch (last.entityType) {
                    case SvgEntityTypesEnum.line:
                        const l = last as SvgLine;
                        l.x2 = point.x;
                        l.y2 = point.y;
                        break;
                    case SvgEntityTypesEnum.path:
                        const p = last as SvgPath;
                        const psp = SvgPathService.getPathStartPoint(p.d);
                        const pep = SvgPathService.getPathEndPoint(p.d);
                        if (psp && pep) {
                            const cep = `${pep.x} ${pep.y}`;
                            const nep = `${point.x} ${point.y}`;
                            p.d = p.d?.replace(cep, nep);

                            const cr = SvgPathService.getRadius(p.d!);
                            const crs = `${cr[0]} ${cr[1]}`;
                            const dist = XcMaths.round(psp.distanceTo(point), 2);
                            const ndist = `${dist} ${dist}`;
                            p.d = p.d?.replace(crs, ndist);
                            }
                        break;
                    default:
                        break;
                }
            } else {
                if (this.currentSegmentType === SvgEntityTypesEnum.line) {
                    const l = last as SvgLine;
                    const lep = new Point(l.x2, l.y2);
                    this.segments.push(SvgLine.fromValues(lep.x, lep.y, point.x, point.y));
                } else {
                    const p = last as SvgPath;
                    const pep = SvgPathService.getPathEndPoint(p.d);
                    if (pep) {
                        const dist = XcMaths.round(pep.distanceTo(point), 3);
                        const ap = PathBuilder.getArcPath(pep, point, dist, 0, 1);
                        this.segments.push(SvgPath.fromStatement(`${SvgConstants.PathAbsoluteMoveToFlagName}${ap}`));
                    }
                }
                this.endPointIsDefined = false;
            }
        }

        //this.updateDAttribute();
    }

    updateFakeSegment(point: Point): void {
        if (this.segments.length === 1) {
            const ep = point.translate(new Vector(0.5, -0.5));
            if (this.segments[0].entityType === SvgEntityTypesEnum.line) {
                const l = this.segments[0] as SvgLine;
                l.x1 = point.x;
                l.y1 = point.y;
                l.x2 = ep.x;
                l.y2 = ep.y;
            } else {
                const p = this.segments[0] as SvgPath;
                const psp = SvgPathService.getPathStartPoint(p.d);
                const pep = SvgPathService.getPathEndPoint(p.d);
                if (psp && pep) {
                    const csp = `${psp.x} ${psp.y}`;
                    const cep = `${pep.x} ${pep.y}`;
                    const nsp = `${point.x} ${point.y}`;
                    const nep = `${ep.x} ${ep.y}`;
                    p.d = p.d?.replace(csp, nsp);
                    p.d = p.d?.replace(cep, nep);
                }
            }
        }
    }

    getGlobalStatement(): string {
        let tmp: string = "";
        if (this.segments.length > 0) {
            const s1 = this.segments[0];
            tmp = this.getEntityStatement(s1, true);
            for (let i = 0; i < this.segments.length - 1; i++) {
                const e = this.segments[i];
                tmp += " " + this.getEntityStatement(e);
            }

            if (this.isClosed) {
                const last = this.segments[this.segments.length - 1];
                if (last.entityType === SvgEntityTypesEnum.path) {
                    let sp: Point | undefined = undefined;
                    if (s1.entityType === SvgEntityTypesEnum.line) {
                        const l = s1 as SvgLine;
                        sp = new Point(l.x1, l.y1);
                    } else {
                        const p = s1 as SvgPath;
                        sp = SvgPathService.getPathStartPoint(p.d!);
                    }
                    tmp += ` ${SvgConstants.PathAbsoluteLineToFlagName}${sp!.x} ${sp!.y}`;
                } else {
                    tmp += " Z";
                }
            }
        }
        return tmp;
    }

    getEntityStatement(entity: SvgEntity, first: boolean = false): string {
        switch (entity.entityType) {
            case SvgEntityTypesEnum.line:
                return this.getLineStatement(first ? SvgConstants.PathAbsoluteMoveToFlagName : SvgConstants.PathAbsoluteLineToFlagName, entity as SvgLine);
            case SvgEntityTypesEnum.path:
                return this.getPathStatement(entity as SvgPath, first);
            default:
                return "";
        }
    }

    getLineStatement(flag: string, line: SvgLine): string {
        if (flag === SvgConstants.PathAbsoluteMoveToFlagName) {
            return `${flag}${line.x1} ${line.y1} ${SvgConstants.PathAbsoluteLineToFlagName}${line.x2} ${line.y2}`;
        }
        return `${flag}${line.x2} ${line.y2}`;
    }

    getPathStatement(path: SvgPath, first: boolean): string {
        if (first) {
         return path.d!;
       }
       const aFlagIndex = path.d!.indexOf(SvgConstants.PathArcFlagName);
       return path.d!.substring(aFlagIndex);
    }

    define(point: Point | undefined): void {
        if (point) this.update(point);
        if (!this.startPointIsDefined) {
            this.startPointIsDefined = true;
        } else {
            if (!this.endPointIsDefined) {
                this.endPointIsDefined = true;
            }
        }
    }

    setCurrentType(type: SvgEntityTypesEnum): void {
        this.currentSegmentType = type;
        let last = this.segments.splice(this.segments.length - 1, 1)[0];
        if (last.entityType !== type) {
            if (type === SvgEntityTypesEnum.line) {
                const p = last as SvgPath;
                const sp = SvgPathService.getPathStartPoint(p.d);
                const ep = SvgPathService.getPathEndPoint(p.d);
                if (sp && ep) {
                    this.segments.push(SvgLine.fromValues(sp.x, sp.y, ep.x, ep.y));
                }

            } else {
                const l = last as SvgLine;
                const sp = new Point(l.x1, l.y1);
                const ep = new Point(l.x2, l.y2);
                const dist = XcMaths.round(sp.distanceTo(ep), 3);
                const ap = PathBuilder.getArcPath(sp, ep, dist, 0, 1);
                this.segments.push(SvgPath.fromStatement(`${SvgConstants.PathAbsoluteMoveToFlagName}${ap}`));
            }

            this.update(this.currentPoint!);
        }
    }

    close(): void {
        if (this.segments.length >= 2) {
            switch (this.currentSegmentType) {
                case SvgEntityTypesEnum.line:
                    break;
                case SvgEntityTypesEnum.path:
                    break;
                default:
                    break;
            }
            this.isClosed = true;
            this.raiseInsertionCompleted();
        }
    }

    end(): void {

        this.raiseInsertionCompleted();
    }
    
    raiseInsertionCompleted(): void {
        if (this.insertionCompleted) {
            this.insertionCompleted();
        } else {
            logError("PathInsertGizmo.insertionCompleted n'est pas écouté");
        }
    }
}