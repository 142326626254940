import { ApiEndpoints } from './../api-endpoints';
import { ApiService } from './../api-service';
import { Service } from 'typedi';
import { CieUserGrantsViewDTO } from './dto/cie-user-grants-view-dto';

@Service({ global: true })  
export class ClientService extends ApiService {
    constructor() {
        super();
    }

    async getClients(): Promise<CieUserGrantsViewDTO[]> {
        //const result = await this.getAsync<ClientVM[]>(this.endPoint(ApiEndpoints.ecsyFile));
        return await this.getArray2Async<CieUserGrantsViewDTO>(CieUserGrantsViewDTO, this.endPoint(ApiEndpoints.ecsyFile));
        // if (result != null && result.payload) {
        //     const payload: ClientVM[] = [];
        //     result.payload.forEach(e => {
        //         payload.push(new ClientVM(e));
        //     });
        //     return payload;
        // }
        return [];
    }
}