import { Floor } from './../../../model/data-model/tables/floor';
import { SvgGroupDTO } from './svg-group-dto';

export class FloorAxonoDTO {
    floor!: Floor;
    viewbox: string;
    groups: SvgGroupDTO[] = [];

    constructor(dtoData: any) {
        this.floor = new Floor(dtoData);
        this.viewbox = dtoData["viewbox"];
        dtoData["SvgGroupDTO"].forEach((g: any) => {
            this.groups.push(new SvgGroupDTO(g));
        });
    }
}