import { DateUtils } from "src/app/core/model/static-functions/date-utils";
import { GanttScaleEnum } from "../../../../move-work/nested/scheduling/model/gantt-scale-enum";
import { ScheduleHeaderCell } from "./schedule-header-cell";
import { StringUtils } from "src/app/core/model/static-functions/string-utils";

export class ScheduleHeader {
    startDate: Date;
    duration: number;
    firstLineCells: ScheduleHeaderCell[] = [];
    secondLineCells: ScheduleHeaderCell[] = [];
    currentUnitWidth: number = 1;
    scale: GanttScaleEnum = GanttScaleEnum.day;

    constructor(startDate: Date, duration: number) {
        this.startDate = startDate;
        this.duration = duration;
    }

    isToday(d: Date): boolean {
        return DateUtils.equals(DateUtils.today(), d);
    }

    calculateHeader(currentUnitWidth: number, scale: GanttScaleEnum): void {
        this.currentUnitWidth = currentUnitWidth;
        this.scale = scale;

        // Peut être vaudrait-il mieux actualiser les width des cellules existantes
        // plutôt que de les recréer ?
        this.firstLineCells.splice(0);
        this.secondLineCells.splice(0);

        this.setFirstLineLabels();
        this.setSecondLineLabels();
    }

    setFirstLineLabels(): void {
        const endDate =  DateUtils.addDays(this.startDate, this.duration - 1);
        const firstYear = this.startDate.getFullYear();
        const lastYear = endDate.getFullYear();

        switch (this.scale) {
            case GanttScaleEnum.day:
                // 2022 sem 15
                this.setWeekLabels(endDate, true);
                break;
            case GanttScaleEnum.week:
                // Juillet 2022
                this.setMonthLabels(firstYear, lastYear, endDate, true);
                break;
            case GanttScaleEnum.month:
                // 2022 T4
                this.setQuarterLabels(endDate, true);
                break;
            case GanttScaleEnum.quarter:
                // 2022 S2
                this.setHalfLabels(endDate, true);
                break;
            case GanttScaleEnum.half:
                // 2022
                const firstYInfos = DateUtils.yearInfos(this.startDate);
                const lastYInfos = DateUtils.yearInfos(endDate);
                
                if (firstYInfos.lasting > 0) {
                    if (firstYInfos.lasting + 1 > this.duration - 1) {
                        firstYInfos.lasting = this.duration - 1;
                    }
                    this.firstLineCells.push(new ScheduleHeaderCell(`${firstYear}`, this.startDate, firstYInfos.lasting + 1));
                }

                for (let y = firstYear + 1; y < lastYear; y++) {
                    const el = DateUtils.yearInfos(new Date(y, 0, 1));
                    this.firstLineCells.push(new ScheduleHeaderCell(`${el.year}`, el.startDate, el.total));
                }

                
                if (firstYear !== lastYear && lastYInfos.passed > 0) {
                    if (lastYInfos.passed + 1 > this.duration - 1) {
                        lastYInfos.passed = this.duration - 1;
                    }
                    this.firstLineCells.push(new ScheduleHeaderCell(`${lastYear}`, lastYInfos.startDate, lastYInfos.passed + 1));
                }

                break;
            case GanttScaleEnum.year:
                break;
           default:
                break;
        }

    }

    setSecondLineLabels(): void {
        const endDate =  DateUtils.addDays(this.startDate, this.duration - 1);
        const firstYear = this.startDate.getFullYear();
        const lastYear = endDate.getFullYear();

        switch (this.scale) {
            case GanttScaleEnum.day:
                // lu 8
                for (let i = 0; i < this.duration; i++) {
                    const d = DateUtils.addDays(this.startDate, i);
                    const day = d.getDay();
                    const dayName = DateUtils.getDayName(day);
                    const text = this.currentUnitWidth > 1.95 ? `${dayName} ${d.getDate()}` : `${d.getDate()}`;
                    this.secondLineCells.push(new ScheduleHeaderCell(text, d, 1));
                }
                break;
            case GanttScaleEnum.week:
                // s7 8/02 - 14/02
                // s7 (lorque la place n'est pas suffisante)
                this.setWeekLabels(endDate, false);
                break;
            case GanttScaleEnum.month:
                // janvier
                this.setMonthLabels(firstYear, lastYear, endDate, false);
                break;
            case GanttScaleEnum.quarter:
                // T3
                this.setQuarterLabels(endDate, false);
                break;
            case GanttScaleEnum.half:
                // S2
                this.setHalfLabels(endDate, false);
                break;
            case GanttScaleEnum.year:
                break;
           default:
                break;
        }
    }
    
    getWeekLabel(startDate: Date, duration: number, firstLine: boolean): string {
        if (firstLine) {
            const month = startDate.getMonth();
            return `${DateUtils.getMonthName(month, false)} ${startDate.getFullYear()} sem ${DateUtils.getWeekNumber(startDate)}`;
        }

        const endDate =  DateUtils.addDays(startDate, duration - 1);
        const wn = DateUtils.getWeekNumber(startDate);
        const sd = `${startDate.getDate()}/${StringUtils.fixedDigitsFormat(startDate.getMonth() + 1)}`;
        const ed = `${endDate.getDate()}/${StringUtils.fixedDigitsFormat(endDate.getMonth() + 1)}`;
        //return this.currentUnitWidth > 0.5 ? `s${wn} ${sd} - ${ed}` : `s${wn}`;
        return `s${wn} ${sd} - ${ed}`;
    }

    setCell(cell: ScheduleHeaderCell, firstLine: boolean): void {
        if (firstLine) {
            this.firstLineCells.push(cell);
        } else {
            this.secondLineCells.push(cell);
        }
    }

    setHalfLabels(endDate: Date, firstLine: boolean): void {
        const halves = DateUtils.halves(this.startDate, endDate);
        const firstHInfos = DateUtils.halfInfos(this.startDate);
        const lastHInfos = DateUtils.halfInfos(endDate);

        if (firstHInfos.lasting > 0) {
            if (firstHInfos.lasting + 1 > this.duration - 1) {
                firstHInfos.lasting = this.duration - 1;
            }
            const prefix = firstLine ? `${firstHInfos.year} ` : "";
            const cell = new ScheduleHeaderCell(`${prefix} S${firstHInfos.half + 1}`, this.startDate, firstHInfos.lasting + 1);
            this.setCell(cell, firstLine);
        }

        for (let h = 1; h < halves.length - 1; h++) {
            const el = halves[h];
            const prefix = firstLine ? `${el.year} ` : "";
            const cell = new ScheduleHeaderCell(`${prefix} S${el.half + 1}`, el.startDate, el.total);
            this.setCell(cell, firstLine);
        }

        if (lastHInfos.passed > 0) {
            if (lastHInfos.passed + 1 > this.duration - 1) {
                lastHInfos.passed = this.duration - 1;
            }
            const prefix = firstLine ? `${lastHInfos.year} ` : "";
            const cell = new ScheduleHeaderCell(`${prefix} S${lastHInfos.half + 1}`, lastHInfos.startDate, lastHInfos.passed + 1);
            this.setCell(cell, firstLine);
        }
    }

    setQuarterLabels(endDate: Date, firstLine: boolean): void {
        const quarters = DateUtils.quarters(this.startDate, endDate);
        const firstQInfos = DateUtils.quarterInfos(this.startDate);
        const lastQInfos = DateUtils.quarterInfos(endDate);

        if (firstQInfos.lasting > 0) {
            if (firstQInfos.lasting + 1 > this.duration - 1) {
                firstQInfos.lasting = this.duration - 1;
            }
            const prefix = firstLine ? `${firstQInfos.year} ` : "";
            const cell = new ScheduleHeaderCell(`${prefix} T${firstQInfos.quarter + 1}`, this.startDate, firstQInfos.lasting + 1);
            this.setCell(cell, firstLine);
        }

        for (let q = 1; q < quarters.length - 1; q++) {
            const el = quarters[q];
            const prefix = firstLine ? `${el.year} ` : "";
            const cell = new ScheduleHeaderCell(`${prefix} T${el.quarter + 1}`, el.startDate, el.total);
            this.setCell(cell, firstLine);
        }

        if (lastQInfos.passed > 0) {
            if (lastQInfos.passed + 1 > this.duration - 1) {
                lastQInfos.passed = this.duration - 1;
            }
            const prefix = firstLine ? `${lastQInfos.year} ` : "";
            const cell = new ScheduleHeaderCell(`${prefix} T${lastQInfos.quarter + 1}`, lastQInfos.startDate, lastQInfos.passed + 1);
            this.setCell(cell, firstLine);
        }
    }

    setMonthLabels(firstYear: number, lastYear: number, endDate: Date, firstLine: boolean): void {
        const firstMonthDay = this.startDate.getDate();
        const firstMonth = this.startDate.getMonth();
        const firstMonthDaysCount = DateUtils.daysInMonth(firstMonth + 1, firstYear);
        const firstMonthDays = firstMonthDaysCount - firstMonthDay + 1;

        const lastMonthDay = endDate.getDate();
        const lastMonth = endDate.getMonth();
        const lastMonthDaysCount = DateUtils.daysInMonth(lastMonth + 1, lastYear);
        const lastMonthDays = lastMonthDay;

        if (firstMonthDays !== firstMonthDaysCount) {
            const suffix = firstLine ? ` ${firstYear}` : "";
            const cell = new ScheduleHeaderCell(`${DateUtils.getMonthName(firstMonth, false)}${suffix}`, this.startDate, firstMonthDays);
            this.setCell(cell, firstLine);
        }
        for (let y = firstYear; y < lastYear + 1; y++) {
            let monthStart = 0;
            if (y === firstYear) monthStart = firstMonth + 1;
            let monthEnd = 12;
            if (y === lastYear && lastMonthDays < lastMonthDaysCount) {
                monthEnd = lastMonth;
            };
            for (let m = monthStart; m < monthEnd; m++) {
                const daysInMonth = DateUtils.daysInMonth(m + 1, y);
                const suffix = firstLine ? ` ${y}` : "";
                const cell = new ScheduleHeaderCell(`${DateUtils.getMonthName(m, false)}${suffix}`, new Date(y, m, 0), daysInMonth);
                this.setCell(cell, firstLine);
            }
        }
        if (lastMonthDays < lastMonthDaysCount) {
            const endStartDate = DateUtils.addDays(endDate, -lastMonthDays);
            const suffix = firstLine ? ` ${lastYear}` : "";
            const cell = new ScheduleHeaderCell(`${DateUtils.getMonthName(lastMonth, false)}${suffix}`, endStartDate, lastMonthDays);
            this.setCell(cell, firstLine);
        }
    }

    setWeekLabels(endDate: Date, firstLine: boolean): void {
        const firstWeekday = this.startDate.getDay();
        const firstWeekDays = firstWeekday === 0 ? 1 : 7 - firstWeekday + 1;
        const lastWeekday = endDate.getDay();
        const lastWeekDays = lastWeekday === 0 ? 0 : lastWeekday;
        if (firstWeekday !== 1) {
            const cell = new ScheduleHeaderCell(this.getWeekLabel(this.startDate, firstWeekDays, firstLine), this.startDate, firstWeekDays);
            this.setCell(cell, firstLine);
        }
        for (let i = firstWeekDays; i <= this.duration - firstWeekDays - lastWeekDays; i+=7) {
            const idate =  DateUtils.addDays(this.startDate, i);
            const cell = new ScheduleHeaderCell(this.getWeekLabel(idate, 7, firstLine), idate, 7);
            this.setCell(cell, firstLine);
        }
        if (lastWeekday > 0) {
            const endStartDate = DateUtils.addDays(endDate, -lastWeekDays);
            const cell = new ScheduleHeaderCell(this.getWeekLabel(endStartDate, lastWeekDays, firstLine), endStartDate, lastWeekDays);
            this.setCell(cell, firstLine);
        }
    }
}