import { EquipmentPurchaseDbView } from "../../db-model/views/equipment-purchase-db-view";
import { EquipmentPurchase } from "../tables/equipment-purchase";

export class EquipmentPurchaseView extends EquipmentPurchase {
    registeredEquipmentItemsCount!: number;
    spacePlanningReservedCount!: number;

    constructor(dtoData: any) {
        super(dtoData, [EquipmentPurchaseDbView]);
    }
}