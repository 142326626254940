import { BoardToolbarCommandEnum } from "./board-toolbar-command-enum";

export class BoardToolbarCommand {
    id: BoardToolbarCommandEnum;
    name: string;
    icon: string;

    constructor(id: BoardToolbarCommandEnum, name: string, icon: string) {
        this.id = id;
        this.name = name;
        this.icon = icon;
    }
}