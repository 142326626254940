import { Point } from "src/app/core/model/geometry-model/point.model";
import { TaskCreateInternalDTO } from "../../../side-panel/projects-gantt/model/task-create-internal-dto";
import { TaskCreateResultDTO } from "src/app/core/services/backend-services/dto/task-create-result-dto";
import { TaskView } from "src/app/core/model/data-model/views/task-view";
import { TaskTypeEnum } from "src/app/core/model/data-model/enums/task-type-enum";
import { TaskProcessing } from "./task-processing";
import { DateUtils } from "src/app/core/model/static-functions/date-utils";
import { toastError } from "src/app/core/services/toast-service";

export class TaskInsertingProcess {
    dto: TaskCreateInternalDTO | undefined;
    processing: boolean = false;
    transientTaskPos: Point = Point.origin();
    targetTask: TaskView | undefined;
    targetProjectId: number | null = null;

    constructor() {}
    
    beguinInsert(dto: TaskCreateInternalDTO): void {
        this.processing = true;
        this.dto = dto;
    }

    endInsert(): void {
        this.processing = false;
        this.transientTaskPos = Point.origin();
    }

    async createTask(d: Date): Promise<TaskCreateResultDTO[] | null> {
        if (!this.processing || !this.dto) return null;
        const result: TaskCreateResultDTO[] = [];

        let createdTask: TaskCreateResultDTO | null  = null;

        const businessTypeId = this.dto.businessType ? this.dto.businessType.id : null;
        const facilityTaskId = this.dto.facilityTask ? this.dto.facilityTask.id : null;
        const floorId = this.dto.floor ? this.dto.floor.id : null;
        const layersIds = this.dto.layers.map(x=> x.id);

        // S'il s'agit de la création d'un projet modèle
        // la date de début est la date du jour par défaut
        if (this.dto.isTemplate && this.targetProjectId == null) {
            d = DateUtils.today();
        }

        if (this.dto.startNewProject) {
            // L'utilisateur a indiqué qu'il voulait créer un nouveau projet
            const newProject = await TaskProcessing.saveInsertedTask(
                this.targetProjectId,
                "Nouveau projet",
                TaskTypeEnum.Project,
                null,
                null,
                null,
                [],
                d, this.dto.isTemplate);
            
            if (newProject != null) {
                result.push(newProject);
                this.targetProjectId = newProject.task.taId;
            } else {
                toastError("Une erreur s'est produite. Contactez votre administrateur");
                return null;
            }
        } 

        // La tâche est ensuite créée soit dans le projet cible soit dans le projet nouvellement créé
        if (this.targetProjectId) {
            const newTask = await TaskProcessing.saveInsertedTask(
                this.targetProjectId,
                "NR",
                this.dto.type,
                businessTypeId,
                facilityTaskId,
                floorId,
                layersIds,
                d, this.dto.isTemplate);
            
            if (newTask != null) {
                result.push(newTask);
            }
        }

        this.endInsert();

        return result;
    }
}