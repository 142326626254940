import { RoomLayoutTypeTable } from '../../../model/db-model/tables/room-layout-type-table';
import { RoomAllocationDbView } from '../../../model/db-model/views/room-allocation-db-view';
import { RoomAllocationViewSet } from 'src/app/core/model/data-model/view-set/room-allocation-view-set';
import { RoomActivityStatusTypeDbView } from 'src/app/core/model/db-model/views/room-activity-status-type-db-view';
import { RoomAttributionTypeDbView } from 'src/app/core/model/db-model/views/room-attribution-type-db-view';
import { RoomLayoutType } from 'src/app/core/model/data-model/tables/room-layout-type';
import { RoomActivityStatusTypeView } from 'src/app/core/model/data-model/views/room-activity-status-type-view';
import { RoomAttributionTypeView } from 'src/app/core/model/data-model/views/room-attribution-type-view';
import { BusinessUnitView } from 'src/app/core/model/data-model/views/business-unit-view';
import { BusinessUnitDbView } from 'src/app/core/model/db-model/views/business-unit-db-view';
import { Building } from 'src/app/core/model/data-model/tables/building';
import { BuildingTable } from 'src/app/core/model/db-model/tables/building-table';
import { WorkplaceViewSet } from 'src/app/core/model/data-model/view-set/workplace-view-set';
import { WorkplaceDbView } from 'src/app/core/model/db-model/views/workplace-db-view';
import { WorkplaceTypeView } from 'src/app/core/model/data-model/views/workplace-type-view';
import { WorkplaceTypeDbView } from 'src/app/core/model/db-model/views/workplace-type-db-view';

export class BusinessUnitTreeDataDTO {
    businessUnitView: BusinessUnitView[] = [];
    roomAllocations: RoomAllocationViewSet[] = [];
    roomLayoutTypes: RoomLayoutType[] = [];
    roomActivityStatusTypes: RoomActivityStatusTypeView[] = [];
    roomAttributionTypes: RoomAttributionTypeView[] = [];
    buildings: Building[] = [];
    workplaceView: WorkplaceViewSet[] = [];
    workplaceTypeView: WorkplaceTypeView[] = [];

    constructor(dtoData: any) {
        if (dtoData) {
            dtoData[BusinessUnitDbView.databaseTableName].forEach((e: any) => {
                this.businessUnitView.push(new BusinessUnitView(e));
            });
            dtoData[RoomAllocationDbView.databaseTableName].forEach((e: any) => {
                this.roomAllocations.push(new RoomAllocationViewSet(e));
            });
            dtoData[RoomLayoutTypeTable.databaseTableName].forEach((e: any) => {
                this.roomLayoutTypes.push(new RoomLayoutType(e));
            });
            dtoData[RoomActivityStatusTypeDbView.databaseTableName].forEach((e: any) => {
                this.roomActivityStatusTypes.push(new RoomActivityStatusTypeView(e));
            });
            dtoData[RoomAttributionTypeDbView.databaseTableName].forEach((e: any) => {
                this.roomAttributionTypes.push(new RoomAttributionTypeView(e));
            });
            dtoData[BuildingTable.databaseTableName].forEach((e: any) => {
                this.buildings.push(new Building(e));
            });
            dtoData[WorkplaceDbView.databaseTableName].forEach((e: any) => {
                this.workplaceView.push(new WorkplaceViewSet(e));
            });
            dtoData[WorkplaceTypeDbView.databaseTableName].forEach((e: any) => {
                this.workplaceTypeView.push(new WorkplaceTypeView(e));
            });
        }
    }
}