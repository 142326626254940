export enum FloorModelEnum {
    Walls = 5,
    Doors = 6,
    Furniture = 7,
    Hardware = 8,
    FloorInsideContours = 10,
    FloorCoreContours = 11,
    BlueprintExtents = 12,
    FloorOutsideContours = 13,
    VerticalTrafficContours = 14,
    VerticalShaftContours = 15,
    FrontFrame = 17,
    FrameLabels = 18,
    FireSafety = 19,
    SanitaryWare = 20,
    PlanningMisc = 21,
    Structural = 22,
    PartitioningFrame = 24,
    Sketch = 26,
    Rooms = 29,
    RoomLabels = 31,
    WorkplaceLabels = 32,
    PeopleLabels = 33,
    Decoration = 35,
    Medical = 36,
    Vegetation = 37,
    Fitness = 38,
    Leisure = 39,
    Catering = 40,
    Accessibility = 41,
    Notes = 45,
    Measurement = 46
}