import { Point } from "src/app/core/model/geometry-model/point.model";
import { SvgEntityTypesEnum } from "src/app/core/model/svg-model/svg-entity-type-enum";
import { SelectableSvgEntity } from "../../../svg/selectable-svg-entity";
import { InteractionCommand } from "../interaction-command";
import { IInsertInteraction } from "./i-insert-interaction";
import { InsertGizmo } from "../../../gizmo/insert/model/insert-gizmo";

export abstract class InsertInteraction<T extends InsertGizmo> implements IInsertInteraction<T> {
    currentCommand: InteractionCommand;
    insertionCompleted?: (entity: SelectableSvgEntity) => void;
    entityType: SvgEntityTypesEnum;
    gizmo: T;

    constructor(command: InteractionCommand, gizmo: T, entityType: SvgEntityTypesEnum) {
        this.currentCommand = command;
        this.gizmo = gizmo;
        this.entityType = entityType;
    }

    define(point: Point | undefined): void {
        this.gizmo.define(point);
    }
    mouseDown(e: MouseEvent, hitPoint: Point): Promise<boolean> {
        throw new Error("Method not implemented.");
    }
    async mouseMove(e: MouseEvent, hitPoint: Point): Promise<void> {
        this.gizmo.update(hitPoint);
    }
    mouseUp(e: MouseEvent, hitPoint: Point | undefined): Promise<void> {
        throw new Error("Method not implemented.");
    }
    keyDown(e: KeyboardEvent): Promise<void> {
        throw new Error("Method not implemented.");
    }
}