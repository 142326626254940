@if (model != null) {
<mat-tree #reTree [dataSource]="model.dataSource" [treeControl]="model.treeControl" class="example-tree">
    <!-- This is the tree node template for leaf nodes -->
    <!-- There is inline padding applied to this node using styles.
      This padding value depends on the mat-icon-button width. -->
  <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
    <div class="treeNode" [ngClass]="{'selected': node.selected}">

        <mat-checkbox class="checklist-leaf-node"
          [disabled]="model.readonly"
          [checked]="node.selected"
          (change)="node.onSelectedChange()">
        </mat-checkbox>
      </div>
      <div class="pinColor" 
      [style.background-color]="node.bu.buUnColor">
    </div>
    {{node.bu.buUnName}}
  </mat-tree-node>
    <!-- This is the tree node template for expandable nodes -->
    <mat-nested-tree-node *matTreeNodeDef="let node; when: model.hasChild">
        <div class="mat-tree-node treeNode" [ngClass]="{'selected': node.selected}">
          <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.name">
            <mat-icon class="mat-icon-rtl-mirror">
              {{model.treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
            </mat-icon>
          </button>
          <mat-checkbox class="parent-node" [checked]="node.selected"
            [disabled]="model.readonly"
            [indeterminate]="node.childrenPartiallySelected"
            (change)="node.onSelectedChange()">
          </mat-checkbox>
          <div class="pinColor" 
            [style.background-color]="node.bu.buUnColor">
          </div>
          {{node.bu.buUnName}}
        </div>

        <!-- There is inline padding applied to this div using styles.
        This padding value depends on the mat-icon-button width.  -->
        <div [class.example-tree-invisible]="!model.treeControl.isExpanded(node)" role="group">
          <ng-container matTreeNodeOutlet></ng-container>
      </div>
    </mat-nested-tree-node>
</mat-tree>
}


