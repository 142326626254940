import { Point } from 'src/app/core/model/geometry-model/point.model';
import { BpSvgPeopleLabel } from '../../../../../svg-entities/model/bp-svg-people-label';
import { BpSvgWorkplaceLabel } from '../../../../../svg-entities/model/bp-svg-workplace-label';
import { EntitiesSelectionSet } from '../../../../itself/model/interaction/entities-selection-set';
import { UpdateGizmo } from './../../update-gizmo.model';
import { BpSvgEntity } from '../../../../../bp-svg-core-model/bp-svg-entity';
import { logError } from 'src/app/core/services/logging-service';

export class PeopleUpdateGizmoVM implements UpdateGizmo {
    selectedLabels: EntitiesSelectionSet = new EntitiesSelectionSet();
    initialMousePosition: Point | undefined;
    translatingWorkplace: BpSvgWorkplaceLabel | undefined;

    isEditable: boolean = false;

    show(labels: EntitiesSelectionSet): void {
        this.selectedLabels = labels;
    }

    hide(): void {
        this.selectedLabels.clear();
    }

    initializeTranslation(initialMousePosition: Point, workplace: BpSvgWorkplaceLabel): void {
        this.initialMousePosition = initialMousePosition;
        this.translatingWorkplace = workplace;
        if (this.selectedLabels.count === 1) {
            (this.selectedLabels.items[0] as BpSvgPeopleLabel).initializeTranslation();
        }
    }

    translate(hitPoint: Point): void {
        if (!this.initialMousePosition || !this.translatingWorkplace) return;
        const delta = new Point(hitPoint.x - this.initialMousePosition.x, hitPoint.y - this.initialMousePosition.y).vector();
        if (this.selectedLabels.count === 1) {
            const uniqueLabel = this.selectedLabels.items[0] as BpSvgPeopleLabel;
            uniqueLabel.translate(delta);
            // Actualise la ligne de repère
            uniqueLabel.updateLeaderLine(this.translatingWorkplace.backgroundRectangle);
        }
    }

    cancelTranslation(): void {
        if (this.selectedLabels.count === 1) {
            const uniqueLabel = this.selectedLabels.items[0] as BpSvgPeopleLabel;
            uniqueLabel.cancelTranslation();
        }
    }

    overlayMouseDown?: (e: MouseEvent) => void;
    onOverlayMouseDown(e: MouseEvent, label: BpSvgEntity): void {
        if (e.shiftKey) return;
        if (this.overlayMouseDown) {
            this.overlayMouseDown(e);
        } else {
            logError("PeopleUpdateGizmoVM.overlayMouseDown n'est pas écouté");
        }
    }
}