import { RoomLayoutType } from "src/app/core/model/data-model/tables/room-layout-type";
import { RoomLayoutTypeView } from "src/app/core/model/data-model/views/room-layout-type-view";

export class LayoutTypeTreeNode {
    lt: RoomLayoutTypeView;
    children: LayoutTypeTreeNode[] = [];
    selected: boolean = false;
    childrenPartiallyVisible: boolean = false;
    parentNode: LayoutTypeTreeNode | undefined;

    constructor(lt: RoomLayoutTypeView) {
        this.lt = lt;
    }
}