import * as L from 'leaflet';
import { EventEmitter } from 'src/app/core/events/event-emitter';
import { LayerGroup, Marker } from 'leaflet';
import { RealEstateMapInteraction } from './real-estate-map-interaction';

// Pour le détail de la mise en oeuvre
// https://blog.mestwin.net/leaflet-angular-marker-clustering/

const iconRetinaUrl = 'assets/marker-icon-2x.png';
const iconUrl = 'assets/marker-icon.png';
const shadowUrl = 'assets/marker-shadow.png';
const iconDefault = L.icon({
    iconRetinaUrl,
    iconUrl,
    shadowUrl,
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    tooltipAnchor: [16, -28],
    shadowSize: [41, 41]
});
L.Marker.prototype.options.icon = iconDefault;

export class RealEstateMap extends EventEmitter {
    private map: L.Map | undefined;
    markers: LayerGroup<Marker<any>> | undefined;

    constructor(defaultLatitude: number, defaultLongitude: number, defaultZoomFactor: number) {
        super();
        this.initMap(defaultLatitude, defaultLongitude, defaultZoomFactor);
    }

    private initMap(defaultLatitude: number, defaultLongitude: number, defaultZoomFactor: number): void {
        this.map = L.map('map', {
            center: [defaultLatitude, defaultLongitude],
            zoomControl: false,
            zoom: defaultZoomFactor,
        });

        L.control.zoom({
            position: 'bottomright'
        }).addTo(this.map);

        const tileUrls = [
            'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
            'http://b.tile.openstreetmap.fr/osmfr/{z}/{x}/{y}.png',
            'https://tiles.wmflabs.org/bw-mapnik/{z}/{x}/{y}.png',
            'https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png',
        ];

        const tiles = L.tileLayer(tileUrls[0], {
            maxZoom: 19,
            detectRetina: true,
            attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
        });

        tiles.addTo(this.map);
    }

    setMarkers(buildings: any[], companyId: string): void {
        this.markers = L.markerClusterGroup({
            showCoverageOnHover: false,
        });

        buildings.forEach(b => {
            const imageSrc = `https://static.echo-on.fr/${companyId}/Building/${b.id}.jpg`;
            //const tooltipId = `mapBuilding-${b.id}`;
            const toolTipContent = `<h3><a href='#'>${b.name}</a></h3><img width='220' src="${imageSrc}"/>`;
            const tooltip = L.DomUtil.create('div', 'map-building');
            tooltip.id = b.id;
            tooltip.innerHTML = toolTipContent;

            const marker = L.marker([b.latitude, b.longitude]);
            marker.bindPopup(tooltip, { minWidth: 220, closeOnEscapeKey: true });

            marker.on('mouseover', (e) => {
                //this.openPopup();
            });
            marker.on('mouseout', (e) => {
                //this.closePopup();
            });
            marker.on('click', (e: any) => {
                //this.buildingMarkerClick(b.id);
            });

            marker.addTo(this.markers!);

            // TODO : s'assurer que les listener sont bien détruits lors du changement de page
            tooltip.getElementsByTagName('a')[0].addEventListener('click', () => this.buildingMarkerClick(b.id));

        });
        this.markers.addTo(this.map!);

        this.markers.on('clusterclick', (e: any) => {
            // On utilise les coordonnées du premier marker
            // pour retrouver le node puis le parentNode
            const firstMarker = e.layer.getAllChildMarkers()[0];
            const coordinates = firstMarker.getLatLng();
            //this.cluserMarkerClick(coordinates.lat, coordinates.lng);
        });
    }

    setView(latitude: number, longitude: number, zoomFactor: number): void {
        this.map?.setView(new L.LatLng(latitude, longitude), zoomFactor);
    }

    async buildingMarkerClick(buildingId: number): Promise<void> {
        await this.emitEventAsync(RealEstateMapInteraction.onBuildingMarkerClick, buildingId);
    }

    // async cluserMarkerClick(latitude: number, longitude: number): Promise<void> {
    //     await this.emitEventAsync(RealEstateMapInteraction.onClusterMarkerClick, latitude, longitude);
    // }
}