import { Point } from "src/app/core/model/geometry-model/point.model";
import { Segment } from "src/app/core/model/geometry-model/segment.model";
import { SvgTransform } from "src/app/core/model/svg-model/svg-transform.model";

export class MagnetData {
    segment: Segment;
    flownOverItemBboxCenter: Point | null;
    flownOverItemTransform: SvgTransform | null; 
    targetItemBboxCenter: Point;
    detectionPoint: Point;
    offsetPosition: number;

    constructor(
        segment: Segment, 
        flownOverItemBboxCenter: Point | null, 
        flownOverItemTransform: SvgTransform | null,
        targetItemBboxCenterDistance: number, 
        initialBboxCenter: Point,
        detectionPoint: Point,
        offsetPosition: number) 
        {
            this.segment = segment;
            this.flownOverItemBboxCenter = flownOverItemBboxCenter;
            this.flownOverItemTransform = flownOverItemTransform;
            this.detectionPoint = detectionPoint;
            this.offsetPosition = offsetPosition;
            this.targetItemBboxCenter = this.calculateTarget(targetItemBboxCenterDistance, initialBboxCenter);
        }

    calculateTarget(targetItemBboxCenterDistance: number, itemBboxCenter: Point): Point {
        // Il faut trouver le point opposé au SourceItemBboxCenter par rapport au segment
        // Si flownOverItemBboxCenter existe c'est qu'on travaille sur un use
        // sinon, sur un wall
        if (this.flownOverItemBboxCenter != null)
        {
            if (this.segment.isRightHand(this.flownOverItemBboxCenter))
            {
                targetItemBboxCenterDistance = -targetItemBboxCenterDistance;
            }
            return this.segment.getOrthogonalOffset(this.detectionPoint, targetItemBboxCenterDistance);
        }
        else
        {
            if (this.segment.isLeftHand(itemBboxCenter))
            {
                targetItemBboxCenterDistance = -targetItemBboxCenterDistance;
            }
            return this.segment.getOrthogonalOffset(this.detectionPoint, targetItemBboxCenterDistance);
        }
    }

    getOffsetPosition(): Point {
        let offset = this.offsetPosition;
        if (this.segment.isLeftHand(this.targetItemBboxCenter))
        {
            offset = -offset;
        }
        return this.segment.getOrthogonalOffset(this.detectionPoint, offset);
    }
}