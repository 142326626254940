export const SiteActivityStatusDbView = {
    databaseTableName: "site_activity_status_view",
    buSiteId: "Bu_SiteId",
    roActivityStatusTypeId: "Ro_ActivityStatusTypeId",
    roAcTyName: "RoAcTy_Name",
    roAcTyColor: "RoAcTy_Color",
    roAcTyDisplayNameId: "RoAcTy_DisplayNameId",
    roAcTyViDisplayName: "RoAcTyVi_DisplayName",
    stAcStViArea: "StAcStVi_Area",
    stAcStViRoundedArea: "StAcStVi_RoundedArea"
}