import { RealEstateActivityStatusDbView } from "../../db-model/views/real-estate-activity-status-db-view";
import { DefinedModelBase } from "../defined-model-base";

export class RealEstateActivityStatusView extends DefinedModelBase {
    roActivityStatusTypeId!: number;
    roAcTyName!: string;
    roAcTyColor!: string;
    roAcTyDisplayNameId!: number;
    roAcTyViDisplayName!: string;
    reEsAcStViArea!: number;
    reEsAcStViRoundedArea!: number;

    constructor(dtoData: any) {
        super(dtoData, [RealEstateActivityStatusDbView]);
    }
}