import { ZColumnView } from 'src/app/core/model/data-model/views/z-column-view';
import { ZContextUITableUser } from '../tables/z-context-ui-table-user';
import { ZContextUITable } from '../tables/z-context-ui-table';
import { ZSortDirection } from '../tables/z-sort-direction';
import { ZDbViewColumnView } from '../views/z-db-view-column-view';
import { ZContextUITableUserColumn } from '../tables/z-context-ui-table-user-column';

export class ContextUIDynViewSet {
    contextUITable: ZContextUITable;
    contextUITableUser: ZContextUITableUser;
    contextUITableUserColumn: ZContextUITableUserColumn;
    dbColumnView: ZDbViewColumnView;
    dbColumn: ZColumnView;
    sortDirection: ZSortDirection;

    constructor(dtoData: any) {
        this.contextUITable = new ZContextUITable(dtoData);
        this.contextUITableUser = new ZContextUITableUser(dtoData);
        this.contextUITableUserColumn = new ZContextUITableUserColumn(dtoData);
        this.dbColumnView = new ZDbViewColumnView(dtoData);
        this.dbColumn = new ZColumnView(dtoData);
        this.sortDirection = new ZSortDirection(dtoData);
    }
}