import { SvgEntity } from "src/app/core/model/svg-model/svg-entity.model";
import { DxfSvgEntity } from "./dxf-svg-entity";
import { Point } from "src/app/core/model/geometry-model/point.model";
import { Vector } from "src/app/core/model/geometry-model/vector.model";
import { SvgEntityPoint } from "../../../shared/gizmos/model/svg-entity-point";
import { SvgEntityPointStyleEnum } from "../../../shared/gizmos/model/svg-entity-point-style-enum";
import { SvgEntityParser } from "../../../shared/gizmos/model/svg-entity-parser";

export class DxfSvgUse extends DxfSvgEntity {
    href: string | undefined;

    constructor(dtoData: any) {
        super(dtoData);
        this.href = SvgEntity.getValue(dtoData, "href");
    }

    protected override getSelectablePoints(): SvgEntityPoint[] {
        return SvgEntityPoint.toStyle([this.location], SvgEntityPointStyleEnum.insert, this.entityId);
    }

    protected override getStatement(): string {
        const attributesStatement = SvgEntityParser.getAttributesStatement([["href", this.href]], this.transform);
        return SvgEntityParser.getTagStatement("use", attributesStatement);
    }

    get location(): Point {
        if (this.transform) {
            return this.transform.translate;
        }
        return Point.origin();
    }
    set location(value: Point) {
        if (this.transform) {
            this.transform = this.transform.translateUpdate(value);
        }
    }

    translate(delta: Vector): void {
        const cn = this.location.translate(delta);
        this.location = cn;
    }
}