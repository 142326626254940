import { TablesNamesEnum } from './../tables-names-enum';
export const FloorDataTable = {
    databaseTableName: TablesNamesEnum.FloorData,
    flDaId: 'FlDa_Id',
    flDaFloorCatalogId: 'FlDa_FloorCatalogId',
    flDaSvgStatement: 'FlDa_SvgStatement',
    flDaBoundingBox: 'FlDa_BoundingBox',
    flDaFloorModelId: 'FlDa_FloorModelId',
    flDaTaskId: 'FlDa_TaskId',
    flDaDataStateId: 'FlDa_DataStateId',
    flDaParentId: 'FlDa_ParentId',
    flDaSourceId: 'FlDa_SourceId',
    flDaAnchorId: 'FlDa_AnchorId',
}