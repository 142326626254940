import { RealEstateView } from "../../services/backend-services/dto/real-estate-view";
import { BuildingTable } from "../db-model/tables/building-table";

export class RealEstateProcessing {
    static getBuildingItems(items: RealEstateView[]): RealEstateView[] {
        let result: any[] = [];

        items.forEach(e => {
            if (e.realEstateTableName === BuildingTable.databaseTableName) {
                result.push(e);
            } else {
                result = result.concat(this.getBuildingItems(e.children));
            }
        });

        return result;
    }
}