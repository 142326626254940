import { DisplayThemableTable } from './../../db-model/tables/display-themable-table';
import { DefinedModelBase } from '../defined-model-base';

export class DisplayThemable extends DefinedModelBase {
    diTbId!: number;
    diTbName!: string;
    diTbDisplayNameId!: number;
    diTbColor!: string | null;
    diTbSvgPatternId!: number | null;

    constructor(dtoData: any, tableDef: any[] = []) {
        super(dtoData, [DisplayThemableTable].concat(tableDef));
    }
}