import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PerimetersGrantsVM } from '../../model/perimeters-grants-vm';
import { DataViewModule } from 'primeng/dataview';
import { CheckboxModule } from 'primeng/checkbox';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'xc-perimeters-grants',
  standalone: true,
  imports: [CommonModule,
    FormsModule,
    DataViewModule,
    CheckboxModule
  ],
  templateUrl: './perimeters-grants.component.html',
  styleUrls: ['./perimeters-grants.component.scss']
})
export class PerimetersGrantsComponent {
  @Input() model: PerimetersGrantsVM | undefined;
}
