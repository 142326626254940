import { DynTableVM } from "src/app/components-lib/dyn-grid/model/dyn-table-vm";
import { DynTableVMBuilder } from "src/app/components-lib/dyn-grid/model/dyn-table-vm-builder";
import { readableUUID } from "src/app/core/events/event-listener-uuid";
import { AppMenuRouteEnum } from "src/app/core/model/data-model/enums/app-menu-route-enum";
import { TablesNamesEnum } from "src/app/core/model/db-model/tables-names-enum";
import { DyntService } from "src/app/core/services/backend-services/dynt-service";
import { PaginationService } from "src/app/core/services/backend-services/pagination-service";
import { PageModel } from "src/app/ui/main/model/page-model";
import Container from "typedi";

export class LeaseTypes extends PageModel {
    adminTable: DynTableVM | undefined;

    private constructor() {
        super(AppMenuRouteEnum.planning_strategy_referentials_leasetypes, 0, readableUUID(LeaseTypes.name));
    }

    static async newAsync(): Promise<LeaseTypes> {
        const result = new LeaseTypes();
        result.adminTable = await result.loadAdminData(TablesNamesEnum.LeaseInternalType);
        return result;
    }

    async loadAdminData(tableName: string): Promise<DynTableVM> {
        const s = Container.get(DyntService);
        const viewSet = await s.contextedDataSet(tableName);
        const p = Container.get(PaginationService);
        const paginator = await p.getPaginator(tableName);
        const adminTable = DynTableVMBuilder.getVM(viewSet, tableName, paginator);

        // adminTable.saveRequested = async (tableName: string, primaryColumnName: string, rowId: any, columnName: string, value: any): Promise<string | null> => {
        //     const s = Container.get(DyntService);
        //     const dto: any = {
        //         "TableName": tableName,
        //         [primaryColumnName]: rowId,
        //         [columnName]: value
        //     }
        //     return await s.patch(tableName, rowId, dto);
        // }

        // // TODO : cette méthode peut être mutualisée avec toutes les autres écoutes de ce type
        // adminTable.insertionRequested = async (values : {}): Promise<any> => {
        //     const s = Container.get(DyntService);
        //     return await s.post<any>("", values);
        // }

        // // TODO : cette méthode peut être mutualisée avec toutes les autres écoutes de ce type
        // adminTable.deletionRequested = async (tableName: string, rowId: any): Promise<boolean> =>  {
        //     const s = Container.get(DyntService);
        //     const result = await s.delete(tableName, rowId);
        //     if (result) {
        //         return true;
        //     }
        //     return false;
        // }

        return adminTable;
    }
}