import { SimulationTimelinesDTO } from 'src/app/core/services/backend-services/dto/simulation-timelines-dto';
import Container from "typedi";
import { StrategyScheduleVM } from "./strategy-schedule-vm";
import { SimulationService } from "src/app/core/services/backend-services/simulation-service";
import { SvgPhotoMarkerVM } from "./svg-photo-marker-vm";
import { logError } from 'src/app/core/services/logging-service';

export class StrategySchedulingVM {
    // TODO : A mettre en référence partagée sur la vue
    static svgScheduleId = "svgStrategySchedule";

    schedule: StrategyScheduleVM;

    constructor() {
        this.schedule = new StrategyScheduleVM(StrategySchedulingVM.svgScheduleId);
        this.listenToStackingViewRequests();
        this.listenToSimulationLabelUpdate();
    }

    async loadSimulation(simulationId: number): Promise<void> {
        const s = Container.get(SimulationService);
        const result = await s.getTimelines(simulationId);
        if (result !== null) {
            this.schedule.draw(result);
        }
    }

    stackingViewRequested?: (p: SvgPhotoMarkerVM) => void;
    listenToStackingViewRequests(): void {
        this.schedule.stackingViewRequested = (p: SvgPhotoMarkerVM) => {
            if (this.stackingViewRequested) {
                this.stackingViewRequested(p);
            } else {
                logError("StrategySchedulingVM.stackingViewRequested n'est pas écouté");
            }
        }
    }

    simulationLabelUpdated?: (s: SimulationTimelinesDTO) => void;
    listenToSimulationLabelUpdate(): void {
        this.schedule.simulationLabelUpdated = (s: SimulationTimelinesDTO) => {
            if (this.simulationLabelUpdated) {
                this.simulationLabelUpdated(s);
            } else {
                logError("StrategySchedulingVM.simulationLabelUpdated n'est pas écouté");
            }
        }
    }

    mouseMove(e: MouseEvent): void {
        this.schedule.mouseMove(e);
    }
}