import { SimulationCreationData } from './simulation-creation-data';

export class CreationStepOneVM {
    completed: boolean = false;
    creationData: SimulationCreationData;

    constructor(creationData: SimulationCreationData) {
        this.creationData = creationData
    }

    nameChange(): void {
        this.completed = this.creationData.checkStepOneCompleted();
    }

    endDateChange(e: any): void {
        this.creationData.endDate = e.value;
        this.completed = this.creationData.checkStepOneCompleted();
    }

    selectionTypeChange(): void {
        this.creationData.selectedBuildings.splice(0);
        this.creationData.selectedBus.splice(0);
    }
}