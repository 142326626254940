import { Point } from "src/app/core/model/geometry-model/point.model";
import { MagnetData } from "../../../subitems/gizmos/equipment-update-gizmo/model/magnet-data";

export class InteractionCommand {
    static noneCommand = "None";

    static selectingSingleItemCommand = "Sélection unitaire";
    static unSelectingSingleItemCommand = "Déselection unitaire";
    static drawingRectangularSelectionCommand = "Tracé d'un rectangle de sélection";
    
    static equipmentInsertionCommand = "Insertion d'un équipement";
    static freeTranslationCommand = "Déplacement libre d'équipement";
    static gripTranslationCommand = "Déplacement d'équipement par grip";
    static sideConstraintTranslationCommand = "Translation contrainte d'équipement";
    static sideFreeMoveCommand = "Déplacement combiné d'équipement";
    static freeRotationCommand = "Rotation libre d'équipement";
    
    static displayContextMenuCommand = "DisplayContextMenu";
    
    static doorTranslationCommand = "Translation d'une porte";
    static doorOpeningAngleCommand = "Changement de l'angle d'ouverture d'une porte";
    static doorInsertionCommand = "Création d'une porte";
    
    static wallEndpointFreeTranslationCommand = "Déplacement libre d'une extrémité de cloison";
    static wallEndpointConstraintTranslationCommand = "Extension d'une cloison";
    static wallFreeTranslationCommand = "Déplacement d'une cloison";
    static wallSideConstraintTranslationCommand = "Translation contrainte d'une cloison";
    static wallInsertionCommand = "Création d'une cloison";
    static wallWidthUpdateCommand = "wallWidthUpdate";
    
    static workplaceInsertionCommand = "Création d'une position de travail";
    static workplaceTranslateCommand = "Déplacement d'une position de travail";
    static workplaceRenumberCommand = "Renumérotation de positions de travail";

    static peopleLabelTranslateCommand = "Déplacement d'une étiquette d'occupant";

    static roomLabelTranslateCommand = "Déplacement d'une étiquette de local";
    static roomAllocationCopyCommand = "Copie des entités logées";
    static roomInsertionCommand = "Création d'un local";
    static roomInsertValidationCommand = "Validation du contour du local créé";
    static updateTaskRoomExtensionCommand = "Extension de la zone d'étude";

    static measurementStartInsertionCommand = "Création d'une cote - Premier point";
    static measurementEndInsertionCommand = "Création d'une cote - Second point";
    static measurementEndPointTranslationCommand = "Déplacement de l'extrémité d'une cote";

    private name: string = InteractionCommand.noneCommand;
    initialClientMousePosition: Point | undefined;
    initialSvgMousePosition: Point | undefined;
    initialBboxCenter: Point | undefined;
    initialSideMidPoint: Point | undefined;
    tempSvgMousePosition: Point | undefined;
    initialMouseAngle: number = 0;
    handleId: string | undefined;
    magnetAttraction: number = 0;
    magnetData: MagnetData | undefined;
    selectedGripOptions: number[] = [];

    /**
     * Vide la commande en cours (name, tempSvgMousePosition et magnetAttraction)
     */
    clear(): void {
        this.name = InteractionCommand.noneCommand;
        this.tempSvgMousePosition = undefined;
        this.magnetAttraction = 0;
    }

    initializeTransformUpdate(bboxCenter: Point, sideMidPoint: Point): void {
        this.initialBboxCenter = bboxCenter;
        this.initialSideMidPoint = sideMidPoint;
    }

    set(commandName: string): void {
        this.name = commandName;
    }

    isCommand(name: string): boolean {
        return this.name === name;
    }

    isNoneCommand(): boolean {
        return this.name === InteractionCommand.noneCommand;
    }

    isContextMenuCommand(): boolean {
        return this.isCommand(InteractionCommand.displayContextMenuCommand);
    }

    isSelectingSingleItemCommand(): boolean {
        return this.isCommand(InteractionCommand.selectingSingleItemCommand);
    }

    isUnselectingSingleItemCommand(): boolean {
        return this.isCommand(InteractionCommand.unSelectingSingleItemCommand);
    }

    isDrawingRectangularSelectionCommand(): boolean {
        return this.isCommand(InteractionCommand.drawingRectangularSelectionCommand);
    }

    isEquipmentInsertionCommand(): boolean {
        return this.isCommand(InteractionCommand.equipmentInsertionCommand);
    }

    isFreeTranslationCommand(): boolean {
        return this.isCommand(InteractionCommand.freeTranslationCommand);
    }

    isGripTranslationCommand(): boolean {
        return this.isCommand(InteractionCommand.gripTranslationCommand);
    }

    isSideConstraintTranslationCommand(): boolean {
        return this.isCommand(InteractionCommand.sideConstraintTranslationCommand);
    }

    isSideFreeMoveCommand(): boolean {
        return this.isCommand(InteractionCommand.sideFreeMoveCommand);
    }

    isFreeRotationCommand(): boolean {
        return this.isCommand(InteractionCommand.freeRotationCommand);
    }

    isDoorTranslationCommand(): boolean {
        return this.isCommand(InteractionCommand.doorTranslationCommand);
    }

    isDoorOpeningAngleCommand(): boolean {
        return this.isCommand(InteractionCommand.doorOpeningAngleCommand);
    }

    isDoorInsertionCommand(): boolean {
        return this.isCommand(InteractionCommand.doorInsertionCommand);
    }

    isWallEndpointFreeTranslationCommand(): boolean {
        return this.isCommand(InteractionCommand.wallEndpointFreeTranslationCommand);
    }

    isWallEndpointConstraintTranslationCommand(): boolean {
        return this.isCommand(InteractionCommand.wallEndpointConstraintTranslationCommand);
    }

    isWallFreeTranslationCommand(): boolean {
        return this.isCommand(InteractionCommand.wallFreeTranslationCommand);
    }

    isWallSideConstraintTranslationCommand(): boolean {
        return this.isCommand(InteractionCommand.wallSideConstraintTranslationCommand);
    }

    isWallWidthUpdateCommand(): boolean {
        return this.isCommand(InteractionCommand.wallWidthUpdateCommand);
    }

    isWallInsertionCommand(): boolean {
        return this.isCommand(InteractionCommand.wallInsertionCommand);
    }

    isWorkplaceInsertionCommand(): boolean {
        return this.isCommand(InteractionCommand.workplaceInsertionCommand);
    }

    isWorkplaceRenumberCommand(): boolean {
        return this.isCommand(InteractionCommand.workplaceRenumberCommand);
    }

    isWorkplaceTranslationCommand(): boolean {
        return this.isCommand(InteractionCommand.workplaceTranslateCommand);
    }

    isPeopleLabelTranslationCommand(): boolean {
        return this.isCommand(InteractionCommand.peopleLabelTranslateCommand);
    }

    isRommLabelTranslateCommand(): boolean {
        return this.isCommand(InteractionCommand.roomLabelTranslateCommand);
    }

    isRoomAllocationCopyCommand(): boolean {
        return this.isCommand(InteractionCommand.roomAllocationCopyCommand);
    }

    isRoomInsertionCommand(): boolean {
        return this.isCommand(InteractionCommand.roomInsertionCommand);
    }

    isRoomInsertValidationCommand(): boolean {
        return this.isCommand(InteractionCommand.roomInsertValidationCommand);
    }

    isMeasurementStartInsertionCommand(): boolean {
        return this.isCommand(InteractionCommand.measurementStartInsertionCommand);
    }

    isMeasurementEndInsertionCommand(): boolean {
        return this.isCommand(InteractionCommand.measurementEndInsertionCommand);
    }

    isMeasurementEndpointTranslationCommand(): boolean {
        return this.isCommand(InteractionCommand.measurementEndPointTranslationCommand);
    }

    isUpdateTaskRoomExtensionCommand(): boolean {
        return this.isCommand(InteractionCommand.updateTaskRoomExtensionCommand);
    }

    isSelectionCommand(): boolean {
        return this.isDrawingRectangularSelectionCommand() ||
        this.isSelectingSingleItemCommand() ||
        this.isUnselectingSingleItemCommand();
    }

    isWallCommand(): boolean {
        return this.isWallEndpointConstraintTranslationCommand() ||
        this.isWallEndpointFreeTranslationCommand() ||
        this.isWallFreeTranslationCommand() ||
        this.isWallInsertionCommand() ||
        this.isWallSideConstraintTranslationCommand() ||
        this.isWallWidthUpdateCommand();
    }

    isRoomCommand(): boolean {
        return this.isRommLabelTranslateCommand() ||
            this.isRoomAllocationCopyCommand() ||
            this.isRoomInsertionCommand() ||
            this.isRoomInsertValidationCommand() ||
            this.isUpdateTaskRoomExtensionCommand();
    }

    isDoorCommand(): boolean {
        return this.isDoorTranslationCommand() ||
        this.isDoorInsertionCommand() ||
        this.isDoorOpeningAngleCommand();
    }

    isWorkplaceCommand(): boolean {
        return this.isWorkplaceInsertionCommand() ||
        this.isWorkplaceTranslationCommand() ||
        this.isWorkplaceRenumberCommand();
    }

    isEquipmentCommand(): boolean {
        return this.isFreeTranslationCommand() ||
        this.isGripTranslationCommand() ||
        this.isSideConstraintTranslationCommand() ||
        this.isSideFreeMoveCommand() ||
        this.isFreeRotationCommand() ||
        this.isEquipmentInsertionCommand();
    }

    isMeasurementCommand(): boolean {
        return this.isMeasurementStartInsertionCommand() ||
        this.isMeasurementEndInsertionCommand() ||
        this.isMeasurementEndpointTranslationCommand();
    }
}