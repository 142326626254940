import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from 'src/app/core/material.module';

@Component({
  standalone: true,
  imports: [CommonModule, MaterialModule],
  selector: 'xc-chat-form',
  templateUrl: './chat-form.component.html',
  styleUrls: ['./chat-form.component.scss']
})
export class ChatFormComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
