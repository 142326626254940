import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UpdateGizmos } from '../../../../model/gizmos/update/update-gizmos';
import { CircleGizmoComponent } from '../circle-gizmo/circle-gizmo.component';
import { EllipseGizmoComponent } from '../ellipse-gizmo/ellipse-gizmo.component';
import { LineGizmoComponent } from '../line-gizmo/line-gizmo.component';
import { PathGizmoComponent } from '../path-gizmo/path-gizmo.component';
import { TextGizmoComponent } from '../text-gizmo/text-gizmo.component';
import { UseGizmoComponent } from '../use-gizmo/use-gizmo.component';

@Component({
  selector: 'g[xc-update-gizmos]',
  standalone: true,
  imports: [CommonModule, LineGizmoComponent, CircleGizmoComponent, PathGizmoComponent, EllipseGizmoComponent, TextGizmoComponent, UseGizmoComponent],

  templateUrl: './update-gizmos.component.svg',
  styleUrls: ['./update-gizmos.component.scss']
})
export class UpdateGizmosComponent {
  @Input() model: UpdateGizmos | undefined;
}
