<h3>Allocations du local</h3>
<div class="flex-container">
  <div class="flex-child bu-ref">
    <mat-tree
      [dataSource]="dataSource"
      [treeControl]="treeControl"
      class="example-tree"
    >
      <!-- This is the tree node template for leaf nodes -->
      <mat-tree-node
        *matTreeNodeDef="let node"
        matTreeNodePadding
        [ngClass]="{ 'last-bu-child': hasChildren }"
      >
        <!-- use a disabled button to provide padding for tree leaf -->
        <button mat-icon-button disabled></button>
        <div class="color-pin" [style.background-color]="node.color"></div>
        <span class="last-bu-child-label">{{ node.name }}</span>
        <button
          [disabled]="totalRate() === 100 || contains(node)"
          class="add-bu-button"
          mat-icon-button
          aria-label="Ajouter à la liste"
          (click)="addBuClick($event, node)"
        >
          <mat-icon class="material-icons-outlined">forward</mat-icon>
        </button>
      </mat-tree-node>
      <!-- This is the tree node template for expandable nodes -->
      <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChildren">
        <div class="mat-tree-node">
          <button
            mat-icon-button
            matTreeNodeToggle
            [attr.aria-label]="'Toggle ' + node.name"
          >
            <mat-icon class="mat-icon-rtl-mirror">
              {{
                treeControl.isExpanded(node) ? "expand_more" : "chevron_right"
              }}
            </mat-icon>
          </button>
          {{ node.name }}
        </div>
        <!-- There is inline padding applied to this div using styles.
                    This padding value depends on the mat-icon-button width.  -->
        <div
          [class.example-tree-invisible]="!treeControl.isExpanded(node)"
          role="group"
        >
          <ng-container matTreeNodeOutlet></ng-container>
        </div>
      </mat-nested-tree-node>
    </mat-tree>
  </div>

  <div class="flex-child">
    <ul id="allocations-list">
      @for (item of displayAllocations; track item) {
      <li class="allocation-item">
        <div class="allocation-item-container">
          <div class="color-pin" [style.background-color]="item.color"></div>
          <span>{{ item.name }}</span>
          <!-- <mat-form-field> -->
          <input
            class="allocation-rate"
            matInput
            type="number"
            step="0.01"
            min="0.01"
            max="100"
            [(ngModel)]="item.ratio"
            (ngModelChange)="rateChange($event, item)"
          />
          <mat-icon matSuffix>percent</mat-icon>
          <!-- </mat-form-field> -->
          <button
            class="delete-bu-button"
            mat-icon-button
            aria-label="Supprimer l'entité de la liste"
            (click)="deleteBuClick($event, item)"
            color="warn"
          >
            <mat-icon class="material-icons-outlined delete-button-icon"
              >delete</mat-icon
            >
          </button>
        </div>
      </li>
      }
    </ul>
  </div>
</div>
<div id="allocations-commands">
  <button
    class="allocation-command"
    mat-button
    color="primary"
    [disabled]="!canSave"
    (click)="save()"
  >
    Ok
  </button>
  <button mat-button color="warn" (click)="cancel()">Annuler</button>
</div>
