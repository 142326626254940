export enum DisplayThemableEnum {
    LayoutType = 1,
    Allocated = 2,
    MultiAllocated = 3,
    NotAllocated = 4,
    Shared = 5,
    Exploitation = 6,
    Used = 7,
    UnderConstruction = 8,
    Empty = 9,
    None,
    StandingBy,
    Identified,
    Unchanged,
    ProposedAdded,
    ProposedDeleted
  }
  