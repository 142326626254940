import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CreationStepTwoVM } from '../../model/creation-step-two-vm';
import { SelectableRealEstateTreeComponent } from '../../selectable-real-estate-tree/view/selectable-real-estate-tree/selectable-real-estate-tree.component';

@Component({
  selector: 'xc-creation-step-two',
  standalone: true,
  imports: [CommonModule, SelectableRealEstateTreeComponent],
  templateUrl: './creation-step-two.component.html',
  styleUrls: ['./creation-step-two.component.scss']
})
export class CreationStepTwoComponent {
  @Input() model: CreationStepTwoVM | undefined;
}
