import { RoomAllocationTable } from './../tables/room-allocation-table';
import { RoomDbView } from 'src/app/core/model/db-model/views/room-db-view';

export const BusinessUnitDbView = {
    databaseTableName: "business_unit_view",
    roViIsCurrentState: RoomDbView.roViIsCurrentState,
    buUnViBuTree: "BuUnVi_BuTree",
    roAlArea: RoomAllocationTable.roAlArea,
    buUnViRoundedArea: "BuUnVi_RoundedArea",
    buUnViOccupancy: "BuUnVi_Occupancy"
}