import { InteractionCommand } from "../interaction-command";
import { SvgEntityTypesEnum } from "src/app/core/model/svg-model/svg-entity-type-enum";
import { InsertInteraction } from "./insert-interaction";
import { PathInsertGizmo } from "../../../gizmo/insert/model/path-insert-gizmo";
import { SelectableSvgPath } from "../../../svg/selectable-svg-path";
import { logError } from "src/app/core/services/logging-service";

export class PathInsertInteraction extends InsertInteraction<PathInsertGizmo> {
    constructor(command: InteractionCommand) {
        super(command, new PathInsertGizmo(), SvgEntityTypesEnum.path);
        this.gizmo.insertionCompleted = () => {
            if (this.insertionCompleted) {
                this.insertionCompleted(SelectableSvgPath.fromValues(this.gizmo.getGlobalStatement(), 0.001));
            } else {
                logError("PathInsertGizmo.insertionCompleted n'est pas écouté");
            }
        }
    }

    override async keyDown(e: KeyboardEvent): Promise<void> {
        switch (e.key.toLowerCase()) {
            case "a":
                this.gizmo.setCurrentType(SvgEntityTypesEnum.path);
                break;
            case "t":
                this.gizmo.drawTangent = !this.gizmo.drawTangent;
                break;
            case "l":
                this.gizmo.setCurrentType(SvgEntityTypesEnum.line);
                break;
            case "c":
                this.gizmo.close();
                break;
            case " ":
            case "enter":
                this.gizmo.end();
                break;
            default:
                break;
        }
    }
}