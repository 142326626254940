import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LineInsertGizmo } from '../../model/line-insert-gizmo';

@Component({
  selector: 'g[xc-picto-line-insert-gizmo]',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './line-insert-gizmo.component.svg',
  styleUrls: ['./line-insert-gizmo.component.scss']
})
export class LineInsertGizmoComponent {
  @Input() model: LineInsertGizmo | undefined;
}
