import { EquipmentPurchaseTable } from "../../db-model/tables/equipment-purchase-table";
import { DefinedModelBase } from "../defined-model-base";

export class EquipmentPurchase extends DefinedModelBase {
    eqPuId!: number;
    eqPuPurchaseDate!: Date;
    eqPuPurchaseRef!: string;
    eqPuTargetDate!: Date;
    eqPuDeliveryDate!: Date | null;
    eqPuTaskId!: number;

    constructor(dtoData: any, tableDef: any[] = []) {
        super(dtoData, [EquipmentPurchaseTable].concat(tableDef));
    }
}