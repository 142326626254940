@if (model) {
    <!-- <xc-resizable-left-panel [content]="sidePanelContent" [contentModel]="model.sidePanelVM.content" [showGutter]="true"
    [tabs]="model.sidePanelVM.tabs" [selectedTabIndex]="model.sidePanelVM.tabs.selectedTab?.index" /> -->

    <p-splitter [panelSizes]="[20, 80]" [minSizes]="[20, 60]" class="w-full h-full" styleClass="h-full border-none">
        <ng-template pTemplate>
            <div class="w-full">
                <xc-mobility-projects-gantt-browser class="side-panel-content-scrollable" [model]="model.projectsGanttBrowser" (scroll)="model.onScroll($event)"/>
            </div>
        </ng-template>
        <ng-template pTemplate>
            <div class="overflow-x-auto">
                <xc-mobility-projects-schedule class="flexContent" [model]="model.projectsSchedule" />
            </div>
        </ng-template>
    </p-splitter>
<!--     <div id="relativeContentPanel">
        @switch (model.selectedPage) {
            @case (model.mobilityBrowserPageEnum.contributorsWorkload) {
                <xc-mobility-contributors-workload-dashboard class="svg" [model]="model.workloadDashboard" />
            }
            @case (model.mobilityBrowserPageEnum.ganttProjectsArchive) {
                <xc-mobility-projects-archive-viewer class="svg" [model]="model.archiveViewer" />
            }
            @case (model.mobilityBrowserPageEnum.ganttProjects) {
                <xc-mobility-projects-schedule class="flexContent" [model]="model.projectsSchedule" />
            }
        }
    </div> -->
}
