import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomPerimetersManageVM } from '../../model/custom-perimeters-manage-vm';
import { MVVMEventDestroyLifeCycle } from 'src/app/components-lib/shared-model/mvvm-events-destroy-lifecycle';
import { TabViewModule } from 'primeng/tabview';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { PickListModule } from 'primeng/picklist';
import { ListboxModule } from 'primeng/listbox';
import { FloatLabelModule } from 'primeng/floatlabel';

@Component({
  selector: 'xc-custom-perimeters-manage',
  standalone: true,
  imports: [CommonModule, 
    FormsModule, 
    TabViewModule, 
    DropdownModule, 
    InputTextModule, 
    ButtonModule, 
    CheckboxModule,
    PickListModule,
    ListboxModule,
    FloatLabelModule
  ],
  templateUrl: './custom-perimeters-manage.component.html',
  styleUrls: ['./custom-perimeters-manage.component.scss']
})
export class CustomPerimetersManageComponent extends MVVMEventDestroyLifeCycle {
  @Input() override model: CustomPerimetersManageVM | undefined;
}
