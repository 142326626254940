@if (model) {

<div id="red-main" class="red-panel">
  <div id="viewOptions">
    @if (model.selectedTreeNode) {
    <mat-tab-group (selectedTabChange)="model.selectedTabChange($event)" fitInkBarToContent mat-stretch-tabs="false"
      mat-align-tabs="center">
      @for (c of model.tabs; track c) {
      <mat-tab [label]="c.name">
        <ng-template matTabContent>
          <div id="viewContent">
            @switch (c.id) {
            @case (model.inventoryTabTypeEnum.inventorystate) {
            <xc-inventory-state class="extended" [model]="$any(c.content)" />
            }
            @case (model.inventoryTabTypeEnum.map) {
            <xc-inventory-map class="extended" [model]="$any(c.content)" />
            }
            @case (model.inventoryTabTypeEnum.referential) {
            <xc-inventory-referential class="extended" [model]="$any(c.content)" />
            }
            @case (model.inventoryTabTypeEnum.documents) {
            <xc-inventory-documents class="extended" [model]="$any(c.content)" />
            }
            }
          </div>
        </ng-template>
      </mat-tab>
      }
    </mat-tab-group>
    } @else {

    <xc-inventory-default-page class="extended column" />

    }
  </div>
</div>

}