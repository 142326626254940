import { MatTooltipModule } from '@angular/material/tooltip';
import { FormsModule } from '@angular/forms';
import { EquipmentFormVM } from './../../model/equipment-form-vm';
import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from 'src/app/core/material.module';

@Component({
  standalone: true,
  imports: [CommonModule, MaterialModule, FormsModule],
  selector: 'xc-equipment-properties-form',
  templateUrl: './equipment-properties-form.component.html',
  styleUrls: ['./equipment-properties-form.component.scss']
})
export class EquipmentPropertiesFormComponent implements OnInit {
  @Input() model: EquipmentFormVM | undefined;

  constructor() { }

  ngOnInit(): void {
  }

}
