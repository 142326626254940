import { RoomLayoutType } from 'src/app/core/model/data-model/tables/room-layout-type';
import { RoomAllocationViewSet } from 'src/app/core/model/data-model/view-set/room-allocation-view-set';
import { ArrayUtils } from 'src/app/core/model/static-functions/array-utils';
import { BusinessUnitView } from 'src/app/core/model/data-model/views/business-unit-view';
import { XcMaths } from 'src/app/core/model/static-functions/xc-maths';
import { BusinessUnitTable } from 'src/app/core/model/db-model/tables/business-unit-table';
import { RoomAllocationTable } from 'src/app/core/model/db-model/tables/room-allocation-table';
import { RoomLayoutTypeTable } from 'src/app/core/model/db-model/tables/room-layout-type-table';
import { RoomTable } from 'src/app/core/model/db-model/tables/room-table';
import { DbModelUtils } from 'src/app/core/model/static-functions/db-model-utils';
import { BusinessUnitTreeDataDTO } from 'src/app/core/services/backend-services/dto/business-unit-tree-data-dto';
import { BusinessUnit } from 'src/app/core/model/data-model/tables/business-unit';
import { FloorTable } from 'src/app/core/model/db-model/tables/floor-table';
import { BuildingTable } from 'src/app/core/model/db-model/tables/building-table';
import { WorkplaceViewSet } from 'src/app/core/model/data-model/view-set/workplace-view-set';
import { WorkplaceTable } from 'src/app/core/model/db-model/tables/workplace-table';
import { WorkplaceTypeTable } from 'src/app/core/model/db-model/tables/workplace-type-table';
import { DonutChartDataVM } from 'src/app/ui/shared/charts/donut/model/donut-chart-data-vm';
import { DonutChartSliceBuilder } from 'src/app/ui/shared/charts/donut/model/donut-chart-slice-builder';
import { DonutChartSliceData } from 'src/app/ui/shared/charts/donut/model/donut-chart-slice-data';
import { ColorUtils } from 'src/app/ui/shared/shared-model/color-utils';

export class BusinessUnitChartDataBuilder {
    static loadAnatomyChartData(dto: BusinessUnitTreeDataDTO, buId: number | null, buName: string, buDepth: number): DonutChartDataVM {
        // if (buId === 0) {
        //     // Cas du root node
        //     buId = null;
        // }
        if (buId == null) {
            buId = 0;
        }

        const bus = dto.businessUnitView;
        const maxDepth = bus.reduce((prev, current) => (prev.buUnDepth > current.buUnDepth) ? prev : current).buUnDepth;

        // Si on est sur le root node, le graph affiche l'anatomie du parc tout entier
        // Si on est sur un item intermédiaire, le graph affiche l'anatomie des dernier enfants de la branche
        // Si on est sur un item de dernier niveau, le graph affiche son anatomie

        // Récupère les items de dernier niveau
        let lastItems: BusinessUnitView[] = BusinessUnitChartDataBuilder.getLastItems(bus, buId, buDepth, maxDepth);

        // Isole les allocations des entités de dernier niveau sélectionnées
        const allocs = ArrayUtils.join<BusinessUnitView, RoomAllocationViewSet, number>(lastItems, dto.roomAllocations, 
            DbModelUtils.key(BusinessUnitTable, BusinessUnitTable.buUnId));
        // Isole les layout types des allocations
        const layouts = ArrayUtils.join<RoomAllocationViewSet, RoomLayoutType, number>(allocs, dto.roomLayoutTypes, 
            DbModelUtils.key(RoomTable, RoomTable.roLayoutTypeId), 
            DbModelUtils.key(RoomLayoutTypeTable, RoomLayoutTypeTable.roLaTyId));
        const sumTuples = ArrayUtils.aggegate(allocs, layouts, 
            DbModelUtils.key(RoomLayoutTypeTable, RoomLayoutTypeTable.roLaTyId), 
            DbModelUtils.key(RoomTable, RoomTable.roLayoutTypeId), 
            DbModelUtils.key(RoomAllocationTable, RoomAllocationTable.roAlArea));

        const totalArea = XcMaths.round(allocs.reduce((sum, el) => sum += el.dataSet.roAlArea, 0), 0);
        
        const result: DonutChartSliceData[] = [];
        let step = 0;
        sumTuples.forEach(l => {
            const ratio = XcMaths.round(XcMaths.round(l[2], 0) * 100 / totalArea, 2);
            const roundedValue = XcMaths.round(l[2], 0);
            result.push(new DonutChartSliceData(l[1].roLaTyId, 
                l[1].roLaTyName, 
                roundedValue, 
                l[1].roLaTyColor, 
                DonutChartSliceBuilder.getDonutSlicePath(ratio, 1, 0.65), 
                step, 
                ratio,
                l[1].roLaTyName,
                `${roundedValue.toLocaleString()} m² - ${ratio}%`));
            step += ratio;
        });

        return new DonutChartDataVM("Anatomie", "Implantation", result, totalArea);
    }

    static loadStructureChartData(dto: BusinessUnitTreeDataDTO, parent: BusinessUnit, children: BusinessUnit[]): DonutChartDataVM {
        // Le graph affiche la répartition des sous entités
        const bus = dto.businessUnitView;
        const maxDepth = bus.reduce((prev, current) => (prev.buUnDepth > current.buUnDepth) ? prev : current).buUnDepth;
            
        // Récupère les items de dernier niveau
        let lastItems: BusinessUnitView[] = BusinessUnitChartDataBuilder.getLastItems(bus, parent.buUnId, parent.buUnDepth, maxDepth);

        // Isole les allocations des entités de dernier niveau sélectionnées
        const allAllocs = ArrayUtils.join<BusinessUnitView, RoomAllocationViewSet, number>(lastItems, dto.roomAllocations, 
            DbModelUtils.key(BusinessUnitTable, BusinessUnitTable.buUnId));

        const totalArea = XcMaths.round(allAllocs.reduce((sum, el) => sum += el.dataSet.roAlArea, 0), 0);
        const result: DonutChartSliceData[] = [];
        let step = 0;
        children.forEach(bu => {
            const lastItemsIds = lastItems.filter(x=> {
                const treeSplit = x.buUnViBuTree.split(';');
                return treeSplit.includes(`${bu.buUnId}`);
            }).map(x=> x.buUnId);

            const allocs = allAllocs.filter(x=> lastItemsIds.includes(x.dataSet.buUnId));
            const area = XcMaths.round(allocs.reduce((sum, el) => sum += el.dataSet.roAlArea, 0), 0);

            const ratio = XcMaths.round(area * 100 / totalArea, 2);
            const roundedValue = XcMaths.round(area, 0);
            result.push(new DonutChartSliceData(bu.buUnId, 
                bu.buUnName, 
                roundedValue, 
                bu.buUnColor, 
                DonutChartSliceBuilder.getDonutSlicePath(ratio, 1, 0.65), 
                step, 
                ratio,
                bu.buUnName,
                `${roundedValue.toLocaleString()} m² - ${ratio}%`));
            step += ratio;
        });

        return new DonutChartDataVM("Structure", "Sous-entités", result, totalArea);
    }

    static loadMapChartData(dto: BusinessUnitTreeDataDTO, parent: BusinessUnit): DonutChartDataVM {
        // Le graph affiche la répartition de l'entité sélectionnée sur les immeubles du parc
        const result: DonutChartSliceData[] = [];
        
        const bus = dto.businessUnitView;
        const maxDepth = bus.reduce((prev, current) => (prev.buUnDepth > current.buUnDepth) ? prev : current).buUnDepth;
            
        // Récupère les items de dernier niveau
        let lastItems: BusinessUnitView[] = BusinessUnitChartDataBuilder.getLastItems(bus, parent.buUnId, parent.buUnDepth, maxDepth);

        // Isole les allocations des entités de dernier niveau sélectionnées
        const allAllocs = ArrayUtils.join<BusinessUnitView, RoomAllocationViewSet, number>(lastItems, dto.roomAllocations, 
            DbModelUtils.key(BusinessUnitTable, BusinessUnitTable.buUnId));

        // Isole les immeubles
        const buildingIds = ArrayUtils.DistinctValueBy<RoomAllocationViewSet, number>(allAllocs, DbModelUtils.key(FloorTable, FloorTable.flBuildingId));
        const buildings = dto.buildings.filter(x=> buildingIds.includes(x.buId));

        // const buildings = ArrayUtils.join<RoomAllocationViewSet, Building, number>(allAllocs, dto.buildings, 
        //     DbModelUtils.key(RoomDbView, FloorTable.flBuildingId), 
        //     DbModelUtils.key(BuildingTable, BuildingTable.buId));
        
        const sumTuples = ArrayUtils.aggegate(allAllocs, buildings, 
            DbModelUtils.key(BuildingTable, BuildingTable.buId), 
            DbModelUtils.key(FloorTable, FloorTable.flBuildingId), 
            DbModelUtils.key(RoomAllocationTable, RoomAllocationTable.roAlArea)).sort((a, b) => a[2] - b[2]);
    
        const totalArea = XcMaths.round(allAllocs.reduce((sum, el) => sum += el.dataSet.roAlArea, 0), 0);

        const areas = sumTuples.map(x=> Math.round(x[2]));
        const min = Math.min(...areas);
        const max = Math.max(...areas);
        const colorRange = ColorUtils.getRainbowRange(min, max);

        let step = 0;
        sumTuples.forEach(l => {
            const ratio = XcMaths.round(XcMaths.round(l[2], 0) * 100 / totalArea, 2);
            const roundedValue = XcMaths.round(l[2], 0);
            result.push(new DonutChartSliceData(l[1].buId, 
                l[1].buName, 
                roundedValue, 
                colorRange.find(x=> x.value === Math.round(l[2]))?.color?? "lightgrey", 
                DonutChartSliceBuilder.getDonutSlicePath(ratio, 1, 0.65), 
                step, 
                ratio,
                l[1].buName,
                `${roundedValue.toLocaleString()} m² - ${ratio}%`));
            step += ratio;
        });

        return new DonutChartDataVM("Cartographie", "Immeubles", result, totalArea);
    }

    static loadWorkplacesChartData(dto: BusinessUnitTreeDataDTO, parent: BusinessUnit): DonutChartDataVM {
        // Le graph affiche la répartition de l'entité sélectionnée sur les immeubles du parc
        const result: DonutChartSliceData[] = [];
        
        const bus = dto.businessUnitView;
        const maxDepth = bus.reduce((prev, current) => (prev.buUnDepth > current.buUnDepth) ? prev : current).buUnDepth;
            
        // Récupère les items de dernier niveau
        let lastItems: BusinessUnitView[] = BusinessUnitChartDataBuilder.getLastItems(bus, parent.buUnId, parent.buUnDepth, maxDepth);

        // Isole les allocations des entités de dernier niveau sélectionnées
        const allAllocs = ArrayUtils.join<BusinessUnitView, RoomAllocationViewSet, number>(lastItems, dto.roomAllocations, 
            DbModelUtils.key(BusinessUnitTable, BusinessUnitTable.buUnId));
        // Isole les positions de travail
        const wpls = ArrayUtils.join<RoomAllocationViewSet, WorkplaceViewSet, number>(allAllocs, dto.workplaceView, 
            DbModelUtils.key(RoomTable, RoomTable.roFloorDataId));

        const countTuples = ArrayUtils.aggegate(wpls, dto.workplaceTypeView, 
            DbModelUtils.key(WorkplaceTypeTable, WorkplaceTypeTable.woTyId), 
            DbModelUtils.key(WorkplaceTable, WorkplaceTable.woWorkplaceType));
            
        let step = 0;
        countTuples.forEach(wc => {
            const ratio = XcMaths.round(wc[2] * 100 / wpls.length, 2);
            const roundedValue = XcMaths.round(wc[2], 0);
            result.push(new DonutChartSliceData(wc[1].woTyId, 
                wc[1].woTyDisplayName, 
                roundedValue, 
                wc[1].woTyColor, 
                DonutChartSliceBuilder.getDonutSlicePath(ratio, 1, 0.65), 
                step, 
                ratio,
                wc[1].woTyDisplayName,
                `${roundedValue.toLocaleString()} - ${ratio}%`));
            step += ratio;
        });
    
        return new DonutChartDataVM("Occupation", "Positions de travail", result, wpls.length);
    }

    static getLastItems(bus: BusinessUnitView[], buId: number, buDepth: number, maxDepth: number): BusinessUnitView[] {
        let result: BusinessUnitView[] = [];
        if (buId === 0) {
            result = bus.filter(x=> x.buUnDepth === maxDepth);
        } else {
            if (buDepth === maxDepth) {
                const single = bus.find(x=> x.buUnId === buId);
                if (single) {
                    result.push(single);
                }
            } else {
                result = bus.filter(x=> {
                    const treeSplit = x.buUnViBuTree.split(';');
                    return treeSplit.includes(`${buId}`);
                });
            }
        }
        return result;
    }
}