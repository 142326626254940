import { FacilityCompanyTaskTable } from "../../db-model/tables/facility-company-task-table";
import { DefinedModelBase } from "../defined-model-base";

export class FacilityCompanyTask extends DefinedModelBase {
    faCoTaId!: number;
    faCoTaCompany!: number;
    faCoTaJobTask!: number;
    faCoTaUnitPrice!: number;
    faCoTaIsActive!: boolean;

    constructor(dtoData: any, tableDef: any[] = []) {
        super(dtoData, [FacilityCompanyTaskTable].concat(tableDef));
    }
}