@if (model) {
    <p-splitter class="splitter-container" [style]="{ height: '100%' }" layout="vertical">
        <ng-template pTemplate>
            <div class="splitter-panel">
                <xc-dyn-table [model]="model.mainTable" />
            </div>
        </ng-template>
        <ng-template pTemplate>
            <div class="splitter-panel">
                @if (model.manage) {
                    <xc-purchases-manage [model]="model.manage"/>
                } @else {
                    <p class="empty-notif">Selectionnez une commande pour afficher le détail des produits ou procéder à la réception</p>
                }
            </div>
        </ng-template>
    </p-splitter>
}
