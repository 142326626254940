@if (model) {
    <div class="flex flex-wrap">
        <div class="flex flex-wrap">
            @if (model.themingModel) {
                <p-dropdown [options]="model.themingModel.availableThemes()" 
                    [(ngModel)]="model.themingModel.selectedThemeId"
                    (onChange)="model.themingModel.onThemeMenuItemClick($event)"
                    placeholder="Choisissez un thème" 
                    [group]="true">

                    <ng-template pTemplate="dropdownicon">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" [style.height.px]="20">
                            <path d="M480-80q-82 0-155-31.5t-127.5-86Q143-252 111.5-325T80-480q0-83 32.5-156t88-127Q256-817 330-848.5T488-880q80 0 151 27.5t124.5 76q53.5 48.5 85 115T880-518q0 115-70 176.5T640-280h-74q-9 0-12.5 5t-3.5 11q0 12 15 34.5t15 51.5q0 50-27.5 74T480-80Zm0-400Zm-220 40q26 0 43-17t17-43q0-26-17-43t-43-17q-26 0-43 17t-17 43q0 26 17 43t43 17Zm120-160q26 0 43-17t17-43q0-26-17-43t-43-17q-26 0-43 17t-17 43q0 26 17 43t43 17Zm200 0q26 0 43-17t17-43q0-26-17-43t-43-17q-26 0-43 17t-17 43q0 26 17 43t43 17Zm120 160q26 0 43-17t17-43q0-26-17-43t-43-17q-26 0-43 17t-17 43q0 26 17 43t43 17ZM480-160q9 0 14.5-5t5.5-13q0-14-15-33t-15-57q0-42 29-67t71-25h70q66 0 113-38.5T800-518q0-121-92.5-201.5T488-800q-136 0-232 93t-96 227q0 133 93.5 226.5T480-160Z"
                            fill="#265c7f"/>
                        </svg>                   
                    </ng-template>
                
                    <ng-template let-group pTemplate="group">
                        <div class="flex align-items-center" [style]="{'padding-left':'0.25rem'}">
                            <span>{{ group.label }}</span>
                        </div>
                    </ng-template>
                </p-dropdown>
            }

            <p-dropdown [options]="model.floorTasks" 
                class="ml-2"
                [placeholder]="model.floorTasks.length === 0 ? 'Aucune étude active' : 'Etudes...'" 
                [disabled]="model.floorTasks.length === 0"
                [(ngModel)]="model.selectedTask"
                (onChange)="model.onTaskMenuItemClick($event)">
                <ng-template pTemplate="dropdownicon">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" [style.height.px]="20">
                        <path d="m270-120-10-88 114-314q15 14 32.5 23.5T444-484L334-182l-64 62Zm420 0-64-62-110-302q20-5 37.5-14.5T586-522l114 314-10 88ZM480-520q-50 0-85-35t-35-85q0-39 22.5-69.5T440-752v-88h80v88q35 12 57.5 42.5T600-640q0 50-35 85t-85 35Zm0-80q17 0 28.5-11.5T520-640q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640q0 17 11.5 28.5T480-600Z"
                        fill="#265c7f"/>
                    </svg>                    
                </ng-template>
                <ng-template let-task pTemplate="selectedItem">
                    <div class="flex align-items-center gap-2" >
                        <div class="flex align-items-center">
                            <div class="rect-shape" [style.background-color]="task.taskStatusColor"></div>
                            <span class="ml-1">{{ task.taskName }}</span>
                        </div>
                    </div>
                </ng-template>
                <ng-template let-task pTemplate="item">
                    <div class="flex align-items-center gap-2">
                        <div>
                            <h4 class="tasks-menu-header">{{ task.taskName }}</h4>
                            @if (task.projectName) {
                                <h5 class="tasks-menu-subheader">{{ task.projectName }}</h5>
                            } 
                            @if (task.taskDeadLine) {
                                <h5 class="tasks-menu-subheader">Echéance : {{ task.taskDeadLine | date : "dd/MM/yy" }}</h5>
                            }
                            <div class="tasks-status-row">
                                <div class="rect-shape" [style.background-color]="task.taskStatusColor" ></div>
                                <span class="tasks-status-name">{{ task.taskStatusName }}</span>
                            </div>
                            </div>
                    </div>
                </ng-template>
            </p-dropdown>
            @if (model.selectedTask) {
                <div class="button-wrapper">
                    <p-button [text]="true" pTooltip="Retirer l\'étude" (onClick)="model.onRemoveTaskButtonClick()">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" [style.height.px]="20">
                            <path d="m270-120-10-88 114-314q15 14 32.5 23.5T444-484L334-182l-64 62Zm420 0-64-62-110-302q20-5 37.5-14.5T586-522l114 314-10 88ZM480-520q-50 0-85-35t-35-85q0-39 22.5-69.5T440-752v-88h80v88q35 12 57.5 42.5T600-640q0 50-35 85t-85 35Zm0-80q17 0 28.5-11.5T520-640q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640q0 17 11.5 28.5T480-600Z"
                            fill="#265c7f"/>
                            <line x1="100" y1="-860" x2="860" y2="-100" stroke="red" vector-effect="non-scaling-stroke"></line>
                        </svg>                    
                    </p-button>
                </div>
            }

            <p-dropdown [options]="model.wallStyles" 
                class="ml-2"
                [placeholder]="'cloisons...'" 
                [disabled]="model.wallStyles.length === 0 || !model.wallStylesEnable"
                [(ngModel)]="model.selectedWallStyle">
                <ng-template pTemplate="dropdownicon">
                    <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960">
                        <path d="M80-80v-800h80v800H80Zm340-200v-400h120v400H420ZM800-80v-800h80v800h-80Z" fill="#265c7f" transform="rotate(15 480 -480)"/>
                    </svg>                
                </ng-template>
                <ng-template let-ws pTemplate="selectedItem">
                    <div class="flex align-items-center gap-2" >
                        <div class="rect-shape" [style.background-color]="ws.flWaStColor" ></div>
                        <span>{{ ws.flWaStName }}</span>
                    </div>
                </ng-template>
                <ng-template let-ws pTemplate="item">
                    <div class="flex align-items-center gap-2">
                        <div class="rect-shape" [style.background-color]="ws.flWaStColor" ></div>
                        <span>{{ ws.flWaStName }}</span>
                    </div>
                </ng-template>
            </p-dropdown>
            @if (model.selectedWallStyle) {
                <div class="button-wrapper">
                    <p-button icon="pi pi-plus" 
                        [text]="true" 
                        pTooltip="Insérer dans l\'étude" 
                        [disabled]="model.wallStyles.length === 0 || !model.wallStylesEnable"
                        (onClick)="model.onWallInsertButtonClick()">
                    </p-button>
                </div>
            }

            <p-dropdown [options]="model.doorStyles" 
                class="ml-2"
                [placeholder]="'portes...'" 
                [disabled]="model.doorStyles.length === 0 || !model.doorStylesEnable"
                [(ngModel)]="model.selectedDoorStyle">
                <ng-template let-ds pTemplate="selectedItem">
                    <div class="flex align-items-center gap-2" >
                        <svg height="30px" viewBox="-0.8 -1.15 1.6 2.2" class="fixed">
                            <line x1="-0.8" y1="0" x2="0.8" y2="0" stroke="blue" stroke-width="0.07"></line>
                            @if (ds.shape) {
                                <g xc-bp-svg-group [entities]="ds.shape.entities" 
                                    stroke-width="0.01" 
                                    stroke="black" 
                                    fill="none"></g>
                            }
                        </svg>
                        <span class="door-style-name">{{ds.name}}</span>
                    </div>
                </ng-template>
                <ng-template let-ds pTemplate="item">
                    <div class="flex align-items-center gap-2">
                        <svg height="50px" viewBox="-0.8 -1.15 1.6 2.2">
                            <line x1="-0.8" y1="0" x2="0.8" y2="0" stroke="blue" stroke-width="0.07"></line>
                            @if (ds.shape) {
                                <g xc-bp-svg-group [entities]="ds.shape.entities" 
                                    stroke-width="0.01" 
                                    stroke="black" 
                                    fill="none"></g>
                            }
                        </svg>
                        <span class="door-style-name">{{ds.name}}</span>
                    </div>
                </ng-template>
            </p-dropdown>
            @if (model.selectedDoorStyle) {
                <div class="button-wrapper">
                    <p-button icon="pi pi-plus" 
                        [text]="true" 
                        pTooltip="Insérer dans l\'étude"
                        [disabled]="model.doorStyles.length === 0 || !model.doorStylesEnable" 
                        (onClick)="model.onDoorInsertButtonClick()">
                    </p-button>
                </div>
            }

            <p-splitButton class="ml-2" 
                [style]="{'border-radius': '2px'}"
                severity="secondary"
                label="svg" 
                icon="pi pi-download" 
                (onClick)="model.onDownloadButtonClick('svg')" 
                [model]="model.exportCommands">
            </p-splitButton>
        </div>
        <div class="flex flex-wrap">
            @if (model.userInteraction.blueprint.hasPlanningTask()) {
                <xc-grips-options-selector [model]="model.userInteraction.gripsOptions"/>
            }
        </div>
    </div>
}
