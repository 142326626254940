import { TabPager } from "src/app/components-lib/tab-page-container/model/tab-pager";
import { InventoryTreeNode } from "../../model/inventory-tree-node";
import { TabPageItem } from "src/app/components-lib/tab-page-container/model/tab-page-item";
import { InventoryTabTypeEnum } from "./inventory-tab-type-enum";
import { InventoryStateVM } from "./inventory-state-vm";
import { InventoryReferentialVM } from "./inventory-referential-vm";
import { InventoryDocumentsVM } from "./inventory-documents-vm";
import { InventoryMapVM } from "./inventory-map-vm";
import { readableUUID } from "src/app/core/events/event-listener-uuid";
import { ITabPageContent } from "src/app/components-lib/tab-page-container/model/i-tab-page-content";
import { InventoryTreeModel } from "../../model/inventory-tree-model";
import { TabPageContentTypeEnum } from "../../../../real-estate/tab-page-content-type-enum";

export class InventoryTabPagerVM extends TabPager {
    selectedTreeNode: InventoryTreeNode | undefined;
    selectedParentTreeNode: InventoryTreeNode | undefined;
    //treeDataDTO: BusinessUnitTreeDataDTO | undefined;
    name: string = "NA";
    tabPageContentTypeEnum = TabPageContentTypeEnum;
    treeUpdateRequested?: (id: number, columnName: string, value: any) => void;
    treeRefreshRequested?: () => void;
    inventoryTabTypeEnum = InventoryTabTypeEnum;

    private constructor() {
        super(readableUUID("InventoryTabPagerVM"));

        this.addEventListener(InventoryTreeModel.inventoryTreeNodeChangeEvent, async (node: InventoryTreeNode, parentNode: InventoryTreeNode | undefined) => {
            await this.loadNodeData(node, parentNode);
        });
    }

    static async newAsync(): Promise<InventoryTabPagerVM> {
        const result = new InventoryTabPagerVM();
        await result.initialize();
        return result;
    }

    async initialize(): Promise<void> {
        this.prepareTabs();
        await this.selectTabPage(0);
        //this.selectedTab = this.tabs[0];
        //await this.loadContent();
    }

    async loadNodeData(node: InventoryTreeNode, parentNode: InventoryTreeNode | undefined): Promise<void> {
        this.selectedTreeNode = node;
        this.selectedParentTreeNode = parentNode;
        await this.setSelectedContent();
        //await this.loadContent();
    }
    
    prepareTabs(): void {
        this.tabs.push(new TabPageItem(InventoryTabTypeEnum.inventorystate, "Etat d'inventaire"));
        this.tabs.push(new TabPageItem(InventoryTabTypeEnum.map, "Cartographie"));
        this.tabs.push(new TabPageItem(InventoryTabTypeEnum.referential, "Référentiel"));
        this.tabs.push(new TabPageItem(InventoryTabTypeEnum.documents, "Documents"));
    }

    override async loadContent(): Promise<ITabPageContent | undefined> {
        const node = this.selectedTreeNode;
        if (!node) return;
        if (!this.selectedTab) return;

        let data: any | undefined = undefined;
        switch (this.selectedTab.id) {
            case InventoryTabTypeEnum.inventorystate:
                return new InventoryStateVM(node);
            case InventoryTabTypeEnum.map:
                return new InventoryMapVM();
            case InventoryTabTypeEnum.referential:
                return new InventoryReferentialVM(node);
            case InventoryTabTypeEnum.documents:
                return new InventoryDocumentsVM();
            default:
                break;
        }

        return undefined;
    }

    // async selecteChartChange(e: MatRadioChange): Promise<void> {
    //     await this.loadContent();
    // }

}