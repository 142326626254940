import { EventListener } from "src/app/core/events/event-listener";
import { NestedToolbarTypeEnum } from "./nested-toolbar-type-enum";
import { readableUUID } from "src/app/core/events/event-listener-uuid";

export abstract class NestedToolbarBase extends EventListener {
    type: NestedToolbarTypeEnum = NestedToolbarTypeEnum.none;

    constructor(type: NestedToolbarTypeEnum) {
        super(readableUUID(NestedToolbarBase.name));
        
        this.type = type;
    }
}