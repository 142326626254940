import { SvgEntityTypesEnum } from 'src/app/core/model/svg-model/svg-entity-type-enum';
import { SvgEntity } from './svg-entity.model';
import { Point } from "../geometry-model/point.model"
import { Segment } from "../geometry-model/segment.model"
import { Polygon } from "../geometry-model/polygon.model"

export class SvgRectangle extends SvgEntity {
    x: number = 0;
    y: number = 0;
    width: number = 0;
    height: number = 0;
    rx: number = 0;
    ry: number = 0;


    constructor(dtoData: any) {
        super(dtoData);
        this.x = SvgEntity.getNumber(dtoData, "x");
        this.y = SvgEntity.getNumber(dtoData, "y");
        this.width = SvgEntity.getNumber(dtoData, "width");
        this.height = SvgEntity.getNumber(dtoData, "height");
    }

    position(): Point {
        return new Point(this.x, this.y);
    }

    originCorner(): Point {
        return new Point(this.x, this.y);
    }

    oppositeCorner(): Point {
        return new Point(this.x + this.width, this.y + this.height);
    }

    topRight(): Point {
        return new Point(this.x + this.width, this.y);
    }

    bottomLeft(): Point {
        return new Point(this.x, this.y + this.height);
    }

    center(): Point {
        const diagonal = new Segment(this.originCorner(), this.oppositeCorner());
        return diagonal.midPoint();
    }

    moveTo(newPosition: Point): void {
        this.x = newPosition.x;
        this.y = newPosition.y;
    }

    polygon(closed: boolean = true): Polygon {
        const p = new Polygon([this.originCorner(), this.topRight(), this.oppositeCorner(), this.bottomLeft()]);
        if (closed) p.close();
        return p;
    }

    static fromValues(x: number, y: number, width: number, height: number): SvgRectangle {
        return new SvgRectangle({x: x, y: y, width: width, height: height, entityType: SvgEntityTypesEnum.rect});
    }
}
