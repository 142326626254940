import { ITabPageContent } from "src/app/components-lib/tab-page-container/model/i-tab-page-content";
import Container from "typedi";
import { DyntService } from "src/app/core/services/backend-services/dynt-service";
import { FloorView } from "src/app/core/model/data-model/views/floor-view";
import { ViewsNames } from "src/app/core/model/db-model/views-names-enum";
import { FloorTable } from "src/app/core/model/db-model/tables/floor-table";
import { XcMaths } from "src/app/core/model/static-functions/xc-maths";
import { DonutChartWrapperVM } from "src/app/ui/shared/charts/donut/model/donut-chart-wrapper-vm";
import { ChartBuilder } from "src/app/ui/shared/shared-model/chart-builder";
import { FloorActivityStatusView } from "src/app/core/model/data-model/views/floor-activity-status-view";
import { FloorEquipmentProviderView } from "src/app/core/model/data-model/views/floor-equipment-provider-view";
import { FloorAllocationView } from "src/app/core/model/data-model/views/floor-allocation-view";
import { FloorAnonymousDonutChartDataBuilder } from "./floor-anonymous-donut-chart-data-builder";
import { FloorAttributionView } from "src/app/core/model/data-model/views/floor-attribution-view";
import { FloorAnatomyView } from "src/app/core/model/data-model/views/floor-anatomy-view";
import { DynTableVM } from "src/app/components-lib/dyn-grid/model/dyn-table-vm";
import { DynTableVMBuilder } from "src/app/components-lib/dyn-grid/model/dyn-table-vm-builder";
import { Paginator } from "src/app/components-lib/dyn-grid/model/paginator";
import { DynviewSet } from "src/app/core/model/data-model/sets/dyn-view-set";
import { DirectoryTable } from "src/app/core/model/db-model/tables/directory-table";
import { DirectoryService } from "src/app/core/services/backend-services/directory-service";
import { PaginationService } from "src/app/core/services/backend-services/pagination-service";
import { TableReportWrapper } from "src/app/ui/shared/charts/table/table-report-wrapper";
import { ReferentialReportWrapper } from "src/app/ui/shared/charts/referential/referential-report-wrapper";
import { TablesNamesEnum } from "src/app/core/model/db-model/tables-names-enum";
import { MoveListsContainerVM } from "./move-lists-container-vm";
import { AnonymousChartDataBuilder } from "../charts/anonymous-chart-data-builder";
import { RealEstateChartTypeEnum } from "../charts/shared-model/real-estate-chart-type-enum";
import { TabPageContentTypeEnum } from "../tab-page-content-type-enum";

export class FloorDashboardVM { //} extends RealEstateTabPageContainerVM implements RealEstateDashboardInterface {
    name: string = "NA";
    floorId: number;

    grossArea: number = 0;
    netArea: number = 0;
    utilRatio: number = 0;

    contentTypeEnum = TabPageContentTypeEnum;

    selectedChart: RealEstateChartTypeEnum | undefined;
    requiredChart: TabPageContentTypeEnum | undefined;
    currentView: ITabPageContent | undefined;

    private constructor(floorId: number) {
        //super(RealEstateDashboardTypeEnum.Floor);
        this.floorId = floorId;
        // this.setChartOptions();
        this.loadInfos();
        //this.selectTabPage(0);
    }

    static async newAsync(floorId: number): Promise<FloorDashboardVM> {
        const tmp = new FloorDashboardVM(floorId);
        await tmp.loadInfos();
        return tmp;
    }
    // setChartOptions(): void {
    //     this.tabs.push(new TabPageItem(RealEstateChartTypeEnum.anatomy, "Anatomie", TabPageContentTypeEnum.donutchart));
    //     this.tabs.push(new TabPageItem(RealEstateChartTypeEnum.activities, "Activité", TabPageContentTypeEnum.donutchart));
    //     this.tabs.push(new TabPageItem(RealEstateChartTypeEnum.attributions, "Attributions", TabPageContentTypeEnum.donutchart));
    //     this.tabs.push(new TabPageItem(RealEstateChartTypeEnum.inventory, "Inventaire", TabPageContentTypeEnum.donutchart));
    //     this.tabs.push(new TabPageItem(RealEstateChartTypeEnum.businessunits, "Entités", TabPageContentTypeEnum.donutchart));
    //     this.tabs.push(new TabPageItem(RealEstateChartTypeEnum.workforce, "Effectifs", TabPageContentTypeEnum.none));
    //     this.tabs.push(new TabPageItem(RealEstateChartTypeEnum.moveLists, "Mouvements", TabPageContentTypeEnum.moveLists));
    //     this.tabs.push(new TabPageItem(RealEstateChartTypeEnum.history, "Historique", TabPageContentTypeEnum.none));
    //     this.tabs.push(new TabPageItem(RealEstateChartTypeEnum.referential, "Référentiel", TabPageContentTypeEnum.referential));
    // }

    async loadInfos(): Promise<void> {
        const s = Container.get(DyntService);
        const fw = await s.downloadRow<FloorView>(ViewsNames.FloorView, undefined, FloorTable.flId, this.floorId);
        if (fw) {
            this.name = fw.flName;
            this.grossArea = XcMaths.round(fw.flViGLA, 0);
            this.netArea = XcMaths.round(fw.flViNUA, 0);
            this.utilRatio = XcMaths.round(this.netArea / this.grossArea, 2);
        }
    }

    async loadContent(chart: RealEstateChartTypeEnum): Promise<void> {
        //if (!this.selectedTab) return;
        this.selectedChart = chart;

        switch (chart) {
            case RealEstateChartTypeEnum.referential:
                this.requiredChart = TabPageContentTypeEnum.referential;
                this.currentView = await ReferentialReportWrapper.newAsync(chart, "", TablesNamesEnum.Floor, this.floorId);
                break;
            case RealEstateChartTypeEnum.moveLists:
                this.requiredChart = TabPageContentTypeEnum.moveLists;
                const moveLists = new MoveListsContainerVM(this.floorId);
                await moveLists.loadTasks();
                this.currentView = moveLists;
                break;
            case RealEstateChartTypeEnum.workforce:
            case RealEstateChartTypeEnum.history:
                this.requiredChart = TabPageContentTypeEnum.none;
                this.currentView = await this.loadListChart(chart);
                break;
            default:
                this.requiredChart = TabPageContentTypeEnum.donutchart;
                this.currentView = await this.loadDonutChart(this.floorId, chart);
                break;
        }
    }

    async loadDonutChart(floorId: number, floorChartType: string): Promise<ITabPageContent | undefined> {
        const s = Container.get(DyntService);
        //const id = crypto.randomUUID();
        const chart = new DonutChartWrapperVM();

        switch (floorChartType) {
            case RealEstateChartTypeEnum.anatomy:
                let ana = await s.downloadTable<FloorAnatomyView>(ViewsNames.FloorAnatomyView, undefined, FloorTable.flId, floorId);
                ana = ana.filter(x=> x.roViIsCurrentState);
                const anaChartData = await AnonymousChartDataBuilder.getAnatomyData(ana, 3);
                chart.set(anaChartData);
                break;
            case RealEstateChartTypeEnum.activities:
                let ac = await s.downloadTable<FloorActivityStatusView>(ViewsNames.FloorActivityStatusView, undefined, FloorTable.flId, floorId);
                ac = ac.filter(x=> x.roViIsCurrentState);
                const acAnonymous = FloorAnonymousDonutChartDataBuilder.getActivityData(ac);
                const pieChartData = ChartBuilder.getActivityDonutChartData(this.name, acAnonymous);
                chart.set(pieChartData);
                break;
            case RealEstateChartTypeEnum.attributions:
                let at = await s.downloadTable<FloorAttributionView>(ViewsNames.FloorAttributionView, undefined, FloorTable.flId, floorId);
                at = at.filter(x=> x.roViIsCurrentState);
                const atAnonymous = FloorAnonymousDonutChartDataBuilder.getAttributionData(at);
                const attrPieChartData = ChartBuilder.getAttributionDonutChartData(this.name, atAnonymous);
                chart.set(attrPieChartData);
                break;
            case RealEstateChartTypeEnum.inventory:
                const inv = await s.downloadTable<FloorEquipmentProviderView>(ViewsNames.FloorEquipmentProviderView, undefined, FloorTable.flId, floorId);
                const invAnonymous = FloorAnonymousDonutChartDataBuilder.getInventoryData(inv);
                const invChartData = ChartBuilder.getInventoryDonutChartData(this.name, invAnonymous, "€");
                chart.set(invChartData);
                break;
            case RealEstateChartTypeEnum.businessunits:
                let bus = await s.downloadTable<FloorAllocationView>(ViewsNames.FloorAllocationView, undefined, FloorTable.flId, floorId);
                bus = bus.filter(x=> x.roViIsCurrentState);
                const busAnonymous = FloorAnonymousDonutChartDataBuilder.getBusinessUnitsData(bus);
                const busChartData = ChartBuilder.getBusinessUnitsChartData(this.name, busAnonymous);
                chart.set(busChartData);
                break;
            case RealEstateChartTypeEnum.workforce:
                break;
            case RealEstateChartTypeEnum.history:
                break;
            default:
                break;
        }

        return chart;
    }

    async loadListChart(floorChartType: RealEstateChartTypeEnum): Promise<ITabPageContent | undefined> {
        const p = Container.get(PaginationService);
        let paginator: Paginator | null;
        let datas: DynviewSet;
        let table: DynTableVM;
        //const id = crypto.randomUUID();
        switch (floorChartType) {
            case RealEstateChartTypeEnum.workforce:
                const d = Container.get(DirectoryService);
                paginator = await d.getReWorkforcePaginator(FloorTable.databaseTableName, this.floorId);
                datas = await d.getReWorkforce(FloorTable.databaseTableName, this.floorId, 0, paginator?.pageItemsCount);
                table = DynTableVMBuilder.getVM(datas, DirectoryTable.databaseTableName, paginator);
                table.newPageDataRequested = async (pageIndex: number) => {
                    const d = Container.get(DirectoryService);
                    const px = await d.getReWorkforce(FloorTable.databaseTableName, this.floorId, pageIndex, paginator?.pageItemsCount);
                    table.setDataSource(px.viewData);
                }
                table.csvExtractRequested = async () => {
                    // TODO : auditer les opérations d'extraction de données
                    const d = Container.get(DirectoryService);
                    const dp = await d.getReWorkforce(FloorTable.databaseTableName, this.floorId);
                    const data = DynTableVMBuilder.getRows(dp.viewData, table.columns, table.primaryColumnName, table.definitions, table.constraints);
                    DynTableVMBuilder.exportToCsv(table.columns, data, table.sourceViewName);
                }
                table.pdfExtractRequested = async () => {
                    alert("Bientôt disponible !");
                }
        
                const tmpwf = new TableReportWrapper();
                tmpwf.set(table);
                return tmpwf
            case RealEstateChartTypeEnum.history:
                const t = Container.get(DyntService);
                paginator = await t.paginator("floor_blueprint_history_dynview", this.floorId);
                datas = await t.contextedDataSet("floor_blueprint_history_dynview", 0, "TaFl_FloorId", this.floorId, paginator?.pageItemsCount);
                table = DynTableVMBuilder.getVM(datas, DirectoryTable.databaseTableName, paginator);
                table.newPageDataRequested = async (pageIndex: number) => {
                    const d = Container.get(DirectoryService);
                    const px = await d.getReWorkforce(FloorTable.databaseTableName, this.floorId, pageIndex, paginator?.pageItemsCount);
                    table.setDataSource(px.viewData);
                }
                table.csvExtractRequested = async () => {
                    // TODO : auditer les opérations d'extraction de données
                    const d = Container.get(DirectoryService);
                    const dp = await d.getReWorkforce(FloorTable.databaseTableName, this.floorId);
                    const data = DynTableVMBuilder.getRows(dp.viewData, table.columns, table.primaryColumnName, table.definitions, table.constraints);
                    DynTableVMBuilder.exportToCsv(table.columns, data, table.sourceViewName);
                }
                table.pdfExtractRequested = async () => {
                    alert("Bientôt disponible !");
                }
        
                const tmpfh = new TableReportWrapper();
                tmpfh.set(table);
                return tmpfh
            default:
                return undefined;
        }

    }
}
