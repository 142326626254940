import { TaskBusinessTypeTable } from "../../db-model/tables/task-business-type-table";
import { DefinedModelBase } from "../defined-model-base"

export class TaskBusinessType extends DefinedModelBase {
    taBuTyId!: number;
    taBuTyName!: string;
    taBuTyDisplayNameId!: number;
    taBuTyScope!: number;
    taBuTyIsActive!: boolean;
    taBuTyColor!: string;

    constructor(dtoData: any, tableDef: any[] = []) {
        super(dtoData, [TaskBusinessTypeTable].concat(tableDef));
    }
}