import { Service } from "typedi";
import { ApiControllers, ApiEndpoints } from "../api-endpoints";
import { ApiService } from "../api-service";
import { LayoutTypeTreeDataDTO } from "./dto/layout-type-tree-data-dto";

@Service({ global: true })  
export class LayoutTypeService extends ApiService {
    private treeDatasEndpoint: string = `${ApiControllers.roomLayoutTypes}/treedatas`;

    constructor() {
        super();
    }

    async loadTreeDatas(): Promise<LayoutTypeTreeDataDTO | null> {
      const result = await this.getAsync<any>(this.endPoint(this.treeDatasEndpoint));
        if (result != null) {
            return new LayoutTypeTreeDataDTO(result.payload);
        }
        return null;
    }

}