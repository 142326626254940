export class ApiData<T> {
    // allowsDelete: boolean;
    // allowsInsert: boolean;
    // allowsRead: boolean;
    // allowsUpdate: boolean;
    // callbackUrl: string;
    // fileable: boolean;
    // primaryColumnName: string;
    contextUserColumns: Array<any>;
    columnsConstraints: Array<any>;
    id: number;
    message: string;
    //message: string | string[];
    payload: T;
    // payloadDefinitions: any;
    statusCode: string;

    constructor(data: any) {
        // this.allowsDelete = data["allowsDelete"];
        // this.allowsInsert = data["allowsInsert"];
        // this.allowsRead = data["allowsRead"];
        // this.allowsUpdate = data["allowsUpdate"];
        // this.callbackUrl = data["callbackUrl"];
        // this.fileable = data["fileable"];
        // this.primaryColumnName = data["primaryColumnName"];
        this.contextUserColumns = data["contextUserColumns"];
        this.columnsConstraints = data["columnsConstraints"];
        this.id = data["id"];
        this.message = data["message"];
        this.payload = data["payload"];
        // this.payloadDefinitions = data["payloadDefinitions"];
        this.statusCode = data["statusCode"];
    }
  }
  