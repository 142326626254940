import { BpSvgDef } from "../../../../bp-svg-core-model/bp-svg-def";
import { BpSvgUse } from "../../../../bp-svg-core-model/bp-svg-use";
import { BpSvgRoom } from "../../../../svg-entities/model/bp-svg-room"
import { BpSvgBoundingBox } from '../../../../bp-svg-core-model/bp-svg-bounding-box';
import { SvgTransform } from 'src/app/core/model/svg-model/svg-transform.model';

export class SimilarRoom {
    room: BpSvgRoom;
    furniture: BpSvgUse[];
    defs: BpSvgDef[];
    viewBox: string;
    transform: SvgTransform;
    orientedViewBox: string;
    bbox: BpSvgBoundingBox;
    orientedBbox: BpSvgBoundingBox;

    constructor(room: BpSvgRoom, funiture: BpSvgUse[], defs: BpSvgDef[], transform: SvgTransform) {
        this.room = room;
        this.furniture = funiture;
        this.defs = defs;
        this.transform = transform;

        this.bbox = BpSvgBoundingBox.fromPolygon(room.tightBbox!.bbox());
        
        this.viewBox = this.bbox.viewBox();

        this.orientedBbox = this.bbox.transformNew(this.transform);
        this.orientedViewBox = this.orientedBbox.viewBox();
    }

    rotate(): void {
        this.transform = this.transform.rotateTranslate(this.bbox.center(), 90);
    }
}