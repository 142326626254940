import { MatDialog } from "@angular/material/dialog";
import { take } from "rxjs";
import { DynTableVM } from "src/app/components-lib/dyn-grid/model/dyn-table-vm";
import { DynTableVMBuilder } from "src/app/components-lib/dyn-grid/model/dyn-table-vm-builder";
import { readableUUID } from "src/app/core/events/event-listener-uuid";
import { AppMenuRouteEnum } from "src/app/core/model/data-model/enums/app-menu-route-enum";
import { Directory } from "src/app/core/model/data-model/tables/directory";
import { TaskContributorDirectory } from "src/app/core/model/data-model/tables/task-contributor-directory";
import { TablesNamesEnum } from "src/app/core/model/db-model/tables-names-enum";
import { TaskContributorDirectoryTable } from "src/app/core/model/db-model/tables/task-contributor-directory-table";
import { DyntService } from "src/app/core/services/backend-services/dynt-service";
import { PaginationService } from "src/app/core/services/backend-services/pagination-service";
import { PageModel } from "src/app/ui/main/model/page-model";
import Container from "typedi";
import { DirectorySelectVM } from "../../../move-work/nested/admin/model/directory-select-vm";
import { DirectorySelectComponent } from "../../../move-work/nested/admin/view/directory-select/directory-select.component";

export class MobilityContributors extends PageModel {
    adminTable: DynTableVM | undefined;
    dialog: MatDialog | undefined;
    waitingForResult: boolean = false;

    private constructor() {
        super(AppMenuRouteEnum.planning_mobility_contributors, 0, readableUUID(MobilityContributors.name));
    }

    static async newAsync(): Promise<MobilityContributors> {
        const result = new MobilityContributors();
        result.adminTable = await result.loadAdminData("task_contributor_directory");
        return result;
    }

    async loadAdminData(tableName: string): Promise<DynTableVM> {
        const s = Container.get(DyntService);
        const p = Container.get(PaginationService);
        const paginator = await p.getPaginator(tableName);
        const viewSet = await s.contextedDataSet(tableName, 0, undefined, undefined, paginator?.pageItemsCount);
        const adminTable = DynTableVMBuilder.getVM(viewSet, tableName, paginator);

        adminTable.newPageDataRequested = async (pageIndex: number) => {
            const px = await s.contextedDataSet(tableName, pageIndex, undefined, undefined, adminTable.paginator?.pageItemsCount);
            adminTable.setDataSource(px.viewData);
        }

        adminTable.saveRequested = async (tableName: string, primaryColumnName: string, rowId: any, columnName: string, value: any): Promise<string | null> => {
            const s = Container.get(DyntService);
            const dto: any = {
                "TableName": tableName,
                [primaryColumnName]: rowId,
                [columnName]: value
            }
            return await s.patch(tableName, rowId, dto);
        }

        adminTable.overrideInsertMethod(this.insertTaskContributorDirectoryMethod);

        // TODO : cette méthode peut être mutualisée avec toutes les autres écoutes de ce type
        adminTable.deletionRequested = async (tableName: string, rowId: any): Promise<boolean> =>  {
            const s = Container.get(DyntService);
            const result = await s.delete(tableName, rowId);
            if (result) {
                return true;
            }
            return false;
        }

        return adminTable;
    }

    insertTaskContributorDirectoryMethod: any = () => {
        if (this.dialog) {
            const dialogRef = this.dialog.open(DirectorySelectComponent, {
                data: new DirectorySelectVM(), width: "450px"
            });

            // Le .pipe(take(1)) s'assure que le traitement ne sera exécuté qu'une seule fois
            dialogRef.afterClosed().pipe(take(1)).subscribe(async (d: Directory) => {
                if (d) {
                    const s = Container.get(DyntService);
                    this.waitingForResult = true;

                    const dto = {
                        "TableName": TablesNamesEnum.TaskContributorDirectory,
                        [TaskContributorDirectoryTable.taCrDiCode]: d.diCode,
                        [TaskContributorDirectoryTable.taCrDiTitle]: d.diTitle,
                        [TaskContributorDirectoryTable.taCrDiFirstName]: d.diFirstName,
                        [TaskContributorDirectoryTable.taCrDiLastName]: d.diLastName,
                        [TaskContributorDirectoryTable.taCrDiIsActive]: true
                }
                    const result = await s.post<TaskContributorDirectory>(TablesNamesEnum.TaskContributorDirectory, dto);
                    if (result != null) {
                        // Recharge la table
                        this.adminTable = await this.loadAdminData(TablesNamesEnum.TaskContributorDirectory);
                    }
                    this.waitingForResult = false;
                }
            })
        }
    }
}