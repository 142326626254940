import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EquipmentSelectionRotationLabel } from '../../model/equipment-selection-rotation-label';

@Component({
  selector: 'g[xc-equiment-selection-rotation-label]',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './equiment-selection-rotation-label.component.svg',
  styleUrls: ['./equiment-selection-rotation-label.component.scss']
})
export class EquimentSelectionRotationLabelComponent {
  @Input() model: EquipmentSelectionRotationLabel | undefined;
}
