import { ZAppParameterTable } from './../../db-model/tables/z-app-parameter-table';
import { DefinedModelBase } from '../defined-model-base';
export class ZAppParameter extends DefinedModelBase {
    apPaName!: number;
    apPaValue!: string;
    apPaType!: number;

    constructor(dtoData: any, tableDef: any[] = []) {
        super(dtoData, [ZAppParameterTable].concat(tableDef));
    }
}