import { Point } from "src/app/core/model/geometry-model/point.model";
import { Vector } from "src/app/core/model/geometry-model/vector.model";
import { BpSvgUse } from "../../../../bp-svg-core-model/bp-svg-use";
import { EntitiesSelectionSet } from "./entities-selection-set";
import { BpSvgDef } from "../../../../bp-svg-core-model/bp-svg-def";

export class EquipmentsSelectionSet extends EntitiesSelectionSet<BpSvgUse> {
    storeSelectionSetInitialTransform(): void {
        this.items.forEach(item => {
            item.initialTransform = item.transform?.clone();
        });
    }

    getSelectedItemsIds(): string[]
    {
        return this.items.map(x=>x.floorDataId.toString());
    }

    translate(delta: Vector): void {
        this.items.forEach(element => {
            (element as BpSvgUse).translate(delta);
        });
    }

    rotate(rotationCenter: Point, angleDeg: number): void {
        this.items.forEach(element => {
            (element as BpSvgUse).rotate(rotationCenter, angleDeg);
        });
    }

    rotateTranslate(rotationCenter: Point, angleDeg: number, deltaTranslate: Vector): void {
        this.items.forEach(element => {
            (element as BpSvgUse).rotateTranslate(rotationCenter, angleDeg, deltaTranslate);
        });
    }

    reset(): void {
        this.items.forEach(element => {
            element.transform = element.initialTransform?.clone();
        });
    }

    copy(): BpSvgUse[] {
        const copy: BpSvgUse[] = [];
        for (let item of (this.items)) {
            const useCopy = new BpSvgUse(JSON.parse(JSON.stringify(item)));
            useCopy.def = item.def;
            // Normalement le floorDataId n'a pas été copié parce qu'il est passé comme "id" par le backend
            // et n'est donc pas retrouvé lors de la copie
            // mais il vaut mieux le réinitialiser ici pour qu'il n'y ait pas de problème en cas de refac côté backend
            // Pour le stockage des identifiants des objets sources c'est le sourceId qui est utilisé
            useCopy.floorDataId = 0;
            useCopy.sourceId = item.floorDataId;
            copy.push(useCopy);
        }

        // La copie remplace l'original dans la sélection
        this.items = copy;
        this.isCopy = true;

        return copy;
    }

    setCopyResult(items: BpSvgUse[]): void {
        this.items = items;
        this.isCopy = false;
    }

    isHomogeneous(): boolean {
        if (this.count === 1) {
            return true;
        }

        const defIds = (this.items as BpSvgUse[]).map(x=> x.def!.id);
        const distinctDefIds = [...new Set(defIds)];
        return distinctDefIds.length === 1;
    }
}
