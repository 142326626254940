export class ChartCaptionItem {
    id: number;
    mainLabel: string;
    subLabel: string;
    color: string;
    hidden: boolean = false;

    constructor(id: number, mainLabel: string, subLabel: string, color: string) {
        this.id = id;
        this.mainLabel = mainLabel;
        this.subLabel = subLabel;
        this.color = color;
    }
}
