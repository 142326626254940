@if (model) {
    <div class="flex flex-column flex-1 gap-2">
        <p-dropdown 
            [options]="model.options" 
            [(ngModel)]="model.selectedOption" 
            (onChange)="model.onSelectedOptionChange($event)"
            placeholder="Sélectionnez une catégorie"
            styleClass="max-w-0 min-w-full"
            autoWidth="false"
            [group]="true"
            [filter]="true" 
            filterBy="label">
            <ng-template let-group pTemplate="group">
                <div class="flex align-items-center">
                    <i class="pi pi-folder-open" [class]="'mr-2'"></i>
                    <span>{{ group.label }}</span>
                </div>
            </ng-template>
            <ng-template let-cat pTemplate="item">
                <div class="ml-4">
                    <div>{{ cat.label }}</div>
                </div>
            </ng-template>
        </p-dropdown>
        <p-listbox [options]="model.pictosOptions" [group]="true" [(ngModel)]="model.selectedPicto" (onChange)="model.onSelectedPictoChange($event)">
            <ng-template let-group pTemplate="group">
                <div class="flex align-items-center">
                    <i class="pi pi-folder-open" [class]="'mr-2'"></i>
                    <span>{{ group.label }}</span>
                </div>
            </ng-template>
            <ng-template let-pict pTemplate="item">
                <div class="flex ml-4 gap-2 alig-items-center">
                    <xc-secured-image [src]="pict.value.dataSet.flCgImageUrl" imgHeight="30px"/>
                    <div class="flex flex-column">
                        <span>{{ pict.label }}</span>
                        <span>{{ pict.value.providerCompany.prCoName }}</span>
                    </div>
                </div>
            </ng-template>
        </p-listbox>
    </div>
}
