import { SimulationCreationData } from './simulation-creation-data';
import { CreationStepFourVM } from './creation-step-four-vm';
import { CreationStepThreeVM } from './creation-step-three-vm';
import { CreationStepTwoVM } from './creation-step-two-vm';
import { CreationStepOneVM } from './creation-step-one-vm';
import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { logError } from 'src/app/core/services/logging-service';

export class CreationStepperVM {
    stepOne: CreationStepOneVM;
    stepBuildingSelection: CreationStepTwoVM;
    stepBusSelection: CreationStepThreeVM;
    stepFour: CreationStepFourVM;
    creationData: SimulationCreationData;

    constructor() {
        this.creationData = new SimulationCreationData();
        this.stepOne = new CreationStepOneVM(this.creationData);
        this.stepBuildingSelection = new CreationStepTwoVM(this.creationData);
        this.stepBusSelection = new CreationStepThreeVM(this.creationData);
        this.stepFour = new CreationStepFourVM(this.creationData, this.stepBuildingSelection.realEstateTree, this.stepBusSelection.buTree);
    }

    creationEndRequested?: (creationData?: SimulationCreationData) => void;
    cancelButtonClick(): void {
        this.raiseCreationEndRequestEvent();
    }

    async loadStepDatas(selectedIndex: number): Promise<void> {
        switch (selectedIndex) {
            case 0:
                break;
            case 1:
                if (this.creationData.busSelection) {
                    this.stepBusSelection.buTree.readonly = false;
                    if (this.creationData.selectedBus.length === 0) {
                        await this.stepBusSelection.loadDatas();
                    }
                } else {
                    this.stepBuildingSelection.realEstateTree.readonly = false;
                    if (this.creationData.selectedBuildings.length === 0) {
                        await this.stepBuildingSelection.loadDatas();
                    }
                }
                break;
            case 2:
                await this.stepFour.loadDatas();
                break;
            default:
                break;
        }
    }

    async selectedStepChange(e: StepperSelectionEvent): Promise<void> {
        await this.loadStepDatas(e.selectedIndex);
    }

    async createSimulation(): Promise<void> {
        
        this.raiseCreationEndRequestEvent(this.creationData);
    }

    raiseCreationEndRequestEvent(creationData?: SimulationCreationData): void {
        if (this.creationEndRequested) {
            this.creationEndRequested(creationData);
        } else {
            logError("CreationStepperVM.creationEndRequested n'est pas écouté");
        }
    }
}