import { FloorCatalogTable } from './../../db-model/tables/floor-catalog-table';
import { DefinedModelBase } from '../defined-model-base';

export class FloorCatalog extends DefinedModelBase {
    flCgId!: number;
    flCgCode!: string;
    flCgName!: string;
    flCgDescription!: string;
    flCgSvgStatement!: string;
    flCgFloorModelId!: number;
    flCgSvgBoundingBox!: string;
    flCgScope!: number;
    flCgImageUrl!: string | null;

    constructor(dtoData: any, tableDef: any[] = []) {
        super(dtoData, [FloorCatalogTable].concat(tableDef));
    }
}