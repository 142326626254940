import { Component, Input } from '@angular/core';
import { SitesToolbarVM } from '../sites-toolbar-vm';
import { DropdownModule } from 'primeng/dropdown';
import { RadioButtonModule } from 'primeng/radiobutton';
import { FormsModule } from '@angular/forms';
import { MVVMEventDestroyLifeCycle } from 'src/app/components-lib/shared-model/mvvm-events-destroy-lifecycle';

@Component({
  selector: 'xc-sites-toolbar',
  standalone: true,
  imports: [DropdownModule, RadioButtonModule, FormsModule],
  templateUrl: './sites-toolbar.component.html',
  styleUrl: './sites-toolbar.component.scss'
})
export class SitesToolbarComponent extends MVVMEventDestroyLifeCycle {
  @Input() override model: SitesToolbarVM | undefined;
}
