import { ZContextUITableTable } from './../../db-model/tables/z-context-ui-table-table';
import { DefinedModelBase } from '../defined-model-base';

export class ZContextUITable extends DefinedModelBase {
    coUITaId!: number;
    coUITaContextUIId!: number;
    coUITaTableName!: string;
    coUITaAllowsInsert!: boolean;
    coUITaAllowsUpdate!: boolean;
    coUITaAllowsDelete!: boolean;

    constructor(dtoData: any, tableDef: any[] = []) {
        super(dtoData, [ZContextUITableTable].concat(tableDef));
    }
}