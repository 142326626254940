import { SimulationBuildingView } from "src/app/core/model/data-model/views/simulation-building-view";
import { SimulationFloorDTO } from "./simulation-floor-dto";
import { SimulationFloorDbView } from "src/app/core/model/db-model/views/simulation-floor-db-view";
import { ZHierarchyDepthName } from "src/app/core/model/data-model/tables/z-hierarchy-depth-name";

export class SimulationBuildingDTO extends SimulationBuildingView {
    floors: SimulationFloorDTO[] = [];
    hierarchyDepthNames: ZHierarchyDepthName[] = [];
    
    constructor(dtoData: any, hierarchyDepthNames: ZHierarchyDepthName[]) {
        super(dtoData);
        this.hierarchyDepthNames = hierarchyDepthNames;
        dtoData[SimulationFloorDbView.databaseTableName].forEach((e: any) => {
            this.floors.push(new SimulationFloorDTO(e, this.hierarchyDepthNames));
        });
    }

    maxFloorArea(): number {
        let result: number = 0;
        this.floors.forEach(f => {
            if (f.siFlViArea > result) {
                result = f.siFlViArea;
            }
        });
        return result;
    }
}