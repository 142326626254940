import { EditableLayer } from './../../../blueprint-viewer-content-panel/itself/model/interaction/editable-layer';
import { FloorBlueprint } from '../../../blueprint-viewer-content-panel/itself/model/floor-blueprint';
import { EntitiesFormModel } from '../../subitems/properties/model/entities-form-model';
import { BpeSidePanelContent } from './bpe-side-panel-content';
import { BpeSidePanelTabs } from './bpe-side-panel-tabs';
import { EventListener } from 'src/app/core/events/event-listener';
import { readableUUID } from 'src/app/core/events/event-listener-uuid';
import { ThemingModel } from '../../subitems/theming/model/theming-model';
import { DisplayThemeSet } from '../../subitems/theming/model/display-theme-set';
import { BlueprintSidePanelTabEnum } from './bp-side-panel-tab.enum';

export class BlueprintViewerSidePanelVM extends EventListener {
    tabs: BpeSidePanelTabs;
    content: BpeSidePanelContent;
    
    constructor(tabsInvariantId: string) {
        super(readableUUID("BlueprintViewerSidePanelVM"));

        this.tabs = new BpeSidePanelTabs(tabsInvariantId);
        this.content = new BpeSidePanelContent(tabsInvariantId);
        
        this.addEventListener(ThemingModel.selectedThemeChange, (theme: DisplayThemeSet) => {
            const themeTab = this.tabs.tabItems.find(x=> x.index === BlueprintSidePanelTabEnum.displayThemes);
            if (themeTab) {
                themeTab.contentTitle = `Thème : ${theme.label}`;
            }
        });

    }

    async setContent(blueprint: FloorBlueprint): Promise<void> {
        this.tabs.initialize();
        await this.content.initialize(blueprint);
    }

    setPropertiesForm(form: EntitiesFormModel | undefined): void {
        this.content.setPropertiesForm(form);
        this.tabs.setPropertiesTabBadge(form !== undefined);
    }

    setEquipmentList(editableLayers: EditableLayer[]): void {
        this.content.setEquipmentList(editableLayers);
    }
}