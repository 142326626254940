import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BusinessUnitsGrantsVM } from '../../model/business-units-grants-vm';

@Component({
  selector: 'xc-business-units-grants',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './business-units-grants.component.html',
  styleUrls: ['./business-units-grants.component.scss']
})
export class BusinessUnitsGrantsComponent {
  @Input() model: BusinessUnitsGrantsVM | undefined;
}
