export class HtmlEcsyConstants {
    static xcFloorDataId = "xc-floor-data-id";
    static xcFloorModelId = "xc-floor-model-id";
    static xcTaskId = "xc-task-id";
    static xcTaskLinkId = "xc-task-link-id";
    static xcParentId = "xc-parent-id";
    static xcDataStateId = "xc-data-state-id";
    static xcName = "xc-name";
    static xcFunc = "xcFunc";
    static xcLabelLeaderLinebase = "leaderbase";
    static xcLaberLeaderLine = "leader";
    static xcMeasureLine = "xc-measure-line";
    static xcLeaderLine = "xc-leader-line";
    static xcExtensionLine = "xc-ext-line";
    static xcMark = "xc-mark";
    static xcLabel = "xc-label"
}
