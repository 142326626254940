import { MaterialModule } from 'src/app/core/material.module';
import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutTypesTreeModel } from '../../model/layout-types-tree-model';
import { MVVMEventDestroyLifeCycle } from 'src/app/components-lib/shared-model/mvvm-events-destroy-lifecycle';

@Component({
  selector: 'xc-layout-types-tree',
  standalone: true,
  imports: [CommonModule, MaterialModule],
  templateUrl: './layout-types-tree.component.html',
  styleUrls: ['./layout-types-tree.component.scss']
})
export class LayoutTypesTreeComponent extends MVVMEventDestroyLifeCycle {
  @Input() override model: LayoutTypesTreeModel | undefined;
}
