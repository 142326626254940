import { Component, Input, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FloorModelEnum } from 'src/app/core/model/data-model/enums/floor-model-enum';
import { UserInteraction } from '../../model/interaction/user-interaction';
import { BlueprintModule } from '../../../blueprint-viewer-module';
import { logError } from 'src/app/core/services/logging-service';

@Component({
  selector: 'xc-floor-blueprint',
  standalone: true,
  imports: [CommonModule, BlueprintModule],
  templateUrl: './floor-blueprint.component.svg',
  styleUrls: ['./floor-blueprint.component.scss']
})
export class FloorBlueprintComponent implements OnChanges, OnDestroy {
  @Input() model: UserInteraction | undefined;

  FloorModelEnum = FloorModelEnum;

  ngOnChanges(e: SimpleChanges): void {
    if (e["model"] && this.model) {
      setTimeout(() => {
        if (this.model) {
            this.model.initializePanZoom();
            this.model.initializeViewboxObserver();
        } else {
          logError("FloorBlueprintComponent userInteraction n'a pas été initialisé");
        }
      });
    }

  }

  ngOnDestroy(): void {
    if (this.model) {
      document.removeEventListener('keydown', this.model.kd, true);
      this.model.viewboxObserver?.disconnect();
      this.model = undefined
    } else {
      logError("CadConvDrawingComponent - Le document event listener n'a pas pu être supprimé");
    }
  }
}
