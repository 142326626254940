import { Segment } from './segment.model';
import { Point } from './point.model';
import { Polygon } from './polygon.model';

export class Rectangle extends Polygon {
    topLeft: Point; // MinPoint
    topRight: Point;
    bottomLeft: Point;
    bottomRight: Point; // MaxPoint
    width: number;
    height: number;

    constructor(corners: Point[]) {
        super(corners);
        if(corners.length === 4){
            this.topLeft = corners[0];
            this.topRight = corners[1];
            this.bottomRight = corners[2];
            this.bottomLeft = corners[3];
            this.width = this.topRight.x - this.topLeft.x;
            this.height = this.bottomLeft.y - this.topRight.y;
        }else {
            throw 'PocRectangle doit être initialisé avec 4 points : ' + corners.length + 'ont été passés';
        }
    }

    corners(): Point[] {
        return [this.topLeft, this.topRight, this.bottomRight, this.bottomLeft];
    }

    center(): Point {
        return new Segment(this.topLeft, this.bottomRight).midPoint();
    }

    override getSegments(): Segment[] {
        const tmp = super.getSegments();
        // On ajoute le segment de fermeture
        tmp.push(new Segment(this.bottomLeft, this.topLeft));
        return tmp;
    }

    static surroundingBbox(vertices: Point[]): Rectangle {
        let minX: number = 1000;
        let minY: number = 1000;
        let maxX: number = -1000;
        let maxY: number = -1000;

        if (vertices == null || vertices.length === 0) {
            return Rectangle.default();
        }

        vertices.forEach(v => {
            if (v.x < minX) minX = v.x;
            if (v.x > maxX) maxX = v.x;
            if (v.y < minY) minY = v.y;
            if (v.y > maxY) maxY = v.y;
        });

        const p1 = new Point(minX, minY);
        const p2 = new Point(maxX, minY);
        const p3 = new Point(maxX, maxY);
        const p4 = new Point(minX, maxY);

        return new Rectangle([ p1, p2, p3, p4 ]);
    }

    static default(): Rectangle {
        return new Rectangle([ Point.origin(), Point.origin(), Point.origin(), Point.origin() ]);
    }

    static fromMinMaxCoordinates(minX: number, minY: number, maxX: number, maxY: number) : Rectangle {
        const topLeft = new Point(minX, minY);
        const topRight = new Point(maxX, minY);
        const bottomRight = new Point(maxX, maxY);
        const bottomLeft = new Point(minX, maxY);
        return new Rectangle([ topLeft, topRight, bottomRight, bottomLeft]);
    }

    static fromPolygon(p: Polygon): Rectangle {
        if(p.vertices.length != 4) {
            throw "Impossible de créer un rectangle : le polygone ne contient pas 4 points : (" + p.vertices.length + ")"
        }

        return new Rectangle(p.vertices);
    }
}
