import { EquipmentCategoryDbView } from './../../db-model/views/equipment-category-db-view';
import { EquipmentCategory } from './../tables/equipment-category';

export class EquipmentCategoryView extends EquipmentCategory {
    eqCaDisplayName!: string;
    eqCaRootId!: number;
    eqCaFlatTreeDisplayName!: string;

    constructor(dtoData: any) {
        super(dtoData, [EquipmentCategoryDbView]);
    }
}