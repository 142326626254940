import { ZHierarchyDepthNameTable } from "../../db-model/tables/z-hierarchy-depth-name-table";
import { DefinedModelBase } from "../defined-model-base";

export class ZHierarchyDepthName extends DefinedModelBase {
    hiDeNaTableName!: string;
    hiDeNaDepth!: number;
    hiDeNaDepthName!: string;

    constructor(dtoData: any) {
        super(dtoData, [ZHierarchyDepthNameTable]);
    }
}