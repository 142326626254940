import { TablesNamesEnum } from './../tables-names-enum';
export const DocumentVersionTable = {
    databaseTableName: TablesNamesEnum.DocumentVersion,
    doVeId: 'DoVe_Id',
    doVeCreationDate: 'DoVe_CreationDate',
    doVeIndex: 'DoVe_Index',
    doVeIndexCode: 'DoVe_IndexCode',
    doVeDocumentId: 'DoVe_DocumentId',
    doVeMemo: 'DoVe_Memo'
  };
  