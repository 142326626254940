import { BusinessUnitTable } from '../../db-model/tables/business-unit-table';
import { DefinedModelBase } from '../defined-model-base';

export class BusinessUnit extends DefinedModelBase {
    buUnId!: number;
    buUnCode!: string;
    buUnName!: string;
    buUnDepth!: number;
    buUnParentId!: number | null;
    buUnColor!: string;
    buUnIsActive!: boolean;
    buUnIsFictive!: boolean;
    buUnVacancyRatio!: number;
    buUnFlexibilityRatio!: number;
    buUnOccupancyRatio!: number;
    buUnAnatomyId!: number | null;
    buUnReadGrantId!: number | null;

    constructor(dtoData: any, tableDef: any[] = []) {
        super(dtoData, [BusinessUnitTable].concat(tableDef));
      }
}