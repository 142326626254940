import { DxfImport } from "src/app/core/model/data-model/tables/dxf-import";
import { DrawingBoard } from "./drawing-board";
import { DxfLayerDTO } from "src/app/core/services/backend-services/dto/dxf-layer-dto";
import { CadConverterSidePanel } from "./side-panel/cad-converter-side-panel";
import { PageModel } from "src/app/ui/main/model/page-model";
import { PageRouter } from "src/app/ui/main/model/router/page-router";
import { BlueprintGWTabEnum } from "./side-panel/blueprint-gw-tab-enum";
import { AppMenuRouteEnum } from "src/app/core/model/data-model/enums/app-menu-route-enum";

export class CadConverterContainerVM extends PageModel {
    sidePanel: CadConverterSidePanel;
    selectedImport: DxfImport | undefined;
    drawingBoard: DrawingBoard | undefined;
    
    recordedRoute: {tableName: string, id: number, page: string} | undefined;

    constructor() {
        super(AppMenuRouteEnum.graphicworks_blueprints, 0, "bd5f4dd0-c08a-4442-a981-4446eea49073");
        
        this.sidePanel = new CadConverterSidePanel();
        this.sidePanel.selectedImportChange = async (selectedImport: DxfImport, layers: DxfLayerDTO[]) => {
            this.selectedImport = selectedImport;
            if (this.drawingBoard === undefined) {
                this.drawingBoard = new DrawingBoard();
            }
            await this.drawingBoard.setDrawing(selectedImport.dxImId, selectedImport.dxImExtents, layers);
        }
        
        this.sidePanel.layersPanel.clearSelectionRequested = async () => {
            await this.drawingBoard?.userInteraction.abortCommand();
        }

        // Récupère les éléments de navigation enregistrés
        const lastTab = PageRouter.pageSegment(1, BlueprintGWTabEnum, BlueprintGWTabEnum.imports);

        // Navigue vers le dernier onglet utilisé
        this.sidePanel.tabs.selectTabItem(lastTab);
    }
}