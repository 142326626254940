import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'xc-cad-conv-default-view',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './cad-conv-default-view.component.html',
  styleUrls: ['./cad-conv-default-view.component.scss']
})
export class CadConvDefaultViewComponent {

}
