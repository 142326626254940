import { ConstraintRequestItemDyntable } from './../../db-model/dynamic/constraint-request-item-dyntable';
import { ConstraintRequestItem } from './../dynamic/constraint-request-item';
import { ZConstraintMode } from './../tables/z-constraint-mode';
import { ZConstraintType } from './../tables/z-constraint-type';
import { ZColumnConstraintDbView } from './../../db-model/views/z-column-constraint-db-view';
import { ZColumnConstraint } from './../tables/z-column-constraint';

export class ZColumnConstraintView extends ZColumnConstraint {
    coCoVidisplayName!: string;
    requestValues: ConstraintRequestItem[] = [];
    constraintType: ZConstraintType;
    constraintMode: ZConstraintMode;

    constructor(dtoData: any, tableDef: any[] = []) {
        super(dtoData, [ZColumnConstraintDbView].concat(tableDef));
        this.constraintType = new ZConstraintType(dtoData);
        this.constraintMode = new ZConstraintMode(dtoData);
        if (dtoData[ConstraintRequestItemDyntable.databaseTableName]) {
            dtoData[ConstraintRequestItemDyntable.databaseTableName].forEach((e: any) => {
                this.requestValues.push(new ConstraintRequestItem(e));
            });
        }
    }
}