import { ZColumnView } from './../views/z-column-view';
import { DefinedModelBase } from "../defined-model-base";
import { ZColumnConstraintView } from "../views/z-column-constraint-view";
import { DataModelBuilder } from '../data-model.builder';

export class ItemPropertiesSet<T extends DefinedModelBase> {
    item: T;
    columnsDefinitions: ZColumnView[] = [];
    columnsConstraints: ZColumnConstraintView[] = [];

    constructor(dtoData: any, className: string) {
        this.item = DataModelBuilder.instance<T>(className, dtoData["item"]);
        dtoData["columnsDefinitions"].forEach((cd: any) => {
            this.columnsDefinitions.push(new ZColumnView(cd));
        });
        dtoData["columnsConstraints"].forEach((cd: any) => {
            this.columnsConstraints.push(new ZColumnConstraintView(cd));
        });
    }
}