import { ZDbColumnTable } from './../../db-model/tables/z-db-column-table';
import { DefinedModelBase } from '../defined-model-base';

export class ZDbColumn extends DefinedModelBase {
    dbCoTableName!: string;
    dbCoColumnName!: string;
    dbCoColumnDisplayNameId!: number;
    dbCoIsPrimaryKey!: boolean;
    dbCoIsIdentity!: boolean;
    dbCoIsBrowsable!: boolean;
    dbCoIsReadOnly!: boolean;
    dbCoScopeId!: number;
    dbCoCategoryId!: number;
    dbCoDisplayOrder!: number;
    dbCoIsDefault!: boolean;
    dbCoType!: number;
    dbCoDisplayStringFormat!: string | null;
    dbCoReadGrantId!: number | null;
    dbCoUpdateGrantId!: number | null;

    constructor(dtoData: any, tableDef: any[] = []) {
        super(dtoData, [ZDbColumnTable].concat(tableDef));
    }
}