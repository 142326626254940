import { SimulationFloorAnatomyTable } from "../../db-model/tables/simulation-floor-anatomy-table";
import { DefinedModelBase } from "../defined-model-base";

export class SimulationFloorAnatomy extends DefinedModelBase {
    siFlAnPhoto!: number;
    siFlAnSimulationFloor!: number;
    siFlAnLayoutTypeId!: number;
    siFlAnAttributionTypeId!: number;
    siFlAnActivityStatusTypeId!: number;
    siFlAnArea!: number;

    constructor(dtoData: any, tableDef: any[] = []) {
        super(dtoData, [SimulationFloorAnatomyTable].concat(tableDef));
    }
}