import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditorMainContainerVM } from '../../model/editor-main-container-vm';
import { CreationStepperComponent } from '../../../../creation-stepper/view/creation-stepper/creation-stepper.component';
import { StrategySchedulingComponent } from '../../../../schedule/view/strategy-scheduling/strategy-scheduling.component';
import { StrategyStackingComponent } from '../../../../stack/view/strategy-stacking/strategy-stacking.component';
import { StrategyWorkforceComponent } from '../../../../workforce/view/strategy-workforce/strategy-workforce.component';
import { MVVMEventDestroyLifeCycle } from 'src/app/components-lib/shared-model/mvvm-events-destroy-lifecycle';

@Component({
  selector: 'xc-editor-main-container',
  standalone: true,
  imports: [CommonModule, CreationStepperComponent, StrategySchedulingComponent, StrategyWorkforceComponent, StrategyStackingComponent],
  templateUrl: './editor-main-container.component.html',
  styleUrls: ['./editor-main-container.component.scss']
})
export class EditorMainContainerComponent extends MVVMEventDestroyLifeCycle {
  @Input() override model: EditorMainContainerVM | undefined;
}
