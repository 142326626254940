import { Point } from "src/app/core/model/geometry-model/point.model";
import { Rectangle } from "src/app/core/model/geometry-model/rectangle.model";
import { SelectedEntities } from "../../../../itself/model/interaction/selected-entities";
import { LayerCommandButtonModel } from "../../layer-command-button/model/layer-command-button-model";
import { logError } from "src/app/core/services/logging-service";

export class VariousSelectionGizmoModel {
    selectedEntities: SelectedEntities;
    commandsPosition: Point = Point.origin();
    bbox: Rectangle = Rectangle.default();
    layerSelectionCommands: LayerCommandButtonModel[] = [];
    displayed: boolean = false;

    constructor(selectedEntities: SelectedEntities) {
        this.selectedEntities = selectedEntities;
    }

    show(display: boolean): void {
        this.layerSelectionCommands.splice(0);
        this.displayed = display;
        if (!display) {
            return;
        }

        // S'il n'y a qu'un seul calque dans la sélection, elle est immédiatement validée
        if (this.selectedEntities.layerEntities.length === 1) {
            this.raiseLayerCommandButtonClick(this.selectedEntities.layerEntities[0].layerId);
        } else {
            // Sinon on affiche les boutons de choix
            let yPos = (this.selectedEntities.layerEntities.length / 2) * 0.7;
            let currentPosition = new Point(this.commandsPosition.x, this.commandsPosition.y - yPos);
            this.selectedEntities.layerEntities.forEach(le => {
                this.layerSelectionCommands.push(new LayerCommandButtonModel(le.layerId, le.layerName, currentPosition));
                yPos += 0.7;
                currentPosition = new Point(this.commandsPosition.x, this.commandsPosition.y - yPos);
            });
        }

        this.layerSelectionCommands.forEach(c => {
            c.buttonMouseDown = (layerId: number) => { this.onLayerCommandButtonClick(layerId); }
        });
    }

    raiseLayerCommandButtonClick(layerId: number): void {
        if (this.layerCommandButtonClick) {
            this.layerCommandButtonClick(layerId);
        } else {
            logError("VariousSelectionGizmoModel.layerCommandButtonClick n'est pas écouté");
            
        }
    }

    layerCommandButtonClick?: (layerId: number) => void;
    onLayerCommandButtonClick(layerId: number): void {
        this.raiseLayerCommandButtonClick(layerId);
    }
}
