export class SidePanelResizer {
    readonly sidePanelMinWidth: number = 30;
    readonly sidePanelMaxResizingWidth: number = 550;
    readonly sidePanelMinResizingWidth: number = 300;
    readonly sidePanelDefaultWidth: number = 350;
    sidePanelCurrentDefaultWidth: number = this.sidePanelDefaultWidth;
    resizeHandleWidth: number = 4; 
    sidePanelWidth: number = this.sidePanelMinWidth;
    sidePanelResizing: boolean = false;
    sidePanelIsFold: boolean = true;
    sidenavResizingInitiaWidth: number = 0;
    resizingInitialClientX: number = 0;
    
    foldSidePanel(unfold: boolean = false): void {
        let targetWidth: number = this.sidePanelMinWidth;
        
        if (unfold) {
            targetWidth = this.sidePanelCurrentDefaultWidth;
        }
    
        this.sidePanelWidth = targetWidth;
        this.sidePanelIsFold = !unfold;
    }
    
    initializeStackSidenavRezising(e: MouseEvent): void {
        e.stopPropagation();
        this.sidePanelResizing = true;
        this.sidenavResizingInitiaWidth = this.sidePanelWidth;
        this.resizingInitialClientX = e.clientX;
    }
    
    onSidenavResizing(e: MouseEvent): void {
        e.stopPropagation();
        if (this.sidePanelResizing && e.buttons === 1) {
            const newWidth = this.sidenavResizingInitiaWidth - e.clientX + this.resizingInitialClientX;
            if (newWidth >= this.sidePanelMinResizingWidth && newWidth <= this.sidePanelMaxResizingWidth) {
                this.sidePanelWidth = newWidth;
            }
        }
    }
    
    onSidenavEndResizing(e: MouseEvent): void {
        e.stopPropagation();
        if (this.sidePanelResizing) {
            this.sidePanelResizing = false;
            this.sidePanelCurrentDefaultWidth = this.sidePanelWidth;
        }
    }
       
    onPinClick(): void {
        this.sidePanelIsFold = !this.sidePanelIsFold;
        
        if (!this.sidePanelIsFold) {
            this.foldSidePanel(true);
        } else {
            this.foldSidePanel();
        }
    }
}