export class SvgPattern {
    id: string;
    firstColor: string;
    secondColor: string;
    refColor: string;

    constructor(id: string, firstColor: string, secondColor: string, refColor: string) {
        this.id = id;
        this.firstColor = firstColor;
        this.secondColor = secondColor;
        this.refColor = refColor;
    }
}
