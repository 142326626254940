import { EmailGrantedDomainTable } from "../../db-model/tables/email-granted-domain-table";
import { DefinedModelBase } from "../defined-model-base";

export class EmailGrantedDomain extends DefinedModelBase {
    emGrDoId!: number;
    emGrDoValue!: string;

    constructor(dtoData: any, tableDef: any[] = []) {
        super(dtoData, [EmailGrantedDomainTable].concat(tableDef));
    }
}