import { FloorCatalogImportTable } from '../../db-model/tables/floor-catalog-import-table';
import { DefinedModelBase } from '../defined-model-base';

export class FloorCatalogImport extends DefinedModelBase {
    flCgImId!: number;
    flCgImRequestId!: string;
    flCgImRequestTimestamp!: Date;
    flCgImTempFolderPath!: string;

    constructor(dtoData: any, tableDef: any[] = []) {
        super(dtoData, [FloorCatalogImportTable].concat(tableDef));
    }
}