import Container from "typedi";
import { DyntService } from "src/app/core/services/backend-services/dynt-service";

export class DynTableDefaultProcessing {
    static async saveNewRow(values: any): Promise<any> {
        const s = Container.get(DyntService);
        return await s.post<any>("", values);
    }

    static async saveRowUpdate(tableName: string, rowId: number, dto: any): Promise<any> {
        const s = Container.get(DyntService);
        return await s.patch(tableName, rowId, dto);
    }

    static async deleteRow(tableName: string, rowId: number): Promise<any> {
        const s = Container.get(DyntService);
        return await s.delete(tableName, rowId);
    }
}