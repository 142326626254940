export class DxfSvgDOM {
    static getEntityBbox(entityId: number): SVGRect | undefined {
        const tmp = document.querySelector(`[entity-id="${entityId}"]`);
        if (tmp != null) {
            const element = tmp as unknown as SVGSVGElement;
            return element.getBBox();
        }
        return undefined;
    }

}