import { TablesNamesEnum } from './../tables-names-enum';
export const FloorDataImportItemTable = {
    databaseTableName: TablesNamesEnum.FloorDataImportItem,
    flDaImItId: 'FlDaImIt_Id',
    flDaImItRequestId: 'FlDaImIt_RequestId',
    flDaImItFloorCatalogId: 'FlDaImIt_FloorCatalogId',
    flDaImItSvgStatement: 'FlDaImIt_SvgStatement',
    flDaImItBoundingBox: 'FlDaImIt_BoundingBox',
    flDaImItFloorModelId: 'FlDaImIt_FloorModelId',
    flDaImItTaskId: 'FlDaImIt_TaskId',
    flDaImItDataStateId: 'FlDaImIt_DataStateId',
    flDaImItParentId: 'FlDaImIt_ParentId',
    flDaImItSourceId: 'FlDaImIt_SourceId',
    flDaImItAnchorId: 'FlDaImIt_AnchorId'
  };
  