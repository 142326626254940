import { Segment } from "src/app/core/model/geometry-model/segment.model";
import { Vector } from "src/app/core/model/geometry-model/vector.model";
import { XcMaths } from "src/app/core/model/static-functions/xc-maths";
import { SvgEllipse } from "src/app/core/model/svg-model/svg-ellipse";
import { SvgEllipseService } from "src/app/core/model/svg-model/svg-ellipse-service";
import { SvgEntityTypesEnum } from "src/app/core/model/svg-model/svg-entity-type-enum";
import { SvgEntity } from "src/app/core/model/svg-model/svg-entity.model";
import { SvgTransform } from "src/app/core/model/svg-model/svg-transform.model";
import { SvgEntityPoint } from "../../shared/gizmos/model/svg-entity-point";
import { SvgEntityPointStyleEnum } from "../../shared/gizmos/model/svg-entity-point-style-enum";
import { SvgEntityParser } from "../../shared/gizmos/model/svg-entity-parser";
import { SelectableSvgEntity } from "./selectable-svg-entity";
import { Point } from "src/app/core/model/geometry-model/point.model";
import { createRandomString } from "./selectable-entity-builder";

export class SelectableSvgEllipse extends SelectableSvgEntity {
    cx: number;
    cy: number;
    rx: number;
    ry: number;

    constructor(dtoData: any) {
        super(dtoData);

        this.cx = SvgEntity.getNumber(dtoData, "cx");
        this.cy = SvgEntity.getNumber(dtoData, "cy");
        this.rx = SvgEntity.getNumber(dtoData, "rx");
        this.ry = SvgEntity.getNumber(dtoData, "ry");
    }

    centerPoint(): SvgEntityPoint {
        return new SvgEntityPoint(SvgEntityPointStyleEnum.center, new Point(this.cx, this.cy));
    }

    majorAxis(): Segment {
        const a = XcMaths.toRad(this.transform ? this.transform.rotationAngle : 0);
        const tmp = new Point(this.cx + this.rx, this.cy);
        const p1 = tmp.rotate(this.centerPoint(), a);
        const p2 = tmp.rotate(this.centerPoint(), a + Math.PI);
        return new Segment(p1, p2);
    }

    override getSelectablePoints(): SvgEntityPoint[] {
        const result = [new SvgEntityPoint(SvgEntityPointStyleEnum.center, new Point(this.cx, this.cy))];
        const e = SvgEllipseService.geometry(this as SvgEllipse);
        const t = this.transform;
        const a = XcMaths.toRad(t? t.rotationAngle : 0);
        result.push(...SvgEntityPoint.toStyle(Point.rotateAll(e.getQuadrants(), e.center, a), SvgEntityPointStyleEnum.quadrant, this.entityId, this.transform?.rotationAngle));
        return result;
    }

    override getStatement(): string {
        const attributesStatement = SvgEntityParser.getAttributesStatement([["cx", this.cx], ["cy", this.cy], ["rx", this.rx], ["ry", this.ry], ["stroke-width", this.strokeWidth], ["stroke", this.stroke]], this.transform);
        return SvgEntityParser.getTagStatement("ellipse", attributesStatement);
    }

    center(): Point {
        return new Point(this.cx, this.cy);
    }

    rotationAngle(): number {
        if (this.transform) return this.transform.rotationAngle;
        return 0;
    }

    isCenter(p: Point): boolean {
        return p.x === this.cx && p.y === this.cy;
    }

    isXRadiusGrip(p: Point): boolean {
        return XcMaths.round(p.distanceTo(this.center()), 3) === XcMaths.round(this.rx, 3);
    }

    radiusDelta(r: number, major: boolean): number {
        if (major) return r - this.rx;
        return r - this.ry;
    }

    changeRadius(delta: number, xRadius: boolean): void {
        if (xRadius) {
            if (this.rx + delta > 0) {
                this.rx += delta;
            }
        } else {
            if (this.ry + delta > 0) {
                this.ry += delta;
            }
        }
    }

    translate(delta: Vector): void {
        const c = new Point(this.cx, this.cy);
        const cn = c.translate(delta);
        this.cx = cn.x;
        this.cy = cn.y;

        if (this.transform && this.transform.rotationCenter) {
            this.transform = this.transform.translateRotationCenter(delta);
        }
    }

    static fromValues(centerPoint: Point, rx: number, ry: number, strokeWidth: number, transform: SvgTransform, stroke: string = "black", fill: string = "none"): SelectableSvgEllipse {
        const result = new SelectableSvgEllipse({entityType: SvgEntityTypesEnum.ellipse, entityId: createRandomString(8), cx: centerPoint.x, cy: centerPoint.y, rx: rx, ry: ry, strokeWidth: strokeWidth, stroke: stroke, fill: fill});
        result.transform = transform;
        return result;
    }
}