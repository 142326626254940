import { Arc } from "./arc.model";
import { GeometricElementType } from "./geometric-element-type.model";
import { GeometricElement } from "./geometric-element.model";
import { Point } from "./point.model";
import { Segment } from "./segment.model";

export class GeometryService {
    /**
     * Utilisé pour connaître l'endroit où se trouve le pointeur sur un SVG path
     * @param items 
     * @param p 
     */
    static getElementsAt(items: GeometricElement[], p: Point, tolerance: number = Number.EPSILON): GeometricElement[] {
        const result: GeometricElement[] = [];

        items.forEach(i => {
            // pour un path il n'y a que deux cas
            switch (i.elementType) {
                case GeometricElementType.Arc:
                    if ((i as Arc).contains(p, tolerance)) result.push(i);
                    break;
                case GeometricElementType.Segment:
                    if ((i as Segment).contains(p, tolerance)) result.push(i);
                    break;
                default:
                    break;
            }
        });

        return result;
    }
}