import { EquipmentCategoryView } from './../views/equipment-category-view';
import { FloorModelView } from './../views/floor-model-view';
import { EquipmentCatalog } from "../tables/equipment-catalog";
import { ProviderCompany } from "../tables/provider-company";

export class InventoryTreeSet {
    floorModelView: FloorModelView[] = []; 
    equipmentCategories: EquipmentCategoryView[] = [];
    equipmentCatalog: EquipmentCatalog[] = [];
    providerCompanies: ProviderCompany[] = [];
    
    constructor(dtoData: any) {
        if (dtoData) {
            dtoData["floor_model_view"].forEach((e: any) => {
                this.floorModelView.push(new FloorModelView(e));
            });
            dtoData["equipment_category_view"].forEach((e: any) => {
                this.equipmentCategories.push(new EquipmentCategoryView(e));
            });
            dtoData["equipment_catalog_view"].forEach((e: any) => {
                this.equipmentCatalog.push(new EquipmentCatalog(e));
            });
            dtoData["provider_company"].forEach((e: any) => {
                this.providerCompanies.push(new ProviderCompany(e));
            });
        }
    }
}