import { TablesNamesEnum } from './../tables-names-enum';
export const FloorCatalogImportItemTable = {
    databaseTableName: TablesNamesEnum.FloorCatalogImportItem,
    flCgImItId: 'FlCgImIt_Id',
    flCgImItImportId: 'FlCgImIt_ImportId',
    flCgImItTempId: 'FlCgImIt_TempId',
    flCgImItCode: 'FlCgImIt_Code',
    flCgImItLayerName: 'FlCgImIt_LayerName',
    flCgImItIsNew: 'FlCgImIt_IsNew',
    flCgImItValidatedCode: 'FlCgImIt_ValidatedCode',
    flCgImItIsValidated: 'FlCgImIt_IsValidated',
    flCgImItFileWeight: 'FlCgImIt_FileWeight',
    flCgImItFilePath: 'FlCgImIt_FilePath',
    flCgImItWidth: 'FlCgImIt_Width',
    flCgImItHeight: 'FlCgImIt_Height'
  };
  