import { Point } from "src/app/core/model/geometry-model/point.model";

export class LayerCommandButtonModel {
    label: string;
    position: Point;
    layerId: number;

    constructor(layerId: number, label: string, position: Point) {
        this.layerId = layerId;
        this.label = label;
        this.position = position;
    }

    buttonMouseDown?: (layerId: number) => void;
    onButtonMouseDown(e: MouseEvent): void {
        e.stopPropagation();
        if (this.buttonMouseDown) {
            this.buttonMouseDown(this.layerId);
        }
    }
}
