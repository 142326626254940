import { SiteActivityStatusDbView } from './../../db-model/views/site-activity-status-db-view';
import { DefinedModelBase } from '../defined-model-base';

export class SiteActivityStatusView extends DefinedModelBase {
    buSiteId!: number;
    roActivityStatusTypeId!: number;
    roAcTyName!: string;
    roAcTyColor!: string;
    roAcTyDisplayNameId!: number;
    roAcTyViDisplayName!: string;
    stAcStViArea!: number;
    stAcStViRoundedArea!: number;
    
    constructor(dtoData: any) {
        super(dtoData, [SiteActivityStatusDbView]);
    }
}