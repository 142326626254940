export enum FloorModelCategoryEnum {
    Background = 1,
    Planning = 2,
    Equipment = 3,
    TechnicalFIM = 4,
    Framing = 9,
    Areas = 16,
    PlanningMisc = 21,
    Structural = 22,
    Compartments = 23,
    Sketching = 25,
    Workplaces = 30,
    Partitioning = 34,
    Annotations = 42,
    Texts = 43,
    Measures = 44
}