import Container from "typedi";
import { LoggerService } from "./logger.service";

export function logInfo(message: string): void {
    const s = Container.get(LoggerService);
    s.log(message);
}

export function logError(message: string): void {
    const s = Container.get(LoggerService);
    s.error(message);
}

export function logWarn(message: string): void {
    const s = Container.get(LoggerService);
    s.warn(message);
}

export function openErrorsGroup(name: string): void {
    const s = Container.get(LoggerService);
    s.group(name);
}

export function closeErrorsGroup(): void {
    const s = Container.get(LoggerService);
    s.groupEnd();
}

export function missingListener(message: string): void {
    const s = Container.get(LoggerService);
    s.missingListener(message);
}