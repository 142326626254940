import { Component, Input } from '@angular/core';
import { GanttSvgTaskVM } from '../../model/gantt-svg-task-vm';
import { SvgGanttTaskLinkComponent } from '../svg-gantt-task-link/svg-gantt-task-link.component';

@Component({
  selector: 'g[xc-svg-gantt-task-shape]',
  standalone: true,
  imports: [SvgGanttTaskLinkComponent],
  templateUrl: './svg-gantt-task-shape.component.svg',
  styleUrls: ['./svg-gantt-task-shape.component.scss']
})
export class SvgGanttTaskShapeComponent {
  @Input() model: GanttSvgTaskVM | undefined;
}
