import { RoomAllocationViewSet } from 'src/app/core/model/data-model/view-set/room-allocation-view-set';
import { BpSvgEntityTypesEnum } from "./bp-svg-entity-type-enum";
import { Room } from 'src/app/core/model/data-model/tables/room';
import { FloorData } from 'src/app/core/model/data-model/tables/floor-data';
import { Floor } from 'src/app/core/model/data-model/tables/floor';
import { Building } from 'src/app/core/model/data-model/tables/building';
import { RoomView } from 'src/app/core/model/data-model/views/room-view';
import { RoomSet } from '../../blueprint-viewer-content-panel/itself/model/room-set';
import { BpSvgPath } from '../../bp-svg-core-model/bp-svg-path';
import { TightBbox } from '../../blueprint-viewer-content-panel/itself/model/similar-room/tight-bbox';

export class BpSvgRoom extends BpSvgPath {
    roomSet: RoomSet | undefined;
    tightBbox: TightBbox | undefined;

    constructor(dtoData: any, group: any) {
        super(dtoData)
        this.entityType = BpSvgEntityTypesEnum.room;

        if(group){
            this.floorModelId = group.floorModelId;
            //this.id = group.id;
            this.floorDataId = group.id;
            this.parentId = group.parentId;
            this.sourceId = group.sourceId;
            this.dataStateId = group.dataStateId;
            this.taskId = group.taskId;
            if (group.tightBbox) {
                this.tightBbox = TightBbox.fromData(group.tightBbox.vertices);
            }
        }
    }

    allocations(): RoomAllocationViewSet[] {
        if (!this.roomSet) return [];
        return this.roomSet.allocationsSet;
    }

    setAllocations(allocations: RoomAllocationViewSet[]): void {
        if (!this.roomSet) return;
        this.roomSet.allocationsSet = allocations;
    }

    room(): (Room & FloorData & Floor & Building & RoomView) {
        if (!this.roomSet) return {} as any;
        return this.roomSet.roomSet.dataSet;
    }

    area(): number {
        const result = this.roomSet?.roomSet.dataSet.roArea;
        return result?? 0;
    }

    setFill(color: string):void {
        this.fill = color;
    }
}