import { ZConstraintTypeEnum } from 'src/app/core/model/data-model/enums/z-constraint-type-enum';
import { ZColumnConstraintView } from 'src/app/core/model/data-model/views/z-column-constraint-view';
import { ZColumnView } from 'src/app/core/model/data-model/views/z-column-view';
import { Field } from './field';
import { FieldListItem } from './field-list-item';
import { FieldTypeEnum } from './field-type-enum';
import { ZDbViewColumnView } from 'src/app/core/model/data-model/views/z-db-view-column-view';

export class ListField<T = number> extends Field<T> {
    selectItems: FieldListItem[] = [];
    selectedItem: FieldListItem | undefined;
    hasImage: boolean = false;

    loadOptionsRequested: () => Promise<FieldListItem[]>;

    constructor(colId: string, rowId: any, value: T, definition: ZColumnView | ZDbViewColumnView, constraints: ZColumnConstraintView[], readOnlyByScope: boolean) {
        super(FieldTypeEnum.list, colId, rowId, value, definition, constraints, readOnlyByScope);

        this.errorsCheck = this.specificErrorCheck;
        //this.loadSelectItems();

        // TODO : renforcer la condition initialement construite sur la contrainte de la colonne EqPu_EquipmentCatalogId
        // Utiliser une constante
        this.hasImage = colId.includes("FileUrl");

        this.loadOptionsRequested = async (): Promise<FieldListItem[]> => {
            return [];
        }
    }

    // loadSelectItems(): void {
    //     const listConstraint = this.constraints.find(x=> x.coCoConstraintType === ZConstraintTypeEnum.List && x.coCoConstraintMode === ZConstraintModeEnum.Request);
    //     if (listConstraint) {
    //         this.selectItems = listConstraint.requestValues;
    //         this.selectedItem = this.selectItems.find(x=> x.id === this.value);
    //     }
    // }

    loadOptions(options: FieldListItem[]): void {
        this.selectItems = options;
        // if (this.selectItems.length > 0) {
        //     this.selectedItem = options[0];
        // }
    }

    loadCurrentValue(value: FieldListItem): void {
        this.selectItems = [value];
        this.selectedItem = value;
}

    specificErrorCheck(): string[] {
        if (!this.allowsNull && (!this.selectedItem || this.selectedItem.id == null)) {
            return [`${this.colId}: Sélection obligatoire`];
        }
        return [];
    }

    selectedItemChange(): void {
        this.value = this.selectedItem?.id as T;
        this.raiseSaveRequestIfNecessary();
        //this.raiseValueChanged();
    }

    async openRequested(): Promise<void> {
        if (this.constraints.find(x=> x.constraintType.coTyId === ZConstraintTypeEnum.Dependency) !== undefined) {
            this.selectItems = await this.loadOptionsRequested();
            this.selectedItem = this.selectItems.find(x=> x.id === this.value);
        }
    }
}