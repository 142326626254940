import { LayoutTypeTreeDataDTO } from "src/app/core/services/backend-services/dto/layout-type-tree-data-dto";
import { ArrayUtils } from "src/app/core/model/static-functions/array-utils";
import { XcMaths } from "src/app/core/model/static-functions/xc-maths";
import { DbModelUtils } from "src/app/core/model/static-functions/db-model-utils";
import { RoomLayoutTypeView } from "src/app/core/model/data-model/views/room-layout-type-view";
import { BuildingLayoutView } from "src/app/core/model/data-model/views/building-layout-view";
import { BuildingLayoutDbView } from "src/app/core/model/db-model/views/building-layout-db-view";
import { BuildingTable } from "src/app/core/model/db-model/tables/building-table";
import { RoomLayoutTypeStatusView } from "src/app/core/model/data-model/views/room-layout-type-status-view";
import { RoomLayoutTypeStatusDbView } from "src/app/core/model/db-model/views/room-layout-type-status-db-view";
import { RoomAttributionTypeTable } from "src/app/core/model/db-model/tables/room-attribution-type-table";
import { RoomActivityStatusTypeTable } from "src/app/core/model/db-model/tables/room-activity-status-type-table";
import { DonutChartDataVM } from "src/app/ui/shared/charts/donut/model/donut-chart-data-vm";
import { DonutChartSliceBuilder } from "src/app/ui/shared/charts/donut/model/donut-chart-slice-builder";
import { DonutChartSliceData } from "src/app/ui/shared/charts/donut/model/donut-chart-slice-data";

export class LayoutTypeChartDataBuilder {
    static loadMapChartData(dto: LayoutTypeTreeDataDTO, parent: RoomLayoutTypeView): DonutChartDataVM {
        // Le graph affiche la répartition du type sélectionné sur les immeubles du parc
        const result: DonutChartSliceData[] = [];
        
        const lts = dto.roomLayoutTypesView;
        const maxDepth = lts.reduce((prev, current) => (prev.roLaTyDepth > current.roLaTyDepth) ? prev : current).roLaTyDepth;

        // Récupère les entrées liées à l'immeuble dans le dataset
        // sachant que le dataset ne contient que des layout types de dernier niveau
        let buildingItems: BuildingLayoutView[] = [];
        if (parent.roLaTyDepth === maxDepth) {
            buildingItems = dto.buildingsLayoutView.filter(x=> x.roLaTyId === parent.roLaTyId); 
        } else {
            if (parent.roLaTyId === 0) {
                buildingItems = dto.buildingsLayoutView;
            } else {
                buildingItems = dto.buildingsLayoutView.filter(x=> x.buLaViLayoutTypeTree.split(';').includes(parent.roLaTyId.toString()));
            }
        }

        // Isole les immeubles
        const buildingIds = ArrayUtils.DistinctValueBy<BuildingLayoutView, number>(buildingItems, DbModelUtils.key(BuildingLayoutDbView, BuildingLayoutDbView.flBuildingId));
        const buildings = dto.buildings.filter(x=> buildingIds.includes(x.buId));
        
        const sumTuples = ArrayUtils.aggegate(buildingItems, buildings, 
            DbModelUtils.key(BuildingTable, BuildingTable.buId), 
            DbModelUtils.key(BuildingLayoutDbView, BuildingLayoutDbView.flBuildingId), 
            DbModelUtils.key(BuildingLayoutDbView, BuildingLayoutDbView.buLaViRoundedArea));
    
        const totalArea = XcMaths.round(buildingItems.reduce((sum, el) => sum += el.buLaViRoundedArea, 0), 2);

        let step = 0;
        sumTuples.forEach(l => {
            const ratio = XcMaths.round(XcMaths.round(l[2], 0) * 100 / totalArea, 2);
            const roundedValue = XcMaths.round(l[2], 0);
            // TODO : générer une palette de couleur répartie sur l'ensemble du spectre
            result.push(new DonutChartSliceData(l[1].buId, 
                l[1].buName, 
                roundedValue, 
                "#" + (roundedValue & 0x00FFFFFF).toString(16).padStart(6, '0'), 
                DonutChartSliceBuilder.getDonutSlicePath(ratio, 1, 0.65), 
                step, 
                ratio,
                l[1].buName,
                `${roundedValue.toLocaleString()} m² - ${ratio}%`));
            step += ratio;
        });

        return new DonutChartDataVM("Cartographie", "Immeubles", result, totalArea);
    }

    static loadAttributionChartData(dto: LayoutTypeTreeDataDTO, parent: RoomLayoutTypeView): DonutChartDataVM {
        // Le graph affiche les attributions du type sélectionné
        const result: DonutChartSliceData[] = [];
        
        const lts = dto.roomLayoutTypesView;
        const maxDepth = lts.reduce((prev, current) => (prev.roLaTyDepth > current.roLaTyDepth) ? prev : current).roLaTyDepth;

        // Récupère les entrées liées aux attributions dans le dataset
        // sachant que le dataset ne contient que des layout types de dernier niveau
        let attributionsItems: RoomLayoutTypeStatusView[] = [];
        if (parent.roLaTyDepth === maxDepth) {
            attributionsItems = dto.layoutTypeStatusView.filter(x=> x.roLayoutTypeId === parent.roLaTyId && x.roLaTyStViStatusTableName === RoomAttributionTypeTable.databaseTableName); 
        } else {
            if (parent.roLaTyId === 0) {
                attributionsItems = dto.layoutTypeStatusView.filter(x=> x.roLaTyStViStatusTableName === RoomAttributionTypeTable.databaseTableName);
            } else {
                attributionsItems = dto.layoutTypeStatusView.filter(x=> x.roLaTyStViLayoutTypeTree.split(';').includes(parent.roLaTyId.toString()) && x.roLaTyStViStatusTableName === RoomAttributionTypeTable.databaseTableName);
            }
        }

        // Isole les attributions
        const attributionsIds = ArrayUtils.DistinctValueBy<RoomLayoutTypeStatusView, number>(attributionsItems, DbModelUtils.key(RoomLayoutTypeStatusDbView, RoomLayoutTypeStatusDbView.roLaTyStViStatusId));
        const attributions = dto.roomAttributionTypes.filter(x=> attributionsIds.includes(x.roAtTyId));
        
        const sumTuples = ArrayUtils.aggegate(attributionsItems, attributions, 
            DbModelUtils.key(RoomAttributionTypeTable, RoomAttributionTypeTable.roAtTyId), 
            DbModelUtils.key(RoomLayoutTypeStatusDbView, RoomLayoutTypeStatusDbView.roLaTyStViStatusId), 
            DbModelUtils.key(RoomLayoutTypeStatusDbView, RoomLayoutTypeStatusDbView.roLaTyStViTotalArea));
    
        const totalArea = XcMaths.round(attributionsItems.reduce((sum, el) => sum += el.roLaTyStViTotalArea, 0), 2);

        let step = 0;
        sumTuples.forEach(l => {
            const ratio = l[2] * 100 / totalArea;
            const roundedRatio = XcMaths.round(ratio, 2);
            const roundedValue = XcMaths.round(l[2], 0);
            result.push(new DonutChartSliceData(l[1].roAtTyId, 
                l[1].roAtTyViDisplayName, 
                l[2], 
                l[1].roAtTyColor, 
                DonutChartSliceBuilder.getDonutSlicePath(ratio, 1, 0.65), 
                step, 
                ratio,
                l[1].roAtTyViDisplayName,
                `${roundedValue.toLocaleString()} m² - ${roundedRatio}%`));
            step += ratio;
        });

        return new DonutChartDataVM("Attributions", "", result, totalArea);
    }

    static loadActivityChartData(dto: LayoutTypeTreeDataDTO, parent: RoomLayoutTypeView): DonutChartDataVM {
        // Le graph affiche le statut d'activité du type sélectionné
        const result: DonutChartSliceData[] = [];
        
        const lts = dto.roomLayoutTypesView;
        const maxDepth = lts.reduce((prev, current) => (prev.roLaTyDepth > current.roLaTyDepth) ? prev : current).roLaTyDepth;

        // Récupère les entrées liées aux statuts d'activité dans le dataset
        // sachant que le dataset ne contient que des layout types de dernier niveau
        let activityItems: RoomLayoutTypeStatusView[] = [];
        if (parent.roLaTyDepth === maxDepth) {
            activityItems = dto.layoutTypeStatusView.filter(x=> x.roLayoutTypeId === parent.roLaTyId && x.roLaTyStViStatusTableName === RoomActivityStatusTypeTable.databaseTableName); 
        } else {
            if (parent.roLaTyId === 0) {
                activityItems = dto.layoutTypeStatusView.filter(x=> x.roLaTyStViStatusTableName === RoomActivityStatusTypeTable.databaseTableName);
            } else {
                activityItems = dto.layoutTypeStatusView.filter(x=> x.roLaTyStViLayoutTypeTree.split(';').includes(parent.roLaTyId.toString()) && x.roLaTyStViStatusTableName === RoomActivityStatusTypeTable.databaseTableName);
            }
        }

        // Isole les statuts d'activité
        const activityIds = ArrayUtils.DistinctValueBy<RoomLayoutTypeStatusView, number>(activityItems, DbModelUtils.key(RoomLayoutTypeStatusDbView, RoomLayoutTypeStatusDbView.roLaTyStViStatusId));
        const activities = dto.roomActivityStatusTypes.filter(x=> activityIds.includes(x.roAcTyId));
        
        const sumTuples = ArrayUtils.aggegate(activityItems, activities, 
            DbModelUtils.key(RoomActivityStatusTypeTable, RoomActivityStatusTypeTable.roAcTyId), 
            DbModelUtils.key(RoomLayoutTypeStatusDbView, RoomLayoutTypeStatusDbView.roLaTyStViStatusId), 
            DbModelUtils.key(RoomLayoutTypeStatusDbView, RoomLayoutTypeStatusDbView.roLaTyStViTotalArea));
    
        const totalArea = XcMaths.round(activityItems.reduce((sum, el) => sum += el.roLaTyStViTotalArea, 0), 2);

        let step = 0;
        sumTuples.forEach(l => {
            const ratio = l[2] * 100 / totalArea;
            const roundedRatio = XcMaths.round(ratio, 2);
            const roundedValue = XcMaths.round(l[2], 0);
            result.push(new DonutChartSliceData(l[1].roAcTyId, 
                l[1].roAcTyViDisplayName, 
                l[2], 
                l[1].roAcTyColor, 
                DonutChartSliceBuilder.getDonutSlicePath(ratio, 1, 0.65), 
                step, 
                ratio,
                l[1].roAcTyViDisplayName,
                `${roundedValue.toLocaleString()} m² - ${roundedRatio}%`));
            step += ratio;
        });

        return new DonutChartDataVM("Activité", "", result, totalArea);
    }
}