import { TablesNamesEnum } from "../tables-names-enum";

export const ZAppMenuTable = {
    databaseTableName: TablesNamesEnum.ZAppMenu,
    zApMeId: "ZApMe_Id", 
    zApMeDisplayNameId: "ZApMe_DisplayNameId", 
    zApMeParentId: "ZApMe_ParentId", 
    zApMeGrantId: "ZApMe_GrantId", 
    zApMeScopeId: "ZApMe_ScopeId", 
    zApMeOrderIndex: "ZApMe_OrderIndex", 
    zApMeDepth: "ZApMe_Depth", 
    zApMeRouteId: "ZApMe_RouteId",
    zApMeLicencing: "ZApMe_Licencing"
}