import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DateField } from 'src/app/components-lib/shared-model/date-field';
import { MaterialModule } from 'src/app/core/material.module';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'xc-date-property',
  standalone: true,
  imports: [CommonModule, MaterialModule, FormsModule],
  templateUrl: './date-property.component.html',
  styleUrls: ['./date-property.component.scss']
})
export class DatePropertyComponent {
  @Input() model: DateField | undefined;
}
