import { PeopleLocationViewSet } from "src/app/core/model/data-model/view-set/people-location-view-set";
import { BpSvgLabel } from "./bp-svg-label";
import { BpSvgRoom } from "./bp-svg-room";
import { BpSvgCountBadge } from "./bp-svg-count-badge";
import { BlueprintSvgEntityTypeEnum } from "./blueprint-svg-entity-type-enum";

export class BpSvgRoomLabel extends BpSvgLabel {
    svgRoom: BpSvgRoom | undefined;
    peopleLocations: PeopleLocationViewSet[] = [];
    countBadge: BpSvgCountBadge | undefined;

    constructor(dtoData: any) {
        super(dtoData, BlueprintSvgEntityTypeEnum.roomLabel);

        this.backgroundSizeChanged = () => {
            this.updateCountBadge();
        }

        this.translated = () => {
            this.updateCountBadge();
        }
    }

    setPeopleLocations(items: PeopleLocationViewSet[]): void {
        this.peopleLocations = items;

        if (this.countBadge) {
            this.entities.splice(this.entities.length - 1);
        }

        if (items.length === 0) {
            this.countBadge = undefined;
        } else {
            this.countBadge = new BpSvgCountBadge(items.length, this.backgroundRectangle.topRight());
            this.entities.push(this.countBadge);
        }
    }

    updateCountBadge(): void {
        if (this.countBadge) {
            this.countBadge.updatePosition(this.backgroundRectangle.topRight());
        }
    }
}
