import { GrantAdminCategoryEnum } from "./grant-admin-category-enum";
import { GrantAdminPageModel } from "./grant-admin-page-model";
import { RoleGrantedPerimeter } from "./role-granted-perimeter";
import { CheckboxChangeEvent } from "primeng/checkbox";
import Container from "typedi";
import { GrantService } from "src/app/core/services/backend-services/grant-service";
import { AppRoleGrant } from "src/app/core/model/data-model/tables/app-role-grant";
import { DyntService } from "src/app/core/services/backend-services/dynt-service";
import { TablesNamesEnum } from "src/app/core/model/db-model/tables-names-enum";
import { AppRoleGrantTable } from "src/app/core/model/db-model/tables/app-role-grant-table";
import { PerimeterView } from "src/app/core/model/data-model/views/perimeter-view";
import { ViewsNames } from 'src/app/core/model/db-model/views-names-enum';
import { PerimeterDbView } from 'src/app/core/model/db-model/views/perimeter-db-view';
import { toastInfo, toastWarn } from 'src/app/core/services/toast-service';

export class PerimetersGrantsVM extends GrantAdminPageModel {
    perimeters: RoleGrantedPerimeter[] = [];
    roleId!: number;
    roleGrants: AppRoleGrant[] = [];

    private constructor() {
        super(GrantAdminCategoryEnum.perimeters);
    }

    static async newAsync(roleId: number): Promise<PerimetersGrantsVM> {
        const result = new PerimetersGrantsVM();
        result.roleId = roleId;
        await result.loadData();
        return result;
    }

    async loadData(): Promise<void> {
        const s = Container.get(DyntService);
        const datas = await s.downloadTable<PerimeterView>(ViewsNames.PerimeterView, undefined, PerimeterDbView.peIsGrantedItem, 1);
        this.perimeters = datas.map(x=> new RoleGrantedPerimeter(x)).sort((a, b) => a.perimeter.peName.localeCompare(b.perimeter.peName));
        this.roleGrants = await s.downloadTable<AppRoleGrant>(TablesNamesEnum.AppRoleGrant, undefined, AppRoleGrantTable.apRoGrRoleId, this.roleId);
        const grantIds = this.roleGrants.map(x=> x.apRoGrGrantId);
        this.perimeters.forEach(p => {
            p.setRoleGrants(grantIds);
        });
    }

    async grantChange(e: CheckboxChangeEvent, perimeter: RoleGrantedPerimeter): Promise<void> {
        const s = Container.get(GrantService);
        let grantId: number = perimeter.perimeter.peReadGrantId!;
        if (e.checked) {
            const result = await s.CreateAppRoleGrant(this.roleId, grantId);
            if (result == null) {
                // TODO : Une erreur s'est produite côté backend, on recharge la liste
            } else {
                toastWarn(`Le rôle a été habilité sur le périmètre ${perimeter.perimeter.peName}`);
            }
        } else {
            const result = await s.deleteRoleGrants(this.roleId, [grantId]);
            if (result == null) {
                // TODO : Une erreur s'est produite côté backend, on recharge la liste
            } else {
                toastInfo(`Le rôle n'est plus habilité sur le périmètre ${perimeter.perimeter.peName}`);
            }
        }
    }
}