<p>Habilitations sur l'édition du plan</p>
@if (model) {
<mat-selection-list #roleGrantSelect>
  <!-- Ne jamais oublier de mettre l'attribut [value] sinon la liste ne s'actualise pas correctement au rechargement -->
    @for (grant of model.allGrants; track grant) {
  <mat-list-option
        [value]="grant"
        (click)="model.toggleGrant(grant)"
        [selected]='grant.selected'>
      {{grant.name}}
    </mat-list-option>
}
</mat-selection-list>
}
  
