import { Component, Input } from '@angular/core';
import { WorkplacesAdminVM } from '../../model/workplaces-admin-vm';
import { MVVMEventDestroyLifeCycle } from 'src/app/components-lib/shared-model/mvvm-events-destroy-lifecycle';
import { DynTableComponent } from 'src/app/components-lib/dyn-table/dyn-table.component';

@Component({
  selector: 'xc-workplaces-admin',
  standalone: true,
  imports: [DynTableComponent],
  templateUrl: './workplaces-admin.component.html',
  styleUrl: './workplaces-admin.component.scss'
})
export class WorkplacesAdminComponent extends MVVMEventDestroyLifeCycle {
  @Input() override model: WorkplacesAdminVM | undefined;
}
