import { PageRouter } from "./router/page-router";
import { EventListener } from "src/app/core/events/event-listener";

export abstract class PageModel extends EventListener {
    route: string
    level: number;
    segments: string [];

    constructor(route: string, level: number, invariantId: string) {
        super(invariantId);

        this.route = route;
        this.level = level;
        this.segments = route.split('/');

        let l = level;
        this.segments.forEach(s => {
            PageRouter.append(s, l);
            l++;
        });
    }
}