import { MatDialog } from "@angular/material/dialog";
import { map, take } from "rxjs";
import { UploadDialogData } from "src/app/components-lib/upload/model/upload-dialog-data";
import { UploadDialogComponent } from "src/app/components-lib/upload/ui/upload-dialog/upload-dialog.component";
import { DxfImport } from "src/app/core/model/data-model/tables/dxf-import";
import { TablesNamesEnum } from "src/app/core/model/db-model/tables-names-enum";
import { DxfImportTable } from "src/app/core/model/db-model/tables/dxf-import-table";
import { ApiEndpoints } from "src/app/core/services/api-endpoints";
import { DyntService } from "src/app/core/services/backend-services/dynt-service";
import { logError } from "src/app/core/services/logging-service";
import Container from "typedi";

export class ImportsPanel {
    dialog: MatDialog | undefined;
    imports: DxfImport[] = [];
    selectedImport: DxfImport | undefined;

    async loadImports(): Promise<void> {
        const s = Container.get(DyntService);
        this.imports = await s.downloadTable<DxfImport>(TablesNamesEnum.DxfImport);
    }

    selectedImportChange?: (selectedImport: DxfImport) => void;
    async onSelectedImportChange(): Promise<void> {
        if (this.selectedImport) {
            this.raiseSelectedImportChanged(this.selectedImport);
        }
    }

    raiseSelectedImportChanged(selectedImport: DxfImport): void {
        if (this.selectedImportChange) {
            this.selectedImportChange(selectedImport);
        } else {
            logError("ImportsPanel.selectedImportChange n'est pas écouté");
        }
    }

    onImportButtonClick(): void {
        if (!this.dialog) return;
        let uploadDialogData = {} as UploadDialogData;

        uploadDialogData.dialogTitle = 'Import d\'un fichier de dessin';
        uploadDialogData.uploadUrl = `${ApiEndpoints.endPoint(ApiEndpoints.dxfToBlueprintSvg)}`;
        uploadDialogData.acceptedFiles = '.dxf, .zip';
        uploadDialogData.closeOnComplete = true;
        uploadDialogData.maxSize = 50 * 1024 * 1024;

        const dialogRef = this.dialog.open(UploadDialogComponent, {
            maxWidth: '90%',
            minWidth: '50%',
            data: uploadDialogData,
        });

            // Le .pipe(take(1)) s'assure que le traitement ne sera exécuté qu'une seule fois
            dialogRef.afterClosed().pipe(take(1)).subscribe((result: any) => {
            if (result?.length > 0) {
                result[0].pipe(
                    map(async (x: any)=> {
                        const importId = x.payload;
                        const t = Container.get(DyntService);
                        const newImport = await t.downloadRow<DxfImport>(TablesNamesEnum.DxfImport, undefined, DxfImportTable.dxImId, importId);
                        if (newImport !== null) {
                            this.imports.push(newImport);
                            this.selectedImport = newImport;
                            this.raiseSelectedImportChanged(this.selectedImport);
                        }
                    })
                ).subscribe();
            }
        });
    }

}