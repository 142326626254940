import { Point } from 'src/app/core/model/geometry-model/point.model';
import { Polygon } from 'src/app/core/model/geometry-model/polygon.model';
import { Segment } from 'src/app/core/model/geometry-model/segment.model';
import { SvgEntityTypesEnum } from 'src/app/core/model/svg-model/svg-entity-type-enum';
import { SvgEntity } from 'src/app/core/model/svg-model/svg-entity.model';
import { BpSvgEntity } from './bp-svg-entity';

export class BpSvgRectangle extends BpSvgEntity {
    x: number = 0;
    y: number = 0;
    width: number = 0;
    height: number = 0;
    rx: number = 0;
    ry: number = 0;

    constructor(dtoData: any) {
        super(dtoData);
        this.x = SvgEntity.getNumber(dtoData, "x");
        this.y = SvgEntity.getNumber(dtoData, "y");
        this.width = SvgEntity.getNumber(dtoData, "width");
        this.height = SvgEntity.getNumber(dtoData, "height");
        this.rx = SvgEntity.getNumber(dtoData, "rx", 0, false);
        this.ry = SvgEntity.getNumber(dtoData, "ry", 0, false);
    }

    position(): Point {
        return new Point(this.x, this.y);
    }

    originCorner(): Point {
        return new Point(this.x, this.y);
    }

    oppositeCorner(): Point {
        return new Point(this.x + this.width, this.y + this.height);
    }

    topRight(): Point {
        return new Point(this.x + this.width, this.y);
    }

    bottomLeft(): Point {
        return new Point(this.x, this.y + this.height);
    }

    center(): Point {
        const diagonal = new Segment(this.originCorner(), this.oppositeCorner());
        return diagonal.midPoint();
    }

    moveTo(newPosition: Point): void {
        this.x = newPosition.x;
        this.y = newPosition.y;
    }

    resize(width: number, height: number): void {
        this.width = width;
        this.height = height;
    }

    polygon(closed: boolean = true): Polygon {
        const p = new Polygon([this.originCorner(), this.topRight(), this.oppositeCorner(), this.bottomLeft()]);
        if (closed) p.close();
        return p;
    }

    static fromValues(x: number, y: number, width: number, height: number): BpSvgRectangle {
        return new BpSvgRectangle({x: x, y: y, width: width, height: height, entityType: SvgEntityTypesEnum.rect});
    }
}
