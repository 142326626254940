import { ZAppMenuRouteTable } from "../../db-model/tables/z-app-menu-route-table";
import { DefinedModelBase } from "../defined-model-base";

export class ZAppMenuRoute extends DefinedModelBase {
    zApMeRoId!: number;
    zApMeRoName!: string;
    zApMeRoDisplayNameId!: number;

    constructor(dtoData: any, tableDef: any[] = []) {
        super(dtoData, [ZAppMenuRouteTable].concat(tableDef));
    }
}