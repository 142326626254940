import { FloorDataStateEnum } from "src/app/core/model/data-model/enums/floor-data-state-enum";
import { BpSvgEntity } from "../../../../bp-svg-core-model/bp-svg-entity";

export class EntitiesSelectionSet<T extends BpSvgEntity = BpSvgEntity> {
    items: T[] = [];
    count: number = 0;
    isCopy: boolean  = false;

    clear(): void {
        this.items.splice(0);
        this.isCopy = false;
        this.raiseSetChanged();
    }

    addOrRemove(items: T[], add: boolean): void {
        // Il n'est pas possible d'ajouter un élément à la sélection si celle-ci est une copie
        if (this.isCopy) return;

        if (add) {
            this.add(items);
        } else {
            this.remove(items);
        }
    }

    single<U extends T>(): U {
        if (this.count > 1) {
            throw "Sélection unitaire incorrecte : le tableau contient plusieurs entités";
        }
        return this.items[0] as U;
    }

    floorDataIds(): number[] {
        return this.items.map(x=> x.floorDataId);
    }

    sourceFloorDataIds(): number[] {
        return this.items.filter(x=> x.sourceId != null).map(x=> x.sourceId!);
    }

    floorDataParentIds(): number[] {
        return this.items.map(x=> x.parentId!);
    }

    private add(items: T[]): void {
        const isDataStateDeleted = this.isDataStateDeleted();

        items.forEach(e => {
            const isDataStateAppropriate = isDataStateDeleted == null || (isDataStateDeleted && e.dataStateId === FloorDataStateEnum.Deleted) || (!isDataStateDeleted && e.dataStateId !== FloorDataStateEnum.Deleted)
            if (this.items.find(x=> x.floorDataId === e.floorDataId) == null && isDataStateAppropriate) {
                this.items.push(e);
            }
        });
        this.raiseSetChanged();
    }

    private remove(items: T[]): void {
        items.forEach(e => {
            const index = this.items.findIndex(x=> x.floorDataId === e.floorDataId);
            this.items.splice(index, 1);
        });
        this.raiseSetChanged();
    }

    // copy(): T[] {
    //     const copy: T[] = [];
    //     for (let item of (this.items)) {
    //         copy.push(item.getCopy() as T);
    //     }

    //     // La copie remplace l'original dans la sélection
    //     this.items = copy;
    //     this.isCopy = true;

    //     return copy;
    // }

    hasHeterogenousDataState(): boolean {
        return [...new Set(this.items.map(x=> x.dataStateId!))].length > 1;
    }

    isDataStateDeleted(): boolean | null {
        if (this.count === 0) return null;

        return this.items[0].dataStateId === FloorDataStateEnum.Deleted;
    }

    setChanged?: () => void;
    private raiseSetChanged(): void {
        this.count = this.items.length;
        if (this.setChanged != null) {
            this.setChanged();
        }
    }
}
