import { BpSvgEntity } from './bp-svg-entity';
import { SvgEntity } from 'src/app/core/model/svg-model/svg-entity.model';
import { Point } from 'src/app/core/model/geometry-model/point.model';
import { Segment } from 'src/app/core/model/geometry-model/segment.model';

export class BpSvgLine extends BpSvgEntity {
    x1: number;
    y1: number;
    x2: number;
    y2: number;

    constructor(dtoData: any) {
        super(dtoData);
        this.x1 = SvgEntity.getNumber(dtoData, "x1");
        this.y1 = SvgEntity.getNumber(dtoData, "y1");
        this.x2 = SvgEntity.getNumber(dtoData, "x2");
        this.y2 = SvgEntity.getNumber(dtoData, "y2");
    }

    
    get startPoint() : Point {
        return new Point(this.x1, this.y1);
    }
    set startPoint(p : Point | undefined) {
        if (p) {
            this.x1 = p.x;
            this.y1 = p.y;
        }
    }

    get endPoint(): Point {
        return new Point(this.x2, this.y2);
    }
    set endPoint(p : Point | undefined) {
        if (p) {
            this.x2 = p.x;
            this.y2 = p.y;
        }
    }

    segment(): Segment {
        return new Segment(this.startPoint, this.endPoint);
    }

    translateStartpointTo(p: Point): void {
        this.x1 = p.x;
        this.y1 = p.y;
    }

    translateEndpointTo(p: Point): void {
        this.x2 = p.x;
        this.y2 = p.y
    }
}