import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StrategyWorkforceVM } from '../../model/strategy-workforce-vm';

@Component({
  selector: 'xc-strategy-workforce',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './strategy-workforce.component.html',
  styleUrls: ['./strategy-workforce.component.scss']
})
export class StrategyWorkforceComponent {
  @Input() model: StrategyWorkforceVM | undefined;
}
