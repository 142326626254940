import { FloorAnatomyDbView } from "../../db-model/views/floor-anatony-db-view";
import { DefinedModelBase } from "../defined-model-base";

export class FloorAnatomyView extends DefinedModelBase {
    buSiteId!: number;
    flBuildingId!: number;
    flId!: number;
    roLaTyId!: number;
    roLaTyCode!: string;
    roLaTyName!: string;
    roLaTyColor!: string;
    roLaTyIsNUA!: boolean;
    roLaTyParentId!: number;
    flAnViArea!: number;
    flAnViRoundedArea!: number;
    roViIsCurrentState!: boolean;

    constructor(dtoData: any) {
        super(dtoData, [FloorAnatomyDbView]);
    }
}