import { Point } from "src/app/core/model/geometry-model/point.model";
import { XcMaths } from "src/app/core/model/static-functions/xc-maths";
import { PathBuilder } from "src/app/core/model/svg-model/svg-path-builder";
import { DonutChartSlicePathVM } from "./donut-chart-slice-path-vm";

export class DonutChartSliceBuilder {
    static getDonutSlicePath(ratio: number, topRadius: number, bottomradius: number): DonutChartSlicePathVM {
        const r = 50; // The pie radius
        const cx = 50; // The pie centerX
        const cy = 50; // The pie centerY
        const trl = r * topRadius; // Top radius to use relative to levels number
        const brl = r * bottomradius; // Bottom radius to use relative to levels number
        if (ratio === 100) ratio = 99.999; // Cas où il n'y a qu'une part. On dégrade le ratio pour permettre le calcul du path
        const angle1Rad = 0;
        const angle2Rad = XcMaths.toRad(360 * ratio / 100);
        // Si le ratio est supérieur à 50%, il faut tracer le grand arc
        const isLargArc = ratio > 50 ? 1 : 0;
        const p1 = new Point(cx + trl * Math.cos(angle1Rad), cy + trl * Math.sin(angle1Rad));
        const p2 = new Point(cx + trl * Math.cos(angle2Rad), cy + trl * Math.sin(angle2Rad));
        const p3 = new Point(cx + brl * Math.cos(angle2Rad), cy + brl * Math.sin(angle2Rad));
        const p4 = new Point(cx + brl * Math.cos(angle1Rad), cy + brl * Math.sin(angle1Rad));
        const regularPath = PathBuilder.getDoughnutSlicePath(p1, p2, p3, p4, trl, brl, isLargArc, 1);

        const trla = trl + 1;
        const p11 = new Point(cx + trla * Math.cos(angle1Rad), cy + trla * Math.sin(angle1Rad));
        const p12 = new Point(cx + trla * Math.cos(angle2Rad), cy + trla * Math.sin(angle2Rad));
        const swollenPath = PathBuilder.getDoughnutSlicePath(p11, p12, p3, p4, trla, brl, isLargArc, 1);
       
        return new DonutChartSlicePathVM(p1, p2, p3, p4, regularPath, swollenPath);
    }
}