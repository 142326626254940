import { TablesNamesEnum } from './../tables-names-enum';
export const EquipmentPlanningTable = {
    databaseTableName: TablesNamesEnum.EquipmentPlanning,
    eqPlFloorDataId: 'EqPl_FloorDataId',
    eqPlEquipmentItemId: 'EqPl_EquipmentItemId',
    eqPlPlanningStateId: 'EqPl_PlanningStateId',
    eqPlMoveCaseTwinId: 'EqPl_MoveCaseTwinId',
    eqPlPurchaseCasePurchaseId: 'EqPl_PurchaseCasePurchaseId',
    eqPlRemoveCaseDeletionCaseId: 'EqPl_RemoveCaseDeletionCaseId',
    eqPlStoreCaseStorageAreaId: 'EqPl_StoreCaseStorageAreaId'
  }
  