import { EquipmentStorageAreaTable } from "../../db-model/tables/equipment-storage-area-table";
import { DefinedModelBase } from "../defined-model-base";

export class EquipmentStorageArea extends DefinedModelBase {
    eqStArId!: number;
    eqStArName!: string;
    eqStArAddressId!: number | null;
    eqStArRoomId!: number | null;
    eqStArScope!: number;

    constructor(dtoData: any, tableDef: any[] = []) {
        super(dtoData, [EquipmentStorageAreaTable].concat(tableDef));
    }
}