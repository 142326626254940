import { Component, Input } from '@angular/core';
import { MobilityProjectsGanttBrowserVM } from '../../model/mobility-projects-gantt-browser-vm';
import { TabViewModule } from 'primeng/tabview';
import { MobilityProjectsGanttTreeComponent } from '../mobility-projects-gantt-tree/mobility-projects-gantt-tree.component';
import { MobilityProjectGanttEditorComponent } from '../mobility-project-gantt-editor/mobility-project-gantt-editor.component';

@Component({
  selector: 'xc-mobility-projects-gantt-browser',
  standalone: true,
  imports: [TabViewModule, MobilityProjectsGanttTreeComponent, MobilityProjectGanttEditorComponent],
  templateUrl: './mobility-projects-gantt-browser.component.html',
  styleUrl: './mobility-projects-gantt-browser.component.scss'
})
export class MobilityProjectsGanttBrowserComponent {
  @Input() model: MobilityProjectsGanttBrowserVM | undefined;
}
