export const BuildingActivityStatusDbView = {
    databaseTableName: "building_activity_status_view",
    buSiteId: "Bu_SiteId",
    flBuildingId: "Fl_BuildingId",
    roActivityStatusTypeId: "Ro_ActivityStatusTypeId", 
    roAcTyName: "RoAcTy_Name",
    roAcTyColor: "RoAcTy_Color", 
    roAcTyDisplayNameId: "RoAcTy_DisplayNameId", 
    roAcTyViDisplayName: "RoAcTyVi_DisplayName", 
    buAcStViArea: "BuAcStVi_Area",
    buAcStViRoundedArea: "BuAcStVi_RoundedArea",
}