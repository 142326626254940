import { Floor } from "src/app/core/model/data-model/tables/floor";
import { TablesNamesEnum } from "src/app/core/model/db-model/tables-names-enum";
import { FloorTable } from "src/app/core/model/db-model/tables/floor-table";
import { DyntService } from "src/app/core/services/backend-services/dynt-service";
import { NestedToolbarBase } from "src/app/ui/main/model/nested-toolbar-base";
import { NestedToolbarTypeEnum } from "src/app/ui/main/model/nested-toolbar-type-enum";
import Container from "typedi";
import { FloorsEventsEnum } from "./floors-events-enum";
import { RealEstateChartTypeEnum } from "../charts/shared-model/real-estate-chart-type-enum";
import { AutoCompleteCompleteEvent, AutoCompleteSelectEvent } from "primeng/autocomplete";
import { SelectButtonOptionClickEvent } from "primeng/selectbutton";
import { RadioButtonClickEvent } from "primeng/radiobutton";
import { BlueprintViewerToolbar } from "../floor-blueprint/content/blueprint-viewer-content-panel/itself/model/blueprint-viewer-toolbar";

export class FloorsToolbarVM extends NestedToolbarBase {
    editorToolbar: BlueprintViewerToolbar | undefined;

    floors: Floor[] = [];
    selectedFloor: Floor | null = null;

    options: {id: RealEstateChartTypeEnum, label: string}[] = [];
    selectedOption: {id: RealEstateChartTypeEnum, label: string};

    chartTypes: {id: string, icon: string}[] = [];
    selectedChartType: {id: string, icon: string} | null;

    private constructor() {
        super(NestedToolbarTypeEnum.floors);
    
        this.options.push({id: RealEstateChartTypeEnum.anatomy, label: "Anatomie"});
        this.options.push({id: RealEstateChartTypeEnum.activities, label: "Activité"});
        this.options.push({id: RealEstateChartTypeEnum.attributions, label: "Attributions"});
        this.options.push({id: RealEstateChartTypeEnum.inventory, label: "Inventaire"});
        this.options.push({id: RealEstateChartTypeEnum.businessunits, label: "Entités"});
        this.options.push({id: RealEstateChartTypeEnum.workforce, label: "Effectifs"});
        this.options.push({id: RealEstateChartTypeEnum.moveLists, label: "Mouvements"});
        this.options.push({id: RealEstateChartTypeEnum.history, label: "Historique"});
        this.options.push({id: RealEstateChartTypeEnum.referential, label: "Référentiel"});
        this.selectedOption = this.options[0];


        this.chartTypes.push({id: "blueprint", icon: "pi pi-align-justify"});
        this.chartTypes.push({id: "dashboard", icon: "pi pi-box"});
        this.selectedChartType = this.chartTypes[0];

        this.addEventListener(FloorsEventsEnum.editorToolbarAvailable, (editorToolbar: any) => {
            this.editorToolbar = editorToolbar;
        });
}

    static async newAsync(floorId?: number): Promise<FloorsToolbarVM> {
        var tmp = new FloorsToolbarVM();
        tmp.emitEventAsync(FloorsEventsEnum.selectedChartChange, tmp.selectedChartType);
        tmp.emitEventAsync(FloorsEventsEnum.selectedOptionChange, tmp.selectedOption);

        if (floorId) {
            await tmp.getFloor(floorId);
        }

        return tmp;
    }

    async getFloor(floorId: number): Promise<void> {
        const s = Container.get(DyntService);
        const result = await s.downloadRow<Floor>(TablesNamesEnum.Floor, undefined, FloorTable.flId, floorId);
        if (result != null) {
            this.selectedFloor = result;
            this.emitEventAsync(FloorsEventsEnum.selectedFloorChange, result);
        }
    }

    timer: number | undefined;
    searchForFloors(value: AutoCompleteCompleteEvent): void {
        clearTimeout(this.timer);
        this.timer = window.setTimeout(async (that: FloorsToolbarVM) => {
            const s = Container.get(DyntService);
            that.floors = await s.search<Floor>(TablesNamesEnum.Floor, value.query, [FloorTable.flName]);
        }, 500, this);
    }

    async onSearchResultSelect(e: AutoCompleteSelectEvent): Promise<void> {
        this.emitEventAsync(FloorsEventsEnum.selectedFloorChange, e.value);
    }

    async onSelectedChartTypeChange(e: SelectButtonOptionClickEvent): Promise<void> {
        this.emitEventAsync(FloorsEventsEnum.selectedChartChange, e.option);
    }
        
    async optionChange(e: RadioButtonClickEvent): Promise<void> {
        this.emitEventAsync(FloorsEventsEnum.selectedOptionChange, e.value);
    }
}