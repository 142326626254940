import { SvgEntityTypesEnum } from './svg-entity-type-enum';
import { SvgEntity } from './svg-entity.model';

export class SvgPath extends SvgEntity {
    d: string | undefined;

    constructor(dtoData: any) {
        super(dtoData);
        this.d = SvgEntity.getValue(dtoData, "d");
    }

    static fromStatement(statement: string): SvgPath {
        return new SvgPath({"d": statement, "entityType": SvgEntityTypesEnum.path})
    }
}
