import { FloorDataStateEnum } from "src/app/core/model/data-model/enums/floor-data-state-enum";
import { BpSvgEntity } from "../../../../bp-svg-core-model/bp-svg-entity";

export class SelectedLayerEntities {
    layerId: number;
    layerName: string;
    svgTagName: string;
    entities: BpSvgEntity[];
    toRemove: boolean;

    constructor(layerId: number, layerName: string, entities: BpSvgEntity[], svgTagName: string, toRemove: boolean) {
        this.layerId = layerId;
        this.layerName = layerName;
        this.entities = entities;
        this.svgTagName = svgTagName;
        this.toRemove = toRemove;
    }

    hasHeterogeneousDataState(): boolean {
        const dataStates = [...new Set(this.entities.map(x=> x.dataStateId))];
        if (dataStates.length > 1 && dataStates.includes(FloorDataStateEnum.Deleted)) {
            return true;
        }

        return false;
    }
}
