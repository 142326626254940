import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TaskCreationFormVM } from '../../model/task-creation-form-vm';
import { FormsModule } from '@angular/forms';
import { MaterialModule } from 'src/app/core/material.module';

@Component({
  selector: 'xc-task-creation-stepper',
  standalone: true,
  imports: [CommonModule, MaterialModule, FormsModule],
  templateUrl: './creation-stepper.component.html',
  styleUrls: ['./creation-stepper.component.scss']
})
export class CreationStepperComponent {
  @Input() model: TaskCreationFormVM | undefined;
}
