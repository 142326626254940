import { FloorModelCategoryView } from './../views/floor-model-category-view';
import { FloorModelView } from './../views/floor-model-view';

export class LayerTreeSet {
    floorModelView: FloorModelView[] = [];
    floorModelCategoryView: FloorModelCategoryView[] = [];

    constructor(dtoData: any) {
        if (dtoData) {
            dtoData.floor_model_view.forEach((e: any) => {
                this.floorModelView.push(new FloorModelView(e));
            });
            dtoData.floor_model_category_view.forEach((e: any) => {
                this.floorModelCategoryView.push(new FloorModelCategoryView(e));
            });
        }
    }
}