<div id="navPanelContainer" [ngClass]="{'isFold': sidePanelIsFold}">
    <div id="navPanel" [class.no-gutter]="!showGutter" [style.width.px]="sidePanelWidth - sidePanelMinWidth"
        [ngClass]="{'resizing': sidePanelResizing}">
        <ng-template contentHost></ng-template>
    </div>

    <div id="navPanelGutter" [class.no-gutter]="!showGutter">
        <!-- Commande d'enroulement du panneau -->
        <div class="gutter-top" [class.no-gutter]="!showGutter">
            <xc-tab-icon class="tab-button" [class.gutter]="showGutter" 
                [matTooltip]="sidePanelIsFold ? tooltip : 'Réduire'" 
                (click)="onPinClick()"/>
        </div>
        @if (tabs) {
<div id="navPanelTabsContainer">
            @for (t of tabs.tabItems; track t) {
  <div class="tab" [ngClass]="{'tab-selected' : t.isSelected}" 
                (click)="tabs.selectedTabClick(t)">
                <span class="tab-label">{{t.contentTitle}}</span>
                <!-- <mat-icon class="tab-icon material-icons-sharp">{{t.iconName}}</mat-icon> -->
                @if (t.displayBadge) {
<div class="data-badge"></div>
}
            </div>
}
        </div>
}
    </div>

    <div id="navPanelResizeHandle"
        [ngClass]="{'isFold': sidePanelIsFold}"
        [style.cursor]="sidePanelIsFold ? 'default' : 'col-resize'" 
        [style.pointer-events]="sidePanelIsFold ? 'none' : 'initial'" 
        [style.width.px]="resizeHandleWidth" 
        (mousedown)="initializeSidenavRezising($event)"
        (mouseup)="onSidenavEndResizing($event)">
    </div>
</div>

@if (sidePanelResizing) {
<div id="overlay"
    (mousemove)="onSidenavResizing($event)" 
    (mouseup)="onSidenavEndResizing($event)">
</div>
}

