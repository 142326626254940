export enum SvgEntityPointStyleEnum {
    none = 0,
    end,
    middle,
    center,
    orthogonal,
    intersection,
    quadrant,
    insert,
    extension,
    nearest,
    plineMiddle
}