import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StackChartDataVM } from '../../model/stack-chart-data-wm';

@Component({
  selector: 'xc-stack-chart',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './stack-chart.component.svg',
  styleUrls: ['./stack-chart.component.scss']
})
export class StackChartComponent implements OnChanges {
  @Input() model: StackChartDataVM | undefined;

  ngOnChanges(changes: SimpleChanges): void {
    if (this.model && this.model instanceof StackChartDataVM) {
      setTimeout( () => {
        // Ce petit délai asynchrone permet l'animation d'entrée
        // Ce n'est pas la durée du délai qui compte mais simplement le fait qu'il soit asynchrone
        // et donc que le traitement soit déclenché après que la vue soit entièrement chargée
        // AfterViewInit ne suffit pas, ni OnChanges sans le timeout
        this.model?.arrange();
      }, 1);
    }
  }
}
