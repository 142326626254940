import { WorkplaceTable } from './../../db-model/tables/workplace-table';
import { DefinedModelBase } from '../defined-model-base';

export class Workplace extends DefinedModelBase {
    woFloorDataId!: number;
    woCode!: string;
    woUsable!: boolean;
    woWorkplaceType!: number;

    constructor(dtoData: any, tableDef: any[] = []) {
        super(dtoData, [WorkplaceTable].concat(tableDef));
    }
}