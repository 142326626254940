import { Point } from "src/app/core/model/geometry-model/point.model";
import { GanttScheduleBaseVM } from "../../shared/model/gantt-schedule-base-vm";

export class TaskLinkingDetection {
    static detectTargetTask(hitPoint: Point, schedule: GanttScheduleBaseVM): void {
        const htmlNodes = document.elementsFromPoint(hitPoint.x, hitPoint.y);

        // La tâche cible d'un lien ne doit pas être la tâche source
        // et se trouver dans le même projet parent
        const taskHandleElement = htmlNodes.find(x=> x.attributes.getNamedItem('task-id') && 
            x.attributes.getNamedItem('task-id')?.value !== schedule.taskLinkingProcess.task?.task.taId.toString() &&
            x.attributes.getNamedItem('task-parent-id')?.value === schedule.taskLinkingProcess.task?.task.taParentId?.toString() &&
            x.classList.contains("link-handle"));
        if (taskHandleElement) {
            // Une poignée de liaison a été trouvée sous le curseur
            const taskId = Number(taskHandleElement.attributes.getNamedItem('task-id')!.value);
            const task = schedule.find(taskId);
            if (task) {
                const targetStartLink = taskHandleElement.classList.contains("link-start-handle")
                schedule.taskLinkingProcess.setTargetTask(task, TaskLinkingDetection.getHandle(taskHandleElement), targetStartLink);
            }
        }
    }

    static getHandle(e: Element): {x: number, y: number, width: number, height: number} | undefined {
        const x = e.attributes.getNamedItem("x")?.value;
        const y = e.attributes.getNamedItem("y")?.value;
        const w = e.attributes.getNamedItem("width")?.value;
        const h = e.attributes.getNamedItem("height")?.value;
        if (x && y && w && h) {
            return {x: Number(x), y: Number(y), width: Number(w), height: Number(h)};
        }
        return undefined;
    }
}