import { ZColumnConstraintView } from "src/app/core/model/data-model/views/z-column-constraint-view";
import { ZColumnView } from "src/app/core/model/data-model/views/z-column-view";
import { Field } from "./field";
import { FieldTypeEnum } from "./field-type-enum";
import { ZDbViewColumnView } from "src/app/core/model/data-model/views/z-db-view-column-view";

export class ColorField extends Field<string> {

    constructor(colId: string, rowId: any, value: string, definition: ZColumnView | ZDbViewColumnView, constraints: ZColumnConstraintView[], readOnlyByScope: boolean) {
        super(FieldTypeEnum.color, colId, rowId, value, definition, constraints, readOnlyByScope);
        
        this.errorsCheck = this.specificErrorsCheck;
    }

    specificErrorsCheck(): string[] {
        // TODO : à compléter avec les contrôles spécifiques aux champs de couleur
        return [];
    }

    click(): void {
        this.editing = true;
    }

    colorChanged(): void {
        this.raiseSaveRequestIfNecessary();
    }
}