import { DxfLayerDTO } from "src/app/core/services/backend-services/dto/dxf-layer-dto";
import { UserInteraction } from "./interaction/user-interaction";
import { DrawingToolbar } from "./drawing-toolbar";
import { DrawingCommandEnum } from "./commands/drawing-command-enum";
import { ToolCommandEnum } from "./commands/tool-command-enum";
import { ToolGridOptionsEnum } from "./commands/tool-grid-options-enum";
import { SvgEntityPointStyleEnum } from "../../shared/gizmos/model/svg-entity-point-style-enum";

export class DrawingBoard {
    userInteraction: UserInteraction;
    boardToolbar: DrawingToolbar;

    constructor() {
        this.userInteraction = new UserInteraction();
        this.boardToolbar = new DrawingToolbar();

        this.boardToolbar.selectedCommandChanged = (selectedCommandId: DrawingCommandEnum) => {
            this.userInteraction.setInsertCommand(selectedCommandId);
        }

        this.boardToolbar.selectedGripStyleChanged = (selectedGripStyleId: SvgEntityPointStyleEnum) => {
            this.userInteraction.currentGripStyle = selectedGripStyleId;
        }

        this.boardToolbar.selectedToolChanged = (toolId: ToolCommandEnum, enabled: boolean) => {
            this.userInteraction.updateGizmos.setTool(toolId, enabled);
        }

        this.boardToolbar.selectedGridOptionChanged = (optionId: ToolGridOptionsEnum) => {
            this.userInteraction.setGridOptionCommand(optionId);
            if (optionId === ToolGridOptionsEnum.size) {
                this.boardToolbar.gridUnitLength = this.userInteraction.updateGizmos.gridGizmo.unitLength;
            }
        }

        this.boardToolbar.gridUnitLengthUpdated = (value: number) => {
            if (value > 0) {
                this.userInteraction.updateGizmos.gridGizmo.unitLength = value;
            }
        }

        this.userInteraction.abortCommandRequested = () => {
            this.boardToolbar.setCommand(DrawingCommandEnum.none);
            this.boardToolbar.setGridOption(ToolGridOptionsEnum.none);
        }

        this.userInteraction.toolbarCommandEnded = () => {
            this.boardToolbar.setCommand(DrawingCommandEnum.none);
            this.boardToolbar.setGridOption(ToolGridOptionsEnum.none);
        }

        this.userInteraction.gripCommandSwitchRequested = () => {
            this.boardToolbar.switchGripStyleCommand();
        }
    }

    async setDrawing(importId: number, extents: string, layers: DxfLayerDTO[]): Promise<void> {
        await this.userInteraction.setDrawing(importId, extents, layers);
    }
}