import { Directory } from './../tables/directory';
import { FloorData } from "../tables/floor-data";
import { PeopleLocation } from "../tables/people-location";
import { getView } from "../views/view-builder";

export class PeopleLocationViewSet {
    dataSet: (PeopleLocation & FloorData & Directory);

    constructor(dtoData: any) {
        this.dataSet = getView(dtoData, PeopleLocation, FloorData, Directory);
    }

    combinedId(): string {
        return `${this.dataSet.peLoFloorDataId}-${this.dataSet.peLoDirectoryId}`;
    }
}