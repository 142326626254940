import { TablesSetsEnum } from "src/app/core/model/data-model/tables-sets-enum";
import { AppRole } from "src/app/core/model/data-model/tables/app-role";
import { Directory } from "src/app/core/model/data-model/tables/directory";
import { Perimeter } from "src/app/core/model/data-model/tables/perimeter";
import { TablesNamesEnum } from "src/app/core/model/db-model/tables-names-enum";
import { DirectoryTable } from "src/app/core/model/db-model/tables/directory-table";
import { DyntService } from "src/app/core/services/backend-services/dynt-service";
import Container from "typedi";

export class CreateUserDialogVM {
    perimeters: Perimeter[] = [];
    appRoles: AppRole[] = [];
    filteredDirectory: Directory[] = [];
    selectedDirectoryItem: Directory | undefined;
    selectedRole: AppRole | undefined;
    selectedPerimeter: Perimeter | undefined;
    selectedEmail: string | undefined;
    selectedEmailIsValid: boolean = false;
    canSave: boolean = false;

    constructor() {
        this.loadRefData();
    }

    async loadRefData(): Promise<void> {
        const s = Container.get(DyntService);
        this.perimeters = await s.downloadTable<Perimeter>(TablesNamesEnum.Perimeter);
        this.appRoles = await s.downloadTable<AppRole>(TablesNamesEnum.AppRole);
    }

    async onSearchChange(e: any): Promise<void> {
        const value = e.target.value;
        if (value === "") {
          this.filteredDirectory = [];
          return;
        }
        const s = Container.get(DyntService);
        this.filteredDirectory = await s.search<Directory>(TablesNamesEnum.Directory, value, [DirectoryTable.diFirstName, DirectoryTable.diLastName]);
    }
    
    
    displayFn(user: Directory): string {
        return user && user.diFirstName ? `${user.diFirstName} ${user.diLastName}` : '';
    }
}