import { RoomLayoutTypeTable } from './../tables/room-layout-type-table';
import { FloorDataTable } from './../tables/floor-data-table';
import { FloorTable } from './../tables/floor-table';
import { BuildingTable } from './../tables/building-table';

export const FloorLayoutDbView = {
  databaseTableName: 'floor_layout_view',
  buSiteId: BuildingTable.buSiteId,
  flBuildingId: FloorTable.flBuildingId,
  flId: FloorTable.flId,
  flDaTaskId: FloorDataTable.flDaTaskId,
  roLaTyId: RoomLayoutTypeTable.roLaTyId,
  roLaTyCode: RoomLayoutTypeTable.roLaTyCode,
  roLaTyName: RoomLayoutTypeTable.roLaTyName,
  roLaTyColor: RoomLayoutTypeTable.roLaTyColor,
  roLaTyIsNUA: RoomLayoutTypeTable.roLaTyIsNUA,
  roLaTyParentId: RoomLayoutTypeTable.roLaTyParentId,
  flLaViArea: 'FlLaVi_Area',
  flLaViRoundedArea: 'FlLaVi_RoundedArea',
  flLaViLayoutTypeTree: 'FlLaVi_LayoutTypeTree',
};
