export enum ViewsNames {
    AppGrantView = "app_grant_view",
    AppGrantGroupView = "app_grant_group_view",
    BuildingView = "building_view",
    BuildingAllocationView = "building_allocation_view",
    BuildingAnatomyView = "building_layout_view",
    BuildingActivityStatusView = "building_activity_status_view",
    BuildingAttributionView = "building_attribution_view",
    BuildingEquipmentProviderView = "building_equipment_provider_view",
    CityView = "city_view",
    ContinentView = "continent_view",
    CountryView = "country_view",
    DirectoryView = "directory_view",
    EquipmentCatalogView = "equipment_catalog_view",
    EquipmentCategoryView = "equipment_category_view",
    EquipmentPlanningStateView = "equipment_planning_state_view",
    EquipmentPlanningTaskView = "equipment_planning_task_view",
    EquipmentPurchaseView = "equipment_purchase_view",
    EquipmentRemovalCaseView = "equipment_removal_case_view",
    EquipmentStorageView = "equipment_storage_view",
    FacilityCompanyTaskView = "facility_company_task_view",
    FloorAnatomyView = "floor_anatomy_view",
    FloorView = "floor_view",
    FloorCatalogImportItemView = "floor_catalog_import_item_view",
    FloorDataStateView = "floor_data_state_view",
    FloorLayoutView = "floor_layout_view",
    FloorModelView = "floor_model_view",
    FloorActivityStatusView = "floor_activity_status_view",
    FloorAllocationView = "floor_allocation_view",
    FloorAttributionView = "floor_attribution_view",
    FloorEquipmentProviderView = "floor_equipment_provider_view",
    LeaseView = "lease_view",
    LeaseStatusView = "lease_status_view",
    PerimeterView = "perimeter_view",
    PerimeterDetailView = "perimeter_detail_view",
    PerimeterTypeView = "perimeter_type_view",
    RealEstateView = "realestate_view",
    RealEstateAnatomyView = "realestate_layout_view",
    RealEstateActivityStatusView = "realestate_activity_status_view",
    RealEstateAttributionView = "realestate_attribution_view",
    RealEstateEquipmentProviderView = "realestate_equipment_provider_view",
    RoomAttributionTypeView = "room_attribution_type_view",
    RoomLayoutTypeView = "room_layout_type_view",
    RoomLayoutTypeStatusView = "room_layout_type_status_view",
    SimulationFloorView = "simulation_floor_view",
    SimulationBuildingView = "simulation_building_view",
    SiteView = "site_view",
    SiteActivityStatusView = "site_activity_status_view",
    SiteAnatomyView = "site_layout_view",
    SiteAttributionView = "site_attribution_view",
    SiteEquipmentProviderView = "site_equipment_provider_view",
    TaskContributorView = "task_contributor_view",
    TaskContributorDirectoryView = "task_contributor_directory_view",
    TaskFacilityView = "task_facility_view",
    TaskFunctionView = "task_function_view",
    TaskFloorModelView = "task_floor_model_view",
    TaskLinkView = "task_link_view",
    TaskStatusView = "task_status_view",
    TaskView = "task_view",
    TaskBusinessTypeView = "task_business_type_view",
    TaskTypeView = "task_type_view",
    WorkplaceTypeView = "workplace_type_view",
    ZAppMenuRouteView = "z_app_menu_route_view",
    ZDbColumnView = "z_column_view",
    ZDbViewColumnView = "z_db_view_column_view",
    ZAppMenuView = "z_app_menu_view",
    ZColumnConstraintView = "z_column_constraint_view",
    ZTableView = "z_table_view",
}