import { ZConstraintModeTable } from './../../db-model/tables/z-constraint-mode-table';
import { DefinedModelBase } from '../defined-model-base';

export class ZConstraintMode extends DefinedModelBase {
    coMoId!: number;
    coMoName!: string;

    constructor(dtoData: any) {
        super(dtoData, [ZConstraintModeTable]);
    }
}