import { buildModel } from "../../../db-model/model-builder";

export class CieUserGrantsView {
    id!: number;
    userId!: string;
    companyId!: string;
    status!: number;
    lastAccess!: Date | null;
    subscriptionstartDate!: Date | null;
    subscriptionendDate!: Date | null;
    subscriptionPlanId!: number;

    static dbMap = {
        databaseTableName: "cie_users_grants_view",
        id: 'id',
        userId: 'userId',
        companyId: 'companyId',
        status: "status",
        lastAccess: "lastAccess",
        subscriptionstartDate: "subscriptionstartDate",
        subscriptionendDate: "subscriptionendDate",
        subscriptionPlanId: "subscriptionPlanId"
    }

    constructor(dtoData: any, tableDef: any[] = []) {
        buildModel(this, dtoData, [CieUserGrantsView.dbMap].concat(tableDef));
    }
}