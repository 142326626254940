import { DefinedModelBase } from '../defined-model-base';
import { RoomLayoutTypeTable } from "../../db-model/tables/room-layout-type-table";

export class RoomLayoutType extends DefinedModelBase {
    roLaTyId!: number;
    roLaTyCode!: string;
    roLaTyDepth!: number;
    roLaTyParentId?: number | null;
    roLaTyIsActive!: boolean;
    roLaTyName!: string;
    roLaTyColor!: string;
    roLaTyScopeId!: number;
    roLaTyIsNUA!: boolean;

    constructor(dtoData: any, tableDef: any[] = []) {
        super(dtoData, [RoomLayoutTypeTable].concat(tableDef))
    }
}