import { DynTableVM } from "src/app/components-lib/dyn-grid/model/dyn-table-vm";
import { TabPageContent } from "src/app/components-lib/tab-page-container/model/tab-page-content";
import { readableUUID } from "src/app/core/events/event-listener-uuid";
import { TabPageContentTypeEnum } from "src/app/ui/pages/layout/real-estate/tab-page-content-type-enum";

export class TableReportWrapper extends TabPageContent<DynTableVM> {

    constructor() {
        super(readableUUID("TableReportWrapper"), TabPageContentTypeEnum.none);
    }
}