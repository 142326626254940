@if (model) {
<div id="graph-table">
    <xc-donut-chart-wrapper class="chart" [model]="model.chart"></xc-donut-chart-wrapper>
    
    <div class="report-table-container">
        <table mat-table [dataSource]="model.reportTable" class="mat-elevation-z1 report-table">
            <!-- Catalog Column -->
            <ng-container matColumnDef="row-header">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element"> {{element.rowHeader}} </td>
            </ng-container>
        
            <!-- Catalog Column -->
            <ng-container matColumnDef="catalog">
                <th mat-header-cell *matHeaderCellDef> Catalogue </th>
                <td mat-cell *matCellDef="let element"> {{element.catalog === -1 ? '-' : element.catalog | number:'1.0-0'}} </td>
            </ng-container>
        
            <!-- Stored Column -->
            <ng-container matColumnDef="stored">
                <th mat-header-cell *matHeaderCellDef> En stock </th>
                <td mat-cell *matCellDef="let element"> {{element.stored | number:'1.0-0'}} </td>
            </ng-container>
        
            <!-- Roomed Column -->
            <ng-container matColumnDef="roomed">
                <th mat-header-cell *matHeaderCellDef> Installé </th>
                <td mat-cell *matCellDef="let element"> {{element.roomed | number:'1.0-0'}} </td>
            </ng-container>
        
            <!-- Removed Column -->
            <ng-container matColumnDef="removed">
                <th mat-header-cell *matHeaderCellDef> Sorties inv. </th>
                <td mat-cell *matCellDef="let element"> {{element.removed | number:'1.0-0'}} </td>
            </ng-container>
            
            <!-- Unidentified Column -->
            <ng-container matColumnDef="unidentified">
                <th mat-header-cell *matHeaderCellDef> A scanner </th>
                <td mat-cell *matCellDef="let element"> {{element.unidentified === -1 ? '-' : element.unidentified | number:'1.0-0'}} </td>
            </ng-container>
        
            <!-- Total Column -->
            <ng-container matColumnDef="total">
                <th mat-header-cell *matHeaderCellDef> Total </th>
                <td mat-cell *matCellDef="let element"> {{element.total | number:'1.0-0'}} </td>
            </ng-container>
        
            <tr mat-header-row *matHeaderRowDef="model.displayedColumns"></tr>
            <tr mat-row 
                (click)="model.onReportRowClick(row)"
                [class.report-row-selected]="row.selected"
                *matRowDef="let row; columns: model.displayedColumns;"></tr>
        </table>
    </div>
</div>
}
