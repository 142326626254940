import { Arc } from "./arc.model";
import { GeometricElementType } from "./geometric-element-type.model";
import { Point } from "./point.model";

export class EllipticalArc extends Arc {
    minorRadius: number;
    override elementType = GeometricElementType.EllipticalArc;

    constructor(startPoint: Point, endPoint: Point, majorRadius: number, minorRadius: number, angle: number, isClockWise: boolean, isLargArc: boolean) {
        super(startPoint, endPoint, majorRadius, angle, isClockWise, isLargArc);
        this.minorRadius = minorRadius  
    }   
}