import { Point } from "src/app/core/model/geometry-model/point.model";
import { Grip } from './grip';

export class Grips {
  static defaultRadius: number = 0.02;
  items: Grip[] = [];
  fill: string = Grip.defaultColor;
  stroke: string = Grip.defaultColor;
  radius: number;

    constructor(fill: string, stroke: string, radius: number = Grips.defaultRadius) {
      this.fill = fill;
      this.stroke = stroke;
      this.radius = radius;
    }

    updateSize(size: number): void {
      this.radius = size / 2;
      this.items.forEach(i => {
        i.radius = this.radius;
      });
    }

    select(point: Point): void {
      this.items.forEach(element => {
        element.select(element.point.equals(point));
      });
    }

    selectNearest(p: Point): void {
      const points = this.items.map(x=> x.point);
      this.select(Point.getNearest(points, p));
    }

    resetPoints(points: Point[], parentId?: string): void {
      this.items.splice(0);
      this.loadFromPoints(points, parentId);
    }

    loadFromPoints(points: Point[], parentId?: string): void {
      const tmp: Grip[] = [];
      let index: number = 0;
      points.forEach(p => {
        if (p != null) {
          const newGrip = new Grip({ entityType: 4, transform: null, d: "", attributes: null}, this.radius, parentId);
          newGrip.fill = this.fill;
          newGrip.stroke = this.stroke;
          newGrip.index = index;
          newGrip.point = p;
          tmp.push(newGrip);
          index ++;
        }
      });
      this.items.push(...tmp);
    }

    clear(): void{
      this.items.splice(0);
    }

    getSelected(): Grip | undefined {
      let result: Grip | undefined;
      for (const g of this.items) {
        if (g.isSelected) {
          result = g;
          break;
        }
      }
      return result;
    }

    getSpecifics(pts: Point[]): Grip[] {
      const result: Grip[] = [];

      pts.forEach(p => {
          const g = this.items.find(x=>x.point.equals(p));
          if (g) {
            result.push(g);
          }
      });

      return result;
  }
}
