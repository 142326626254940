import { Point } from 'src/app/core/model/geometry-model/point.model';
import { Rectangle } from 'src/app/core/model/geometry-model/rectangle.model';
import { DoorStyleEnum } from 'src/app/core/model/data-model/enums/door-style-enum';
import { DoorData } from '../../shared-model/door-data';
import { BpSvgGroup } from '../../bp-svg-core-model/bp-svg-group';
import { BpSvgLine } from '../../bp-svg-core-model/bp-svg-line';
import { BpSvgPath } from '../../bp-svg-core-model/bp-svg-path';
import { BpSvgRectangle } from '../../bp-svg-core-model/bp-svg-rectangle';
import { DoorWallSideOrientationEnum } from './door-wall-side-orientation-enum';
import { Segment } from 'src/app/core/model/geometry-model/segment.model';
import { DoorOpeningDirectionEnum } from '../door-opening-direction-enum';
import { XcMaths } from 'src/app/core/model/static-functions/xc-maths';
import { BlueprintSvgEntityTypeEnum } from './blueprint-svg-entity-type-enum';

export class BpSvgDoor extends BpSvgGroup {
    doorStyle: number | undefined;
    doorData: DoorData;
    insertionPoint: Point | undefined;
    wipeout: BpSvgRectangle;
    frame: BpSvgGroup | undefined;
    opening: BpSvgGroup[] = [];
    contour: string | undefined;
    readonly frameThickness: number = 0.04;
    isTaskZoneBound: boolean = false;

    constructor(dtoData: any) {
        super(dtoData, BlueprintSvgEntityTypeEnum.door);

        if (dtoData.attributes["doorData"]) {
            this.doorData = new DoorData(String(dtoData.attributes["doorData"]));
            this.doorStyle = Number(dtoData.attributes["xcDoorStyle"]);
        } else {
            // Nom de l'attribut retourné par le endpoint des styles
            // TODO : uniformiser en centralisant les noms des attributs
            this.doorData = new DoorData(String(dtoData.attributes["door-data"]));
            // L'attribut xyDoorStyle n'est pas présent dans le dataset des styles
            // Le type id est passé par le constructeur de DoorStyle
        }
        this.insertionPoint = this.transform?.translate;
        this.wipeout = this.entities[0] as BpSvgRectangle;
        this.frame = this.entities[1] as BpSvgGroup;
        this.opening = this.entities.slice(2) as BpSvgGroup[];
        this.contour = this.getContour();
    }

    getContour(): string {
        if (this.doorData.openingAngle > 0 && (this.doorStyle === DoorStyleEnum.SingleSwing
            || this.doorStyle === DoorStyleEnum.SingleSwingDoubleHung)) {
                return this.getSingleOpeningPath();
        }
        
        if (this.doorData.openingAngle > 0 && (this.doorStyle === DoorStyleEnum.DoubleSwing 
            || this.doorStyle === DoorStyleEnum.UnevenSwing)) {
                return this.getDoubleOpeningPath();
        }
        
        if (this.doorData.openingAngle > 0 && (this.doorStyle === DoorStyleEnum.DoubleSwingDoubleHung
            || this.doorStyle === DoorStyleEnum.UnevenSwingDoubleHung)) {
                return this.getDoubleDoubleOpeningPath();
        }

        if (this.doorStyle === DoorStyleEnum.Revolving) {
            return this.getRevolvingPath();
        }

        const r = Rectangle.fromPolygon(this.wipeout.polygon(false));
        return "M" + r.topLeft.x + " " + r.topLeft.y + " " + r.topRight.x + " " + r.topRight.y + " " + r.bottomRight.x + " " + r.bottomRight.y + " " + r.bottomLeft.x + " " + r.bottomLeft.y + "z";
    }

    getSingleOpeningPath(index: number = 0, closed: boolean = true): string {
        const openingFrame = this.opening[index].entities[0] as BpSvgLine;
        const openingShape = this.opening[index].entities[1] as BpSvgPath;
        let path = openingShape.d;
        path = path + " L" + openingFrame.startPoint.x + " " + openingFrame.startPoint.y;
        if (closed) path = path + "z";
        return path;
    }

    getDoubleOpeningPath(): string {
        let result = this.getSingleOpeningPath(0, false);
        const cp = this.closingPoint(false);
        result += " L" + cp.x + " " + cp.y;
        result += " " + this.getSingleOpeningPath(1);
        return result;
    }

    getDoubleDoubleOpeningPath(): string {
        let result = this.getSingleOpeningPath(0, false);
        result += " L0 0";
        result += " " + this.getSingleOpeningPath(1, false);
        result += " L0 0";
        result += this.getSingleOpeningPath(2, false);
        result += " L0 0";
        result += this.getSingleOpeningPath(3);
        return result;
    }

    getRevolvingPath(): string {
        const r = this.doorData.doorWidth / 2;
        return "M0 " + r + "A" + r + " " + r + " 0 0 0 0 " + -r + "A" + r + " " + r + " 0 0 0 0 " + r;
    }

    axis(transformed: boolean = true): Segment {
        const left = new Segment(this.wipeout.originCorner(), this.wipeout.bottomLeft());
        const right = new Segment(this.wipeout.topRight(), this.wipeout.oppositeCorner());

        let result: Segment
        if (this.doorData.openingWallSide === DoorWallSideOrientationEnum.Right) {
            result = new Segment(left.midPoint(), right.midPoint());
        } else {
            result = new Segment(right.midPoint(), left.midPoint());
        }
        if (this.doorData.openingDirection === DoorOpeningDirectionEnum.Left) {
            result = result.inverted();
        }

        if (transformed && this.transform) {
            const t = this.transform;
            return result.transform(t.rotationCenter, XcMaths.toRad(t.rotationAngle), t.translate.vector())
        }
        return result;
    }

    inSide(transformed: boolean = true): Segment {
        if (this.isDoubleHung()) {
            return this.axis(transformed);
        }

        let result: Segment;
        if (this.isBefold() || (!this.isBefold() && this.doorData.openingWallSide === DoorWallSideOrientationEnum.Right)) {
            result = new Segment(this.wipeout.bottomLeft(), this.wipeout.oppositeCorner());
        } else {
            result = new Segment(this.wipeout.topRight(), this.wipeout.originCorner());
        }
        if (this.doorData.openingDirection === DoorOpeningDirectionEnum.Left) {
            result = result.inverted();
        }

        if (transformed && this.transform) {
            const t = this.transform;
            return result.transform(t.rotationCenter, XcMaths.toRad(t.rotationAngle), t.translate.vector())
        }
        return result;
    }

    hingeSide(transformed: boolean = true): Segment {
        let result: Segment;
        result = new Segment(this.wipeout.originCorner(), this.wipeout.bottomLeft());
        if ((this.doorData.openingWallSide === DoorWallSideOrientationEnum.Right && this.doorData.openingDirection === DoorOpeningDirectionEnum.Right) ||
            (this.doorData.openingWallSide === DoorWallSideOrientationEnum.Left && this.doorData.openingDirection === DoorOpeningDirectionEnum.Left)) {
            result = new Segment(this.wipeout.originCorner(), this.wipeout.bottomLeft());
        } else {
            result = new Segment(this.wipeout.oppositeCorner(), this.wipeout.topRight());
        }
        if (this.doorData.openingWallSide === DoorWallSideOrientationEnum.Left) {
            result = result.inverted();
        }

        if (transformed && this.transform) {
            const t = this.transform;
            return result.transform(t.rotationCenter, XcMaths.toRad(t.rotationAngle), t.translate.vector())
        }
        return result;
    }

    hingePoint(transformed: boolean = true): Point {
        const invert = this.doorData.openingWallSide === DoorOpeningDirectionEnum.Left && this.isUneven();
        return this.inSide(transformed).getEndPointAt(this.frameThickness, invert);
    }

    isInOpeningSide(p: Point): boolean {
        const inSide = this.inSide(true);
        const d = this.doorData.openingDirection === DoorOpeningDirectionEnum.Right ? -1 : 1;
        const dd = inSide.getDeterminant(p);
        return Math.sign(d) !== Math.sign(dd);
    }

    openingWidth(): number {
        return this.doorData.doorWidth - (this.frameThickness * 2);
    }

    closingPoint(transformed: boolean = true): Point {
        let result: Point;
        if (this.doorData.leafCount === 2) {
            if (this.isSymetricDoubleSwing()) {
                result = this.inSide(transformed).getEndPointAt((this.doorData.doorWidth / 2) + this.frameThickness, false);
            } else {
                const invert = this.doorData.openingWallSide === DoorOpeningDirectionEnum.Left;
                result = this.inSide(transformed).getEndPointAt((this.doorData.doorWidth * 2 / 3) + this.frameThickness, invert);
            }
        } else {
            result = this.inSide(transformed).getEndPointAt(this.frameThickness, true);
        }

        return result;
    }

    mainLeaf(transformed: boolean = true): Segment | null {
        let result: Segment | null = null;
        if (this.isSwing()) {
            if (this.doorData.openingAngle > 0) {
                if (this.isSimpleSwing()) {
                    result = (this.opening[0].entities[0] as BpSvgLine).segment();
                } else {
                    const l0 = (this.opening[0].entities[0] as BpSvgLine).segment().length();
                    const l1 = (this.opening[1].entities[0] as BpSvgLine).segment().length();
                    if (l0 > l1) {
                        result = (this.opening[0].entities[0] as BpSvgLine).segment();
                    } else {
                        result = (this.opening[1].entities[0] as BpSvgLine).segment();
                    }
                }
            } else {
                // Si la porte est fermée, le battant est confondu avec la face de la cloison
                const inSide = this.inSide(false);
                result = new Segment(inSide.getEndPointAt(this.frameThickness, false), inSide.getEndPointAt(this.frameThickness, true));
            }
        } else {
            // Il s'agit d'une porte accordéon ou coulissante
            // l'équivalent du battant est le segment qui joint la charnière à la poignée
            // donc variable selon le pourcentage d'ouverture
            const inSide = this.inSide(false);
            const offset = this.doorData.doorWidth - this.frameThickness;
            result = new Segment(inSide.getEndPointAt(this.frameThickness, false), inSide.getEndPointAt(this.frameThickness + offset - (this.doorData.openingAngle * this.openingWidth() / 100), false));
        }

        if (transformed && result && this.transform) {
            const t = this.transform;
            return result.transform(t.rotationCenter, XcMaths.toRad(t.rotationAngle), t.translate.vector())
        }
        return result;
    }

    isSwing(): boolean {
        return this.doorStyle === DoorStyleEnum.DoubleSwing || 
            this.doorStyle === DoorStyleEnum.DoubleSwingDoubleHung ||
            this.doorStyle === DoorStyleEnum.SingleSwing ||
            this.doorStyle === DoorStyleEnum.SingleSwingDoubleHung ||
            this.doorStyle === DoorStyleEnum.UnevenSwing ||
            this.doorStyle === DoorStyleEnum.UnevenSwingDoubleHung;
    }

    isSimpleSwing(): boolean {
        return this.doorStyle === DoorStyleEnum.SingleSwing ||
            this.doorStyle === DoorStyleEnum.SingleSwingDoubleHung;
    }

    isBefold(): boolean {
        return this.doorStyle === DoorStyleEnum.SingleBiFold ||
            this.doorStyle === DoorStyleEnum.DoubleBiFold;
    }

    isSymetricDoubleSwing(): boolean {
        return this.doorStyle === DoorStyleEnum.DoubleSwing || 
            this.doorStyle === DoorStyleEnum.DoubleSwingDoubleHung;
    }

    isDoubleHung(): boolean {
        return this.doorStyle === DoorStyleEnum.UnevenSwingDoubleHung || 
            this.doorStyle === DoorStyleEnum.DoubleSwingDoubleHung || 
            this.doorStyle === DoorStyleEnum.SingleSwingDoubleHung;
    }

    isUneven(): boolean {
        return this.doorStyle === DoorStyleEnum.UnevenSwing || 
            this.doorStyle === DoorStyleEnum.UnevenSwingDoubleHung;
    }

    /**
     * Changer le sens d'ouverture par rapport à la cloison n'a de sens que pour les portes battantes
     * dont l'angle d'ouverture est supérieur à zéro
     * @returns true si le sens d'ouverture par rapport à la cloison peut être inversé
     */
    canBeWallInverted(): boolean {
        return this.isSwing() && this.doorData.openingAngle > 0;
    }

    /**
     * Changer le sens d'ouverture de la porte n'a de sens que pour les portes battantes,
     * asymétiques lorsq'elles sont doubles, et dont l'angle d'ouverture est supérieur à zéro
     * @returns true si le sens d'ouverture peut être inversé
     */
    canBeSideInverted(): boolean {
        return  this.doorStyle === DoorStyleEnum.SingleBiFold || this.doorStyle === DoorStyleEnum.SinglePocketSliding || this.doorStyle === DoorStyleEnum.SingleSliding || (this.isSwing() && !this.isSymetricDoubleSwing() && this.doorData.openingAngle > 0);
    }

    /**
     * 
     * @returns true sauf pour les portes tambour
     */
    canBeClosed(): boolean {
        return this.doorStyle !== DoorStyleEnum.Revolving;
    }
}