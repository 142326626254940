@if (model) {
    <div class="flex align-items-center">
        <p-inputGroup class="w-auto">
            <p-inputGroupAddon class="w-auto p-0">
                <i class="pi pi-search"></i>
            </p-inputGroupAddon>
            <p-autoComplete [style]="{'width': 'auto', 'height': '34px'}"
                [(ngModel)]="model.selectedFloor" 
                field="flName"
                placeholder="Etage..."
                [suggestions]="model.floors" 
                (completeMethod)="model.searchForFloors($event)" 
                (onSelect)="model.onSearchResultSelect($event)"/>
        </p-inputGroup>
        <p-selectButton class="ml-3"
            [disabled]="!model.selectedFloor"
            allowEmpty="false"
            [options]="model.chartTypes" 
            [(ngModel)]="model.selectedChartType"
            (onOptionClick)="model.onSelectedChartTypeChange($event)" 
            optionLabel="icon">
            <ng-template let-item pTemplate>
                @if (item.id === 'blueprint') {
                    <div class="flex align-items-center" [style]="{'height': '16px'}">
                        <svg xmlns="http://www.w3.org/2000/svg" 
                            viewBox="0 -960 960 960" 
                            width="24px"
                            fill="#5f6368">
                            <path d="m270-120-10-88 114-314q15 14 32.5 23.5T444-484L334-182l-64 62Zm420 0-64-62-110-302q20-5 37.5-14.5T586-522l114 314-10 88ZM480-520q-50 0-85-35t-35-85q0-39 22.5-69.5T440-752v-88h80v88q35 12 57.5 42.5T600-640q0 50-35 85t-85 35Zm0-80q17 0 28.5-11.5T520-640q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640q0 17 11.5 28.5T480-600Z"/>
                        </svg>
                    </div>
                } @else {
                    <i class="pi pi-chart-pie"></i>
                }
            </ng-template>
        </p-selectButton>
            @switch (model.selectedChartType?.id) {
                @case ("blueprint") {
                    <xc-blueprint-editor-toolbar [model]="model.editorToolbar"/>
                }
                @default {
                    <div class="flex flex-wrap gap-3 ml-3">
                    @for (option of model.options; track $index) {
                        <div class="field-checkbox mb-0">
                            <p-radioButton 
                                [inputId]="option.id" 
                                name="label" 
                                [value]="option" 
                                [(ngModel)]="model.selectedOption" 
                                (onClick)="model.optionChange($event)"/>
                            <label [for]="option.id" class="ml-2">
                                {{ option.label }}
                            </label>
                        </div>
                    }
                    </div>
                }
            }

    </div>
}
