import { SvgEntity } from './svg-entity.model';

export class SvgTspan extends SvgEntity {
    text: string;

    constructor(dtoData: any) {
        super(dtoData);
        this.text = SvgEntity.getValue(dtoData, "text");
    }
}
