import { EditableLayer } from './../../../blueprint-viewer-content-panel/itself/model/interaction/editable-layer';
import { EquipmentListFormVM } from './../../subitems/equipments/model/equipment-list-form-vm';
import { BlueprintService } from 'src/app/core/services/backend-services/blueprint-service';
import Container from 'typedi';
import { FloorBlueprint } from '../../../blueprint-viewer-content-panel/itself/model/floor-blueprint';
import { FloorTasksTabModel } from '../../subitems/floor-task/model/floor-tasks-tab-model';
import { LayersTreeModel } from '../../subitems/layer-tree/model/layers-tree-model';
import { EntitiesFormModel } from '../../subitems/properties/model/entities-form-model';
import { ThemingModel } from '../../subitems/theming/model/theming-model';
import { LabelingFormModel } from '../../subitems/labeling/model/labeling-form-model';
import { TabItem } from 'src/app/components-lib/shared-model/tab-item-model';
import { BlueprintSidePanelTabEnum } from './bp-side-panel-tab.enum';
import { FloorModelEnum } from 'src/app/core/model/data-model/enums/floor-model-enum';
import { EventListener } from 'src/app/core/events/event-listener';
import { Tabs } from 'src/app/components-lib/shared-model/tabs-model';
import { readableUUID } from 'src/app/core/events/event-listener-uuid';
import { ThemeCaptions } from '../../subitems/theming/model/theme-captions';
import { FloorBlueprintEventsEnum } from '../../../../container/model/floor-blueprint-events-enum';

// Zone de contenu des onglets du panneau latéral du blueprint viewer
// Un composant est chargé sur la zone lorsque l'onglet sélectionné change

export class BpeSidePanelContent extends EventListener {
    blueprint: FloorBlueprint | undefined;
    layersTree: LayersTreeModel | undefined;
    floorTasksTabModel: FloorTasksTabModel | undefined;
    themeCaptions: ThemeCaptions | undefined;
    propertiesModel: EntitiesFormModel | undefined;
    equipmentsList: EquipmentListFormVM = new EquipmentListFormVM();
    labelingModel: LabelingFormModel | undefined;
    editableLayers: EditableLayer[] = [];


    constructor(tabsInvariantId: string) {
        super(readableUUID("BpeSidePanelContent"));

        this.floorTasksTabModel = new FloorTasksTabModel();
        
        this.addEventListener(`${tabsInvariantId}.${Tabs.selectedTabChanged}`, async (tab: TabItem): Promise<void> => {
            await this.setContent(tab);
        });

        this.addEventListener(FloorBlueprintEventsEnum.editableLayersChanged, (layers: EditableLayer[]) => {
            this.setEquipmentList(layers);
        });
    }

    async initialize(blueprint: FloorBlueprint): Promise<void> {
        this.blueprint = blueprint;
        this.clearContent();
        //this.listenToBlueprintUpdateTaskChange();
        await this.setThemingModel();

        const l = Container.get(BlueprintService);
        this.layersTree = new LayersTreeModel(this.blueprint, await l.loadFloorModelTree());
        this.addEventListener(LayersTreeModel.layerTreeRefreshRequestEvent, async () => {
            this.layersTree?.set();
        });
}

    setPropertiesForm(form: EntitiesFormModel | undefined): void {
        this.propertiesModel = form;
        if (form) {
            form.themeRefreshRequested = (reloadCaptions: boolean) => {
                this.emitEventAsync(ThemingModel.refreshThemeNeeded, reloadCaptions)
            }
        }
    }

    setEquipmentList(editableLayers: EditableLayer[]): void {
        this.editableLayers = editableLayers;
        this.equipmentsList.setEditableLayers(editableLayers);
        if (this.labelingModel) {
            this.labelingModel.canUpdatePartition = (this.editableLayers.find(x=> x.layerId === FloorModelEnum.Walls) != undefined);
        }
}

    // listenToBlueprintUpdateTaskChange(): void {
    //     if (!this.blueprint) return;
    //     this.blueprint.updateTaskChange = async (task: FloorTask, removed: boolean) => {
    //         if (this.layersTree) {
    //             if (removed) {
    //                 this.layersTree.loadLayerTree();
    //             } else {
    //                 this.layersTree.injectTaskLayers(task);
    //             }
    //         }
    //         if (this.labelingModel) {
    //             this.labelingModel.hasPlanningTask = this.blueprint!.hasPlanningTask();
    //         }
    //         //await this.themeCaptions?.loadOrUpdate();
    //     }
    // }

    clearContent(): void {
        this.layersTree = undefined;
        this.floorTasksTabModel?.clear();
        this.themeCaptions = undefined;
        this.labelingModel = undefined;
    }

    async setThemingModel(): Promise<void> {
        if (!this.themeCaptions && this.blueprint) {
            this.themeCaptions = new ThemeCaptions(this.blueprint);
        }
    }

    /**
     * Déclenche le chargement du contenu de l'onglet sur la zone d'affichage
     * @param tab Onglet sélectionné
     * @returns void
     */
    async setContent(tab: TabItem): Promise<void> {
        if (!this.blueprint) return ;

        switch (tab.index) {
            case BlueprintSidePanelTabEnum.tasks:
                
                //await this.loadTasksTab();
                break;
            case BlueprintSidePanelTabEnum.masterPlan:
                break;
            case BlueprintSidePanelTabEnum.layers:
                // if (!this.layersTree) {
                //     const l = Container.get(BlueprintService);
                //     this.layersTree = new LayersTreeModel(this.blueprint, await l.loadFloorModelTree());
                //     this.addEventListener(LayersTreeModel.layerTreeRefreshRequestEvent, async () => {
                //         this.layersTree?.set();
                //     });
                // }
                break;
            case BlueprintSidePanelTabEnum.displayThemes:
                this.setThemingModel();
                break;
            case BlueprintSidePanelTabEnum.properties:
                // if (this.propertiesModel && this.propertiesModel instanceof RoomFormVM) {
                //     this.propertiesModel.directTaskCreated = (taskId: number) => {
                //         this.raiseEvent(this.directTaskCreated, taskId);
                //     }
                // }
                break;
            case BlueprintSidePanelTabEnum.equipments:
                await this.equipmentsList.loadProviders();
                break;
            case BlueprintSidePanelTabEnum.labeling:
                if (!this.labelingModel) {
                    this.labelingModel = new LabelingFormModel();
                    this.labelingModel.hasPlanningTask = this.blueprint.hasPlanningTask();
                    // this.labelingModel.roomInsertRequest = (code: string) => {
                    //     this.raiseEvent(this.roomInsertRequested, code);
                    // }
                    // this.labelingModel.workplaceInsertRequest = (labelingForm: LabelingFormModel) => {
                    //     this.raiseEvent(this.workplaceInsertRequested, labelingForm);
                    // }
                    // this.labelingModel.workplaceRenumberRequest = (labelingForm: LabelingFormModel) => {
                    //     this.raiseEvent(this.workplaceRenumberRequested, labelingForm);
                    // }
                    // this.labelingModel.measurementInsertRequest = () => {
                    //     this.raiseEvent(this.measurementInsertRequested);
                    // }
                    // this.labelingModel.labelTypeChange = (layerId: number) => {
                    //     this.raiseEvent(this.labelTypeSelectionChange, layerId);
                    // }
                }
                break;
        }
    }

    //blueprintRefreshRequested?: () => void;
    // async loadTasksTab(taskId: number | null = null): Promise<void> {
    //     if (!this.floorTasksTabModel) {
    //         this.floorTasksTabModel = new FloorTasksTabModel();
    //         //await this.floorTasksTabModel.initializeContent(this.blueprint!.floorId);
    //         // this.floorTasksTabModel.selectedTaskChanged = (task: FloorTask | null) => {
    //         //     this.raiseEvent(this.selectedTaskChange, task);
    //         // }
    //         // this.floorTasksTabModel.planningTaskValidated = async () => {
    //         //     // L'étude a été validée, on la retire du plan et on recharge la liste des études
    //         //     this.raiseEvent(this.blueprintRefreshRequested);
    //         // }
    //         // this.floorTasksTabModel.planningTaskVariantLoadRequested = (variantTaskId: number) => {
    //         //     console.log("BpeSidePanelContent.loadTasksTab : Traitement non défini")
    //         // }
    //         // this.floorTasksTabModel.planningTaskRoomExtensionRequested = () => {
    //         //     console.log("BpeSidePanelContent.loadTasksTab : Traitement non défini")
    //         // }
    //         // if (taskId != null) {
    //         //     this.floorTasksTabModel.selectTask(taskId);
    //         // }
    //     }
    // }
}