import { SvgEntityTypesEnum } from "src/app/core/model/svg-model/svg-entity-type-enum";
import { BpSvgGroup } from "../../bp-svg-core-model/bp-svg-group";
import { BpSvgRectangle } from "../../bp-svg-core-model/bp-svg-rectangle";
import { BpSvgText } from "../../bp-svg-core-model/bp-svg-text";
import { Point } from "src/app/core/model/geometry-model/point.model";
import { BlueprintSvgEntityTypeEnum } from "./blueprint-svg-entity-type-enum";

export class BpSvgCountBadge extends BpSvgGroup {
    background!: BpSvgRectangle;
    text!: BpSvgText;
    value: number = 0;

    constructor(value: number, position: Point) {
        super({entityType: SvgEntityTypesEnum.g}, BlueprintSvgEntityTypeEnum.countBadge);
        this.value = value;
        this.setBadge(position);
    }

    setBadge(position: Point): void {
        const digits = this.value.toString().length;
        const r = 0.15 / digits;
        this.background = this.setBackground(position, r);
        this.text = this.setText(position);
    }

    private setBackground(position: Point, r: number): BpSvgRectangle {
        const result =  new BpSvgRectangle({entityType: SvgEntityTypesEnum.rect, "x": position.x - 0.15, "y": position.y - 0.25, "rx": r, "ry": r, "width": 0.3, "height": 0.3, "fill": "#adff2f", "stroke": "#8fdf1d"});
        this.entities.push(result);
        return result
    }

    private setText(position: Point): BpSvgText {
        const result = new BpSvgText({entityType: SvgEntityTypesEnum.text, "x": position.x, "y": position.y, "textAnchor": "middle", "fontSize": 0.2, "text": this.value.toString()});
        this.entities.push(result);
        return result;
    }

    updatePosition(pos: Point): void {
        this.background.moveTo(new Point(pos.x - 0.15, pos.y - 0.25));
        this.text.moveTo(new Point(pos.x, pos.y));
    }

}