import { DyntService } from 'src/app/core/services/backend-services/dynt-service';
import { Container } from 'typedi';
import { FormsModule } from '@angular/forms';
import { MaterialModule } from 'src/app/core/material.module';
import { Component, Inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ArrayUtils } from 'src/app/core/model/static-functions/array-utils';
import { ViewsNames } from 'src/app/core/model/db-model/views-names-enum';
import { TablesSetsEnum } from 'src/app/core/model/data-model/tables-sets-enum';
import { DirectoryViewSet } from 'src/app/core/model/data-model/view-set/directory-view-set';
import { WorkplaceAllocationViewSet } from 'src/app/core/model/data-model/view-set/workplace-allocation-view-set';
import { DirectoryTable } from 'src/app/core/model/db-model/tables/directory-table';
import { PeopleLocationDisplay } from '../../model/people-location-display';

@Component({
  selector: 'xc-workplace-allocations-editor',
  standalone: true,
  imports: [CommonModule, MaterialModule, FormsModule],
  templateUrl: './workplace-allocations-editor.component.html',
  styleUrls: ['./workplace-allocations-editor.component.scss']
})
export class WorkplaceAllocationsEditorComponent implements OnInit {
  filteredDirectory: DirectoryViewSet[] = [];
  displayAllocations: PeopleLocationDisplay[] = [];
  canSave: boolean = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: { workplaceId: number, currentAllocations: WorkplaceAllocationViewSet[], useRatio: false, title: string }, 
    public dialogRef: MatDialogRef<WorkplaceAllocationsEditorComponent>) { }

  async ngOnInit(): Promise<void> {
    if (this.data.currentAllocations) {
      this.data.currentAllocations.forEach(async a => {
        this.displayAllocations.push(await this.getDisplayCurrentAllocation(a));
      });
    }
  }

  async getDisplayCurrentAllocation(a: WorkplaceAllocationViewSet): Promise<PeopleLocationDisplay> {
    const s = Container.get(DyntService);
    const p = await s.downloadRow<DirectoryViewSet>(ViewsNames.DirectoryView, TablesSetsEnum.DirectoryViewSet, DirectoryTable.diId, a.dataSet.peLoDirectoryId);
    let title = "NA";
    let firstName = "NA";
    let lastName = "NA";
    let occupancyRatio = 1;
    if (p != null) {
      title = p.dataSet.diTitle;
      firstName = p.dataSet.diFirstName;
      lastName = p.dataSet.diLastName;
      occupancyRatio = p.dataSet.diOccupancyRatio;
    }
    return { 
      directoryId: a.dataSet.peLoDirectoryId,
      floorDataId: a.dataSet.peLoFloorDataId,
  
      name: title + " " + firstName + " " + lastName,
      ratio: a.dataSet.peLoRatio,
      maxOccupancy: occupancyRatio * 100,
      hasError:  false
    }
  }

  getDisplayNewAllocation(workplaceFloorDataId: number, d: DirectoryViewSet): PeopleLocationDisplay {
    let ratio = d.dataSet.diOccupancyRatio * 100;
    if (this.data.useRatio) {
      ratio = Math.min(100 - this.totalRatio(), ratio);
    }

    return { 
      directoryId: d.dataSet.diId,
      floorDataId: workplaceFloorDataId,
      name: d.dataSet.diTitle + " " + d.dataSet.diFirstName + " " + d.dataSet.diLastName,
      ratio: ratio,
      maxOccupancy: d.dataSet.diOccupancyRatio * 100,
      hasError:  false
    }
  }

  hasChanges(): boolean {
    if (this.data.currentAllocations) {
      for (const a of this.data.currentAllocations) {
        const d = this.displayAllocations.find(x=> x.directoryId === a.dataSet.peLoDirectoryId);
        // Cas où une allocation a été supprimée
        if (d == null) return true;
  
        // Cas où une allocation a été modidfiée
        if (d.ratio !== a.dataSet.peLoRatio) return true;
      }
    }

    for (const d of this.displayAllocations) {
      const a = this.data.currentAllocations?.find(x=> x.dataSet.peLoDirectoryId === d.directoryId);
      // Cas où une allocation a été ajoutée
      if (a == null) return true;
    }

    // Aucun changement dans les allocations
    return false;
  }

  contains(item: DirectoryViewSet): boolean {
    return this.displayAllocations.find(x=> x.directoryId === item.dataSet.diId) != null;
  }

  totalRatio(): number {
    return ArrayUtils.sumBy(this.displayAllocations, "ratio");
  }

  // totalOthersRatio(meId: number): number {
  //   const others = this.displayAllocations.filter(x=> x.floorDataId === meId);
  //   return ArrayUtils.sumBy(others, "ratio");
  // }

  updateCanSave(): void {
    const hasError = this.displayAllocations.filter(x=> x.hasError === true).length > 0;
    this.canSave = this.data.useRatio ? this.totalRatio() <= 100 && this.hasChanges() && !hasError : true;
  }

  rateChange(e: number, a: PeopleLocationDisplay) {
    // Se produit lorsqu'on saisit le séparateur décimal
    if (e == null) return;
    
    if (e > 0 && e <= a.maxOccupancy) {
      a.hasError = false;
    } else {
      a.hasError = true;
    }

    this.updateCanSave();
  }

  async onSearchChange(e: any): Promise<void> {
    const value = e.target.value;
    if (value === "") {
      this.filteredDirectory = [];
      return;
    }
    const s = Container.get(DyntService);
    //this.filteredDirectory = this.data.directory.filter(x=> x.dataSet.diLastName.toLowerCase().indexOf(value) === 0);
    this.filteredDirectory = await s.search<DirectoryViewSet>(ViewsNames.DirectoryView, value, [DirectoryTable.diLastName], TablesSetsEnum.DirectoryViewSet);
  }

  addPeopleClick(e: MouseEvent, d: DirectoryViewSet) {
    const totalRatio = this.totalRatio();
    if (this.data.useRatio && totalRatio === 100) return;

    // La personne ne peut pas occuper la position de travail au delà de son propre temps de présence dans l'entreprise
    const newDisplayAllocation = this.getDisplayNewAllocation(this.data.workplaceId, d);
    if (newDisplayAllocation) {
      this.displayAllocations.push(newDisplayAllocation);
    }

    this.updateCanSave();
  }

  deletePeopleClick(e: MouseEvent, n: PeopleLocationDisplay) {
    const index = this.displayAllocations.indexOf(n);
    this.displayAllocations.splice(index, 1);

    this.updateCanSave();
  }

  save() {
    this.dialogRef.close(this.displayAllocations);
  }

  cancel() {
    this.dialogRef.close();
  }
}