import { AddressTable } from '../../db-model/tables/address-table';
import { DefinedModelBase } from '../defined-model-base';

export class Address extends DefinedModelBase {
    adId!: number;
    adName!: string;
    adStreetNumber!: string | null;
    adLine1!: string;
    adLine2!: string | null;
    adLine3!: string | null;
    adCityId!: number;
    adCounty!: string | null;
    adZipCode!: string | null;
    adScope!: number;

    constructor(dtoData: any, tableDef: any[] = []) {
        super(dtoData, [AddressTable].concat(tableDef));
    }
}