import { Component, Input } from '@angular/core';
import { BoardToolbarVM } from '../../model/board-toolbar-vm';
import { FormsModule } from '@angular/forms';
import { GripsOptionsSelectorComponent } from '../../../../../shared/drawing/grips-options-selector/grips-options-selector.component';
import { InsertOptionsSelectorComponent } from '../../commands/view/insert-options-selector/insert-options-selector.component';

@Component({
  selector: 'xc-board-toolbar',
  standalone: true,
  imports: [FormsModule, GripsOptionsSelectorComponent, InsertOptionsSelectorComponent],
  templateUrl: './board-toolbar.component.html',
  styleUrls: ['./board-toolbar.component.scss']
})
export class BoardToolbarComponent {
  @Input() model: BoardToolbarVM | undefined;
}
