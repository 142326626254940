import { EventListener } from "src/app/core/events/event-listener";
import { Point } from "../../geometry-model/point.model";

export abstract class SvgDOMBase extends EventListener {
    private svgElementId: string;

    constructor(svgElementId: string, invariantId: string) {
        super(invariantId);
        
        this.svgElementId = svgElementId;
    }

    getSvgSvgElement(): SVGSVGElement | undefined {

        let result: SVGSVGElement | undefined = undefined;
        if (this.svgElementId) {
            const temp = document.getElementById(this.svgElementId);
            if (temp !== null) {
                result = temp as unknown as SVGSVGElement;
            }
        } 
        return result;
    }

    getSvgPixelUnit(): number {
        const p0 = this.getPointPosition(0, 0);
        const p1 = this.getPointPosition(1, 0);
        if (p0 && p1) {
            return p1.x - p0.x;
        }
        return 1;
}

    getPointPosition(x: number, y: number): Point | undefined {
        const svgElement = this.getSvgSvgElement();
        if(svgElement) {
            const ctm = svgElement.getScreenCTM();
            if(ctm) {
                return new Point((x - ctm.e) / ctm.a, (y - ctm.f) / ctm.d);
            }
        }
        return undefined;
    }

    // getSvgBbox(htmlBbox: DOMRect): SVGRect | undefined {
    //     const svgElement = this.getSvgSvgElement();
    //     if(svgElement) {
    //         const ctm = svgElement.getScreenCTM();
    //         if(ctm) {
    //             const x = (htmlBbox.x - ctm.e) / ctm.a;
    //             const y = (htmlBbox.y - ctm.f) / ctm.d;
    //             const w = (htmlBbox.width - ctm.e) / ctm.a;
    //             const h = (htmlBbox.height - ctm.f) / ctm.d;
    //             return new SVGRect(x, y, w, h);
    //         }
    //     }
    //     return undefined;
    // }

    // getSvgRelativeBbox(elementId: string): SVGRect | undefined {
    //     const temp = document.getElementById(elementId);
    //     if (temp !== null) {
    //         const htmlBbox = temp.getBoundingClientRect();
    //         return this.getSvgBbox(htmlBbox);
    //         //return (temp as unknown as SVGGraphicsElement).getBBox();
    //     }
    //     return undefined;
    // }

    getClientPointFromSvgPoint(p: Point): Point | null {
        const svgElement = this.getSvgSvgElement();

        if (svgElement === undefined) return null;

        let pt = svgElement.createSVGPoint();
        pt.x = p.x;
        pt.y = p.y;
        const m = svgElement.getScreenCTM();
        if (m) {
            const domPoint = pt.matrixTransform(m);
            return new Point(domPoint.x, domPoint.y);
        }
        return null;
    }

    getSvgBbox(elementId: string): SVGRect | undefined {
        const tmp = document.getElementById(elementId);
        if (tmp != null) {
            const element = tmp as unknown as SVGSVGElement;
            return element.getBBox();
        }
        return undefined;
    }
}