@if (model) {
    <p-splitter [panelSizes]="[25, 75]" [style]="{ height: '100%', width: '100%' }" styleClass="mb-5">
        <ng-template pTemplate>
            <div>
                <xc-layout-types-tree [model]="model.tree"/>
            </div>
        </ng-template>
        <ng-template pTemplate>
            <div class="col flex align-items-center justify-content-center">
                <xc-layout-type-content [model]="model.content"/>
            </div>
        </ng-template>
    </p-splitter>
}