import { Arc } from "./arc.model";
import { GeometricElementType } from "./geometric-element-type.model";
import { GeometricElement } from "./geometric-element.model";
import { Point } from "./point.model";
import { Segment } from "./segment.model";

export function getGeometry(dtoData: any): GeometricElement[] {
    let tmp = new Array<GeometricElement>;

    dtoData.forEach((element: any) => {
        switch(element.elementType) {
            case GeometricElementType.Arc:
                const a = Arc.loadFromDto(element);
                if (a) {
                    tmp.push(a);
                }
                break;
            case GeometricElementType.Segment:
                const s = Segment.fromDto(element);
                if (s) {
                    tmp.push(s);
                }
                break;
            default:
                break;
        }
    });

    return tmp;
}

export function getEndPoints(items: GeometricElement[]): Point[] {
    const tmp: Point[] = [];

    items.forEach(element => {
        switch(element.elementType){
            case GeometricElementType.Arc:
                const arc = element as Arc;
                tmp.push(arc.startPoint);
                tmp.push(arc.endPoint);
                break;
            case GeometricElementType.Segment:
                const segment = element as Segment;
                tmp.push(segment.startPoint);
                tmp.push(segment.endPoint);
                break;
            default:
                break;
        }
    });

    // Retourne le tableau disctinct
    let result = tmp.reduce((prev, p) =>{
        if(prev.some(p1 => p1.x == p.x && p1.y == p.y))
             return prev;
        return [...prev, p]
    }, [] as Point[]);

    return result;
}

export function getMiddlePoints(items: GeometricElement[]): Point[] {
    const tmp: Point[] = [];

    items.forEach(element => {
        switch(element.elementType){
            case GeometricElementType.Arc:
                const arc = element as Arc;
                tmp.push(arc.midPoint());
                break;
            case GeometricElementType.Segment:
                const segment = element as Segment;
                tmp.push(segment.midPoint());
                break;
            default:
                break;
        }
    });

    // Retourne le tableau disctinct
    let result = tmp.reduce((prev, p) =>{
        if(prev.some(p1 => p1.x == p.x && p1.y == p.y))
             return prev;
        return [...prev, p]
    }, [] as Point[]);

    return result;
}

export function getSegments(items: GeometricElement[]): Segment[] {
    return items.filter(x=>x.elementType === GeometricElementType.Segment) as Segment[];
}
