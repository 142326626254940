import { Field } from './field';
import { FieldTypeEnum } from './field-type-enum';
import { ZColumnView } from 'src/app/core/model/data-model/views/z-column-view';
import { ZColumnConstraintView } from 'src/app/core/model/data-model/views/z-column-constraint-view';

export class BooleanField extends Field<boolean> {

    constructor(colId: string, rowId: any, value: boolean, definition: ZColumnView, constraints: ZColumnConstraintView[], readOnlyByScope: boolean) {
        super(FieldTypeEnum.boolean, colId, rowId, value, definition, constraints, readOnlyByScope);
        
        this.errorsCheck = this.specificErrorsCheck;
    }

    change(): void {
        //this.value = !this.value;
        this.raiseSaveRequestIfNecessary();
    }

    specificErrorsCheck(): string[] {
        return [];
    }
}