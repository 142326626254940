import { Simulation } from "src/app/core/model/data-model/tables/simulation";
import { SimulationPhoto } from "src/app/core/model/data-model/tables/simulation-photo";
import { SimulationPhotoTable } from "src/app/core/model/db-model/tables/simulation-photo-table";
import { SimulationTable } from "src/app/core/model/db-model/tables/simulation-table";

export class SimulationTimelinesDTO extends Simulation {
    timelinesCount!: number;
    photos: SimulationPhoto[] = [];
    variants: SimulationTimelinesDTO[] = [];

    constructor(dtoData: any) {
        super(dtoData);
        if (dtoData["TimelinesCount"]) {
            this.timelinesCount = dtoData["TimelinesCount"];
        }
        if (dtoData[SimulationPhotoTable.databaseTableName]) {
            dtoData[SimulationPhotoTable.databaseTableName].forEach((p: any) => {
                this.photos.push(new SimulationPhoto(p));
            });
        }
        if (dtoData[SimulationTable.databaseTableName]) {
            dtoData[SimulationTable.databaseTableName].forEach((s: any) => {
                this.variants.push(new SimulationTimelinesDTO(s));
            });
        }
    }
}