export class CounterResult {
    initialValue: string;
    resultValue: string;
    resultType: number;
    valueType: number;
    resultError: number;

    constructor(dtoData: any) {
        this.initialValue = dtoData.initialValue;
        this.resultValue = dtoData.resultValue;
        this.resultType = dtoData.resultType;
        this.valueType = dtoData.valueType;
        this.resultError = dtoData.resultError;
    }
}
