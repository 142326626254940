import { ZDbViewTable } from './../../db-model/tables/z-db-view-table';
import { DefinedModelBase } from "../defined-model-base";

export class ZDbView extends DefinedModelBase {
    dbViId!: number;
    dbViName!: string;
    dbViScope!: number;
    dbViDefaultSortKey!: string;
    dbViPrimaryFilterKey!: string | null;
    dbViTableTypeId!: number;
    dbViMainTableName!: string;

    constructor(dtoData: any) {
        super(dtoData, [ZDbViewTable]);
    }
}