export enum DoorStyleEnum {
    SingleSwing = 1,
    DoubleSwing = 2,
    UnevenSwing = 3,
    SingleSwingDoubleHung = 4,
    DoubleSwingDoubleHung = 5,
    UnevenSwingDoubleHung = 6,
    SingleSliding = 7,
    DoubleSliding = 8,
    SinglePocketSliding = 9,
    DoublePocketSliding = 10,
    SingleBiFold = 11,
    DoubleBiFold = 12,
    Revolving = 13
}
  