import { CommandGroup } from "./command-group";
import { ICommand } from "./i-command";
import { SvgEntityPointStyleEnum } from "../../../shared/gizmos/model/svg-entity-point-style-enum";

export class GripStyleCommands extends CommandGroup<SvgEntityPointStyleEnum> {
    endCommand: ICommand;
    middleCommand: ICommand;
    centerCommand: ICommand;
    quadrantCommand: ICommand;
    orthogonalCommand: ICommand;
    intersectionCommand: ICommand;

    constructor() {
        super();
        this.endCommand = this.addCommand(SvgEntityPointStyleEnum.end, "Extrémité", "square");
        this.middleCommand = this.addCommand(SvgEntityPointStyleEnum.middle, "Milieu", "close_fullscreen");
        this.centerCommand = this.addCommand(SvgEntityPointStyleEnum.center, "Centre", "adjust");
        this.quadrantCommand = this.addCommand(SvgEntityPointStyleEnum.quadrant, "Quadrant", "all_out");
        this.orthogonalCommand = this.addCommand(SvgEntityPointStyleEnum.orthogonal, "Orthogonal", "signal_cellular_null");
        this.intersectionCommand = this.addCommand(SvgEntityPointStyleEnum.intersection, "Intersection", "close");
    }
}