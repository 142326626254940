import { ApiControllers } from './../api-endpoints';
import { HttpParams } from "@angular/common/http";
import { Service } from "typedi";
import { GrantTypeEnum } from "../../model/data-model/enums/grant-type-enum";
import { ApiService } from "../api-service";

@Service({ global: true })  
export class FloorModelService extends ApiService {
    private createGrantEndPoint(layerId: number): string { return `${ApiControllers.floorModel}/${layerId}/AddGrant` };
    private updateUserOprionEndPoint(layerId: number, visible: boolean): string { return `${ApiControllers.floorModel}/useroption/${layerId}/${visible}` };

    constructor() {
        super();
    }

    async createLayerGrant(layerId: number, grantType: number): Promise<number | null> {
        let httpParams = new HttpParams();
        if (grantType === GrantTypeEnum.Read) {
            httpParams = httpParams.append('read', true);
        } else if (grantType === GrantTypeEnum.Update) {
            httpParams = httpParams.append('read', false);
        }
        const result = await this.postAsync<number>(this.endPoint(this.createGrantEndPoint(layerId)), null, httpParams);
        if (result) {
            return result.payload;
        }
        return null;
    }

    async updateUserOption(layerId: number, isVisible: boolean): Promise<void> {
        // Il n'est pas utile de retourner de valeur
        // si l'enregistrement ne marche pas le user peut tout de même gérer ses calques
        await this.patchAsync(this.endPoint(this.updateUserOprionEndPoint(layerId, isVisible)));
    }
}