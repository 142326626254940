import { Floor } from "src/app/core/model/data-model/tables/floor";
import { FloorAllocationView } from "src/app/core/model/data-model/views/floor-allocation-view";
import { FloorAllocationDbView } from "src/app/core/model/db-model/views/floor-allocation-db-view";

export class FloorAllocationDTO extends Floor {
    allocations: FloorAllocationView[] = [];

    constructor(dtoData: any) {
        super(dtoData);
        if (dtoData[FloorAllocationDbView.databaseTableName]) {
            dtoData[FloorAllocationDbView.databaseTableName].forEach((e: any) => {
                this.allocations.push(new FloorAllocationView(e));
            });
        }
    }
}