import { logError } from '../../services/logging-service';
import { IModelBase } from './i-model-base';

export abstract class DefinedModelBase implements IModelBase {
    protected tableDefinition: any;
    tableName!: string;

    constructor(dtoData: any, tableDef: any[]) {
        if (tableDef.length === 0) {
            logError("ModelBase : aucune définition de table n'a été passée");
            return;
        }

        this.tableDefinition = tableDef[0];
        this.tableName = dtoData["TableName"];

        const thisAsAny = this as any;

        // Pour chaque table de référence...
        tableDef.forEach(td => {
            // pour chaque propriété de la table de référence...
            for(const key of Object.keys(td)) {
                const dbColumnName = td[key as keyof typeof td];
                thisAsAny[key] = dtoData[dbColumnName];
            }       
        });

        // Elimine les propriétés qui n'ont pas été initialisées parce qu'elles ne se trouvent pas dans le dto data set
        Object.keys(thisAsAny).forEach(key => thisAsAny[key] === undefined && delete thisAsAny[key]);
    }

    convertBack(): any {
        const result: any = {};

        const thisAsAny = this as any;
        for (const key in this.tableDefinition) {
            const dbColumnName = (this.tableDefinition as any)[key];
            if (key === "databaseTableName") {
                result["TableName"] = thisAsAny[key];
            } else {
                result[dbColumnName] = thisAsAny[key];
            }
        }

        return result;
    }

    /**
     * 
     * @param key Nom de la propriété sur l'objet
     * @returns Retourne le nom de la colonne correspondante en base de données
     */
    dbColumnName(key: string): string {
        for (const k in this.tableDefinition) {
            if (k === key) {
                return (this.tableDefinition as any)[key];
            }
        }
        return "";
    }

    /**
     * 
     * @param dbColumnName Nom de la colonne en base de données
     * @returns Retourne le nom de la propriété correspondante sur l'objet
     */
    key(dbColumnName: string): string {
        for (const key in this.tableDefinition) {
            if (dbColumnName === (this.tableDefinition as any)[key]) {
                return key;
            }
        }
        return "";
    }

    /**
     * 
     * @param dbColumnName Nom de la colonne en base de données
     * @returns Retourne la valeur pour la propriété correspondant au nom de la colonne en base de données
     */
    getValue(dbColumnName: string): any {
        const key = this.key(dbColumnName);
        return (this as any)[key];
    }
}