import { SimulationPhoto } from "src/app/core/model/data-model/tables/simulation-photo";
import { logError } from "src/app/core/services/logging-service";

export class SvgPhotoMarkerVM {
    inactiveMarkerFill: string = "#9fc5e8";
    activeMarkerFill: string = "#66ff66";
    fictiveMarkerFill: string = "#eeeeee";

    photo: SimulationPhoto;
    x: number;
    y: number;
    stroke: string = "#165b9a";
    selected: boolean = false;
    selectable: boolean = true;
    isCurrentOccupation: boolean = false;
    isLast: boolean = false;

    constructor(photo: SimulationPhoto, x: number, y: number) {
        this.photo = photo;
        this.x = x;
        this.y = y;
    }

    isLockable(): boolean {
        // On peut changer le statut que de la dernière photo à condition qu'elle ne soit ni l'occupation courante
        // ni la source d'une variante
        return this.isLast && !this.isCurrentOccupation && this.photo.siPhSourceId === null;
    }

    isDeletable(): boolean {
        // On ne peut supprimer que la dernière photo à condition qu'elle ne soit ni l'occupation courante
        // ni la source d'une variante
        return this.isLast && !this.isCurrentOccupation && this.photo.siPhSourceId === null;
    }

    canBeVariantSource(): boolean {
        return !this.isCurrentOccupation;
    }

    labelEditRequested?: (pm: SvgPhotoMarkerVM, clientX: number, clientY: number) => void;
    labelClick(e: MouseEvent): void {
        if (this.labelEditRequested) {
            this.labelEditRequested(this, e.clientX, e.clientY);
        } else {
            logError("SvgPhotoMarkerVM.labelEditRequested n'est pas écouté");
        }
    }
}