import { SubscriptionPlanEnum } from "src/app/core/model/db-model/policy/subscription-plan-enum";
import { ClientGrantsVM } from "./client-grants-vm";
import { CieUserGrantsViewDTO } from "src/app/core/services/backend-services/dto/cie-user-grants-view-dto";

export class ClientVM extends CieUserGrantsViewDTO {
    grants: ClientGrantsVM;

    constructor(dtoData: any) {
        super(dtoData);
        this.grants = new ClientGrantsVM(this.hasOccupancy(), this.hasMobility(), this.hasStrategy(), this.hasMaintenance(), this.isPlayground());
    }

    hasOccupancy(): boolean {
        return this.subscriptionPlanId != null && this.subscriptionPlanId >= SubscriptionPlanEnum.occupancy;
    }

    hasMobility(): boolean {
        return this.subscriptionPlanId != null && this.subscriptionPlanId >= SubscriptionPlanEnum.mobility && this.subscriptionPlanId < SubscriptionPlanEnum.playground;
    }

    hasStrategy(): boolean {
        return this.subscriptionPlanId != null && this.subscriptionPlanId >= SubscriptionPlanEnum.strategy && this.subscriptionPlanId < SubscriptionPlanEnum.playground;
    }

    hasMaintenance(): boolean {
        return this.subscriptionPlanId != null && this.subscriptionPlanId >= SubscriptionPlanEnum.exploitation && this.subscriptionPlanId < SubscriptionPlanEnum.playground;
    }

    isPlayground(): boolean {
        return this.subscriptionPlanId != null && this.subscriptionPlanId === SubscriptionPlanEnum.playground;
    }
}