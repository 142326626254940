import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from 'src/app/core/material.module';
import { FormsModule } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { LoggerService } from 'src/app/core/services/logger.service';
import { CadConverterLayersPanelVM } from '../../model/cad-converter-layers-panel-vm';

@Component({
  selector: 'xc-cad-converter-layers-panel',
  standalone: true,
  imports: [CommonModule, MaterialModule, FormsModule],
  templateUrl: './cad-converter-layers-panel.component.html',
  styleUrls: ['./cad-converter-layers-panel.component.scss']
})
export class CadConverterLayersPanelComponent {
  @Input() model: CadConverterLayersPanelVM | undefined;

  // constructor(protected dialog: MatDialog) {}

  // ngAfterViewInit(): void {
  //   // Injecte le matDialog dans le modèle
  //   if (this.model) {
  //     this.model.dialog = this.dialog;
  //   } else {
  //     LoggerService.error("CadConverterLayersPanelComponent : model.dialog n'a pas pu être initialisé");
  //   }
  // }
}
