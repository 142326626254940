export enum TaskBusinessTaskEnum {
    PreventiveMaintenance = 1,
    Works = 2,
    Move = 3,
    Request = 4,
    MacroSimulation = 5,
    CurativeMaintenance = 6,
    Quote = 7,
    Purchase = 8,
    ReconcileEquipmentPrepare = 9,
    AcceptanceOfTheWork = 10,
    SpacePlanning = 11,
    BlueprintImport = 12,
    ReconcileEquipmentScan = 13,
    ReconcileBlueprint = 14,
    Reservation = 15,
    Intervention = 16,
    SpacePlanningImport = 17,
    PurchaseReceipt = 18
}