import { SvgEntityTypesEnum } from "src/app/core/model/svg-model/svg-entity-type-enum";
import { DxfSvgText } from "../../svg/dxf-svg-text";
import { UpdateGizmo } from "./update-gizmo";
import { Vector } from "src/app/core/model/geometry-model/vector.model";

export class TextGizmo extends UpdateGizmo<DxfSvgText> {
    constructor(pixelUnit: number) {
        super(pixelUnit, SvgEntityTypesEnum.text);
    }

    translateTexts(delta: Vector): void {
        this.entities.forEach(e => {
            e.translate(delta);
        });
        this.grips.items.forEach(g => {
            g.point = g.point.translate(delta);
        });
    }

    endTranslate(): void {
        this.entities.forEach(e => {
            e.updateSelectablePoints();
        });
    }
}