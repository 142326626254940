import { SvgTransform } from "src/app/core/model/svg-model/svg-transform.model";
import { SelectableSvgEntity } from "../svg/selectable-svg-entity";
import { HtmlConstants } from "src/app/core/model/html-model/html-constants.model";
import { SelectableSvgPath } from "../svg/selectable-svg-path";
import { SvgEntityTypesEnum } from "src/app/core/model/svg-model/svg-entity-type-enum";
import { SvgPathService } from "src/app/core/model/svg-model/svg-path-service";
import { getPictoGroup } from "../svg/selectable-entity-builder";
import { BpSvgBoundingBox } from "../../../layout/real-estate/floor-blueprint/content/bp-svg-core-model/bp-svg-bounding-box";

export class DrawingVM {
    pictoId: number | undefined;
    viewbox: string;
    extents!: string;
    transform: SvgTransform = new SvgTransform({ });
    //static zoomScale: number = 1;
    justInsertedEntities: SelectableSvgEntity[] = [];
    entities: SelectableSvgEntity[] = [];
    stroke: string = "black";
    strokeWidth: number = 0.001;

    constructor() {
        this.viewbox = "-1 -1 2 2";
        this.extents = "-1 -1;2 2";
    }
    
    set(id: number, bbox: BpSvgBoundingBox): void {
        //const bbox = BpSvgBoundingBox.getFromString(extents);
        //this.viewbox = extents;
        this.pictoId = id;
        if (bbox) {
            this.extents = `${bbox.minX} ${bbox.minY};${bbox.width()} ${bbox.height()}`;
            this.viewbox = `${bbox?.minX} ${bbox?.minY} ${bbox?.width()} ${bbox?.height()}`;
        } else {
            console.log("Impossible de définir l'étendue du dessin");
        }
    }

    /**
     * Actualise l'épaisseur des traits en fonction du facteur de zoom. Méthode branchée sur le panzoomController.zoomChanged event
     * @param zoomScale 
     * @returns 
     */
    updateStrokeWidth(pixelUnit: number): void {
        this.strokeWidth = pixelUnit;
        //this.layersController.updateStrokeWidth();
        //this.layersController.updateFontSize();
    }

    async updateExtents(svgBbox: SVGRect): Promise<boolean> {
        let result = false;
        const currentBbox = BpSvgBoundingBox.getFromString(this.extents);
        const newBbox = new BpSvgBoundingBox(svgBbox.x, svgBbox.y, svgBbox.x + svgBbox.width, svgBbox.y + svgBbox.height).rounded();
        if (currentBbox && !newBbox.equals(currentBbox)) {
            const newExtents = `${newBbox.minX} ${newBbox.minY};${newBbox.maxX} ${newBbox.maxY}`;
            
            // Enregistre la nouvelle étendue du dessin
            // const s = Container.get(DyntService);
            // await s.patch(DxfImportTable.databaseTableName, this.importId, { TableName: DxfImportTable.databaseTableName, [DxfImportTable.dxImId]: this.importId, [DxfImportTable.dxImExtents]: newExtents });
            
            this.extents = newExtents;
            result = true;
        }

        this.justInsertedEntities.splice(0);
        return result;
    }

    async insert(entity: SelectableSvgEntity): Promise<void> {
        let status: boolean = false;

        entity.cursor = HtmlConstants.styleCursorPointer;

        // const s = Container.get(DxfService);
        // const l = await s.insertEntity(entity.layerId, entity.statement, entity.entityType);
        // if (l != null) {
        //     status = true;
        this.entities.push(entity);
        //     entity.entityId = l.dxEnId;
        // }

        // Si l'entité ajoutée se trouve même partiellement hors de l'extension du dessin, tous ses points ne sont pas
        // référencés dans le tuilage
        // En attendant la fin de la commande d'insertion, on stocke la nouvelle entité dans un tableau dédié
        this.justInsertedEntities.push(entity);

    }

    delete(entities: SelectableSvgEntity[]): void {
        entities.forEach(e => {
            const i = this.entities.findIndex(x=> x.entityId === e.entityId);
            this.entities.splice(i, 1);
        });
    }

    getContourPath(): SelectableSvgPath | undefined {
        // Les paths fermés
        const paths = (this.entities.filter(x=> x.entityType === SvgEntityTypesEnum.path && (x as SelectableSvgPath).isClosed()) as SelectableSvgPath[]);
        if (paths.length === 0) return undefined;
        // Un tableau de ces paths avec leurs aires
        const tmp = paths.map(x=> ({entity: x, area: SvgPathService.getPolygon(x.d, true).area()}));
        // Retourne celui qui a la plus grande surface
        return tmp.reduce(function(p, c) { return (p && p.area > c.area) ? p : c }).entity;
    }

    combine(items: SelectableSvgEntity[]): SelectableSvgEntity[] {
        const result: SelectableSvgEntity[] = [];
        
        // On peut optimiser les deux lignes suivantes avec une seule boucle
        const ellipses = items.filter(x=> x.entityType === SvgEntityTypesEnum.ellipse);
        const others = items.filter(x=> x.entityType !== SvgEntityTypesEnum.ellipse);

        // Les ellipses sont restituées telles quelles
        result.push(...ellipses);

        // Les autres sont combinés en un path unique
        const combinedPathStatement = SvgPathService.fromEntities(others);
        const combinedPath = SelectableSvgPath.fromValues(combinedPathStatement, 0.01);
        result.push(combinedPath);

        return result;
    }

    combinedSet(): string | undefined {
        const contour = this.getContourPath();
        // Si le contour n'a pas été trouvé on retourne un set vide
        if (contour) {
            const tmp: SelectableSvgEntity[] = [];
            tmp.push(contour);
            tmp.push(...this.combine(this.entities.filter(x=> x.entityId !== contour.entityId)));

            return getPictoGroup(`gdef${this.pictoId}`, tmp);
        }

        return undefined;
    }
}