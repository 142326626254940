import { Site } from './../tables/site';
import { SiteDbView } from '../../db-model/views/site-db-view';

export class SiteView extends Site {
    siViLatitude!: number;
    siViLongitude!: number;
    siViAltitude!: number;
    siViArea!: number;
    siViGLA!: number;
    siViNUA!: number;
    siViBuildingsCount!: number;

    constructor(dtoData: any) {
        super(dtoData, [SiteDbView]);
    }
}