import { SvgEntityTypesEnum } from "src/app/core/model/svg-model/svg-entity-type-enum";
import { DxfSvgEntity } from "./dxf-svg-entity";
import { DxfSvgLine } from "./dxf-svg-line";
import { DxfSvgPath } from "./dxf-svg-path";
import { DxfSvgCircle } from "./dxf-svg-circle";
import { DxfSvgEllipse } from "./dxf-svg-ellipse";
import { DxfSvgDef } from "./dxf-svg-def";
import { DxfSvgUse } from "./dxf-svg-use";
import { DxfSvgText } from "./dxf-svg-text";

export function getDxfEntities(entities: any): Array<DxfSvgEntity> {
    let result = new Array<DxfSvgEntity>();
    if(entities){
        entities.forEach((element: { entityType: any; }) => {
            switch(element.entityType){
                case SvgEntityTypesEnum.g:
                    result.push(new DxfSvgDef(element));
                    break;
                case SvgEntityTypesEnum.text:
                    result.push(new DxfSvgText(element));
                    break;
                case SvgEntityTypesEnum.line:
                    result.push(new DxfSvgLine(element));
                    break;
                case SvgEntityTypesEnum.path:
                    result.push(new DxfSvgPath(element));
                    break;
                case SvgEntityTypesEnum.use:
                    result.push(new DxfSvgUse(element));
                    break;
                case SvgEntityTypesEnum.rect:
                    //result.push(new BpSvgRectangle(element));
                    break;
                case SvgEntityTypesEnum.circle:
                    result.push(new DxfSvgCircle(element));
                    break;
                case SvgEntityTypesEnum.ellipse:
                    result.push(new DxfSvgEllipse(element));
                    break
                default:
                    break;
            }
        });
    }

    return result;
}

// export function getDxfEntityPoints(entity: DxfSvgEntity, extentsMinPoint: Point): Point[] {
//     return getRelativeFlooredPoints(entity.selectablePoints, extentsMinPoint);
// }

// export function getRelativeFlooredPoint(p: Point, o: Point): Point {
//     const temp = new Point(p.x - o.x, p.y - o.y);
//     return new Point(Math.floor(temp.x), Math.floor(temp.y));
// }

// export function getRelativeFlooredPoints(points: Point[], o: Point): Point[] {
//     const result: Point[] = [];
//     points.forEach(p => {
//         result.push(getRelativeFlooredPoint(p, o));
//     });
//     return result;
// }

