import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReportGridVM } from '../../model/report-grid-vm';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
registerLocaleData(localeFr, 'fr');

@Component({
  selector: 'xc-report-grid',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './report-grid.component.html',
  styleUrls: ['./report-grid.component.scss']
})
export class ReportGridComponent {
  @Input() model: ReportGridVM | undefined;
}
