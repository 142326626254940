import { Component, Input } from '@angular/core';
import { SitesVM } from '../sites-vm';
import { MVVMEventDestroyLifeCycle } from 'src/app/components-lib/shared-model/mvvm-events-destroy-lifecycle';
import { DonutChartWrapperComponent } from 'src/app/ui/shared/charts/donut/view/donut-chart-wrapper/donut-chart-wrapper.component';
import { BusinessUnitPieChartComponent } from '../../charts/business-unit-chart/view/business-unit-pie-chart/business-unit-pie-chart.component';
import { PropertiesEditorComponent } from '../../properties-editor/view/properties-editor/properties-editor.component';

@Component({
  selector: 'xc-sites',
  standalone: true,
  imports: [
    BusinessUnitPieChartComponent, 
    DonutChartWrapperComponent, 
    PropertiesEditorComponent
  ],
  templateUrl: './sites.component.html',
  styleUrl: './sites.component.scss'
})
export class SitesComponent extends MVVMEventDestroyLifeCycle {
  @Input() override model: SitesVM | undefined;
}
