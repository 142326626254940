import { Component, Input } from '@angular/core';
import { BusinessUnitsUpload } from '../../model/business-units-upload';
import { MVVMEventDestroyLifeCycle } from 'src/app/components-lib/shared-model/mvvm-events-destroy-lifecycle';
import { FileUploadModule } from 'primeng/fileupload';

@Component({
  selector: 'xc-business-units-upload',
  standalone: true,
  imports: [FileUploadModule],
  templateUrl: './business-units-upload.component.html'
})
export class BusinessUnitsUploadComponent extends MVVMEventDestroyLifeCycle {
  @Input() override model: BusinessUnitsUpload | undefined;
}
