import { Component, Input } from '@angular/core';
import { MatTabsModule } from '@angular/material/tabs';
import { FormsModule } from '@angular/forms';
import { ProvidersDashboardComponent } from '../providers-dashboard/providers-dashboard.component';
import { ProvidersManage } from '../../model/providers-manage';
import { MVVMEventDestroyLifeCycle } from 'src/app/components-lib/shared-model/mvvm-events-destroy-lifecycle';
import { DynTableComponent } from 'src/app/components-lib/dyn-table/dyn-table.component';

@Component({
  selector: 'xc-providers-manage',
  standalone: true,
  imports: [DynTableComponent, MatTabsModule, FormsModule, ProvidersDashboardComponent],
  templateUrl: './providers-manage.component.html',
  styleUrl: './providers-manage.component.scss'
})
export class ProvidersManageComponent extends MVVMEventDestroyLifeCycle {
  @Input() override model: ProvidersManage | undefined;
}
