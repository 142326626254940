import { ZScopeEnum } from './../../../core/model/data-model/enums/z-scope-enum';
import { DbModelUtils } from './../../../core/model/static-functions/db-model-utils';
import { ZDbViewColumnView } from './../../../core/model/data-model/views/z-db-view-column-view';
import { FieldBuilder } from '../../shared-model/field-builder';
import { ZColumnConstraintView } from './../../../core/model/data-model/views/z-column-constraint-view';
import { DynTableRow } from './dyn-table-row';
import { DynviewSet } from './../../../core/model/data-model/sets/dyn-view-set';
import { DynTableColumn } from './dyn-table-column';
import { DynTableVM } from './dyn-table-vm';
import { Paginator } from './paginator';
import { ZConstraintTypeEnum } from 'src/app/core/model/data-model/enums/z-constraint-type-enum';

export class DynTableVMBuilder {
    static getVM(viewSet: DynviewSet, viewName: string, paginator: Paginator | null, withSelectableRows: boolean = false): DynTableVM {
        const cols: DynTableColumn[] = [];
        const mainTableName = viewSet.mainTable();
        //const primary = viewSet.getPrimaryColumnName();
        const allowUpdate = viewSet.allowUpdate();
        const allowInsert = viewSet.allowInsert();
        const allowDelete = viewSet.allowDelete();
        const contextTableUserId = viewSet.contextUIDynViewSet[0].contextUITableUser.coUITaUsId;

        viewSet.contextUIDynViewSet.sort((a, b) => a.contextUITableUserColumn.coUITaUsCoDisplayOrder - b.contextUITableUserColumn.coUITaUsCoDisplayOrder).forEach(e => {
            const isBrowsable = e.dbColumnView.dbViewColumn.dbViCoIsBrowsable ?? e.dbColumnView.dbCoIsBrowsable;
            if (isBrowsable) {
                const constraints = viewSet.columnsConstraints.filter(x=> x.coCoColumnName === e.dbColumnView.dbCoColumnName || 
                    (e.dbColumnView.dbViewColumn.dbViCoMainColumnOverride !== null && x.coCoColumnName === e.dbColumnView.dbViewColumn.dbViCoMainColumnOverride) || 
                    (e.dbColumnView.dbViewColumn.dbViCoColumnAlias !== null && x.coCoColumnName === e.dbColumnView.dbViewColumn.dbViCoColumnAlias));
                const realColumnName = e.dbColumnView.dbViewColumn.dbViCoMainColumnOverride ?? e.dbColumnView.dbViewColumn.dbViCoColumnAlias ?? e.dbColumnView.dbCoColumnName;
                cols.push(new DynTableColumn(
                    FieldBuilder.getComponentTypeFromDbType(realColumnName, e.dbColumnView.dbCoType, constraints), 
                    e.dbColumnView.dbCoType, 
                    realColumnName, 
                    e.dbColumnView.coViColumnDisplayName, 
                    e.dbColumnView.dbViewColumn.dbViCoIsReadOnly || e.dbColumnView.dbCoIsReadOnly || !allowUpdate, 
                    e.dbColumn, 
                    constraints,
                    e.contextUITableUserColumn.coUITaUsCoId));
            }
        });
        const definitions = viewSet.contextUIDynViewSet.map(x=> x.dbColumnView);
        return new DynTableVM(cols, viewSet.viewData, mainTableName, viewName, viewSet.primaryColumnName, allowUpdate, allowInsert, allowDelete, paginator, definitions, viewSet.columnsConstraints, contextTableUserId, withSelectableRows);
    }

    static getRows(viewData: any[], cols: DynTableColumn[], primaryColumnName: string, definitions: ZDbViewColumnView[], constraints: ZColumnConstraintView[]): DynTableRow[] {
        const result: DynTableRow[] = [];

        viewData.forEach(e => {
            const scopeColumn = DbModelUtils.scopeColumn(e);
            const isReadOnlyOnApplicationScope = FieldBuilder.getConstraint<boolean>(constraints, ZConstraintTypeEnum.IsReadOnlyOnApplicationScope, false);
            const readOnlyByScope: boolean = (scopeColumn !== undefined && e[scopeColumn] === ZScopeEnum.Application) && isReadOnlyOnApplicationScope;
            const fieldSet = FieldBuilder.getSetFromModelObject(e, definitions, constraints, readOnlyByScope);
            const newRow = new DynTableRow(primaryColumnName, e[primaryColumnName], fieldSet.fields, readOnlyByScope); // DynTableVMBuilder.getRowCells(e, cols, primaryColumnName));
            result.push(newRow);
        });

        return result;
    }

    static async exportToCsv(columns: DynTableColumn[], rows: DynTableRow[], fileName: string): Promise<void> {
        const headerLabels = columns.map(x=> x.headerLabel);
        const headerIds = columns.map(x=> x.id);
        const headerString = headerLabels.join(',');

        // handle null or undefined values here
        const replacer = (key: any, value: any) => value ?? '';
        
        const rowItems = rows.map(x=> headerIds.map(colId => JSON.stringify(x.field(colId)?.value, replacer)).join(','));
       
        // join header and body, and break into separate lines
        const csv = [headerString, ...rowItems].join('\r\n');

        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        // if (navigator.msSaveBlob) { // IE 10+
        //   navigator.msSaveBlob(blob, this.tableLabel);
        // } else {
          const link = document.createElement('a');
          if (link.download !== undefined) { // feature detection
            // Browsers that support HTML5 download attribute
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', `${fileName}.csv`);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        //   }
        }
    }

    static async exportToPdf(): Promise<void> {
        //     const data = deepCopy(this.table.data.filteredData);
        //     const columns = deepCopy(this.table.columns.map(c => c.contextUiTableUserColumnColumnName));
        //     const keysToDelete = Object.keys(data[0]).filter(k => !columns.includes(k));
        //     const headers = Object.keys(data[0]).filter(k => columns.includes(k));
        //     data.forEach(d => {
        //       keysToDelete.forEach(k => delete d[k]);
        //     });
        //     const doc = new jsPDF('l', 'pt', 'a4');
        //     doc.text(this.tableLabel, 40, 30);
        //     autoTable(doc, {
        //       theme: 'grid',
        //       body: data,
        //       columns: headers.map(h => {
        //         return {
        //           header: this.columnsDictionary[h].columnViewColumnDisplayName,
        //           dataKey: h
        //         };
        //       })
        //     });
        
        //     doc.save(`${this.tableLabel}.pdf`);
    }
    
}