import { BpSvgBoundingBox } from "../../../../../bp-svg-core-model/bp-svg-bounding-box";

export class EquipmentSelectionFreeTranslationHandle {
    cx: number = 0;
    cy: number = 0;
    fill: string = "#00cc7a";
    fillOpacity: number = 0.3;
    stroke: string = "#00ff99";

    constructor() {}
    
    update(overlayBbox: BpSvgBoundingBox): void {
        const center = overlayBbox.center();
        this.cx = center.x;
        this.cy = center.y;
    }
}