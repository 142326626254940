import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'xc-wip-icon',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './wip-icon.component.svg',
  styleUrls: ['./wip-icon.component.scss']
})
export class WipIconComponent {

}
