import { EquipmentPlanning } from "src/app/core/model/data-model/tables/equipment-planning";
import { ApiControllers } from "src/app/core/services/api-endpoints";
import { ApiService } from "src/app/core/services/api-service";
import { Service } from "typedi";
import { HttpParams } from '@angular/common/http';
import { Point } from 'src/app/core/model/geometry-model/point.model';
import { EquipmentPlanningTable } from "src/app/core/model/db-model/tables/equipment-planning-table";
import { FloorCatalogTable } from "src/app/core/model/db-model/tables/floor-catalog-table";
import { FloorDataTable } from "src/app/core/model/db-model/tables/floor-data-table";
import { FloorDataStateView } from "src/app/core/model/data-model/views/floor-data-state-view";
import { FloorDataStateDbView } from "src/app/core/model/db-model/views/floor-data-state-db-view";
import { EquipmentPlanningStateView } from "src/app/core/model/data-model/views/equipment-planning-state-view";
import { EquipmentPlanningStateDbView } from "src/app/core/model/db-model/views/equipment-planning-state-db-view";
import { BpSvgEquipmentCatalogGroup } from "src/app/ui/pages/layout/real-estate/floor-blueprint/content/blueprint-viewer-side-panel/subitems/equipments/model/bp-svg-equipment-catalog-group";
import { BpSvgDef } from "src/app/ui/pages/layout/real-estate/floor-blueprint/content/bp-svg-core-model/bp-svg-def";
import { BpSvgUse } from "src/app/ui/pages/layout/real-estate/floor-blueprint/content/bp-svg-core-model/bp-svg-use";
import { UpdatedFloorDataDTO } from "src/app/ui/pages/layout/real-estate/floor-blueprint/content/services/dto/updated-floor-data-dto";
import { BlueprintSvgEntityTypeEnum } from "src/app/ui/pages/layout/real-estate/floor-blueprint/content/svg-entities/model/blueprint-svg-entity-type-enum";
import { BpSvgLabel } from "src/app/ui/pages/layout/real-estate/floor-blueprint/content/svg-entities/model/bp-svg-label";
import { BlueprintEquipmentLayer } from "src/app/ui/pages/layout/real-estate/floor-blueprint/content/svg-entities/model/layers/blueprint-equipment-layer";

@Service({ global: true })  
export class BpEquipmentService extends ApiService {
    private replaceEndPoint(catalogItemId: number): string { return `${ApiControllers.floorDataEquipments}/replace/${catalogItemId}` };
    private layerCategoriesEndPoint(layerId: number): string { return `${ApiControllers.floorDataEquipments}/LayerCategories/${layerId}` };
    private floorCatalogEndPoint(layerId: number, categoryId: number): string { return `${ApiControllers.floorDataEquipments}/FloorCatalog/${layerId}/${categoryId}` };
    private transformEndPoint: string = `${ApiControllers.floorDataEquipments}/transform`;
    private copyEndPoint: string = `${ApiControllers.floorDataEquipments}/copy`;
    private copySimilarEndPoint(taskId: number): string { return `${ApiControllers.floorDataEquipments}/copysimilar/${taskId}`};



    constructor() {
        super();
    }

    async replaceEquipments(equipmentsIds: number[], catalogItemId: number): Promise<{ use: BpSvgUse[], equipmentPlanning: EquipmentPlanning[], floorCatalog: BpSvgDef[] } | null> {
        const result = await this.postAsync<any>(this.endPoint(this.replaceEndPoint(catalogItemId)), equipmentsIds);
        if (result) {
            const useResult: BpSvgUse[] = result.payload['SvgUseDTO'].map((x: any)=> new BpSvgUse(x));
            const equipmentPlanningResult: EquipmentPlanning[] = result.payload['EquipmentPlanning'].map((x: any)=> new EquipmentPlanning(x));
            const floorCatalogResult: BpSvgDef[] = result.payload['FloorCatalog'].map((x: any)=> new BpSvgDef(x));
            return {
                use: useResult,
                equipmentPlanning: equipmentPlanningResult,
                floorCatalog: floorCatalogResult
            }
        }
        return null;
    }


    async getLayerEquipmentCategories(layerId: number): Promise<any[]> {
        const result = await this.getAsync<any[]>(this.endPoint(this.layerCategoriesEndPoint(layerId)));
        if (result) {
            return result.payload.map(item => {
                return item;
            })
        }
        return [];
    }

    async getEquipmentCategoryFloorCatalog(layerId: number, categoryId: number): Promise<BpSvgEquipmentCatalogGroup[]> {
        const result = await this.getAsync<any[]>(this.endPoint(this.floorCatalogEndPoint(layerId, categoryId)));
        if (result != null) {
          return result.payload.map(item => {
              return new BpSvgEquipmentCatalogGroup(item);
          })
        }
        return [];
    }

    
    async transformEquipments(transfoms: BpSvgUse[]): Promise<{ svgUses: BpSvgUse[], svgLabels: BpSvgLabel[], equipmentPlanningItems: EquipmentPlanning[] } | null> {
        const dto = transfoms.map(u => {
            return {
            Id: u.floorDataId,
            RotationPoint: u.transform?.rotationCenter,
            Translate: u.transform?.translate,
            Angle: u.transform?.rotationAngle
            };
        });
        const result = await this.patchAsync<any>(this.endPoint(this.transformEndPoint), dto);
        if (result && result.payload != null) {
            const useResult: BpSvgUse[] = result.payload['SvgUseDTO'].map((x: any)=> new BpSvgUse(x));
            const labelResult: BpSvgLabel[] = result.payload['SvgGroupDTO'].map((x: any)=> new BpSvgLabel(x, BlueprintSvgEntityTypeEnum.none));
            const epResult: EquipmentPlanning[] = result.payload[EquipmentPlanningTable.databaseTableName].map((x: any)=> new EquipmentPlanning(x));
            return {
                svgUses: useResult,
                svgLabels: labelResult,
                equipmentPlanningItems: epResult
            };
        }
        return null;
    }

    async deleteEquipments(equipmentsIds: number[]): Promise<UpdatedFloorDataDTO[]> {
        let params = new HttpParams();
        equipmentsIds.forEach(id => {
            params = params.append(`id`, String(id));
        });
        const result = await this.deleteAsync<any>(this.endPoint(ApiControllers.floorDataEquipments), params);
        if (result) {
            return result.payload.map((item: any) => {
                return new UpdatedFloorDataDTO(item);
            })
        } else {
            return [];
        }
    }

    
    async insertEquipment(taskId: number, floorCatalogId: number, insertionPoint: Point): Promise<{ 
        use: BpSvgUse, 
        equipmentPlanning: any, 
        floorCatalog: BpSvgDef, 
        floorModel: BlueprintEquipmentLayer,
        floorDataState: FloorDataStateView,
        equipmentPlanningState: EquipmentPlanningStateView} | null> {
        const dto: any = {};
        dto[FloorDataTable.flDaTaskId] = taskId;
        dto[FloorCatalogTable.flCgId] = floorCatalogId;
        dto['x'] = insertionPoint.x;
        dto['y'] = insertionPoint.y;
    
        const result = await this.postAsync<any>(this.endPoint(ApiControllers.floorDataEquipments), dto);
        if (result) {
            return {
                use: new BpSvgUse(result.payload['SvgUseDTO']),
                equipmentPlanning: new EquipmentPlanning(result.payload['EquipmentPlanning']),
                floorCatalog: new BpSvgDef(result.payload['FloorCatalog']),
                floorModel: new BlueprintEquipmentLayer(result.payload['FloorModel'], taskId),
                floorDataState: new FloorDataStateView(result.payload[FloorDataStateDbView.databaseTableName]),
                equipmentPlanningState: new EquipmentPlanningStateView(result.payload[EquipmentPlanningStateDbView.databaseTableName])
            }
        } else {
            return null;
        }
    }
    
    async copyEquipments(x: number, y: number, sourceFloorDataIds: number[]): Promise<BpSvgUse[] | null> {
        const dto: any = {};
        dto['EquipmentIds'] = sourceFloorDataIds;
        dto['UpdateXValue'] = x;
        dto['UpdateYValue'] = y;
    
        const result = await this.postAsync<any>(this.endPoint(this.copyEndPoint), dto);
        if (result) {
            return result.payload.map((item: any) => {
                const def = new BpSvgDef(item["def"]);
                const use = new BpSvgUse(item);
                use.def = def;
                return use;
            })
        } else {
            return null;
        }
    }
    
    async copySimilarEquipments(taskId: number, values: {floorDataId: number, x: number, y: number, a: number}[]): Promise<{
        uses: BpSvgUse[],         
        equipmentsPlanning: EquipmentPlanning[], 
        floorCatalog: BpSvgDef[], 
        floorDataState: FloorDataStateView,
        equipmentPlanningState: EquipmentPlanningStateView} | null> {
        const result = await this.postAsync<any>(this.endPoint(this.copySimilarEndPoint(taskId)), values);
        if (result && result.payload != null) {
            const uses = result.payload["SvgUseDTO"].map((x: any)=> {return new BpSvgUse(x)});
            const ep = result.payload["EquipmentPlanning"].map((x: any)=> {return  new EquipmentPlanning(x)});
            const fc = result.payload["FloorCatalog"].map((x: any)=> {return  new BpSvgDef(x)});
            const fds = new FloorDataStateView(result.payload[FloorDataStateDbView.databaseTableName]);
            const eps = new EquipmentPlanningStateView(result.payload[EquipmentPlanningStateDbView.databaseTableName]);
            return {
                uses: uses,
                equipmentsPlanning: ep,
                floorCatalog: fc,
                floorDataState: fds,
                equipmentPlanningState: eps
            }
            // return result.payload.map((item: any) => {
            //     const def = new BpSvgDef(item["def"]);
            //     const use = new BpSvgUse(item);
            //     use.def = def;
            //     return use;
            // })
        } else {
            return null;
        }
    }

}
