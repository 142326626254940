import { AppGrantTable } from './../../model/db-model/tables/app-grant-table';
import { TablesNamesEnum } from './../../model/db-model/tables-names-enum';
import { ApiControllers, ApiEndpoints } from './../api-endpoints';
import { ApiService } from './../api-service';
import { Service } from 'typedi';
import { AppGrant } from '../../model/data-model/tables/app-grant';
import { AppRoleGrant } from '../../model/data-model/tables/app-role-grant';
import { HttpParams } from '@angular/common/http';

@Service({ global: true })  
export class GrantService extends ApiService {
    private roleGrantReadEndpoint(roleId: number): string { return `${ApiControllers.appRoleGrants}/${roleId}` };
    private roleGrantInsertEndpoint(roleId: number, grantId: number): string { return `${ApiControllers.appRoleGrants}/${roleId}/${grantId}` };
    private roleTableGrantInsertEndpoint(roleId: number, tableName: string, grantIdPropertyName: string): string { return `${ApiControllers.appRoleGrants}/${roleId}/AddTableGrant/${tableName}/${grantIdPropertyName}` };
    private roleGrantDeleteEndpoint(roleId: number): string { return `${ApiControllers.appRoleGrants}/${roleId}` };
    private grantDeleteEndpoint(grantId: number): string { return `${ApiControllers.appRoleGrants}/grant/${grantId}` };

    constructor() {
        super();
    }

    async loadUserGrants(): Promise<AppRoleGrant[]> {
        return await this.getArrayAsync<AppRoleGrant>(TablesNamesEnum.AppRoleGrant, this.endPoint(ApiEndpoints.appUserGrants));
    }

    async loadGrants(): Promise<AppGrant[]> {
        return await this.getArrayAsync<AppGrant>(TablesNamesEnum.AppGrant, this.dynt(TablesNamesEnum.AppGrant));
    }
    
    async loadAppRoleGrants(): Promise<AppRoleGrant[]> {
        return await this.getArrayAsync<AppRoleGrant>(TablesNamesEnum.AppRoleGrant, this.dynt(TablesNamesEnum.AppRoleGrant));
    }
    
    async loadRoleGrants(roleId: number): Promise<AppRoleGrant[]> {
        return await this.getArrayAsync<AppRoleGrant>(TablesNamesEnum.AppRoleGrant, this.endPoint(this.roleGrantReadEndpoint(roleId)));
    }

    async deleteGrant(grantId: number): Promise<boolean | null> {
        // NOTA : la suppression dans la table app-grant entraîne les modifications nécessaires dans les tables liées par cascade directement par le SGDB
        // sauf les éléments de language qui sont gérés par le endpoint
        const result = await this.deleteAsync<boolean>(this.endPoint(this.grantDeleteEndpoint(grantId)));
        if (result) {
            return result.payload;
        }
        return null;
    }
    
    async CreateAppRoleGrant(roleId: number, grantId: number): Promise<AppRoleGrant | null> {
        const result = await this.postAsync<any>(this.endPoint(this.roleGrantInsertEndpoint(roleId, grantId)), undefined);
        if (result) {
            return new AppRoleGrant(result.payload);
        }
        return null;
    }
    
    async CreateAppRoleTableGrant(roleId: number, tableName: string, grantIdPropertyName: string): Promise<AppRoleGrant | null> {
        const result = await this.postAsync<any>(this.endPoint(this.roleTableGrantInsertEndpoint(roleId, tableName, grantIdPropertyName)), undefined);
        if (result) {
            return new AppRoleGrant(result.payload);
        }
        return null;
    }

    async deleteRoleGrants(roleId: number, grantIds: number[]): Promise<number | null> {
        let params = new HttpParams();
        grantIds.forEach(id => {
            params = params.append('id', id);
        });
        const result = await this.deleteAsync<number>(this.endPoint(this.roleGrantDeleteEndpoint(roleId)), params);
        if (result) {
            return result.payload;
        }
        return null;
    }
}