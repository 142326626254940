import { Point } from 'src/app/core/model/geometry-model/point.model';
import { Polygon } from 'src/app/core/model/geometry-model/polygon.model';
import { Rectangle } from 'src/app/core/model/geometry-model/rectangle.model';
import { BpSvgPath } from '../../../../bp-svg-core-model/bp-svg-path';

export class RoomContourDataDTO {
    svgPathDTO: BpSvgPath;
    wallIds: number[];
    scale: number = 0.01;
    transformOrigin: string = "center";

    constructor(dtoData: any) {
        this.svgPathDTO = new BpSvgPath(dtoData["SvgPathDTO"]);
        this.wallIds = dtoData["WallIds"];
    }

    setTransformOrigin(labelPosition: Point): void {
        // Le transform-origin est destiné à l'affichage de l'animation de la transition d'échelle
        // de façon à ce que la surface recalculée croisse à partir de l'emplacement de l'étiquette
        // Il est calculé relativement à l'origine de la bbox du nouveau contour
        const p = this.svgPathDTO.geometry[0] as Polygon;
        const r = Rectangle.surroundingBbox(p.vertices);
        const tl = r.topLeft;
        this.transformOrigin = `${labelPosition!.x - tl.x}px ${labelPosition!.y - tl.y}px`;
    }
}