import { SvgPath } from 'src/app/core/model/svg-model/svg-path.model';
import { Point } from "src/app/core/model/geometry-model/point.model";

export class GripPath extends SvgPath {
    constructor(data: any, radius: number) {
        super(data);
        this._radius = radius;
    }

    private _radius: number;
    get radius(): number {
        return this._radius;
    }
    set radius(value: number) {
        this._radius = value;
        this.setPath();
    }

    private _point: Point = Point.origin();
    get point(): Point {
        return this._point;
    }
    set point(value: Point) {
        this._point = value;
        this.setPath();
    }

    private setPath(): void {
        const x = this.point.x;
        const y = this.point.y;
        const r = this._radius;
        const x0 = x - r;
        const y0 = y - r;
        const x1 = x - r;
        const y1 = y + r;
        const x2 = x + r;
        const y2 = y + r;
        const x3 = x + r;
        const y3 = y - r;

        this.d = "M " + x0 + " " + y0 + " " + x1 + " " + y1 + " " + x2 + " " + y2 + " " + x3 + " " + y3 + " Z"
    }
}