import { TabPageContentTypeEnum } from "src/app/ui/pages/layout/real-estate/tab-page-content-type-enum";
import { ITabPageContent } from "../../../../components-lib/tab-page-container/model/i-tab-page-content";
import { DefinedModelBase } from "src/app/core/model/data-model/defined-model-base";
import { PropertiesEditorVM } from "src/app/ui/pages/layout/real-estate/properties-editor/model/properties-editor-vm";

export class ReferentialReportWrapper<T extends DefinedModelBase> extends PropertiesEditorVM<T> implements ITabPageContent {
    contentType: TabPageContentTypeEnum = TabPageContentTypeEnum.referential;
    id: string;
    name: string;
    content: any | undefined;

    private constructor(id: string, name: string, tableName: string, itemId: number) {
        super(tableName, itemId);
        this.id = id;
        this.name = name;
        this.tableName = tableName;
        this.itemId = itemId === 0 ? null : itemId;
    }

    static async newAsync(id: string, name: string, tableName: string, itemId: number): Promise<ReferentialReportWrapper<DefinedModelBase>> {
        const tmp = new ReferentialReportWrapper(id, name, tableName, itemId);
        await tmp.loadItemDatas();
        return tmp;
    }

    set(data: any): void {
    }
}