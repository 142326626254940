import { OnChanges, SimpleChanges } from '@angular/core';
/**
 * https://gist.github.com/boneskull/27f8003eeb605900a907ae8d8fd42c10?permalink_comment_id=2775666
 * @see https://stackoverflow.com/questions/15975440/add-ellipses-to-overflowing-text-in-svg
 * @example
 * <!-- truncate at 200px -->
 * <svg><svg:text ellipsis [text]="text to truncate" [width]="200"></svg:text></svg>
 */

import { Directive, ElementRef, Input, OnInit } from '@angular/core';
  
const ELLIPSIS = '\u2026';
  
@Directive({
    standalone: true,
    selector: 'svg text[ellipsis]'
})
export class SVGTextEllipsisDirective implements OnInit, OnChanges {
    @Input() text: string | undefined;
  
    @Input() width: number = 0;
  
    constructor (private _el: ElementRef) {
    }
  
    ngOnInit (): void {
        this._textEllipsis(this._el.nativeElement);
    }

    ngOnChanges(s: SimpleChanges): void {
        this._textEllipsis(this._el.nativeElement);
    }
  
    private _textEllipsis (el: SVGTextContentElement) {
        if (!this.text) return;

        let text = this.text;
        const width = this.width;
        if (typeof el.getSubStringLength !== 'undefined') {
            el.textContent = text;
            let len = text.length;
            if (el.getSubStringLength(0, len) > width) {
                while (el.getSubStringLength(0, len--) > width) {
                    el.textContent = text.slice(0, len) + ELLIPSIS;
                }
            }
        } else if (typeof el.getComputedTextLength !== 'undefined') {
            while (el.getComputedTextLength() > width) {
                text = text.slice(0, -1);
                el.textContent = `${text}${ELLIPSIS}`;
            }
        } else {
            // the last fallback
            while (el.getBBox().width > width) {
                text = text.slice(0, -1);
                // we need to update the textContent to update the boundary width
                el.textContent = `${text}${ELLIPSIS}`;
            }
        }
    }
}