import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EllipseInsertGizmo } from '../../model/ellipse-insert-gizmo';

@Component({
  selector: 'g[xc-picto-ellipse-insert-gizmo]',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './ellipse-insert-gizmo.component.svg',
  styleUrls: ['./ellipse-insert-gizmo.component.scss']
})
export class EllipseInsertGizmoComponent {
  @Input() model: EllipseInsertGizmo | undefined;
}
