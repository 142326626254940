@if (model) {

<div id="content-header">
  <span class="header">Locaux</span>
  <span class="header subheader"
    >Sélection :
    {{
      model.selectedRoomLabels == null
        ? "aucune"
        : model.selectedRoomLabels.count
    }}</span
  >
</div>
<mat-divider></mat-divider>

<!-- <form> -->
<!-- Information de surface -->
<p>{{ "surface : " + model.totalArea + "m²" }}</p>

<!-- Code -->
<!-- Nota : Impossible d'utiliser le two way binding [(ngModel)] puisque le le modèle est dynamique -->
<!-- on utilise donc la forme combinée avec le (ngModelChange) -->
<mat-form-field appearance="outline">
  <mat-label>Code</mat-label>
  <input
    matInput
    [ngModel]="
      model.selectedRoomLabels.count === 1
        ? $any(model.selectedRoomLabels.items[0]).text.tSpans[0].text
        : model.sharedCode
    "
    (ngModelChange)="
      model.selectedRoomLabels.count === 1
        ? ($any(model.selectedRoomLabels.items[0]).text.tSpans[0].text = $event)
        : null
    "
    required
    name="code"
    #code="ngModel"
    [disabled]="model.selectedRoomLabels.count > 1 || !model.isEditable"
    (keyup.enter)="model.onCodeInputKeyUpEnter($event, code.valid)"
    (focusout)="model.onCodeInputKeyUpEnter($event, code.valid)"
    pattern="[0-9a-zA-Z \- \/ _]{1,10}"
    autocomplete="off"
  />
  <div [hidden]="code.valid || code.pristine">
    Entre 1 et 10 caractères alphanumériques + (- / _)
  </div>
</mat-form-field>

<!-- Implantation type -->
<mat-form-field appearance="outline">
  <mat-label>Implantation type</mat-label>
  <mat-select
    required
    [disabled]="!model.isEditable"
    [(ngModel)]="model.currentLayoutType"
    (ngModelChange)="model.onLayoutTypeChange($event)"
    name="layoutTypeSelect"
    [compareWith]="model.roomLayoutTypeOptionCompare"
  >
    <mat-select-trigger>
      <div class="dropdown-row" fxLayout="row">
        <span
          class="color-cell"
          [style.background-color]="model.currentLayoutType?.roLaTyColor"
          [style.border-color]="model.currentLayoutType?.roLaTyColor"
        ></span>
        <span class="dropdown-label">{{
          model.currentLayoutType?.roLaTyName
        }}</span>
      </div>
    </mat-select-trigger>
    @for (layoutType of model.roomLayoutTypes; track layoutType) {
    <mat-option
      [style.display]="
        model.selectedRoomLabels.count === 1 && layoutType.roLaTyId === 0
          ? 'none'
          : 'block'
      "
      [value]="layoutType"
    >
      <div class="dropdown-row" fxLayout="row">
        <span
          class="color-cell"
          [style.background-color]="layoutType.roLaTyColor"
          [style.border-color]="layoutType.roLaTyColor"
        ></span>
        <span class="dropdown-label">{{ layoutType.roLaTyName }}</span>
      </div>
    </mat-option>
    }
  </mat-select>
  <mat-error>type d'implantation obligatoire</mat-error>
</mat-form-field>

<!-- Utilisation -->
<mat-form-field appearance="outline">
  <mat-label>Utilisation</mat-label>
  <mat-select
    required
    [disabled]="!model.isEditable"
    [(ngModel)]="model.currentUseOption"
    (ngModelChange)="model.onUseChange($event)"
    name="use"
    [compareWith]="model.roomUseOptionCompare"
  >
    <mat-select-trigger>
      <div class="dropdown-row" fxLayout="row">
        <span
          class="color-cell"
          [style.background]="
            'repeating-linear-gradient(45deg, ' +
            model.currentUseOption?.color +
            ' 0px, lightgrey 4px)'
          "
          [style.border-color]="model.currentUseOption?.color"
        ></span>
        <span class="dropdown-label">{{ model.currentUseOption?.label }}</span>
      </div>
    </mat-select-trigger>
    @for (roomUseOption of model.roomUsesOptions; track roomUseOption) {
    <mat-option
      [style.display]="
        model.selectedRoomLabels.count === 1 && roomUseOption.label === '*'
          ? 'none'
          : 'block'
      "
      [value]="roomUseOption"
      [disabled]="roomUseOption.disabled"
      [style.display]="!roomUseOption.activityStatusId && { display: 'none' }"
    >
      <div class="dropdown-row" fxLayout="row">
        <span
          class="color-cell"
          [style.background-color]="roomUseOption.color"
          [style.border-color]="roomUseOption.color"
          [style.background]="
            'repeating-linear-gradient(45deg, ' +
            roomUseOption.color +
            ' 0px, lightgrey 4px)'
          "
        ></span>
        <span class="dropdown-label">{{ roomUseOption.label }}</span>
      </div>
    </mat-option>
    }
  </mat-select>
</mat-form-field>

<!-- Périmètres -->
@if (model.currentUseOption?.attributionTypeId === 2) {
<mat-form-field appearance="outline">
  <mat-label>Périmètre de mutualisation</mat-label>
  <mat-select
    required
    [disabled]="!model.isEditable"
    [(ngModel)]="model.currentPerimeter"
    (ngModelChange)="model.onPerimeterChange($event)"
    name="perimeter"
    [compareWith]="model.perimeterCompare"
  >
    @for (perimeter of model.perimeters; track perimeter) {
    <mat-option
      [style.display]="
        model.selectedRoomLabels.count === 1 && perimeter.peName === '*'
          ? 'none'
          : 'block'
      "
      [value]="perimeter"
    >
      {{ perimeter.peName }}
    </mat-option>
    }
  </mat-select>
</mat-form-field>
}
<!-- </form> -->

<!-- Allocations -->
@if (model.currentUseOption?.attributionTypeId === 1) {

<div>
  <div class="allocation-header">
    <p>Entités</p>
    <button
      mat-icon-button
      [disabled]="!model.isEditable"
      color="primary"
      aria-label="Modifier les entités"
      matTooltip="Modifier les entités"
      [matTooltipPosition]="'above'"
      (click)="model.onAllocationUpdateButtonClick()"
    >
      <mat-icon id="allocation-edit">edit</mat-icon>
    </button>
    @if (model.selectedRoomLabels.count === 1) {
    <button
      mat-icon-button
      [disabled]="!model.isEditable"
      color="primary"
      aria-label="Assigner les mêmes entités"
      matTooltip="Assigner les mêmes entités"
      [matTooltipPosition]="'above'"
      (click)="model.onAllocationSetButtonClick()"
    >
      <mat-icon id="allocation-edit">brush</mat-icon>
    </button>
    }
  </div>

  @if (model.currentAllocations.length === 0) {

    <span class="allocation-header">Allocations variées</span>

  } 
  
  @if (model.currentAllocations.length > 0) {

    <mat-list id="allocations-list" #allocations [disabled]="!model.isEditable">
      @for (allocation of model.currentAllocations; track allocation) {
      <mat-list-item class="allocation-row">
        <div class="dropdown-row" fxLayout="row">
          <span
            class="color-cell"
            [style.background-color]="allocation.dataSet.buUnColor"
            [style.border-color]="allocation.dataSet.buUnColor"
          ></span>
          <span class="dropdown-label">{{ allocation.dataSet.buUnName }}</span>
          <span class="dropdown-label">{{
            allocation.dataSet.roAlRate + "%"
          }}</span>
        </div>
      </mat-list-item>
      }
    </mat-list>

  }
</div>

}

<!-- Bouton de création de tâche directe -->
@if (model.canCreateDirectTask && !model.hasPlanningTask && model.isEditable) {
<div id="box-panel">
  <span class="box-header">Etude directe</span>
  <div id="box-panel-content">
    <mat-slide-toggle [color]="'#0000ff'" #directTaskToggle
      >Créer une étude pour cette zone</mat-slide-toggle
    >
    @if (directTaskToggle.checked) {
    <div class="direct-task-form">
      <mat-form-field class="example-full-width" appearance="outline">
        <mat-label>Nom de l'étude</mat-label>
        <input
          matInput
          [(ngModel)]="model.directTaskName"
          name="name"
          #name="ngModel"
          required
          placeholder="Nom de l'étude"
          autocomplete="off"
          pattern="[0-9a-zA-Zàéèëêîïôûç \- \/ _]{1,50}"
        />
      </mat-form-field>
      <div [hidden]="name.valid">
        Entre 1 et 50 car. alphanumériques + (- / _)
      </div>
      <!-- TODO : Ajouter un champ de saisie pour la date de réalisation prévue -->
      <!-- ainsi qu'une case à cocher pour la gestion de l'inventaire -->
      <!-- cette option déclenchant la création des tâches liées de gestion d'inventaire -->
      <!-- ReconcileEquipmentPrepare et ReconcileEquipmentScan -->
      <!-- <mat-form-field class="example-full-width">
          <mat-label>Date de réalisation prévue</mat-label>
          <input matInput required [matDatepicker]="picker">
          <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field> -->
      <button
        mat-button
        color="primary"
        [disabled]="!name.valid"
        (click)="model.onDirectTaskCreateButtonClick(name.model)"
      >
        Créer
      </button>
    </div>
    }
  </div>
</div>
}

<!-- Définition d'un lieu de stockage d'équipements -->
@if (model.canDefineStorageArea && model.isEditable) {
<div id="box-panel">
  <span class="box-header">Stockage d'équipements</span>
  <div id="box-panel-content">
    <button
      mat-button
      color="primary"
      (click)="model.onDefineStorageAreaButtonClick()"
    >
      Créer à partir des surfaces sélectionnées
    </button>
  </div>
</div>
} }
