import { MobilityProjectsGanttTreeEventsEnum } from "../../../side-panel/projects-gantt/model/mobility-projects-gantt-tree-events-enum";
import { MobilityProjectTreeNode } from "../../../side-panel/shared-model/mobility-project-tree-node";
import { GanttScheduleBaseVM } from "../../shared/model/gantt-schedule-base-vm";
import Container from 'typedi';
import { TaskLinkView } from 'src/app/core/model/data-model/views/task-link-type-view';
import { ScheduleEventsEnum } from "../../shared/model/schedule-events-enum";
import { MobilityProjectsGanttEditorEventsEnum } from "../../../side-panel/projects-gantt/model/mobility-projects-gantt-editor-events-enum";
import { TaskCreateInternalDTO } from "../../../side-panel/projects-gantt/model/task-create-internal-dto";
import { GanttSvgTaskVM } from "../../shared/model/gantt-svg-task-vm";
import { TaskService } from "src/app/core/services/backend-services/task-service";
import { MobilityProjectsCategoryEnum } from "../../../side-panel/projects-gantt/model/mobility-projects-category-enum";

export class MobilityProjectsScheduleVM extends GanttScheduleBaseVM {
    //schedule: ScheduleCurrent | undefined;
    projectsCategory: MobilityProjectsCategoryEnum = MobilityProjectsCategoryEnum.none;
    
    kd = this.keyDown.bind(this);

    constructor(startDate: Date, duration: number) {
        super(startDate, duration);
        
        this.addEventListener(MobilityProjectsGanttTreeEventsEnum.projectsLoaded, async (projects: MobilityProjectTreeNode[], category: MobilityProjectsCategoryEnum) => {
            if (projects.length > 0) {
                const links = await this.loadLinks(category);
                const first = projects[0];
                const taskHeight = this.setPosition(first);
                this.getTasksClientHeight(projects);
                this.initialize(projects, links, taskHeight);
            } else {
                this.initialize(projects, [], 0);
            }
        });
        
        this.addEventListener(MobilityProjectsGanttTreeEventsEnum.projectExpanded, (project: MobilityProjectTreeNode) => {
            project.clientHeight += this.getLiHeight(project);
            this.setRootYOffset();
            // Actualise les liens
            const ganttItem = this.ganttRootProjects.find(x=> x.rootProject.data.taId === project.data.taRootTaskId);
            ganttItem?.svgRootProject.updateLinksRecursively();
        });
        
        this.addEventListener(MobilityProjectsGanttTreeEventsEnum.projectCollapsed, (project: MobilityProjectTreeNode) => {
            project.clientHeight -= this.getLiHeight(project);
            this.setRootYOffset();
            // Actualise les liens
            const ganttItem = this.ganttRootProjects.find(x=> x.rootProject.data.taId === project.data.taRootTaskId);
            ganttItem?.svgRootProject.updateLinksRecursively();
        });
        
        this.addEventListener(MobilityProjectsGanttTreeEventsEnum.taskSelectedByUser, (node: MobilityProjectTreeNode) => {
            const itemToScrollTo = document.getElementById('svgGanttTask' + node.data.taId);
            itemToScrollTo?.scrollIntoView({behavior: "smooth", block: 'nearest', inline: "center"});
        });

        this.addEventListener(MobilityProjectsGanttEditorEventsEnum.taskInsertRequested, (dto: TaskCreateInternalDTO) => {
            dto.isTemplate = this.projectsCategory === MobilityProjectsCategoryEnum.templates;
            this.taskInsertingProcess.beguinInsert(dto);
        });

        this.addEventListener(MobilityProjectsGanttEditorEventsEnum.projectsCategoryChanged, (category: MobilityProjectsCategoryEnum) => {
            this.projectsCategory = category;
        });

        this.addEventListener(MobilityProjectsGanttTreeEventsEnum.taskRemoved, (taskId: number) => {
            // Retire la tâche du calendrier
            const parentProject = this.remove(taskId);
            
            // Si le projet supprimé était un projet racine on retire le conteneur
            if (!parentProject) {
                const container = this.ganttRootProjects.find(x=> x.svgRootProject.task.taId === taskId);
                if (container) {
                    const containerIndex = this.ganttRootProjects.indexOf(container);
                    this.ganttRootProjects.splice(containerIndex, 1);
                }
            }

            this.setRootYOffset();

            if (parentProject) {
                // Retire les liens relatifs à la tâche supprimée
                parentProject.removeLinks(taskId);
                // Recalcule les liens
                parentProject.resetLinks();
            }
        });

        document.addEventListener('keydown', this.kd, true);
    }

    async loadLinks(category: MobilityProjectsCategoryEnum): Promise<TaskLinkView[]> {
        const s = Container.get(TaskService);
        if (category !== MobilityProjectsCategoryEnum.templates) return await s.downloadLinks(true, false, false, false);
        return await s.downloadTemplatesLinks();
    }

    setPosition(first: MobilityProjectTreeNode): number {
        // La position du conteneur des tâches
        const projectTree = document.getElementById("mobility-projects-tree");
        const projectTreeBbox = projectTree?.getBoundingClientRect();
        if (projectTreeBbox) this.tasksContainerTop = projectTreeBbox.y;

        const firstBbox = this.getTreeNodeBbox(first);
        if (firstBbox) return firstBbox.height;

        return 0;
        // this.ganttTasks.forEach(t => {
        //     const bbox = this.getTaskBbox(t);
        //     if (bbox) {
        //         t.clientY = bbox.y;
        //         t.clientHeight = bbox.height;
        //     }
        // });
    }

    getTreeNodeBbox(t: MobilityProjectTreeNode): DOMRect | undefined {
        const htmlId = t.key;
        const htmlElement = document.getElementById(htmlId!);
        // TODO : remplacer par une recherche récursive arrière sur une balise et/ou une classe
        const ancestor = htmlElement?.parentElement?.parentElement?.parentElement?.parentElement;
        return ancestor?.getBoundingClientRect();
    }

    getLiHeight(t: MobilityProjectTreeNode): number {
        // Impossible de récupérer le noeud du DOM (à cause du prime tree ?)
        // du coup il faut faire une fonction récursive pour trouver tous les noeuds affichés
        let result = t.children.length * this.taskDefaultHeight;

        t.children.forEach(c => {
            if (c.expanded) {
                result = this.getLiHeight(c);
            }
        });

        return result;
    }

    getTasksClientHeight(tasks: MobilityProjectTreeNode[]): void {
        tasks.forEach(t => {
            const tbbox = this.getTreeNodeBbox(t);
            if (tbbox) t.clientHeight = tbbox.height;
        });
    }

    /**
     * Supprime la tâche ou le projet du calendrier
     * @param taskId 
     * @param rootSet 
     * @returns Retourne le projet parent pour recalcul des liens
     */
    remove(taskId: number, rootSet: GanttSvgTaskVM[] = this.ganttRootProjects.map(x=> x.svgRootProject), parent?: GanttSvgTaskVM): GanttSvgTaskVM | undefined {
        let result: GanttSvgTaskVM | undefined;

        // On commence par rechercher si le noeud à supprimer est dans le set initial
        const index = rootSet.findIndex(x=> x.task.taId === taskId);
        if (index > -1) {
            // Si c'est le cas on le retire et on sort
            rootSet.splice(index, 1);
            return parent;
        }

        // Le noeud à supprimer n'était pas dans le set initial
        // On recherche dans les enfants du set initial
        for (const n of rootSet) {
            result = this.remove(taskId, n.children, n);
            if (result) break;
        }

        return result;
    }

    keyDown(e: KeyboardEvent): void {
        if (e.key.toLowerCase() === "escape") {
            if (this.taskInsertingProcess.processing) {
                this.taskInsertingProcess.endInsert();
                return;
            }
            if (this.taskMovingProcess.processing) {
                this.taskMovingProcess.abort();
                return;
            }
            if (this.taskRangeProcess.processing) {
                this.taskRangeProcess.abort();
                return;
            }
            if (this.taskLinkingProcess.processing) {
                this.taskLinkingProcess.abort();
                return;
            }
            this.emitEventAsync(ScheduleEventsEnum.escapeKeyStroke);
            return;
        }

        if (e.key.toLowerCase() === "delete") {
            this.emitEventAsync(ScheduleEventsEnum.deleteKeyStroke);
            return;
        }
    }
}