import { AfterViewInit, Component, Input, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CadConvSvgGroupComponent } from '../cad-conv-svg-group/cad-conv-svg-group.component';
import { SvgSelectionPathComponent } from 'src/app/components-lib/svg/svg-selection-path/svg-selection-path.component';
import { UpdateGizmosComponent } from '../gizmos/update/update-gizmos/update-gizmos.component';
import { LineInsertGizmoComponent } from '../gizmos/insert/line-insert-gizmo/line-insert-gizmo.component';
import { CircleInsertGizmoComponent } from '../gizmos/insert/circle-insert-gizmo/circle-insert-gizmo.component';
import { EllipseInsertGizmoComponent } from '../gizmos/insert/ellipse-insert-gizmo/ellipse-insert-gizmo.component';
import { PathInsertGizmoComponent } from '../gizmos/insert/path-insert-gizmo/path-insert-gizmo.component';
import { TextInsertGizmoComponent } from '../gizmos/insert/text-insert-gizmo/text-insert-gizmo.component';
import { GridGizmoComponent } from '../gizmos/grid-gizmo/grid-gizmo.component';
import { CadConvSvgReticlesComponent } from '../cad-conv-svg-reticles/cad-conv-svg-reticles.component';
import { UserInteraction } from '../../model/interaction/user-interaction';
import { logError } from 'src/app/core/services/logging-service';

@Component({
  selector: 'xc-cad-conv-drawing',
  standalone: true,
  imports: [CommonModule, 
    CadConvSvgGroupComponent, 
    SvgSelectionPathComponent, 
    UpdateGizmosComponent, 
    LineInsertGizmoComponent, 
    CircleInsertGizmoComponent, 
    EllipseInsertGizmoComponent, 
    PathInsertGizmoComponent, 
    TextInsertGizmoComponent, 
    GridGizmoComponent, 
    CadConvSvgReticlesComponent
  ],
  templateUrl: './cad-conv-drawing.component.svg',
  styleUrls: ['./cad-conv-drawing.component.scss']
})
export class CadConvDrawingComponent implements AfterViewInit, OnDestroy {
  @Input() model: UserInteraction | undefined;

  ngAfterViewInit(): void {
      setTimeout(() => {
        if (this.model) {
            this.model.initializePanZoom();
            this.model.initializeViewboxObserver();
        } else {
          logError("CadConvDrawingComponent userInteraction n'a pas été initialisé");
        }
      });
  }

  ngOnDestroy(): void {
    if (this.model) {
      document.removeEventListener('keydown', this.model.kd, true);
      this.model.viewboxObserver?.disconnect();
      this.model = undefined
    } else {
      logError("CadConvDrawingComponent - Le document event listener n'a pas pu être supprimé");
    }
  }
}
