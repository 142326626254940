import { AppUser } from "../data-model/tables/app-user";
import { Directory } from "../data-model/tables/directory";
import { TaskContributorDirectory } from "../data-model/tables/task-contributor-directory";
import { TaskContributorView } from "../data-model/views/task-contributor-view";

export function appUserDisplayName(item: AppUser, WithTitle: boolean, fullFirstName: boolean): string {
    return peopleDisplayName(item.apUsTitle, item.apUsFirstName, item.apUsLastName, WithTitle, fullFirstName);
}

export function directoryItemDisplayName(item: Directory | TaskContributorView, WithTitle: boolean, fullFirstName: boolean): string {
    return peopleDisplayName(item.diTitle, item.diFirstName, item.diLastName, WithTitle, fullFirstName);
}

export function taskContributorDirectoryItemDisplayName(item: TaskContributorDirectory, WithTitle: boolean, fullFirstName: boolean): string {
    return peopleDisplayName(item.taCrDiTitle, item.taCrDiFirstName, item.taCrDiLastName, WithTitle, fullFirstName);
}

export function peopleDisplayName(title: string, firstName: string, lastName: string, WithTitle: boolean, fullFirstName: boolean): string {
    if (!title) title = "";
    if (!firstName) firstName = "John";
    if (!lastName) lastName = "Doe";

    let t = "";
    let f = "";
    if (WithTitle) t = title;
    if (fullFirstName) f = firstName; else f = firstName.substring(0, 1) + ".";
    return t + " " + f + " " + lastName
}
