import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export class CreateValidators {
    static directoryValidator(): ValidatorFn {
        return (control:AbstractControl) : ValidationErrors | null => {
            const selection: any = control.value;
            if (typeof selection === 'string') {
                return { incorrect: true };
            }
            return null;        
        }
    }

    static emailValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
          const patternRegex = /^[A-Za-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
          if (patternRegex.test(control.value)) {
            //console.log('Match exists.');
            return null;
          } else {
            return { pattern: true };
          }
        };
    }
}