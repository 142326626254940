import { EquipmentPlanningTable } from '../../db-model/tables/equipment-planning-table';
import { DefinedModelBase } from '../defined-model-base';

export class EquipmentPlanning extends DefinedModelBase {
    eqPlFloorDataId!: number;
    eqPlEquipmentItemId!: number | null;
    eqPlPlanningStateId!: number;
    eqPlMoveCaseTwinId!: number | null;
    eqPlPurchaseCasePurchaseId!: number | null;
    eqPlRemoveCaseDeletionCaseId!: number | null;
    eqPlStoreCaseStorageAreaId!: number | null;

    constructor(dtoData: any, tableDef: any[] = []) {
        super(dtoData, [EquipmentPlanningTable].concat(tableDef));
    }
}