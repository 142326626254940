import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CircleGizmo } from '../../../../model/gizmos/update/circle-gizmo';

@Component({
  selector: 'g[xc-circle-gizmo]',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './circle-gizmo.component.svg',
  styleUrls: ['./circle-gizmo.component.scss']
})
export class CircleGizmoComponent {
  @Input() model: CircleGizmo | undefined;
}
