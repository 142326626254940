import { TablesNamesEnum } from '../tables-names-enum';

export const AddressTable = {
    databaseTableName: TablesNamesEnum.Address,
    adId: 'Ad_Id',
    adName: 'Ad_Name',
    adStreetNumber: 'Ad_StreetNumber',
    adLine1: 'Ad_Line1',
    adLine2: 'Ad_Line2',
    adLine3: 'Ad_Line3',
    adCityId: 'Ad_CityId',
    adCounty: 'Ad_County',
    adCountryId: 'Ad_CountryId',
    adZipCode: 'Ad_ZipCode',
    adScope: 'Ad_Scope'
  };
  