import { CityTable } from '../../db-model/tables/city-table';
import { DefinedModelBase } from '../defined-model-base';
export class City extends DefinedModelBase {
    ciId!: number;
    ciName!: string;
    ciLocation!: string | null;
    ciCountryId!: number;

    constructor(dtoData: any, tableDef: any[] = []) {
        super(dtoData, [CityTable].concat(tableDef));
    }
}