import { FormsModule } from '@angular/forms';
import { RoomFormVM } from './../../model/room-form-vm';
import { CommonModule } from '@angular/common';
import { Component, Input, AfterViewInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MaterialModule } from 'src/app/core/material.module';

@Component({
  standalone: true,
  imports: [CommonModule, MaterialModule, FormsModule],
  selector: 'xc-room-properties-form',
  templateUrl: './room-properties-form.component.html',
  styleUrls: ['./room-properties-form.component.scss']
})
export class RoomPropertiesFormComponent implements AfterViewInit {
  @Input() model: RoomFormVM | undefined;

  constructor(public dialog: MatDialog) { }

  ngAfterViewInit(): void {
    if (this.model) {
      this.model.dialog = this.dialog;
    }
  }
}
