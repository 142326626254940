import { DefinedModelBase } from '../defined-model-base';
import { ProviderCompanyTable } from "../../db-model/tables/provider-company-table";

export class ProviderCompany extends DefinedModelBase {
    prCoId!: number;
    prCoName!: string;
    prCoIsActive!: boolean;
    prCoColor!: string;
    prCoImageUrl!: string;

    constructor(dtoData: any) {
        super(dtoData, [ProviderCompanyTable]);
    }
}