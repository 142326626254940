import { BuildingAllocationDbView } from './../../db-model/views/building-allocation-db-view';
import { DefinedModelBase } from 'src/app/core/model/data-model/defined-model-base';

export class BuildingAllocationView extends DefinedModelBase {
    buSiteId!: number;
    flBuildingId!: number;
    buUnName!: string;
    roAlBusinessUnitId!: number;
    buUnCode!: string;
    buUnColor!: string;
    buUnViBuTree!: string;
    buAlViArea!: number;
    buAlViRoundedArea!: number;

    constructor(dtoData: any) {
        super(dtoData, [BuildingAllocationDbView]);
    }
}