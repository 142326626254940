import { NestedTreeControl } from "@angular/cdk/tree";
import { MatTreeNestedDataSource } from "@angular/material/tree";
import { LayerTreeNode } from "./layer-tree-node.model";
import { FloorModelCategoryEnum } from 'src/app/core/model/data-model/enums/floor-model-category-enum';
import { LayerTreeSet } from "src/app/core/model/data-model/sets/layer-tree-set";
import { FloorBlueprint } from "../../../../blueprint-viewer-content-panel/itself/model/floor-blueprint";
import { FloorTask } from "../../../../shared-model/floor-task";
import { BlueprintLayer } from "../../../../svg-entities/model/layers/blueprint-layer";
import Container from "typedi";
import { FloorModelService } from "src/app/core/services/backend-services/floor-model-service";
import { EventListener } from "src/app/core/events/event-listener";
import { readableUUID } from "src/app/core/events/event-listener-uuid";
import { FloorBlueprintEventsEnum } from "../../../../../container/model/floor-blueprint-events-enum";

export class LayersTreeModel extends EventListener {
    static layersVisibilityChanged = "layersVisibilityChanged";
    static layerTreeRefreshRequestEvent: string = "layerTreeRefreshRequestEvent";

    treeControl = new NestedTreeControl<LayerTreeNode>(node => node.children);
    dataSource = new MatTreeNestedDataSource<LayerTreeNode>();

    blueprint: FloorBlueprint;
    viewSet: LayerTreeSet;
  
    constructor(blueprint: FloorBlueprint, viewSet: LayerTreeSet) {
        super(readableUUID(LayersTreeModel.name));
        this.blueprint = blueprint;
        this.viewSet = viewSet;
        this.set();

        this.addEventListener(FloorBlueprintEventsEnum.blueprintLayersChange, () => {
            if (this.blueprint.hasPlanningTask()) {
                // Injecte le noeud de la nouvelle étude
                this.injectTaskLayers();
            } else {
                this.dataSource.data = [this.dataSource.data[0]];
            }
        });
    }

    set(): void {
        this.loadLayerTree();
    }

    injectTaskLayers(): void {
        const task = this.blueprint.updateTasks[0];
        const taskLayers = this.blueprint.layersController.layers.filter(x=> x.taskId === task.taskId);
        const taskRoot = this.getNode("Etude " + task.taskName, 0);
        taskRoot.setChildren(this.getReversedRootTree(taskLayers));
        taskRoot.visibilityChange = async () => {
            await this.emitEventAsync(LayersTreeModel.layersVisibilityChanged, this.childrenPartiallyVisible());
        }

        this.dataSource.data = this.dataSource.data.concat(taskRoot);
        this.treeControl.expand(taskRoot);

        // Actualise l'état général des calques à partir des user settings
        this.ManageUserOptions(taskRoot);
        this.checkInitialParentsState(taskRoot);
    }

    loadLayerTree(): void {
        const tmp: LayerTreeNode[] = [];

        const currentTaskLayers = this.blueprint.layersController.layers.filter(x=> x.taskId === this.blueprint.taskId);
        const currentRoot = this.getNode("Courant", 0);
        currentRoot.setChildren(this.getReversedRootTree(currentTaskLayers));
        currentRoot.visibilityChange = async () => {
            await this.emitEventAsync(LayersTreeModel.layersVisibilityChanged, this.childrenPartiallyVisible());
        }

        tmp.push(currentRoot);

        // Actualise l'état général des calques à partir des user settings
        this.ManageUserOptions(currentRoot);
        this.checkInitialParentsState(currentRoot);

        this.blueprint.updateTasks.forEach(t => {
            const taskLayers = this.blueprint.layersController.layers.filter(x=> x.taskId === t.taskId);
            const taskRoot = this.getNode("Etude " + t.taskName, 0);
            taskRoot.setChildren(this.getReversedRootTree(taskLayers));
            taskRoot.visibilityChange = async () => {
                await this.emitEventAsync(LayersTreeModel.layersVisibilityChanged, this.childrenPartiallyVisible());
            }

            tmp.push(taskRoot);

            // Actualise l'état général des calques à partir des user settings
            this.ManageUserOptions(taskRoot);
            this.checkInitialParentsState(taskRoot);
        });

        this.dataSource.data = tmp;

        // Le noeud racine est déplié
        this.treeControl.expand(currentRoot);
        const planningNode = currentRoot.children.find(x=> x.id === FloorModelCategoryEnum.Planning);
        if (planningNode) {
            // Le noeud "Implantation" est déplié
            this.treeControl.expand(planningNode);
        }
    }

    checkInitialParentsState(root: LayerTreeNode): void {
        root.children.forEach(c => {
            c.children.forEach(cc => {
                cc.checkChildrenVisibility();
            });
        });
    }

    ManageUserOptions(root: LayerTreeNode): void {
        const leaves = this.getLeaves(root);
        leaves.forEach(l => {
            if (!l.isVisible) {
                l.setVisibility(false, false);
            }
            l.userOptionUpdateRequested = async (id: number, visible: boolean) => {
                const s = Container.get(FloorModelService);
                await s.updateUserOption(id, visible);
            }
        });
    }

    getLeaves(parent: LayerTreeNode): LayerTreeNode[] {
        const result: LayerTreeNode[] = [];
        
        parent.children.forEach(c => {
            if (c.layer) {
                result.push(c);
            } else {
                result.push(...this.getLeaves(c));
            }
        });

        return result;
    }

    // L'arbre est constitué en commençant par les feuilles et en remontant
    // ce qui permet de ne retenir des niveaux supérieurs que ceux qui contiennent des enfants
    getReversedRootTree(taskLayers: BlueprintLayer[]): LayerTreeNode[] {
        const rootTree: LayerTreeNode[] = [];

        // On commence par récupérer les deux derniers niveaux de l'arborescence
        // c'est-à-dire les feuilles et leurs parents
        // leafTree est donc un tableau des parents des feuilles avec les feuilles comme enfants
        const leafTree = this.getReverseLeafTree(taskLayers);
        
        // On isole les parentsIds du tableau
        const parentIds = leafTree.map(x=> x.parentId);
        // Isole les distinct parentIds
        const distinctParentIds = [...new Set(parentIds)];
        distinctParentIds.forEach(id => {
            const parentChildren = leafTree.filter(x=> x.parentId === id);
            if (id != null) {
                const category = this.viewSet.floorModelCategoryView.find(x=> x.flMoCaId === id);
                if (category) {
                    const parentNode = this.getNode(category.flMoCaDisplayName, category.flMoCaId, category.flMoCaId);
                    parentNode.setChildren(parentChildren);
                    rootTree.push(parentNode);
                }
            } else {
                // Si un élément n'a pas de parentId c'est qu'il est un calque racine
                // Ce cas se produit lorsque la feuille n'est pas de profondeur 3 et est traité dans la boucle suivante
            }
        });

        const alreadyRoot = leafTree.filter(x=> x.parentId === null);
        alreadyRoot.forEach(ar => {
            // On recherche l'élément racine correspondant
            const rootItem = rootTree.find(x=> x.id === ar.id);
            if (rootItem != null) {
                rootItem.addChildren(ar.children);
            } else {
                rootTree.push(ar);
            }
        });

        return rootTree.sort((a, b) => a.name.localeCompare(b.name));
    }

    getReverseLeafTree(taskLayers: BlueprintLayer[]): LayerTreeNode[] {
        const leafTree: LayerTreeNode[] = [];

        // Isole les categoryIds des calques du plan
        const categoryIds = taskLayers.map(x=> x.categoryId);
        // Isole les distinct categoryIds
        const distinctCategoryIds = [...new Set(categoryIds)];

        distinctCategoryIds.forEach(id => {
            // Pour chaque identifiant de categorie
            // on isole les calques du plan qui sont dans cette catégorie
            const parentChildren = taskLayers.filter(x=> x.categoryId === id);
            // et on constitue un tableau pour l'affichage
            const nodes: LayerTreeNode[] = [];
            parentChildren.forEach(l => {
                const node = this.getNode(l.name, l.id, l.categoryId, l);
                // Le calque est peut être déjà masqué par défaut ou état enregistré
                node.isVisible = l.isVisible();
                nodes.push(node);
            });
            // On trouve le parent dans la table de référence
            const parentCategory = this.viewSet.floorModelCategoryView.find(x=> x.flMoCaId === id);
            // et on en fait un parent node pour l'affichage
            if (parentCategory) {
                const parentNode = this.getNode(parentCategory.flMoCaDisplayName, parentCategory.flMoCaId, parentCategory.flMoCaParentId);
                // auquel on assigne les noeuds de calque comme enfants
                parentNode.setChildren(nodes.sort((a, b) => a.name.localeCompare(b.name)));
                // Assigne l'état de la checkbox du parent en fonction de celui des enfants
                //parentNode.checkChildrenVisibility();
                leafTree.push(parentNode);
            }
        });

        // On retourne le tableau trié par le nom du calque
        return leafTree.sort((a, b) => a.name.localeCompare(b.name));
    }

    getNode(name: string, id: number, parentId: number | null = null, layer?: BlueprintLayer): LayerTreeNode {
        return new LayerTreeNode(id, name, parentId, layer);
    }

    childrenPartiallyVisible(): boolean {
        let result = false;

        this.dataSource.data.forEach(l => {
            if (l.childrenPartiallyVisible) {
                result = true;
            }
        });
        
        return result;
    }

    hasChild = (_: number, node: LayerTreeNode) => !!node.children && node.children.length > 0;
}
