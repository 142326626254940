import { ChartCaptionItem } from '../../../shared-model/chart-caption-item';
import { StackAnonymousRowData } from '../../../shared-model/stack-anonymous-row-data';
import { StackAnonymousUnitData } from '../../../shared-model/stack-anonymous-unit-data';
import { StackChartDataVM } from './stack-chart-data-wm';
import { stackFloor } from './stack-floor';
import { SvgBsFloor } from './svg-bs-floor';
import { TabPageContent } from 'src/app/components-lib/tab-page-container/model/tab-page-content';
import { readableUUID } from 'src/app/core/events/event-listener-uuid';
import { TabPageContentTypeEnum } from 'src/app/ui/pages/layout/real-estate/tab-page-content-type-enum';
import { logError } from 'src/app/core/services/logging-service';

export class StackChartWrapperVM  extends TabPageContent<StackChartDataVM> {
    floorHeight: number = 20;
    data: StackChartDataVM | undefined;
    onFloorLabelClick?: (floorId: number) => void;
    
    captionVisible: boolean = true;
    captions: ChartCaptionItem[] = [];
    colSpan: number = 1;
    rowSpan: number = 1;

    constructor() {
        super(readableUUID("StackChartWrapperVM"),TabPageContentTypeEnum.donutchart);
    }

    setBuildingStack(buildingId: number, floors: StackAnonymousRowData[]): void {
        this.data = new StackChartDataVM("NA" , "NA");

        this.data.viewBox = "0 0 500 " + floors.length * 20.5;

        this.data.floors.splice(0);
        //const maxArea = Math.max(...floors.map(o => o.flArea))

        //const descFloors = floors.sort((a, b) => b.flElevation - a.flElevation);
        const tmp: stackFloor[] = [];
        let i: number = 0;
        floors.forEach(f => {
            const sf = new stackFloor(f.id, f.name, f.level, f.value, f.rowMaxValue);
            sf.graphics = new SvgBsFloor(0, i * (this.floorHeight + 0.5), 500, 20, sf.name, sf.area, f.rowMaxValue);
            sf.floorLabelClick = () => { 
                if (this.onFloorLabelClick) {
                    this.onFloorLabelClick(sf.id); 
                } else {
                    logError("BuildingStack.onFloorLabelClick n'est pas écouté");
                }
            };

            //const datas = BuildingAnonymousStackChartDataBuilder.getAnatomyData(f.layoutTypes);
            sf.graphics.loadData(f.units);
            this.loadCaptions(f.units);
                
            tmp.push(sf);

            i++;
        });

        this.data.floors = tmp;
    }

    // setBuildingStack(buildingId: number, floors: FloorLayoutTypeDTO[]): void {
    //     this.data = new StackChartDataVM("NA" , "NA");

    //     this.data.viewBox = "0 0 500 " + floors.length * 20.5;

    //     this.data.floors.splice(0);
    //     const maxArea = Math.max(...floors.map(o => o.flArea))

    //     const descFloors = floors.sort((a, b) => b.flElevation - a.flElevation);
    //     const tmp: stackFloor[] = [];
    //     let i: number = 0;
    //     descFloors.forEach(f => {
    //         const sf = new stackFloor(f.flId, f.flCode, f.flElevation, f.flArea, maxArea);
    //         sf.graphics = new SvgBsFloor(0, i * (this.floorHeight + 0.5), 500, 20, sf.name, sf.area, maxArea);
    //         sf.floorLabelClick = () => { 
    //             if (this.onFloorLabelClick) {
    //                 this.onFloorLabelClick(sf.id); 
    //             } else {
    //                 logError("BuildingStack.onFloorLabelClick n'est pas écouté");
    //             }
    //         };

    //         const datas = BuildingAnonymousStackChartDataBuilder.getAnatomyData(f.layoutTypes);
    //         sf.graphics.loadData(datas);
    //         this.loadCaptions(datas);
                
    //         tmp.push(sf);

    //         i++;
    //     });

    //     this.data.floors = tmp;
    // }

    loadCaptions(data: StackAnonymousUnitData[]): void {
        data.forEach(d => {
            if (!this.captions.find(x=> x.id === d.id)) {
                const color = d.color;
                const total = data.filter(x=> x.id === d.id).map(x=> x.value).reduce((a,b) => a + b);
                const newCaption = new ChartCaptionItem(d.id, d.label, total + " " + " m²", color);
                this.captions.push(newCaption);
            }
        });
    }

    enlarge(): void {
        this.colSpan = 5;
        this.rowSpan = 3;
    }

    reduce(): void {
        this.colSpan = 1;
        this.rowSpan = 1;
        this.captionVisible = false;
    }

    captionButtonClick(): void {
        this.captionVisible = !this.captionVisible;
    }

    showIsolateCaptionButtonClick(e: MouseEvent, c: ChartCaptionItem): void {
        this.data?.isolate(c.id, c.hidden);

        if (c.hidden) {
            c.hidden = false;
        } else {
            this.captions.forEach(ca => {
                if (ca.id !== c.id) {
                    ca.hidden = true;
                }
            });
        }
    }

    hideCaptionButtonClick(e: MouseEvent, c: ChartCaptionItem): void {
        c.hidden = true;
        this.data?.hide(c.id);
    }
}