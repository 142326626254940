import { SimulationPhotoTable } from "../../db-model/tables/simulation-photo-table";
import { DefinedModelBase } from "../defined-model-base";

export class SimulationPhoto extends DefinedModelBase {
    siPhId!: number;
    siPhSimulation!: number;
    siPhPhotoDate!: Date;
    siPhIsActive!: boolean;
    siPhName!: string;
    siPhDescription!: string | null;
    siPhSourceId!: number | null;

    constructor(dtoData: any, tableDef: any[] = []) {
        super(dtoData, [SimulationPhotoTable].concat(tableDef));
        // Il faut convertir explicitement la date en Date parce qu'elle arrive nativement comme string
        this.siPhPhotoDate = new Date(this.siPhPhotoDate);
    }
}