import { Point } from "src/app/core/model/geometry-model/point.model";
import { SvgEntityTypesEnum } from "src/app/core/model/svg-model/svg-entity-type-enum";
import { SelectableSvgLine } from "../../svg/selectable-svg-line";
import { LineGizmo } from "../../gizmo/model/line-gizmo";
import { InteractionCommand } from "./interaction-command";
import { SvgEntityPointStyleEnum } from "../../../shared/gizmos/model/svg-entity-point-style-enum";
import { SvgLineService } from "src/app/core/model/svg-model/svg-line-service";
import { Segment } from "src/app/core/model/geometry-model/segment.model";
import { EntityInteraction } from "./entity-interaction";

export class LineInteraction extends EntityInteraction<SelectableSvgLine, LineGizmo> {
    // entityType: SvgEntityTypesEnum = SvgEntityTypesEnum.line;
    // currentCommand: InteractionCommand;
    // selectionSet: SelectableSvgLine[] = [];
    // selectionSetCopy: SelectableSvgLine[] = [];
    // selectionInteraction: SelectionInteraction | undefined;
    // updateGizmo: LineGizmo;

    constructor(command: InteractionCommand, gizmo: LineGizmo) {
        super(SvgEntityTypesEnum.line, command, gizmo);
        // this.currentCommand = command;
        // this.updateGizmo = gizmo;
        // this.selectionSet = gizmo.entities;
        // this.cloneEntities();
    }

    getOriginal(entityId: string): SelectableSvgLine | undefined {
        return this.selectionSetCopy.find(x=> x.entityId === entityId);
    }
    
    override async mouseDown(e: MouseEvent, hitPoint: Point): Promise<boolean> {
        throw new Error("Method not implemented.");
    }
    
    override async mouseMove(e: MouseEvent, hitPoint: Point): Promise<void> {
        if (this.currentCommand.isLineEndpointTranslationCommand()) {
            // Actualise la position courante de la souris pour le contrôle de l'attraction lors du prochain MouseMove
            //this.currentCommand.tempSvgMousePosition = hitPoint;
      
            if (this.currentCommand.selectedGripsOptions.includes(SvgEntityPointStyleEnum.extension)) {
                const single = this.updateGizmo.single;
                if (single) {
                    const l = SvgLineService.geometry(single);
                    let fixedPoint: Point;
                    if (this.currentCommand.gripClicked!.point.equals(single.startPoint)) {
                        fixedPoint = single.endPoint;
                    } else {
                        fixedPoint = single.startPoint;
                    }
                    const initialSegment = new Segment(fixedPoint, this.currentCommand.initialgripClickedPosition!);
                    const opd = hitPoint.orthogonalDistanceTo(initialSegment);
                    if (opd < 0.01) {
                        // Si le pointeur n'est pas trop éloigné de la droite du segment original
                        // on contraint le déplacement en extension puisque l'accrochage est sélectionné
                        hitPoint = initialSegment.getOrthogonalProjection(hitPoint);
                    }
                }
            }
                
            this.updateGizmo.freeTranslateEndpoint(hitPoint, false);

            return;
        }

        if (this.currentCommand.isEntityTranslationCommand() && this.currentCommand.initialSvgMousePosition) {
            const delta = hitPoint.minus(this.currentCommand.initialSvgMousePosition);
            this.updateGizmo.translateLines(delta);
        }
    }
    
    override async mouseUp(e: MouseEvent, hitPoint: Point | undefined): Promise<void> {
        if (this.currentCommand.isLineEndpointTranslationCommand() && this.updateGizmo.single) {
            if (hitPoint) {
                if (this.currentCommand.selectedGripsOptions.includes(SvgEntityPointStyleEnum.extension)) {
                    const single = this.updateGizmo.single;
                    if (single) {
                        const l = SvgLineService.geometry(single);
                        let fixedPoint: Point;
                        if (this.currentCommand.gripClicked!.point.equals(single.startPoint)) {
                            fixedPoint = single.endPoint;
                        } else {
                            fixedPoint = single.startPoint;
                        }
                        const initialSegment = new Segment(fixedPoint, this.currentCommand.initialgripClickedPosition!);
                        const opd = hitPoint.orthogonalDistanceTo(initialSegment);
                        if (opd < 0.01) {
                            // Si le pointeur n'est pas trop éloigné de la droite du segment original
                            // on contraint le déplacement en extension puisque l'accrochage est sélectionné
                            hitPoint = initialSegment.getOrthogonalProjection(hitPoint);
                        }
                    }
                }

                this.updateGizmo.freeTranslateEndpoint(hitPoint, false);
            }

            this.updateGizmo.endFreeTranslateEndpoint();    
            this.cloneEntities();   
            //this.updateGizmo.clearCurrent();   

            return;         
        }

        if (this.currentCommand.isEntityTranslationCommand()) {
            if (hitPoint && this.currentCommand.gripClicked) {
                const delta = hitPoint.minus(this.currentCommand.gripClicked.point);
                this.updateGizmo.translateLines(delta);
            }
            this.updateGizmo.endTranslate();  
            this.cloneEntities();     
            //this.updateGizmo.clearCurrent();   
            return;         
        }
    }
    
    override keyDown(e: KeyboardEvent): Promise<void> {
        throw new Error("Method not implemented.");
    }
    
}