import { Service } from "typedi";
import { ApiControllers, ApiEndpoints } from "../api-endpoints";
import { ApiService } from "../api-service";
import { BuildingAxonoDTO } from "./dto/building-axono-dto";
import { RoomColorMapDTO } from "./dto/room-color-map-dto";

@Service({ global: true })  
export class GraphicsService extends ApiService {
    private axonoEndpoint(buildingId: number): string { return `${ApiControllers.graphics}/axono/${buildingId}` };
    private axonoColorMapEndpoint(buildingId: number, mapType: string): string { return `${ApiControllers.graphics}/colormap/${buildingId}/${mapType}` };

    constructor() {
        super();
    }

    async getAxonoColorMap(buildingId: number, mapType: string): Promise<RoomColorMapDTO[]> {
        const result = await this.getAsync<any[]>(this.endPoint(this.axonoColorMapEndpoint(buildingId, mapType)));
        if (result != null) {
            return result.payload.map(item => {
                return item as RoomColorMapDTO;
            })
        }
        return [];
    }

    async downloadBuildingAxono(buildingId: number): Promise<BuildingAxonoDTO | null> {
        const result = await this.getAsync<any>(this.endPoint(this.axonoEndpoint(buildingId)));
          if (result != null) {
                return new BuildingAxonoDTO(result.payload);
          }
          return null;
    }
}
