import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImageFileField } from '../../shared-model/image-file-field';
import { SecuredImageComponent } from '../../secured-image/secured-image.component';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { toastError } from 'src/app/core/services/toast-service';

@Component({
  selector: 'xc-dyn-image-file-cell',
  standalone: true,
  imports: [CommonModule, SecuredImageComponent, ProgressSpinnerModule],
  templateUrl: './dyn-image-file-cell.component.html',
  styleUrls: ['./dyn-image-file-cell.component.scss']
})
export class DynImageFileCellComponent {
  @Input() model: ImageFileField | undefined;
  @ViewChild('fileInput') file: ElementRef | undefined;

  click(): void {
    if (this.model) {
      //this.model.editing = true;
      this.file?.nativeElement.click();
    }
  }

  async onFileSelected(): Promise<void> {
    if (!this.model) return;
    const files: { [key: string]: File } = this.file?.nativeElement.files;
    // Il ne peut y avoir qu'un seul fichier sélectionné
    const selectedFile = files[0];
    if (this.model.maxSize < selectedFile.size) {
      toastError(`Le fichier sélectionné (${selectedFile.size}Ko) dépasse la taille maximale autorisée : ${this.model.maxSize}Ko`)
    } else {
      await this.model.fileSelected(selectedFile);
    }
  }
}
