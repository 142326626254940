import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DynTableVM } from 'src/app/components-lib/dyn-grid/model/dyn-table-vm';
import { DynTableComponent } from 'src/app/components-lib/dyn-table/dyn-table.component';

@Component({
  selector: 'xc-people-move-list',
  standalone: true,
  imports: [CommonModule, DynTableComponent],
  templateUrl: './people-move-list.component.html',
  styleUrl: './people-move-list.component.scss'
})
export class PeopleMoveListComponent {
  @Input() model: DynTableVM | undefined;
}
