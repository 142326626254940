@if (model) {
    <div class="flex max-h-full w-full justify-content-center p-3">
        @if (model.categoryChildren) {
            <xc-dyn-table class="table" [model]="model.categoryChildren.content" />
        } 
        @else {
            <!-- Liste des items au catalogue pour les catégories de dernier niveau -->
            @if (model.providersList.length > 0) {
                <div class="flex max-h-full w-full justify-content-center p-3">
                    <xc-dyn-table class="table" [model]="model.providerCatalog?.content" />
                </div>
            } @else {
                <span>Il n'y a pas encore de référence pour cette entrée de catalogue</span>
            }
        }
    </div>
}