import { ZDbViewColumnView } from './../../core/model/data-model/views/z-db-view-column-view';
import { FieldSet } from './field-set';
import { ZConstraintTypeEnum } from "src/app/core/model/data-model/enums/z-constraint-type-enum";
import { ZDbTypeEnum } from "src/app/core/model/data-model/enums/z-db-type-enum";
import { ZColumnConstraintView } from "src/app/core/model/data-model/views/z-column-constraint-view";
import { FieldTypeEnum } from "./field-type-enum";
import { ZColumnView } from 'src/app/core/model/data-model/views/z-column-view';
import { BooleanField } from './boolean-field';
import { StringField } from './string-field';
import { ListField } from './list-field';
import { IModelBase } from 'src/app/core/model/data-model/i-model-base';
import { Field } from './field';
import { NumberField } from './number-field';
import { DateField } from './date-field';
import { ColorField } from './color-field';
import { ImageFileField } from './image-file-field';

export class FieldBuilder {
    static getComponentTypeFromDbType(columnName: string, dbType: ZDbTypeEnum, constraints: ZColumnConstraintView[]): FieldTypeEnum {
        const isListType = constraints.find(x=> x.coCoColumnName === columnName && x.coCoConstraintType === ZConstraintTypeEnum.List) !== undefined;
        if (isListType) return FieldTypeEnum.list;

        switch (dbType) {
            case ZDbTypeEnum.DecimalDecimal:
            case ZDbTypeEnum.DoubleDouble:
            case ZDbTypeEnum.Int32Int:
            case ZDbTypeEnum.Int64Int:
                return FieldTypeEnum.number;
            case ZDbTypeEnum.StringVarchar:
            case ZDbTypeEnum.StringText:
            case ZDbTypeEnum.StringChar:
                return FieldTypeEnum.string;
            case ZDbTypeEnum.ColorChar:
                return FieldTypeEnum.color;
            case ZDbTypeEnum.BooleanTinyint:
                return FieldTypeEnum.boolean;
            case ZDbTypeEnum.DateTimeDatetime:
            case ZDbTypeEnum.DateTimeTimestamp:
                return FieldTypeEnum.date;
            case ZDbTypeEnum.ImageFile:
                return FieldTypeEnum.imageFile;
            default:
                return FieldTypeEnum.string;
        }
    }

    static getConstraint<T>(constraints: ZColumnConstraintView[], constraintType: ZConstraintTypeEnum, defaultValue: T): T {
        const c = constraints.find(x=> x.coCoConstraintType === constraintType);
        if (c) return c.coCoValue as T;
        return defaultValue;
    }

    static getPrimary(definitions: ZColumnView[]): string {
        const primary = definitions.find(x => x.dbCoIsPrimaryKey === true);
        if (primary) return primary.dbCoColumnName;
        return "";
    }

    static getSetFromModelObject(item: IModelBase, definitions: ZColumnView[] | ZDbViewColumnView[], constraints: ZColumnConstraintView[], readOnlyByScope: boolean): FieldSet {
        const result = new FieldSet();
        const fields: Field<any>[] = [];

        const primary = FieldBuilder.getPrimary(definitions);
        const rowId = item.getValue(primary);

        result.setPrimary(primary, rowId);

        definitions.forEach(d => {
            let realColumnName = d.dbCoColumnName;
            
            if (d instanceof ZDbViewColumnView) {
                const alias = d.dbViewColumn.dbViCoColumnAlias;
                if (alias) {
                    realColumnName = alias;
                }
            }

            const value = item.getValue(realColumnName);
            const cnstrnts = constraints.filter(x=> x.coCoColumnName === realColumnName);

            const fieldType = FieldBuilder.getComponentTypeFromDbType(realColumnName, d.dbCoType, constraints)
            switch (fieldType) {
                case FieldTypeEnum.boolean:
                    fields.push(new BooleanField(realColumnName, rowId, Boolean(value), d, cnstrnts, readOnlyByScope));
                    break;
                case FieldTypeEnum.string:
                    fields.push(new StringField(realColumnName, rowId, String(value), d, cnstrnts, readOnlyByScope));
                    break;
                case FieldTypeEnum.number:
                    fields.push(new NumberField(realColumnName, rowId, Number(value), d, cnstrnts, readOnlyByScope));
                    break;
                case FieldTypeEnum.color:
                    fields.push(new ColorField(realColumnName, rowId, String(value), d, cnstrnts, readOnlyByScope));
                    break;
                case FieldTypeEnum.date:
                    fields.push(new DateField(realColumnName, rowId, this.getDate(value), d, cnstrnts, readOnlyByScope));
                    break;
                case FieldTypeEnum.list:
                    fields.push(new ListField(d.dbCoColumnName, rowId, value, d, cnstrnts, readOnlyByScope));
                    break;
                case FieldTypeEnum.imageFile:
                    fields.push(new ImageFileField(d.dbCoColumnName, rowId, String(value), d, cnstrnts, readOnlyByScope));
                    break;
           
                default:
                    break;
            }
        });

        result.setFields(fields, readOnlyByScope);
        return result;
    }

    static getDate(dbDate: string | null): Date | null {
        if (dbDate == null) return null;
        if (!dbDate.endsWith('Z')) dbDate = dbDate + ".000Z";
        const result = new Date(dbDate);
        return result;
    }
}