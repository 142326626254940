@if (model) {
<div class="editor-main">
    <div class="editor-props">
        @if (model.reportGrid) {
<xc-report-grid class="editor-report-grid card" [model]="model.reportGrid"/>
}
        @if (model.itemId != null) {
<xc-property-grid class="editor-property-grid card" [model]="model.propertyGridVM"/>
} 
    </div>
    <xc-dyn-table [model]="model.adminTable"/>
</div>
}
