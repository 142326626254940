import { DxfLayerDTO } from "src/app/core/services/backend-services/dto/dxf-layer-dto";
import { InteractionCommand } from "../interaction-command";
import { IInsertInteraction } from "./i-insert-interaction";
import { SvgEntityTypesEnum } from "src/app/core/model/svg-model/svg-entity-type-enum";
import { InsertInteraction } from "./insert-interaction";
import { Point } from "src/app/core/model/geometry-model/point.model";
import { PathInsertGizmo } from "../../gizmos/insert/path-insert-gizmo";
import { DxfSvgPath } from "../../svg/dxf-svg-path";
import { logError } from "src/app/core/services/logging-service";

export class PathInsertInteraction extends InsertInteraction implements IInsertInteraction {
    entityType: SvgEntityTypesEnum = SvgEntityTypesEnum.path;
    gizmo: PathInsertGizmo;

    constructor(command: InteractionCommand, layers: DxfLayerDTO[]) {
        super(command, layers);
        this.currentCommand = command;
        this.layers = layers;
        this.gizmo = new PathInsertGizmo();
        this.gizmo.insertionCompleted = () => {
            if (this.insertionCompleted) {
                const currentLayer = this.layers.find(x=> x.current);
                if (currentLayer) {
                    this.insertionCompleted(DxfSvgPath.fromValues(currentLayer.dxLaId, this.gizmo.getGlobalStatement(), 0.01));
                }
            } else {
                logError("PathInsertGizmo.insertionCompleted n'est pas écouté");
            }
        }
    }

    define(point: Point | undefined): void {
        this.gizmo.define(point?.rounded());
    }

    async mouseDown(e: MouseEvent, hitPoint: Point): Promise<boolean> {
        throw new Error("Method not implemented.");
    }

    async mouseMove(e: MouseEvent, hitPoint: Point): Promise<void> {
        this.gizmo.update(hitPoint.rounded());
    }

    async mouseUp(e: MouseEvent, hitPoint: Point | undefined): Promise<void> {
        throw new Error("Method not implemented.");
    }

    async keyDown(e: KeyboardEvent): Promise<void> {
        switch (e.key.toLowerCase()) {
            case "a":
                this.gizmo.setCurrentType(SvgEntityTypesEnum.path);
                break;
            case "l":
                this.gizmo.setCurrentType(SvgEntityTypesEnum.line);
                break;
            case "c":
                this.gizmo.close();
                break;
            case " ":
            case "enter":
                this.gizmo.end();
                break;
            default:
                break;
        }
    }
}