import { SvgDOM } from './svg-dom';
import { Container } from 'typedi';
import { FloorModelEnum } from "src/app/core/model/data-model/enums/floor-model-enum";
import { Point } from "src/app/core/model/geometry-model/point.model";
import { EditableLayer } from "./editable-layer";
import { InteractionCommand } from "./interaction-command";
import { LayerInteraction } from "./layer-interaction";
import { SelectionInteraction } from "./selection-interaction";
import { LabelingFormModel } from '../../../../blueprint-viewer-side-panel/subitems/labeling/model/labeling-form-model';
import { WorkplaceFormModel } from '../../../../blueprint-viewer-side-panel/subitems/properties/model/workplace-form-model';
import { CounterResult } from '../../services/dto/counter-result';
import { FloorBlueprint } from '../floor-blueprint';
import { PeopleLocationDisplay } from '../../../../dialog/workplace-allocation-editor/model/people-location-display';
import { BpSvgWorkplaceLabel } from '../../../../svg-entities/model/bp-svg-workplace-label';
import { BlueprintEquipmentLayer } from '../../../../svg-entities/model/layers/blueprint-equipment-layer';
import { WorkplaceUpdateGizmo } from '../../../subitems/gizmos/workplace-update-gizmo/model/workplace-update-gizmo.model';
import { EntitiesSelectionSet } from './entities-selection-set';
import { DyntService } from 'src/app/core/services/backend-services/dynt-service';
import { TablesSetsEnum } from 'src/app/core/model/data-model/tables-sets-enum';
import { WorkplaceAllocationViewSet } from 'src/app/core/model/data-model/view-set/workplace-allocation-view-set';
import { PeopleLocationDbView } from 'src/app/core/model/db-model/views/people-location-db-view';
import { PeopleLocationTypeEnum } from 'src/app/core/model/data-model/enums/people-location-type-enum';
import { PeopleLocationViewSet } from 'src/app/core/model/data-model/view-set/people-location-view-set';
import { DirectoryService } from 'src/app/core/services/backend-services/directory-service';
import { EventEmitter } from 'src/app/core/events/event-emitter';
import { LayersTreeModel } from '../../../../blueprint-viewer-side-panel/subitems/layer-tree/model/layers-tree-model';
import { WorkplaceService } from 'src/app/core/services/backend-services/workplace-service';
import { logError } from 'src/app/core/services/logging-service';

export class WorkplaceInteraction extends EventEmitter implements LayerInteraction {
    layerId = FloorModelEnum.WorkplaceLabels;
    layerName: string = "NA";
    selectionSet: EntitiesSelectionSet<BpSvgWorkplaceLabel> = new EntitiesSelectionSet();

    isEditable: boolean;

    floorBlueprint: FloorBlueprint;
    editableLayers: EditableLayer[] = [];
    currentCommand: InteractionCommand;

    selectionInteraction: SelectionInteraction | undefined;
    selectionCount: number = 0;

    labelingForm: LabelingFormModel | undefined;
    propertiesForm: WorkplaceFormModel;
    updateGizmo: WorkplaceUpdateGizmo;

    constructor(command: InteractionCommand, blueprint: FloorBlueprint, editableLayers: EditableLayer[]) {
        super();

        this.floorBlueprint = blueprint;
        this.editableLayers = editableLayers;
        this.currentCommand = command;

        this.isEditable = editableLayers.find(x=> x.layerId === this.layerId) != null;

        const layer = editableLayers.find(x=> x.layerId === this.layerId);
        if (layer) {
            this.layerName = layer.layerName;
        }
        
        this.propertiesForm = new WorkplaceFormModel();
        this.updateGizmo = new WorkplaceUpdateGizmo();
        this.listenToGizmoOverlayMouseDown();
        this.listenToGizmoOverlayAllocationsChange();
        this.listenToThemableDataChange();
    }

    async activate(labels: BpSvgWorkplaceLabel[], toRemove: boolean): Promise<void> {
        this.selectionSet.addOrRemove(labels, !toRemove);
        this.selectionSet.setChanged = () => this.onSelectionSetChanged();

        this.updateGizmo.show(this.selectionSet);
        await this.propertiesForm.initialize(this.selectionSet, this.floorBlueprint.hasPlanningTask(), this.isEditable);
    }

    deactivate(): void {
        this.selectionSet.clear();
    }

    abort(): void {
        if (this.currentCommand.isWorkplaceTranslationCommand()) {
            this.updateGizmo.cancelTranslation();
            return;
        }

        if (this.currentCommand.isWorkplaceInsertionCommand() ! || this.currentCommand.isWorkplaceRenumberCommand()) {
            this.endInsert();
        }

        this.updateGizmo.hide();
        this.currentCommand.clear();

        this.updateGizmo.hide();

        this.labelingForm?.reset();
    }

    beginInsert(labelingForm: LabelingFormModel): void {
        this.labelingForm = labelingForm;
        this.currentCommand.set(InteractionCommand.workplaceInsertionCommand);
        this.updateGizmo.showTransient(labelingForm.workplaceInsertCode);
    }

    beginRenumber(labelingForm: LabelingFormModel): void {
        this.labelingForm = labelingForm;
        this.currentCommand.set(InteractionCommand.workplaceRenumberCommand);
        this.updateGizmo.showTransient(labelingForm.workplaceInsertCode);
    }

    endInsert(): void {
        this.currentCommand.clear();
        // Réactive le curseur de sélection sur les items de l'étude
        const topMostTaskId = this.floorBlueprint.topMostTaskId();
        this.floorBlueprint.layersController.updateCursor(this.editableLayers, topMostTaskId, true);
        this.updateGizmo.hideTransient();
        this.emitEventAsync(LayersTreeModel.layerTreeRefreshRequestEvent);
    }

    // setLabelingForm(): void {
    //     this.labelingForm!.workplaceRenumberRequest = (code: string, refine: boolean) => {
    //         // Désactive le curseur de sélection sur les items de l'étude
    //         const topMostTaskId = this.floorBlueprint.topMostTaskId();
    //         this.floorBlueprint.layersController.updateCursor(this.editableLayers, topMostTaskId, false);
    //         // Active le curseur d'insertion sur les positions de travail
    //         this.floorBlueprint.layersController.setCursor(topMostTaskId, [FloorModelEnum.WorkplaceLabels], "cell");

    //         this.currentCommand.set(InteractionCommand.workplaceRenumberCommand);
    //         this.updateGizmo.showTransient(code);
    //     }
    // }

    selectionDeleted?: () => void;
    raiseSelectionDeleted(): void {
        if (this.selectionDeleted) {
            this.selectionDeleted();
        }
    }

    onSelectionSetChanged(): void {
        this.propertiesForm.selectionSetUpdate();
        this.updateGizmo.selectionSetUpdate();
    }

    async deleteSelectedItems(): Promise<void> {
        const wl = this.floorBlueprint.layersController.workplacesLabelsLayer(this.floorBlueprint.topMostTaskId());
        if (!wl) {
            logError("WorkplaceInteraction.deleteSelectedItems : impossible de récupérer le calque des positions de travail");
            return;
        }

        // Les positions de travail supprimées sur le plan courant sont réellement supprimées
        // et dans ce cas il n'y a pas de gestion d'état puisqu'on n'est pas dans une étude
        if (this.selectionSet != null) {
            const ids = this.selectionSet.items.map(x=> x.floorDataId);
            const ws = Container.get(WorkplaceService);
            const result = await ws.deleteWorkplaces(ids);
            if (result != null) {
                // Supprime les étiquettes de personne de l'affichage si nécessaire
                // elles ont déjà été supprimées de la base par cascade en même temps que les positions de travail
                const pl = this.floorBlueprint.layersController.peopleLabelsLayer(this.floorBlueprint.topMostTaskId());
                if (pl) {
                    const people = pl.typedData().filter(x=> x.workplace === undefined || !ids.includes(x.workplace.dataSet.woFloorDataId));
                    pl.data = people;
                }

                if (this.floorBlueprint.hasPlanningTask()) {
                    // On est dans une étude, on actualise les états
                    this.selectionSet.items.forEach(label => {
                        const resultItem = result.find(x=> x.id === label.floorDataId);
                        if (resultItem) {
                            if (resultItem.dataStateId == null) {
                                // La position de travail avait été créé dans l'étude, elle a donc été réellement supprimée de la base
                                // on la supprime du plan
                                wl.remove(resultItem.id);
                            } else {
                                label.updateState(resultItem);
                            }
                        }
                    });

                    // On actualise l'affichage

                    // et on demande l'actualisation du thème s'il est affiché
                    this.askForThemerefresh();
                } else {
                    // On n'est pas dans une étude, on supprime la position de travail
                    this.selectionSet.items.forEach(label => {
                        wl.remove(label.floorDataId);
                    });
                    // if (this.floorBlueprint.workplacesLabelsLayer(this.floorBlueprint.taskId).remove(this.updateGizmo.selectedLabels[0].floorDataId)) {
                    //     this.deactivate();
                    // } else {
                    //     // Normalement, le cas inverse ne devrait pas se produire en prod
                    //     // en dev ça permet juste de s'assurer que les entités sont correctement réparties avec leurs ids
                    //     alert("Anomalie sur le svg : impossible de retirer la position de travail")
                    // }
                }

                this.selectionSet.clear();
                this.raiseSelectionDeleted();
            }
        }
    }

    listenToGizmoOverlayMouseDown(): void {
        this.updateGizmo.overlayMouseDown = (e: MouseEvent) => {
            this.currentCommand.set(InteractionCommand.workplaceTranslateCommand);
            const hitPoint = SvgDOM.getPointPosition(e.clientX, e.clientY);
            if (hitPoint) {
                if (this.selectionSet.count === 1 ) {
                    const uniqueLabel = this.selectionSet.items[0];
                    const peopleLayerData = this.floorBlueprint.layersController.peopleLabelsLayer(this.floorBlueprint.topMostTaskId())?.typedData();
                    const peopleLabels = peopleLayerData?.filter(x=> x.parentId === uniqueLabel.floorDataId);
                    if (peopleLabels) {
                        this.updateGizmo.initializeTranslation(hitPoint, peopleLabels);
                    }
                }
            }
        }
    }

    listenToGizmoOverlayAllocationsChange(): void {
        this.updateGizmo.allocationsChange = async (e: PeopleLocationDisplay[]) => {
            if (this.selectionSet.count !== 1) {
                throw "Erreur non gérée : le changement d'allocation ne peut être effectué que sur une sélection unitaire";
            }
    
            const uniqueLabel = this.selectionSet.items[0] as BpSvgWorkplaceLabel;
            const taskId = uniqueLabel.taskId;
            const currentAllocations = uniqueLabel.workplaceAllocations != null ? uniqueLabel.workplaceAllocations : [];
            const deletedIds: string[] = [];
            const was = Container.get(DirectoryService);
            // Enregistrement des changements différentiels
            currentAllocations.forEach(async ca => {
                const newAllocation = e.find(x=> x.directoryId === ca.dataSet.peLoDirectoryId);
                if (newAllocation == null) {
                    // L'allocation courante n'a pas été trouvée dans les nouvelles allocations
                    // elle doit donc être supprimée
                    deletedIds.push(ca.combinedId());
                } else {
                    if (ca.dataSet.peLoRatio !== newAllocation.ratio) {
                        // Le ratio d'occupation de l'allocation courante a été modifié
                        ca.dataSet.peLoRatio = newAllocation.ratio;
                        await was.updatePeopleLocationRatio(ca.dataSet.peLoFloorDataId, ca.dataSet.peLoDirectoryId, newAllocation.ratio);
                    }
                }
            });

            if (deletedIds.length > 0) {
                await was.deleteAllocations(deletedIds);
            }
            deletedIds.forEach(id => {
                // Actualise le tableau d'allocations sur l'étiquette de position de travail
                const allocationIndex = currentAllocations.findIndex(x=> x.combinedId() === id);
                currentAllocations.splice(allocationIndex, 1);
                // Retire l'étiquette de la personne sur le plan
                const peopleLabelId = Number(id.split('-')[0]);
                this.floorBlueprint.layersController.peopleLabelsLayer(this.floorBlueprint.topMostTaskId())?.remove(peopleLabelId);
            });
    
            e.forEach(async na => {
                const currentAllocation = currentAllocations.find(x=> x.dataSet.peLoDirectoryId === na.directoryId);
                if (currentAllocation == null) {
                    // La nouvelle allocation n'a pas été trouvée dans les allocations courantes
                    // elle doit donc être créée
                    const createResult = await was.createPeopleLocation(uniqueLabel.floorDataId, PeopleLocationTypeEnum.Workplace, na, taskId);
                    if (createResult != null && !(createResult instanceof PeopleLocationViewSet)) {
                        // Actualise le tableau d'allocations sur l'étiquette de position de travail
                        currentAllocations.push(createResult.allocation);
                        // Crée le calque si nécessaire
                        let peopleLayer = this.floorBlueprint.layersController.peopleLabelsLayer(this.floorBlueprint.topMostTaskId());
                        if (peopleLayer == null && createResult.layer) {
                            peopleLayer = createResult.layer;
                            peopleLayer.taskId = this.floorBlueprint.topMostTaskId();
                            this.floorBlueprint.layersController.insertLayer(peopleLayer);
                        }
                        if (peopleLayer) {
                            // Ajoute l'étiquette de la personne sur le plan
                            peopleLayer.insert(createResult.label);
                            // Ajoute la référence à la position de travail
                            createResult.label.workplace = uniqueLabel.workplace;
                            // Rend l'étiquette sélectionnable
                            peopleLayer.setItemsSelectable(true);
                        }
                    }
                }
            });

            // Recharge les allocations de la position de travail
            const ds = Container.get(DyntService);
            const reloadedAllocations = await ds.downloadTable<WorkplaceAllocationViewSet>(PeopleLocationDbView.databaseTableName, 
                TablesSetsEnum.WorkplaceAllocationViewSet, 
                PeopleLocationDbView.parentId,
                uniqueLabel.floorDataId);
            uniqueLabel.workplaceAllocations = reloadedAllocations;
        }
    }

    listenToThemableDataChange(): void {
        this.propertiesForm.themeRefreshRequested = () => {
            this.askForThemerefresh();
        }
    }

    refreshThemeNeeded?: () => void;
    askForThemerefresh(): void {
        setTimeout(() => {
            if (this.refreshThemeNeeded) {
                this.refreshThemeNeeded();
            }
        }, 0);
    }

    async insert(svgPoint: Point, furnitureId: number): Promise<void> {
        if (this.labelingForm?.workplaceInsertCode == null) {
            return;
        }
        
        const ws = Container.get(WorkplaceService);
        const result = await ws.insert(furnitureId, svgPoint, this.labelingForm.workplaceInsertCode, this.labelingForm.workplaceInsertType);
        if (result != null) {
            let workplaceLabelLayer = this.floorBlueprint.layersController.workplacesLabelsLayer(this.floorBlueprint.topMostTaskId());
            if (!workplaceLabelLayer && result.layer) {
                // Se produit lorsqu'il n'y a pas encore de position de travail sur le plan et dans ce cas les datas de retour
                // contiennent le calque à insérer
                workplaceLabelLayer = result.layer;
                this.floorBlueprint.layersController.insertLayer(workplaceLabelLayer);
            }
            workplaceLabelLayer?.insert(result.label);
        }
    }

    // beginWorkplaceInsertCommand(): void {
    //     this.currentCommand.name = PocUserInteractionCommand.workplaceInsertionCommand;
    // }

    // endWorkplaceInsert(): void {
    //     this.currentCommand.clear();
    //     // Réactive le curseur de sélection sur les items de l'étude
    //     const topMostTaskId = this.floorBlueprint.topMostTaskId();
    //     this.floorBlueprint.updateCursor(this.editableLayers, topMostTaskId, true);
    // }

    async getNextCodeValue(currentValue: string, updateDisplay: boolean): Promise<CounterResult | null> {
        const ws = Container.get(WorkplaceService);
        const result = await ws.getNextCodeValue(currentValue, 1);
        if (this.labelingForm && result != null && result.resultError == null) {
            if (updateDisplay) {
                this.labelingForm.workplaceInsertCode = result.resultValue;
                if (this.updateGizmo && this.updateGizmo.transientLabel) {
                    this.updateGizmo.transientLabel.text = this.labelingForm.workplaceInsertCode;
                }
            }
        }
        return result;
    }

    async renumberAfter(startValue: number, newValue: string, workplaces: BpSvgWorkplaceLabel[]): Promise<void> {
        const higher = workplaces.filter(x=> x.numericValue() != null && x.numericValue() > startValue ).sort((a, b) => a.numericValue() - b.numericValue());
        let currentValue = startValue;
        if (higher.length > 0) {
            const ws = Container.get(WorkplaceService);
            const counterResult = await ws.getNextCodeValues(newValue, 1, higher.length);
            if (counterResult != null) {
                for (let i = 0; i < higher.length; i++) {
                    const w = higher[i];
                    const c = counterResult[i];

                    if (w.numericValue() === currentValue + 1 || this.labelingForm?.workplaceRenumberRefine) {
                        currentValue = w.numericValue();
                        if (c.resultError == null) {
                            const update = await ws.updateLabel(w.floorDataId, c.resultValue)
                            if (update != null) {
                                w.updateCode(c.resultValue, update.dataStateId);
                            }
                        }
                    }
                }
            }
        }
    }

    async mouseDown(e: MouseEvent, hitPoint: Point): Promise<boolean> {
        // Il s'agit du clic d'insertion de position de travail
        if (this.currentCommand.isWorkplaceInsertionCommand()) {
            // On regarde s'il y a un élément de mobilier au point cliqué
            // TODO : ajouter un contrôle sur la catégorie pour ne pouvoir insérer de position de travail
            // que sur les tables et les sièges
            const itemAtClientPoint = this.floorBlueprint.layersController.getSvgEntityFromClientPoint(e.clientX, e.clientY);
            if (itemAtClientPoint != null && itemAtClientPoint.floorModelId === FloorModelEnum.Furniture) {
                // La position de travail est insérée immédiatement
                await this.insert(hitPoint, itemAtClientPoint.floorDataId);
    
                // La commande continue jusqu'à l'utilisation de la touche Escape si le user a demandé une insertion multiple
                if (!this.labelingForm?.workplaceInsertCycle) {
                    this.endInsert();
                } else {
                    const counterResult = await this.getNextCodeValue(this.labelingForm.workplaceInsertCode, true);
                    if (counterResult == null || counterResult.resultError != null) {
                        // Si l'incrémentation a échoué, on termine la commande
                        this.endInsert();
                    }
                }
            }

            this.askForThemerefresh();
    
            return true;
        }
        
        if (this.currentCommand.isWorkplaceRenumberCommand()) {
            // Masque immédiatement le transient label
            this.updateGizmo.hideTransient();

            // On regarde s'il y a une position de travail au point cliqué
            const itemAtClientPoint = this.floorBlueprint.layersController.getSvgEntityFromClientPoint(e.clientX, e.clientY);
            if (itemAtClientPoint != null && itemAtClientPoint.floorModelId === FloorModelEnum.WorkplaceLabels && this.labelingForm) {
                // Le parent d'une position de travail est un élément de mobilier
                const parentId = itemAtClientPoint.parentId;
                const furnitureLayer = this.floorBlueprint.layersController.layer<BlueprintEquipmentLayer>(FloorModelEnum.Furniture, this.floorBlueprint.topMostTaskId());
                if (furnitureLayer) {
                    const furnitureItem = furnitureLayer.data.find(x=> x.floorDataId === parentId);
                    if (furnitureItem) {
                        // La parent d'un élément de mobilier est la surface
                        const roomId = furnitureItem.parentId;
                        // Récupère toutes les positions de travail de la surface parente
                        const workplaces = this.floorBlueprint.layersController.getRoomWorkplaces(roomId!, this.floorBlueprint.topMostTaskId());
                        // La position de travail cliquée
                        const hitWorkplace = workplaces.find(x=>x.floorDataId === itemAtClientPoint.floorDataId);
                        if (hitWorkplace) {
                            let currentNumericValue = hitWorkplace.numericValue();
                            if (currentNumericValue != null) {
                                // Pour le moment, cet outil ne fonctionne qu'avec les codes numériques
                                // on commence par renuméroter les étiquettes dont le numéro est supérieur
                                await this.renumberAfter(currentNumericValue, this.labelingForm.workplaceInsertCode, workplaces);
                            }
                            // Renumérote l'étiquette cliquée
                            const ws = Container.get(WorkplaceService);
                            const update = await ws.updateLabel(itemAtClientPoint.floorDataId, this.labelingForm.workplaceInsertCode)
                            if (update != null) {
                                hitWorkplace.updateCode(this.labelingForm.workplaceInsertCode, update.dataStateId);
                            }
                        }
                    }
                }
            }

            // La commande est unitaire et termine immédiatement, même si elle a échoué quelque part
            this.currentCommand.clear();
            // Réactive le curseur de sélection sur les items de l'étude
            const topMostTaskId = this.floorBlueprint.topMostTaskId();
            this.floorBlueprint.layersController.updateCursor(this.editableLayers, topMostTaskId, true);

            return true;
        }

        return false;
    }

    mouseMove(e: MouseEvent, hitPoint: Point): void {
        // La commande d'insertion de position de travail n'utilise pas le mousemove
        if (this.currentCommand.isWorkplaceInsertionCommand() || this.currentCommand.isWorkplaceRenumberCommand()) {
            this.updateGizmo.moveTransientLabel(hitPoint);
            return;
        }

        if (this.currentCommand.isWorkplaceTranslationCommand()) {
            // Déplace l'étiquette
            this.updateGizmo.translate(hitPoint);
            return;
        }
    }

    async mouseUp(e: MouseEvent): Promise<void> {
        // La commande d'insertion de position de travail n'utilise pas le mouseup
        if (this.currentCommand.isWorkplaceInsertionCommand()) {
            return;
        }

        // Enregistre le déplacement
        if (this.currentCommand.isWorkplaceTranslationCommand()) {
            const uniqueLabel = (this.selectionSet.items[0] as BpSvgWorkplaceLabel)
            const initialPosition = uniqueLabel.initialTextPosition;
            const currentPosition = uniqueLabel.text.position();
            const finalDeltaTranslation = new Point(currentPosition.x - initialPosition.x, currentPosition.y - initialPosition.y).vector();
            const ws = Container.get(WorkplaceService);
            const result = await ws.updatePosition(uniqueLabel.floorDataId, finalDeltaTranslation);
            if (result != null) {
                if (this.floorBlueprint.hasPlanningTask()) {
                    // S'il y a une étude chargée, la translation a nécessairement eu lieu sur les positions de travail de l'étude
                    // il faut donc actualiser le floorDataState
                    uniqueLabel.dataStateId = result[0].dataStateId;
                }
            } else {
                this.updateGizmo.cancelTranslation();
            }
        }

        this.currentCommand.clear();
    }

    async keyDown(e: KeyboardEvent): Promise<void> {
        
    }
}

