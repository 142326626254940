import { AfterViewInit, Component, Input } from '@angular/core';
import { MVVMEventDestroyLifeCycle } from 'src/app/components-lib/shared-model/mvvm-events-destroy-lifecycle';
import { MobilityContributors } from '../../model/mobility-contributors';
import { MatDialog } from '@angular/material/dialog';
import { DynTableComponent } from 'src/app/components-lib/dyn-table/dyn-table.component';
import { logError } from 'src/app/core/services/logging-service';

@Component({
  selector: 'xc-mobility-contributors',
  standalone: true,
  imports: [DynTableComponent],
  templateUrl: './mobility-contributors.component.html',
  styleUrl: './mobility-contributors.component.scss'
})
export class MobilityContributorsComponent extends MVVMEventDestroyLifeCycle implements AfterViewInit {
  @Input() override model: MobilityContributors | undefined;

  constructor(protected dialog: MatDialog) {
    super();
  }

  ngAfterViewInit(): void {
    // Injecte le matDialog dans le modèle
    if (this.model) {
      this.model.dialog = this.dialog;
    } else {
      logError("ReferentialsManageComponent : model.dialog n'a pas pu être initialisé");
    }
  }
}
