import { BusinessUnit } from './../tables/business-unit';
import { Directory } from './../tables/directory';
import { getView } from '../views/view-builder';

export class DirectoryViewSet {
    dataSet: (Directory & BusinessUnit);
    InvariantFirstName!: string;
    InvariantLastName!: string;

    constructor(dtoData: any) {
        this.dataSet = getView(dtoData, Directory, BusinessUnit);
    }
}