import { Service } from "typedi";
import { ApiService } from "../api-service";
import { ApiControllers } from "../api-endpoints";
import { DxfLayerDTO } from "./dto/dxf-layer-dto";
import { DxfSvgLine } from "src/app/ui/pages/graphic-works/blueprint/model/svg/dxf-svg-line";
import { DxfEntity } from '../../model/data-model/tables/dxf-entity';
import { DxfSvgEntity } from 'src/app/ui/pages/graphic-works/blueprint/model/svg/dxf-svg-entity';

@Service({ global: true })  
export class DxfService extends ApiService {
    private getImportsEndpoint: string = `${ApiControllers.dxf}/imports`;
    private getImportEndpoint(importId: number): string  { return `${ApiControllers.dxf}/importlayers/${importId}` };
    private insertEndpoint: string = `${ApiControllers.dxf}/insert`;
    private entitiesUpdateEndpoint: string = `${ApiControllers.dxf}/entities`;

    constructor() {
        super();
    }

    async getImportLayers(importId: number): Promise<DxfLayerDTO[]> {
        const result = await this.getAsync<any[]>(this.endPoint(this.getImportEndpoint(importId)));
        if (result != null) {
            return result.payload.map(item => {
                return new DxfLayerDTO(item);
            })
        }
        return [];
    }

    async insertEntity(layerId: number, statement: string, entityTypeId: number): Promise<DxfEntity | null> {
        const result = await this.postAsync<DxfEntity>(this.endPoint(this.insertEndpoint), {layerId: layerId, statement: statement, entityTypeId: entityTypeId });
        if (result != null) {
            return new DxfEntity(result.payload);
        }

        return null;
    }

    async update(entities: DxfSvgEntity[]): Promise<boolean> {
        const body: {id: number, statement: string}[] = [];
        entities.forEach(e => {
            body.push({id: e.entityId, statement: e.statement})
        });
        const result = await this.patchAsync<DxfEntity[]>(this.endPoint(this.entitiesUpdateEndpoint), body);
        return result != null;
    }
}