export class Paginator {
    totalItemsCount: number;
    pageItemsCount: number;
    pageIndex: number = 0;
    pageSizeOptions: number[] = [5, 10, 25];
    pageReport: string = "";

    constructor(dtoData: any) {
        this.totalItemsCount = dtoData.totalItemsCount;
        this.pageItemsCount = dtoData.pageItemsCount;
        // Il peut arriver que pageItemsCount ne se trouve pas dans la liste des options
        // si on a changé cette liste après coup par exemple
        // dans ce cas il faut ajouter la valeur à la liste des options
        if (!this.pageSizeOptions.includes(this.pageItemsCount)) {
            this.pageSizeOptions.push(this.pageItemsCount);
            this.pageSizeOptions = this.pageSizeOptions.sort((a, b) => a - b);
        }
    }

    setPageReport(loadedItemsCount: number): void {
        const first = (this.pageIndex * this.pageItemsCount) + 1;
        this.pageReport = `${first} - ${first + loadedItemsCount - 1} sur ${this.totalItemsCount}`;
    }
}