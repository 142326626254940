import { Service } from "typedi";
import { ApiService } from "../../api-service";
import { ApiControllers } from "../../api-endpoints";
import { Contents } from "src/app/core/model/db-model/doc/tables/contents";

@Service({ global: true })  
export class DocService extends ApiService {
    private contentsEndpoint: string = `${ApiControllers.doc}/contents`;

    constructor() {
        super();
    }

    async getContents(): Promise<Contents[]> {
        return await this.getArray2Async<Contents>(Contents, this.endPoint(this.contentsEndpoint));
    }
}