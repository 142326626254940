import { BpSvgRoomLabel } from '../bp-svg-room-label';
import { BlueprintLabelLayer } from './blueprint-label-layer';

export class BlueprintRoomLabelLayer extends BlueprintLabelLayer<BpSvgRoomLabel> {
    constructor(dtoData: any, taskId: number) {
        super(dtoData, taskId);
    }

    removeFromRoomId(roomId: number): boolean {
        const item = (this.typedData()).find(x=> x.svgRoom && x.svgRoom.floorDataId === roomId);
        if (item != null) {
            return this.remove(item.floorDataId);
        }
        return false;
    }
}