export class EquipmentSelectionSideHandle {
    id: string;
    code: string;
    path: string = "";
    fill: string = EquipmentSelectionSideHandle.defaultColor;
    fillOpacity: number = 0.3;
    stroke: string = "#00ff99";
    
    static defaultColor = "#00cc7a";
    static copiedItemsColor = "#ff00ff";

    constructor(id: string, code: string) {
        this.id = id;
        this.code = code;
    }

    setFill(color: string): void {
        this.fill = color;
    }
}