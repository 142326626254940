import { SelectItem, SelectItemGroup } from "primeng/api";
import { DropdownChangeEvent } from "primeng/dropdown";
import { ListboxChangeEvent } from "primeng/listbox";
import { EquipmentCatalogViewSet } from "src/app/core/model/data-model/view-set/equipment-catalog-view-set";
import { EquipmentCategoryView } from "src/app/core/model/data-model/views/equipment-category-view";
import { EquipmentCategoryTable } from "src/app/core/model/db-model/tables/equipment-category-table";
import { ViewsNames } from "src/app/core/model/db-model/views-names-enum";
import { ArrayUtils } from "src/app/core/model/static-functions/array-utils";
import { DyntService } from "src/app/core/services/backend-services/dynt-service";
import Container from "typedi";
import { PictoBrowserEventsEnum } from "./picto-browser-events-enum";
import { EventListener } from "src/app/core/events/event-listener";
import { readableUUID } from "src/app/core/events/event-listener-uuid";

export class PictosBrowser extends EventListener {
    categories: EquipmentCategoryView[] = [];
    options: SelectItemGroup[] = [];
    selectedOption: SelectItem | undefined;
    pictos: EquipmentCatalogViewSet[] = [];
    pictosOptions: SelectItemGroup[] = [];
    selectedPicto: SelectItem | undefined;

    constructor() {
        super(readableUUID(PictosBrowser.name));
    }

    async initialize(): Promise<void> {
        const s = Container.get(DyntService);
        this.categories = await s.downloadTable<EquipmentCategoryView>(ViewsNames.EquipmentCategoryView);
        const level1 = this.categories.filter(x=> x.eqCaDepth === 1);
        level1.forEach(l1 => {
            const items = this.categories.filter(x=> x.eqCaParentId === l1.eqCaId);
            this.options.push({label: l1.eqCaDisplayName, value: l1, items: this.getCatOptions(items)});
        });
    }

    getCatOptions(items: EquipmentCategoryView[]): SelectItem[] {
        const result: SelectItem[] = [];
        items.forEach(i => {
            result.push({label: i.eqCaDisplayName, value: i.eqCaId});
        });
        return result;
    }

    getPictoOptions(items: EquipmentCatalogViewSet[]): SelectItem[] {
        const result: SelectItem[] = [];
        items.forEach(i => {
            result.push({label: i.dataSet.eqCgName, value: i});
        });
        return result;
    }

    async loadPictos(catId: any): Promise<void> {
        // Récupère toutes les catégories de niveau 2
        const level2Ids = this.categories.filter(x=> x.eqCaParentId === catId).map(x=> x.eqCaId);
        const s = Container.get(DyntService);
        this.pictos = await s.downloadTable<EquipmentCatalogViewSet>(ViewsNames.EquipmentCatalogView, EquipmentCatalogViewSet.name, EquipmentCategoryTable.eqCaId, level2Ids);
        const pcats = ArrayUtils.DistinctBy(this.pictos.map(x=> x.equipmentCategoryView), 'eqCaId');
        const tmp: SelectItemGroup[] = [];
        pcats.forEach(cat => {
            const items = this.getPictoOptions(this.pictos.filter(x=> x.dataSet.eqCgCategoryId === cat.eqCaId));
            tmp.push({label: cat.eqCaDisplayName, value: cat, items: items});
        });
        this.pictosOptions = tmp;
    }

    async onSelectedOptionChange(e: DropdownChangeEvent): Promise<void> {
        if (this.selectedOption) {
            await this.loadPictos(this.selectedOption);
        }
    }

    async onSelectedPictoChange(e: ListboxChangeEvent): Promise<void> {
        await this.emitEventAsync(PictoBrowserEventsEnum.selectedPictoChange, e.value.dataSet.flCgId);
    }
}