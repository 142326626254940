import { NestedToolbarBase } from "src/app/ui/main/model/nested-toolbar-base";
import { RealEstateChartTypeEnum } from "../../real-estate/charts/shared-model/real-estate-chart-type-enum";
import { NestedToolbarTypeEnum } from "src/app/ui/main/model/nested-toolbar-type-enum";
import { TreeNode } from "primeng/api";
import { BusinessUnit } from "src/app/core/model/data-model/tables/business-unit";
import { BusinessUnitsEventsEnum } from "./business-units-events-enum";
import { RadioButtonClickEvent } from "primeng/radiobutton";
import { TabPageContentTypeEnum } from "../../real-estate/tab-page-content-type-enum";

export class BusinessUnitsToolbarVM  extends NestedToolbarBase {
    selectedTreeNode: TreeNode<BusinessUnit> | undefined;

    options: {id: RealEstateChartTypeEnum, label: string, chartType: string}[] = [];
    selectedOption: {id: RealEstateChartTypeEnum, label: string};
    
    private constructor() {
        super(NestedToolbarTypeEnum.businessUnits);

        this.options.push({id: RealEstateChartTypeEnum.anatomy, label: "Anatomie", chartType: TabPageContentTypeEnum.donutchart});
        this.options.push({id: RealEstateChartTypeEnum.businessunits, label: "Entités", chartType: TabPageContentTypeEnum.donutchart});
        this.options.push({id: RealEstateChartTypeEnum.buildings, label: "Cartographie", chartType: TabPageContentTypeEnum.donutchart});
        this.options.push({id: RealEstateChartTypeEnum.occupation, label: "Positions de travail", chartType: TabPageContentTypeEnum.donutchart});
        this.options.push({id: RealEstateChartTypeEnum.workforce, label: "Effectifs", chartType: TabPageContentTypeEnum.none});
        this.options.push({id: RealEstateChartTypeEnum.referential, label: "Référentiel", chartType: TabPageContentTypeEnum.referential});
        this.selectedOption = this.options[0];
    }

    static async newAsync(): Promise<BusinessUnitsToolbarVM> {
        var tmp = new BusinessUnitsToolbarVM();
        tmp.emitEventAsync(BusinessUnitsEventsEnum.selectedOptionChange, tmp.selectedOption);

        return tmp;
    }
        
    async optionChange(e: RadioButtonClickEvent): Promise<void> {
        this.emitEventAsync(BusinessUnitsEventsEnum.selectedOptionChange, e.value);
    }

}