@if (model != null) {
<mat-tree #reTree [dataSource]="model.dataSource" [treeControl]="model.treeControl" class="example-tree">
    <!-- This is the tree node template for leaf nodes -->
    <!-- There is inline padding applied to this node using styles.
      This padding value depends on the mat-icon-button width. -->
    <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle
        (click)="model.nodeClick($event, node)">
        <div class="treeNode" [ngClass]="{'selected': node.selected}">
          <div class="pinColor leaf" 
            [style.background-color]="node.lt.roLaTyColor">
          </div>
          <span class="leaf-label">{{node.lt.roLaTyName}}</span>
        </div>
    </mat-tree-node>
    <!-- This is the tree node template for expandable nodes -->
    <mat-nested-tree-node *matTreeNodeDef="let node; when: model.hasChild"
        (click)="model.nodeClick($event, node)">
        <div class="mat-tree-node treeNode" [ngClass]="{'selected': node.selected}">
          <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.lt.roLaTyName">
            <mat-icon class="mat-icon-rtl-mirror">
              {{model.treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
            </mat-icon>
          </button>
          <div class="pinColor" 
            [style.background-color]="node.lt.roLaTyColor">
          </div>
          <span>{{node.lt.roLaTyName}}</span>
        </div>

        <!-- There is inline padding applied to this div using styles.
        This padding value depends on the mat-icon-button width.  -->
        <div [class.example-tree-invisible]="!model.treeControl.isExpanded(node)" role="group">
          <ng-container matTreeNodeOutlet></ng-container>
      </div>
    </mat-nested-tree-node>
</mat-tree>
}

