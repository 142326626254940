import { SvgEntityTypesEnum } from 'src/app/core/model/svg-model/svg-entity-type-enum';
import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BpSvgEntityTypesEnum } from '../../model/bp-svg-entity-type-enum';
import { BpSvgEntity } from '../../../bp-svg-core-model/bp-svg-entity';
import { BpSvgLabelComponent } from '../bp-svg-label/bp-svg-label.component';
import { BpSvgLabel } from '../../model/bp-svg-label';

@Component({
  selector: 'g[xc-bp-svg-group]',
  standalone: true,
  imports: [CommonModule, BpSvgLabelComponent],
  templateUrl: './bp-svg-group.component.svg',
  styleUrls: ['./bp-svg-group.component.scss']
})
export class BpSvgGroupComponent {
  SvgEntityTypesEnum = SvgEntityTypesEnum;
  BpSvgEntityTypesEnum = BpSvgEntityTypesEnum;
  BpSvgLabel = BpSvgLabel;

  @Input() entities: BpSvgEntity[] = [];
}
