import { BlueprintSvgEntityTypeEnum } from '../svg-entities/model/blueprint-svg-entity-type-enum';
import { BpSvgEntity } from './bp-svg-entity';
import { getBpEntities } from './bp-svg-entity-builder';

export class BpSvgGroup extends BpSvgEntity {
    entities: BpSvgEntity[] = [];

    constructor(dtoData: any, blueprintEntityType: BlueprintSvgEntityTypeEnum) {
        super(dtoData, blueprintEntityType);

        if (dtoData.entities) {
            this.entities = getBpEntities(dtoData.entities);
        }
    }
}