import { Component, DoCheck, Input, IterableDiffer, IterableDiffers, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Point } from 'src/app/core/model/geometry-model/point.model';

@Component({
  selector: 'g[xc-svg-selection-path]',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './svg-selection-path.component.svg',
  styleUrls: ['./svg-selection-path.component.scss']
})
export class SvgSelectionPathComponent implements OnInit, DoCheck {
  private differ: IterableDiffer<Point>;
  pathData: string | undefined;
  @Input() model: Point[] | undefined; 

  constructor(private differs: IterableDiffers) { 
    this.differ = differs.find([]).create();;
  }

  ngOnInit(): void {
  }

  ngDoCheck(): void {
    const changes = this.differ.diff(this.model);
    if (changes) {
      this.updatePath();
    }
  }

  updatePath(): void {
    if(this.model && this.model.length > 0) {
        const p1 = this.model[0];
        this.pathData = "M" + p1.x + " " + p1.y;
        for (let i = 1; i < this.model.length; i++) {
            const p = this.model[i];
            this.pathData += " " + p.x + " " + p.y;
        }

        this.pathData += " Z";
    } else {
      this.pathData = "";
    }
  }
}
