export enum ZAppParameterEnum {
    AllowEquipmentAllocationOutsideProjectGrantId = "AllowEquipmentAllocationOutsideProjectGrantId",
    AllowRoomAllocationOutsideProjectGrantId = "AllowRoomAllocationOutsideProjectGrantId",
    AllowRoomLabelUpdateOutsideProjectGrantId = "AllowRoomLabelUpdateOutsideProjectGrantId",
    AllowRoomTypeUpdateOutsideProjectGrantId = "AllowRoomTypeUpdateOutsideProjectGrantId",
    AllowWPAllocationOutsideProjectGrantId = "AllowWPAllocationOutsideProjectGrantId",
    AllowWPDeleteInsertOutsideProjectGrantId = "AllowWPDeleteInsertOutsideProjectGrantId",
    AllowWPUpdateOutsideProjectGrantId = "AllowWPUpdateOutsideProjectGrantId",
    CurrentCulture = "CurrentCulture",
    DirectTaskCreationGrantId = "DirectTaskCreationGrantId",
    EdmSupportId = "EdmSupportId",
    EquipmentReservationTimeLimit = "EquipmentReservationTimeLimit",
    FloorCatalogPeopleLabelId = "FloorCatalogPeopleLabelId",
    FloorCatalogRoomLabelId = "FloorCatalogRoomLabelId",
    FloorCatalogWorkplaceLabelId = "FloorCatalogWorkplaceLabelId",
    IsDatabaseAvailable = "IsDatabaseAvailable",
    OutsideProjectUpdatableFloorModelIds = "OutsideProjectUpdatableFloorModelIds",
    PaginationDefaultItemsCount = "PaginationDefaultItemsCount",
    SimulationMinArea = "SimulationMinArea",
    UncategorizedEquipmentCategoryId = "UncategorizedEquipmentCategoryId",
    UseGrosAreaRootItemId = "UseGrosAreaRootItemId",
    UseNetAreaRootItemId = "UseNetAreaRootItemId",
    UserAccountAdminMenuGrantId = "UserAccountAdminMenuGrantId",
    StorageRoomLayoutTypeId = "StorageRoomLayoutTypeId",
    LastPage = "LastPage"
}