import { TaskStatus } from "src/app/core/model/data-model/tables/task-status";
import { TaskWorkflowProcessing } from "src/app/core/model/data-processing/task-workflow-processing";

export class TaskWorkflowCommand {
    taskStatus: TaskStatus;
    actionName: string;

    constructor(taskStatus: TaskStatus) {
        this.taskStatus = taskStatus;
        this.actionName = TaskWorkflowProcessing.actionName(this.taskStatus.taStId);
    }
}
