import { DefinedModelBase } from '../defined-model-base';
import { FloorWallStyleTable } from "src/app/core/model/db-model/tables/floor-wall-style-table";

export class FloorWallStyle extends DefinedModelBase {
    flWaStId!: number;
    flWaStName!: string;
    flWaStDisplayNameId!: number;
    flWaStColor!: string;
    flWaStScope!: number;

    constructor(dtoData: any, tableDef: any[] = []) {
        super(dtoData, [FloorWallStyleTable].concat(tableDef));
    }
}
