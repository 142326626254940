import { FloorActivityStatusView } from 'src/app/core/model/data-model/views/floor-activity-status-view';
import { FloorLayoutView } from 'src/app/core/model/data-model/views/floor-layout-view';
import { FloorActivityStatusDTO } from 'src/app/core/services/backend-services/dto/floor-activity-status-dto';
import { FloorAttributionView } from 'src/app/core/model/data-model/views/floor-attribution-view';
import { FloorEquipmentProviderView } from 'src/app/core/model/data-model/views/floor-equipment-provider-view';
import { FloorEquipmentProviderDTO } from 'src/app/core/services/backend-services/dto/floor-equipment-provider-dto';
import { FloorAttributionDTO } from 'src/app/core/services/backend-services/dto/floor-attribution-dto';
import { FloorLayoutTypeDTO } from 'src/app/core/services/backend-services/dto/floor-layout-type-dto';
import { FloorAllocationDTO } from 'src/app/core/services/backend-services/dto/floor-allocation-dto';
import { FloorAllocationView } from 'src/app/core/model/data-model/views/floor-allocation-view';
import { StackAnonymousRowData } from 'src/app/ui/shared/shared-model/stack-anonymous-row-data';
import { StackAnonymousUnitData } from 'src/app/ui/shared/shared-model/stack-anonymous-unit-data';

export class BuildingAnonymousStackChartDataBuilder {
    private static getAnatomyUnitData(view: FloorLayoutView[]): StackAnonymousUnitData[] {
        const result: StackAnonymousUnitData[] = [];

        view.forEach(e => {
            result.push(new StackAnonymousUnitData(e.flId, e.roLaTyId, e.roLaTyName, e.flLaViRoundedArea, e.roLaTyColor));
        });

        return result;
    }

    private static getActivityUnitData(view: FloorActivityStatusView[]): StackAnonymousUnitData[] {
        const result: StackAnonymousUnitData[] = [];

        view.forEach(e => {
            result.push(new StackAnonymousUnitData(e.flId, e.roActivityStatusTypeId, e.roAcTyViDisplayName, e.flAcStViRoundedArea, e.roAcTyColor));
        });

        return result;
    }

    private static getAttributionUnitData(view: FloorAttributionView[]): StackAnonymousUnitData[] {
        const result: StackAnonymousUnitData[] = [];

        view.forEach(e => {
            result.push(new StackAnonymousUnitData(e.flId, e.roAttributionTypeId, e.roAtTyViDisplayName, e.flAtViRoundedArea, e.roAtTyColor));
        });

        return result;
    }

    private static getInventoryUnitData(view: FloorEquipmentProviderView[]): StackAnonymousUnitData[] {
        const result: StackAnonymousUnitData[] = [];

        view.forEach(e => {
            result.push(new StackAnonymousUnitData(e.flId, e.eqCgProviderId, e.prCo_Name, e.equipmentValue, e.prCo_Color));
        });

        return result;
    }

    private static getAllocationsUnitData(view: FloorAllocationView[]): StackAnonymousUnitData[] {
        const result: StackAnonymousUnitData[] = [];

        view.forEach(e => {
            result.push(new StackAnonymousUnitData(e.flId, e.roAlBusinessUnitId, e.buUnName, e.flAlViRoundedArea, e.buUnColor));
        });

        return result;
    }

    static getAnatomyRowData(data: FloorLayoutTypeDTO[]): StackAnonymousRowData[] {
        const descFloors = data.sort((a, b) => b.flElevation - a.flElevation);
        const maxArea = Math.max(...data.map(o => o.flArea))

        const result: StackAnonymousRowData[] = [];

        descFloors.forEach(f => {
            const sr = new StackAnonymousRowData(f.flId, f.flCode, f.flElevation, f.flArea, maxArea);
            sr.units = BuildingAnonymousStackChartDataBuilder.getAnatomyUnitData(f.layoutTypes);
            result.push(sr);
        });

        return result;
    }

    static getActivityRowData(data: FloorActivityStatusDTO[]): StackAnonymousRowData[] {
        const descFloors = data.sort((a, b) => b.flElevation - a.flElevation);
        const maxArea = Math.max(...data.map(o => o.flArea))

        const result: StackAnonymousRowData[] = [];

        descFloors.forEach(f => {
            const sr = new StackAnonymousRowData(f.flId, f.flCode, f.flElevation, f.flArea, maxArea);
            sr.units = BuildingAnonymousStackChartDataBuilder.getActivityUnitData(f.activityStatus);
            result.push(sr);
        });

        return result;
    }

    static getAttributionRowData(data: FloorAttributionDTO[]): StackAnonymousRowData[] {
        const descFloors = data.sort((a, b) => b.flElevation - a.flElevation);
        const maxArea = Math.max(...data.map(o => o.flArea))

        const result: StackAnonymousRowData[] = [];

        descFloors.forEach(f => {
            const sr = new StackAnonymousRowData(f.flId, f.flCode, f.flElevation, f.flArea, maxArea);
            sr.units = BuildingAnonymousStackChartDataBuilder.getAttributionUnitData(f.activityStatus);
            result.push(sr);
        });

        return result;
    }

    static getInventoryRowData(data: FloorEquipmentProviderDTO[]): StackAnonymousRowData[] {
        const descFloors = data.sort((a, b) => b.flElevation - a.flElevation);
        const maxArea = Math.max(...data.map(o => o.flArea))

        const result: StackAnonymousRowData[] = [];

        descFloors.forEach(f => {
            const sr = new StackAnonymousRowData(f.flId, f.flCode, f.flElevation, f.flArea, maxArea);
            sr.units = BuildingAnonymousStackChartDataBuilder.getInventoryUnitData(f.equipmentProviders);
            result.push(sr);
        });

        return result;
    }

    static getAllocationsRowData(data: FloorAllocationDTO[]): StackAnonymousRowData[] {
        const descFloors = data.sort((a, b) => b.flElevation - a.flElevation);
        const maxArea = Math.max(...data.map(o => o.flArea))

        const result: StackAnonymousRowData[] = [];

        descFloors.forEach(f => {
            const sr = new StackAnonymousRowData(f.flId, f.flCode, f.flElevation, f.flArea, maxArea);
            sr.units = BuildingAnonymousStackChartDataBuilder.getAllocationsUnitData(f.allocations);
            result.push(sr);
        });
        
        return result;
    }
}