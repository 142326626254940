import { CommandGroup } from "./command-group";
import { ICommand } from "./i-command";
import { ToolCommandEnum } from "./tool-command-enum";

export class ToolsCommands extends CommandGroup<ToolCommandEnum> {
    gridCommand: ICommand;

    constructor() {
        super();
        this.gridCommand = this.addCommand(ToolCommandEnum.grid, "Grille", "grid_3x3");
    }

    // enableGridOptions(enable: boolean): void {
    //     this.gridTranslateCommand.enabled = enable;
    //     this.gridAlignCommand.enabled = enable;
    //     this.gridSizeCommand.enabled = enable;
    //     this.gridResetCommand.enabled = enable;
    // }
}