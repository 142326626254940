import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EquipmentSelectionOverlay } from '../../model/equipment-selection-overlay';

@Component({
  selector: 'g[xc-equiment-selection-overlay]',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './equiment-selection-overlay.component.svg',
  styleUrls: ['./equiment-selection-overlay.component.scss']
})
export class EquimentSelectionOverlayComponent {
  @Input() model: EquipmentSelectionOverlay | undefined;
}
