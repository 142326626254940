import { Component, Input } from '@angular/core';
import { MVVMEventDestroyLifeCycle } from 'src/app/components-lib/shared-model/mvvm-events-destroy-lifecycle';
import { MobilityProjectTaskTypes } from '../../model/mobility-project-task-types';
import { DynTableComponent } from 'src/app/components-lib/dyn-table/dyn-table.component';

@Component({
  selector: 'xc-mobility-project-task-types',
  standalone: true,
  imports: [DynTableComponent],
  templateUrl: './mobility-project-task-types.component.html',
  styleUrl: './mobility-project-task-types.component.scss'
})
export class MobilityProjectTaskTypesComponent extends MVVMEventDestroyLifeCycle {
  @Input() override model: MobilityProjectTaskTypes | undefined;
}
