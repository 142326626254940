import { FloorModelEnum } from "src/app/core/model/data-model/enums/floor-model-enum";
import { FloorBlueprint } from './floor-blueprint';
import { RoomAttributionTypeEnum } from 'src/app/core/model/data-model/enums/room-attribution-type-enum';
import { RoomSet } from './room-set';
import { EditableLayer } from './interaction/editable-layer';
import { SvgDOM } from './interaction/svg-dom';
import { BpSvgWorkplaceLabel } from '../../../svg-entities/model/bp-svg-workplace-label';
import { BlueprintCoreContoursLayer } from '../../../svg-entities/model/layers/blueprint-core-contour-layer';
import { BlueprintDoorsLayer } from '../../../svg-entities/model/layers/blueprint-doors-layer';
import { BlueprintEquipmentLayer } from '../../../svg-entities/model/layers/blueprint-equipment-layer';
import { BlueprintInsideContoursLayer } from '../../../svg-entities/model/layers/blueprint-inside-contour-layer';
import { BlueprintLabelLayer } from '../../../svg-entities/model/layers/blueprint-label-layer';
import { BlueprintLayer } from '../../../svg-entities/model/layers/blueprint-layer';
import { getLayer } from '../../../svg-entities/model/layers/blueprint-layer-builder';
import { BlueprintPartitioningFrameLayer } from '../../../svg-entities/model/layers/blueprint-partitioning-frame-layer';
import { BlueprintPeopleLabelLayer } from '../../../svg-entities/model/layers/blueprint-people-label-layer';
import { BlueprintRoomLabelLayer } from '../../../svg-entities/model/layers/blueprint-room-label-layer';
import { BlueprintRoomLayer } from '../../../svg-entities/model/layers/blueprint-room-layer';
import { BlueprintWallsLayer } from '../../../svg-entities/model/layers/blueprint-walls-layer';
import { BlueprintWorkplaceLabelLayer } from '../../../svg-entities/model/layers/blueprint-workplace-label-layer';
import { RoomAllocationViewSet } from "src/app/core/model/data-model/view-set/room-allocation-view-set";
import { WorkplaceAllocationViewSet } from "src/app/core/model/data-model/view-set/workplace-allocation-view-set";
import { WorkplaceViewSet } from "src/app/core/model/data-model/view-set/workplace-view-set";
import { BpSvgRoom } from "../../../svg-entities/model/bp-svg-room";
import { BpSvgDef } from "../../../bp-svg-core-model/bp-svg-def";
import { BpSvgEntity } from "../../../bp-svg-core-model/bp-svg-entity";
import { BpSvgUse } from "../../../bp-svg-core-model/bp-svg-use";
import { PeopleLocationTypeEnum } from 'src/app/core/model/data-model/enums/people-location-type-enum';
import { FloorDataTable } from 'src/app/core/model/db-model/tables/floor-data-table';
import { PeopleLocationViewSet } from 'src/app/core/model/data-model/view-set/people-location-view-set';
import { BpSvgText } from "../../../bp-svg-core-model/bp-svg-text";

export class BlueprintLayersController {
    layers: BlueprintLayer[] = [];
    floorTaskId: number = 0;
    roomDatas: RoomSet[] = [];
    workplacesDatas: WorkplaceViewSet[] = [];
    workplaceAllocations: WorkplaceAllocationViewSet[] = [];

    intialize(dtoData: any, taskId: number): void {
        // Vide les tableaux en cas de changement de plan
        this.layers.splice(0);
        this.roomDatas.splice(0);
        this.workplacesDatas.splice(0);
        this.workplaceAllocations.splice(0);

        this.floorTaskId = taskId;
        this.setDatas(dtoData, taskId);
    }

    setDatas(dtoData: any, taskId: number): void {
        // Charge les calques
        this.loadLayers(dtoData.layers.items, dtoData.floorModelUserOptions, taskId);

        // Réparti l'état d'inventaire sur les uses
        // TODO : optimiser par la fourniture directe de la data par le backend
        this.spreadInventoryState(dtoData.equipmentPlanning, taskId)

        // Assemble les données de surface
        const roomPeopleLocations = dtoData.peopleLocations.filter((x: any)=> x.PeLo_LocationTypeId === PeopleLocationTypeEnum.Room);
        this.assembleRoomDatas(dtoData, dtoData.rooms, roomPeopleLocations, taskId);
        // Assemble les données de position de travail
        const workplacePeopleLocations = dtoData.peopleLocations.filter((x: any)=> x.PeLo_LocationTypeId === PeopleLocationTypeEnum.Workplace);
        this.assembleWorkplacesDatas(dtoData, dtoData.workplaces, workplacePeopleLocations, taskId);
    }

    loadLayers(dtoData: any, userOptions: any[], taskId: number): void {
        if(dtoData){
            const tmp: BlueprintLayer[] = [];

            dtoData.forEach((element: any) => {
                const newLayer = getLayer(element, taskId);
                newLayer.strokeWidth = 0.01 / FloorBlueprint.zoomScale;

                const option = userOptions.find(x=> x.FlMoUsOp_FloorModelId === newLayer.id);
                if (option) {
                    newLayer.hideByUser(!option.FlMoUsOp_Displayed);
                }

                // // A l'initialisation les calques de positions de travail ne sont affichée
                // // TODO : rendre plus robuste et généraliser par l'enregistrement de l'état de calque pour le user
                // if (newLayer.id === FloorModelEnum.WorkplaceLabels || newLayer.id === FloorModelEnum.PeopleLabels) {
                //     newLayer.hideByUser(true);
                // }

                tmp.push(newLayer);
            });

            this.layers = this.layers.concat(tmp);
        }
    }

    nestDefinitions(definitions: BpSvgDef[], taskId: number): void {
        this.equipmentLayers(taskId).forEach(l => {
            // Distinct sur les floorCatalogId présents dans les uses du calque
            const defsIds = [...new Set(l.typedData().map(u => u.floorCatalogId))];
            defsIds.forEach(defId => {
                // Récupère la définition
                const def = definitions.find(x=> x.id === defId);
                // Récupère les uses pour le defId
                const defIdUses = l.typedData().filter(x=> x.floorCatalogId === defId);
                // Ajoute une référence à la définition dans les uses
                defIdUses.forEach(u=> {
                    u.def = def;
                    u.floorModelName = l.name;
                });
            });
        }); 
    }

    // TODO : optimiser par la fourniture directe de la data par le backend
    spreadInventoryState(dtoData: any[], taskId: number) {
        if (dtoData) {
            const equipmentLayers = this.equipmentLayers(taskId);
            equipmentLayers.forEach(l => {
                l.setEquipmentPlanning(dtoData);
            });
        }
    }

    private loadRoomDatas(dtoData: any): void {
        const tmp: RoomSet[] = []
        dtoData.forEach((r: any) => {
            tmp.push(new RoomSet(r));
        });
        this.roomDatas = this.roomDatas.concat(tmp);
    }

    assembleRoomDatas(dtoData: any, roomData: any, peopleLocations: any, taskId: number): void {
        if (roomData.length === 0) return;

        // Génère un tableau des données externes des surfaces pour utilisation directe
        this.loadRoomDatas(roomData);

        // Les données externes
        const rooms = this.roomDatas;
        
        // Référence les allocations
        this.loadRoomAllocations(rooms, dtoData.roomAllocations == null ? dtoData.floorBlueprint : dtoData, taskId);

        const roomLayer = this.roomsLayer(taskId);
        if (roomLayer) {
            // Les surfaces sur le plan
            const svgRooms = roomLayer.typedData();
            // Référence les données externes à l'intérieur des surfaces
            svgRooms.forEach(r => {
                const room = rooms.find(x=> x.roomSet.dataSet.roFloorDataId === r.floorDataId);
                r.roomSet = room;
            });

            const roomLabelsLayer = this.roomLabelsLayer(taskId);
            if (roomLabelsLayer) {
                // Les étiquettes des surfaces
                const svgRoomLabels = roomLabelsLayer.typedData();
                // Référence les surfaces à l'intérieur des étiquettes
                svgRoomLabels.forEach(rl => {
                    let svgRoom = svgRooms.find(x=> x.floorDataId === rl.parentId);
                    if (!svgRoom) {
                        // svgRoom n'a pas été trouvé si la surface est à l'état "Deleted"
                        // car dans ce cas le parentId est null
                        // On peut faire une recherche sur le numéro du local vu qu'il est unique dans l'étude
                        const code = (rl.entities[1] as BpSvgText).tSpans[0].text;
                        svgRoom = svgRooms.find(x=> x.roomSet?.roomSet.dataSet.roCode === code);
                    }
                    rl.svgRoom = svgRoom;
                    const locations = peopleLocations.filter((x: any)=> x[FloorDataTable.flDaParentId] === rl.parentId);
                    const tmp: PeopleLocationViewSet[] = [];
                    locations.forEach((x: any) => {
                        tmp.push(new PeopleLocationViewSet(x));
                    });
                    rl.setPeopleLocations(tmp);
                });
            }
        }
    }

    private loadRoomAllocations(rooms: RoomSet[], dtoData: any, taskId: number): void {
        if (dtoData.roomAllocations) {
            // Crée le tables d'allocations
            const tmp: RoomAllocationViewSet[] = [];
            dtoData.roomAllocations.forEach((ra: any) => {
                tmp.push(new RoomAllocationViewSet(ra));
            });

            // Isole les surfaces allouées
            const allocatedRooms = rooms.filter(x=> x.roomSet.dataSet.flDaTaskId === taskId && x.roomSet.dataSet.roAttributionTypeId === RoomAttributionTypeEnum.Allocation);
            allocatedRooms.forEach(ar => {
                // Isole les allocations de la surface
                const allocations = tmp.filter(x=> x.dataSet.roAlFloorDataId === ar.roomSet.dataSet.roFloorDataId);
                // Mappe les allocations sur la surface
                ar.allocationsSet = allocations;
            });
        }
    }

    loadWorkplacesDatas(dtoData: any, taskId: number): void {
        const tmp: WorkplaceViewSet[] = []
        dtoData.forEach((w: any) => {
            const newWp = new WorkplaceViewSet(w);
            //newWp.dataSet. = taskId,
            tmp.push(newWp);
        });
        this.workplacesDatas = this.workplacesDatas.concat(tmp);
    }

    loadWorkplaceAllocations(dtoData: any, taskId: number): void {
        const tmp: WorkplaceAllocationViewSet[] = []
        dtoData.forEach((wa: any) => {
            const newWpa = new WorkplaceAllocationViewSet(wa);
            //newWpa.floorDataTaskId = taskId;
            tmp.push(newWpa);
        });
        this.workplaceAllocations = tmp;
    }

    assembleWorkplacesDatas(dtoData: any, workplaceData: any, peopleLocations: any, taskId: number): void {
        if (workplaceData == null || workplaceData.length === 0) return;

        // Génère un tableau des données externes des positions de travail pour utilisation directe
        this.loadWorkplacesDatas(workplaceData, taskId);
        
        // Référence les allocations
        this.loadWorkplaceAllocations(peopleLocations, taskId);

        // Les données externes
        const workplaces = this.workplacesDatas;
        // Les étiquettes sur le plan
        const labelLayer = this.workplacesLabelsLayer(taskId);
        if (labelLayer) {
            const wpLabels = labelLayer.typedData();

            // Référence les positions de travail et les allocations à l'intérieur des étiquettes
            wpLabels.forEach(wl => {
                const workplace = workplaces.find(x=> x.dataSet.woFloorDataId === wl.floorDataId);
                wl.workplace = workplace;
            });

            // Référence les positions de travail à l'intérieur des étiquettes de personne

            // Les étiquettes de personne
            var peopleLayer = this.peopleLabelsLayer(taskId);
            if (peopleLayer != null) {
                const peopleLabels = peopleLayer.typedData();
                peopleLabels.forEach(pl => {
                    const workplace = workplaces.find(x=> x.dataSet.woFloorDataId === pl.parentId);
                    pl.workplace = workplace;
                    const workplaceAllocation = this.workplaceAllocations.find(x=> x.dataSet.peLoFloorDataId === pl.floorDataId);
                    pl.workplaceAllocation = workplaceAllocation;
                });
        
                // Référence les positions de travail et les allocations à l'intérieur des étiquettes
                wpLabels.forEach(wl => {
                    const wlPeopleLabels = peopleLabels.filter(x=> x.parentId === wl.floorDataId);
                    wl.workplaceAllocations = wlPeopleLabels.filter(x=> x.workplaceAllocation != undefined).map(x=> x.workplaceAllocation!);
                    // Actualise les lignes de repère des étiquettes de personne par rapport à l'arrière plan de l'étiquette de position de travail
                    wlPeopleLabels.forEach(pl => {
                        pl.setParent(wl);
                        //pl.updateLeaderLine(wl.backgroundRectangle);
                    });
                });
            }
        }
        
    }

    lightenCurrentLayers(opacity: number): void {
        // Change l'opacipé des calques d'équipement et de cloisonnement courant pour faire ressortir la zone de l'étude
        const spacePlanningLayers = this.spacePlanningLayers(this.floorTaskId);
        spacePlanningLayers.forEach(l => {
            l.opacity = opacity;
        });
    }

    displayTaskZone(taskId: number, display: boolean): void {
        const taskRooms = this.roomDatas.filter(x=> x.roomSet.dataSet.flDaTaskId === taskId)
        // Récupère les sourceId des surfaces puisqu'on veut masquer les éléments des surfaces qui sont à l'orgine de celles de l'étude
        const sourceIds = taskRooms.map(x=> x.roomSet.dataSet.flDaSourceId);
        // Récupère les surfaces sources
        const parentRooms = this.roomDatas.filter(x=> sourceIds.includes(x.roomSet.dataSet.roFloorDataId));
        // Isole les identifiants des surfaces sources
        const parentRoomsIds = parentRooms.map(x=> x.roomSet.dataSet.roFloorDataId);

        this.spacePlanningLayers(this.floorTaskId).forEach(l => {
            l.data.forEach(i => {
                if (parentRoomsIds.includes(i.parentId!) || parentRoomsIds.includes(i.floorDataId)) {
                    i.show(display);
                }
            });
        });
    }

    showDataState(taskId: number, dataStateId: number, display: boolean) : void {
        var spacePlanningLayers = this.spacePlanningLayers(taskId);
        spacePlanningLayers.forEach(l => {
            l.showDataState(dataStateId, display);
        });
    }

    removeRoom(labelId: number, roomId: number, taskId: number): boolean {
        let result: boolean = false;

        const roomLayer = this.roomsLayer(taskId);
        const roomLabelLayer = this.roomLabelsLayer(taskId);
        if (roomLayer && roomLabelLayer) {
            // Supprime l'étiquette
            result = roomLabelLayer.remove(labelId);
            // Supprime la surface
            result = roomLayer.remove(roomId);
            // Supprime les références au parentId sur les enfants
            const equipmentLayers = this.equipmentLayers(taskId);
            equipmentLayers.forEach(el => {
                const children = el.data.filter(x=> x.parentId === roomId);
                children.forEach(c => {
                    c.parentId = null;
                });
            });
        }

        return result;
    }

    removeElement(e: BpSvgEntity): boolean {
        const layer = this.layer(e.floorModelId, e.taskId);
        if (layer == null) return false;
        return layer.remove(e.floorDataId);
    }

    layer<T extends BlueprintLayer = BlueprintLayer>(layerId: number, taskId: number): T | null {
        const result = this.layers.find(x=>x.id === layerId && x.taskId === taskId);
        if (result) {
            return result as T;
        }
        return null;
    }

    allLayers<T extends BlueprintLayer = BlueprintLayer>(layerId: number): T[] {
        return this.layers.filter(x=>x.id === layerId) as T[];
    }

    equipmentLayers(taskId?: number): BlueprintEquipmentLayer[] {
        if (taskId) {
            return this.layers.filter(x=> x.isUseLayer && x.taskId === taskId) as BlueprintEquipmentLayer[];
        }
        return this.layers.filter(x=> x.isUseLayer) as BlueprintEquipmentLayer[];
    }

    labelLayers(taskId: number | null = null): BlueprintLabelLayer[] {
        return this.layers.filter(x=> x.isLabelLayer && (taskId != null ? x.taskId === taskId : true)) as BlueprintLabelLayer[];
    }

    roomsLayer(taskId: number): BlueprintRoomLayer | null {
        return this.layer<BlueprintRoomLayer>(FloorModelEnum.Rooms, taskId);
    }

    roomLabelsLayer(taskId: number): BlueprintRoomLabelLayer | null {
        return this.layer<BlueprintRoomLabelLayer>(FloorModelEnum.RoomLabels, taskId);
    }

    workplacesLabelsLayer(taskId: number): BlueprintWorkplaceLabelLayer | null {
        return this.layer<BlueprintWorkplaceLabelLayer>(FloorModelEnum.WorkplaceLabels, taskId);
    }

    measurementLayer(taskId: number): BlueprintLabelLayer | null {
        return this.layer<BlueprintLabelLayer>(FloorModelEnum.Measurement, taskId);
    }

    peopleLabelsLayer(taskId: number): BlueprintPeopleLabelLayer | null {
        return this.layer<BlueprintPeopleLabelLayer>(FloorModelEnum.PeopleLabels, taskId);
    }

    wallsLayer(taskId: number): BlueprintWallsLayer | null {
        return this.layer<BlueprintWallsLayer>(FloorModelEnum.Walls, taskId);
    }

    doorsLayer(taskId: number): BlueprintDoorsLayer | null {
        return this.layer<BlueprintDoorsLayer>(FloorModelEnum.Doors, taskId);
    }

    partitioningFrameLayer(): BlueprintPartitioningFrameLayer | null {
        return this.layer<BlueprintPartitioningFrameLayer>(FloorModelEnum.PartitioningFrame, this.floorTaskId);
    }

    coreContoursLayer(): BlueprintCoreContoursLayer | null {
        return this.layer<BlueprintCoreContoursLayer>(FloorModelEnum.FloorCoreContours, this.floorTaskId);
    }

    insideContoursLayer(): BlueprintInsideContoursLayer | null {
        return this.layer<BlueprintInsideContoursLayer>(FloorModelEnum.FloorInsideContours, this.floorTaskId);
    }

    spacePlanningLayers(taskId: number): BlueprintLayer[] {
        return this.layers.filter(x=> (x.isUseLayer || [
            FloorModelEnum.Walls, 
            FloorModelEnum.Doors, 
            FloorModelEnum.Rooms, 
            FloorModelEnum.RoomLabels, 
            FloorModelEnum.WorkplaceLabels, 
            FloorModelEnum.PeopleLabels
        ].includes(x.id)) && x.taskId === taskId);
    }

    insertLayer(layer: BlueprintLayer): void {
        layer.strokeWidth =  0.01 / FloorBlueprint.zoomScale;
        this.layers = this.layers.concat(layer);
    }

    workplaceLabels(taskId?: number): BpSvgWorkplaceLabel[] {
        if (taskId) {
            const layer = this.layer<BlueprintWorkplaceLabelLayer>(FloorModelEnum.WorkplaceLabels, taskId);
            if (layer == null) return [];
            return layer.typedData();
        }
        const layers = this.allLayers<BlueprintWorkplaceLabelLayer>(FloorModelEnum.WorkplaceLabels);
        return layers.flatMap(x=> x.typedData());
    }

    rooms(taskId: number): BpSvgRoom[] {
        const layer = this.layer<BlueprintRoomLayer>(FloorModelEnum.Rooms, taskId);
        if (layer == null) return [];
        return layer.typedData();
    }

    getRoomWorkplaces(roomId: number, taskId: number): BpSvgWorkplaceLabel[] {
        const furnitureLayer = this.layer<BlueprintEquipmentLayer>(FloorModelEnum.Furniture, taskId);
        const workplaceLayer = this.workplacesLabelsLayer(taskId);
        if (furnitureLayer && workplaceLayer) {
            const furniture = furnitureLayer.data.filter(x=> x.parentId === roomId);
            const furnitureIds = furniture.map(x=> x.floorDataId);
            return workplaceLayer.typedData().filter(x=> furnitureIds.includes(x.parentId!));
        }
        return [];
    }

    equipments(taskId: number): BpSvgUse[]
    {
        const layers = this.equipmentLayers(taskId);
        let result: BpSvgUse[] = [];

        layers.forEach(l => {
            result = result.concat(l.typedData());
        });

        return result;
    }

    topBottomDistinctSvgWorkplaceLabels(bottomTaskId: number, topTaskId: number): BpSvgWorkplaceLabel[] {
        const bottomWorkplaces = this.workplaceLabels(bottomTaskId);
        if (!topTaskId) {
            return bottomWorkplaces;
        }

        const topWorkplaces = this.workplaceLabels(topTaskId);
        const sourceIds = topWorkplaces.map(x=> x.sourceId);
        return bottomWorkplaces.concat(topWorkplaces.filter(r=> r.taskId === bottomTaskId && !sourceIds.includes(r.floorDataId)));
    }

    topBottomDistinctSvgRooms(bottomTaskId: number, topTaskId: number): BpSvgRoom[] {
        const bottomRooms = this.rooms(bottomTaskId);
        if (!topTaskId) {
            return bottomRooms;
        }

        const topRooms = this.rooms(topTaskId);
        const sourceIds = topRooms.map(x=> x.sourceId);
        // Parmi les locaux courants, on ne récupère pas ceux qui sont les sources des locaux de l'étude
        return bottomRooms.filter(x=> !sourceIds.includes(x.floorDataId)).concat(topRooms);
        //return bottomRooms.concat(topRooms.filter(r=> r.taskId === bottomTaskId && !sourceIds.includes(r.floorDataId)));
    }

    updateStrokeWidth(): void {
        const zoomScale = FloorBlueprint.zoomScale;
        if (zoomScale < 1) return;
        // Actualise les stroke-width des calques
        // Pour ne pas surcharger de calcul et rendre le zoom plus fluide, on n'actualise les échelles des traits que par incrément de 0.25
        if(this.layers && zoomScale && zoomScale !== 0 && Math.round(zoomScale % 0.5) === 0) {
            this.layers.forEach(element => {
                element.strokeWidth = 0.01 / zoomScale;
            });
        }
    }

    updateFontSize(): void {
        this.labelLayers().forEach(l => {
            l.typedData().forEach(t => {
                // TODO : voir s'il est possible de modifier dynamiquement l'échelle des étiquettes
                // pour éviter qu'elles n'encombrent la zone de travail
                // mais en plus de la mise à l'échelle il faut trouver comment replacer les étiquettes de personnes
                // à proximité des étiquettes de position de travail et peut être redessiner les leader lines
                //t.transform = new SvgTransform({ scaleX: 1 / zoomScale, scaleY: 1 / zoomScale});
            });
        });
    }
    
    updateCursor(editableLayers: EditableLayer[], topMostTaskId: number, selectable: boolean = true): void {
        this.layers.forEach(l => {
            // Les surfaces ne sont pas sélectionnables directement mais seulement par leur étiquette
            if (l.id !== FloorModelEnum.Rooms) {
                const selectableOnTask = editableLayers.filter(x=> x.layerId === l.id).length === 1 && l.taskId === topMostTaskId;
                //l.setItemsSelectable(selectableOnTask && selectable);
                l.setItemsSelectable(selectable);
            }
        });
    }

    setCursor(taskId: number, targetLayersIds: number[], cursor: string): void {
        const layers = this.layers.filter(x=> x.taskId === taskId);
        layers.forEach(l => {
            if (targetLayersIds.includes(l.id)) {
                l.setCursor(cursor);
            }
        });
    }

    removeUpdateTaskGraphicsData(taskId: number): void {
        // Retire les calques de la tâche
        const nonTaskLayers = this.layers.filter(x=> x.taskId !== taskId);
        this.layers = nonTaskLayers;

        // Retire les surfaces de la tâche
        // const nonTaskRooms = this.roomDatas.filter(x=> x.floorDataTaskId !== taskId);
        // this.roomDatas = nonTaskRooms;

        // Retire les positions de travail de la tâche
        // const nonTaskWorkplaces = this.workplacesDatas.filter(x=> x.floorDataTaskId !== taskId);
        // this.workplacesDatas = nonTaskWorkplaces;

        // Retire les allocations de positions de travail de la tâche
        // const nonTaskWorkplacesAllocations = this.workplaceAllocations.filter(x=> x.floorDataTaskId !== taskId);
        // this.workplaceAllocations = nonTaskWorkplacesAllocations;
    }

    getSvgEntityFromClientPoint(x: number, y: number): BpSvgEntity | undefined {
        const itemData = SvgDOM.getDOMSvgItemIdFromClientPoint(x, y);
        if(itemData == null) {
            return undefined;
        }

        const itemId = itemData.itemId;
        const layer = this.layers.find(x=> x.id === itemData.layerId && x.taskId === itemData.taskId);
        if(layer) {
            return layer.data.find((x) => x.floorDataId === itemId);
        }

        return undefined;
    }

    getUseFromClientPoint(x: number, y: number): BpSvgUse | null {
        const usedata =  SvgDOM.getDOMUseIdFromClientPoint(x, y);
        if(usedata == null) {
            return null;
        }

        const useId = usedata.useId;
        const layer = this.layer<BlueprintEquipmentLayer>(usedata.layerId, usedata.taskId);
        if(layer && layer.isUseLayer) {
            return layer.typedData().find((x: BpSvgUse) => x.floorDataId === useId) as BpSvgUse;
        }

        return null;
    }
}