import { FacilityCompany } from "../tables/facility-company";
import { FacilityJobTaskView } from "./facility_job_task_view";

export class FacilityCompanyTaskView extends FacilityCompany {
    jobTaskView: FacilityJobTaskView;

    constructor(dtoData: any) {
        super(dtoData);
        this.jobTaskView = new FacilityJobTaskView(dtoData);
    }
}