import { FloorModelEnum } from "src/app/core/model/data-model/enums/floor-model-enum";
import { Point } from "src/app/core/model/geometry-model/point.model";
import { HtmlConstants } from "src/app/core/model/html-model/html-constants.model";
import { HtmlEcsyConstants } from "src/app/core/model/html-model/html-ecsy-constants.model";
import { SvgDOMItem } from "./svg-dom-item";

export class SvgDOM {
    // Cette variable static est initialisée par le composant lorsqu'il est créé
    // elle est donc ensuite disponible pour les accès en lecture sur le DOM
    static svgElement: SVGSVGElement;

    static initialize(id: string): boolean {
        const temp = document.getElementById(id);
        if (temp == null) {
            return false;
        }
        this.svgElement = temp?.firstChild as unknown as SVGSVGElement;
        SvgDOM.svgElement = this.svgElement;
        return true;
    }

    static getSvgSvgElement(svgElementId?: string): SVGSVGElement | null {
        let result: SVGSVGElement | null = null;
        if (svgElementId) {
            const temp = document.getElementById(svgElementId);
            if (temp !== null) {
                result = temp as unknown as SVGSVGElement;
            }
        } else {
            result = SvgDOM.svgElement;
        }
        return result;
    }

    static getPointPosition(x: number, y: number): Point | undefined {
        const svgElement = SvgDOM.svgElement;
        if(svgElement) {
            const ctm = svgElement.getScreenCTM();
            if(ctm) {
                return new Point((x - ctm.e) / ctm.a, (y - ctm.f) / ctm.d);
            }
        }
        return undefined;
    }

    static getClientPointFromSvgPoint(p: Point): Point | null {
        const svgElement = SvgDOM.svgElement;

        if (svgElement === null) return null;

        let pt = svgElement.createSVGPoint();
        pt.x = p.x;
        pt.y = p.y;
        const m = svgElement.getScreenCTM();
        if (m) {
            const domPoint = pt.matrixTransform(m);
            return new Point(domPoint.x, domPoint.y);
        }
        return null;
    }

    static getElementFromSvgPoint(hitPoint: Point, floorDataIdStringsToExlude: string[]): SvgDOMItem | null {
        const clientPoint = SvgDOM.getClientPointFromSvgPoint(hitPoint);
        if (!clientPoint) return null;

        const items = document.elementsFromPoint(clientPoint.x, clientPoint.y);

        let floorDataId: string | null = "0";
        let floorModelId: string | null = "0";
        let tagName: string;
        let found = false;
        for (var item of items) {
            tagName = item.tagName.toLowerCase();
            switch (tagName) {
                case "use":
                    var parentNode = item.parentNode as Element;
                    if (parentNode.tagName.toLowerCase() == "g") {
                        floorModelId = parentNode.getAttribute(HtmlEcsyConstants.xcFloorModelId);
                    }
                    floorDataId = item.getAttribute(HtmlEcsyConstants.xcFloorDataId);
                    if (floorDataId && (floorDataIdStringsToExlude == null || !floorDataIdStringsToExlude.includes(floorDataId))) {
                        found = true;
                    }
                    break;
                case "line":
                    floorDataId = item.getAttribute(HtmlEcsyConstants.xcFloorDataId);
                    floorModelId = item.getAttribute(HtmlEcsyConstants.xcFloorModelId);
                    if (floorDataId && floorDataId !== '0' && (floorDataIdStringsToExlude == null || !floorDataIdStringsToExlude.includes(floorDataId))) {
                        found = true;
                    }
                    break;
                default:
                    break;
            }
    
            if (found) {
                return new SvgDOMItem(tagName, floorModelId, floorDataId);
            }
        }
    
        return null;
    }


    static isLayerNode(item: Element): boolean {
        const id = item.getAttribute(HtmlEcsyConstants.xcFloorModelId);
        const taskId = item.getAttribute(HtmlEcsyConstants.xcTaskId);
        return id != null && taskId != null;
    }

    static isRootGroupNode(item: Element): boolean {
        const id = item.getAttribute(HtmlEcsyConstants.xcFloorDataId);
        return item.tagName === "g" && id != null;
    }

    static getParentLayerNode(item: Element): Element | null {
        if (item == null) {
            return null;
        }

        // Si on est remonté jusqu'au svg root, on sort
        const tagName = item.tagName.toLowerCase();
        if (tagName === "svg" || tagName.startsWith("xc-")) {
            return null;
        }

        if (this.isLayerNode(item)) {
            return item;
        }

        const parentNode = item.parentNode as Element;
        if (parentNode && this.isLayerNode(parentNode)) {
            return parentNode;
        }

        return this.getParentLayerNode(parentNode);
    }

    static getItemRootGroupNode(item: Element): Element | null {
        if (item == null) {
            return null;
        }
        
        if (this.isRootGroupNode(item)) {
            return item;
        }

        const parentNode = item.parentNode as Element;
        if (parentNode && this.isRootGroupNode(parentNode)) {
            return parentNode;
        }

        return this.getItemRootGroupNode(parentNode);
    }

    static getDOMSvgItemIdFromClientPoint(x: number, y: number): {itemId: number, layerId: number, layerName: string | null, taskId: number} | null {
        const items = document.elementsFromPoint(x, y);

        for (var item of items) {
            const tagName = item.tagName.toLowerCase();
            
            // Si on est remonté jusqu'au svg root, on sort
            if (tagName === "svg" || tagName.startsWith("xc-")) {
                return null;
            }

            // Si l'élément est un use, on le retourne, il s'agit d'un équipement
            if (tagName === "use") {
                const layerNode = item.parentNode as Element;
                return { 
                    itemId: Number(item.getAttribute(HtmlEcsyConstants.xcFloorDataId)), 
                    layerId: Number(layerNode.getAttribute(HtmlEcsyConstants.xcFloorModelId)), 
                    layerName: layerNode.getAttribute(HtmlEcsyConstants.xcName),
                    taskId: Number(layerNode.getAttribute(HtmlEcsyConstants.xcTaskId)) 
                };
            }

            // Sinon, on remonte au groupe parent qui représente le calque pour savoir de quel objet il s'agit
            const parentNode = this.getParentLayerNode(item);
            if(parentNode) {
                const layerId = Number(parentNode.getAttribute(HtmlEcsyConstants.xcFloorModelId));
                const layerName = parentNode.getAttribute(HtmlEcsyConstants.xcName);

                if (layerId === FloorModelEnum.Walls && item.tagName === "line") {
                    // L'item cliqué est une cloison
                    return { 
                        itemId: Number(item.getAttribute(HtmlEcsyConstants.xcFloorDataId)), 
                        layerId: layerId, 
                        layerName: layerName,
                        taskId: Number(parentNode.getAttribute(HtmlEcsyConstants.xcTaskId)) 
                    };
                }

                if (layerId === FloorModelEnum.Doors) {
                    // L'item cliqué est une porte
                    const itemRootGroupNode = this.getItemRootGroupNode(item);
                    if (itemRootGroupNode != null) {
                        return { 
                            itemId: Number(itemRootGroupNode.getAttribute(HtmlEcsyConstants.xcFloorDataId)), 
                            layerId: layerId, 
                            layerName: layerName,
                            taskId: Number(parentNode.getAttribute(HtmlEcsyConstants.xcTaskId)) 
                        };
                    }
                }

                if ((layerId === FloorModelEnum.RoomLabels ||
                    layerId === FloorModelEnum.WorkplaceLabels ||
                    layerId === FloorModelEnum.PeopleLabels) && item.tagName === "tspan") {
                    // L'item cliqué est une étiquette de surface
                    // Le FloorData est sur le groupe qui contient le texte et le rectangle de fond
                    const itemRootGroupNode = this.getItemRootGroupNode(item);
                    if (itemRootGroupNode != null) {
                        return { 
                            itemId: Number(itemRootGroupNode.getAttribute(HtmlEcsyConstants.xcFloorDataId)), 
                            layerId: layerId, 
                            layerName: 
                            layerName,
                            taskId: Number(parentNode.getAttribute(HtmlEcsyConstants.xcTaskId)) 
                        };
                    }
                }

                if (layerId === FloorModelEnum.Measurement) {
                    // L'item cliqué est une cote
                    const itemRootGroupNode = this.getItemRootGroupNode(item);
                    if (itemRootGroupNode != null) {
                        return { 
                            itemId: Number(itemRootGroupNode.getAttribute(HtmlEcsyConstants.xcFloorDataId)), 
                            layerId: layerId, 
                            layerName: layerName,
                            taskId: Number(parentNode.getAttribute(HtmlEcsyConstants.xcTaskId)) 
                        };
                    }
                }
            }
        }

        return null;
    }

    static getDOMRoomFromClientPoint(x: number, y: number): {roomLabelId: number, taskId: number, layerId: number, layerName: string | null} | null {
        const items = document.elementsFromPoint(x, y);
        for (var item of items) {
            const parentNode = this.getParentLayerNode(item);
            if(parentNode) {
                const layerId = Number(parentNode.getAttribute(HtmlEcsyConstants.xcFloorModelId));
                const layerName = parentNode.getAttribute(HtmlEcsyConstants.xcName);

                if ((layerId === FloorModelEnum.RoomLabels ||
                    layerId === FloorModelEnum.WorkplaceLabels ||
                    layerId === FloorModelEnum.PeopleLabels) && item.tagName === "tspan") {
                    // L'item cliqué est une étiquette de surface
                    // Le FloorData est sur le groupe qui contient le texte et le rectangle de fond
                    const itemRootGroupNode = this.getItemRootGroupNode(item);
                    if (itemRootGroupNode != null) {
                        return { 
                            roomLabelId: Number(itemRootGroupNode.getAttribute(HtmlEcsyConstants.xcFloorDataId)), 
                            layerId: layerId, 
                            layerName: layerName,
                            taskId: Number(parentNode.getAttribute(HtmlEcsyConstants.xcTaskId)) 
                        };
                    }
                }
            }
        }

        return null;
    }

    static getDOMUseIdFromClientPoint(x: number, y: number): {useId: number, taskId: number, layerId: number, layerName: string | null} | null {
        const items = document.elementsFromPoint(x, y);
        for (var item of items) {
            const tagName = item.tagName.toLowerCase();
            if (tagName === "use") {
                const layerNode = item.parentNode as Element;
                return { 
                    useId: Number(item.getAttribute(HtmlEcsyConstants.xcFloorDataId)), 
                    taskId: Number(layerNode.getAttribute(HtmlEcsyConstants.xcTaskId)),
                    layerId: Number(layerNode.getAttribute(HtmlEcsyConstants.xcFloorModelId)), 
                    layerName: layerNode.getAttribute("name") 
                };
            }
        }

        return null;
    }

    static getDOMWallIdFromClientPoint(x: number, y: number, wallIdsToExclude: number[]): number |null {
        const items = document.elementsFromPoint(x, y);
        for (var item of items) {
            const tagName = item.tagName.toLowerCase();
            if (tagName === "line") {
                const id = Number(item.getAttribute(HtmlEcsyConstants.xcFloorDataId));
                if (id > 0 && !wallIdsToExclude.includes(id)) {
                    return id;
                }
            }
        }

        return null;
    }

    static getSvgElementByFloorDataId(floorDataId: number): SVGGraphicsElement | null {
        const item = document.querySelector(`[${HtmlEcsyConstants.xcFloorDataId}='${floorDataId}']`) as Element;

        if (item) {
            return item as SVGGraphicsElement;
        }

        return null;
    }
    
    static getBbox(floorDataId: number): DOMRect | null {
        const item = SvgDOM.getSvgElementByFloorDataId(floorDataId);
        if (item) {
            return (item as SVGGraphicsElement).getBBox();
        }

        return null;
    }

    static getLabelTextSize(floorDataId: number): DOMRect | null {
        const label = this.getSvgElementByFloorDataId(floorDataId);
        if (label) {
            const text = label.getElementsByTagName('text');
            if (text.length > 0) {
                return (text[0] as SVGGraphicsElement).getBBox();
            }
        }
        return null;
    }
}
