import { Point } from "src/app/core/model/geometry-model/point.model";
import { Segment } from "src/app/core/model/geometry-model/segment.model";
import { Vector } from "src/app/core/model/geometry-model/vector.model";
import { HtmlConstants } from "src/app/core/model/html-model/html-constants.model";
import { SvgTransform } from "src/app/core/model/svg-model/svg-transform.model";
import { EquipmentSelectionOverlay } from "./equipment-selection-overlay";

export class EquipmentTransient {
    detectedSegment: Segment | undefined;
    transform: SvgTransform | undefined;
    initialTransform: SvgTransform | undefined;
    minX: number = 0;
    minY: number = 0;
    width: number = 0;
    height: number = 0;
    stroke: string = "#00cc7a";
    fill: string = "#00ff99";
    display: string = "none";
    targetRotationCenter: Point | undefined;
    targetDeltaAngle: number | undefined;
    targetDeltaTranslate: Point | undefined;

    // TODO : Mutualiser avec PocSvgSelectionOverlay les propriétés et méthodes qui se ramènent à celles d'une bbox
    constructor(selectionOverlay: EquipmentSelectionOverlay) {
        if (!selectionOverlay.transform) return;
        this.transform = selectionOverlay.transform.clone();
        this.initialTransform = selectionOverlay.transform.clone();
        this.minX = selectionOverlay.minX;
        this.minY = selectionOverlay.minY;
        this.width = selectionOverlay.width;
        this.height = selectionOverlay.height;
    }

    show(): void {
        this.display = HtmlConstants.styleDisplayBlock;
    }

    hide(): void {
        this.display = HtmlConstants.styleDisplayNone;
    }

    translate(deltaTranslate: Vector): void {
        if (!this.initialTransform) return;
        this.transform = this.initialTransform.addTranslation(deltaTranslate);
    }

    rotate(rotationCenter: Point, deltaAngle: number): void {
        this.rotateTranslate(rotationCenter, deltaAngle);
    }
    
    rotateTranslate(rotationCenter: Point, targetAngleDeg: number, rotatedTargetTranslate?: Vector): void {
        if (!this.initialTransform) return;
        let newT = this.initialTransform.clone().rotateTranslate(rotationCenter, targetAngleDeg, rotatedTargetTranslate);
        this.transform = newT;
    }
}