@if (model != null) {

<div id="content-header">
  <span class="header">Equipements</span>
  <span class="header subheader">Sélection :
    {{
    model.selectedEquipments == null
    ? "aucune"
    : model.selectedEquipments.count
    }}</span>
</div>

<mat-divider></mat-divider>

@if (model.selectedEquipments != null) { @if (model.selectedEquipments.count ===
1) {

<div class="property-row">
  <span class="property-name">Nom </span>
  <span class="property-value">{{
    $any(model.selectedEquipments.items[0]).def.name
    }}</span>
</div>
<div class="property-row">
  <span class="property-name">Code prod. </span>
  <span class="property-value">{{
    $any(model.selectedEquipments.items[0]).def.name
    }}</span>
</div>
<div class="property-row">
  <span class="property-name">Fournisseur </span>
  <span class="property-value">{{
    $any(model.selectedEquipments.items[0]).def.equipmentProviderName
    }}</span>
</div>
<div class="property-row">
  <span class="property-name">Classement </span>
  <span class="property-value">{{
    $any(model.selectedEquipments.items[0]).floorModelName +
    " / " +
    $any(model.selectedEquipments.items[0]).def.equipmentCategoryName
    }}</span>
</div>
<div class="property-row">
  <span class="property-name">Taille </span>
  <span class="property-value">{{
    $any(model.selectedEquipments.items[0]).def.bBox.width() +
    " x " +
    $any(model.selectedEquipments.items[0]).def.bBox.height()
    }}</span>
</div>

} @else {

<ul>
  @for (c of model.categories; track c) {
  <li class="category">
    {{ c.name }}
    <ul>
      @for (i of c.items; track i) {
      <li class="reference">
        {{ i.name + " - (" + i.count + ")" }}
      </li>
      }
    </ul>
  </li>
  }
</ul>

}

<mat-divider></mat-divider>

@if (model.hasPlanningTask) {
<div class="property-row">
  <span class="property-name">Implantation </span>
  <div class="property-value composite">
    <div class="color-pin" matTooltip="Afficher le thème" [matTooltipPosition]="'left'"
      [style.background-color]="model.selectedDataStateColor"
      (click)="model.statePinClick($event, model.selectedDataStateThemeId)"></div>
    <span class="property-value">{{ model.selectedDataStateName }}</span>
  </div>
</div>
<div class="property-row">
  <span class="property-name">Inventaire </span>
  <div class="property-value composite">
    <div class="color-pin" matTooltip="Afficher le thème" [matTooltipPosition]="'left'"
      [style.background-color]="model.selectedPlanningStateColor"
      (click)="model.statePinClick($event, model.selectedPlanningStateThemeId)"></div>
    <span class="property-value">{{ model.selectedPlanningStateName }}</span>
  </div>
</div>
}

<!-- Zone de remplacement de la sélection -->
<!-- N'est disponible que si la sélection ne comporte qu'un seul type d'équipement -->
<!-- Dans ce cas on affiche la liste des autres fournisseurs pour lesquels on a le même type d'équipement -->
<!-- et lorsqu'un fournisseur est sélectionné, la liste des équipements disponibles -->
@if ((model.selectedEquipments.count === 1 || model.categories.length === 1) && model.isEditable && model.selectedDataStateId !== 3) {

<div class="box">
  <span class="box-header">Remplacement</span>

  <mat-form-field id="provider-select" appearance="outline">
    <mat-label>Sélectionnez un fournisseur</mat-label>
    <mat-select [(ngModel)]="model.selectedProvider" (ngModelChange)="model.onSelectedProviderChange($event)">
      @for (p of model.selectedCategoryProviders; track p) {
      <mat-option [value]="p">{{ p.prCoName }}</mat-option>
      }
    </mat-select>
  </mat-form-field>

  @if (model.selectedProviderCatalog.length > 0) {
  <mat-selection-list #items [multiple]="false">
    @for (item of model.selectedProviderCatalog; track item) {
    <mat-list-option (click)="model.onReplaceEquipmentClick($event, item)" [value]="item">
      <div>
        <div class="eq-label">
          <span class="catcode">{{
            item.equipmentCatalog.eqCgName +
            " / " +
            item.equipmentCatalog.eqCgProductId
            }}</span>
          <span class="size">{{ item.width + " x " + item.height }}</span>
        </div>
        <img [src]="'https://static.echo-on.fr/' + item.floorCatalog.flCgImageUrl" />
      </div>
    </mat-list-option>
    }
  </mat-selection-list>
  }
</div>

}

<!-- Zone de définition des mouvements d'inventaire -->
@if (model.hasPlanningTask && model.planningStateChoices.length > 0) {
<div class="box">
  <span class="box-header">Mouvements d'inventaire</span>

  @for (choice of model.planningStateChoices; track choice) {
  <div class="planning-state-choice-container">
    <div class="planning-state-choice">
      <div class="color-pin"
        [style.background-color]="choice.planningState?.eqPlStColor"></div>
      <span class="property-value" [class.disabled]="choice.disabled">{{ choice.disabled ? choice.disabledName : choice.name }}</span>
    </div>
    @for (c of choice.children; track c) {
      <span class="property-value planning-state-command"
      [class.selected]="c.selected"
      [class.disabled]="!c.enabled"
      [class.fake]="c.id === 0"
      (click)="model.planningStateCommandClick($event, choice, c)">{{ c.name }}</span>
    }
  </div>
  }
</div>
} } }