import { SidePanelResizer } from './../../model/side-panel-resizer';
import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from 'src/app/core/material.module';
import { BlueprintViewerSidePanelVM } from '../../model/blueprint-viewer-side-panel-vm';
import { BpViewerSidePanelContentComponent } from '../bp-viewer-side-panel-content/bp-viewer-side-panel-content.component';

@Component({
  selector: 'xc-bp-viewer-side-panel',
  standalone: true,
  imports: [CommonModule, MaterialModule, BpViewerSidePanelContentComponent],
  templateUrl: './bp-viewer-side-panel.component.html',
  styleUrls: ['./bp-viewer-side-panel.component.scss']
})
export class BpViewerSidePanelComponent {
  @Input() sidePanelResizer: SidePanelResizer | undefined;
  @Input() sidePanel: BlueprintViewerSidePanelVM | undefined;
}
