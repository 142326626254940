export class DbModelUtils {
    /**
     * 
     * @param m La constante dans laquelle on recherche la clé
     * @param v La valeur du membre de constante
     * @returns Retourne la clé du membre de constante
     */
    static key(m: any, v: string): string {
        const result = Object.entries(m).find(([key, val]) => val === v)?.[0];
        if (result) return result;
        return "";
    }

    static scopeColumn(o: any): string | undefined {
        return Object.keys(o).find(x=> x.endsWith("_Scope") || x.endsWith("_ScopeId"));
    }

    static setProperty(o: object, key: string, value: any): void {
        Object.defineProperty(o, key, {
            value: value,
            writable: true,
            enumerable: true,
            configurable: true
        });
    }

    static getObject(entries: [key: string, value: any][]): {} {
        const result = {};
        entries.forEach(e => {
            DbModelUtils.setProperty(result, e[0], e[1]);
        });
        return result;
    }
}