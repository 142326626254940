export class RoomAllocationDisplay {
    name: string;
    businessUnitId: number;
    color: string;
    area: number;
    ratio: number;
    hasError: boolean = false;

    constructor(name: string, businessUnitId: number, color: string, area: number, ratio: number) {
        this.name = name;
        this.businessUnitId = businessUnitId;
        this.color = color;
        this.area = area;
        this.ratio = ratio;
    }
}