export enum TabPageContentTypeEnum {
    none = "none",
    piechart = "piechart",
    donutchart = "donutchart",
    hbarchart = "hbarchart",
    buildingstack = "buildingstack",
    buildingaxono = "buildingaxono",
    referential = "referential",
    inventory = "inventory",
    moveLists = "moveLists"
}