import { SvgEntityTypesEnum } from "src/app/core/model/svg-model/svg-entity-type-enum";
import { Grips } from "src/app/components-lib/svg/grips/grips";

export abstract class InsertGizmo {
    entityType: SvgEntityTypesEnum;
    targetGrips: Grips = new Grips("purple", "purple");
    completed: boolean = false;
    inserting: boolean = false;
    insertionCompleted?: () => void;

    constructor(entityType: SvgEntityTypesEnum) {
        this.entityType = entityType;
    }

    /**
     * Doit être overridé dans les classes héritées
     */
    protected initialize(): void {
    }

    reset(): void {
        this.initialize();
    }
}