import { FloorDataStateTable } from "../../db-model/tables/floor-data-state-table";
import { DefinedModelBase } from "../defined-model-base";

export class FloorDataState extends DefinedModelBase {
    flDaStId!: number;
    flDaStName!: string;
    flDaStDisplayNameId!: number;
    flDaStColor!: string;

    constructor(dtoData: any, tableDef: any[] = []) {
        super(dtoData, [FloorDataStateTable].concat(tableDef));
    }
}