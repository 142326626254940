import { Point } from "src/app/core/model/geometry-model/point.model";
import { Polygon } from "src/app/core/model/geometry-model/polygon.model";
import { Segment } from "src/app/core/model/geometry-model/segment.model";
import { XcMaths } from "src/app/core/model/static-functions/xc-maths";

export class TightBbox extends Polygon {
    constructor(vertices: Point[]) {
        super(vertices);
    }

    angle(): number {
        // pour déterminer l'angle on considère la droite menant du centre au milieu du petit côté
        // dont la coordonnée en x est supérieure ou égale à celle du centre
        // ainsi que la droite horizontale passant par le centre
        // si x est égale à c.x, on choisit y > c.y
        const c = this.center();
        const segs = this.SegmentsLengths();
        const min = segs.reduce((a, b) => a.length < b.length ? a : b).length;
        const minSegs = segs.filter(x=> Math.abs(x.length - min) < 0.000001);
        if (minSegs.length === 2) {
            const m1 = minSegs[0].segment.midPoint();
            const m2 = minSegs[1].segment.midPoint();
            let m: Point;
            if (m1.x === c.x) {
                if (m1.y > c.y) {
                    m = m1;
                } else {
                    m = m2;
                }
            } else {
                if (m1.x > c.x) {
                    m = m1;
                } else {
                    m = m2;
                }
            }
            const s = new Segment(c, m);
            return XcMaths.toDeg(s.angleWith(new Segment(c, new Point(c.x + 1, c.y))));
        }
        return 0;
    }

    SegmentsLengths(): {segment: Segment, length: number}[] {
        const segs = this.getSegments();
        const lengths: {segment: Segment, length: number}[] = [];

        segs.forEach(s => {
            lengths.push({segment: s, length: s.length()});
        });

        return lengths;
    }

    override getSegments(): Segment[] {
        const segs = super.getSegments();
        // Ajoute le segment de clôture
        segs.push(new Segment(this.vertices[0], this.vertices[this.vertices.length - 1]));
        return segs;
    }

    center(): Point {
        const vertices = this.vertices;
        const diag = new Segment(vertices[0], vertices[2]);
        return diag.midPoint();
    }

    static fromData(data: {x: number, y: number}[] | Point[]) {
        const vertices: Point[] = [];
        data.forEach((p) => {
            if (p instanceof Point) {
                vertices.push(p);
            } else {
                vertices.push(new Point(p.x, p.y));
            }
        });
        return new TightBbox(vertices);
    }

    layFlat(): TightBbox {
        return this.rotate(this.angle());
    }

    rotate(angleDeg: number): TightBbox {
        const c = this.center();
        const rotatedVertices: Point[] = [];
        this.vertices.forEach(v => {
            rotatedVertices.push(v.rotate(c, XcMaths.toRad(angleDeg)));
        });
        return TightBbox.fromData(rotatedVertices);
    }
}