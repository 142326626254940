import { RoomAllocationViewSet } from 'src/app/core/model/data-model/view-set/room-allocation-view-set';
import { RoomViewSet } from 'src/app/core/model/data-model/view-set/room-view-set';

export class RoomSet {
    roomSet: RoomViewSet;
    allocationsSet: RoomAllocationViewSet[] = [];

    constructor(dtoData: any) {
        this.roomSet = new RoomViewSet(dtoData);
    }
}