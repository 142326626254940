import { UnitOfWork } from './../tables/unit_of_work';
import { FacilityCompany } from "../tables/facility-company";
import { FacilityJob } from "../tables/facility-job";
import { FacilityJobTask } from "../tables/facility-job-task";
import { TaskFacility } from "../tables/task-facility";

export class TaskFacilityView extends TaskFacility {
    company: FacilityCompany;
    job: FacilityJob;
    jobTask: FacilityJobTask;
    unitOfWork: UnitOfWork;

    constructor(dtoData: any) {
        super(dtoData);
        this.company = new FacilityCompany(dtoData);
        this.job = new FacilityJob(dtoData);
        this.jobTask = new FacilityJobTask(dtoData);
        this.unitOfWork = new UnitOfWork(dtoData);
    }
}