import { FloorAttributionView } from 'src/app/core/model/data-model/views/floor-attribution-view';
import { FloorAttributionDbView } from 'src/app/core/model/db-model/views/floor-attribution-db-view';
import { Floor } from './../../../model/data-model/tables/floor';

export class FloorAttributionDTO extends Floor {
    activityStatus: FloorAttributionView[] = [];

    constructor(dtoData: any) {
        super(dtoData);
        if (dtoData[FloorAttributionDbView.databaseTableName]) {
            dtoData[FloorAttributionDbView.databaseTableName].forEach((e: any) => {
                this.activityStatus.push(new FloorAttributionView(e));
            });
        }
    }
}