import { DrawingBoardComponent } from '../drawing-board/drawing-board.component';
import { Component, Input } from '@angular/core';
import { DrawingContainerModel } from '../../model/drawing-container-model';
import { MaterialModule } from 'src/app/core/material.module';
import { ResizableLeftPanelVM } from 'src/app/components-lib/resizable-left-panel/model/resizable-left-panel-vm';
import { SplitterModule } from 'primeng/splitter';
import { PictosBrowserComponent } from '../pictos-browser/pictos-browser.component';

@Component({
  selector: 'xc-drawing-container',
  standalone: true,
  imports: [SplitterModule, MaterialModule, DrawingBoardComponent, PictosBrowserComponent],
  templateUrl: './drawing-container.component.html',
  styleUrls: ['./drawing-container.component.scss']
})
export class DrawingContainerComponent extends ResizableLeftPanelVM {
  @Input() override model: DrawingContainerModel | undefined;
}
