@if (model) {
<div class="chart-main">
    <div class="dashboardItemHeader">
        <button mat-icon-button aria-label="'Légende'" 
            class="action-button"
            (click)="model.captionButtonClick()">
            <mat-icon class="material-icons-outlined">format_list_bulleted</mat-icon>
        </button>
    </div>

    <xc-stack-chart [model]="model.data"></xc-stack-chart>

    <div class="dashboardItemCaption" 
        [style.display]="model.captionVisible ? 'flex' : 'none'">
        @for (c of model.captions; track c) {
  <div class="caption-row">
            <!-- <div class="color-pin" 
                [style.background]="item.pattern == null ? item.color : 'repeating-linear-gradient(45deg, ' + item.pattern.firstColor + ' 0px, ' + item.pattern.secondColor + ' 4px)'">
            </div> -->
            <div class="caption-commands">
                <button class="themeIcon themeColorIcon" 
                        [class.hidden]="c.hidden"
                        mat-icon-button 
                        matTooltip="{{c.hidden ? 'Afficher' : 'Isoler'}}"> 
                    <mat-icon class="material-icons"
                    [style.background]="c.color"
                    (click)="model.showIsolateCaptionButtonClick($event, c)">
                    square</mat-icon>
                </button>
                <button class="themeIcon"
                        [disabled]="c.hidden" 
                        mat-icon-button 
                        matTooltip="Masquer" 
                        aria-label="Masquer"
                        (click)="model.hideCaptionButtonClick($event, c)"> 
                    <mat-icon class="material-icons-outlined">visibility_off</mat-icon>
                </button>
            </div>
            <div [class.hidden]="c.hidden" class="caption-labels">
                <div class="color-pin" [style.background]="c.color"></div>
                <span class="caption-main-label">{{c.mainLabel}}</span>
                <!-- <span class="caption-sub-label">{{c.subLabel}}</span> -->
            </div>
        </div>
}
    </div>
</div>
}
