import { DynTableVM } from "src/app/components-lib/dyn-grid/model/dyn-table-vm";
import { DynTableVMBuilder } from "src/app/components-lib/dyn-grid/model/dyn-table-vm-builder";
import { FieldListItem } from "src/app/components-lib/shared-model/field-list-item";
import { ListField } from "src/app/components-lib/shared-model/list-field";
import { readableUUID } from "src/app/core/events/event-listener-uuid";
import { AppMenuRouteEnum } from "src/app/core/model/data-model/enums/app-menu-route-enum";
import { City } from "src/app/core/model/data-model/tables/city";
import { TablesNamesEnum } from "src/app/core/model/db-model/tables-names-enum";
import { AddressTable } from "src/app/core/model/db-model/tables/address-table";
import { CityTable } from "src/app/core/model/db-model/tables/city-table";
import { DbModelUtils } from "src/app/core/model/static-functions/db-model-utils";
import { DyntService } from "src/app/core/services/backend-services/dynt-service";
import { PaginationService } from "src/app/core/services/backend-services/pagination-service";
import { PageModel } from "src/app/ui/main/model/page-model";
import Container from "typedi";

export class AddressesAdminVM extends PageModel {
    adminTable: DynTableVM | undefined;

    private constructor() {
        super(AppMenuRouteEnum.layout_realestate_addresses, 0, readableUUID(AddressesAdminVM.name));
    }

    static async newAsync(): Promise<AddressesAdminVM> {
        const result = new AddressesAdminVM();
        result.adminTable = await result.loadAdminData("address_dynview");
        return result;
    }

    async loadAdminData(tableName: string): Promise<DynTableVM> {
        const s = Container.get(DyntService);
        const viewSet = await s.contextedDataSet(tableName);
        const p = Container.get(PaginationService);
        const paginator = await p.getPaginator(tableName);
        const adminTable = DynTableVMBuilder.getVM(viewSet, tableName, paginator);

        // Chargement des valeurs actuelles pour les villes
        const citiesIds = viewSet.viewData.map(x=> (x as any)[AddressTable.adCityId]);
        const cities = await s.downloadTable<City>(TablesNamesEnum.City, undefined, CityTable.ciId, citiesIds);
        adminTable.setCurrentSelectOption(AddressTable.adCityId, DbModelUtils.key(CityTable, CityTable.ciId), DbModelUtils.key(CityTable, CityTable.ciName), cities);
        
        adminTable.loadOptionsRequested = async (f: ListField, rowId: number) => {
            // Sélectionne les villes
            const cities = await s.downloadTable<City>(TablesNamesEnum.City);
            return cities.map(x=> new FieldListItem(x.ciId, x.ciName, null));
        }

        // adminTable.saveRequested = async (tableName: string, primaryColumnName: string, rowId: any, columnName: string, value: any): Promise<string | null> => {
        //     const s = Container.get(DyntService);
        //     const dto: any = {
        //         "TableName": tableName,
        //         [primaryColumnName]: rowId,
        //         [columnName]: value
        //     }
        //     return await s.patch(tableName, rowId, dto);
        // }

        // // TODO : cette méthode peut être mutualisée avec toutes les autres écoutes de ce type
        // adminTable.insertionRequested = async (values : {}): Promise<any> => {
        //     const s = Container.get(DyntService);
        //     return await s.post<any>("", values);
        // }

        // // TODO : cette méthode peut être mutualisée avec toutes les autres écoutes de ce type
        // adminTable.deletionRequested = async (tableName: string, rowId: any): Promise<boolean> =>  {
        //     const s = Container.get(DyntService);
        //     const result = await s.delete(tableName, rowId);
        //     if (result) {
        //         return true;
        //     }
        //     return false;
        // }

        return adminTable;
    }
}