@if (model) {
    <div class="flex justify-content-center h-full" [style]="{'--font-family': 'Open Sans', lineHeight: '1.6'}">
        <p-splitter 
            [panelSizes]="[25, 75]" 
            class="w-full"
            [style]="{ height: '100%' }" 
            styleClass="mb-5">
            <ng-template pTemplate>
                <div class="w-full h-full">
                    <h3 class="px-3">Table des matières</h3>
                    <p-tree class="w-full" 
                        styleClass="p-0 border-0" 
                        selectionMode="single" 
                        [(selection)]="model.selectedContent"
                        [value]="model.contents"
                        (selectionChange)="model.selectedContentChange()" />
                </div>
            </ng-template>
            <ng-template pTemplate>
                <div class="flex justify-content-center px-3 w-full overflow-auto">
                    <div [style]="{maxWidth: '50rem'}">
                        <markdown [disableSanitizer]="true" [data]="model.document"></markdown>
                        <div class="flex justify-content-center gap-3">
                            <p-button 
                                label="Préc." 
                                icon="pi pi-chevron-left" 
                                iconPos="left" 
                                size="small" 
                                [disabled]="!model.previousEnable"
                                (onClick)="model.previousButtonClick()"/>
                            <p-button 
                                label="Suiv." 
                                icon="pi pi-chevron-right" 
                                iconPos="right" 
                                size="small" 
                                [disabled]="!model.nextEnable"
                                (onClick)="model.nextButtonClick()"/>
                        </div>
                    </div>
                </div>
            </ng-template>
        </p-splitter>
    </div>
}
