@if (model) {
<mat-list>
    @for (item of model.defs; track item) {
  <mat-list-item>
        <div class="def-row">
            <span class="def-name">{{item.id}}</span>
            <div class="def-shape">
                <svg height="100%" width="100%" [attr.viewBox]="item.viewBox()" (click)="model.itemClick($event, item)">
                    <!-- <line x1="-0.8" y1="0" x2="0.8" y2="0" stroke="blue" stroke-width="0.07"></line> -->
                    <g xc-cad-conv-svg-group [entities]="item.entities" 
                        stroke-width="0.01" 
                        stroke="black" 
                        fill="none"></g>
                </svg>
            </div>
        </div>
    </mat-list-item>
}
</mat-list>
}

