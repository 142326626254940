import { BuildingEquipmentProviderDbView } from '../../db-model/views/building-equipment-provider-db-view';
import { DefinedModelBase } from '../defined-model-base';

export class BuildingEquipmentProviderView extends DefinedModelBase {
    buSiteId!: number;
    buId!: number;
    eqCgProviderId!: number;
    prCoName!: string;
    prCoColor!: string;
    buEqPrViEquipmentValue!: number;
    buEqPrViEquipmentCount!: number;

    constructor(dtoData: any) {
        super(dtoData, [BuildingEquipmentProviderDbView]);
    }
}