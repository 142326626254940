import { ZAppMenuDbView } from "../../db-model/views/z-app-menu-db-view";
import { ZAppMenu } from "../tables/z-app-menu";

export class ZAppMenuView extends ZAppMenu {
    zApMeDisplayName!: string;
    zApMeRoDisplayName!: string | null;
    zApMeRoName!: string | null;

    constructor(dtoData: any) {
        super(dtoData, [ZAppMenuDbView]);
    }
}