import { ITabPageContent } from "src/app/components-lib/tab-page-container/model/i-tab-page-content";
import { InventoryTabTypeEnum } from "./inventory-tab-type-enum";
import { DonutChartWrapperVM } from "src/app/ui/shared/charts/donut/model/donut-chart-wrapper-vm";
import { InventoryReportRow } from "./inventory-report-row";
import { InventoryChartBuilder } from "./inventory-chart-builder";
import { ChartBuilder } from "src/app/ui/shared/shared-model/chart-builder";
import { InventoryTreeNode } from "../../model/inventory-tree-node";
import { EquipmentService } from "src/app/core/services/backend-services/equipment-service";
import Container from "typedi";
import { EquipmentCatalogViewSet } from "src/app/core/model/data-model/view-set/equipment-catalog-view-set";
import { TabPageContent } from "src/app/components-lib/tab-page-container/model/tab-page-content";
import { readableUUID } from "src/app/core/events/event-listener-uuid";

export class InventoryStateVM extends TabPageContent<any> {
    catalog: EquipmentCatalogViewSet[] = [];
    chart: DonutChartWrapperVM | undefined;
    reportTable: InventoryReportRow[] = [];
    displayedColumns: string[] = ['row-header', 'catalog', 'stored', 'roomed', 'removed', 'unidentified', 'total'];

    constructor(node: InventoryTreeNode) {
        super(readableUUID("InventoryStateVM"), InventoryTabTypeEnum.inventorystate)
        this.setReport(node);
    }

    async setReport(node: InventoryTreeNode): Promise<void> {
        const s = Container.get(EquipmentService);
        const catalogChildren = this.getCatalogChildren(node);
        const equipments = await s.getEquipmentCategoryReport(node.id);
        if (equipments) {
            const qtyRow: InventoryReportRow = { rowHeader: "Quantité", catalog: catalogChildren.length, stored: equipments.stored, roomed: equipments.roomed, removed: equipments.removed, unidentified: 0, total: equipments.stored + equipments.roomed, selected: true };
            const valRow: InventoryReportRow = { rowHeader: "Valeur", catalog: -1, stored: equipments.storedValue, roomed: equipments.roomedValue, removed: equipments.removedValue, unidentified: -1, total: equipments.storedValue + equipments.roomedValue, selected: false };
            this.reportTable = [ qtyRow, valRow ];
            this.loadChartData(qtyRow);
        }
    }

    getCatalogChildren(node: InventoryTreeNode): EquipmentCatalogViewSet[] {
        let result: EquipmentCatalogViewSet[] = [];
    
        node.children.forEach(c => {
           if (c.children) {
            result = result.concat(this.getCatalogChildren(c));
           } else {
            result = result.concat(this.catalog.filter(x=> x.dataSet.eqCgCategoryId === c.parentId && x.dataSet.eqCgProviderId === c.id));
           }
        });
    
        return result;
    }

    loadChartData(r: InventoryReportRow): void {
        let unit = "€";
        if (r.rowHeader === "Quantité") unit = "U";
        this.chart = new DonutChartWrapperVM();
        const aData = InventoryChartBuilder.getAnonymousData(r);
        const chartData = ChartBuilder.getInventoryDonutChartData("Inventaire", aData, unit);
        chartData.labelsFontSize = 5;
        this.chart.set(chartData);
    }
      
    onReportRowClick(r: InventoryReportRow): void {
        this.reportTable[0].selected = false;
        this.reportTable[1].selected = false;
        r.selected = true;
        this.loadChartData(r);
    }
}