import { EquipmentPlanningTaskDbView } from "../../db-model/views/equipment-planning-task-db-view";
import { EquipmentPlanning } from "../tables/equipment-planning";

export class EquipmentPlanningTaskView extends EquipmentPlanning {
    taId!: number;
    taName!: string;
    eqPuPurchaseRef!: string | null;
    eqStArName!: string | null;
    eqPlTaViLocation!: string | null;
    eqItCatalogId!: number;
    eqCgFloorCatalogId!: number;

    constructor(dtoData: any) {
        super(dtoData, [EquipmentPlanningTaskDbView]);
    }
}