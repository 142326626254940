import { SvgEntityTypesEnum } from "src/app/core/model/svg-model/svg-entity-type-enum";
import { BpSvgGroup } from "./bp-svg-group";
import { BpSvgLine } from "./bp-svg-line";
import { BpSvgPath } from "./bp-svg-path";
import { BpSvgUse } from "./bp-svg-use";
import { FloorDataStateEnum } from "src/app/core/model/data-model/enums/floor-data-state-enum";
import { BpSvgEntity } from "./bp-svg-entity";
import { BpSvgCircle } from "./bp-svg-circle";
import { BpSvgRectangle } from "./bp-svg-rectangle";
import { BpSvgText } from "./bp-svg-text";
import { UpdatedFloorDataDTO } from "../services/dto/updated-floor-data-dto";
import { BlueprintSvgEntityTypeEnum } from "../svg-entities/model/blueprint-svg-entity-type-enum";

export function getBpEntities(entities: any): Array<BpSvgEntity> {
    let result = new Array<BpSvgEntity>();
    if(entities){
        entities.forEach((element: { entityType: any; }) => {
            switch(element.entityType){
                case SvgEntityTypesEnum.g:
                    result.push(new BpSvgGroup(element, BlueprintSvgEntityTypeEnum.none));
                    break;
                case SvgEntityTypesEnum.text:
                    result.push(new BpSvgText(element));
                    break;
                case SvgEntityTypesEnum.line:
                    result.push(new BpSvgLine(element));
                    break;
                case SvgEntityTypesEnum.path:
                    result.push(new BpSvgPath(element));
                    break;
                case SvgEntityTypesEnum.use:
                    result.push(new BpSvgUse(element));
                    break;
                case SvgEntityTypesEnum.rect:
                    result.push(new BpSvgRectangle(element));
                    break;
                case SvgEntityTypesEnum.circle:
                    result.push(new BpSvgCircle(element));
                    break;
                default:
                    result.push(new BpSvgEntity(element));
            }
        });
    }

    return result;
}


/**
 * Actualise les états à partir du retour du backend et masque ou affiche l'entité en fonction de son dataState
 * @param updates DTO de retour du backend contenant les identifiants des états
 */
function updateState(e: BpSvgEntity, updates: UpdatedFloorDataDTO): void {
    e.dataStateId = updates.dataStateId;
    e.planningStateId = updates.planningStateId;

    e.show(e.dataStateId !== FloorDataStateEnum.Deleted); 
}
