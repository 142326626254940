import { TaskContributorDirectoryTable } from '../../db-model/tables/task-contributor-directory-table';
import { TaskChangeLogDbView } from '../../db-model/views/task-change-log-db-view';
import { TaskChangeLog } from './../tables/task-change-log';

export class TaskChangeLogView extends TaskChangeLog {
    taCrDiId!: number;
    taCrDiCode!: string;
    taCrDiTitle!: string;
    taCrDiFirstName!: string;
    taCrDiLastName!: string;
    taCrDiIsActive!: boolean;
    sourceStatusName!: string;
    sourceStatusColor!: string;
    targetStatusName!: string;
    targetStatusColor!: string;

    constructor(dtoData: any) {
        super(dtoData, [TaskChangeLogDbView, TaskContributorDirectoryTable]);
    }
}