import { InventoryTabTypeEnum } from "./inventory-tab-type-enum";
import { InventoryTreeNode } from "../../model/inventory-tree-node";
import Container from "typedi";
import { DyntService } from "src/app/core/services/backend-services/dynt-service";
import { DynTableVMBuilder } from "src/app/components-lib/dyn-grid/model/dyn-table-vm-builder";
import { EquipmentCatalogTable } from "src/app/core/model/db-model/tables/equipment-catalog-table";
import { TableReportWrapper } from "src/app/ui/shared/charts/table/table-report-wrapper";
import { EquipmentService } from "src/app/core/services/backend-services/equipment-service";
import { EquipmentCatalogViewSet } from "src/app/core/model/data-model/view-set/equipment-catalog-view-set";
import { ProviderCompany } from "src/app/core/model/data-model/tables/provider-company";
import { TablesNamesEnum } from "src/app/core/model/db-model/tables-names-enum";
import { ProviderCompanyTable } from "src/app/core/model/db-model/tables/provider-company-table";
import { EquipmentCategoryTable } from "src/app/core/model/db-model/tables/equipment-category-table";
import { MatDialog } from "@angular/material/dialog";
import { ApiEndpoints } from "src/app/core/services/api-endpoints";
import { UploadService } from "src/app/core/services/backend-services/upload-service";
import { InventoryService } from "src/app/core/services/backend-services/inventory-service";
import { TabPageContent } from "src/app/components-lib/tab-page-container/model/tab-page-content";
import { readableUUID } from "src/app/core/events/event-listener-uuid";
import { ListField } from "src/app/components-lib/shared-model/list-field";
import { FieldListItem } from "src/app/components-lib/shared-model/field-list-item";

export class InventoryReferentialVM extends TabPageContent<any> {
    selectedTreeNode: InventoryTreeNode | undefined;
    categoryChildren: TableReportWrapper | undefined;
    providersList: ProviderCompany[] = [];
    selectedProvider: ProviderCompany | undefined;
    //selectedProviderCatalog: ProviderCatalog[] = [];
    //providerCatalogDisplayedColumns: string[] = ['symbol', 'productId', 'name', 'unitPrice', 'stockMin', 'stockAvg', 'stored', 'roomed', 'removed', 'unidentified', 'commands'];
    catalog: EquipmentCatalogViewSet[] = [];
    providerCatalog: TableReportWrapper | undefined;

    dialog: MatDialog | undefined;

    constructor(node: InventoryTreeNode) {
        super(readableUUID("InventoryReferentialVM"), InventoryTabTypeEnum.referential);
        this.loadData(node);
    }

    async loadData(node: InventoryTreeNode): Promise<void> {
        this.selectedTreeNode = node;
        if (node.children.length > 0) {
            const s = Container.get(DyntService);
            const datas = await s.contextedDataSet("equipment_category_dynview", 0, EquipmentCategoryTable.eqCaParentId, node.id);
            const table = DynTableVMBuilder.getVM(datas, EquipmentCatalogTable.databaseTableName, null);

            table.saveRequested = async (tableName: string, primaryColumnName: string, rowId: any, columnName: string, value: any): Promise<string | null> => {
                // La modification du nom de la catégorie c'est la modification du display name
                // donc de l'item de traduction

                // const s = Container.get(DyntService);
                // const dto: any = {
                //     "TableName": tableName,
                //     [primaryColumnName]: rowId,
                //     [columnName]: value
                // }
                // return await s.patch(tableName, rowId, dto);
                return null;
            }

            table.insertionRequested = async (values : {}): Promise<any> => {
                const s = Container.get(EquipmentService);
                //return await s.createNewInventoryEntry(values);
                return  null;
            }

            // TODO : cette méthode peut être mutualisée avec toutes les autres écoutes de ce type
            table.deletionRequested = async (tableName: string, rowId: any): Promise<boolean> =>  {
                // const s = Container.get(DyntService);
                // const result = await s.delete(tableName, rowId);
                // if (result) {
                //     return true;
                // }
                return false;
            }

            const tmpec = new TableReportWrapper();
            tmpec.set(table);
            this.categoryChildren = tmpec;
        } else {
            this.categoryChildren = undefined;
            // On est au niveau le plus profond, on affiche les entrées des catalogues fournisseurs
            await this.setProviderList();
        }
    }
    
    async setProviderList(): Promise<void> {
        if (!this.selectedTreeNode) return;
        const selectedNodeId = this.selectedTreeNode.id;
        const s = Container.get(EquipmentService);
        this.catalog = await s.getEquipmentCatalogView();

        const providersIds = [...new Set(this.catalog.filter(x=> x.dataSet.eqCgCategoryId === selectedNodeId).map(x=> x.dataSet.eqCgProviderId))];
        if (providersIds.length === 0) {
            this.providersList = [];
            return;
        }

        const t = Container.get(DyntService);
        this.providersList = await t.downloadTable<ProviderCompany>(TablesNamesEnum.ProviderCompany, undefined, ProviderCompanyTable.prCoId, providersIds);
    
        if (this.providersList.length > 0) {
            this.selectedProvider = this.providersList[0];
            await this.getProviderCatalog(this.selectedTreeNode.id, this.selectedProvider.prCoId);
        }
    }
        
    async getProviderCatalog(categoryId: number, providerId: number): Promise<void> {
        const s = Container.get(DyntService);
        const datas = await s.contextedDataSet("equipment_catalog_dynview", 0, EquipmentCatalogTable.eqCgCategoryId, categoryId);
        //datas.viewData = datas.viewData.filter(x=> x.getValue(EquipmentCatalogTable.eqCgCategoryId) === categoryId);
        const table = DynTableVMBuilder.getVM(datas, EquipmentCatalogTable.databaseTableName, null);
        const tmpec = new TableReportWrapper();
        tmpec.set(table);
        this.providerCatalog = tmpec;

        table.saveRequested = async (tableName: string, primaryColumnName: string, rowId: any, columnName: string, value: any): Promise<string | null> => {
            const s = Container.get(DyntService);
            const dto: any = {
                "TableName": tableName,
                [primaryColumnName]: rowId,
                [columnName]: value
            }
            return await s.patch(tableName, rowId, dto);
        }

        table.imageUploadRequested = async (file: File, inserting: boolean, maxSize: number, rowId: number): Promise<any> => {
            const s = Container.get(UploadService);
            if (inserting) {
                return await s.uploadTempFile(file, `${ApiEndpoints.endPoint(ApiEndpoints.floorCatalogTempImage)}`, maxSize);
            } else {
                return await s.uploadUpdateFile(file, `${ApiEndpoints.endPoint(ApiEndpoints.floorCatalogImageUrl)}`, rowId);
            }
        }

        table.insertionRequested = async (values : {}): Promise<any> => {
            const s = Container.get(InventoryService);
            return await s.createNewInventoryEntry(values);
        }

        
        // Chargement des valeurs actuelles pour les fournisseurs
        const currentProvOptions = this.providersList.map(x=> new FieldListItem(x.prCoId, x.prCoName, null));
        table.setSelectCurrentOptions(EquipmentCatalogTable.eqCgProviderId, currentProvOptions);

        // Chargement des valeurs actuelles pour les catégories
        const currentCatOptions = this.catalog.map(x=> new FieldListItem(x.equipmentCategoryView.eqCaId, x.equipmentCategoryView.eqCaFlatTreeDisplayName, null));
        table.setSelectCurrentOptions(EquipmentCatalogTable.eqCgCategoryId, currentCatOptions);

        table.loadOptionsRequested = async (f: ListField, rowId: number) => {
            if (f.colId === EquipmentCatalogTable.eqCgCategoryId) {
                 return this.catalog.map(x=> new FieldListItem(x.equipmentCategoryView.eqCaId, x.equipmentCategoryView.eqCaFlatTreeDisplayName, null));
            }

            if (f.colId === EquipmentCatalogTable.eqCgProviderId) {
                return this.providersList.map(x=> new FieldListItem(x.prCoId, x.prCoName, null));
            }

            return [];
    }

        // TODO : cette méthode peut être mutualisée avec toutes les autres écoutes de ce type
        table.deletionRequested = async (tableName: string, rowId: any): Promise<boolean> =>  {
            // const s = Container.get(DyntService);
            // const result = await s.delete(tableName, rowId);
            // if (result) {
            //     return true;
            // }
            return false;
        }
    }

    async onSelectedProviderChange(provider: ProviderCompany): Promise<void> {
        if (this.selectedTreeNode) {
            return await this.getProviderCatalog(this.selectedTreeNode.id, provider.prCoId);
        }
    }

}