import { TablesNamesEnum } from './../tables-names-enum';
export const TaskContributorDirectoryTable = {
    databaseTableName: TablesNamesEnum.TaskContributorDirectory,
    taCrDiId: 'TaCrDi_Id',
    taCrDiCode: 'TaCrDi_Code',
    taCrDiTitle: 'TaCrDi_Title',
    taCrDiFirstName: 'TaCrDi_FirstName',
    taCrDiLastName: 'TaCrDi_LastName',
    taCrDiIsActive: 'TaCrDi_IsActive'
  };
  