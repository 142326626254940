import { PerimeterDbView } from './../../db-model/views/perimeter-db-view';
import { Perimeter } from "../tables/perimeter";
import { PerimeterType } from "../tables/perimeter-type";

export class PerimeterView extends Perimeter {
    perimeterType!: PerimeterType;
    peIsGrantedItem!: boolean;

    constructor(dtoData: any) {
        super(dtoData, [PerimeterDbView]);
        this.perimeterType = new PerimeterType(dtoData);
        // force la valeur booléenne qui se trouve sous forme d'int dans la vue en base
        // avec MySql il est impossible de faire un cast en tinyint
        this.peIsGrantedItem = Boolean(this.peIsGrantedItem);
    }
}