import { TaskFacilityTable } from "../../db-model/tables/task-facility-table";
import { DefinedModelBase } from "../defined-model-base";

export class TaskFacility extends DefinedModelBase {
    taFaTaskId!: number;
    taFaCompanyTaskId!: number;
    taFaUnitOfWorkAmount!: number;

    constructor(dtoData: any) {
        super(dtoData, [TaskFacilityTable]);
    }
}