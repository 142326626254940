@if (model) {
<div>
    <p class="title">Informations générales</p>
    <div id="stepOneMainContainer">
        <div id="stepOneInfos">
            <p>La simulation produira un schéma directeur qui pourra être utilisé pour conduire un projet de transformation. Si celui-ci est déjà programmé vous pouvez le lier ici.</p>
            <p>La date de début est fixée à la date de création de la simulation car elle doit nécessairement correspondre au moment de la photo initiale de l'occupation.</p>
            <p>L'information de durée est nécessaire pour le calcul des variations d'effectifs mais pourra être ajustée par la suite.</p>
            <p>A l'étape suivante, vous allez définir le périmètre de la simulation. Par défaut il s'agit d'un choix parmi les immeubles mais vous pouvez opter pour une sélection par entités.</p>
        </div>
        <div id="stepOneForm">
            <form>
                <mat-form-field appearance="outline">
                    <mat-label>Intitulé</mat-label>
                    <input matInput name="nameInput" placeholder="Intitulé" required [(ngModel)]="model.creationData.name" (input)="model.nameChange()">
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Planification Transferts / Travaux</mat-label>
                    <mat-select>
                        <mat-option>Aucun schéma directeur n'est planifié</mat-option>
                    <!-- <mat-option *ngFor="let task of tasks" [value]="task.taId">
                        {{task.taName}}
                    </mat-option> -->
                    </mat-select>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Début</mat-label>
                    <input matInput required [disabled]="true" [value]="model.creationData.defaultStartDate | date : 'dd/MM/yyyy'">
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Fin</mat-label>
                    <input matInput disabled [matDatepicker]="picker" [min]="model.creationData.defaultStartDate" required (dateChange)="model.endDateChange($event)">
                    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker disabled="false" [startAt]="model.creationData.defaultStartDate"></mat-datepicker>
                </mat-form-field>
            </form>
            <mat-slide-toggle [(ngModel)]="model.creationData.busSelection" (change)="model.selectionTypeChange()">Sélection par les entités</mat-slide-toggle>
        </div>
    </div>

</div>
}
