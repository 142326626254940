import { HttpParams } from "@angular/common/http";
import { Service } from "typedi";
import { WorkplaceType } from 'src/app/core/model/data-model/tables/workplace-type';
import { WorkplaceViewSet } from 'src/app/core/model/data-model/view-set/workplace-view-set';
import { FloorDataTable } from 'src/app/core/model/db-model/tables/floor-data-table';
import { WorkplaceTable } from 'src/app/core/model/db-model/tables/workplace-table';
import { WorkplaceTypeDbView } from 'src/app/core/model/db-model/views/workplace-type-db-view';
import { ApiService } from 'src/app/core/services/api-service';
import { Vector } from 'src/app/core/model/geometry-model/vector.model';
import { ApiEndpoints } from 'src/app/core/services/api-endpoints';
import { Point } from 'src/app/core/model/geometry-model/point.model';
import { CounterResult } from "src/app/ui/pages/layout/real-estate/floor-blueprint/content/blueprint-viewer-content-panel/itself/services/dto/counter-result";
import { BpSvgGroup } from "src/app/ui/pages/layout/real-estate/floor-blueprint/content/bp-svg-core-model/bp-svg-group";
import { UpdatedFloorDataDTO } from "src/app/ui/pages/layout/real-estate/floor-blueprint/content/services/dto/updated-floor-data-dto";
import { BlueprintSvgEntityTypeEnum } from "src/app/ui/pages/layout/real-estate/floor-blueprint/content/svg-entities/model/blueprint-svg-entity-type-enum";
import { BpSvgWorkplaceLabel } from "src/app/ui/pages/layout/real-estate/floor-blueprint/content/svg-entities/model/bp-svg-workplace-label";
import { BlueprintWorkplaceLabelLayer } from "src/app/ui/pages/layout/real-estate/floor-blueprint/content/svg-entities/model/layers/blueprint-workplace-label-layer";

@Service({ global: true })  
export class WorkplaceService extends ApiService {
    constructor() {
        super();
    }

    async getTypes(): Promise<WorkplaceType[]> {
        const result = await this.getAsync<WorkplaceType[]>(this.dynt(WorkplaceTypeDbView.databaseTableName));
        if (result != null) {
            return result.payload.map(item => {
                return new WorkplaceType(item);
            })
        }
        return [];
    }

    async getNextCodeValue(code: string, step: number): Promise<CounterResult | null> {
        const result = await this.getAsync<CounterResult>(this.endPoint(ApiEndpoints.workplaceCodeNextValue(code, step)));
        if (result != null) {
            return new CounterResult(result.payload);        
        }
        return null;
    }

    async getNextCodeValues(code: string, step: number, count: number): Promise<CounterResult[]> {
        const result = await this.getAsync<CounterResult[]>(this.endPoint(ApiEndpoints.workplaceCodeNextValues(code, step, count)));
        if (result != null) {
            return result.payload.map(x=> {
                return new CounterResult(x);
            })
        }
        return [];
    }
  
    async insert(furnitureId: number, insertionPoint: Point, code: string, type: number): Promise<{label: BpSvgWorkplaceLabel, layer: BlueprintWorkplaceLabelLayer | null} | null> {
        const dto: any = {};
        dto[FloorDataTable.flDaId] = furnitureId;
        dto[WorkplaceTable.woCode] = code;
        dto[WorkplaceTable.woWorkplaceType] = type;
        dto['x'] = insertionPoint.x;
        dto['y'] = insertionPoint.y;
        const result = await this.postAsync<any>(this.endPoint(ApiEndpoints.workplaces), dto);
        if (result != null) {
            const label = new BpSvgWorkplaceLabel(result.payload['SvgGroupDTO'][0]);
            const workplace = new WorkplaceViewSet(result.payload["Workplace"]);
            label.workplace = workplace;
            let layer = null;
            if (result.payload['FloorModel']) {
                layer = new BlueprintWorkplaceLabelLayer(result.payload['FloorModel'], label.taskId);
            }
            return {label: label, layer: layer};
        }
        return null;
    }

    async updateLabel(floorDataId: number, code: string): Promise<BpSvgGroup | null> {
        const result = await this.patchAsync<BpSvgGroup>(this.endPoint(ApiEndpoints.workplaceUpdateLabelCode(floorDataId, code)), null);
        if (result != null) {
            return new BpSvgGroup(result.payload, BlueprintSvgEntityTypeEnum.workplaceLabel);
        }
        return null;
    }

    async updateType(floorDataIds: number[], typeId: number): Promise<BpSvgGroup[]> {
        const dto: any = {};
        dto[FloorDataTable.flDaId] = floorDataIds;
        dto[WorkplaceTable.woWorkplaceType] = typeId;

        const result = await this.patchAsync<BpSvgGroup[]>(this.endPoint(ApiEndpoints.workplaceUpdateType), dto);
        if (result != null) {
            return result.payload.map(item => {
                return new BpSvgGroup(item, BlueprintSvgEntityTypeEnum.workplaceLabel);
            })
        }
        return [];
    }

    async updatePosition(floorDataId: number, deltaPosition: Vector): Promise<BpSvgGroup[]> {
        const dto = {
            x: deltaPosition.u,
            y: deltaPosition.v
        };
        const result = await this.patchAsync<BpSvgGroup[]>(this.endPoint(ApiEndpoints.moveLabel(floorDataId)), dto);
        if (result != null) {
            return result.payload.map(item => {
                return new BpSvgGroup(item, BlueprintSvgEntityTypeEnum.workplaceLabel);
            })
        }
        return [];
    }
    
    async deleteWorkplaces(workplaceIds: number[]): Promise<UpdatedFloorDataDTO[]> {
        let params = new HttpParams();
        workplaceIds.forEach(id => {
            params = params.append(`id`, String(id));
        });
        const result = await this.deleteAsync<UpdatedFloorDataDTO[]>(this.endPoint(ApiEndpoints.workplaces), params);
        if (result != null) {
            return result.payload.map(item => {
                return new UpdatedFloorDataDTO(item);
            })
        }
        return [];
    }
}