@if (model) {
    <div class="h-full overflow-auto max-w-30rem">
        <p-dataView #dv [value]="model.layers">
            <ng-template pTemplate="header">
                <div class="flex flex-column">
                    <div class="col-12 p-0 flex justify-content-between align-items-center">
                        <span>Equipement</span>
                        <div class="flex gap-5">
                            <span>Lecture</span>
                            <span>Ecriture</span>
                        </div>
                    </div>
                </div>
            </ng-template>
            <ng-template let-layers pTemplate="list">
                <div class="grid grid-nogutter">
                    @for (layer of model.layers; track $index) {
                        <div class="col-12 flex justify-content-between p-2 align-items-center">
                            <span>{{ layer.layer.flMoDisplayName }}</span>
                            <div class="flex gap-7 pr-3">
                                <p-checkbox [(ngModel)]="layer.isRoleReadGranted" 
                                    (onChange)="model.grantChange($event, layer.layer, model.grantTypes.Read)" 
                                    [disabled]="!layer.readGranted"
                                    [binary]="true" 
                                    [inputId]="layer.layer.flMoId + 'read'">
                                </p-checkbox>
                                <p-checkbox [(ngModel)]="layer.isRoleUpdateGranted" 
                                    (onChange)="model.grantChange($event, layer.layer, model.grantTypes.Update)" 
                                    [disabled]="!layer.updateGranted"
                                    [binary]="true" 
                                    [inputId]="layer.layer.flMoId + 'update'">
                                </p-checkbox>
                            </div>
                        </div>
                    }
                </div>
            </ng-template>
        </p-dataView>
    </div>
}
