@if (model != null) {
<mat-tree #layersTree [dataSource]="model.dataSource" [treeControl]="model.treeControl" class="example-tree">
    <!-- This is the tree node template for leaf nodes -->
    <!-- There is inline padding applied to this node using styles.
      This padding value depends on the mat-icon-button width. -->
    <mat-tree-node class="treeNode" *matTreeNodeDef="let node" matTreeNodeToggle>
        <!-- <mat-radio-button *ngIf="node.isEditable" class="editable"></mat-radio-button> -->
        <mat-checkbox class="checklist-leaf-node"
          [checked]="node.isVisible"
          (change)="node.onVisibilityCheckChange()">
          {{node.name}}
        </mat-checkbox>
      
    </mat-tree-node>
    <!-- This is the tree node template for expandable nodes -->
    <mat-nested-tree-node *matTreeNodeDef="let node; when: model.hasChild">
        <div class="mat-tree-node treeNode">
          <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.name">
            <mat-icon class="mat-icon-rtl-mirror">
              {{model.treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
            </mat-icon>
          </button>
          <mat-checkbox class="parent-node" [checked]="node.isVisible"
            [indeterminate]="node.childrenPartiallyVisible"
            (change)="node.onVisibilityCheckChange()">
            {{node.name}}
          </mat-checkbox>
          
        </div>
        <!-- There is inline padding applied to this div using styles.
        This padding value depends on the mat-icon-button width.  -->
        <div [class.example-tree-invisible]="!model.treeControl.isExpanded(node)" role="group">
          <ng-container matTreeNodeOutlet></ng-container>
      </div>
    </mat-nested-tree-node>
</mat-tree>
}
