import { TreeNode } from 'primeng/api';
import { TaskView } from 'src/app/core/model/data-model/views/task-view';
import { MoveWorkTreeNodeTypeEnum } from './move-work-tree-node-type-enum';

export class MoveWorkTreeNode implements TreeNode<TaskView> {
    taskType: MoveWorkTreeNodeTypeEnum;
    id: number;
    name: string;
    key: string;
    label: string;
    // startDate: Date;
    // duration: number;
    // color: string;
    //badgeType: string = "";
    //detailsLoaded: boolean = false;
    children: MoveWorkTreeNode[] = [];
    parent: MoveWorkTreeNode | undefined;
    selected: boolean = false;
    expanded: boolean = false;
    data: TaskView;

    constructor(type: MoveWorkTreeNodeTypeEnum, id: number, name: string, data: TaskView) {
        this.taskType = type;
        this.id = id;
        this.name = name;
        this.label = name;
        this.key = id.toString();
        // this.startDate = startDate;
        // this.duration = duration;
        // this.color = color;
        this.data = data;
    }

    setChildren(children: MoveWorkTreeNode[]): void {
        this.children = children;
        children.forEach(c => {
            c.parent = this;
        });
    }

    // isTaskNode(): boolean {
    //     return this.type === MoveWorkTreeNodeTypeEnum.project || this.type === MoveWorkTreeNodeTypeEnum.task;
    // }
}