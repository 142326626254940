import { LeaseEditorVM } from '../../model/lease-editor-vm';
import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WipIconComponent } from 'src/app/components-lib/wip-icon/wip-icon.component';
import { SplitterModule } from 'primeng/splitter';
import { LeasesTreeComponent } from '../leases-tree/leases-tree.component';
import { MVVMEventDestroyLifeCycle } from 'src/app/components-lib/shared-model/mvvm-events-destroy-lifecycle';

@Component({
  selector: 'xc-lease-editor',
  standalone: true,
  imports: [CommonModule, WipIconComponent, SplitterModule, LeasesTreeComponent],
  templateUrl: './lease-editor.component.html',
  styleUrls: ['./lease-editor.component.scss']
})
export class LeaseEditorComponent extends MVVMEventDestroyLifeCycle {
  @Input() override model: LeaseEditorVM | undefined;
}
