@if (model) {
<div class="side-panel-content">
@switch (selectedTabIndex) {
    @case (BlueprintSidePanelTabEnum.tasks) {
  <xc-planning-task-form [model]="model.floorTasksTabModel"/>
}
    @case (BlueprintSidePanelTabEnum.masterPlan) {
  <xc-master-plan-form/>
}
    @case (BlueprintSidePanelTabEnum.layers) {
  <xc-layers-form [model]="model.layersTree"/>
}
    @case (BlueprintSidePanelTabEnum.displayThemes) {
  <xc-theming [model]="model.themeCaptions">Thèmes</xc-theming>
}
    @case (BlueprintSidePanelTabEnum.properties) {
  <div>
      @if (model.propertiesModel) {

        
@switch (model.propertiesModel.layerId) {
          @case (FloorModelEnum.Rooms) {
  <xc-room-properties-form [model]="$any(model.propertiesModel)"/>
}
          @case (FloorModelEnum.Walls) {
  <xc-wall-properties-form [model]="$any(model.propertiesModel)"/>
}
          @case (FloorModelEnum.Doors) {
  <xc-door-properties-form [model]="$any(model.propertiesModel)"/>
}
          @case (FloorModelEnum.PeopleLabels) {
  <xc-people-properties-form/>
}
          @case (FloorModelEnum.WorkplaceLabels) {
  <xc-workplace-properties-form [model]="$any(model.propertiesModel)"/>
}
          @default {
  <xc-equipment-properties-form [model]="$any(model.propertiesModel)"/>
}
        }

      
}
    </div>
}
    @case (BlueprintSidePanelTabEnum.equipments) {
  <xc-equipment-list-form [model]="model.equipmentsList"/>
}
    @case (BlueprintSidePanelTabEnum.labeling) {
  <xc-labeling-list-form [model]="model.labelingModel"/>
}
  }
</div>
}

