import { HtmlEcsyConstants } from 'src/app/core/model/html-model/html-ecsy-constants.model';
import { Segment } from "src/app/core/model/geometry-model/segment.model";
import { SvgRectangle } from "src/app/core/model/svg-model/svg-rectangle.model";
import { SvgTspan } from "src/app/core/model/svg-model/svg-tspan.model";
import { BpSvgLabel } from "./bp-svg-label";
import { WorkplaceAllocationViewSet } from 'src/app/core/model/data-model/view-set/workplace-allocation-view-set';
import { WorkplaceViewSet } from 'src/app/core/model/data-model/view-set/workplace-view-set';
import { BpSvgLine } from '../../bp-svg-core-model/bp-svg-line';
import { BlueprintSvgEntityTypeEnum } from './blueprint-svg-entity-type-enum';
import { BpSvgLeaderLine } from './bp-svg-leader-line';
import { BpSvgWorkplaceLabel } from './bp-svg-workplace-label';
import { logError } from 'src/app/core/services/logging-service';

export class BpSvgPeopleLabel extends BpSvgLabel {
    titleTspan: SvgTspan;
    firstNameTspan: SvgTspan;
    lastNameTspan: SvgTspan;

    // leaderlineBase: BpSvgLine | undefined;
    // leaderlineConnector: BpSvgLine | undefined;
    leaderLine!: BpSvgLeaderLine;

    workplace: WorkplaceViewSet | undefined;
    workplaceAllocation: WorkplaceAllocationViewSet | undefined;

    parent: BpSvgWorkplaceLabel | undefined;

    constructor(dtoData: any) {
        super(dtoData, BlueprintSvgEntityTypeEnum.peopleLabel);

        this.titleTspan = this.text.tSpans[0];
        this.firstNameTspan = this.text.tSpans[1];
        this.lastNameTspan = this.text.tSpans[2];

        this.leaderLine = new BpSvgLeaderLine(this.entities.find(x=> x.xcFunc === HtmlEcsyConstants.xcLabelLeaderLinebase) as BpSvgLine,
            this.entities.find(x=> x.xcFunc === HtmlEcsyConstants.xcLaberLeaderLine) as BpSvgLine);
        
            this.entities.push(this.leaderLine);
        
            
        this.backgroundSizeChanged = () => {
            if (this.parent) {
                this.updateLeaderLine(this.parent.backgroundRectangle);
            }
        }

    }

    setParent(parent: BpSvgWorkplaceLabel): void {
        this.parent = parent;
        // this.parent.backgroundSizeChanged = () => {
        //     this.updateLeaderLine(this.parent!.backgroundRectangle);
        // }
    }

    moveLeaderEndTo(workplaceLabelBackground: SvgRectangle): void {
        if (this.leaderLine.leaderlineConnector) {
            const leader = new Segment(this.leaderLine.leaderlineConnector.startPoint, workplaceLabelBackground.center());
            const intersects = workplaceLabelBackground.polygon().getBpIntersects([leader]);
            if (intersects.length > 0) {
                const firstI = intersects[0].intersections[0];
                this.leaderLine.leaderlineConnector.translateEndpointTo(firstI.point);
            } else {
                logError("BpSvgPeopleLabel.moveLeaderEndTo : la ligne de repère n'a pas été initialisée");
            }
        }
    }

    updateLeaderLine(workplaceLabelBackground: SvgRectangle): void {
        if (this.leaderLine.leaderlineBase && this.leaderLine.leaderlineConnector) {
            // Le segment joignant les centres des deux rectangles d'arrière plan
            const s = new Segment(this.backgroundRectangle.center(), workplaceLabelBackground.center());
            // La ligne de repère va de l'un à l'autre centre et est interrompue à au niveau des intersections
            const i1 = workplaceLabelBackground.polygon().getBpIntersects([s]);
            const i2 = this.backgroundRectangle.polygon().getBpIntersects([s]);
            if (i1.length > 0 && i2.length > 0) {
                const firstI1 = i1[0].intersections[0];
                const firstI2 = i2[0].intersections[0];

                // TODO : revoir le calcul pour les cas limites où l'étiquette chevauche celle de la position de travail
                if (firstI1) {
                    this.leaderLine.leaderlineConnector.translateEndpointTo(firstI1.point);
                }
                if (firstI2) {
                    this.leaderLine.leaderlineConnector.translateStartpointTo(firstI2.point);

                    this.leaderLine.leaderlineBase.translateStartpointTo(firstI2.segment.startPoint);
                }
                this.leaderLine.leaderlineBase.translateEndpointTo(this.leaderLine.leaderlineConnector.startPoint);

                this.leaderLine.hidden = false;
            }
        } else {
            logError("BpSvgPeopleLabel.moveLeaderEndTo : la ligne de repère n'a pas été initialisée");
        }
    }
}
