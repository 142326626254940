import { Polygon } from "src/app/core/model/geometry-model/polygon.model";
import { HtmlConstants } from "src/app/core/model/html-model/html-constants.model";
import { BpSvgLabel } from "../bp-svg-label";
import { BlueprintLayer } from "./blueprint-layer";

export class BlueprintLabelLayer<T extends BpSvgLabel = BpSvgLabel> extends BlueprintLayer<T> {
    constructor(dtoData: any, taskId: number) {
        super(dtoData, taskId)
        this.isLabelLayer = true;
    }

    setLabelsColor(color: string, opacity: number): void {
        (this.typedData()).forEach(l => {
            l.setDisplay(color, opacity);
        });
    }

    resetLabelsColor(): void {
        (this.typedData()).forEach(l => {
            l.resetDisplay();
        });
    }

    showLabels(display: boolean): void {
        (this.typedData()).forEach(l => {
            l.show(display);
        });
    }

    select(polygon: Polygon): T[] {
        const result: T[] = [];

        (this.typedData()).forEach(l => {
            if (l.display === HtmlConstants.styleDisplayBlock) {
                if (polygon.contains(l.backgroundRectangle.center())) {
                    result.push(l);
                }
            }
        });

        return result;
    }
}
