import { SvgEntityTypesEnum } from "src/app/core/model/svg-model/svg-entity-type-enum";
import { DxfSvgCircle } from "../../svg/dxf-svg-circle";
import { UpdateGizmo } from "./update-gizmo";
import { Vector } from "src/app/core/model/geometry-model/vector.model";

export class CircleGizmo extends UpdateGizmo<DxfSvgCircle> {
    constructor(pixelUnit: number) {
        super(pixelUnit, SvgEntityTypesEnum.circle);
    }

    translateCircles(delta: Vector): void {
        this.entities.forEach(e => {
            e.translate(delta);
        });
        this.grips.items.forEach(g => {
            g.point = g.point.translate(delta);
        });
    }

    endTranslate(): void {
        this.entities.forEach(e => {
            e.updateSelectablePoints();
        });
    }
}