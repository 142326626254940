import { Directory } from "src/app/core/model/data-model/tables/directory";
import { TaskContributorDirectory } from "src/app/core/model/data-model/tables/task-contributor-directory";
import { TablesNamesEnum } from "src/app/core/model/db-model/tables-names-enum";
import { DirectoryTable } from "src/app/core/model/db-model/tables/directory-table";
import { DyntService } from "src/app/core/services/backend-services/dynt-service";
import Container from "typedi";

export class DirectorySelectVM {
    currentContibutorsCodes: string[] = [];
    filteredDirectory: Directory[] = [];
    selectedDirectoryItem: Directory | undefined;
    canSave: boolean = false;

    constructor() {
        this.loadRefData();
    }

    async loadRefData(): Promise<void> {
        const s = Container.get(DyntService);
        // Charge les contributeurs existants pour filtrer les résultats de recherche
        const tmp = await s.downloadTable<TaskContributorDirectory>(TablesNamesEnum.TaskContributorDirectory);
        this.currentContibutorsCodes = tmp.map(x=> x.taCrDiCode);
    }

    async onSearchChange(e: any): Promise<void> {
        const value = e.target.value;
        if (value === "") {
          this.filteredDirectory = [];
          return;
        }
        const s = Container.get(DyntService);
        const tmp = await s.search<Directory>(TablesNamesEnum.Directory, value, [DirectoryTable.diFirstName, DirectoryTable.diLastName]);
        // Exclu des résultats les contributeurs existants
        this.filteredDirectory = tmp.filter(x=> !this.currentContibutorsCodes.includes(x.diCode))
    }
    
    
    displayFn(user: Directory): string {
        return user && user.diFirstName ? `${user.diFirstName} ${user.diLastName}` : '';
    }
}