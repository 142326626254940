import { BpSvgBoundingBox } from "src/app/ui/pages/layout/real-estate/floor-blueprint/content/bp-svg-core-model/bp-svg-bounding-box";
import { DxfSvgEntity } from "./dxf-svg-entity";
import { getDxfEntities } from "./dxf-svg-entity-builder";

export class DxfSvgDef extends DxfSvgEntity {
    entities: DxfSvgEntity[] = [];
    id: string;
    bBox: BpSvgBoundingBox | undefined;

    constructor(dtoData: any) {
        super(dtoData);
        
        this.id = dtoData.attributes["id"];
        this.entities = getDxfEntities(dtoData.entities);
        this.calculateBbox();
    }

    calculateBbox(): void {
        this.bBox = BpSvgBoundingBox.default();
        this.entities.forEach(e => {
            e.updateSelectablePoints();
            this.bbox.add(e.bbox);
        });
    }

    viewBox(): string {
        if (this.bBox) {
            return `${this.bbox.minX} ${this.bbox.minY} ${this.bbox.width()} ${this.bbox.height()}`;
        }
        return "-1 -1 2 2";
    }
}