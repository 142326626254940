@if (model) {
    <div class="h-full p-3">
        @switch (model.selectedChartType?.id) {
            @case ("blueprint") {
                <xc-floor-blueprint-editor [model]="model.blueprintEditor"/>
            }
            @default {
                <xc-floor-dashboard [model]="model.dashboard"/>
            }
        }
    </div>
}