import { ZConstraintTypeEnum } from './../../../core/model/data-model/enums/z-constraint-type-enum';
import { ZColumnConstraintView } from './../../../core/model/data-model/views/z-column-constraint-view';
import { FieldListItem } from '../../shared-model/field-list-item';
import { ZDbTypeEnum } from "src/app/core/model/data-model/enums/z-db-type-enum";
import { FieldTypeEnum } from "../../shared-model/field-type-enum";
import { ZColumnView } from 'src/app/core/model/data-model/views/z-column-view';

export class DynTableColumn {
    cellType: FieldTypeEnum;
    dbType: ZDbTypeEnum;
    id: string;
    headerLabel: string;
    readOnly: boolean;
    definitions: ZColumnView;
    constraints: ZColumnConstraintView[];
    selectItems: FieldListItem[] = [];
    contextTableUerColumnId: number;

    constructor(cellType: FieldTypeEnum, dbType: ZDbTypeEnum, id: string, headerLabel: string, readOnly: boolean, definitions: ZColumnView, constraints: ZColumnConstraintView[], contextTableUerColumnId: number) {
        this.cellType = cellType;
        this.dbType = dbType;
        this.id = id;
        this.headerLabel = headerLabel;
        this.readOnly = readOnly;
        this.definitions = definitions;
        this.constraints = constraints;
        this.contextTableUerColumnId = contextTableUerColumnId;
        this.loadSelectedItems();
    }

    loadSelectedItems(): void {
        const listConstaint = this.constraints.find(x=> x.coCoConstraintType === ZConstraintTypeEnum.List);
        if (listConstaint) {
            listConstaint.requestValues.forEach(v => {
                this.selectItems.push(new FieldListItem(v.id, v.value, v.fileUrl));
            });
        }
    }
}