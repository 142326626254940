import { Component, Input } from '@angular/core';
import { StoresDashboardVM } from '../../model/stores-dashboard-vm';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { WebcamImage } from 'ngx-webcam';
import { DynTableComponent } from 'src/app/components-lib/dyn-table/dyn-table.component';

@Component({
  selector: 'xc-stores-dashboard',
  standalone: true,
  imports: [DropdownModule, FormsModule, DynTableComponent],
  templateUrl: './stores-dashboard.component.html',
  styleUrl: './stores-dashboard.component.scss'
})
export class StoresDashboardComponent {
  @Input() model: StoresDashboardVM | undefined;
  
  private trigger: Subject<any> = new Subject();
  public webcamImage!: WebcamImage;
  private nextWebcam: Subject<any> = new Subject();
  sysImage = '';


  public getSnapshot(): void {
    this.trigger.next(void 0);
  }

  public captureImg(webcamImage: WebcamImage): void {
    this.webcamImage = webcamImage;
    this.sysImage = webcamImage!.imageAsDataUrl;
    console.info('got webcam image', this.sysImage);
  }

  public get invokeObservable(): Observable<any> {
    return this.trigger.asObservable();
  }
  
  public get nextWebcamObservable(): Observable<any> {
    return this.nextWebcam.asObservable();
  }
}
