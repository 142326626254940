@if (model) {
    <div class="flex align-items-center">
        <p-inputGroup class="w-auto">
            <p-inputGroupAddon class="w-auto p-0">
                <i class="pi pi-search"></i>
            </p-inputGroupAddon>
            <p-autoComplete [style]="{'width': 'auto', 'height': '34px'}"
                [(ngModel)]="model.selectedBuilding" 
                field="buName"
                placeholder="Immeuble..."
                [suggestions]="model.buildings" 
                (completeMethod)="model.searchForBuilding($event)" 
                (onSelect)="model.onSearchResultSelect($event)"/>
        </p-inputGroup>
        <p-selectButton class="ml-3"
            [disabled]="!model.selectedBuilding"
            allowEmpty="false"
            [options]="model.chartTypes" 
            [(ngModel)]="model.selectedChartType"
            (onOptionClick)="model.onSelectedChartTypeChange($event)" 
            optionLabel="icon">
            <ng-template let-item pTemplate>
                <i [class]="item.icon"></i>
            </ng-template>
        </p-selectButton>
        <div class="flex flex-wrap gap-3 ml-3">
            @for (option of model.options; track $index) {
                <div class="field-checkbox mb-0" [style]="{'display': model.optionDisabled(option.id) ? 'none' : 'block'}">
                    <p-radioButton 
                        [inputId]="option.id" 
                        name="label" 
                        [value]="option" 
                        [(ngModel)]="model.selectedOption" 
                        (onClick)="model.optionChange($event)"/>
                    <label [for]="option.id" class="ml-2">
                        {{ option.label }}
                    </label>
                </div>
            }
        </div>
    </div>
}