import { Component, Input } from '@angular/core';
import { MVVMEventDestroyLifeCycle } from 'src/app/components-lib/shared-model/mvvm-events-destroy-lifecycle';
import { DataViewModule } from 'primeng/dataview';
import { CheckboxModule } from 'primeng/checkbox';
import { LayersGrantsVM } from '../../model/layers-grants-vm';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'xc-layers-grants',
  standalone: true,
  imports: [DataViewModule, CheckboxModule, FormsModule],
  templateUrl: './layers-grants.component.html',
  styleUrl: './layers-grants.component.scss'
})
export class LayersGrantsComponent extends MVVMEventDestroyLifeCycle {
  @Input() override model: LayersGrantsVM | undefined;
}
