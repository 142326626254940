import { SvgEntityTypesEnum } from "src/app/core/model/svg-model/svg-entity-type-enum";
import { InsertGizmo } from "./insert-gizmo";
import { Point } from "src/app/core/model/geometry-model/point.model";
import { logError } from "src/app/core/services/logging-service";

export class TextInsertGizmo extends InsertGizmo {
    position!: Point;
    fontSize: number = 0.1;
    textAnchor: string = "start";
    fill: string = "black";
    text: string = "Nouveau texte";

    constructor() {
        super(SvgEntityTypesEnum.line);
        this.initialize();
    }

    protected override initialize(): void {
        this.position = Point.origin();
        this.completed = false;
    }

    update(point: Point): void {
        this.position = point;
    }

    define(point: Point | undefined): void {
        if (this.insertionCompleted) {
            this.insertionCompleted();
            this.completed = true;
        } else {
            logError("TextInsertGizmo.insertionCompleted n'est pas écouté");
        }
    }
}