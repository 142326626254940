export class HtmlConstants {
    static noneValue = "none";
    static defaultValue = "default";
    static inheritValue = "inherit";

    static styleDisplayNone = HtmlConstants.noneValue;
    static styleDisplayBlock = "block";

    static idAttributeName = "id";

    static styleCursorDefault = HtmlConstants.defaultValue;
    static styleCursorPointer = "pointer";
    static styleCursorInherit = HtmlConstants.inheritValue;
}
