import { DateUtils } from "src/app/core/model/static-functions/date-utils";
import { logError } from "src/app/core/services/logging-service";

export class SvgAddPhotoGizmoVM {
    x: number = 0;
    y: number = 0;
    stroke: string = "#ffbb33";
    fill = "#ffd480";
    date: Date;
    startDate: Date;
    dayWidth: number;
    offset: number;

    constructor(startDate: Date, dayWidth: number, offset: number) {
        this.startDate = startDate;
        this.offset = offset;
        this.date = startDate;
        this.dayWidth = dayWidth;
    }

    initialize(y: number): void {
        this.y = y;
        this.setDateLabel();
    }

    setDateLabel(): void {
        this.date = DateUtils.addDays(this.startDate, (this.x - this.offset) / this.dayWidth)
    }

    move(x: number): void {
        this.x = x;
        this.setDateLabel();
    }

    addPhotoRequested?: (date: Date) => void;
    markerClick(): void {
        if (this.addPhotoRequested) {
            this.addPhotoRequested(this.date);
        } else {
            logError("SvgAddPhotoGizmoVM.addPhotoRequested n'est pas écouté");
        }
    }
}