import { Component, Input } from '@angular/core';
import { MVVMEventDestroyLifeCycle } from 'src/app/components-lib/shared-model/mvvm-events-destroy-lifecycle';
import { FloorsToolbarVM } from '../floors-toolbar-vm';
import { FormsModule } from '@angular/forms';
import { InputGroupModule } from 'primeng/inputgroup';
import { InputGroupAddonModule } from 'primeng/inputgroupaddon';
import { SelectButtonModule } from 'primeng/selectbutton';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { RadioButtonModule } from 'primeng/radiobutton';
import { BlueprintEditorToolbarComponent } from '../../floor-blueprint/container/view/blueprint-editor-toolbar/blueprint-editor-toolbar.component';

@Component({
  selector: 'xc-floors-toolbar',
  standalone: true,
  imports: [
    FormsModule, 
    InputGroupModule, 
    InputGroupAddonModule, 
    SelectButtonModule, 
    AutoCompleteModule, 
    RadioButtonModule,
    BlueprintEditorToolbarComponent],
  templateUrl: './floors-toolbar.component.html',
  styleUrl: './floors-toolbar.component.scss'
})
export class FloorsToolbarComponent extends MVVMEventDestroyLifeCycle {
  @Input() override model: FloorsToolbarVM | undefined;
}
