import { SimulationBuildingDbView } from "../../db-model/views/simulation-building-db-view";
import { DefinedModelBase } from "../defined-model-base";

export class SimulationBuildingView extends DefinedModelBase {
    siBuViRootSimulation!: number;
    siBuViBuildingId!: number;
    siBuViBuildingName!: string;

    constructor(dtoData: any) {
        super(dtoData, [SimulationBuildingDbView]);
    }

}