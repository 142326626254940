import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SvgSegmentDebugVM } from '../../model/svg-segment-debug-vm';

@Component({
  selector: 'g[xc-svg-segment-debug]',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './svg-segment-debug.component.svg',
  styleUrls: ['./svg-segment-debug.component.scss']
})
export class SvgSegmentDebugComponent {
  @Input() model: SvgSegmentDebugVM | undefined;
}
