import { TreeNode } from "primeng/api";

export class MenuTreeNode implements TreeNode {
    key: string;
    label: string;
    children?: MenuTreeNode[];
    leaf?: boolean;
    expanded?: boolean;
    type?: string;
    parent: MenuTreeNode | undefined;
    partialSelected?: boolean;
    style?: any;
    styleClass?: string;
    selectable: boolean = false;
    enable: boolean = false;
    route: string | null;
    
    constructor(key: string, label: string, enable: boolean, selectable: boolean, route: string | null, parent: MenuTreeNode | undefined) {
        this.key = key;
        this.label = label;
        this.enable = enable;
        this.selectable = selectable && enable;
        this.route = route;
        this.parent = parent;
    }
}