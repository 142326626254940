export enum AppMenuRouteEnum {
    welcome = "welcome",
    administration_useraccount = "administration/useraccount",
    administration_layergrants = "administration/layergrants",
    administration_maildomains = "administration/maildomains",
    administration_roles = "administration/roles",
    graphicworks_blueprints = "graphicworks/blueprints",
    graphicworks_pictograms = "graphicworks/pictograms",
    layout_inventory_catalogs = "layout/inventory/catalogs",
    layout_inventory_providers = "layout/inventory/providers",
    layout_inventory_purchases = "layout/inventory/purchases",
    layout_inventory_stores = "layout/inventory/stores",
    layout_organization_dashboard = "layout/organization/dashboard",
    layout_organization_upload = "layout/organization/upload",
    layout_realestate_map = "layout/realestate/map",
    layout_realestate_sites = "layout/realestate/sites",
    layout_realestate_buildings = "layout/realestate/buildings",
    layout_realestate_floors = "layout/realestate/floors",
    layout_realestate_perimeters = "layout/realestate/perimeters",
    layout_realestate_addresses = "layout/realestate/addresses",
    layout_realestate_cities = "layout/realestate/cities",
    layout_realestate_workplacetypes = "layout/realestate/workplacetypes",
    layout_realestate_layouttypes = "layout/realestate/layouttypes",
    planning_maintenance = "planning/maintenance",
    planning_mobility_projects = "planning/mobility/projects",
    planning_mobility_contributors = "planning/mobility/contributors",
    planning_mobility_companies = "planning/mobility/companies",
    planning_mobility_functions = "planning/mobility/functions",
    planning_mobility_jobtasks = "planning/mobility/jobtasks",
    planning_mobility_jobs = "planning/mobility/jobs",
    planning_mobility_companytasks = "planning/mobility/companytasks",
    planning_mobility_projecttasks = "planning/mobility/projecttasks",
    planning_mobility_workloads = "planning/mobility/workloads",
    planning_strategy_leases = "planning/strategy/leases",
    planning_strategy_referentials_contractors = "planning/strategy/referentials/contractors",
    planning_strategy_referentials_durationtypes = "planning/strategy/referentials/durationtypes",
    planning_strategy_referentials_leases = "planning/strategy/referentials/leases",
    planning_strategy_referentials_leaseitems = "planning/strategy/referentials/leaseitems",
    planning_strategy_referentials_leasetypes = "planning/strategy/referentials/leasetypes",
    planning_strategy_simulations = "planning/strategy/simulations",
    clients = "clients"
}