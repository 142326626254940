import { RoomLayoutType } from "src/app/core/model/data-model/tables/room-layout-type";
import { BuildingAnatomyView } from "src/app/core/model/data-model/views/building-anatomy-view";
import { SiteAnatomyView } from "src/app/core/model/data-model/views/site-anatomy-view";
import { TablesNamesEnum } from "src/app/core/model/db-model/tables-names-enum";
import { DyntService } from "src/app/core/services/backend-services/dynt-service";
import { DonutChartDataVM } from "src/app/ui/shared/charts/donut/model/donut-chart-data-vm";
import { ChartBuilder } from "src/app/ui/shared/shared-model/chart-builder";
import { SliceAnonymousData } from "src/app/ui/shared/shared-model/slice-anonymous-data";
import Container from "typedi";
import { FloorAnatomyView } from "src/app/core/model/data-model/views/floor-anatomy-view";
import { RealEstateAnatomyView } from "src/app/core/model/data-model/views/real-estate-anatomy-view";
import { BuildingAnonymousDonutChartDataBuilder } from "../buildings/building-anonymous-donut-chart-data-builder";

export class AnonymousChartDataBuilder {
    static async getAnatomyData(view: FloorAnatomyView[] | BuildingAnatomyView[] | SiteAnatomyView[] | RealEstateAnatomyView[], level: number): Promise<DonutChartDataVM> {
        const ana: SliceAnonymousData[] = [];

        view.forEach(e => {
            const area = e instanceof BuildingAnatomyView ? e.buLaViRoundedArea : e instanceof FloorAnatomyView ? e.flAnViRoundedArea : e instanceof RealEstateAnatomyView ? e.reEsLaViRoundedArea : e.stLaViRoundedArea;
            ana.push(new SliceAnonymousData(e.roLaTyId, e.roLaTyColor, e.roLaTyName, area, level, e.roLaTyParentId));
        });

        return await this.getChartData(ana);
    }

    static async getChartData(data: SliceAnonymousData[]): Promise<DonutChartDataVM> {
        const s = Container.get(DyntService);
        const layoutTable = await s.downloadTable<RoomLayoutType>(TablesNamesEnum.RoomLayoutType);
        const l2ItemsIds = [...new Set(data.map(x=> x.parentId))];
        const l2Anonymous: SliceAnonymousData[] = [];
        l2ItemsIds.forEach(id => {
            const l2Item = layoutTable.find(x=> x.roLaTyId === id);
            if (l2Item) {
                const l2A = BuildingAnonymousDonutChartDataBuilder.getRoomLayoutData(data, [l2Item], 2)[0];
                const l21 = data.filter(x=> x.parentId === id);
                l2A.children = l21; 
                l2Anonymous.push(l2A);
            }
        });
        const l3ItemsIds = [...new Set(l2Anonymous.map(x=> x.parentId))];
        const l3Anonymous: SliceAnonymousData[] = [];
        l3ItemsIds.forEach(id => {
            const l3Item = layoutTable.find(x=> x.roLaTyId === id);
            if (l3Item) {
                const l3A = BuildingAnonymousDonutChartDataBuilder.getRoomLayoutData(l2Anonymous, [l3Item], 1)[0];
                const l31 = l2Anonymous.filter(x=> x.parentId === id);
                l3A.children = l31; 
                l3Anonymous.push(l3A);
            }
        });
        return ChartBuilder.getAnatomyDonutChartData(this.name, l3Anonymous, 3);
    }
}