import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InventoryMapVM } from '../../model/inventory-map-vm';

@Component({
  selector: 'xc-inventory-map',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './inventory-map.component.html',
  styleUrls: ['./inventory-map.component.scss']
})
export class InventoryMapComponent {
  @Input() model: InventoryMapVM | undefined;
}
