import { AppRoleTable } from './../../db-model/tables/app-role-table';
import { DefinedModelBase } from '../defined-model-base';

export class AppRole extends DefinedModelBase {
    apRoId!: number;
    apRoName!: string;
    apRoIsActive!: boolean;

    constructor(dtoData: any, tableDef: any[] = []) {
        super(dtoData, [AppRoleTable].concat(tableDef));
    }
}