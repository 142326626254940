import { BuildingTable } from "../tables/building-table";
import { FloorTable } from "../tables/floor-table";
import { RoomLayoutTypeTable } from "../tables/room-layout-type-table";

export const BuildingLayoutDbView = {
    databaseTableName: 'building_layout_view',
    buSiteId: BuildingTable.buSiteId,
    flBuildingId: FloorTable.flBuildingId,
    roLaTyId: RoomLayoutTypeTable.roLaTyId,
    roLaTyCode: RoomLayoutTypeTable.roLaTyCode,
    roLaTyName: RoomLayoutTypeTable.roLaTyName,
    roLaTyColor: RoomLayoutTypeTable.roLaTyColor,
    roLaTyIsNUA: RoomLayoutTypeTable.roLaTyIsNUA,
    roLaTyParentId: RoomLayoutTypeTable.roLaTyParentId,
    buLaViArea: 'BuLaVi_Area',
    buLaViRoundedArea: 'BuLaVi_RoundedArea',
    buLaViLayoutTypeTree: 'BuLaVi_LayoutTypeTree'
  };