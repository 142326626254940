import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SvgPhotoMarkerGizmoVM } from '../../model/svg-photo-marker-gizmo-vm';

@Component({
  selector: 'g[xc-svg-photo-marker-gizmo]',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './svg-photo-marker-gizmo.component.svg',
  styleUrls: ['./svg-photo-marker-gizmo.component.scss']
})
export class SvgPhotoMarkerGizmoComponent {
  @Input() model: SvgPhotoMarkerGizmoVM | undefined;
}
