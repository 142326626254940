import { Component, Input } from '@angular/core';
import { GanttRootProjectContainerVM } from '../../model/gantt-root-project-container-vm';
import { SvgGanttTaskShapeComponent } from '../svg-gantt-task-shape/svg-gantt-task-shape.component';
import { SvgGanttTaskLinkComponent } from '../svg-gantt-task-link/svg-gantt-task-link.component';

@Component({
  selector: 'xc-gantt-root-project-container',
  standalone: true,
  imports: [SvgGanttTaskShapeComponent, SvgGanttTaskLinkComponent],
  templateUrl: './gantt-root-project-container.component.html',
  styleUrl: './gantt-root-project-container.component.scss'
})
export class GanttRootProjectContainerComponent {
  @Input() model: GanttRootProjectContainerVM | undefined;
}
