import { WorkplaceTypeTable } from '../../db-model/tables/workplace-type-table';
import { DefinedModelBase } from '../defined-model-base';

export class WorkplaceType extends DefinedModelBase {
    woTyId!: number;
    woTyName!: string;
    woTyColor!: string;
    woTyScope!: number;
    woTyIsActive!: boolean;
    woTyIsAllocatable!: boolean;
    woTyDisplayNameId!: number;

    constructor(dtoData: any, tableDef: any[] = []) {
        super(dtoData, [WorkplaceTypeTable].concat(tableDef));
    }
}