import { Service } from "typedi";
import { ApiService } from "../api-service";
import { ApiControllers } from "../api-endpoints";
import { PurchaseDetailDTO } from "./dto/purchase-detail-dto";
import { HttpParams } from "@angular/common/http";
import { Task } from "../../model/data-model/tables/task";
import { EquipmentPurchaseTable } from "../../model/db-model/tables/equipment-purchase-table";
import { EquipmentItemTable } from "../../model/db-model/tables/equipment-item-table";

@Service({ global: true })  
export class PurchasesService extends ApiService {
    private hasReceiptTaskEndpoint: string = `${ApiControllers.purchase}/HasReceiptTask`;
    private detailsEndpoint(purchaseId: number): string { return `${ApiControllers.purchase}/PurchaseDetail/${purchaseId}`};
    private tasksEndpoint(purchaseId: number): string { return `${ApiControllers.purchase}/PurchaseTasks/${purchaseId}`};
    private receiptEndpoint: string = `${ApiControllers.purchase}/PurchaseReceipt`;

    constructor() {
        super();
    }

    async loadDetails(purchaseId: number): Promise<PurchaseDetailDTO[]> {
        const result = await this.getAsync<any>(this.endPoint(this.detailsEndpoint(purchaseId)));
          if (result != null) {
            return result.payload.map((item: any) => {
                return new PurchaseDetailDTO(item);
            })
          }
          return [];
    }

    async loadTasks(purchaseId: number): Promise<Task[]> {
        const result = await this.getAsync<any>(this.endPoint(this.tasksEndpoint(purchaseId)));
          if (result != null) {
            return result.payload.map((item: any) => {
                return new Task(item);
            })
          }
          return [];
    }

    async hasReceiptTask(purchasesIds: number[]): Promise<{purchaseId: number, hasReceiptTask: boolean}[]> {
        let params = new HttpParams();
        purchasesIds.forEach(id => {
            params = params.append("purchaseId", id);
        });
        const result = await this.getAsync<any>(this.endPoint(this.hasReceiptTaskEndpoint), undefined, params);
        if (result) {
            return result.payload.map((item: any) => {
                return {purchaseId: item["PurchaseId"], hasReceiptTask: item["HasReceiptTask"]};
            })
        }
        return [];
    }

    async registerReceipt(purchaseId: number, receiptDate: Date, items: {}[]): Promise<boolean> {
        const dto = {
            [EquipmentPurchaseTable.eqPuId]: purchaseId,
            [EquipmentPurchaseTable.eqPuDeliveryDate]: receiptDate,
            [EquipmentItemTable.databaseTableName]: items
        }
        const result = await this.postAsync<boolean>(this.endPoint(this.receiptEndpoint), dto);
        if (result) {
            return result.payload;
        }
        return false;
    }
}

