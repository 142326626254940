import { FloorAttributionDbView } from '../../db-model/views/floor-attribution-db-view';
import { DefinedModelBase } from '../defined-model-base';

export class FloorAttributionView extends DefinedModelBase {
    buSiteId!: number;
    flBuildingId!: number;
    flId!: number;
    flDaTaskId!: number;
    roAttributionTypeId!: number;
    roAtTyName!: string;
    roAtTyColor!: string;
    roAtTyDisplayNameId!: number;
    roAtTyViDisplayName!: string;
    flAtViArea!: number;
    flAtViRoundedArea!: number;
    roViIsCurrentState!: boolean;

    constructor(dtoData: any) {
        super(dtoData, [FloorAttributionDbView]);
    }
}