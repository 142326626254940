import { ITabPageContent } from "src/app/components-lib/tab-page-container/model/i-tab-page-content";
import { TaskService } from "src/app/core/services/backend-services/task-service";
import Container from "typedi";
import { MoveListsEnum } from "./move-lists-enum";
import { DynTableVM } from "src/app/components-lib/dyn-grid/model/dyn-table-vm";
import { MoveListBuilder } from "./move-list-builder";
import { FloorTask } from "../floor-blueprint/content/shared-model/floor-task";
import { TabPageContentTypeEnum } from "../tab-page-content-type-enum";

export class MoveListsContainerVM implements ITabPageContent {
    contentType: TabPageContentTypeEnum = TabPageContentTypeEnum.moveLists;
    content: any;
    floorId: number;
    floorTasks: FloorTask[] = [];
    selectedTask: FloorTask | undefined;
    selectedListType: number = MoveListsEnum.people;
    moveListsEnum = MoveListsEnum;
    listData: DynTableVM | undefined;

    constructor(floorId: number) {
        this.floorId = floorId;
    }

    async loadTasks(): Promise<void> {
        const s = Container.get(TaskService);
        this.floorTasks = await s.loadFloorActiveTasks(this.floorId);
    }

    set(data: any): void {
    }

    taskCompare(a: FloorTask, b: FloorTask ): boolean {
        return a?.taskId === b?.taskId;
    }

    async loadListData(): Promise<void> {
        if (!this.selectedTask) return;

        switch (this.selectedListType) {
            case MoveListsEnum.people:
                this.listData = await MoveListBuilder.getPeopleList(this.selectedTask.taskId);
                break;
            case MoveListsEnum.equipment:
                this.listData = await MoveListBuilder.getEquipmentList(this.selectedTask.taskId);
                break;
            default:
                break;
        }
    }

    async onTaskSelectionChange(): Promise<void> {
        await this.loadListData();
    }

    async selecteListTypeChange(): Promise<void> {
        await this.loadListData();
    }

}