import { DynTableVM } from "src/app/components-lib/dyn-grid/model/dyn-table-vm";
import Container from "typedi";
import { DyntService } from "src/app/core/services/backend-services/dynt-service";
import { ApiResponseCodeEnum } from "src/app/core/services/api-response-code-enum";
import { PageModel } from "src/app/ui/main/model/page-model";
import { UserAccountJunctionsEnum } from "src/app/ui/main/model/router/user-account-junctions-enum";
import { DynTableVMBuilder } from "src/app/components-lib/dyn-grid/model/dyn-table-vm-builder";
import { TablesNamesEnum } from "src/app/core/model/db-model/tables-names-enum";
import { PaginationService } from "src/app/core/services/backend-services/pagination-service";
import { AppMenuRouteEnum } from "src/app/core/model/data-model/enums/app-menu-route-enum";
import { GrantsAdminVM } from "../grants/model/grants-admin-vm";
import { TabViewChangeEvent } from "primeng/tabview";

export class RolesAdminVM extends PageModel {
    adminTable: DynTableVM | undefined;
    grants: GrantsAdminVM | undefined;
    selectedTabIndex: number = 0;

    private constructor() {
        super(AppMenuRouteEnum.administration_roles, 0, "808c7627-e700-4c3f-b67c-942399c4d850");
    }

    static async newAsync(): Promise<RolesAdminVM> {
        const result = new RolesAdminVM();
        await result.loadRoleTable();
        return result;
    }
    
    async loadRoleTable(): Promise<void> {
        const s = Container.get(DyntService);
        const contextedDataSet = await s.contextedDataSet(TablesNamesEnum.AppRole, undefined);
        const p = Container.get(PaginationService);
        const paginator = await p.getPaginator(TablesNamesEnum.AppRole);
        this.adminTable = DynTableVMBuilder.getVM(contextedDataSet, TablesNamesEnum.AppRole, paginator);

        // TODO : cette méthode peut être mutualisée avec toutes les autres écoutes de ce type
        this.adminTable.insertionRequested = async (values: {}) => {
            const s = Container.get(DyntService);
            return await s.post<any>("", values);
        }

        // TODO : cette méthode peut être mutualisée avec toutes les autres écoutes de ce type
        this.adminTable.deletionRequested = async (tableName: string, rowId: any): Promise<boolean> =>  {
            const s = Container.get(DyntService);
            const result = await s.delete(tableName, rowId);
            if (result && result.statusCode === ApiResponseCodeEnum.success) {
                return true;
            }
            return false;
        }
    }

    async loadTab(): Promise<void> {
        if (this.selectedTabIndex === 0) {
            await this.loadRoleTable();
            return;
        }
        if (!this.grants) {
            this.grants = await GrantsAdminVM.newAsync();
        } else {
            await this.grants.loadRoles();
        }
    }

    async selectedTabChange(e: TabViewChangeEvent): Promise<void> {
        this.selectedTabIndex = e.index;
        await this.loadTab();
    }
}