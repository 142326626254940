import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from 'src/app/core/material.module';

@Component({
  standalone: true,
  imports: [CommonModule, MaterialModule],
  selector: 'xc-master-plan-form',
  templateUrl: './master-plan-form.component.html',
  styleUrls: ['./master-plan-form.component.scss']
})
export class MasterPlanFormComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
