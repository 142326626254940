import { Component, Input } from '@angular/core';
import { TaskContributorsVM } from '../../model/task-contributors-vm';
import { DataViewModule } from 'primeng/dataview';
import { AvatarModule } from 'primeng/avatar';
import { ButtonModule } from 'primeng/button';
import { InputNumberModule } from 'primeng/inputnumber';
import { DropdownModule } from 'primeng/dropdown';
import { FieldsetModule } from 'primeng/fieldset';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'xc-task-contributors',
  standalone: true,
  imports: [CommonModule, FormsModule, DataViewModule, AvatarModule, ButtonModule, InputNumberModule, DropdownModule, FieldsetModule],
  templateUrl: './task-contributors.component.html',
  styleUrl: './task-contributors.component.scss'
})
export class TaskContributorsComponent {
  @Input() model: TaskContributorsVM | undefined;
}
