import { BuildingActivityStatusDbView } from '../../db-model/views/building-activity-status-db-view';
import { DefinedModelBase } from '../defined-model-base';

export class BuildingActivityStatusView extends DefinedModelBase {
    buSiteId!: number;
    flBuildingId!: number;
    roActivityStatusTypeId!: number;
    roAcTyName!: string;
    roAcTyColor!: string;
    roAcTyDisplayNameId!: number;
    roAcTyViDisplayName!: string;
    buAcStViArea!: number;
    buAcStViRoundedArea!: number;

    constructor(dtoData: any) {
        super(dtoData, [BuildingActivityStatusDbView]);
    }
}