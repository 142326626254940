import { FloorActivityStatusView } from 'src/app/core/model/data-model/views/floor-activity-status-view';
import { FloorActivityStatusDbView } from 'src/app/core/model/db-model/views/floor-activity-status-db-view';
import { Floor } from '../../../model/data-model/tables/floor';

export class FloorActivityStatusDTO extends Floor {
    activityStatus: FloorActivityStatusView[] = [];

    constructor(dtoData: any) {
        super(dtoData);
        if (dtoData[FloorActivityStatusDbView.databaseTableName]) {
            dtoData[FloorActivityStatusDbView.databaseTableName].forEach((e: any) => {
                this.activityStatus.push(new FloorActivityStatusView(e));
            });
        }
    }
}