@if (model && model.contentPanel.userInteraction) {
  <div id="flBpContentPanel">
    <div id="blueprintContainer" class="w-full h-full">
      @if (model.contentPanel.userInteraction) {
        <div class="w-full h-full">
          <!-- <xc-blueprint-editor-toolbar [model]="model.contentPanel.toolbar"/> -->
          <xc-floor-blueprint id="floorblueprint"
            (mousedown)="model.contentPanel.userInteraction.panzoomController?.mouseDown($event)"
            (touchstart)="model.contentPanel.userInteraction.panzoomController?.touchStart($event)"
            (mousemove)="model.contentPanel.userInteraction.panzoomController?.mouseMove($event)"
            (touchmove)="model.contentPanel.userInteraction.panzoomController?.touchMove($event)"
            (mouseup)="model.contentPanel.userInteraction.panzoomController?.mouseUp($event)"
            (touchup)="model.contentPanel.userInteraction.panzoomController?.touchUp($event)"
            (mouseleave)="model.contentPanel.userInteraction.panzoomController?.mouseLeave($event)"
            (wheel)="model.contentPanel.userInteraction.panzoomController?.mouseWheel($event)"
            style="outline: none;" 
            [model]="model.contentPanel.userInteraction" 
            [ngClass]="{'svg-wall-inserting': model.contentPanel.userInteraction.currentCommand.isWallInsertionCommand()}">
          </xc-floor-blueprint>
        </div>
      }
      @if (model.contentPanel.userInteraction && model.contentPanel.userInteraction.showLabelInput) {
        <input id="bp-label-input" matInput
            type="number" 
            min="0.3" max="2" step="0.01"
            xcAutoFocus
            [style.left.px]="model.contentPanel.userInteraction.labelInputPos?.x"
            [style.top.px]="model.contentPanel.userInteraction.labelInputPos?.y"
            [(ngModel)]="model.contentPanel.userInteraction.labelInputValue"
            (focusout)="model.contentPanel.userInteraction.focusOut()" 
            (keydown)="model.contentPanel.userInteraction.inputKeyDown($event)">
      }
    </div>
    @if (model.contentPanel.userInteraction.similarCurrentRoomId && model.contentPanel.userInteraction.similarRooms != null) {
      <div class="carousel-container">
        <div class="flex align-items-center justify-content-center">
          <span class="mr-3">{{'Locaux similaires trouvés (' + model.contentPanel.userInteraction.similarRooms.rooms.length + ')'}}</span>
          <span class="mr-3">{{'Tolérance : ' + model.contentPanel.userInteraction.similarTolerance * 100 + '%'}}</span>
          <p-slider class="block w-2 mr-3" [(ngModel)]="model.contentPanel.userInteraction.similarTolerance" [min]="0" [max]="1" [step]="0.1"></p-slider>
          <p-button class="sim-button" icon="pi pi-search"  [rounded]="true" severity="info" [outlined]="true" (onClick)="model.contentPanel.userInteraction.onSimilarRoomSearchButtonClick()"></p-button>
          <!-- <p-button class="sim-button" icon="pi pi-compass"  [rounded]="true" severity="info" [outlined]="true" (onClick)="model.contentPanel.userInteraction.onSimilarRoomOrientationButtonClick()"></p-button> -->
          <p-button class="sim-button" icon="pi pi-times"  [rounded]="true" severity="danger" [outlined]="true" (onClick)="model.contentPanel.userInteraction.onSimilarRoomCloseButtonClick()"></p-button>
        </div>
        <p-carousel [value]="model.contentPanel.userInteraction.similarRooms.rooms" [numVisible]="3" [numScroll]="3">
          <ng-template let-room pTemplate="item">
              <div class="border-1 surface-border border-round m-2 text-center">
                <div class="image-container p-2 relative" [style.height.px]="250">
                  <svg class="w-full h-full"
                      [attr.viewBox]="room.orientedViewBox"
                      xmlns="http://www.w3.org/2000/svg">
                      <defs>
                          @for (def of room.defs; track def) {
                              <g xc-bp-svg-def 
                                  [def]="def" 
                                  [attr.id]="'gdef' + def.id">
                              </g>
                          }
                      </defs>

                      <g [style.transform]="'rotate(' + room.transform?.rotationAngle + 'deg)'"
                        [style.transform-origin]="room.transform?.rotationCenter.x + 'px ' + room.transform?.rotationCenter.y + 'px'"
                        class="similar-room">
                        <path
                          fill="#f9f9f9"
                          stroke="#bbbbbb"
                          stroke-width="0.01"
                          [attr.d]="room.room.d">
                        </path>

                        <g>
                          @for (f of room.furniture; track f) {
                            <use
                              [attr.href]="f.href" 
                              [attr.transform]="f.transform?.transformString"
                              stroke="#bbbbbb"
                              stroke-width="0.01">
                            </use>
                          }
                        </g>
                      </g>
                  </svg>
                  @if (!model.contentPanel.userInteraction.similarRooms.validatingId) {
                    <div class="absolute right-0 flex">
                      <p-button class="sim-button" icon="pi pi-replay" [rounded]="true" severity="info" [outlined]="true" (onClick)="model.contentPanel.userInteraction.onSimilarRoomRotateButtonClick(room)"></p-button>
                      <p-button class="sim-button" icon="pi pi-copy" [rounded]="true" severity="info" [outlined]="true" (onClick)="model.contentPanel.userInteraction.onSimilarRoomGetButtonClick(room)"></p-button>
                    </div>
                  } @else {
                    @if (model.contentPanel.userInteraction.similarRooms.validatingId === room.room.floorDataId) {
                      <div class="absolute right-0 flex">
                        <p-button class="sim-button" icon="pi pi-check" [rounded]="true" severity="success" [outlined]="true" (onClick)="model.contentPanel.userInteraction.onSimilarRoomValidateButtonClick(room)"></p-button>
                        <p-button class="sim-button" icon="pi pi-times" [rounded]="true" severity="danger" [outlined]="true" (onClick)="model.contentPanel.userInteraction.onSimilarRoomCancelButtonClick(room)"></p-button>
                      </div>
                    }
                  }   
                </div>
                <!-- <div>
                    <h4 class="mb-1">room label</h4>
                </div> -->
              </div>
          </ng-template>
      </p-carousel>
    </div>
    }
  </div>
}
<xc-bp-viewer-side-panel [sidePanel]="model?.sidePanel" [sidePanelResizer]="model?.sidePanelResizer"></xc-bp-viewer-side-panel>
  @if (model?.sidePanelResizer?.sidePanelResizing) {
    <div id="flBpResizingOverlay"
      (mousemove)="model?.sidePanelResizer?.onSidenavResizing($event)" 
      (mouseup)="model?.sidePanelResizer?.onSidenavEndResizing($event)">
    </div>
}
