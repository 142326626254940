import { TablesNamesEnum } from './../tables-names-enum';
export const FloorDoorStyleTable = {
    databaseTableName: TablesNamesEnum.FloorDoorStyle,
    id: 'FlDoSt_Id',
    name: 'FlDoSt_Name',
    displayNameId: 'FlDoSt_DisplayNameId',
    color: 'FlDoSt_Color',
    scope: 'FlDoSt_Scope',
    openingTypeId: 'FlDoSt_OpeningTypeId',
    leafCount: 'FlDoSt_LeafCount'
  }