import { NestedTreeControl } from "@angular/cdk/tree";
import { MatTreeNestedDataSource } from "@angular/material/tree";
import { LeaseView } from "src/app/core/model/data-model/views/lease-view";
import { ViewsNames } from "src/app/core/model/db-model/views-names-enum";
import { DyntService } from "src/app/core/services/backend-services/dynt-service";
import Container from "typedi";
import { LeaseTreeNode } from "./lease-tree-node";
import { logError } from "src/app/core/services/logging-service";

export class LeasesTreeVM {
    treeControl = new NestedTreeControl<LeaseTreeNode>(node => node.children);
    dataSource = new MatTreeNestedDataSource<LeaseTreeNode>();

    selectedNode: LeaseTreeNode | undefined;
    
    private constructor() {
    }

    static async newAsync(): Promise<LeasesTreeVM> {
        const result = new LeasesTreeVM();
        await result.loadLeases();
        return result;
    }

    private async loadLeases(): Promise<void> {
        const d = Container.get(DyntService);
        this.loadLeaseTree(await d.downloadTable<LeaseView>(ViewsNames.LeaseView));
    }

    loadLeaseTree(leases: LeaseView[]): void {
        const tmp:LeaseTreeNode[] = [];
        leases.forEach(l => {
            tmp.push(new LeaseTreeNode(l.leId, l.lePurpose, l.leDateOfSignature, 100, l.statutView.leStColor, l));
        });
        this.dataSource.data = tmp;
    }

    seletedNodeChange?: (node:LeaseTreeNode) => void;
    nodeClick(e: MouseEvent, node:LeaseTreeNode):void {
        e.stopPropagation();

        this.selectedNode = node;

        if (this.seletedNodeChange) {
            this.seletedNodeChange(node);
        } else {
            logError("LeasesTreeVM.selectedNodeChange n'était pas écouté");
        }
    }

    hasChild = (_: number, node: LeaseTreeNode) => !!node.children && node.children.length > 0;
}