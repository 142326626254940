import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { BpSvgLeaderLine } from '../../model/bp-svg-leader-line';

@Component({
  selector: 'g[xc-bp-svg-leader-line]',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './bp-svg-leader-line.component.svg',
  styleUrl: './bp-svg-leader-line.component.scss'
})
export class BpSvgLeaderLineComponent {
  @Input() model: BpSvgLeaderLine | undefined;
}
