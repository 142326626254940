import { DxfLayerDTO } from "src/app/core/services/backend-services/dto/dxf-layer-dto";
import { InteractionCommand } from "../interaction-command";
import { IInsertInteraction } from "./i-insert-interaction";
import { SvgEntityTypesEnum } from "src/app/core/model/svg-model/svg-entity-type-enum";
import { LineInsertGizmo } from "../../gizmos/insert/line-insert-gizmo";
import { InsertInteraction } from "./insert-interaction";
import { Point } from "src/app/core/model/geometry-model/point.model";
import { DxfSvgLine } from "../../svg/dxf-svg-line";
import { logError } from "src/app/core/services/logging-service";

export class LineInsertInteraction extends InsertInteraction implements IInsertInteraction {
    entityType: SvgEntityTypesEnum = SvgEntityTypesEnum.line;
    gizmo: LineInsertGizmo;

    constructor(command: InteractionCommand, layers: DxfLayerDTO[]) {
        super(command, layers);
        this.currentCommand = command;
        this.layers = layers;
        this.gizmo = new LineInsertGizmo();
        this.gizmo.insertionCompleted = () => {
            if (this.insertionCompleted) {
                const currentLayer = this.layers.find(x=> x.current);
                if (currentLayer) {
                    this.insertionCompleted(DxfSvgLine.fromValues(currentLayer.dxLaId, this.gizmo.startPoint, this.gizmo.endPoint, 0.01));
                }
            } else {
                logError("LineInsertGizmo.insertionCompleted n'est pas écouté");
            }
        }
    }

    define(point: Point | undefined): void {
        this.gizmo.define(point);
    }

    async mouseDown(e: MouseEvent, hitPoint: Point): Promise<boolean> {
        throw new Error("Method not implemented.");
    }

    async mouseMove(e: MouseEvent, hitPoint: Point): Promise<void> {
        this.gizmo.update(hitPoint);
    }

    async mouseUp(e: MouseEvent, hitPoint: Point | undefined): Promise<void> {
        throw new Error("Method not implemented.");
    }

    keyDown(e: KeyboardEvent): Promise<void> {
        throw new Error("Method not implemented.");
    }
}