export class UpdatedFloorDataDTO {
    id: number;
    dataStateId: number;
    planningStateId: number;
    floorModelId: number;

    constructor(dtoData: any) {
        this.id = dtoData.id;
        this.dataStateId = dtoData.dataStateId;
        this.planningStateId = dtoData.planningStateId;
        this.floorModelId = dtoData.floorModelId;
    }
}
