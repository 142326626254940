import { IModelBase } from './i-model-base';

export class UndefinedModelBase implements IModelBase {
    constructor(dtoData: any) {
        const thisAsAny = this as any;
        Object.keys(dtoData).forEach(key => thisAsAny[key] = dtoData[key]);
    }

    /**
     * 
     * @param dbColumnName Nom de la colonne en base de données
     * @returns Retourne la valeur pour la propriété correspondant au nom de la colonne en base de données
     */
    getValue(dbColumnName: string): any {
        return (this as any)[dbColumnName];
    }
}