import { EventListener } from "src/app/core/events/event-listener";
import { readableUUID } from "src/app/core/events/event-listener-uuid";
import { ThemingModel } from "../../../blueprint-viewer-side-panel/subitems/theming/model/theming-model";
import { FloorTask } from "../../../shared-model/floor-task";
import { FloorBlueprint } from "./floor-blueprint";
import { UserInteraction } from "./interaction/user-interaction";
import { FloorBlueprintEventsEnum } from "../../../../container/model/floor-blueprint-events-enum";
import Container from "typedi";
import { TaskService } from "src/app/core/services/backend-services/task-service";
import { DropdownChangeEvent } from "primeng/dropdown";
import { WallService } from "src/app/core/services/backend-services/wall-service";
import { FloorWallStyle } from "src/app/core/model/data-model/tables/floor-wall-style";
import { EditableLayer } from "./interaction/editable-layer";
import { FloorModelEnum } from "src/app/core/model/data-model/enums/floor-model-enum";
import { DoorStyle } from "../../../shared-model/door-style";
import { BpDoorService } from "src/app/core/services/backend-services/bp-door-service";
import { DownloadService } from "src/app/core/services/backend-services/download-service";
import { MenuItem } from "primeng/api";

export class BlueprintViewerToolbar extends EventListener {
    userInteraction: UserInteraction;
    themingModel: ThemingModel | undefined;
    floorTasks: FloorTask[] = [];
    selectedTask: FloorTask | undefined;
    
    wallStyles: FloorWallStyle[] = [];
    selectedWallStyle: FloorWallStyle | undefined;
    wallStylesEnable: boolean = false;
    
    doorStyles: DoorStyle[] = [];
    selectedDoorStyle: DoorStyle | undefined;
    doorStylesEnable: boolean = false;

    exportCommands: MenuItem[];
    waitingForApiResult: boolean = false;

    private constructor(userInteraction: UserInteraction) {
        super(readableUUID(BlueprintViewerToolbar.name));
        this.userInteraction = userInteraction;

        this.exportCommands = [            {
            label: 'dxf',
            icon: 'pi pi-download',
            command: async () => {
                await this.onDownloadButtonClick('dxf');
            }
        }];

        this.addEventListener(FloorBlueprintEventsEnum.taskAnyHowEnded, async () => {
            this.selectedTask = undefined;
            await this.loadTasks();
        });

        this.addEventListener(FloorBlueprintEventsEnum.directTaskCreated, async (taskId: number) => {
            await this.loadTasks();
            await this.selectTask(taskId);
        });

        this.addEventListener(FloorBlueprintEventsEnum.planningTaskValidated, async () => {
            this.selectedTask = undefined;
            await this.loadTasks();
        });

        this.addEventListener(FloorBlueprintEventsEnum.editableLayersChanged, async (layers: EditableLayer[]) => {
            this.wallStylesEnable = layers.find(x=> x.layerId === FloorModelEnum.Walls) !== undefined;
            this.doorStylesEnable = layers.find(x=> x.layerId === FloorModelEnum.Doors) !== undefined;
        });
    }

    static async newAsync(userInteraction: UserInteraction): Promise<BlueprintViewerToolbar> {
        const result = new BlueprintViewerToolbar(userInteraction);
        await result.setThemingModel(userInteraction.blueprint);
        await result.loadTasks();
        await result.loadWallStyles();
        await result.loadDoorStyles();
        return result;
    }

    async setThemingModel(blueprint: FloorBlueprint): Promise<void> {
        if (!this.themingModel) {
            this.themingModel = new ThemingModel(blueprint);
        }
        await this.themingModel.loadOrUpdate();
    }

    async loadTasks(): Promise<void> {
        const s = Container.get(TaskService);
        this.floorTasks = await s.loadFloorActiveTasks(this.userInteraction.blueprint.floorId);
    }

    async loadWallStyles(): Promise<void> {
        const s = Container.get(WallService);
        this.wallStyles = await s.downloadWallStyles();
    }

    async loadDoorStyles(): Promise<void> {
        const d = Container.get(BpDoorService);
        this.doorStyles = await d.downloadDoorStyles();
    }

    async selectTask(taskId: number | undefined): Promise<void> {
        const task = this.floorTasks.find(x=> x.taskId === taskId);
        this.selectedTask = task;
        await this.emitEventAsync(FloorBlueprintEventsEnum.selectedTaskChanged, task);
    }

    async onTaskMenuItemClick(e: DropdownChangeEvent): Promise<void> {
        // if (this.selectedTask) {
        //     // provoque le retrait de l'étude actuellement affichée
        //     await this.selectTask(undefined);
        // }
        await this.selectTask(e.value.taskId);
    }

    async onRemoveTaskButtonClick(): Promise<void> {
        await this.selectTask(undefined);
    }

    async onWallInsertButtonClick(): Promise<void> {
        if (!this.selectedWallStyle) return;
        await this.emitEventAsync(FloorBlueprintEventsEnum.wallInsertRequested, this.selectedWallStyle);
    }

    async onDoorInsertButtonClick(): Promise<void> {
        if (!this.selectedDoorStyle) return;
        await this.emitEventAsync(FloorBlueprintEventsEnum.doorInserRequested, this.selectedDoorStyle);
    }

    async onDownloadButtonClick(extension: string): Promise<void> {
        this.waitingForApiResult = true;
        const s = Container.get(DownloadService);
        await s.dowloadBlueprintFile(this.userInteraction.blueprint.taskId, extension, `${this.userInteraction.blueprint.floorName}.${extension}`);
        this.waitingForApiResult = false;
    }
}