import { LoggerService } from 'src/app/core/services/logger.service';
import { SelectableSvgEntity } from './selectable-svg-entity';
import { GeometricElement } from 'src/app/core/model/geometry-model/geometric-element.model';
import { SvgEntityTypesEnum } from 'src/app/core/model/svg-model/svg-entity-type-enum';
import { SvgLineService } from 'src/app/core/model/svg-model/svg-line-service';
import { SvgCircleService } from 'src/app/core/model/svg-model/svg-circle.service';
import { SelectableSvgLine } from './selectable-svg-line';
import { IntersectionService } from 'src/app/core/model/geometry-model/intersection-service';
import { SvgEllipseService } from 'src/app/core/model/svg-model/svg-ellipse-service';
import { SvgPathService } from 'src/app/core/model/svg-model/svg-path-service';
import { SvgEntityPointStyleEnum } from '../../shared/gizmos/model/svg-entity-point-style-enum';
import { SelectableSvgCircle } from './selectable-svg-circle';
import { SelectableSvgEllipse } from './selectable-svg-ellipse';
import { SelectableSvgPath } from './selectable-svg-path';
import { SvgEntityPoint } from '../../shared/gizmos/model/svg-entity-point';
import { Rectangle } from 'src/app/core/model/geometry-model/rectangle.model';
import { Point } from 'src/app/core/model/geometry-model/point.model';
import { XcMaths } from 'src/app/core/model/static-functions/xc-maths';

export class SvgGeometryService {
    static getGeometry(elements: SelectableSvgEntity[]): GeometricElement[] {
        const result: GeometricElement[] = [];

        // NOTA : L'élément SVG rect n'est jamais utilisé dans ecsy
        // Les élements g, text et use n'ont pas d'équivalents géométriques
        // Faut-il pouvoir récupérer les éléments internes à g ?
        elements.forEach(e => {
            switch (e.entityType) {
                case SvgEntityTypesEnum.line:
                    result.push(SvgLineService.geometry(e as SelectableSvgLine));
                    break;
                case SvgEntityTypesEnum.circle:
                    result.push(SvgCircleService.geometry(e as SelectableSvgCircle));
                    break;
                case SvgEntityTypesEnum.ellipse:
                    result.push(SvgEllipseService.geometry(e as SelectableSvgEllipse));
                    break;
                case SvgEntityTypesEnum.path:
                    result.push(...SvgPathService.getPathEntities((e as SelectableSvgPath).d!));
                    break;
                case SvgEntityTypesEnum.text:
                    break;
                case SvgEntityTypesEnum.rect:
                    break;
                case SvgEntityTypesEnum.g:
                    break;
                case SvgEntityTypesEnum.use:
                    break;
                default:
                    break;
            }
        });

        return result;
    }

    static getIntersectedElements(elements: SelectableSvgEntity[], rectangle: Rectangle, excludesIds: string[]): SelectableSvgEntity[] {
        const result: SelectableSvgEntity[] = [];

        elements.forEach(e => {
            if (!excludesIds.includes(e.entityId)) {
                let geometry: GeometricElement[] = [];
                switch (e.entityType) {
                    case SvgEntityTypesEnum.line:
                        geometry.push(SvgLineService.geometry(e as SelectableSvgLine));
                        break;
                    case SvgEntityTypesEnum.circle:
                        geometry.push(SvgCircleService.geometry(e as SelectableSvgCircle));
                        break;
                    case SvgEntityTypesEnum.ellipse:
                        geometry.push(SvgEllipseService.geometry(e as SelectableSvgEllipse));
                        break;
                    case SvgEntityTypesEnum.path:
                        geometry.push(...SvgPathService.getPathEntities((e as SelectableSvgPath).d!));
                        break;
                    case SvgEntityTypesEnum.text:
                        break;
                    case SvgEntityTypesEnum.rect:
                        break;
                    case SvgEntityTypesEnum.g:
                        break;
                    case SvgEntityTypesEnum.use:
                        break;
                    default:
                        break;
                }

                const intersects: Point[] = [];
                geometry.forEach(g => {
                    intersects.push(...IntersectionService.getRectangleIntersects(g, rectangle));
                });
                if (intersects.length > 0) {
                    result.push(e);
                }
            }
        });

        return result;
    }

    static getOrthogonalProjections(elements: SelectableSvgEntity[], p: Point): SvgEntityPoint[] {
        const result: SvgEntityPoint[] = [];

        elements.forEach(e => {
            const op = SvgGeometryService.getOrthogonalProjection(e, p);
            if (op) {
                result.push(op);
            }
        });

        return result;
    }

    static getOrthogonalProjection(e: SelectableSvgEntity, p: Point): SvgEntityPoint | undefined {
        switch (e.entityType) {
            case SvgEntityTypesEnum.line:
                const s = SvgLineService.geometry(e as SelectableSvgLine);
                return this.getOrientedEntityPoint(e, s.getOrthogonalProjection(p), SvgEntityPointStyleEnum.orthogonal);
            case SvgEntityTypesEnum.circle:
                break;
            case SvgEntityTypesEnum.ellipse:
                break;
            case SvgEntityTypesEnum.path:
                break;
            case SvgEntityTypesEnum.text:
                break;
            case SvgEntityTypesEnum.rect:
                break;
            case SvgEntityTypesEnum.g:
                break;
            case SvgEntityTypesEnum.use:
                break;
            default:
                break;
        }

        return undefined;
    }

    static getIntersects(elements: SelectableSvgEntity[], refEntites: GeometricElement[], tolerance: number = Number.EPSILON): SvgEntityPoint[] {
        const result: SvgEntityPoint[] = [];
        // il ne peut pas y avoir plus de deux éléments géométriques à tester
        // ce qui se produit lorsque les éléments de référence sont issus d'un SVG path
        if (refEntites.length > 2) return result;

        const geometry = SvgGeometryService.getGeometry(elements);
        refEntites.forEach(e => {
            geometry.forEach(g => {
                result.push(...SvgEntityPoint.toStyle(IntersectionService.getEntitiesIntersects([g, e], tolerance), SvgEntityPointStyleEnum.intersection));
            });
        });

        return result;
    }

    static getOrientedEntityPoint(e: SelectableSvgEntity, p: Point, style: SvgEntityPointStyleEnum): SvgEntityPoint {
        switch (e.entityType) {
            case SvgEntityTypesEnum.line:
                const s = SvgLineService.geometry(e as SelectableSvgLine);
                const a = XcMaths.toDeg(s.angle());
                return SvgEntityPoint.fromPoint(p, style, e.entityId, -a);
            case SvgEntityTypesEnum.circle:
                return SvgEntityPoint.fromPoint(p, style, e.entityId);
            case SvgEntityTypesEnum.ellipse:
                return SvgEntityPoint.fromPoint(p, style, e.entityId);
            case SvgEntityTypesEnum.path:
                return SvgEntityPoint.fromPoint(p, style, e.entityId);
            case SvgEntityTypesEnum.text:
                return SvgEntityPoint.fromPoint(p, style, e.entityId);
            case SvgEntityTypesEnum.rect:
                return SvgEntityPoint.fromPoint(p, style, e.entityId);
            case SvgEntityTypesEnum.g:
                return SvgEntityPoint.fromPoint(p, style, e.entityId);
            case SvgEntityTypesEnum.use:
                return SvgEntityPoint.fromPoint(p, style, e.entityId);
            default:
                return SvgEntityPoint.fromPoint(p, style, e.entityId);
        }
    }
}