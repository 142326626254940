import { Component, Input } from '@angular/core';
import { PurchasesManageVM } from '../../model/purchases-manage-vm';
import { FormsModule } from '@angular/forms';
import { PurchasesReceiptComponent } from '../purchases-receipt/purchases-receipt.component';
import { PurchasesListComponent } from '../purchases-list/purchases-list.component';
import { TabViewModule } from 'primeng/tabview';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { DynTableComponent } from 'src/app/components-lib/dyn-table/dyn-table.component';

@Component({
  selector: 'xc-purchases-manage',
  standalone: true,
  imports: [DynTableComponent, TabViewModule, ScrollPanelModule, FormsModule, PurchasesReceiptComponent, PurchasesListComponent],
  templateUrl: './purchases-manage.component.html',
  styleUrl: './purchases-manage.component.scss'
})
export class PurchasesManageComponent {
  @Input() model: PurchasesManageVM | undefined;
}
