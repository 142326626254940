
@if (model != null && model.selectedLabels != null) {

    <div id="content-header">
      <span class="header">Positions de travail</span>
      <span class="header subheader">Sélection : {{model.selectedLabels == null ? 'aucune' : model.selectedLabels.count}}</span>
    </div>
    <mat-divider></mat-divider>

    <!-- Code -->
    <!-- Nota : Impossible d'utiliser le two way binding [(ngModel)] puisque le le modèle est dynamique -->
    <!-- on utilise donc la forme combinée avec le (ngModelChange) -->
    <mat-form-field appearance="outline">
        <mat-label>Code</mat-label>
        <input matInput [ngModel]="model.selectedLabels.count === 1 ? $any(model.selectedLabels.items[0]).text.tSpans[0].text : model.sharedCode"
            (ngModelChange)="model.selectedLabels.count === 1 ? ($any(model.selectedLabels.items[0]).text.tSpans[0].text = $event) : null"
            [disabled]="model.selectedLabels.count > 1"
            required
            name="code" 
            #code="ngModel"
            (keyup.enter)="model.onCodeInputKeyUpEnter($event, code.valid)"
            pattern="[0-9a-zA-Z \- \/ _]{1,10}"
            autocomplete="off">
        <div [hidden]="code.valid || code.pristine">
            Entre 1 et 10 car. alphanumériques + (- / _)
        </div>
    </mat-form-field>

    <mat-form-field appearance="outline">
        <mat-label>Sélectionnez un type de position de travail</mat-label>
        <mat-select 
            [(ngModel)]="model.sharedTypeId"
            name="type" 
            (ngModelChange)='model.onTypeChange($event)'>
            @for (w of model.workplacesTypes; track w) {
  <mat-option 
                [style.display]="(model.selectedLabels.count === 1 && w.woTyId === 0) ? 'none' : 'block'"
                [value]="w.woTyId">{{w.woTyDisplayName}}</mat-option>
}
            </mat-select>
    </mat-form-field>

}
