import { EquipmentPlanningStateView } from "src/app/core/model/data-model/views/equipment-planning-state-view";
import { EquipmentPlanningStateCommand } from "./equipment-planning-state-command";

export class EquipmentPlanningStateChoice {
    name: string;
    disabledName: string;
    disabled: boolean = false;
    planningState: EquipmentPlanningStateView | undefined;
    children: EquipmentPlanningStateCommand[] = [];

    constructor(name: string, planningState: EquipmentPlanningStateView | undefined, disabledName?: string) {
        this.name = name;
        this.disabledName = disabledName?? name;
        this.planningState = planningState;
    }

    setChildren(values: [id: number, name: string][]): void {
        values.forEach(v => {
            this.children.push(new EquipmentPlanningStateCommand(v[0], v[1]));
        });
    }

    setSelected(caseId: number): void {
        const c = this.children.find(x=> x.id === caseId);
        if (c) c.selected = true;
    }
}