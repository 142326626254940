import { TablesNamesEnum } from './../tables-names-enum';
export const EquipmentItemTable = {
    databaseTableName: TablesNamesEnum.EquipmentItem,
    eqItId: 'EqIt_Id',
    eqItMaterialId: 'EqIt_MaterialId',
    eqItCreationDate: 'EqIt_CreationDate',
    eqItCatalogId: 'EqIt_CatalogId',
    eqItInventoryStateId: 'EqIt_InventoryStateId',
    eqItPurchaseId: 'EqIt_PurchaseId'
  };
  