import { ICommand } from "./i-command";

export class Command<T extends number> implements ICommand {
    id: T;
    name: string;
    icon: string;
    enabled: boolean;

    constructor(id: T, name: string, icon: string, enabled: boolean = true) {
        this.id = id;
        this.name = name;
        this.icon = icon;
        this.enabled = enabled;
    }
}