import { SiteAttributionDbView } from '../../db-model/views/site-attribution-db-view';
import { DefinedModelBase } from '../defined-model-base';

export class SiteAttributionView extends DefinedModelBase {
    buSiteId!: number;
    roAttributionTypeId!: number;
    roAtTyName!: string;
    roAtTyColor!: string;
    roAtTyDisplayNameId!: number;
    roAtTyViDisplayName!: string;
    stAtViArea!: number;
    stAtViRoundedArea!: number;

    constructor(dtoData: any) {
        super(dtoData, [SiteAttributionDbView]);
    }
}