@if (model) {
    <div class="red-panel">
        <!-- <div class="header">
            <input type="date" class="header-item" [ngModel]="model.startDate | date:'yyyy-MM-dd'" (ngModelChange)="model.startDate = $event">
            <input type="date" class="header-item" [ngModel]="model.endDate | date:'yyyy-MM-dd'" (ngModelChange)="model.endDate = $event">
            
            <mat-form-field class="header-item" appearance="outline">
                <mat-label>Commande</mat-label>
                    <mat-select [(ngModel)]="model.selectedPurchase" (selectionChange)="model.selectedPurchaseChange()">
                        @for (p of model.purchases; track p) {
                            <mat-option [value]="p">{{(p.eqPuPurchaseDate | date: 'dd/MM/yyyy') + ' - ' + p.eqPuPurchaseRef}}</mat-option>
                        }
                    </mat-select>
            </mat-form-field>
        </div> -->
        <div class="content">
            <!-- <div class="equipment-infos">
                @if (model.selectedPurchaseDetails.length > 0) {
                    <xc-secured-image class="img" [src]="model.selectedPurchaseDetails[0].equipmentCatalog.floorCatalog.flCgImageUrl!"/>
                }
            </div> -->
            <div class="content-details">
                @if (!model.selectedPurchaseHasReceiptTask) {
                    <span class="no-receipt-task-info">Cette commande n'a pas de tâche de réception ou celle-ci ne vous est pas attribuée</span>
                } @else {
                    @if (!model.hasReceipt) {
                        <div class="flex gap-3 p-3">
                            <span class="p-float-label">
                                <p-calendar 
                                    inputId="receipt_date" 
                                    dateFormat="dd.mm.yy" 
                                    [disabled]="model.hasReceipt"
                                    [maxDate]="model.receiptMaxDate" 
                                    [(ngModel)]="model.receiptDate" 
                                    [showIcon]="true">
                                </p-calendar>
                                <label for="receipt_date">Date de réception</label>
                            </span>
                            <p-dropdown [options]="model.storageLocations" 
                                [disabled]="model.hasReceipt"
                                [(ngModel)]="model.selectedStorageLocation" 
                                optionLabel="eqStArName" 
                                [showClear]="true" 
                                placeholder="Stock de destination">
                            </p-dropdown>
                            <p-toast></p-toast>
                            @if (!model.hasReceipt) {
                                <p-confirmPopup #confirmPopupRef>
                                    <ng-template pTemplate="headless" let-message let-test>
                                        <div class="bg-gray-900 text-white border-round p-3">
                                            <span>{{ message.message }}</span>
                                            <div class="flex align-items-center gap-2 mt-3">
                                                <button (click)="accept()" pButton label="Continuer" class="p-button-sm p-button-outlined"></button>
                                                <button (click)="reject()" pButton label="Annuler" class="p-button-sm p-button-text"></button>
                                            </div>
                                        </div>
                                    </ng-template>
                                </p-confirmPopup>
                                <p-button [disabled]="model.selectedStorageLocation == null || model.receiptDate == null" 
                                    (click)="confirm($event)" 
                                    icon="pi pi-check" label="Valider">
                                </p-button>
                            }
                        </div>
                        <p-table 
                            [value]="model.selectedPurchaseDetails" 
                            dataKey="index"
                            [(selection)]="model.selectedItems"
                            [tableStyle]="{ 'min-width': '50rem' }" 
                            [styleClass]="'p-datatable-sm'" 
                            [scrollable]="true" 
                            scrollHeight="400px">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th>Index</th>
                                    <th>Picto</th>
                                    <th>Réservé</th>
                                    <th>Stock</th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-row>
                                <tr>
                                    <td>{{ row.index }}</td>
                                    <td>
                                        <xc-secured-image class="img" [src]="row.equipmentCatalog.floorCatalog.flCgImageUrl!"/>
                                    </td>
                                    <td>{{ row.planningReservedTaskName }}</td>
                                    <td>{{ row.storeAreaTargetName }}</td>
                                </tr>
                            </ng-template>
                        </p-table>
                    } @else {
                        <span >Commande livrée</span>
                    }
                }
                <!-- <table mat-table [dataSource]="model.selectedPurchaseDetails" class="mat-elevation-z1 report-table">
                    <ng-container matColumnDef="index">
                        <th mat-header-cell *matHeaderCellDef> Index </th>
                        <td mat-cell *matCellDef="let element"> {{element.index}} </td>
                    </ng-container>
                
                    <ng-container matColumnDef="picto">
                        <th mat-header-cell *matHeaderCellDef> Picto </th>
                        <td mat-cell *matCellDef="let element"> 
                            <xc-secured-image class="img" [src]="element.equipmentCatalog.floorCatalog.flCgImageUrl!"/>
                        </td>
                    </ng-container>
                
                    <ng-container matColumnDef="reserved">
                        <th mat-header-cell *matHeaderCellDef> Réservé </th>
                        <td mat-cell *matCellDef="let element"> {{element.planningReservedTaskName}} </td>
                    </ng-container>
                    
                    <ng-container matColumnDef="store">
                        <th mat-header-cell *matHeaderCellDef> Stock </th>
                        <td mat-cell *matCellDef="let element"> {{element.storeAreaTargetName}} </td>
                    </ng-container>
                
                    <tr mat-header-row *matHeaderRowDef="model.displayedColumns"></tr>
                    <tr mat-row 
                        (click)="model.onDetailRowClick(row)"
                        [class.report-row-selected]="row.selected"
                        *matRowDef="let row; columns: model.displayedColumns;"></tr>
                </table> -->
            </div>
        </div>
    </div>
}
