@if (model) {
    <div>
        <div class="flex justify-content-center">
            <p-dropdown [options]="model.roles" 
                [(ngModel)]="model.selectedRole" 
                optionLabel="apRoName" 
                [showClear]="true" 
                placeholder="Sélectionnez un rôle"
                (onChange)="model.selectedRoleChanged()">
            </p-dropdown>
        </div>
        <div>
            @if (model.selectedRole) {
                <p-tabView (onChange)="model.selectedTabChange($event)">
                    <p-tabPanel header="Calques">
                        <xc-layers-grants [model]="$any(model.selectedPage)"/>
                    </p-tabPanel>
                    <p-tabPanel header="Entités">
                        <xc-business-units-grants [model]="$any(model.selectedPage)"/>
                    </p-tabPanel>
                    <p-tabPanel header="Navigation">
                        <xc-menu-grants [model]="$any(model.selectedPage)"/>
                    </p-tabPanel>
                    <p-tabPanel header="Périmètres">
                        <xc-perimeters-grants [model]="$any(model.selectedPage)"/>
                    </p-tabPanel>
                    <p-tabPanel header="Plan">
                        <xc-blueprint-update-grants [model]="$any(model.selectedPage)"/>
                    </p-tabPanel>
                </p-tabView>
            }
        </div>
    </div>
}

