import { SiteActivityStatusView } from 'src/app/core/model/data-model/views/site-activity-status-view';
import { SiteAttributionView } from 'src/app/core/model/data-model/views/site-attribution-view';
import { SiteEquipmentProviderView } from 'src/app/core/model/data-model/views/site-equipment-provider-view';
import { SliceAnonymousData } from "src/app/ui/shared/shared-model/slice-anonymous-data";
import { RealEstateActivityStatusView } from "src/app/core/model/data-model/views/real-estate-activity-status-view";
import { RealEstateAttributionView } from 'src/app/core/model/data-model/views/real-estate-attribution-view';
import { RealEstateEquipmentProviderView } from 'src/app/core/model/data-model/views/real-estate-equipment-provider-view';
import { SiteView } from 'src/app/core/model/data-model/views/site-view';
import { ColorUtils } from 'src/app/ui/shared/shared-model/color-utils';
import { BuildingView } from 'src/app/core/model/data-model/views/building-view';
import { XcMaths } from 'src/app/core/model/static-functions/xc-maths';

export class SiteAnonymousChartDataBuilder {
    static getActivityData(view: SiteActivityStatusView[] | RealEstateActivityStatusView[]): SliceAnonymousData[] {
        const result: SliceAnonymousData[] = [];

        view.forEach(e => {
            const area = e instanceof SiteActivityStatusView ? e.stAcStViRoundedArea : e.reEsAcStViRoundedArea;
            result.push(new SliceAnonymousData(e.roActivityStatusTypeId, e.roAcTyColor, e.roAcTyViDisplayName, area));
        });

        return result;
    }

    static getAttributionData(view: SiteAttributionView[] | RealEstateAttributionView[]): SliceAnonymousData[] {
        const result: SliceAnonymousData[] = [];

        view.forEach(e => {
            const area = e instanceof SiteAttributionView ? e.stAtViRoundedArea : e.reEsAtViRoundedArea;
            result.push(new SliceAnonymousData(e.roAttributionTypeId, e.roAtTyColor, e.roAtTyViDisplayName, area));
        });

        return result;
    }

    static getInventoryData(view: SiteEquipmentProviderView[] | RealEstateEquipmentProviderView[]): SliceAnonymousData[] {
        const result: SliceAnonymousData[] = [];

        view.forEach(e => {
            const value = e instanceof SiteEquipmentProviderView ? e.stEqPrViEquipmentValue : e.reEsEqPrViEquipmentValue;
            result.push(new SliceAnonymousData(e.eqCgProviderId, e.prCoColor, e.prCoName, value));
        });

        return result;
    }

    static getNetRatiosData(view: BuildingView[]): SliceAnonymousData[] {
        const result: SliceAnonymousData[] = [];

        view = view.sort((a, b) => a.buViNUA / a.buViGLA - b.buViNUA / b.buViGLA);

        const ratios = view.map(x=> XcMaths.round(x.buViNUA / x.buViGLA, 2));
        const min = Math.min(...ratios);
        const max = Math.max(...ratios);
        const colorRange = ColorUtils.getRedToGreenRainbowRange(min * 100, max * 100, 100, 70);
        view.forEach(e => {
            const netRatio = XcMaths.round(e.buViNUA / e.buViGLA, 2);
            const color = colorRange.find(x=> x.value === Math.round(netRatio * 100))?.color;
            const tmp = new SliceAnonymousData(e.buId, "#efefef", e.buName, 1);
            tmp.children.push(new SliceAnonymousData(e.buId, color?? "lightgrey", e.buName, e.buViNUA / e.buViGLA));
            //tmp.children.push(new SliceAnonymousData(e.buId, color?? "lightgrey", e.buName, e.buViGLA / totalArea));
            result.push(tmp);
        });

        return result;
    }
}