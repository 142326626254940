import { LeaseStatusView } from './lease-status-view';
import { Lease } from 'src/app/core/model/data-model/tables/lease';
import { LeaseDbView } from '../../db-model/views/lease-db-view';

export class LeaseView extends Lease {
    statutView: LeaseStatusView;

    constructor(dtoData: any) {
        super(dtoData, [LeaseDbView]);
        this.statutView = new LeaseStatusView(dtoData);
    }
}