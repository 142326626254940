import { SvgEntity } from "src/app/core/model/svg-model/svg-entity.model";
import { BpSvgGroup } from "../../../../bp-svg-core-model/bp-svg-group";
import { BlueprintSvgEntityTypeEnum } from "../../../../svg-entities/model/blueprint-svg-entity-type-enum";

export class BpSvgEquipmentCatalogGroup extends BpSvgGroup {
    providerName: string;
    equipmentName: string;
    imageUrl: string;
    floorCatalogId: number;

    constructor(dtoData: any) {
        super(dtoData, BlueprintSvgEntityTypeEnum.none);
        this.providerName = SvgEntity.getValue(dtoData, "provider", "NA", true);
        this.equipmentName = SvgEntity.getValue(dtoData, "equipmentName", "NA", true);
        this.imageUrl = SvgEntity.getValue(dtoData, "imageUrl", "NA", true);
        this.floorCatalogId = SvgEntity.getNumber(dtoData, "floorCatalogId", 0, true);
    }
}