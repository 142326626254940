import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PeopleUpdateGizmoVM } from '../../model/people-update-gizmo-vm';

@Component({
  selector: 'g[xc-people-update-gizmo]',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './people-update-gizmo.component.svg',
  styleUrls: ['./people-update-gizmo.component.scss']
})
export class PeopleUpdateGizmoComponent {
  @Input() model: PeopleUpdateGizmoVM | undefined;
}
