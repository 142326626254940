import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InventoryDocumentsVM } from '../../model/inventory-documents-vm';

@Component({
  selector: 'xc-inventory-documents',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './inventory-documents.component.html',
  styleUrls: ['./inventory-documents.component.scss']
})
export class InventoryDocumentsComponent {
  @Input() model: InventoryDocumentsVM | undefined;
}
