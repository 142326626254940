import { FloorDoorStyleTable } from 'src/app/core/model/db-model/tables/floor-door-style-table';
import { ZLanguageItemTable } from 'src/app/core/model/db-model/tables/z-language-item-table';
import { BpSvgDoor } from '../svg-entities/model/bp-svg-door';

export class DoorStyle {
    id: number;
    invariantName: string;
    name: string;
    color: string;
    leafCount: number;
    scope: number;
    shape: BpSvgDoor | undefined;
    shapeDto: any;

    constructor(dtoData: any) {
        this.id = dtoData[FloorDoorStyleTable.id];
        this.invariantName = dtoData[FloorDoorStyleTable.name];
        this.name = dtoData[ZLanguageItemTable.laItText];
        this.color = dtoData[FloorDoorStyleTable.color];
        this.leafCount = Number(dtoData[FloorDoorStyleTable.leafCount]);
        this.scope = dtoData[FloorDoorStyleTable.scope];
        this.shape = new BpSvgDoor(dtoData["DoorShape"]);
        this.shape.doorStyle = this.id;
        this.shapeDto = dtoData["DoorShape"];
    }

    // setShape(dtoData: any): void {
    //     this.shape = new BpSvgDoor(dtoData["DoorShape"]);
    // }
}