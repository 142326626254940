import { SimulationBuildingDTO } from "src/app/core/services/backend-services/dto/simulation-building-dto";
import { SvgBuildingStackVM } from "./svg-building-stack-vm";
import { SvgDOMBase } from "src/app/core/model/svg-model/abstract/svg-dom-base";
import { SvgStrategyStackingVM } from "./svg-strategy-stacking-vm";
import { SvgTransform } from "src/app/core/model/svg-model/svg-transform.model";
import { Point } from "src/app/core/model/geometry-model/point.model";
import { SvgStackSettings } from "./svg-stack-settings";
import { SvgBsFloorDataItem } from "src/app/ui/shared/charts/stack/model/svg-bs-floor-data-item";
import { logError } from "src/app/core/services/logging-service";

export class SvgBuildingStackContainerVM extends SvgDOMBase {
    index: number;
    containerId: string;
    bgId: string;
    x: number = 0;
    y: number = 0;
    width: number;
    height: number;
    transform: SvgTransform;
    initialPosition: Point;
    draggingStart: Point | undefined;
    buildingStack: SvgBuildingStackVM;
    tooltipRequested?: (e: MouseEvent, d: SvgBsFloorDataItem | undefined) => void;

    areaStartDraggingRequested?: (e: MouseEvent, d: SvgBsFloorDataItem) => void;

    constructor(x: number, height: number, sb: SimulationBuildingDTO, index: number) {
        super(SvgStrategyStackingVM.svgId, "c80b0164-a637-4aca-b444-41d4ecfc2368");
        this.index = index;
        this.height = height;
        this.buildingStack = new SvgBuildingStackVM(sb, SvgStackSettings.hMargins, height);
        this.width = (sb.maxFloorArea() * SvgStackSettings.xScale) + SvgStackSettings.floorLabelsWidth + (SvgStackSettings.hMargins * 2);
        this.containerId = `container${sb.siBuViBuildingId}`;
        this.bgId = `containerBg${sb.siBuViBuildingId}`;
        this.initialPosition = new Point(x, this.y);
        this.transform = new SvgTransform({translate: this.initialPosition});

        this.listenToTooltipRequests();
        this.listenToAreaStartDraggingRequests();
    }

    listenToTooltipRequests(): void {
        this.buildingStack.tooltipRequested = (e: MouseEvent, d: SvgBsFloorDataItem | undefined) => {
            this.raiseTooltipRequest(e, d);
        }
    }

    listenToAreaStartDraggingRequests(): void {
        this.buildingStack.areaStartDraggingRequested = (e: MouseEvent, d: SvgBsFloorDataItem) => {
            if (this.areaStartDraggingRequested) {
                this.areaStartDraggingRequested(e, d);
            } else {
                logError("SvgBuildingStackContainerVM.areaStartDraggingRequested n'est pas écouté");
            }
        }
    }

    raiseTooltipRequest(e: MouseEvent, d: SvgBsFloorDataItem | undefined): void {
        if (this.tooltipRequested) {
            this.tooltipRequested(e, d);
        } else {
            logError("SvgBuildingStackContainerVM.tooltipRequested n'est pas écouté");
        }
    }

    dropZoneRequested?: (x: number, item: SvgBuildingStackContainerVM) => void;
    mouseMove(e: MouseEvent): void {
        // On n'est plus au dessus d'une surface, l'infobulle doit être masquée
        this.raiseTooltipRequest(e, undefined);

        if (e.buttons !== 1) return;

        const hitPoint = this.getPointPosition(e.clientX, e.clientY);
        if (hitPoint) {
            if (this.draggingStart) {
                this.transform = this.transform.translateUpdate(this.initialPosition.translate(hitPoint.minus(this.draggingStart)));
            }
            
            if (this.dropZoneRequested) {
                this.dropZoneRequested(hitPoint.x, this);
            } else {
                logError("SvgBuildingStackContainerVM.dropZoneRequested n'est pas écouté");
            }
        }
    }

    draggingStarted?: (id: string) => void;
    mouseDown(e: MouseEvent): void {
        const hitPoint = this.getPointPosition(e.clientX, e.clientY);
        if (hitPoint) {
            this.draggingStart = hitPoint;
        }
        
        if (this.draggingStarted) {
            this.draggingStarted(this.containerId);
        } else {
            logError("SvgBuildingStackContainerVM.draggingStarted n'est pas écouté");
        }
    }

    draggingEnded?: (item: SvgBuildingStackContainerVM) => void;
    mouseUp(e: MouseEvent): void {
        const hitPoint = this.getPointPosition(e.clientX, e.clientY);
        this.draggingStart = undefined;
        this.transform = new SvgTransform({translate: this.initialPosition});
        
        if (this.draggingEnded) {
            this.draggingEnded(this);
        } else {
            logError("SvgBuildingStackContainerVM.draggingEnded n'est pas écouté");
        }
    }
}