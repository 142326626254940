import { BlueprintViewerContentPanelVM } from '../../content/blueprint-viewer-content-panel/itself/model/blueprint-viewer-content-panel-vm';
import { EditableLayer } from '../../content/blueprint-viewer-content-panel/itself/model/interaction/editable-layer';
import { LayerInteraction } from '../../content/blueprint-viewer-content-panel/itself/model/interaction/layer-interaction';
import { BlueprintViewerSidePanelVM } from '../../content/blueprint-viewer-side-panel/itself/model/blueprint-viewer-side-panel-vm';
import { SidePanelResizer } from '../../content/blueprint-viewer-side-panel/itself/model/side-panel-resizer';
import { BpSvgGroup } from '../../content/bp-svg-core-model/bp-svg-group';
import { FloorTask } from '../../content/shared-model/floor-task';
import { LabelingFormModel } from '../../content/blueprint-viewer-side-panel/subitems/labeling/model/labeling-form-model';
import { TabItem } from 'src/app/components-lib/shared-model/tab-item-model';
import { PageModel } from 'src/app/ui/main/model/page-model';
import { RealEstateJunctionsEnum } from 'src/app/ui/main/model/router/real-estate-junctions-enum';
import { BlueprintSidePanelTabEnum } from '../../content/blueprint-viewer-side-panel/itself/model/bp-side-panel-tab.enum';
import { FloorModelEnum } from 'src/app/core/model/data-model/enums/floor-model-enum';
import { Tabs } from 'src/app/components-lib/shared-model/tabs-model';
import { readableUUID } from 'src/app/core/events/event-listener-uuid';
import { FloorBlueprintEventsEnum } from './floor-blueprint-events-enum';
import { EquipmentFormVM } from '../../content/blueprint-viewer-side-panel/subitems/properties/model/equipment-form-vm';
import { DisplayThemeEnum } from 'src/app/core/model/data-model/enums/display-theme-enum';
import { AppMenuRouteEnum } from 'src/app/core/model/data-model/enums/app-menu-route-enum';

export class BlueprintViewerVM extends PageModel {
    sidePanel: BlueprintViewerSidePanelVM;
    sidePanelResizer: SidePanelResizer = new SidePanelResizer();
    contentPanel: BlueprintViewerContentPanelVM = new BlueprintViewerContentPanelVM();

    constructor() {
        super(RealEstateJunctionsEnum.blueprint, 5, readableUUID("BlueprintViewerVM"));

        const tabsInvariantId = readableUUID("TabPage");
        this.sidePanel = new BlueprintViewerSidePanelVM(tabsInvariantId);
        
        this.addEventListener(`${tabsInvariantId}.${Tabs.selectedTabChanged}`, (tab: TabItem): void => {
            this.sidePanelResizer.foldSidePanel(true);
        })

        // Ecoute les changements de type d'accrochage
        //this.listenToGripTypeChange();
        // Ecoute les demandes d'actualisation des composants d'onglet
        this.listenToSidePanelUpdateRequests();
        // Ecoute le changement d'interaction utilisateur sur le plan
        this.listenToCurrentInteractionChanged();
        // Ecoute les demande d'actualisation du thème
        //this.listenToThemeRefreshRequest();
        // Ecoute les changements de calques éditables
        //this.listenToEditableLayersChanged();
        // Ecoute les demande d'insertion d'équipement
        this.listenToEquipmentInsertRequests();
        // Ecoute les demandes de changement de type d'accrochage
        //this.listenToGripTypeSwitchRequests();
        // Ecoute les demande d'affichage de thème
        this.addEventListener(EquipmentFormVM.applyStateRequest, (themeId: DisplayThemeEnum) => {
            setTimeout(async () => {
                //await this.sidePanel.content.setThemingModel();
                //await this.sidePanel.content.themingModel?.selectTheme(themeId);
            }, 0);
        })
    }

    /**
     * Déclenche le chargement d'un plan d'étage
     * @param floorId Identifiant de l'étage à afficher
     */
    async setContent(floorId: number): Promise<void> {
        // Passe la demande de chargement au panneau d'affichage et récupère le plan en retour
        const bp = await this.contentPanel.setContent(floorId);
        // Passe le plan au panneau latéral qui va l'utiliser comme source de données, par exemple pour afficher l'arbre des calques
        await this.sidePanel.setContent(bp);
    }

    // switchGripTypeRequested?: () => void;
    // listenToGripTypeSwitchRequests(): void {
    //     this.contentPanel!.switchGripTypeRequested = () => {
    //         this.sidePanel.content.gripTypeList?.switch();
    //     }
    // }

    // listenToThemeRefreshRequest(): void {
    //     this.contentPanel.themeRefreshRequested = () => {
    //         //this.sidePanel.content.themingModel?.refreshDisplayReport(false);
    //     }
    // }

    // Le changement de sélection sur le plan est passé au panneau latéral
    // pour l'affichage du formulaire des propriétés
    listenToCurrentInteractionChanged(): void {
        this.contentPanel.currentInteractionChanged = (i: LayerInteraction | undefined) => {
            this.sidePanel.setPropertiesForm(i?.propertiesForm)
        }
    }

    listenToSidePanelUpdateRequests(): void {
        // Ecoute les demandes d'affichage d'études sur le plan
        this.listenToSelectedTaskChange();
        // Ecoute les création de tâches directes
        this.listenToDirectTaskCreated();
        // Ecoute les annulations de tâche
        this.listenToSelectedTasksEnded();
        // Ecoute les changement de type d'étiquette
        this.listenToLabelTypeSelectionChange();
        // Ecoute les demande de rechargemen du plan
        this.listenToBlueprintRefreshRequests();
    }

    listenToBlueprintRefreshRequests(): void {
        this.addEventListener(FloorBlueprintEventsEnum.planningTaskValidated, async () => {
            //this.contentPanel.toolbar?.loadTasks();
            //this.sidePanel.content.floorTasksTabModel = undefined;
            //await this.sidePanel.content.loadTasksTab();
            this.contentPanel.userInteraction?.setBlueprint(this.contentPanel.userInteraction.blueprint.floorId);
        });
    }

    listenToSelectedTaskChange(): void {
        this.addEventListener(FloorBlueprintEventsEnum.selectedTaskChanged, async (task: FloorTask | null): Promise<void> => {
            // Annule toute interaction éventuellement en cours
            this.contentPanel.userInteraction?.abortCommand();
            // Actualise les onglets du side panel
            this.sidePanel.tabs.setEnable(task != null);

            // s'il y a déjà une étude sur le plan on la retire
            if (this.contentPanel.userInteraction?.blueprint.hasPlanningTask()) {
                await this.contentPanel.updateTask(null);
            }

            // Déclenche l'affichage de l'étude sur le plan
            if (task != null) {
                await this.contentPanel.updateTask(task);
            }
            this.sidePanel.tabs.setTasksTabBadge(task != null);
            // if (task != null) {
            //     this.sidePanel.content.themingModel?.refreshDisplayReport();
            // }
            // Réinitialise la sélection éventuelle sur l'onglet d'étiquetage
            //this.sidePanel.content.labelingModel?.reset();
        });
    }

    listenToDirectTaskCreated(): void {
        this.addEventListener(FloorBlueprintEventsEnum.directTaskCreated, async (taskId: number) => {
            // Annule toute interaction éventuellement en cours
            this.contentPanel.userInteraction?.abortCommand();

            // Charge en arrière plan l'onglet des tâches de façon à ce que
            // si on l'ouvre on le trouve dans l'état où il serait si on avait chargé l'étude par là
            // On commence par détruire le composant s'il était déjà chargé
            //this.sidePanel.content.floorTasksTabModel = undefined;
            // et on le recharge
            //await this.sidePanel.content.loadTasksTab(taskId);
            // Le fait de lui passer le taskId va provoquer le chargement de l'étude sur le plan
            // mais on veut également que l'onglet des tâche soit ouvert pour que le user
            // comprenne bien ce qui vient de se passer
            this.sidePanel.tabs.selectTabItem(BlueprintSidePanelTabEnum.tasks);
        });
    }

    listenToSelectedTasksEnded(): void {
        this.addEventListener(FloorBlueprintEventsEnum.taskAnyHowEnded, async () => {
            // Annule toute interaction éventuellement en cours
            this.contentPanel.userInteraction?.abortCommand();
            // Retire l'étude sur le plan
            await this.contentPanel.updateTask(null);
            // Retire le badge de notification sur l'onglet
            this.sidePanel.tabs.setTasksTabBadge(false);
        });
    }

    listenToLabelTypeSelectionChange(): void {
        this.addEventListener(LabelingFormModel.labelTypeChange, (layerId: number) => {
            const isWorkplaceLabelLayer = layerId === FloorModelEnum.WorkplaceLabels;
            const topMostTask = this.contentPanel.userInteraction!.blueprint.topMostTaskId();
            const wpLayer = this.contentPanel.userInteraction?.blueprint.layersController.workplacesLabelsLayer(topMostTask);
            if (wpLayer != null) {
                if (wpLayer.displayChangedByProcess && !isWorkplaceLabelLayer) {
                    wpLayer.switchDisplay(true);
                } else {
                    wpLayer.hideByProcess(false);
                }
            }
        });
    }

    listenToEquipmentInsertRequests(): void {
        this.sidePanel.content.equipmentsList.equipmentInsertRequested = (item: BpSvgGroup) => {
            this.contentPanel.userInteraction?.initializeEquipmentInsertion(item);
        }
    }
}