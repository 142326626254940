import { SvgTransform } from "src/app/core/model/svg-model/svg-transform.model";
import { SvgBsFloor } from "./svg-bs-floor";

export class stackFloor {
    id: number;
    name: string;
    area: number;
    buildingFloorMaxArea: number;
    level: number;
    transform: SvgTransform | undefined;
    labelTransform: SvgTransform | undefined;
    pathStatement: string | undefined;
    labelBackgroundStatement: string | undefined;
    graphics: SvgBsFloor | undefined;
    scaleX: number = 0;
    transitionDuration: number = 0.3;

    constructor(id: number, name: string, level: number, area: number, maxArea: number) {
        this.id = id;
        this.name = name;
        this.area = area;
        this.buildingFloorMaxArea = maxArea;
        this.level = level;
        this.transitionDuration = (Math.random() * (0.6 - 0.3 + 0.1)) + 0.3;
    }

    floorLabelClick(): void {

    }
}