import { TaskLinkTypeEnum } from "src/app/core/model/data-model/enums/task-link-type-enum";
import { DateUtils } from "src/app/core/model/static-functions/date-utils";
import Container from "typedi";
import { TaskService } from "src/app/core/services/backend-services/task-service";
import { TaskStatusEnum } from "src/app/core/model/data-model/enums/task-status-enum";
import { TaskTypeEnum } from "src/app/core/model/data-model/enums/task-type-enum";
import { TaskCreateResultDTO } from "src/app/core/services/backend-services/dto/task-create-result-dto";
import { TaskFacilityTable } from "src/app/core/model/db-model/tables/task-facility-table";
import { businessTypeIsFacilityType } from "src/app/core/model/data-processing/task-processing";
import { TaskView } from "src/app/core/model/data-model/views/task-view";
import { GanttSvgTaskVM } from "../../shared/model/gantt-svg-task-vm";
import { GanttSvgTaskLink } from "../../shared/model/gantt-svg-task-link";
import { from } from "rxjs";

export class TaskProcessing {
    // static isBackwardUpdatable(taskToUpdate: GanttSvgTaskVM): boolean {
    //     let updatable: boolean = true;

    //     if (!taskToUpdate.isBackwardExtendable()) return false;

    //     // Liens end to start en amont
    //     const etsLinks = taskToUpdate.links.filter(x=> x.targetTask.task.taId === taskToUpdate.task.taId && x.taskLink.taLiLinkType === TaskLinkTypeEnum.EndToStart);
    //     etsLinks.forEach(l => {
    //         const other = l.otherTask(taskToUpdate.task.taId);
    //         if (other.endXPos() >= taskToUpdate.xPos) {
    //             // Le début de la tâche à modifier dépend de la fin d'une autre tâche et les dates sont identiques
    //             // la modification envisagée est impossible
    //             updatable = false;
    //             return;
    //         }
    //     });

    //     // La modification est impossible, inutile de vérifier le reste
    //     if (!updatable) return updatable;

    //     // Si la tâche a un lien start to start, la tâche liée doit être modifiée également
    //     // mais ça n'est possible que si la tâche liée n'a pas de contrainte de liaison l'interdisant
    //     const stsLinks = taskToUpdate.links.filter(x=> x.taskLink.taLiLinkType === TaskLinkTypeEnum.StartToStart);
    //     stsLinks.forEach(l => {
    //         if (!TaskProcessing.isBackwardUpdatable(l.otherTask(taskToUpdate.task.taId))) {
    //             updatable = false;
    //             return;
    //         }
    //     });
        
    //     return updatable;
    // }

    // static isForwardUpdatable(taskToUpdate: GanttSvgTaskVM): boolean {
    //     let updatable: boolean = true;

    //     if (!taskToUpdate.isForwardExtendable()) return false;

    //     // Liens end to start en aval
    //     const etsLinks = taskToUpdate.links.filter(x=> x.sourceTask.task.taId === taskToUpdate.task.taId && x.taskLink.taLiLinkType === TaskLinkTypeEnum.EndToStart);
    //     etsLinks.forEach(l => {
    //         const other = l.otherTask(taskToUpdate.task.taId);
    //         if (other.xPos <= taskToUpdate.endXPos()) {
    //             // La fin de la tâche à modifier déclenche le début d'une autre tâche et les dates sont identiques
    //             // la modification envisagée est impossible
    //             updatable = false;
    //         }
    //     });

    //     // La modification est impossible, inutile de vérifier le reste
    //     if (!updatable) return updatable;

    //     // Si la tâche a un lien end to end, la tâche liée doit être modifiée également
    //     // mais ça n'est possible que si la tâche liée n'a pas de contrainte de liaison l'interdisant
    //     const eteLinks = taskToUpdate.links.filter(x=> x.taskLink.taLiLinkType === TaskLinkTypeEnum.EndToEnd);
    //     eteLinks.forEach(l => {
    //         if (!TaskProcessing.isForwardUpdatable(l.otherTask(taskToUpdate.task.taId))) {
    //             updatable = false;
    //         }
    //     });

    //     return updatable;
    // }

    // static updateParentProjects(tasks: SvgGanttTask[], task: SvgGanttTask): void {
    //     const parent = tasks.find(x=> x.planningTask.taId === task.planningTask.taParentId);
    //     if (parent) {
    //         const children = tasks.filter(x=> x.planningTask.taParentId === parent.planningTask.taId);
    //         // Les deux reduce pourraient être optimisés avec une seule boucle mais vu que le nombre de tâches ne sera jamais très grand
    //         // on va privilégier la concision
    //         const firstOne = children.reduce((prev, curr) => prev.planningTask.taStartDate < curr.planningTask.taStartDate ? prev : curr);
    //         const lastOne = children.reduce((prev, curr) => DateUtils.addDays(prev.planningTask.taStartDate, prev.planningTask.taDuration) > DateUtils.addDays(curr.planningTask.taStartDate, curr.planningTask.taDuration) ? prev : curr);
    //         const duration = DateUtils.getDays(firstOne.planningTask.taStartDate, lastOne.planningTask.taStartDate) + lastOne.planningTask.taDuration;
    //         if (parent.extends(firstOne.planningTask.taStartDate, firstOne.x, duration)) {
    //             // On continue l'actualisation récursivement
    //             TaskProcessing.updateParentProjects(tasks, parent);
    //         }

    //     }
    // }

    // static updateStartDate(tasks: SvgGanttTask[], links: SvgGanttTaskLink[], taskToUpdate: SvgGanttTask, deltaX: number, root: boolean): void {
    //     if (taskToUpdate.moveStart(deltaX)) {
    //         // Si la tâche a un lien start to start, la tâche liée doit être modifiée également
    //         const stsLinks = links.filter(x=> x.tasksIds().includes(taskToUpdate.id) && x.taskLink.taLiLinkType === TaskLinkTypeEnum.StartToStart);
    //         stsLinks.forEach(l => {
    //             const other = l.otherTask(taskToUpdate.id);
    //             other.moveStart(deltaX);
    //             TaskProcessing.updateStartDate(tasks, links, other, deltaX, false);
    //         });

    //         if (root) {
    //             // Les liens sont recalculés
    //             links.forEach(l => {
    //                 l.calculatePathData();
    //             });

    //             TaskProcessing.updateParentProjects(tasks, taskToUpdate);
    //         }
    //     }
    // }

    // static updateDuration(tasks: SvgGanttTask[], links: SvgGanttTaskLink[], taskToUpdate: SvgGanttTask, deltaX: number, root: boolean): void {
    //     if (taskToUpdate.moveEnd(deltaX)) {
    //         // Si la tâche a un lien end to end, la tâche liée doit être modifiée également
    //         const stsLinks = links.filter(x=> x.tasksIds().includes(taskToUpdate.id) && x.taskLink.taLiLinkType === TaskLinkTypeEnum.EndToEnd);
    //         stsLinks.forEach(l => {
    //             const other = l.otherTask(taskToUpdate.id);
    //             other.moveEnd(deltaX);
    //             TaskProcessing.updateDuration(tasks, links, other, deltaX, false);
    //         });

    //         if (root) {
    //             // Les liens sont recalculés
    //             links.forEach(l => {
    //                 l.calculatePathData();
    //             });

    //             TaskProcessing.updateParentProjects(tasks, taskToUpdate);
    //         }
    //     }
    // }

    static isTaskExtendable(taskToUpdate: GanttSvgTaskVM, deltaX: number, todayXOffset: number, fromStart?: boolean): boolean {
        // On ignore le cas où on remet la tâche dans son état initial puisqu'il n'y a pas de contrôle à effectuer
        if (!(taskToUpdate.hasPositionUpdates() && deltaX === 0)) {
            switch (Math.sign(deltaX)) {
                case -1:
                    return taskToUpdate.isBackwardExtendable(todayXOffset, fromStart, deltaX);
                case 1:
                    return taskToUpdate.isForwardExtendable(fromStart, deltaX);
                default:
                    return false;
            }
        }
        return true;
    }

    static isTaskMovable(taskToUpdate: GanttSvgTaskVM, deltaX: number, todayXOffset: number, fromStart?: boolean): boolean {
        if (taskToUpdate.task.taTypeId === TaskTypeEnum.Task) {
            return TaskProcessing.isTaskExtendable(taskToUpdate, deltaX, todayXOffset, fromStart);
        }

        // Si la tâche est un projet, il ne peut être déplacé que si toutes ses tâches sont en attente
        return TaskProcessing.isProjectMovable(taskToUpdate, deltaX, todayXOffset, fromStart);
    }

    static isProjectMovable(project: GanttSvgTaskVM, deltaX: number, todayXOffset: number, fromStart?: boolean): boolean {
        // Si la tâche est un projet, il ne peut être déplacé que si toutes ses tâches sont en attente
        // et pas avant le début de son projet parent s'il existe
        // et de toute façon pas avant la date courante

        //if (deltaX < 0 && project.task.taStartDate <= DateUtils.today()) return false;

        // TOTO : implémenter le contrôle sur le projet parent

        let tmp: boolean = true;
        project.children.forEach(c => {
            if (c.task.taTypeId === TaskTypeEnum.Task) {
                if (c.task.taStatusId !== TaskStatusEnum.Pending) {
                    tmp = false;
                }
            } else {
                tmp = TaskProcessing.isProjectMovable(c, deltaX, todayXOffset);
            }
        });

        if (!tmp) return false;

        return this.isTaskExtendable(project, deltaX, todayXOffset, fromStart);
    }

    // static moveTask(tasks: SvgGanttTask[], links: SvgGanttTaskLink[], taskToUpdate: SvgGanttTask, deltaX: number, root: boolean): void {
    //     if (taskToUpdate.translate(deltaX)) {
    //         if (taskToUpdate.planningTask.taTypeId === TaskTypeEnum.Task) {
    //             let lks: SvgGanttTaskLink[] = [];
    //             switch (Math.sign(deltaX)) {
    //                 case -1:
    //                     // Si la tâche a un lien start to start, la tâche liée doit être modifiée également
    //                     lks = links.filter(x=> x.tasksIds().includes(taskToUpdate.id) && x.taskLink.taLiLinkType === TaskLinkTypeEnum.StartToStart);
    //                     break;
    //                 case 1:
    //                     // Si la tâche a un lien end to end, la tâche liée doit être modifiée également
    //                     lks = links.filter(x=> x.tasksIds().includes(taskToUpdate.id) && x.taskLink.taLiLinkType === TaskLinkTypeEnum.EndToEnd);
    //                     break;
    //                 default:
    //                     break;
    //             }

    //             lks.forEach(l => {
    //                 const other = l.otherTask(taskToUpdate.id);
    //                 other.translate(deltaX);
    //                 TaskProcessing.moveTask(tasks, links, other, deltaX, false);
    //             });
    //         } else {
    //             TaskProcessing.moveProject(tasks, taskToUpdate, deltaX);
    //         }

    //         if (root) {
    //             // Les liens sont recalculés
    //             links.forEach(l => {
    //                 l.calculatePathData();
    //             });

    //             TaskProcessing.updateParentProjects(tasks, taskToUpdate);
    //         }
    //     }

    //     //const taskLinks = links.filter(x=> x.sourceTask.id === taskToUpdate.id || x.targetTask.id === taskToUpdate.id);
        
    //     // if (taskLinks.length > 0) {
    //     //     // on actualise les tâches liées par des liens start to start ou end to end
    //     // }
        
    //     // if (deltaX) {
    //     //     // On sort si le traitement n'a provoqué aucun changement
    //     //     if (!taskToUpdate.translate(deltaX)) return;
    //     // }

    //     // // Les liens sont recalculés
    //     // taskLinks.forEach(l => {
    //     //     l.calculatePathData();
    //     // });

    //     // // Toutes les tâches enfant sont déplacées, récursivement
    //     // const children = tasks.filter(x=> x.planningTask.taParentId === taskToUpdate.id);
    //     // children.forEach(c => {
    //     //     this.moveTask(tasks, links, c, deltaX);
    //     // });

    //     // // Les tâches parent (qui sont nécessairement de type project) sont actualisées récursivement
    //     // TaskInteraction.updateParentProjects(tasks, taskToUpdate);
    // }

    
    // // static async saveUpdatedParentProjects(tasks: SvgGanttTask[], task: SvgGanttTask): Promise<void> {
    // //     const parent = tasks.find(x=> x.planningTask.taId === task.planningTask.taParentId);
    // //     if (parent && parent.hasUpdates()) {
    // //         const s = Container.get(TaskService);
    // //         await s.updateTaskSchedule(parent.id, DateUtils.toBackendString(parent.planningTask.taStartDate), parent.planningTask.taDuration);

    // //         // On continue la sauvegarde récursivement
    // //         await TaskProcessing.saveUpdatedParentProjects(tasks, parent);
    // //     }
    // // }

    // static moveProject(tasks: SvgGanttTask[], project: SvgGanttTask, deltaX: number): void {
    //     const children = tasks.filter(x=> x.planningTask.taParentId === project.planningTask.taId);
    //     children.forEach(c => {
    //         if (c.planningTask.taTypeId === TaskTypeEnum.Task) {
    //             c.translate(deltaX);
    //         } else {
    //             TaskProcessing.moveProject(tasks, c, deltaX);
    //         }
    //     });
    // }

    // static async saveUpdatedTaskSchedule(tasks: SvgGanttTask[], task: SvgGanttTask): Promise<void> {
    //     // Constitue un tableau des éléments modifiés
    //     const datas: {taskId: number, startDate: string, duration: number}[] = [];
    //     const updated = tasks.filter(x=> x.hasUpdates());
    //     updated.forEach(t => {
    //         // Termine les opérations de modification
    //         t.endUpdating();
    //         // Constitue le tableau des éléments modifiés pour envoi au backend
    //         datas.push({taskId: t.id, startDate: DateUtils.toBackendString(t.planningTask.taStartDate), duration: t.planningTask.taDuration});
    //     });
    //     const s = Container.get(TaskService);
    //     const taskResult = await s.updateTasksSchedule(datas);
    //     if (taskResult != null) {
    //         //await TaskProcessing.saveUpdatedParentProjects(tasks, task);
    //     }
    // }

    static async saveInsertedTask(
        parentProjectId: number | null, 
        name: string, 
        type: TaskTypeEnum, 
        businessTypeId: number | null, 
        facilityTaskId: number | null, 
        floorId: number | null, 
        layersIds: number[], 
        date: Date,
        isTemplate: boolean
        ): Promise<TaskCreateResultDTO | null> {
        const s = Container.get(TaskService);

        //let parentId: number | null = null;
        let sourceTaskId: number | undefined = undefined;
        let linkTyeId: number | undefined = undefined;
        let taskFacilityDto: {} | undefined = undefined;
        //parentId = parentProjectId;
        //sourceTaskId = parentProjectId;
        //linkTyeId = TaskLinkTypeEnum.EndToStart;

        if (type === TaskTypeEnum.Task) {
            if (businessTypeIsFacilityType(businessTypeId!)) {
                taskFacilityDto = {[TaskFacilityTable.taFaCompanyTaskId]: facilityTaskId, [TaskFacilityTable.taFaUnitOfWorkAmount]: 1};
            }
        }

        const newItem = await s.createTask(
            name,
            DateUtils.toBackendString(date),
            1,
            type,
            isTemplate,
            parentProjectId,
            businessTypeId,
            [],
            floorId,
            layersIds,
            taskFacilityDto,
            sourceTaskId,
            linkTyeId);

        return newItem;
    }
}