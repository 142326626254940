import { TablesNamesEnum } from './../tables-names-enum';
export const EquipmentCatalogTable = {
    databaseTableName: TablesNamesEnum.EquipmentCatalog,
    eqCgId: 'EqCg_Id',
    eqCgProductId: 'EqCg_ProductId',
    eqCgName: 'EqCg_Name',
    eqCgDescription: 'EqCg_Description',
    eqCgProviderId: 'EqCg_ProviderId',
    eqCgCategoryId: 'EqCg_CategoryId',
    eqCgFloorCatalogId: 'EqCg_FloorCatalogId',
    eqCgUnitPrice: 'EqCg_UnitPrice',
    eqCgMinQty: 'EqCg_MinQty',
    eqCgAvgQty: 'EqCg_AvgQty'
}