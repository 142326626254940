import { Component, Input } from '@angular/core';
import { UpdateGizmos } from '../../model/update-gizmos';
import { LineGizmoComponent } from '../line-gizmo/line-gizmo.component';
import { CircleGizmoComponent } from '../circle-gizmo/circle-gizmo.component';
import { PathGizmoComponent } from '../path-gizmo/path-gizmo.component';
import { EllipseGizmoComponent } from '../ellipse-gizmo/ellipse-gizmo.component';

@Component({
  selector: 'g[xc-update-gizmos]',
  standalone: true,
  imports: [LineGizmoComponent, CircleGizmoComponent, PathGizmoComponent, EllipseGizmoComponent],
  templateUrl: './update-gizmos.component.svg',
  styleUrl: './update-gizmos.component.scss'
})
export class UpdateGizmosComponent {
  @Input() model: UpdateGizmos | undefined;
}
