import { Point } from "src/app/core/model/geometry-model/point.model";
import { InteractionCommand } from "./interaction-command";
import { Drawing } from "../drawing";
import { ArrayUtils } from "src/app/core/model/static-functions/array-utils";
import { Polygon } from "src/app/core/model/geometry-model/polygon.model";
import { DxfSvgEntity } from "../svg/dxf-svg-entity";
import { logError } from "src/app/core/services/logging-service";

export class SelectionInteraction {
    currentCommand: InteractionCommand;
    drawing: Drawing;
    selectionPathPoints: Point[] = [];
    selectedEntities: DxfSvgEntity[] = [];
    selectionChanged?: (selection: DxfSvgEntity[]) => void;

    constructor(command: InteractionCommand, drawing: Drawing) {
        this.currentCommand = command;
        this.drawing = drawing;
    }

    single(): DxfSvgEntity | undefined {
        if (this.selectedEntities.length === 1) {
            return this.selectedEntities[0];
        }
        return undefined;
    }

    updateSelectionPathPoints(p: Point, toRemove: boolean): void {
        if (this.selectionPathPoints.length === 0)
        {
            return;
        }

        // TODO : Condition de la sélection continue à supprimer ?
        // Et dans ce cas, idem pour le SelectionInteraction dans le module Blueprint
        if (this.currentCommand.isDrawingRectangularSelectionCommand())
        {
            const firstPoint = this.selectionPathPoints[0];
            const temp: Point[] = [];

            temp.push(firstPoint);
            const secondPoint = new Point(p.x, firstPoint.y);
            temp.push(secondPoint);
            temp.push(p);
            const lastPoint = new Point(firstPoint.x, p.y);
            temp.push(lastPoint);

            this.selectionPathPoints = temp;
        }
        else
        {
            // TODO : Sélection continue à supprimer ?
            var distance = this.selectionPathPoints[this.selectionPathPoints.length - 1].distanceTo(p);
            if (distance > 0.3)
            {
                this.selectionPathPoints.push(p.rounded());
            }
        }

        this.selectedEntities = this.getZoneSelection();
        this.raiseSelectionChange();
    }

    getZoneSelection(): DxfSvgEntity[] {
        if (this.drawing.geoTiling) {
            const result: DxfSvgEntity[] = [];
            // On réctifie le rectanble de sélection
            const topLeftSquare = Polygon.topLeftOriginSquare(this.selectionPathPoints);
            // On récupère les entités qui ont des points dans les tuiles que touche le rectangle de sélection
            const tilesEntities = this.drawing.geoTiling.getTiles(topLeftSquare[0], topLeftSquare[2]).map(x=> x.items).flat(1);
            // On filtre les entités des calques non visibles ou verrouillés
            const availableEntities = tilesEntities.filter(x=> x.visible && !x.locked);
            // On filtre les entités disctinctes parce qu'une même entité peut être référencée dans plusieurs tuiles
            const distinctEntities = ArrayUtils.DistinctBy(availableEntities, "entityId");
            // On recherche alors les entités qui ont tous leurs points dans le rectangle de sélection
            const polygon = new Polygon(this.selectionPathPoints);
            polygon.close();
            distinctEntities.forEach(de => {
                const pts = de.selectablePoints;
                if (polygon.containsAll(pts)) {
                    result.push(de);
                }
            });
    
            return result;
        }

        return [];
    }

    setSelection(items: DxfSvgEntity[]): void {
        this.selectedEntities = items;
        this.raiseSelectionChange();
    }

    addSelection(items: DxfSvgEntity[]): void {
        items.forEach(i => {
            const alreadyIn = this.selectedEntities.find(x=> x.entityId === i.entityId) !== undefined;
            if (!alreadyIn) {
                this.selectedEntities.push(i);
            }
        });
        this.raiseSelectionChange();
    }

    clear(): void {
        this.selectionPathPoints.splice(0);
        //this.gizmo.show(false);
        // if (this.userLayerSelect) {
        //     this.userLayerSelect(null);
        // } else {
        //     LoggerService.error("SelectionInteraction.userLayerSelect n'est pas écouté");
        // }
    }

    raiseSelectionChange(): void {
        if (this.selectionChanged) {
            this.selectionChanged(this.selectedEntities);
        } else {
            logError("SelectionInteraction.selectionChanged n'est pas écouté");
        }
    }
}