import { ZDbView } from './../tables/z-db-view';
import { ZDbViewColumnDbView } from './../../db-model/views/z-db-view-column-db-view';
import { ZColumnView } from "./z-column-view";
import { ZDbViewColumn } from './z-db-view-column';

export class ZDbViewColumnView extends ZColumnView {
    dbView: ZDbView;
    dbViewColumn: ZDbViewColumn;

    constructor(dtoData: any) {
        super(dtoData, [ZDbViewColumnDbView]);
        this.dbView = new ZDbView(dtoData);
        this.dbViewColumn = new ZDbViewColumn(dtoData);
    }
}