import { readableUUID } from "src/app/core/events/event-listener-uuid";
import { PageModel } from "src/app/ui/main/model/page-model";
import { MobilityBrowserSidePanelVM } from "./mobility-browser-side-panel-vm";
import { MobilityBrowserPageEnum } from "./mobility-browser-page-enum";
import { PageRouter } from "src/app/ui/main/model/router/page-router";
import { ContributorsWorkloadDashboardVM } from "../../nested/contributors-workload/model/contributors-workload-dashboard-vm";
import { MobilityContributorsTreeVM } from "../../side-panel/contributors-workload/model/mobility-contributors-tree-vm";
import { MobilityProjectsArchiveViewerVM } from "../../nested/projects-archives/model/mobility-projects-archive-viewer-vm";
import { MobilityProjectsScheduleVM } from "../../nested/projects-schedule/model/mobility-projects-schedule-vm";
import { MobilityProjectsGanttBrowserVM } from "../../side-panel/projects-gantt/model/mobility-projects-gantt-browser-vm";
import { MobilityProjectsArchiveTreeVM } from "../../side-panel/projects-archives/model/mobility-projects-archive-tree-vm";
import { DateUtils } from "src/app/core/model/static-functions/date-utils";
import { AppMenuRouteEnum } from "src/app/core/model/data-model/enums/app-menu-route-enum";

export class MobilityBrowserVM extends PageModel {
    mobilityBrowserPageEnum = MobilityBrowserPageEnum;
    //sidePanelVM: MobilityBrowserSidePanelVM;
    //selectedPage: MobilityBrowserPageEnum = MobilityBrowserPageEnum.none;

    projectsSchedule: MobilityProjectsScheduleVM | undefined;
    workloadDashboard: ContributorsWorkloadDashboardVM | undefined;
    archiveViewer: MobilityProjectsArchiveViewerVM | undefined;
    projectsGanttBrowser: MobilityProjectsGanttBrowserVM | undefined;


    private constructor() {
        super(AppMenuRouteEnum.planning_mobility_projects, 0, readableUUID("MobilityBrowser"));

        // this.addEventListener(MobilitySidePanelEventsEnum.mobilitySidePanelContentLoaded, async (tabIndex: string, content: any): Promise<void> => {
        //     await this.switchContent(tabIndex, content);
        // });

        //this.sidePanelVM = new MobilityBrowserSidePanelVM();
        
        // Récupère les éléments de navigation enregistrés
        //const lastTab = PageRouter.pageSegment(1, AppMenuRouteEnum, AppMenuRouteEnum.pl_mo_pr);

        // Navigue vers le dernier onglet utilisé
        //this.sidePanelVM.tabs.selectTabItem(0);
    }

    static async newAsync(): Promise<MobilityBrowserVM> {
        const result = new MobilityBrowserVM();
        await result.loadTree();
        return result;
    }

    private async loadTree(): Promise<void> {
        this.projectsGanttBrowser = await MobilityProjectsGanttBrowserVM.newAsync();
        if (this.projectsGanttBrowser.projectTreeview.dateRange) {
            //const s = Container.get(PlanningService);
            //const links = await s.downloadLinks(true, false, false, false);
            // Le calendrier est toujours prolongé de 90 jours au delà de la date courante
            const todayPlus90 = DateUtils.addDays(DateUtils.today(), 90);
            const duration = DateUtils.dateDiffInDays(todayPlus90, this.projectsGanttBrowser.projectTreeview.dateRange[0]);
            this.projectsSchedule = new MobilityProjectsScheduleVM(this.projectsGanttBrowser.projectTreeview.dateRange[0], duration);
            //this.selectedPage = MobilityBrowserPageEnum.ganttProjects;
        }
}

    // async switchContent(tabIndex: string, content: any): Promise<void> {
    //     switch (tabIndex) {
    //         case MobilityBrowserPageEnum.ganttProjects:
    //             const pb = content as MobilityProjectsGanttBrowserVM;
    //             if (pb && pb.projectTreeview.dateRange) {
    //                 //const s = Container.get(PlanningService);
    //                 //const links = await s.downloadLinks(true, false, false, false);
    //                 // Le calendrier est toujours prolongé de 90 jours au delà de la date courante
    //                 const todayPlus90 = DateUtils.addDays(DateUtils.today(), 90);
    //                 const duration = DateUtils.dateDiffInDays(todayPlus90, pb.projectTreeview.dateRange[0]);
    //                 this.projectsSchedule = new MobilityProjectsScheduleVM(pb.projectTreeview.dateRange[0], duration);
    //                 this.selectedPage = MobilityBrowserPageEnum.ganttProjects;
    //             }
    //             break;
    //         case MobilityBrowserPageEnum.contributorsWorkload:
    //             const cTree = content as MobilityContributorsTreeVM;
    //             if (cTree && cTree.dateRange) {
    //                 this.workloadDashboard = new ContributorsWorkloadDashboardVM(cTree.dateRange[0], cTree.dateRange[1]);
    //                 this.selectedPage = MobilityBrowserPageEnum.contributorsWorkload;
    //             }
    //             break;
    //         // case MobilityBrowserPageEnum.referentialsManage:
    //         //     const adminTree = content as MobilityReferentialsListVM;
    //         //     if (adminTree) {
    //         //         this.referentialsManage = new MobilityReferentialsManageVM();
    //         //         this.selectedPage = MobilityBrowserPageEnum.referentialsManage;
    //         //     }
    //         //     break;
    //         case MobilityBrowserPageEnum.ganttProjectsArchive:
    //             const archiveTree = content as MobilityProjectsArchiveTreeVM;
    //             if (archiveTree) {
    //                 this.archiveViewer = new MobilityProjectsArchiveViewerVM();
    //                 this.selectedPage = MobilityBrowserPageEnum.ganttProjectsArchive;
    //             }
    //             break;
    //     }
    // }

    // TODO : il serait mieux d'écouter le scroll sur le tab content
    // mais il reste à trouver comment obtenir la scrollbar à l'intérieur du tabpage
    onScroll(e: Event): void {
        // if (this.selectedTabIndex === MobilityBrowserPageEnum.ganttProjects) {
        //     if (this.projectsGanttBrowser?.selectedTab?.content?.contentType === MobilityProjectsGanttBrowserTabsEnum.projects) {
        //         this.emitEventAsync(MobilityProjectsGanttTreeEventsEnum.projectsTreeScrolled, (e.target as HTMLElement).scrollTop);
        //     }
        // }
    }
}