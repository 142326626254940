import { Point } from "src/app/core/model/geometry-model/point.model";
import { SvgTransform } from "src/app/core/model/svg-model/svg-transform.model";

export class SelectionRotationLabelVM {
    display: string = "none";
    text: string = "";
    transform: SvgTransform | undefined;

    constructor() {}

    initialize(position: Point, text: string): void {
        this.transform = new SvgTransform({translate: position});
        this.show(text);
    }

    show(text: string): void {
        this.text = text;
        this.display = "block";
    }

    hide(): void {
        this.text = "";
        this.display = "none";
    }
}
