import { TablesNamesEnum } from './../tables-names-enum';
export const FloorTable = {
    databaseTableName: TablesNamesEnum.Floor,
    flId: 'Fl_Id',
    flBuildingId: 'Fl_BuildingId',
    flCode: 'Fl_Code',
    flName: 'Fl_Name',
    flIsActive: 'Fl_IsActive',
    flIsFictive: 'Fl_IsFictive',
    flLease: 'Fl_Lease',
    flLocation: 'Fl_Location',
    flArea: 'Fl_Area',
    flElevation: 'Fl_Elevation',
    flTaskId: 'Fl_TaskId',
    flDefaultPerimeterId: 'Fl_DefaultPerimeterId',
  };
  