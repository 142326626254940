import { Container } from 'typedi';
import { SvgDOM } from './../../../../itself/model/interaction/svg-dom';
import { Point } from "src/app/core/model/geometry-model/point.model";
import { Segment } from "src/app/core/model/geometry-model/segment.model";
import { HtmlConstants } from "src/app/core/model/html-model/html-constants.model";
import { XcMaths } from "src/app/core/model/static-functions/xc-maths";
import { SvgTransform } from "src/app/core/model/svg-model/svg-transform.model";
import { BpSvgDoor } from "../../../../../svg-entities/model/bp-svg-door";
import { FloorBlueprint } from "../../../../itself/model/floor-blueprint";
import { UpdateGizmo } from "../../update-gizmo.model";
import { DoorWallSideOrientationEnum } from '../../../../../svg-entities/model/door-wall-side-orientation-enum';
import { DoorOpeningDirectionEnum } from '../../../../../svg-entities/door-opening-direction-enum';
import { SelectionRotationLabelVM } from '../../../selection/model/selection-rotation-label-vm';
import { SvgSegmentDebugVM } from 'src/app/components-lib/svg/debug/model/svg-segment-debug-vm';
import { PathBuilder } from 'src/app/core/model/svg-model/svg-path-builder';
import { SvgPointDebugVM } from 'src/app/components-lib/svg/debug/model/svg-point-debug-vm';
import { BpDoorService } from 'src/app/core/services/backend-services/bp-door-service';
import { logError } from 'src/app/core/services/logging-service';

export class DoorUpdateGizmoVM implements UpdateGizmo {
    floorBlueprint: FloorBlueprint;
    initialTransform: SvgTransform | undefined;
    initialAxis: Segment | undefined;
    stroke: string = "#00cc7a";
    fill: string = "#00ff99";
    display: string = "none";
    selectedDoor: BpSvgDoor | undefined;
    wall: Segment | undefined;
    isEditable: boolean = false;

    transientShape: BpSvgDoor | undefined;
    inserting: boolean = false;

    wallSideSwitchArrowPath: string | undefined;

    doorSideSwitchArrowPath: string | undefined;

    angleTransientLeaf: Segment = Segment.null();
    angleUpdating: boolean = false;
    angleLabel: SelectionRotationLabelVM = new SelectionRotationLabelVM()

    doorWidthLabelPos: Point | undefined;

    private arrowSize: number = 0.07;
    private sideTranslationArrowOffset: number = 0.1;

    // debug
    hingeDebug: SvgSegmentDebugVM | undefined;
    inSideDebug: SvgSegmentDebugVM | undefined;
    axisDebug: SvgSegmentDebugVM | undefined;
    inOpeningSideDebug: SvgPointDebugVM | undefined;
    closingPointDebug: SvgPointDebugVM | undefined;

    constructor(blueprint: FloorBlueprint, editable: boolean) {
        this.isEditable = editable;
        this.floorBlueprint = blueprint;
     }

    show(args: {selectedDoor: BpSvgDoor, isEditable: boolean}): void {
        this.selectedDoor = args.selectedDoor;
        this.isEditable = args.isEditable;
        this.display = HtmlConstants.styleDisplayBlock;

        // Récupère la cloison
        const parentId = args.selectedDoor.parentId;
        const line = SvgDOM.getSvgElementByFloorDataId(parentId!) as SVGLineElement;
        if (line) {
            this.wall = new Segment(new Point(line.x1.baseVal.value, line.y1.baseVal.value), new Point(line.x2.baseVal.value, line.y2.baseVal.value));
        }

        // Calcule les poignées d'action à afficher
        this.calculateActionArrow();
    }

    hide(): void {
        this.display = HtmlConstants.styleDisplayNone;
        this.selectedDoor = undefined;
        this.initialTransform = undefined;
    }

    showTransient(shape: BpSvgDoor | undefined): void {
        if (shape) {
            this.transientShape = shape;
            this.inserting = true;
        }
    }

    hideTransient(): void {
        this.transientShape = undefined;
        this.inserting = false;
    }

    calculateActionArrow(): void {
        if (!this.selectedDoor || !this.selectedDoor.insertionPoint || !this.wall) return;

        const s = this.wall;
        const d = this.selectedDoor.doorData.openingWallSide === DoorWallSideOrientationEnum.Right ? -1 : 1;

        // Poignée de changement de sens d'ouverture par rapport à la cloison
        if (this.selectedDoor.canBeWallInverted()) {
            const p1 = s.getOrthogonalOffset(this.selectedDoor.insertionPoint, this.sideTranslationArrowOffset * d);
            // Calcule un point décalé de la face de la cloison
            const p2 = s.getOrthogonalOffset(this.selectedDoor.insertionPoint, (this.sideTranslationArrowOffset + this.arrowSize) * d);
            // Calcule la rotation de ce point dans une direction et dans la direction opposée
            const p3 = p2.rotate(p1, XcMaths.toRad(25));
            const p4 = p2.rotate(p1, XcMaths.toRad(-25));
    
            this.wallSideSwitchArrowPath = PathBuilder.getPath([p1, p3, p4], true);
        }

        // Poignée de changement de sens d'ouverture
        if (this.selectedDoor.canBeSideInverted()) {
            //const d = this.selectedDoor.doorData.openingWallSide === DoorWallSideOrientationEnum.Right ? -1 : 1;
            const p1 = s.getOrthogonalOffset(this.selectedDoor.insertionPoint, (this.selectedDoor.doorData.doorWidth / 2) * d);

            // Calcule un point décalé par rapport à l'axe des charnières
            const h = this.selectedDoor.hingeSide();
            const hp = h.getOrthogonalProjection(p1);
            const dist = hp.distanceTo(p1);
            const ho = h.isRightHand(this.selectedDoor.insertionPoint) ? 1 : -1;

            const p2 = h.getOrthogonalOffset(hp, (dist - this.arrowSize) * ho);
            const p3 = p2.rotate(p1, XcMaths.toRad(25));
            const p4 = p2.rotate(p1, XcMaths.toRad(-25));
    
            this.doorSideSwitchArrowPath = PathBuilder.getPath([p1, p3, p4], true);
        }
    
        // debug
        // this.hingeDebug = new SvgSegmentDebugVM(this.selectedDoor.hingeSide());
        // this.inSideDebug = new SvgSegmentDebugVM(this.selectedDoor.inSide());
        // this.axisDebug = new SvgSegmentDebugVM(this.selectedDoor.axis());
        // const d = this.selectedDoor.doorData.openingDirection === DoorOpeningDirectionEnum.Right ? -1 : 1;
        // this.inOpeningSideDebug = new SvgPointDebugVM(this.selectedDoor.inSide().getOffsetMidPoint(d));
        // this.closingPointDebug = new SvgPointDebugVM(this.selectedDoor.closingPoint(true));

        // console.log(this.selectedDoor.doorData.openingDirection === DoorOpeningDirectionEnum.Right ? "Poussant droit" : "Poussant gauche");
        // console.log(this.selectedDoor.doorData.openingWallSide === DoorWallSideOrientationEnum.Right ? "A droite de la cloison" : "A gauche de la cloison");
        // console.log("Est du côté de l'ouverture", this.selectedDoor.isInOpeningSide(this.inOpeningSideDebug.p));


        // Poignée de réglage de l'angle d'ouverture située à l'extrémité du battant
        // ou d'un des deux battants selon le cas pour les portes battantes
        // Pour les portes coulissantes ou accordéon la poignée est située un peu à l'écart de la cloison pour ne pas
        // risquer d'être superposée à la poignée de translation
        // La poignée n'est pas disponible sur les portes tambour
        if (this.selectedDoor.canBeClosed()) {
            const s = this.selectedDoor.mainLeaf();
            if (!s) return;
            this.angleTransientLeaf = s;
        }

        this.doorWidthLabelPos = s.getOrthogonalOffset(this.selectedDoor.insertionPoint, (this.selectedDoor.doorData.doorWidth / 2) * -d);
    }

    moveTransientShape(hitPoint: Point): void {
        if (!this.transientShape) return;
        this.transientShape.transform = new SvgTransform({ translate: hitPoint, rotationCenter: null, rotationAngle: 0});
    }

    translate(initialHitPoint: Point, currentHitPoint: Point): void {
        if (!this.wall || !this.initialTransform || !this.initialAxis || !this.selectedDoor || !this.selectedDoor.transform) return;
        // La translation est calculée par la projection orthogonale des positions de la souris sur la ligne définie par la cloison
        const initialOrthoProjection = this.wall.getOrthogonalProjection(initialHitPoint);
        const currentOrthoProjection = this.wall.getOrthogonalProjection(currentHitPoint);
        const delta = new Point(currentOrthoProjection.x - initialOrthoProjection.x, currentOrthoProjection.y - initialOrthoProjection.y).vector();
        
        // La porte ne peut pas être déplacée au délà des extrémités du segment de cloison
        const axis = this.selectedDoor.axis();
        const targetAxis = this.initialAxis.transform(this.selectedDoor.insertionPoint!, 0, delta);
        const isInWallBounds = this.wall.contains(targetAxis.startPoint, 0.001) && this.wall.contains(targetAxis.endPoint, 0.001);
        if (isInWallBounds) {
            const newTranslate = this.initialTransform.translate.translate(delta);
            const tmp = new SvgTransform({ rotationCenter: Point.origin(), rotationAngle: this.selectedDoor.transform.rotationAngle, translate: newTranslate});
            this.selectedDoor.transform = tmp;
            this.selectedDoor.insertionPoint = newTranslate;
    
            this.calculateActionArrow();
        }
    }

    startAngleUpdate(hitPoint: Point): void {
        if (!this.selectedDoor || !this.selectedDoor.insertionPoint || !this.wall) return;
        
        var angleDeg = this.calculateFinalAngle(this.selectedDoor.insertionPoint, this.selectedDoor.isSwing(), this.wall);

        const d = this.selectedDoor.doorData.openingWallSide === DoorWallSideOrientationEnum.Right ? -2.5 : 2.5;
        const p1 = this.wall.getOrthogonalOffset(this.selectedDoor.insertionPoint, this.sideTranslationArrowOffset * d);

        // L'étiquette est affichée du côté de l'ouverture
        this.angleLabel.initialize(p1, angleDeg.toString());

        this.angleUpdating = true;
}

    updateAngle(currentHitPoint: Point): void {
        if (!this.selectedDoor || !this.selectedDoor.insertionPoint || !this.wall) return;

        const inSide = this.selectedDoor.inSide();
        let isInOpenSide = this.selectedDoor.isInOpeningSide(currentHitPoint);

        if (this.selectedDoor?.isSwing()) {
            const aa = this.angleTransientLeaf.angleWith(inSide);
            const a = this.angleTransientLeaf.angleWith(new Segment(this.angleTransientLeaf.startPoint, currentHitPoint));
            var endPoint = this.angleTransientLeaf.endPoint.rotate(this.angleTransientLeaf.startPoint, a);
            if (!isInOpenSide) {
                // Le curseur est passé du mauvais côté de la porte (dans le cas d'une porte battante)
                // Si le mouvement a été trop rapide, le battant n'a pas suivi et n'est pas totalement ouvert ou fermé
                // On force donc les valeurs maximales
                const projP = inSide.getOrthogonalProjection(endPoint);
                const isClosed = inSide.contains(projP);
                if (isClosed) {
                    //endPoint = inSide.endPoint;
                    endPoint = this.selectedDoor.closingPoint();
                } else {
                    //endPoint = inSide.endPoint.rotate(inSide.startPoint, Math.PI);
                    endPoint = this.selectedDoor.closingPoint().rotate(this.selectedDoor.hingePoint(), Math.PI);
                }
            }
            this.angleTransientLeaf.endPoint = endPoint;
        } else {
            // Cas d'une porte coulissante ou accordéon
            // L'équivalent de l'angle d'ouverture est le pourcentage d'ouverture
            // La poignée se déplace le long de l'ouverture
            const inSide = this.selectedDoor.inSide();
            const projP = inSide.getOrthogonalProjection(currentHitPoint);
            const tmp = new Segment(this.angleTransientLeaf.startPoint, projP);
            const l = tmp.length();
            if (l < this.selectedDoor.openingWidth() * 20 / 100 || l > this.selectedDoor.openingWidth() * 95 / 100) {
                isInOpenSide = false;
            } else {
                this.angleTransientLeaf = tmp;
            }
        }

        if (isInOpenSide) {
            const angleDeg = this.calculateFinalAngle(this.selectedDoor.insertionPoint, this.selectedDoor.isSwing(), this.wall);
            this.angleLabel.show(angleDeg.toString());
        } else {
            this.angleLabel.hide();
        }
    }

    calculateFinalAngle(doorPos: Point, isSwing: boolean, wall: Segment): number {
        if (!this.selectedDoor) return 0;

        const projectedLeafStartPoint = wall.getOrthogonalProjection(this.angleTransientLeaf.startPoint);
        const orientedWallSegment = new Segment(projectedLeafStartPoint, doorPos);
        let angle = 0;
        if (isSwing) {
            angle = this.angleTransientLeaf.angleWith(orientedWallSegment);
        } else {
            // Pour une porte coulissante ou accordéon, le pourcentage d'ouverture est donné de ?
            return XcMaths.round(100 - (this.angleTransientLeaf.length() / this.selectedDoor?.openingWidth() * 100), 0);
        }

        return Math.abs(XcMaths.round(XcMaths.toDeg(angle), 0));
    }

    async endAngleUpdate(): Promise<void> {
        this.angleUpdating = false;
        if (!this.selectedDoor || !this.selectedDoor.insertionPoint || !this.wall) return;

        const mainLeaf = this.selectedDoor.mainLeaf();
        if (!mainLeaf) return;

        var angleDeg = this.calculateFinalAngle(this.selectedDoor.insertionPoint, this.selectedDoor.isSwing(), this.wall);

        const s = Container.get(BpDoorService);
        const result = await s.updateDoorsOpeningAngle([this.selectedDoor.floorDataId], angleDeg);
        if (result != null && result.length === 1) {
            const doorsLayer = this.floorBlueprint.layersController.doorsLayer(this.floorBlueprint.topMostTaskId());
            if (doorsLayer) {
                doorsLayer.remove(this.selectedDoor.floorDataId);
                doorsLayer.insert(result[0]);
            }
            this.show({selectedDoor: result[0], isEditable: this.isEditable});
        }

    }

    async endTranslate(): Promise<void> {
        if (!this.selectedDoor || !this.selectedDoor.insertionPoint) return;

        const s = Container.get(BpDoorService);
        const result = await s.translateDoor(this.selectedDoor.floorDataId , this.selectedDoor.insertionPoint);
        if (result != null) {
            const doorsLayer = this.floorBlueprint.layersController.doorsLayer(this.floorBlueprint.topMostTaskId());
            if (doorsLayer) {
                doorsLayer.remove(this.selectedDoor.floorDataId);
                doorsLayer.insert(result);
            }
            this.show({selectedDoor: result, isEditable: this.isEditable});
        }
    }

    async saveInput(width: number): Promise<void> {
        if (this.selectedDoor) {
            const s = Container.get(BpDoorService);
            const result = await s.updateDoorsWidth([this.selectedDoor.floorDataId], XcMaths.round(width, 2));
            if (result != null) {
                const doorsLayer = this.floorBlueprint.layersController.doorsLayer(this.floorBlueprint.topMostTaskId());
                if (doorsLayer) {
                    doorsLayer.remove(this.selectedDoor.floorDataId);
                    doorsLayer.insert(result[0]);
                }
                this.show({selectedDoor: result[0], isEditable: this.isEditable});
            }
        }
    }

    doorWidthLabelUpdateRequested?: (p: Point, width: number) => void;
    doorWidthLabelClick(e: MouseEvent): void {
        if (this.doorWidthLabelUpdateRequested) {
            if (!this.selectedDoor || !this.isEditable) return;
            this.doorWidthLabelUpdateRequested(new Point(e.clientX, e.clientY), this.selectedDoor.doorData.doorWidth);
        } else {
            logError("DoorUpdateGizmoVM.doorWidthLabelUpdateRequested n'est pas écouté");
        }
    }

    onDoorTranslationHandleMouseDown?: (clientPoint: Point) => void
    doorTranslationHandleMouseDown(e: MouseEvent): void {
        e.stopPropagation();
        if (!this.selectedDoor?.transform) return;
        // Le modèle reçoit l'event provenant du composant et l'émet à sont tour à destination du
        // parent model abonné
        if (this.onDoorTranslationHandleMouseDown) {
            this.onDoorTranslationHandleMouseDown(new Point(e.clientX, e.clientY));
        } else {
            logError("DoorUpdateGizmoVM.onDoorTranslationHandleMouseDown n'est pas écouté");
        }
        // Il va y avoir une translation de la porte
        // on stoke donc le transform et l'axe initiaux
        this.initialTransform = this.selectedDoor.transform.clone();
        this.initialAxis = this.selectedDoor.axis();
    }

    async onWallSideSwitchHandleMouseDown(e: MouseEvent): Promise<void> {
        e.stopPropagation();
        if (!this.selectedDoor) return;

        const cwso = this.selectedDoor.doorData.openingWallSide;
        const nwso = cwso === DoorWallSideOrientationEnum.Left ?  DoorWallSideOrientationEnum.Right :  DoorWallSideOrientationEnum.Left;
        const s = Container.get(BpDoorService);
        const result = await s.updateDoorsWallSideOrientation([this.selectedDoor.floorDataId], nwso);
        if (result != null && result.length === 1) {
            const doorsLayer = this.floorBlueprint.layersController.doorsLayer(this.floorBlueprint.topMostTaskId());
            if (doorsLayer) {
                doorsLayer.remove(this.selectedDoor.floorDataId);
                doorsLayer.insert(result[0]);
            }
            this.show({selectedDoor: result[0], isEditable: this.isEditable});
        }
    }

    async onDoorSideSwitchHandleMouseDown(e: MouseEvent): Promise<void> {
        e.stopPropagation();
        if (!this.selectedDoor) return;

        const cod = this.selectedDoor.doorData.openingDirection;
        const nod = cod === DoorOpeningDirectionEnum.Left ?  DoorOpeningDirectionEnum.Right :  DoorOpeningDirectionEnum.Left;
        const s = Container.get(BpDoorService);
        const result = await s.updateDoorsOpeningDirection([this.selectedDoor.floorDataId], nod);
        if (result != null && result.length === 1) {
            const doorsLayer = this.floorBlueprint.layersController.doorsLayer(this.floorBlueprint.topMostTaskId());
            if (doorsLayer) {
                doorsLayer.remove(this.selectedDoor.floorDataId);
                doorsLayer.insert(result[0]);
            }
            this.show({selectedDoor: result[0], isEditable: this.isEditable});
        }
    }

    onDoorAngleHandleMouseDown?: (clientPoint: Point) => void
    async doorAngleHandleMouseDown(e: MouseEvent): Promise<void> {
        e.stopPropagation();
        if (!this.selectedDoor?.transform) return;
        // Le modèle reçoit l'event provenant du composant et l'émet à sont tour à destination du
        // parent model abonné
        if (this.onDoorAngleHandleMouseDown) {
            this.onDoorAngleHandleMouseDown(new Point(e.clientX, e.clientY));
        } else {
            logError("DoorUpdateGizmoVM.onDoorAngleHandleMouseDown n'est pas écouté");
        }
    }
    
}
