import { BpSvgGroupComponent } from './../../../../../../svg-entities/view/bp-svg-group/bp-svg-group.component';
import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EquipmentUpdateGizmoOverlayComponent } from '../equipment-update-gizmo-overlay/equipment-update-gizmo-overlay.component';
import { EquipmentUpdateGizmoVM } from '../../model/equipment-update-gizmo-vm';

@Component({
  selector: 'g[xc-equipment-update-gizmo]',
  standalone: true,
  imports: [CommonModule, EquipmentUpdateGizmoOverlayComponent, BpSvgGroupComponent],
  templateUrl: './equipment-update-gizmo.component.svg',
  styleUrls: ['./equipment-update-gizmo.component.scss']
})
export class EquipmentUpdateGizmoComponent {
  @Input() model: EquipmentUpdateGizmoVM | undefined;
}
