@if (model) {
<div id="inventoryTreeContainer">
    <h4>Catalogues</h4>
    @if (model != null) {
<mat-tree [dataSource]="model.dataSource" [treeControl]="model.treeControl" class="example-tree">
        <!-- This is the tree node template for leaf nodes -->
        <!-- There is inline padding applied to this node using styles.
        This padding value depends on the mat-icon-button width. -->
        <mat-tree-node class="treeNode" [ngClass]="{'selected': node.selected}" *matTreeNodeDef="let node" matTreeNodeToggle (click)="model.nodeClick($event, node)">
            {{node.name}}
        </mat-tree-node>
        <!-- This is the tree node template for expandable nodes -->
        <mat-nested-tree-node *matTreeNodeDef="let node; when: model.hasChild" 
            (click)="model.nodeClick($event, node)">
            <div class="treeNode" [ngClass]="{'selected': node.selected}">
                <button mat-icon-button matTreeNodeToggle
                        [attr.aria-label]="'Toggle ' + node.name">
                    <mat-icon class="mat-icon-rtl-mirror">
                    {{model.treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                    </mat-icon>
                </button>
                {{node.name}}
                <!-- <button mat-icon-button *ngIf="node.canInsertChild" 
                        aria-label="Ajouter une catégorie"
                        matTooltip="Ajouter une catégorie sous {{node.name}}"
                        [style.display]="node.isHovering ? 'block' : 'none'"
                        (click)="model.addCategoryButtonClick(node)">
                        <mat-icon class="add-button">add</mat-icon>
                </button> -->
                <!-- Commandes de gestion des habilitations en lecture et modification sur les calques -->
                <!-- Les commandes sont activées en fonction des droits de l'utilisateur -->
                <!-- <ng-container *ngIf="node.parentId === 0">
                    <button mat-icon-button 
                    aria-label="Lecture" 
                    matTooltip="{{node.layerReadGrantId == null ? 'Créer une habilitation en lecture sur le calque de cette catégorie' : 'Supprimer l\'habilitation en lecture sur le calque de cette catégorie'}}" 
                    *ngIf="node.isHovering || node.layerReadGrantId != null" 
                    (click)='model.toggleLayerGrant(node, GrantTypeEnum.Read)'>
                    <mat-icon class="material-icons-outlined">visibility</mat-icon>
                    </button>
                    <button mat-icon-button 
                    aria-label="Modification"
                    matTooltip="{{node.layerUpdateGrantId == null ? 'Créer une habilitation en modification sur le calque de cette catégorie' : 'Supprimer l\'habilitation en modification sur le calque de cette catégorie'}}"
                    *ngIf="node.isHovering || node.layerUpdateGrantId != null" 
                    (click)='model.toggleLayerGrant(node, GrantTypeEnum.Update)'>
                    <mat-icon class="material-icons-outlined">edit</mat-icon>
                    </button>
                </ng-container> -->
                </div>
                <!-- There is inline padding applied to this div using styles.
                    This padding value depends on the mat-icon-button width.  -->
                <div [class.example-tree-invisible]="!model.treeControl.isExpanded(node)"
                    role="group">
                <ng-container matTreeNodeOutlet></ng-container>
            </div>
        </mat-nested-tree-node>
    </mat-tree>
}
</div>
}
