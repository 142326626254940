import { BusinessUnitDbView } from 'src/app/core/model/db-model/views/business-unit-db-view';
import { RoomAllocationTable } from 'src/app/core/model/db-model/tables/room-allocation-table';
import { BusinessUnitTable } from 'src/app/core/model/db-model/tables/business-unit-table';
import { FloorTable } from 'src/app/core/model/db-model/tables/floor-table';
import { BuildingTable } from 'src/app/core/model/db-model/tables/building-table';

export const BuildingAllocationDbView = {
    databaseTableName: "building_allocation_view",
    buSiteId: BuildingTable.buSiteId, 
    flBuildingId: FloorTable.flBuildingId,
    buUnName: BusinessUnitTable.buUnName, 
    roAlBusinessUnitId: RoomAllocationTable.roAlBusinessUnitId,
    buUnCode: BusinessUnitTable.buUnCode, 
    buUnColor: BusinessUnitTable.buUnColor, 
    buUnViBuTree: BusinessUnitDbView.buUnViBuTree,
    buAlViArea: "BuAlVi_Area", 
    buAlViRoundedArea: "BuAlVi_RoundedArea"
}