export class ArrayUtils {
  /**
  * 
  * @param t1 Tableau principal
  * @param t2 Tableau de jointure
  * @param c1 Nons de la colonne de jointure dans le tableau principal
  * @param c2 Nons de la colonne de jointure dans le tableau de jointure. Si omise, identique à c1
  * @returns Retourne un extrait du second tableau join sur le premier par la colonne c
  */
  public static join<T, U, V>(t1: T[], t2: U[], c1: string, c2?: string): U[] {
    if (!c2) c2 = c1;
    const jIds = ArrayUtils.DistinctValueBy<T, V>(t1, c1);
    if (jIds.length === 0) return [];
    const result = t2.filter(x => {
      const a = x as any;
      let v = ArrayUtils.getItemValue<V>(x, c2!);
      // let v: V;
      // if (a['dataSet']) {
      //   // Cas où le tableau de jointure est un dataSet
      //   v =  a['dataSet'][c2!];
      // } else {
      //   v =  a[c2!];
      // }
      return jIds.includes(v)
    });

    return result;
  }

  /**
   * 
   * @param t1 Table sur laquelle on fait la consolidation
   * @param t2 Table  pour les entrées de laquelle on veut la consolidation
   * @param c0 Colonne des identifiants de t2 
   * @param c1 Colonne de jointure entre les tables t1 et t2
   * @param c2 Colonne à sommer dans la table t1. Si undefined, la consolidation est un comptage
   * @returns Retourne un Array<Tuple[number, U, number]> où U est t2
   */
  static aggegate<T, U>(t1: T[], t2: U[], c0: string, c1: string, c2: string | undefined = undefined): [number, U, number][] {
    const result: [number, U, number][] = [];

    for (let i = 0; i < t2.length; i++) {
      const t = t2[i];
      const id = (t as any)[c0];
      const data = t1.filter(x => {
        const v = ArrayUtils.getItemValue<number>(x, c1);
        return v === id
      }
      );
      let tmp = 0;
      if (c2) {
        tmp = ArrayUtils.sumBy(data, c2);
      } else {
        tmp = data.length;
      }
      result.push([id, t, tmp]);
    }

    return result;
  }

  private static getItemValue<T>(item: any, column: string): T {
    if (item['dataSet']) {
      // Cas où le tableau de jointure est un dataSet
      return item['dataSet'][column] as T;
    } else {
      return item[column] as T;
    }
  }

  /**
  * 
  * @param t1 Tableau principal
  * @param t2 Tableau de jointure
  * @param c1 Nons de la colonne de jointure dans le tableau principal
  * @param c2 Nons de la colonne de jointure dans le tableau de jointure
  * @returns Retourne un extrait du second tableau join sur le premier par la colonne c
  */
  public static joinSum<T, U, V>(t1: T[], t2: U[], c1: string, c2: string, s: string): U[] {
    const jIds = ArrayUtils.DistinctValueBy<T, V>(t1, c1);
    return t2.filter(x => jIds.includes((x as any)[c2]));
  }

  /**
   * 
   * @param objectsArray Tableau d'objet dont on veut extraire les éléments distincts
   * @param propertyName Nom de la propriété sur laquelle on distingue les objets les uns des autres, typiquement l'identifiant
   */
  public static DistinctBy<T>(objectsArray: T[], propertyName: string): T[] {
    var unique: number[] = [];
    var distinct: T[] = [];
    for (let i = 0; i < objectsArray.length; i++) {
      const value = (objectsArray[i] as any)[propertyName];
      if (!unique[value]) {
        distinct.push(objectsArray[i]);
        unique[value] = 1;
      }
    }

    return distinct;
  }

  /**
   * 
   * @param objectsArray Tableau d'objet dont on veut extraire une propriété distincte
   * @param propertyName Nom de la propriété sur laquelle on veut regrouper
   * @returns Retourne un tableau des valeurs distinctes
   */
  public static DistinctValueBy<T, U>(objectsArray: T[], propertyName: string): U[] {
    var unique: number[] = [];
    var distinct: U[] = [];
    for (let i = 0; i < objectsArray.length; i++) {
      const a = objectsArray[i] as any;
      let v = ArrayUtils.getItemValue<U>(a, propertyName);
      // let v: U;
      // if (a['dataSet']) {
      //   // Cas où le tableau de jointure est un dataSet
      //   v =  a['dataSet'][propertyName];
      // } else {
      //   v =  a[propertyName];
      // }

      if (v !== undefined && !unique[v as any]) {
        distinct.push(v);
        unique[v as any] = 1;
      }
    }

    return distinct;
  }

  /**
 * POC
 * @param array Tableau d'objets
 * @param propertyName Nom de la propriété à sommer
 * @returns Retourne la somme pour la propriété
 */
  public static sumBy(array: any[], propertyName: string): number {
    return array.reduce((sum, item) => {
      const v = ArrayUtils.getItemValue<number>(item, propertyName);
      return sum + v;
      //return sum + item[propertyName];
    }, 0);
  }

  /**
   * 
   * @param values Tableau de valeurs numériques, typiquement des ids
   * @returns Retourne un tableau key/value des valeurs distinctes avec leurs quantités respectives
   */
  public static dictinctCount(values: number[]): {value: number, count: number}[] {
    const result: {value: number, count: number}[] = [];
    values.forEach(v => {
        const count = values.filter(x=> x === v).length;
        result.push({value: v, count: count});
    });
    return result;
  }
}
