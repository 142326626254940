import { Component, Input } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatTabsModule } from '@angular/material/tabs';
import { StoresDashboardComponent } from '../stores-dashboard/stores-dashboard.component';
import { StoresManage } from '../../model/stores-manage';
import { MVVMEventDestroyLifeCycle } from 'src/app/components-lib/shared-model/mvvm-events-destroy-lifecycle';
import { DynTableComponent } from 'src/app/components-lib/dyn-table/dyn-table.component';

@Component({
  selector: 'xc-stores-manage',
  standalone: true,
  imports: [DynTableComponent, MatTabsModule, FormsModule, StoresDashboardComponent],
  templateUrl: './stores-manage.component.html',
  styleUrl: './stores-manage.component.scss'
})
export class StoresManageComponent extends MVVMEventDestroyLifeCycle {
  @Input() override model: StoresManage | undefined;
}
