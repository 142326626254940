import { SvgCircle } from "./svg-circle.model";
import { SvgEntityTypesEnum } from "./svg-entity-type-enum";
import { SvgEntity } from "./svg-entity.model";
import { SvgGroup } from "./svg-group.model";
import { SvgLine } from "./svg-line.model";
import { SvgPath } from "./svg-path.model";
import { SvgRectangle } from "./svg-rectangle.model";
import { SvgText } from "./svg-text.model";
import { SvgUse } from "./svg-use.model";

export function getEntities(entities: any): Array<SvgEntity> {
    let result = new Array<SvgEntity>();
    if(entities){
        entities.forEach((element: { entityType: any; }) => {
            switch(element.entityType){
                case SvgEntityTypesEnum.g:
                    result.push(new SvgGroup(element));
                    break;
                case SvgEntityTypesEnum.text:
                    result.push(new SvgText(element));
                    break;
                case SvgEntityTypesEnum.line:
                    result.push(new SvgLine(element));
                    break;
                case SvgEntityTypesEnum.path:
                    result.push(new SvgPath(element));
                    break;
                case SvgEntityTypesEnum.use:
                    result.push(new SvgUse(element));
                    break;
                case SvgEntityTypesEnum.rect:
                    result.push(new SvgRectangle(element));
                    break;
                case SvgEntityTypesEnum.circle:
                    result.push(new SvgCircle(element));
                    break;
                default:
                    result.push(new SvgEntity(element));
            }
        });
    }

    return result;
}
