import { FloorActivityStatusView } from "src/app/core/model/data-model/views/floor-activity-status-view";
import { FloorAllocationView } from "src/app/core/model/data-model/views/floor-allocation-view";
import { FloorAnatomyView } from "src/app/core/model/data-model/views/floor-anatomy-view";
import { FloorAttributionView } from "src/app/core/model/data-model/views/floor-attribution-view";
import { FloorEquipmentProviderView } from "src/app/core/model/data-model/views/floor-equipment-provider-view";
import { SliceAnonymousData } from "src/app/ui/shared/shared-model/slice-anonymous-data";

export class FloorAnonymousDonutChartDataBuilder {
    static getActivityData(view: FloorActivityStatusView[]): SliceAnonymousData[] {
        const result: SliceAnonymousData[] = [];

        view.forEach(e => {
            result.push(new SliceAnonymousData(e.roActivityStatusTypeId, e.roAcTyColor, e.roAcTyViDisplayName, e.flAcStViRoundedArea));
        });

        return result;
    }

    static getAttributionData(view: FloorAttributionView[]): SliceAnonymousData[] {
        const result: SliceAnonymousData[] = [];

        view.forEach(e => {
            result.push(new SliceAnonymousData(e.roAttributionTypeId, e.roAtTyColor, e.roAtTyViDisplayName, e.flAtViRoundedArea));
        });

        return result;
    }

    static getInventoryData(view: FloorEquipmentProviderView[]): SliceAnonymousData[] {
        const result: SliceAnonymousData[] = [];

        view.forEach(e => {
            result.push(new SliceAnonymousData(e.eqCgProviderId, e.prCo_Color, e.prCo_Name, e.equipmentValue));
        });

        return result;
    }

    static getBusinessUnitsData(view: FloorAllocationView[]): SliceAnonymousData[] {
        const result: SliceAnonymousData[] = [];

        view.forEach(e => {
            result.push(new SliceAnonymousData(e.roAlBusinessUnitId, e.buUnColor, e.buUnName, e.flAlViRoundedArea));
        });
        
        return result;
    }
}