import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from 'src/app/core/material.module';
import { FormsModule } from '@angular/forms';
import { StringField } from '../../../shared-model/string-field';

@Component({
  selector: 'xc-string-property',
  standalone: true,
  imports: [CommonModule, MaterialModule, FormsModule],
  templateUrl: './string-property.component.html',
  styleUrls: ['./string-property.component.scss']
})
export class StringPropertyComponent {
  @Input() model: StringField | undefined;
}
