import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GrantsAdminVM } from '../../model/grants-admin-vm';
import { BlueprintUpdateGrantsComponent } from '../blueprint-update-grants/blueprint-update-grants.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { PerimetersGrantsComponent } from '../perimeters-grants/perimeters-grants.component';
import { LayersGrantsComponent } from '../layers-grants/layers-grants.component';
import { BusinessUnitsGrantsComponent } from '../business-units-grants/business-units-grants.component';
import { MenuGrantsComponent } from '../menu-grants/menu-grants.component';
import { MVVMEventDestroyLifeCycle } from 'src/app/components-lib/shared-model/mvvm-events-destroy-lifecycle';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule } from '@angular/forms';
import { TabViewModule } from 'primeng/tabview';

@Component({
  selector: 'xc-grants-admin',
  standalone: true,
  imports: [CommonModule, 
    FormsModule,
    DropdownModule,
    TabViewModule,
    MatFormFieldModule, 
    BlueprintUpdateGrantsComponent, 
    PerimetersGrantsComponent, 
    LayersGrantsComponent, 
    BusinessUnitsGrantsComponent, 
    MenuGrantsComponent],
  templateUrl: './grants-admin.component.html',
  styleUrls: ['./grants-admin.component.scss']
})
export class GrantsAdminComponent {
  @Input() model: GrantsAdminVM | undefined;
}
