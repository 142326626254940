import { RoomLayoutType } from 'src/app/core/model/data-model/tables/room-layout-type';
import { AxonoFloor } from './axono-floor';
import { RoomColorMapDTO } from 'src/app/core/services/backend-services/dto/room-color-map-dto';
import { BuildingAxonoDTO } from 'src/app/core/services/backend-services/dto/building-axono-dto';
import { logError } from 'src/app/core/services/logging-service';

export class BuildingAxono {
    floors: AxonoFloor[] = [];
    layoutTypes: RoomLayoutType[] = [];
    elevationMax!: number;
    isExpanded: boolean = false;
    selectedFloor: AxonoFloor | undefined;
    hasFloorsAbove: boolean = false;
    hasFloorsBelow: boolean = false;

    load(dto: BuildingAxonoDTO) {
        this.floors.splice(0);
        this.isExpanded = false;
        this.selectedFloor = undefined;
        this.hasFloorsAbove = false;
        this.hasFloorsBelow = false;

        //this.layoutTypes = dto.layoutTypes;
        const infra = dto.floors.filter(x=> x.floor.flElevation < 0);
        this.elevationMax = Math.max(...dto.floors.map(x => x.floor.flElevation)) + infra.length;
        const sortedFloors = dto.floors.sort((a, b) => a.floor.flElevation - b.floor.flElevation);
        sortedFloors.forEach(f => {
            this.floors.push(new AxonoFloor(f, infra.length)); //, rooms, this.layoutTypes));
        });
    }

    setColorMap(colorMap: RoomColorMapDTO[], mapType: string): void {
        this.floors.forEach(f => {
            f.setColorMap(mapType, colorMap.filter(x=> x.floorId === f.floor.flId));
        });
    }

    blueprintRequested?: (floorId: number) => void;
    floorClick(e: MouseEvent, f: AxonoFloor): void {
		if (this.isExpanded ) {
            if (this.blueprintRequested) {
                this.blueprintRequested(f.floor.flId);
            } else {
                logError("BuildingAxono.blueprintRequested n'est pas écouté");
            }

			return;
		}

        this.selectedFloor = f;
        f.isSelected = true;

        this.checkPosition();

        this.isExpanded = true;
    }

    floorMouseEnter(e: MouseEvent, f: AxonoFloor): void {
        this.checkOverPosition(f);
        f.overX = -30;
    }

    floorMouseLeave(e: MouseEvent, f: AxonoFloor): void {
        this.floors.forEach(e => {
            if (e.floor.flElevation > f.floor.flElevation) {
                e.notOverAboveZ = 0;
                e.notOverBelowZ = 0;
            } else {
                e.notOverAboveZ = 0;
                e.notOverBelowZ = 0;
            }
        });
        f.overX = 0;
    }

    checkOverPosition(current: AxonoFloor): void {
        this.floors.forEach(e => {
            if (e.floor.flId !== current.floor.flId) {
                if (e.floor.flElevation > current.floor.flElevation) {
                    e.notOverAboveZ = 10;
                    e.notOverBelowZ = 0;
                } else {
                    e.notOverAboveZ = 0;
                    e.notOverBelowZ = -10;
                }
            }
        });
    }

    checkPosition(): void {
        if (!this.selectedFloor) return;

        this.hasFloorsAbove = false;
        this.hasFloorsBelow = false;

        const f = this.selectedFloor;
        this.floors.forEach(e => {
            e.notOverAboveZ = 0;
            e.notOverBelowZ = 0;
            if (e.floor.flId !== f.floor.flId) {
                if (e.floor.flElevation > f.floor.flElevation) {
                    e.isNotSelectedAbove = true;
                    e.isNotSelectedBelow = false;
                    this.hasFloorsAbove = true;
                } else {
                    e.isNotSelectedAbove = false;
                    e.isNotSelectedBelow = true;
                    this.hasFloorsBelow = true;
                }
            }
        });
    }

    getNextFloor(increment: number): AxonoFloor {
        const f = this.selectedFloor!;
        const i = this.floors.indexOf(f);
        return this.floors[i + increment];
    }

    selectFloor(floor: AxonoFloor): void {
        if (this.selectedFloor) {
            this.selectedFloor.isSelected = false;
        }
        this.selectedFloor = floor;
        this.selectedFloor.isSelected = true;
        this.selectedFloor.isNotSelectedAbove = false;
        this.selectedFloor.isNotSelectedBelow = false;

    }

    onUpButtonClick(e: MouseEvent): void {
        this.selectFloor(this.getNextFloor(1));
        this.checkPosition();
    }

    onCollapseButtonClick(e: MouseEvent): void {
        this.floors.forEach(e => {
            e.isNotSelectedAbove = false;
            e.isNotSelectedBelow = false;
            e.isSelected = false;
        });

        this.isExpanded = false;
        this.hasFloorsAbove = false;
        this.hasFloorsBelow = false;
        this.selectedFloor = undefined;
    }

    onDownButtonClick(e: MouseEvent): void {
        this.selectFloor(this.getNextFloor(-1));
        this.checkPosition();
    }
}