import { XcMaths } from "../static-functions/xc-maths";
import { GeometricElementType } from "./geometric-element-type.model";
import { GeometricElement } from "./geometric-element.model";
import { Point } from "./point.model";
import { Segment } from "./segment.model";
import { Vector } from "./vector.model";

export class Ellipse implements GeometricElement {
    elementType: GeometricElementType = GeometricElementType.Ellipse;
    center: Point = Point.origin();
    majorRadius: number = 0;
    minorRadius: number = 0;
    majorAxisRotation: number = 0;

    constructor(center: Point, majorRadius: number, minorRadius: number, rotation: number) {
        this.center = center;
        this.majorRadius = majorRadius;
        this.minorRadius = minorRadius;
        this.majorAxisRotation = rotation;
    }

    equals(e: Ellipse): boolean{
        return this.center.equals(e.center) && 
        this.majorRadius === e.majorRadius &&
        this.minorRadius === e.minorRadius ;
    }

    translate (translate: Vector): Ellipse {
        const newCenter = this.center.translate(translate);
        return new Ellipse(newCenter, this.majorRadius, this.minorRadius, this.majorAxisRotation);
    }

    /**
     * 
     * @returns [left, bottom, right, top]
     */
    getQuadrants(): Point[] {
        const result: Point[] = [];

        const p0 = new Point(this.center.x + this.majorRadius, this.center.y);
        result.push(p0);
        const p1 = new Point(this.center.x, this.center.y + this.minorRadius);
        result.push(p1);
        const p2 = new Point(this.center.x - this.majorRadius, this.center.y);
        result.push(p2);
        const p3 = new Point(this.center.x, this.center.y - this.minorRadius);
        result.push(p3);

        return result;
    }

    /**
     * https://www.xarg.org/book/computer-graphics/line-segment-ellipse-intersection/
     * @param s 
     * @returns 
     */
    getIntersects(s: Segment): Point[] {
        const result: Point[] = [];

        // Si l'ellipse est tournée, le segment est tourné dans le sens opposé pour effecter le calcul
        // et la rotation sera appliquée de nouveau aux points résultants
        const phi = XcMaths.toRad(this.majorAxisRotation);

        //const cv = this.center.vector();
        const tsp = s.startPoint.vector().minus(this.center).rotate(-phi).toPoint();
        const tep = s.endPoint.vector().minus(this.center).rotate(-phi).toPoint();
    
        //const rs = s.transform(this.center, -phi, new Vector(0, 0));
        //const sp = rs.startPoint;
        //const ep = rs.endPoint;
        const v = Vector.fromPoints(tsp, tep);
        const rx2 = this.majorRadius * this.majorRadius;
        const ry2 = this.minorRadius * this.minorRadius;

        const a = rx2 * v.v * v.v + ry2 * v.u * v.u;
        const b = 2 * (rx2 * tsp.y * v.v + ry2 * tsp.x * v.u);
        const c = rx2 * tsp.y * tsp.y + ry2 * tsp.x * tsp.x - rx2 * ry2;
    
        const D = b * b - 4 * a * c; // Discriminant
    
        if (D >= 0) {
    
            const sqrtD = Math.sqrt(D);
            const t1 = (-b + sqrtD) / (2 * a);
            const t2 = (-b - sqrtD) / (2 * a);
    
            if (0 <= t1 && t1 <= 1) {
                result.push(v.scale(t1).plus(tsp).rotate(phi).plus(this.center).toPoint());
            }
    
            if (0 <= t2 && t2 <= 1 && Math.abs(t1 - t2) > Number.EPSILON) {
                result.push(v.scale(t2).plus(tsp).rotate(phi).plus(this.center).toPoint());
            }
        }
        
        return result
    }
}