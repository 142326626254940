import { SelectableRealEstateTreeComponent } from './../../selectable-real-estate-tree/view/selectable-real-estate-tree/selectable-real-estate-tree.component';
import { SelectableBuTreeComponent } from './../../selectable-bu-tree/view/selectable-bu-tree/selectable-bu-tree.component';
import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CreationStepFourVM } from '../../model/creation-step-four-vm';

@Component({
  selector: 'xc-creation-step-four',
  standalone: true,
  imports: [CommonModule, SelectableBuTreeComponent, SelectableRealEstateTreeComponent],
  templateUrl: './creation-step-four.component.html',
  styleUrls: ['./creation-step-four.component.scss']
})
export class CreationStepFourComponent {
  @Input() model: CreationStepFourVM | undefined;
}
