import { MatRadioModule } from '@angular/material/radio';
import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule } from '@angular/forms';
import { DonutChartWrapperComponent } from 'src/app/ui/shared/charts/donut/view/donut-chart-wrapper/donut-chart-wrapper.component';
import { MatTabsModule } from '@angular/material/tabs';
import { DynTableComponent } from 'src/app/components-lib/dyn-table/dyn-table.component';
import { PropertiesEditorComponent } from 'src/app/ui/pages/layout/real-estate/properties-editor/view/properties-editor/properties-editor.component';
import { FloorDashboardVM } from '../floor-dashboard-vm';
import { MoveListsContainerComponent } from '../move-lists-container/move-lists-container.component';

@Component({
  selector: 'xc-floor-dashboard',
  standalone: true,
  imports: [CommonModule, 
    MatDividerModule, 
    MatRadioModule, 
    MatIconModule, 
    FormsModule, 
    MatTabsModule,
    DonutChartWrapperComponent, 
    DynTableComponent, 
    PropertiesEditorComponent,
  MoveListsContainerComponent],
  templateUrl: './floor-dashboard.component.html',
  styleUrls: ['./floor-dashboard.component.scss']
})
export class FloorDashboardComponent {
  @Input() model: FloorDashboardVM | undefined;
}
