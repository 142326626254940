import { HtmlEcsyConstants } from "src/app/core/model/html-model/html-ecsy-constants.model";
import { BpSvgLine } from "../../bp-svg-core-model/bp-svg-line";
import { BpSvgLabel } from "./bp-svg-label";
import { Point } from "src/app/core/model/geometry-model/point.model";
import { Segment } from "src/app/core/model/geometry-model/segment.model";
import { XcMaths } from "src/app/core/model/static-functions/xc-maths";
import { BlueprintSvgEntityTypeEnum } from "./blueprint-svg-entity-type-enum";
import { BpSvgMeasureLines } from "./bp-svg-measure-lines";

export class BpSvgMeasurementLabel extends BpSvgLabel {
    static extLength = 0.07;

    measureLines: BpSvgMeasureLines | undefined;
    leaderlines: BpSvgLine[] = [];
    marks: BpSvgLine[] = [];
    extensionLines: BpSvgLine[] = [];
    measureLine: BpSvgLine | undefined;

    constructor(dtoData: any) {
        super(dtoData, BlueprintSvgEntityTypeEnum.measurementLabel);
        this.backgroundRectangle.fillOpacity = 0.05;

        this.entities.forEach(e => {
            if (e.xcFunc != null) {
                switch (e.xcFunc) {
                    case HtmlEcsyConstants.xcMeasureLine:
                        this.measureLine = e as BpSvgLine;
                        break;
                    case HtmlEcsyConstants.xcLeaderLine:
                        this.leaderlines.push(e as BpSvgLine);
                        break;
                    case HtmlEcsyConstants.xcExtensionLine:
                        this.extensionLines.push(e as BpSvgLine);
                        break;
                    case HtmlEcsyConstants.xcMark:
                        this.marks.push(e as BpSvgLine);
                        break;
                    default:
                        break;
                }
            }
        });

        this.measureLines = new BpSvgMeasureLines(this.leaderlines, this.marks, this.extensionLines, this.measureLine);
        this.entities.push(this.measureLines);
    }

    moveEndpoint(p: Point, endPointIsStartPoint: boolean): void {
        if(endPointIsStartPoint) {
            this.measureLine!.startPoint = p;
            this.updateStartMark();
        } else {
            this.measureLine!.endPoint = p;
            this.updateEndMark();
        }
        this.updateLabel();
    }

    updateStartMark(): void {
        const startMarkSegment = BpSvgMeasurementLabel.getStartMarkSegment(this.measureLine!.segment());
        this.marks[0].startPoint = startMarkSegment.startPoint;
        this.marks[0].endPoint = startMarkSegment.endPoint;

    }

    updateEndMark(): void {
        const endMarkSegment = BpSvgMeasurementLabel.getEndMarkSegment(this.measureLine!.segment());
        this.marks[1].startPoint = endMarkSegment.startPoint;
        this.marks[1].endPoint = endMarkSegment.endPoint;
    }

    updateLabel(): void {
        const s = this.measureLine!.segment();
        const l = s.length();
        this.updatePosition(s.getEndPointAt(l / 2, false));
        this.mainSpan().text = XcMaths.round(l, 2).toString();
    }

    static getStartMarkSegment(leaderLine: Segment): Segment {
        const a = - Math.PI / 4;
        const extLength = BpSvgMeasurementLabel.extLength;

        const e1 = leaderLine.getEndPointAt(-extLength / 2, false).rotate(leaderLine.startPoint, a);
        const e2 = leaderLine.getEndPointAt(extLength / 2, false).rotate(leaderLine.startPoint, a);
        return new Segment(e1, e2);
    }

    static getEndMarkSegment(leaderLine: Segment): Segment {
        const a = - Math.PI / 4;
        const extLength = BpSvgMeasurementLabel.extLength;

        const e3 = leaderLine.getEndPointAt(-extLength / 2, true).rotate(leaderLine.endPoint, a);
        const e4 = leaderLine.getEndPointAt(extLength / 2, true).rotate(leaderLine.endPoint, a);
        return new Segment(e3, e4);
    }

}
