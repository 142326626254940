import { LayerInteraction } from './interaction/layer-interaction';
import { FloorBlueprint } from "./floor-blueprint";
import { FloorTask } from "../../../shared-model/floor-task";
import { UserInteraction } from "./interaction/user-interaction";
import { ThemingModel } from '../../../blueprint-viewer-side-panel/subitems/theming/model/theming-model';
import { EventListener } from 'src/app/core/events/event-listener';
import { readableUUID } from 'src/app/core/events/event-listener-uuid';
import { BlueprintViewerToolbar } from './blueprint-viewer-toolbar';
import { FloorsEventsEnum } from '../../../../../floors/floors-events-enum';
import { logError } from 'src/app/core/services/logging-service';

export class BlueprintViewerContentPanelVM extends EventListener {
    userInteraction: UserInteraction | undefined;
    toolbar: BlueprintViewerToolbar | undefined;
    // themingModel: ThemingModel | undefined;
    // floorTasks: FloorTask[] = [];

    constructor() {
        super(readableUUID(BlueprintViewerContentPanelVM.name));
    }

    /**
     * Déclenche le chargement du plan d'étage
     * @param floorId Identifiant du plan à charger
     * @returns retourne le plan d'étage chargé
     */
    async setContent(floorId: number): Promise<FloorBlueprint> {
        if (!this.userInteraction) {
            this.userInteraction = new UserInteraction();
            // Ecoute le changement d'interaction utilisateur sur le plan
            this.listenToCurrentInteractionChanged();
            // Ecoute les changements des calques éditables
            //this.listenToEditableLayersChanged();
            // Ecoute les demandes de changement de type d'accrochage
            this.listenToGripTypeSwitchRequests();
        } 
        const blueprint = await this.userInteraction.setBlueprint(floorId);
        this.toolbar = await BlueprintViewerToolbar.newAsync(this.userInteraction);
        this.emitEventAsync(FloorsEventsEnum.editorToolbarAvailable, this.toolbar);
        //await this.setThemingModel(blueprint);

        return blueprint;
    }

    // async setThemingModel(blueprint: FloorBlueprint): Promise<void> {
    //     if (!this.themingModel) {
    //         this.themingModel = new ThemingModel(blueprint);
    //     }
    //     await this.themingModel.loadOrUpdate();
    // }

    currentInteractionChanged?: (i: LayerInteraction | undefined) => void;
    listenToCurrentInteractionChanged(): void {
        if (this.userInteraction) {
            this.userInteraction.currentInteractionChanged = (i: LayerInteraction | undefined) => {
                if (this.currentInteractionChanged) {
                    this.currentInteractionChanged(i);
                } else {
                    logError("BlueprintViewerContentPanelVM.currentInteractionChanged n'est pas écouté");
                }
            }
        }
    }

    switchGripTypeRequested?: () => void;
    listenToGripTypeSwitchRequests(): void {
        this.userInteraction!.switchGripTypeRequested = () => {
            if (this.switchGripTypeRequested) {
                this.switchGripTypeRequested();
            } else {
                logError("BlueprintViewerContentPanelVM.switchGripTypeRequested n'est pas écouté");
            }
        }
    }

    // editableLayersChanged?: (editableLayers: EditableLayer[]) => void;
    // listenToEditableLayersChanged(): void {
    //     if (this.userInteraction) {
    //         this.userInteraction.editableLayersChanged = (editableLayers: EditableLayer[]) => {
    //             if (this.editableLayersChanged) {
    //                 this.editableLayersChanged(editableLayers);
    //             } else {
    //                 LoggerService.error("BlueprintViewerContentPanelVM.editableLayersChanged n'est pas écouté");
    //             }
    //         }
    //     }
    // }

    /**
     * Déclenche le chargement d'une étude
     * @param task Etude à charger
     * @returns void
     */
    async updateTask(task: FloorTask | null): Promise<void> {
        if (!this.userInteraction) return;
        if (task != null) {
            await this.userInteraction.blueprint.loadBlueprintTask(task);
        } else {
            this.userInteraction.blueprint.removeTopMostTask();
        }
        await this.emitEventAsync(ThemingModel.refreshThemeNeeded, true);
        await this.userInteraction.setEditableLayers();
    }
}