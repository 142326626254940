import { Segment } from 'src/app/core/model/geometry-model/segment.model';
import { XcMaths } from 'src/app/core/model/static-functions/xc-maths';
import { Point } from 'src/app/core/model/geometry-model/point.model';
import { DonutChartSlicePathVM } from './donut-chart-slice-path-vm';

export class DonutChartSliceData {
    id: number;
    name: string;
    value: number;
    color: string;
    path: DonutChartSlicePathVM;
    angle: number;
    startAngle: number;
    rotation: number = 0;
    labelPosition: Point = Point.origin();
    leaderLine: Segment = Segment.null();
    mainLabel: string;
    subLabel: string
    labelMovedPosition: Point = Point.origin();
    labelOffset: number = 0;
    topRadius: number;
    mouseOver: boolean = false;
    leaderLineOpacity: number = 0;

    constructor(id: number, 
        name: string, 
        value: number, 
        color: string, 
        path: DonutChartSlicePathVM, 
        startAngle: number, 
        angle: number, 
        mainLabel: string, 
        subLabel: string,
        topRadius: number = 1) {
        this.id = id;
        this.name = name;
        this.value = value;
        this.color = color;
        this.path = path;
        this.angle = 360 * angle / 100;
        this.startAngle = 360 * startAngle / 100;
        this.topRadius = topRadius;
        this.calculateLeaderLine();
        this.calculateLabelPosition();
        this.mainLabel = mainLabel;
        this.subLabel = subLabel;
    }

    rotate(angle: number): void {
        this.startAngle += angle;
        this.calculateLeaderLine();
        this.calculateLabelPosition();
    }

    calculateLeaderLine(): void {
        const la = this.startAngle + (this.angle / 2);
        const lar = XcMaths.toRad(la);
        const top = this.topRadius * 50;
        const startPoint = new Point(50 + top * Math.cos(lar), 50 + top * Math.sin(lar));
        const x2 = la < 90 || la > 270 ? 110 : -10;
        const endPoint = new Point(x2, startPoint.y);
        this.leaderLine = new Segment(startPoint, endPoint);
    }

    calculateLabelPosition(): void {
        const la = this.startAngle + (this.angle / 2);
        this.labelOffset = la < 90 || la > 270 ? 2 : -2;
        this.labelPosition = new Point(this.labelOffset, this.leaderLine.endPoint.y - 0.75);
        this.labelMovedPosition = this.labelPosition;
    }

    setRotation(): void {
        this.rotation = this.startAngle;
    }

    prepareMoveLabel(yDelta: number): void {
        this.labelMovedPosition = new Point(this.labelPosition.x, this.labelMovedPosition.y + yDelta);
    }

    flushLabelPosition(): void {
        const yDelta = this.labelMovedPosition.y - this.labelPosition.y;
        this.labelPosition = this.labelMovedPosition;
        const llEndPoint = new Point(this.leaderLine.endPoint.x, this.leaderLine.endPoint.y + yDelta);
        this.leaderLine.endPoint = llEndPoint;
        this.leaderLineOpacity = 1;
    }

    sliceClick(): void {
        // TODO : léger déplacement vers l'extérieur ou retour en place
    }

    mouseEnter(e: MouseEvent): void {
        this.mouseOver = true;
    }

    mouseLeave(e: MouseEvent): void {
        this.mouseOver = false;
    }
}