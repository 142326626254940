@if (model) {

    <div id="content-header">
      <span class="header">Cloisons</span>
      <span class="header subheader">Sélection : {{model.selectedWalls == null ? 'aucune' : model.selectedWalls.count}}</span>
    </div>

    <mat-form-field appearance="outline">
        <mat-label>largeur</mat-label>
        <input matInput type="number" step="0.01" id="width" min="0.01" [disabled]="model.sharedStyle?.flWaStId === 5 || !model.isEditable"
                cdkFocusInitial
                required="model.SharedStyle?.id !== 5"
                [(ngModel)]="model.sharedWidth" 
                name="width" 
                #width="ngModel"
                (ngModelChange)="model.onWidthChange($event)">
        <div [hidden]="width.valid || width.pristine">
            La largeur doit être supérieure à zéro
        </div>
    </mat-form-field>

    <mat-form-field appearance="outline">
        <mat-label>Style</mat-label>
        <mat-select id="style"
                [disabled]="!model.isEditable"
                required
                [(ngModel)]="model.sharedStyle" 
                (ngModelChange)='model.onStyleChange($event)' 
                name="style" 
                #wallStyle="ngModel" 
                [compareWith]="model.wallStyleCompare">
            @for (style of model.wallStyles; track style) {
  <mat-option [value]="style">{{style.flWaStName}}</mat-option>
}
        </mat-select>
        <mat-error>Style obligatoire</mat-error>
    </mat-form-field> 
    
    <mat-checkbox [checked]="model.sharedSpaceBound"
        (change)="model.onSpaceBoundChange($event)"
        [disabled]="model.sharedStyle?.flWaStId === 5 || !model.isEditable">
        Séparateur d'espace
    </mat-checkbox>

}
