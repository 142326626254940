import { GeometricElement } from "../../../../../../core/model/geometry-model/geometric-element.model";
import { IntersectionService } from "../../../../../../core/model/geometry-model/intersection-service";
import { Point } from "../../../../../../core/model/geometry-model/point.model";
import { Rectangle } from "../../../../../../core/model/geometry-model/rectangle.model";
import { SvgCircleService } from "../../../../../../core/model/svg-model/svg-circle.service";
import { SvgEllipseService } from "../../../../../../core/model/svg-model/svg-ellipse-service";
import { SvgEntityTypesEnum } from "../../../../../../core/model/svg-model/svg-entity-type-enum";
import { SvgLineService } from "../../../../../../core/model/svg-model/svg-line-service";
import { SvgPathService } from "../../../../../../core/model/svg-model/svg-path-service";
import { DxfSvgCircle } from "./dxf-svg-circle";
import { DxfSvgEllipse } from "./dxf-svg-ellipse";
import { DxfSvgEntity } from "./dxf-svg-entity";
import { DxfSvgLine } from "./dxf-svg-line";
import { DxfSvgPath } from "./dxf-svg-path";
import { SvgEntityPoint } from "../../../shared/gizmos/model/svg-entity-point";
import { SvgEntityPointStyleEnum } from '../../../shared/gizmos/model/svg-entity-point-style-enum';
import { logError } from 'src/app/core/services/logging-service';

export class SvgGeometryService {
    static getGeometry(elements: DxfSvgEntity[]): GeometricElement[] {
        const result: GeometricElement[] = [];

        // NOTA : L'élément SVG rect n'est jamais utilisé dans ecsy
        // Les élements g, text et use n'ont pas d'équivalents géométriques
        // Faut-il pouvoir récupérer les éléments internes à g ?
        elements.forEach(e => {
            switch (e.entityType) {
                case SvgEntityTypesEnum.line:
                    result.push(SvgLineService.geometry(e as DxfSvgLine));
                    break;
                case SvgEntityTypesEnum.circle:
                    result.push(SvgCircleService.geometry(e as DxfSvgCircle));
                    break;
                case SvgEntityTypesEnum.ellipse:
                    result.push(SvgEllipseService.geometry(e as DxfSvgEllipse));
                    break;
                case SvgEntityTypesEnum.path:
                    result.push(...SvgPathService.getPathEntities((e as DxfSvgPath).d!));
                    break;
                case SvgEntityTypesEnum.text:
                    break;
                case SvgEntityTypesEnum.rect:
                    break;
                case SvgEntityTypesEnum.g:
                    break;
                case SvgEntityTypesEnum.use:
                    break;
                default:
                    break;
            }
        });

        return result;
    }

    static getIntersectedElements(elements: DxfSvgEntity[], rectangle: Rectangle): DxfSvgEntity[] {
        const result: DxfSvgEntity[] = [];

        elements.forEach(e => {
            let geometry: GeometricElement[] = [];
            switch (e.entityType) {
                case SvgEntityTypesEnum.line:
                    geometry.push(SvgLineService.geometry(e as DxfSvgLine));
                    break;
                case SvgEntityTypesEnum.circle:
                    geometry.push(SvgCircleService.geometry(e as DxfSvgCircle));
                    break;
                case SvgEntityTypesEnum.ellipse:
                    geometry.push(SvgEllipseService.geometry(e as DxfSvgEllipse));
                    break;
                case SvgEntityTypesEnum.path:
                    geometry.push(...SvgPathService.getPathEntities((e as DxfSvgPath).d!));
                    break;
                case SvgEntityTypesEnum.text:
                    break;
                case SvgEntityTypesEnum.rect:
                    break;
                case SvgEntityTypesEnum.g:
                    break;
                case SvgEntityTypesEnum.use:
                    break;
                default:
                    break;
            }

            const intersects: Point[] = [];
            geometry.forEach(g => {
                intersects.push(...IntersectionService.getRectangleIntersects(g, rectangle));
            });
            if (intersects.length > 0) {
                result.push(e);
            }
        });

        return result;
    }

    static getOrthogonalProjections(elements: DxfSvgEntity[], p: Point): SvgEntityPoint[] {
        const result: SvgEntityPoint[] = [];

        elements.forEach(e => {
            const op = SvgGeometryService.getOrthogonalProjection(e, p);
            if (op) {
                result.push(op);
            }
        });

        return result;
    }

    static getOrthogonalProjection(e: DxfSvgEntity, p: Point): SvgEntityPoint | undefined {
        switch (e.entityType) {
            case SvgEntityTypesEnum.line:
                const s = SvgLineService.geometry(e as DxfSvgLine);
                return SvgEntityPoint.fromPoint(s.getOrthogonalProjection(p), SvgEntityPointStyleEnum.orthogonal);
            case SvgEntityTypesEnum.circle:
                break;
            case SvgEntityTypesEnum.ellipse:
                break;
            case SvgEntityTypesEnum.path:
                break;
            case SvgEntityTypesEnum.text:
                break;
            case SvgEntityTypesEnum.rect:
                break;
            case SvgEntityTypesEnum.g:
                break;
            case SvgEntityTypesEnum.use:
                break;
            default:
                break;
        }

        return undefined;
    }

    static getIntersects(elements: DxfSvgEntity[]): SvgEntityPoint[] {
        const result: SvgEntityPoint[] = [];
        if (elements.length !== 2) {
            logError("Le nombre d'éléments passés à SvgGeometryService.getIntersects est incorrect");
            return result;
        }

        const geometry = SvgGeometryService.getGeometry(elements);
        result.push(...SvgEntityPoint.toStyle(IntersectionService.getEntitiesIntersects(geometry), SvgEntityPointStyleEnum.intersection));

        return result;
    }
}