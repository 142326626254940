import { ApiControllers, ApiEndpoints } from './../api-endpoints';
import { Service } from "typedi";
import { ApiService } from "../api-service";
import { InventoryTreeSet } from '../../model/data-model/sets/inventory-tree-set';

@Service({ global: true })  
export class InventoryService extends ApiService {
  createEndpoint: string = `${ApiControllers.inventory}`;

    constructor() {
        super();
    }

    async loadInventoryView(): Promise<InventoryTreeSet> {
        const result = await this.getAsync<any>(this.endPoint(ApiEndpoints.inventoryView));
          if (result != null) {
            return new InventoryTreeSet(result.payload);
          }
          return new InventoryTreeSet(null);
    }
    
    async createNewInventoryEntry(values: {}): Promise<{} | null> {
      const result = await this.postAsync<{}>(this.endPoint(this.createEndpoint), values);
      if (result != null) {
        return result.payload;
      }
      return null;
    }
}