import { CreateDoorDTO } from './../../../../services/dto/create-door-dto';
import { SvgDOM } from './svg-dom';
import { Container } from 'typedi';
import { DoorFormVM } from './../../../../blueprint-viewer-side-panel/subitems/properties/model/door-form-vm';
import { FloorModelEnum } from "src/app/core/model/data-model/enums/floor-model-enum";
import { Point } from "src/app/core/model/geometry-model/point.model";
import { DoorStyle } from "../../../../shared-model/door-style";
import { BpSvgDoor } from "../../../../svg-entities/model/bp-svg-door";
import { BpSvgWall } from "../../../../svg-entities/model/bp-svg-wall";
import { BlueprintWallsLayer } from "../../../../svg-entities/model/layers/blueprint-walls-layer";
import { DoorUpdateGizmoVM } from "../../../subitems/gizmos/door-update-gizmo/model/door-update-gizmo-vm";
import { FloorBlueprint } from "../floor-blueprint";
import { EditableLayer } from "./editable-layer";
import { EntitiesSelectionSet } from "./entities-selection-set";
import { InteractionCommand } from "./interaction-command";
import { LayerInteraction } from "./layer-interaction";
import { SelectionInteraction } from "./selection-interaction";
import { DoorOpeningDirectionEnum } from '../../../../svg-entities/door-opening-direction-enum';
import { DoorWallSideOrientationEnum } from '../../../../svg-entities/model/door-wall-side-orientation-enum';
import { PartitioningDetection } from './partitioning-detection';
import { BpDoorService } from 'src/app/core/services/backend-services/bp-door-service';

export class DoorInteraction implements LayerInteraction {
    layerId = FloorModelEnum.Doors;
    layerName: string = "NA";
    selectionSet: EntitiesSelectionSet = new EntitiesSelectionSet();
    isEditable: boolean;

    floorBlueprint: FloorBlueprint;
    editableLayers: EditableLayer[] = [];
    currentCommand: InteractionCommand;

    selectionInteraction: SelectionInteraction | undefined;
    selectionCount: number = 0;

    updateGizmo: DoorUpdateGizmoVM;
    propertiesForm: DoorFormVM | undefined;

    planningTaskWallsLayer: BlueprintWallsLayer | null;

    magnetAttraction = 0.25;
    detectedWall: BpSvgWall | undefined;

    constructor(command: InteractionCommand, blueprint: FloorBlueprint, editableLayers: EditableLayer[]) {
        this.floorBlueprint = blueprint;
        this.editableLayers = editableLayers;
        this.currentCommand = command;

        this.isEditable = editableLayers.find(x=> x.layerId === this.layerId) != null;

        const layer = editableLayers.find(x=> x.layerId === this.layerId);
        if (layer) {
            this.layerName = layer.layerName;
        }
        this.planningTaskWallsLayer = this.floorBlueprint.layersController.wallsLayer(this.floorBlueprint.topMostTaskId());

        this.updateGizmo = new DoorUpdateGizmoVM(this.floorBlueprint, this.isEditable);
        this.listenToDoorGizmoEvents();

        this.propertiesForm = new DoorFormVM();
    }
    
    async activate(doors: BpSvgDoor[]): Promise<void> {
        this.selectionSet = new EntitiesSelectionSet(); 
        this.selectionSet.addOrRemove(doors, true);
        
        const isEditable = this.isEditable && !this.selectionSet.hasHeterogenousDataState() && !this.selectionSet.isDataStateDeleted();

        this.updateGizmo?.show({selectedDoor: doors[0], isEditable: isEditable}); 

        // Si le calque est éditable c'est qu'on est dans une étude
        // mais il n'est pas possible de modifier les portes qui sont dans les cloisons périphériques
        
        //await this.propertiesForm.initialize(this.selectionSet);
    }

    deactivate(): void {
        this.selectionSet.clear();
    }

    abort(): void {
        this.endDoorInsertCommand();
        this.updateGizmo?.hide();
    }
    
    beginInsert(style: DoorStyle): void {
        this.currentCommand.set(InteractionCommand.doorInsertionCommand);
        this.updateGizmo?.showTransient(style.shape);
    }

    endInsert(): void {
        this.detectedWall = undefined;
        this.updateGizmo?.hideTransient();
    }

    endDoorInsertCommand(): void {
        this.endInsert();

        // Réactive le curseur de sélection sur les items de l'étude
        const topMostTaskId = this.floorBlueprint.topMostTaskId();
        this.floorBlueprint.layersController.updateCursor(this.editableLayers, topMostTaskId, true);
    }

    selectionDeleted?: () => void;
    raiseSelectionDeleted(): void {
        if (this.selectionDeleted) {
            this.selectionDeleted();
        }
    }

    refreshThemeNeeded?: () => void;
    askForThemerefresh(): void {
        if (this.refreshThemeNeeded) {
            this.refreshThemeNeeded();
        }
    }

    async deleteSelectedItems(): Promise<void> {
        const doorsLayer = this.floorBlueprint.layersController.doorsLayer(this.floorBlueprint.topMostTaskId());
        if (!doorsLayer) return;

        // Si la porte est au planning state 'Added' elle est supprimée du DOM
        // sinon on actualise son statut
        const ids = this.selectionSet.floorDataIds();
        const s = Container.get(BpDoorService);
        const result = await s.deleteDoors(ids);
        if (result != null) {
            (this.selectionSet.items as BpSvgDoor[]).forEach(w => {
                const itemResult = result.find(x=> x.id === w.floorDataId);
                if (itemResult) {
                    if (itemResult.dataStateId == null) {
                        // Masque le gizmo de sélection (et vide la sélection)
                        this.updateGizmo?.hide();
                        // La porte est totalement supprimée du plan
                        doorsLayer.remove(w.floorDataId);
                    } else {
                        // La cloison est marquée avec le statut retourné par le backend
                        doorsLayer.updateDataState(w.floorDataId, itemResult.dataStateId);
                    }
                }
            });

            this.selectionSet.clear();
            this.raiseSelectionDeleted();
        }
    }
    
    listenToDoorGizmoEvents(): void {
        if (!this.updateGizmo) return;
        // Abonnement au MouseDown de la poignée de translation d'une porte
        this.updateGizmo.onDoorTranslationHandleMouseDown = (clientPoint: Point) => {
            // Le user a cliqué sur la poignée de translation libre, on initialise la commande correspondante
            this.initializeDoorTranslation(clientPoint);
        }
        this.updateGizmo.onDoorAngleHandleMouseDown = (clientPoint:Point) => {
            // Le user a cliqué sur la poignée d'angle d'ouverture de porte
            this.initializeDoorAngleChange(clientPoint);
        }
    }

    initializeDoorTranslation(clientPoint: Point): void {
        this.currentCommand.set(InteractionCommand.doorTranslationCommand);
        this.currentCommand.initialClientMousePosition = clientPoint;
        const svgMousePosition = SvgDOM.getPointPosition(clientPoint.x, clientPoint.y);
        this.currentCommand.initialSvgMousePosition = svgMousePosition;
    }

    initializeDoorAngleChange(clientPoint: Point): void {
        const svgMousePosition = SvgDOM.getPointPosition(clientPoint.x, clientPoint.y);
        if (svgMousePosition) {
            this.currentCommand.set(InteractionCommand.doorOpeningAngleCommand);
            this.currentCommand.initialClientMousePosition = clientPoint;
            this.currentCommand.initialSvgMousePosition = svgMousePosition;
            this.updateGizmo?.startAngleUpdate(svgMousePosition);
        }
    }

    moveTransientShape(hitPoint: Point): void {
        if (!this.updateGizmo) return;
        //this.updateGizmo.updateTransientReticle(hitPoint, 0.15);
        this.updateGizmo.moveTransientShape(hitPoint);
    }

    async mouseDown(e: MouseEvent, hitPoint: Point): Promise<boolean> {
        if (this.currentCommand.isDoorInsertionCommand()) {
            if (this.detectedWall && this.updateGizmo?.transientShape) {
                // Fin de la commande
                // le mouseUp n'est pas utilisé
                if (this.currentCommand.magnetAttraction > 0) {
                    const insertionPoint = this.detectedWall.segment().getOrthogonalProjection(hitPoint);
                    const props = new CreateDoorDTO(
                        1,
                         90,
                        this.updateGizmo.transientShape.doorStyle!,
                        DoorOpeningDirectionEnum.Right,
                        DoorWallSideOrientationEnum.Left
                    );
                    const s = Container.get(BpDoorService);
                    const result = await s.createDoor(this.detectedWall.floorDataId, insertionPoint, props);
                    if (result != null) {
                        const newDoor = result.door;
                        const layer = result.layer;
                        if (layer != null) {
                            // Cas où le calque n'existe pas encore sur l'étude
                            this.floorBlueprint.layersController.layers.push(layer);
                        }
            
                        // Ajoute la porte aux datas du calque
                        this.floorBlueprint.layersController.doorsLayer(this.floorBlueprint.topMostTaskId())?.data.push(newDoor);

                        // Selectionne la nouvelle porte pour continuer immédiatement l'implantation
                        await this.activate([newDoor]);
                        //this.selectionSet.addOrRemove([newDoor], true);
                    }
                }
            }
    
            // Termine la commande
            this.currentCommand.clear();
            this.endDoorInsertCommand();
            
            return true;
        }
        return false;
    }

    timer: number | undefined;
    async mouseMove(e: MouseEvent, hitPoint: Point): Promise<void> {
        if (this.currentCommand.isDoorTranslationCommand() && this.currentCommand.initialSvgMousePosition != null) {
            this.updateGizmo?.translate(this.currentCommand.initialSvgMousePosition, hitPoint);
            return;
        }

        if (this.currentCommand.isDoorOpeningAngleCommand() && this.currentCommand.initialSvgMousePosition != null) {
            this.updateGizmo?.updateAngle(hitPoint);
            return;
        }

        if (this.currentCommand.isDoorInsertionCommand()) {
            // Arrête le timer
            clearTimeout(this.timer);
        
            // Le magnetAttraction sert à empêcher tout mouvement tant que la souris ne s'est pas déplacée d'une distance minimale
            // lorsqu'un attracteur est affiché
            // Cela permet à l'utilisateur de bien voir l'attracteur avant de décider ou non de relâcher la souris
            // sans que le moindre minuscule mouvement ne le fasse disparaître ou changer de place
            if (this.currentCommand.tempSvgMousePosition != null &&
                hitPoint.distanceTo(this.currentCommand.tempSvgMousePosition) < this.currentCommand.magnetAttraction) {
                return;
            }
        
            // La souris est sortie du périmètre d'attraction, on masque l'attracteur
            if (this.currentCommand.magnetAttraction > 0) {
                //this.updateGizmo.targetGrips.clear();
                this.currentCommand.magnetAttraction = 0;
            }
        
            // Actualise la position courante de la souris pour le contrôle de l'attraction lors du prochain MouseMove
            this.currentCommand.tempSvgMousePosition = hitPoint;
        
            this.moveTransientShape(hitPoint);
        
            // Redémarre le timer
            this.timer = window.setTimeout(PartitioningDetection.detectTargetWall, 150, hitPoint, this);
        
            return;
        }
    }

    async mouseUp(e: MouseEvent): Promise<void> {
        if (this.currentCommand.isDoorTranslationCommand()) {
            // Fin de la commande de translation de porte
            this.currentCommand.clear();
            await this.updateGizmo?.endTranslate();
            return;
        }

        if (this.currentCommand.isDoorOpeningAngleCommand()) {
            // Fin de la commande de changement de l'angle d'ouverture
            this.currentCommand.clear();
            await this.updateGizmo?.endAngleUpdate();
            return;
        }
    }

    async keyDown(e: KeyboardEvent): Promise<void> {
        
    }
}
