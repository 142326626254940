import { AppMenuRouteEnum } from "src/app/core/model/data-model/enums/app-menu-route-enum";
import { PageModel } from "src/app/ui/main/model/page-model";

export class MaintenanceBrowserVM extends PageModel {
    private constructor() {
        super(AppMenuRouteEnum.planning_maintenance, 0, "cd610a56-368f-44c0-ac25-63403fcf811f");
    }

    static async newAsync(): Promise<MaintenanceBrowserVM> {
        const result = new MaintenanceBrowserVM();

        return result;
    }
}