import { DateUtils } from "src/app/core/model/static-functions/date-utils";
import { GanttScaleEnum } from "../../../../move-work/nested/scheduling/model/gantt-scale-enum";
import { ScheduleHeader } from "./schedule-header";
import { EventListener } from "src/app/core/events/event-listener";
import { readableUUID } from "src/app/core/events/event-listener-uuid";
import { ScheduleEventsEnum } from "./schedule-events-enum";

export abstract class ScheduleBase extends EventListener {
    startDate: Date;
    duration: number;
    firstWeekDays: number = 7;
    lastWeekDays: number = 7;

    weeksDurations: number[] = [];
    monthsDurations: number[] = [];
    quartersDurations: number[] = [];
    halfsDurations: number[] = [];
    yearsDurations: number[] = [];

    cols: Date[] = [];
    header: ScheduleHeader;

    lineStroke: string = "grey";
    lineStrokeWidth: number = 0.01;
    halfMinWidth: number = 0.04; // 0.02;
    quarterMinWidth: number = 0.1; // 0.05;
    monthMinWidth: number = 0.3; // 0.15;
    weekMinWidth: number = 0.6; // 0.3;
    dayMinWidth: number = 3; // 1.5;
    dayMaxWidth: number = 8; // 4;
    dayWidth: number = 5; // 2.5;

    zoom: number = 1;
    currentUnitWidth: number = this.dayWidth;
    //currentXOffset: number = 0;

    gridScale: number = 1;
    scale: GanttScaleEnum = GanttScaleEnum.day;

    constructor(startDate: Date, duration: number) {
        super(readableUUID("Schedule"));
        this.startDate = startDate;
        this.duration = duration;

        this.header = new ScheduleHeader(startDate, duration);

        this.calculateGrid(0);
    }

    calculateDurations(): void {
        // Le calendrier ne comprend pas nécessairement des semaines ou des mois complets en début ou en fin
        const firstWeekday = this.startDate.getDay();
        this.firstWeekDays = firstWeekday === 0 ? 1 : 7 - firstWeekday + 1;
        let firstCompleteWeekMonday = this.startDate;
        // La semaine commence le dimanche = 0 => samedi = 6
        if (firstWeekday !== 1) {
            firstCompleteWeekMonday = DateUtils.addDays(this.startDate, 7 + 1 - firstWeekday);
        }
        const endDate =  DateUtils.addDays(this.startDate, this.duration);
        const lastWeekday = endDate.getDay();
        this.lastWeekDays = lastWeekday === 0 ? 7 : lastWeekday;
        let lastCompleteWeekSunday = endDate;
        if (lastWeekday > 0) {
            lastCompleteWeekSunday = DateUtils.addDays(endDate, -lastWeekday);
        }
    }

    setScale(zoomDelta: number): void {
        const zTmp = this.zoom + zoomDelta;
        const zw = zTmp * this.currentUnitWidth;
        switch (this.scale) {
            case GanttScaleEnum.day:
                if (!(zw > this.dayMinWidth && zw < this.dayMaxWidth)) {
                    if (zw < this.dayMinWidth) {
                        this.scale = GanttScaleEnum.week;
                    } 
                }
                break;
            case GanttScaleEnum.week:
                if (zw > this.dayMinWidth) {
                    this.scale = GanttScaleEnum.day;
                } else {
                    if (zw < this.weekMinWidth) {
                        this.scale = GanttScaleEnum.month;
                    }
                }
                break;
            case GanttScaleEnum.month:
                if (zw > this.weekMinWidth) {
                    this.scale = GanttScaleEnum.week;
                } else {
                    if (zw < this.monthMinWidth) {
                        this.scale = GanttScaleEnum.quarter;
                    }
                }
                break;
            case GanttScaleEnum.quarter:
                if (zw > this.monthMinWidth) {
                    this.scale = GanttScaleEnum.month;
                } else {
                    if (zw < this.quarterMinWidth) {
                        this.scale = GanttScaleEnum.half;
                    }
                }
                break;
            case GanttScaleEnum.half:
                if (zw > this.quarterMinWidth) {
                    this.scale = GanttScaleEnum.quarter;
                }
                break;
            case GanttScaleEnum.year:
                break;
           default:
                break;
        }

        this.currentUnitWidth = Math.min(zw , this.dayMaxWidth);
    }

    calculateGrid(zoomDelta: number): void {
        this.setScale(zoomDelta);
        this.header.calculateHeader(this.currentUnitWidth, this.scale);

        // Création d'une div par jour
        this.cols.splice(0);
        for (let i = 0; i < this.duration; i++) {
            const date = DateUtils.addDays(this.startDate, i);
            this.cols.push(date);
        }
    }

    // viewScrolled(delta: number): void {
    //     this.currentXOffset += delta;
    //     if (this.currentXOffset < 0) this.currentXOffset = 0;
    // }
}