import { SiteAnatomyDbView } from '../../db-model/views/site-anatomy-db-view';
import { DefinedModelBase } from '../defined-model-base';

export class SiteAnatomyView extends DefinedModelBase {
    buSiteId!: number;
    roLaTyId!: number;
    roLaTyCode!: string;
    roLaTyName!: string;
    roLaTyColor!: string;
    roLaTyIsNUA!: boolean;
    roLaTyParentId!: number;
    stLaViArea!: number;
    stLaViRoundedArea!: number;
    stLaViLayoutTypeTree!: string;

    constructor(dtoData: any) {
        super(dtoData, [SiteAnatomyDbView]);
    }
}