import { SliceAnonymousData } from "../../../shared-model/slice-anonymous-data";
import { HbarBarItem } from "./hbar-bar-item";

export class HbarChartDataVM {
    title: string = "";
    subTitle: string = "";
    labelsFontSize: number = 3;
    bars: HbarBarItem[] = [];
    barHeight: number = 20;
    width: number = 200;
    height: number = 100;
    paddingLeft: number = 5;
    vLabelsWidth: number = 50;
    vLabelsFontSize: number = 5;
    viewBox: string = "";
    fill: string = "#ffffff";
    fillOpacity: number = 1;
    axesStroke: string = "lightgrey";
    axesStrokeWidth: number = 0.1;
    xAxisOffset: number = 4
    gutter: number = 0.2;
    hLabels: string[] = [];

    constructor(title: string, subTitle: string, fontSize: number = 3) {
        this.title = title;
        this.subTitle = subTitle;
        this.labelsFontSize = fontSize;
    }

    setSize(width: number, height: number, barHeight: number, hLabels: string[]): void {
        this.width = width;
        this.height = height;
        this.barHeight = barHeight;
        this.hLabels = hLabels;
        this.viewBox = `0, 0, ${width}, ${height}`;
        this.vLabelsFontSize = Math.max(2, barHeight / 5);
    }

    loadSeries(data: SliceAnonymousData[]): void {
        const bars: HbarBarItem[] = [];

        let level = 0;
        data.forEach(d => {
            const b = new HbarBarItem(d.id, d.name, level, d.value, d.color, 0);
            let step = 0;
            d.children.forEach(c => {
                b.children.push(new HbarBarItem(c.id, c.name, 0, c.value, c.color, step));
                step += c.value;
            });
            bars.push(b);
            level += 1;
        });

        this.bars = bars;
    }

    arrange(): void {
        this.bars.forEach(b => {
            b.scaleX = 1;
        });
    }

}