import { Point } from "src/app/core/model/geometry-model/point.model";
import { GanttSvgTaskLinkBase } from "./gantt-svg-task-link-base";
import { Vector } from "src/app/core/model/geometry-model/vector.model";
import { PathBuilder } from "src/app/core/model/svg-model/svg-path-builder";
import { GanttSvgTaskVM } from "./gantt-svg-task-vm";

export class GanttSvgTaskLinkTransient extends GanttSvgTaskLinkBase {
    isStartPointMoving: boolean = false;
    transientFirstPoint: Point | undefined;
    transientLastPoint: Point | undefined;
    transientSourceTask: GanttSvgTaskVM;
    firstHandle: Point = Point.origin();
    lastHandle: Point = Point.origin();
    targetHandlePos: number | undefined;


    constructor(t: GanttSvgTaskVM, initialMousePosition: Point, startLink: boolean) {
        super();
        this.transientSourceTask = t;
        this.firstPoint = initialMousePosition;
        this.lastPoint = initialMousePosition;
        this.isStartPointMoving = startLink;
    }

    // isLinkable(newTask: GanttSvgTaskVM, toStart: boolean): boolean {
    //     // end to end implique que les deux tâches aient la même date de fin
    //     // start to start implique que les deux tâches aient la même date de début
    //     // end to start implique que la date de début de la tâche cible soit égale ou supérieur la la date de fin de la tâche source
    //     // start to end ne semble pas avoir vraiment de sens et ne devrait pas se produire

    //     if (newTask.task.taId === this.sourceTask.task.taId && !this.isStartPointMoving) return false;
    //     if (newTask.task.taId === this.targetTask.task.taId && this.isStartPointMoving) return false;

    //     switch (this.taskLink.taLiLinkType) {
    //         case TaskLinkTypeEnum.EndToEnd:
    //             if (!this.isStartPointMoving) {
    //                 if (toStart) {
    //                     return this.sourceTask.endDate() <= newTask.task.taStartDate;
    //                 } else {
    //                     return this.sourceTask.endDate().getTime() === newTask.endDate().getTime();
    //                 }
    //             } else {
    //                 if (toStart) {
    //                     return false;
    //                 } else {
    //                     return this.targetTask.endDate().getTime() === newTask.endDate().getTime();
    //                 }
    //             }
    //         case TaskLinkTypeEnum.StartToStart:
    //             if (this.isStartPointMoving) {
    //                 if (!toStart) {
    //                     return this.targetTask.task.taStartDate >= newTask.endDate();
    //                 } else {
    //                     return this.targetTask.task.taStartDate.getTime() === newTask.task.taStartDate.getTime();
    //                 }
    //             } else {
    //                 if (toStart) {
    //                     return this.sourceTask.task.taStartDate.getTime() === newTask.task.taStartDate.getTime();
    //                 } else {
    //                     return false;
    //                 }
    //             }
    //         case TaskLinkTypeEnum.EndToStart:
    //             if (this.isStartPointMoving) {
    //                 if (toStart) {
    //                     return this.targetTask.task.taStartDate.getTime() === newTask.task.taStartDate.getTime();
    //                 } else {
    //                     return this.targetTask.task.taStartDate >= newTask.endDate();
    //                 }
    //             } else {
    //                 if (toStart) {
    //                     return this.sourceTask.endDate() <= newTask.task.taStartDate;
    //                 } else {
    //                     return this.sourceTask.endDate().getTime() === newTask.endDate().getTime();
    //                 }
    //             }
    //         default:
    //             return false;
    //     }
    // }

    move(p: Point): void {
        this.lastPoint = p;
        this.setHandles();
        this.pathData = PathBuilder.getBezierPath(this.firstPoint, this.firstHandle, this.lastHandle, this.lastPoint);
    }

    moveTransientStart(delta: Vector): void {
        this.isStartPointMoving = true;
        this.firstPoint = this.transientFirstPoint!.translate(delta);
        this.setHandles();
        this.pathData = PathBuilder.getBezierPath(this.firstPoint, this.firstHandle, this.lastHandle, this.lastPoint);
    }

    moveTransientEnd(delta: Vector): void {
        this.isStartPointMoving = false;
        this.lastPoint = this.transientLastPoint!.translate(delta);
        this.setHandles();
        this.pathData = PathBuilder.getBezierPath(this.firstPoint, this.firstHandle, this.lastHandle, this.lastPoint);
    }


    setHandles(): void {
        if (this.isStartPointMoving) {
            this.firstHandle = new Point(this.firstPoint.x - 50, this.firstPoint.y);
            this.lastHandle = new Point(this.firstPoint.x - 50, this.lastPoint.y);
        } else {
            this.firstHandle = new Point(Math.max(this.firstPoint.x + 50, this.lastPoint.x), this.firstPoint.y);
            this.lastHandle = new Point(this.lastPoint.x > this.firstPoint.x + 50 ? this.firstPoint.x : this.firstPoint.x + 50, this.lastPoint.y);
        }
    }
}