export const BuildingAnatomyDbView = {
    databaseTableName: "building_anatomy_view",
    buSiteId: "Bu_SiteId", 
    flBuildingId: "Fl_BuildingId",
    roLaTyId: "RoLaTy_Id",
    roLaTyCode: "RoLaTy_Code",
    roLaTyName: "RoLaTy_Name",
    roLaTyColor: "RoLaTy_Color",
    roLaTyIsNUA: "RoLaTy_IsNUA",
    roLaTyParentId: "RoLaTy_ParentId",
    buLaViArea: "BuLaVi_Area",
    buLaViRoundedArea: "BuLaVi_RoundedArea",
    buLaViLayoutTypeTree: "BuLaVi_LayoutTypeTree"
}