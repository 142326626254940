@if (model) {
<div id="layersPanel">
    <!-- <mat-form-field appearance="outline" class="drop-down">
        <mat-label>Imports</mat-label>
        <mat-select [(ngModel)]="model.selectedImport" (selectionChange)="model.onSelectedImportChange()">
            <mat-option [value]="i" *ngFor="let i of model.imports">{{i.dxImFileName}}</mat-option>
        </mat-select>
    </mat-form-field>

    <button mat-button id="importButton"
        aria-label="Importer un DXF"
        matTooltip='Importer un DXF'
        (click)='model.onImportButtonClick()'>
        Importer un fichier DXF
    </button>

    <mat-divider></mat-divider> -->

    <div id="layersHeader">
        <span id="LayersHeaderLabel">Calques</span>
        <div id="layersHeaderCommands">
            <span class="layer-icon material-icons-outlined"
            (click)="model.globalLayersDisplay()">visibility</span>
            <span class="layer-icon material-icons-outlined"
            (click)="model.globalLayersUnlock()">lock</span>
        </div>
    </div>
    <mat-list>
        @for (l of model.layers; track l) {
  <mat-list-item class="layer"
            [ngClass]="{'current-layer': l.current}"
            [style.display]="l.entities.length === 0 ? 'none' : 'block'"
            (mouseenter)="l.mouseEnter()"
            (mouseleave)="l.mouseLeave()">
            <div>
                <span class="layer-name"
                (click)="model.layerClick(l)">{{l.dxLaName + ' (' + l.entities.length + ')'}}</span>
                <span class="layer-icon material-icons-outlined"
                (click)="model.switchLayerVisibility(l)">{{!l.dxLaHidden ? 'visibility' : 'visibility_off'}}</span>
                <span class="layer-icon material-icons-outlined"
                (click)="model.switchLayerLock(l)">{{!l.dxLaLocked ? 'lock_open' : 'lock'}}</span>
                <span class="layer-icon material-icons-outlined"
                (click)="model.focusSingleLayer(l)">center_focus_strong</span>
            </div>
        </mat-list-item>
}
    </mat-list>
      
</div>
}


