import { GripPath } from "./grip-path";

export class Grip extends GripPath {
    index: number = 0;
    parentId: string | undefined;
    isSelected: boolean = false;
    static defaultColor: string = "orange";
    static selectedColor: string = "green";

    constructor(data: any, radius: number, parentId?: string) {
        super(data, radius);
        this.parentId = parentId;
    }

    select(selected: boolean): void {
        this.isSelected = selected;
    }
}