import { DynTableVMBuilder } from "src/app/components-lib/dyn-grid/model/dyn-table-vm-builder";
import { TabPageItem } from "src/app/components-lib/tab-page-container/model/tab-page-item";
import { TabPager } from "src/app/components-lib/tab-page-container/model/tab-pager";
import { readableUUID } from "src/app/core/events/event-listener-uuid";
import { DyntService } from "src/app/core/services/backend-services/dynt-service";
import { PaginationService } from "src/app/core/services/backend-services/pagination-service";
import Container from "typedi";
import { StoresTabEnum } from "./stores-tab-enum";
import { ITabPageContent } from "src/app/components-lib/tab-page-container/model/i-tab-page-content";
import { TableReportWrapper } from "src/app/ui/shared/charts/table/table-report-wrapper";
import { StoresDashboardVM } from "./stores-dashboard-vm";
import { Address } from "src/app/core/model/data-model/tables/address";
import { TablesNamesEnum } from "src/app/core/model/db-model/tables-names-enum";
import { AddressTable } from "src/app/core/model/db-model/tables/address-table";
import { FieldListItem } from "src/app/components-lib/shared-model/field-list-item";
import { EquipmentStorageArea } from "src/app/core/model/data-model/tables/equipment-storage-area";
import { ListField } from "src/app/components-lib/shared-model/list-field";
import { EquipmentStorageAreaTable } from "src/app/core/model/db-model/tables/equipment-storage-area-table";

export class StoresManagePager extends TabPager {
    tableName: string = "equipment_storage_area_dynview";
    storesTabEnum = StoresTabEnum;

    private constructor() {
        super(readableUUID("StoresManageVM"));
    }

    static async newAsync(): Promise<StoresManagePager> {
        const result = new StoresManagePager();

        await result.initialize();
        
        return result;
    }
    
    async initialize(): Promise<void> {
        this.prepareTabs();
        await this.selectTabPage(0);
    }

    prepareTabs(): void {
        this.tabs.push(new TabPageItem(StoresTabEnum.list, "Emplacements"));
        this.tabs.push(new TabPageItem(StoresTabEnum.dashboard, "Suivi"));
    }

    override async loadContent(): Promise<ITabPageContent | undefined> {
        if (!this.selectedTab) return;

        switch (this.selectedTab.id) {
            case StoresTabEnum.list:
                return await this.loadAdminData();
            case StoresTabEnum.dashboard:
                const s = Container.get(DyntService);
                const stores = await s.downloadTable<EquipmentStorageArea>(TablesNamesEnum.EquipmentStorageArea);
                return new StoresDashboardVM(stores);
            default:
                break;
        }

        return undefined;
    }

    async loadAdminData(): Promise<TableReportWrapper> {
        const p = Container.get(PaginationService);
        const paginator = await p.getPaginator(this.tableName);
        const s = Container.get(DyntService);
        const viewSet = await s.contextedDataSet(this.tableName, 0, undefined, undefined, paginator?.pageItemsCount);
        const adminTable = DynTableVMBuilder.getVM(viewSet, this.tableName, paginator);
        adminTable.documentName = "StorageAreas";
        adminTable.documentTitle = "Emplacements de stockage";

        adminTable.loadOptionsRequested = async (f: ListField, rowId: number) => {
            if (f.colId === EquipmentStorageAreaTable.eqStArAddressId) {
                const s = Container.get(DyntService);
                const addresses = await s.downloadTable<Address>(AddressTable.databaseTableName);
                const result = [new FieldListItem(null, "NR", null)];
                result.push(...addresses.map(x=> new FieldListItem(x.adId, x.adName, null)));
                return result;
            }
            return [];
        }

        const result = new TableReportWrapper();
        result.set(adminTable);
        return result;
    }
}