import { FloorDataImportLogTable } from '../../db-model/tables/floor-data-import-log-table';
import { DefinedModelBase } from '../defined-model-base';

export class FloorDataImportLog extends DefinedModelBase {
    flDaImLoId!: number;
    flDaImLoImportItemId!: number | null;
    flDaImLoText!: string;
    flDaImLoRequestId!: string;

    constructor(dtoData: any) {
        super(dtoData, [FloorDataImportLogTable]);
    }
}