import { BoardToolbarCommandEnum } from "./board-toolbar-command-enum";
import { MatButtonToggleChange } from "@angular/material/button-toggle";
import { BoardToolbarCommand } from "./board-toolbar-command";
import { BoardToolbarCommands } from "./board-toolbar-commands";
import { GripsOptions } from "../../../../shared/drawing/grips-options";
import { InsertOptions } from "../commands/model/insert-options";
import { logError } from "src/app/core/services/logging-service";

export class BoardToolbarVM {
    menuCommands: BoardToolbarCommands;
    selectedMenuCommand: BoardToolbarCommand | undefined;
    MenuCommandEnum = BoardToolbarCommandEnum;
    gripsOptions: GripsOptions;
    insertOptions: InsertOptions;

    constructor() {
        this.menuCommands = new BoardToolbarCommands();
        this.gripsOptions = new GripsOptions();
        this.insertOptions = new InsertOptions();
    }

    setCommand(command: BoardToolbarCommandEnum): void {
        this.selectedMenuCommand = this.menuCommands.items.find(x=> x.id === command);
    }

    selectedMenuChanged?: (selectedCommand: BoardToolbarCommandEnum) => void;
    selectedMenuCommandClick(e: MatButtonToggleChange): void {
        if (this.selectedMenuChanged) {
            this.selectedMenuCommand = this.menuCommands.items.find(x=> x.id === e.value);
            this.selectedMenuChanged(e.value);
        } else {
            logError("BoardToolbarVM.selectedMenuChanged n'est pas écouté");
        }
    }
}