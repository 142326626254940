import { RoomLayoutTypeTable } from './../../../model/db-model/tables/room-layout-type-table';
import { RoomLayoutType } from 'src/app/core/model/data-model/tables/room-layout-type';
import { DisplayThemeDbView } from './../../../model/db-model/views/display-theme-db-view';
import { DisplayThemeCategoryDbView } from './../../../model/db-model/views/display-theme-category-db-view';
import { DisplayThemeItemViewSet } from "src/app/core/model/data-model/view-set/display-theme-item-view-set";
import { DisplayThemeView } from "src/app/core/model/data-model/views/display-theme-view";
import { DisplayThemeCategorySet } from 'src/app/ui/pages/layout/real-estate/floor-blueprint/content/blueprint-viewer-side-panel/subitems/theming/model/display-theme-category-set';

export class ThemingDTO {
    displayThemeCategories: DisplayThemeCategorySet[] = [];
    displayThemes: DisplayThemeView[] = [];
    displayThemeItems: DisplayThemeItemViewSet[] = [];
    roomLayoutTypes: RoomLayoutType[] = [];
    
    constructor(dtoData: any) {
        if (dtoData[DisplayThemeCategoryDbView.databaseTableName]) {
            dtoData[DisplayThemeCategoryDbView.databaseTableName].forEach((c: any) => {
                this.displayThemeCategories.push(new DisplayThemeCategorySet(c));
            });
        }
        if (dtoData[DisplayThemeDbView.databaseTableName]) {
            dtoData[DisplayThemeDbView.databaseTableName].forEach((t: any) => {
                this.displayThemes.push(new DisplayThemeView(t));
            });
        }
        if (dtoData[DisplayThemeItemViewSet.databaseTableName]) {
            dtoData[DisplayThemeItemViewSet.databaseTableName].forEach((i: any) => {
                this.displayThemeItems.push(new DisplayThemeItemViewSet(i));
            });
        }
        if (dtoData[RoomLayoutTypeTable.databaseTableName]) {
            dtoData[RoomLayoutTypeTable.databaseTableName].forEach((r: any) => {
                this.roomLayoutTypes.push(new RoomLayoutType(r));
            });
        }
    }
}