import { Component, Input } from '@angular/core';
import { MVVMEventDestroyLifeCycle } from 'src/app/components-lib/shared-model/mvvm-events-destroy-lifecycle';
import { LeaseContractors } from '../../model/lease_contractors';
import { DynTableComponent } from 'src/app/components-lib/dyn-table/dyn-table.component';

@Component({
  selector: 'xc-lease-contractors',
  standalone: true,
  imports: [DynTableComponent],
  templateUrl: './lease-contractors.component.html',
  styleUrl: './lease-contractors.component.scss'
})
export class LeaseContractorsComponent extends MVVMEventDestroyLifeCycle {
  @Input() override model: LeaseContractors | undefined;
}
