export class StackAnonymousUnitData {
    rowId: number;
    id: number;
    label: string;
    value: number; 
    color: string;

    constructor(rowId: number, id: number, label: string, value: number, color: string) {
        this.rowId = rowId;
        this.id = id;
        this.label = label;
        this.color = color;
        this.value = value;
    }
}