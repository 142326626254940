import { TabPageItem } from "./tab-page-item";
import { ITabPageContent } from "./i-tab-page-content";
import { EventListener } from "src/app/core/events/event-listener";

export abstract class TabPager extends EventListener {
    tabs: TabPageItem[] = [];
    selectedTab: TabPageItem | undefined;

    constructor(uuid: string) {
        super(uuid);
    }

    async loadContent(): Promise<ITabPageContent | undefined> {
        return undefined;
    }

    async selectTabPage(index: number): Promise<void> {
        if (index >= 0 && index < this.tabs.length) {
            this.selectedTab = this.tabs[index];
            await this.setSelectedContent();
        } else {
            console.log("Tab index out of range");
        }
    }

    async setSelectedContent(): Promise<void> {
        if (!this.selectedTab) return;
        this.selectedTab.content = await this.loadContent();
    }

    async selectedTabChange(e: any): Promise<void> {
        await this.selectTabPage(e.index);
    }
}