import { DynTableRow } from "src/app/components-lib/dyn-grid/model/dyn-table-row";
import { DynTableVM } from "src/app/components-lib/dyn-grid/model/dyn-table-vm";
import { DynTableVMBuilder } from "src/app/components-lib/dyn-grid/model/dyn-table-vm-builder";
import { FieldListItem } from "src/app/components-lib/shared-model/field-list-item";
import { ListField } from "src/app/components-lib/shared-model/list-field";
import { Task } from "src/app/core/model/data-model/tables/task";
import { TablesNamesEnum } from "src/app/core/model/db-model/tables-names-enum";
import { EquipmentPurchaseTable } from "src/app/core/model/db-model/tables/equipment-purchase-table";
import { TaskTable } from "src/app/core/model/db-model/tables/task-table";
import { DbModelUtils } from "src/app/core/model/static-functions/db-model-utils";
import { ApiEndpoints } from "src/app/core/services/api-endpoints";
import { DyntService } from "src/app/core/services/backend-services/dynt-service";
import { PaginationService } from "src/app/core/services/backend-services/pagination-service";
import { PurchasesService } from "src/app/core/services/backend-services/purchases-service";
import { UploadService } from "src/app/core/services/backend-services/upload-service";
import Container from "typedi";
import { EquipmentPurchaseDetailTable } from "src/app/core/model/db-model/tables/equipment-purchase-detail-table";
import { EquipmentCatalogTable } from "src/app/core/model/db-model/tables/equipment-catalog-table";
import { EquipmentCategoryView } from "src/app/core/model/data-model/views/equipment-category-view";
import { ViewsNames } from "src/app/core/model/db-model/views-names-enum";
import { EquipmentCategoryTable } from "src/app/core/model/db-model/tables/equipment-category-table";
import { EquipmentCatalog } from "src/app/core/model/data-model/tables/equipment-catalog";
import { PurchasesManageVM } from "./purchases-manage-vm";
import { EventListener } from "src/app/core/events/event-listener";
import { readableUUID } from "src/app/core/events/event-listener-uuid";
import { PurchaseEventsEnum } from "./purchase-events-enum";
import { DateField } from "src/app/components-lib/shared-model/date-field";
import { PageModel } from "src/app/ui/main/model/page-model";
import { AppMenuRouteEnum } from "src/app/core/model/data-model/enums/app-menu-route-enum";

export class PurchasesListVM extends PageModel {
    tableName: string = "equipment_purchase_dynview";
    mainTable: DynTableVM | undefined;
    detailsTable: DynTableVM | undefined;
    manage: PurchasesManageVM | undefined;
    selectedRow:DynTableRow | undefined;

    private constructor() {
        super(AppMenuRouteEnum.layout_inventory_purchases, 0, readableUUID("PurchasesListVM"));

        this.addEventListener(PurchaseEventsEnum.receiptConfirmed, (purchaseId: number, receiptDate: Date) => {
            if (this.selectedRow && this.selectedRow.rowId === purchaseId) {
                this.selectedRow.field(EquipmentPurchaseTable.eqPuDeliveryDate)!.value = receiptDate;
                this.selectedRow.field(EquipmentPurchaseTable.eqPuDeliveryDate)!.initialValue = receiptDate;
            }
        });
    }

    static async newAsync(): Promise< PurchasesListVM> {
        const result = new PurchasesListVM();

        await result.loadMainTable();

        return result;
    }

    async loadMainTable(): Promise<void> {
        const p = Container.get(PaginationService);
        const paginator = await p.getPaginator(this.tableName);
        const s = Container.get(DyntService);
        const viewSet = await s.contextedDataSet(this.tableName, 0, undefined, undefined, paginator?.pageItemsCount);
        const adminTable = DynTableVMBuilder.getVM(viewSet, this.tableName, paginator);

        adminTable.withSelectableRows = true;

        // TODO : les valeurs courantes des options doivent se trouver dans la dynview en non-affichable
        // ici on constitue les FieldListItem

        // Chargement des valeurs actuelles pour les tâches
        const taskIds = viewSet.viewData.map(x=> (x as any)[EquipmentPurchaseTable.eqPuTaskId]);
        const tasks = await s.downloadTable<Task>(TablesNamesEnum.Task, undefined, TaskTable.taId, taskIds);
        adminTable.setCurrentSelectOption(EquipmentPurchaseTable.eqPuTaskId, DbModelUtils.key(TaskTable, TaskTable.taId), DbModelUtils.key(TaskTable, TaskTable.taName), tasks);

        adminTable.loadOptionsRequested = async (f: ListField, rowId: number) => {
            if (f.colId === EquipmentPurchaseTable.eqPuTaskId) {
                // Sélectionne les tâches de commande actives qui ne sont pas déjà associées à une commande
                const p = Container.get(PurchasesService);
                const tasks = await p.loadTasks(rowId);
                return tasks.map(x=> new FieldListItem(x.taId, x.taName, null));
            }
            return [];
        }

        adminTable.selectedRowChanged = async (row: DynTableRow) => {
            this.selectedRow = row;
            const receiptDate = (row.field(EquipmentPurchaseTable.eqPuDeliveryDate) as DateField)!.value;
            if (!this.manage) {
                this.manage = new PurchasesManageVM();
                await this.manage.initialize(row.rowId, receiptDate !== null);
            } else {
                await this.manage.refresh(row.rowId, receiptDate !== null);
            }
            //await this.loadDetailsTable(row.rowId);
        }

        adminTable.deletionRequested = async (tableName: string, rowId: any): Promise<boolean> =>  {
            let result: boolean = false;
    
            return result;
        }

        this.mainTable = adminTable;
    }

    async loadDetailsTable(purchaseId: number): Promise<void> {
        const detailsTable = "equipment_purchase_detail_dynview";
        const p = Container.get(PaginationService);
        const paginator = await p.getPaginator(detailsTable);
        const s = Container.get(DyntService);
        const viewSet = await s.contextedDataSet(detailsTable, 0, EquipmentPurchaseDetailTable.eqPuDePurchaseId, purchaseId, paginator?.pageItemsCount);
        const table = DynTableVMBuilder.getVM(viewSet, detailsTable, paginator);
        
        // Chargement des valeurs actuelles        
        table.setCurrentSelectOption(EquipmentPurchaseDetailTable.eqPuDeEquipmentCategoryId, "CategoryId", "CategoryName");
        table.setCurrentSelectOption(EquipmentPurchaseDetailTable.eqPuDeEquipmentCatalogId, "CatalogId", "CatalogName");

        table.loadOptionsRequested = async (f: ListField, rowId: number) => {
            const p = Container.get(DyntService);
            if (f.colId === EquipmentPurchaseDetailTable.eqPuDeEquipmentCategoryId) {
                // Sélectionne les catégories d'équipement
                const cats = await p.downloadTable<EquipmentCategoryView>(ViewsNames.EquipmentCategoryView, undefined, EquipmentCategoryTable.eqCaDepth, 3);
                return cats.sort((a, b) => a.eqCaFlatTreeDisplayName.localeCompare(b.eqCaFlatTreeDisplayName)).map(x=> new FieldListItem(x.eqCaId, x.eqCaFlatTreeDisplayName, null));
            }

            // Sélectionne les références d'équipements
            const row = table.dataSource.find(x=> x.rowId === rowId);
            if (row) {
                const catField = row.fields.find(x=> x.colId === EquipmentPurchaseDetailTable.eqPuDeEquipmentCategoryId);
                if (catField) {
                    const refs = await p.downloadTable<EquipmentCatalog>(TablesNamesEnum.EquipmentCatalog, undefined, EquipmentCatalogTable.eqCgCategoryId, catField.value);
                    return refs.map(x=> new FieldListItem(x.eqCgId, x.eqCgName, null));
                }
            }

            return [];
        }

        table.imageUploadRequested = async (file: File, inserting: boolean, maxSize: number, rowId: number): Promise<string> => {
            const s = Container.get(UploadService);
            if (inserting) {
                return await s.uploadTempFile(file, `${ApiEndpoints.endPoint(ApiEndpoints.fsToAirlock)}`, maxSize);
            } else {
                return await s.uploadUpdateFile(file, `${ApiEndpoints.endPoint(ApiEndpoints.fsProviderLogo)}`, rowId);
            }
        }

        table.newRowSaveRequested = async (values : any): Promise<any> => {
            let result: any;
            values[EquipmentPurchaseDetailTable.eqPuDePurchaseId] = this.mainTable?.selectedRow?.rowId;
            const t = Container.get(DyntService);
            result = await t.post<any>("", values);
            return result;
        }

        table.deletionRequested = async (tableName: string, rowId: any): Promise<boolean> =>  {
            let result: boolean = false;
    
            return result;
        }

        this.detailsTable = table;
    }
}