import { MatButtonToggleChange } from "@angular/material/button-toggle";
import { DrawingCommandEnum } from "./commands/drawing-command-enum";
import { DrawingCommands } from "./commands/drawing-commands";
import { GripStyleCommands } from "./commands/grip-style-commands";
import { ToolCommandEnum } from './commands/tool-command-enum';
import { ToolsCommands } from "./commands/tool-commands";
import { ToolGridOptionsCommands } from "./commands/tool-grid-options-commands";
import { ToolGridOptionsEnum } from "./commands/tool-grid-options-enum";
import { SvgEntityPointStyleEnum } from "../../shared/gizmos/model/svg-entity-point-style-enum";
import { logError } from "src/app/core/services/logging-service";

export class DrawingToolbar {
    drawingCommands: DrawingCommands;
    selectedCommandId: DrawingCommandEnum = DrawingCommandEnum.none;
    MenuCommandEnum = DrawingCommandEnum;
    gripStylesCommands: GripStyleCommands;
    selectedGripStyleId: SvgEntityPointStyleEnum = SvgEntityPointStyleEnum.none;
    toolsCommands: ToolsCommands;
    extension: boolean = false;
    gridOptionsCommands: ToolGridOptionsCommands;
    selectedGridOptionId: ToolGridOptionsEnum = ToolGridOptionsEnum.none;
    optionsLeft: number = 0;
    gridUnitLength: number = 1;
    gridUnitLengthVisible: boolean = false;

    constructor() {
        this.drawingCommands = new DrawingCommands();
        this.gripStylesCommands = new GripStyleCommands();
        this.toolsCommands = new ToolsCommands();
        this.selectedGripStyleId = SvgEntityPointStyleEnum.end;
        this.gridOptionsCommands = new ToolGridOptionsCommands();
    }

    setCommand(id: DrawingCommandEnum): void {
        this.selectedCommandId = id;
    }

    setGridOption(id: ToolGridOptionsEnum): void {
        this.selectedGridOptionId = id;
        this.gridUnitLengthVisible = (id === ToolGridOptionsEnum.size);
    }

    setGripStyleCommand(id: SvgEntityPointStyleEnum): void {
        this.selectedGripStyleId = id;
    }

    switchGripStyleCommand(): void {
        const current = this.gripStylesCommands.items.findIndex(x=> x.id === this.selectedGripStyleId);
        let next = current + 1;
        if (next > this.gripStylesCommands.items.length - 1) {
            next = 0;
        }
        const newG = this.gripStylesCommands.items[next];
        this.raiseSelectedGripStyleChanged(newG.id);
    }

    selectedCommandChanged?: (selectedCommandId: DrawingCommandEnum) => void;
    selectedCommandClick(e: MatButtonToggleChange): void {
        this.raiseSelectedCommandChanged(e.value);
    }

    selectedGripStyleChanged?: (selectedGripStyleId: SvgEntityPointStyleEnum) => void;
    selectedGripStyleClick(e: MatButtonToggleChange): void {
        this.raiseSelectedGripStyleChanged(e.value);
    }

    selectedToolChanged?: (selectedToolId: ToolCommandEnum, enabled: boolean) => void;
    selectedToolClick(e: MatButtonToggleChange): void {
        const toggle = e.source;
        if (toggle && e.value.some((item: any) => item == toggle.value)) {
            toggle.buttonToggleGroup.value = [toggle.value];
        }
        this.raiseToolCommandChanged(toggle.value, toggle.checked);
    }

    selectedGridOptionChanged?: (selectedOptionId: ToolGridOptionsEnum) => void;
    selectedGridOptionClick(e: MatButtonToggleChange): void {
        this.selectedGridOptionId = e.value;
        this.raiseSelectedGridOptionChanged(e.value);
    }

    gridResetCommandClick(e: MouseEvent): void {
        this.raiseSelectedGridOptionChanged(ToolGridOptionsEnum.reset);
    }

    raiseSelectedGridOptionChanged(selectedOptionId: ToolGridOptionsEnum): void {
        this.gridUnitLengthVisible = (selectedOptionId === ToolGridOptionsEnum.size);
        if (this.selectedGridOptionChanged) {
            this.selectedGridOptionChanged(selectedOptionId);
        } else {
            logError("DrawingToolbar.selectedGridOptionChanged n'est pas écouté");
        }
    }

    raiseToolCommandChanged(id: ToolCommandEnum, enabled: boolean): void {
        if (id === ToolCommandEnum.grid) {
            // Affiche le panneau des options de grille
            const left = document.querySelector(`[tool-id="tool${id}"]`)?.getBoundingClientRect().left
            if (left) this.optionsLeft = left;
            this.extension = enabled;
        }
        if (this.selectedToolChanged) {
            this.selectedToolChanged(id, enabled);
        } else {
            logError("DrawingToolbar.selectedToolChanged n'est pas écouté");
        }
    }

    raiseSelectedGripStyleChanged(selectedGripStyleId: SvgEntityPointStyleEnum): void {
        if (this.selectedGripStyleChanged) {
            this.selectedGripStyleId = selectedGripStyleId;
            this.selectedGripStyleChanged(selectedGripStyleId);
        } else {
            logError("DrawingToolbar.selectedGripStyleChanged n'est pas écouté");
        }
    }

    raiseSelectedCommandChanged(selectedCommandId: DrawingCommandEnum): void {
        if (this.selectedCommandChanged) {
            this.selectedCommandId = selectedCommandId;
            this.selectedCommandChanged(selectedCommandId);
        } else {
            logError("DrawingToolbar.selectedCommandChanged n'est pas écouté");
        }
    }
    
    gridUnitLengthUpdated?: (value: number) => void;
    gridUnitLengthFocusOut(): void {
        if (this.gridUnitLengthUpdated) {
            this.gridUnitLengthUpdated(this.gridUnitLength);
        } else {
            logError("DrawingToolbar.gridUnitLengthUpdated n'est pas écouté");
        }
    }

    gridUnitLengthKeyDown(e: KeyboardEvent): void {
        if (e.code.toLowerCase() === "enter" || e.code.toLowerCase() === "numpadenter") {
            this.gridUnitLengthFocusOut();
        }
    }
}