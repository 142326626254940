export const BuildingAttributionDbView = {
    databaseTableName: "building_attribution_view",
    buSiteId: "Bu_SiteId", 
    flBuildingId: "Fl_BuildingId", 
    roAttributionTypeId: "Ro_AttributionTypeId", 
    roAtTyName: "RoAtTy_Name", 
    roAtTyColor: "RoAtTy_Color", 
    roAtTyDisplayNameId: "RoAtTy_DisplayNameId", 
    roAtTyViDisplayName: "RoAtTyVi_DisplayName", 
    buAtVi_Area: "BuAtVi_Area", 
    buAtViRoundedArea: "BuAtVi_RoundedArea"
}