import { Service } from "typedi";
import { ApiService } from "../api-service";
import { ApiControllers } from "../api-endpoints";
import { ProviderCompany } from "../../model/data-model/tables/provider-company";

@Service({ global: true })  
export class ProviderCompanyService extends ApiService {
    private createEndpoint: string = `${ApiControllers.providerCompany}`;
    private deleteEndpoint(id: number): string { return `${ApiControllers.providerCompany}/${id}`};

    constructor() {
        super();
    }

    async createNewProviderCompany(values : {}): Promise<ProviderCompany | null> {
      const result = await this.postAsync<any>(this.endPoint(this.createEndpoint), values);
        if (result != null) {
            return result.payload;
        }
        return null;
    }

    async deleteProviderCompany(id: number): Promise<boolean> {
        const result = await this.deleteAsync<boolean>(this.endPoint(this.deleteEndpoint(id)));
        if (result) {
            return result.payload;
        }
        return false;
    }
}