import { DbModelUtils } from './../../static-functions/db-model-utils';
import { RoomAllocationTable } from '../../db-model/tables/room-allocation-table';
import { DefinedModelBase } from '../defined-model-base';

export class RoomAllocation extends DefinedModelBase {
    roAlFloorDataId!: number;
    roAlBusinessUnitId!: number;
    roAlArea!: number;
    roAlRate!: number;
    roAlUpdateTimeStamp!: Date;

    constructor(dtoData: any, tableDef: any[] = []) {
        super(dtoData, [RoomAllocationTable].concat(tableDef));
    }

    static fromValues(floorDataId: number, buId: number, area: number, rate: number, timestamp: Date) {
        return new RoomAllocation({
            [RoomAllocationTable.roAlFloorDataId]: floorDataId, 
            [RoomAllocationTable.roAlBusinessUnitId]: buId, 
            [RoomAllocationTable.roAlArea]: area, 
            [RoomAllocationTable.roAlRate]: rate, 
            [RoomAllocationTable.roAlUpdateTimeStamp]: timestamp});
    }
}