import { Component, Input } from '@angular/core';
import { ScheduleHeader } from '../../model/schedule-header';

@Component({
  selector: 'xc-schedule-header',
  standalone: true,
  imports: [],
  templateUrl: './schedule-header.component.html',
  styleUrl: './schedule-header.component.scss'
})
export class ScheduleHeaderComponent {
  @Input() model: ScheduleHeader | undefined;
}
