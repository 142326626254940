@if (model) {
    <p-table #dt
    [columns]="model.columns" 
    [value]="model.dataSource" 
    dataKey="rowId" 
    editMode="row"
    [selectionMode]="model.withSelectableRows ? 'single' : null"
    [(selection)]="model.selectedRow"
    (onRowSelect)="model.selectedRowChange($event)"
    [lazy]="true"
    [paginator]="model.paginator"
    alwaysShow="false"
    [first]="model.firstItemIndex"
    [rows]="model.paginator?.pageItemsCount"
    [totalRecords]="model.paginator != null ? model.paginator.totalItemsCount : model.dataSource.length"
    [showCurrentPageReport]="true"
    [showPageLinks]="false"
    [rowsPerPageOptions]="model.paginator?.pageSizeOptions"
    [currentPageReportTemplate]="model.paginator != null ? model.paginator.pageReport : ''"
    (onPage)="model.newPageRequested($event)"
    styleClass="p-datatable-gridlines p-datatable-sm"
    [scrollable]="true" scrollHeight="flex"
    [tableStyle]="{ 'min-width': '50rem' }">
    <ng-template pTemplate="caption">
        <div class="flex">
            <button type="button" pButton pRipple icon="pi pi-file" (click)="dt.exportCSV()" class="p-button-success mr-2" pTooltip="CSV" tooltipPosition="bottom"></button>
            <button type="button" pButton pRipple icon="pi pi-file-pdf" (click)="model.exportPdf()" class="p-button-warning mr-2" pTooltip="PDF" tooltipPosition="bottom"></button>
            @if (model.allowInsert && !model.inserting) {
                <button type="button" pButton pRipple icon="pi pi-plus" (click)="model.insertRow(dt)" class="ml-auto" pTooltip="Nouveau type" tooltipPosition="bottom"></button>
            }
        </div>
    </ng-template>
    <ng-template pTemplate="header" let-columns>
        <tr>
            @if (model.withSelectableRows) {
                <th style="width: 4rem"></th>
            }
            @for (col of columns; track $index) {
                <th>
                    {{ col.headerLabel }}
                </th>
            }
            <th></th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-row let-columns="columns" let-ri="rowIndex">
        <tr [pEditableRow]="row">
            @if (model.withSelectableRows) {
                <td>
                    <div class="flex justify-content-center">
                        <p-tableRadioButton [value]="row"/>
                    </div>
                </td>
            }
            @for (col of columns; track $index) {
                <td>
                    @switch (col.cellType) {
                        @case (model.fieldTypeEnum.boolean) {
                            @if (col.readOnly || row.field(col.id).readOnly) {
                                <div class="flex justify-content-center">
                                    <p-checkbox [(ngModel)]="row.field(col.id).value" [binary]="true" [disabled]="true" [inputId]="row.rowId + '-' + col.id"/>
                                </div>
                            } @else {
                                <p-cellEditor>
                                    <ng-template pTemplate="input">
                                        <div class="flex justify-content-center">
                                            <p-checkbox 
                                                [(ngModel)]="row.field(col.id).value" 
                                                [binary]="true" 
                                                [inputId]="row.rowId + '-' + col.id"
                                                (onChange)="row.checkErrors()"/>
                                        </div>
                                    </ng-template>
                                    <ng-template pTemplate="output">
                                        <div class="flex justify-content-center">
                                            <p-checkbox 
                                                [(ngModel)]="row.field(col.id).value" 
                                                [binary]="true" 
                                                [disabled]="true" 
                                                [inputId]="row.rowId + '-' + col.id"/>
                                        </div>
                                    </ng-template>
                                </p-cellEditor>
                            }
                        }
                        @case (model.fieldTypeEnum.string) {
                            @if (col.readOnly || row.field(col.id).readOnly) {
                                <div>
                                    {{ row.field(col.id).value }}
                                </div>
                            } @else {
                                <p-cellEditor>
                                    <ng-template pTemplate="input">
                                        <input pInputText type="text" 
                                            [id]="row.rowId + '-' + col.id"
                                            class="p-inputtext-sm"
                                            [required]="!row.field(col.id).allowNull"
                                            [minlength]="row.field(col.id).minLength" 
                                            [maxlength]="row.field(col.id).maxLength" 
                                            [(ngModel)]="row.field(col.id).value"
                                            (input)="row.checkErrors()">
                                    </ng-template>
                                    <ng-template pTemplate="output">
                                        {{ row.field(col.id).value }}
                                    </ng-template>
                                </p-cellEditor>
                            }
                        }
                        @case (model.fieldTypeEnum.color) {
                            @if (col.readOnly || row.field(col.id).readOnly) {
                                <div class="flex justify-content-center">
                                    <div [style.background-color]="row.field(col.id).value" [style]="{width: '32px', height: '32px', borderRadius: '6px', border: '1px solid #d1d5db'}"></div>
                                </div>
                            } @else {
                                <p-cellEditor>
                                    <ng-template pTemplate="input">
                                        <div class="flex justify-content-center">
                                            <p-colorPicker [inputId]="row.rowId + '-' + col.id" appendTo="body" [(ngModel)]="row.field(col.id).value"/>
                                        </div>
                                    </ng-template>
                                    <ng-template pTemplate="output">
                                        <div class="flex justify-content-center">
                                            <div [style.background-color]="row.field(col.id).value" [style]="{width: '32px', height: '32px', borderRadius: '6px', border: '1px solid #d1d5db'}"></div>
                                        </div>
                                    </ng-template>
                                </p-cellEditor>
                            }
                        }
                        @case (model.fieldTypeEnum.list) {
                            @if (row.field(col.id).readOnly) {
                                <div class="flex justify-content-center">
                                    {{ row.field(col.id).selectedItem?.value }}
                                </div>
                            } @else {
                                <p-cellEditor>
                                    <ng-template pTemplate="input">
                                        <div class="flex justify-content-center">
                                            <p-dropdown appendTo="body" 
                                                [options]="row.field(col.id).selectItems" 
                                                [(ngModel)]="row.field(col.id).selectedItem" 
                                                (onChange)="model.onListFieldValueChange(row, row.field(col.id))"
                                                (onClick)="row.field(col.id).openRequested()"
                                                optionLabel="value" 
                                                placeholder="Selectionnez...">
                                            </p-dropdown>                                       
                                        </div>
                                    </ng-template>
                                    <ng-template pTemplate="output">
                                        <div class="flex justify-content-center">
                                            {{ row.field(col.id).selectedItem?.value }}
                                        </div>
                                    </ng-template>
                                </p-cellEditor>
                            }
                        }
                        @case (model.fieldTypeEnum.date) {
                            @if (col.readOnly || row.field(col.id).readOnly) {
                                <div class="flex justify-content-center">
                                    {{row.field(col.id).value | date:'shortDate'}}
                                </div>
                            } @else {
                                <p-cellEditor>
                                    <ng-template pTemplate="input">
                                        <div class="flex justify-content-center">
                                            <p-calendar 
                                                appendTo="body" 
                                                [firstDayOfWeek]="1"
                                                dateFormat="dd/mm/yy"
                                                [showClear]="row.field(col.id).allowsNull"
                                                [required]="!row.field(col.id).allowsNull"
                                                [inputId]="row.rowId + '-' + col.id"
                                                [(ngModel)]="row.field(col.id).value"
                                                (onInput)="row.checkErrors()"/>
                                        </div>
                                    </ng-template>
                                    <ng-template pTemplate="output">
                                        <div class="flex justify-content-center">
                                            {{row.field(col.id).value | date:'shortDate'}}
                                        </div>
                                    </ng-template>
                                </p-cellEditor>
                            }
                        }
                        @case (model.fieldTypeEnum.imageFile) {
                            <!-- Ce champs peut être modifié directement : peut être à revoir pour uniformiser l'ergonomie de la mise à jour d'une ligne -->
                            <div class="flex justify-content-center max-w-3rem">
                                <xc-dyn-image-file-cell
                                [model]="row.field(col.id)"
                              />
                            </div>
                        }
                        @case (model.fieldTypeEnum.number) {
                            @if (row.field(col.id).readOnly) {
                                <div class="flex justify-content-center">
                                    {{ row.field(col.id).value }}
                                </div>
                            } @else {
                                <p-cellEditor>
                                    <ng-template pTemplate="input">
                                        <div class="flex justify-content-center">
                                            <p-inputNumber 
                                            [inputId]="row.rowId + '-' + col.id"
                                            [required]="!row.field(col.id).allowNull"
                                            [min]="row.field(col.id).min" 
                                            [max]="row.field(col.id).max" 
                                            [(ngModel)]="row.field(col.id).value"
                                            (input)="row.checkErrors()"/>
                                        </div>
                                    </ng-template>
                                    <ng-template pTemplate="output">
                                        <div class="flex justify-content-center">
                                            {{ row.field(col.id).value }}
                                        </div>
                                    </ng-template>
                                </p-cellEditor>
                            }
                        }
                    }
                </td>
            }
            <td>
                <div class="flex align-items-center justify-content-center gap-2">
                    @if (!row.readOnly) {
                        @if (!row.editing) {
                            <button pButton pRipple type="button" pInitEditableRow icon="pi pi-pencil" (click)="model.onRowEditInit(row)" class="p-button-rounded p-button-text"></button>
                            @if (model.allowDelete) {
                                <button pButton pRipple icon="pi pi-trash" type="button" class="p-button-rounded p-button-text p-button-warning" (click)="model.deleteRow(row, ri)"></button>
                            }
                        } @else {
                            @if (!row.hasError) {
                                <button pButton pRipple type="button" pSaveEditableRow icon="pi pi-check" (click)="model.onRowEditSave(row)" class="p-button-rounded p-button-text p-button-success mr-2"></button>
                            } @else {
                                <button pButton pRipple type="button" icon="pi pi-exclamation-triangle" (click)="model.onShowErrors(row)" class="p-button-rounded p-button-text p-button-warning mr-2"></button>
                            }
                            <button pButton pRipple type="button" pCancelEditableRow icon="pi pi-times" (click)="model.onRowEditCancel(row, ri)" class="p-button-rounded p-button-text p-button-danger"></button>
                        }
                    }
                </div>
            </td>
        </tr>
    </ng-template>
</p-table>
}