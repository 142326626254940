import { FloorModelCategory } from '../tables/floor-model-category';
import { FloorModelCategoryDbView } from './../../db-model/views/floor-model-category-db-view';

export class FloorModelCategoryView extends FloorModelCategory {
    flMoCaHierarchicalTree!: string;
    flMoCaDisplayName!: string;

    constructor(dtoData: any) {
        super(dtoData, [FloorModelCategoryDbView]);
    }
}