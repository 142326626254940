import { DefinedModelBase } from '../defined-model-base';
import { DxfEntityTable } from '../../db-model/tables/dxf-entity-table';
export class DxfEntity extends DefinedModelBase {
    dxEnId!: number;
    dxEnLayerId!: number;
    dxEnSvgStatement!: string;

    constructor(dtoData: any, tableDef: any[] = []) {
        super(dtoData, [DxfEntityTable].concat(tableDef));
    }
}