export enum FloorBlueprintEventsEnum {
    directTaskCreated = "directTaskCreated",
    taskAnyHowEnded = "taskAnyHowEnded",
    selectedTaskChanged = "selectedTaskChanged",
    planningTaskVariantLoadRequested = "planningTaskVariantLoadRequested",
    planningTaskValidated = "planningTaskValidated",
    planningTaskRoomExtensionRequested = "planningTaskRoomExtensionRequested",
    editableLayersChanged = "editableLayersChanged",
    wallInsertRequested = "wallInsertRequested",
    doorInserRequested = "doorInserRequested",
    blueprintLayersChange = "blueprintLayersChange"
}