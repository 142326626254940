import { SvgEntityTypesEnum } from "./svg-entity-type-enum";
import { SvgEntity } from "./svg-entity.model";

export class SvgCircle extends SvgEntity {
    cx: number;
    cy: number;
    r: number;

    constructor(dtoData: any) {
        super(dtoData);
        this.cx = SvgEntity.getNumber(dtoData, "cx");
        this.cy = SvgEntity.getNumber(dtoData, "cy");
        this.r = SvgEntity.getNumber(dtoData, "r");
    }

    static create(cx: number, cy: number, r: number): SvgCircle {
        const result = new SvgCircle({ entityType: SvgEntityTypesEnum.circle, cx: cx, cy: cy, r: r });
        return result;
    }
}
