import { SvgPath } from 'src/app/core/model/svg-model/svg-path.model';
import { Point } from "src/app/core/model/geometry-model/point.model";
import { SvgEntityPoint } from '../../../shared/gizmos/model/svg-entity-point';
import { SvgEntityPointStyleEnum } from '../../../shared/gizmos/model/svg-entity-point-style-enum';
import { SvgPathService } from 'src/app/core/model/svg-model/svg-path-service';

export class GripPath extends SvgPath {
    constructor(data: any, radius: number) {
        super(data);
        this._radius = radius;
    }

    private _radius: number;
    get radius(): number {
        return this._radius;
    }
    set radius(value: number) {
        this._radius = value;
        this.setPath();
    }

    private _point: SvgEntityPoint = new SvgEntityPoint(SvgEntityPointStyleEnum.none, Point.origin());
    get point(): SvgEntityPoint {
        return this._point;
    }
    set point(value: SvgEntityPoint | Point) {
        if (value instanceof SvgEntityPoint) {
            this._point = value;
        }
        else {
            this._point = new SvgEntityPoint(this._point.gripStyle, value);
        }
        this.setPath();
    }

    private setPath(): void {
        const x = this.point.x;
        const y = this.point.y;
        const r = this._radius;
        switch (this.point.gripStyle) {
            // on augmente légèrement le rayon pour que le grip ait une taille visuellement proportionnée à celle du carré qui est la référence
            case SvgEntityPointStyleEnum.center:
                const cr = r * 1.3;
                this.d = `${SvgPathService.getPathAbsoluteMoveToStatement(x - cr, y)}${SvgPathService.getPathArcStatement(cr, cr, 0, 0, 0, x + cr, y)}${SvgPathService.getPathArcStatement(cr, cr, 0, 0, 0, x - cr, y)} Z`;
                break;
            case SvgEntityPointStyleEnum.quadrant:
                const qr = r * 1.4;
                this.d = `${SvgPathService.getPathAbsoluteMoveToStatement(x - qr, y)}${SvgPathService.getPathAbsoluteLineToStatement(x, y - qr)}${SvgPathService.getPathAbsoluteLineToStatement(x + qr, y)}${SvgPathService.getPathAbsoluteLineToStatement(x, y + qr)} Z`;
                break;
            case SvgEntityPointStyleEnum.middle:
                const mr = r * 1.4;
                this.d = `${SvgPathService.getPathAbsoluteMoveToStatement(x - mr, y + (mr / 2))}${SvgPathService.getPathAbsoluteLineToStatement(x, y - mr)}${SvgPathService.getPathAbsoluteLineToStatement(x + mr, y + (mr / 2))} Z`;
                break;
            case SvgEntityPointStyleEnum.orthogonal:
                this.d = `${SvgPathService.getPathAbsoluteMoveToStatement(x - r, y - r)}${SvgPathService.getPathAbsoluteLineToStatement(x - r, y + r)}${SvgPathService.getPathAbsoluteLineToStatement(x + r, y + r)}${SvgPathService.getPathAbsoluteLineToStatement(x, y + r)}${SvgPathService.getPathAbsoluteLineToStatement(x, y)}${SvgPathService.getPathAbsoluteLineToStatement(x - r, y)} Z`;
                break;
            case SvgEntityPointStyleEnum.plineMiddle:
                const ry = r / 2;
                this.d = `${SvgPathService.getPathAbsoluteMoveToStatement(x - r, y - ry)}${SvgPathService.getPathAbsoluteLineToStatement(x - r, y + ry)}${SvgPathService.getPathAbsoluteLineToStatement(x + r, y + ry)}${SvgPathService.getPathAbsoluteLineToStatement(x + r, y - ry)} Z`;
                break;
            default:
                const x0 = x - r;
                const y0 = y - r;
                const x1 = x - r;
                const y1 = y + r;
                const x2 = x + r;
                const y2 = y + r;
                const x3 = x + r;
                const y3 = y - r;

                this.d = "M " + x0 + " " + y0 + " " + x1 + " " + y1 + " " + x2 + " " + y2 + " " + x3 + " " + y3 + " Z"
                break;
        }
    }
}