import { Component, Input } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { SelectButtonModule } from 'primeng/selectbutton';
import { GripsOptions } from '../grips-options';
import { TooltipModule } from 'primeng/tooltip';

@Component({
  selector: 'xc-grips-options-selector',
  standalone: true,
  imports: [FormsModule, SelectButtonModule, TooltipModule],
  templateUrl: './grips-options-selector.component.html',
  styleUrl: './grips-options-selector.component.scss'
})
export class GripsOptionsSelectorComponent {
  @Input() model: GripsOptions | undefined;
}
