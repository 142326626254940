import { IntersectedSegment } from './intersected-segment';
import { Vector } from './vector.model';
import { GeometricElementType } from './geometric-element-type.model';
import { GeometricElement } from './geometric-element.model';
import { PointPosition } from './point-position.model';
import { Point } from './point.model';
import { Segment } from './segment.model';
import { LoggerService } from '../../services/logger.service';
import { Rectangle } from './rectangle.model';

export class Polygon implements GeometricElement {
    vertices: Point[];
    elementType = GeometricElementType.Polygon;

    constructor(vertices: Point[]){
        this.vertices = Point.clone(vertices);
    }

    equals(p: Polygon): boolean{
        let pVertices = p.vertices;
        if (this.vertices.length !== pVertices.length)
        {
            return false;
        }

        for (let i = 0; i < this.vertices.length; i++)
        {
            if (this.vertices[i] !== pVertices[i])
            {
                return false;
            }
        }

        return true;
    }

    area(): number {
        this.close();
        let num = 0.0;
        for (let i = 0; i < this.vertices.length - 1; i++) {
            const p1 = this.vertices[i];
            const p2 = this.vertices[i + 1];
            const num2 = p1.x - p2.x;
            const num3 = (p1.y + p2.y) / 2.0;
            num += num2 * num3;
        }

        return Math.abs(num);
    }

    // Détermine si un polygone fermé contient un point
    contains(p: Point, includingOnEdge: boolean = true): boolean {
        // Adaptation du code C++ trouvé sur
        // https://web.archive.org/web/20130126163405/http://geomalgorithms.com/a03-_inclusion.html

        // Copyright 2000 softSurfer, 2012 Dan Sunday
        // This code may be freely used and modified for any purpose
        // providing that this copyright notice is included with it.
        // SoftSurfer makes no warranty for this code, and cannot be held
        // liable for any real or imagined damage resulting from its use.
        // Users of this code must verify correctness for their application.

        // L'algo cherche si le point est strictement à droite ou à gauche du segment considéré
        // Si le point est sur le segment il est donc ignoré dans le calcul
        // ce qui revient à le considérer comme étant à l'intérieur du polygone

        let wn = 0;

        for (let i = 0; i < this.vertices.length - 1; i++)
        {
            const segment = new Segment(this.vertices[i], this.vertices[i + 1]);
            const pointPosition = segment.getPointPosition(p);
            // Si on ne veut pas inclure le cas où le point est sur un côté du polygone
            if (pointPosition === PointPosition.On && !includingOnEdge) {
                wn = 0;
                break;
            }

            if (this.vertices[i].y <= p.y)
            {
                if (this.vertices[i + 1].y > p.y)
                {
                    if (pointPosition === PointPosition.Left) wn++;
                }
            }
            else
            {
                if (this.vertices[i + 1].y <= p.y)
                {
                    if (pointPosition === PointPosition.Right) wn--;
                }
            }
        }

        return wn !== 0;
    }

    isInTheEdge(p: Point): boolean {
        let result: boolean = false;
        this.getSegments().forEach(s => {
            if (s.contains(p)) {
                result = true;
                return;
            }
            
        });
        return result;
    }

    containsAll(points: Point[]): boolean {
        let result = true;
        points.forEach(p => {
            if (!this.contains(p)) {
                result = false;
            }
        });
        return result;
    }

    areIn(points: Point[]): Point[] {
        let result: Point[] = [];
        points.forEach(p => {
            if (this.contains(p)) {
                result.push(p);
            }
        });
        return result;
    }

    rotateNew(rotationCenter: Point, angleRad: number): Polygon {
        if(rotationCenter && angleRad != 0) {
            const vertices: Point[] = [];
        
            this.vertices.forEach(v => {
                vertices.push(v.rotate(rotationCenter, angleRad));
            });
    
            return new Polygon(vertices);
        }else {
            return this;
        }
    }

    translateNew(translate: Vector): Polygon {
        if(translate) {
            const vertices: Point[] = [];
        
            this.vertices.forEach(v => {
                vertices.push(v.translate(translate));
            });
            
            return new Polygon(vertices);
        }else {
            return this;
        }
    }

    transformNew(rotationCenter: Point, rotationAngleRad: number, translate: Vector): Polygon {
        const result = this.rotateNew(rotationCenter, rotationAngleRad);
        return result.translateNew(translate);
    }

    // Ferme le polygone si nécessaire (le premier point est égal au dernier point)
    close(): void {
        const firstVertice = this.vertices[0];
        if (!firstVertice.equals(this.vertices[this.vertices.length - 1])) {
            this.vertices.push(firstVertice.clone());
        } 
    }

    isClosed(): boolean {
        return this.vertices[0].equals(this.vertices[this.vertices.length -1]);
    }

    getSegments(): Segment[] {
        let result: Segment[] = [];
        for (let i = 0; i < this.vertices.length - 1; i++) {
            result.push(new Segment(this.vertices[i], this.vertices[i + 1]));
        }
        return result;
    }

    getIntersects(s: Segment): Point[] {
        const result: Point[] = [];

        const ps = this.getSegments();
        this.getSegments().forEach(ps => {
            const i = s.getIntersect(ps);
            if (i) result.push(i);
        });

        return result;
    }

    bbox(): Polygon {
        let minX: number = Number.MAX_SAFE_INTEGER;
        let minY: number = Number.MAX_SAFE_INTEGER;
        let maxX: number = Number.MIN_SAFE_INTEGER;
        let maxY: number = Number.MIN_SAFE_INTEGER;

        this.vertices.forEach(v => {
            if (v.x < minX) minX = v.x;
            if (v.y < minY) minY = v.y;
            if (v.x > maxX) maxX = v.x;
            if (v.y > maxY) maxY = v.y;
        });

        return new Polygon([new Point(minX, minY), new Point(maxX, minY), new Point(maxX, maxY), new Point(minX, maxY)]);
    }

    // TODO : Méthode à déplacer car uniquement dépendante de floorBlueprint
    getBpIntersects(segments: Segment[], withFullInside: boolean = true): IntersectedSegment[] {
        let result: IntersectedSegment[] = [];

        segments.forEach(s => {
            if (withFullInside && this.contains(s.startPoint) && this.contains(s.endPoint)) {
                result.push(new IntersectedSegment(s, []));
                return;
            }

            const intersects: {segment: Segment, point: Point}[] = [];

            for(let s2 of this.getSegments())
            {
                const intersect = s2.getIntersect(s);
                if (intersect != null)
                {
                    intersects.push({segment: s2, point: intersect});
                }
            }

            if (intersects.length > 0) {
                result.push(new IntersectedSegment(s, intersects));
            }
        });

        return result;
    }

    // TODO : Méthode SVG à sortir de la lib de géométrie
    static topLeftOriginSquare(points: Point[]): Point[] {
        if (points.length !== 4) {
            return points;
        }

        const p1 = points[0];
        const p2 = points[1];
        const p3 = points[2];
        const p4 = points[3];

        if (p1.x < p2.x && p1.x < p3.x && p1.x === p4.x && p1.y === p2.y && p1.y < p3.y && p1.y < p4.y) {
            return points;
        }

        if (p1.x > p2.x && p1.x > p3.x && p1.x === p4.x && p1.y === p2.y && p1.y < p3.y && p1.y < p4.y) {
            return [p2, p1, p4, p4];
        }

        if (p1.x > p2.x && p1.x > p3.x && p1.x === p4.x && p1.y === p2.y && p1.y > p3.y && p1.y > p4.y) {
            return [p3, p4, p1, p2];
        }

        if (p1.x < p2.x && p1.x < p3.x && p1.x === p4.x && p1.y === p2.y && p1.y > p3.y && p1.y > p4.y) {
            return [p4, p3, p2, p1];
        }

        return points;
    }
}