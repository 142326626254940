import { LeaseTable } from './../../db-model/tables/lease-table';
import { DefinedModelBase } from '../defined-model-base';

export class Lease extends DefinedModelBase {
    leId!: number;
    leParentId!: number | null;
    leInternalTypeId!: number;
    lePurpose!: string;
    lePerimeterId!: number;
    leLessorId!: number;
    leTenantId!: number;
    leDateOfSignature!: Date;
    leLeaseStatusId!: number;
    leEffectiveDate!: Date;
    leAmendmentCount!: number;
    leTerm!: Date | null;
    leDurationTypeId!: number;
    leNoticeLength!: number;

    constructor(dtoData: any, tableDef: any[] = []) {
        super(dtoData, [LeaseTable].concat(tableDef));
        // Il faut convertir explicitement les dates en dates parce qu'ellse arrivent nativement comme string
        this.leDateOfSignature = new Date(this.leDateOfSignature);
        this.leEffectiveDate = new Date(this.leEffectiveDate);
    }
}