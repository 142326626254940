import { DateUtils } from "src/app/core/model/static-functions/date-utils";
import { MobilityProjectTreeNode } from "../../../side-panel/shared-model/mobility-project-tree-node";
import { GanttSvgTaskVM } from "./gantt-svg-task-vm";
import { TaskLinkView } from "src/app/core/model/data-model/views/task-link-type-view";
import { TaskTypeEnum } from "src/app/core/model/data-model/enums/task-type-enum";
import { XcMaths } from "src/app/core/model/static-functions/xc-maths";
import { GanttSvgTaskLinkTransient } from "./gantt-svg-task-link-transient";
import Container from "typedi";
import { DyntService } from "src/app/core/services/backend-services/dynt-service";
import { TablesNamesEnum } from "src/app/core/model/db-model/tables-names-enum";

export class GanttRootProjectContainerVM {
    rootProject: MobilityProjectTreeNode;
    taskDefaultHeight: number;
    xDaysOffset: number;
    xPos: number = 0;
    width: number = 0;
    currentUnitWidth: number;
    svgRootProject!: GanttSvgTaskVM;
    transientLink: GanttSvgTaskLinkTransient | undefined;
    startDate: Date;
    links: TaskLinkView[] = [];

    initialXDayOffset: number;
    initialXPos: number;
    initialWidth: number;

    constructor(rootProject: MobilityProjectTreeNode, links: TaskLinkView[], taskDefaultHeight: number, startDate: Date, currentUnitWidth: number) {
        this.startDate = startDate;
        this.xDaysOffset = DateUtils.getDays(this.startDate, rootProject.data.taStartDate);
        this.initialXDayOffset = this.xDaysOffset;
        this.rootProject = rootProject;
        this.links = links;
        this.taskDefaultHeight = taskDefaultHeight;
        this.currentUnitWidth = currentUnitWidth;
        // TODO : centraliser la largeur par défaut d'un jour (20) avec le style.css (--xc-schedule-col-default-width)
        this.setGeometry();
        this.initialXPos = this.xPos;
        this.initialWidth = this.width;
        this.loadSvgRootProject();
    }

    refreshContent(): void {
        this.loadSvgRootProject();
    }

    setGeometry(): void {
        this.xPos = XcMaths.round(this.xDaysOffset * this.currentUnitWidth * 20, 0);
        this.width = this.rootProject.data.taDuration * this.currentUnitWidth * 20;
        this.initialXPos = this.xPos;
        this.initialWidth = this.width;
    }

    private loadSvgRootProject(): void {
        const rootProjectStartDate = this.rootProject.data.taStartDate;
        // Les liens du projets sont ceux de ses tâches de type task
        const chikdrenTasksLinks = this.getTasksLinks(this.rootProject.children);
        this.svgRootProject = new GanttSvgTaskVM(this.rootProject, rootProjectStartDate, this.currentUnitWidth, this.taskDefaultHeight);
        this.loadSvgTaskChildren(this.svgRootProject, this.rootProject.children, this.links, rootProjectStartDate);
        this.svgRootProject.initializeSvgLinks(chikdrenTasksLinks);

        this.svgRootProject.sizeUpdateRequested = (minDelta: number, maxDelta: number) => {
            const rightPos = this.xPos + this.width;
            this.xPos += minDelta;
            this.width = rightPos + maxDelta - this.xPos;
        }

        // TODO : à remonter vers le calendrier ?
        this.svgRootProject.linkRemoved = async (linkId: number) => {
            const s = Container.get(DyntService);
            await s.delete(TablesNamesEnum.TaskLink, linkId);
        };

    }

    private loadSvgTaskChildren(parent: GanttSvgTaskVM, nodes: MobilityProjectTreeNode[], links: TaskLinkView[], rootProjectStartDate: Date): void {
        nodes.forEach(n => {
            const newSvgTask = new GanttSvgTaskVM(n, rootProjectStartDate, this.currentUnitWidth, this.taskDefaultHeight, parent);
            parent.addChild(newSvgTask);
            if (n.children.length > 0) {
                // Les liens du projets sont ceux de ses tâches de type task
                const chikdrenTasksLinks = this.getTasksLinks(n.children);
                this.loadSvgTaskChildren(newSvgTask, n.children, links, rootProjectStartDate);
                newSvgTask.initializeSvgLinks(chikdrenTasksLinks);
            }
        });
    }

    private getTasksLinks(tasks: MobilityProjectTreeNode[]): TaskLinkView[] {
        const childrenTasksIds = tasks.filter(x=> x.data.taTypeId === TaskTypeEnum.Task).map(x=> x.data.taId);
        return this.links.filter(x=> childrenTasksIds.includes(x.taLiSourceTaskId) ||  childrenTasksIds.includes(x.taLiTargetTaskId));
    }

    taskHeight(t: MobilityProjectTreeNode): number {
        let result: number = this.taskDefaultHeight;

        if (t.expanded) {
            t.children.forEach(c => {
                if (c.children.length === 0) {
                    result += c.clientHeight;
                }
                result += this.taskHeight(c);
            });
        }

        return result;
    }

    setScale(currentUnitWidth: number): void {
        this.currentUnitWidth = currentUnitWidth;
        this.setGeometry();

        this.svgRootProject.setScale(currentUnitWidth);
    }

    hasChanges(): boolean {
        return this.xPos !== DateUtils.getDays(this.startDate, this.rootProject.data.taStartDate) * this.currentUnitWidth * 20 || 
        this.width !== this.rootProject.data.taDuration * this.currentUnitWidth * 20;
    }

    hasMoved(): boolean {
        return this.initialXPos !== this.xPos && this.initialWidth === this.width;
    }

    decreaseContainerPosition(delta: number): void {
        this.xPos = this.initialXPos - delta;
        this.containerIsStartDecreased = true;
    }

    increaseContainerPosition(delta: number): void {
        this.xPos = this.initialXPos + delta;
        if (this.containerIsStartDecreased && this.xPos - this.initialXPos >= 0) {
            this.containerIsStartDecreased = false;
            this.xPos = this.initialXPos;
       }
    }

    extendContainerByStart(delta: number): void {
        this.xPos = this.initialXPos - delta;
        if (this.xPos < this.initialXPos) {
            this.containerIsStartExtended = true;
        }
    }

    hasSameXPosDecendants(taskToCompare: GanttSvgTaskVM, project: GanttSvgTaskVM = this.svgRootProject): boolean {
        if (taskToCompare.task.taId === this.svgRootProject.task.taId) return false;

        let result: boolean = false;

        const taskToCompareIsProject = taskToCompare.isProject();
        if (taskToCompareIsProject) {
            for (const c of project.children) {
                if (c.isProject()) {
                    if (c.xPos === taskToCompare.xPos) {
                        result = true;
                        break;
                    } else {
                        return this.hasSameXPosDecendants(taskToCompare, c);
                    }
                }
            }
        } else {
            for (const c of project.children) {
                if (c.isProject()) {
                    result = this.hasSameXPosDecendants(taskToCompare, c);
                } else {
                    if (c.task.taId !== taskToCompare.task.taId && c.xPos === taskToCompare.xPos) {
                        result = true;
                        break;
                    }
                }
            }
        }

        return result;
    }

    moving: boolean = false;
    containerIsStartDecreased: boolean = false;

    move(task: GanttSvgTaskVM, delta: number, direction: number): void {
        const isProject = task.isProject();

        this.moving = true;

        if (task.width === this.svgRootProject.width) {
            // durée de la tâche égale à la durée du projet racine
            if (direction === -1) {
                // Direction du déplacement vers la gauche
                this.decreaseContainerPosition(-delta);
                this.svgRootProject.compensateTasksPosition(task, -delta);
            } else {
                // Direction du déplacement vers la droite
                if (this.hasSameXPosDecendants(task)) {
                    // la tâche se déplace uniquement si elle n'est pas la seule du parent à être à la position 0
                    task.move(delta, isProject);
                } else {
                    // sinon on déplace le conteneur
                    this.increaseContainerPosition(delta);
                    this.svgRootProject.compensateTasksPosition(task, -delta);
                }
            }
        } else {
            // durée de la tâche inférieure à la durée du projet racine
            if (direction === -1) {
                // Direction du déplacement vers la gauche
                if (task.initialXPos + delta >= 0) {
                    // déplace la tâche vers la gauche si sa position le permet
                    task.move(delta - (this.xPos - this.initialXPos), isProject);
                } else {
                    // La tâche est à sa position minimale, c'est le conteneur qui s'agrandit
                    task.xPos = 0;
                    this.decreaseContainerPosition(-(task.initialXPos + delta));
                    this.svgRootProject.compensateTasksPosition(task, -(task.initialXPos + delta));
                }
            } else {
                // Direction du déplacement vers la droite
                if (this.containerIsStartDecreased) {
                    // Le conteneur avait été étendu vers le passé
                    this.increaseContainerPosition(task.initialXPos + delta);
                    this.svgRootProject.compensateTasksPosition(task, -(task.initialXPos + delta));
                } else {
                    if (task.xPos === 0 && !this.hasSameXPosDecendants(task)) {
                        // La tâche est en premier et seule à cette position
                        this.increaseContainerPosition(task.initialXPos + delta);
                        this.svgRootProject.compensateTasksPosition(task, -(task.initialXPos + delta));
                    } else {
                        task.move(delta - (this.xPos - this.initialXPos), isProject);
                    }
                }
            }
        }

        this.moving = false;
    }

    validate(task: GanttSvgTaskVM): void {
        this.containerIsStartDecreased = false;

        this.xDaysOffset = XcMaths.round(this.xPos / (20 * this.currentUnitWidth), 0);
        this.xPos = this.xDaysOffset * this.currentUnitWidth * 20;
        const daysDiff = this.xDaysOffset - this.initialXDayOffset;
        const rootStartDate = DateUtils.addDays(this.svgRootProject.task.taStartDate, daysDiff)
       
        // Si l'élément déplacé est le projet racine, toutes les dates de début sont modifiées
        if (this.svgRootProject.task.taId === task.task.taId || this.hasMoved()) {
            this.svgRootProject.moveStartDate(daysDiff);
        } else {
            this.svgRootProject.validate(rootStartDate);
        }

        this.initialXPos = this.xPos;
        this.initialXDayOffset = this.xDaysOffset;
        this.initialWidth = this.width;
    }

    reset(): void {
        this.svgRootProject.reset();
        this.xPos = this.initialXPos;
        this.width = this.initialWidth;
        this.containerIsStartDecreased = false;
        this.moving = false;
    }

    containerIsStartExtended: boolean = false;
    extend(task: GanttSvgTaskVM, delta: number, startExtension: boolean, direction: number): void {
        if (startExtension) {
            this.extendStart(task, delta, direction);
        } else {
            this.extendDuration(task, delta);
        }
    }

    extendStart(task: GanttSvgTaskVM, delta: number, direction: number): void {
        this.moving = true;

        if (task.width === this.svgRootProject.width) {
            // durée de la tâche égale à la durée du projet racine
            if (direction === -1) {
                // Direction du déplacement vers la gauche
                if (this.xPos > this.initialXPos) {
                    // Le début du conteneur a précédemment été déplacé vers le futur
                    if (task.extendByDuration(delta)) {
                        this.extendContainerByStart(-delta);
                        this.svgRootProject.compensateTasksPosition(task, -delta);
                    }
                } else {
                    if (task.extendByStart(delta)) {
                        this.extendContainerByStart(-delta);
                        this.svgRootProject.compensateTasksPosition(task, -delta);
                    }
                }
            } else {
                // Direction du déplacement vers la droite
                if (this.hasSameXPosDecendants(task)) {
                    // la tâche se déplace uniquement si elle n'est pas la seule du parent à être à la position 0
                    task.extendByStart(delta - (this.xPos - this.initialXPos), delta);
                } else {
                    // sinon on déplace le conteneur
                    if (task.extendByDuration(delta)) {
                        this.extendContainerByStart(-delta);
                        this.svgRootProject.compensateTasksPosition(task, -delta);
                    }
                }
            }
        } else {
            // durée de la tâche inférieure à la durée du projet racine
            if (direction === -1) {
                // Direction du déplacement vers la gauche
                if (task.initialXPos + delta - (this.xPos - this.initialXPos) >= 0) {
                    // déplace la tâche vers la gauche si sa position le permet
                    task.extendByStart(delta - (this.xPos - this.initialXPos), delta);
                } else {
                    // La tâche est à sa position minimale, c'est le conteneur qui s'agrandit
                    task.xPos = 0;
                    if (task.extendByDuration(delta)) {
                        this.decreaseContainerPosition(-(task.initialXPos + delta));
                        this.svgRootProject.compensateTasksPosition(task, -(task.initialXPos + delta));
                    }
                }
            } else {
                // Direction du déplacement vers la droite
                if (this.containerIsStartDecreased) {
                    // Le conteneur avait été étendu vers le passé
                    if (task.extendByDuration(delta)) {
                        this.increaseContainerPosition(task.initialXPos + delta);
                        this.svgRootProject.compensateTasksPosition(task, -(task.initialXPos + delta));
                    }
                } else {
                    if (task.xPos === 0 && !this.hasSameXPosDecendants(task)) {
                        // La tâche est en premier et seule à cette position
                        task.decreaseDuration(delta);
                        this.increaseContainerPosition(task.initialXPos + delta);
                        this.svgRootProject.compensateTasksPosition(task, -(task.initialXPos + delta));
                    } else {
                        task.extendByStart(delta - (this.xPos - this.initialXPos), delta);
                    }
                }
            }
        }

        this.moving = false;
    }

    extendDuration(task: GanttSvgTaskVM, delta: number): void {
        this.moving = true;

        task.extendByDuration(-delta);

        this.moving = false;
    }
}