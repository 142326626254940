import { SvgPattern } from "src/app/core/model/svg-model/svg-pattern.model";
import { DisplayThemableView } from 'src/app/core/model/data-model/views/display-themable-view';
import { DisplayThemeView } from 'src/app/core/model/data-model/views/display-theme-view';

export class DisplayThemeCaption {
    themeId!: number;
    id!: number;
    mainLabel!: string;
    subLabel?: string;
    color: string = "#ffffff";
    pattern: SvgPattern | undefined;
    isDynamicDataGenerated: boolean = false;
    removedFromList: boolean = false;
    hidden: boolean = false;

    constructor(dataSet?: (DisplayThemeView & DisplayThemableView)) {
        // Chargement à partir des datas provenant du backend
        if (dataSet) {
            this.themeId = dataSet.diThId;
            this.id = dataSet.diTbId;
            this.mainLabel = dataSet.diTbDisplayName;
            if (dataSet.diTbColor) {
                this.color = dataSet.diTbColor;
                this.pattern = new SvgPattern(this.getPatternId(), this.color, "#ffffff", this.getPatternUrlRef());
            }
        }
    }

    static fromBlueprintData(themeId: number, id: number, mainLabel: string, subLabel: string, color: string): DisplayThemeCaption {
        //Chargement à partir des datas du plan
        const result = new DisplayThemeCaption();
        result.themeId = themeId;
        result.id = id;
        result.mainLabel = mainLabel;
        result.subLabel = subLabel;
        result.color = color;
        result.isDynamicDataGenerated = true;
        return result;
    }

    getPatternId(): string {
        return "pattern-" + this.themeId.toString() + "-" + this.id.toString();
    }

    getPatternUrlRef(): string {
        return "url('#" + this.getPatternId() + "')";
    }
}