import { Component, ElementRef, EventEmitter, Input, Output, ViewChild, AfterViewInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { forkJoin, Observable, Subject } from 'rxjs';
import { UploadService } from 'src/app/core/services/backend-services/upload-service';
import { ApiData } from 'src/app/core/services/api-data';
import { MaterialModule } from 'src/app/core/material.module';
import { toastError } from 'src/app/core/services/toast-service';

@Component({
  selector: 'xc-file-upload',
  standalone: true,
  imports: [CommonModule, MaterialModule],
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements AfterViewInit {
  @Input() multi: boolean | undefined;
  @Input() acceptedFiles: string | undefined = '*';
  @Input() uploadUrl: string | undefined;
  @Input() uploadMetadata: string | undefined;
  @Input() beginUpload = new Subject<boolean>();
  @Input() simpleIcon: boolean = false;
  @Input() fileMaxSize: number | undefined;
  @Input() fileName!: string;

  @Output() successfulUpload = new EventEmitter<any>();
  @Output() uploading = new EventEmitter<boolean>();
  @Output() filesCount = new EventEmitter<number>();
  @ViewChild('file') file: ElementRef | undefined;

  progress: { [key: string]: { progress: Observable<number>, response: Observable<ApiData<any>> }} | undefined;
  canBeClosed = true;
  primaryButtonText = 'Téléverser';
  // showCancelButton = true;
  // uploading = false;
  uploadSuccessful = false;
  progressIcon = 'cloud';

  public files: Set<File> = new Set();

  constructor(public uploadService: UploadService) {
  }

  ngOnInit() {
    this.beginUpload.subscribe(() => this.uploadFiles());
  }

  ngAfterViewInit(): void {
    this.addFiles();
  }

  addFiles() {
    this.file?.nativeElement.click();
  }

  onFilesAdded() {
    const files: { [key: string]: File } = this.file?.nativeElement.files;
    this.files.clear();
    for (let key in files) {
      if (!isNaN(parseInt(key))) {
        const file = files[key];
        if (this.fileMaxSize && this.fileMaxSize < file.size) {
          toastError(`Le fichier sélectionné (${file.size / 1024}Ko) dépasse la taille maximale autorisée : ${this.fileMaxSize}Ko`)
        } else {
          this.files.add(file);
        }
      }
    }
    this.filesCount.next(this.files.size);
  }

  uploadFiles() {
    if (!this.uploadUrl) return;
    if (!this.fileMaxSize) return;
    // if everything was uploaded already, just close the upload-dialog
    // if (this.uploadSuccessful) {
    //   return this.dialogRef.close();
    // }

    // set the component state to "uploading"
    //this.uploading = true;
    this.uploading.next(true);

    // start the upload and save the progress map
    // TODO : si possibilité d'upload multiples, générer un tableau de noms de fichiers
    this.progress = this.uploadService.upload(this.files, this.uploadUrl, this.fileMaxSize, this.fileName);

    // convert the progress map into an array
    const allProgressObservables = [];
    for (const key in this.progress) {
      // allProgressObservables.push(this.progress[key].progress);
      allProgressObservables.push(this.progress[key].response);
    }

    // When all progress-observables are completed...
    forkJoin([allProgressObservables]).subscribe(end => {
      this.successfulUpload.next(end);

      // ... the upload was successful...
      this.uploadSuccessful = true;

      // ... and the component is no longer uploading
      this.uploading.next(false);
    });
  }

}
