import { readableUUID } from "src/app/core/events/event-listener-uuid";
import { AppMenuRouteEnum } from "src/app/core/model/data-model/enums/app-menu-route-enum";
import { PageModel } from "src/app/ui/main/model/page-model";
import { StoresManagePager } from "./stores-manage-pager";

export class StoresManage extends PageModel {
    pager: StoresManagePager | undefined;

    private constructor() {
        super(AppMenuRouteEnum.layout_inventory_stores, 0, readableUUID(StoresManage.name));
    }

    static async newAsync(): Promise<StoresManage> {
        const result = new StoresManage();
        result.pager = await StoresManagePager.newAsync();
        return result;
    }
}