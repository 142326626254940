import { Container } from 'typedi';
import { BuildingAxono } from './building-axono';
import { DonutChartDataVM } from '../../donut/model/donut-chart-data-vm';
import { GraphicsService } from 'src/app/core/services/backend-services/graphics-service';
import { TabPageContent } from 'src/app/components-lib/tab-page-container/model/tab-page-content';
import { readableUUID } from 'src/app/core/events/event-listener-uuid';
import { logError } from 'src/app/core/services/logging-service';

export class BuildingAxonoWrapper extends TabPageContent<DonutChartDataVM> {
    buildingAxono: BuildingAxono = new BuildingAxono();

    constructor(id: string, name: string) {
        super(readableUUID("BuildingAxonoWrapper"), id);
    }

    async setColorMap(buildingId: number, buildingChartType: string): Promise<void> {
        const s = Container.get(GraphicsService);
        const colorMap = await s.getAxonoColorMap(buildingId, buildingChartType);
        this.buildingAxono.setColorMap(colorMap, buildingChartType);
    }

    blueprintRequested?: (floorId: number) => void;
    async loadBuilding(buildingId: number): Promise<void> {
        const s = Container.get(GraphicsService);
        const dto = await s.downloadBuildingAxono(buildingId);
        if (dto) {
            this.buildingAxono.load(dto);
            this.buildingAxono.blueprintRequested = (floorId: number) => {
                if (this.blueprintRequested) {
                    this.blueprintRequested(floorId);
                } else {
                    logError("BuildingAxonoWrapper.blueprintRequested n'est pas écouté");
                }
            }
        }
    }
}