export class AttributeObserver {
    attributeName: string;
    attributeChanged?: () => void;
    observer: MutationObserver;

    constructor(drawingNode: any, attributeName: string) {
        this.attributeName = attributeName;

        // Options for the observer (which mutations to observe)
        const config = { attributes: true, childList: false, subtree: false };

        // Callback function to execute when mutations are observed
        const callback = (mutationList: any, observer: any) => {
            for (const mutation of mutationList) {
                if (mutation.type === "attributes" && mutation.attributeName === attributeName) {
                    if (this.attributeChanged) {
                        this.attributeChanged();
                    }
                }
            }
        };

        // Create an observer instance linked to the callback function
        this.observer = new MutationObserver(callback);

        // Start observing the target node for configured mutations
        this.observer.observe(drawingNode, config);
    }

    disconnect(): void {
        this.observer.disconnect();
    }
}