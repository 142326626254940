import { ApplicationConfig, LOCALE_ID, SecurityContext, importProvidersFrom, isDevMode } from '@angular/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import { authHttpInterceptorFn, provideAuth0 } from '@auth0/auth0-angular';
import { HttpClient, provideHttpClient, withInterceptors } from '@angular/common/http';
import { LocaleSwitcherService } from './core/services/locale/locale-switcher-service';
import { LocaleId } from './core/services/locale/locale-id-service';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { provideRouter } from '@angular/router';
import { MARKED_OPTIONS, provideMarkdown } from 'ngx-markdown';
import { routes } from './app-routes';
import { environment as env } from '../environments/environment';
import { provideServiceWorker } from '@angular/service-worker';

// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
    return new TranslateHttpLoader(httpClient, './assets/i18n/', '.json');
}

export const provideTranslation = () => ({
    defaultLanguage: 'en',
    loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
    },
});


export const appConfig: ApplicationConfig = {
    providers: [
        provideRouter(routes),
        provideHttpClient(withInterceptors([
            authHttpInterceptorFn
            //apiRequestsInterceptor
        ])),
        provideAuth0({
            ...env.auth,
            httpInterceptor: {
                ...env.httpInterceptor,
            }
          }),
        importProvidersFrom([
            TranslateModule.forRoot(provideTranslation())
        ]),
        {
            provide: LOCALE_ID,
            useClass: LocaleId,
            deps: [LocaleSwitcherService],
        },
        provideMarkdown({
            markedOptions: {
              provide: MARKED_OPTIONS,
              useValue: {
              },
            },
        }),
        provideAnimations(), provideServiceWorker('ngsw-worker.js', {
            enabled: !isDevMode(),
            registrationStrategy: 'registerWhenStable:30000'
          })
    ]
};
