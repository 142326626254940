@if (model) {
    <!-- Enveloppe du projet racine. Elle s'étend sur la largeur totale du calendrier -->
    <div class="schedule-task-wrapper"> <!--  [style.height.px]="model.taskHeight(model.rootProject)" -->
        <!-- Conteneur du SVG. Il s'étend sur la durée du projet racine -->
        <!-- -02 et +04 sont des valeurs arbitraires pour élargir le conteneur de façon à ce que le SVG ne touche pas le contour -->
        <div class="schedule-task-container" 
            [style.height.px]="model.taskHeight(model.rootProject) - 2" 
            [style.margin-left.px]="model.xPos - (0.5 * model.currentUnitWidth * 20)"
            [style.width.px]="model.width + (1 * model.currentUnitWidth * 20)">
            <!-- 20 est la largeur par défaut d'un jour du calendrier. Impossible ici d'utiliser la variable css déclarée dans stryle.css. -->
            <!-- TODO : Trouver une façon de rendre ça plus robuste -->
            <div class="svg-container"
                [style.width.px]="model.width + (1 * model.currentUnitWidth * 20)">
                <svg  
                    [attr.viewBox]="'-50 0 ' + (model.width + 100) + ' ' + model.taskHeight(model.rootProject)" 
                    [attr.id]="'svgRootProject' + model.rootProject.data.taId"
                    xmlns="http://www.w3.org/2000/svg"
                    class="svg-project">
                    <g xc-svg-gantt-task-shape 
                        class="task-svg-item" 
                        [model]="model.svgRootProject">
                    </g>
                    <!-- Le lien temporaire qui apparaît lors des opérations de liaison -->
                    <!-- Il est affiché par dessus les tâches pour être bien visible -->
                    @if (model.transientLink) {
                        <g xc-svg-gantt-task-link [model]="model.transientLink"></g>
                    }
                </svg>
            </div>
        </div>
        <div class="task-label">
            {{model.rootProject.data.taName + ' / ' + model.rootProject.data.statusView.taStViDisplayName}}
        </div>
    </div>
}
