import { SvgEntityDTOBuilder } from './svg-entity-dto-builder';
import { SvgEntityDTO } from './svg-entity-dto';
import { SvgPathDTO } from './svg-path-dto';
import { SvgTransform } from './../../../model/svg-model/svg-transform.model';
import { SvgEntityTypesEnum } from 'src/app/core/model/svg-model/svg-entity-type-enum';
export class SvgGroupDTO {
    id?: number;
    taskId?: number;
    floorModelId?: number;
    dataStateId?: number;
    sourceId?: number;
    parentId?: number;
    boundingBoxString?: string;
    floorCatalogName?: string;
    equipmentCategoryId?: number;
    euipmentCategoryName?: string;
    equipmentProviderId?: number;
    equipmentProviderName?: string;
    equipmentProviderColor?: string;
    entities: SvgEntityDTO[] = []
    transform?: SvgTransform;
    attributes: any;
    entityType = SvgEntityTypesEnum.g;
    defsGeometry: SvgPathDTO[] = [];

    constructor(dtoData: any) {
        if (dtoData) {
            this.id = dtoData["id"];
            this.taskId = dtoData["taskId"];
            this.floorModelId = dtoData["floorModelId"];
            this.dataStateId = dtoData["dataStateId"];
            this.sourceId = dtoData["sourceId"];
            this.parentId = dtoData["parentId"];
            this.boundingBoxString = dtoData["boundingBoxString"];
            this.floorCatalogName = dtoData["floorCatalogName"];
            this.equipmentCategoryId = dtoData["equipmentCategoryId"];
            this.equipmentProviderName = dtoData["equipmentProviderName"];
            this.equipmentProviderColor = dtoData["equipmentProviderColor"];
            this.transform = new SvgTransform(dtoData["transform"]);
            this.attributes = new SvgTransform(dtoData["attributes"]);
            dtoData["defsGeometry"].forEach((p: any) => {
                this.defsGeometry.push(new SvgPathDTO(p));
            });
            this.entities = SvgEntityDTOBuilder.getDTOSvgEntities(dtoData["entities"]);
        }
    }
}