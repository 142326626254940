import { Point } from "src/app/core/model/geometry-model/point.model";
import { UserInteraction } from "./interaction/user-interaction";
import { PolygonService } from "src/app/core/model/geometry-model/polygon-service";
import { SvgEntityTypesEnum } from "src/app/core/model/svg-model/svg-entity-type-enum";
import { SvgLineService } from "src/app/core/model/svg-model/svg-line-service";
import { DxfSvgLine } from "./svg/dxf-svg-line";
import { SvgEntityPoint } from "../../shared/gizmos/model/svg-entity-point";
import { SvgEntityPointStyleEnum } from "../../shared/gizmos/model/svg-entity-point-style-enum";
import { SvgGeometryService } from "./svg/svg-geometry-service";

export class TimeoutDetection {
    // Cette méthode est appelée par le callback du timer de détection d'accrochage
    static detectTargetGrips(svgHitPoint: Point, ui: UserInteraction): void {
        // Défini le réticule de détection
        const reticle = PolygonService.centeredSquare(svgHitPoint, ui.getSvgPixelUnit() * 15);
        const grips: Point[] = [];
        let selectedIds = ui.selectionInteraction.selectedEntities.map(x=> x.entityId);

        // if (ui.drawing.geoTiling) {
        //     // On récupère les entités qui ont des points dans les tuiles que touche le réticule
        //     const tilesEntities = ui.drawing.geoTiling.getTiles(reticle.vertices[0], reticle.vertices[2]).map(x=> x.items).flat(1);
        //     // On recherche alors les entités qui ont tous leurs points dans le rectangle de sélection
        //     tilesEntities.forEach(te => {
        //         if (!selectedIds.includes(te.entityId)) {
        //             const pts = te.selectablePoints.filter(x=> x.gripStyle === ui.currentGripStyle);
        //             //grips.push(...reticle.areIn(pts));
        //             grips.push(...pts);
        //         }
        //     });

        //     // On recherche également parmi les entités éventuellement nouvellement créées
        //     ui.drawing.justInsertedEntities.forEach(e => {
        //         const pts = e.selectablePoints.filter(x=> x.gripStyle === this.currentGripStyle);
        //         //grips.push(...reticle.areIn(pts));
        //         grips.push(...pts);
        //     });

        //     if (grips.length > 0) {
        //         const nearestGrip = Point.getNearest(grips, svgHitPoint);
        //         ui.targetGrips.loadFromPoints(grips);
        //         ui.currentCommand.magnetAttraction = 0.15;
        //         ui.targetGrips.select(nearestGrip);
        //     }
        // }

        const all = ui.drawing.layersController.getAllEntities();
        const intersectedItems = SvgGeometryService.getIntersectedElements(all, reticle);
        if (ui.currentGripStyle === SvgEntityPointStyleEnum.intersection) {
            const others = intersectedItems.filter(x=> !selectedIds.includes(x.entityId));
            if (others.length === 2) {
                grips.push(...SvgGeometryService.getIntersects(others));
            }
        } else {
            intersectedItems.forEach(ii => {
                if (!selectedIds.includes(ii.entityId)) {
                    const pts: SvgEntityPoint[] = []
                    switch (ui.currentGripStyle) {
                        case SvgEntityPointStyleEnum.end:
                        case SvgEntityPointStyleEnum.center:
                        case SvgEntityPointStyleEnum.middle:
                        case SvgEntityPointStyleEnum.quadrant:
                            // Cas des points propres aux entités
                            pts.push(...ii.selectablePoints.filter(x=> x.gripStyle === ui.currentGripStyle));
                            break;
                        case SvgEntityPointStyleEnum.orthogonal:
                            // Cas des points calculés par rapport à l'élément en cours de modification
                            const p = ui.updateGizmos.initialGripClickedPosition;
                            if (p) {
                                const single = ui.selectionInteraction.single();
                                // TODO : traitement pour un segment de path
                                if (single && single.entityType === SvgEntityTypesEnum.line) {
                                    const otherEnd = SvgLineService.otherEnd((single as DxfSvgLine), p);
                                    const op = SvgGeometryService.getOrthogonalProjection(ii, otherEnd);
                                    if (op) {
                                        pts.push(op);
                                    }
                                }
                            }
                            break;
                        case SvgEntityPointStyleEnum.intersection:
                            // Ce cas n'est pas traité dans la boucle unitaire
                            break;
                        default:
                            break;
                    }
                    grips.push(...pts);
                }
            });
        }

        // Si la grille est affichée, on recherche l'intersection sous le réticule
        if (ui.updateGizmos.gridGizmo.displayed && ui.currentGripStyle === SvgEntityPointStyleEnum.intersection) {
            const gi = ui.updateGizmos.gridGizmo.getTransformedNearestIntersect(svgHitPoint);
            if (gi) {
                grips.push(gi);
            }
        }

        if (grips.length > 0) {
            const nearestGrip = Point.getNearest(grips, svgHitPoint);
            ui.targetGrips.loadFromPoints(grips);
            ui.currentCommand.magnetAttraction = ui.getSvgPixelUnit() * 15;
            ui.targetGrips.select(nearestGrip);
        }
    }
}