@if (model) {
    <div>
        <p-iconField iconPosition="left" class="ml-auto">
            <p-inputIcon>
                <i class="pi pi-search"></i>
            </p-inputIcon>
            <input 
                pInputText 
                type="text" 
                (input)="model!.searchForBuilding($any($event.target).value)" 
                placeholder="Immeuble..." />
        </p-iconField>
    </div>
}
