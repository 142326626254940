import { TablesNamesEnum } from './../tables-names-enum';
export const EquipmentPurchaseDetailTable = {
    databaseTableName: TablesNamesEnum.EquipmentPurchaseDetail,
    eqPuDeId: 'EqPuDe_Id',
    eqPuDePurchaseId: 'EqPuDe_PurchaseId',
    eqPuDeEquipmentCategoryId: 'EqPuDe_EquipmentCategoryId',
    eqPuDeEquipmentCatalogId: 'EqPuDe_EquipmentCatalogId',
    eqPuDeQuantity: 'EqPuDe_Quantity',
    eqPuDeUnitPrice: 'EqPuDe_UnitPrice',
  }
  