export enum QuadrantPosition {
        //
    // Résumé :
    //     Le point est en haut à gauche
    TopLeft,
    //
    // Résumé :
    //     Le point est en haut à droite
    TopRight,
    //
    // Résumé :
    //     Le point est en bas à gauche
    BottomLeft,
    //
    // Résumé :
    //     Le point est en bas à droite
    BottomRight,
    //
    // Résumé :
    //     Le point est sur la partie positive de l'ordonnée
    TopAxis,
    //
    // Résumé :
    //     Le point est sur la partie négative de l'ordonnée
    BottomAxis,
    //
    // Résumé :
    //     Le point est sur la partie négative de l'abscice
    LeftAxis,
    //
    // Résumé :
    //     Le point est sur la partie positive de l'abscice
    RightAxis,
    //
    // Résumé :
    //     La position du point n'est pas définie
    None
}
