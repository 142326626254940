import { Room } from './../tables/room';
import { RoomView } from './../views/room-view';
import { FloorData } from './../tables/floor-data';
import { Floor } from './../tables/floor';
import { Building } from './../tables/building';
import { WorkplaceType } from 'src/app/core/model/data-model/tables/workplace-type';
import { Workplace } from 'src/app/core/model/data-model/tables/workplace';
import { getView } from '../views/view-builder';

export class WorkplaceViewSet {
    dataSet: (Workplace & WorkplaceType & Building & Floor & FloorData & RoomView & Room);

    constructor(dtoData: any) {
        this.dataSet = getView(dtoData, Workplace, WorkplaceType, Building, Floor, FloorData, RoomView, Room);
    }
}