import { BuildingActivityStatusView } from "src/app/core/model/data-model/views/building-activity-status-view";
import { BuildingAnatomyView } from "src/app/core/model/data-model/views/building-anatomy-view";
import { BuildingAttributionView } from "src/app/core/model/data-model/views/building-attribution-view";
import { BuildingEquipmentProviderView } from "src/app/core/model/data-model/views/building-equipment-provider-view";
import { BuildingAllocationView } from "src/app/core/model/data-model/views/building-allocation-view";
import { SliceAnonymousData } from "src/app/ui/shared/shared-model/slice-anonymous-data";
import { RoomLayoutType } from "src/app/core/model/data-model/tables/room-layout-type";
import { ArrayUtils } from "src/app/core/model/static-functions/array-utils";

export class BuildingAnonymousDonutChartDataBuilder {
    static getRoomLayoutData(view: SliceAnonymousData[], data: RoomLayoutType[], level: number): SliceAnonymousData[] {
        const result: SliceAnonymousData[] = [];

        data.forEach(e => {
            const l1Items = view.filter(x=> x.parentId === e.roLaTyId);
            const area = ArrayUtils.sumBy(l1Items, "value");
            result.push(new SliceAnonymousData(e.roLaTyId, e.roLaTyColor, e.roLaTyName, area, level, e.roLaTyParentId?? 0));
        });

        return result;
    }

    static getActivityData(view: BuildingActivityStatusView[]): SliceAnonymousData[] {
        const result: SliceAnonymousData[] = [];

        view.forEach(e => {
            result.push(new SliceAnonymousData(e.roActivityStatusTypeId, e.roAcTyColor, e.roAcTyViDisplayName, e.buAcStViRoundedArea));
        });

        return result;
    }

    static getAttributionData(view: BuildingAttributionView[]): SliceAnonymousData[] {
        const result: SliceAnonymousData[] = [];

        view.forEach(e => {
            result.push(new SliceAnonymousData(e.roAttributionTypeId, e.roAtTyColor, e.roAtTyViDisplayName, e.buAtViRoundedArea));
        });

        return result;
    }

    static getInventoryData(view: BuildingEquipmentProviderView[]): SliceAnonymousData[] {
        const result: SliceAnonymousData[] = [];

        view.forEach(e => {
            result.push(new SliceAnonymousData(e.eqCgProviderId, e.prCoColor, e.prCoName, e.buEqPrViEquipmentValue));
        });

        return result;
    }

    static getBusinessUnitsData(view: BuildingAllocationView[]): SliceAnonymousData[] {
        const result: SliceAnonymousData[] = [];

        view.forEach(e => {
            result.push(new SliceAnonymousData(e.roAlBusinessUnitId, e.buUnColor, e.buUnName, e.buAlViRoundedArea));
        });
        
        return result;
    }
}