import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SvgEntityTypesEnum } from 'src/app/core/model/svg-model/svg-entity-type-enum';
import { DxfSvgEntity } from '../../model/svg/dxf-svg-entity';

@Component({
  selector: 'g[xc-cad-conv-svg-group]',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './cad-conv-svg-group.component.svg',
  styleUrls: ['./cad-conv-svg-group.component.scss']
})
export class CadConvSvgGroupComponent {
  SvgEntityTypesEnum = SvgEntityTypesEnum;
  @Input() entities: DxfSvgEntity[] = [];
}
