@if (model) {
    <div id="red-main">
      <p-tabView (onChange)="model.selectedTabChange($event)">
        @for (c of model.tabs; track c) {
          <p-tabPanel [header]="c.name">
            @switch (c.id) {
              @case (model.purchaseTabEnum.detail) {
                <xc-dyn-table [model]="$any(c.content?.content)" class="center-center" />
              }
              @case (model.purchaseTabEnum.receipt) {
                  <xc-purchases-receipt class="extended" [model]="$any(c.content)"/>
              }
            }
          </p-tabPanel>
        }
      </p-tabView>
    </div>
}
