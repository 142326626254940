import { Container } from 'typedi';
import { FloorModelEnum } from "src/app/core/model/data-model/enums/floor-model-enum";
import { Point } from "src/app/core/model/geometry-model/point.model";
import { EntitiesFormModel } from "../../../../blueprint-viewer-side-panel/subitems/properties/model/entities-form-model";
import { MeasurementUpdateGizmoWM } from "../../../subitems/gizmos/measurement-update-gizmo/model/measurement-update-gizmo-vm";
import { FloorBlueprint } from "../floor-blueprint";
import { EditableLayer } from "./editable-layer";
import { EntitiesSelectionSet } from "./entities-selection-set";
import { InteractionCommand } from "./interaction-command";
import { LayerInteraction } from "./layer-interaction";
import { SelectionInteraction } from "./selection-interaction";
import { GripsDetection } from './grips-detection';
import { SvgDOM } from './svg-dom';
import { BpSvgMeasurementLabel } from '../../../../svg-entities/model/bp-svg-measurement-label';
import { DyntService } from 'src/app/core/services/backend-services/dynt-service';
import { FloorDataTable } from 'src/app/core/model/db-model/tables/floor-data-table';
import { EventEmitter } from 'src/app/core/events/event-emitter';
import { LayersTreeModel } from '../../../../blueprint-viewer-side-panel/subitems/layer-tree/model/layers-tree-model';
import { FloorDataStateEnum } from 'src/app/core/model/data-model/enums/floor-data-state-enum';
import { MeasurementService } from 'src/app/core/services/backend-services/measurement-service';

export class MeasurementInteraction extends EventEmitter implements LayerInteraction {
    layerId = FloorModelEnum.Measurement;
    layerName: string = "NA";
    floorBlueprint: FloorBlueprint;
    editableLayers: EditableLayer[] = [];

    isEditable: boolean;

    selectionSet: EntitiesSelectionSet = new EntitiesSelectionSet();
    currentCommand: InteractionCommand;

    selectionInteraction: SelectionInteraction | undefined;
    selectionCount: number = 0;

    updateGizmo: MeasurementUpdateGizmoWM = new MeasurementUpdateGizmoWM();
    // TODO: monter un properties forms si nécessaire
    propertiesForm: EntitiesFormModel | undefined;

    constructor(command: InteractionCommand, blueprint: FloorBlueprint, editableLayers: EditableLayer[]) {
        super();

        this.floorBlueprint = blueprint;
        this.editableLayers = editableLayers;
        this.currentCommand = command;
        
        this.isEditable = editableLayers.find(x=> x.layerId === this.layerId) != null;

        this.updateGizmo = new MeasurementUpdateGizmoWM();
        this.updateGizmo.onEndHandled = (clientPoint: Point) => {
            this.currentCommand.set(InteractionCommand.measurementEndPointTranslationCommand);
        }
    }

    async activate(labels: BpSvgMeasurementLabel[]): Promise<void> {
        this.selectionSet = new EntitiesSelectionSet(); 
        this.selectionSet.addOrRemove(labels, true);

        this.updateGizmo.show(this.selectionSet);
    }

    deactivate(): void {
        throw new Error('Method not implemented.');
    }
    
    abort(): void {
        if (this.currentCommand.isMeasurementEndpointTranslationCommand()) {
            this.updateGizmo?.resetEndpoint();
            return;
        }

        if (this.currentCommand.isMeasurementStartInsertionCommand()) {
            this.endMeasurementInsertCommand();
            this.updateGizmo?.hide();
        }
    }

    selectionSetChanged(): void {
        this.updateGizmo.selectionSetChanged();    }

    async deleteSelectedItems(): Promise<void> {
        // La cote est simplement supprimée du jeu de données. Il n'y a pas de gestion de data state.
        const s = Container.get(DyntService);
        const result = await s.deleteMultiple(FloorDataTable.databaseTableName, this.selectionSet.floorDataIds());
        if (result != null) {
            const l = this.floorBlueprint.layersController.measurementLayer(this.floorBlueprint.topMostTaskId());
            if (l) {
                this.selectionSet.items.forEach(e => {
                    l.remove(e.floorDataId);
                });
            }

            this.selectionSet.clear();
            this.raiseSelectionDeleted();
        }
    }

    beginInsert(args: any): void {
        this.currentCommand.set(InteractionCommand.measurementStartInsertionCommand);
        this.updateGizmo?.showTransient();
    }

    endInsert(): void {
        this.updateGizmo?.hideTransient();
    }

    endMeasurementInsertCommand(): void {
        this.endInsert();

        // Réactive le curseur de sélection sur les items de l'étude
        const topMostTaskId = this.floorBlueprint.topMostTaskId();
        this.floorBlueprint.layersController.updateCursor(this.editableLayers, topMostTaskId, true);
    }

    // moveTransientSegment(hitPoint: Point): void {
    //     //this.updateGizmo.updateTransientReticle(hitPoint, 0.15);
    //     if (this.updateGizmo) {
    //         this.updateGizmo.transientSegment = Segment.null();
    //     }
    // }

    refreshThemeNeeded?: (() => void) | undefined;
    askForThemerefresh(): void {
        throw new Error('Method not implemented.');
    }

    selectionDeleted?: (() => void) | undefined;
    raiseSelectionDeleted(): void {
        if (this.selectionDeleted) {
            this.selectionDeleted();
        }
    }

    detectTargetGrip(hitPoint: Point, mi: MeasurementInteraction): void {
        if(!hitPoint) {
            return;
        }

        let result = SvgDOM.getElementFromSvgPoint(hitPoint, mi.selectionSet.floorDataIds().map(String));

        if(!result){
            return;
        }

        switch (result.tagName)
        {
            case "use":
                if (result.floorDataId != null)
                {
                    mi.setUseTargetGrips(result.floorDataId, hitPoint);
                }
                break;
            case "line":
                if (result.floorDataId != null && result.floorModelId === 5)
                {
                    //mi.setUseWallTargetGrips(result.floorDataId, hitPoint);
                }
                break;
            default:
                break;
        }

        mi.currentCommand.magnetAttraction = 0.15;
    }

    setUseTargetGrips(floorDataId: number, hitPoint: Point): void {
        const contourVertices = GripsDetection.getUseTargetGrips(this.floorBlueprint, floorDataId, this.currentCommand.selectedGripOptions, hitPoint);

        const nearest = Point.getNearest(contourVertices, hitPoint);

        this.updateGizmo.targetGrips.loadFromPoints(contourVertices);
        this.updateGizmo.targetGrips.select(nearest);
    }


    async mouseDown(e: MouseEvent, hitPoint: Point): Promise<boolean> {
        if (this.currentCommand.isMeasurementStartInsertionCommand()) {
            let targetPoint = hitPoint;

            this.updateGizmo?.setTransientSegmentStartPoint(hitPoint);

            this.currentCommand.set(InteractionCommand.measurementEndInsertionCommand);
            //this.updateGizmo.showTransient(targetPoint);
            // if (this.updateGizmo.targetGrips.items.length > 0) {
            // // S'il y a des grips de positionnement affichés, déplace l'extrémité de la cloison sur le grip le plus proche
            // const nearestGrip = this.updateGizmo.targetGrips.getSelected();
            // if (nearestGrip) {
            //     targetPoint = nearestGrip.point;
            // }
            // this.updateGizmo.targetGrips.clear();
            // }
    
            // Fin de la commande
            // le mouseUp n'est pas utilisé
            // le call au backend se fait dans le setTransientStartPoint
            //const newWall = await this.setTransientStartPoint(targetPoint);
    
    
            // Selectionne la nouvelle cloison pour continuer immédiatement l'implantation
            //this.selectionSet.addOrRemove([newWall], true);
            //this.selectItem(newWall);
            //this.onSelectionSetChanged();
            
            // Indique au caller (userInteraction) de sortir immédiatement de son propre mouseDown
            return true;
        }

        if (this.currentCommand.isMeasurementEndInsertionCommand()) {
            let targetPoint = hitPoint;
            if (!this.updateGizmo) return true;

            const s = Container.get(MeasurementService);
            const result = await s.insertNew(
                this.floorBlueprint.topMostTaskId(),
                this.updateGizmo.transientSegment.startPoint,
                this.updateGizmo.transientSegment.endPoint,
                null,
                null);
            if (result != null) {
                let measurementLayer = this.floorBlueprint.layersController.measurementLayer(this.floorBlueprint.topMostTaskId());
                if (measurementLayer == null && result.layer) {
                    // Se produit lorsqu'il n'y a pas encore de cote sur le plan et dans ce cas les datas de retour
                    // contiennent le calque à insérer
                    measurementLayer = result.layer;
                    this.floorBlueprint.layersController.insertLayer(measurementLayer);
                    this.emitEventAsync(LayersTreeModel.layerTreeRefreshRequestEvent);
                }
                result.label.dataStateId = FloorDataStateEnum.Added;
                measurementLayer!.insert(result.label);

                // Selectionne la nouvelle porte pour continuer immédiatement l'implantation
                await this.activate([result.label]);
            }

            // Termine la commande
            this.currentCommand.clear();
            this.endMeasurementInsertCommand();
            document.documentElement.style.cursor = "default";

            return true;
        }

        if (this.currentCommand.isMeasurementEndpointTranslationCommand()) {
            return true;
        }

        return false;
    }

    timer: number | undefined;
    mouseMove(e: MouseEvent, hitPoint: Point): void {
        if (this.currentCommand.isMeasurementStartInsertionCommand()) {
            // Arrête le timer
            clearTimeout(this.timer);
        
            // Le magnetAttraction sert à empêcher tout mouvement tant que la souris ne s'est pas déplacée d'une distance minimale
            // lorsqu'un attracteur est affiché
            // Cela permet à l'utilisateur de bien voir l'attracteur avant de décider ou non de relâcher la souris
            // sans que le moindre minuscule mouvement ne le fasse disparaître ou changer de place
            if (this.currentCommand.tempSvgMousePosition != null &&
                hitPoint.distanceTo(this.currentCommand.tempSvgMousePosition) < this.currentCommand.magnetAttraction) {
                return;
            }
        
            // La souris est sortie du périmètre d'attraction, on masque l'attracteur
            if (this.currentCommand.magnetAttraction > 0) {
                this.updateGizmo.targetGrips.clear();
                this.currentCommand.magnetAttraction = 0;
            }
        
            // Actualise la position courante de la souris pour le contrôle de l'attraction lors du prochain MouseMove
            this.currentCommand.tempSvgMousePosition = hitPoint;
        
            this.updateGizmo.moveEndpoint(hitPoint);
            this.updateGizmo?.moveReticle(hitPoint);
        
            // Redémarre le timer
            this.timer = window.setTimeout(this.detectTargetGrip, 150, hitPoint, this);
        
            return;
        }

        if (this.currentCommand.isMeasurementEndInsertionCommand()) {
            this.updateGizmo?.setTransientSegmentEndPoint(hitPoint);
            return;
        }

        if (this.currentCommand.isMeasurementEndpointTranslationCommand()) {
            // Arrête le timer
            clearTimeout(this.timer);
        
            // Le magnetAttraction sert à empêcher tout mouvement tant que la souris ne s'est pas déplacée d'une distance minimale
            // lorsqu'un attracteur est affiché
            // Cela permet à l'utilisateur de bien voir l'attracteur avant de décider ou non de relâcher la souris
            // sans que le moindre minuscule mouvement ne le fasse disparaître ou changer de place
            if (this.currentCommand.tempSvgMousePosition != null &&
                hitPoint.distanceTo(this.currentCommand.tempSvgMousePosition) < this.currentCommand.magnetAttraction) {
                return;
            }
        
            // La souris est sortie du périmètre d'attraction, on masque l'attracteur
            if (this.currentCommand.magnetAttraction > 0) {
                this.updateGizmo.targetGrips.clear();
                this.currentCommand.magnetAttraction = 0;
            }
        
            // Actualise la position courante de la souris pour le contrôle de l'attraction lors du prochain MouseMove
            this.currentCommand.tempSvgMousePosition = hitPoint;
        
            this.updateGizmo?.moveEndpoint(hitPoint);
        
            // Redémarre le timer
            this.timer = window.setTimeout(this.detectTargetGrip, 150, hitPoint, this);
        
            return;
        }

    }

    async mouseUp(e: MouseEvent): Promise<void> {
        if (this.currentCommand.isMeasurementEndpointTranslationCommand()) {
            this.currentCommand.clear();

            if (this.updateGizmo.targetGrips.items.length > 0) {
                // S'il y a des grips de positionnement affichés, déplace l'extrémité de la cote sur le grip le plus proche
                const nearestGrip = this.updateGizmo.targetGrips.getSelected();
                if (nearestGrip) {
                    this.updateGizmo.moveEndpoint(nearestGrip.point);
                }
                this.updateGizmo.targetGrips.clear();
            } 

            const single = this.selectionSet.single() as BpSvgMeasurementLabel;
            if (single) {
                const s = Container.get(MeasurementService);
                s.update(single.floorDataId, single.measureLine!.startPoint, single.measureLine!.endPoint, null, null);
            }
        }
    }

    async keyDown(e: KeyboardEvent): Promise<void> {
        throw new Error('Method not implemented.');
    }
}
