import { Vector } from "src/app/core/model/geometry-model/vector.model";
import { SvgEntityTypesEnum } from "src/app/core/model/svg-model/svg-entity-type-enum";
import { SvgEntity } from "src/app/core/model/svg-model/svg-entity.model";
import { SvgPathService } from "src/app/core/model/svg-model/svg-path-service";
import { SvgEntityPoint } from "../../shared/gizmos/model/svg-entity-point";
import { SvgEntityPointStyleEnum } from "../../shared/gizmos/model/svg-entity-point-style-enum";
import { SvgEntityParser } from "../../shared/gizmos/model/svg-entity-parser";
import { SelectableSvgEntity } from "./selectable-svg-entity";
import { Point } from "src/app/core/model/geometry-model/point.model";
import { createRandomString } from "./selectable-entity-builder";

export class SelectableSvgPath extends SelectableSvgEntity {
    d: string;

    constructor(dtoData: any) {
        super(dtoData);
        this.d = SvgEntity.getValue(dtoData, "d");
    }

    override getSelectablePoints(): SvgEntityPoint[] {
        const vertices = SvgEntityPoint.toStyle(SvgPathService.getVertices(this.d), SvgEntityPointStyleEnum.end, this.entityId);
        return vertices.concat(SvgPathService.getMiddlePoints(this.entityId, this.d));
    }

    override getStatement(): string {
        const attributesStatement = SvgEntityParser.getAttributesStatement([["d", this.d], ["stroke-width", this.strokeWidth], ["stroke", this.stroke]]);
        return SvgEntityParser.getTagStatement("path", attributesStatement);
    }

    startPoint(): Point | undefined {
        return SvgPathService.getPathStartPoint(this.d);
    }

    translate(delta: Vector): void {
        let tmp = this.d;
        let index = 0;
        let part = this.d.substring(index);
        this.selectablePoints.forEach(p => {
            const pn = p.translate(delta);
            const ps = `${p.x} ${p.y}`;
            const pi = part.indexOf(ps);
            const pns = `${pn.x} ${pn.y}`;
            const partn = part.replace(ps, pns);
            tmp = tmp.replace(part, partn);
            index = pi + ps.length;
            part = part.substring(index);
        });
        this.d = tmp;
        this.updateSelectablePoints();
    }

    isClosed(): boolean {
        return this.d.trimEnd().toLowerCase().endsWith("z");
    }

    translatePoint(p: Point, target: Point): void {
        let tmp = this.d;
        const ps = `${p.x} ${p.y}`;
        const pns = `${target.x} ${target.y}`;
        tmp = tmp.replace(ps, pns);
        this.d = tmp;
        this.updateSelectablePoints();
    }

    static fromValues(d: string, strokeWidth: number, stroke: string = "black", fill: string = "none"): SelectableSvgPath {
        return new SelectableSvgPath({entityType: SvgEntityTypesEnum.path, entityId: createRandomString(8), d: d, strokeWidth: strokeWidth, stroke: stroke, fill: fill});
    }
}